import React, { useEffect, useState } from "react";
import AddCreditNoteHeader from "./AddCreditNoteHeader";
import AddCreditNoteBody from "./AddCreditNoteBody";
import AddItemInAddCredit from "./AddItemInAddCredit";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../../api/ApiCall";
import { CreditNoteRelatedEndPoints } from "../../../../api/Endpoints";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToAllCreditNote } from "../../../../Navigation/Navigation";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { toast } from "react-toastify";

function AddCreditNote() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setloader] = useState(false);
  const [selectedCustomer, setselectedCustomer] = useState("");
  const [selectedInvoice, setselectedInvoice] = useState("");
  const [creditNoteNumber, setcreditNoteNumber] = useState("");
  const [invoiceItems, setinvoiceItems] = useState([]);
  const [items, setitems] = useState([]);
  const [reason, setreason] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [locationData, setlocationData] = useState([]);
  const [creditNotesDetails, setCreditNotesDetails] = useState([]);
  const [adjustment, setadjustment] = useState(0);

  const calculateTotalSum = () => {
    const sum = invoiceItems
      ?.map((item) => parseFloat(item?.total || 0))
      ?.reduce((acc, val) => acc + val, 0);
    const total = parseInt(sum) + parseInt(adjustment || 0);
    return total?.toFixed(2);
  };

  const handleReset = () => {
    setloader(false);
    setselectedCustomer("");
    setselectedInvoice("");
    setcreditNoteNumber("");
    setreason("");
    setinvoiceItems("");
    setitems("");
    navigate(navigateToAllCreditNote());
  };

  // ADD CREDIT NOTE FUNCTION API CALL
  const handleAddCreditNote = async () => {
    startApiCall(seterrorMessage, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      creditNoteNumber: creditNoteNumber,
      reason: reason,
      amount: calculateTotalSum(),
      createdBy: user?._id,
      invoiceId: selectedInvoice,
      customerId: selectedCustomer,
      items: items,
      adjustment: adjustment,
    };
    const res = await ApiCall(
      "post",
      CreditNoteRelatedEndPoints.addCredit,
      data
    );
    if (res?.success) {
      toast.success("Credit Note created successfully!");
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };
  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  const handleGetCreditnotesDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      creditNoteId: locationData?.id,
    };
    const res = await ApiCall(
      "post",
      CreditNoteRelatedEndPoints.getCreditNoteById,
      data
    );
    if (res?.success) {
      setCreditNotesDetails(res?.invoice);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (locationData?.type === "Edit") {
      handleGetCreditnotesDetails();
    }
  }, [locationData]);

  // SET ALL VALUE WHEN EDIT CREDIT NOTE
  useEffect(() => {
    if (locationData?.type === "Edit") {
      setselectedCustomer(creditNotesDetails?.customerId?._id);
      setselectedInvoice(creditNotesDetails?.invoiceId?._id);
      setcreditNoteNumber(creditNotesDetails?.creditNoteNumber);
      setinvoiceItems(creditNotesDetails?.items);
      const mappedItems = creditNotesDetails?.items?.map((item) => ({
        itemId: item?.itemId?._id,
        quantity: item?.quantity,
        discount: item?.itemId?.discount ? item?.itemId?.discount : 0,
        total: item?.total,
      }));
      setitems(mappedItems);
      setreason(creditNotesDetails?.reason);
      setadjustment(creditNotesDetails?.adjustment);
    }
  }, [locationData, creditNotesDetails]);

  // EDIT CREDIT NOTE FUNCTION API CALL
  const handleEditCreditNotes = async () => {
    startApiCall(seterrorMessage, setloader);
    const data = {
      creditNoteId: creditNotesDetails?._id,
      actionBy: user?._id,
      status: "pending",
      amount: calculateTotalSum(),
      reason: reason,
      items: items,
      adjustment: adjustment,
    };
    const res = await ApiCall(
      "post",
      CreditNoteRelatedEndPoints.editCreditNotes,
      data
    );
    if (res?.success) {
      toast.success("Edit Credit note successfully!");
      handleReset();
      handleGetCreditnotesDetails();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <AddCreditNoteHeader locationData={locationData} />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && (
                <AddCreditNoteBody
                  selectedCustomer={selectedCustomer}
                  setselectedCustomer={setselectedCustomer}
                  selectedInvoice={selectedInvoice}
                  setselectedInvoice={setselectedInvoice}
                  setcreditNoteNumber={setcreditNoteNumber}
                  creditNoteNumber={creditNoteNumber}
                  invoiceItems={invoiceItems}
                  calculateTotalSum={calculateTotalSum}
                  setreason={setreason}
                  reason={reason}
                  errorMessage={errorMessage}
                  handleAddCreditNote={handleAddCreditNote}
                  locationData={locationData}
                  creditNotesDetails={creditNotesDetails}
                  adjustment={adjustment}
                  setadjustment={setadjustment}
                  handleEditCreditNotes={handleEditCreditNotes}
                />
              )}
              {loader && <DataLoader title="Creating Credit Note..." />}
            </div>
          </div>
        </div>
        <AddItemInAddCredit
          invoiceItems={invoiceItems}
          setinvoiceItems={setinvoiceItems}
          setitems={setitems}
        />
      </div>
    </div>
  );
}

export default AddCreditNote;
