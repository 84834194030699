import React from "react";
import {
  convertAmountLocal,
  dateAndTimeFormatter,
} from "../../../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";
import { navigateToManageInvoiceDetails } from "../../../../Navigation/Navigation";

const InvoiceTable = ({ allInvoice, page, pageLimit, setpreviousTransaction, setInvoiceData }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8 text-center">
          <thead>
            <tr className="text-center">
              <th>Sl No.</th>
              <th>Order Number</th>
              <th>Invoice No.</th>
              <th>Created date</th>
              <th>Amount</th>
              <th>Payment Status</th>
              <th>Invoice</th>
              <th>Transactions</th>
            </tr>
          </thead>
          <tbody>
            {allInvoice?.map((data, index) => {
              return (
                <tr className="text-center inv-table">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td>{data?.orderId?.orderNumber.toString().padStart(3, "0")}</td>
                  <td>{data?.invoiceNumber}</td>
                  <td>{dateAndTimeFormatter(data?.createdAt)}</td>
                  <td className="text-dark font-3 font-mon">{convertAmountLocal(data?.orderId?.amount)}</td>
                  <td
                    className={`text-capitalize ${
                      data?.orderStatus === "confirmed"
                        ? "text-success"
                        : data?.orderStatus === "pending"
                        ? "text-warning"
                        : "text-danger"
                    }`}
                  >
                    {data?.orderStatus==="confirmed"?"Paid":data?.orderStatus}
                  </td>
                  <td
                    onClick={() => {
                      navigate(
                        navigateToManageInvoiceDetails({
                          id: data?.orderId?._id,
                        }),
                        {
                          state: {
                            subHeader: "All Invoice",
                            data: "",
                          },
                        }
                      );
                    }}
                  >
                    <span className="btn btn-icon btn-rounded btn-sm">
                      <span nonce="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="#3862DD"
                          class="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                          />
                        </svg>
                      </span>
                    </span>
                  </td>
                  <td>
                    <span
                      onClick={() => {
                        // handleGetOrdersTransaction(data?.orderId?._id);
                        setpreviousTransaction(true);
                        setInvoiceData({data: data, index: index});
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="pointer"
                      >
                        <circle cx="11" cy="11" r="10.5" stroke="#3862DD" />
                        <path
                          d="M11.9224 8.0752V16H9.98145V8.0752H11.9224ZM9.84961 6.00244C9.84961 5.71436 9.94727 5.4751 10.1426 5.28467C10.3428 5.09424 10.6113 4.99902 10.9482 4.99902C11.2852 4.99902 11.5513 5.09424 11.7466 5.28467C11.9468 5.4751 12.0469 5.71436 12.0469 6.00244C12.0469 6.28564 11.9468 6.52246 11.7466 6.71289C11.5513 6.90332 11.2852 6.99854 10.9482 6.99854C10.6113 6.99854 10.3428 6.90332 10.1426 6.71289C9.94727 6.52246 9.84961 6.28564 9.84961 6.00244Z"
                          fill="#3862DD"
                        />
                      </svg>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InvoiceTable;
