import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  filterSearchData,
  getAllEmployees,
  getCurrentWorkspaceId,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  CrmContactRelatedEndPoints,
  DealRelatedEndPoints,
  LeadRelatedEndPoints,
  StatusRelatedEndPoints,
} from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const CreateDeal = ({
  handleGetAllDeals,
  editDealData,
  setEditDealData,
  handleGetDealDetails,
  isLeadDeal,
  id,
}) => {
  const { user } = useSelector((state) => state);
  var date = reversesDateFormatter(new Date());
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [allGropus, setAllGroups] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [contact, setContact] = useState("");
  const [contactFilter, setContactFilter] = useState("");
  const [contactId, setContactId] = useState("");
  const [initialStatusLoader, setInitialStatusLoader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [subStatus, setSubStatus] = useState([]);
  const [group, setGroup] = useState("");
  const [filter, setFilter] = useState("");
  const [groupId, setGroupId] = useState("");
  const [email, setEmail] = useState("");
  const filteredGroup = allGropus?.filter((grp) =>
    grp?.name?.toLowerCase()?.includes(filter.toLowerCase())
  );
  const filteredContacts = allContacts?.filter((con) =>
    con?.name?.toLowerCase()?.includes(contactFilter?.toLocaleLowerCase())
  );

  const allBusinessType = ["B2B", "B2C", "B2B2C"];
  const [businessType, setBusinessType] = useState("");
  const [allLeadSource, setAllLeadSource] = useState([]);
  const [leadSource, setleadSource] = useState("");

  const [dealInfo, setDealInfo] = useState({
    dealOwner: "",
    dealName: "",
    dealStage: "",
    amount: "",
    closingDate: "",
  });

  const [additionalDetails, setAdditionalDetails] = useState({
    employee: "",
    revenue: "",
    industry: "",
    leadStatus: "",
  });
  const [note, setNote] = useState("");

  //Select Deal Owner
  const [dealOwnerLoader, setdealOwnerLoader] = useState(false);
  const [selectDealOwner, setselectDealOwner] = useState("");
  const [filterDealOwner, setFilterDealOwner] = useState("");
  const [allDealOwner, setallDealOwner] = useState([]);
  const [dealOwnerId, setdealOwnerId] = useState("");
  const filteredDealOwner = filterSearchData(allDealOwner, filterDealOwner);

  const handleGetAllContacts = async () => {
    startApiCall(null, setloader3);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isRemoved: false,
      },
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.getContact,
      data
    );
    if (res?.success) {
      setAllContacts(res?.result?.data);
      setloader3(false);
    } else {
      setloader3(false);
    }
  };

  useEffect(() => {
    if (editDealData) {
      setDealInfo({
        dealName: editDealData?.title,
        dealStage: editDealData?.dealStage,
        amount: editDealData?.amount,
        closingDate: reversesDateFormatter(editDealData?.closingDate),
      });
      setAdditionalDetails({
        employee: editDealData?.numberOfEmployees,
        revenue: editDealData?.annualRevenue,
        industry: editDealData?.industry,
        // leadSource: editDealData?.source,
      });
      setleadSource(editDealData?.source);
      setdealOwnerId(editDealData?.dealOwner?._id);
      setselectDealOwner(editDealData?.dealOwner?.name);
      setContactId(editDealData?.contactId?._id);
      setContact(editDealData?.contactId?.name);
      setNote(editDealData?.note);
      setBusinessType(editDealData?.businessType);
      setEmail(editDealData?.contactId?.email);
    }
  }, [editDealData]);

  const handleChangeDealInfo = (e) => {
    const { name, value } = e.target;
    setDealInfo({
      ...dealInfo,
      [name]: value,
    });
  };

  const handleGetLeadSource = async () => {
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLeadSource, data);
    if (res?.success) {
      setAllLeadSource(res?.result?.data);
    }
  };

  useEffect(() => {
    handleGetLeadSource();
  }, []);

  const handleChangeAdditionalDetails = (e) => {
    const { name, value } = e.target;
    if (name === "revenue" || name === "employee") {
      const newValue = value.replace(/\D/g, "");
      setAdditionalDetails({
        ...additionalDetails,
        [name]: newValue,
      });
    } else {
      setAdditionalDetails({
        ...additionalDetails,
        [name]: value,
      });
    }
  };

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setDealInfo({
      dealOwner: "",
      dealName: "",
      dealStage: "",
      amount: "",
      closingDate: "",
    });
    setAdditionalDetails({
      employee: "",
      revenue: "",
      industry: "",
      leadSource: "",
      leadStatus: "",
    });
    if (editDealData !== "") {
      setEditDealData("");
    }
    setEmail("");
    setleadSource("");
    setContactId("");
    setContact("");
    setGroupId("");
    setGroup("");
    setselectDealOwner("");
    setdealOwnerId("");
    setNote("");
    const closeModalDom = document.getElementById("create_new_deal");
    if (closeModalDom) closeModalDom.click();
  };

  // CREATE DEAL FUNCTION API CALL
  const handleCreateDeal = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
      title: dealInfo.dealName,
      amount: dealInfo.amount,
      closingDate: dealInfo.closingDate,
      contactId: isLeadDeal ? id : contactId,
      groupId: groupId,
      source: leadSource,
      status: additionalDetails.leadStatus,
      note,
      businessType,
    };
    const res = await ApiCall("post", DealRelatedEndPoints.createDeal, data);
    if (res?.success) {
      toast.success("Deal created successfully!");
      handleReset();
      handleGetAllDeals();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // GET GROUP NAME FUNCTION API CALL
  const handleGetGroups = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 300,
      filters: {
        businessId: getCurrentWorkspaceId(),
        moduleType: "Deal",
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getGropus, data);
    if (res?.success) {
      setAllGroups(res?.result?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  // GET INITIAL STATUS FUNCTION API CALL
  const handleGetInitialGroups = async () => {
    startApiCall(null, setInitialStatusLoader);
    const data = {
      page: 1,
      limit: 100,
      filters: {
        groupId: groupId,
      },
    };
    const res = await ApiCall(
      "post",
      StatusRelatedEndPoints.getInitialGroups,
      data
    );
    if (res?.success) {
      const openStatusId = res?.result?.data?.find(
        (item) => item.name === "open"
      )?._id;
      if (openStatusId) {
        handleGetAllStatus(openStatusId);
      }
      setInitialStatusLoader(false);
    } else {
      setInitialStatusLoader(false);
    }
  };

  useEffect(() => {
    if (groupId) {
      handleGetInitialGroups();
    }
  }, [groupId]);

  // GET SUB STATUS FROM INITIAL GROUP
  const handleGetAllStatus = async (statusId) => {
    startApiCall(null, setStatusLoader);
    const data = {
      page: 1,
      limit: 50,
      filters: {
        initialStatusId: statusId,
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getStatus, data);
    if (res?.success) {
      setSubStatus(res?.result?.data);
      setStatusLoader(false);
    } else {
      setStatusLoader(false);
    }
  };

  // EDIT DEAL FUNCTION API CALL
  const handleEditDeal = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      dealId: editDealData?._id,
      title: dealInfo.dealName,
      amount: dealInfo.amount,
      closingDate: dealInfo.closingDate,
      source: leadSource,
      // dealOwner: dealOwnerId,
      actionBy: user?._id,
      note,
      businessType,
      email,
    };
    const res = await ApiCall("post", DealRelatedEndPoints.editDeal, data);
    if (res?.success) {
      toast.success("Edit deal successfully!");
      handleReset();
      handleGetDealDetails();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleGetAllEmployee = async () => {
    startApiCall(null, setdealOwnerLoader);
    const data = {
      pageLimit: 50000,
      pageNumber: 1,
      s2adminId: user?._id,
      searchKey: "",
      businessId: getCurrentWorkspaceId(),
    };

    const res = await getAllEmployees(data);
    setallDealOwner(res?.data);
    setdealOwnerLoader(false);
  };

  return (
    <div
      id="create_new_deal"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "60%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {" "}
              {editDealData === "" ? "Create" : "Edit"} Deal
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editDealData === "") {
                  handleCreateDeal(e);
                } else {
                  handleEditDeal(e);
                }
              }}
            >
              <div className="mh-450p nicescroll-bar simple-scrollbar">
                <div className="row gx-3 px-3 w-100">
                  <div
                    className="d-flex justify-content-start mt-1 mb-3 fs-5 text-dark"
                    style={{ backgroundColor: "#E3EDFF" }}
                  >
                    Deal Information
                  </div>
                  {!isLeadDeal && (
                    <div className="col-sm-4">
                      <label className="text-dark fw-250 fs-6 mb-2">
                        Select contact {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="selectable-dropdown">
                          <div className="dropdown selectable-dropdown">
                            <span
                              className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                                editDealData ? "bg-light text-disabled" : ""
                              }`}
                              aria-expanded="false"
                              type="button"
                              data-bs-toggle="dropdown"
                              disabled={editDealData}
                              onClick={() => {
                                handleGetAllContacts();
                                setContactFilter("");
                              }}
                            >
                              {contact ? (
                                <>
                                  {contact}
                                  {contact !== "" && !editDealData && (
                                    <button
                                      type="button"
                                      className="btn-close btn-sm ms-2"
                                      onClick={() => {
                                        setContact("");
                                        setContactFilter("");
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  )}
                                </>
                              ) : (
                                "Select contact"
                              )}
                            </span>
                            <div role="menu" className="dropdown-menu">
                              <form className="d-sm-block d-none" role="search">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search.."
                                  value={contactFilter}
                                  onChange={(e) =>
                                    setContactFilter(e.target.value)
                                  }
                                />
                              </form>
                              {loader3 ? (
                                <span className="d-flex text-center mt-1">
                                  Loading...
                                </span>
                              ) : (
                                <div
                                  style={{
                                    maxHeight: "25vh",
                                    overflow: "auto",
                                  }}
                                >
                                  {filteredContacts?.map((con, index) => (
                                    <div
                                      className="dropdown-item text-capitalize"
                                      key={con._id}
                                      onClick={() => {
                                        setContact(con.name);
                                        setContactId(con?._id);
                                      }}
                                    >
                                      {index + 1}. {con?.name}
                                    </div>
                                  ))}
                                </div>
                              )}
                              {!loader3 && filteredContacts?.length === 0 && (
                                <span className="d-flex text-center mt-1">
                                  No Contact found
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className="col-sm-6 mt-1">
                    <div className="form-group">
                      <label className="text-dark fw-250 fs-6 mb-2">
                        Select Lead Owner
                      </label>
                      <div className="form-group">
                        <div className="selectable-dropdown w-100">
                          <div className="dropdown selectable-dropdown">
                            <span
                              className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                                editDealData ? "bg-light text-disabled" : ""
                              }`}
                              aria-expanded="false"
                              type="button"
                              data-bs-toggle="dropdown"
                              disabled={editDealData}
                              onClick={() => {
                                setFilterDealOwner("");
                                handleGetAllEmployee();
                              }}
                            >
                              {selectDealOwner ? (
                                <>
                                  {selectDealOwner}
                                  {selectDealOwner !== "" && !editDealData && (
                                    <button
                                      type="button"
                                      className="btn-close btn-sm ms-2"
                                      onClick={() => {
                                        setselectDealOwner("");
                                        setFilterDealOwner("");
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  )}
                                </>
                              ) : (
                                "Select Deal Owner"
                              )}
                            </span>
                            <div role="menu" className="dropdown-menu">
                              <form className="d-sm-block d-none" role="search">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search.."
                                  value={filterDealOwner}
                                  onChange={(e) =>
                                    setFilterDealOwner(e.target.value)
                                  }
                                />
                              </form>
                              {dealOwnerLoader ? (
                                <span className="d-flex text-center mt-1">
                                  Loading...
                                </span>
                              ) : (
                                <div
                                  style={{
                                    maxHeight: "13vh",
                                    overflow: "auto",
                                  }}
                                >
                                  {filteredDealOwner?.map((owner, index) => (
                                    <div
                                      className={`dropdown-item text-capitalize`}
                                      key={owner?._id}
                                      onClick={() => {
                                        setselectDealOwner(owner?.name);
                                        setdealOwnerId(owner?._id);
                                      }}
                                    >
                                      {index + 1}. {owner?.name}
                                    </div>
                                  ))}
                                </div>
                              )}

                              {!dealOwnerLoader &&
                                filteredDealOwner?.length === 0 && (
                                  <span className="d-flex text-center mt-1">
                                    No owner found
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">
                        Deal Name {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="dealName"
                        placeholder="deal name"
                        required
                        value={dealInfo.dealName}
                        onChange={handleChangeDealInfo}
                      />
                    </div>
                  </div>
                  {editDealData !== "" && (
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="form-label">Email</label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">
                        Amount ₹ {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        name="amount"
                        placeholder="deal amount"
                        required
                        value={dealInfo.amount}
                        onChange={handleChangeDealInfo}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">
                        Closing Date {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="date"
                        name="closingDate"
                        min={date}
                        required
                        value={dealInfo.closingDate}
                        onChange={handleChangeDealInfo}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">
                        Business type {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md"
                            required
                            value={businessType}
                            onChange={(e) => {
                              setBusinessType(e.target.value);
                            }}
                          >
                            <option value="">Select Type</option>
                            {allBusinessType?.map((data) => {
                              return <option>{data}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Additional */}
                  <div
                    className="d-flex justify-content-start mt-3 mb-3 fs-5 text-dark"
                    style={{ backgroundColor: "#E3EDFF" }}
                  >
                    Additional
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">
                        Lead Source {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md"
                            name="leadSource"
                            required
                            value={leadSource}
                            onChange={(e) => {
                              setleadSource(e.target.value);
                            }}
                          >
                            <option value="">Select Source</option>
                            {allLeadSource?.map((data, index) => {
                              return (
                                <option value={data?.name}>
                                  {index + 1}. {data?.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!editDealData && (
                    <>
                      <div className="col-sm-4">
                        <label className="form-label">
                          Choose status group {reuiredField}
                        </label>
                        <div className="form-group">
                          <div className="selectable-dropdown">
                            <div className="dropdown selectable-dropdown">
                              <span
                                className="me-2 fs-7 fw-medium  text-dark text-capitalize border rounded px-4 py-2 w-100"
                                aria-expanded="false"
                                type="button"
                                data-bs-toggle="dropdown"
                                onClick={() => {
                                  handleGetGroups();
                                  setFilter("");
                                }}
                              >
                                {group ? (
                                  <>
                                    {group}
                                    {group !== "" && (
                                      <button
                                        type="button"
                                        className="btn-close btn-sm ms-2"
                                        onClick={() => {
                                          setGroup("");
                                          setFilter("");
                                        }}
                                        aria-label="Close"
                                      ></button>
                                    )}
                                  </>
                                ) : (
                                  "Select group"
                                )}
                              </span>
                              <div role="menu" className="dropdown-menu">
                                <form
                                  className="d-sm-block d-none"
                                  role="search"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search.."
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                  />
                                </form>
                                {loader2 ? (
                                  <span className="d-flex text-center mt-1">
                                    Loading...
                                  </span>
                                ) : (
                                  <div
                                    style={{
                                      maxHeight: "25vh",
                                      overflow: "auto",
                                    }}
                                  >
                                    {filteredGroup?.map((grp, index) => (
                                      <div
                                        className="dropdown-item text-capitalize"
                                        key={grp.name}
                                        onClick={() => {
                                          setGroup(grp.name);
                                          setGroupId(grp?._id);
                                        }}
                                      >
                                        {index + 1}. {grp.name}
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {!loader2 && filteredGroup?.length === 0 && (
                                  <span className="d-flex text-center mt-1">
                                    No group found
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label className="form-label">
                            Deal Status {reuiredField}
                          </label>
                          <div className="form-group">
                            <div className="todo-toolbar">
                              <select
                                className="form-select form-select-md text-capitalize"
                                disabled={
                                  group === "" ||
                                  initialStatusLoader ||
                                  statusLoader
                                }
                                name="leadStatus"
                                required
                                value={additionalDetails.leadStatus}
                                onChange={handleChangeAdditionalDetails}
                              >
                                <option value="">Select Status</option>
                                {subStatus?.map((data) => {
                                  return (
                                    <option value={data?._id}>
                                      {data?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-sm-8">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Note/Description</label>
                      </div>
                      <textarea
                        className="form-control"
                        placeholder="description"
                        rows="3"
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                        value={note}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? (
                    <ApiLoader />
                  ) : editDealData === "" ? (
                    "Create"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDeal;
