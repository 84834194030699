import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../api/ApiCall";
import { InventoryRelatedEndPoints } from "../../../../../api/Endpoints";
import ApiLoader from "../../../../../components/Loaders/ApiLoader/ApiLoader";

function AddMaterialInItem({
  setmaterialsInItem,
  setmaterialsInTable,
  slectedItem,
  materialEditData,
  materialEdit,
  setMaterialEdit,
  materialsInTable,
  materialsInItem,
}) {
  const [loader, setloader] = useState(false);
  const [selectMaterial, setselectMaterial] = useState("");
  const [allMaterials, setallMaterials] = useState([]);
  const [materialsData, setmaterialsData] = useState([]);
  const [materialFilter, setmaterialFilter] = useState("");
  const [quantity, setquantity] = useState("");
  const [selectedUnit, setselectedUnit] = useState("");
  const [unitId, setunitId] = useState("");

  useEffect(() => {
    if (materialEditData && materialEdit) {
      setselectMaterial(materialEditData?.inventoryItem?.name || "");
      setmaterialsData(materialEditData?.inventoryItem);
      setquantity(materialEditData?.quantity || "");
      setselectedUnit(materialEditData?.unit?.name || "");
      setunitId(materialEditData?.unit?._id || "");
    } else {
    }
  }, [materialEditData, materialEdit]);

  const handleReset = () => {
    setloader(false);
    setselectedUnit("");
    setquantity("");
    setselectMaterial("");
    setmaterialsData([]);
    setmaterialFilter("");
    setselectedUnit("");
    setunitId("");
    setMaterialEdit(false);
    const closeModalDom = document.getElementById("add_item_material");
    if (closeModalDom) {
      closeModalDom.click();
    }
  };

  const handleGetAllMaterial = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
      },
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getInventory,
      data
    );
    if (res?.success) {
      setallMaterials(res?.inventory?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllMaterial();
    }
  }, []);

  const filteredMaterial = allMaterials?.filter((matr) =>
    matr?.name?.toLowerCase()?.includes(materialFilter?.toLowerCase())
  );

  const handleAddItem = () => {
    startApiCall(null, setloader);

    const existingItemIndex =
      materialsInItem &&
      materialsInItem?.findIndex(
        (item) => item?.inventoryItem === materialsData?._id
      );

    if (materialEdit && existingItemIndex !== -1) {
      const updatedItem = {
        ...materialsInItem[existingItemIndex],
        quantity: quantity,
        unit: unitId,
      };

      setmaterialsInItem((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[existingItemIndex] = updatedItem;
        return updatedItems;
      });
    } else if (!materialEdit) {
      const newItem = {
        inventoryItem: materialsData?._id,
        quantity: quantity,
        unit: unitId,
      };

      setmaterialsInItem((prevItems) => [...prevItems, newItem]);
    }
    const existingTableItemIndex =
      materialsInTable &&
      materialsInTable?.findIndex(
        (item) => item.inventoryItem?._id === materialsData?._id
      );

    if (materialEdit && existingTableItemIndex !== -1) {
      const updatedTableItem = {
        ...materialsInTable[existingTableItemIndex],
        quantity: quantity,
        unit: {
          name: selectedUnit,
          _id: unitId,
        },
      };

      setmaterialsInTable((prevItems) => {
        const updatedTableItems = [...prevItems];
        updatedTableItems[existingTableItemIndex] = updatedTableItem;
        return updatedTableItems;
      });
    } else if (!materialEdit) {
      const tableItem = {
        inventoryItem: {
          name: materialsData?.name,
          _id: materialsData?._id,
        },
        quantity: quantity,
        unit: {
          name: selectedUnit,
          _id: unitId,
        },
      };

      setmaterialsInTable((prevItems) => [...prevItems, tableItem]);
    }

    handleReset();
  };

  return (
    <div
      id="add_item_material"
      className="modal fade add-new-task "
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "50%" }}
      >
        <div className="modal-content" style={{overflow:"visible"}}>
          <div className="modal-body">
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Add New Material</h5>
            <div className="border-top mb-3"></div>
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div class="form-group row w-100 mb-0">
                    <div className="col-sm-4 mt-1">
                      <label class="form-label">Select Material :</label>
                    </div>
                    <div className="col-sm-8">
                      <div className="row gx-3 w-100">
                        <div className="col-sm-12">
                          <div className="selectable-dropdown">
                            <div className="dropdown selectable-dropdown">
                              <span
                                className={`me-2 fs-7 fw-medium ${
                                  materialEdit ? "text-disabled" : "text-dark"
                                } text-capitalize border rounded p-2 w-300p `}
                                aria-expanded="false"
                                type="button"
                                data-bs-toggle="dropdown"
                                disabled={materialEdit}
                              >
                                {selectMaterial ? (
                                  <>
                                    {materialsData?.name}
                                    {selectMaterial !== "" && !materialEdit && (
                                      <button
                                        type="button"
                                        className="btn-close btn-sm ms-4"
                                        onClick={() => {
                                          setselectMaterial("");
                                          setmaterialsData("");
                                        }}
                                        aria-label="Close"
                                      ></button>
                                    )}
                                  </>
                                ) : (
                                  "Select Material"
                                )}
                              </span>
                              <div role="menu" className="dropdown-menu">
                                <form
                                  className="d-sm-block d-none"
                                  role="search"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Material name.."
                                    value={materialFilter}
                                    onChange={(e) => {
                                      setmaterialFilter(e.target.value);
                                    }}
                                  />
                                </form>
                                {loader ? (
                                  <span className="d-flex text-center mt-1">
                                    Loading...
                                  </span>
                                ) : (
                                  <div
                                    style={{
                                      maxHeight: "14rem",
                                      overflow: "auto",
                                      maxWidth: "35vw",
                                    }}
                                  >
                                    {filteredMaterial?.map((mat, index) => (
                                      <div
                                        className={`dropdown-item text-capitalize text-truncate ${
                                          materialsInItem?.find(
                                            (item) =>
                                              item?.inventoryItem === mat?._id
                                          )
                                            ? "disabled"
                                            : ""
                                        }`}
                                        key={mat?._id}
                                        onClick={() => {
                                          setselectMaterial(mat?.name);
                                          setmaterialsData(mat);
                                          setselectedUnit(mat?.unit?.name);
                                          setunitId(mat?.unit?._id);
                                        }}
                                      >
                                        {index + 1}. {mat?.name}
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {!loader && filteredMaterial?.length === 0 && (
                                  <span className="d-flex text-center mt-1">
                                    No Items found
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 mt-4">
                  <div class="form-group row w-100">
                    <div className="col-sm-6 mt-1">
                      <label class="form-label">Select Quantity : </label>
                    </div>
                    <div className="col-sm-6">
                      <input
                        class="form-control"
                        type="number"
                        value={quantity}
                        onChange={(e) => {
                          setquantity(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-4">
                  <div class="form-group row w-100">
                    <div className="col-sm-5 mt-1">
                      <label className="form-label">Unit</label>
                    </div>
                    <div className="col-sm-7">
                      <input
                        className="form-control"
                        type="text"
                        required
                        value={selectedUnit}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer align-items-center mt-4">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                >
                  Discard
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleAddItem}
                  disabled={!selectMaterial || !quantity || !selectedUnit}
                >
                  {loader ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
          {/* {addUnitModal && (
            <AddRawMaterialUnit
              setAddUnitModal={setAddUnitModal}
              handleGetAllUnit={handleGetAllUnit}
            />
          )} */}
        </div>
      </div>
    </div>
  );
}

export default AddMaterialInItem;
