import React from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  convertAmountLocal,
  convertTimeAndDateMoment,
  getName,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";

function CashInHandTable({
  transactions,
  page,
  pageLimit,
  setisMoreDetails,
  setselectedTransaction,
  setcashModal,
  setEditCash,
  setisDelete,
  currentWorkSpaceRole,
  isModuleReadWriteAccessForCashInHand,
}) {
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-5">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th>Date</th>
              <th>Type</th>
              <th>Amount</th>
              <th>From</th>
              <th style={{ textAlign: "left" }}>Transaction by </th>
              <th>More Details</th>
              {isModuleReadWriteAccessForCashInHand && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {transactions?.map((trans, index) => {
              return (
                <tr key={index} className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td>{convertTimeAndDateMoment(trans?.date)}</td>
                  <td>
                    {trans?.accountHeader === "income" ? (
                      <span
                        className="d-inline-block text-truncate text-capitalize text-teal"
                        style={{ width: "200px" }}
                      >
                        <span className="border px-2 me-2 bg-success">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#FFFFFF"
                            className="bi bi-plus-lg mb-1"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                            />
                          </svg>
                        </span>{" "}
                        Cash In
                      </span>
                    ) : (
                      <span
                        className="d-inline-block text-truncate text-capitalize text-danger"
                        style={{ width: "200px" }}
                      >
                        <span className="border px-2 me-2 bg-danger">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            fill="#FFFFFF"
                            className="bi bi-dash-lg mb-1"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                            />
                          </svg>
                        </span>{" "}
                        Cast Out
                      </span>
                    )}
                  </td>
                  <td
                    className="font-mon fs-6"
                    style={{
                      color:
                        trans?.accountHeader === "income" ? "green" : "red",
                    }}
                  >
                    {convertAmountLocal(trans?.amountReceived)}
                  </td>
                  <td>{trans?.transactionModule}</td>

                  <td style={{ textAlign: "left" }}>
                    <Avatar
                      name={trans?.transactionDoneBy?.name}
                      count={1}
                      image={trans?.transactionDoneBy?.image}
                      size="xss"
                      color="primary"
                    />
                    <span className="text-capitalize ms-2">
                      {getName(trans?.transactionDoneBy, user?._id)}
                    </span>
                  </td>
                  <td>
                    {trans?.description ? (
                      <svg
                        onClick={() => {
                          setselectedTransaction(trans);
                          setisMoreDetails(true);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-info-circle pointer text-primary"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                      </svg>
                    ) : (
                      "-"
                    )}
                  </td>
                  {isModuleReadWriteAccessForCashInHand && (
                    <td>
                      <span className="feather-icon pointer">
                        <span className="btn-icon-wrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            title="Edit Table"
                            className="bi bi-pencil-square me-2 text-primary"
                            viewBox="0 0 16 16"
                            onClick={() => {
                              setEditCash(trans);
                              setcashModal(true);
                            }}
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                          <svg
                            onClick={() => {
                              setEditCash(trans);
                              setisDelete(true);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            title="Delete Table"
                            fill="currentColor"
                            className="bi bi-trash text-danger"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg>
                        </span>
                      </span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CashInHandTable;
