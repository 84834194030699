import React, { useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { managementEndpoints } from "../../api/Endpoints";
import {
  getAllRolesForWorkspace,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ErrorMessage from "../Error/ErrorMessage";
import ApiLoader from "../Loaders/ApiLoader/ApiLoader";
import DataLoader from "../Loaders/DataLoader/DataLoader";
import { toast } from "react-toastify";

const ChangeRoleModal = (props) => {
  const {
    isChangeRoleModal,
    setisChangeRoleModal,
    user,
    handleRefresh,
    title,
    type,
    currentUserId,
  } = props;
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [selectedRole, setselectedRole] = useState("Select Role");
  const [allRoles, setallRoles] = useState(null);

  useEffect(() => {
    setselectedRole(user?.permission ? user?.permission?._id : "Select Role");
  }, [user]);

  const closeModal = () => {
    seterrorMessage("");
    setLoader(false);
    setisChangeRoleModal(false);
  };
  const getAllRoles = async () => {
    startApiCall(null, setLoader);
    let roles = await getAllRolesForWorkspace(getCurrentWorkspaceId());
    setallRoles(roles);
    setLoader(false);
  };
  useEffect(() => {
    getAllRoles();
  }, []);

  const endApiCall = () => {
    handleRefresh();
    closeModal();
  };

  const handleChangeEmployeeRole = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const data = {
      oldPermissionsId: user?.permission?._id,
      newPermissionsId: selectedRole,
      employeeId: user?.employee?._id,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall("post", managementEndpoints.changeRole, data);
    if (res?.success) {
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleApproveOrRejectJoiningRequest = async (e) => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      actionBy: currentUserId,
      isApproved: type === "joinApproved" ? true : false,
      employeeId: user?.employee?._id,
      role: selectedRole === "Select Role" ? "" : selectedRole,
    };
    const res = await ApiCall(
      "post",
      managementEndpoints.acceptOrRejectBusinessRequest,
      data
    );
    if (res?.success) {
      toast.success(
        `Joining request has been ${
          type === "joinApproved" ? "Approved" : "Rejected"
        } successfully`
      );
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <div
      id="change_role_people"
      className={`modal fade add-new-contact mt-30 ${
        isChangeRoleModal ? "show" : ""
      }`}
      tabIndex="-1"
      role="dialog"
      style={{
        display: isChangeRoleModal ? "block" : "none",
        pointerEvents: loader ? "none" : "",
      }}
      aria-hidden="true"
    >
      <form>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="mb-4 text_center">
                {title ? title : "Confirmation of Change Role"}
              </h5>
              {type !== "joinRejected" && (
                <div className="col-sm-12">
                  {allRoles?.length > 0 && (
                    <>
                      <div className="todo-toolbar">
                        {allRoles !== null ? (
                          <select
                            className="form-select form-select text-capitalize"
                            value={selectedRole}
                            onChange={(event) =>
                              setselectedRole(event.target.value)
                            }
                          >
                            <option value="Select Role">Select Role</option>
                            {allRoles?.map((data) => {
                              return (
                                <option value={data?._id}>
                                  {data?.roleName}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <DataLoader />
                        )}
                      </div>
                    </>
                  )}
                  {allRoles?.length === 0 && (
                    <span className="text-danger pointer text-center">
                      There is no role exists in workspace, Create new role to
                      continue{" "}
                    </span>
                  )}
                </div>
              )}
              <div className="text_center"></div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
            <div
              className="px-4 pb-4 mt-2"
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                disabled={loader}
                className="btn btn-rounded btn-secondary col-sm-5"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                disabled={
                  loader ||
                  (type === "joinApproved" &&
                    (allRoles?.length === 0 || selectedRole === "Select Role"))
                }
                className="btn btn-rounded btn-primary col-sm-5"
                onClick={
                  type !== ""
                    ? handleApproveOrRejectJoiningRequest
                    : handleChangeEmployeeRole
                }
              >
                {loader ? <ApiLoader /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangeRoleModal;
