import React from "react";
import { dateAndTimeFormatter } from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";

const CustomerDetails = ({
  customerDetails,
  setIsOpenDetailsModal,
  setEditCustomer,
  setIsOpen,
  isModuleReadWriteAccessCustomers,
}) => {
  return (
    <div className="details-modal" style={{ width: "380px" }}>
      <div className="nicescroll-bar overflow-hidden">
        <div className="d-flex justify-content-between">
          <span className="file-name">Customer Details</span>
          <button
            onClick={() => setIsOpenDetailsModal(false)}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="tab-content mt-1 h-80 overflow-auto">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple d-flex flex-column gap-3  nicescroll-bar simple-scrollbar p-0 mb-3">
              <div className="card bg-light px-3 rounded-5">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#fl_info"
                    aria-expanded="true"
                    className="fs-6"
                  >
                    Details
                  </a>
                </div>
                <div id="fl_info" className="collapse show">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span className="me-0">Type : </span>
                        <span className="text-capitalize">
                          {customerDetails?.customerType}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Name : </span>
                        <span className="text-capitalize">
                          <Avatar
                            name={customerDetails?.name}
                            count={1}
                            image={customerDetails?.image}
                            size="xss"
                            color="primary"
                          />
                          &nbsp;
                          {customerDetails?.name}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Email : </span>
                        <span className="text-capitalize">
                          {customerDetails?.email}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Phone : </span>
                        <span className="text-capitalize">
                          {customerDetails?.phone}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Created At : </span>
                        <span className="text-capitalize">
                          {dateAndTimeFormatter(customerDetails?.createdAt)}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Updated At : </span>
                        <span className="text-capitalize">
                          {dateAndTimeFormatter(customerDetails?.updatedAt)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card bg-light px-3 rounded-5">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#fl_address"
                    aria-expanded="true"
                    className="fs-6"
                  >
                    Address
                  </a>
                </div>
                <div id="fl_address" className="collapse show">
                  <div className="card-body d-flex flex-column gap-3">
                    <div>
                      <span>Billing Address :</span>
                      <span className="text-dark">
                        {customerDetails?.billingAddress ? (
                          <>
                            <p>{customerDetails?.billingAddress?.street}</p>
                            <p>{customerDetails?.billingAddress?.city}</p>
                            <p>{customerDetails?.billingAddress?.pinCode}</p>
                            <p>{customerDetails?.billingAddress?.country}</p>
                          </>
                        ) : (
                          <span className="ms-2">No Billing Address</span>
                        )}
                      </span>
                    </div>
                    <div>
                      <span>Shipping Address :</span>
                      <span className="text-dark">
                        {customerDetails?.shippingAddress ? (
                          <>
                            <p>{customerDetails?.shippingAddress?.street}</p>
                            <p>{customerDetails?.shippingAddress?.city}</p>
                            <p>{customerDetails?.shippingAddress?.pinCode}</p>
                            <p>{customerDetails?.shippingAddress?.country}</p>
                          </>
                        ) : (
                          <span className="ms-2">No Shipping Address</span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModuleReadWriteAccessCustomers && (
          <div className="d-flex justify-content-center border-top p-1 gap-2 dropdown-footer mb-5">
            <button
              type="button"
              className="btn btn-sm btn-outline-primary mt-1"
              data-bs-toggle="modal"
              data-bs-target="#add_customer"
              onClick={() => {
                setEditCustomer(customerDetails);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                class="bi bi-pencil-square"
                viewBox="0 0 16 16"
              >
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                <path
                  fill-rule="evenodd"
                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                />
              </svg>
              &nbsp; Edit
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-outline-danger mt-1"
              onClick={() => {
                setIsOpen(true);
                setEditCustomer(customerDetails?._id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                class="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
              </svg>
              &nbsp; Delete
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
