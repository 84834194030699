import React, { useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { restaurantTableRelatedEndPoints } from "../../../api/Endpoints";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const SelectTableModal = ({
  setSelectTable,
  tableId,
  setTableId,
  type,
  setType,
  noOfPeople,
  setNoOfPeople,
  tableData,
  setTableData,
  handleCreateOrder,
  loader3,
  locationData,
  handleEditOrder,
  handleResetTableData,
}) => {
  const [loader, setloader] = useState(false);
  const [allTables, setAllTables] = useState([]);
  const [filter, setFilter] = useState("");
  const filteredTables = allTables?.filter((table) =>
    table?.tableName?.toLowerCase()?.includes(filter.toLowerCase())
  );

  const handleGetAllTables = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isDeleted:false
      },
    };
    const res = await ApiCall("post", restaurantTableRelatedEndPoints.getTable, data);
    if (res?.success) {
      setAllTables(res?.tables?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  return (
    <div
      id="add_table_data"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleResetTableData();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Select Table</h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (locationData) {
                  handleEditOrder(e);
                } else {
                  handleCreateOrder(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Select Table (optional)
                    </label>
                    <div className="selectable-dropdown">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-210p `}
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          onClick={() => {
                            handleGetAllTables();
                            setFilter("");
                          }}
                        >
                          {tableId ? (
                            <div className="d-flex justify-content-between">
                              {tableData}
                              {tableId !== "" && (
                                <button
                                  type="button"
                                  className="btn-close btn-sm ms-1"
                                  onClick={() => {
                                    setTableId("");
                                    setFilter("");
                                    setTableData("");
                                  }}
                                  aria-label="Close"
                                ></button>
                              )}
                            </div>
                          ) : (
                            "Select Table"
                          )}
                        </span>
                        <div role="menu" className="dropdown-menu ">
                          <form className="d-sm-block d-none" role="search">
                            <input
                              type="text"
                              placeholder="Search.."
                              className="form-control"
                              value={filter}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </form>
                          {loader ? (
                            <span className="d-flex text-center mt-1">
                              Loading...
                            </span>
                          ) : (
                            <div
                              style={{
                                maxHeight: "20vh",
                                overflow: "auto",
                              }}
                            >
                              {filteredTables?.map((table, index) => (
                                <div
                                  className="dropdown-item text-capitalize"
                                  key={table._id}
                                  onClick={() => {
                                    setTableId(table._id);
                                    setTableData(table?.tableName);
                                  }}
                                >
                             {table?.tableName}  ({table?.currentOrder?"Booked":"Vacant"})
                                </div>
                              ))}
                            </div>
                          )}
                          {!loader && filteredTables?.length === 0 && (
                            <span className="d-flex text-center mt-1">
                              No Tables found
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <label className="form-label">Customer Type (optional)</label>
                  <div className="form-group">
                    <div className="todo-toolbar">
                      <select
                        className="form-select form-select-md"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value="">Select Type</option>
                        <option value="Family">Family</option>
                        <option value="Individual">Individual</option>
                        <option value="Couple">Couple</option>
                        <option value="Others">Other</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      No of People (optional)
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      value={noOfPeople}
                      onChange={(e) => setNoOfPeople(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer align-items-center mt-3 justify-content-end">
                <div>
                  <button type="submit" className="btn btn-primary" disabled={loader3}>
                    {loader3 ? <ApiLoader /> : "Confirm order"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTableModal;
