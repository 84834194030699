import React, { useEffect, useState } from "react";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import { complaintEndPoints } from "../../../api/Endpoints";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import AllComplaintsCard from "./AllComplaintsCard";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import ImageModal from "./ImageModal";
import AllComplaintsHeader from "./AllComplaintsHeader";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import AllComplaintsSkeleton from "./AllComplaintsSkeleton";

const AllComplaint = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [status, setStatus] = useState("");
  const [loader, setloader] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [allComplaints, setAllComplaints] = useState([]);
  const [count, setcount] = useState({});
  const [pageLimit, setpageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);
  const [complaintCount, setComplaintCount] = useState(0);
  const [image, setimage] = useState([]);
  const [moduleType, setmoduleType] = useState("");

  const isModuleReadWriteAccessAllGrievance =
    allModuleAccess?.[PRIVATEROUTES.ALL_GRIEVANCE];

  const handleTabChange = (status) => {
    setStatus(status);
  };

  const allTabs = [
    {
      name: "All",
      value: "",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-info">
          {count?.all}
        </span>
      ),
    },
    {
      name: "Open",
      value: "open",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-warning">
          {count?.open}
        </span>
      ),
    },
    {
      name: "Processing",
      value: "processing",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-primary">
          {count?.processing}
        </span>
      ),
    },
    {
      name: "Resolved",
      value: "resolved",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-success">
          {count?.resolved}
        </span>
      ),
    },
    {
      name: "Closed",
      value: "closed",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-success">
          {count?.closed}
        </span>
      ),
    },
    {
      name: "Cancelled",
      value: "cancelled",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-success">
          {count?.cancelled}
        </span>
      ),
    },
  ];

  const handleGetComplaints = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        status: status,
        businessId: getCurrentWorkspaceId(),
        category: moduleType,
      },
      searchKey: search,
    };
    const res = await ApiCall("post", complaintEndPoints.getComplaints, data);
    if (res?.success) {
      setloader(false);
      setAllComplaints(res?.data?.data);
      settotalPages(res?.data?.totalPages);
      setComplaintCount(res?.data?.totalCounts);
      setcount(res?.data?.counts);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetComplaints();
      closeAllModals();
    }
  }, [search, status, page, moduleType]);

  const handleChangeType = (type) => {
    setmoduleType(type);
    setPage(1);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <AllComplaintsHeader
            setPage={setPage}
            search={search}
            setSearch={setSearch}
            handleGetComplaints={handleGetComplaints}
            moduleType={moduleType}
            handleChangeType={handleChangeType}
          />

          {/* **CUSTOM TAB*** */}
          <CustomTab
            allTabs={allTabs}
            currentTab={status}
            handleTabChange={handleTabChange}
            isShowCount={true}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && allComplaints?.length > 0 && (
                <AllComplaintsCard
                  allComplaints={allComplaints}
                  status={status}
                  handleGetComplaints={handleGetComplaints}
                  setimage={setimage}
                  pageLimit={pageLimit}
                  page={page}
                  isModuleReadWriteAccessAllGrievance={
                    isModuleReadWriteAccessAllGrievance
                  }
                />
              )}
              {!loader && allComplaints?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={complaintCount}
                  dataLength={allComplaints?.length}
                  align={"end"}
                />
              )}
              {loader && <AllComplaintsSkeleton />}
              {!loader && allComplaints?.length === 0 && (
                <NoData title={`No ${status} Complaints`} />
              )}
            </div>
          </div>
        </div>
      </div>
      <ImageModal image={image} setimage={setimage} />
    </div>
  );
};

export default AllComplaint;
