import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import AllTask from "../../pages/Task/AllTask/AllTask";

const AllTasksRoutes = {
  path: "/alltask",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <AllTask />,
    },
  ],
};

export default AllTasksRoutes;
