import React, { useEffect, useState } from "react";
import LeaveHistoryTable from "./LeaveHistoryTable";
import {
  getCurrentWorkspaceId,
  monthNames,
  startApiCall,
  closeAllModals,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { leaveRelatedEndPoints } from "../../../../api/Endpoints";
import NoData from "../../../../components/NoData/NoData";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import { navigateToLeaveRequestDetails } from "../../../../Navigation/Navigation";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

const LeaveHistory = () => {
  const { allModuleAccess, user } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [allLeaveHistory, setAllLeaveHistory] = useState([]);
  const [allLeaveTypes, setAllLeaveTypes] = useState([]);
  const navigate = useNavigate();

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // MONTHLY FILTER
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const currentMonth = monthNames[new Date().getMonth()];
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const year = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const isModuleReadWriteAccessForLeaveHistory =
    allModuleAccess?.[PRIVATEROUTES.LEAVE_HISTORY];

  const lastDayOfMonth = new Date(year, thisMonth + 1, 0);
  const formattedStartDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-01`;
  const formattedEndDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);

    const selectedMonthIndex = monthNames.indexOf(selectedMonth);
    if (selectedMonthIndex !== -1) {
      const month = selectedMonthIndex + 1;
      const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay
        .toString()
        .padStart(2, "0")}`;

      setstartDate(startDate);
      setendDate(endDate);
    }
  };

  useEffect(() => {
    if (!isModuleReadWriteAccessForLeaveHistory) {
      navigate(
        navigateToLeaveRequestDetails({
          id: user?._id,
          monthname: selectedMonth,
        }),
        {
          state: { name: user?.name, image: user?.image },
        }
      );
    }
  }, [isModuleReadWriteAccessForLeaveHistory]);

  // GET ALL LEAVE HISTORY FUNCTION API CALL
  const handleGetAllLeaveHistory = async () => {
    startApiCall(null, setLoader);
    const data = {
      filters: {
        searchKey: "",
      },
      businessId: getCurrentWorkspaceId(),
      startDate: startDate ? startDate : formattedStartDate,
      endDate: endDate ? endDate : formattedEndDate,
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.getLeaveHistory,
      data
    );
    if (res?.success) {
      setAllLeaveHistory(res?.leave?.result);
      handleGetAllLeaveTypes();
      settotalPages(res?.leave?.totalPages);
      setcount(res?.leave?.totalCounts);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllLeaveHistory();
      closeAllModals();
    }
  }, [selectedMonth, page, pageLimit]);

  const handleGetAllLeaveTypes = async () => {
    startApiCall(null, setLoader2);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.getLeaveTypes,
      sendData
    );
    if (res?.success) {
      setAllLeaveTypes(res?.leaveType);
      setLoader2(false);
    } else {
      setLoader2(false);
    }
  };

  const totalAllowedDays = allLeaveTypes?.reduce(
    (sum, data) => sum + data?.allowedDays,
    0
  );

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <span className="todoapp-title link-dark">
              <h1>Leave History</h1>
            </span>
            <div className="todo-options-wrap">
              <div className="me-1">
                <select
                  className="form-select form-select"
                  value={selectedMonth}
                  onChange={handleMonthChange}
                >
                  {monthNames?.map((mon) => {
                    return <option>{mon}</option>;
                  })}
                </select>
              </div>
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                data-bs-original-title="Refresh"
              >
                <span
                  className="btn-icon-wrap"
                  onClick={handleGetAllLeaveHistory}
                >
                  <span className="feather-icon">
                    <i className="ri-refresh-line"></i>
                  </span>
                </span>
              </span>
              <div className="v-separator d-lg-inline-block d-none"></div>
            </div>
          </header>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && allLeaveHistory?.length > 0 && (
                <LeaveHistoryTable
                  allLeaveHistory={allLeaveHistory}
                  page={page}
                  pageLimit={pageLimit}
                  selectedMonth={selectedMonth}
                  totalAllowedDays={totalAllowedDays}
                />
              )}
              {!loader && allLeaveHistory?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allLeaveHistory?.length}
                  align={"end"}
                />
              )}
              {!loader && allLeaveHistory?.length === 0 && <NoData />}
              {loader && <DataLoader title="data is loading..." />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveHistory;
