import React from "react";
import { navigateToCrmActivityGroups } from "../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";

const GroupDetailsHeader = ({ handleRefresh,groupDetails,loader }) => {
  const navigate = useNavigate();

  return (
    <>
      <header className="todo-header">
        <div className="d-flex align-items-center gap-2">
          <span
            className="pointer"
            onClick={() => navigate(navigateToCrmActivityGroups())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </span>
          {!loader && (
          <CustomTooltip text={groupDetails?.name}>
            <span className="todoapp-title  link-dark">
              <sapn className="header-title-font text-capitalize mw-225p text-truncate font-5">
                {groupDetails?.name}
              </sapn>
            </span>
          </CustomTooltip>
        )}
        {loader && <SkeletonConstant width="w-200p" height="h-35p" />}
        </div>
        <div className="todo-options-wrap">
          <div className="v-separator d-lg-inline-block d-none"></div>
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="dsdsd"
            data-bs-original-title="Refresh"
          >
            <span
              className="btn-icon-wrap"
              onClick={() => {
                handleRefresh();
              }}
            >
              <span className="feather-icon">
                <i
                  className="ri-refresh-line"
                  title="Refresh business list"
                ></i>
              </span>
            </span>
          </span>
        </div>
      </header>
    </>
  );
};

export default GroupDetailsHeader;
