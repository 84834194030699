import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { QR_URL } from "../../../api/base";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import { saveAs } from "file-saver";
import domtoimage from "dom-to-image";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { toast } from "react-toastify";
import { restaurantTableRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";

const MenuQRGenerate = () => {
  const qrRef = useRef(null);
  const [loader, setloader] = useState(false);
  const [allTables, setallTables] = useState(null);
  const [selectedTable, setselectedTable] = useState(null);
  
  // const shareUrl =selectedTable?QR_URL + "/" + getCurrentWorkspaceId()+ "/"  + selectedTable : QR_URL + "/" + getCurrentWorkspaceId();

  const shareUrl = selectedTable?`${QR_URL}?res_Id=${getCurrentWorkspaceId()}&tb_Id=${selectedTable}`:`${QR_URL}?res_Id=${getCurrentWorkspaceId()}`

  const downloadQR = () => {
    domtoimage.toBlob(qrRef.current).then(function (blob) {
      saveAs(blob, "qr-code.png");
    });
  };

  const copyUrlToClipboard = () => {
    const urlToCopy = shareUrl;
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        toast.success("URL copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Error copying URL to clipboard: ", error);
      });
  };

  // GET ALL TABLE  API CALL
  const handleGetAllTable = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 1000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isDeleted: false,
      },
    };
    const res = await ApiCall(
      "post",
      restaurantTableRelatedEndPoints.getTable,
      data
    );
    if (res?.success) {
      setallTables(res?.tables?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  useEffect(() => {
    handleGetAllTable();
  }, []);

  return (
    <div>
      <label className="form-label fw-semibold">QRCode for Menu</label>
      <div className="d-flex flex-column align-items-center gap-2">
        <div className="todo-toolbar mb-6">
          <select
            className="form-select form-select-md"
            name="leadStatusGroup"
            required
            value={selectedTable}
            onChange={(e) => {
              setselectedTable(e.target.value);
            }}
          >
            <option value="">No Table</option>
            {loader && <option disabled>Loading Tables...</option>}
            {!loader && (
              <>
                {allTables?.map((item, index) => {
                  return (
                    <option value={item?._id}>
                      {index + 1}. {item?.tableName} ({item?.currentOrder?"Booked":"Vacant"})
                    </option>
                  );
                })}
              </>
            )}
          </select>
        </div>
        <div ref={qrRef}>
          <QRCode size={300} value={shareUrl} viewBox={`0 0 300 300`} />
        </div>
        <p className="fs-7 mb-0">
          Simply scan QR code to effortlessly place orders for your favorite
          dish.
        </p>
        <div className="d-flex align-items-center gap-2">
          <span className="text-primary">
            <span className="text-dark">Link : </span>
            {shareUrl}
          </span>

          <CustomTooltip text="Copy to clipboard" placement="top">
            <span
              className="btn btn-sm btn-icon btn-light rounded-circle"
              onClick={copyUrlToClipboard}
            >
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-copy"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                  />
                </svg>
              </span>
            </span>
          </CustomTooltip>
        </div>
        <div className="d-flex align-items-center gap-3">
          <FacebookShareButton
            url={shareUrl}
            quote="Take a Look at Our Menu! High sky Cafe and restaurant powered by Bizhood"
          >
            <CustomTooltip text="Share on Facebook" placement="top">
              <span className="btn btn-icon btn-primary rounded-circle">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </FacebookShareButton>

          <TwitterShareButton
            url={shareUrl}
            title="Take a Look at Our Menu! High sky Cafe and restaurant powered by Bizhood"
          >
            <CustomTooltip text="Share on Twitter" placement="top">
              <span className="btn btn-icon btn-info rounded-circle">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-twitter"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15" />
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </TwitterShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title="Take a Look at Our Menu! High sky Cafe and restaurant powered by Bizhood"
          >
            <CustomTooltip text="Share on Whatsapp" placement="top">
              <span className="btn btn-icon btn-success rounded-circle">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-whatsapp"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </WhatsappShareButton>

          <EmailShareButton
            url={shareUrl}
            subject="Take a Look at Our Menu! High sky Cafe and restaurant powered by Bizhood"
          >
            <CustomTooltip text="Share on Email" placement="top">
              <span className="btn btn-icon btn-danger rounded-circle">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-envelope"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                  </svg>
                </span>
              </span>
            </CustomTooltip>
          </EmailShareButton>

          <CustomTooltip text="Download QR" placement="top">
            <span
              className="btn btn-icon btn-light rounded-circle"
              onClick={downloadQR}
            >
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                </svg>
              </span>
            </span>
          </CustomTooltip>
        </div>
      </div>
    </div>
  );
};

export default MenuQRGenerate;
