import React, { useEffect, useState } from "react";
import AddQuoteHeader from "./AddQuoteHeader";
import AddQuoteBody from "./AddQuoteBody";
import AddItemInQuote from "./AddItemInQuote";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { QuotesRelatedEndPoints } from "../../../../api/Endpoints";
import { useSelector } from "react-redux";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToAllQuotes } from "../../../../Navigation/Navigation";

const AddQuote = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [loader, setloader] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [quoteNumber, setQuoteNumber] = useState("");
  const [quoteDate, setQuoteDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [selectedCustomer, setselectedCustomer] = useState("");
  const [quoteSubject, setQuoteSubject] = useState("");
  const [quoteItems, setQuoteItems] = useState([]);
  const [items, setitems] = useState([]);
  const [discount, setDiscount] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [editQuotes, setEditQuotes] = useState([]);
  const [editItem, setEditItem] = useState("");

  const [billingAddress, setbillingAddress] = useState({
    country: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const [shippingAddress, setshippingAddress] = useState({
    country: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const handleReset = () => {
    setloader(false);
    setloader3(false);
    setQuoteNumber("");
    setQuoteDate("");
    setExpiryDate("");
    setselectedCustomer("");
    setQuoteSubject("");
    setQuoteItems("");
    setitems("");
    setbillingAddress({
      country: "",
      city: "",
      zipCode: "",
      address: "",
    });
    setshippingAddress({
      country: "",
      city: "",
      zipCode: "",
      address: "",
    });
    navigate(navigateToAllQuotes());
  };

  const calculateTotalSum = () => {
    const sum = quoteItems
      ?.map((item) => parseFloat(item?.total || 0))
      ?.reduce((acc, val) => acc + val, 0);
    const discountedAmount = sum - (sum * parseFloat(discount || 0)) / 100;
    return discountedAmount.toFixed(2);
  };

  // ADD QUOTE FUNCTION API CALL
  const handleAddQuote = async () => {
    startApiCall(seterrorMessage, setloader);
    const data = {
      customerId: selectedCustomer,
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      quoteNumber,
      quoteDate,
      expiryDate,
      subject: quoteSubject,
      items: items,
      discountPercentage: discount,
      totalAmount: calculateTotalSum(),
      termsAndConditions,
      billingPinCode: billingAddress?.zipCode,
      billingCountry: billingAddress?.country,
      billingCity: billingAddress?.city,
      billingStreet: billingAddress?.address,
      shippingPinCode: shippingAddress?.zipCode,
      shippingCountry: shippingAddress?.country,
      shippingCity: shippingAddress?.city,
      shippingStreet: shippingAddress?.address,
    };
    const res = await ApiCall("post", QuotesRelatedEndPoints.addQuote, data);
    if (res?.success) {
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  // EDIT QUOTE FUNCTION API CALL
  const handleEditQuote = async () => {
    startApiCall(null, setloader3);
    const data = {
      quoteId: locationData?.id,
      expiryDate,
      subject: quoteSubject,
      items: items,
      discountPercentage: discount,
      termsAndConditions,
      totalAmount: calculateTotalSum(),
      billingPinCode: billingAddress?.zipCode,
      billingCountry: billingAddress?.country,
      billingCity: billingAddress?.city,
      billingStreet: billingAddress?.address,
      shippingPinCode: shippingAddress?.zipCode,
      shippingCountry: shippingAddress?.country,
      shippingCity: shippingAddress?.city,
      shippingStreet: shippingAddress?.address,
    };
    const res = await ApiCall("patch", QuotesRelatedEndPoints.editQuote, data);
    if (res?.success) {
      handleReset();
    } else {
      setloader3(false);
      seterrorMessage(res.error);
    }
  };

  // GET QUOTE FUNCTION API CALL
  const handleGetQuoteDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      quoteId: locationData?.id,
    };
    const res = await ApiCall(
      "post",
      QuotesRelatedEndPoints.getQuoteById,
      data
    );
    if (res?.success) {
      setEditQuotes(res?.quotes);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (locationData?.id) {
      handleGetQuoteDetails();
    }
  }, [locationData]);

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  // SET ALL VALUE WHEN EDIT QUOTE
  useEffect(() => {
    if (locationData?.id) {
      setselectedCustomer(editQuotes?.customerId?._id);
      setQuoteNumber(editQuotes?.quoteNumber);
      setQuoteDate(reversesDateFormatter(editQuotes?.quoteDate));
      setExpiryDate(reversesDateFormatter(editQuotes?.expiryDate));
      setQuoteSubject(editQuotes?.subject);
      setbillingAddress({
        country: editQuotes?.billingAddress?.country,
        city: editQuotes?.billingAddress?.city,
        zipCode: editQuotes?.billingAddress?.pinCode,
        address: editQuotes?.billingAddress?.street,
      });
      setshippingAddress({
        country: editQuotes?.shippingAddress?.country,
        city: editQuotes?.shippingAddress?.city,
        zipCode: editQuotes?.shippingAddress?.pinCode,
        address: editQuotes?.shippingAddress?.street,
      });
      setTermsAndConditions(editQuotes?.termsAndConditions);
      setDiscount(editQuotes?.discountPercentage);
      setQuoteItems(editQuotes?.items);
      const mappedItems = editQuotes?.items?.map((item) => ({
        itemId: item?.itemId?._id,
        quantity: item?.quantity,
        rate: item?.rate,
        total: item?.total,
      }));
      setitems(mappedItems);
    }
  }, [locationData, editQuotes]);

  return (
    <>
      <div className="todoapp-wrap">
        <div className="todoapp-content">
          <div className="todoapp-detail-wrap">
            <AddQuoteHeader locationData={locationData} />
            <div className="todo-body">
              <div data-simplebar className="nicescroll-bar simple-scrollbar">
                {!loader && (
                  <AddQuoteBody
                    selectedCustomer={selectedCustomer}
                    setselectedCustomer={setselectedCustomer}
                    quoteNumber={quoteNumber}
                    setQuoteNumber={setQuoteNumber}
                    quoteDate={quoteDate}
                    setQuoteDate={setQuoteDate}
                    expiryDate={expiryDate}
                    setExpiryDate={setExpiryDate}
                    quoteSubject={quoteSubject}
                    setQuoteSubject={setQuoteSubject}
                    quoteItems={quoteItems}
                    items={items}
                    calculateTotalSum={calculateTotalSum}
                    discount={discount}
                    setDiscount={setDiscount}
                    handleAddQuote={handleAddQuote}
                    errorMessage={errorMessage}
                    shippingAddress={shippingAddress}
                    setshippingAddress={setshippingAddress}
                    billingAddress={billingAddress}
                    setbillingAddress={setbillingAddress}
                    termsAndConditions={termsAndConditions}
                    setTermsAndConditions={setTermsAndConditions}
                    locationData={locationData}
                    editQuotes={editQuotes}
                    handleEditQuote={handleEditQuote}
                    setEditItem={setEditItem}
                    loader3={loader3}
                  />
                )}
                {loader && <DataLoader title="Creating New Quote..." />}
              </div>
            </div>
          </div>
          <AddItemInQuote
            quoteItems={quoteItems}
            setQuoteItems={setQuoteItems}
            setitems={setitems}
            editItem={editItem}
            setEditItem={setEditItem}
          />
        </div>
      </div>
    </>
  );
};

export default AddQuote;
