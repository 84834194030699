import React, { useState } from "react";
import ConfirmModal from "../../../../../components/ConfirmationModal/ConfirmModal";

function AddedMaterialsTable({
  materialsInTable,
  setMaterialEdit,
  setmaterialEditData,
  setmaterialsInTable,
  setmaterialsInItem
}) {
  const [isTableDelete, setisTableDelete] = useState(false);
  const [deletedData, setdeletedData] = useState(null);

  const handleCloseDeleteModal = () => {
    setisTableDelete(false);
  };

  const handleDeleteSpecificItem = (e) => {
    e.preventDefault();
    const newItem = materialsInTable?.filter(
      (dt) => dt?._id !== deletedData?._id
    );
    setmaterialsInTable(newItem)
    const data = newItem?.map((item) => ({
      inventoryItem: item?.inventoryItem?._id,
      quantity: item?.quantity,
      unit: item?.unit?._id,
    }));
    setmaterialsInItem(data)
    handleCloseDeleteModal()
  };

  return (
    <table className="table table-bordered text-center">
      {materialsInTable?.length > 0 ? (
        <>
          {" "}
          <thead>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>QT</th>
              <th>Unit</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {materialsInTable?.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item?.inventoryItem?.name}</td>
                  <td>{item?.quantity}</td>
                  <td> {item?.unit?.name} </td>
                  <td className="d-flex justify-content-center">
                    <div className="d-flex align-items-center ">
                      <span
                        data-bs-toggle="modal"
                        data-bs-target="#add_item_material"
                        onClick={() => {
                          setMaterialEdit(true);
                          setmaterialEditData(item);
                        }}
                        className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                      >
                        <span className="btn-icon-wrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fillRule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                            />
                          </svg>
                        </span>
                      </span>
                      <span
                        onClick={() => {
                          setdeletedData(item);
                          setisTableDelete(true);
                        }}
                        className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                      >
                        <span className="btn-icon-wrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash3  ms-1 "
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                          </svg>
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </>
      ) : (
        <div className="no-data-wrapper p-7">
          <h3 className="fw-semibold ">
            No Materials Added{" "}
            <span className="fw-normal fs-5">(Please add materials)</span>
            {/* {asteriskReuired} */}
          </h3>
        </div>
      )}
      {isTableDelete && (
        <ConfirmModal
          isOpen={isTableDelete}
          onClose={handleCloseDeleteModal}
          text={`Are you sure? You want to delete item : ${deletedData?.inventoryItem?.name} ?`}
          // loader={loader2}
          // errorMessage={errorMessage}
          handleConfirm={handleDeleteSpecificItem}
        />
      )}
    </table>
  );
}

export default AddedMaterialsTable;
