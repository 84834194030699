import React, { useEffect, useState } from "react";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import allCountries from "../../../components/Countries/Countries";
import { InventoryCustomerRelatedEndPoints } from "../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";

function AddCustomer({
  setaddCustomerModal,
  handleGetAllCustomer,
  isEdit,
  customerData,
  setisEdit,
}) {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setaddress] = useState("");
  const [countryCode, setcountryCode] = useState("");
  const [codeFilter, setcodeFilter] = useState("");

  const filteredCountries = allCountries?.filter(
    (country) =>
      country?.code?.includes(codeFilter) ||
      country?.country?.toLowerCase()?.includes(codeFilter?.toLowerCase())
  );

  const asteriskReuired = (
    <CustomTooltip
      text={"This field is required to be filled"}
      placement="right"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="6"
        fill="currentColor"
        class="bi bi-asterisk mb-2 text-danger"
        viewBox="0 0 16 16"
      >
        <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1" />
      </svg>
    </CustomTooltip>
  );

  useEffect(() => {
    if (isEdit) {
      setName(customerData?.name);
      setEmail(customerData?.email);
      setPhone(customerData?.phone);
      setaddress(customerData?.address);
      setcountryCode(customerData?.countryCode);
    }
  }, [isEdit, customerData]);

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setName("");
    setPhone("");
    setEmail("");
    setaddress("");
    setcountryCode("");
    setcodeFilter("");
    setisEdit(false);
    setaddCustomerModal(false);
  };

  // ADD CUSTOMER FUNCTION API CALL
  const handleAddCustomer = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      name: name,
      phone: phone,
      email: email,
      address: address,
      businessId: getCurrentWorkspaceId(),
      countryCode: countryCode,
      createdBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      InventoryCustomerRelatedEndPoints.addCustomer,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetAllCustomer();
      toast.success("Add customer successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };
  // EDIT CUSTOMER FUNCTION API CALL
  const handleEditCustomer = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      buyerId: customerData?._id,
      name: name,
      phone: phone,
      email: email,
      address: address,
      countryCode: countryCode,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      InventoryCustomerRelatedEndPoints.editCustomer,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetAllCustomer();
      toast.success("Edit customer successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{isEdit ? "Edit Customer" : "Add Customer"}</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (isEdit) {
                  handleEditCustomer(e);
                } else {
                  handleAddCustomer(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="d-flex gap-2 mt-3">
                  <span className="text-dark w-20 fw-lighter">
                    Name {asteriskReuired} :
                  </span>
                  &nbsp;
                  <input
                    className="form-control w-80"
                    type="text"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="d-flex gap-2 mt-3">
                  <span className="text-dark w-20 fw-lighter ">
                    Phone {asteriskReuired} :
                  </span>
                  &nbsp;
                  <div className="selectable-dropdown mt-1">
                    <div className="dropdown selectable-dropdown">
                      <span
                        className={
                          "me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-110p   "
                        }
                        aria-expanded="false"
                        type="button"
                        data-bs-toggle="dropdown"
                        onClick={() => setcodeFilter("")}
                      >
                        {countryCode ? (
                          <>
                            + {countryCode}
                            {countryCode !== "" && (
                              <button
                                type="button"
                                className="btn-close btn-sm ms-1"
                                onClick={() => {
                                  setcountryCode("");
                                  setcodeFilter("");
                                }}
                                aria-label="Close"
                              ></button>
                            )}
                          </>
                        ) : (
                          "Code"
                        )}
                      </span>
                      <div role="menu" className="dropdown-menu">
                        <form className="d-sm-block d-none" role="search">
                          <input
                            type="text"
                            placeholder="Search.."
                            className="form-control"
                            value={codeFilter}
                            onChange={(e) => setcodeFilter(e.target.value)}
                          />
                        </form>
                        <div
                          style={{
                            maxHeight: "28vh",
                            overflow: "auto",
                            zIndex: 9999,
                          }}
                        >
                          {filteredCountries?.map((country) => (
                            <div
                              className="dropdown-item text-Capitalize"
                              key={country.country}
                              onClick={() => setcountryCode(country.code)}
                            >
                              <span>
                                {" "}
                                + {country?.code} &nbsp; {country?.country}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    className="form-control w-50"
                    type="tel"
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                    title="Please enter a 10-digit phone number"
                    required
                    value={phone}
                    disabled={!countryCode}
                    // onChange={handlePhoneChange}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="d-flex gap-2 mt-3">
                  <span className="text-dark w-20 fw-lighter ">Email :</span>
                  &nbsp;
                  <input
                    className="form-control w-80"
                    type="email"
                    // disabled={editCustomer}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="d-flex gap-2 mt-3">
                  <span className="text-dark w-20 fw-lighter ">Address :</span>
                  &nbsp;
                  <textarea
                    className="form-control rounded-5 w-80"
                    rows="3"
                    placeholder="Type here"
                    onChange={(e) => {
                      setaddress(e.target.value);
                    }}
                    value={address}
                  ></textarea>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              </div>
              <div className="modal-footer align-items-center mt-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!name || !phone}
                >
                  {loader ? <ApiLoader /> : isEdit ? "Edit" : "+ Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
