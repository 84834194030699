import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToAllTask } from "../../../Navigation/Navigation";
import { useSelector } from "react-redux";

const TaskCountCard = ({ taskCount }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="border rounded-4 p-3 shadow-sm d-flex flex-column">
        <div
          className="d-flex align-items-center justify-content-between pointer"
          onClick={() => {
            navigate(navigateToAllTask());
          }}
        >
          <div className="d-flex align-items-center gap-1">
            <span className="btn btn-icon btn-rounded btn-sm bg-blue-light-5 text-primary">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z" />
                </svg>
              </span>
            </span>
            <p className="text-primary fw-bold text-underline">All Task</p>
          </div>
          <span className="d-block fs-4 fw-semibold text-dark mb-0">
            {taskCount?.all ? taskCount?.all : 0}
          </span>
          
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div
            className="border rounded-4 p-3 shadow-sm d-flex flex-column pointer mb-md-3 mb-sm-3 mb-lg-0"
            onClick={() => {
              navigate(navigateToAllTask());
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <p>Assigned</p>
              <span className="btn btn-icon btn-rounded btn-sm bg-success-light-5 text-success">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                    fill="currentColor"
                  >
                    <path d="M14 14.252V16.3414C13.3744 16.1203 12.7013 16 12 16C8.68629 16 6 18.6863 6 22H4C4 17.5817 7.58172 14 12 14C12.6906 14 13.3608 14.0875 14 14.252ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11ZM17.7929 19.9142L21.3284 16.3787L22.7426 17.7929L17.7929 22.7426L14.2574 19.2071L15.6716 17.7929L17.7929 19.9142Z"></path>
                  </svg>
                </span>
              </span>
            </div>
            <span className="d-block fs-4 fw-semibold text-dark mb-0">
              {taskCount?.assigned ? taskCount?.assigned : 0}
            </span>
            <p className="fs-7">tasks are assigned to the user</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div
            className="border rounded-4 p-3 shadow-sm d-flex flex-column pointer"
            onClick={() => {
              navigate(navigateToAllTask());
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <p>Scheduled</p>
              <span className="btn btn-icon btn-rounded btn-sm bg-warning-light-4 text-warning">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-stopwatch"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                    <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                  </svg>
                </span>
              </span>
            </div>
            <span className="d-block fs-4 fw-bold text-dark mb-0">
              {taskCount?.scheduled ? taskCount?.scheduled : 0}
            </span>
            <p className="fs-7">tasks have due dates</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskCountCard;
