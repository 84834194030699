import React from "react";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import No_food from "../../../assets/images/No_food.png";

const MenuCardsSection = (props) => {
  const {
    itemCategory,
    categoryItemsData,
    loader,
    loader2,
    selectedCategory,
    setSelectedCategory,
    handleProductClick,
    search,
    setSearch,
  } = props;

  return (
    <div className="d-flex flex-column gap-3">
      {!loader && itemCategory?.length > 0 && (
        <>
          <form className="d-sm-block d-none w-190p" role="search">
            <input
              type="text"
              className="form-control"
              placeholder="Search dish.."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </form>
          <div className="d-flex align-items-center gap-2 text-dark form-control">
            <div
              className="d-flex align-items-center gap-3 w-100 simple-scrollbar"
              style={{
                overflowX: "scroll",
                whiteSpace: "nowrap",
              }}
            >
              <span
                className={
                  selectedCategory === ""
                    ? "border border-primary rounded-4 py-1 px-2 shadow shadow-primary"
                    : "py-1 btn btn-sm"
                }
                onClick={() => {
                  setSelectedCategory("");
                }}
              >
                All
              </span>
              {itemCategory?.map((category) => {
                return (
                  <span
                    key={category?._id}
                    className={
                      selectedCategory === category
                        ? "border border-primary rounded-4 py-1 px-2 shadow shadow-primary"
                        : "py-1 btn btn-sm"
                    }
                    onClick={() => {
                      setSelectedCategory(category);
                    }}
                  >
                    {category?.categoryName}
                  </span>
                );
              })}
            </div>
            {/* <div>
       <span className="btn btn-sm btn-icon btn-rounded btn-primary">
         <span className="feather-icon">
           <svg
             xmlns="http://www.w3.org/2000/svg"
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
           >
             <path
               fill-rule="evenodd"
               clip-rule="evenodd"
               d="M4.64645 1.64645C4.84171 1.45118 5.15829 1.45118 5.35355 1.64645L11.3536 7.64645C11.5488 7.84171 11.5488 8.15829 11.3536 8.35355L5.35355 14.3536C5.15829 14.5488 4.84171 14.5488 4.64645 14.3536C4.45118 14.1583 4.45118 13.8417 4.64645 13.6464L10.2929 8L4.64645 2.35355C4.45118 2.15829 4.45118 1.84171 4.64645 1.64645Z"
               fill="white"
             />
           </svg>
         </span>
       </span>
     </div> */}
          </div>
        </>
      )}
      {loader && (
        <div className="form-control d-flex gap-5">
          {[...Array(6)].map((_, index) => (
            <SkeletonConstant key={index} width="w-100p" height="h-30p" />
          ))}
        </div>
      )}

      <div
        className="row gx-3 simple-scrollbar"
        style={{ overflowY: "scroll", height: "65vh" }}
      >
        {categoryItemsData?.map((dish) => {
          if (!loader2 && categoryItemsData?.length > 0) {
            return (
              <div
                className="col-lg-3 col-md-6 pointer"
                key={dish?._id}
                onClick={() => {
                  handleProductClick(dish);
                }}
              >
                <div className="card p-3 gap-2 text-center text-dark shadow-hover-lg hover-card">
                  <CustomTooltip text={dish?.productName} placement="bottom">
                    <div className="overflow-hidden w-120p h-120p d-flex align-items-center justify-content-center bg-light mx-auto circle">
                      <img
                        className="w-100 h-100 objectFit-cover scale-hover"
                        src={dish?.images?.length ? dish?.images?.[0] : No_food}
                        alt=""
                      />
                    </div>
                  </CustomTooltip>

                  <p className="text-truncate mw-200p">{dish?.productName}</p>
                  <p>₹ {dish?.sellingPrice}</p>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}

        {/* Out of stock card UI */}
        {/* <div className="col-lg-3 col-md-6">
          <div className="card p-3 gap-2 text-center text-dark shadow-hover-lg hover-card opacity-65">
            <span className="text-overflow-top text-danger fw-semibold">
              Out of stock
            </span>
            <div className="overflow-hidden w-100p h-100p d-flex align-items-center justify-content-center bg-light mx-auto circle">
              <img
                className="w-100 h-100 objetFit-cover scale-hover"
                src="https://images.unsplash.com/photo-1546069901-ba9599a7e63c?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
            </div>
            <p>Chicken wings</p>
            <p>INR 250</p>
          </div>
        </div> */}

        {loader2 && <DataLoader title="data is loading..." />}
        {!loader2 && categoryItemsData?.length === 0 && (
          <NoData title="No dish added" />
        )}
      </div>
    </div>
  );
};

export default MenuCardsSection;
