import React from "react";
import {
  dateFormatter,
  getName,
  getTimeAgo,
} from "../../global-functions/globalFunctions";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { Avatar } from "../Avatar/Avatar";
import { useSelector } from "react-redux";

function LogsTab({ logData }) {
  const user = useSelector((state) => state.user);
  var date = new Date();
  var yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  const groupedLogs = logData?.reduce((groups, log) => {
    const createdAtDate = new Date(log?.createdAt).toDateString();
    if (!groups[createdAtDate]) {
      groups[createdAtDate] = [];
    }
    groups[createdAtDate].push(log);
    return groups;
  }, {});

  return (
    <div>
      {Object?.entries(groupedLogs)?.map(
        ([createdAtDate, logsForDate], index) => (
          <div key={createdAtDate} className="collapse-simple mb-2">
            <div className="card">
              <div className="card-header">
                <a
                  role="button"
                  data-bs-toggle="collapse"
                  href={`#act_info_${index}`}
                  aria-expanded="true"
                >
                  <span>
                    {dateFormatter(createdAtDate) === dateFormatter(date)
                      ? "Today"
                      : dateFormatter(createdAtDate) ===
                        dateFormatter(yesterday)
                      ? "Yesterday"
                      : dateFormatter(createdAtDate)}
                    <span className="badge badge-md badge-pill badge-soft-primary ms-2">
                      {logsForDate?.length}
                    </span>
                  </span>
                </a>
              </div>
              <div id={`act_info_${index}`} className="collapse show">
                <div className="card-body">
                  <ul className="activity-list list-group list-group-flush">
                    {logsForDate?.map((log) => (
                      <li
                        key={log?._id}
                        className="list-group-item d-flex align-items-start p-1 mt-1"
                      >
                        <div className="d-flex align-items-center gap-3">
                          <span className="align-items-center jsutify-content-center">
                            <CustomTooltip
                              text={log?.actionBy?.name}
                              placement="bottom"
                            >
                              <Avatar
                                name={log?.actionBy?.name}
                                count={1}
                                image={log?.actionBy?.image}
                                size="xs"
                              />
                            </CustomTooltip>
                          </span>
                          <div className="d-flex flex-column">
                            <p>
                              <span className="text-medium-em font-2 text-capitalize">
                                {log?.actionTitle}
                              </span>
                              &nbsp;&nbsp;
                              <span className="text-body font-2  text-">
                                {getTimeAgo(log?.createdAt)}
                              </span>
                            </p>
                            <p className="mt-1 fw-medium">
                              <span className="text-high-em font-3 ">
                                <span className="text-primary">
                                  {getName(log?.actionBy , user?._id)}
                                </span>
                                &nbsp;
                                {log?.actionDescription}
                              </span>
                              {log?.actionOn && (
                                <span className="text-primary font-3">
                                  &nbsp;
                                  <CustomTooltip
                                    text={log?.actionOn?.name}
                                    placement="bottom"
                                  >
                                    <Avatar
                                      name={log?.actionOn?.name}
                                      count={1}
                                      image={log?.actionOn?.image}
                                      size="xxs"
                                    />
                                  </CustomTooltip>
                                  &nbsp;{getName(log?.actionOn, user?._id)}&nbsp;
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default LogsTab;
