import React from "react";

const GroupHeader = ({
  handleGetAllGroup,
  search,
  setsearch,
  setPage,
  moduleType,
  setModuleType,
  isModuleReadWriteAccessForStatusGroup,
}) => {
  const allModuleType = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Deal",
      value: "Deal",
    },
    {
      name: "Lead",
      value: "Lead",
    },
  ];

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1 className="header-title-font ">Status Group</h1>

          {isModuleReadWriteAccessForStatusGroup && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                data-bs-toggle="modal"
                data-bs-target="#create_new_group"
              >
                Add Group
              </button>
            </div>
          )}
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="me-2">
          <select
            className="form-select"
            value={moduleType}
            onChange={(e) => {
              setModuleType(e.target.value);
            }}
          >
            {allModuleType?.map((module) => {
              return <option value={module?.value}>{module?.name}</option>;
            })}
          </select>
        </div>

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllGroup}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-lg-block" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setsearch(e.target.value);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default GroupHeader;
