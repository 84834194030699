import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { dateAndTimeFormatter } from "../../../../global-functions/globalFunctions";

function CategoryItemMoreInfoModal({ handleDetailsDrawer, slectedItem }) {
  return (
    <div>
      <div className="details-modal pb-7">
        <div className="nicescroll-bar simple-scrollbar">
          <div className="d-flex justify-content-between">
            <span className="file-name">Item Details</span>
            <button
              onClick={handleDetailsDrawer}
              type="button"
              className="info-close btn-close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="d-flex justify-content-center mt-1">
            <Avatar
              name={slectedItem?.productName}
              count={1}
              image={slectedItem?.images?.[0]}
              size="lg"
              color="primary"
            />
          </div>
          <div className="tab-content mt-0">
            <div className="tab-pane fade show active" id="tab_info">
              <div className="collapse-simple">
                <div className="card py-0">
                  <div className="card-header">
                    <a
                      role="button"
                      data-bs-toggle="collapse"
                      href="#fl_info"
                      aria-expanded="true"
                    >
                      Specification
                    </a>
                  </div>
                  <div id="fl_info" className="collapse show">
                    <div className="card-body">
                      <ul className="fm-info">
                        <li>
                          <span>Name : </span>
                          <span>{slectedItem?.productName}</span>
                        </li>
                        <li>
                          <span>Product Code : </span>
                          <span>{slectedItem?.productCode}</span>
                        </li>
                        <li>
                          <span>Created At : </span>
                          <span>
                            {dateAndTimeFormatter(slectedItem?.createdAt)}
                          </span>
                        </li>
                        <li>
                          <span>Avg Time To Cook : </span>
                          <span>
                            {slectedItem?.avgCookingTimeInMin
                              ? slectedItem?.avgCookingTimeInMin
                              : 0}{" "}
                            min
                          </span>
                        </li>
                        <li>
                          <span>Cost Price :</span>
                          <span>{slectedItem?.costPrice}</span>
                        </li>
                        <li>
                          <span>Selling Price :</span>
                          <span>{slectedItem?.sellingPrice}</span>
                        </li>

                        <li>
                          <span>Total Raw Materials :</span>
                          <span>{slectedItem?.items?.length}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* {selectWorkspace?.approvalRequest?.status === "approved" && (
                  <div className="card">
                    <div className="row">
                      <span className="text-primary">
                        Shared with
                        <span className="ms-2">People</span>
                        <div className="avatar-group avatar-group-md avatar-group-overlapped me-3 pointer ms-2">
                          <div className="avatar avatar-soft-primary avatar-rounded">
                            <Link
                              to={navigateToManagePeople()}
                              className="initial-wrap"
                            >
                              {selectWorkspace?.employees?.length}
                            </Link>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <div className="tab-pane fade show active" id="tab_info">
              <div className="collapse-simple">
                <div className="card py-0">
                  <div className="card-header">
                    <a
                      role="button"
                      data-bs-toggle="collapse"
                      href="#fl_info1"
                      aria-expanded="true"
                    >
                      <p>
                        Raw materials&nbsp;
                        <span className="badge badge-pill badge-sm badge-soft-primary">
                          {slectedItem?.items?.length}
                        </span>
                      </p>
                    </a>
                  </div>
                  <div id="fl_info1" className="collapse show">
                    <div className="card-body">
                      <ul className="fm-info">
                        {slectedItem?.items?.map((data) => {
                          return (
                            <>
                              <li>
                                <span>Name : </span>
                                <span className="text-capitalize">
                                  {data?.inventoryItem?.name}
                                </span>
                              </li>
                              <li>
                                <span>Quantity :</span>
                                <span>
                                  {data?.quantity}&nbsp;
                                  {data?.unit?.name}
                                </span>
                              </li>
                              <hr className="bg-dark w-75 mx-auto" />
                            </>
                          );
                        })}
                        {/* <li>
                          <span>Total Raw Materials :</span>
                          <span>{slectedItem?.items?.length}</span>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* {selectWorkspace?.approvalRequest?.status === "approved" && (
                  <div className="card">
                    <div className="row">
                      <span className="text-primary">
                        Shared with
                        <span className="ms-2">People</span>
                        <div className="avatar-group avatar-group-md avatar-group-overlapped me-3 pointer ms-2">
                          <div className="avatar avatar-soft-primary avatar-rounded">
                            <Link
                              to={navigateToManagePeople()}
                              className="initial-wrap"
                            >
                              {selectWorkspace?.employees?.length}
                            </Link>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryItemMoreInfoModal;
