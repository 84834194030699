import React, { useState } from "react";
import {
  dateFormatter,
  getName,
} from "../../../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";
import { navigateToManageInvoiceDetails } from "../../../../Navigation/Navigation";
import ItemsInfoModal from "../../OrderHistory/ItemsInfoModal";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const OrderDetailsTable = ({ allOrder, page, pageLimit, id }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [itemDetails, setItemDetails] = useState([]);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-6">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th>Order Date</th>
              <th>Action By</th>
              <th>Items</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            {allOrder?.map((order, index) => {
              return (
                <tr className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td>{dateFormatter(order?.createdAt)}</td>
                  <td>
                    {" "}
                    {order?.actionBy ? (
                      <>
                        <Avatar
                          name={order?.actionBy?.name}
                          count={1}
                          image={order?.actionBy?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(order?.actionBy, user?._id)}
                      </>
                    ) : order?.createdBy ? (
                      <>
                        <Avatar
                          name={order?.createdBy?.name}
                          count={1}
                          image={order?.createdBy?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(order?.createdBy, user?._id)}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {order?.products?.length}
                    <span
                      className="feather-icon text-primary pointer"
                      onClick={() => {
                        setItemDetails(order);
                        setIsOpenDetailsModal(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="bi bi-info-circle ms-1 mb-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                      </svg>
                    </span>
                  </td>
                  <td>{order?.amount}</td>
                  <td
                    className={`text-capitalize ${
                      order?.status === "confirmed"
                        ? "text-success"
                        : order?.status === "pending"
                        ? "text-warning"
                        : order?.status === "cancelled"
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {order?.status}
                  </td>
                  <td>
                    {order?.status === "confirmed" ? (
                      <span
                        className="feather-icon pointer text-primary"
                        onClick={() =>
                          navigate(
                            navigateToManageInvoiceDetails({ id: order?._id }),
                            {
                              state: { buyerId: id, subHeader: "Customer" },
                            }
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          fill="currentColor"
                          className="bi bi-box-arrow-up-right mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                          />
                        </svg>
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isOpenDetailsModal && (
        <ItemsInfoModal
          setIsOpenDetailsModal={setIsOpenDetailsModal}
          itemDetails={itemDetails}
        />
      )}
    </>
  );
};

export default OrderDetailsTable;
