import React from "react";

const TablesHeader = ({
  searchKey,
  setsearchKey,
  handleGetAllTable,
  setisAddTable,
  isModuleReadWriteAccessForTables,
}) => {
  return (
    <>
      <header className="todo-header">
        <div className="d-flex align-items-center">
          <span className="todoapp-title link-dark">
            <h5 className="header-title-font mt-2">All Tables</h5>
            <div className="ms-3"></div>
          </span>
        </div>
        <div className="todo-options-wrap">
          {isModuleReadWriteAccessForTables && (
            <button
              className="btn btn-md btn-primary flex-shrink-0 d-lg-inline-block header-button-shadow"
              onClick={() => {
                setisAddTable(true);
              }}
            >
              Add Table
            </button>
          )}
          <div className="v-separator d-lg-inline-block d-none"></div>
          <form className="d-lg-block d-sm-none" role="search">
            <input
              type="text"
              className="form-control"
              placeholder="Search by name"
              value={searchKey}
              onChange={(e) => {
                setsearchKey(e.target.value);
              }}
            />
          </form>
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="dsdsd"
            data-bs-original-title="Refresh"
            onClick={handleGetAllTable}
          >
            <span className="btn-icon-wrap">
              <span className="feather-icon">
                <i className="ri-refresh-line" title="Refresh Table list"></i>
              </span>
            </span>
          </span>
        </div>
      </header>
    </>
  );
};

export default TablesHeader;
