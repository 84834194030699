import React from "react";

function ParticipantsGroupsHeader({
  search,
  setSearch,
  setPage,
  setaddGroup,
  handleGetAllGroups,
  setAddParticipant,
  setshowCheckBox,
  showCheckBox,
  checkedGroups,
  setCheckedGroups,
  isModuleReadWriteAccessForParticipantGroup,
}) {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1 className="header-title-font ">Participants Group</h1>

          <div className="ms-3">
            {isModuleReadWriteAccessForParticipantGroup && (
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                onClick={() => {
                  setaddGroup(true);
                }}
              >
                Add Group
              </button>
            )}
            {/* <button
              className={`btn btn-sm ${
                showCheckBox ? "btn-primary" : "btn-outline-light "
              } flex-shrink-0 d-lg-inline-block header-button-shadow ms-2`}
              onClick={() => {
                setshowCheckBox(!showCheckBox);
                setCheckedGroups({});
              }}
            >
              Add Participant To Multiple Group
            </button> */}
          </div>
        </span>
      </div>
      {Object.keys(checkedGroups)?.length > 0 && (
        <button
          className="btn btn-md btn-primary flex-shrink-0 d-lg-inline-block floating-button-crm-group"
          onClick={() => {
            setAddParticipant(true);
          }}
        >
          Add{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-arrow-right"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
            />
          </svg>
        </button>
      )}

      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={() => {
            handleGetAllGroups();
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
          />
        </form>
      </div>
    </header>
  );
}

export default ParticipantsGroupsHeader;
