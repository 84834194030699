import React from "react";
import { getName } from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const LastActivity = ({ setEditLeadData, editLeadData }) => {
  const user = useSelector((state) => state.user);

  const handleReset = () => {
    setEditLeadData("");
    const closeModalDom = document.getElementById("activity_info");
    if (closeModalDom) closeModalDom.click();
  };

  return (
    <div
      id="activity_info"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered " role="document">
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Last Activity</h5>
            <div className="border-bottom mb-3"></div>
            <div className="row gx-3 px-3 mt-3">
              <div className="d-flex flex-column gap-2">
                <p className="font-4 text-capitalize">
                  Action : &nbsp;
                  <span className="text-dark">
                    {editLeadData[editLeadData?.length - 1]?.description}
                  </span>
                </p>
                <p className="font-4 text-capitalize">
                  Updated By : &nbsp;
                  <Avatar
                    name={
                      editLeadData[editLeadData?.length - 1]?.updatedBy?.name
                    }
                    count={1}
                    image={
                      editLeadData[editLeadData?.length - 1]?.updatedBy?.image
                    }
                    size="xss"
                    color="primary"
                  />
                  &nbsp;
                  {getName(
                    editLeadData[editLeadData?.length - 1]?.updatedBy,
                    user?._id
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastActivity;
