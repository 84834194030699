import React, { useEffect, useState } from "react";
import PollHeader from "./PollHeader";
import { Avatar } from "../../../components/Avatar/Avatar";
import AddPoll from "./AddPoll";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../api/ApiCall";
import { PollRelatedEndPoints } from "../../../api/Endpoints";
import PollCard from "./PollCard";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { useLocation, useNavigate } from "react-router-dom";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import AddVote from "./AddVote";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { toast } from "react-toastify";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const Poll = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [errorMessage, seterrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [allPolls, setallPolls] = useState(null);
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);
  const [PollCount, setPollCount] = useState(0);
  const navigate = useNavigate();
  const [showPoll, setshowPoll] = useState("all");
  const location = useLocation();
  const [locationData, setlocationData] = useState(null);
  const [selectedPoll, setselectedPoll] = useState([]);
  const [isConfirmClose, setisConfirmClose] = useState(false);
  const [moduleType, setmoduleType] = useState("");

  useEffect(() => {
    setlocationData(location?.state);
    setmoduleType(location?.state ? location?.state?.moduleType : "");
  }, []);

  const isModuleReadWriteAccessPoll = allModuleAccess?.[PRIVATEROUTES.POLL];

  const handleTabChange = (status) => {
    setshowPoll(status);
  };

  const allTabs = [
    {
      name: "All Polls",
      value: "all",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-info">
          {PollCount}
        </span>
      ),
    },
    {
      name: "Your Polls",
      value: user?._id,
      count: (
        <span className="badge badge-pill badge-sm badge-soft-info">
          {PollCount}
        </span>
      ),
    },
  ];

  //  ***---- GET POLL API----***
  const handleGetPolls = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        createdBy: showPoll === "all" ? "" : showPoll,
        isDeleted: false,
        moduleId: location?.state ? location?.state?.moduleId : "",
        moduleType: location?.state ? location?.state?.moduleType : moduleType,
        searchKey: search,
        employees: user?._id,
      },
    };
    const res = await ApiCall("post", PollRelatedEndPoints.getPoll, data);
    if (res?.success) {
      setallPolls(res?.polls?.data);
      settotalPages(res?.polls?.totalPages);
      setPollCount(res?.polls?.totalCounts);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  //  ***---- CALL GET POLL ----***
  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetPolls();
      closeAllModals();
    }
  }, [search, showPoll, page, pageLimit, moduleType]);

  //  ***----POLL SEARCH SET----***
  const handleSearchSet = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  //  ***---- CLOSE POLL CLOSE MODAL ----***
  const handleClose = () => {
    setisConfirmClose(false);
    seterrorMessage("");
    setLoader(false);
  };

  //  ***---- CLOSE POLL API----***
  const handleClosePoll = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      pollId: selectedPoll?._id,
      actionBy: user?._id,
      isClosed: true,
    };
    const res = await ApiCall("patch", PollRelatedEndPoints.editPoll, data);
    if (res?.success) {
      toast.success("Poll Closed successfully");
      handleGetPolls();
      setisConfirmClose(false);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleChangeType = (type) => {
    setlocationData(null);
    navigate(location.pathname, { state: null });
    setmoduleType(type);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <PollHeader
            search={search}
            handleSearchSet={handleSearchSet}
            handleGetPolls={handleGetPolls}
            PollCount={PollCount}
            locationData={locationData}
            navigate={navigate}
            handleChangeType={handleChangeType}
            moduleType={moduleType}
            isModuleReadWriteAccessPoll={isModuleReadWriteAccessPoll}
          />

          {/* *******CUSTOM TAB******** */}
          <CustomTab
            allTabs={allTabs}
            currentTab={showPoll}
            handleTabChange={handleTabChange}
            isShowCount={false}
          />

          <div className="todo-body  mb-5">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && allPolls?.length > 0 && (
                <>
                  {allPolls?.map((poll, index) => {
                    return (
                      <PollCard
                        poll={poll}
                        index={index}
                        page={page}
                        pageLimit={pageLimit}
                        setselectedPoll={setselectedPoll}
                        user={user}
                        setisConfirmClose={setisConfirmClose}
                        isModuleReadWriteAccessPoll={
                          isModuleReadWriteAccessPoll
                        }
                      />
                    );
                  })}
                </>
              )}

              {!loader && allPolls?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={PollCount}
                  dataLength={allPolls?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allPolls?.length === 0 && (
                <NoData title={`No Polls`} />
              )}
            </div>
          </div>
        </div>
        <AddPoll
          user={user}
          handleGetPolls={handleGetPolls}
          locationData={locationData}
        />
        <AddVote
          user={user}
          poll={selectedPoll}
          handleGetPolls={handleGetPolls}
        />
        {isConfirmClose && (
          <ConfirmModal
            isOpen={isConfirmClose}
            onClose={handleClose}
            text={`Are you sure you want to Close this Poll ?`}
            loader={loader}
            errorMessage={errorMessage}
            handleConfirm={handleClosePoll}
          />
        )}
      </div>
    </div>
  );
};

export default Poll;
