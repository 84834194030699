import React from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import { dateFormatter } from "../../../global-functions/globalFunctions";

const ExpenseDetails = ({ setexpenseDetailsModal, expenseDetails }) => {
  return (
    <div className="details-modal">
      <div className="nicescroll-bar overflow-hidden pb-6">
        <div className="d-flex justify-content-between">
          <span className="file-name">Expense Details</span>
          <button
            onClick={() => setexpenseDetailsModal(false)}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="tab-content mt-1 overflow-auto">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple d-flex flex-column gap-3 nicescroll-bar simple-scrollbar p-0 mb-3">
              <div className="card bg-light px-3 rounded-5">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#fl_info"
                    aria-expanded="true"
                    className="fs-6"
                  >
                    Details
                  </a>
                </div>
                <div id="fl_info" className="collapse show">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span className="me-0">Created By : </span>
                        <span className="text-capitalize">
                          <Avatar
                            name={expenseDetails?.createdBy?.name}
                            count={1}
                            image={expenseDetails?.createdBy?.image}
                            size="xss"
                            color="primary"
                          />
                          &nbsp;
                          {expenseDetails?.createdBy?.name}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Date : </span>
                        <span className="text-capitalize">
                          {dateFormatter(expenseDetails?.date)}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Invoice No. : </span>
                        <span className="text-capitalize">
                          {expenseDetails?.invoiceNumber}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Expense Account : </span>
                        <span className="text-capitalize">
                          {expenseDetails?.expenseAccount?.accountName}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Paid Through : </span>
                        <span className="text-capitalize">
                          {expenseDetails?.paidThrough?.accountName}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Vendor : </span>
                        <span className="text-capitalize">
                          {expenseDetails?.vendor?.name}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Amount : </span>
                        <span className="text-capitalize">
                          ₹&nbsp;{expenseDetails?.amount}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Note : </span>
                        <span className="text-capitalize">
                          {expenseDetails?.notes}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseDetails;
