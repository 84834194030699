import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { navigateToLeaveRequestDetails } from "../../../../Navigation/Navigation";

const LeaveHistoryTable = ({ allLeaveHistory, page, pageLimit, selectedMonth, totalAllowedDays }) => {
  const navigate = useNavigate();

  return (
    <div className="contact-list-view">
      <table id="datable_1" className="table nowrap w-100 mb-8">
        <thead>
          <tr className="text-center">
            <th>No</th>
            <th className="w-50" style={{ textAlign: "left" }}>
              Employee Name
            </th>
            <th>Total leaves</th>
          </tr>
        </thead>
        <tbody>
          {allLeaveHistory?.map((leave, index) => {
            return (
              <tr className="text-center">
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td style={{ textAlign: "left" }}>
                  <span
                    className="d-flex align-items-center gap-2 text-dark fw-light text-capitalize pointer"
                    onClick={() =>
                      navigate(
                        navigateToLeaveRequestDetails({
                          id: leave?.staff?.employeeId?._id, monthname: selectedMonth
                        }),{
                          state: {name: leave?.staff?.employeeId?.name , image: leave?.staff?.employeeId?.image}
                        }
                      )
                    }
                  >
                    <Avatar
                      name={leave?.staff?.employeeId?.name}
                      count={1}
                      size="xss"
                      color="primary"
                      image={leave?.staff?.employeeId?.image}
                    />
                    {leave?.staff?.employeeId?.name}
                  </span>
                </td>
                <td>{leave?.leaveCount} / {totalAllowedDays} </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveHistoryTable;
