import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ManagePeople from "../../pages/Settings/ManagePeople/ManagePeople";
import OtherSettings from "../../pages/Settings/OtherSettings/OtherSettings";

const OtherSettingRoutes = {
  path: "/settings",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <ManagePeople />,
    },
    {
      path: "othersetting",
      element: <OtherSettings />,
    },
  ],
};

export default OtherSettingRoutes;
