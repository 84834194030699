import React, { useEffect, useState } from "react";
import { Avatar } from "../../../../../components/Avatar/Avatar";
import { navigateToDealOverview } from "../../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import {
  dateFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import { DealRelatedEndPoints } from "../../../../../api/Endpoints";
import ApiCall from "../../../../../api/ApiCall";
import DataLoader from "../../../../../components/Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";

const DealDetails = ({ setShowDealDetails, id }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [dealsLoader, setdealsLoader] = useState(false);
  const [allDeals, setAllDeals] = useState([]);

  const handleGetAllDeals = async () => {
    startApiCall(null, setdealsLoader);
    const data = {
      page: 1,
      limit: 5000,
      employeeId: user?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "deal",
        contactId: id,
      },
    };
    const res = await ApiCall("post", DealRelatedEndPoints.getDeal, data);
    if (res?.success) {
      setAllDeals(res?.result?.data);
      setdealsLoader(false);
    } else {
      setdealsLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllDeals();
  }, []);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "70%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              onClick={() => setShowDealDetails(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2 text-capitalize">All Deals</h5>
            <div className="border-bottom"></div>
            <div className="row gx-3 px-3 mt-3">
              {!dealsLoader && (
                <div className="overflow-y-scroll" style={{ maxHeight: "60vh" }}>
                  <table id="datable_1" className="table nowrap w-100 mb-8">
                    <thead>
                      <tr className="text-center">
                        <th>No .</th>
                        <th style={{ textAlign: "left" }}>Deal Name</th>
                        <th style={{ textAlign: "left" }}>Added By </th>
                        <th>Amount</th>
                        <th>Closing Date</th>
                        <th>Deal Owner</th>
                        <th>Source</th>
                        <th>Current Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allDeals?.map((deal, index) => {
                        return (
                          <tr className="text-center">
                            <td>{index + 1} .</td>
                            <td style={{ textAlign: "left" }}>
                              <span
                                className="text-capitalize text-truncate pointer text-link"
                                style={{ width: "300px" }}
                                onClick={() => {
                                  navigate(
                                    navigateToDealOverview({ id: deal?._id })
                                  );
                                }}
                              >
                                {deal?.title}
                              </span>
                            </td>
                            <td style={{ textAlign: "left" }}>
                              <Avatar
                                name={deal?.createdBy?.name}
                                count={1}
                                image={deal?.createdBy?.image}
                                size="xss"
                                color="primary"
                              />
                              <span className="ms-1">
                                {deal?.createdBy?.name}
                              </span>
                            </td>
                            <td>{deal?.amount}</td>

                            <td>{dateFormatter(deal?.closingDate)}</td>
                            <td>
                              {deal?.dealOwner ? deal?.dealOwner?.name : "-"}
                            </td>
                            <td>{deal?.source}</td>
                            <td className="text-capitalize">
                              <span
                                className="badge badge-md ms-auto"
                                style={{
                                  backgroundColor: `${
                                    deal?.initialStatusId?.color || "blue"
                                  }`,
                                }}
                              >
                                {deal?.subStatusId?.name}
                              </span>
                            </td>
                            {/* <td>XYZ</td> */}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {dealsLoader && <DataLoader title="data is loading..." />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealDetails;
