import axios from "axios";
import { ACTIVE_VERSION, BASE_URL } from "./base";
import { resolveErrorMessage } from "./service";
import { toast } from "react-toastify";

const API = axios.create({ baseURL: BASE_URL });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("refreshTokenForTaskManager")) {
    req.headers.token = localStorage.getItem("refreshTokenForTaskManager");
  }
  req.headers.version = ACTIVE_VERSION;
  req.headers.platform = "dashboard";
  return req;
});

const ApiCall = async (type, route, data) => {
  try {
    let response;
    const apiFunctions = {
      post: API.post,
      get: API.get,
      patch: API.patch,
      delete: API.delete,
      put: API.put,
    };
    // if(type === "delete"){
    //   response = await apiFunctions[type](route, {data})
    // } else {
      response = await apiFunctions[type](route, data)
    // }
    var success = true;
    if (response?.data?.success) {
      return response?.data;
    }
  } catch (error) {
    success = false;
    toast.error(
      `Something went wrong, ${resolveErrorMessage(error)}`
      // ,
      // ${
      //   error?.response?.status
      // }
    );
    return {
      success,
      error: resolveErrorMessage(error),
      status: error?.response?.status,
    };
  }
};

export default ApiCall;
