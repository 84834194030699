import React from "react";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import No_food from "../../../assets/images/No_food.png";
import { useNavigate } from "react-router-dom";
import { navigateToOrderHistory } from "../../../Navigation/Navigation";
import { convertAmountLocal, reversesDateFormatter } from "../../../global-functions/globalFunctions";

const OverviewInfoCards = ({
  salesOverview,
  stockOverview,
  customerOverview,
  loader,
  loader2,
  loader3,
  loader4,
  topSellingItem,
  dateType,
  currentDate,
  yesterdayDate,
  startDate,
  endDate,
  setdateType,
  setdateModal,
  handleResetFilters
}) => {
  const navigate = useNavigate();

  return (
    <div className="card rounded-10">
      <div className="card-header">
        <p className="fs-5 text-dark fs-semibold">Daily Overview</p>

        {!loader && !loader2 && !loader3 && !loader4 && (
          <div className="selectable-dropdown">
            <div className="dropdown selectable-dropdown">
              <span
                className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
                aria-expanded="false"
                type="button"
                data-bs-toggle="dropdown"
              >
                <span className="text-secondary"> Date :&nbsp;</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>
                &nbsp;
                {dateType}
              </span>
              <div
                role="menu"
                className="dropdown-menu"
                style={{ maxHeight: "40vh", overflow: "auto" }}
              >
                <div className="d-flex justify-content-between">
                  <div
                    className={`dropdown-item text-capitalize ${
                      dateType === "Today" ? "fw-bold" : ""
                    }`}
                    onClick={() => {
                      setdateType("Today");
                      handleResetFilters();
                    }}
                  >
                    1. Today
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div
                    className={`dropdown-item text-capitalize ${
                      dateType === "yesterday" ? "fw-bold" : ""
                    }`}
                    onClick={() => {
                      setdateType("yesterday");
                      handleResetFilters();
                    }}
                  >
                    2. Yesterday
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div
                    className={`dropdown-item text-capitalize ${
                      dateType === "Date Range" ? "fw-bold" : ""
                    }`}
                    onClick={() => {
                      setdateType("Date Range");
                      setdateModal(true);
                    }}
                  >
                    3. Date Range
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {loader && loader2 && loader3 && loader4 && (
          <div className="w-140p border rounded p-2 loading-skeleton">
            <SkeletonConstant width="w-100" height="h-25p" />
          </div>
        )}
      </div>
      <div className="card-body">
        <div className="row g-3">
          <div className="col-lg-5">
            {!loader && (
              <div className="card justify-content-between rounded-10 h-100 p-4 shadow-none">
                <p className="fs-6 text-dark fw-semibold">Sales overview</p>
                <div className="row gx-3 gy-2">
                  <div className="col-6">
                    <div className="d-flex align-items-center gap-3">
                      <span className="btn btn-icon btn-lg bg-indigo-light-5 rounded-6">
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                          >
                            <path
                              d="M10.3753 3.07532C10.6239 2.66093 10.4896 2.12346 10.0752 1.87483C9.6608 1.6262 9.12332 1.76057 8.87469 2.17495L3.87958 10.5001H0.875C0.391751 10.5001 0 10.8919 0 11.3751V13.1251C0 13.6084 0.391751 14.0001 0.875 14.0001H1.96499L5.19301 25.2982C5.354 25.8617 5.869 26.2501 6.45501 26.2501H21.545C22.131 26.2501 22.646 25.8617 22.807 25.2982L26.035 14.0001H27.125C27.6082 14.0001 28 13.6084 28 13.1251V11.3751C28 10.8919 27.6082 10.5001 27.125 10.5001H24.1204L19.1253 2.17495C18.8767 1.76057 18.3392 1.6262 17.9248 1.87483C17.5104 2.12346 17.3761 2.66093 17.6247 3.07532L22.0796 10.5001H5.92042L10.3753 3.07532ZM7 17.5002C7 16.5337 7.7835 15.7502 8.75 15.7502C9.7165 15.7502 10.5 16.5337 10.5 17.5002V21.0002C10.5 21.9667 9.7165 22.7502 8.75 22.7502C7.7835 22.7502 7 21.9667 7 21.0002V17.5002ZM12.25 17.5002C12.25 16.5337 13.0335 15.7502 14 15.7502C14.9665 15.7502 15.75 16.5337 15.75 17.5002V21.0002C15.75 21.9667 14.9665 22.7502 14 22.7502C13.0335 22.7502 12.25 21.9667 12.25 21.0002V17.5002ZM19.25 15.7502C20.2165 15.7502 21 16.5337 21 17.5002V21.0002C21 21.9667 20.2165 22.7502 19.25 22.7502C18.2835 22.7502 17.5 21.9667 17.5 21.0002V17.5002C17.5 16.5337 18.2835 15.7502 19.25 15.7502Z"
                              fill="#FF822E"
                            />
                          </svg>
                        </span>
                      </span>
                      <div>
                        <p className="mb-0 fw-light">Total orders</p>
                        <p className="mb-0 fs-5 text-dark fw-medium">
                          {salesOverview?.orders}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center gap-3">
                      <span className="btn btn-icon btn-lg bg-indigo-light-5 rounded-6">
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31"
                            height="31"
                            viewBox="0 0 31 31"
                            fill="none"
                          >
                            <path
                              d="M1.9375 5.8125C1.9375 4.74245 2.80495 3.875 3.875 3.875H27.125C28.1951 3.875 29.0625 4.74245 29.0625 5.8125L1.9375 5.8125Z"
                              fill="#4F00D0"
                            />
                            <path
                              d="M15.5 21.3125C17.6401 21.3125 19.375 19.5776 19.375 17.4375C19.375 15.2974 17.6401 13.5625 15.5 13.5625C13.3599 13.5625 11.625 15.2974 11.625 17.4375C11.625 19.5776 13.3599 21.3125 15.5 21.3125Z"
                              fill="#4F00D0"
                            />
                            <path
                              d="M0 9.6875C0 8.61745 0.867448 7.75 1.9375 7.75H29.0625C30.1326 7.75 31 8.61745 31 9.6875V25.1875C31 26.2576 30.1326 27.125 29.0625 27.125H1.9375C0.867448 27.125 0 26.2576 0 25.1875V9.6875ZM5.8125 9.6875C5.8125 11.8276 4.0776 13.5625 1.9375 13.5625V21.3125C4.0776 21.3125 5.8125 23.0474 5.8125 25.1875H25.1875C25.1875 23.0474 26.9224 21.3125 29.0625 21.3125V13.5625C26.9224 13.5625 25.1875 11.8276 25.1875 9.6875H5.8125Z"
                              fill="#4F00D0"
                            />
                          </svg>
                        </span>
                      </span>
                      <div>
                        <p className="mb-0 fw-light">Total Revenue</p>
                        <p className="mb-0 fs-5 text-dark fw-medium font-mon">
                          {salesOverview?.totalCosts?.[0]?.revenue
                            ? convertAmountLocal(salesOverview?.totalCosts?.[0]?.revenue)
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center gap-3">
                      <span className="btn btn-icon btn-lg bg-indigo-light-5 rounded-6">
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M20.625 28.125C24.7671 28.125 28.125 24.7671 28.125 20.625C28.125 16.4829 24.7671 13.125 20.625 13.125C16.4829 13.125 13.125 16.4829 13.125 20.625C13.125 24.7671 16.4829 28.125 20.625 28.125ZM30 20.625C30 25.8027 25.8027 30 20.625 30C15.4473 30 11.25 25.8027 11.25 20.625C11.25 15.4473 15.4473 11.25 20.625 11.25C25.8027 11.25 30 15.4473 30 20.625Z"
                              fill="#3862DD"
                            />
                            <path
                              d="M17.6953 22.3957C17.7847 23.5131 18.6669 24.3842 20.2525 24.4873V25.3125H20.9558V24.4816C22.595 24.367 23.5547 23.4902 23.5547 22.2181C23.5547 21.0605 22.8215 20.4645 21.5102 20.1551L20.9558 20.0233V17.777C21.6592 17.8572 22.1062 18.2411 22.2135 18.7741H23.4474C23.358 17.6967 22.4341 16.8544 20.9558 16.7627V15.9375H20.2525V16.7799C18.8517 16.9174 17.898 17.7598 17.898 18.9517C17.898 20.0061 18.6073 20.6766 19.7875 20.9516L20.2525 21.0662V23.4501C19.5312 23.3412 19.0544 22.9458 18.9471 22.3957H17.6953ZM20.2465 19.8571C19.5551 19.6967 19.1795 19.37 19.1795 18.8772C19.1795 18.3271 19.5849 17.9145 20.2525 17.7942V19.8571H20.2465ZM21.0571 21.2553C21.8976 21.4502 22.2851 21.7654 22.2851 22.3212C22.2851 22.9573 21.8022 23.3928 20.9558 23.473V21.2324L21.0571 21.2553Z"
                              fill="#3862DD"
                            />
                            <path
                              d="M1.875 0C0.839466 0 0 0.839467 0 1.875V16.875C0 17.9105 0.839466 18.75 1.875 18.75H9.53055C9.63881 18.1047 9.802 17.4781 10.0151 16.875H5.625C5.625 14.8039 3.94607 13.125 1.875 13.125V5.625C3.94607 5.625 5.625 3.94607 5.625 1.875H24.375C24.375 3.94607 26.0539 5.625 28.125 5.625V12.2396C28.8377 12.8775 29.4686 13.6049 30 14.4041V1.875C30 0.839466 29.1605 0 28.125 0H1.875Z"
                              fill="#3862DD"
                            />
                            <path
                              d="M18.7468 9.53109C18.7489 9.47932 18.75 9.42729 18.75 9.375C18.75 7.30393 17.0711 5.625 15 5.625C12.9289 5.625 11.25 7.30393 11.25 9.375C11.25 10.6584 11.8948 11.7913 12.878 12.4673C14.4654 10.9594 16.4923 9.90998 18.7468 9.53109Z"
                              fill="#3862DD"
                            />
                          </svg>
                        </span>
                      </span>
                      <div>
                        <p className="mb-0 fw-light">Total cost</p>
                        <p className="mb-0 fs-5 text-dark fw-medium font-mon">
                          {salesOverview?.totalCosts?.[0]?.costPrice
                            ? convertAmountLocal(salesOverview?.totalCosts?.[0]?.costPrice)
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center gap-3">
                      <span className="btn btn-icon btn-lg bg-indigo-light-5 rounded-6">
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="31"
                            height="31"
                            viewBox="0 0 31 31"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0 0H1.9375V29.0625H31V31H0V0ZM19.375 6.78125C19.375 6.24622 19.8087 5.8125 20.3438 5.8125H28.0938C28.6288 5.8125 29.0625 6.24622 29.0625 6.78125V14.5312C29.0625 15.0663 28.6288 15.5 28.0938 15.5C27.5587 15.5 27.125 15.0663 27.125 14.5312V9.49511L20.1248 18.0509C19.9515 18.2628 19.6966 18.3914 19.4233 18.405C19.1499 18.4187 18.8835 18.316 18.69 18.1225L13.6791 13.1117L6.59596 22.851C6.28128 23.2837 5.6754 23.3794 5.24271 23.0647C4.81001 22.75 4.71435 22.1442 5.02904 21.7115L12.779 11.0552C12.9457 10.8261 13.2043 10.6814 13.4867 10.6592C13.7692 10.6371 14.0472 10.7397 14.2475 10.94L19.3031 15.9956L26.0495 7.75H20.3438C19.8087 7.75 19.375 7.31628 19.375 6.78125Z"
                              fill={salesOverview?.totalCosts?.[0]?.profit?.toFixed(2) >=
                                0 ?"#00C172":"red"}
                            />
                          </svg>
                        </span>
                      </span>
                      <div>
                        <p className="mb-0 fw-light">
                          Total&nbsp;
                          {salesOverview?.totalCosts?.[0]?.profit?.toFixed(2) >=
                          0
                            ? "Profits"
                            : "loss"}
                        </p>
                        <p
                          className={`mb-0 fs-5 fw-medium font-mon ${
                            salesOverview?.totalCosts?.[0]?.profit?.toFixed(
                              2
                            ) >= 0
                              ? "text-success"
                              : "text-danger"
                          }`}
                        >
                          {salesOverview?.totalCosts?.[0]?.profit?.toFixed(2) >=
                            0 && "+"}
                          {salesOverview?.totalCosts?.[0]?.profit ? convertAmountLocal(salesOverview?.totalCosts?.[0]?.profit) : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {loader && (
              <div className="card justify-content-between rounded-10 h-100 p-4 loading-skeleton">
                <SkeletonConstant width="w-120p" height="h-25p" />
                <div className="row gx-3 gy-2">
                  <div className="col-6">
                    <SkeletonConstant width="w-80" height="h-50p" />
                  </div>
                  <div className="col-6">
                    <SkeletonConstant width="w-80" height="h-50p" />
                  </div>
                  <div className="col-6">
                    <SkeletonConstant width="w-80" height="h-50p" />
                  </div>
                  <div className="col-6">
                    <SkeletonConstant width="w-80" height="h-50p" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-7">
            <div className="row g-3">
              <div className="col-md-4 col-sm-6">
                {!loader2 && (
                  <div className="card h-100 p-4 justify-content-between rounded-10 shadow-none">
                    <p className="fs-6 text-dark fw-semibold">Stock overview</p>
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex gap-2">
                        <span className="btn btn-icon btn-xs bg-indigo-light-5 rounded-6">
                          <span className="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#00C172"
                              class="bi bi-arrow-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                              />
                            </svg>
                          </span>
                        </span>
                        <div>
                          <p className="mb-0 fw-light">Stock IN </p>
                          <p className="mb-0 fs-5 text-dark fw-medium">
                            {stockOverview?.stockIn}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <span className="btn btn-icon btn-xs bg-indigo-light-5 rounded-6">
                          <span className="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#F31D2F"
                              class="bi bi-arrow-left"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                              />
                            </svg>
                          </span>
                        </span>
                        <div>
                          <p className="mb-0 fw-light">Total OUT</p>
                          <p className="mb-0 fs-5 text-dark fw-medium">
                            {stockOverview?.stockOut}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {loader2 && (
                  <div className="card h-100 p-4 justify-content-between rounded-10 loading-skeleton">
                    <SkeletonConstant width="w-120p" height="h-25p" />
                    <div className="d-flex flex-column gap-2">
                      <SkeletonConstant width="w-80" height="h-50p" />
                      <SkeletonConstant width="w-80" height="h-50p" />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-6">
                {!loader3 && (
                  <div className="card h-100 p-4 justify-content-between rounded-10 shadow-none">
                    <p className="fs-6 text-dark fw-semibold">
                      Customer overview
                    </p>
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex gap-2">
                        <span className="btn btn-icon btn-xs bg-indigo-light-5 rounded-6">
                          <span className="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 13 13"
                              fill="none"
                            >
                              <path
                                d="M9.93909 12.6441C11.4236 12.6441 12.6271 11.4406 12.6271 9.95606C12.6271 8.4715 11.4236 7.26803 9.93909 7.26803C8.45453 7.26803 7.25106 8.4715 7.25106 9.95606C7.25106 11.4406 8.45453 12.6441 9.93909 12.6441ZM11.2284 9.19362L10.2029 10.9028C10.0101 11.2241 9.56665 11.2787 9.30167 11.0137L8.70755 10.4196C8.55758 10.2696 8.55758 10.0265 8.70755 9.87653C8.85751 9.72656 9.10065 9.72656 9.25061 9.87653L9.67085 10.2968L10.5698 8.79848C10.6789 8.61662 10.9148 8.55765 11.0967 8.66677C11.2785 8.77588 11.3375 9.01176 11.2284 9.19362Z"
                                fill="#3862DD"
                              />
                              <path
                                d="M8.78708 4.19599C8.78708 5.46847 7.75553 6.50002 6.48305 6.50002C5.21057 6.50002 4.17903 5.46847 4.17903 4.19599C4.17903 2.92352 5.21057 1.89197 6.48305 1.89197C7.75553 1.89197 8.78708 2.92352 8.78708 4.19599Z"
                                fill="#3862DD"
                              />
                              <path
                                d="M1.875 10.3401C1.875 11.1081 2.64301 11.1081 2.64301 11.1081H6.67971C6.55235 10.7477 6.48305 10.36 6.48305 9.95606C6.48305 8.91644 6.94209 7.98403 7.66854 7.35046C7.31026 7.29734 6.9162 7.26803 6.48305 7.26803C2.64301 7.26803 1.875 9.57205 1.875 10.3401Z"
                                fill="#3862DD"
                              />
                            </svg>
                          </span>
                        </span>
                        <div>
                          <p className="mb-0 fw-light">Total on board</p>
                          <p className="mb-0 fs-5 text-dark fw-medium">
                            {customerOverview?.totalOnBoard}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        <span className="btn btn-icon btn-xs bg-indigo-light-5 rounded-6">
                          <span className="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                            >
                              <path
                                d="M8.375 5.75C9.82475 5.75 11 6.92525 11 8.375C11 9.82475 9.82475 11 8.375 11C6.92525 11 5.75 9.82475 5.75 8.375C5.75 6.92525 6.92525 5.75 8.375 5.75ZM8.64017 10.1402L9.76517 9.01517C9.91161 8.86872 9.91161 8.63128 9.76517 8.48483C9.61872 8.33839 9.38128 8.33839 9.23483 8.48483L8.75 8.96967V6.875C8.75 6.66789 8.58211 6.5 8.375 6.5C8.16789 6.5 8 6.66789 8 6.875V8.96967L7.51517 8.48483C7.36872 8.33839 7.13128 8.33839 6.98483 8.48483C6.83839 8.63128 6.83839 8.86872 6.98483 9.01517L8.10983 10.1402C8.18016 10.2105 8.27554 10.25 8.375 10.25C8.47446 10.25 8.56984 10.2105 8.64017 10.1402Z"
                                fill="#3862DD"
                              />
                              <path
                                d="M7.25 2.75C7.25 3.99264 6.24264 5 5 5C3.75736 5 2.75 3.99264 2.75 2.75C2.75 1.50736 3.75736 0.5 5 0.5C6.24264 0.5 7.25 1.50736 7.25 2.75Z"
                                fill="#3862DD"
                              />
                              <path
                                d="M0.5 8.75C0.5 9.5 1.25 9.5 1.25 9.5H5.19204C5.06767 9.14812 5 8.76946 5 8.375C5 7.35976 5.44827 6.44921 6.15769 5.8305C5.80781 5.77863 5.42299 5.75 5 5.75C1.25 5.75 0.5 8 0.5 8.75Z"
                                fill="#3862DD"
                              />
                            </svg>
                          </span>
                        </span>
                        <div>
                          <p className="mb-0 fw-light">Purchased</p>
                          <p className="mb-0 fs-5 text-dark fw-medium">
                            {customerOverview?.purchased}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {loader3 && (
                  <div className="card h-100 p-4 justify-content-between rounded-10 loading-skeleton">
                    <SkeletonConstant width="w-120p" height="h-25p" />
                    <div className="d-flex flex-column gap-2">
                      <SkeletonConstant width="w-80" height="h-50p" />
                      <SkeletonConstant width="w-80" height="h-50p" />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                {!loader4 && (
                  <div className="card rounded-10 justify-content-between h-100 p-4 shadow-none">
                    <div className="d-flex align-items-center gap-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M3.61077 15.4427C3.22433 15.6413 2.78677 15.2942 2.86457 14.8508L3.69429 10.1213L0.171963 6.76462C-0.157217 6.45092 0.0131827 5.87737 0.454172 5.81472L5.35265 5.11885L7.53677 0.792305C7.73352 0.402565 8.26648 0.402565 8.46323 0.792305L10.6474 5.11885L15.5458 5.81472C15.9868 5.87737 16.1572 6.45092 15.828 6.76462L12.3057 10.1213L13.1354 14.8508C13.2132 15.2942 12.7757 15.6413 12.3892 15.4427L8 13.1868L3.61077 15.4427Z"
                          fill="#FFC207"
                        />
                      </svg>
                      <p className="fs-6 mb-0 text-dark fw-semibold">
                        Top Selling Item
                      </p>
                    </div>
                    {topSellingItem?.highestSellingProduct?.sellingPrice ? (
                      <>
                        <div className="row gx-3">
                          <div className="col-4">
                            <CustomTooltip
                              text={
                                topSellingItem?.highestSellingProduct
                                  ?.productName
                              }
                              placement="top"
                            >
                              <img
                                className="w-100 h-100 objetFit-cover"
                                src={
                                  topSellingItem?.highestSellingProduct?.images
                                    ?.length
                                    ? topSellingItem?.highestSellingProduct
                                        ?.images?.[0]
                                    : No_food
                                }
                                alt=""
                              />
                            </CustomTooltip>
                          </div>
                          <div className="col-8">
                            <div className="fw-light d-flex flex-column">
                              <p className="mb-0 text-dark text-truncate w-100">
                                {
                                  topSellingItem?.highestSellingProduct
                                    ?.productName
                                }
                              </p>
                              <p className="mb-0 text-primary font-mon font-3">
                                {
                                  convertAmountLocal(topSellingItem?.highestSellingProduct
                                    ?.sellingPrice)
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                        <span
                          className="text-center py-1 border border-light shadow rounded-6 fw-medium pointer"
                          onClick={() => {
                            navigate(navigateToOrderHistory(), {
                              state: {
                                id: topSellingItem?.highestSellingProduct?._id,
                                startDate:
                                  dateType === "Today"
                                    ? reversesDateFormatter(currentDate)
                                    : dateType === "yesterday"
                                    ? reversesDateFormatter(yesterdayDate)
                                    : dateType === "Date Range"
                                    ? startDate
                                    : "",
                                endDate:
                                  dateType === "Today"
                                    ? reversesDateFormatter(currentDate)
                                    : dateType === "yesterday"
                                    ? reversesDateFormatter(yesterdayDate)
                                    : dateType === "Date Range"
                                    ? endDate
                                    : "",
                                dateType: dateType,
                              },
                            });
                          }}
                        >
                          {topSellingItem?.highestSellingCounts} orders
                        </span>
                      </>
                    ) : (
                      <div className="d-flex flex-column align-items-center gap-3">
                        <img
                          className="w-70p objetFit-cover"
                          src={No_food}
                          alt=""
                        />
                        <p className="text-secondary fw-semibold">
                          No Items Ordered Yet
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {loader4 && (
                  <div className="card rounded-10 justify-content-between h-100 p-4 loading-skeleton">
                    <SkeletonConstant width="w-120p" height="h-25p" />
                    <div className="d-flex flex-column gap-3">
                      <SkeletonConstant width="w-80" height="h-75p" />
                      <SkeletonConstant width="w-80" height="h-25p" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewInfoCards;
