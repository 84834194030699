import React, { useEffect, useState } from "react";
import HolidayHeader from "./HolidayHeader";
import HolidayTable from "./HolidayTable";
import AddHoliday from "./AddHoliday";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { HolidayRelatedEndPoints } from "../../api/Endpoints";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../components/NoData/NoData";
import FooterPagination from "../../components/Pagination/FooterPagination";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../components/Constant/Route-Constants";
import SkeletonConstant from "../../components/Constant/SkeletonConstant";
import HolidaySkeleton from "./HolidaySkeleton";

const Holiday = () => {
  const [addHoliday, setAddHoliday] = useState(false);
  const [allHoliday, setAllHoliday] = useState([]);
  const [loader, setloader] = useState(true);

  const { allModuleAccess } = useSelector((state) => state);

  const isModuleReadWriteAccessForHoliday =
    allModuleAccess?.[PRIVATEROUTES.HOLIDAY];

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllHolidays = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      HolidayRelatedEndPoints.getAllHolidays,
      data
    );
    if (res?.success) {
      setAllHoliday(res?.holidays?.data);
      setcount(res?.holidays?.totalCounts);
      settotalPages(res?.holidays?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllHolidays();
    closeAllModals();
  }, [page, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <HolidayHeader
            setAddHoliday={setAddHoliday}
            handleGetAllHolidays={handleGetAllHolidays}
            isModuleReadWriteAccessForHoliday={
              isModuleReadWriteAccessForHoliday
            }
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="contact-list-view">
                {!loader && allHoliday?.length > 0 && (
                  <HolidayTable
                    allHoliday={allHoliday}
                    page={page}
                    pageLimit={pageLimit}
                    isModuleReadWriteAccessForHoliday={
                      isModuleReadWriteAccessForHoliday
                    }
                  />
                )}
                {!loader && allHoliday?.length > 0 && (
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allHoliday?.length}
                    align={"end"}
                  />
                )}
                {loader && <HolidaySkeleton />}
                {!loader && allHoliday?.length === 0 && <NoData />}
              </div>
            </div>
          </div>
        </div>
        {addHoliday && (
          <AddHoliday
            allHoliday={allHoliday}
            setAddHoliday={setAddHoliday}
            handleGetAllHolidays={handleGetAllHolidays}
          />
        )}
      </div>
    </div>
  );
};

export default Holiday;
