import React, { useEffect, useState } from "react";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  attendanceRelatedEndPoints,
  salaryRelatedEndPoints,
} from "../../../api/Endpoints";
import NoData from "../../../components/NoData/NoData";
import StaffTable from "./StaffTable";
import OnboardingAllSteps from "./OnboardingSteps/OnboardingAllSteps";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import EditAttendanceMethod from "./EditOnboarding/EditAttendanceMethod";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import { skeletonsTable } from "../../../components/Constant/Skeleton";
import EditStaff from "./EditSatff";
import { Avatar } from "../../../components/Avatar/Avatar";
import { toast } from "react-toastify";

const Onboarding = () => {
  const user = useSelector((state) => state.user);
  const { allModuleAccess } = useSelector((state) => state);
  const [search, setSearch] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [approvedStaff, setapprovedStaff] = useState([]);
  const [employeeData, setemployeeData] = useState("");
  const [modalType, setmodalType] = useState("");
  const [page, setPage] = useState(1);
  const [count, setcount] = useState(500);
  const [pageLimit, setpageLimit] = useState(30);
  const [EditStaffModal, setEditStaffModal] = useState(false);
  const [salaryData, setSalaryData] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [editSalary, seteditSalary] = useState(false);

  const onClose = () => {
    setEditStaffModal(false);
    seteditSalary(false);
    setSalaryData("");
    seterrorMessage("");
  };

  // ***** EDIT ATTENDANCE METHOD *****
  const [isEditOnboarding, setisEditOnboarding] = useState(false);
  const isModuleReadWriteAccessForOnboarding =
    allModuleAccess?.[PRIVATEROUTES.ONBOARDING];

  const handleGetApprovedEmployee = async () => {
    startApiCall(null, setloader);

    const res = await ApiCall(
      "get",
      attendanceRelatedEndPoints.staffOnboarding,
      {
        params: {
          businessId: getCurrentWorkspaceId(),
          page: page,
          limit: pageLimit,
          searchKey: search,
        },
      }
    );
    if (res?.success) {
      setcount(res?.data);
      setapprovedStaff(res?.data?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  //REMOVE EMPLOYEE
  const handleRemoveEmployee = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    const data = {
      staffId: employeeData?.staff?._id,
    };

    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.removeStaff,
      data
    );
    if (res?.success) {
      toast.success("Staff removed successfully!");
      onClose();
      handleGetApprovedEmployee();
      setloader2(false);
    } else {
      seterrorMessage(res?.error);
      setloader2(false);
    }
  };

  const handleEditSalary = async (e) => {
    e.preventDefault();
    const salaryAmount = employeeData?.staff?.salary;
    if (parseFloat(salaryAmount) === parseFloat(salaryData)) {
      seterrorMessage(
        "The current salary can't be the same as the previous salary."
      );
      setloader2(false);
    } else {
      startApiCall(seterrorMessage, setloader2);
      const data = {
        staffId: employeeData?.staff?._id,
        salary: salaryData,
      };

      const res = await ApiCall(
        "post",
        salaryRelatedEndPoints.editSalary,
        data
      );
      if (res?.success) {
        toast.success("Staff salary edited successfully!");
        onClose();
        handleGetApprovedEmployee();
        setloader2(false);
      } else {
        seterrorMessage(res?.error);
        setloader2(false);
      }
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetApprovedEmployee();
      closeAllModals();
    }
  }, [search, page, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <a className="todoapp-title  link-dark">
                <h1>Staff Onboarding</h1>
              </a>
            </div>

            {!loader && approvedStaff?.length > 0 && (
              <div className="d-flex align-items-center">
                <span className="d-md-inline">Total Staff: </span>
                <span className="text-dark fs-5 fw-medium ps-2">
                  {count?.staffCount}
                </span>
              </div>
            )}
            {loader && <SkeletonConstant width="w-140p" height="h-35p" />}

            <div className="todo-options-wrap">
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="dsdsd"
                data-bs-original-title="Refresh"
                onClick={handleGetApprovedEmployee}
              >
                <span className="btn-icon-wrap">
                  <span className="feather-icon">
                    <i
                      className="ri-refresh-line"
                      title="Refresh Shift list"
                    ></i>
                  </span>
                </span>
              </span>
              <div className="v-separator d-lg-inline-block d-none"></div>
              <form className="d-sm-block d-none" role="search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name"
                  value={search}
                  onChange={(e) => {
                    setPage(1);
                    setSearch(e.target.value);
                  }}
                />
              </form>
            </div>
          </header>

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="todo-list-view">
                {!loader && approvedStaff?.length > 0 && (
                  <StaffTable
                    approvedStaff={approvedStaff}
                    setemployeeData={setemployeeData}
                    page={page}
                    pageLimit={pageLimit}
                    setmodalType={setmodalType}
                    setisEditOnboarding={setisEditOnboarding}
                    setEditStaffModal={setEditStaffModal}
                    seteditSalary={seteditSalary}
                    isModuleReadWriteAccessForOnboarding={
                      isModuleReadWriteAccessForOnboarding
                    }
                  />
                )}

                {loader && skeletonsTable(6, 6)}
                {!loader && approvedStaff?.length === 0 && <NoData />}
              </div>
              {!loader && approvedStaff?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={count?.totalPages}
                  count={count?.totalCounts}
                  dataLength={approvedStaff?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
        {isEditOnboarding && (
          <EditAttendanceMethod
            isEditOnboarding={isEditOnboarding}
            setisEditOnboarding={setisEditOnboarding}
            employeeData={employeeData}
            setemployeeData={setemployeeData}
            handleGetApprovedEmployee={handleGetApprovedEmployee}
          />
        )}
        {EditStaffModal && (
          <EditStaff
            isOpen={EditStaffModal}
            onClose={onClose}
            text={
              editSalary ? (
                "Are you sure you want to edit the salary of this staff member?"
              ) : (
                <>
                  Are you sure you want to remove this staff member?
                  <br />
                  {
                    <Avatar
                      name={employeeData?.employeeId?.name}
                      count={1}
                      image={employeeData?.employeeId?.image}
                      size="xss"
                      color="primary"
                    />
                  }
                  &nbsp;
                  {employeeData?.employeeId?.name}{" "}
                </>
              )
            }
            header={editSalary ? "Edit Salary" : "Remove Staff"}
            loader={loader2}
            errorMessage={errorMessage}
            employeeData={employeeData}
            handleConfirm={editSalary ? handleEditSalary : handleRemoveEmployee}
            salaryData={salaryData}
            editSalary={editSalary}
            setSalaryData={setSalaryData}
          />
        )}
      </div>
      <OnboardingAllSteps
        employeeData={employeeData}
        setemployeeData={setemployeeData}
        handleGetApprovedEmployee={handleGetApprovedEmployee}
        modalType={modalType}
        setmodalType={setmodalType}
      />
    </div>
  );
};

export default Onboarding;
