import React, { useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ReactToPrint from "react-to-print";

const PrintKotOptionModal = (props) => {
  const { componentRef, setprintOption, printOption, orderData } = props;
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  const handleKotPrint = (event) => {
    const selectedAttendance = event.target.value;
    setprintOption(selectedAttendance);
  };

  return (
    <div
      id="kot_print_modal"
      className="modal fade add-new-task"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <form>
          <div className="modal-content">
            <div className="modal-body p-3">
              <h5 className="mb-4 text_center">
                Print KOT{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  fill="currentColor"
                  class="bi bi-printer-fill text-primary"
                  viewBox="0 0 16 16"
                >
                  <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                  <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                </svg>
              </h5>
              <div className="border-bottom"></div>
              <div className="row gx-3 mt-4">
                <div className="col-sm-12 w-400p">
                  <div className="form-inline">
                    <div className="form-group  d-flex justify-content-center">
                      <div className="form-check form-check-inline ms-2">
                        <div className="custom-control custom-radio radio-primary">
                          <input
                            type="radio"
                            id="customRadioc2"
                            name="customRadioc2"
                            className="form-check-input pointer"
                            value="Full KOT"
                            onChange={handleKotPrint}
                            checked={printOption === "Full KOT"}
                            required
                          />
                          <label className="form-check-label">Full KOT </label>
                        </div>
                      </div>
                      <div className="form-check form-check-inline">
                        <div className="custom-control custom-radio radio-primary">
                          <input
                            type="radio"
                            id="customRadioc3"
                            name="customRadioc2"
                            className="form-check-input pointer"
                            value="Added Items"
                            onChange={handleKotPrint}
                            checked={printOption === "Added Items"}
                            required
                          />
                          <label className="form-check-label">
                            Latest Added Items
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text_center"></div>
              {printOption === "Added Items" &&
                !orderData?.lastAddedItem.length && (
                  <ErrorMessage errorMessage={"No Last Added Item"} />
                )}
            </div>
            <div
              className="px-4 pb-4"
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <button
                disabled={loader}
                className="btn btn-rounded btn-secondary col-sm-5"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={
                      loader ||
                      (printOption === "Added Items" &&
                        !orderData?.lastAddedItem.length)
                    }
                    className="btn btn-rounded btn-primary col-sm-5"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    {loader ? <ApiLoader /> : "Confirm"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PrintKotOptionModal;
