import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  CrmSettingsRelatedEndPoints,
  LeadRelatedEndPoints,
  StatusRelatedEndPoints,
} from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import allCountries from "../../../components/Countries/Countries";

const CreateLead = ({
  handleGetAllLeads,
  editLeadData,
  setEditLeadData,
  handleGetLeadDetails,
}) => {
  const { user } = useSelector((state) => state);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loaderGroup, setLoaderGroup] = useState(false);
  const [loaderSub, setloaderSub] = useState(false);
  const allSource = ["Advertisement", "Social", "Other"];
  const allBusinessType = ["B2B", "B2C", "B2B2C"];
  const [allLeadSource, setAllLeadSource] = useState([]);
  const [leadSource, setleadSource] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [leadStatusGroup, setleadStatusGroup] = useState("");
  const [allStatusGroup, setallStatusGroup] = useState(null);
  const [currentStatus, setcurrentStatus] = useState("");
  const [allSubGroup, setallSubGroup] = useState(null);
  const [allFields, setallFields] = useState([]);
  const [leadInfo, setleadInfo] = useState({
    name: "",
    companyName: "",
    role: "",
    websiteLink: "",
    desc: "",
    referBy: "",
  });

  const [contactInfo, setcontactInfo] = useState({
    phone: "",
    email: "",
    address: "",
    city: "",
    pin: "",
    state: "",
  });
  const [countryCode, setcountryCode] = useState("");
  const [codeFilter, setcodeFilter] = useState("");

  const filteredCountries = allCountries?.filter(
    (country) =>
      country?.code?.includes(codeFilter) ||
      country?.country?.toLowerCase()?.includes(codeFilter?.toLowerCase())
  );
  const [dynamicFields, setDynamicFields] = useState("");

  useEffect(() => {
    if (allFields?.length > 0 && !editLeadData) {
      setDynamicFields(
        allFields?.reduce((acc, field) => {
          acc[field?.feildName] = {
            fieldName: field?.feildName,
            fieldValue: "",
          };
          return acc;
        }, {})
      );
    }
  }, [allFields, editLeadData]);

  const handleDynamicFieldChange = (e, fieldName) => {
    const { value } = e.target;
    setDynamicFields((prevFields) => ({
      ...prevFields,
      [fieldName]: { fieldName, fieldValue: value },
    }));
  };

  const handleLeadInfoChange = (e) => {
    const { name, value } = e.target;
    setleadInfo({
      ...leadInfo,
      [name]: value,
    });
  };
  const handleContactInfoChange = (e) => {
    const { name, value } = e.target;
    setcontactInfo({
      ...contactInfo,
      [name]: value,
    });
  };

  const handleGetLeadSource = async () => {
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLeadSource, data);
    if (res?.success) {
      setAllLeadSource(res?.result?.data);
    }
  };

  const handleGetDynamicFields = async () => {
    startApiCall(null, setLoader2);
    const data = {
      page: 1,
      limit: 1000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        feildFor: "Lead",
      },
    };
    const res = await ApiCall(
      "post",
      CrmSettingsRelatedEndPoints.getAllFields,
      data
    );
    if (res?.success) {
      setallFields(res?.customField?.data);
      setLoader2(false);
    } else {
      setLoader2(false);
    }
  };

  const handleGetLeadStatusGroup = async () => {
    startApiCall(seterrorMessage, setLoaderGroup);
    const data = {
      page: 1,
      limit: 300,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
        moduleType: "Lead",
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getGropus, data);
    if (res?.success) {
      setallStatusGroup(res?.result?.data);
      setLoaderGroup(false);
    } else {
      setLoaderGroup(false);
      seterrorMessage(res.error);
    }
  };

  const handleCreateLead = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const dataToSend = Object?.keys(dynamicFields)?.map((key) => {
      return dynamicFields[key];
    });

    const payload = {
      phone: contactInfo?.phone,
      countryCode: countryCode,
      email: contactInfo?.email,
      address: contactInfo?.address,
      city: contactInfo?.city,
      state: contactInfo?.state,
      pinCode: contactInfo?.pin,
      title: leadInfo?.name,
      businessName: leadInfo?.companyName,
      description: leadInfo?.desc,
      role: leadInfo?.role,
      websiteLink: leadInfo?.websiteLink,
      source: leadSource,
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
      isDeal: false,
      groupId: leadStatusGroup,
      status: [currentStatus],
      referredBy: leadInfo.referBy,
      businessType,
      additionalInfo: dataToSend,
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.createLead, payload);
    if (res?.success) {
      handleGetAllLeads();
      toast.success("Lead has been created Successfully!");
      handleReset();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  // EDIT LEAD FUNCTION API CALL
  const handleEditLead = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const dataToSend = Object?.keys(dynamicFields)?.map((key) => {
      return dynamicFields[key];
    });
    const data = {
      leadId: editLeadData?._id,
      countryCode: countryCode,
      phone: contactInfo?.phone,
      email: contactInfo?.email,
      address: contactInfo?.address,
      city: contactInfo?.city,
      state: contactInfo?.state,
      pinCode: contactInfo?.pin,
      title: leadInfo?.name,
      businessName: leadInfo?.companyName,
      description: leadInfo?.desc,
      role: leadInfo?.role,
      websiteLink: leadInfo?.websiteLink,
      source: leadSource,
      leadGroupActionBy: user?._id,
      referredBy: leadInfo.referBy,
      businessType,
      additionalInfo: dataToSend,
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.editLead, data);
    if (res?.success) {
      toast.success("Edit lead successfully!");
      handleReset();
      handleGetLeadDetails();
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    handleGetLeadStatusGroup();
    handleGetLeadSource();
    handleGetDynamicFields();
  }, []);

  const handleReset = () => {
    setleadInfo({
      name: "",
      companyName: "",
      role: "",
      websiteLink: "",
      desc: "",
    });

    setcontactInfo({
      phone: "",
      email: "",
      address: "",
      city: "",
      pin: "",
      state: "",
    });
    seterrorMessage("");
    setLoader(false);
    setLoaderGroup(false);
    setleadSource("");
    setleadStatusGroup("");
    setEditLeadData("");
    setcountryCode("");
    setcodeFilter("");
    setDynamicFields(
      allFields?.reduce((acc, field) => {
        acc[field?.feildName] = { fieldName: field?.feildName, fieldValue: "" };
        return acc;
      }, {})
    );
    const closeModalDom = document.getElementById("create_new_lead");
    if (closeModalDom) closeModalDom.click();
  };

  const handleGetAllSubStatus = async () => {
    startApiCall(null, setloaderSub);
    const data = {
      page: 1,
      limit: 200,
      filters: {
        statusGroupId: leadStatusGroup,
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getStatus, data);
    if (res?.success) {
      setallSubGroup(res?.result?.data);
      setloaderSub(false);
    } else {
      setloaderSub(false);
    }
  };

  useEffect(() => {
    if (leadStatusGroup !== "") {
      handleGetAllSubStatus();
    }
  }, [leadStatusGroup]);

  useEffect(() => {
    if (allSubGroup?.length > 0) {
      setcurrentStatus(allSubGroup?.[0]?._id);
    }
  }, [allSubGroup]);

  useEffect(() => {
    if (editLeadData) {
      setleadInfo({
        name: editLeadData?.title,
        companyName: editLeadData?.businessName,
        role: editLeadData?.role,
        websiteLink: editLeadData?.websiteLink,
        desc: editLeadData?.description,
        referBy: editLeadData?.referredBy,
      });
      setcontactInfo({
        phone: editLeadData?.phone,
        email: editLeadData?.email,
        address: editLeadData?.address,
        city: editLeadData?.city,
        pin: editLeadData?.pinCode,
        state: editLeadData?.state,
      });
      setcountryCode(editLeadData?.countryCode);
      setleadSource(editLeadData?.source);
      setBusinessType(editLeadData?.businessType);
      setDynamicFields(
        editLeadData?.additionalInfo?.reduce((acc, field) => {
          acc[field?.fieldName] = {
            fieldName: field?.fieldName,
            fieldValue: field?.fieldValue,
          };
          return acc;
        }, {})
      );
    }
  }, [editLeadData]);

  return (
    <div
      id="create_new_lead"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "60%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editLeadData ? "Edit Lead" : "Create Lead"}
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editLeadData) {
                  handleEditLead(e);
                } else {
                  handleCreateLead(e);
                }
              }}
            >
              <div className="mh-450p nicescroll-bar simple-scrollbar">
                <div className="row gx-3 px-3 w-100">
                  <div
                    className="d-flex justify-content-start mt-1 mb-3 fs-5 text-dark"
                    style={{ backgroundColor: "#E3EDFF" }}
                  >
                    Lead Info
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Lead Name {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder="Enter Lead Name"
                        required
                        value={leadInfo.name}
                        onChange={handleLeadInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">Company Name </label>
                      <input
                        className="form-control"
                        type="text"
                        name="companyName"
                        placeholder="Enter Company Name"
                        value={leadInfo.companyName}
                        onChange={handleLeadInfoChange}
                      />
                    </div>
                  </div>

                  {!editLeadData && (
                    <>
                      <div className="col-sm-4">
                        <label className="form-label">
                          Choose Status group {reuiredField}
                        </label>
                        <div className="form-group">
                          <div className="todo-toolbar">
                            <select
                              className="form-select form-select-md"
                              name="leadStatusGroup"
                              required
                              value={leadStatusGroup}
                              onChange={(e) => {
                                setleadStatusGroup(e.target.value);
                              }}
                            >
                              <option value="">Choose Status Group</option>
                              {loaderGroup && (
                                <option disabled>Loading Groups...</option>
                              )}
                              {!loaderGroup && (
                                <>
                                  {allStatusGroup?.map((item, index) => {
                                    return (
                                      <option value={item?._id}>
                                        {index + 1}. {item?.name}
                                      </option>
                                    );
                                  })}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* {!editLeadData && (
                    <div className="col-sm-3">
                      <label className="form-label">
                        Current Status {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md"
                            name="currentStatus"
                            required
                            disabled={leadStatusGroup === ""}
                            value={currentStatus}
                            onChange={(e) => {
                              setcurrentStatus(e.target.value);
                            }}
                          >
                            <option value="">Select Current Status</option>
                            {loaderSub && (
                              <option disabled>Loading Groups...</option>
                            )}
                            {!loaderSub && (
                              <>
                                {allSubGroup?.map((item, index) => {
                                  return (
                                    <option value={item?._id}>
                                      {index + 1}. {item?.name}
                                    </option>
                                  );
                                })}
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  )} */}

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">
                        Lead Source {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md"
                            name="leadSource"
                            required
                            value={leadSource}
                            onChange={(e) => {
                              setleadSource(e.target.value);
                            }}
                          >
                            <option value="">Select Source</option>
                            {allLeadSource?.map((data, index) => {
                              return (
                                <option value={data?.name}>
                                  {index + 1}. {data?.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">
                        Your Role / Occupation{" "}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="role"
                        placeholder="Enter Role / Occupation"
                        value={leadInfo.role}
                        onChange={handleLeadInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">Website link</label>
                      <input
                        className="form-control"
                        type="text"
                        name="websiteLink"
                        placeholder="Enter Link"
                        value={leadInfo.websiteLink}
                        onChange={handleLeadInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">Refer by</label>
                      <input
                        className="form-control"
                        type="text"
                        name="referBy"
                        placeholder="Enter refer name"
                        value={leadInfo.referBy}
                        onChange={handleLeadInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">
                        Business type {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md"
                            required
                            value={businessType}
                            onChange={(e) => {
                              setBusinessType(e.target.value);
                            }}
                          >
                            <option value="">Select Type</option>
                            {allBusinessType?.map((data) => {
                              return <option>{data}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-8">
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        type="text"
                        name="desc"
                        placeholder="Enter Description here"
                        value={leadInfo.desc}
                        onChange={handleLeadInfoChange}
                      />
                    </div>
                  </div>
                  <div className="row gx-3 px-3 w-100 col-sm-12 d-flex">
                    {allFields
                      ?.filter((field) => field?.placement === "basicInfo")
                      ?.map((field) => {
                        return (
                          <div className="col-sm-4" key={field?._id}>
                            <div className="form-group">
                              <label>
                                {field?.feildName}{" "}
                                {field?.isRequired && reuiredField}
                              </label>
                              <input
                                type={field?.feildType}
                                className="form-control"
                                name={field.feildName}
                                required={field?.isRequired ? true : false}
                                value={
                                  dynamicFields[field?.feildName]
                                    ? dynamicFields[field?.feildName]
                                        ?.fieldValue
                                    : ""
                                }
                                onChange={(e) => {
                                  handleDynamicFieldChange(e, field?.feildName);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>

                  {/* contact */}
                  <div
                    className="d-flex justify-content-start mt-3 mb-3 fs-5 text-dark"
                    style={{ backgroundColor: "#E3EDFF" }}
                  >
                    Contact Details
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Phone Number {reuiredField}
                      </label>
                      <div className="d-flex">
                        <div className="selectable-dropdown">
                          <div className="dropdown selectable-dropdown">
                            <span
                              className={
                                "me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-110p"
                              }
                              aria-expanded="false"
                              type="button"
                              data-bs-toggle="dropdown"
                              onClick={() => setcodeFilter("")}
                            >
                              {countryCode ? (
                                <>
                                  + {countryCode}
                                  {countryCode !== "" && (
                                    <button
                                      type="button"
                                      className="btn-close btn-sm ms-1"
                                      onClick={() => {
                                        setcountryCode("");
                                        setcodeFilter("");
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  )}
                                </>
                              ) : (
                                "Code"
                              )}
                            </span>
                            <div role="menu" className="dropdown-menu">
                              <form className="d-sm-block d-none" role="search">
                                <input
                                  type="text"
                                  placeholder="Search.."
                                  className="form-control"
                                  value={codeFilter}
                                  onChange={(e) =>
                                    setcodeFilter(e.target.value)
                                  }
                                />
                              </form>
                              <div
                                style={{
                                  maxHeight: "28vh",
                                  overflow: "auto",
                                  zIndex: 9999,
                                }}
                              >
                                {filteredCountries?.map((country) => (
                                  <div
                                    className="dropdown-item text-Capitalize"
                                    key={country.country}
                                    onClick={() => setcountryCode(country.code)}
                                  >
                                    <span>
                                      {" "}
                                      + {country?.code} &nbsp;{" "}
                                      {country?.country}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Enter Number"
                          name="phone"
                          maxLength="15"
                          required
                          disabled={!countryCode}
                          value={contactInfo?.phone}
                          onChange={handleContactInfoChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">Email Address</label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        placeholder="Enter Email Address"
                        value={contactInfo.email}
                        onChange={handleContactInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Address</label>
                      <input
                        className="form-control"
                        type="text"
                        name="address"
                        placeholder="Enter Address"
                        value={contactInfo.address}
                        onChange={handleContactInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">City</label>
                      <input
                        className="form-control"
                        type="text"
                        name="city"
                        placeholder="Enter City"
                        value={contactInfo.city}
                        onChange={handleContactInfoChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">State</label>
                      <input
                        className="form-control"
                        type="text"
                        name="state"
                        placeholder="Enter State"
                        value={contactInfo.state}
                        onChange={handleContactInfoChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">Pin code</label>
                      <input
                        className="form-control"
                        type="text"
                        name="pin"
                        placeholder="Enter Pin"
                        value={contactInfo.pin}
                        onChange={handleContactInfoChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gx-3 px-3 w-100 col-sm-12 d-flex">
                  {allFields
                    ?.filter((field) => field?.placement === "contactInfo")
                    ?.map((field) => {
                      return (
                        <div className="col-sm-4" key={field?._id}>
                          <div className="form-group">
                            <label>
                              {field?.feildName}{" "}
                              {field?.isRequired && reuiredField}
                            </label>
                            <input
                              type={field?.feildType}
                              className="form-control"
                              name={field.feildName}
                              required={field?.isRequired ? true : false}
                              value={
                                dynamicFields[field?._id]
                                  ? dynamicFields[field?._id]?.fieldValue
                                  : ""
                              }
                              onChange={(e) => {
                                handleDynamicFieldChange(e, field?.fieldName);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader}
                >
                  {loader ? <ApiLoader /> : editLeadData ? "Edit " : "Create "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLead;
