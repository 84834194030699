import React, { useState } from "react";
import ApiCall from "../../../api/ApiCall";
import { loginEndpoints } from "../../../api/Endpoints";
import { Link } from "react-router-dom";
import { startApiCall } from "../../../global-functions/globalFunctions";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { toast } from "react-toastify";

const LoginRight = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const inviteCode = queryParameters.get("inviteCode");
  const userId = queryParameters.get("userId");
  const token = queryParameters.get("token");
  const businessId = queryParameters.get("businessId");
  const roleId = queryParameters.get("role");

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const data = {
      emailOrphone: email,
      password,
    };
    const res = await ApiCall("post", loginEndpoints.userLogin, data);
    if (res?.success) {
      toast.success("Login Successfully");
      let refreshToken = res.data.tokens.refresh.token;
      localStorage.setItem("refreshTokenForTaskManager", refreshToken);
      if (inviteCode && userId && token && businessId && roleId) {
        window.location.href = `/workspace/invitations?token=${token}&businessId=${businessId}&userId=${userId}&inviteCode=${inviteCode}&role=${roleId}`;
      } else {
        window.location.href = "/home";
      }
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 position-relative mx-auto">
        <div className="auth-content py-md-0 py-8">
          <form className="w-100" onSubmit={handleLogin}>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <h4 className="mb-4 text-center">Sign in to your account</h4>
                <div className="row gx-3">
                  <div className="form-group col-lg-12">
                    <div className="form-label-group">
                      <label>Email Address</label>
                    </div>
                    <input
                      className="form-control"
                      placeholder="Enter your email"
                      type="email"
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                    />
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="form-label-group">
                      <label>Password</label>
                      <Link to="/reset" className="fs-7 fw-medium">
                        Forgot Password ?
                      </Link>
                    </div>
                    <div className="input-group password-check">
                      <span className="input-affix-wrapper">
                        <input
                          className="form-control"
                          placeholder="Enter your password"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          required
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        <div className="input-suffix text-muted">
                          <span
                            className="feather-icon"
                            onClick={handleShowPassword}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={
                                showPassword ? "ri-eye-line" : "ri-eye-off-line"
                              }
                            ></i>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <button
                  type="submit"
                  disabled={loader}
                  className="btn btn-primary btn-uppercase btn-block mt-3"
                >
                  {loader ? <ApiLoader /> : "Login"}
                </button>
                <p className="p-xs mt-2 text-center">
                  New to Bizhood ?{" "}
                  <Link to="/signup">
                    <u>Create new account</u>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginRight;
