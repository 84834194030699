import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OTPBox from "../../../components/OTPBox/OTPBox";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiCall from "../../../api/ApiCall";
import { signupEndpoints } from "../../../api/Endpoints";
import { startApiCall } from "../../../global-functions/globalFunctions";

const OtpVerifyForRegistration = (props) => {
  const {
    completeSecondStep,
    email,
    errorMessage,
    seterrorMessage,
    loader,
    setloader,
    otp,
    setotp,
    otpString,
    setOtpString,
    handleEmailVerify,
    loader2,
    resetStep,
  } = props;
  const [counter, setCounter] = useState(40);

  const handleOtpVerify = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const res = await ApiCall(
      "post",
      signupEndpoints.validateOtpForRegistration,
      {
        email,
        otp: otpString,
      }
    );
    if (res?.success) {
      setloader(false);
      completeSecondStep();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };
  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => setCounter((prevCounter) => prevCounter - 1), 1000);
    }

    return () => clearInterval(timer);
  }, [counter]);

  return (
    <>
      <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 position-relative mx-auto">
        <div className="auth-content py-md-0 py-8">
          <form className="w-100" onSubmit={handleOtpVerify}>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <OTPBox
                  otp={otp}
                  otpString={otpString}
                  errorMessage={errorMessage}
                  loader={loader}
                  handleOtpVerify={handleOtpVerify}
                  setOtpString={setOtpString}
                  setotp={setotp}
                  title="Verification"
                  description="Please enter the verification code that has been sent to your email address."
                  email={email}
                />
                {counter !== 0 && (
                <div className="d-flex justify-content-end ">
                  Resend otp in : {counter}
                </div>
                )}
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <button
                  type="submit"
                  className="btn btn-primary btn-uppercase btn-block mt-2"
                >
                  {loader ? <ApiLoader /> : "Verify"}
                </button>
                <button
                  onClick={handleEmailVerify}
                  disabled={counter}
                  className="btn btn-outline-primary btn-uppercase btn-block mt-2"
                >
                  {loader2 ? <ApiLoader /> : "Resend OTP"}
                </button>
                <p className="p-xs mt-2 text-center">
                  Back to{" "}
                  <Link onClick={resetStep}>
                    <u>Signup</u>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OtpVerifyForRegistration;
