import React from "react";
import { navigateToAllQuotes } from "../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

function QuotesDetailsHeader({ handleGetQuoteDetails }) {
  const navigate = useNavigate();
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center gap-1">
        <div
          className="pointer"
          onClick={() => navigate(navigateToAllQuotes())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>
        <span className="todoapp-title link-dark">
          <h1> Quote Details</h1>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="v-separator d-lg-inline-block d-none"></div>

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetQuoteDetails}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
}

export default QuotesDetailsHeader;
