import React from "react";
import {
  dateAndTimeFormatter,
  dateFormatter,
  getTimeFormatter,
} from "../../../../global-functions/globalFunctions";

function StatCard(props) {
  const {
    attendanceDetailsCount,
    attendanceDetails,
    attendanceOverview,
    today,
    startDate,
  } = props;
  const monthAndYear = new Date(startDate);

  const todayPercentage =
    (attendanceOverview?.dateHours?.totalHours /
      attendanceDetails?.staff?.totalHours) *
    100;

  const monthlyPercentage =
    (attendanceDetailsCount?.totalPresentDay /
      attendanceDetailsCount?.totalWorkingDays) *
    100;
  const leavePercentage =
    (attendanceDetailsCount?.leaveCount /
      attendanceDetailsCount?.totalWorkingDays) *
    100;

  return (
    <div className="row justify-content-center w-100 ms-1">
      <div className="col-md-7 ps-0">
        <div
          className="card rounded-4 pt-1 pb-2 px-4 d-flex flex-column gap-3"
          style={{
            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <p className=" fs-5 text-primary fw-medium">
            {" "}
            {monthAndYear.toLocaleDateString(undefined, {
              month: "long",
              year: "numeric",
            })}{" "}
            Overview
          </p>
          <div className="d-flex flex-column gap-2 h-220p">
            <div className="border rounded-4 py-2 px-3 d-flex flex-column gap-2">
              <div className="d-flex justify-content-between text-dark fw-medium fs-7">
                <p>Today</p>
                <p>
                  {attendanceOverview?.dateHours?.totalHours
                    ? attendanceOverview?.dateHours?.totalHours
                    : 0}
                  /{attendanceDetails?.staff?.totalHours}
                  Hours
                </p>
              </div>
              <div className="progress bg-light rounded-5">
                <div
                  className="progress-bar bg-primary"
                  role="progressbar"
                  style={{ width: `${todayPercentage}%` }}
                ></div>
              </div>
            </div>
            <div className="border rounded-4 py-2 px-3 d-flex flex-column gap-2">
              <div className="d-flex justify-content-between text-dark fw-medium fs-7">
                <p>Present this month</p>
                <p>
                  {attendanceDetailsCount?.totalPresentDay}/
                  {attendanceDetailsCount?.totalWorkingDays} Days
                </p>
              </div>
              <div className="progress bg-light rounded-5">
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: `${monthlyPercentage}%` }}
                ></div>
              </div>
            </div>
            <div className="border rounded-4 py-2 px-3 d-flex flex-column gap-2">
              <div className="d-flex justify-content-between text-dark fw-medium fs-7">
                <p>Leave this month</p>
                <p>
                  {attendanceDetailsCount?.leaveCount}/
                  {attendanceDetailsCount?.totalWorkingDays} Days
                </p>
              </div>
              <div className="progress bg-light rounded-5">
                <div
                  className="progress-bar bg-danger"
                  role="progressbar"
                  style={{ width: `${leavePercentage}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 px-1">
        <div
          className="card rounded-4 pt-1 pb-2 px-4 d-flex flex-column gap-1"
          style={{
            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <p className=" fs-5 text-primary fw-medium">
            {" "}
            {attendanceOverview?.dateDetails
              ? dateFormatter(
                  attendanceOverview?.dateDetails?.presentStatus
                    ? attendanceOverview?.dateDetails?.date
                    : attendanceOverview?.dateDetails?.dates
                )
              : dateFormatter(today)}{" "}
            Activity
          </p>
          {attendanceOverview?.dateDetails?.sessions?.length > 0 && (
            <div className="nicescroll-bar d-flex flex-column simple-scrollbar px-0 py-1 h-225p">
              {attendanceOverview?.dateDetails?.sessions?.map((activity) => {
                return (
                  <>
                    {activity?.loginTime && (
                      <div className="d-flex gap-2 fs-7">
                        <div>
                          <p className="text-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            </svg>
                          </p>
                          <span className="border-start ms-2 fs-3"></span>
                        </div>
                        <div className="text-dark">
                          <p className="fw-medium">Punch in at</p>
                          <p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-clock"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                            </svg>
                            &nbsp;{" "}
                            {dateAndTimeFormatter(activity?.loginTime)
                              ?.split(",")[1]
                              ?.trim()}
                          </p>
                        </div>
                      </div>
                    )}
                    {activity?.logoutTime && (
                      <div className="d-flex gap-2 fs-7">
                        <div>
                          <p className="text-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            </svg>
                          </p>
                          <span className="border-start ms-2 fs-3"></span>
                        </div>
                        <div className="text-dark">
                          <p className="fw-medium">Punch out at</p>
                          <p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-clock"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                            </svg>
                            &nbsp;
                            {dateAndTimeFormatter(activity?.logoutTime)
                              ?.split(",")[1]
                              ?.trim()}
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
          {!attendanceOverview?.dateDetails?.sessions?.length && (
            <div className="nicescroll-bar d-flex flex-column simple-scrollbar px-0 py-1 h-225p align-items-center ">
              <strong className="mt-2 fs-4">No Sessions</strong>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StatCard;
