import React, { useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { businessEndpoints } from "../../api/Endpoints";
import {
  closeAllModals,
  startApiCall,
} from "../../global-functions/globalFunctions";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import WorkspaceHeader from "./WorkspaceHeader";
import WorkspaceDrawer from "./WorkspaceDrawer";
import WorkspaceTableView from "./WorkspaceTableView";
import AddWorkspace from "./AddWorkspace";
import { useSelector } from "react-redux";
import NoData from "../../components/NoData/NoData";
import FooterPagination from "../../components/Pagination/FooterPagination";

const Workspace = () => {
  //  ***----GET USERID FROM REDUX----***
  const user = useSelector((state) => state.user);

  //  ***----WORKSPACE OTHER STATES----***
  const [allBusiness, setAllBusiness] = useState(null);
  const [loader, setLoader] = useState(true);
  const [errorMessage, seterrorMessage] = useState("");
  const [businessCount, setbusinessCount] = useState(0);
  const [searchKey, setsearchKey] = useState("");
  const [filterStatus, setfilterStatus] = useState("");
  const [workspaceCounts, setworkspaceCounts] = useState({});
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);

  //  ***----WORKSPACE SIDEDRAWER OPEN----***
  const [isOpenSideDrawer, setisOpenSideDrawer] = useState(false);

  //  ***----WORKSPACE GET ALL WORKSPACESS----***
  const handleGetAllBusiness = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      pageLimit: pageLimit,
      pageNumber: page,
      startDate: "",
      endDate: "",
      searchKey: searchKey,
      status: filterStatus,
      employeeId: user?._id,
      // sortType: "createdAt",
    };
    const res = await ApiCall("post", businessEndpoints.getBusiness, data);
    const counts = await ApiCall("post", businessEndpoints.workspaceCount, {
      employeeId: user?._id,
    });

    if (res?.success) {
      setworkspaceCounts(counts?.count);
      setAllBusiness(res?.business?.business);
      setbusinessCount(res?.business?.counts?.totalCount);
      settotalPages(res?.business?.counts?.totalPages);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllBusiness();
    closeAllModals();
  }, [searchKey, filterStatus, page, pageLimit]);

  //  ***----WORKSPACE SEARCH SET----***
  const handleSearchSet = (e) => {
    setsearchKey(e.target.value);
    setPage(1);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <WorkspaceDrawer
          isOpenSideDrawer={isOpenSideDrawer}
          setfilterStatus={setfilterStatus}
          filterStatus={filterStatus}
          workspaceCounts={workspaceCounts}
        />
        <div className="todoapp-detail-wrap">
          <WorkspaceHeader
            isOpenSideDrawer={isOpenSideDrawer}
            setisOpenSideDrawer={setisOpenSideDrawer}
            handleGetAllBusiness={handleGetAllBusiness}
            handleSearchSet={handleSearchSet}
            searchKey={searchKey}
            filterStatus={filterStatus}
            businessCount={businessCount}
            setPage={setPage}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && allBusiness?.length > 0 && (
                <WorkspaceTableView
                  allBusiness={allBusiness}
                  page={page}
                  pageLimit={pageLimit}
                />
              )}
              {!loader && allBusiness?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={businessCount}
                  dataLength={allBusiness?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allBusiness?.length === 0 && (
                <NoData title={`No ${filterStatus} Workspaces`} />
              )}
            </div>
          </div>
        </div>
        <AddWorkspace handleGetAllBusiness={handleGetAllBusiness} />
      </div>
    </div>
  );
};

export default Workspace;
