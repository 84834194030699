import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
  closeAllModals,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { activityGroupRelatedEndpoints } from "../../../api/Endpoints";
import ActivityGroupHeader from "./ActivityGroupHeader";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import NoData from "../../../components/NoData/NoData";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import ActivityGroupTable from "./ActivityGroupTable";
import AddActivityGroup from "./AddActivityGroup";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import { useSelector } from "react-redux";

const ActiviyGroups = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [editAllGroups, seteditAllGroups] = useState("");
  const [selectType, setSelectType] = useState("");
  const [search, setSearch] = useState("");

  const isModuleReadWriteAccessForActivityGroup =
    allModuleAccess?.[PRIVATEROUTES.ACTIVITY_GROUP];

  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllGroup = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        activityType: selectType,
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivityGroup,
      data
    );
    if (res?.success) {
      setAllGroups(res?.group?.data);
      setcount(res?.group?.totalCounts);
      settotalPages(res?.group?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllGroup();
      closeAllModals();
    }
  }, [page, pageLimit, search, selectType]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ActivityGroupHeader
            handleGetAllGroup={handleGetAllGroup}
            selectType={selectType}
            setSelectType={setSelectType}
            search={search}
            handleSearch={handleSearch}
            isModuleReadWriteAccessForActivityGroup={
              isModuleReadWriteAccessForActivityGroup
            }
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && allGroups?.length > 0 && (
                <ActivityGroupTable
                  allGroups={allGroups}
                  page={page}
                  pageLimit={pageLimit}
                  seteditAllGroups={seteditAllGroups}
                  isModuleReadWriteAccessForActivityGroup={
                    isModuleReadWriteAccessForActivityGroup
                  }
                />
              )}
              {!loader && allGroups?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allGroups?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allGroups?.length === 0 && (
                <NoData title="No groups added" />
              )}
            </div>
          </div>
        </div>
        <AddActivityGroup
          handleGetAllGroup={handleGetAllGroup}
          editAllGroups={editAllGroups}
          seteditAllGroups={seteditAllGroups}
        />
      </div>
    </div>
  );
};

export default ActiviyGroups;
