import React from "react";
import { useNavigate } from "react-router-dom";
import {
  navigateToAddQuote,
  navigateToGenerateInvoice,
  navigateToQuotesDetails,
} from "../../../Navigation/Navigation";
import { dateFormatter } from "../../../global-functions/globalFunctions";

function QuotesTable(props) {
  const navigate = useNavigate();
  const {
    allQuotesData,
    page,
    pageLimit,
    handleDeleteQuotes,
    isModuleReadWriteAccessQuotes,
  } = props;
  return (
    <div className="contact-list-view">
      <table id="datable_1" className="table nowrap w-100 mb-8">
        <thead>
          <tr className="text-center">
            <th>No</th>
            <th>Quote Number</th>
            <th>Customer Name</th>
            <th>Email Id</th>
            <th>Date</th>
            <th>Due Date</th>
            <th>Status</th>
            <th>Amount</th>
            {isModuleReadWriteAccessQuotes && <th>Action</th>}
          </tr>
        </thead>

        <tbody>
          {allQuotesData.map((quote, index) => {
            return (
              <tr className="text-center">
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td>{quote?.quoteNumber}</td>
                <td
                  onClick={() => {
                    navigate(navigateToQuotesDetails({ id: quote?._id }));
                  }}
                  className="pointer text-capitalize"
                >
                  {quote?.customerId?.name}
                </td>
                <td
                  className="pointer text-capitalize"
                  onClick={() => {
                    navigate(navigateToQuotesDetails({ id: quote?._id }));
                  }}
                >
                  {quote?.customerId?.email ? quote?.customerId?.email : "-"}
                </td>
                <td>{dateFormatter(quote?.quoteDate)}</td>
                <td>{dateFormatter(quote?.expiryDate)}</td>
                <td>Sent</td>
                <td>₹ {quote?.totalAmount}</td>
                {isModuleReadWriteAccessQuotes && (
                  <td>
                    {" "}
                    <span className="feather-icon pointer">
                      <i
                        data-bs-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="ri-more-fill"
                      ></i>
                      <div className="dropdown-menu">
                        <div
                          className="dropdown-item d-flex align-items-center"
                          onClick={() => {
                            navigate(navigateToAddQuote(), {
                              state: { id: quote?._id },
                            });
                          }}
                        >
                          <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="20"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </span>
                          </span>
                          <p>Edit</p>
                        </div>
                        <div
                          className="dropdown-item d-flex align-items-center"
                          onClick={() => {
                            navigate(navigateToGenerateInvoice(), {
                              state: {
                                Type: "Convert",
                                id: quote?._id,
                              },
                            });
                          }}
                        >
                          <span
                            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-receipt"
                                viewBox="0 0 16 16"
                              >
                                <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z" />
                                <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" />
                              </svg>
                            </span>
                          </span>
                          <p>Convert to Invoice</p>
                        </div>
                        <div
                          className="dropdown-item d-flex align-items-center"
                          onClick={() => {
                            handleDeleteQuotes(quote?._id);
                          }}
                        >
                          <span
                            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                              </svg>
                            </span>
                          </span>
                          <p>Delete</p>
                        </div>
                      </div>
                    </span>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default QuotesTable;
