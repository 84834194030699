import React, { useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { taskEndpoint } from "../../api/Endpoints";
import { useSelector } from "react-redux";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { toast } from "react-toastify";
import ApiLoader from "../Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../Error/ErrorMessage";

function ChangeDuedate(props) {
  const {
    seteditDuedDate,
    editDueDate,
    taskId,
    taskType,
    handleGetTaskDetails,
  } = props;
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const user = useSelector((state) => state.user);
  const [dueDate, setdueDate] = useState("");
  var date = reversesDateFormatter(new Date());

  const handleReset = () => {
    seteditDuedDate(false);
    setloader(false);
    setErrorMessage("");
  }

  const handleEditTask = async () => {
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      taskId: editDueDate ? taskId?._id : "",
      userId: user?._id,
      dueDate: dueDate ? dueDate : "",
    };
    const res = await ApiCall("post", taskEndpoint.editTask, sendData);
    if (res?.success) {
      toast.success("Edit Task Successfully");
      handleGetTaskDetails();
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  const handleRemoveDueDate = async () => {
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      taskId: taskId?._id,
      actionBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      taskType: taskType,
    };
    const res = await ApiCall("post", taskEndpoint.removeDuedate, sendData);
    if (res?.success) {
      toast.success("Edit Task Successfully");
      handleGetTaskDetails();
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  useEffect(() => {
    if (editDueDate && taskId?.dueDate) {
      setdueDate(reversesDateFormatter(taskId?.dueDate));
    }
  }, [editDueDate]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: 350 }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-4">
              <span>Change Due Date</span>
            </div>
            <div className="row gx-3">
              <div className="col-sm-9">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="date"
                    value={dueDate}
                    onChange={(e) => {
                      setdueDate(e.target.value);
                    }}
                    min={date}
                  />
                </div>
              </div>
              <div className="col-sm-3 mt-1">
                <span
                  className="btn btn-xs btn-flush-danger flush-soft-hover"
                  onClick={() => {
                    setdueDate("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <div className="modal-footer align-items-center">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleReset}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                if (dueDate === "") {
                  handleRemoveDueDate();
                } else {
                  handleEditTask();
                }
              }}
            >
              {loader ? <ApiLoader /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeDuedate;
