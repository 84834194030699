import React, { useEffect, useState } from "react";
import VendorDetailsHeader from "./VendorDetailsHeader";
import VendorTrscnDetails from "./VendorTrscnDetails";
import VendorDetailsInfo from "./VendorDetailsInfo";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import AddAccountDetails from "./AddAccountDetails/AddAccountDetails";
import AddVendor from "../AddVendor/AddVendor";
import { useNavigate, useParams } from "react-router-dom";
import { VendorRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { navigateToAllVendors } from "../../../Navigation/Navigation";
import VendorPayment from "./VendorPayment";
import PaymentSuccess from "./PaymentSuccess";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import BIllImagesModal from "../../InventoryManagement/RawMaterial/StockDetails/BIllImagesModal";

const VendorDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [loader4, setloader4] = useState(false);
  const [loader5, setloader5] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [vendorDetails, setVendorDetails] = useState([]);
  const [editVendor, setEditVendor] = useState("");
  const [vendorParentTransaction, setvendorParentTransaction] = useState([]);
  const [vendorSubTransaction, setvendorSubTransaction] = useState([]);
  const [vendorAmounts, setvendorAmounts] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [editAccountDetails, seteditAccountDetails] = useState("");
  const [iamgesData, setiamgesData] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [vendorPaymentModal, setVendorPaymentModal] = useState(false);
  const [successPayment, setSuccessPayment] = useState(false);
  const [addPaymentRes, setaddPaymentRes] = useState(null);
  const [selectTab, setSelectTab] = useState("Profile");
  const tabs = ["Profile", "Transaction history"];

  const handleTabChange = (mode) => {
    setSelectTab(mode);
  };

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  const handleGetVendorDetails = async () => {
    startApiCall(null, setloader2);
    const data = {
      vendorId: id,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorById,
      data
    );
    if (res?.success) {
      setVendorDetails(res?.vendor);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };
  const handleGetVendorAmounts = async (e) => {
    startApiCall(null, setloader5);
    const data = {
      businessId: getCurrentWorkspaceId(),
      userId: id,
      refKey: "vendor",
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorTransactionAmount,
      data
    );
    if (res?.success) {
      setvendorAmounts(res?.data);
      setloader5(false);
    } else {
      setloader5(false);
    }
  };
  const handleGetVendortransaction = async (e) => {
    e && e.preventDefault();
    startApiCall(null, setloader3);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        creditTo: id,
        debitFrom: id,
        isParentTransaction: true,
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : "",
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorTransaction,
      data
    );
    if (res?.success) {
      setvendorParentTransaction(res?.transactions?.data);
      settotalPages(res?.transactions?.totalPages);
      setcount(res?.transactions?.totalCounts);
      setloader3(false);
    } else {
      setloader3(false);
    }
  };
  const handleGetVendorSubtransaction = async (subId) => {
    setErrorMessage("");
    setloader4((prevLoading) => ({
      ...prevLoading,
      [subId]: true,
    }));
    setTimeout(() => {
      setloader4((prevLoading) => ({
        ...prevLoading,
        [subId]: false,
      }));
    }, 50000);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        creditTo: id,
        debitFrom: id,
        isParentTransaction: false,
        parentTransaction: subId,
      },
      page: 1,
      limit: 500,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorTransaction,
      data
    );
    if (res?.success) {
      setvendorSubTransaction((prev) => ({
        ...prev,
        [subId]: res?.transactions?.data,
      }));
      setloader4((prevLoading) => ({
        ...prevLoading,
        [subId]: false,
      }));
    } else {
      setloader4((prevLoading) => ({
        ...prevLoading,
        [subId]: false,
      }));
      setErrorMessage(res.error);
    }
  };

  const handleDeleteVendor = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      vendorId: id,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.deleteVendor,
      data
    );
    if (res?.success) {
      handleClose();
      navigate(navigateToAllVendors());
      toast.success("Vendor deleted successfully!");
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId) {
      handleGetVendorDetails();
      handleGetVendorAmounts();
    }
  }, []);

  useEffect(() => {
    if (getCurrentWorkspaceId && selectTab === "Transaction history") {
      handleGetVendortransaction();
    }
  }, [page, pageLimit, selectTab, startDate, endDate]);

  const handleRefresh = () => {
    handleGetVendorDetails();
    handleGetVendortransaction();
    handleGetVendorAmounts();
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <VendorDetailsHeader
            handleRefresh={handleRefresh}
            selectTab={selectTab}
            startDate={startDate}
            setstartDate={setstartDate}
            endDate={endDate}
            setendDate={setendDate}
            setPage={setPage}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <div className="d-flex justify-content-center mb-3">
                  <div className="tabs px-2 py-2">
                    {tabs?.map((name) => {
                      return (
                        <span
                          onClick={() => {
                            handleTabChange(name);
                          }}
                          className={`${
                            selectTab === name ? "select" : ""
                          } pointer`}
                          key={name}
                        >
                          {name}
                        </span>
                      );
                    })}
                  </div>
                </div>

                <>
                  {selectTab === "Profile" && (
                    <VendorDetailsInfo
                      setIsOpen={setIsOpen}
                      vendorDetails={vendorDetails}
                      setEditVendor={setEditVendor}
                      loader5={loader5}
                      loader2={loader2}
                      id={id}
                      handleGetVendorDetails={handleGetVendorDetails}
                      selectTab={selectTab}
                      seteditAccountDetails={seteditAccountDetails}
                    />
                  )}
                  {selectTab === "Transaction history" && (
                    <>
                      <VendorTrscnDetails
                        vendorParentTransaction={vendorParentTransaction}
                        page={page}
                        pageLimit={pageLimit}
                        handleGetVendorSubtransaction={
                          handleGetVendorSubtransaction
                        }
                        loader5={loader5}
                        loader4={loader4}
                        loader3={loader3}
                        loader2={loader2}
                        vendorSubTransaction={vendorSubTransaction}
                        setVendorPaymentModal={setVendorPaymentModal}
                        vendorAmounts={vendorAmounts}
                        setiamgesData={setiamgesData}
                      />
                      {!loader3 && vendorParentTransaction?.length > 0 && (
                        <FooterPagination
                          setpageLimit={setpageLimit}
                          pageLimit={pageLimit}
                          setPage={setPage}
                          page={page}
                          totalPages={totalPages}
                          count={count}
                          dataLength={vendorParentTransaction?.length}
                          align={"end"}
                        />
                      )}
                    </>
                  )}
                </>
              </div>
              <ConfirmModal
                isOpen={isOpen}
                onClose={handleClose}
                text="Do you want to delete the Vendor"
                loader={loader}
                errorMessage={errorMessage}
                handleConfirm={handleDeleteVendor}
              />
            </div>
          </div>
        </div>
        <AddVendor
          handleGetAllVendor={handleGetVendorDetails}
          setEditVendor={setEditVendor}
          editVendor={editVendor}
        />
        {vendorPaymentModal && (
          <VendorPayment
            setVendorPaymentModal={setVendorPaymentModal}
            vendorPaymentModal={vendorPaymentModal}
            vendorDetails={vendorDetails}
            setSuccessPayment={setSuccessPayment}
            id={id}
            setaddPaymentRes={setaddPaymentRes}
            handleGetVendorSubtransaction={handleGetVendorSubtransaction}
            handleGetVendortransaction={handleGetVendortransaction}
            handleGetVendorAmounts={handleGetVendorAmounts}
          />
        )}
        <AddAccountDetails
          id={id}
          handleGetVendorDetails={handleGetVendorDetails}
          vendorDetails={vendorDetails}
          editAccountDetails={editAccountDetails}
          seteditAccountDetails={seteditAccountDetails}
        />
        {successPayment && (
          <PaymentSuccess
            setSuccessPayment={setSuccessPayment}
            addPaymentRes={addPaymentRes}
          />
        )}
        <BIllImagesModal editStock={iamgesData} setEditStock={setiamgesData} />
      </div>
    </div>
  );
};

export default VendorDetails;
