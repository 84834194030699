import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { startApiCall } from "../../../global-functions/globalFunctions";
import { signupEndpoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { redirectToPrivacyPolicy } from "../../../Navigation/Navigation";
import constants from "../../../components/Constant/Constants";
import { toast } from "react-toastify";

const AddPersonalDetails = (props) => {
  const {
    firstName,
    setfirstName,
    lastName,
    setlastName,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    errorMessage,
    seterrorMessage,
    loader,
    navigate,
    setloader,
    email,
    resetStep,
  } = props;

  const [isShowPassword, setisShowPassword] = useState(false);
  const [isChecked, setisChecked] = useState(true);

  const handleShowPassword = () => {
    setisShowPassword(!isShowPassword);
  };

  const handleAddPersonalDetails = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      seterrorMessage("Password and confirm password must be same.");
    } else {
      startApiCall(seterrorMessage, setloader);
      const sendData = {
        name: `${firstName} ${lastName}`,
        password: password,
        email: email,
      };
      const res = await ApiCall(
        "post",
        signupEndpoints.addDetailsForRegistration,
        sendData
      );
      if (res?.success) {
        toast.success("Signup Successfully");
        setloader(false);
        navigate("/login");
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };

  return (
    <>
      <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 position-relative mx-auto">
        <div className="auth-content py-5">
          <form
            className="w-100"
            onSubmit={handleAddPersonalDetails}
            autoComplete="off"
          >
            <h2 className="text-center mb-4">Create Your Account</h2>
            <p className="text-center mb-4">
              Let's start by entering your information for{" "}
              <span className="text-teal">{email}</span>
            </p>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      className="form-control"
                      placeholder="Enter your first name"
                      type="text"
                      value={firstName}
                      autocomplete="off"
                      onChange={(e) => {
                        setfirstName(e.target.value);
                      }}
                      required
                      maxLength={constants?.FIRST_NAME_MAX_LENGTH}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      className="form-control"
                      placeholder="Enter your last name"
                      type="text"
                      autocomplete="off"
                      value={lastName}
                      onChange={(e) => {
                        setlastName(e.target.value);
                      }}
                      required
                      maxLength={constants?.LAST_NAME_MAX_LENGTH}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <div className="input-group password-check">
                    <span className="input-affix-wrapper">
                      <input
                        className="form-control"
                        placeholder="Enter Password"
                        type={isShowPassword ? "text" : "password"}
                        required
                        pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                        title="Password must be minimum eight characters, at least one letter, one number and one special character."
                        value={password}
                        autocomplete="off"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <div className="input-suffix text-muted">
                        <span
                          className="feather-icon"
                          onClick={handleShowPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <i
                            className={
                              isShowPassword ? "ri-eye-line" : "ri-eye-off-line"
                            }
                          ></i>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className="input-group password-check">
                    <span className="input-affix-wrapper">
                      <input
                        className="form-control"
                        placeholder="Confirm Password"
                        type="password"
                        required
                        autocomplete="off"
                        pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                        title="Password must be minimum eight characters, at least one letter, one number and one special character."
                        value={confirmPassword}
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                      />
                    </span>
                  </div>
                </div>

                <div className="form-check  mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="logged_in"
                    checked={isChecked}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setisChecked(!isChecked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="agreeTerms">
                    I have read and agree to the{" "}
                    <Link onClick={() => navigate(redirectToPrivacyPolicy())}>
                      Privacy Policy
                    </Link>
                    .
                  </label>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <button
                  type="submit"
                  disabled={!isChecked}
                  className="btn btn-primary btn-rounded btn-uppercase btn-block"
                >
                  {loader ? <ApiLoader /> : "Create Account"}
                </button>
                <p className="p-xs mt-2 text-center">
                  Already a member ?{" "}
                  <Link onClick={resetStep} to="/login">
                    Log In
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddPersonalDetails;
