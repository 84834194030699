import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { ItemRelatedEndPoints } from "../../../../api/Endpoints";

function AddItemInAddCredit(props) {
  const { setitems, invoiceItems, setinvoiceItems } = props;
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [unit, setUnit] = useState("");
  const [itemData, setitemData] = useState([]);
  const [loader, setloader] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [discount, setdiscount] = useState(0);
  const [quantity, setquantity] = useState("");
  const [total, settotal] = useState(0);
  // console.log("invoiceItems: ", invoiceItems);

  const handleReset = () => {
    setloader(false);
    setItemName("");
    setItemCode("");
    setUnit("");
    setSalePrice("");
    setdiscount(0);
    setquantity("");
    setitemData("");
    settotal(0);
    const closeModalDom = document.getElementById("#add_creditnote_item");
    if (closeModalDom) {
      closeModalDom.click();
    }
  };

  const handleGetAllItems = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", ItemRelatedEndPoints.getItem, data);
    if (res?.success) {
      setAllItems(res?.item?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllItems();
    }
  }, []);

  useEffect(() => {
    if (itemData) {
      setItemCode(itemData?.itemCode);
      setSalePrice(itemData?.salePrice);
      setUnit(itemData?.unit);
      settotal(
        itemData?.salePrice * quantity -
          ((itemData?.salePrice * quantity) / 100) * discount || 0
      );
    }
  }, [itemData, quantity, discount]);

  const handleItemSelection = (selectedValue) => {
    const selectedItem = allItems?.find((item) => item?._id === selectedValue);

    if (selectedItem) {
      setitemData(selectedItem);
    }
  };
  const handleAddItem = () => {
    const newItem = {
      itemId: {
        itemName: itemData?.itemName,
        itemCode,
        salePrice,
        unit,
        discount,
      },
      quantity,
      total,
    };
    const item = {
      itemId: itemName,
      quantity: quantity,
      discount: discount,
      total: total,
    };

    setinvoiceItems((prevItems) => [...prevItems, newItem]);
    setitems((prevItems) => [...prevItems, item]);

    handleReset();
  };

  return (
    <div
      id="add_creditnote_item"
      className="modal fade add-new-task "
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "50%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Add Item</h5>
            <div className="border-top mb-3"></div>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div class="form-group row w-100 mb-0">
                    <div className="col-sm-4">
                      <label class="form-label">Item name</label>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            required
                            name="itemName"
                            className="form-select form-select-md"
                            value={itemName}
                            onChange={(e) => {
                              setItemName(e.target.value);
                              handleItemSelection(e.target.value);
                            }}
                          >
                            <option value="">Select Item</option>
                            {allItems?.map((item) => {
                              const isDisabled = invoiceItems?.some((data) =>
                                data?.itemId?._id?.includes(item?._id)
                              );
                              return (
                                <option
                                  key={item?._id}
                                  value={item?._id}
                                  disabled={isDisabled}
                                >
                                  {item?.itemName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Item code</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control text-dark"
                        type="text"
                        value={itemCode}
                        disabled
                        onChange={(e) => {
                          setItemCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Selling price</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control text-dark"
                        type="number"
                        disabled
                        value={salePrice}
                        onChange={(e) => {
                          setSalePrice(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Unit</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control text-dark"
                        type="text"
                        disabled
                        value={unit}
                        onChange={(e) => {
                          setUnit(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Quantity</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control"
                        type="text"
                        value={quantity}
                        onChange={(e) => {
                          setquantity(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Discount %</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control"
                        type="text"
                        value={discount}
                        onChange={(e) => {
                          setdiscount(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Total</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control"
                        type="text"
                        value={total}
                        onChange={(e) => {
                          settotal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end gap-3">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                >
                  Discard
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleAddItem}
                  disabled={
                    !itemName || !salePrice || !itemCode || !unit || !quantity
                  }
                >
                  add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItemInAddCredit;
