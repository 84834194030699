import React, { useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";

const StockConfirmModal = (props) => {
  const {
    setConfirmStock,
    handleAddStock,
    handleRemoveStock,
    quantity,
    selectType,
    selectItem,
    loader,
    errorMessage,
    setQuantity,
  } = props;

  const handleReset = () => {
    setConfirmStock(false);
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered mw-600p"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-2"
              onClick={() => setConfirmStock(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">{`Confirm Stock ${
              selectType === "in" ? "In" : "Out"
            } For ${selectItem}`}</h5>
            <div className="border-bottom mb-3 p-0"></div>
            <form
              onSubmit={(e) => {
                if (selectType === "in") {
                  handleAddStock(e);
                } else {
                  handleRemoveStock(e);
                }
              }}
            >
              <div className="row gx-3 px-2">
                <div className="d-flex gap-2 justify-content-center">
                  <span className="text-dark text-capitalize mt-2">
                    Are you sure you want to confirm with quantity
                  </span>
                  <input
                    className="form-control w-150p"
                    type="number"
                    required
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  {/* <span
                    className="text-primary text-decoration-underline pointer ms-2"
                    // onClick={handleReset}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    Change
                  </span> */}
                </div>
                {/* <div className="d-flex justify-content-center">
                  <div
                    className="alert alert-info text-primary fs-7"
                    role="alert"
                  >
                    The Selected Quantity for{" "}
                    {selectType === "in" ? "Stock In" : "Stock Out"} :
                    <strong> {quantity} </strong>
                    You can modify this value if needed.
                  </div>
                </div> */}
                {/* <div className="d-flex justify-content-end">
                  {changeValue && (
                    <div className="d-flex justify-content-center">
                      <div className="">
                        <label className="form-label">Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          required
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  <span
                    className="text-primary text-decoration-underline pointer"
                    onClick={() => setChangeValue(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                    Change
                  </span>
                </div> */}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setConfirmStock(false)}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : "Confirm"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockConfirmModal;
