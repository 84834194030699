import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import WorkingDuration from "./WorkingDuration";
import AttendanceMethod from "./AttendanceMethod";
import SalaryMethod from "./SalaryMethod";
import { attendanceRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import { useSelector } from "react-redux";

function OnboardingAllSteps(props) {
  const {
    employeeData,
    setemployeeData,
    handleGetApprovedEmployee,
    modalType,
    setmodalType,
  } = props;
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const checkIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-check2"
      viewBox="0 0 16 16"
    >
      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
    </svg>
  );

  //WORKINGDAYS
  const [setUp, setSetUp] = useState(true);
  const [selectedDays, setSelectedDays] = useState([]);
  const [workingDaysType, setworkingDaysType] = useState("");
  const [workingType, setworkingType] = useState("dailyWages");
  const [selectedOption, setSelectedOption] = useState("");
  const [settlementDate, setsettlementDate] = useState("1");
  const [presentShift, setpresentShift] = useState([]);
  const [selectedShift, setselectedShift] = useState("");
  const completeFirstStep =
    selectedOption &&
    selectedShift &&
    (selectedOption === "monthly" ? !!workingDaysType : true);

  //ATTENDANCE SETUP
  const [setUp2, setSetUp2] = useState(false);
  const [selfgpsOptions, setselfGpsOptions] = useState({
    gpsTracking: false,
    liveSelfie: false,
    gpsLocking: false,
  });
  const [autogpsOptions, setautoGpsOptions] = useState({
    gpsTracking: false,
    liveSelfie: false,
    gpsLocking: false,
  });
  const [selectedAttendanceOption, setSelectedAttendanceOption] = useState("");
  const [selectedLocation, setselectedLocation] = useState("");
  const [checkInDistance, setcheckInDistance] = useState("");
  const completeSecondStep =
    completeFirstStep && selectedAttendanceOption !== "";
  //SALARY METHOD
  const [setUp3, setSetUp3] = useState(false);
  const [salary, setsalary] = useState("");
  const [advance, setadvance] = useState("");
  const [deposit, setdeposit] = useState("");
  const completeThirdStep =
    completeFirstStep && completeSecondStep && salary ? true : false;

  const selectSetup = () => {
    setSetUp(true);
    setSetUp2(false);
    setSetUp3(false);
  };
  const selectSetup2 = () => {
    setSetUp(false);
    setSetUp2(true);
    setSetUp3(false);
  };
  const selectSetup3 = () => {
    setSetUp(false);
    setSetUp2(false);
    setSetUp3(true);
  };

  // const returnMainDate = async (timeString) => {
  //   const currentDate = new Date();
  //   const tomorrowDate = new Date(
  //     new Date(currentDate).setDate(currentDate.getDate() + 1)
  //   );
  //   const [hours, minutes] = timeString.split(":");
  //   currentDate.setHours(hours, minutes);
  //   tomorrowDate.setHours(hours, minutes);
  //   return currentDate;
  // };
  // const returnNextDate = async (timeString) => {
  //   const currentDate = new Date();
  //   const tomorrowDate = new Date(
  //     new Date(currentDate).setDate(currentDate.getDate() + 1)
  //   );
  //   const [hours, minutes] = timeString.split(":");
  //   tomorrowDate.setHours(hours, minutes);
  //   return tomorrowDate;
  // };

  // const returnCalculatedMinutes = async () => {
  //   const loginTime = await returnMainDate(startTime);
  //   const logoutTime =
  //     shiftDay === "sameday"
  //       ? await returnMainDate(endTime)
  //       : await returnNextDate(endTime);
  //   const duration = moment.duration(logoutTime - loginTime);
  //   const sessionMinutes = duration.asMinutes().toFixed(2);
  //   const sessionHours = duration.asHours().toFixed(2);
  //   setSelectedHours(sessionHours);
  //   setminutes(sessionMinutes);
  //   return sessionMinutes;
  // };

  const handleReset = () => {
    setErrorMessage("");
    setworkingDaysType("");
    setSelectedDays([]);
    setloader(false);
    setsettlementDate("1");
    setSelectedOption("");
    setSelectedAttendanceOption("");
    setemployeeData("");
    setErrorMessage("");
    setsalary("");
    setadvance("");
    setdeposit("");
    selectSetup();
    setselfGpsOptions({
      gpsTracking: false,
      liveSelfie: false,
      gpsLocking: false,
    });
    setautoGpsOptions({
      gpsTracking: false,
      liveSelfie: false,
      gpsLocking: false,
    });
    setmodalType("");
    setselectedLocation("");
    setcheckInDistance("");
    const closeModalDom = document.getElementById("attendance_modal");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddShift = async () => {
    startApiCall(null, setloader);
    const sendData = {
      shiftId: selectedShift,
      employeeId: employeeData?.employeeId?._id,
      businessId: getCurrentWorkspaceId(),
      paymentType: selectedOption,
      attendanceMethod: selectedAttendanceOption,
      settlementType:
        selectedOption === "monthly"
          ? workingDaysType
          : selectedOption === "payPerHour"
          ? workingType
          : "",
      settlementDate: selectedOption === "monthly" ? settlementDate : "",
      salary: salary,
      offDays: selectedDays,
      isGpsTracking:
        selectedAttendanceOption === "selfAttendance"
          ? selfgpsOptions.gpsTracking
          : selectedAttendanceOption === "autoMarking"
          ? autogpsOptions.gpsTracking
          : false,
      isLiveSelfie:
        selectedAttendanceOption === "selfAttendance"
          ? selfgpsOptions.liveSelfie
          : selectedAttendanceOption === "autoMarking"
          ? autogpsOptions.liveSelfie
          : false,
      isGpsLocking:
        selectedAttendanceOption === "selfAttendance"
          ? selfgpsOptions.gpsLocking
          : selectedAttendanceOption === "autoMarking"
          ? autogpsOptions.gpsLocking
          : false,
      shiftType: "present",
      location: selectedLocation,
      allowedLoginDistance: checkInDistance,
      isStrictGpsLocking: true,
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.addStaff,
      sendData
    );
    if (res?.success) {
      setloader(false);
      handleGetApprovedEmployee();
      toast.success("Added Staff successfully!");
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };
  const handleEditShift = async () => {
    startApiCall(null, setloader);
    const sendData = {
      staffId: employeeData?.staff?._id,
      // shiftTiming: {
      //   startTime: "",
      //   endTime: "",
      //   totalHours: "",
      // },
      employeeId: employeeData?.employeeId?._id,
      businessId: getCurrentWorkspaceId(),
      paymentType: selectedOption,
      actionBy: user?._id,
      attendanceMethod: selectedAttendanceOption,
      settlementType:
        selectedOption === "monthly"
          ? workingDaysType
          : selectedOption === "payPerHour"
          ? workingType
          : "",
      settlementDate: selectedOption === "monthly" ? settlementDate : "",
      salary: salary,
      offDays: selectedDays,
      isGpsTracking:
        selectedAttendanceOption === "selfAttendance"
          ? selfgpsOptions.gpsTracking
          : selectedAttendanceOption === "autoMarking"
          ? autogpsOptions.gpsTracking
          : false,
      isLiveSelfie:
        selectedAttendanceOption === "selfAttendance"
          ? selfgpsOptions.liveSelfie
          : selectedAttendanceOption === "autoMarking"
          ? autogpsOptions.liveSelfie
          : false,
      isGpsLocking:
        selectedAttendanceOption === "selfAttendance"
          ? selfgpsOptions.gpsLocking
          : selectedAttendanceOption === "autoMarking"
          ? autogpsOptions.gpsLocking
          : false,
      shiftId: presentShift?._id,
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.editStaff,
      sendData
    );
    if (res?.success) {
      setloader(false);
      handleGetApprovedEmployee();
      toast.success("Edit Staff successfully!");
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (modalType === "edit") {
      setSelectedOption(employeeData?.staff?.paymentType);
      setsettlementDate(employeeData?.staff?.settlementDate);
      setworkingDaysType(employeeData?.staff?.settlementType);
      const presentShift = employeeData?.staff?.shiftTimings.find(
        (shift) => shift.shiftType === "present"
      );
      setpresentShift(presentShift);
      setSelectedDays(employeeData?.staff?.offDays);
      setSelectedAttendanceOption(employeeData?.staff?.attendanceMethod);

      if (employeeData?.staff?.attendanceMethod === "autoMarking") {
        setautoGpsOptions({
          gpsTracking: employeeData?.staff?.isGpsTracking,
          liveSelfie: employeeData?.staff?.isLiveSelfie,
          gpsLocking: employeeData?.staff?.isGpsLocking,
        });
      } else if (employeeData?.staff?.attendanceMethod === "selfAttendance") {
        setselfGpsOptions({
          gpsTracking: employeeData?.staff?.isGpsTracking,
          liveSelfie: employeeData?.staff?.isLiveSelfie,
          gpsLocking: employeeData?.staff?.isGpsLocking,
        });
      }
      setsalary(employeeData?.staff?.salary);
    }
  }, [modalType, employeeData]);

  return (
    <div
      id="attendance_modal"
      className="modal fade add-new-task "
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "80%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="text-capitalize mb-2">
              {modalType === "add"
                ? `Staff Setup > ${employeeData?.employeeId?.name}`
                : `Edit Staff Setup > ${employeeData?.employeeId?.name}`}
            </h5>
            <div className="border-top mb-2"></div>
            <div className="d-flex gap-3 p-2 px-2">
              <div className="d-flex flex-column gap-3 border rounded-7 p-3 w-400p h-100">
                <p className="text-dark fw-semibold">3 step set up</p>
                <div className="d-flex align-items-center gap-3">
                  <span
                    className={`border ${
                      completeFirstStep ? "bg-success" : "bg-light"
                    }  circle d-flex align-items-center justify-content-center text-dark`}
                    style={{ height: "28px", width: "28px" }}
                  >
                    {setUp ? checkIcon : 1}
                  </span>
                  <p className="text-dark">Working duration</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <span
                    className={`border ${
                      completeSecondStep ? "bg-success" : "bg-light"
                    }  circle d-flex align-items-center justify-content-center text-dark`}
                    style={{ height: "28px", width: "28px" }}
                  >
                    {setUp2 ? checkIcon : 2}
                  </span>
                  <p className="text-dark">Attendance Method</p>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <span
                    className={`border ${
                      completeThirdStep ? "bg-success" : "bg-light"
                    }  circle d-flex align-items-center justify-content-center text-dark`}
                    style={{ height: "28px", width: "28px" }}
                  >
                    {setUp3 ? checkIcon : 3}
                  </span>
                  <p className="text-dark">Salary method</p>
                </div>
              </div>

              {setUp && !setUp2 && !setUp3 && (
                <WorkingDuration
                  selectedDays={selectedDays}
                  setSelectedDays={setSelectedDays}
                  selectSetup2={selectSetup2}
                  workingDaysType={workingDaysType}
                  setworkingDaysType={setworkingDaysType}
                  completeFirstStep={completeFirstStep}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  settlementDate={settlementDate}
                  setsettlementDate={setsettlementDate}
                  setworkingType={setworkingType}
                  workingType={workingType}
                  modalType={modalType}
                  setselectedShift={setselectedShift}
                  selectedShift={selectedShift}
                />
              )}
              {!setUp && setUp2 && !setUp3 && (
                <AttendanceMethod
                  selectSetup={selectSetup}
                  selectSetup3={selectSetup3}
                  selectedAttendanceOption={selectedAttendanceOption}
                  setSelectedAttendanceOption={setSelectedAttendanceOption}
                  selfgpsOptions={selfgpsOptions}
                  setselfGpsOptions={setselfGpsOptions}
                  autogpsOptions={autogpsOptions}
                  setautoGpsOptions={setautoGpsOptions}
                  completeSecondStep={completeSecondStep}
                  setselectedLocation={setselectedLocation}
                  selectedLocation={selectedLocation}
                  checkInDistance={checkInDistance}
                  setcheckInDistance={setcheckInDistance}
                />
              )}
              {!setUp && !setUp2 && setUp3 && (
                <SalaryMethod
                  selectSetup2={selectSetup2}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  handleAddShift={handleAddShift}
                  errorMessage={errorMessage}
                  completeThirdStep={completeThirdStep}
                  salary={salary}
                  setsalary={setsalary}
                  advance={advance}
                  setadvance={setadvance}
                  deposit={deposit}
                  setdeposit={setdeposit}
                  loader={loader}
                  modalType={modalType}
                  handleEditShift={handleEditShift}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingAllSteps;
