import React from "react";
import nodata from "../../assets/images/nodata.jpg";

const NoData = ({ title, description }) => {
  return (
    <div className="d-flex flex-column">
      <div className="no-data-wrapper p-7 pb-0">
        <h5 className="fw-semibold text-capitalize mb-1">
          {title ? title : "No results found"}
        </h5>
        <p>
          {description
            ? description
            : "Try adjusting your search or filter to find what you're looking for."}
        </p>
      </div>
      <div className="w-100 d-flex align-items-center justify-content-center">
        <img
          style={{ filter: "grayscale(100%)" }}
          className="h-180p objetFit-contain"
          src={nodata}
          alt=""
        />
      </div>
    </div>
  );
};

export default NoData;
