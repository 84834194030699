import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import AddAccountType from "./AddAccountType";
import ApiCall from "../../../api/ApiCall";
import { AccountRelatedEndPoints } from "../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { toast } from "react-toastify";

const AddAccount = ({
  handleGetAllAccount,
  editAccDetails,
  setEditAccDetails,
  allMainAccountType,
}) => {
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState("");
  const [addTypeModal, setAddTypeModal] = useState(false);
  const [accountCode, setAccountCode] = useState("");
  const [accountName, setAccountName] = useState("");
  const [description, setDescription] = useState("");
  const [subAccType, setSubAccType] = useState([]);
  const [subAccId, setSubAccId] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (editAccDetails) {
      setAccountName(editAccDetails?.accountName);
      setAccountCode(editAccDetails?.accountCode);
      setType(editAccDetails?.subAccountType);
      setDescription(editAccDetails?.description);
    }
  }, [editAccDetails]);

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setType("");
    setSubAccId("");
    setAccountName("");
    setAccountCode("");
    setAccountCode("");
    setDescription("");
    setEditAccDetails("");
    const closeModalDom = document.getElementById("add_account_type");
    if (closeModalDom) closeModalDom.click();
  };

  // ADD ACCOUNT FUNCTION API CALL
  const handleAddAccount = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      mainAccoutType: subAccId,
      subAccountType: type,
      businessId: getCurrentWorkspaceId(),
      accountName,
      accountCode,
      description,
    };
    const res = await ApiCall("post", AccountRelatedEndPoints.addAccount, data);
    if (res?.success) {
      handleReset();
      handleGetAllAccount();
      toast.success("Add account successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // EDIT ACCOUNT FUNCTION API CALL
  const handleEditAccount = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      accountId: editAccDetails?._id,
      accountName,
      accountCode,
      description,
    };
    const res = await ApiCall(
      "post",
      AccountRelatedEndPoints.editAccount,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetAllAccount();
      toast.success("Edit account successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleGetSubAccount = async () => {
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      AccountRelatedEndPoints.getSubAccountType,
      data
    );
    if (res?.success) {
      setSubAccType(res?.accountType?.data);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetSubAccount();
    }
  }, []);

  return (
    <div
      id="add_account_type"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{editAccDetails !== "" ? "Edit Account" : "Add Account"}</h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editAccDetails === "") {
                  handleAddAccount(e);
                } else {
                  handleEditAccount(e);
                }
              }}
            >
              <div className="row gx-3 w-100">
                <div className="col-sm-8">
                  <label>
                    Select Type{" "}
                    <span
                      className="pointer ms-2"
                      onClick={() => {
                        setAddTypeModal(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="primary"
                        class="bi bi-plus-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                      </svg>
                    </span>
                  </label>
                  <div className="form-group mt-2">
                    <div className="selectable-dropdown">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-250p mt-2 ${
                            editAccDetails ? "bg-light text-disabled" : ""
                          }`}
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          disabled={editAccDetails}
                          onClick={() => setFilter("")}
                        >
                          {type ? (
                            <div className="d-flex justify-content-between">
                              {type}
                              {type !== "Select unit" && !editAccDetails && (
                                <button
                                  type="button"
                                  className="btn-close btn-sm ms-2"
                                  onClick={() => {
                                    setType("");
                                    setFilter("");
                                  }}
                                  aria-label="Close"
                                ></button>
                              )}
                            </div>
                          ) : (
                            "Select type"
                          )}
                        </span>
                        <div role="menu" className="dropdown-menu">
                          <form className="d-sm-block d-none" role="search">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search.."
                              value={filter}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </form>
                          <div className="ps-2 py-2"
                            style={{ maxHeight: "33vh", overflow: "auto" }}
                          >
                            {allMainAccountType?.map((mainType) => {
                              const matchingSubTypes = subAccType.filter(
                                (subType) =>
                                  subType.mainAccountTypeName ===
                                    mainType.value &&
                                  subType.subAcountTypeName
                                    .toLowerCase()
                                    .includes(filter.toLowerCase())
                              );
                              if (
                                filter === "" ||
                                matchingSubTypes.length > 0
                              ) {
                                return (
                                  <div
                                    className="ps-1 pt-1 text-capitalize"
                                    key={mainType?.value}
                                  >
                                    <span className="text-dark fw-bold">
                                      {mainType?.name}
                                    </span>
                                    <div className="dropdown-item text-dark ms-2">
                                      {matchingSubTypes.map((subType) => (
                                        <div
                                          key={subType._id}
                                          className={`${
                                            type === subType.subAcountTypeName
                                              ? "subtext-bg px-2 py-1 rounded"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setType(subType.subAcountTypeName);
                                            setSubAccId(
                                              subType?.mainAccountTypeName
                                            );
                                          }}
                                        >
                                          {subType.subAcountTypeName}
                                          {type ===
                                            subType.subAcountTypeName && (
                                            <span className="btn-icon-wrap ms-14">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="#0000FF"
                                                className="bi bi-check2"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                              </svg>
                                            </span>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gx-3 w-100">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label className="text-dark fw-300 fs-6">Code</label>
                  <input
                    className="form-control mt-2"
                    type="text"
                    required
                    value={accountCode}
                    onChange={(e) => setAccountCode(e.target.value)}
                  />
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label">Description</label>
                    </div>
                    <textarea
                      className="form-control"
                      rows="3"
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      value={description}
                    ></textarea>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? (
                    <ApiLoader />
                  ) : editAccDetails === "" ? (
                    "Add"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
          {addTypeModal && (
            <AddAccountType
              setAddTypeModal={setAddTypeModal}
              allMainAccountType={allMainAccountType}
              handleGetSubAccount={handleGetSubAccount}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddAccount;
