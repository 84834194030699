import React from "react";
import { dateFormatter } from "../../../global-functions/globalFunctions";

const DealsHeader = ({
  handleGetAllDeals,
  search,
  setSearch,
  setPage,
  statusFilter,
  setstatusFilter,
  setassigneeModal,
  allDeals,
  isModuleReadWriteAccessForDeals,
}) => {
  const allType = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Close won",
      value: "close won",
    },
    {
      name: "Close lost",
      value: "close lost",
    },
  ];

  const downloadCSV = () => {
    let csvData = [];
    allDeals?.map((dt, index) => {
      csvData.push({
        "Sr. No.": index + 1,
        "Deal Name": dt?.title,
        Amount: dt?.amount ? `₹ ${dt?.amount}` : "-",
        "Closing Amount": dt?.closingAmount ? `₹ ${dt?.closingAmount}` : "-",
        Source: dt.source,
        "Status Group": dt.groupId?.name,
        "Added By": dt.createdBy ? dt.createdBy.name : dt?.convertedBy?.name,
        "Current Status": dt?.subStatusId?.name,
        "Closing Date": dt?.closingDate ? dateFormatter(dt?.closingDate) : "-",
      });
    });
    const headers = Object.keys(csvData[0]);
    // Convert header and data to CSV format
    const headerRow = headers.map((header) => `"${header}"`).join(",");
    const dataRows = csvData.map((obj) =>
      headers.map((header) => `"${obj[header]}"`).join(",")
    );
    const csvContent = `data:text/csv;charset=utf-8,${headerRow}\n${dataRows.join(
      "\n"
    )}`;
    // Create a data URI and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "All_Deals.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <>
      <header className="todo-header">
        <div className="d-flex align-items-center">
          <span className="todoapp-title  link-dark">
            <h1 className="header-title-font ">Deals</h1>
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                data-bs-toggle="modal"
                data-bs-target="#create_new_deal"
              >
                Create Deal
              </button>
            </div>
          </span>
        </div>
        <div className="todo-options-wrap">
          <div className="me-2">
            <button
              className="btn btn-primary"
              onClick={downloadCSV}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className="bi bi-download me-1"
                viewBox="0 0 16 16"
              >
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
              </svg>
              Export CSV
            </button>
          </div>
          <div className="me-2">
            <select
              className="form-select form-select"
              value={statusFilter}
              onChange={(e) => {
                setstatusFilter(e.target.value);
              }}
            >
              {allType?.map((status) => {
                return <option value={status?.value}>{status?.name}</option>;
              })}
            </select>
          </div>
          {isModuleReadWriteAccessForDeals && (
            <div className="me-2">
              <button
                className="btn btn-outline-light ms-1 fs-9 "
                onClick={() => {
                  setassigneeModal(true);
                }}
              >
                Select Assignee
              </button>
            </div>
          )}
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="dsdsd"
            data-bs-original-title="Refresh"
          >
            <span className="btn-icon-wrap" onClick={handleGetAllDeals}>
              <span className="feather-icon">
                <i
                  className="ri-refresh-line"
                  title="Refresh business list"
                ></i>
              </span>
            </span>
          </span>
          <div className="v-separator d-lg-inline-block d-none"></div>
          <form className="d-sm-block d-none" role="search">
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
            />
          </form>
        </div>
      </header>
    </>
  );
};

export default DealsHeader;
