import React, { useMemo } from "react";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import GaugeChart from "../EmployeeAnalytics/GaugeChart";
import LeadsCarousel from "./LeadsCarousel";
import UpcomingActivities from "./UpcomingActivities";
import { dateFormatter } from "../../../../../global-functions/globalFunctions";
import SkeletonConstant from "../../../../../components/Constant/SkeletonConstant";
import NoData from "../../../../../components/NoData/NoData";
import GaugeChartContact from "./GaugeChartContact";
import GaugeChartDeal from "./GaugeChartDeal";
import ContactCarousel from "./ContactCarousel";
import DealsCarousel from "./DealsCarousel";

const OverviewAnalytics = ({
  allLeadsContacts,
  filterType,
  leadCount,
  recentLeads,
  recentContacts,
  recentDeals,
  leadtoContactCount,
  upcomingActivity,
  leadGenerationCount,
  loader4,
  loader2,
  loader3,
  loader6,
  loader5,
  loader,
  activityProgress,
  activeTab,
  setActiveTab,
}) => {
  // Analytics tab

  const tabs = [
    {
      label: "Leads this month",
      count: Number(leadCount?.leads) || 0,
    },
    {
      label: "Contacts",
      count: Number(leadCount?.contacts) || 0,
    },
    {
      label: "Deals this month",
      count: Number(leadCount?.deals) || 0,
    },
  ];

  //   Doughnut chart
  const leadsThisMonth = {
    labels: ["Lead Won", "Lead Lost", "Open Lead"],
    datasets: [
      {
        label: "Lead Count",
        data: [
          leadtoContactCount?.closeWonLead,
          leadtoContactCount?.closeLostLead,
          leadtoContactCount?.openLead + leadtoContactCount?.pending,
        ],
        backgroundColor: ["#00d67f", "#ff0000", "#1944c2"],
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };
  const contacts = {
    labels: ["Contacts Converted", "Total Lead"],
    datasets: [
      {
        label: "Contact Count",
        data: [
          leadtoContactCount?.convertedContacts,
          leadtoContactCount?.totalLeads,
        ],
        backgroundColor: ["#BA0893", "#1944c2"],
        borderColor: ["#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };
  const dealsThisMonth = {
    labels: ["Deal Won", "Deal Lost", "Open Deal"],
    datasets: [
      {
        label: "Deal Count",
        data: [
          Number(leadtoContactCount?.closeWonDeal),
          Number(leadtoContactCount?.closeLostDeal),
          leadtoContactCount?.openDeal + leadtoContactCount?.pending,
        ],
        backgroundColor: ["#00d67f", "#ff0000", "#1944c2"],
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };
  const doughnutOptions = {
    responsive: true,
    cutout: "60%",
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: false,
    },
  };
  const getChartData = () => {
    switch (activeTab) {
      case "Leads this month":
        return leadsThisMonth;
      case "Contacts":
        return contacts;
      case "Deals this month":
        return dealsThisMonth;
      default:
        return leadsThisMonth;
    }
  };

  const chartData = useMemo(() => getChartData());

  const hasData = useMemo(
    () => chartData?.datasets?.[0]?.data?.some((value) => value > 0),
    [chartData]
  );

  // Bar chart
  const barChartOptions = {
    plugins: {
      title: {
        display: true,
        text: "Daily Leads And Contact Count",
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        anchor: "center",
        align: "center",
        formatter: (value, context) => {
          return parseFloat(value)?.toFixed(2);
        },
        color: "#fff",
        font: {
          size: 12,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
      },
    },
  };
  const barChartData = {
    // labels: allLeadsContacts?.map((data) => dateFormatter(data?.date)),
    labels:
      filterType === "thisweek" || filterType === "lastweek"
        ? [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ]
        : allLeadsContacts?.map((date) => dateFormatter(date?.date)),

    datasets: [
      {
        label: "Contacts",
        data: allLeadsContacts?.map((contact) => contact?.contactCount),
        backgroundColor: ["#FF2969"],
      },
      {
        label: "Leads",
        data: allLeadsContacts?.map((lead) => lead?.allLead),
        backgroundColor: ["#7A29FF"],
      },
    ],
  };

  // Task Details Chart
  const taskDoughnutData = {
    labels: ["In deadline", "Task pending", "Beyond deadline"],
    datasets: [
      {
        data: [
          activityProgress?.withinSla,
          activityProgress?.pending,
          activityProgress?.beyondSla,
        ],
        backgroundColor: ["#00d67f", "#fed000", "#ff0000"],
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="d-flex flex-column gap-1">
      <ul className="row gx-7 mb-0 w-100">
        {!loader2 && !loader4 && (
          <>
            {tabs.map((tab, index) => (
              <li
                className="col-4"
                key={index}
                onClick={() => setActiveTab(tab.label)}
              >
                <span
                  className={`border py-2 px-4 rounded-6 pointer d-flex justify-content-between gap-7 ${
                    activeTab === tab.label ? "bg-primary text-white" : ""
                  }`}
                >
                  <p className="mb-0">{tab.label}</p>
                  <p className="mb-0">{tab.count}</p>
                </span>
              </li>
            ))}
          </>
        )}
        {(loader2 || loader4) &&
          [1, 2, 3].map((item) => (
            <li className="col-4" key={item}>
              <div className="d-flex justify-content-start w-100">
                <SkeletonConstant
                  width="w-100"
                  height="h-50p"
                  additional="mx-auto rounded-10 ms-2"
                />
              </div>
            </li>
          ))}
      </ul>

      <div className="d-flex justify-content-center">
        <div className="position-relative">
          {!loader2 && !loader4 && hasData && (
            <Chart
              type="doughnut"
              data={getChartData()}
              options={doughnutOptions}
            />
          )}
          {!loader2 && !loader4 && !hasData && <NoData title="No Data" />}

          {(loader2 || loader4) && (
            <div className="d-flex justify-content-start w-100">
              <SkeletonConstant
                width="w-230p"
                height="h-230p"
                additional="mx-auto ms-2 rounded-34"
              />
            </div>
          )}

          {!loader2 && !loader4 && hasData && (
            <span
              className="d-flex flex-column align-items-center"
              style={{
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {/* <p className="fs-5">Total</p> */}
              <p className="fs-4 text-dark fw-semibold">
                {activeTab === "Leads this month"
                  ? leadCount?.leads > 0
                    ? leadCount?.leads
                    : ""
                  : activeTab === "Deals this month"
                  ? leadCount?.deals > 0
                    ? leadCount?.deals
                    : ""
                  : leadCount?.contacts > 0
                  ? leadCount?.contacts
                  : ""}
              </p>
            </span>
          )}
        </div>
        {activeTab === "Leads this month" &&
          !loader2 &&
          !loader4 &&
          (leadtoContactCount?.closeWonLead > 0 ||
            leadtoContactCount?.closeLostLead > 0 ||
            leadtoContactCount?.openLead + leadtoContactCount?.pending > 0) && (
            <div className="p-3">
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#00d67f"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                &nbsp;Leads Won : {leadtoContactCount?.closeWonLead}
              </div>
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#ff0000"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                &nbsp;Leads Lost : {leadtoContactCount?.closeLostLead}
              </div>
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#1944c2"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                &nbsp;Open Leads :{" "}
                {leadtoContactCount?.openLead + leadtoContactCount?.pending}
              </div>
            </div>
          )}
        {activeTab === "Contacts" &&
          !loader2 &&
          !loader4 &&
          (leadtoContactCount?.totalLeads > 0 ||
            leadtoContactCount?.convertedContacts > 0) && (
            <div className="p-3">
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#1944c2"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                &nbsp;Total Leads : {leadtoContactCount?.totalLeads}
              </div>
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#BA0893"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                &nbsp;Converted Contacts :{" "}
                {leadtoContactCount?.convertedContacts}
              </div>
            </div>
          )}
        {activeTab === "Deals this month" &&
          !loader2 &&
          !loader4 &&
          (leadtoContactCount?.closeWonDeal > 0 ||
            leadtoContactCount?.closeLostDeal > 0 ||
            leadtoContactCount?.openDeal + leadtoContactCount?.pending > 0) && (
            <div className="p-3">
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#00d67f"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                &nbsp;Deals Won : {leadtoContactCount?.closeWonDeal}
              </div>
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#ff0000"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                &nbsp;Deals Lost : {leadtoContactCount?.closeLostDeal}
              </div>
              <div className="d-flex align-items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#1944c2"
                  class="bi bi-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <circle cx="8" cy="8" r="8" />
                </svg>
                &nbsp;Open Deals :{" "}
                {leadtoContactCount?.openDeal + leadtoContactCount?.pending}
              </div>
            </div>
          )}
      </div>

      {loader2 && loader4 && (
        <div className="row gx-3 text-center">
          {activeTab === "Contacts"
            ? [1, 2]?.map((index) => (
                <div className="col-4">
                  <div
                    className="d-flex justify-content-start w-100"
                    key={index}
                  >
                    <SkeletonConstant
                      width="w-70"
                      height="h-30p"
                      additional="mx-auto rounded-10 ms-2"
                    />
                  </div>
                </div>
              ))
            : [1, 2, 3]?.map((index) => (
                <div className="col-4">
                  <div
                    className="d-flex justify-content-start w-100"
                    key={index}
                  >
                    <SkeletonConstant
                      width="w-70"
                      height="h-30p"
                      additional="mx-auto rounded-10 ms-2"
                    />
                  </div>
                </div>
              ))}
        </div>
      )}

      <div className="row col-sm-12">
        <div className="col-6">
          {!loader3 && (
            <div className="w-100">
              {activeTab === "Leads this month" && (
                <LeadsCarousel recentLeads={recentLeads} />
              )}
              {activeTab === "Contacts" && (
                <ContactCarousel recentContacts={recentContacts} />
              )}
              {activeTab === "Deals this month" && (
                <DealsCarousel recentDeals={recentDeals} />
              )}
            </div>
          )}
          {loader3 && (
            <div className="d-flex justify-content-start w-100">
              <SkeletonConstant
                width="w-100"
                height="h-320p"
                additional="mx-auto ms-2 rounded-4"
              />
            </div>
          )}
        </div>
        <div className="col-6">
          {!loader6 && (
            <div className="card">
              <div className="card-body">
                <div style={{ height: "310px" }}>
                  <p className="fs-4 text-dark">
                    {`${
                      activeTab === "Leads this month"
                        ? "Lead"
                        : activeTab === "Contacts"
                        ? "Contact"
                        : "Deal"
                    }`}{" "}
                    Generation Target
                  </p>
                  <div className="d-flex justify-content-center">
                    {activeTab === "Leads this month" && (
                      <GaugeChart
                        value={leadGenerationCount?.generatedLeads}
                        generatedLeadsTarget={
                          leadGenerationCount?.generatedLeadsTarget
                        }
                        isLeadTarget={true}
                      />
                    )}
                    {activeTab === "Contacts" && (
                      <GaugeChartContact
                        value={leadGenerationCount?.generatedContacts}
                        generatedContactTarget={
                          leadGenerationCount?.generatedContactsTarget
                        }
                      />
                    )}
                    {activeTab === "Deals this month" && (
                      <GaugeChartDeal
                        value={leadGenerationCount?.generatedDeals}
                        generatedLeadsTarget={
                          leadGenerationCount?.generatedDealsTarget
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {loader6 && (
            <div className="d-flex justify-content-start w-100 h-320p">
              <SkeletonConstant
                width="w-100"
                height="h-100"
                additional="mx-auto ms-2 rounded-4"
              />
            </div>
          )}
        </div>
        <div className="col-12">
          {!loader && (
            <div className="card">
              <div className="card-body">
                <div className="h-350p">
                  <div className="d-flex justify-content-center h-100">
                    <Bar data={barChartData} options={barChartOptions} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {loader && (
            <div className="d-flex justify-content-start w-100">
              <SkeletonConstant
                width="w-100"
                height="h-350p"
                additional="mx-auto ms-2 rounded-4"
              />
            </div>
          )}
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="card p-3">
                <div className="d-flex justify-content-between">
                  <p>
                    Activities Close -{" "}
                    {activeTab === "Leads this month"
                      ? "Lead"
                      : activeTab === "Contacts"
                      ? "Contact"
                      : "Deal"}
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  {!loader5 && (
                    <GaugeChart
                      value={activityProgress?.activityCompletionPercentage}
                      activityProgress={activityProgress}
                      totalActivity={activityProgress?.total}
                    />
                  )}
                  {loader5 && (
                    <div className="d-flex justify-content-start w-100 h-310p">
                      <SkeletonConstant
                        width="w-100"
                        height="h-100"
                        additional="mx-auto ms-2 rounded-4"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card p-3">
                <div className="d-flex justify-content-between">
                  <p>
                    Task Details Chart -{" "}
                    {activeTab === "Leads this month"
                      ? "Lead"
                      : activeTab === "Contacts"
                      ? "Contact"
                      : "Deal"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center position-relative">
                  <div className="w-280p h-280p">
                    <Chart type="doughnut" data={taskDoughnutData} />
                  </div>
                  <span
                    className="d-flex flex-column align-items-center"
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <p className="fs-5">Total</p>
                    <p className="fs-4 text-dark fw-semibold">
                      {activityProgress?.total}
                    </p>
                  </span>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#00d67f"
                      class="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                    &nbsp;In deadline : {activityProgress?.withinSla}
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fed000"
                      class="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                    &nbsp;Task pending : {activityProgress?.pending}
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#ff0000"
                      class="bi bi-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <circle cx="8" cy="8" r="8" />
                    </svg>
                    &nbsp;Beyond deadline : {activityProgress?.beyondSla}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!loader5 && (
          <div className="col-12 h-280p">
            <UpcomingActivities
              activeTab={activeTab}
              upcomingActivity={upcomingActivity}
            />
          </div>
        )}
        {loader5 && (
          <div className="col-12">
            <div className="d-flex justify-content-start w-100 h-480p">
              <SkeletonConstant
                width="w-100"
                height="h-100"
                additional="mx-auto ms-2 rounded-4"
              />
            </div>
          </div>
        )}

        {/* <div className="col-6">
          <div className="card">
            <div className="card-body d-flex flex-column align-items-center gap-5">
              <p className="fs-4">Deal closed</p>
              <CircleProgressBar
                size={150}
                percent={leadGenerationCount?.dealsClosedPercentage}
                progressColor="#00C172"
              />
              <p className="fs-5 text-dark">
                {leadGenerationCount?.dealsClosedPercentage}% of the deals were
                a close won
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OverviewAnalytics;
