import React, { useEffect, useState } from "react";
import AccountTypeHeader from "./AccountHeader";
import AccountsTable from "./AccountsTable";
import AddAccount from "./AddAccount";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { AccountRelatedEndPoints } from "../../../api/Endpoints";
import NoData from "../../../components/NoData/NoData";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const ChartOfAccount = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [search, setSearch] = useState("");
  const [allAccount, setAllAccount] = useState([]);
  const [editAccDetails, setEditAccDetails] = useState("");
  const [selectType, setSelectType] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessAccounts = allModuleAccess?.[PRIVATEROUTES.CHART_OF_ACCOUNTS]

  // GET ACCOUNT FUNCTION API CALL
  const handleGetAllAccount = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        mainAccoutType: selectType,
      },
    };
    const res = await ApiCall("post", AccountRelatedEndPoints.getAccount, data);
    if (res?.success) {
      setAllAccount(res?.account?.data);
      setcount(res?.account?.totalCounts);
      settotalPages(res?.account?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllAccount();
    }
  }, [page, pageLimit, search, selectType]);

  const allMainAccountType = [
    {
      name: "Assets",
      value: "asset",
    },
    {
      name: "Liability",
      value: "liability",
    },
    {
      name: "Expense",
      value: "expense",
    },
    {
      name: "Income",
      value: "income",
    },
    {
      name: "Equity",
      value: "equity",
    },
  ];

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <AccountTypeHeader
            handleGetAllAccount={handleGetAllAccount}
            setSearch={setSearch}
            search={search}
            setPage={setPage}
            setSelectType={setSelectType}
            selectType={selectType}
            allMainAccountType={allMainAccountType}
            isModuleReadWriteAccessAccounts={isModuleReadWriteAccessAccounts}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allAccount?.length > 0 && (
                <AccountsTable
                  allAccount={allAccount}
                  page={page}
                  pageLimit={pageLimit}
                  setEditAccDetails={setEditAccDetails}
                  handleGetAllAccount={handleGetAllAccount}
                  editAccDetails={editAccDetails}
                  isModuleReadWriteAccessAccounts={isModuleReadWriteAccessAccounts}
                />
              )}
              {!loader && allAccount?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allAccount?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allAccount?.length === 0 && (
                <NoData title="No accounts added" />
              )}
            </div>
          </div>
        </div>
        <AddAccount
          handleGetAllAccount={handleGetAllAccount}
          editAccDetails={editAccDetails}
          setEditAccDetails={setEditAccDetails}
          allMainAccountType={allMainAccountType}
        />
      </div>
    </div>
  );
};

export default ChartOfAccount;
