import React from "react";
import { navigateToStaffAttendance } from "../../../../Navigation/Navigation";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { dateFormatter } from "../../../../global-functions/globalFunctions";

const AttendanceDetailsHeader = (props) => {
  const {
    attendanceDetails,
    handleGetAttendanceDetails,
    handleGetAttendanceOverview,
    currentYear,
    currentMonth,
    selectedDate,
    isModuleReadWriteAccessForAttendance,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center gap-1 text-dark me-2 col-sm-8">
        {isModuleReadWriteAccessForAttendance && (
          <div
            className="pointer"
            onClick={() => {
              navigate(navigateToStaffAttendance());
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </div>
        )}

        <span className="d-flex align-items-center gap-2 text-capitalize">
          <Avatar
            name={attendanceDetails?.staff?.employeeId?.name}
            count={1}
            size="xss"
            color="primary"
            image={attendanceDetails?.staff?.employeeId?.image}
          />
          <p>{attendanceDetails?.staff?.employeeId?.name}</p>
        </span>
      </div>
      <span className="d-flex">
        <span className="d-flex align-items-center gap-2 text-capitalize">
          <p>
            {location?.state?.selectedMonth
              ? new Date(currentYear, currentMonth).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    year: "numeric",
                  }
                )
              : location?.state?.selectedDate
              ? selectedDate
                ? dateFormatter(selectedDate)
                : "-"
              : new Date(currentYear, currentMonth).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    year: "numeric",
                  }
                )}
          </p>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block ml-2 col-sm-2"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={() => {
            handleGetAttendanceDetails();
            handleGetAttendanceOverview();
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </span>
    </header>
  );
};

export default AttendanceDetailsHeader;
