import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToItemCategory } from "../../../../Navigation/Navigation";

function CategoryItemsHeader({
  setitemModal,
  handleGetCategoryItems,
  category,
  search,
  setsearch,
  setPage,
  isModuleReadWriteAccessForCategoryItems,
  setCopyModal,
}) {
  const navigate = useNavigate();
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <button
            className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
            onClick={() => {
              navigate(navigateToItemCategory());
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <p className="mb-0">Back</p>
          </button>
          <div className="v-separator d-lg-inline-block d-none"></div>
          <h1 className="header-title-font">{category}</h1>
          {isModuleReadWriteAccessForCategoryItems && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block  header-button-shadow"
                onClick={() => {
                  setitemModal(true);
                }}
              >
                Add Item
              </button>
            </div>
          )}
          {isModuleReadWriteAccessForCategoryItems && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block  header-button-shadow"
                onClick={() => {
                  setCopyModal(true);
                }}
              >
                Copy Item
              </button>
            </div>
          )}
        </span>
      </div>
      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetCategoryItems}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setsearch(e.target.value);
            }}
          />
        </form>
      </div>
    </header>
  );
}

export default CategoryItemsHeader;
