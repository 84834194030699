import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loginImg from "../../assets/images/logo/bizhoodfullLogo.png";
import { startApiCall } from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { loginEndpoints } from "../../api/Endpoints";
import ApiLoader from "../Loaders/ApiLoader/ApiLoader";
import { toast } from "react-toastify";

const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setloader] = useState(false);

  const submitDeletionRequest = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader);
    const data = {
      email,
      message:description,
    };
    const res = await ApiCall("post", loginEndpoints.deleteAccount, data);
    if (res?.success) {
      toast.success("Account deletion request submited successfully! we will delete your account in next 48 hours");
      setEmail("");
      setDescription("");
      setloader(false);
    } else {
      setloader(false);
    }
  };

  return (
    <div className="container mt-5">
      <nav className="navbar navbar-expand-lg navbar-light bg-light px-2">
        <Link className="navbar-brand" to="/login">
          <img src={loginImg} alt="Logo" style={{ height: "40px" }} />
        </Link>
        <div className="collapse navbar-collapse justify-content-end">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Dashboard
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="row mt-10">
        <div className="col-md-8 offset-md-2">
          <h2>Request Account Deletion</h2>
          <p>
            If you would like to delete your account, please fill out the form
            below. Alternatively, you can email our support team or follow the
            link to our self-guided account deletion process.
          </p>

          <h4>Account Deletion Form</h4>
          <form onSubmit={submitDeletionRequest}>
            <div className="form-group">
              <label htmlFor="formEmail">Email address</label>
              <input
                type="email"
                required
                className="form-control mt-2"
                id="formEmail"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="formReason">Reason for deletion</label>
              <textarea
                required
                className="form-control mt-2"
                id="formReason"
                rows="3"
                placeholder="Reason for deleting your account"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <button type="submit" className="btn btn-primary">
              {loader ? <ApiLoader /> : "Submit Deletion Request"}
            </button>
          </form>

          <h4 className="mt-4">Contact Support</h4>
          <p>
            If you prefer, you can also request account deletion by emailing us
            at <a href="mailto:contact@sociohood.com">contact@sociohood.com</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
