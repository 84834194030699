import React from "react";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";

const AllComplaintsSkeleton = () => {
  return (
    <div className="d-flex flex-column gap-3">
      <div className="card loading-skeleton p-3">
        <div className="d-flex justify-content-between gap-3">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex align-items-center gap-2">
              <SkeletonConstant width="w-60p" height="h-60p" />
              <div className="d-flex flex-column gap-2">
                <SkeletonConstant width="w-120p" height="h-30p" />
                <SkeletonConstant width="w-240p" height="h-25p" />
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <SkeletonConstant width="w-160p" height="h-30p" />
              <SkeletonConstant width="w-220p" height="h-25p" />
              <SkeletonConstant width="w-220p" height="h-25p" />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between gap-3">
            <SkeletonConstant
              width="w-120p"
              height="h-25p"
              additional="ms-auto"
            />
            <div className="d-flex flex-column gap-2">
              <SkeletonConstant
                width="w-300p"
                height="h-60p"
                additional="ms-auto"
              />
              <SkeletonConstant
                width="w-150p"
                height="h-40p"
                additional="ms-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card loading-skeleton p-3">
        <div className="d-flex justify-content-between gap-3">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex align-items-center gap-2">
              <SkeletonConstant width="w-60p" height="h-60p" />
              <div className="d-flex flex-column gap-2">
                <SkeletonConstant width="w-120p" height="h-30p" />
                <SkeletonConstant width="w-240p" height="h-25p" />
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <SkeletonConstant width="w-160p" height="h-30p" />
              <SkeletonConstant width="w-220p" height="h-25p" />
              <SkeletonConstant width="w-220p" height="h-25p" />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between gap-3">
            <SkeletonConstant
              width="w-120p"
              height="h-25p"
              additional="ms-auto"
            />
            <div className="d-flex flex-column gap-2">
              <SkeletonConstant
                width="w-300p"
                height="h-60p"
                additional="ms-auto"
              />
              <SkeletonConstant
                width="w-150p"
                height="h-40p"
                additional="ms-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card loading-skeleton p-3">
        <div className="d-flex justify-content-between gap-3">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex align-items-center gap-2">
              <SkeletonConstant width="w-60p" height="h-60p" />
              <div className="d-flex flex-column gap-2">
                <SkeletonConstant width="w-120p" height="h-30p" />
                <SkeletonConstant width="w-240p" height="h-25p" />
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <SkeletonConstant width="w-160p" height="h-30p" />
              <SkeletonConstant width="w-220p" height="h-25p" />
              <SkeletonConstant width="w-220p" height="h-25p" />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between gap-3">
            <SkeletonConstant
              width="w-120p"
              height="h-25p"
              additional="ms-auto"
            />
            <div className="d-flex flex-column gap-2">
              <SkeletonConstant
                width="w-300p"
                height="h-60p"
                additional="ms-auto"
              />
              <SkeletonConstant
                width="w-150p"
                height="h-40p"
                additional="ms-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllComplaintsSkeleton;
