import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { activityGroupRelatedEndpoints } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import { useSelector } from "react-redux";

const AddActivityStatus = ({
  id,
  setcreateStatus,
  selectStatus,
  editStatus,
  seteditStatus,
  handleGetAllStatus,
  handleGetGroupDetails,
}) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [statusName, setStatusName] = useState("");
  const [actions, setActions] = useState({
    file: { mandatory: false, description: "" },
    note: { mandatory: false, description: "" },
    location: { mandatory: false, description: "" },
  });

  useEffect(() => {
    if (editStatus) {
      setStatusName(editStatus?.name);
      setActions({
        file: {
          mandatory: editStatus?.tasks?.file?.mandatory,
          description: editStatus?.tasks?.file?.description,
        },
        note: {
          mandatory: editStatus?.tasks?.note?.mandatory,
          description: editStatus?.tasks?.note?.description,
        },
        location: {
          mandatory: editStatus?.tasks?.location?.mandatory,
          description: editStatus?.tasks?.location?.description,
        },
      });
    }
  }, [editStatus]);

  const handleReset = () => {
    setcreateStatus(false);
    setloader(false);
    setErrorMessage("");
    setStatusName("");
    seteditStatus("");
    setActions({
      file: { mandatory: false, description: "" },
      note: { mandatory: false, description: "" },
      location: { mandatory: false, description: "" },
    });
  };

  const handleActionChange = (event) => {
    const { name, checked } = event.target;
    setActions((prevActions) => ({
      ...prevActions,
      [name]: {
        ...prevActions[name],
        mandatory: checked,
        description: checked ? prevActions[name].description : "",
      },
    }));
  };

  const handleDescriptionChange = (event) => {
    const { name, value } = event.target;
    setActions((prevActions) => ({
      ...prevActions,
      [name]: { ...prevActions[name], description: value },
    }));
  };

  const handleAddActivityStatus = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const trimmedStatusName = statusName.trim();
    const data = {
      activityGroupStatus: selectStatus?._id,
      name: trimmedStatusName,
      businessId: getCurrentWorkspaceId(),
      tasks: actions,
      activityGroupId: id,
      createdBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.addActivityStatus,
      data
    );
    if (res?.success) {
      toast.success("Create status successfully!");
      handleReset();
      handleGetAllStatus();
      handleGetGroupDetails();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditActivityStatus = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      name: statusName,
      activityStatusId: editStatus?._id,
      tasks: actions,
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.editActivityStatus,
      data
    );
    if (res?.success) {
      toast.success("Edit status successfully!");
      handleReset();
      handleGetAllStatus();
      handleGetGroupDetails();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editStatus !== ""
                ? `Edit status under ${selectStatus?.name}`
                : `Create status under ${selectStatus?.name}`}
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editStatus === "") {
                  handleAddActivityStatus(e);
                } else {
                  handleEditActivityStatus(e);
                }
              }}
            >
              <div className="row gx-3 px-3 w-100 mh-400p overflow-auto ">
                <div className="col-md-8">
                  <div className="form-group">
                    <label className="form-label">Activity Name</label>
                    <input
                      className="form-control"
                      type="text"
                      value={statusName}
                      onChange={(e) => {
                        setStatusName(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
                {/* {!editStatus && ( */}
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">
                      Mandatory Action{" "}
                      <p className="text-secondary">
                        activity status (process) will change based on your
                        selected group.
                      </p>
                    </label>
                    <div className="form-group">
                      {Object.keys(actions).map((action) => (
                        <div className="row mb-2" key={action}>
                          <div className="col-4">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                id={"actionCheckbox" + action}
                                name={action}
                                className="form-check-input pointer"
                                value={action}
                                onChange={handleActionChange}
                                checked={actions[action].mandatory}
                              />
                              <label
                                className="form-check-label text-capitalize"
                                htmlFor={"actionCheckbox" + action}
                              >
                                {action
                                  .replace("is", "")
                                  .replace("Mandatory", "")}
                              </label>
                            </div>
                          </div>
                          {actions[action].mandatory && (
                            <div className="col-8">
                              <div className="form-group">
                                <label className="form-label">
                                  Add a title (optional)
                                </label>
                                <textarea
                                  className="form-control"
                                  rows="3"
                                  name={action}
                                  onChange={handleDescriptionChange}
                                  value={actions[action].description}
                                  maxLength={100}
                                ></textarea>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : editStatus === "" ? "Add" : "Edit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddActivityStatus;
