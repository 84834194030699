import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToActivityGroupDetails } from "../../../Navigation/Navigation";
import { dateAndTimeFormatter, getName } from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const ActivityGroupTable = ({
  allGroups,
  page,
  pageLimit,
  seteditAllGroups,
  isModuleReadWriteAccessForActivityGroup,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8 text-center">
          <thead>
            <tr className="text-center">
              <th>No .</th>
              <th className="text-start">Group Name</th>
              <th>Type</th>
              <th>Created By</th>
              <th>Last modified</th>
              {isModuleReadWriteAccessForActivityGroup && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {allGroups?.map((item, index) => {
              return (
                <tr key={item?._id}>
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td className="text-start text-link">
                    <div
                      className="text-capitalize text-truncate pointer mw-300p"
                      onClick={() =>
                        navigate(
                          navigateToActivityGroupDetails({ id: item?._id })
                        )
                      }
                    >
                      {item?.name}
                    </div>
                  </td>
                  <td className="text-capitalize">
                    {item?.activityType ? item?.activityType : "-"}
                  </td>
                  <td>
                    <Avatar
                      name={item?.createdBy?.name}
                      count={1}
                      image={item?.createdBy?.image}
                      size="xss"
                      color="primary"
                    />
                    <span className="ms-1">{getName(item?.createdBy , user?._id)}</span>
                  </td>
                  <td>{dateAndTimeFormatter(item?.updatedAt)}</td>
                  {isModuleReadWriteAccessForActivityGroup && (
                    <td>
                      <span
                        className="btn btn-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#add_activity_group"
                        onClick={() => {
                          seteditAllGroups(item);
                        }}
                      >
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </span>
                      </span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ActivityGroupTable;
