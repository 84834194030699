import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ShiftDetails from "../../pages/StaffManagement/StaffShiftTiming/ShiftDetail/ShiftDetails";
import AllShifts from "../../pages/StaffManagement/StaffShiftTiming/ShiftTiming/AllShifts";
import StaffAllShifts from "../../pages/StaffManagement/StaffShiftTiming/ShiftTiming/StaffAllShift";

const StaffShiftTimingRoutes = {
  path: "/staff",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <StaffAllShifts />,
    },
    {
      path: "allshifts",
      element: <StaffAllShifts />,
    },
    {
      path: "businessshift",
      element: <AllShifts />,
    },
    {
      path: "shiftdetails/:id",
      element: <ShiftDetails />,
    },
  ],
};

export default StaffShiftTimingRoutes;
