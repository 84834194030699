import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ParticipantsGroups from "../../pages/CRM/ParticipantsGroups/ParticipantsGroups";
import GroupDetails from "../../pages/CRM/ParticipantsGroups/GroupDetails/GroupDetails";

const ParticipantsGroupRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "allparticipantsgroups",
      element: <ParticipantsGroups />,
    },
    {
      path: "allparticipantsgroups/groupdetails/:id",
      element: <GroupDetails />,
    },
  ],
};

export default ParticipantsGroupRoutes;
