import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";

const CalendarView = ({ taskData, settaskDetailsModal,setselectedTask}) => {
  const localizer = momentLocalizer(moment);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return new Date(year, month, day, hours, minutes, 0, 0);
  }

  const events = taskData?.map((task) => ({
    title: (
      <>
        <span
          className="btn btn-xs btn-icon btn-flush-dark btn-rounded flush-soft-hover flex-shrink-0"
          href="#"
          data-bs-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={() => {
            setselectedTask(task?._id);
          }}
        >
          <span className="icon">
            <span className="feather-icon">
              <i className="ri-more-2-line"></i>
            </span>
          </span>
        </span>
        <div className="dropdown-menu">
          <span
            className="dropdown-item"
            // data-bs-toggle="modal"
            // data-bs-target="#taskdetails_modal"
            onClick={() => {
              settaskDetailsModal(true);
            }}
          >
            <span className="feather-icon dropdown-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-info-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
            </span>
            <span>View Details</span>
          </span>
        </div>
        {task?.name}
      </>
    ),
    start: formatDate(task?.createdAt),
    end: task?.dueDate
      ? formatDate(task?.dueDate)
      : formatDate(task?.createdAt),
  }));
  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ width: "100%" }}
    />
  );
};

export default CalendarView;
