import React from "react";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  getName,
} from "../../../../global-functions/globalFunctions";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";

const GroupLogs = ({ groupLogs, loader }) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      {loader && <DataLoader title="logs is loading..." />}
      {!loader && groupLogs?.length > 0 && (
        <div className="col-md-12 col-sm-12  mb-8">
          <div
            // className="overflow-y-scroll simple-scrollbar"
            // style={{ maxHeight: "80vh" }}
          >
            {groupLogs?.map((log) => {
              return (
                <div className="d-flex flex-column gap-2 mt-2">
                  <div className="d-flex align-items-center gap-3 p-2 rounded-4 bg-light shadow-hover-sm">
                    <span
                      className="avatar avatar-sm  text-white"
                      style={{
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      <CustomTooltip
                        text={log?.actionBy?.name}
                        placement="right"
                      >
                        <Avatar
                          name={log?.actionBy?.name}
                          count={1}
                          image={log?.actionBy?.image}
                          size="xs"
                        />
                      </CustomTooltip>
                    </span>
                    <div className="d-flex flex-column">
                      <p className="text-capitalize fs-7">
                        <span className="text-medium-em font-2">
                          {log?.status}
                        </span>
                        &nbsp;&nbsp;
                        <span className="text-body font-2">
                          {dateAndTimeFormatter(log?.actionOn)}
                        </span>
                      </p>
                      <p className="text-dark">
                        <span className="text-primary font-3">
                          {getName(log?.actionBy, user?._id)} &nbsp;
                        </span>

                        <span className="text-high-em font-3 ">
                          {log?.description}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default GroupLogs;
