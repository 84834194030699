import React, { useEffect, useState } from "react";
import OthersSettingDetails from "./OthersSettingDetails";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../api/ApiCall";
import {
  businessEndpoints,
  businessInvoiceEndpoints,
} from "../../../api/Endpoints";
import { toast } from "react-toastify";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import OtherSettingTable from "./OtherSettingTable";
import AddAccount from "./AddAccount";
import InvoiceDetails from "./InvoiceDetails/InvoiceDetails";
import EditInvoiceModal from "./InvoiceDetails/EditInvoiceModal";
import ProfileDetails from "../../InventoryManagement/InventorySetings/ProfileDetails";

const OtherSettings = () => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [businessDetails, setBusinessDetails] = useState([]);
  const [accHolderName, setAccHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [addAccountModal, setaddAccountModal] = useState(false);
  const [editAccountDetails, seteditAccountDetails] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [editInvoiceDetails, setEditInvoiceDetails] = useState("");

  const [activeTab, setActiveTab] = useState("Bank Accounts");
  const tabs = [
    {
      name: "Bank Accounts",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-person-bounding-box"
          viewBox="0 0 16 16"
        >
          <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5" />
          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
        </svg>
      ),
    },
    {
      name: "Invoice",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-receipt"
          viewBox="0 0 16 16"
        >
          <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z" />
          <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" />
        </svg>
      ),
    },
    {
      name: "Profile",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-person-check mb-1"
          viewBox="0 0 16 16"
        >
          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
          <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z" />
        </svg>
      ),
    },
  ];
  const handleClickTab = (tab) => {
    setActiveTab(tab);
  };

  const handleGetBusinessDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      employeeId: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.getBusinessById, data);
    if (res?.success) {
      setBusinessDetails(res?.business);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetBusinessDetails();
    closeAllModals();
  }, []);

  const handleAddSettings = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      businessId: getCurrentWorkspaceId(),
      ifscCode: ifscCode,
      accountNumber: accountNumber,
      accountHolderName: accHolderName,
      updatedBy: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.editBusiness, data);
    if (res?.success) {
      handleGetBusinessDetails();
      toast.success("Account Details added successfully!");
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (businessDetails?.businessId?.accountNumber !== null) {
      setAccountNumber(businessDetails?.businessId?.accountNumber);
      setAccHolderName(businessDetails?.businessId?.accountHolderName);
      setIfscCode(businessDetails?.businessId?.ifscCode);
    }
  }, [businessDetails]);

  const handleGetInvoiceDetails = async () => {
    startApiCall(null, setloader3);
    const data = {
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      businessInvoiceEndpoints.getBusinessInvoice,
      data
    );
    if (res?.success) {
      setInvoiceDetails(res?.business);
      setloader3(false);
    } else {
      setloader3(false);
    }
  };
  useEffect(() => {
    handleGetInvoiceDetails();
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <span className="todoapp-title  link-dark">
                <h1 className="header-title-font">Other Settings</h1>
                {activeTab === "Bank Accounts" && (
                  <div className="ms-3">
                    <button
                      className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
                      onClick={() => {
                        setaddAccountModal(true);
                      }}
                    >
                      Add Account
                    </button>
                  </div>
                )}
              </span>
            </div>
            {activeTab === "Bank Accounts" && (
              <div className="todo-options-wrap">
                <div className="v-separator d-lg-inline-block d-none"></div>
                <span
                  className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="dsdsd"
                  data-bs-original-title="Refresh"
                >
                  <span
                    className="btn-icon-wrap"
                    onClick={handleGetBusinessDetails}
                  >
                    <span className="feather-icon">
                      <i
                        className="ri-refresh-line"
                        title="Refresh business list"
                      ></i>
                    </span>
                  </span>
                </span>
              </div>
            )}
          </header>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <div className="row g-4">
                  <div className="col-2">
                    <div className="d-flex flex-column gap-2">
                      {tabs.map((tab, index) => (
                        <div
                          key={index}
                          className={
                            activeTab === tab?.name
                              ? "custom_active custom_tab nowrap"
                              : "custom_tab nowrap"
                          }
                          onClick={() => handleClickTab(tab?.name)}
                        >
                          {tab?.svg} {tab?.name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-10">
                    {/* {!loader && (
                      <OthersSettingDetails
                        handleAddSettings={handleAddSettings}
                        setAccHolderName={setAccHolderName}
                        setAccountNumber={setAccountNumber}
                        setIfscCode={setIfscCode}
                        accHolderName={accHolderName}
                        accountNumber={accountNumber}
                        ifscCode={ifscCode}
                        loader2={loader2}
                        businessDetails={businessDetails}
                      />
                    )} */}
                    {activeTab === "Bank Accounts" && (
                      <>
                        {!loader && (
                          <OtherSettingTable
                            businessDetails={businessDetails}
                            setaddAccountModal={setaddAccountModal}
                            seteditAccountDetails={seteditAccountDetails}
                          />
                        )}
                      </>
                    )}
                    {activeTab === "Invoice" && (
                      <>
                        {!loader && (
                          <InvoiceDetails
                            invoiceDetails={invoiceDetails}
                            setInvoiceModal={setInvoiceModal}
                            setEditInvoiceDetails={setEditInvoiceDetails}
                          />
                        )}
                      </>
                    )}
                    {activeTab === "Profile" && (
                      <>
                        <ProfileDetails
                          isModuleReadWriteAccessForSettings={true}
                          activeTab={activeTab}
                        />
                      </>
                    )}
                    {loader && <DataLoader title="data is loading..." />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {addAccountModal && (
          <AddAccount
            businessDetails={businessDetails}
            setaddAccountModal={setaddAccountModal}
            handleGetBusinessDetails={handleGetBusinessDetails}
            editAccountDetails={editAccountDetails}
            seteditAccountDetails={seteditAccountDetails}
          />
        )}
        {invoiceModal && (
          <EditInvoiceModal
            setInvoiceModal={setInvoiceModal}
            editInvoiceDetails={editInvoiceDetails}
            handleGetInvoiceDetails={handleGetInvoiceDetails}
          />
        )}
      </div>
    </div>
  );
};

export default OtherSettings;
