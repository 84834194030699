import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import LeaveType from "../../pages/StaffManagement/StaffLeave/LeaveType/LeaveType";

const LeaveTypeRoutes = {
  path: "/leave",
  element: <Navbar />,
  children: [
    {
      path: "leavetype",
      element: <LeaveType />,
    },
  ],
};

export default LeaveTypeRoutes;
