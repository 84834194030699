import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getCurrentWorkspaceId,
  monthNames,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../api/ApiCall";
import { leaveRelatedEndPoints } from "../../../../../api/Endpoints";
import LeaveDetailsData from "./LeaveDetailsData";
import DataLoader from "../../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../../components/NoData/NoData";
import LeaveHistoryDetailsHeader from "./LeaveHistoryDetailsHeader";
import ApplyLeave from "./ApplyLeave/ApplyLeave";

const LeaveHistoryDetails = () => {
  const { id, monthname } = useParams();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [applyLeave, setApplyLeave] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [allLeaveCounts, setAllLeaveCounts] = useState([]);

  // MONTHLY FILTER
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(monthname);
  const year = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const date = new Date(`${monthname} 1, ${year}`);
  const monthNumber = date.getMonth() + 1;
  const lastDayOfMonth = new Date(year, thisMonth + 1, 0);
  const formattedStartDate = `${year}-${(monthname
    ? monthNumber
    : thisMonth + 1
  )
    .toString()
    .padStart(2, "0")}-01`;
  const formattedEndDate = `${year}-${(monthname ? monthNumber : thisMonth + 1)
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);

    const selectedMonthIndex = monthNames.indexOf(selectedMonth);
    if (selectedMonthIndex !== -1) {
      const month = selectedMonthIndex + 1;
      const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay
        .toString()
        .padStart(2, "0")}`;

      setstartDate(startDate);
      setendDate(endDate);
    }
  };

  // GET ALL LEAVE FOR STAFF FUNCTION API CALL
  const handleGetLeaveDetailsForStaff = async () => {
    startApiCall(null, setLoader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        employeeId: id,
        startDate: startDate ? startDate : formattedStartDate,
        endDate: endDate ? endDate : formattedEndDate,
      },
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.getLeavesForStaff,
      data
    );
    if (res?.success) {
      setLeaveDetails(res?.leave?.leave);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetLeaveDetailsForStaff();
  }, [selectedMonth]);

  const handleGetAllLeaveCounts = async () => {
    startApiCall(null, setLoader3);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
      employeeId: id,
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.getTotalLeaveCountTypeWise,
      sendData
    );
    if (res?.success) {
      setAllLeaveCounts(res?.leave);
      setLoader3(false);
    } else {
      setLoader3(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllLeaveCounts();
    }
  }, []);

  const handleRefresh = () => {
    handleGetLeaveDetailsForStaff();
    handleGetAllLeaveCounts();
  };

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  const totalLeaveTaken = allLeaveCounts?.reduce(
    (sum, data) => sum + data?.used,
    0
  );

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <LeaveHistoryDetailsHeader
            locationData={locationData}
            selectedMonth={selectedMonth}
            handleMonthChange={handleMonthChange}
            monthNames={monthNames}
            setApplyLeave={setApplyLeave}
            handleGetLeaveDetailsForStaff={handleRefresh}
          />
          <div className="d-flex flex-wrap mt-2 ps-4 gap-3">
            {allLeaveCounts?.map((data) => {
              return (
                <div
                  className="card col-md-3 h-70p w-200p"
                  style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
                >
                  <h5 className="font-3 text-center mt-3">
                    {data?.leaveTypeName}
                  </h5>
                  <p className="text-center text-dark">{data?.used}</p>
                </div>
              );
            })}
          </div>
          <div className="todo-body">
            <div
              data-simplebar
              className="nicescroll-bar d-flex flex-column gap-3"
            >
              <div className="contact-list-view d-flex flex-column gap-3">
                {!loader && leaveDetails?.length > 0 && (
                  <LeaveDetailsData leaveDetails={leaveDetails} />
                )}
                {!loader && leaveDetails?.length === 0 && (
                  <NoData title="No leaves found" />
                )}
                {(loader || loader3) && <DataLoader />}
              </div>
            </div>
          </div>
        </div>
        {applyLeave && (
          <ApplyLeave
            setApplyLeave={setApplyLeave}
            id={id}
            handleGetLeaveDetailsForStaff={handleRefresh}
            totalLeaveTaken={totalLeaveTaken}
            loader3={loader3}
          />
        )}
      </div>
    </div>
  );
};

export default LeaveHistoryDetails;
