import React, { useEffect, useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { activityGroupRelatedEndpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { useSelector } from "react-redux";

const AddActivityGroup = ({
  handleGetAllGroup,
  editAllGroups,
  seteditAllGroups,
}) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [groupName, setgroupName] = useState("");
  const [activityType, setActivityType] = useState("meeting");

  useEffect(() => {
    if (editAllGroups) {
      setgroupName(editAllGroups?.name);
      setActivityType(editAllGroups?.activityType);
    }
  }, [editAllGroups]);

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setgroupName("");
    seteditAllGroups("");
    setActivityType("meeting");
    const closeModalDom = document.getElementById("add_activity_group");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      name: groupName,
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
      activityType,
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.addActivityGroup,
      data
    );
    if (res?.success) {
      toast.success("Group created successfully!");
      handleReset();
      handleGetAllGroup();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      name: groupName,
      groupId: editAllGroups?._id,
      activityType
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.editActivityGroup,
      data
    );
    if (res?.success) {
      toast.success("Group edit successfully!");
      handleReset();
      handleGetAllGroup();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_activity_group"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editAllGroups !== "" ? "Edit Group" : "Create Group"}
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editAllGroups === "") {
                  handleAddGroup(e);
                } else {
                  handleEditGroup(e);
                }
              }}
            >
              <div className="row gx-3 px-3 w-100">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Group Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={groupName}
                      onChange={(e) => {
                        setgroupName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Activity Type</label>
                    <select
                      className="form-select form-select-md"
                      value={activityType}
                      onChange={(e) => setActivityType(e.target.value)}
                      required
                    >
                      <option value="meeting">Meeting</option>
                      <option value="call">Call</option>
                      <option value="task">Task</option>
                    </select>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? (
                    <ApiLoader />
                  ) : editAllGroups === "" ? (
                    "Add"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddActivityGroup;
