import React from "react";
import { Avatar } from "../Avatar/Avatar";

const AssigneEmployeeDetails = ({ taskDetails }) => {
  return (
    <div
      role="menu"
      className="dropdown-menu simple-scrollbar"
      style={{ maxHeight: "25vh", overflow: "auto", }}
    >
      {taskDetails?.map((data) => {
        return (
          <div className="d-flex justify-content-between">
            <div className="d-flex dropdown-item text-capitalize">
              <Avatar
                name={data?.name}
                count={1}
                image={data?.image}
                size="sm"
                color="primary"
              />
              <span className="ms-4 mt-1">{data?.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AssigneEmployeeDetails;
