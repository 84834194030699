import React from "react";

export const Status = (props) => {
  const {
    selectedTaskStatus,
    handleChangeStatus,
    taskStatus,
    handleGetAllTaskStatus,
    taskStatusLoader,
  } = props;
  return (
    <div className="selectable-dropdown">
      <div className="dropdown selectable-dropdown">
        <span
          style={{ border: `1px solid ${selectedTaskStatus?.color}` }}
          className={`me-2 fs-7 fw-medium  text-dark text-capitalize rounded p-2`}
          aria-expanded="false"
          type="button"
          data-bs-toggle="dropdown"
          onClick={handleGetAllTaskStatus}
        >
          <svg
            className="circle"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill={selectedTaskStatus?.color}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="15" height="15" fill={selectedTaskStatus?.color} />
          </svg>
          &nbsp; {selectedTaskStatus?.statusName}
        </span>
        <div
          role="menu"
          className="dropdown-menu"
          style={{ maxHeight: "40vh", overflow: "auto" }}
        >
          {taskStatusLoader ? (
            <span className="text-center">Loading...</span>
          ) : (
            <>
              <div
                onClick={() => {
                  handleChangeStatus({
                    statusName: "All Status",
                    color: "#c8c8c8",
                  });
                }}
                className="dropdown-item text-capitalize"
              >
                <svg
                  className="circle"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="#c8c8c8"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="15" height="15" />
                </svg>
                &nbsp;&nbsp; All Status&nbsp;
                {selectedTaskStatus?.statusName === "All Status" && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#68a660"
                    className="bi bi-check2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>
                )}
              </div>
              {taskStatus?.map((status, index) => (
                <div
                  onClick={() => {
                    handleChangeStatus(status);
                  }}
                  className="dropdown-item text-capitalize"
                  key={index}
                >
                  <svg
                    className="circle"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill={status?.color}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="15" height="15" fill={status?.color} />
                  </svg>
                  &nbsp;&nbsp;
                  {status?.statusName}&nbsp;&nbsp;
                  {status?.statusName === selectedTaskStatus?.statusName && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#68a660"
                      className="bi bi-check2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
