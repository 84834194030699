import React, { useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import {
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";
import { OrderRelatedEndPoints } from "../../../api/Endpoints";
import { useSelector } from "react-redux";

function ReturnOrderModal(props) {
  const { user } = useSelector((state) => state);
  const { setreturnOrder, orderData, handleGetOrders } = props;
  const [description, setDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  //RETURN ORDER

  const handleReturnOrder = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      orderId: orderData?._id,
      reason: description,
      actionBy: user?._id,
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.returnOrder, data);
    if (res?.success) {
      toast.success("Order returned successfully!");
      setreturnOrder(false);
      handleGetOrders();
      setloader(false);
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block flex" }}
    >
      <form onSubmit={handleReturnOrder}>
        <div
          className="modal-dialog modal-dialog-centered w-500p"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <h4 className="mb-4 text_center">Confirmation</h4>
              <div className="row gx-3">
                <label className="form-label text_center">
                  Are You Sure You Want To Return This Order ?
                </label>
              </div>
              <div className="col-md-12 mt-2">
                <div className="form-group">
                  <div className="form-label-group">
                    <label className="form-label">
                      Reason for Return {reuiredField}
                    </label>
                  </div>
                  <textarea
                    className="form-control"
                    rows="3"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    required
                    placeholder="Please provide a reason for return .."
                    value={description}
                    maxLength={200}
                  ></textarea>
                </div>
              </div>
              <div className="text_center"></div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
            <div
              className="px-4 pb-4"
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                disabled={loader}
                className="btn btn-rounded btn-secondary col-sm-5"
                onClick={() => {
                  setreturnOrder(false);
                }}
              >
                Cancel
              </button>
              <button
                disabled={loader}
                className="btn btn-rounded btn-primary col-sm-5"
                type="submit"
              >
                {loader ? <ApiLoader /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ReturnOrderModal;
