import React from "react";

function AddCreditItemTable({ invoiceItems }) {
  return (
    <table className="table table-bordered text-center">
      {invoiceItems?.length > 0 ? (
        <>
          {" "}
          <thead>
            <tr>
              <th>Item name</th>
              <th>Item code</th>
              <th>Unit</th>
              <th>Selling Price</th>
              <th>Quantity</th>
              <th>Discount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoiceItems?.map((item) => {
              return (
                <tr>
                  <td>{item?.itemId?.itemName}</td>
                  <td>{item?.itemId?.itemCode}</td>
                  <td>{item?.itemId?.unit}</td>
                  <td>{item?.itemId?.salePrice}</td>
                  <td>{item?.quantity}</td>
                  <td>{item?.itemId?.discount ? item?.itemId?.discount : 0}</td>
                  <td>{item?.total?.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
        </>
      ) : (
        <div className="no-data-wrapper p-7">
          <h3 className="fw-semibold text-capitalize">No items Added</h3>
        </div>
      )}
    </table>
  );
}

export default AddCreditItemTable;
