import React from "react";
import { dateAndTimeFormatter, getName } from "../../../../global-functions/globalFunctions";
import { skeletonsTable } from "../../../../components/Constant/Skeleton";
import { Avatar } from "../../../../components/Avatar/Avatar";

const StatusBody = ({
  group,
  allStatus,
  seteditAllStatus,
  loader,
  bluePrintData,
  loader3,
  setcreateStatus,
  user,
  isModuleReadWriteAccessForStatusGroup,
}) => {
  return (
    <>
      <div className="simple-scrollbar" style={{ overflowY: "scroll" }}>
        {!loader && allStatus?.length > 0 && (
          <table id="datable_1" className="table nowrap w-100 text-center mb-0">
            <thead>
              <tr className="text-center">
                <th>No .</th>
                <th className="text-start">Step Name</th>
                <th>Created on</th>
                <th>Created by</th>
                {isModuleReadWriteAccessForStatusGroup && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {allStatus?.map((data, index) => {
                return (
                  <tr>
                    <td>{index + 1} .</td>
                    <td className="text-start">
                      <div className="text-capitalize text-truncate pointer mw-300p">
                        {data?.name}
                      </div>
                    </td>
                    <td>{dateAndTimeFormatter(data?.createdAt)}</td>
                    <td>
                      {data?.createdBy ? (
                        <>
                          <Avatar
                            name={data?.createdBy?.name}
                            count={1}
                            image={data?.createdBy?.image}
                            size="xss"
                            color="primary"
                          />
                          <span className="ms-1">{getName(data?.createdBy , user?._id)}</span>
                        </>
                      ) : (
                        "System"
                      )}
                    </td>
                    {isModuleReadWriteAccessForStatusGroup && (
                      <td>
                        <span
                          className="btn btn-icon"
                          onClick={() => {
                            seteditAllStatus(data);
                            setcreateStatus(true);
                          }}
                        >
                          <span className="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path
                                fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                              />
                            </svg>
                          </span>
                        </span>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {loader && skeletonsTable(5, 5)}
      </div>

      {/* {!loader3 && (
        <div className="py-5 px-10" style={{ background: "#E3EDFF" }}>
          <div className="row g-3">
            <div className="col-12">
              <p className="fw-semibold font-7 text-dark text-center">
                Blueprint
              </p>
            </div>
            {group?.InitialStatus?.map((item, index) => {
              const statusByGroupId = bluePrintData.filter(
                (element) => element?.initialStatusId === item?._id
              );

              return (
                <div className="col" key={item?._id}>
                  <div className="d-flex flex-column justify-content-center">
                    <div
                      className="d-flex justify-content-center"
                      style={{ position: "relative" }}
                    >
                      <span className="bg-white border rounded-5 px-3 py-1 text-dark text-capitalize text-center w-130p">
                        {item?.name}
                      </span>
                    </div>
                    {statusByGroupId?.map((data, index) => {
                      return (
                        <div
                          className="d-flex flex-column justify-content-center"
                          key={index}
                        >
                          <span className="icon text-center text-dark font-10">
                            <span className="feather-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-down"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"
                                />
                              </svg>
                            </span>
                          </span>

                          <CustomTooltip text={data?.name}>
                            <div className="d-flex justify-content-center">
                              <span className="bg-white mw-150p text-truncate border rounded-5 px-3 py-1 text-dark text-capitalize text-center">
                                {data?.name}
                              </span>
                            </div>
                          </CustomTooltip>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {loader3 && <DataLoader title="data is loading..." />} */}
    </>
  );
};

export default StatusBody;
