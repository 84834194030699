import React, { useEffect, useState } from "react";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { ShiftRelatedEndPoints } from "../../../../api/Endpoints";
import { navigateToShiftTiming } from "../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

function WorkingDuration(props) {
  const {
    selectedDays,
    setSelectedDays,
    selectSetup2,
    workingDaysType,
    setworkingDaysType,
    completeFirstStep,
    selectedOption,
    setSelectedOption,
    settlementDate,
    setsettlementDate,
    setworkingType,
    workingType,
    modalType,
    selectedShift,
    setselectedShift,
  } = props;
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [allShifts, setAllShifts] = useState([]);

  const handleGetAllShifts = async () => {
    startApiCall(null, setloader);
    const sendData = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      ShiftRelatedEndPoints.getStaffShift,
      sendData
    );
    if (res?.success) {
      setAllShifts(res?.staff?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllShifts();
  }, []);

  const handleOptionChange = (event) => {
    setworkingDaysType(event.target.value);
  };

  const toggleDay = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const handlePaymentOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setworkingDaysType("");
  };

  const isDaySelected = (day) => selectedDays.includes(day);

  useEffect(() => {
    if (modalType === "add") {
      setselectedShift(allShifts?.find((shift) => shift?.isDefault)?._id);
    }
  }, [modalType]);

  return (
    <div
      className="d-flex flex-column justify-content-between border rounded-7 p-4 w-100 mh-450p"
      style={{ overflow: "auto" }}
    >
      <div className="d-flex flex-column gap-1">
        <div className="d-flex flex-column gap-3">
          <div className="d-flex flex-column">
            <p className="text-dark fs-6 ">Select payment type</p>
            <p className="fs-7 mt-1">
              Choose a method by which you pay your employees
            </p>
          </div>
          <div className="d-flex flex-column">
            <div className="form-check">
              <input
                className="form-check-input circle pointer"
                type="radio"
                id="radio1"
                name="salaryoptions"
                value="monthly"
                checked={selectedOption === "monthly"}
                onChange={handlePaymentOptionChange}
                disabled={modalType === "edit"}
              />
              <label className="form-check-label fs-6 text-dark ">
                Monthly
              </label>
            </div>
            <p className="fs-7 ms-4">
              Employees are paid Salary basis per month.
            </p>
            {selectedOption === "monthly" && (
              <>
                <div className="col-sm-12 ms-5 mt-2">
                  <div className="form-group">
                    <label className="form-label fs-6">
                      Select settlement Date
                      <p className="fs-7">
                        You will receive reminders to pay your employees every
                        month on this date
                      </p>
                    </label>

                    <div class="container">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="row">
                            <div class="col-sm-3">
                              <div class="d-flex align-items-center border rounded-5 text-dark">
                                <span class="d-flex align-items-center justify-content-center py-2 px-4">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-calendar2-check-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zm9.954 3H2.545c-.3 0-.545.224-.545.5v1c0 .276.244.5.545.5h10.91c.3 0 .545-.224.545-.5v-1c0-.276-.244-.5-.546-.5zm-2.6 5.854a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                  </svg>
                                </span>
                                <div class="d-flex align-items-center justify-content-between w-100 py-2 border-start px-4">
                                  <select
                                    name="numbers"
                                    className="border-0"
                                    onChange={(e) => {
                                      setsettlementDate(e.target.value);
                                    }}
                                    value={settlementDate}
                                    disabled={modalType === "edit"}
                                  >
                                    {Array.from(
                                      { length: 30 },
                                      (_, i) => i + 1
                                    ).map((number) => (
                                      <option key={number} value={number}>
                                        {number}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-4 pt-2">
                              <span>of every month</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-1 mt-2 ms-5">
                  <p className="text-dark fs-6 ">
                    Choose working days in a month
                  </p>
                  <p className="fs-7">
                    We can help you calculate the total number of pay days each
                    employee with the following information-
                  </p>
                </div>

                <div className="d-flex flex-column gap-1 ms-6">
                  <div class="form-check">
                    <input
                      class="form-check-input circle pointer"
                      type="radio"
                      id="radio1"
                      name="Daily wages"
                      value="dailyWages"
                      checked={workingDaysType === "dailyWages"}
                      onChange={handleOptionChange}
                      disabled={modalType === "edit"}
                    />
                    <label class="form-check-label text-dark">
                      Daily wages
                    </label>
                  </div>
                  <p className="ms-4 fs-7">
                    In this system the months with 31 days will pay the employee
                    for the extra one day.
                  </p>
                </div>
                <div className="d-flex flex-column gap-1 ms-6">
                  <div class="form-check">
                    <input
                      class="form-check-input circle pointer"
                      type="radio"
                      id="radio2"
                      name="Monthly"
                      value="30_days"
                      checked={workingDaysType === "30_days"}
                      onChange={handleOptionChange}
                      disabled={modalType === "edit"}
                    />
                    <label class="form-check-label text-dark">
                      Monthly 30 days
                    </label>
                  </div>
                  <p className="ms-4 fs-7">
                    In this system every month has 30 payable days
                  </p>
                </div>
                <div className="d-flex flex-column gap-1 ms-6">
                  <div class="form-check">
                    <input
                      class="form-check-input circle pointer"
                      type="radio"
                      id="radio3"
                      name="Weekly Off"
                      value="weeklyOff"
                      checked={workingDaysType === "weeklyOff"}
                      onChange={handleOptionChange}
                      disabled={modalType === "edit"}
                    />
                    <label class="form-check-label text-dark">Weekly off</label>
                  </div>
                  <p className="ms-4 fs-7">
                    In this system the employee will not be paid for the weekly
                    off days
                  </p>
                </div>
                {/* <div className="d-flex flex-column gap-1 ms-6">
                  <div class="form-check">
                    <input
                      class="form-check-input circle pointer"
                      type="radio"
                      id="radio3"
                      name="Custom"
                      value="custom"
                      checked={workingDaysType === "custom"}
                      onChange={handleOptionChange}
                      disabled={modalType === "edit"}
                    />
                    <label class="form-check-label text-dark">
                      Custom days
                    </label>
                  </div>
                  <p className="ms-4 fs-7">
                    Choose the total number of days an employ will be working
                  </p>
                </div> */}
              </>
            )}
          </div>
          <div className="d-flex flex-column">
            <div className="form-check">
              <input
                className="form-check-input circle pointer"
                type="radio"
                id="radio2"
                name="salaryoptions"
                value="payPerHour"
                checked={selectedOption === "payPerHour"}
                onChange={handlePaymentOptionChange}
                disabled={modalType === "edit"}
              />
              <label className="form-check-label fs-6 text-dark ">Hourly</label>
            </div>
            <p className="fs-7 ms-4">Employees are paid per hourly basis.</p>
            {selectedOption === "payPerHour" && (
              <div className="col-sm-12 ms-5 mt-2">
                <div className="form-group">
                  <label className="form-label fs-6">
                    Select settlement type
                    <p className="fs-7">
                      Do you settle the salary monthly, weekly or daily?
                    </p>
                  </label>

                  <span className="d-flex flex-column gap-1">
                    <div className="d-flex align-items-center border rounded-5 w-120p text-dark">
                      <div className="d-flex align-items-center justify-content-between w-120p py-2 border-start px-4">
                        <select
                          name="Type"
                          className="border-0"
                          onChange={(e) => {
                            setworkingType(e.target.value);
                          }}
                          value={workingType}
                          disabled={modalType === "edit"}
                        >
                          <option value={"dailyWages"}>Daily</option>
                          <option value={"30_days"}>Monthly</option>
                        </select>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* <div className="d-flex flex-column">
            <div className="form-check">
              <input
                className="form-check-input circle pointer"
                type="radio"
                name="salaryoptions"
                value="payPerJob"
                checked={selectedOption === "payPerJob"}
                onChange={handlePaymentOptionChange}
                
              />
              <label className="form-check-label fs-6 text-dark ">
                Pay per job
              </label>
            </div>
            <p className="fs-7 ms-4">Employees are paid as per job.</p>
          </div> */}
        </div>

        <div className="d-flex flex-column gap-1 mt-2">
          <p className="text-dark fs-6 ">Working Shift</p>
          <p className="fs-7">Select start time and end time</p>
        </div>

        <span className="d-flex flex-column gap-1">
          {allShifts?.length > 0 ? (
            <div className="d-flex align-items-center border rounded-5 col-sm-7 gap-2 text-dark">
              <span className="d-flex align-items-center justify-content-center py-2 px-4 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-clock"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                </svg>
              </span>

              <div className="d-flex align-items-center justify-content-between py-2 border-start px-4">
                <select
                  name="Type"
                  className="border-0"
                  value={selectedShift}
                  onChange={(e) => {
                    setselectedShift(e.target.value);
                  }}
                >
                  <option value={""}>Select Shift</option>
                  {allShifts?.map((shift, index) => (
                    <option key={index} value={shift?._id}>
                      {" "}
                      {dateAndTimeFormatter(shift?.startTime)
                        .split(",")[1]
                        ?.trim()}{" "}
                      -{" "}
                      {dateAndTimeFormatter(shift?.endTime)
                        .split(",")[1]
                        ?.trim()}{" "}
                      <span className="text-capitalize ">
                        &nbsp;&nbsp;{shift?.shiftName}
                      </span>
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            <span className="text-dark fw-semibold ">
              Please{" "}
              <span
                className="text-underline text-primary pointer"
                onClick={() => {
                  const closeModalDom =
                    document.getElementById("attendance_modal");
                  if (closeModalDom) closeModalDom.click();
                  navigate(navigateToShiftTiming());
                }}
              >
                create a shift
              </span>{" "}
              to continue
            </span>
          )}
        </span>
        {/* {selectedHours && (
          <>
            <div className="d-flex flex-column gap-1 mt-2">
              <p className="fs-7">Total Hours</p>
            </div>
            <div className="d-flex align-items-center border rounded-5 w-200p text-dark">
              <span className="d-flex align-items-center justify-content-center py-2 px-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-clock"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                </svg>
              </span>
              <div className="d-flex align-items-center justify-content-between w-140p py-2 border-start px-4 bg-secondary-light-5 rounded-5">
                <p> {selectedHours > 0 ? selectedHours : 0} Hrs</p>
              </div>
            </div>
          </>
        )} */}
        <div className="d-flex flex-column mt-3">
          <p className="text-dark fs-6">
            Select Non-Working days for this shift &nbsp;&nbsp;
            {selectedDays.map((day) => (
              <div key={day} className="badge bg-primary me-2">
                {day}
              </div>
            ))}
          </p>
        </div>
        {modalType !== "edit" && (
          <div className="d-flex align-items-center border rounded-5 w-350p text-dark p-1 pointer mb-1 mt-2">
            {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
              <div
                key={day}
                className={`flex-fill text-center ${
                  isDaySelected(day) ? "text-primary" : ""
                }`}
                onClick={() => toggleDay(day)}
              >
                {day}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2">
        <button
          className="btn btn-sm btn-primary px-4"
          onClick={selectSetup2}
          disabled={!completeFirstStep}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default WorkingDuration;
