import React from "react";
import { Avatar } from "../../components/Avatar/Avatar";
import { dateAndTimeFormatter } from "../../global-functions/globalFunctions";

const UserInfoModal = ({ user }) => {
  return (
    <>
      <div
        id="user_information_modal"
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between">
                  <div className="">
                    <Avatar
                      name={user?.name}
                      count={1}
                      image={user?.image}
                      size="lg"
                      color="primary"
                    />
                  </div>
                  <div>
                    <span className="badge badge-light badge-sm">Offline</span>
                  </div>
                </div>
                <div className="d-flex flex-column gap-3">
                  <p className="fs-4 text-dark fw-semibold text-capitalize">
                    {user?.name}
                  </p>
                  <div className="d-flex align-items-center gap-3 text-dark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                    <p>{user?.email}</p>
                  </div>
                  <div className="d-flex align-items-center gap-3 text-dark">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                    <p>{dateAndTimeFormatter(new Date())}</p>
                  </div>
                </div>
                {/* <div className="border rounded p-2 w-100 text-center text-dark shadow-sm fw-semibold fs-7">
                  View profile
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfoModal;
