import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  dateFormatter,
} from "../../../../global-functions/globalFunctions";

const AllRequestDetails = ({ leaveRequestDetails, setIsOpenDetailsModal }) => {
  return (
    <div className="details-modal mb-5">
      <div className="nicescroll-bar">
        <div className="d-flex justify-content-between">
          <span className="file-name">Leave Details</span>
          <button
            onClick={() => setIsOpenDetailsModal(false)}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="d-flex justify-content-center mt-1">
          <Avatar
            name={leaveRequestDetails?.employeeId?.name}
            count={1}
            image={leaveRequestDetails?.employeeId?.image}
            size="md"
            color="primary"
          />
        </div>
        <div className="tab-content mt-0">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple">
              <div className="card py-0">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#fl_info"
                    aria-expanded="true"
                  >
                    Specification
                  </a>
                </div>
                <div id="fl_info" className="collapse show">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span>Leave Type : </span>
                        <span className="text-capitalize">
                          {leaveRequestDetails?.leaveType?.leaveName}
                        </span>
                      </li>
                      <li>
                        <span>Allowed days : </span>
                        <span>
                          {leaveRequestDetails?.leaveType?.allowedDays}
                        </span>
                      </li>
                      <li>
                        <span className="mw-300p text-secondary">
                          Leave Dates :{" "}
                        </span>
                        <span className="text-dark d-flex flex-wrap justify-content-start mt-1">
                          {leaveRequestDetails?.dates?.map((data, index) => (
                            <div key={index} className="col-md-4 mb-2">
                              <span
                                className="btn btn-sm d-flex align-items-center border w-100p bg-primary ms-3"
                                style={{ color: "#ffffff" }}
                              >
                                <React.Fragment key={index}>
                                  {dateFormatter(data)}
                                  {index < leaveRequestDetails.dates.length - 1}
                                </React.Fragment>
                              </span>
                            </div>
                          ))}
                        </span>
                      </li>

                      <li>
                        <span>Date Created :</span>
                        <span>
                          {dateAndTimeFormatter(leaveRequestDetails?.createdAt)}
                        </span>
                      </li>
                      <li>
                        <span>Status :</span>
                        <span
                          className={`badge badge-soft-${
                            leaveRequestDetails?.status === "approved"
                              ? "green"
                              : leaveRequestDetails?.status === "pending"
                              ? "yellow"
                              : "red"
                          } my-1  me-2`}
                        >
                          {leaveRequestDetails?.status}
                        </span>
                      </li>
                      {leaveRequestDetails?.status !== "pending" && (
                        <li>
                          <span>Action by :</span>
                          <span>
                            <Avatar
                              name={leaveRequestDetails?.actionBy?.name}
                              count={1}
                              image={leaveRequestDetails?.actionBy?.image}
                              size="xss"
                              color="primary"
                            />
                            &nbsp; {leaveRequestDetails?.actionBy?.name}
                          </span>
                        </li>
                      )}
                      {leaveRequestDetails?.status !== "pending" && (
                        <li>
                          <span>Action on : </span>
                          <span>
                            {dateAndTimeFormatter(
                              leaveRequestDetails?.actionOn
                            )}
                          </span>
                        </li>
                      )}
                      <li>
                        <span>Description : </span>
                        <span>{leaveRequestDetails?.description}</span>
                      </li>
                      {leaveRequestDetails?.status === "rejected" && (
                        <li>
                          <span>Reason : </span>
                          <span>{leaveRequestDetails?.remark}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRequestDetails;
