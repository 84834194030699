import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "../../components/Avatar/Avatar";
import { dateAndTimeFormatter, getName } from "../../global-functions/globalFunctions";
import { navigateToManagePeople } from "../../Navigation/Navigation";
import { useSelector } from "react-redux";

const WorkspaceDetailsModal = ({ handleDetailsDrawer, selectWorkspace }) => {
  const user = useSelector((state) => state.user);
  return (
    <div className="details-modal pb-7">
      <div className="nicescroll-bar simple-scrollbar">
        <div className="d-flex justify-content-between">
          <span className="file-name">Workspace Details</span>
          <button
            onClick={handleDetailsDrawer}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
          <div className="d-flex justify-content-center mt-1">
            <Avatar
              name={selectWorkspace?.name}
              count={1}
              image={selectWorkspace?.image}
              size="md"
              color="primary"
            />
          </div>
          <div className="tab-content mt-0">
            <div className="tab-pane fade show active" id="tab_info">
              <div className="collapse-simple">
                <div className="card py-0">
                  <div className="card-header">
                    <a
                      role="button"
                      data-bs-toggle="collapse"
                      href="#fl_info"
                      aria-expanded="true"
                    >
                      Specification
                    </a>
                  </div>
                  <div id="fl_info" className="collapse show">
                    <div className="card-body">
                      <ul className="fm-info">
                        <li>
                          <span>Name : </span>
                          <span>{selectWorkspace?.name}</span>
                        </li>
                        <li>
                          <span>Unique Code : </span>
                          <span>{selectWorkspace?.businessUniqueId}</span>
                        </li>
                        <li>
                          <span>Date Modified : </span>
                          <span>
                            {dateAndTimeFormatter(selectWorkspace?.updatedAt)}
                          </span>
                        </li>
                        <li>
                          <span>Created by :</span>
                          <span>
                            <Avatar
                              name={selectWorkspace?.createdBy?.name}
                              count={1}
                              image={selectWorkspace?.createdBy?.image}
                              size="xss"
                              color="primary"
                            />
                            &nbsp;
                            {getName(selectWorkspace?.createdBy, user?._id)}
                          </span>
                        </li>
                        <li>
                          <span>Date Created :</span>
                          <span>
                            {dateAndTimeFormatter(selectWorkspace?.createdAt)}
                          </span>
                        </li>
                        {selectWorkspace?.approvalRequest?.status ===
                          "approved" && (
                          <li>
                            <span>Total Spaces :</span>
                            <span>
                              {selectWorkspace?.spaces?.length > 0
                                ? selectWorkspace?.spaces?.length + " List"
                                : "No List "}
                            </span>
                          </li>
                        )}

                        <li>
                          <span>Current Status :</span>
                          <span
                            className={`badge badge-soft-${
                              selectWorkspace?.approvalRequest?.status ===
                              "approved"
                                ? "green"
                                : selectWorkspace?.approvalRequest?.status ===
                                  "pending"
                                ? "yellow"
                                : "red"
                            } my-1  me-2`}
                          >
                            {selectWorkspace?.approvalRequest?.status}
                          </span>
                        </li>
                        {selectWorkspace?.approvalRequest?.status !==
                          "pending" && (
                          <li>
                            <span>Action by :</span>
                            <span>
                              <Avatar
                                name={selectWorkspace?.actionBy?.name}
                                count={1}
                                image={selectWorkspace?.actionBy?.image}
                                size="xss"
                                color="primary"
                              />
                              &nbsp; {getName(selectWorkspace?.actionBy , user?._id)}
                            </span>
                          </li>
                        )}
                        <li>
                          <span>Address : </span>
                          <span>
                            {selectWorkspace?.city} - {selectWorkspace?.state}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {selectWorkspace?.approvalRequest?.status === "approved" && (
                  <div className="card">
                    <div className="row">
                      <span className="text-primary">
                        Shared with
                        <span className="ms-2">People</span>
                        <div className="avatar-group avatar-group-md avatar-group-overlapped me-3 pointer ms-2">
                          <div className="avatar avatar-soft-primary avatar-rounded">
                            <Link
                              to={navigateToManagePeople()}
                              className="initial-wrap"
                            >
                              {selectWorkspace?.employees?.length}
                            </Link>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default WorkspaceDetailsModal;
