import React, { useEffect, useState } from "react";
import OrderApprovalHeader from "./OrderApprovalHeader";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import OrderApprovalTable from "./OrderApprovalTable";
import moment from "moment";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { OrderRelatedEndPoints } from "../../../api/Endpoints";
import NoData from "../../../components/NoData/NoData";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import CustomerDetailsModal from "./CustomerDetailsModal";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const OrderApprovals = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [allOrders, setallOrders] = useState(null);
  const [startDate, setstartDate] = useState(currentDate);
  const [endDate, setendDate] = useState(currentDate);
  const [searchKey, setsearchKey] = useState("");
  const [itemDetails, setItemDetails] = useState(null);
  const [isCustomerModal, setisCustomerModal] = useState(false);
  const [typeFilter, settypeFilter] = useState("pending");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForOrderApprovals =
    allModuleAccess?.[PRIVATEROUTES.ORDERAPPROVALS];

  // Get Order Function
  const handleGetOrders = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: searchKey,
        startDate,
        endDate,
        isApproved: typeFilter,
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.getOrders, data);
    if (res?.success) {
      setallOrders(res?.orders?.data);
      setcount(res?.orders?.totalCounts);
      settotalPages(res?.orders?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetOrders();
    closeAllModals();
  }, [page, pageLimit, searchKey, typeFilter]);

  const handleResetFilters = () => {
    setstartDate(currentDate);
    setendDate(currentDate);
    handleGetOrders();
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <OrderApprovalHeader
            searchKey={searchKey}
            setsearchKey={setsearchKey}
            handleGetOrders={handleGetOrders}
            handleResetFilters={handleResetFilters}
            currentDate={currentDate}
            loader={loader}
            setstartDate={setstartDate}
            startDate={startDate}
            endDate={endDate}
            setendDate={setendDate}
            settypeFilter={settypeFilter}
            typeFilter={typeFilter}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allOrders?.length > 0 && (
                <OrderApprovalTable
                  allOrders={allOrders}
                  page={page}
                  pageLimit={pageLimit}
                  handleGetOrders={handleGetOrders}
                  itemDetails={itemDetails}
                  setItemDetails={setItemDetails}
                  setisCustomerModal={setisCustomerModal}
                  isModuleReadWriteAccessForOrderApprovals={
                    isModuleReadWriteAccessForOrderApprovals
                  }
                />
              )}

              {loader && <DataLoader title="Fetching Orders..." />}
              {!loader && allOrders?.length === 0 && (
                <NoData title="No Order found" />
              )}
              {!loader && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allOrders?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
        {isCustomerModal && (
          <CustomerDetailsModal
            setisCustomerModal={setisCustomerModal}
            itemDetails={itemDetails}
            isCustomerModal={isCustomerModal}
          />
        )}
      </div>
    </div>
  );
};

export default OrderApprovals;
