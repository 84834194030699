import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import AllComplaint from "../../pages/Complaints/AllComplaints/AllComplaints";

const AllComplaintRoutes = {
  path: "/grievance",
  element: <Navbar />,
  children: [
    {
      path: "allgrievance",
      element: <AllComplaint />,
    },
  ],
};

export default AllComplaintRoutes;
