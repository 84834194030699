import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import { getTimeAgo } from "../../../../global-functions/globalFunctions";

const CommentSection = (props) => {
  const {
    handleGetAllComments,
    taskEditLoader,
    allComments,
    handleShowReply,
    setReply,
    setShowAddReply,
    handleAddComment,
    showAddReply,
    reply,
  } = props;
  const convertTextToLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const newlineRegex = /\n/g;

    if (text) {
      text = text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });

      text = text.replace(newlineRegex, "<br>");
    }

    return text;
  };

  return (
    <div>
      {" "}
      <div className="d-flex justify-content-between">
        {taskEditLoader && <span>Loading...</span>}
        <div className="d-flex align-items-center justify-content-end w-100 my-1">
          <span
            className="btn btn-sm btn-outline-light btn-icon btn-rounded"
            onClick={handleGetAllComments}
          >
            <span className="feather-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-clockwise"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                />
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
              </svg>
            </span>
          </span>
        </div>
      </div>
      <div className="comment-block">
        <>
          {allComments?.map((com, index) => {
            return (
              <div className="media bg-light rounded-4 mb-2 p-2 me-1">
                <div className="media-body mb-2">
                  <div className="d-flex flex-column ms-2">
                    <span className="cm-name d-flex align-items-center gap-1 fw-medium mb-1">
                      <Avatar
                        name={com?.commentator?.name}
                        count={1}
                        image={com?.commentator?.image}
                        size="xxs"
                        color="primary"
                      />
                      {com?.commentator?.name}
                      <span className="font-2 ms-1 text-smoke f2-light">
                        {getTimeAgo(com?.time)}
                      </span>
                    </span>
                    <span className="ms-1 comment-text">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-chat-dots"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                      </svg>
                      &nbsp;
                      <span
                        className="text-dark"
                        dangerouslySetInnerHTML={{
                          __html: convertTextToLinks(com?.comment),
                        }}
                      ></span>
                    </span>
                    <div className="fs-7 mt-1 ms-1 d-flex align-items-center gap-3">
                      <span
                        className="text-primary pointer"
                        onClick={() => {
                          if (com?.replies?.length > 0) {
                            handleShowReply(com);
                          }
                        }}
                      >
                        {com?.replies?.length > 0 ? com?.replies?.length : "No"}{" "}
                        {com?.replies?.length >= 2 ? "Replies" : "Reply"}
                      </span>
                      <span
                        className=" pointer"
                        onClick={() => {
                          setReply("");
                          setShowAddReply(index);
                        }}
                      >
                        Add reply
                      </span>
                      {showAddReply === index && (
                        <div className="d-flex align-items-center gap-1">
                          <input
                            className="form-control py-0 px-2"
                            type="text"
                            required
                            onChange={(e) => setReply(e.target.value)}
                          />
                          <button
                            className="btn btn-xs btn-light"
                            onClick={() => {
                              setReply("");
                              setShowAddReply();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-xs btn-primary"
                            disabled={reply === "" || taskEditLoader}
                            onClick={(e) => {
                              handleAddComment(e, com?._id);
                            }}
                          >
                            {taskEditLoader ? <ApiLoader /> : "Send"}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      </div>
      {allComments?.length === 0 && !taskEditLoader && (
        <span className="font-4 text-dark">No Comments</span>
      )}
    </div>
  );
};

export default CommentSection;
