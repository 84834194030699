import React from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

function CustomDateFilterMeeting({
  dateModal,
  handleGetMeeting,
  dateType,
  startDate,
  setstartDate,
  endDate,
  setendDate,
  mainLoader,
  handleResetFilters,
  setdateType,
}) {
  return (
    <div
      className={` custom-modal  ${dateModal ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: dateModal ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h5>Filter by Custom Date</h5>
            <div className="dropdown-divider"></div>

            {dateType === "Date Range" && (
              <div className="row gx-3 mt-3">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Start Date</label>
                    <input
                      className="form-control"
                      name="single-date-pick"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        setstartDate(e.target.value);
                        setendDate("");
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">End Date</label>
                    <input
                      className="form-control"
                      name="single-date-pick"
                      type="date"
                      value={endDate}
                      onChange={(e) => {
                        setendDate(e.target.value);
                      }}
                      min={startDate}
                    />
                  </div>
                </div>
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      handleResetFilters("Today", false);
                      setdateType("Today");
                    }}
                  >
                    Close
                  </button>
                  <button
                    disabled={startDate === "" || endDate === ""}
                    className="btn btn-primary"
                    onClick={() => {
                      handleGetMeeting();
                    }}
                  >
                    {mainLoader ? <ApiLoader /> : "Filter"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomDateFilterMeeting;
