import React, { useEffect, useState } from "react";
import {
  dateAndTimeFormatter,
  getName,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";
import { MeetingRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { navigateToIndividualSpaces } from "../../../Navigation/Navigation";

const MeetingDetailsModal = ({
  handleDetailsDrawer,
  meeting,
  handleStatusWiseColor,
  user,
  setuserInfoData,
  navigate,
}) => {
  const [loader, setLoader] = useState(false);
  const [allParticipants, setallParticipants] = useState([]);

  const handleGetAllParticipants = async () => {
    startApiCall(null, setLoader);
    const data = {
      meetingId: meeting?._id,
      page: 1,
      limit: 5000,
    };
    const res = await ApiCall("get", MeetingRelatedEndPoints.getParticipants, {
      params: { ...data },
    });
    if (res?.success) {
      setallParticipants(res?.meetings);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllParticipants();
  }, []);

  return (
    <div className="details-modal pb-7">
      <div className="nicescroll-bar simple-scrollbar">
        <div className="d-flex justify-content-between">
          <span className="file-name">Meeting Details</span>
          <button
            onClick={handleDetailsDrawer}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="tab-content mt-0">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple">
              <div className="card py-0">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#fl_info"
                    aria-expanded="true"
                  >
                    Informations
                  </a>
                </div>
                <div id="fl_info" className="collapse show">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span>Date Created :</span>
                        <span>{dateAndTimeFormatter(meeting?.createdAt)}</span>
                      </li>
                      <li>
                        <span>Meeting Title : </span>
                        <span>{meeting?.meetingTitle}</span>
                      </li>
                      <li>
                        <span>Start Date & Time : </span>
                        <span>
                          {dateAndTimeFormatter(meeting?.scheduleTime)}
                        </span>
                      </li>
                      <li>
                        <span>End Date & Time : </span>
                        <span>
                          {dateAndTimeFormatter(meeting?.endMeetingTime)}
                        </span>
                      </li>

                      <li>
                        <span>Hosted by :</span>
                        <span>
                          <Avatar
                            name={meeting?.createdBy?.name}
                            count={1}
                            image={meeting?.createdBy?.image}
                            size="xss"
                            color="primary"
                          />
                          &nbsp;
                          {getName(meeting?.createdBy, user?._id)}
                        </span>
                      </li>
                      <li>
                        <span>Current Status :</span>
                        <span
                          className="text-capitalize"
                          style={{
                            color: handleStatusWiseColor(meeting?.status).color,
                          }}
                        >
                          {handleStatusWiseColor(meeting?.status).svg}&nbsp;
                          {meeting?.status}
                        </span>
                      </li>
                      {meeting?.actionBy && (
                        <li>
                          <span>Change Status By :</span>
                          <span>
                            <Avatar
                              name={meeting?.actionBy?.name}
                              count={1}
                              image={meeting?.actionBy?.image}
                              size="xss"
                              color="primary"
                            />
                            &nbsp;
                            {getName(meeting?.actionBy, user?._id)}
                          </span>{" "}
                        </li>
                      )}
                      <li>
                        <span>Date Modified : </span>
                        <span>{dateAndTimeFormatter(meeting?.updatedAt)}</span>
                      </li>
                      <li>
                        <span>Description : </span>
                        <span>{meeting?.description}</span>
                      </li>
                      <li>
                        <span>Location : </span>
                        <span
                          onClick={() => {
                            if (meeting?.moduleType === "Space") {
                              navigate(
                                navigateToIndividualSpaces({
                                  spacename: meeting?.moduleId?.name,
                                  id: meeting?.moduleId?._id,
                                })
                              );
                            }
                          }}
                          className={`switch-text pointer ${
                            meeting?.moduleType === "Business"
                              ? "text-dark"
                              : "text-primary"
                          }`}
                        >
                          {meeting?.moduleType === "Business"
                            ? "Workspace"
                            : meeting?.moduleType}{" "}
                          {meeting?.moduleId && `( ${meeting?.moduleId?.name})`}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="row">
                  <span className="text-primary">
                    Participants
                    <div className="avatar-group avatar-group-md avatar-group-overlapped me-3 pointer ms-2">
                      <div className="avatar avatar-soft-primary avatar-rounded">
                        <span className="initial-wrap">
                          {meeting?.participants?.length}
                        </span>
                      </div>
                    </div>
                  </span>
                  {loader && <DataLoader />}
                  {!loader && (
                    <ul class="hk-list d-flex justify-content-center pointer">
                      {allParticipants?.map((emp) => {
                        return (
                          <CustomTooltip text={emp?.name} placement="bottom">
                            <li
                              data-bs-toggle="modal"
                              data-bs-target="#user_information_modal"
                              onClick={() => {
                                setuserInfoData(emp);
                              }}
                            >
                              <Avatar
                                name={emp?.name}
                                count={1}
                                image={emp?.image}
                                size="xs"
                              />
                            </li>
                          </CustomTooltip>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingDetailsModal;
