import React, { useState } from "react";
import AddMaterialInItem from "./AddMaterialInItem";
import AddedMaterialsTable from "./AddedMaterialsTable";
import { reuiredField } from "../../../../../global-functions/globalFunctions";

function AddItemStepTwo(props) {
  const {
    selectSetup,
    selectSetup3,
    setmaterialsInItem,
    setmaterialsInTable,
    materialsInTable,
    isEdit,
    completeSecondStep,
    slectedItem,
    materialsInItem,
  } = props;

  const [materialEdit, setMaterialEdit] = useState(false);
  const [materialEditData, setmaterialEditData] = useState("");

  return (
    <div>
      <div className="col-sm-12 fs-5 d-flex align-items-start justify-content-start mt-3">
        <label className="text-daek">
          Step 2 | Choose raw materials {reuiredField}
        </label>
      </div>
      <div className="col-sm-10 fs-6 d-flex align-items-start justify-content-start mt-1">
        <p className="text-secondary">
          Choosing the raw material and their quantity will help you track your
          inventory supply and cost of production.{" "}
        </p>
      </div>
      <div className="d-flex justify-content-end me-4 mt-2">
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#add_item_material"
        >
          + Add Raw Material
        </button>
      </div>
      <div className="mt-2 mh-300p overflow-auto ">
        <AddedMaterialsTable
          materialsInTable={materialsInTable}
          isEdit={isEdit}
          setMaterialEdit={setMaterialEdit}
          setmaterialEditData={setmaterialEditData}
          setmaterialsInTable={setmaterialsInTable}
          setmaterialsInItem={setmaterialsInItem}
        />
      </div>

      <div className="modal-footer align-items-center  justify-content-between mt-2">
        <span
          onClick={() => selectSetup()}
          className="d-flex align-items-center gap-1 text-dark pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-double-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          <span>Previous</span>
        </span>
        <button
          onClick={selectSetup3}
          className="btn btn-primary"
          disabled={!completeSecondStep}
        >
          Next
        </button>
      </div>
      <AddMaterialInItem
        setmaterialsInItem={setmaterialsInItem}
        setmaterialsInTable={setmaterialsInTable}
        slectedItem={slectedItem}
        isEdit={isEdit}
        materialEditData={materialEditData}
        materialEdit={materialEdit}
        setMaterialEdit={setMaterialEdit}
        materialsInTable={materialsInTable}
        materialsInItem={materialsInItem}
      />
    </div>
  );
}

export default AddItemStepTwo;
