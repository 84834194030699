import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import { activityGroupRelatedEndpoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";

function CreateTaskForStatus({ setcreateTask, settaskArray, taskArray }) {
  const [loader, setloader] = useState(false);
  const [loaderForActGroup, setloaderForActGroup] = useState(false);
  const [taskTitle, setTaskTitle] = useState("");
  const [actStatusGroups, setactStatusGroups] = useState([]);
  const [selectedActGroup, setselectedActGroup] = useState("");
  const [allStatus, setallStatus] = useState([]);
  const [selectedActivityStatus, setselectedActivityStatus] = useState("");
  const [loader2, setloader2] = useState(false);
  const [activityType, setactivityType] = useState("call");
  const [selectedActGroupStatus, setselectedActGroupStatus] = useState("");

  const [actions, setActions] = useState({
    isPhotoMandatory: { value: false, description: "" },
    isDocumentMandatory: { value: false, description: "" },
    isNotesMandatory: { value: false, description: "" },
    isLocationMandatory: { value: false, description: "" },
  });

  const handleReset = () => {
    setcreateTask(false);
    setloader(false);
    setactivityType("call");
    setTaskTitle("");
    setActions({
      isPhotoMandatory: { value: false, description: "" },
      isDocumentMandatory: { value: false, description: "" },
      isNotesMandatory: { value: false, description: "" },
      isLocationMandatory: { value: false, description: "" },
    });
    setselectedActivityStatus("");
  };

  const handleActivityType = (event) => {
    setactivityType(event.target.value);
    setselectedActGroup("");
  };
  const handleActionChange = (event) => {
    const { name, checked } = event.target;
    setActions((prevActions) => ({
      ...prevActions,
      [name]: { ...prevActions[name], value: checked },
    }));
  };

  const handleDescriptionChange = (event) => {
    const { name, value } = event.target;
    setActions((prevActions) => ({
      ...prevActions,
      [name]: { ...prevActions[name], description: value },
    }));
  };

  const handleAddItem = () => {
    startApiCall(null, setloader);

    // const selectedActions = Object.keys(actions).map((actionKey) => ({
    //   [actionKey]: actions[actionKey].value,
    // }));

    const tableItem = {
      name: taskTitle,
      taskType: activityType,
      assignees: [],
      // isPhotoMandatory: { ...actions.isPhotoMandatory },
      // isDocumentMandatory: { ...actions.isDocumentMandatory },
      // isNotesMandatory: { ...actions.isNotesMandatory },
      // isLocationMandatory: { ...actions.isLocationMandatory },
      activityGroup: selectedActGroup,
      activityGroupStatus: selectedActivityStatus?.activityGroupStatus,
      activityStatus: selectedActivityStatus?._id,
    };

    settaskArray((prevItems) => [...prevItems, tableItem]);

    handleReset();
  };

  const handleGetAllGroup = async () => {
    startApiCall(null, setloaderForActGroup);
    const data = {
      page: 1,
      limit: 1000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        activityType,
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivityGroup,
      data
    );
    if (res?.success) {
      setactStatusGroups(res?.group?.data);
      setloaderForActGroup(false);
    } else {
      setloaderForActGroup(false);
    }
  };
  const handleGetAllStatus = async (id) => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
        activityGroupId: selectedActGroup,
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivitySubStatus,
      data
    );
    if (res?.success) {
      setallStatus(res?.status?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllGroup();
    }
  }, [activityType]);

  useEffect(() => {
    if (selectedActGroup !== "") {
      handleGetAllStatus();
    }
  }, [selectedActGroup]);

  useEffect(() => {
    if (allStatus?.length > 0) {
      setselectedActivityStatus(allStatus?.[0]);
    }
  }, [allStatus]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      {" "}
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Create Activity</h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={() => {
                handleAddItem();
              }}
            >
              <div className="mh-450p nicescroll-bar simple-scrollbar">
                <div className="row gx-3 px-3 w-100">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label className="form-label">
                        Activity Title {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={taskTitle}
                        onChange={(e) => {
                          setTaskTitle(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">
                        Activity Type {reuiredField}
                      </label>
                      <div className="form-group">
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="task"
                              name="task"
                              className="form-check-input pointer"
                              value="task"
                              checked={activityType === "task"}
                              onChange={handleActivityType}
                            />
                            <label className="form-check-label">Task</label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="call"
                              name="call"
                              className="form-check-input pointer"
                              value="call"
                              checked={activityType === "call"}
                              onChange={handleActivityType}
                            />
                            <label className="form-check-label">Call</label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="meeting"
                              name="meeting"
                              className="form-check-input pointer"
                              value="meeting"
                              checked={activityType === "meeting"}
                              onChange={handleActivityType}
                            />
                            <label className="form-check-label">Meeting</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        Choose Activity (Process) Status Group {reuiredField}
                      </label>
                      <p>
                        activity status (process) will change based on your
                        selected group.
                      </p>
                      <div className="form-group mt-2">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md"
                            name="leadSource"
                            required
                            value={selectedActGroup}
                            onChange={(e) => {
                              setselectedActGroup(e.target.value);
                            }}
                          >
                            <option value="">Choose Status Group</option>
                            {loaderForActGroup && (
                              <option disabled>Loading Groups...</option>
                            )}
                            {!loaderForActGroup && (
                              <>
                                {actStatusGroups?.map((item, index) => {
                                  return (
                                    <option value={item?._id}>
                                      {index + 1}. {item?.name}
                                    </option>
                                  );
                                })}
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !taskTitle || !selectedActivityStatus || !selectedActGroup
                  }
                >
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateTaskForStatus;
