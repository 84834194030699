import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ActiviyGroups from "../../pages/CRM/ActivityGroups/ActiviyGroups";
import GroupDetails from "../../pages/CRM/ActivityGroups/ActivityGroupDetails/GroupDetails";

const CrmActivityGroupRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <ActiviyGroups />,
    },
    {
      path: "activitygroups",
      element: <ActiviyGroups />,
    },
    {
      path: "activitygroups/groupdetails/:id",
      element: <GroupDetails />,
    },
  ],
};

export default CrmActivityGroupRoutes;
