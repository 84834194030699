import React from "react";
import { Link } from "react-router-dom";
import AccessDenied from "../../assets/images/no_access.jpg";

const NoAccess = () => {
  return (
    <div className="maintainance-page">
      <div className="maintainance-box">
        <img
          className="mb-2"
          src={AccessDenied}
          alt=""
          style={{ height: "450px" }}
        />

        <h6>Sorry, but you currently don't have access to this page</h6>
        <p className="fw-medium">
          Which means you are unable to view this module. Please contact your
          administrator or request access if you believe this is in error.
        </p>
        <Link to="/home" className="switch-text fw-semibold text-primary mt-2 p-2 pointer">Back to home</Link>
        <div className="ball-wrapper mt-4">
          <div className="circles"></div>
          <div className="circles"></div>
          <div className="circles"></div>
          <div className="shadows"></div>
          <div className="shadows"></div>
          <div className="shadows"></div>
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
