import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { navigateToInvoiceDetails } from "../../../Navigation/Navigation";
import {
  dateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { InvoicesRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";

function InvoicesTable(props) {
  const {
    allInvoicesData,
    page,
    pageLimit,
    handleGetAllInovices,
    isModuleReadWriteAccessInvoice,
  } = props;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invoiceId, setInvoiceId] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  // DELETE INVOICE FUNCTION API CALL
  const handleDeleteInvoice = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      invoiceId,
    };
    const res = await ApiCall(
      "post",
      InvoicesRelatedEndPoints.deleteInvoice,
      data
    );
    if (res?.success) {
      toast.success("Invoice deleted successfully!");
      handleGetAllInovices();
      handleClose();
    } else {
      setloader(false);
      setErrorMessage("");
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th>Date</th>
              <th>Invoice Number</th>
              <th>Customer Name</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Total Amount</th>
              <th>Due</th>
              {isModuleReadWriteAccessInvoice && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {allInvoicesData?.map((invoice, index) => {
              return (
                <tr className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td
                    onClick={() => {
                      navigate(navigateToInvoiceDetails({ id: invoice?._id }));
                    }}
                    className="pointer"
                  >
                    {dateFormatter(invoice?.createdAt)}
                  </td>
                  <td
                    onClick={() => {
                      navigate(navigateToInvoiceDetails({ id: invoice?._id }));
                    }}
                    className="pointer"
                  >
                    {invoice?.invoiceNumber}
                  </td>
                  <td
                    onClick={() => {
                      navigate(navigateToInvoiceDetails({ id: invoice?._id }));
                    }}
                    className="text-capitalize pointer"
                  >
                    {invoice?.customerId?.name}
                  </td>
                  <td
                    onClick={() => {
                      navigate(navigateToInvoiceDetails({ id: invoice?._id }));
                    }}
                    className="pointer"
                  >
                    {dateFormatter(invoice?.dueDate)}
                  </td>
                  <td
                    className="text-capitalize pointer"
                    onClick={() => {
                      navigate(navigateToInvoiceDetails({ id: invoice?._id }));
                    }}
                  >
                    {invoice?.isVoid ? (
                      <span className="text-danger">Void</span>
                    ) : invoice?.status === "partially-paid" ? (
                      "Partially Paid"
                    ) : (
                      invoice?.status
                    )}
                  </td>
                  <td>₹ {invoice?.totalAmount?.toFixed(2)}</td>
                  <td>
                    {invoice?.totalDueAmount
                      ? `₹ ${invoice?.totalDueAmount?.toFixed(2)}`
                      : 0}
                  </td>
                  {isModuleReadWriteAccessInvoice && (
                    <td>
                      <span className="feather-icon pointer">
                        {invoice?.isVoid === false ? (
                          <i
                            data-bs-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="ri-more-fill"
                          ></i>
                        ) : (
                          "-"
                        )}
                        <div className="dropdown-menu">
                          <div
                            className="dropdown-item d-flex align-items-center"
                            onClick={() => {
                              setIsOpen(true);
                              setInvoiceId(invoice?._id);
                            }}
                          >
                            <span
                              className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                              </span>
                            </span>
                            <p>Void</p>
                          </div>
                        </div>
                      </span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text="Are You Sure You Want To Void This Invoice"
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleDeleteInvoice}
      />
    </>
  );
}

export default InvoicesTable;
