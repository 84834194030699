import React from "react";
import {
  convertTotalHoursToHoursAndMinutes,
  getCurrentWorkspaceName,
  monthNames,
  reversesDateFormatter,
} from "../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";

const AttendanceHeader = (props) => {
  const {
    selectedMonth,
    handleMonthChange,
    attendanceView,
    setattendanceView,
    handleGetAllStaffAttendance,
    handleStaffAttendancePerDay,
    dailyDateFilter,
    setdailyDateFilter,
    search,
    setsearch,
    setpage,
    thisMonth,
    staffData,
  } = props;
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const currentWorkspaceId = localStorage.getItem("current-workspace");
  const myApproveWorkspaces = useSelector((state) => state.approvedWorkspace);

  const downloadCSV = () => {
    let csvData = [];
    staffData?.map((data, index) => {
      csvData.push({
        No: index + 1,
        Employee: data?.staff?.employeeId?.name,
        "Settlement Type":
          data?.staff?.settlementType === "30_days"
            ? "30 days"
            : data?.staff?.settlementType === "dailyWages"
            ? "Daily"
            : data?.staff?.settlementType === "weeklyOff"
            ? "Weekly Off"
            : data?.staff?.settlementType === "custom"
            ? "Custom"
            : "-",
        "Total working days": data?.totalWorkingDays,
        "Total days present": data?.totalPresentDays,
        "Present full days": data?.totalFullDays,
        "Present half day": data?.totalHalfDays,
        Leaves: data?.totalLeaveDays,
        Absent: data?.totalAbsentDays,
        "Total hours": data
          ? convertTotalHoursToHoursAndMinutes(data?.totalHours)
          : 0,
      });
    });
    const headers = Object.keys(csvData[0]);
    const headerRow = headers.map((header) => `"${header}"`).join(",");
    const dataRows = csvData.map((obj) =>
      headers.map((header) => `"${obj[header]}"`).join(",")
    );
    const csvContent = `data:text/csv;charset=utf-8,${headerRow}\n${dataRows.join(
      "\n"
    )}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `Attendance_Report_${getCurrentWorkspaceName(
        myApproveWorkspaces,
        currentWorkspaceId
      )}_${selectedMonth}_${year}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <a className="todoapp-title  link-dark">
          <h1>Staff Attendance Details</h1>
        </a>
      </div>
      <div className="todo-options-wrap">
        {attendanceView === "Monthly" && (
          <button
            className="btn btn-primary btn-sm me-2 pointer"
            onClick={downloadCSV}
          >
            Download Report
          </button>
        )}
        <div className="me-2">
          <select
            className="form-select form-select"
            value={attendanceView}
            onChange={(event) => {
              setattendanceView(event.target.value);
            }}
          >
            <option value="Monthly">Monthly</option>
            <option value="Daily">Daily</option>
          </select>
        </div>
        {attendanceView === "Monthly" && (
          <div className="me-1">
            <select
              className="form-select form-select"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {monthNames?.map((mon, index) => {
                return <option disabled={index > thisMonth}>{mon}</option>;
              })}
            </select>
          </div>
        )}
        {attendanceView === "Daily" && (
          <input
            className="form-control w-150p"
            name="single-date-pick"
            type="date"
            value={dailyDateFilter}
            onChange={(e) => {
              setdailyDateFilter(e.target.value);
            }}
            max={reversesDateFormatter(currentDate)}
          />
        )}

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={() => {
            if (attendanceView === "Monthly") {
              handleGetAllStaffAttendance();
            } else {
              handleStaffAttendancePerDay();
            }
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none me-2" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search staff"
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
              setpage(1);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default AttendanceHeader;
