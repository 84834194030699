import React, { useState } from "react";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import {
  authenticateEndpoints,
  managementEndpoints,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { Avatar } from "../../../components/Avatar/Avatar";
import WorkspaceDetailsModal from "../../Workspace/WorkspaceDetailsModal";
import { toast } from "react-toastify";

const AllBusinessTable = (props) => {
  const { status, allBusiness, handleGetAllBusiness, user, page, pageLimit } =
    props;
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [businessId, setBusinessId] = useState(false);
  const [isSendForApproval, setIsSendForApproval] = useState(false);
  const [isDetailsModalOpen, setisDetailsModalOpen] = useState(false);
  const [selectWorkspace, setselectWorkspace] = useState([]);

  const handleDetailsDrawer = (workspace) => {
    setselectWorkspace(workspace);
    setisDetailsModalOpen(!isDetailsModalOpen);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    seterrorMessage("");
    setIsOpen(true);
  };

  const handleCloseBusinessRequest = () => {
    setIsOpen(false);
    seterrorMessage("");
    setLoader(false);
    setIsSendForApproval(false);
  };

  const handleResendForApproval = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const data = {
      businessId: businessId,
    };
    const res = await ApiCall(
      "post",
      authenticateEndpoints.sendForApproval,
      data
    );
    if (res?.success) {
      handleGetAllBusiness();
      handleCloseBusinessRequest();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleApproveorRejectBusiness = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const data = {
      businessId: businessId,
      isApproved: selectedStatus === "Approve" ? true : false,
      adminId: user?._id,
      modules: [{ moduleName: "all", permissionType: "read-write" }],
    };
    const res = await ApiCall(
      "post",
      managementEndpoints.approveOrRejectBusiness,
      data
    );
    if (res?.success) {
      toast.success(
        `Workspace has been ${
          selectedStatus === "Approve" ? "Approved" : "Rejected"
        } successfully`
      );
      handleGetAllBusiness();
      handleCloseBusinessRequest();
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <>
      <table id="datable_1" className="table nowrap w-100 mb-5">
        <thead>
          <tr className="text-center">
            <th>No.</th>
            <th style={{ textAlign: "left" }}>Name</th>
            <th>Date Created</th>
            <th>More Info</th>
            {status !== "approved" && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {allBusiness?.map((workspace, index) => {
            return (
              <tr key={index} className="text-center">
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td style={{ textAlign: "left" }}>
                  <span
                    className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize"
                    style={{ width: "300px" }}
                  >
                    <Avatar
                      name={workspace?.name}
                      count={1}
                      image={workspace?.image}
                      size="xss"
                      color="primary"
                    />
                    &nbsp; {workspace?.name}
                  </span>
                </td>
                <td>{dateAndTimeFormatter(workspace?.createdAt)}</td>
                <td>
                  <div className="fs-7 text-primary pointer">
                    <svg
                      onClick={() => {
                        handleDetailsDrawer(workspace);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </div>
                </td>

                {status === "pending" && (
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="d-flex">
                        <span
                          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover text-success"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                        >
                          <span
                            className="btn-icon-wrap"
                            onClick={() => {
                              handleStatusChange("Approve");
                              setBusinessId(workspace?._id);
                            }}
                          >
                            <span className="feather-icon">
                              <i
                                className="ri-checkbox-circle-fill"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </span>
                          </span>
                        </span>
                        <span
                          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                        >
                          <span
                            className="btn-icon-wrap"
                            onClick={() => {
                              handleStatusChange("Reject");
                              setBusinessId(workspace?._id);
                            }}
                          >
                            <span className="feather-icon ">
                              <i
                                className="ri-close-circle-fill"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </td>
                )}
                {status === "rejected" && (
                  <td>
                    <button
                      className="btn btn-primary btn-rounded"
                      type="button"
                      onClick={() => {
                        setIsSendForApproval(true);
                        setBusinessId(workspace?._id);
                      }}
                    >
                      Send For Approval
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <ConfirmModal
        isOpen={isOpen || isSendForApproval}
        onClose={handleCloseBusinessRequest}
        text={
          status === "pending"
            ? `Are You Sure You Want To ${selectedStatus} This Request ?`
            : `Are You Sure You Want To Resend This Request`
        }
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={
          status === "rejected"
            ? handleResendForApproval
            : handleApproveorRejectBusiness
        }
      />
      {isDetailsModalOpen && (
        <WorkspaceDetailsModal
          handleDetailsDrawer={handleDetailsDrawer}
          selectWorkspace={selectWorkspace}
        />
      )}
    </>
  );
};

export default AllBusinessTable;
