import React from "react";
import { dateAndTimeFormatter } from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";

const ReasonDetails = ({ setShowReason, showReason }) => {
  const { show, stock } = showReason || {};
  const handleReset = () => {
    setShowReason(false);
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content px-3">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Reason details</h5>
            <div className="border-bottom mb-2"></div>
            <form>
              <div className="row gx-3 px-2">
                <div className="d-flex flex-column gap-2">
                  <span className="d-flex text-dark text-capitalize">
                    Reason Type : {stock?.stockOutCase}
                  </span>
                  <span className="d-flex text-dark text-capitalize gap-2">
                    Created By :
                    <Avatar
                      name={stock?.createdBy?.name}
                      count={1}
                      image={stock?.createdBy?.image}
                      size="xss"
                      color="primary"
                    />
                    {stock?.createdBy?.name}
                  </span>
                  <span className="d-flex text-dark text-capitalize">
                    Created Date : {dateAndTimeFormatter(stock?.createdAt)}
                  </span>
                  {stock?.stockOutCase === "customized" && (
                    <>
                      <span className="d-flex text-dark text-capitalize">
                        Order No : {stock?.stockOutDescription?.orderNumber}
                      </span>
                      <span className="d-flex text-dark text-capitalize">
                        Dish Name :{" "}
                        {stock?.stockOutDescription?.productId?.productName}
                      </span>
                    </>
                  )}
                  <span className="d-flex text-dark text-capitalize">
                    Description : {stock?.stockOutDescription?.reason}
                  </span>
                </div>
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonDetails;
