import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Notice from "../../pages/Communication/AllNotices/Notice/Notice"
import NoticeDetails from "../../pages/Communication/AllNotices/NoticeDetails/NoticeDetails";

const NoticeRoutes = {
  path: "/communication",
  element: <Navbar />,
  children: [
    {
      path: '',
      element: <Notice />,
    },
    {
      path: 'notices',
      element: <Notice />,
    },
    
    {
      path: 'noticedetails/:id',
      element: <NoticeDetails />
    }
  ],
};

export default NoticeRoutes;
