import React from "react";

function PlaceOrderForCustomerHeader({
  handleGetItemCategory,
  setSelectedCategory,
}) {
  return (
    <header>
      <div className="d-flex justify-content-between p-2">
        <h5 className="header-title-font mt-2">Place order</h5>
        <div className="border-left">
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="dsdsd"
            data-bs-original-title="Refresh"
            onClick={() => {
              handleGetItemCategory();
              setSelectedCategory("");
            }}
          >
            <span className="btn-icon-wrap">
              <span className="feather-icon">
                <i
                  className="ri-refresh-line"
                  title="Refresh business list"
                ></i>
              </span>
            </span>
          </span>
        </div>
      </div>

      <div className="border-bottom mt-1 mb-2 me-2"></div>
    </header>
  );
}

export default PlaceOrderForCustomerHeader;
