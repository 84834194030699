import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import CrmTask from "../../pages/CRM/CrmTasks/CrmTask";

const CrmTasksRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "allTasks",
      element: <CrmTask />,
    },
  ],
};

export default CrmTasksRoutes;