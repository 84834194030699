import React, { useEffect, useState } from "react";
import SuccessPopup from "../SuccessPopup";
import ApiCall from "../../../api/ApiCall";
import {
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { forgotPasswordEndpoints } from "../../../api/Endpoints";
import OTPBox from "../../../components/OTPBox/OTPBox";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { toast } from "react-toastify";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";

const ChangePassword = ({ firstStep, setfirstStep }) => {
  const { user } = useSelector((state) => state);
  const [secondStep, setsecondStep] = useState(false);
  const [thirdStep, setthirdStep] = useState(false);
  const [otpString, setOtpString] = useState("");
  const [otp, setotp] = useState(new Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [isShowPassowrd, setisShowPassowrd] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (user?.email) {
      setEmail(user?.email);
    }
  }, [user]);

  const handleShowPassword = () => {
    setisShowPassowrd(!isShowPassowrd);
  };

  const completeFirstStep = () => {
    setfirstStep(false);
    setsecondStep(true);
    setthirdStep(false);
  };

  const completeSecondStep = () => {
    setfirstStep(false);
    setsecondStep(false);
    setthirdStep(true);
  };

  const handleReset = () => {
    setloader(false);
    setloader2(false);
    setloader3(false);
    seterrorMessage("");
    setEmail("");
    setOtpString("");
    setotp("");
    setPassword("");
    setConfirmPassword("");
    setthirdStep(false);
    const closeModalDom = document.getElementById("change_password");
    if (closeModalDom) closeModalDom.click();
  };

  // Verify Email
  const handleEmailVerify = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const res = await ApiCall(
      "post",
      forgotPasswordEndpoints.sendOtpForResetPassword,
      { email }
    );
    if (res?.success) {
      setotp(new Array(6).fill(""));
      setOtpString("");
      setloader(false);
      completeFirstStep();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  // OTP Verify
  const handleOtpVerify = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    const res = await ApiCall(
      "post",
      forgotPasswordEndpoints.validateOtpForResetPassword,
      {
        otp: otpString,
      }
    );
    if (res?.success) {
      setloader2(false);
      completeSecondStep();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  // Password Change
  const handleResetPassword = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader3);
    if (password !== confirmPassword) {
      seterrorMessage("Password and confirm password must be same.");
    } else {
      startApiCall(seterrorMessage, setloader);
      const sendData = {
        password: password,
        otp: otpString,
      };
      const res = await ApiCall(
        "post",
        forgotPasswordEndpoints.resetPassword,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Password change successfully");
        setSuccessModal(true);
      } else {
        setloader3(false);
        seterrorMessage(res.error);
      }
    }
  };

  return (
    <>
      <div
        id="change_password"
        className="modal fade add-new-contact"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered "
          role="document"
          style={{ maxWidth: "45%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close mt-2 pe-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="ps-2">Change Password</h5>

              <form
                onSubmit={(e) => {
                  if (firstStep) {
                    handleEmailVerify(e);
                  } else if (secondStep) {
                    handleOtpVerify(e);
                  } else if (thirdStep) {
                    handleResetPassword(e);
                  }
                }}
              >
                <div className="row gx-3 px-3 mb-2">
                  <div className="mt-2 pe-9 mb-3">
                    You'll be log out of all devices in session except this one
                    to protect your account is anyone is trying to gain access
                  </div>
                  {thirdStep && (
                    <div className="mt-2 pe-9">
                      Your password must be 8 characters {reuiredField}
                    </div>
                  )}
                  {firstStep && !secondStep && !thirdStep && (
                    <div className="col-sm-10 mt-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Enter your email"
                          value={email}
                          disabled
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          type="text"
                          required
                        />
                      </div>
                    </div>
                  )}
                  {!firstStep && secondStep && !thirdStep && (
                    <OTPBox
                      otp={otp}
                      otpString={otpString}
                      errorMessage={errorMessage}
                      loader={loader}
                      handleOtpVerify={handleOtpVerify}
                      setOtpString={setOtpString}
                      setotp={setotp}
                      description="Please enter the verification code we sent to your email address"
                      email={email}
                    />
                  )}
                  {!firstStep && !secondStep && thirdStep && (
                    <>
                      <div className="col-sm-10 mt-3">
                        <div className="input-group password-check">
                          <span className="input-affix-wrapper">
                            <input
                              className="form-control"
                              placeholder="Enter new Password"
                              type={isShowPassowrd ? "text" : "password"}
                              required
                              pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                              title="Password must be minimum eight characters, at least one letter, one number and one special character."
                              value={password}
                              onChange={(event) =>
                                setPassword(event.target.value)
                              }
                            />
                            <div className="input-suffix text-muted">
                              <span
                                className="feather-icon"
                                onClick={handleShowPassword}
                                style={{ cursor: "pointer" }}
                              >
                                <i
                                  className={
                                    isShowPassowrd
                                      ? "ri-eye-line"
                                      : "ri-eye-off-line"
                                  }
                                ></i>
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-10 mt-3">
                        <div className="input-group password-check">
                          <span className="input-affix-wrapper">
                            <input
                              className="form-control"
                              placeholder="Retype new Password"
                              type="password"
                              required
                              pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                              title="Password must be minimum eight characters, at least one letter, one number and one special character."
                              value={confirmPassword}
                              onChange={(event) =>
                                setConfirmPassword(event.target.value)
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center mt-3">
                  <button type="submit" className="btn btn-primary btn-lg">
                    {firstStep ? (
                      loader ? (
                        <ApiLoader />
                      ) : (
                        "Send OTP"
                      )
                    ) : secondStep ? (
                      loader2 ? (
                        <ApiLoader />
                      ) : (
                        "Verify"
                      )
                    ) : loader3 ? (
                      <ApiLoader />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {successModal && (
        <SuccessPopup
          text="Your password was changed successfully"
          successModal={successModal}
          setSuccessModal={setSuccessModal}
        />
      )}
    </>
  );
};

export default ChangePassword;
