import React, { useEffect, useState } from "react";
import SalaryTable from "./SalaryTable";
import SalaryHeader from "./SalaryHeader";
import ApiCall from "../../../../api/ApiCall";
import { salaryRelatedEndPoints } from "../../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  monthNames,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navigateToStaffSalaryDetails } from "../../../../Navigation/Navigation";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";
import SalarySkeleton from "./SalarySkeleton";

const Salary = () => {
  const { allModuleAccess, user } = useSelector((state) => state);
  const navigate = useNavigate();
  const [allStaffSalary, setAllStaffSalary] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState();
  const [salaryCount, setSalaryCount] = useState({});

  const isModuleReadWriteAccessForSalary =
    allModuleAccess?.[PRIVATEROUTES.SALARY];

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // MONTHLY FILTER
  const today = new Date();
  today.setMonth(today.getMonth());
  const previousMonth = monthNames[today.getMonth()];
  const [selectedMonth, setSelectedMonth] = useState(previousMonth);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const year = today.getFullYear();
  const thisMonth = today.getMonth();
  const previousMonthFirstDay = new Date(year, thisMonth, 1);
  const previousMonthLastDay = new Date(year, thisMonth + 1, 0);
  const formattedStartDate = `${previousMonthFirstDay.getFullYear()}-${(
    previousMonthFirstDay.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-01`;
  const formattedEndDate = `${previousMonthLastDay.getFullYear()}-${(
    previousMonthLastDay.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${previousMonthLastDay
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
    const selectedMonthIndex = monthNames.indexOf(selectedMonth);
    if (selectedMonthIndex !== -1) {
      const month = selectedMonthIndex + 1;
      const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay
        .toString()
        .padStart(2, "0")}`;

      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  // GET ALL STAFF SALARY FUNCTION API CALL
  const handleGetAllStaffSalary = async () => {
    startApiCall(null, setLoader);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
      page: page,
      limit: pageLimit,
      startDate: startDate ? startDate : formattedStartDate,
      endDate: endDate ? endDate : formattedEndDate,
      searchKey: search,
    };
    const res = await ApiCall(
      "post",
      salaryRelatedEndPoints.getAllStaffSalary,
      sendData
    );
    if (res?.success) {
      let res2;
      if (res?.salary?.results?.length > 0) {
        res2 = await ApiCall(
          "post",
          salaryRelatedEndPoints.getTotalSalaryCount,
          {
            businessId: getCurrentWorkspaceId(),
            startDate: startDate ? startDate : formattedStartDate,
            endDate: endDate ? endDate : formattedEndDate,
          }
        );
      }
      setSalaryCount(res2?.salary);
      setAllStaffSalary(res?.salary?.results);
      setcount(res?.salary?.totalCounts);
      settotalPages(res?.salary?.totalPages);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId) {
      handleGetAllStaffSalary();
    }
  }, [selectedMonth, search, page, pageLimit]);

  useEffect(() => {
    if (!isModuleReadWriteAccessForSalary) {
      navigate(
        navigateToStaffSalaryDetails({
          id: user?._id,
          monthname: selectedMonth,
        }),
        {
          state: { employeeName: user?.name, image: user?.image },
        }
      );
    }
  }, [isModuleReadWriteAccessForSalary]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <SalaryHeader
            selectedMonth={selectedMonth}
            handleMonthChange={handleMonthChange}
            monthNames={monthNames}
            thisMonth={thisMonth}
            handleGetAllStaffSalary={handleGetAllStaffSalary}
            setSearch={setSearch}
            search={search}
            setPage={setPage}
          />
          {!loader && allStaffSalary?.length > 0 && (
            <div className="d-flex col-md-12 mt-5 ps-4 gap-3">
              <div
                className="card col-md-3 h-80p w-230p"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              >
                <h5 className="font-3 text-center mt-3">Total Salary</h5>
                <p className="text-center text-dark">
                  INR&nbsp;
                  {salaryCount?.[0]?.totalSalary
                    ? salaryCount?.[0]?.totalSalary
                    : 0}
                </p>
              </div>
            </div>
          )}
          {loader && (
            <div className="d-flex col-md-12 mt-5 ps-4 gap-1">
              <div
                className="card loading-skeleton col-md-3 h-80p w-230p justify-content-evenly"
                style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
              >
                <SkeletonConstant
                  width="w-120p"
                  height="h-25p"
                  additional="mx-auto"
                />
                <SkeletonConstant
                  width="w-120p"
                  height="h-25p"
                  additional="mx-auto"
                />
              </div>
            </div>
          )}
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view mb-8">
                {!loader && allStaffSalary?.length > 0 && (
                  <SalaryTable
                    allStaffSalary={allStaffSalary}
                    page={page}
                    pageLimit={pageLimit}
                    selectedMonth={selectedMonth}
                  />
                )}
                {!loader && allStaffSalary?.length > 0 && (
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allStaffSalary?.length}
                    align={"end"}
                  />
                )}
                {loader && <SalarySkeleton />}
                {!loader && allStaffSalary?.length === 0 && <NoData />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Salary;
