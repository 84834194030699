import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  convertCsvName,
  copyToClipboard,
  reuiredField,
} from "../../../../global-functions/globalFunctions";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { toast } from "react-toastify";
import { CrmSettingsRelatedEndPoints } from "../../../../api/Endpoints";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";

function CreateDynamicFields({
  setdynamicFieldsModal,
  editData,
  seteditData,
  handleGetDynamicFields,
}) {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState("");
  const [type, settype] = useState("Lead");
  const [isRequired, setisRequired] = useState("false");
  const [placement, setplacement] = useState("basicInfo");
  const [inputType, setinputType] = useState("text");
  const [csvField, setcsvField] = useState("");
  const [copy, setcopy] = useState({
    isTrue: false,
    item: "",
  });
  const handleCopy = (text) => {
    setcopy({ isTrue: true, item: text });
    copyToClipboard(text);
    setTimeout(() => {
      setcopy({ isTrue: false, item: text });
    }, 800);
  };
  useEffect(() => {
    if (name !== "" && name !== undefined) {
      setcsvField(convertCsvName(name));
    }
  }, [name]);

  const handleReset = () => {
    setdynamicFieldsModal(false);
    seteditData("");
    setErrorMessage("");
    setName("");
    settype("Lead");
    setisRequired("false");
    setplacement("basicInfo");
    setinputType("text");
  };

  const handleAddDynamicField = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const trimmedName = name.trim();
    const sendData = {
      feildFor: type,
      feildName: trimmedName,
      feildType: inputType,
      isRequired: isRequired,
      moduleName: "CRM",
      placement: "basicInfo",
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
      actionBy: user?._id,
      csvColumnName: csvField,
      isVisible: true,
    };
    const res = await ApiCall(
      "post",
      CrmSettingsRelatedEndPoints.createDynamicField,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetDynamicFields();
      toast.success("Add field successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };
  const handleEditDynamicField = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      id: editData?._id,
      feildFor: type,
      feildName: name,
      feildType: inputType,
      isRequired: isRequired,
      moduleName: "CRM",
      placement: placement,
      csvColumnName: csvField,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmSettingsRelatedEndPoints.editDynamicField,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetDynamicFields();
      toast.success("Edit field successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (editData) {
      settype(editData?.feildFor);
      setName(editData?.feildName);
      setinputType(editData?.feildType);
      setisRequired(editData?.isRequired);
      setplacement(editData?.placement);
    }
  }, [editData]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered "
        role="document"
        style={{ maxWidth: 550 }}
      >
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{editData ? "Edit Dynamic Field" : "Create Dynamic Field"}</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (editData) {
                  handleEditDynamicField(e);
                } else {
                  handleAddDynamicField(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="d-flex gap-2 mt-3">
                  <CustomTooltip
                    text={"Please input the label for the input box here."}
                    placement="right"
                    additional="font-resize mt-2"
                  >
                    <span className="text-dark w-30 fw-lighter ">
                      Label Name {reuiredField} :
                    </span>
                  </CustomTooltip>
                  &nbsp;
                  <input
                    className="form-control w-70"
                    type="text"
                    maxLength="28"
                    pattern="^[a-zA-Z0-9 ]*$"
                    required
                    placeholder="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row gx-3 d-flex">
                <div className="d-flex mt-3 col-6">
                  <CustomTooltip
                    text={"Please select the type of input box."}
                    placement="bottom"
                    additional="font-resize mt-2"
                  >
                    <span className="text-dark w-40 fw-lighter mt-1">
                      Input Type {reuiredField} :
                    </span>
                  </CustomTooltip>
                  &nbsp;
                  <div className="form-group w-60">
                    <div className="todo-toolbar">
                      <select
                        className="form-select form-select-md"
                        value={inputType}
                        onChange={(e) => {
                          setinputType(e.target.value);
                        }}
                      >
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="email">Email</option>
                        <option value="url">Url</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-3 col-6">
                  <CustomTooltip
                    text={
                      "Please select if the input box is required to be filled."
                    }
                    placement="right"
                    additional="font-resize mt-2"
                  >
                    <span className="text-dark w-40 fw-lighter mt-1">
                      Required {reuiredField} :
                    </span>
                  </CustomTooltip>
                  &nbsp;
                  <div className="form-group w-60">
                    <div className="todo-toolbar">
                      <select
                        className="form-select form-select-md"
                        value={isRequired}
                        onChange={(e) => {
                          setisRequired(e.target.value);
                        }}
                      >
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row gx-3 d-flex">
                {/* <div className="d-flex mt-3 col-6">
                  <span className="text-dark w-40 fw-lighter mt-1">
                    Type {reuiredField} :
                  </span>
                  &nbsp;
                  <div className="form-group w-60">
                    <div className="todo-toolbar">
                      <select
                        className="form-select form-select-md"
                        value={type}
                        onChange={(e) => {
                          settype(e.target.value);
                        }}
                      >
                        <option value="Contact">Contact</option>
                        <option value="Lead">Lead</option>
                        <option value="Deal">Deal</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                {/* <div className="d-flex mt-3 col-6">
                  <CustomTooltip
                    text={"Please select the placement of the field."}
                    placement="right"
                    additional="font-resize mt-2"
                  >
                    <span className="text-dark w-45 fw-lighter mt-1">
                      Placement {reuiredField} :
                    </span>
                  </CustomTooltip>
                  &nbsp;
                  <div className="form-group w-55">
                    <div className="todo-toolbar">
                      <select
                        className="form-select form-select-md"
                        value={placement}
                        onChange={(e) => {
                          setplacement(e.target.value);
                        }}
                      >
                        <option value="basicInfo">Basic Info</option>
                        <option value="contactInfo">Contact Info</option>
                      </select>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="mt-2 mb-1">
                {name !== "" && (
                  <span className="text-dark w-40 font-resize fw-lighter">
                    Csv Column Field :
                    <span className="fw-bold ms-2 pointer">
                      {csvField}&nbsp;&nbsp;
                      <CustomTooltip
                        text={
                          copy?.isTrue && copy?.item === csvField
                            ? "Copied"
                            : "Click to copy"
                        }
                        placement="right"
                        additional="font-1"
                      >
                        <div
                          className="text-center"
                          onClick={() => {
                            handleCopy(csvField);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="currentColor"
                            class="bi bi-copy text-primary"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                          </svg>
                        </div>
                      </CustomTooltip>
                    </span>
                  </span>
                )}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : editData ? "Edit" : "Create"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateDynamicFields;
