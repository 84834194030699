import React from "react";
import logo from "../../../../assets/images/logo/bizhoodfullLogo.png";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { dateFormatter } from "../../../../global-functions/globalFunctions";

const PrintBillDetails = React.forwardRef((props, ref) => {
  const { isBillDetails } = props;
  return (
    <div ref={ref}>
      <div className="d-flex flex-column gap-3 p-5">
        <div className="d-flex justify-content-between align-items-end gap-3">
          <img src={logo} alt="sociohoodlogo" className="mw-175p mh-150p" />
          <div className="text-primary fw-semibold fs-4 mt-2">
            Bill# {isBillDetails?.billNumber}
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end gap-3">
          <div>
            <p>Kolkata</p>
            <p>India</p>
            <p>9100000000</p>
            <p>testtechnixia@gmail.com</p>
          </div>
          <div>
            <div className="d-flex justify-content-between gap-5">
              <p>Vendor :</p>
              <span>
                <Avatar
                  name={isBillDetails?.vendorId?.name}
                  count={1}
                  // image={isBillDetails?.vendorId?.image}
                  size="xss"
                  color="primary"
                />
                &nbsp;{isBillDetails?.vendorId?.name}
              </span>
            </div>
            <div className="d-flex justify-content-between gap-5">
              <p>Bill date :</p>
              <p>{dateFormatter(isBillDetails?.billDate)}</p>
            </div>
            <div className="d-flex justify-content-between gap-5">
              <p>Due date :</p>
              <p>{dateFormatter(isBillDetails?.dueDate)}</p>
            </div>
          </div>
        </div>
        <table id="datable_1" className="table nowrap w-100 mb-5">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {isBillDetails?.items?.map((data, index) => {
              return (
                <tr className="text-center">
                  <td>{index + 1} .</td>
                  <td>
                    <span className="text-capitalize">
                      {data?.itemId?.itemName}
                    </span>
                  </td>
                  <td>{data?.quantity}</td>
                  <td>{data?.rate}</td>
                  <td>{data?.total}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="d-flex justify-content-end align-items-end flex-column">
          <div className="d-flex justify-content-between gap-5">
            <p>Discount :</p>
            <p>{isBillDetails?.discount}%</p>
          </div>
          <div className="d-flex justify-content-between gap-5 text-dark">
            <p>Total :</p>
            <p>{isBillDetails?.totalAmount}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintBillDetails;
