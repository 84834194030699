import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToIndividualSpaces } from "../../../Navigation/Navigation";

function MyComplaintsHeader(props) {
  const {
    isToggled,
    handleToggle,
    setsearchKey,
    setpage,
    handleChangeType,
    moduleType,
    locationData,
    searchKey,
    isModuleReadWriteAccessMyGrievance,
  } = props;
  const navigate = useNavigate();

  return (
    <header class="todo-header">
      <span class="todoapp-title link-dark">
        <h1>
          My Grievance &nbsp;
          {locationData && (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-geo-alt mt-2"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              <span
                onClick={() => {
                  if (locationData?.moduleType === "Space") {
                    navigate(
                      navigateToIndividualSpaces({
                        spacename: locationData?.moduleName,
                        id: locationData?.moduleId,
                      })
                    );
                  } else if (locationData?.moduleType === "Task") {
                  }
                }}
                className="text-primary ms-1 pointer switch-text fs-5 pt-1"
              >
                {locationData?.moduleName}
              </span>
            </>
          )}
        </h1>
      </span>
      {/* {isModuleReadWriteAccessMyGrievance && ( */}
        <div
          className="floating-button-complaint"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#register_complaint"
        >
          <i className="fa fa-plus"></i>
        </div>
      {/* )} */}

      <div className="d-flex justify-content-end">
        <div className="selectable-dropdown me-2">
          <div className="dropdown selectable-dropdown">
            <span
              className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Type :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp; {moduleType === "" ? "All" : moduleType}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("");
                  }}
                >
                  1. All
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "Space" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("Space");
                  }}
                >
                  2. Space
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "Task" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("Task");
                  }}
                >
                  3. Task
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModuleReadWriteAccessMyGrievance && (
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
              data-bs-placement="top"
              data-bs-original-title="Setting"
              data-bs-toggle="dropdown"
            >
              <span className="btn-icon-wrap">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-gear"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                  </svg>
                </span>
              </span>
            </span>
            <div role="menu" className="dropdown-menu">
              <span className="dropdown-item">
                <span>
                  Anonymous{" "}
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={isToggled}
                    onChange={handleToggle}
                  />
                </span>
                &nbsp;
              </span>
            </div>
          </div>
        )}

        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none me-2" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search Grievance"
            value={searchKey}
            onChange={(e) => {
              setsearchKey(e.target.value);
              setpage(1);
            }}
          />
        </form>
      </div>
    </header>
  );
}

export default MyComplaintsHeader;
