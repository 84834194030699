import React, { useEffect, useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import PersonalInformation from "./PersonalInformation";
import SuccessPopup from "../SuccessPopup";
import EditPhone from "./EditPhone";
import ApiCall from "../../../api/ApiCall";
import { editProfileEndPoints } from "../../../api/Endpoints";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../redux-setup";
import {
  dateAndTimeFormatter,
  getWorkSpaceRole,
  isUserSuperAdmin,
  startApiCall,
} from "../../../global-functions/globalFunctions";

const UserProfile = ({ user, isUploading, setFileForUpload }) => {
  const [successModal, setSuccessModal] = useState(false);
  const [step1, setStep1] = useState(true);
  const dispatch = useDispatch();
  const [editName, seteditName] = useState(false);
  const [name, setName] = useState("");
  const [loader, setloader] = useState(false);

  const handleReset = () => {
    setloader(false);
    setSuccessModal(true);
    setName("");
    const closeModalDom = document.getElementById("edit_profile");
    if (closeModalDom) closeModalDom.click();
  };

  const handleEditProfile = async () => {
    startApiCall(null, setloader);
    const sendData = {
      name: name,
      employeeId: user?._id,
    };
    const res = await ApiCall(
      "post",
      editProfileEndPoints.editProfile,
      sendData
    );

    if (res?.success) {
      const updatedUser = { ...user, name: name };
      dispatch(actionCreators.addAuthenticateUser(updatedUser));
      handleReset();
    }
  };
  useEffect(() => {
    setName(user?.name);
  }, [editName, user]);

  return (
    <>
      <div className="d-flex flex-column gap-2">
        <span className="text-dark fs-4 fw-semibold mb-0">User profile</span>
        <span className="fs-4 mb-0">Your profile info in services</span>
        <div className="card rounded-7">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div className="avatar avatar-xl position-relative mx-auto">
                  <Avatar
                    name={user?.name}
                    count={1}
                    image={user?.image}
                    size="xl"
                    color="primary"
                  />
                  <span
                    className="badge text-dark border bg-white position-bottom-end-overflow-1 circle d-flex align-items-center justify-content-center mnw-0 p-0"
                    style={{ height: "24px", width: "24px" }}
                  >
                    {isUploading && <span>uploading....</span>}
                    {!isUploading && (
                      <>
                        <input
                          type="file"
                          name="image"
                          id="profilePhoto"
                          className="input-file"
                          accept="image/png,image/jpeg"
                          onChange={setFileForUpload}
                        />
                        <label
                          htmlFor="profilePhoto"
                          className="pointer text-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                          </svg>
                        </label>
                      </>
                    )}
                  </span>{" "}
                </div>
                <div className="d-flex flex-column">
                  <span className="fs-4 fw-semibold mb-0 text-dark">
                    {user?.name}
                  </span>
                  <span className="fs-4 fw-semibold mb-0 text-capitalize">
                    {isUserSuperAdmin(user)
                      ? "Super Admin"
                      : getWorkSpaceRole(user?.permissions, "roleName")}
                  </span>
                  {/* <span className="fs-5 fw-medium mb-0">
                    Kanpur, Uttar pradesh
                  </span> */}
                </div>
              </div>
              <div>
                <span
                  className="btn btn-outline-secondary rounded-7 d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_profile"
                  onClick={() => {
                    seteditName(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                  </svg>
                  &nbsp;Edit
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="card rounded-7">
          <div className="card-body">
            <div className="d-flex justify-content-between ">
              <span className="fs-4" style={{ color: "#525252" }}>
                Personal information
              </span>
              <div>
                <span
                  className="btn btn-outline-secondary rounded-7 d-flex align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#edit_phone"
                  onClick={() => {
                    setStep1(true);
                    seteditName(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                  </svg>
                  &nbsp;Edit
                </span>
              </div>
            </div>
            <div className="d-flex col-md-9 mt-1">
              <div className="col-sm-6 fs-5">Name</div>
              <div className="col-sm-6 fs-5">Phone</div>
            </div>
            <div className="d-flex col-md-9">
              <div className="col-sm-6 fs-5 text-dark">{user?.name}</div>
              <div className="col-sm-6 fs-5 text-dark">
                {user?.phone ? user?.phone : "-"}
              </div>
            </div>
            <div className="d-flex col-md-9 mt-2">
              <div className="col-sm-6 fs-5">Role</div>
              <div className="col-sm-6 fs-5">Email Address</div>
            </div>
            <div className="d-flex col-md-9">
              <div className="col-sm-6 fs-5 text-dark text-capitalize">
                {isUserSuperAdmin(user)
                  ? "Super Admin"
                  : getWorkSpaceRole(user?.permissions, "roleName")}
              </div>
              <div className="col-sm-6 fs-5 text-dark">{user?.email}</div>
            </div>
            <div className="d-flex col-md-9 mt-2">
              <div className="col-sm-6 fs-5">Joining Date</div>
              <div className="col-sm-6 fs-5">
                {user?.business?.length > 0
                  ? "Total Workspace"
                  : "No Workspace"}
              </div>
            </div>
            <div className="d-flex col-md-9">
              <div className="col-sm-6 fs-5 text-dark text-capitalize">
                {dateAndTimeFormatter(user?.createdAt)}
              </div>
              <div className="col-sm-6 fs-5 text-dark">
                {user?.business?.length > 0 && <>{user?.business?.length}</>}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="card rounded-7">
          <div className="card-body">
            <div className="d-flex justify-content-between ">
              <span className="fs-4" style={{ color: "#525252" }}>
                Address
              </span>
              <div>
                <span className="btn btn-outline-secondary rounded-7 d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                  </svg>
                  &nbsp;Edit
                </span>
              </div>
            </div>
            <div className="d-flex col-md-9 mt-1">
              <div className="col-sm-6 fs-5">Country</div>
              <div className="col-sm-6 fs-5">City/State</div>
            </div>
            <div className="d-flex col-md-9">
              <div className="col-sm-6 fs-5 fw-semibold">India</div>
              <div className="col-sm-6 fs-5 fw-semibold">Kolkalta</div>
            </div>
            <div className="d-flex col-md-9 mt-2">
              <div className="col-sm-6 fs-5">Postal code</div>
            </div>
            <div className="d-flex col-md-9">
              <div className="col-sm-6 fs-5 fw-semibold">759027</div>
            </div>
          </div>
        </div> */}
      </div>

      <PersonalInformation
        handleEditProfile={handleEditProfile}
        name={name}
        setName={setName}
        loader={loader}
        seteditName={seteditName}
      />
      <EditPhone
        setSuccessModal={setSuccessModal}
        setStep1={setStep1}
        step1={step1}
      />
      {successModal && (
        <SuccessPopup
          text={
            editName
              ? "Your name changed successfully"
              : "Your phone no. changed successfully"
          }
          setSuccessModal={setSuccessModal}
          successModal={successModal}
        />
      )}
    </>
  );
};

export default UserProfile;
