import React from "react";
import { Link } from "react-router-dom";
import BusinessDropDown from "./BusinessDropDown";
import {
  getCurrentWorkspaceName,
  getWorkSpaceRole,
  isUserSuperAdmin,
} from "../../../global-functions/globalFunctions";

const NavbarLeft = ({ handleSidebarActive, myApproveWorkspaces, user }) => {
  const currentWorkspaceId = localStorage.getItem("current-workspace");

  return (
    <>
      <div className="nav-start-wrap">
        <button
          onClick={handleSidebarActive}
          className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover navbar-toggle d-xl-none"
        >
          <span className="icon">
            <span className="feather-icon">
              <i className="ri-align-left"></i>
            </span>
          </span>
        </button>

        {myApproveWorkspaces?.length > 0 && (
          <div className="media align-items-center">
            <div className="dropdown">
              <Link
                to="/"
                className="d-block dropdown-toggle rounded-4 text-dark "
                data-bs-toggle="dropdown"
                data-dropdown-animation
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon me-2 btn btn-icon btn-rounded btn-soft-primary">
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase "
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </span>
                </span>
                {getCurrentWorkspaceName(
                  myApproveWorkspaces,
                  currentWorkspaceId
                )}
              </Link>
              <BusinessDropDown
                myApproveWorkspaces={myApproveWorkspaces}
                user={user}
              />
            </div>
          </div>
        )}

        <span className={`badge badge-soft-primary ms-2`}>
          {isUserSuperAdmin(user)?"Super Admin":getWorkSpaceRole(user?.permissions, "roleName")}
        </span>
      </div>
    </>
  );
};

export default NavbarLeft;
