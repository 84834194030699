import React, { useState } from "react";

const CustomTooltip = ({ text, placement, children, additional }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const getTooltipClassName = () => {
    switch (placement) {
      case "top":
        return "tooltip-text top";
      case "right":
        return "tooltip-text right";
      case "left":
        return "tooltip-text left";
      default:
        return "tooltip-text bottom";
    }
  };

  return (
    <div
      className={`tooltip-container ${placement} ${
        additional ? additional : ""
      } font-2`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && <div className={getTooltipClassName()}>{text}</div>}
    </div>
  );
};

export default CustomTooltip;
