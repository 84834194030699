import React, { useState } from "react";
import { dateFormatter } from "../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { taskEndpoint } from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import SmallLoader from "../../components/Loaders/SmallLoader";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";

const ToDoTable = ({
  page,
  pageLimit,
  allTask,
  setEdittask,
  handleGetTask,
  statusId,
}) => {
  const [loader, setloader] = useState(false);
  const user = useSelector((state) => state.user);

  const handleEditTodoTask = async (taskId, status) => {
    setloader((prevLoading) => ({
      ...prevLoading,
      [taskId]: true,
    }));
    setTimeout(() => {
      setloader((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    }, 50000);
    const data = {
      taskId,
      status:
        status?.statusName === "closed"
          ? statusId?.openStatusId
          : statusId?.closedStatusId,
      userId: user?._id,
    };
    const res = await ApiCall("post", taskEndpoint.editTask, data);
    if (res?.success) {
      toast.success("Edit task successfully!");
      handleGetTask();
      setloader((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    } else {
      setloader((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-5">
          <thead>
            <tr>
              <th className="text-center"></th>
              <th>No.</th>
              <th>Task</th>
              <th className="text-center">Due date</th>
              <th className="text-center">Priority</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {allTask?.map((tsk, index) => {
              return (
                <tr key={tsk?._id}>
                  <td className="text-center">
                    {!loader[tsk?._id] && (
                      <span
                        className="btn btn-icon btn-xs btn-rounded shadow-sm shadow-sm d-flex align-items-center justify-content-center mx-auto"
                        style={{
                          background: "#E3EDFF",
                        }}
                        onClick={() =>
                          handleEditTodoTask(tsk?._id, tsk?.status)
                        }
                      >
                        {tsk?.status?.statusName === "closed" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 23"
                            fill="none"
                          >
                            <path
                              d="M19.1186 5.67617C19.5319 5.25736 20.202 5.25736 20.6153 5.67617C21.0235 6.08984 21.0285 6.75739 20.6303 7.17729L12.1746 17.1727C12.1665 17.183 12.1578 17.1928 12.1486 17.2022C11.7353 17.621 11.0652 17.621 10.6518 17.2022L5.50627 11.9881C5.09296 11.5693 5.09296 10.8902 5.50627 10.4714C5.91958 10.0526 6.58968 10.0526 7.00299 10.4714L11.3683 14.8949L19.0905 5.70825C19.0992 5.69699 19.1086 5.68628 19.1186 5.67617Z"
                              fill="#3862DD"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </span>
                    )}
                    {loader[tsk?._id] && <SmallLoader />}
                  </td>
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td className="text-capitalize text-truncate">
                    <p
                      style={{
                        textDecoration:
                          tsk?.status?.statusName === "closed"
                            ? "line-through"
                            : "",
                        textDecorationColor:
                          tsk?.status?.statusName === "closed" ? "#3862DD" : "",
                      }}
                    >
                      {tsk?.name}
                    </p>
                  </td>
                  <td className="text-center">
                    {tsk?.dueDate ? dateFormatter(tsk?.dueDate) : "-"}
                  </td>
                  <td className="text-center text-de">
                    <span className="icon">
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="25"
                          viewBox="0 0 17 25"
                          fill="none"
                        >
                          <path d="M1 0V24.5" stroke="#979797" />
                          <path
                            d="M16.5 7.5L1 1V13L16.5 7.5Z"
                            fill={
                              tsk?.priority === "LOW"
                                ? "#0D6EFD"
                                : tsk?.priority === "MEDIUM"
                                ? "#FFC400"
                                : tsk?.priority === "NO_PRIORITY"
                                ? "#505050"
                                : "#F31D2F"
                            }
                          />
                        </svg>
                      </span>
                    </span>
                  </td>
                  <td className="text-center">
                    <span
                      className="btn btn-icon btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#add_todo_task"
                      onClick={() => {
                        setEdittask(tsk);
                      }}
                    >
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M19.377 2.42418C19.6211 2.66825 19.6211 3.06398 19.377 3.30806L18.0734 4.61166L15.5734 2.11166L16.877 0.808058C17.1211 0.56398 17.5168 0.563981 17.7609 0.808058L19.377 2.42418Z"
                            fill="#3862DD"
                          />
                          <path
                            d="M17.1895 5.49554L14.6895 2.99554L6.17326 11.5118C6.10465 11.5804 6.05296 11.664 6.02228 11.7561L5.01658 14.7732C4.93514 15.0175 5.16756 15.2499 5.41186 15.1685L8.42896 14.1628C8.52101 14.1321 8.60465 14.0804 8.67326 14.0118L17.1895 5.49554Z"
                            fill="#3862DD"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.25 16.875C1.25 17.9105 2.08947 18.75 3.125 18.75H16.875C17.9105 18.75 18.75 17.9105 18.75 16.875V9.375C18.75 9.02982 18.4702 8.75 18.125 8.75C17.7798 8.75 17.5 9.02982 17.5 9.375V16.875C17.5 17.2202 17.2202 17.5 16.875 17.5H3.125C2.77982 17.5 2.5 17.2202 2.5 16.875V3.125C2.5 2.77982 2.77982 2.5 3.125 2.5H11.25C11.5952 2.5 11.875 2.22018 11.875 1.875C11.875 1.52982 11.5952 1.25 11.25 1.25H3.125C2.08947 1.25 1.25 2.08947 1.25 3.125V16.875Z"
                            fill="#3862DD"
                          />
                        </svg>
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ToDoTable;
