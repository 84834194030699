import React from "react";

const ExpensesHeader = ({
  search,
  setsearch,
  setPage,
  handleGetAllExpense,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1>All Expenses</h1>
          <div className="ms-3">
            <button
              className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
              data-bs-toggle="modal"
              data-bs-target="#add_expense"
            >
              Add Expense
            </button>
          </div>
        </span>
      </div>
      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllExpense}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
              value={search}
              onChange={(e) => {
                setPage(1);
                setsearch(e.target.value);
              }}
          />
        </form>
      </div>
    </header>
  );
};

export default ExpensesHeader;
