import React, { useEffect, useMemo, useRef, useState } from "react";
import OrderHistoryHeader from "./OrderHistoryHeader";
import OrderHistoryTable from "./OrderHistoryTable";
import {
  closeAllModals,
  convertAmountLocal,
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  InventoryAnalyticsRelatedEndPoints,
  OrderRelatedEndPoints,
} from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import CustomDateFilterStock from "../ManageStock/CustomDateFilterStock";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentModal from "./PaymentModal";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import ReturnOrderModal from "./ReturnOrderModal";
import PrintKOT from "./PrintKOT";
import PrintKotOptionModal from "./PrintKotOptionModal";
import SubPaymentModal from "./SubPaymentModal";
import { navigateToAllInventoryInvoices } from "../../../Navigation/Navigation";

const OrderHistory = () => {
  const { allModuleAccess, user } = useSelector((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [totalSell, settotalSell] = useState([]);
  const [paymentModal, setpaymentModal] = useState(false);
  const [subPaymentModal, setsubPaymentModal] = useState(false);
  const [orderData, setorderData] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const locationData = useMemo(() => location?.state || "", [location]);
  const [returnOrder, setreturnOrder] = useState(false);
  const [printOption, setprintOption] = useState("Full KOT");
  const componentRef = useRef();
  const [paymentData, setpaymentData] = useState([]);

  const isModuleReadWriteAccessForOrderHistory =
    allModuleAccess?.[PRIVATEROUTES.ORDER_HISTORY];

  // *******Confirm Modal ******/////
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [isOrder, setIsOrder] = useState([]);
  const [selectStatus, setSelectStatus] = useState("");

  const currentDate = new Date();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(currentDate.getDate() - 1);
  const [startDate, setstartDate] = useState("");

  const [endDate, setendDate] = useState(reversesDateFormatter(currentDate));
  const [dateType, setdateType] = useState("Today");
  const [dateModal, setdateModal] = useState(false);

  const handleResetFilters = (type, isReset) => {
    setstartDate("");
    setendDate("");
    setdateModal(false);
  };

  useEffect(() => {
    if (locationData) {
      setdateType(
        locationData?.dateType === "yesterday"
          ? "Yesterday"
          : locationData?.dateType
      );
      if (dateType === "Date Range") {
        setstartDate(locationData?.startDate);
        setendDate(locationData?.endDate);
        handleGetOrders();
      }
    }
  }, [locationData, dateType]);

  // Get Order Function
  const handleGetOrders = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        startDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Yesterday"
            ? reversesDateFormatter(yesterdayDate)
            : dateType === "Date Range"
            ? startDate
            : "",
        endDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Yesterday"
            ? reversesDateFormatter(yesterdayDate)
            : dateType === "Date Range"
            ? endDate
            : "",
        status: selectStatus,
        "products.productId": locationData ? locationData?.id : "",
        isApproved: "approved",
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.getOrders, data);
    if (res?.success) {
      setIsOrder(res?.orders?.data);
      setdateModal(false);
      setcount(res?.orders?.totalCounts);
      settotalPages(res?.orders?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // Get Total Sell Function
  const handleGetTotalSalesInfo = async () => {
    startApiCall(null, setloader2);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "Yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? startDate
          : "",
      endDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "Yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? endDate
          : "",
    };
    const res = await ApiCall(
      "post",
      InventoryAnalyticsRelatedEndPoints.getSalesOverview,
      data
    );
    if (res?.success) {
      settotalSell(res?.data);
      settotalPages(res?.data);
      setdateModal(false);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (dateType !== "Date Range") {
      if (getCurrentWorkspaceId() && !locationData) {
        handleGetOrders();
        handleGetTotalSalesInfo();
      } else if (locationData && locationData?.id && dateType) {
        handleGetOrders();
      }
    } else if (dateType === "Date Range" && startDate && endDate) {
      handleGetOrders();
      handleGetTotalSalesInfo();
    }
    closeAllModals();
  }, [search, page, pageLimit, selectStatus, dateType]);

  const handleRefresh = () => {
    handleGetOrders();
    if (!locationData) {
      handleGetTotalSalesInfo();
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  //CANCEL ORDER

  const handleCancelOrders = async () => {
    startApiCall(setErrorMessage, setloader3);
    const data = {
      orderId: orderData?._id,
      actionBy: user?._id,
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.cancelOrder, data);
    if (res?.success) {
      toast.success("Order cancelled successfully!");
      handleClose();
      handleGetOrders();
      setloader3(false);
    } else {
      setloader3(false);
      setErrorMessage(res?.error);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <OrderHistoryHeader
            handleRefresh={handleRefresh}
            search={search}
            handleSearch={handleSearch}
            dateType={dateType}
            setdateType={setdateType}
            setdateModal={setdateModal}
            selectStatus={selectStatus}
            setSelectStatus={setSelectStatus}
            locationData={locationData}
            handleResetFilters={handleResetFilters}
          />

          {!loader2 && !locationData && (
            <div className="d-flex p-3 pb-0 justify-content-between ">
              <div className="d-flex gap-3">
                <div
                  className="card mb-0"
                  style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
                >
                  <div className="card-body text-center py-2">
                    <h5 className="font-3">Paid Orders</h5>
                    <p className="text-dark mb-0 fw-bold ">
                      {totalSell?.orders}
                    </p>
                  </div>
                </div>
                <div
                  className="card mb-0"
                  style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }}
                >
                  <div className="card-body text-center py-2">
                    <h5 className="font-3">Total Sell</h5>
                    <p className="text-dark mb-0 fw-bold font-mon">
                      {totalSell?.totalCosts?.length > 0
                        ? convertAmountLocal(
                            totalSell?.totalCosts?.[0]?.revenue
                          )
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate(navigateToAllInventoryInvoices())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-receipt me-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z" />
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" />
                  </svg>
                  View All Invoice{" "}
                </button>
              </div>
            </div>
          )}
          {loader2 && !locationData && (
            <div className="d-flex gap-3 p-3 pb-0">
              <div className="card mb-0 loading-skeleton">
                <div className="card-body py-2">
                  <SkeletonConstant
                    width="w-90p"
                    height="h-25p"
                    additional="mb-1"
                  />
                  <SkeletonConstant width="w-100" height="h-25p" />
                </div>
              </div>
              <div className="card mb-0 loading-skeleton">
                <div className="card-body py-2">
                  <SkeletonConstant
                    width="w-90p"
                    height="h-25p"
                    additional="mb-1"
                  />
                  <SkeletonConstant width="w-100" height="h-25p" />
                </div>
              </div>
            </div>
          )}

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && isOrder?.length > 0 && (
                <OrderHistoryTable
                  isOrder={isOrder}
                  page={page}
                  pageLimit={pageLimit}
                  locationData={locationData}
                  setpaymentModal={setpaymentModal}
                  setorderData={setorderData}
                  setIsOpen={setIsOpen}
                  setreturnOrder={setreturnOrder}
                  isModuleReadWriteAccessForOrderHistory={
                    isModuleReadWriteAccessForOrderHistory
                  }
                  componentRef={componentRef}
                />
              )}

              {loader && <DataLoader title="data is loading..." />}
              {!loader && isOrder.length === 0 && (
                <NoData title="No Orders Added" />
              )}
              {!loader && isOrder?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={isOrder?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
        <CustomDateFilterStock
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader}
          handleResetFilters={handleResetFilters}
          dateModal={dateModal}
          handleGetAllStock={handleRefresh}
          dateType={dateType}
          setdateType={setdateType}
          setdateModal={setdateModal}
        />
        {paymentModal && (
          <PaymentModal
            setpaymentModal={setpaymentModal}
            orderData={orderData}
            paymentModal={paymentModal}
            setsubPaymentModal={setsubPaymentModal}
            paymentData={paymentData}
            setpaymentData={setpaymentData}
            handleGetOrders={handleGetOrders}
          />
        )}
        {subPaymentModal && (
          <SubPaymentModal
            setsubPaymentModal={setsubPaymentModal}
            setpaymentData={setpaymentData}
            paymentData={paymentData}
          />
        )}

        <ConfirmModal
          isOpen={isOpen}
          onClose={handleClose}
          text={"Are You Sure You Want To Cancel This Order"}
          loader={loader3}
          errorMessage={errorMessage}
          handleConfirm={handleCancelOrders}
        />
        {returnOrder && (
          <ReturnOrderModal
            setreturnOrder={setreturnOrder}
            orderData={orderData}
            handleGetOrders={handleGetOrders}
          />
        )}

        <PrintKotOptionModal
          componentRef={componentRef}
          setprintOption={setprintOption}
          printOption={printOption}
          orderData={orderData}
        />

        <div style={{ display: "none" }}>
          {orderData && (
            <PrintKOT
              ref={componentRef}
              orderData={orderData}
              printOption={printOption}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
