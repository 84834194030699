import React from 'react'

const EmployeeHeader = (props) => {
    const {handleGetAllEmployees,setSearch,search,count,loader,allEmployees,setPage} = props
  return (
    <header className="todo-header">
    <div className="d-flex align-items-center">
      <a className="todoapp-title  link-dark">
        <h1>All Employees</h1>
      </a>
    </div>

    {allEmployees?.length > 0 && !loader && (
      <div className="d-flex align-items-center">
        <span className="d-md-inline">Total Employees:</span>
        <span className="text-dark fs-5 fw-medium ps-2">
          {count}{" "}
        </span>
      </div>
    )}

    <div className="todo-options-wrap">
      <span
        className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title=""
        data-bs-original-title="Refresh"
      >
        <span className="btn-icon-wrap" onClick={handleGetAllEmployees}>
          <span className="feather-icon">
            <i className="ri-refresh-line"></i>
          </span>
        </span>
      </span>
      <div className="v-separator d-lg-inline-block d-none"></div>
      <form className="d-sm-block d-none" role="search">
        <input
          type="text"
          className="form-control"
          placeholder="Search by name"
          value={search}
          onChange={(e) => {
            setPage(1)
            setSearch(e.target.value);
          }}
        />
      </form>
    </div>
  </header>
  )
}

export default EmployeeHeader