import React, { useEffect, useState } from "react";
import AddQuoteAddressDetails from "./AddQuoteAddressDetails";
import AddQuoteItemTable from "./AddQuoteItemTable";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { CustomerRelatedEndPoints } from "../../../../api/Endpoints";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";

const AddQuoteBody = (props) => {
  const {
    selectedCustomer,
    setselectedCustomer,
    quoteNumber,
    setQuoteNumber,
    quoteDate,
    setQuoteDate,
    expiryDate,
    setExpiryDate,
    quoteSubject,
    setQuoteSubject,
    quoteItems,
    calculateTotalSum,
    discount,
    setDiscount,
    handleAddQuote,
    items,
    errorMessage,
    shippingAddress,
    setshippingAddress,
    billingAddress,
    setbillingAddress,
    termsAndConditions,
    setTermsAndConditions,
    locationData,
    editQuotes,
    handleEditQuote,
    setEditItem,
    loader3
  } = props;
  const [loader2, setloader2] = useState(false);
  const [allCustomers, setallCustomers] = useState([]);
  const [customerData, setcustomerData] = useState([]);
  const currentDate = new Date()

  const handleCustomerSelection = (selectedValue) => {
    const selectedCustomer = allCustomers.find(
      (customer) => customer._id === selectedValue
    );

    if (selectedCustomer) {
      setcustomerData(selectedCustomer);
    }
  };

  const handleGetAllCustomer = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      CustomerRelatedEndPoints.getCustomer,
      data
    );
    if (res?.success) {
      setallCustomers(res?.customer?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllCustomer();
    }
  }, []);

  return (
    <>
      <div className="contact-list-view">
        <div className="row">
          <div className="col-md-8 px-5 py-2">
            <div className="d-flex flex-column gap-3">
              <div className="row">
                <div className="col-lg-6">
                  <div class="form-group row w-100 align-items-center">
                    <div className="col-md-5 col-sm-4">
                      <label className="form-label mb-0">Customer Name</label>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="form-group mb-0">
                        <div className="todo-toolbar">
                          <select
                            required
                            name="unittype"
                            className="form-select form-select-md"
                            disabled={locationData?.id}
                            value={selectedCustomer}
                            onChange={(e) => {
                              setselectedCustomer(e.target.value);
                              handleCustomerSelection(e.target.value);
                            }}
                          >
                            <option value="">Select Customer</option>
                            {allCustomers?.map((customer) => {
                              return (
                                <option
                                  value={customer?._id}
                                  onClick={() => {
                                    setcustomerData(customer);
                                  }}
                                >
                                  {locationData?.id
                                    ? editQuotes?.customerId?.name
                                    : customer?.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group row w-100 align-items-center">
                    <div className="col-md-5 col-sm-4">
                      <label className="form-label mb-0">Quote number</label>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <input
                        class="form-control"
                        type="number"
                        disabled={locationData?.id}
                        value={quoteNumber}
                        onChange={(e) => {
                          setQuoteNumber(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group row w-100 align-items-center">
                    <div className="col-md-5 col-sm-4">
                      <label className="form-label mb-0">Quote date</label>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <input
                        class="form-control"
                        type="date"
                        disabled={locationData?.id}
                        value={quoteDate}
                        onChange={(e) => {
                          setQuoteDate(e.target.value);
                        }}
                        min={reversesDateFormatter(currentDate)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="form-group row w-100 align-items-center">
                    <div className="col-md-5 col-sm-4">
                      <label className="form-label mb-0">Expiry date</label>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <input
                        class="form-control"
                        type="date"
                        value={expiryDate}
                        onChange={(e) => {
                          setExpiryDate(e.target.value);
                        }}
                        min={quoteDate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div class="form-group row">
                    <div className="col-md-2 col-sm-4">
                      <label className="form-label">Quote subject</label>
                    </div>
                    <div className="col-md-10 col-sm-8">
                      <div className="d-flex align-items-start">
                        <textarea
                          class="form-control"
                          placeholder="Add subject"
                          rows="2"
                          value={quoteSubject}
                          onChange={(e) => {
                            setQuoteSubject(e.target.value);
                          }}
                        ></textarea>
                        <button className="btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                              fill="#6F6F6F"
                            />
                            <path
                              d="M8.9307 6.58789L6.63969 6.875L6.55766 7.25586L7.00883 7.33789C7.3018 7.4082 7.36039 7.51367 7.29594 7.80664L6.55766 11.2754C6.3643 12.1719 6.66313 12.5938 7.36625 12.5938C7.91117 12.5938 8.54398 12.3418 8.83109 11.9961L8.91898 11.5801C8.71977 11.7559 8.4268 11.8262 8.23344 11.8262C7.95805 11.8262 7.85844 11.6328 7.92875 11.293L8.9307 6.58789Z"
                              fill="#6F6F6F"
                            />
                            <path
                              d="M9 4.5C9 5.05228 8.55229 5.5 8 5.5C7.44772 5.5 7 5.05228 7 4.5C7 3.94772 7.44772 3.5 8 3.5C8.55229 3.5 9 3.94772 9 4.5Z"
                              fill="#6F6F6F"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <AddQuoteItemTable
                quoteItems={quoteItems}
                setEditItem={setEditItem}
                locationData={locationData}
              />

              <div className="d-flex justify-content-end me-4">
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_new_quote"
                >
                  + Add Item
                </button>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group d-flex align-items-center justify-content-between gap-3">
                    <label>Discount</label>
                    <input
                      class="form-control w-75"
                      type="number"
                      value={discount}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group d-flex align-items-center justify-content-between gap-3">
                    <label>Adjustment</label>
                    <input
                      class="form-control w-75"
                      type="text"
                      // value={invoiceNumber}
                      // onChange={(e) => {
                      //   setinvoiceNumber(e.target.value);
                      // }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <textarea
                    class="form-control"
                    placeholder="Add terms and conditions"
                    rows="3"
                    value={termsAndConditions}
                    onChange={(e) => {
                      setTermsAndConditions(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between border text-dark px-3 py-2">
                      <p>Shipping Amount</p>
                      <p>0</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between border text-dark fw-semibold px-3 py-2">
                      <p>Total Amount</p>
                      <p>{calculateTotalSum()}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-md-4">
                  <div className="d-flex flex-column align-items-center gap-3">
                    <p className="text-dark fw-semibold">
                      Attach files to Quote
                    </p>
                    <span className="card bg-light py-2 w-100 text-center">
                      <p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M0.75 14.85C1.16421 14.85 1.5 15.1858 1.5 15.6V19.35C1.5 20.1785 2.17157 20.85 3 20.85H21C21.8284 20.85 22.5 20.1785 22.5 19.35V15.6C22.5 15.1858 22.8358 14.85 23.25 14.85C23.6642 14.85 24 15.1858 24 15.6V19.35C24 21.0069 22.6569 22.35 21 22.35H3C1.34315 22.35 0 21.0069 0 19.35V15.6C0 15.1858 0.335786 14.85 0.75 14.85Z"
                            fill="#6F6F6F"
                          />
                          <path
                            d="M11.4697 1.71967C11.7626 1.42678 12.2374 1.42678 12.5303 1.71967L17.0303 6.21967C17.3232 6.51256 17.3232 6.98744 17.0303 7.28033C16.7374 7.57322 16.2626 7.57322 15.9697 7.28033L12.75 4.06066V17.25C12.75 17.6642 12.4142 18 12 18C11.5858 18 11.25 17.6642 11.25 17.25V4.06066L8.03033 7.28033C7.73744 7.57322 7.26256 7.57322 6.96967 7.28033C6.67678 6.98744 6.67678 6.51256 6.96967 6.21967L11.4697 1.71967Z"
                            fill="#6F6F6F"
                          />
                        </svg>
                        &nbsp; Upload files
                      </p>
                    </span>
                  </div>
                </div> */}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex modal-footer align-items-center justify-content-end ">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (locationData?.id) {
                      handleEditQuote();
                    } else {
                      handleAddQuote();
                    }
                  }}
                  disabled={
                    !quoteDate ||
                    !expiryDate ||
                    !selectedCustomer ||
                    !quoteNumber ||
                    !billingAddress?.country ||
                    !billingAddress?.city ||
                    !billingAddress?.zipCode ||
                    !billingAddress?.address ||
                    !items?.length
                  }
                >
                  {loader3 ? <ApiLoader /> : locationData?.id ? "Edit Quote" : "Add New Quote"}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-2">
            <AddQuoteAddressDetails
              shippingAddress={shippingAddress}
              setshippingAddress={setshippingAddress}
              billingAddress={billingAddress}
              setbillingAddress={setbillingAddress}
              customerData={customerData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQuoteBody;
