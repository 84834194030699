import React, { useState } from "react";
import allCountries from "../../../../components/Countries/Countries";
import { reuiredField } from "../../../../global-functions/globalFunctions";

const CustomerInfo = (props) => {
  const {
    selectSetup2,
    customerType,
    setCustomerType,
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    completeFirstStep,
    editCustomer,
    gstNo,
    setGstNo,
    panNo,
    setPanNo,
    setcountryCode,
    countryCode,
  } = props;

  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [codeFilter, setcodeFilter] = useState("");

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    const truncatedPhone = newPhone.slice(0, 10);

    if (newPhone.length > 10) {
      setPhone(truncatedPhone);
    } else {
      setPhone(newPhone);
    }

    setIsPhoneValid(/^[0-9]{10}$/.test(truncatedPhone));
  };
  const filteredCountries = allCountries?.filter(
    (country) =>
      country?.code?.includes(codeFilter) ||
      country?.country?.toLowerCase()?.includes(codeFilter?.toLowerCase())
  );

  return (
    <div
      className="d-flex flex-column justify-content-between border rounded-7 p-4 w-100 mnh-400p h-100"
      style={{ zIndex: 1 }}
    >
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <span className="text-dark w-20">
            Customer Type {reuiredField}
          </span>
          &nbsp;&nbsp;
          <input
            type="radio"
            id="customRadioc2"
            name="customRadioc2"
            className="form-check-input pointer"
            value="business"
            required
            disabled={editCustomer}
            checked={customerType === "business"}
            onClick={(e) => setCustomerType("business")}
          />
          <label className="form-check-label fs-7">Business</label>
          <input
            type="radio"
            id="customRadioc2"
            name="customRadioc2"
            className="form-check-input pointer"
            value="individual"
            required
            disabled={editCustomer}
            checked={customerType === "individual"}
            onClick={(e) => setCustomerType("individual")}
          />
          <label className="form-check-label fs-7">Individual</label>
        </div>
        <div className="d-flex gap-2 mt-2">
          <span className="text-dark w-20">Name {reuiredField}</span>
          &nbsp;
          <input
            className="form-control w-50"
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="d-flex gap-2 mt-2">
          <span className="text-dark w-20">Phone {reuiredField}</span>
          &nbsp;
          <div className="selectable-dropdown">
            <div className="dropdown selectable-dropdown">
              <span
                className={
                  "me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-110p "
                }
                aria-expanded="false"
                type="button"
                data-bs-toggle="dropdown"
                onClick={() => setcodeFilter("")}
              >
                {countryCode ? (
                  <div className="d-flex justify-content-between">
                    + {countryCode}
                    {countryCode !== "" && (
                      <button
                        type="button"
                        className="btn-close btn-sm ms-1"
                        onClick={() => {
                          setcountryCode("");
                          setcodeFilter("");
                        }}
                        aria-label="Close"
                      ></button>
                    )}
                  </div>
                ) : (
                  "Code"
                )}
              </span>
              <div
                role="menu"
                className="dropdown-menu"
                // style={{
                //   position: "absolute",
                //   zIndex: 100,
                //   top: "-340px", // Adjust the top position to move it above
                // }}
              >
                <form className="d-sm-block d-none" role="search">
                  <input
                    type="text"
                    placeholder="Search.."
                    className="form-control"
                    value={codeFilter}
                    onChange={(e) => setcodeFilter(e.target.value)}
                  />
                </form>
                <div
                  style={{
                    maxHeight: "28vh",
                    overflow: "auto",
                    // position: "absolute",
                    zIndex: 9999,
                    // top: "-340px", // Adjust the top position to move it above
                  }}
                >
                  {filteredCountries?.map((country) => (
                    <div
                      className="dropdown-item text-Capitalize"
                      key={country.country}
                      onClick={() => setcountryCode(country.code)}
                    >
                      <span>
                        {" "}
                        + {country?.code} &nbsp; {country?.country}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <input
            className="form-control w-50 "
            type="number"
            pattern="[0-9]{10}"
            title="Please enter a 10-digit phone number"
            required
            value={phone}
            disabled={!countryCode}
            // onChange={handlePhoneChange}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="d-flex gap-2 mt-2">
          <span className="text-dark w-20">Email {reuiredField}</span>
          &nbsp;
          <input
            className="form-control w-50"
            type="email"
            required
            disabled={editCustomer}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="d-flex gap-2 mt-2">
          <span className="text-dark w-20">Pan No </span>
          &nbsp;
          <input
            className="form-control w-50"
            type="text"
            required
            value={panNo}
            onChange={(e) => setPanNo(e.target.value)}
          />
        </div>
        {customerType === "business" && (
          <div className="d-flex gap-2 mt-2">
            <span className="text-dark w-20">Gst No </span>
            &nbsp;
            <input
              className="form-control w-50"
              type="text"
              required
              value={gstNo}
              onChange={(e) => setGstNo(e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2">
        <button
          className="btn btn-sm btn-primary px-4"
          onClick={selectSetup2}
          disabled={!completeFirstStep}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomerInfo;
