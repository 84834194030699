import React, { useState } from "react";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  CrmContactRelatedEndPoints,
  LeadRelatedEndPoints,
  CrmTaskRelatedEndPoints,
  DealRelatedEndPoints,
} from "../../../api/Endpoints";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import moment from "moment";

const LogCalls = ({
  handleRefresh,
  editCall,
  seteditCall,
  isLeadDeal,
  leadDetails,
  groupLoader,
  allGroups,
  id,
  description,
  setdescription,
}) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [title, settitle] = useState("");
  var today = reversesDateFormatter(new Date());
  var currentTime = moment().format("HH:mm");

  const [startTime, setstartTime] = useState(currentTime);
  const [type, settype] = useState("incoming");
  const [duration, setduration] = useState("");
  const [date, setdate] = useState(today);
  const [recording, setrecording] = useState("");
  const [relatedTo, setrelatedTo] = useState("Contact");

  //Activity Group And Status
  const [selectedGroup, setselectedGroup] = useState("");
  const [selectedActivityStatus, setselectedActivityStatus] = useState("");

  //Select Contact
  const [contactLoader, setcontactLoader] = useState(false);
  const [selectContact, setselectContact] = useState("");
  const [filter, setFilter] = useState("");
  const [allContacts, setallContacts] = useState([]);
  const [contactId, setcontactId] = useState("");
  const filteredContacts = filterSearchData(allContacts, filter);

  //Select Lead
  const [leadLoader, setleadLoader] = useState(false);
  const [selectlead, setselectlead] = useState("");
  const [filterLeads, setFilterLeads] = useState("");
  const [allLeads, setallLeads] = useState([]);
  const [leadId, setleadId] = useState("");
  const filteredleads = allLeads?.filter((data) =>
    data?.title?.toLowerCase()?.includes(filterLeads.toLowerCase())
  );

  // Select Deal
  const [dealLoader, setdealLoader] = useState(false);
  const [selectdeal, setselectdeal] = useState("");
  const [filterDeals, setFilterDeals] = useState("");
  const [allDeals, setallDeals] = useState([]);
  const [dealId, setdealId] = useState("");
  const filtereddeals = allDeals?.filter((data) =>
    data?.title?.toLowerCase()?.includes(filterDeals.toLowerCase())
  );

  //Contacts
  const handleGetContact = async () => {
    startApiCall(null, setcontactLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isRemoved: false,
      },
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.getContact,
      data
    );
    if (res?.success) {
      setallContacts(res?.result?.data);
      setcontactLoader(false);
    } else {
      setcontactLoader(false);
    }
  };
  //Leads
  const handleGetLeads = async () => {
    startApiCall(null, setleadLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "lead",
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLead, data);
    if (res?.success) {
      setallLeads(res?.result?.data);
      setleadLoader(false);
    } else {
      setleadLoader(false);
    }
  };
  // Deals
  const handleGetAllDeals = async () => {
    startApiCall(null, setdealLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "deal",
      },
    };
    const res = await ApiCall("post", DealRelatedEndPoints.getDeal, data);
    if (res?.success) {
      setallDeals(res?.result?.data);
      setdealLoader(false);
    } else {
      setdealLoader(false);
    }
  };

  //Log CALL IN CRM

  const handleLogCall = async (e) => {
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      taskType: "call",
      title: title,
      activityDate: date,
      relatedTo:
        isLeadDeal && leadDetails?.status === "lead"
          ? "Lead"
          : isLeadDeal && leadDetails?.status === "deal"
          ? "Deal"
          : relatedTo === "Lead"
          ? "Lead"
          : relatedTo === "Deal"
          ? "Deal"
          : relatedTo,
      startTime: startTime,
      actionOn: isLeadDeal
        ? id
        : relatedTo === "Contact"
        ? contactId
        : relatedTo === "Lead"
        ? leadId
        : dealId,
      actionBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      priority: "low",
      recordingLink: recording,
      duration: duration,
      isLog: true,
      activityGroup: selectedGroup,
      notes: description,
      createdBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.addTask,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleRefresh();
      toast.success("Call Log created successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    settitle("");
    setstartTime(currentTime);
    setduration("");
    setdate(today);
    seteditCall("");
    setrecording("");
    setleadId("");
    setselectlead("");
    setrelatedTo("Contact");
    setcontactId("");
    setselectContact("");
    setselectedGroup("");
    settype("incoming");
    setselectedActivityStatus("");
    setdescription("");
    const closeModalDom = document.getElementById("create_call_modal");
    if (closeModalDom) closeModalDom.click();
  };

  // const handleGetAllStatus = async (id) => {
  //   startApiCall(setErrorMessage, setloader3);
  //   const data = {
  //     page: 1,
  //     limit: 200,
  //     filters: {
  //       businessId: getCurrentWorkspaceId(),
  //       activityGroupId: selectedGroup,
  //     },
  //   };
  //   const res = await ApiCall(
  //     "post",
  //     activityGroupRelatedEndpoints.getActivitySubStatus,
  //     data
  //   );
  //   if (res?.success) {
  //     setallStatus(res?.status?.data);
  //     setloader3(false);
  //   } else {
  //     setloader3(false);
  //     // setloader3(res?.error);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedGroup !== "") {
  //     handleGetAllStatus();
  //   }
  // }, [selectedGroup]);

  // useEffect(() => {
  //   if (allStatus?.length > 0) {
  //     setselectedActivityStatus(allStatus?.[0]);
  //   }
  // }, [allStatus]);

  return (
    <div>
      <div className="row gx-4 px-2 w-100">
        <div className="col-sm-6 mb-3">
          <label className="form-label">Call Title {reuiredField}</label>

          <input
            className="form-control p-2"
            type="text"
            placeholder="Add a call title"
            required
            value={title}
            onChange={(e) => {
              settitle(e.target.value);
            }}
          />
        </div>
        <div className="col-sm-6 mb-3">
          <label className="form-label">Add Recording Link</label>

          <input
            className="form-control p-2"
            type="text"
            pattern="https?://(?:www\.)?\S+"
            placeholder="Call Recording Link"
            value={recording}
            onChange={(e) => {
              setrecording(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="row gx-4 px-2 w-100">
        <div className="col-sm-6">
          <div className="form-group">
            <div className="form-group">
              <label className="form-label">Choose Type {reuiredField}</label>
              <div className="todo-toolbar">
                <select
                  className="form-select form-select-md p-2"
                  value={type}
                  onChange={(e) => {
                    settype(e.target.value);
                  }}
                >
                  <option value="">Type</option>
                  <option value="outgoing">Outgoing Call </option>
                  <option value="incoming">Incoming Call </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <label className="form-label">
            Choose Activity Status group {reuiredField}
          </label>
          <div className="form-group">
            <div className="todo-toolbar">
              <select
                className="form-select form-select-md"
                name="leadStatusGroup"
                required
                value={selectedGroup}
                onChange={(e) => {
                  setselectedGroup(e.target.value);
                }}
              >
                <option value="">Select Group</option>
                {groupLoader && <option disabled>Loading Groups...</option>}
                {!groupLoader && (
                  <>
                    {allGroups?.map((item, index) => {
                      return (
                        <option value={item?._id}>
                          {index + 1}. {item?.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
          </div>
        </div>
        {!isLeadDeal && (
          <div className="col-sm-6">
            <div className="form-group">
              <label className="form-label">Related To</label>
              <div className="form-group">
                <div className="todo-toolbar">
                  <select
                    className="form-select form-select-md"
                    value={relatedTo}
                    onChange={(e) => {
                      setrelatedTo(e.target.value);
                    }}
                  >
                    <option value="Contact">Contact</option>
                    <option value="Lead">Lead</option>
                    <option value="Deal">Deal</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLeadDeal && (
          <>
            {relatedTo === "Contact" && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Select Contact </label>
                  <div className="selectable-dropdown w-100">
                    <div className="dropdown selectable-dropdown">
                      <span
                        className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100  `}
                        aria-expanded="false"
                        type="button"
                        data-bs-toggle="dropdown"
                        onClick={() => {
                          setFilter("");
                          handleGetContact();
                        }}
                      >
                        {selectContact ? (
                          <>
                            {selectContact}
                            {selectContact !== "" && (
                              <button
                                type="button"
                                className="btn-close btn-sm ms-2"
                                onClick={() => {
                                  setselectContact("");
                                  setFilter("");
                                }}
                                aria-label="Close"
                              ></button>
                            )}
                          </>
                        ) : (
                          "Select Contact"
                        )}
                      </span>
                      <div role="menu" className="dropdown-menu">
                        <form className="d-sm-block d-none" role="search">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search.."
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                          />
                        </form>
                        {contactLoader ? (
                          <span className="d-flex text-center mt-1">
                            Loading...
                          </span>
                        ) : (
                          <div
                            style={{
                              maxHeight: "12rem",
                              overflow: "auto",
                            }}
                          >
                            {filteredContacts?.map((contact, index) => (
                              <div
                                className={`dropdown-item text-capitalize`}
                                key={contact?._id}
                                onClick={() => {
                                  setselectContact(contact?.name);
                                  setcontactId(contact?._id);
                                }}
                              >
                                {index + 1}. {contact?.name}
                              </div>
                            ))}
                          </div>
                        )}

                        {!contactLoader && filteredContacts?.length === 0 && (
                          <span className="d-flex text-center mt-1">
                            No Contacts found
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {relatedTo === "Lead" && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Select Leads </label>
                  <div className="selectable-dropdown w-100">
                    <div className="dropdown selectable-dropdown">
                      <span
                        className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100  `}
                        aria-expanded="false"
                        type="button"
                        data-bs-toggle="dropdown"
                        onClick={() => {
                          setFilterLeads("");
                          handleGetLeads();
                        }}
                      >
                        {selectlead ? (
                          <>
                            {selectlead}
                            {selectlead !== "" && (
                              <button
                                type="button"
                                className="btn-close btn-sm ms-2"
                                onClick={() => {
                                  setselectlead("");
                                  setFilterLeads("");
                                }}
                                aria-label="Close"
                              ></button>
                            )}
                          </>
                        ) : (
                          "Select Lead"
                        )}
                      </span>
                      <div role="menu" className="dropdown-menu">
                        <form className="d-sm-block d-none" role="search">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search.."
                            value={filterLeads}
                            onChange={(e) => setFilterLeads(e.target.value)}
                          />
                        </form>
                        {leadLoader ? (
                          <span className="d-flex text-center mt-1">
                            Loading...
                          </span>
                        ) : (
                          <div
                            style={{
                              maxHeight: "13vh",
                              overflow: "auto",
                            }}
                          >
                            {filteredleads?.map((lead, index) => (
                              <div
                                className={`dropdown-item text-capitalize`}
                                key={lead?._id}
                                onClick={() => {
                                  setselectlead(lead?.title);
                                  setleadId(lead?._id);
                                }}
                              >
                                {index + 1}. {lead?.title}
                              </div>
                            ))}
                          </div>
                        )}

                        {!leadLoader && filteredleads?.length === 0 && (
                          <span className="d-flex text-center mt-1">
                            No Leads found
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {relatedTo === "Deal" && (
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="form-label">Select Deal </label>
                  <div className="selectable-dropdown w-100">
                    <div className="dropdown selectable-dropdown">
                      <span
                        className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                          editCall ? "bg-light text-disabled" : ""
                        } `}
                        aria-expanded="false"
                        type="button"
                        data-bs-toggle="dropdown"
                        disabled={editCall}
                        onClick={() => {
                          setFilterDeals("");
                          handleGetAllDeals();
                        }}
                      >
                        {selectdeal ? (
                          <>
                            {selectdeal}
                            {selectdeal !== "" && !editCall && (
                              <button
                                type="button"
                                className="btn-close btn-sm ms-2"
                                onClick={() => {
                                  setselectdeal("");
                                  setFilterDeals("");
                                }}
                                aria-label="Close"
                              ></button>
                            )}
                          </>
                        ) : (
                          "Select Deal"
                        )}
                      </span>
                      <div role="menu" className="dropdown-menu">
                        <form className="d-sm-block d-none" role="search">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search.."
                            value={filterDeals}
                            onChange={(e) => setFilterDeals(e.target.value)}
                          />
                        </form>
                        {dealLoader ? (
                          <span className="d-flex text-center mt-1">
                            Loading...
                          </span>
                        ) : (
                          <div
                            style={{
                              maxHeight: "13vh",
                              overflow: "auto",
                            }}
                          >
                            {filtereddeals?.map((deal, index) => (
                              <div
                                className={`dropdown-item text-capitalize`}
                                key={deal?._id}
                                onClick={() => {
                                  setselectdeal(deal?.title);
                                  setdealId(deal?._id);
                                }}
                              >
                                {index + 1}. {deal?.title}
                              </div>
                            ))}
                          </div>
                        )}

                        {!dealLoader && filtereddeals?.length === 0 && (
                          <span className="d-flex text-center mt-1">
                            No Deals found
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="row gx-4 px-2 w-100">
        <div className="col-sm-6">
          <label className="form-label">Log Date {reuiredField}</label>

          <div className="form-group">
            <input
              className="form-control p-2"
              type="date"
              required
              value={date}
              onChange={(e) => {
                setdate(e.target.value);
              }}
              max={today}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <label className="form-label">Log Time {reuiredField}</label>
          <div className="form-group">
            <input
              className="form-control p-2"
              type="time"
              required
              value={startTime}
              onChange={(e) => {
                setstartTime(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="row gx-4 px-2 w-100">
        <div className="col-sm-6">
          <label className="form-label">Add Duration Time {reuiredField}</label>

          <div className="form-group">
            <input
              className="form-control p-2"
              type="number"
              placeholder="Duration (minutes)"
              required
              value={duration}
              onChange={(e) => {
                setduration(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <div className="form-group">
              <label className="form-label">Note</label>
              <div className="todo-toolbar">
                <textarea
                  className="form-control"
                  rows="3"
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                  value={description}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      <div className="d-flex justify-content-end ">
        <button
          type="button"
          className="btn btn-primary"
          disabled={
            loader ||
            !startTime ||
            title === "" ||
            date === "" ||
            duration === "" ||
            (!isLeadDeal &&
              ((relatedTo === "Contact" && !selectContact) ||
                (relatedTo === "Lead" && !selectlead) ||
                (relatedTo === "Deal" && !selectdeal)))
          }
          onClick={(e) => {
            handleLogCall(e);
          }}
        >
          {loader ? <ApiLoader /> : "Save"}
        </button>
      </div>
    </div>
  );
};

export default LogCalls;
