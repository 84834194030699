import React from "react";
import { useSelector } from "react-redux";
import { getName } from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";

const AssigneeDetails = ({ setassigneeEmpModal, assigneeDetails }) => {
  const user = useSelector((state) => state.user);

  const closeModal = () => {
    setassigneeEmpModal(false);
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
      >
        <div className="modal-content p-2">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={closeModal}>
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Assignee Details</h5>
            </span>
            <div className="border-bottom mb-3"></div>
            <form>
              <div className="row gx-3 px-3 w-100 mh-400p overflow-auto">
                <p className="font-4 text-capitalize">
                  {assigneeDetails?.assignees?.map((item, index) => {
                    return (
                      <span className="text-dark mt-3  d-flex">
                        {index + 1}.&nbsp;
                        <Avatar
                          name={item?.name}
                          count={1}
                          image={item?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(item, user?._id)}
                        <br />
                      </span>
                    );
                  })}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssigneeDetails;
