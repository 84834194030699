import React from "react";
import { reuiredField } from "../../../global-functions/globalFunctions";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const PersonalInformation = ({ handleEditProfile, name, setName, loader, seteditName }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    handleEditProfile();
  };

  return (
    <div
      id="edit_profile"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body px-6 py-4">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => seteditName(false)}
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                </svg>
              </span>
            </button>
            <h5 className="header_dark">Personal information</h5>
            <form onSubmit={handleSubmit}>
              <div className="form-group mt-4">
                <label className="form-label custom_label">
                  Name {reuiredField}
                </label>
                <input
                  className="custom_form_control"
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="modal-footer border-0 py-0 mt-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                >
                  {loader ? <ApiLoader /> : "Save Changes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
