import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);

const GaugeChart = ({ filled, allTaskOverview }) => {
  const data = {
    labels: ["Beyond Deadline", "Due Date"],
    datasets: [
      {
        data: [
          allTaskOverview?.beyondDueDate,
          100 - allTaskOverview?.withinDueDate,
        ],
        backgroundColor: ["#FAAB3C", "#23EB31"],
        hoverBackgroundColor: ["#FAAB3C", "#23EB31"],
      },
    ],
  };

  const options = {
    rotation: -90,
    circumference: 180,
    cutout: "60%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label || "";
            const value = tooltipItem.raw;
            return `${label}: ${value}%`;
          },
        },
      },
    },
  };

  return (
    <div
      className="position-relative"
      aria-label={`Gauge chart with ${filled}% filled`}
    >
      <div
        className="text-center position-absolute"
        style={{ top: "0", left: "50%", transform: "translate( -50%, 0 )" }}
      >
        <p className="text-dark fs-4 fw-semibold">
          {allTaskOverview?.statuses?.all}
        </p>
        <p>Total Tasks</p>
      </div>
      <Doughnut data={data} options={options} />
      <div
        className="position-absolute fs-7 text-dark fw-semibold"
        style={{
          bottom: "50px",
          left: "50%",
          transform: "translate( -50%, -50% )",
        }}
      >
        <p className="d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#23EB31"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
          Due date
        </p>
        <p className="d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#FAAB3C"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
          Beyond Deadline
        </p>
      </div>
    </div>
  );
};

export default GaugeChart;
