import React, { useEffect, useState } from "react";
import TablesHeader from "./TablesHeader";
import TableData from "./TableData";
import AddTable from "./AddTable";
import { restaurantTableRelatedEndPoints } from "../../../api/Endpoints";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const AllTables = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [searchKey, setsearchKey] = useState("");
  const [allTables, setallTables] = useState(null);
  const [selectedTable, setselectedTable] = useState(null);
  const [isAddTable, setisAddTable] = useState(false);
  const [isDelete, setisDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForTables =
    allModuleAccess?.[PRIVATEROUTES.TABLES];

  // GET ALL TABLE  API CALL
  const handleGetAllTable = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        searchKey,
        businessId: getCurrentWorkspaceId(),
        isDeleted: false,
      },
    };
    const res = await ApiCall(
      "post",
      restaurantTableRelatedEndPoints.getTable,
      data
    );
    if (res?.success) {
      setallTables(res?.tables?.data);
      setcount(res?.tables?.totalCounts);
      settotalPages(res?.tables?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // GET DELETE TABLE  API CALL
  const handleDeleteTable = async () => {
    startApiCall(null, setloader2);
    const data = {
      tableId: selectedTable?._id,
    };
    const res = await ApiCall(
      "post",
      restaurantTableRelatedEndPoints.deleteTable,
      data
    );
    if (res?.success) {
      toast.success("Table Deleted Successfully!");
      handleGetAllTable();
      setloader2(false);
      handleClose();
    } else {
      setErrorMessage(res.error);
      setloader2(false);
    }
  };

  const handleClose = () => {
    setisDelete(false);
  };

  useEffect(() => {
    handleGetAllTable();
    closeAllModals();
  }, [page, searchKey, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <TablesHeader
            searchKey={searchKey}
            setsearchKey={setsearchKey}
            handleGetAllTable={handleGetAllTable}
            setisAddTable={setisAddTable}
            isModuleReadWriteAccessForTables={isModuleReadWriteAccessForTables}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && allTables?.length > 0 && (
                <div className="contact-list-view">
                  <TableData
                    allTables={allTables}
                    page={page}
                    pageLimit={pageLimit}
                    setselectedTable={setselectedTable}
                    setisAddTable={setisAddTable}
                    setisDelete={setisDelete}
                    isModuleReadWriteAccessForTables={
                      isModuleReadWriteAccessForTables
                    }
                  />
                </div>
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allTables?.length === 0 && (
                <NoData title="No Table found" />
              )}
              {!loader && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allTables?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
        {isAddTable && (
          <AddTable
            handleGetAllTable={handleGetAllTable}
            setselectedTable={setselectedTable}
            selectedTable={selectedTable}
            setisAddTable={setisAddTable}
          />
        )}
        <ConfirmModal
          isOpen={isDelete}
          onClose={handleClose}
          text={`Are you sure? You want to delete : ${selectedTable?.tableName}`}
          loader={loader2}
          errorMessage={errorMessage}
          handleConfirm={handleDeleteTable}
        />
      </div>
    </div>
  );
};

export default AllTables;
