import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { taskEndpoint } from "../../api/Endpoints";
import ErrorMessage from "../../components/Error/ErrorMessage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddTaskModal = ({ editTask, setEdittask, handleGetTask, allList }) => {
  const user = useSelector((state) => state.user);
  var date = reversesDateFormatter(new Date());

  const [taskName, settaskName] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [priority, setPriority] = useState("NO_PRIORITY");
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editTask) {
      settaskName(editTask?.name);
      setdueDate(
        editTask?.dueDate ? reversesDateFormatter(editTask?.dueDate) : ""
      );
      setPriority(editTask?.priority);
    }
  }, [editTask]);

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    settaskName("");
    setdueDate("");
    setPriority("NO_PRIORITY");
    setEdittask("");
    const closeModalDom = document.getElementById("add_todo_task");
    if (closeModalDom) closeModalDom.click();
  };

  const handlePriorityChange = (event) => {
    const selectedPriority = event.target.value;
    setPriority(selectedPriority);
  };

  const handleAssigntask = async (taskId) => {
    startApiCall(null, setloader);
    const sendData = {
      taskId: taskId,
      employees: [{ employeeId: user?._id, isRemoved: false }],
      userId: user?._id,
    };
    const res = await ApiCall("post", taskEndpoint.assignTask, sendData);
    if (res?.success) {
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleAddTodoTask = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      name: taskName,
      taskType: "maintask",
      taskAbilities: "business",
      dueDate: dueDate,
      priority: priority ? priority : "NO_PRIORITY",
      createdBy: user?._id,
      assignedBy: [user?._id],
      businessId: getCurrentWorkspaceId(),
      list: allList?.[0]?._id,
    };
    const res = await ApiCall("post", taskEndpoint.createTask, data);
    if (res?.success) {
      handleAssigntask(res?.task?._id);
      toast.success("Create Task successfully!");
      handleGetTask();
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditTodoTask = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      taskId: editTask?._id,
      name: taskName,
      dueDate: dueDate,
      priority: priority ? priority : "NO_PRIORITY",
      userId: user?._id,
    };
    const res = await ApiCall("post", taskEndpoint.editTask, data);
    if (res?.success) {
      toast.success("Edit task successfully!");
      handleGetTask();
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_todo_task"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body px-4">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-3">
              {editTask === "" ? "Add" : "Edit"} New Task
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editTask === "") {
                  handleAddTodoTask(e);
                } else {
                  handleEditTodoTask(e);
                }
              }}
            >
              <div className="d-flex flex-column gap-3">
                <div className="form-group">
                  <label>Task name</label>
                  <input
                    className="form-control mt-1"
                    type="text"
                    required
                    placeholder="What do you need to do.."
                    onChange={(e) => {
                      settaskName(e.target.value);
                    }}
                    value={taskName}
                  />
                </div>
                <div className="form-group">
                  <label>Due Date</label>
                  <input
                    className="form-control w-150p mt-1"
                    type="date"
                    value={dueDate}
                    onChange={(e) => {
                      setdueDate(e.target.value);
                    }}
                    min={date}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Set priority:</label>
                  <div className="form-check form-check-inline ms-2">
                    <div className="custom-control custom-radio radio-primary">
                      <input
                        type="radio"
                        id="customRadioc2"
                        name="customRadioc2"
                        className="form-check-input pointer"
                        value="HIGH"
                        onChange={handlePriorityChange}
                        checked={priority === "HIGH"}
                      />
                      <label className="form-check-label">High</label>
                    </div>
                  </div>
                  <div className="form-check form-check-inline">
                    <div className="custom-control custom-radio radio-primary">
                      <input
                        type="radio"
                        id="customRadioc3"
                        name="customRadioc2"
                        className="form-check-input pointer"
                        value="MEDIUM"
                        onChange={handlePriorityChange}
                        checked={priority === "MEDIUM"}
                      />
                      <label className="form-check-label">Medium</label>
                    </div>
                  </div>
                  <div className="form-check form-check-inline">
                    <div className="custom-control custom-radio radio-primary">
                      <input
                        type="radio"
                        id="customRadioc4"
                        name="customRadioc2"
                        className="form-check-input pointer"
                        value="LOW"
                        onChange={handlePriorityChange}
                        checked={priority === "LOW"}
                      />
                      <label className="form-check-label">Low</label>
                    </div>
                  </div>
                  <div className="form-check form-check-inline">
                    <div className="custom-control custom-radio radio-primary">
                      <input
                        type="radio"
                        id="customRadioc4"
                        name="customRadioc2"
                        className="form-check-input pointer"
                        value="NO_PRIORITY"
                        onChange={handlePriorityChange}
                        checked={priority === "NO_PRIORITY"}
                      />
                      <label className="form-check-label">No Priority</label>
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : editTask === "" ? "Add" : "Edit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTaskModal;
