import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import JoinWorkspaceFromInvitation from "../../pages/InviteWorkspace/JoinWorkspaceFromInvitation";
import Workspace from "../../pages/Workspace/Workspace";

const WorkspaceRoutes = {
  path: "/workspace",
  element: <Navbar />,
  children: [
    {
      path: '',
      element: <Workspace />,
    },
    {
      path: 'invitations',
      element: <JoinWorkspaceFromInvitation />,
    },
  ],
};

export default WorkspaceRoutes;