import React, { useEffect, useState } from "react";
import ApiLoader from "../../../../../../components/Loaders/ApiLoader/ApiLoader";
import { Avatar } from "../../../../../../components/Avatar/Avatar";
import DataLoader from "../../../../../../components/Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";
import {
  dateAndTimeFormatter,
  dateFormatter,
  getName,
  handleDeleteAwsImage,
  handleUploadInDirectAwsAttachment,
  startApiCall,
} from "../../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../../api/ApiCall";
import { CrmTaskRelatedEndPoints } from "../../../../../../api/Endpoints";
import { toast } from "react-toastify";
import NoDataWithoutImage from "../../../../../../components/NoData/NoDataWithoutImage";
import ManualLocationModal from "../ActivityMandatoryTask/ManualLocationModal";
import DetectLocationModal from "../ActivityMandatoryTask/DetectLocationModal";
import ActivityAssignParticipant from "../ActivityMandatoryTask/ActivityAssignParticipant";
import ErrorMessage from "../../../../../../components/Error/ErrorMessage";
import ActivityOverview from "../ActivityOverview";
import ConfirmModal from "../../../../../../components/ConfirmationModal/ConfirmModal";
import ActivityTimelineMoreInfoModal from "./ActivityTimelineMoreInfoModal";
import DetailsModalAdditional from "./DetailsModalAdditional";
import CustomTooltip from "../../../../../../components/CustomTooltip/CustomTooltip";
import NoData from "../../../../../../components/NoData/NoData";

function CallDetailsModal({
  leadDetails,
  loader,
  activityDetails,
  setactivityDetails,
  seteditTask,
  settaskWarningModal,
  setstatusWarningDetails,
  relatedTo,
  handleAllTask,
  handleGetMandatoryTask,
  setimageData,
  setimageModal,
}) {
  const [selectTab, setSelectTab] = useState("Overview");
  const [errorMessage, setErrorMessage] = useState("");
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [selectedEmpId, setselectedEmpId] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [statusGroup, setstatusGroup] = useState("");
  const [activityGroup, setactivityGroup] = useState("");
  const [attachmentFileData, setattachmentFileData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [timelineMoreInfo, setTimelineMoreInfo] = useState(false);
  const [timeLineDetails, settimeLineDetails] = useState([]);
  const [mandatoryTask, setmandatoryTask] = useState([]);
  const [automaticLocationModal, setautomaticLocationModal] = useState(false);
  const [taskLoader, settaskLoader] = useState(false);
  const [allActivity, setallActivity] = useState([]);
  const [activityData, setactivityData] = useState({
    taskTitle: "",
    activityDate: "",
    activityTime: "",
    priority: "",
    endTime: "",
    recordingLink: "",
    duration: "",
    notes: "",
  });
  const user = useSelector((state) => state.user);
  const [description, setdescription] = useState("");
  const [mapLink, setmapLink] = useState("");
  const [manualLocation, setmanualLocation] = useState(false);
  const [fileData, setfileData] = useState([]);
  const [locationData, setLocationData] = useState({
    areaName: "",
    flatHouseNo: "",
    city: "",
    state: "",
    updatedBy: user?._id,
  });
  const [activityLoader, setactivityLoader] = useState(false);
  const logData = allActivity?.activityLifeCycle;
  const reverseLogData = logData?.slice()?.reverse();
  // ******* DATE *********
  var date = new Date();
  var yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  const copyToClipboard = (data) => {
    navigator.clipboard.writeText(data);
  };
  const handlePreviewFileInLocal = (files) => {
    if (!files?.url) {
      let img = URL.createObjectURL(files);
      return img;
    } else {
      return files?.url;
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader3(false);
  };

  const tabs = ["Overview", "Status Task", "Attachments", "Timeline"];
  const handleTabChange = (mode) => {
    setSelectTab(mode);
  };

  const uploadAttachmentData = async (e) => {
    startApiCall(null, setactivityLoader);
    e.preventDefault();
    try {
      const reqBodies = [];
      if (mapLink) {
        const location = {
          data: {
            url: [{ mapLink, updatedBy: user?._id }],
          },
          attachmentType: "automaticlocation",
        };
        reqBodies.push(location);
      }

      if (locationData?.areaName) {
        const manualLocation = {
          data: {
            url: [{ locationData, updatedBy: user?._id }],
          },
          attachmentType: "manuallocation",
        };
        reqBodies.push(manualLocation);
      }

      if (fileData?.length > 0) {
        let isError = false;
        let uploaded;
        let temp = [];
        try {
          for (let i = 0; i < fileData.length; i++) {
            uploaded = await handleUploadInDirectAwsAttachment(fileData[i]);
            if (uploaded?.Location) {
              temp.push({
                url: uploaded?.Location,
                name: fileData[i]?.name,
                type: fileData[i]?.type,
                updatedBy: user?._id,
              });
            }
          }
        } catch (error) {
          isError = true;
          setactivityLoader(false);
          setErrorMessage("Attachment uploading failed, try again later.");
        }
        if (!isError) {
          const documentAttachment = {
            data: {
              url: temp,
            },
            attachmentType: "file",
          };
          reqBodies.push(documentAttachment);
        }
      }

      if (description) {
        const noteAttachment = {
          data: {
            url: [{ description: description, updatedBy: user?._id }],
          },
          attachmentType: "note",
        };
        reqBodies.push(noteAttachment);
      }

      await Promise.all(
        reqBodies.map(async (reqBody) => {
          const sendData = {
            leadTaskId: allActivity?._id,
            actionBy: user?._id,
            isUploading: true,
            attachment: reqBody,
            activityStatus: allActivity?.activityStatus?._id,
          };

          const res = await ApiCall(
            "post",
            CrmTaskRelatedEndPoints.uploadAttachment,
            sendData
          );

          if (!res?.success) {
            throw new Error(res.error);
          }
        })
      );
      handleClose();
      toast.success("Attachments added successfully!");
      handleGetAllTask();
    } catch (error) {
      console.error("Error handling attachments:", error);
      setErrorMessage(
        error.message || "Attachment uploading failed, try again later."
      );
    } finally {
      setactivityLoader(false);
    }
  };

  const handleGetAllTask = async () => {
    startApiCall(null, settaskLoader);
    const data = {
      page: 1,
      limit: 5000,
      sortBy: "createdAt",
      filters: {
        _id: activityDetails?._id,
        isDeleted: false,
        relatedTo,
      },
    };
    const res = await ApiCall("post", CrmTaskRelatedEndPoints.getTask, data);
    if (res?.success) {
      setallActivity(res?.result?.data?.[0]);
      settaskLoader(false);
    } else {
      settaskLoader(false);
    }
  };

  useEffect(() => {
    if (activityDetails?._id) {
      handleGetAllTask();
    }
  }, [activityDetails]);

  const handleReset = () => {
    setSelectTab("Overview");
    setactivityDetails("");
    setselectedEmpId([]);
    setselectedEmployees([]);
    seteditTask("");
    setErrorMessage("");
    const closeModalDom = document.getElementById("call_details_modal");
    if (closeModalDom) closeModalDom.click();
  };

  const groupedLogs = reverseLogData?.reduce((groups, log) => {
    const createdAtDate = new Date(log?.date).toDateString();
    if (!groups[createdAtDate]) {
      groups[createdAtDate] = [];
    }
    groups[createdAtDate].push(log);
    return groups;
  }, []);

  const handleEditActivity = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setactivityLoader);
    const sendData = {
      leadTaskId: allActivity?._id,
      taskType: allActivity?.taskType,
      title: activityData?.taskTitle,
      activityDate: activityData?.activityDate,
      priority: activityData?.priority,
      relatedTo: allActivity?.relatedTo,
      actionOn: allActivity?.actionOn?.id,
      actionBy: user?._id,
      assignees: selectedEmpId,
      activityStatus: statusId?._id,
      activityGroup: statusGroup?._id,
      activityGroupStatus: activityGroup?._id,
      startTime: activityData?.activityTime,
      endTime: activityData?.endTime,
      recordingLink: activityData?.recordingLink,
      duration: activityData?.duration,
      notes: activityData?.notes,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.editTask,
      sendData
    );
    if (res?.success) {
      setactivityLoader(false);
      handleGetAllTask();
      handleAllTask();
      toast.success("Edited task successfully!");
    } else {
      setactivityLoader(false);
      setErrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (allActivity?.attachments) {
      const noteAttachments = allActivity?.attachments?.filter(
        (attachment) =>
          attachment?.attachmentType === "note" &&
          attachment?.activityStatus === allActivity?.activityStatus?._id
      );
      const fileAttachments = allActivity?.attachments?.filter(
        (attachment) =>
          attachment?.attachmentType === "file" &&
          attachment?.activityStatus === allActivity?.activityStatus?._id
      );

      const automaticLocation = allActivity?.attachments?.filter(
        (attachment) =>
          attachment?.attachmentType === "automaticlocation" &&
          attachment?.activityStatus === allActivity?.activityStatus?._id
      );
      const manualLocation = allActivity?.attachments?.filter(
        (attachment) =>
          attachment?.attachmentType === "manuallocation" &&
          attachment?.activityStatus === allActivity?.activityStatus?._id
      );

      setdescription(noteAttachments?.[0]?.data?.url?.[0]?.description);
      setmapLink(automaticLocation?.[0]?.data?.url?.[0]?.mapLink);
      setLocationData({
        areaName: manualLocation?.[0]?.data?.url?.[0]?.locationData?.areaName,
        flatHouseNo:
          manualLocation?.[0]?.data?.url?.[0]?.locationData?.flatHouseNo,
        city: manualLocation?.[0]?.data?.url?.[0]?.locationData?.city,
        state: manualLocation?.[0]?.data?.url?.[0]?.locationData?.state,
      });
      setfileData(fileAttachments);
    }
  }, [allActivity]);

  const handleDeleteAttachment = async (e) => {
    e.preventDefault();

    startApiCall(setErrorMessage, setloader3);

    let updatedAttachments = [...allActivity.attachments];

    let fileData = updatedAttachments.filter(
      (attachment) => attachment?.attachmentType === "file"
    );

    if (attachmentFileData?.name) {
      const indexToDelete = fileData?.[0]?.data?.url?.findIndex(
        (att) => att.name === attachmentFileData.name
      );

      if (indexToDelete !== -1) {
        fileData[0].data.url.splice(indexToDelete, 1);
        updatedAttachments = fileData[0].data.url;
      }

      handleDeleteAwsImage(attachmentFileData?.name);
    }

    const sendData = {
      leadTaskId: activityDetails?._id,
      attachment: {
        data: {
          url: updatedAttachments,
        },
        attachmentType: "file",
      },
      isUploading: true,
      exists: fileData?.length > 0 ? true : false,
      actionBy: user?._id,
    };

    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.uploadAttachment,
      sendData
    );

    if (res?.success) {
      setloader3(false);
      handleGetAllTask();
      handleClose();
      toast.success("Image deleted successfully!");
    } else {
      setloader3(false);
      setErrorMessage(res.error);
    }
  };

  function renderParticipantInfo(participants) {
    return participants?.map((item) => {
      return (
        <span className="text-primary ms-2">
          <Avatar
            name={item?.name}
            count={1}
            image={item?.image}
            size="xxs"
            color="primary"
          />
          <span className="ms-1">{item?.name}</span>
        </span>
      );
    });
  }

  function findMandatoryItems() {
    let tasks = [];

    allActivity?.mandatoryTasks?.map((item) => {
      const key = Object.keys(item)[0];
      const mandatory = item[key]?.mandatory;

      if (mandatory) {
        tasks.push({
          [key]: item[key]?.isCompleted,
          description: item[key]?.description,
        });
      }
    });

    setmandatoryTask(tasks);
  }

  useEffect(() => {
    if (allActivity) {
      findMandatoryItems();
    }
  }, [allActivity]);

  return (
    <>
      <div
        id="call_details_modal"
        className="modal fade add-new-contact"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content p-2 " style={{ overflow: "visible" }}>
            <div className="modal-body">
              <button
                type="button"
                className="btn-close mt-2 pe-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="ps-2 text-capitalize">
                {activityDetails?.taskType} Details{" "}
                <span className="btn btn-xs btn-soft-primary fw-bold font-2">
                  {activityDetails?.actionFromRef === "Status"
                    ? "Auto"
                    : "Manual"}
                </span>
              </h5>

              <form
                onSubmit={(e) => {
                  if (selectTab === "Status Task") {
                    uploadAttachmentData(e);
                  } else {
                    handleEditActivity(e);
                  }
                }}
              >
                <div className="row gx-3 px-3 w-100 mt-4">
                  <div className="col-sm-12">
                    {" "}
                    <div
                      className="btn-group btn-sm d-sm-flex d-none mt-2 w-100"
                      role="group"
                      aria-label="Basic outlined example"
                    >
                      {tabs?.map((name) => {
                        return (
                          <button
                            onClick={() => {
                              handleTabChange(name);
                            }}
                            type="button"
                            className={`btn btn-outline-light fs-7 ${
                              selectTab === name
                                ? "active bg-primary text-white "
                                : ""
                            }`}
                            key={name}
                          >
                            {name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {selectTab === "Status Task" && (
                  <>
                    {mandatoryTask?.length > 0 && (
                      <>
                        <div className="d-flex justify-content-center mt-2">
                          <div
                            className="alert alert-info text-primary fs-7"
                            role="alert"
                          >
                            <strong>Note:</strong> These tasks are mandatory to
                            complete in order to change the status.
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          {mandatoryTask?.filter(
                            (loc) => Object.keys(loc)[0] === "note"
                          )?.length > 0 && (
                            <div className="form-group">
                              <label className="fs-7 mb-2 d-flex align-items-center gap-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-plus-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                                  />
                                </svg>
                                Add Note
                              </label>
                              <textarea
                                class="form-control"
                                placeholder={
                                  mandatoryTask?.filter(
                                    (loc) => Object.keys(loc)[0] === "note"
                                  )?.[0]?.description
                                }
                                disabled
                                required
                                rows="2"
                                value={description}
                                onChange={(e) => {
                                  setdescription(e.target.value);
                                }}
                              ></textarea>
                            </div>
                          )}
                          {mandatoryTask?.filter(
                            (loc) => Object.keys(loc)[0] === "location"
                          )?.length > 0 && (
                            <div className="form-group">
                              <label className="fs-7 d-flex align-items-center gap-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-geo-alt-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                </svg>
                                Location Details
                              </label>
                              <p className="text-secondary fs-8 mb-2">
                                {
                                  mandatoryTask?.filter(
                                    (loc) => Object.keys(loc)[0] === "location"
                                  )?.[0]?.description
                                }
                              </p>
                              <div className="row">
                                <div className="col-md-6">
                                  {/* {!mapLink && (
                                    <div class="card py-2 px-3 mb-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center gap-2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="#3862DD"
                                            class="bi bi-crosshair"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7 7 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7 7 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7 7 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7 7 0 0 0 8.5 1.018zm-6.48 7A6 6 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6 6 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6 6 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6 6 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1zM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                                          </svg>
                                          <div className="d-flex flex-column">
                                            <p className="mb-0 fs-7">
                                              Your Location
                                            </p>
                                            <span className="fw-400 text-center">
                                              Using GPS
                                            </span>
                                          </div>
                                        </div>
                                        <span
                                          className="btn btn-sm btn-outline-primary"
                                          onClick={() => {
                                            setautomaticLocationModal(true);
                                          }}
                                        >
                                          GRANT
                                        </span>
                                      </div>
                                    </div>
                                  )} */}
                                  {mapLink && (
                                    <div class="card py-2 px-3 mb-0">
                                      <div className="d-flex align-items-center justify-content-between h-45p">
                                        <div className="d-flex align-items-center gap-2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="currentColor"
                                            class="bi bi-check-circle-fill text-success"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                          </svg>
                                          <div className="d-flex flex-column">
                                            <p className="mb-0 fs-7">
                                              Your Location
                                            </p>
                                            <span className="fs-7 text-success">
                                              Location Fetched
                                            </span>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center gap-1">
                                          <CustomTooltip
                                            text="View Address"
                                            placement="left"
                                          >
                                            <a
                                              className="fw-bold mb-2"
                                              target="_blank"
                                              href={mapLink}
                                            >
                                              <span className="btn btn-icon btn-sm rounded-circle btn-light">
                                                <span className="feather-icon">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    class="bi bi-eye"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                  </svg>
                                                </span>
                                              </span>
                                            </a>
                                          </CustomTooltip>

                                          <CustomTooltip
                                            text="Copy Address"
                                            placement="left"
                                          >
                                            <span
                                              className="btn btn-icon btn-sm rounded-circle btn-light"
                                              onClick={() => {
                                                copyToClipboard(mapLink);
                                              }}
                                            >
                                              <span className="feather-icon">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="16"
                                                  height="16"
                                                  fill="currentColor"
                                                  class="bi bi-copy"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                                  />
                                                </svg>
                                              </span>
                                            </span>
                                          </CustomTooltip>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-6">
                                  {/* {!locationData?.areaName && (
                                    <div class="card py-2 px-3 mb-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center gap-2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="30"
                                            height="30"
                                            fill="#3862DD"
                                            class="bi bi-plus-lg"
                                            viewBox="0 0 16 16"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                                            />
                                          </svg>
                                          <div className="d-flex flex-column">
                                            <p className="mb-0 fs-7">
                                              Add Address
                                            </p>
                                            <span className="fw-400 text-center">
                                              Manually
                                            </span>
                                          </div>
                                        </div>
                                        <span
                                          className="btn btn-sm btn-outline-primary"
                                          onClick={() => {
                                            setmanualLocation(true);
                                          }}
                                        >
                                          GRANT
                                        </span>
                                      </div>
                                    </div>
                                  )} */}

                                  {locationData?.areaName && (
                                    <div class="card py-2 px-3 mb-0">
                                      <div className="d-flex gap-2">
                                        <span className="h-45p overflow-y-scroll simple-scrollbar text-dark w-80">
                                          {locationData?.areaName},{" "}
                                          {locationData?.flatHouseNo},{" "}
                                          {locationData?.city},{" "}
                                          {locationData?.state}
                                        </span>
                                        <CustomTooltip
                                          text="Copy Address"
                                          placement="left"
                                        >
                                          <span
                                            className="btn btn-icon btn-sm rounded-circle btn-light"
                                            onClick={() => {
                                              copyToClipboard(`${locationData?.areaName},
                                              ${locationData?.flatHouseNo},
                                              ${locationData?.city},
                                              ${locationData?.state}`);
                                            }}
                                          >
                                            <span className="feather-icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-copy"
                                                viewBox="0 0 16 16"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                                />
                                              </svg>
                                            </span>
                                          </span>
                                        </CustomTooltip>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {mandatoryTask?.filter(
                            (loc) => Object.keys(loc)[0] === "file"
                          )?.length > 0 && (
                            <div className="form-group">
                              <label className="fs-7 d-flex align-items-center gap-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-folder2-open"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7z" />
                                </svg>
                                Files Upload
                              </label>
                              <p className="text-secondary fs-8 mb-2">
                                {
                                  mandatoryTask?.filter(
                                    (loc) => Object.keys(loc)[0] === "file"
                                  )?.[0]?.description
                                }
                              </p>
                              <div className="d-flex">
                                {fileData?.[0]?.data?.url?.map((att) => {
                                  return (
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column gap-2 mh-125p overflow-y-scroll simple-scrollbar mt-2">
                                        <div className="border rounded px-3 py-2 d-flex align-items-center justify-content-between gap-3">
                                          <div className="d-flex align-items-center gap-2">
                                            {att?.type?.includes("image") ? (
                                              <img
                                                className="border-dashed rounded h-40p w-40p"
                                                src={att?.url}
                                                alt="image"
                                              />
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="40"
                                                fill="currentColor"
                                                class="bi bi-file-earmark-pdf"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                              </svg>
                                            )}

                                            <span className="w-100p text-truncate">
                                              {att?.name}
                                            </span>
                                          </div>
                                          <div className="d-flex align-items-center gap-1">
                                            {att?.type?.includes("image") && (
                                              <CustomTooltip
                                                text="View Image"
                                                placement="left"
                                              >
                                                <span
                                                  className="btn btn-icon btn-sm rounded-circle btn-light"
                                                  onClick={() => {
                                                    setimageData(att?.url);
                                                    setimageModal(true);
                                                  }}
                                                >
                                                  <span className="feather-icon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="currentColor"
                                                      class="bi bi-eye"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                    </svg>
                                                  </span>
                                                </span>
                                              </CustomTooltip>
                                            )}

                                            <CustomTooltip
                                              text="Download Image"
                                              placement="left"
                                            >
                                              <a
                                                href={att?.url}
                                                download={att?.name}
                                              >
                                                <span className="btn btn-icon btn-sm rounded-circle btn-soft-primary">
                                                  <span className="feather-icon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="16"
                                                      height="16"
                                                      fill="currentColor"
                                                      class="bi bi-download"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                                    </svg>
                                                  </span>
                                                </span>
                                              </a>
                                            </CustomTooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {mandatoryTask?.length === 0 && (
                      <NoData
                        title="No Mandatory task found"
                        description="This status has no mandatory task to complete."
                      />
                    )}
                  </>
                )}

                {selectTab === "Attachments" && !taskLoader && (
                  <DetailsModalAdditional
                    allActivity={allActivity?.attachments}
                    selectTab={selectTab}
                    setimageData={setimageData}
                    setimageModal={setimageModal}
                  />
                )}
                {selectTab === "Overview" && !taskLoader && (
                  <ActivityOverview
                    selectedEmployees={selectedEmployees}
                    setassigneeModal={setassigneeModal}
                    setactivityData={setactivityData}
                    activityData={activityData}
                    setStatusId={setStatusId}
                    statusGroup={statusGroup}
                    statusId={statusId}
                    allActivity={allActivity}
                    setactivityGroup={setactivityGroup}
                    setstatusGroup={setstatusGroup}
                    activityDetails={activityDetails}
                    setselectedEmployees={setselectedEmployees}
                    setselectedEmpId={setselectedEmpId}
                    handleGetMandatoryTask={handleGetMandatoryTask}
                  />
                )}
                {(selectTab === "Overview" ||
                  selectTab === "Status Timeline") &&
                  taskLoader && <DataLoader />}

                {selectTab === "Timeline" && (
                  <div className="col-sm-12 ">
                    <div className="card card-border px-3 py-3">
                      <div className="overflow-y-scroll h-505p">
                        <div className="d-flex flex-column gap-3 mb-10">
                          {taskLoader && (
                            <DataLoader title="Timeline is loading..." />
                          )}
                          {!taskLoader &&
                            allActivity?.activityLifeCycle.length > 0 &&
                            Object?.entries(groupedLogs)?.map(
                              ([createdAtDate, logsForDate]) => (
                                <>
                                  <button className="btn btn-primary btn-md text-white w-120p mt-3 mb-3">
                                    {dateFormatter(createdAtDate) ===
                                    dateFormatter(date)
                                      ? "Today"
                                      : dateFormatter(createdAtDate) ===
                                        dateFormatter(yesterday)
                                      ? "Yesterday"
                                      : dateFormatter(createdAtDate)}
                                  </button>
                                  <div className="timeline">
                                    {logsForDate?.map((data) => (
                                      <div className="px-3 py-2">
                                        <div className="d-flex align-items-center">
                                          <span className="timeline-item-icon | faded-icon">
                                            {data?.moreInfo?.[0]?.taskType ===
                                            "call" ? (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                height="10"
                                                fill="currentColor"
                                                className="bi bi-telephone text-primary"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                              </svg>
                                            ) : data?.moreInfo?.[0]
                                                ?.taskType === "meeting" ? (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                height="10"
                                                fill="currentColor"
                                                class="bi bi-people-fill text-primary"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                              </svg>
                                            ) : data?.moreInfo?.[0]
                                                ?.taskType === "task" ? (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                height="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="text-primary"
                                              >
                                                <path d="M19 4H5V20H19V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H19.9997C20.5519 2 20.9996 2.44772 20.9997 3L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM11.2929 13.1213L15.5355 8.87868L16.9497 10.2929L11.2929 15.9497L7.40381 12.0607L8.81802 10.6464L11.2929 13.1213Z"></path>
                                              </svg>
                                            ) : (
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="10"
                                                height="10"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="text-primary"
                                              >
                                                <path d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H3ZM4 19V5H20V19H4ZM14 7H6V9H14V7ZM18 15V17H10V15H18ZM16 11H8V13H16V11Z"></path>
                                              </svg>
                                            )}
                                          </span>
                                          <p className="mb-0 ms-3">
                                            {dateAndTimeFormatter(data?.date)}
                                          </p>
                                        </div>
                                        <div className="ms-1 text-capitalize text-dark">
                                          {data?.action === "attachment" &&
                                            data?.moreInfo?.[0]
                                              ?.attachmentType !== "location" &&
                                            data?.moreInfo?.[0]
                                              ?.attachmentType !== "file" && (
                                              <>
                                                {
                                                  data?.moreInfo?.[0]
                                                    ?.attachmentType
                                                }{" "}
                                                {data?.description}{" "}
                                                {
                                                  <svg
                                                    onClick={() => {
                                                      settimeLineDetails(data);
                                                      setTimelineMoreInfo(true);
                                                    }}
                                                    width="22"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="pointer"
                                                  >
                                                    <circle
                                                      cx="11"
                                                      cy="11"
                                                      r="10.5"
                                                      stroke="#3862DD"
                                                    />
                                                    <path
                                                      d="M11.9224 8.0752V16H9.98145V8.0752H11.9224ZM9.84961 6.00244C9.84961 5.71436 9.94727 5.4751 10.1426 5.28467C10.3428 5.09424 10.6113 4.99902 10.9482 4.99902C11.2852 4.99902 11.5513 5.09424 11.7466 5.28467C11.9468 5.4751 12.0469 5.71436 12.0469 6.00244C12.0469 6.28564 11.9468 6.52246 11.7466 6.71289C11.5513 6.90332 11.2852 6.99854 10.9482 6.99854C10.6113 6.99854 10.3428 6.90332 10.1426 6.71289C9.94727 6.52246 9.84961 6.28564 9.84961 6.00244Z"
                                                      fill="#3862DD"
                                                    />
                                                  </svg>
                                                }
                                              </>
                                            )}
                                          {data?.action === "attachment" &&
                                            data?.moreInfo?.[0]
                                              ?.attachmentType === "file" && (
                                              <>
                                                {
                                                  data?.moreInfo?.[0]
                                                    ?.attachmentType
                                                }{" "}
                                                {data?.description}{" "}
                                              </>
                                            )}
                                          {data?.action === "attachment" &&
                                            data?.moreInfo?.[0]
                                              ?.attachmentType ===
                                              "location" && (
                                              <>
                                                {
                                                  data?.moreInfo?.[0]
                                                    ?.locationType
                                                }{" "}
                                                {
                                                  data?.moreInfo?.[0]
                                                    ?.attachmentType
                                                }{" "}
                                                {data?.description}&nbsp;
                                                {
                                                  <svg
                                                    onClick={() => {
                                                      settimeLineDetails(data);
                                                      setTimelineMoreInfo(true);
                                                    }}
                                                    width="22"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="pointer"
                                                  >
                                                    <circle
                                                      cx="11"
                                                      cy="11"
                                                      r="10.5"
                                                      stroke="#3862DD"
                                                    />
                                                    <path
                                                      d="M11.9224 8.0752V16H9.98145V8.0752H11.9224ZM9.84961 6.00244C9.84961 5.71436 9.94727 5.4751 10.1426 5.28467C10.3428 5.09424 10.6113 4.99902 10.9482 4.99902C11.2852 4.99902 11.5513 5.09424 11.7466 5.28467C11.9468 5.4751 12.0469 5.71436 12.0469 6.00244C12.0469 6.28564 11.9468 6.52246 11.7466 6.71289C11.5513 6.90332 11.2852 6.99854 10.9482 6.99854C10.6113 6.99854 10.3428 6.90332 10.1426 6.71289C9.94727 6.52246 9.84961 6.28564 9.84961 6.00244Z"
                                                      fill="#3862DD"
                                                    />
                                                  </svg>
                                                }
                                              </>
                                            )}

                                          {data?.action === "create" &&
                                            `${data?.description} ${data?.moreInfo?.[0]?.title}`}

                                          {data?.action === "update" &&
                                            data?.moreInfo?.[0]?.participant
                                              ?.length > 0 && (
                                              <>
                                                {data?.moreInfo?.[0]
                                                  ?.participant &&
                                                  renderParticipantInfo(
                                                    data.moreInfo?.[0]
                                                      ?.participant
                                                  )}
                                                &nbsp;
                                                {data?.description} to{" "}
                                                {data?.moreInfo?.[0]?.title}{" "}
                                              </>
                                            )}
                                          {data?.action === "update" &&
                                            data?.moreInfo?.[0]?.newStatus !==
                                              data?.moreInfo?.[0]?.prevStatus &&
                                            data?.moreInfo?.[0]?.participant
                                              ?.length === 0 && (
                                              <>
                                                {data?.description} from{" "}
                                                {
                                                  data?.moreInfo?.[0]
                                                    ?.prevStatus
                                                }{" "}
                                                to{" "}
                                                {data?.moreInfo?.[0]?.newStatus}{" "}
                                              </>
                                            )}

                                          {data?.action !== "attachment" &&
                                            data?.action !== "create" &&
                                            data?.moreInfo?.[0]?.participant
                                              ?.length === 0 &&
                                            data?.moreInfo?.[0]?.newStatus ===
                                              data?.moreInfo?.[0]?.prevStatus &&
                                            `${data?.description}`}
                                        </div>
                                        <p className="ms-1 text-primary font-3 gap-2">
                                          Action by :{" "}
                                          <Avatar
                                            name={data?.updatedBy?.name}
                                            count={1}
                                            image={data?.updatedBy?.image}
                                            size="xxs"
                                            color="primary"
                                          />
                                          {getName(data?.updatedBy, user?._id)}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              )
                            )}
                          {!taskLoader &&
                            allActivity?.activityLifeCycle?.length === 0 && (
                              <NoDataWithoutImage title="No Timeline Found" />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectTab === "Overview" && (
                  <>
                    {errorMessage && (
                      <ErrorMessage errorMessage={errorMessage} />
                    )}
                    <div className="d-flex justify-content-end mt-2">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loader}
                      >
                        {activityLoader ? <ApiLoader /> : "Save Changes"}
                      </button>
                    </div>
                  </>
                )}
                {selectTab === "Status Task" && mandatoryTask?.length > 0 && (
                  <>
                    {errorMessage && (
                      <ErrorMessage errorMessage={errorMessage} />
                    )}
                    <div className="d-flex justify-content-end mt-2 gap-2">
                      <button
                        onClick={handleReset}
                        className="btn btn-primary"
                        disabled={loader}
                      >
                        Close
                      </button>
                      {/* <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loader}
                      >
                        {activityLoader ? <ApiLoader /> : "Save"}
                      </button> */}
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
        {assigneeModal && (
          <ActivityAssignParticipant
            setassigneeModal={setassigneeModal}
            addParticipant={assigneeModal}
            selectedAssigneeId={selectedEmpId}
            setselectedAssigneeId={setselectedEmpId}
            loader2={activityLoader}
            errorMessage={errorMessage}
            allGroupMembers={allActivity?.assignees}
            isSingle={true}
            leadDetails={leadDetails}
            setselectedEmployees={setselectedEmployees}
            selectedEmployees={selectedEmployees}
          />
        )}
        {manualLocation && (
          <ManualLocationModal
            setmanualLocation={setmanualLocation}
            allActivity={allActivity}
            description={
              mandatoryTask?.filter(
                (loc) => Object.keys(loc)[0] === "location"
              )?.[0]?.description
            }
            setLocationData={setLocationData}
            locationData={locationData}
          />
        )}
        {automaticLocationModal && (
          <DetectLocationModal
            setautomaticLocationModal={setautomaticLocationModal}
            handleGetAllTask={handleGetAllTask}
            allActivity={allActivity}
            description={
              mandatoryTask?.filter(
                (loc) => Object.keys(loc)[0] === "location"
              )?.[0]?.description
            }
            setmapLink={setmapLink}
            mapLink={mapLink}
          />
        )}
      </div>

      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text="Are You Sure You Want To Delete This Attachment"
        loader={loader3}
        errorMessage={errorMessage}
        handleConfirm={handleDeleteAttachment}
      />
      {timelineMoreInfo && (
        <ActivityTimelineMoreInfoModal
          setTimelineMoreInfo={setTimelineMoreInfo}
          timeLineDetails={timeLineDetails}
        />
      )}
    </>
  );
}

export default CallDetailsModal;
