import React, { useEffect, useRef, useState } from "react";
import ListHeader from "./ListHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  closeAllModals,
  getAllWorkspaceStatusBySpace,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { spaceEndpoint, taskEndpoint } from "../../api/Endpoints";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../components/NoData/NoData";
import AddList from "./AddList";
import AddTask from "../Task/AddTask";
import { toast } from "react-toastify";
import ListMoreInfo from "./ListMoreInfo";
import PermisionModal from "../../components/PermisionModal/PermisionModal";
import UserInfoModal from "../../components/UserInfoModal/UserInfoModal";
import FooterPagination from "../../components/Pagination/FooterPagination";
import PRIVATEROUTES from "../../components/Constant/Route-Constants";
import ListGridView from "./ListGridView";

const List = () => {
  const { spaceId, id, spacename, foldername } = useParams();
  const navigate = useNavigate();
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [search, setSearch] = useState("");
  const [listData, setListData] = useState([]);
  const [isDetailsModal, setisDetailsModal] = useState(false);
  const [listDetails, setListDetails] = useState([]);
  const [listId, setListId] = useState(false);
  const [allStatus, setAllStatus] = useState([]);
  const [loaderForEdit, setloaderForEdit] = useState(false);
  const [editableTaskId, seteditableTaskId] = useState({
    taskId: "",
    type: "",
  });
  const [userInfoData, setuserInfoData] = useState([]);
  const isModuleReadWriteAccessForSpace =
    allModuleAccess?.[PRIVATEROUTES.SPACE];
  const [permissionModalType, setpermissionModalType] = useState("");
  const [permissionData, setpermissionData] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleDrawerOpen = () => {
    setisDetailsModal(true);
  };
  const handleDrawerClose = () => {
    setisDetailsModal(false);
  };

  const handlTaskStatus = async () => {
    startApiCall(null, setLoader2);
    const res = await getAllWorkspaceStatusBySpace(spaceId);
    setAllStatus(res);
    setLoader2(false);
  };

  const handleGetAllLists = async () => {
    !loaderForEdit && startApiCall(null, setLoader);
    const sendData = {
      spaceId: spaceId,
      pageLimitFolder: 5000,
      pageNumberFolder: 1,
      pageLimitList: pageLimit,
      pageNumberList: page,
      employeeId: user?._id,
      searchKey: search,
      folderId: id,
    };
    const res = await ApiCall("post", spaceEndpoint.getFolderAndList, sendData);
    if (res?.success) {
      setisDetailsModal(false);
      setListData(res?.space);
      setcount(res?.space?.lists?.totalCounts);
      settotalPages(res?.space?.lists?.totalPages);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const handleEditTask = async (taskId, statusId, priority, type) => {
    startApiCall(null, setloaderForEdit);
    seteditableTaskId({ taskId, type });
    const sendData = {
      taskId: taskId,
      status: statusId ? statusId : "",
      userId: user?._id,
      priority: priority ? priority : "",
    };
    const res = await ApiCall("post", taskEndpoint.editTask, sendData);
    if (res?.success) {
      toast.success(`Change ${priority ? "Priority" : "Status"} successfully!`);
      seteditableTaskId({ taskId: "", type: "" });
      setloaderForEdit(false);
      handleGetAllLists();
    } else {
      seteditableTaskId({ taskId: "", type: "" });
      setloaderForEdit(false);
    }
  };

  useEffect(() => {
    handleGetAllLists();
    closeAllModals();
  }, [search, page, pageLimit]);

  useEffect(() => {
    handlTaskStatus();
  }, []);

  return (
    <div className="todoapp-wrap" style={{ overflow: "scroll" }}>
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ListHeader
            search={search}
            setSearch={setSearch}
            spacename={spacename}
            foldername={foldername}
            spaceId={spaceId}
            navigate={navigate}
            handleGetAllLists={handleGetAllLists}
            setPage={setPage}
            isModuleReadWriteAccessForSpace={isModuleReadWriteAccessForSpace}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="d-flex flex-column gap-5 w-100 ">
                {!loader && listData?.lists?.data?.length > 0 && (
                  <>
                    <h1 className="fs-6 ms-2 mb-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="17"
                        height="17"
                      >
                        <path d="M12.6727 1.61162 20.7999 9H17.8267L12 3.70302 6 9.15757V19.0001H11V21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001 11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162ZM14 11H23V18H14V11ZM16 13V16H21V13H16ZM24 21H13V19H24V21Z"></path>
                      </svg>
                      &nbsp;
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "10vw",
                        }}
                      >
                        {spacename}
                      </span>
                      &nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chevron-compact-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
                        />
                      </svg>
                      &nbsp;
                      <div className="avatar avatar-icon avatar-xxs avatar-soft-warning">
                        <span className="initial-wrap">
                          <i className="ri-folder-2-fill"></i>
                        </span>
                      </div>
                      &nbsp; &nbsp;
                      <span
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "10vw",
                        }}
                      >
                        {foldername}
                      </span>
                    </h1>
                    <div className="row gx-3 row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-2 w-90-sm mb-6">
                      <ListGridView
                        spaceData={listData}
                        spacename={spacename}
                        handleOpenListInfo={handleDrawerOpen}
                        isModuleReadWriteAccessForSpace={
                          isModuleReadWriteAccessForSpace
                        }
                        setpermissionModalType={setpermissionModalType}
                        setpermissionData={setpermissionData}
                        handleCloseAllInfoModal={handleDrawerClose}
                        id={id}
                        spaceId={spaceId}
                        foldername={foldername}
                      />
                    </div>
                    {/* <ListTableData
                      navigate={navigate}
                      listData={listData}
                      setListDetails={setListDetails}
                      handleEditTask={handleEditTask}
                      setListId={setListId}
                      handleDrawerOpen={handleDrawerOpen}
                      allStatus={allStatus}
                      loader={loader}
                      spacename={spacename}
                      id={id}
                      foldername={foldername}
                      spaceId={spaceId}
                      editableTaskId={editableTaskId}
                      loaderForEdit={loaderForEdit}
                      setuserInfoData={setuserInfoData}
                    /> */}

                    <FooterPagination
                      setpageLimit={setpageLimit}
                      pageLimit={pageLimit}
                      setPage={setPage}
                      page={page}
                      totalPages={totalPages}
                      count={count}
                      dataLength={listData?.lists?.data?.length}
                      align={"end"}
                    />
                  </>
                )}
                {loader && <DataLoader title="data is loading..." />}
                {!loader &&
                  !loaderForEdit &&
                  !listData?.lists?.data?.length && (
                    <NoData title={`No List Found`} />
                  )}
              </div>
              {isDetailsModal && (
                <ListMoreInfo
                  setlistMoreInfoModal={setisDetailsModal}
                  moreInfoDetails={permissionData}
                  listMoreInfoModal={isDetailsModal}
                  user={user}
                  setuserInfoData={setuserInfoData}
                  isModuleReadWriteAccessForSpace={
                    isModuleReadWriteAccessForSpace
                  }
                />
              )}
            </div>
          </div>
        </div>
        <PermisionModal
          infoData={permissionData}
          handleGetFolderList={handleGetAllLists}
          type="list"
          user={user}
        />
        <AddList
          spaceId={spaceId}
          id={id}
          user={user}
          handleGetAllLists={handleGetAllLists}
          modalId={"add_folder_list"}
        />
        <UserInfoModal user={userInfoData} />
        <AddTask
          modalId={"add_new_task_folderlist"}
          listId={permissionData?._id}
          handleGetAllLists={handleGetAllLists}
        />
      </div>
    </div>
  );
};

export default List;
