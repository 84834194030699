import SkeletonConstant from "./SkeletonConstant";

export const skeletonsCountCard = (number) => {
  return Array.from({ length: number }, (_, index) => (
    <div
      key={index}
      className="card p-3 w-100 loading-skeleton"
      //   style={{ minWidth: "190px" }}
    >
      <SkeletonConstant width="w-100" height="h-100p" />
    </div>
  ));
};

export const skeletonsTable = (NumbersofCol, NumbersofRow) => {
  return (
    <table className="table nowrap w-100 mb-6 loading-skeleton">
      <thead>
        <tr>
          {Array.from({ length: NumbersofCol }, (_, index) => (
            <th key={index}>
              <SkeletonConstant
                width="w-110p"
                height="h-25p"
                additional="mx-auto"
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 8 }, (_, index) => (
          <tr key={index}>
            {Array.from({ length: NumbersofRow }, (_, index) => (
              <td>
                <SkeletonConstant
                  width="w-110p"
                  height="h-35p"
                  additional="mx-auto"
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
