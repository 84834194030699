import React from "react";

const AddProductModal = ({ setProductModal }) => {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog"
        role="document"
        style={{ maxWidth: "80%" }}
      >
        <div className="modal-content">
          <div className="modal-body p-4">
            <button
              className="btn-close mt-2 pe-3"
              type="button"
              onClick={() => setProductModal(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-3">Add Products</h5>
              <form className="d-sm-block d-none me-10" role="search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  // value={search}
                />
              </form>
            </div>
            <form>
              <div className="overflow-auto mh-400p simple-scrollbar">
                <table
                  id="datable_1"
                  className="table text-center nowrap w-100 my-3"
                >
                  <thead
                    style={{
                      backgroundColor: "#EDF2FF",
                      border: "#fff",
                      borderRadius: "16px",
                    }}
                  >
                    <tr>
                      <th style={{ borderBottom: "none" }}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="select_all"
                          />
                          <label class="form-check-label" htmlFor="select_all">
                            Product Name
                          </label>
                        </div>
                      </th>
                      <th style={{ borderBottom: "none" }}>Product Code</th>
                      <th style={{ borderBottom: "none" }}>Vendor Name</th>
                      <th style={{ borderBottom: "none" }}>Unit Price</th>
                      <th style={{ borderBottom: "none" }}>Tax</th>
                      <th style={{ borderBottom: "none" }}>Taxable</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="select_product"
                          />
                          <label
                            class="form-check-label"
                            htmlFor="select_product"
                          >
                            xyz
                          </label>
                        </div>
                      </td>
                      <td>4444</td>
                      <td>JJK</td>
                      <td>$ 40.00</td>
                      <td>8%</td>
                      <td>Yes</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductModal;
