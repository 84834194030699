import React, { useState } from "react";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import {
  getCurrentWorkspaceId,
  handlePreviewFileInLocal,
  handleUploadInDirectAws,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../../api/ApiCall";
import { noticeRelatedEndPoints } from "../../../../api/Endpoints";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import { toast } from "react-toastify";

const AddNotice = ({ locationData, handleGetAllNotices }) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, settype] = useState("published");
  const [image, setimage] = useState("");

  const setFileForUpload = (event) => {
    setimage(event);
  };

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    settype("published");
    setTitle("");
    setDescription("");
    setimage("");
    const closeModalDom = document.getElementById("add_new_notice");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddNotice = async (e) => {
    e.preventDefault();
    let isError = false;
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    if (image) {
      try {
        const uploaded = await handleUploadInDirectAws(image);
        if (uploaded.Location) {
          temp.push(uploaded.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    }
    if (!isError) {
      const sendData = {
        title: title,
        description: description,
        publishStatus: type,
        noticeType: locationData ? locationData?.moduleType : "Business",
        moduleId: locationData ? locationData?.moduleId : "",
        businessId: getCurrentWorkspaceId(),
        raisedBy: user?._id,
        actionBy: type === "published" ? user?._id : "",
        images: temp,
      };
      const res = await ApiCall(
        "post",
        noticeRelatedEndPoints.addNotice,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Add notice successfully!");
        handleGetAllNotices();
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    }
  };

  return (
    <>
      <div
        id="add_new_notice"
        className="modal fade add-new-contact"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered my-4"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5>Add Notice</h5>
              <div className="border-bottom mb-2"></div>
              <form onSubmit={handleAddNotice}>
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <div class="d-flex align-items-center gap-1 fs-7">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="published"
                      checked={type === "published"}
                      onClick={(e) => settype("published")}
                    />
                    <label
                      className="form-check-label mt-1"
                      htmlFor="inlineRadio1"
                    >
                      Publish now
                    </label>
                  </div>
                  <div class="d-flex align-items-center gap-1 fs-7">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="unpublished"
                      checked={type === "unpublished"}
                      onClick={(e) => settype("unpublished")}
                    />
                    <label
                      className="form-check-label mt-1"
                      htmlFor="inlineRadio2"
                    >
                      Save as draft
                    </label>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center dropify-square mt-4">
                  {image ? (
                    <>
                      <input
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        className="input-file"
                        onChange={setFileForUpload}
                      />
                      <label
                        htmlFor="file"
                        className="position-relative pointer"
                      >
                        <img
                          htmlFor="file"
                          id="local_show_img"
                          src={handlePreviewFileInLocal(image)}
                          style={{ padding: 5 }}
                          className="dropify-wrapper text-center"
                          alt="img"
                        />
                        <span
                          className="position-top-end-overflow"
                          onClick={() => setimage("")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      </label>
                    </>
                  ) : (
                    <span className="dropify-wrapper text-center">
                      <input
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        className="input-file"
                        onChange={setFileForUpload}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-upload"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                      </svg>
                      <label htmlFor="file" className="pointer text-center">
                        Upload Image
                      </label>
                    </span>
                  )}
                </div>
                {locationData && (
                  <div className="row gx-3 mt-1">
                    <label className="form-label">
                      {locationData?.moduleType} :{" "}
                      <span className="text-capitalize">
                        {locationData?.moduleName}
                      </span>
                    </label>
                  </div>
                )}
                <div className="row gx-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Title</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        value={title}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gx-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loader ? <ApiLoader /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNotice;
