import React, { useEffect, useState } from "react";
import { startApiCall } from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { businessEndpoints } from "../../../api/Endpoints";
import AllBusinessTable from "./AllBusinessTable";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";

const AllBusiness = () => {
  const user = useSelector((state) => state.user);
  const [status, setStatus] = useState("approved");
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [allBusiness, setAllBusiness] = useState([]);
  const [count, setCount] = useState({});
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);

  const handleTabChange = (status) => {
    setStatus(status);
  };

  const allTabs = [
    {
      name: "Approved",
      value: "approved",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-success">
          {count?.approved}
        </span>
      ),
    },
    {
      name: "Pending",
      value: "pending",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-warning">
          {count?.pending}
        </span>
      ),
    },
    {
      name: "Rejected",
      value: "rejected",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-danger">
          {" "}
          {count?.rejected}
        </span>
      ),
    },
  ];

  const handleGetAllBusiness = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      pageLimit: pageLimit,
      pageNumber: page,
      searchKey: search,
      status: status,
      sortType: "createdAt",
      adminId: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.getBusiness, data);
    if (res?.success) {
      setAllBusiness(res?.business?.business);
      setCount(res?.business?.counts);
      settotalPages(res?.business?.totalPages);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllBusiness();
  }, [search, status, page, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <a className="todoapp-title  link-dark">
                <h1>All Workspaces</h1>
              </a>
            </div>

            <div className="d-flex align-items-center">
              <span className="d-md-inline">Total Workspaces :</span>
              <span className="text-dark fs-5 fw-medium ps-2">
                {count?.totalCount}
              </span>
            </div>
            <div className="todo-options-wrap">
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                data-bs-original-title="Refresh"
              >
                <span className="btn-icon-wrap" onClick={handleGetAllBusiness}>
                  <span className="feather-icon">
                    <i className="ri-refresh-line"></i>
                  </span>
                </span>
              </span>
              <div className="v-separator d-lg-inline-block d-none"></div>
              <form className="d-sm-block d-none" role="search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                />
              </form>
            </div>
          </header>

          {/* *******CUSTOM TAB******** */}
          <CustomTab
            allTabs={allTabs}
            currentTab={status}
            handleTabChange={handleTabChange}
            isShowCount={true}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="todo-list-view">
                {allBusiness?.length > 0 && !loader && (
                  <AllBusinessTable
                    status={status}
                    allBusiness={allBusiness}
                    handleGetAllBusiness={handleGetAllBusiness}
                    user={user}
                    page={page}
                    pageLimit={pageLimit}
                  />
                )}
                {!loader && allBusiness?.length > 0 && (
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count?.totalCount}
                    dataLength={allBusiness?.length}
                    align={"end"}
                  />
                )}
                {allBusiness?.length === 0 && !loader && (
                  <NoData title={`No ${status} Workspaces`} />
                )}

                {loader && <DataLoader title="data is loading..." />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBusiness;
