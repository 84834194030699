import React, { useEffect, useState } from "react";
import QuotesHeader from "./QuotesHeader";
import QuotesTable from "./QuotesTable";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { QuotesRelatedEndPoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { toast } from "react-toastify";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

function Quotes() {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [search, setsearch] = useState("");
  const [allQuotesData, setallQuotesData] = useState([]);

  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessQuotes = allModuleAccess?.[PRIVATEROUTES.QUOTES]

  // GET ALL QUOTES FUNCTION API CALL
  const handleGetAllQuotes = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        isDeleted: false,
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall("post", QuotesRelatedEndPoints.getQuote, data);
    if (res?.success) {
      setallQuotesData(res?.quotes?.data);
      setcount(res?.quotes?.totalCounts);
      settotalPages(res?.quotes?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // DELETE QUOTE FUNCTION API CALL
  const handleDeleteQuotes = async (id) => {
    startApiCall(null, setloader);
    const res = await ApiCall("delete", QuotesRelatedEndPoints.deleteQuote, {
      params: {
        quoteId: id,
      },
    });
    if (res?.success) {
      toast.success("Quote deleted successfully!");
      handleGetAllQuotes();
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllQuotes();
    }
  }, [search, page, pageLimit]);
  
  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <QuotesHeader
            setsearch={setsearch}
            search={search}
            setPage={setPage}
            handleGetAllQuotes={handleGetAllQuotes}
            isModuleReadWriteAccessQuotes={isModuleReadWriteAccessQuotes}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allQuotesData?.length > 0 && (
                <QuotesTable
                  allQuotesData={allQuotesData}
                  page={page}
                  pageLimit={pageLimit}
                  handleDeleteQuotes={handleDeleteQuotes}
                  isModuleReadWriteAccessQuotes={isModuleReadWriteAccessQuotes}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allQuotesData?.length === 0 && (
                <NoData title="No Quotes Added" />
              )}
              {!loader && allQuotesData?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allQuotesData?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quotes;
