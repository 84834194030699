import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Contacts from "../../pages/CRM/Contacts/Contacts";
import ContactOverview from "../../pages/CRM/Contacts/ContactDetails/ContactOverview/ContactOverview";
import ContactTimeline from "../../pages/CRM/Contacts/ContactDetails/ContactTimeline/ContactTimeline";

const ContactsRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "allcontacts",
      element: <Contacts />,
    },
    {
      path: "allcontacts/contactoverview/:id",
      element: <ContactOverview />,
    },
    {
      path: "allcontacts/contacttimeline/:id/:uniqueid",
      element: <ContactTimeline />,
    },
  ],
};

export default ContactsRoutes;
