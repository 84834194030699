import React, { useEffect, useState } from "react";
import ItemsTable from "./ItemsTable";
import AddItem from "./AddItem";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { ItemRelatedEndPoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import ItemsHeader from "./ItemsHeader";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const AllItems = () => {
  const { allModuleAccess , configuration } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [editItemDetails, setEditItemDetails] = useState("");
  const [search, setSearch] = useState("");
  const [itemtype, setItemType] = useState("");
  const [allUnits, setAllUnits] = useState([]);
  const [unit, setUnit] = useState("");

  const showImageInput = configuration?.[0]?.itemImageAllowd;
  const isModuleReadWriteAccessItems = allModuleAccess?.[PRIVATEROUTES.ITEMS]

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // GET ALL ITEMS FUNCTION API CALL
  const handleGetAllItems = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        itemType: itemtype,
        unit: unit,
      },
    };
    const res = await ApiCall("post", ItemRelatedEndPoints.getItem, data);
    if (res?.success) {
      setAllItems(res?.item?.data);
      setcount(res?.item?.totalCounts);
      settotalPages(res?.item?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllItems();
    }
  }, [page, pageLimit, search, itemtype, unit]);

  const handleGetAllUnit = async () => {
    const data = {
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall("post", ItemRelatedEndPoints.getItemUnit, data);
    if (res?.success) {
      setAllUnits(res?.item);
    }
  };

  useEffect(() => {
    handleGetAllUnit();
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ItemsHeader
            search={search}
            setSearch={setSearch}
            setPage={setPage}
            itemtype={itemtype}
            setItemType={setItemType}
            handleGetAllItems={handleGetAllItems}
            allUnits={allUnits}
            unit={unit}
            setUnit={setUnit}
            isModuleReadWriteAccessItems={isModuleReadWriteAccessItems}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allItems?.length > 0 && (
                <ItemsTable
                  allItems={allItems}
                  setEditItemDetails={setEditItemDetails}
                  editItemDetails={editItemDetails}
                  handleGetAllItems={handleGetAllItems}
                  page={page}
                  pageLimit={pageLimit}
                  showImageInput={showImageInput}
                  isModuleReadWriteAccessItems={isModuleReadWriteAccessItems}
                />
              )}
              {!loader && allItems?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allItems?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allItems?.length === 0 && (
                <NoData title="No items added" />
              )}
            </div>
          </div>
        </div>
        <AddItem
          handleGetAllItems={handleGetAllItems}
          allUnits={allUnits}
          handleGetAllUnit={handleGetAllUnit}
          showImageInput={showImageInput}
          editItemDetails={editItemDetails}
          setEditItemDetails={setEditItemDetails}
        />
      </div>
    </div>
  );
};

export default AllItems;
