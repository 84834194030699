import React, { useEffect, useState } from "react";
import AddFolder from "../Folder/AddFolder";
import AddList from "../List/AddList";
import {
  closeAllModals,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { spaceEndpoint } from "../../api/Endpoints";
import { useParams } from "react-router-dom";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../components/NoData/NoData";
import SpacesBody from "./SpacesBody";
import { useSelector } from "react-redux";
import SpaceHeader from "./SpaceHeader";
import SpaceMoreInfo from "./SpaceMoreInfo";
import PermisionModal from "../../components/PermisionModal/PermisionModal";
import FolderMoreInfo from "../Folder/FolderMoreInfo";
import ListMoreInfo from "../List/ListMoreInfo";
import UserInfoModal from "../../components/UserInfoModal/UserInfoModal";
import Status from "../Settings/Status/Status";
import PRIVATEROUTES from "../../components/Constant/Route-Constants";

function Spaces() {
  const { spaceId, spacename } = useParams();
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [spaceData, setspaceData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedTab, setSelectedTab] = useState("ALL");
  const [userInfoData, setuserInfoData] = useState([]);

  const isModuleReadWriteAccessForSpace =
    allModuleAccess?.[PRIVATEROUTES.SPACE];

  //  ******** PERMISSION MODAL *******
  const [permissionModalType, setpermissionModalType] = useState("");
  const [permissionData, setpermissionData] = useState([]);

  //  ******** INFO MODAL *******
  const [spaceMoreInfoModal, setspaceMoreInfoModal] = useState(false);
  const [folderMoreInfoModal, setfolderMoreInfoModal] = useState(false);
  const [listMoreInfoModal, setlistMoreInfoModal] = useState(false);

  //  ******** PAGINATION *******
  const [folderPageLimit, setfolderPageLimit] = useState(30);
  const [ListPageLimit, setListPageLimit] = useState(30);
  const [folderPageNumber, setfolderPageNumber] = useState(1);
  const [ListPageNumber, setListPageNumber] = useState(1);
  const [folderTotalPages, setfolderTotalPages] = useState(0);
  const [listTotalPages, setlistTotalPages] = useState(0);
  const [folderCount, setfolderCount] = useState(0);
  const [listCount, setlistCount] = useState(0);

  const handleCloseAllInfoModal = () => {
    setspaceMoreInfoModal(false);
    setfolderMoreInfoModal(false);
    setlistMoreInfoModal(false);
  };

  const handleGetFolderList = async () => {
    startApiCall(null, setloader);
    const sendData = {
      spaceId: spaceId,
      pageLimitFolder: folderPageLimit,
      pageNumberFolder: folderPageNumber,
      pageLimitList: ListPageLimit,
      pageNumberList: ListPageNumber,
      employeeId: user?._id,
      searchKey: search,
    };
    const res = await ApiCall("post", spaceEndpoint.getFolderAndList, sendData);
    if (res?.success) {
      handleCloseAllInfoModal();
      setspaceData(res?.space);
      setfolderTotalPages(res?.space?.folders?.totalPages);
      setlistTotalPages(res?.space?.lists?.totalPages);
      setfolderCount(res?.space?.folders?.totalCounts);
      setlistCount(res?.space?.lists?.totalCounts);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetFolderList();
    setSelectedTab("ALL");
    closeAllModals();
  }, [
    spaceId,
    search,
    folderPageLimit,
    ListPageLimit,
    folderPageNumber,
    ListPageNumber,
  ]);

  const handleOpenFolderInfo = () => {
    setfolderMoreInfoModal(true);
    setspaceMoreInfoModal(false);
    setlistMoreInfoModal(false);
  };
  const handleOpenSpaceInfo = () => {
    setspaceMoreInfoModal(true);
    setfolderMoreInfoModal(false);
    setlistMoreInfoModal(false);
  };
  const handleOpenListInfo = () => {
    setlistMoreInfoModal(true);
    setspaceMoreInfoModal(false);
    setfolderMoreInfoModal(false);
  };

  return (
    <div className={`fmapp-wrap fmapp-info}`}>
      <div className="fmapp-content">
        <div className="fmapp-detail-wrap">
          <SpaceHeader
            search={search}
            setSearch={setSearch}
            handleGetFolderList={handleGetFolderList}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            spaceData={spaceData?.space}
            handleOpenSpaceInfo={handleOpenSpaceInfo}
            setpermissionModalType={setpermissionModalType}
            setpermissionData={setpermissionData}
            handleCloseAllInfoModal={handleCloseAllInfoModal}
            setfolderPageNumber={setfolderPageNumber}
            setListPageNumber={setListPageNumber}
            isModuleReadWriteAccessForSpace={isModuleReadWriteAccessForSpace}
            spaceId={spaceId}
            spacename={spacename}
          />
          {loader && <DataLoader title="data is loading..." />}

          {spaceData?.folders?.data?.length === 0 &&
            spaceData?.lists?.data?.length === 0 &&
            !loader && (
              <div className="d-flex justify-content-center">
                <NoData />
              </div>
            )}
          {(spaceData?.folders?.data?.length !== 0 ||
            spaceData?.lists?.data?.length !== 0) &&
            !loader && (
              <SpacesBody
                spaceData={spaceData}
                loader={loader}
                selectedTab={selectedTab}
                spacename={spacename}
                spaceId={spaceId}
                handleOpenFolderInfo={handleOpenFolderInfo}
                handleOpenListInfo={handleOpenListInfo}
                setpermissionModalType={setpermissionModalType}
                setpermissionData={setpermissionData}
                handleCloseAllInfoModal={handleCloseAllInfoModal}
                folderPageLimit={folderPageLimit}
                setfolderPageLimit={setfolderPageLimit}
                ListPageLimit={ListPageLimit}
                setListPageLimit={setListPageLimit}
                folderPageNumber={folderPageNumber}
                setfolderPageNumber={setfolderPageNumber}
                ListPageNumber={ListPageNumber}
                setListPageNumber={setListPageNumber}
                folderTotalPages={folderTotalPages}
                listTotalPages={listTotalPages}
                folderCount={folderCount}
                listCount={listCount}
                isModuleReadWriteAccessForSpace={
                  isModuleReadWriteAccessForSpace
                }
              />
            )}
          {spaceMoreInfoModal && (
            <SpaceMoreInfo
              moreInfoDetails={spaceData?.space}
              spaceMoreInfoModal={spaceMoreInfoModal}
              setspaceMoreInfoModal={setspaceMoreInfoModal}
              user={user}
              setuserInfoData={setuserInfoData}
              isModuleReadWriteAccessForSpace={isModuleReadWriteAccessForSpace}
            />
          )}
          {folderMoreInfoModal && (
            <FolderMoreInfo
              moreInfoDetails={permissionData}
              folderMoreInfoModal={folderMoreInfoModal}
              setfolderMoreInfoModal={setfolderMoreInfoModal}
              user={user}
              setuserInfoData={setuserInfoData}
              isModuleReadWriteAccessForSpace={isModuleReadWriteAccessForSpace}
            />
          )}
          {listMoreInfoModal && (
            <ListMoreInfo
              moreInfoDetails={permissionData}
              listMoreInfoModal={listMoreInfoModal}
              setlistMoreInfoModal={setlistMoreInfoModal}
              user={user}
              setuserInfoData={setuserInfoData}
              isModuleReadWriteAccessForSpace={isModuleReadWriteAccessForSpace}
            />
          )}
        </div>

        <AddFolder
          spaceId={spaceId}
          user={user}
          handleGetFolderList={handleGetFolderList}
        />
        <PermisionModal
          infoData={permissionData}
          handleGetFolderList={handleGetFolderList}
          type={permissionModalType}
          user={user}
        />
        <AddList
          spaceId={spaceId}
          user={user}
          handleGetFolderList={handleGetFolderList}
          modalId={"add_space_list"}
        />
        <UserInfoModal user={userInfoData} />
        <Status
          isModuleReadWriteAccessForSpace={isModuleReadWriteAccessForSpace}
        />
      </div>
    </div>
  );
}

export default Spaces;
