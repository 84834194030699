import React from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const BillFilter = ({
  startDate,
  setstartDate,
  endDate,
  setendDate,
  mainLoader,
  setdueDate,
  dueDate,
  handleResetFilters,
  dateModal,
  handleGetAllBills,
  filterType,
}) => {
  return (
    <div
      className={` custom-modal  ${dateModal ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: dateModal ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h5>
              Filter by {`${filterType === "date" ? "Date" : "Due Date"}`}
            </h5>
            <div className="dropdown-divider"></div>

            {filterType === "date" && (
              <div className="row gx-3 mt-3">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Start Date</label>
                    <input
                      className="form-control"
                      name="single-date-pick"
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        setstartDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">End Date</label>
                    <input
                      className="form-control"
                      name="single-date-pick"
                      type="date"
                      value={endDate}
                      onChange={(e) => {
                        setendDate(e.target.value);
                      }}
                      min={startDate}
                    />
                  </div>
                </div>
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      handleResetFilters("Date Range", false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    disabled={startDate === "" || endDate === ""}
                    className="btn btn-primary"
                    onClick={() => {
                      handleGetAllBills();
                    }}
                  >
                    {mainLoader ? <ApiLoader /> : "Filter"}
                  </button>
                </div>
              </div>
            )}

            {filterType === "duedate" && (
              <div className="form-group">
                <label className="form-label">Select Due Date</label>
                <input
                  className="form-control w-50"
                  name="single-date-pick"
                  type="date"
                  value={dueDate}
                  onChange={(e) => {
                    setdueDate(e.target.value);
                  }}
                />
                <div className="modal-footer align-items-center mt-2">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      handleResetFilters("Due Date", false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    disabled={dueDate === ""}
                    className="btn btn-primary"
                    onClick={() => {
                      handleGetAllBills();
                    }}
                  >
                    {mainLoader ? <ApiLoader /> : "Filter"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillFilter;
