import React from "react";
import logo from "../../../../assets/images/logo/bizhoodfullLogo.png";
import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  convertAmountLocal,
  convertTimeAndDateMoment,
} from "../../../../global-functions/globalFunctions";
import numberToWords from "number-to-words";

const PrintInvoiceDetails = React.forwardRef((props, ref) => {
  const { invoiceDetails, invoiceSettingDetails } = props;

  const calculatePercentageAmount = (charge) => {
    let subTotal = invoiceDetails?.orderId?.subTotal || 0;
    if (charge?.chargeId?.chargeType === "percentage") {
      const percentageValue = charge?.amount || 0;
      return (subTotal * percentageValue) / 100;
    }
    return 0;
  };

  const getCustomizationItem = (productId) => {
    const tempItem = invoiceDetails?.orderId?.customization?.filter(
      (inventory) => inventory?.productId === productId
    );
    return tempItem;
  };

  const calculateCustomizeTotal = (productId) => {
    const total = getCustomizationItem(productId).reduce((accumulator, dt) => {
      const value = dt.price;
      return accumulator + parseInt(value);
    }, 0);
    return parseInt(total);
  };
  return (
    <div ref={ref}>
      <div class="invoice-wrapper">
        <div class="invoice">
          <table>
            <tbody>
              <tr>
                <td class="text-center" colspan="10">
                  <h6 class="text-uppercase fw-bold mb-0-5">High Sky</h6>
                  <p class="text-uppercase fw-bold mb-0-5">Cafe & Restaurant</p>
                  <p class=" mb-0-5">4th & roof top, Bhubneshwari Plaza</p>
                  <p class="text-uppercase mb-0-5">
                    NEAR PLAZA MARKET, TELCO, JAMSHEDPUR
                  </p>
                  <p class="text-uppercase mb-0-5">
                    EAST SINGHBHUM, JHARKHAND 831010
                  </p>
                  <p class=" mb-0-5">
                    Phone : {invoiceSettingDetails?.businessPhone}
                  </p>
                  <p class=" mb-0-5">
                    Email : {invoiceSettingDetails?.businessEmail}
                  </p>
                  <p class=" mb-0-5">Web : www.highskyrestaurant.com</p>
                  <p class="mb-0-5">
                    GST No. : {invoiceSettingDetails?.businessGSTNumber}
                  </p>
                </td>
              </tr>
              <tr class="border-top-dashed border-bottom-dashed">
                <td class="text-left" colspan="5">
                  {invoiceDetails?.orderId?.tableId?.tableName && (
                    <p class="w-100 overflow-hide nowrap mb-0-5">
                      Table : {invoiceDetails?.orderId?.tableId?.tableName}
                    </p>
                  )}
                  <p class="w-100 overflow-hide nowrap mb-0">
                    Customer Phone :{" "}
                    {invoiceDetails?.orderId?.buyer?.phone
                      ? invoiceDetails?.orderId?.buyer?.phone
                      : "Guest"}
                  </p>
                </td>
                <td class="text-left" colspan="5">
                  <p class="w-100 overflow-hide nowrap mb-0-5">
                    Invoice No. : {invoiceDetails?.invoiceNumber}
                  </p>
                  <p class="w-100 overflow-hide nowrap mb-0">
                    Date : {convertTimeAndDateMoment(invoiceDetails?.createdAt)}
                  </p>
                </td>
              </tr>
              <tr class="border-bottom-dashed">
                <td colspan="4">
                  <p class="w-100 overflow-hide nowrap mb-0">S. Description</p>
                </td>
                <td colspan="2" class="text-right">
                  <p class="w-100 overflow-hide nowrap mb-0">Qty</p>
                </td>
                <td colspan="2" class="text-right">
                  <p class="w-100 overflow-hide nowrap mb-0">Rate</p>
                </td>
                <td colspan="2" class="text-right">
                  <p class="w-100 overflow-hide nowrap mb-0">Amt</p>
                </td>
              </tr>
              {invoiceDetails?.orderId?.products?.map((data, index) => {
                return (
                  <tr>
                    <td colspan="4" class="text-uppercase">
                      <p class="w-100 mb-00 fs-md">
                        {index + 1}. {data?.productId?.productName}
                      </p>
                    </td>
                    <td colspan="2" class="text-right">
                      <p class="w-100 overflow-hide nowrap mb-00 fs-md">
                        {data?.quantity}
                      </p>
                    </td>
                    <td colspan="2" class="text-right">
                      <p class="w-100 overflow-hide nowrap mb-00 fs-md">
                        {convertAmountLocal(data?.productId?.sellingPrice)}
                      </p>
                    </td>
                    <td colspan="2" class="text-right">
                      <p class="w-100 overflow-hide nowrap mb-00 fs-md">
                        {convertAmountLocal(
                          data?.productId?.sellingPrice * data?.quantity
                        )}
                      </p>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan="10">
                  <hr class="border-bottom-dashed w-100 mb-0 mt-sm" />
                </td>
              </tr>
              <tr>
                <td class="text-left" colspan="5">
                  <p class="w-100 overflow-hide nowrap mb-0">
                    Item Oty :&nbsp;
                    {invoiceDetails?.orderId?.products?.length}
                  </p>
                </td>
                <td class="text-right text-uppercase" colspan="5">
                  {invoiceDetails?.orderId?.charges?.map((chrg, index) => {
                    return (
                      <div class="d-flex align-items-center justify-content-between">
                        <p class="w-100 overflow-hide nowrap fw-semibold mb-0-5">
                          {chrg?.chargeId?.chargeName}&nbsp;
                          {chrg?.chargeId?.chargeType === "percentage" && (
                            <span>({chrg?.amount}%)</span>
                          )}
                          &nbsp;:&nbsp;
                        </p>
                        <p class="mb-0-5">
                          ₹&nbsp;
                          {chrg?.chargeId?.chargeType === "amount"
                            ? chrg?.amount?.toFixed(2)
                            : calculatePercentageAmount(chrg).toFixed(2)}{" "}
                        </p>
                      </div>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <td colSpan="10">
                  <hr class="border-bottom-dashed w-100 mb-0-5 mt-0-5" />
                </td>
              </tr>
              <tr>
                <td colSpan="10" class="text-right">
                  <p class="fw-bold f-lg mb-00">
                    Grand Total :&nbsp;
                    {convertAmountLocal(invoiceDetails?.orderId?.amount)}
                  </p>
                  <p class="f-sm mb-0-5">
                    {numberToWords.toWords(
                      invoiceDetails?.orderId?.amount || 0
                    )}
                  </p>
                </td>
              </tr>
              <tr class="border-bottom-dashed">
                <td colspan="10">
                  <p class="w-100 overflow-hide nowrap mb-0">
                    Terms & Conditions :-
                  </p>
                </td>
              </tr>
              <tr>
                <td class="text-center" colspan="10">
                  <p class="w-100 overflow-hide nowrap text-uppercase">
                    !!! Thankyou !!! Visit Again !!!
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default PrintInvoiceDetails;
