import React, { useEffect, useState } from "react";
import ShiftHeader from "./ShiftHeader";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { ShiftRelatedEndPoints } from "../../../../api/Endpoints";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import NoData from "../../../../components/NoData/NoData";
import AddShiftType from "./AddShiftType";
import ShiftTable from "./ShiftTable";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";
import AllShiftsSkeleton from "./AllShiftsSkeleton";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

const AllShifts = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [allShifts, setAllShifts] = useState([]);
  const [search, setSearch] = useState();
  const [editShiftDetails, seteditShiftDetails] = useState(null);
  const [isAddShift, setisAddShift] = useState(false);

  // * PAGINATION *
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllShifts = async () => {
    startApiCall(null, setloader);
    const sendData = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      ShiftRelatedEndPoints.getStaffShift,
      sendData
    );
    if (res?.success) {
      setAllShifts(res?.staff?.data);
      setcount(res?.staff?.totalCounts);
      settotalPages(res?.staff?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllShifts();
  }, [search, page, pageLimit]);

  const isModuleReadWriteAccessShift = allModuleAccess?.[PRIVATEROUTES.SHIFT];

  return (
    <>
      <div className="todoapp-wrap">
        <div className="todoapp-content">
          <div className="todoapp-detail-wrap">
            <ShiftHeader
              handleGetAllShifts={handleGetAllShifts}
              setSearch={setSearch}
              setPage={setPage}
              search={search}
              setisAddShift={setisAddShift}
              isModuleReadWriteAccessShift={isModuleReadWriteAccessShift}
            />
            <div className="todo-body">
              <div data-simplebar className="nicescroll-bar simple-scrollbar">
                <div className="contact-list-view mb-8">
                  {!loader && allShifts?.length > 0 && (
                    <ShiftTable
                      allShifts={allShifts}
                      page={page}
                      pageLimit={pageLimit}
                      seteditShiftDetails={seteditShiftDetails}
                      setisAddShift={setisAddShift}
                      isModuleReadWriteAccessShift={
                        isModuleReadWriteAccessShift
                      }
                    />
                  )}

                  {!loader && allShifts?.length > 0 && (
                    <FooterPagination
                      setpageLimit={setpageLimit}
                      pageLimit={pageLimit}
                      setPage={setPage}
                      page={page}
                      totalPages={totalPages}
                      count={count}
                      dataLength={allShifts?.length}
                      align={"end"}
                    />
                  )}
                  {loader && <AllShiftsSkeleton />}
                  {!loader && allShifts?.length === 0 && (
                    <NoData title="No shift added" />
                  )}
                </div>
              </div>
            </div>
          </div>

          {isAddShift && (
            <AddShiftType
              setisAddShift={setisAddShift}
              isAddShift={isAddShift}
              handleGetAllShifts={handleGetAllShifts}
              editShiftDetails={editShiftDetails}
              seteditShiftDetails={seteditShiftDetails}
              allShifts={allShifts}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AllShifts;
