import React, { useState } from "react";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { InvoicesRelatedEndPoints } from "../../../../api/Endpoints";
import { toast } from "react-toastify";

const AddPayment = ({
  id,
  invoiceDetails,
  handleGetTransactions,
  handleGetInvoiceDetails,
}) => {
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [rcvAmount, setRcvAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("bank-transfer");
  const [status, setStatus] = useState("paid");
  const [transactionNo, setTransactionNo] = useState("");

  const allPaymentMode = [
    {
      name: "Bank Transfer",
      value: "bank-transfer",
    },
    {
      name: "Cash",
      value: "cash",
    },
    {
      name: "UPI",
      value: "upi",
    },
  ];

  const allStatusTypes = [
    {
      name: "Paid",
      value: "paid",
    },
    {
      name: "Partially Paid",
      value: "partially-paid",
    },
    {
      name: "Due",
      value: "due",
    },
  ];

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setPaymentDate("");
    setRcvAmount("");
    setPaymentMode("bank-transfer");
    setStatus("paid");
    setTransactionNo("");
    const closeModalDom = document.getElementById("add_payment");
    if (closeModalDom) closeModalDom.click();
  };

  // ADD TRANSACTION FUNCTION API CALL
  const handleAddTransaction = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
      invoiceId: id,
      paymentDate,
      amountReceived: rcvAmount,
      paymentMode,
      transactionNumber: transactionNo,
      customerId: invoiceDetails?.customerId?._id,
      status,
    };
    const res = await ApiCall(
      "post",
      InvoicesRelatedEndPoints.addTransaction,
      sendData
    );
    if (res?.success) {
      toast.success("Add transaction successfully!");
      handleReset();
      handleGetTransactions();
      handleGetInvoiceDetails();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_payment"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Add Payment</h5>
            <div className="border-bottom mb-3"></div>
            <form onSubmit={handleAddTransaction}>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <label className="text-dark fw-250 fs-6">Payment date</label>
                  <div className="form-group">
                    <input
                      className="form-control mt-2"
                      type="date"
                      required
                      value={paymentDate}
                      onChange={(e) => {
                        setPaymentDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <label className="text-dark fw-300 fs-6">
                    Amount Received
                  </label>
                  <input
                    className="form-control mt-2"
                    type="number"
                    required
                    value={rcvAmount}
                    onChange={(e) => {
                      setRcvAmount(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <label className="text-dark fw-250 fs-6">Payment mode</label>
                  <div className="form-group mt-2">
                    <select
                      className="form-select form-select-md p-2"
                      value={paymentMode}
                      onChange={(e) => {
                        setPaymentMode(e.target.value);
                      }}
                    >
                      {allPaymentMode?.map((data) => {
                        return (
                          <option value={data?.value}>{data?.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <label className="text-dark fw-250 fs-6">Status</label>
                  <div className="form-group mt-2">
                    <select
                      className="form-select form-select-md p-2"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      {allStatusTypes?.map((data) => {
                        return (
                          <option value={data?.value}>{data?.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {paymentMode !== "cash" && (
                <div className="row gx-3">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="text-dark fw-300 fs-6">
                        Transaction number
                      </label>
                      <input
                        className="form-control mt-2"
                        type="text"
                        required
                        value={transactionNo}
                        onChange={(e) => {
                          setTransactionNo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPayment;
