import React, { useEffect, useState } from "react";
import GenerateInvoiceBody from "./GenerateInvoiceBody";
import GenerateInvoiceHeader from "./GenerateInvoiceHeader";
import AddItemInInvoice from "./AddItemInInvoice";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import {
  InvoicesRelatedEndPoints,
  QuotesRelatedEndPoints,
} from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import { useSelector } from "react-redux";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToAllInvoices } from "../../../../Navigation/Navigation";

const GenerateInvoice = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setloader] = useState(false);
  const [invoiceDate, setinvoiceDate] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [selectedCustomer, setselectedCustomer] = useState("");
  const [customerData, setcustomerData] = useState([]);
  const [invoiceNumber, setinvoiceNumber] = useState("");
  const [description, setdescription] = useState("");
  const [invoiceItems, setinvoiceItems] = useState([]);
  const [items, setitems] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [locationData, setlocationData] = useState([]);
  const [quoteDetailsData, setquoteDetailsData] = useState("");
  // console.log("locationData: ", locationData);
  const [billingAddress, setbillingAddress] = useState({
    country: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const [shippingAddress, setshippingAddress] = useState({
    country: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const handleReset = () => {
    setloader(false);
    setinvoiceDate("");
    setdueDate("");
    setselectedCustomer("");
    setinvoiceNumber("");
    setdescription("");
    setinvoiceItems("");
    setitems("");
    setbillingAddress({
      country: "",
      city: "",
      zipCode: "",
      address: "",
    });
    setshippingAddress({
      country: "",
      city: "",
      zipCode: "",
      address: "",
    });
    navigate(navigateToAllInvoices());
  };

  const calculateTotalSum = () => {
    const sum = invoiceItems
      ?.map((item) => parseFloat(item?.total || 0))
      ?.reduce((acc, val) => acc + val, 0);
    return sum?.toFixed(2);
  };

  // CREATE INVOICE FUNCTION API CALL
  const handleAddInovices = async () => {
    startApiCall(null, setloader);
    const data = {
      customerId: selectedCustomer,
      invoiceNumber: invoiceNumber,
      invoiceDate: invoiceDate,
      dueDate: dueDate,
      subject: description,
      items: items,
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      termsAndConditions: termsAndConditions,
      totalAmount: calculateTotalSum(),
      billingPinCode: billingAddress?.zipCode,
      billingCountry: billingAddress?.country,
      billingCity: billingAddress?.city,
      billingStreet: billingAddress?.address,
      shippingPinCode: shippingAddress?.zipCode,
      shippingCountry: shippingAddress?.country,
      shippingCity: shippingAddress?.city,
      shippingStreet: shippingAddress?.address,
    };
    const res = await ApiCall(
      "post",
      InvoicesRelatedEndPoints.addInvoice,
      data
    );
    if (res?.success) {
      handleReset();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  const handleGetQuoteDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      quoteId: locationData?.id,
    };
    const res = await ApiCall(
      "post",
      QuotesRelatedEndPoints.getQuoteById,
      data
    );
    if (res?.success) {
      setquoteDetailsData(res?.quotes);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && locationData?.Type === "Convert") {
      handleGetQuoteDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.Type === "Convert") {
      setselectedCustomer(quoteDetailsData?.customerId?._id);
      setcustomerData(quoteDetailsData?.customerId?.name)
      // setdescription();
      setinvoiceDate(reversesDateFormatter(quoteDetailsData?.quoteDate));
      setdueDate(reversesDateFormatter(quoteDetailsData?.expiryDate));
      setbillingAddress({
        country: quoteDetailsData?.billingAddress?.country,
        city: quoteDetailsData?.billingAddress?.city,
        zipCode: quoteDetailsData?.billingAddress?.pinCode,
        address: quoteDetailsData?.billingAddress?.street,
      });
      setshippingAddress({
        country: quoteDetailsData?.shippingAddress?.country,
        city: quoteDetailsData?.shippingAddress?.city,
        zipCode: quoteDetailsData?.shippingAddress?.pinCode,
        address: quoteDetailsData?.shippingAddress?.street,
      });
      setTermsAndConditions(quoteDetailsData?.termsAndConditions);
      setinvoiceItems(quoteDetailsData?.items);
      const mappedItems = quoteDetailsData?.items?.map((item) => ({
        itemId: item?.itemId?._id,
        quantity: item?.quantity,
        discount: item?.itemId?.discount ? item?.itemId?.discount : 0,
        total: item?.total,
      }));
      setitems(mappedItems);
    }
  }, [locationData, quoteDetailsData]);

  return (
    <>
      <div className="todoapp-wrap">
        <div className="todoapp-content">
          <div className="todoapp-detail-wrap">
            <GenerateInvoiceHeader />
            <div className="todo-body">
              <div data-simplebar className="nicescroll-bar simple-scrollbar">
                {!loader && (
                  <GenerateInvoiceBody
                    invoiceDate={invoiceDate}
                    setinvoiceDate={setinvoiceDate}
                    dueDate={dueDate}
                    setdueDate={setdueDate}
                    setselectedCustomer={setselectedCustomer}
                    selectedCustomer={selectedCustomer}
                    setinvoiceNumber={setinvoiceNumber}
                    invoiceNumber={invoiceNumber}
                    description={description}
                    setdescription={setdescription}
                    shippingAddress={shippingAddress}
                    setshippingAddress={setshippingAddress}
                    billingAddress={billingAddress}
                    setbillingAddress={setbillingAddress}
                    invoiceItems={invoiceItems}
                    handleAddInovices={handleAddInovices}
                    calculateTotalSum={calculateTotalSum}
                    errorMessage={errorMessage}
                    items={items}
                    termsAndConditions={termsAndConditions}
                    setTermsAndConditions={setTermsAndConditions}
                    locationData={locationData}
                    customerData={customerData}
                    setcustomerData={setcustomerData}
                    quoteDetailsData={quoteDetailsData}
                  />
                )}

                {loader && <DataLoader title="Creating Invoice..." />}
              </div>
            </div>
          </div>
          <AddItemInInvoice
            setinvoiceItems={setinvoiceItems}
            setitems={setitems}
            invoiceItems={invoiceItems}
          />
        </div>
      </div>
    </>
  );
};

export default GenerateInvoice;
