import React, { useState } from "react";
import {
  dateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";
import {
  navigateToBillDetails,
  navigateToCreateBills,
} from "../../../Navigation/Navigation";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import ApiCall from "../../../api/ApiCall";
import { BillRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

const BillsTable = ({ allBills, page, pageLimit, handleGetAllBills }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [billId, setBillId] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setErrorMessage("");
    setIsOpen(true);
  };

  const handleVoidOrDeleteBill = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      billId,
      isVoid: selectedStatus === "Void" ? true : false,
      isDeleted: selectedStatus === "Delete" ? true : false,
    };
    const res = await ApiCall(
      "post",
      BillRelatedEndPoints.voidOrDeleteBill,
      data
    );
    if (res?.success) {
      toast.success(`Invoice ${selectedStatus} successfully!`);
      handleGetAllBills();
      handleClose();
    } else {
      setloader(false);
      setErrorMessage("");
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th>Date</th>
              <th>Bill Number</th>
              <th>Vendor Name</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Total Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allBills?.map((bill, index) => {
              return (
                <tr className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td>{dateFormatter(bill?.billDate)}</td>
                  <td
                    className="pointer"
                    onClick={() => {
                      navigate(navigateToBillDetails({ id: bill?._id }));
                    }}
                  >
                    {bill?.billNumber}
                  </td>
                  <td>{bill?.vendorId?.name}</td>
                  <td>{dateFormatter(bill?.dueDate)}</td>
                  <td className="text-capitalize">{bill?.status}</td>
                  <td>{bill?.totalAmount?.toFixed(2)}</td>
                  <td>
                    <span className="feather-icon pointer">
                      {bill?.isVoid === false ? (
                        <i
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="ri-more-fill"
                        ></i>
                      ) : (
                        "-"
                      )}
                      <div className="dropdown-menu">
                        <div
                          className="dropdown-item d-flex align-items-center"
                          onClick={() => {
                            navigate(navigateToCreateBills(), {
                              state: { id: bill?._id },
                            });
                          }}
                        >
                          <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="20"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </span>
                          </span>
                          <p>Edit</p>
                        </div>
                        <div
                          className="dropdown-item d-flex align-items-center"
                          onClick={() => {
                            handleStatusChange("Void");
                            setBillId(bill?._id);
                          }}
                        >
                          <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger">
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-ban"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15 8a6.973 6.973 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
                              </svg>
                            </span>
                          </span>
                          <p>Void</p>
                        </div>
                        <div
                          className="dropdown-item d-flex align-items-center"
                          onClick={() => {
                            handleStatusChange("Delete");
                            setBillId(bill?._id);
                          }}
                        >
                          <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger">
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                              </svg>
                            </span>
                          </span>
                          <p>Delete</p>
                        </div>
                      </div>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text={`Are You Sure You Want To ${selectedStatus} This Bill`}
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleVoidOrDeleteBill}
      />
    </>
  );
};

export default BillsTable;
