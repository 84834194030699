import React, { useEffect, useState } from "react";
import TaskListTable from "./Views/TaskListTable";
import AddTask from "./AddTask";
import {
  closeAllModals,
  getAllWorkspaceStatusBySpace,
  startApiCall,
} from "../../global-functions/globalFunctions";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import { useLocation, useParams } from "react-router-dom";
import ApiCall from "../../api/ApiCall";
import { taskEndpoint } from "../../api/Endpoints";
import NoData from "../../components/NoData/NoData";
import { useSelector } from "react-redux";
import { Assignee } from "./Filters/Assignee";
import { DueDate } from "./Filters/DueDate";
import { DateRange } from "./Filters/DateRange";
import TaskHeader from "./TaskHeader";
import TaskDetailsModal from "./TaskDetailsModal/TaskDetailsModal";
import { AssignEmployee } from "./AssignEmployee";
import NoTask from "./NoTask";
import ResetFilterWithChip from "./Filters/ResetFilterWithChip";
import CalendarView from "./Views/CalendarView";
import KanbanView from "../../components/Kanban/KanbanView";
import FooterPagination from "../../components/Pagination/FooterPagination";
import UserInfoModal from "../../components/UserInfoModal/UserInfoModal";
import ChangeDuedate from "../../components/ChangeDuedate/ChangeDuedate";

function Task() {
  const { id } = useParams();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [taskData, settaskData] = useState([]);
  const [search, setSearch] = useState("");
  const [subTask, setsubTask] = useState([]);
  const [locationData, setlocationData] = useState([]);
  const [selectedFilterType, setselectedFilterType] = useState("");
  const taskFilters = ["Assignee", "Date Range", "Due Date"];
  const [selectedAssigneeId, setselectedAssigneeId] = useState([]);
  const [dueDateForFilter, setdueDateForFilter] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [taskStatus, settaskStatus] = useState([]);
  const [taskStatusLoader, settaskStatusLoader] = useState(false);
  const [selectedTaskStatus, setselectedTaskStatus] = useState({
    statusName: "All Status",
    color: "#c8c8c8",
  });
  const [selectedTaskPriority, setselectedTaskPriority] = useState({
    name: "All Priority",
    color: "#c8c8c8",
    value: "",
  });
  const [isFilteredModal, setisFilteredModal] = useState(false);
  const [isFilterReset, setisFilterReset] = useState(false);
  const [selectedTask, setselectedTask] = useState([]);
  const [taskDetailsModal, settaskDetailsModal] = useState(false);
  const [isAssigneeEmployeeModal, setisAssigneeEmployeeModal] = useState(false);
  const [selectedView, setSelectedView] = useState(
    localStorage.getItem("taskview")
      ? localStorage.getItem("taskview")
      : "Table View"
  );
  const [allTasks, setAllTasks] = useState([]);

  const [editDueDate, seteditDuedDate] = useState(false);
  const [taskId, settaskId] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // ****** FOR USER DETILS ******
  const [userInfoData, setuserInfoData] = useState([]);

  const rightIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-chevron-compact-right mt-1"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
      />
    </svg>
  );

  const handleChangePriority = (pr) => {
    setselectedTaskPriority(pr);
  };
  const handleChangeStatus = (status) => {
    setselectedTaskStatus(status);
  };

  const handleGetAllTaskStatus = async () => {
    startApiCall(null, settaskStatusLoader);
    const res = await getAllWorkspaceStatusBySpace(locationData?.spaceId);
    settaskStatus([...res]);
    settaskStatusLoader(false);
  };

  const handleSubTask = async (taskId) => {
    seterrorMessage("");
    setloader3((prevLoading) => ({
      ...prevLoading,
      [taskId]: true,
    }));
    setTimeout(() => {
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    }, 50000);
    const sendData = {
      page: 1,
      limit: 500,
      filters: {
        maintaskId: taskId,
        taskType: "subtask",
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      setsubTask((prevSubtaskData) => ({
        ...prevSubtaskData,
        [taskId]: res?.task?.data,
      }));
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    } else {
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
      seterrorMessage(res.error);
    }
  };

  const handleGetTask = async (e) => {
    e && e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const sendData = {
      page: page,
      limit: pageLimit,
      filters: {
        list: id,
        taskType: "maintask",
        searchKey: search,
        startDate: startDate,
        endDate: endDate,
        dueDate: dueDateForFilter,
        priority: selectedTaskPriority?.value,
        assignedTo: selectedAssigneeId?.length === 0 ? "" : selectedAssigneeId,
        status:
          selectedTaskStatus?.statusName === "All Status"
            ? ""
            : selectedTaskStatus?._id,
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      setisFilteredModal(false);
      setisFilterReset(false);
      settaskData(res?.task?.data);
      setcount(res?.task?.totalCounts);
      settotalPages(res?.task?.totalPages);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  useEffect(() => {
    handleGetTask();
    closeAllModals();
  }, [
    search,
    selectedTaskStatus,
    selectedTaskPriority,
    selectedView,
    page,
    pageLimit,
  ]);

  useEffect(() => {
    if (isFilterReset) {
      handleGetTask();
    }
  }, [isFilterReset]);

  const handleResetFilters = (type, isReset) => {
    if (type === "Assignee") {
      setselectedAssigneeId([]);
    } else if (type === "Date Range") {
      setstartDate("");
      setendDate("");
    } else if (type === "Due Date") {
      setdueDateForFilter("");
    } else if (type === "Status") {
      setselectedTaskStatus({
        statusName: "All Status",
        color: "#c8c8c8",
      });
    } else if (type === "Priority") {
      setselectedTaskPriority({
        name: "All Priority",
        color: "#c8c8c8",
        value: "",
      });
    }
    setisFilteredModal(false);
    setisFilterReset(isReset);
  };

  return (
    <div
      className="todoapp-wrap task-info todoapp-info
      "
    >
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <TaskHeader
            location={location}
            locationData={locationData}
            taskFilters={taskFilters}
            setselectedFilterType={setselectedFilterType}
            setSearch={setSearch}
            search={search}
            handleGetTask={handleGetTask}
            handleGetAllTaskStatus={handleGetAllTaskStatus}
            taskStatus={taskStatus}
            taskStatusLoader={taskStatusLoader}
            handleChangeStatus={handleChangeStatus}
            selectedTaskStatus={selectedTaskStatus}
            handleChangePriority={handleChangePriority}
            selectedTaskPriority={selectedTaskPriority}
            setisFilteredModal={setisFilteredModal}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            setPage={setPage}
            isAssigned={false}
            data={taskData}
          />

          <ResetFilterWithChip
            selectedAssigneeId={selectedAssigneeId}
            dueDateForFilter={dueDateForFilter}
            startDate={startDate}
            endDate={endDate}
            selectedTaskStatus={selectedTaskStatus}
            selectedTaskPriority={selectedTaskPriority}
            handleResetFilters={handleResetFilters}
          />
          <div className="todo-body">
            <div className="nicescroll-bar">
              {selectedView === "Table View" && !loader && (
                <>
                  <div className="d-flex align-items-center text-capitalize text-secondary fs-6 ms-2 mb-3">
                    {locationData?.foldername ? (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="17"
                          height="17"
                        >
                          <path d="M12.6727 1.61162 20.7999 9H17.8267L12 3.70302 6 9.15757V19.0001H11V21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001 11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162ZM14 11H23V18H14V11ZM16 13V16H21V13H16ZM24 21H13V19H24V21Z"></path>
                        </svg>
                        &nbsp;
                        <span className="text-truncate mw-200p">
                          {locationData?.spacename}
                        </span>{" "}
                        {rightIcon}{" "}
                        <div className="avatar avatar-icon avatar-xxs avatar-soft-warning">
                          <span className="initial-wrap">
                            <i className="ri-folder-2-fill"></i>
                          </span>
                        </div>
                        &nbsp;&nbsp;
                        <span className="text-truncate  mw-200p">
                          {locationData?.foldername}
                        </span>{" "}
                        {rightIcon}{" "}
                        <div className="avatar avatar-icon avatar-soft-blue avatar-xxs">
                          <span className="initial-wrap">
                            <i className="ri-file-text-fill"></i>
                          </span>
                        </div>
                        &nbsp;&nbsp;
                        <span className="text-truncate  mw-200p">
                          {location?.state?.list}
                        </span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="17"
                          height="17"
                          className="mt-1"
                        >
                          <path d="M12.6727 1.61162 20.7999 9H17.8267L12 3.70302 6 9.15757V19.0001H11V21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001 11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162ZM14 11H23V18H14V11ZM16 13V16H21V13H16ZM24 21H13V19H24V21Z"></path>
                        </svg>
                        &nbsp;&nbsp;
                        <span className="text-truncate mw-200p">
                          {locationData?.spacename}
                        </span>
                        {rightIcon}{" "}
                        <div className="avatar avatar-icon avatar-soft-blue avatar-xxs">
                          <span className="initial-wrap">
                            <i className="ri-file-text-fill"></i>
                          </span>
                        </div>
                        &nbsp;
                        <span className="text-truncate mw-200p">
                          {location?.state?.list}
                        </span>
                      </>
                    )}
                  </div>
                  {taskData?.length > 0 && (
                    <>
                      <TaskListTable
                        taskData={taskData}
                        handleGetTask={handleGetTask}
                        subTask={subTask}
                        handleSubTask={handleSubTask}
                        loader3={loader3}
                        setloader3={setloader3}
                        locationData={locationData}
                        location={location}
                        //new
                        setselectedTask={setselectedTask}
                        settaskDetailsModal={settaskDetailsModal}
                        handleGetAllTaskStatus={handleGetAllTaskStatus}
                        taskStatus={taskStatus}
                        taskStatusLoader={taskStatusLoader}
                        setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
                        page={page}
                        pageLimit={pageLimit}
                        setuserInfoData={setuserInfoData}
                        seteditDuedDate={seteditDuedDate}
                        settaskId={settaskId}
                        isAssigned={false}
                      />
                      <FooterPagination
                        setpageLimit={setpageLimit}
                        pageLimit={pageLimit}
                        setPage={setPage}
                        page={page}
                        totalPages={totalPages}
                        count={count}
                        dataLength={taskData?.length}
                        align={"start"}
                      />
                    </>
                  )}
                </>
              )}

              {selectedView === "Board View" && !loader && (
                <KanbanView
                  selectedView={selectedView}
                  id={id}
                  taskData={allTasks}
                  settaskData={setAllTasks}
                  setselectedTask={setselectedTask}
                  settaskDetailsModal={settaskDetailsModal}
                />
              )}

              {selectedView === "Calendar View" && !loader && (
                <CalendarView
                  taskData={taskData}
                  settaskDetailsModal={settaskDetailsModal}
                  setselectedTask={setselectedTask}
                />
              )}

              {loader && <DataLoader title="Your task of list is loading..." />}
              {!loader && taskData?.length === 0 && search === "" && <NoTask />}
              {!loader && search !== "" && taskData?.length === 0 && (
                <NoData title={`No Task`} />
              )}

              <div
                className="position-fixed bottom-0 end-0 p-2"
                style={{
                  position: "fixed",
                  zIndex: "9999",
                }}
              >
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_new_task"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>{" "}
                  task
                </button>
              </div>
            </div>
          </div>
        </div>
        <AddTask
          listId={id}
          handleGetTask={handleGetTask}
          modalId={"add_new_task"}
        />
      </div>

      {/* ************ FILTER TASK *********** */}
      {isFilteredModal && (
        <div
          className={`modal fade add-new-contact mt-10 ${
            isFilteredModal ? "show" : ""
          }`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: isFilteredModal ? "block" : "none" }}
        >
          <form onSubmit={handleGetTask}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <h5>Filter by {selectedFilterType}</h5>
                  <div className="dropdown-divider"></div>
                  {selectedFilterType === "Assignee" && (
                    <Assignee
                      user={user}
                      listId={id}
                      setselectedAssigneeId={setselectedAssigneeId}
                      selectedAssigneeId={selectedAssigneeId}
                      mainLoader={loader}
                      handleResetFilters={handleResetFilters}
                    />
                  )}
                  {selectedFilterType === "Date Range" && (
                    <DateRange
                      startDate={startDate}
                      setstartDate={setstartDate}
                      endDate={endDate}
                      setendDate={setendDate}
                      mainLoader={loader}
                      handleResetFilters={handleResetFilters}
                    />
                  )}
                  {selectedFilterType === "Due Date" && (
                    <DueDate
                      setdueDateForFilter={setdueDateForFilter}
                      dueDateForFilter={dueDateForFilter}
                      mainLoader={loader}
                      handleResetFilters={handleResetFilters}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      {/* ************ CHANGE DUE DATE *********** */}
      {editDueDate && (
        <ChangeDuedate
          seteditDuedDate={seteditDuedDate}
          taskId={taskId}
          editDueDate={editDueDate}
          taskType={taskId?.taskType}
          handleGetTaskDetails={handleGetTask}
        />
      )}

      {/* ************  TASK DETAILS *********** */}
      {taskDetailsModal && (
        <TaskDetailsModal
          taskId={
            selectedView === "Calendar View" ? selectedTask : selectedTask?._id
          }
          taskDetailsModal={taskDetailsModal}
          settaskDetailsModal={settaskDetailsModal}
          isTask={true}
          handleGetTask={handleGetTask}
        />
      )}
      {isAssigneeEmployeeModal && (
        <AssignEmployee
          setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
          isAssigneeEmployeeModal={isAssigneeEmployeeModal}
          listId={id}
          user={user}
          selectedTask={selectedTask}
          handleGetTask={handleGetTask}
        />
      )}
      <UserInfoModal user={userInfoData} />
    </div>
  );
}

export default Task;
