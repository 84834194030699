import React from "react";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";

const LocationSkeleton = () => {
  return (
    <table id="datable_1" className="table w-100 mb-5 loading-skeleton">
      <thead>
        <tr>
          <th>
            <SkeletonConstant width="w-50p" height="h-25p" />
          </th>
          <th>
            <SkeletonConstant width="w-120p" height="h-25p" />
          </th>
          <th>
            <SkeletonConstant
              width="w-100p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-50p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <SkeletonConstant width="w-50p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant width="w-160p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant width="w-50p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant width="w-160p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant width="w-50p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant width="w-160p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant width="w-50p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant width="w-160p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant width="w-50p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant width="w-160p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant width="w-50p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant width="w-160p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant width="w-50p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant width="w-160p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant width="w-50p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant width="w-160p" height="h-30p" />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default LocationSkeleton;
