import React from "react";
import nodata from "../../assets/images/nodata.jpg";

const NoDataWithoutImage = ({ title, description }) => {
  return (
    <div className="d-flex flex-column">
      <div className="no-data-wrapper p-7 pb-0 pt-3">
        <h3 className="fw-semibold text-capitalize">
          {title ? title : "No results found"}
        </h3>
        <p className="fs-5">
          {description
            ? description
            : "Try adjusting your search or filter to find what you're looking for."}
        </p>

        {/* <button className="fw-semibold fs-5 mt-2 ">Clear search</button> */}
      </div>
    </div>
  );
};

export default NoDataWithoutImage;
