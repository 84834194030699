import React, { useEffect, useState } from "react";
import CreditNoteHeader from "./CreditNoteHeader";
import CreditNoteTable from "./CreditNoteTable";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { CreditNoteRelatedEndPoints } from "../../../api/Endpoints";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const CreditNotes = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [search, setsearch] = useState("");
  const [allCreditnotes, setAllCreditnotes] = useState([]);

  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessCreditNote = allModuleAccess?.[PRIVATEROUTES.CREDIT_NOTES]

  // GET ALL CREDIT NOTE FUNCTION API CALL
  const handleGetAllNotes = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        isDeleted: false,
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall(
      "post",
      CreditNoteRelatedEndPoints.getCreditNotes,
      data
    );
    if (res?.success) {
      setAllCreditnotes(res?.invoice?.data);
      setcount(res?.invoice?.totalCounts);
      settotalPages(res?.invoice?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllNotes()
  }, [search, page, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <CreditNoteHeader
            setsearch={setsearch}
            search={search}
            setPage={setPage}
            handleGetAllNotes={handleGetAllNotes}
            isModuleReadWriteAccessCreditNote={isModuleReadWriteAccessCreditNote}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allCreditnotes?.length > 0 && (
                <CreditNoteTable
                  allCreditnotes={allCreditnotes}
                  page={page}
                  pageLimit={pageLimit}
                  handleGetAllNotes={handleGetAllNotes}
                  isModuleReadWriteAccessCreditNote={isModuleReadWriteAccessCreditNote}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allCreditnotes?.length === 0 && (
                <NoData title="No Credit Notes Added" />
              )}
              {!loader && allCreditnotes?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allCreditnotes?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditNotes;
