import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  handlePreviewFileInLocal,
  handleUploadInDirectAws,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  InventoryRelatedEndPoints,
  OrderRelatedEndPoints,
  StockRelatedEndPoints,
} from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { useSelector } from "react-redux";
import StockConfirmModal from "./StockConfirmModal";

const AddStock = ({
  id,
  handleGetAllStock,
  isManageStock,
  handleGetLogs,
  setEditStock,
  editStock,
  locationData,
  handleGetAllVendor,
  vendorLoader,
  allVendors,
  itemName,
  setVendorDetails,
  setaddStockRes,
}) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [allMaterial, setAllMaterial] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectItem, setSelectItem] = useState("");
  const [inventoryId, setInventoryId] = useState("");
  const [price, setPrice] = useState();
  const [pricePerUnit, setPricePerUnit] = useState("");
  const [quantity, setQuantity] = useState("");
  const [description, setDescription] = useState("");
  const [unit, setUnit] = useState("");
  const [unitId, setUnitId] = useState("");
  const [selectType, setSelectType] = useState("in");
  const [stockOutPrice, setStockOutPrice] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [reasonType, setReasonType] = useState("Select Reason");
  const [confirmStock, setConfirmStock] = useState(false);
  const [image1, setimage1] = useState("");
  const [image2, setimage2] = useState("");
  const [image3, setimage3] = useState("");

  const filteredInvItems = filterSearchData(allMaterial, filter);

  //Select Vendor
  const [selectedVendor, setselectedVendor] = useState("");
  const [vendorData, setvendorData] = useState("");
  const [vendorfFlter, setVendorFilter] = useState("");
  const filteredVendors = filterSearchData(allVendors, vendorfFlter);

  //Select Order
  const [allOrders, setAllOrders] = useState([]);
  const [selectOrder, setSelectOrder] = useState("");
  const [orderData, setOrderData] = useState("");
  const [orderFilter, setOrderFilter] = useState("");
  const [orderLoader, setOrderLoader] = useState(false);
  const filteredOrder = allOrders?.filter((order) =>
    order?.orderNumber?.toString().padStart(3, "0").includes(orderFilter)
  );
  const [allProducts, setAllProducts] = useState([]);
  const [selectProduct, setSelectProduct] = useState("");

  const handlePreviewFileInLocalEdit = (image) => {
    if (image) {
      const img = image;
      return img;
    }
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    setConfirmStock(true);
  };

  useEffect(() => {
    if (editStock) {
      setSelectCategory(editStock?.stock?.itemId?.category);
      setSelectItem(editStock?.stock?.itemId?.name);
      setInventoryId(editStock?.stock?.itemId?._id);
      setQuantity(editStock?.stock?.quantity?.toFixed(1));
      setPrice(editStock?.stock?.price?.toFixed(2));
      setUnit(editStock?.stock?.unit?.name);
      setBillNumber(editStock?.stock?.stockId?.billNumber);
      setvendorData(editStock?.stock?.stockId?.vendorId?.name);
      setselectedVendor(editStock?.stock?.stockId?.vendorId?._id);
      setimage1(editStock?.data?.images?.[0]);
      setimage2(editStock?.data?.images?.[1]);
      setimage3(editStock?.data?.images?.[2]);
      setDescription(editStock?.data?.description);
      if (editStock?.stock?.stockId?.isAdded) {
        setSelectType("in");
      } else {
        setSelectType("out");
      }
    }
  }, [editStock]);

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setPrice("");
    setQuantity("");
    setSelectCategory("");
    setSelectItem("");
    setBillNumber("");
    setDescription("");
    setUnit("");
    setEditStock("");
    setSelectType("in");
    setStockOutPrice("");
    setvendorData("");
    setselectedVendor("");
    setSelectOrder("");
    setOrderData("");
    setReasonType("Select Reason");
    setSelectProduct("");
    setAllProducts([]);
    setConfirmStock(false);
    setimage1("");
    setimage2("");
    setimage3("");
    const closeModalDom = document.getElementById("add_stock");
    if (closeModalDom) closeModalDom.click();
  };

  useEffect(() => {
    setPricePerUnit(price / quantity || 0);
  }, [quantity, price]);

  const allCategories = [
    {
      name: "Vegetables",
    },
    {
      name: "Non-veg",
    },
    {
      name: "Ingredients",
    },
    {
      name: "Others",
    },
  ];

  const handleGetAllMaterial = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
        category: selectCategory,
      },
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getInventory,
      data
    );
    if (res?.success) {
      setAllMaterial(res?.inventory?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (selectCategory) {
      handleGetAllMaterial();
    }
  }, [selectCategory]);

  useEffect(() => {
    if (selectCategory === "") {
      setUnit("");
      setSelectItem("");
    }
  }, [selectCategory]);

  // ADD STOCK FUNCTION API CALL
  const handleAddStock = async (e) => {
    e.preventDefault();
    let isError = false;
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    if (image1 !== "" && image2 === "" && image3 === "") {
      try {
        const uploaded = await handleUploadInDirectAws(image1);
        if (uploaded.Location) {
          temp.push(uploaded.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    } else if (image1 !== "" && image2 !== "" && image3 === "") {
      try {
        const uploaded1 = await handleUploadInDirectAws(image1);
        const uploaded2 = await handleUploadInDirectAws(image2);
        if (uploaded1.Location && uploaded2?.Location) {
          temp.push(uploaded1.Location, uploaded2.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    } else if (image1 !== "" && image2 !== "" && image3 !== "") {
      try {
        const uploaded1 = await handleUploadInDirectAws(image1);
        const uploaded2 = await handleUploadInDirectAws(image2);
        const uploaded3 = await handleUploadInDirectAws(image3);
        if (uploaded1.Location && uploaded2?.Location && uploaded3.Location) {
          temp.push(uploaded1.Location, uploaded2.Location, uploaded3.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    }
    if (!isError) {
      const data = {
        inventories: [
          {
            itemId: isManageStock ? inventoryId : id,
            quantity,
            price,
            unit: isManageStock ? unitId : locationData?.unitId,
            perUnitPrice: pricePerUnit,
          },
        ],
        isAdded: selectType === "in" ? true : false,
        stockType: "new",
        businessId: getCurrentWorkspaceId(),
        description,
        billNumber,
        transactionType: "single",
        createdBy: user?._id,
        vendorId: selectedVendor,
        totalAmount: price,
        images: temp,
      };
      const res = await ApiCall("post", StockRelatedEndPoints.addStock, data);
      if (res?.success) {
        if (isManageStock) {
          handleGetAllStock();
          setaddStockRes(res.stock);
        } else {
          handleGetLogs();
        }
        toast.success("Add stock successfully!");
        handleReset();
        setVendorDetails(true);
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    }
  };

  // REMOVE STOCK FUNCTION API CALL
  const handleRemoveStock = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      inventoryId: isManageStock ? inventoryId : id,
      quantity,
      stockType: selectType==="in"?"new":"out",
      businessId: getCurrentWorkspaceId(),
      price: stockOutPrice,
      stockOutCase: reasonType,
      orderNumber: orderData?.orderNumber?.toString(),
      productId: selectProduct,
      reason: description,
      createdBy: user?._id,
    };
    const res = await ApiCall("post", StockRelatedEndPoints.removeStock, data);
    if (res?.success) {
      if (isManageStock) {
        handleGetAllStock();
      } else {
        handleGetLogs();
      }
      toast.success("Stock out successfully!");
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // EDIT STOCK FUNCTION API CALL
  const handleEditStock = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      inventoryTransactionId: editStock?.logsId,
      itemId: inventoryId,
      price,
      perUnitPrice: pricePerUnit,
      stockType: selectType==="in"?"new":"out",
      description,
      billNumber,
    };
    const res = await ApiCall("post", StockRelatedEndPoints.editStock, data);
    if (res?.success) {
      if (isManageStock) {
        handleGetAllStock();
      } else {
        handleGetLogs();
      }
      toast.success("Edit stock successfully!");
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // GET ALL ORDERS FUNCTION API CALL
  const handleGetOrders = async () => {
    startApiCall(null, setOrderLoader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "pending",
        isApproved:"approved"
      },
      page: 1,
      limit: 100,
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.getOrders, data);
    if (res?.success) {
      setAllOrders(res?.orders?.data);
      setOrderLoader(false);
    } else {
      setOrderLoader(false);
    }
  };

  return (
    <div
      id="add_stock"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editStock === "" ? "Add / Reduce " : "Edit"} Stock
            </h5>
            <div className="border-bottom mb-3 p-0"></div>
            <form
              onSubmit={(e) => {
                if (editStock === "") {
                  // if (selectType === "in") {
                  //   handleAddStock(e);
                  // } else {
                  //   handleRemoveStock(e);
                  // }
                  handleConfirm(e);
                } else {
                  handleEditStock(e);
                }
              }}
            >
              <div className="mh-500p overflow-auto">
                <div className="row gx-3 px-3 w-100">
                  <div className="d-flex justify-content-start gap-2 mb-3 mt-2">
                    <button
                      type="button"
                      className={`border rounded-4 p-2 bg-white ${
                        selectType === "in" ? "border-primary" : ""
                      }`}
                      onClick={() => setSelectType("in")}
                    >
                      <span className="border px-2 me-2 bg-success">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="#FFFFFF"
                          className="bi bi-plus-lg mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                      </span>
                      Stock in
                    </button>
                    <button
                      type="button"
                      className={`border rounded-4 p-2 bg-white ${
                        selectType === "out" ? "border-primary" : ""
                      }`}
                      onClick={() => setSelectType("out")}
                      disabled={editStock}
                    >
                      <span className="border px-2 me-2 bg-danger">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          fill="#FFFFFF"
                          className="bi bi-dash-lg mb-1 "
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                          />
                        </svg>
                      </span>
                      Stock out
                    </button>
                  </div>
                  {quantity !== "" && (
                    <div className="d-flex justify-content-center">
                      <div
                        className="alert alert-info text-primary fs-7"
                        role="alert"
                      >
                        Selected Quantity for{" "}
                        {selectType === "in" ? "Stock In" : "Stock Out"} :
                        <strong> {quantity}</strong>
                      </div>
                    </div>
                  )}
                  {selectType === "in" && (
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">
                          Vendor {reuiredField}
                          <span
                            className="pointer ms-2"
                            data-bs-toggle="modal"
                            data-bs-target="#add_vendor"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="primary"
                              class="bi bi-plus-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                            </svg>
                          </span>
                        </label>
                        <div className="selectable-dropdown">
                          <div className="dropdown selectable-dropdown">
                            <span
                              className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-200p ${
                                editStock ? "bg-light text-disabled" : ""
                              }`}
                              aria-expanded="false"
                              type="button"
                              required
                              data-bs-toggle="dropdown"
                              onClick={() => {
                                handleGetAllVendor();
                                setVendorFilter("");
                              }}
                            >
                              {selectedVendor ? (
                                <div className="d-flex justify-content-between">
                                  {vendorData}
                                  {selectedVendor !== "" && !editStock && (
                                    <button
                                      type="button"
                                      className="btn-close btn-sm ms-1"
                                      onClick={() => {
                                        setselectedVendor("");
                                        setVendorFilter("");
                                        setvendorData("");
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  )}
                                </div>
                              ) : (
                                "Select Vendor"
                              )}
                            </span>
                            <div role="menu" className="dropdown-menu ">
                              <form className="d-sm-block d-none" role="search">
                                <input
                                  type="text"
                                  placeholder="Search.."
                                  className="form-control"
                                  value={vendorfFlter}
                                  onChange={(e) =>
                                    setVendorFilter(e.target.value)
                                  }
                                />
                              </form>
                              {vendorLoader ? (
                                <span className="d-flex text-center mt-1">
                                  Loading...
                                </span>
                              ) : (
                                <div
                                  style={{
                                    maxHeight: "30vh",
                                    overflow: "auto",
                                  }}
                                >
                                  {filteredVendors?.map((vendor, index) => (
                                    <div
                                      className="dropdown-item text-capitalize"
                                      key={vendor._id}
                                      onClick={() => {
                                        setselectedVendor(vendor._id);
                                        setvendorData(vendor?.name);
                                      }}
                                    >
                                      {index + 1}. {vendor?.name}
                                    </div>
                                  ))}
                                </div>
                              )}
                              {!vendorLoader &&
                                filteredVendors?.length === 0 && (
                                  <span className="d-flex text-center mt-1">
                                    No Vendors found
                                  </span>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectType === "in" && (
                    <div className="col-sm-6">
                      <label className="form-label">
                        Vendor Id/Bill/Challan {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        value={billNumber}
                        onChange={(e) => setBillNumber(e.target.value)}
                      />
                    </div>
                  )}
                  {selectType === "in" && (
                    <div className="row gx-3">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="form-label">
                            Upload Bill Attachment
                          </label>

                          <div className="d-flex dropify-square gap-3 mt-3">
                            {image1 ? (
                              <>
                                <input
                                  type="file"
                                  name="image"
                                  id="file1"
                                  accept="image/png,image/jpeg"
                                  className="input-file"
                                  disabled={image1}
                                  onChange={(event) => {
                                    setimage1(event);
                                  }}
                                />
                                <label
                                  htmlFor="file1"
                                  className="position-relative pointer"
                                >
                                  <img
                                    htmlFor="file1"
                                    id="local_show_img_1"
                                    src={
                                      editStock
                                        ? handlePreviewFileInLocalEdit(image1)
                                        : handlePreviewFileInLocal(image1)
                                    }
                                    className="dropify-wrapper text-center"
                                    alt="img1"
                                  />
                                  {!editStock && (
                                    <span
                                      className="position-top-end-overflow"
                                      onClick={() => {
                                        setimage1("");
                                        setimage2("");
                                        setimage3("");
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-x"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                      </svg>
                                    </span>
                                  )}
                                </label>
                              </>
                            ) : (
                              <span
                                className={`dropify-wrapper text-center ${
                                  editStock ? "bg-light text-disabled" : ""
                                }`}
                              >
                                <input
                                  type="file"
                                  name="image"
                                  id="file1"
                                  accept="image/png,image/jpeg"
                                  className={`input-file `}
                                  disabled={editStock}
                                  onChange={(event) => {
                                    setimage1(event);
                                  }}
                                />
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-upload"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg>
                                <label
                                  htmlFor="file1"
                                  className="pointer text-center"
                                >
                                  Upload Image
                                </label>
                              </span>
                            )}
                            {image2 ? (
                              <>
                                <input
                                  type="file"
                                  name="image"
                                  id="file2"
                                  accept="image/png,image/jpeg"
                                  className="input-file"
                                  disabled={image2}
                                  onChange={(event) => {
                                    setimage2(event);
                                  }}
                                />
                                <label
                                  htmlFor="file2"
                                  className="position-relative pointer"
                                >
                                  <img
                                    htmlFor="file2"
                                    id="local_show_img_2"
                                    src={
                                      editStock
                                        ? handlePreviewFileInLocalEdit(image2)
                                        : handlePreviewFileInLocal(image2)
                                    }
                                    className="dropify-wrapper text-center"
                                    alt="img2"
                                  />
                                  {!editStock && (
                                    <span
                                      className="position-top-end-overflow"
                                      onClick={() => {
                                        setimage2("");
                                        setimage3("");
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-x"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                      </svg>
                                    </span>
                                  )}
                                </label>
                              </>
                            ) : (
                              <span
                                className={`dropify-wrapper text-center ${
                                  editStock || !image1 ? "bg-light text-disabled" : ""
                                }`}
                              >
                                <input
                                  type="file"
                                  name="image"
                                  id="file2"
                                  accept="image/png,image/jpeg"
                                  className="input-file"
                                  disabled={editStock || !image1}
                                  onChange={(event) => {
                                    setimage2(event);
                                  }}
                                />
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-upload"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg>
                                <label
                                  htmlFor="file2"
                                  className="pointer text-center"
                                >
                                  Upload Image
                                </label>
                              </span>
                            )}
                            {image3 ? (
                              <>
                                <input
                                  type="file"
                                  name="image"
                                  id="file3"
                                  accept="image/png,image/jpeg"
                                  className="input-file"
                                  disabled={image3}
                                  onChange={(event) => {
                                    setimage3(event);
                                  }}
                                />
                                <label
                                  htmlFor="file3"
                                  className="position-relative pointer"
                                >
                                  <img
                                    htmlFor="file3"
                                    id="local_show_img_3"
                                    src={
                                      editStock
                                        ? handlePreviewFileInLocalEdit(image3)
                                        : handlePreviewFileInLocal(image3)
                                    }
                                    className="dropify-wrapper text-center"
                                    alt="img3"
                                  />
                                  {!editStock && (
                                    <span
                                      className="position-top-end-overflow"
                                      onClick={() => {
                                        setimage3("");
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        class="bi bi-x"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                      </svg>
                                    </span>
                                  )}
                                </label>
                              </>
                            ) : (
                              <span
                                className={`dropify-wrapper text-center ${
                                  editStock || !image2 ? "bg-light text-disabled" : ""
                                }`}
                              >
                                <input
                                  type="file"
                                  name="image"
                                  id="file3"
                                  accept="image/png,image/jpeg"
                                  className="input-file"
                                  disabled={editStock || !image2}
                                  onChange={(event) => {
                                    setimage3(event);
                                  }}
                                />
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-upload"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg>
                                <label
                                  htmlFor="file3"
                                  className="pointer text-center"
                                >
                                  Upload Image
                                </label>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {(selectType === "in" || selectType === "out") && (
                    <>
                      {isManageStock && (
                        <>
                          <div className="col-sm-6">
                            <label className="form-label">
                              Category {reuiredField}
                            </label>
                            <div className="form-group">
                              <div className="todo-toolbar">
                                <select
                                  className="form-select form-select-md"
                                  required
                                  disabled={editStock}
                                  value={selectCategory}
                                  onChange={(e) => {
                                    setSelectCategory(e.target.value);
                                  }}
                                >
                                  <option value="">Select Category</option>
                                  {allCategories?.map((cat) => {
                                    return (
                                      <option value={cat?.name}>
                                        {cat?.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <label className="text-dark fw-300 fs-6 mb-2">
                              Item {reuiredField}
                            </label>
                            <div className="form-group">
                              <div className="selectable-dropdown">
                                <div className="dropdown selectable-dropdown">
                                  <span
                                    className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                                      selectCategory === "" || editStock
                                        ? "bg-light text-disabled"
                                        : ""
                                    }`}
                                    aria-expanded="false"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    disabled={selectCategory === ""}
                                    onClick={() => setFilter("")}
                                  >
                                    {selectItem ? (
                                      <>
                                        {selectItem}
                                        {selectItem !== "" && !editStock && (
                                          <button
                                            type="button"
                                            className="btn-close btn-sm ms-2"
                                            onClick={() => {
                                              setSelectItem("");
                                              setFilter("");
                                              setUnit("");
                                            }}
                                            aria-label="Close"
                                          ></button>
                                        )}
                                      </>
                                    ) : (
                                      "Select item"
                                    )}
                                  </span>
                                  <div role="menu" className="dropdown-menu">
                                    <form
                                      className="d-sm-block d-none"
                                      role="search"
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search.."
                                        value={filter}
                                        onChange={(e) =>
                                          setFilter(e.target.value)
                                        }
                                      />
                                    </form>
                                    {loader2 ? (
                                      <span className="d-flex text-center mt-1">
                                        Loading...
                                      </span>
                                    ) : (
                                      <div
                                        style={{
                                          maxHeight: "30vh",
                                          overflow: "auto",
                                        }}
                                      >
                                        {filteredInvItems?.map((inv) => (
                                          <div
                                            className="dropdown-item text-capitalize"
                                            key={inv._id}
                                            onClick={() => {
                                              setSelectItem(inv.name);
                                              setInventoryId(inv?._id);
                                              setUnit(inv?.unit?.name);
                                              setUnitId(inv?.unit?._id);
                                            }}
                                          >
                                            {inv.name}
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                    {!loader2 &&
                                      filteredInvItems?.length === 0 && (
                                        <span className="d-flex text-center mt-1">
                                          No Inventory found
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div
                        className={`${
                          selectType === "in" ? "col-sm-3" : "col-sm-6"
                        }`}
                      >
                        <div className="form-group">
                          <label className="form-label">
                            Quantity {reuiredField}
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            required
                            disabled={editStock}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          selectType === "in" ? "col-sm-3" : "col-sm-6"
                        }`}
                      >
                        <div className="form-group">
                          <label className="form-label">Unit</label>
                          <input
                            className="form-control"
                            type="text"
                            required
                            value={isManageStock ? unit : locationData?.unit}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {selectType === "in" && (
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">
                          Total Cost {reuiredField}
                        </label>
                        <div className="d-flex align-items-center border rounded-5 w-200p text-dark ">
                          <span className="d-flex align-items-center justify-content-center py-1 px-2 fs-7">
                            INR ₹
                          </span>

                          <div className="d-flex align-items-center justify-content-between w-140p py-1 border-start px-2">
                            <input
                              className="form-control"
                              type="number"
                              required
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectType === "out" && (
                    <div className="col-sm-6">
                      <label className="form-label">Approx. price</label>

                      <div className="d-flex align-items-center border rounded-5 w-200p text-dark">
                        <span className="d-flex align-items-center justify-content-center py-1 px-2 fs-7">
                          INR ₹
                        </span>

                        <div className="d-flex align-items-center justify-content-between w-140p py-1 border-start px-2">
                          <input
                            className="form-control"
                            type="number"
                            value={stockOutPrice}
                            onChange={(e) => setStockOutPrice(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {selectType === "out" && (
                    <>
                      <div className="col-sm-6">
                        <label className="form-label">
                          Reason type {reuiredField}
                        </label>
                        <div className="form-group">
                          <div className="todo-toolbar">
                            <select
                              className="form-select form-select-md"
                              required
                              disabled={editStock}
                              value={reasonType}
                              onChange={(e) => {
                                setReasonType(e.target.value);
                                if (
                                  reasonType === "expired" ||
                                  reasonType === "cancelled" ||
                                  reasonType === "Select Reason"
                                ) {
                                  setOrderData("");
                                  setSelectOrder("");
                                  setSelectProduct("");
                                  setAllProducts([]);
                                }
                              }}
                            >
                              <option value="Select Reason">
                                Select Reason
                              </option>
                              <option value="customized">Customization</option>
                              <option value="expired">Expired / Wastage</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {reasonType === "customized" && (
                        <>
                          <div className="col-sm-6 mt-2">
                            <div className="form-group">
                              <label className="form-label">
                                Select Order {reuiredField}
                              </label>
                              <div className="selectable-dropdown">
                                <div className="dropdown selectable-dropdown">
                                  <span
                                    className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-200p ${
                                      editStock ? "bg-light text-disabled" : ""
                                    }`}
                                    aria-expanded="false"
                                    type="button"
                                    required
                                    data-bs-toggle="dropdown"
                                    onClick={() => {
                                      handleGetOrders();
                                      setOrderFilter("");
                                    }}
                                  >
                                    {selectOrder ? (
                                      <div className="d-flex justify-content-between">
                                        {orderData?.orderNumber
                                          ?.toString()
                                          .padStart(3, "0")}{" "}
                                        {`(₹ ${orderData?.subTotal})`}
                                        {selectOrder !== "" && !editStock && (
                                          <button
                                            type="button"
                                            className="btn-close btn-sm ms-1"
                                            onClick={() => {
                                              setSelectOrder("");
                                              setOrderFilter("");
                                              setOrderData("");
                                              setAllProducts([]);
                                            }}
                                            aria-label="Close"
                                          ></button>
                                        )}
                                      </div>
                                    ) : (
                                      "Select Order"
                                    )}
                                  </span>
                                  <div role="menu" className="dropdown-menu ">
                                    <form
                                      className="d-sm-block d-none"
                                      role="search"
                                    >
                                      <input
                                        type="text"
                                        placeholder="Search.."
                                        className="form-control"
                                        value={orderFilter}
                                        onChange={(e) =>
                                          setOrderFilter(e.target.value)
                                        }
                                      />
                                    </form>
                                    {orderLoader ? (
                                      <span className="d-flex text-center mt-1">
                                        Loading...
                                      </span>
                                    ) : (
                                      <div
                                        style={{
                                          maxHeight: "30vh",
                                          overflow: "auto",
                                        }}
                                      >
                                        {filteredOrder?.map((order, index) => (
                                          <div
                                            className="dropdown-item text-capitalize"
                                            key={order._id}
                                            onClick={() => {
                                              setSelectOrder(order._id);
                                              setOrderData(order);
                                              setAllProducts(order?.products);
                                            }}
                                          >
                                            {index + 1}.{" "}
                                            {order?.orderNumber
                                              ?.toString()
                                              .padStart(3, "0")}{" "}
                                            {`(₹ ${order?.subTotal})`}
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                    {!orderLoader &&
                                      filteredOrder?.length === 0 && (
                                        <span className="d-flex text-center mt-1">
                                          No Orders found
                                        </span>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 mt-2">
                            <label className="form-label">
                              Dish {reuiredField}
                            </label>
                            <div className="form-group">
                              <div className="todo-toolbar">
                                <select
                                  className="form-select form-select-md"
                                  required
                                  disabled={editStock || orderData === ""}
                                  value={selectProduct}
                                  onChange={(e) => {
                                    setSelectProduct(e.target.value);
                                  }}
                                >
                                  <option value="">Choose Dish</option>
                                  {allProducts?.map((pro) => {
                                    return (
                                      <option value={pro?.productId?._id}>
                                        {pro?.productId?.productName}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {(selectType === "in" || selectType === "out") && (
                    <div className="col-md-12 mt-2">
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label">
                            {selectType === "out" ? "Reason" : "Description"}{" "}
                            {selectType === "out" ? reuiredField : ""}{" "}
                          </label>
                        </div>
                        <textarea
                          className="form-control"
                          rows="3"
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          value={description}
                          maxLength={100}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !quantity ||
                    selectType === "" ||
                    (selectType === "in" &&
                      !selectedVendor &&
                      billNumber === "") ||
                    (selectType === "out" && !description) ||
                    (reasonType === "customized" &&
                      !selectOrder &&
                      !selectProduct)
                  }
                >
                  {loader && editStock !== "" ? (
                    <ApiLoader />
                  ) : editStock === "" ? (
                    "Save"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
          {confirmStock && (
            <StockConfirmModal
              setConfirmStock={setConfirmStock}
              handleAddStock={handleAddStock}
              handleRemoveStock={handleRemoveStock}
              quantity={quantity}
              selectType={selectType}
              selectItem={selectItem ? selectItem : itemName}
              loader={loader}
              errorMessage={errorMessage}
              setQuantity={setQuantity}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddStock;
