import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToCreateBills } from "../../../Navigation/Navigation";

const BillsHeader = ({
  search,
  setPage,
  setsearch,
  handleGetAllBills,
  filterType,
  handleFilterType,
  setdateModal,
  setdueDate,
  setstartDate,
  setendDate,
}) => {
  const navigate = useNavigate();
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1>All Bills</h1>
          {/* {isModuleReadWriteAccessInvoice && ( */}
          <div className="ms-3">
            <button
              className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
              onClick={() => {
                navigate(navigateToCreateBills());
              }}
            >
              Create Bills
            </button>
          </div>
          {/* )} */}
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="selectable-dropdown me-2">
          <div className="dropdown selectable-dropdown">
            <span
              className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Filter :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp;{" "}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    filterType === "date" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleFilterType("date");
                    setdateModal(true);
                    setdueDate("");
                  }}
                >
                  1. Date Range
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    filterType === "duedate" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleFilterType("duedate");
                    setdateModal(true);
                    setstartDate("");
                    setendDate("");
                  }}
                >
                  2. Due Date
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllBills}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setPage(1);
              setsearch(e.target.value);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default BillsHeader;
