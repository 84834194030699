import React, { useState } from "react";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import allCountries from "../../../components/Countries/Countries";
import { reuiredField } from "../../../global-functions/globalFunctions";

const VendorAddress = (props) => {
  const {
    selectSetup1,
    billingAddress,
    handleChangeBillingAddress,
    setBillingAddress,
    shippingAddress,
    handleChangeShippingAddress,
    loader,
    errorMessage,
    editVendor,
    handleEditVendor,
    handleAddVendor,
    setShippingAddress,
    isSameAddress,
    setIsSameAddress,
  } = props;
  const [countryFilter, setcountryFilter] = useState("");
  const [shippingCountryFilter, setshippingCountryFilter] = useState("");

  const billingFilteredCountries = allCountries?.filter((country) =>
    country?.country?.toLowerCase()?.includes(countryFilter?.toLowerCase())
  );
  const shippingFilteredCountries = allCountries?.filter((country) =>
    country?.country
      ?.toLowerCase()
      ?.includes(shippingCountryFilter?.toLowerCase())
  );

  const handleCheckboxChange = () => {
    setIsSameAddress((prevIsSameAddress) => !prevIsSameAddress);

    if (!isSameAddress) {
      setShippingAddress({
        country: billingAddress.country,
        city: billingAddress.city,
        zipCode: billingAddress.zipCode,
        address: billingAddress.address,
      });
    } else {
      setShippingAddress({
        country: "",
        city: "",
        zipCode: "",
        address: "",
      });
    }
  };

  return (
    <>
      <div className="border rounded-7 p-3 w-100">
        <form
          className="w-100 d-flex justify-content-center"
          onSubmit={(e) => {
            if (editVendor !== "") {
              handleEditVendor(e);
            } else {
              handleAddVendor(e);
            }
          }}
        >
          <div className="mh-500p nicescroll-bar simple-scrollbar">
            <div className="row w-100">
              <div className="col-lg-6">
                <p className="text-dark fw-semibold mb-2">Billing Address</p>
                <div className="d-flex flex-column">
                  <div className="form-group row w-100">
                    <div className="col-sm-4">
                      <label className="form-label">
                        Country {reuiredField}
                      </label>
                    </div>
                    <div className="col-sm-8">
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={
                              "me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-190p "
                            }
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => setcountryFilter("")}
                          >
                            {billingAddress.country ? (
                              <div className="d-flex justify-content-between">
                                {billingAddress.country}
                                {billingAddress.country !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-1"
                                    onClick={() => {
                                      setBillingAddress({
                                        ...billingAddress,
                                        country: "",
                                      });
                                      setcountryFilter("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Country"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu ">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                placeholder="Search.."
                                className="form-control"
                                value={countryFilter}
                                onChange={(e) =>
                                  setcountryFilter(e.target.value)
                                }
                              />
                            </form>
                            <div
                              style={{ maxHeight: "12rem", overflow: "auto" }}
                            >
                              {billingFilteredCountries?.map((country) => (
                                <div
                                  className="dropdown-item text-capitalize"
                                  key={country.country}
                                  onClick={() => {
                                    setBillingAddress({
                                      ...billingAddress,
                                      country: country.country,
                                    });
                                  }}
                                >
                                  {country?.country}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <input
                        className="form-control"
                        type="text"
                        name="country"
                        required
                        value={billingAddress.country}
                        onChange={handleChangeBillingAddress}
                      /> */}
                    </div>
                  </div>
                  <div className="form-group row w-100">
                    <div className="col-sm-4">
                      <label className="form-label">City {reuiredField}</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        name="city"
                        required
                        disabled={!billingAddress.country}
                        value={billingAddress.city}
                        onChange={handleChangeBillingAddress}
                        placeholder="Enter City"
                      />
                    </div>
                  </div>
                  <div className="form-group row w-100">
                    <div className="col-sm-4">
                      <label className="form-label">
                        Zip code {reuiredField}
                      </label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        name="zipCode"
                        required
                        disabled={!billingAddress.city}
                        value={billingAddress.zipCode}
                        onChange={handleChangeBillingAddress}
                        placeholder="Enter Zip Code"
                      />
                    </div>
                  </div>
                  <div className="form-group row w-100">
                    <div className="col-sm-4">
                      <label className="form-label">
                        Address {reuiredField}
                      </label>
                    </div>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        rows="2"
                        name="address"
                        required
                        disabled={!billingAddress.zipCode}
                        value={billingAddress.address}
                        onChange={handleChangeBillingAddress}
                        placeholder="Enter Address"
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input pointer"
                      type="checkbox"
                      checked={isSameAddress}
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label">
                      Billing and shipping address is same
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <p className="text-dark fw-semibold mb-2">Shipping Address</p>
                <div className="d-flex flex-column">
                  <div className="form-group row w-100">
                    <div className="col-sm-4">
                      <label className="form-label">
                        Country {reuiredField}
                      </label>
                    </div>
                    <div className="col-sm-8">
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={
                              "me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-190p "
                            }
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => setshippingCountryFilter("")}
                          >
                            {shippingAddress.country ? (
                              <div className="d-flex justify-content-between">
                                {shippingAddress.country}
                                {shippingAddress.country !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-1"
                                    onClick={() => {
                                      setShippingAddress({
                                        ...billingAddress,
                                        country: "",
                                      });
                                      setshippingCountryFilter("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Country"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu ">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                placeholder="Search.."
                                className="form-control"
                                value={shippingCountryFilter}
                                onChange={(e) =>
                                  setshippingCountryFilter(e.target.value)
                                }
                              />
                            </form>
                            <div
                              style={{ maxHeight: "12rem", overflow: "auto" }}
                            >
                              {shippingFilteredCountries?.map((country) => (
                                <div
                                  className="dropdown-item text-capitalize"
                                  key={country.country}
                                  onClick={() => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      country: country.country,
                                    });
                                  }}
                                >
                                  {country?.country}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row w-100">
                    <div className="col-sm-4">
                      <label className="form-label">City {reuiredField}</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        name="city"
                        disabled={isSameAddress || !shippingAddress.country}
                        value={shippingAddress.city}
                        onChange={handleChangeShippingAddress}
                        placeholder="Enter City"
                      />
                    </div>
                  </div>
                  <div className="form-group row w-100">
                    <div className="col-sm-4">
                      <label className="form-label">
                        Zip code {reuiredField}
                      </label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        name="zipCode"
                        disabled={isSameAddress || !shippingAddress.city}
                        value={shippingAddress.zipCode}
                        onChange={handleChangeShippingAddress}
                        placeholder="Enter Zip Code"
                      />
                    </div>
                  </div>
                  <div className="form-group row w-100">
                    <div className="col-sm-4">
                      <label className="form-label">
                        Address {reuiredField}
                      </label>
                    </div>
                    <div className="col-sm-8">
                      <textarea
                        className="form-control"
                        rows="2"
                        name="address"
                        disabled={isSameAddress || !shippingAddress.zipCode}
                        value={shippingAddress.address}
                        onChange={handleChangeShippingAddress}
                        placeholder="Enter Address"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex align-items-center justify-content-between pointer">
                <span className="d-flex align-items-center gap-1 text-dark">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-chevron-double-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                  <span onClick={selectSetup1}>Previous</span>
                </span>
                <div className="d-flex align-items-center justify-content-end mt-2">
                  <button className="btn btn-sm btn-primary px-3">
                    {loader ? (
                      <ApiLoader />
                    ) : editVendor === "" ? (
                      "Add Vendor"
                    ) : (
                      "Edit Vendor"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default VendorAddress;
