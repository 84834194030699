import React, { useEffect } from "react";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

function OrderConfirmModal(props) {
  const {
    uniqueItems,
    payableAmount,
    subTotal,
    customerData,
    handleCreateOrder,
    loader3,
    errorMessage,
    handleReset,
    customerType,
    allChargesData,
    setpayableAmount,
    updateValueForItem,
    locationData,
    handleEditOrder,
  } = props;

  useEffect(() => {
    let totalAmountOnly = 0;
    let totalPercentageAmountOnly = parseInt(subTotal);

    allChargesData?.map((ch) => {
      if (ch.chargeType === "percentage") {
        if (ch.add) {
          totalPercentageAmountOnly += (subTotal * ch.value) / 100;
        } else {
          totalPercentageAmountOnly -= (subTotal * ch.value) / 100;
        }
      } else {
        if (ch.add) {
          totalAmountOnly += parseInt(ch.value);
        } else {
          totalAmountOnly -= parseInt(ch.value);
        }
      }
    });
    let finalAmount = parseInt(totalAmountOnly + totalPercentageAmountOnly);
    setpayableAmount(finalAmount.toFixed(2));
  }, [allChargesData, subTotal]);

  const handleConfirm = (e) => {
    e.preventDefault();
  };

  return (
    <div
      id="check_order_modal"
      className="modal fade add-new-task"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-625p"
        role="document"
      >
        <div className="modal-content px-3">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Check order</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                // if (locationData) {
                //   handleEditOrder(e);
                // } else {
                //   handleCreateOrder(e);
                // }
                handleConfirm(e);
              }}
            >
              <div className="d-flex flex-column gap-3 mb-3">
                <div className="text-dark">
                  <p className="text-capitalize">
                    Customer Name :{" "}
                    {customerType === "guest" ? "Guest" : customerData?.name}
                  </p>
                  {customerType !== "guest" && (
                    <>
                      <p>
                        Customer Number :{" "}
                        {customerType === "guest" ? "-" : customerData?.phone}{" "}
                      </p>
                      {customerData?.email && (
                        <p>
                          Customer Email :{" "}
                          {customerType === "guest" ? "-" : customerData?.email}{" "}
                        </p>
                      )}
                    </>
                  )}
                </div>
                <div
                  className="border simple-scrollbar"
                  style={{ overflowY: "scroll", height: "194px" }}
                >
                  <table className="table text-center mb-0 vertical-align-top">
                    <thead>
                      <tr>
                        <th className="text-start">No.</th>
                        <th className="text-start">Item</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uniqueItems?.map((item, index) => {
                        return (
                          <tr>
                            <td className="text-start">{index + 1}</td>
                            <td className="text-start">{item?.productName}</td>
                            <td>{item?.count}</td>
                            <td>
                              <span className="nowrap">
                                ₹ {item?.sellingPrice}
                              </span>
                            </td>
                            <td>
                              <span className="nowrap">
                                ₹ {item?.itemPrice}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="py-2 px-3" style={{ background: "#E3EDFF" }}>
                  <div className="d-flex justify-content-between text-dark">
                    <p>Subtotal :</p>
                    <p>₹ {subTotal}</p>
                  </div>
                  {allChargesData?.length > 0 && (
                    <div
                      className="d-flex flex-column gap-1 pt-1 pb-1 overflow-y-scroll"
                      style={{ height: "20vh" }}
                    >
                      {allChargesData?.map((charge) => {
                        return (
                          <div
                            className="d-flex justify-content-between border mt-1 py-1 px-2 h-40p"
                            style={{ background: "#EAF1FF" }}
                          >
                            <p className="mt-1 fs-7 text-capitalize">
                              (<span>{charge?.add ? "+" : "-"}</span>){" "}
                              {charge?.chargeName} &nbsp;
                              {charge?.chargeType === "percentage" ? "%" : "₹"}
                            </p>
                            <span className="d-flex gap-2">
                              <input
                                className="form-control w-100p"
                                type="number"
                                min="0"
                                value={charge?.value}
                                onChange={(e) =>
                                  updateValueForItem(
                                    charge?._id,
                                    e.target.value
                                  )
                                }
                              />
                              {/* <span
                            className="mt-1 text-danger pointer"
                            onClick={() => toggleCancelIcon("packingCharges")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                          </span> */}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="d-flex justify-content-between text-dark">
                    <p>Amount Payable : </p>
                    <p>₹ {payableAmount}</p>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary"
                  // disabled={loader3}
                  data-bs-toggle="modal"
                  data-bs-target="#add_table_data"
                >
                  {/* {loader3 ? <ApiLoader /> : "Confirm order"} */}
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderConfirmModal;
