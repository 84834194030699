import React, { useEffect, useState } from "react";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import { getCurrentWorkspaceId } from "../../../../global-functions/globalFunctions";
import { LeadRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import { toast } from "react-toastify";

const CrmLeadGenerationTarget = ({
  businessDetails,
  handleGetBusinessDetails,
  isModuleReadWriteAccessForSetting,
}) => {
  const [targets, setTargets] = useState({
    lead: 100,
    deal: 100,
    contact: 100,
  });
  const [disabledStates, setDisabledStates] = useState({
    lead: true,
    deal: true,
    contact: true,
  });
  const [loader, setLoader] = useState({
    lead: false,
    deal: false,
    contact: false,
  });

  const handleInputChange = (field, value) => {
    setTargets((prevTargets) => ({ ...prevTargets, [field]: value }));
  };

  const toggleDisabledState = (field) => {
    setDisabledStates((prevStates) => ({
      ...prevStates,
      [field]: !prevStates[field],
    }));
  };

  const handleAddEditLeadSource = async (type) => {
    setLoader((prevLoader) => ({ ...prevLoader, [type]: true }));
    setTimeout(() => {
      setLoader((prevLoader) => ({ ...prevLoader, [type]: false }));
    }, 50000);
    if (targets[type] < 100) {
      toast.error("Target value should be more than 100.");
      setLoader((prevLoader) => ({ ...prevLoader, [type]: false }));
    } else {
      const sendData = {
        businessId: getCurrentWorkspaceId(),
        type,
        target: targets[type],
      };
      const res = await ApiCall(
        "post",
        LeadRelatedEndPoints.generationTarget,
        sendData
      );
      if (res?.success) {
        toast.success("Target added successfully!");
        handleGetBusinessDetails();
        setLoader((prevLoader) => ({ ...prevLoader, [type]: false }));
        setDisabledStates((prevStates) => ({
          ...prevStates,
          [type]: true,
        }));
      } else {
        setLoader((prevLoader) => ({ ...prevLoader, [type]: false }));
      }
    }
  };

  useEffect(() => {
    setTargets({
      lead: businessDetails?.crmGenerationTarget?.lead,
      deal: businessDetails?.crmGenerationTarget?.deal,
      contact: businessDetails?.crmGenerationTarget?.contact,
    });
  }, [businessDetails]);

  return (
    <div className="contact-list-view">
      <div className="container py-4">
        <div className="card">
          <div className="card-body d-flex justify-content-center">
            {!disabledStates.lead ? (
              <div className="row g-5">
                <div className="col-12 d-flex gap-2 justify-content-center">
                  <div className="form-group">
                    <label className="form-label">Lead Generation Target</label>

                    <input
                      className="form-control"
                      type="number"
                      disabled={""}
                      value={targets.lead}
                      onChange={(e) =>
                        handleInputChange("lead", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => toggleDisabledState("lead")}
                  >
                    Close
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleAddEditLeadSource("lead")}
                  >
                    {loader.lead ? <ApiLoader /> : "Save"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center flex-column">
                {" "}
                <span className="h-80p w-140p bg-light rounded-10 d-flex align-items-center justify-content-center position-relative">
                  <h4 className="fs-4 fw-semibold text-dark mb-0">
                    {targets?.lead}
                  </h4>
                  {isModuleReadWriteAccessForSetting && (
                    <span className="position-top-end-overflow bg-white rounded-circle">
                      <span
                        className="btn btn-sm btn-icon btn-rounded btn-outline-secondary"
                        onClick={() => toggleDisabledState("lead")}
                      >
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                  )}
                </span>
                <h5 class="card-title mt-2">Lead Generation Target</h5>
              </div>
            )}
          </div>
        </div>
        <div className="card">
          <div className="card-body d-flex justify-content-center">
            {!disabledStates.deal ? (
              <div className="row g-5">
                <div className="col-12 d-flex gap-2 justify-content-center">
                  <div className="form-group">
                    <label className="form-label">Deal Generation Target</label>
                    <input
                      className="form-control"
                      type="number"
                      disabled={""}
                      value={targets.deal}
                      onChange={(e) =>
                        handleInputChange("deal", e.target.value)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => toggleDisabledState("deal")}
                  >
                    Close
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleAddEditLeadSource("deal")}
                  >
                    {loader.deal ? <ApiLoader /> : "Save"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center flex-column">
                <span className="h-80p w-140p bg-light rounded-10 d-flex align-items-center justify-content-center position-relative">
                  <h4 className="fs-4 fw-semibold text-dark mb-0">
                    {targets?.deal}
                  </h4>
                  {isModuleReadWriteAccessForSetting && (
                    <span className="position-top-end-overflow bg-white rounded-circle">
                      <span
                        className="btn btn-sm btn-icon btn-rounded btn-outline-secondary"
                        onClick={() => toggleDisabledState("deal")}
                      >
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                  )}
                </span>
                <h5 class="card-title mt-2">Deal Generation Target</h5>
              </div>
            )}
          </div>
        </div>
        <div className="card">
          <div className="card-body d-flex justify-content-center">
            {!disabledStates.contact ? (
              <div className="row g-5">
                <div className="col-12 d-flex justify-content-center gap-2">
                  <div className="form-group ">
                    <label className="form-label">
                      {" "}
                      Contact Generation Target
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      disabled={""}
                      value={targets.contact}
                      onChange={(e) =>
                        handleInputChange("contact", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => toggleDisabledState("contact")}
                  >
                    Close
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleAddEditLeadSource("contact")}
                  >
                    {loader.contact ? <ApiLoader /> : "Save"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center flex-column">
                <span className="h-80p w-140p bg-light rounded-10 d-flex align-items-center justify-content-center position-relative">
                  <h4 className="fs-4 fw-semibold text-dark mb-0">
                    {targets?.contact}
                  </h4>
                  {isModuleReadWriteAccessForSetting && (
                    <span className="position-top-end-overflow bg-white rounded-circle">
                      <span
                        className="btn btn-sm btn-icon btn-rounded btn-outline-secondary"
                        onClick={() => toggleDisabledState("contact")}
                      >
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </span>
                      </span>
                    </span>
                  )}
                </span>
                <h5 class="card-title mt-2">Contact Generation Target</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmLeadGenerationTarget;
