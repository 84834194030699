import React from "react";
import DataLoader from "../../../../../components/Loaders/DataLoader/DataLoader";
import {
  dateAndTimeFormatter,
  dateFormatter,
  getName,
  getTimeFormatter,
} from "../../../../../global-functions/globalFunctions";
import NoDataWithoutImage from "../../../../../components/NoData/NoDataWithoutImage";
import FooterPagination from "../../../../../components/Pagination/FooterPagination";
import { useSelector } from "react-redux";
import { Avatar } from "../../../../../components/Avatar/Avatar";

const TimelineCard = ({
  allLogs = [],
  loader,
  allEvents,
  loader2,
  setpageLimit,
  pageLimit,
  setPage,
  page,
  totalPages,
  count,
  eventPage,
  seteventPage,
  eventPageLimit,
  seteventPageLimit,
  eventCount,
  totaleventPages,
}) => {
  const user = useSelector((state) => state.user);
  // ******* DATE *********
  var date = new Date();
  var yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  const groupedLogs = allLogs?.reduce((groups, log) => {
    const createdAtDate = new Date(log?.date).toDateString();
    if (!groups[createdAtDate]) {
      groups[createdAtDate] = [];
    }
    groups[createdAtDate].push(log);
    return groups;
  }, {});

  return (
    <div className="d-flex gap-2">
      <div className="col-sm-7">
        <div className="card card-border px-3 py-3" style={{ height: "81vh" }}>
          <span className="text-dark fs-5">History </span>
          <div className="d-flex flex-column gap-3">
            {loader && <DataLoader title="Logs is loading..." />}
            {!loader &&
              Object?.entries(groupedLogs)?.map(
                ([createdAtDate, logsForDate]) => (
                  <>
                    <button className="btn btn-primary btn-md text-white w-120p mt-3 mb-3">
                      {dateFormatter(createdAtDate) === dateFormatter(date)
                        ? "Today"
                        : dateFormatter(createdAtDate) ===
                          dateFormatter(yesterday)
                        ? "Yesterday"
                        : dateFormatter(createdAtDate)}
                    </button>
                    <div className="timeline">
                      {logsForDate?.map((data) => (
                        <div className="px-3 py-2">
                          <div className="d-flex align-items-center">
                            <span className="timeline-item-icon | faded-icon">
                              {data?.logType === "call" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  fill="currentColor"
                                  className="bi bi-telephone text-primary"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                </svg>
                              ) : data?.logType === "meeting" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  fill="currentColor"
                                  class="bi bi-people-fill text-primary"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                                </svg>
                              ) : data?.logType === "task" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="text-primary"
                                >
                                  <path d="M19 4H5V20H19V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H19.9997C20.5519 2 20.9996 2.44772 20.9997 3L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM11.2929 13.1213L15.5355 8.87868L16.9497 10.2929L11.2929 15.9497L7.40381 12.0607L8.81802 10.6464L11.2929 13.1213Z"></path>
                                </svg>
                              ) : data?.logType === "email" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  fill="currentColor"
                                  className="bi bi-envelope text-primary"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="text-primary"
                                >
                                  <path d="M3 3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V4C22 3.44772 21.5523 3 21 3H3ZM4 19V5H20V19H4ZM14 7H6V9H14V7ZM18 15V17H10V15H18ZM16 11H8V13H16V11Z"></path>
                                </svg>
                              )}
                            </span>
                            <p className="mb-0 ms-3">
                              {dateAndTimeFormatter(data?.createdAt)}
                            </p>
                          </div>
                          <div className="ms-1 text-capitalize text-dark">
                            {data?.logType === "status"
                              ? `${data?.text} to`
                              : data?.text}
                            <span className="text-primary ms-1">
                              {data?.logType === "status"
                                ? data?.statusId?.name
                                : data?.logType === "task"
                                ? data?.taskId?.title
                                : data?.logType === "meeting"
                                ? data?.taskId?.title
                                : data?.taskId?.title}
                            </span>
                          </div>
                          <p className="ms-1 text-primary font-3 gap-2">
                            Action by : {" "}
                            <Avatar
                              name={data?.actionBy?.name}
                              count={1}
                              image={data?.actionBy?.image}
                              size="xss"
                              color="primary"
                            />
                            {getName(data?.actionBy, user?._id)}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )
              )}
          </div>
          {!loader && allLogs?.length > 0 && (
            <FooterPagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setPage}
              page={page}
              totalPages={totalPages}
              count={count}
              dataLength={allLogs?.length}
              align={"end"}
            />
          )}
        </div>
      </div>
      <div className="col-sm-5">
        <div className="card card-border px-3 py-3" style={{ height: "81vh" }}>
          <span className="text-dark fs-5">Schedule Event</span>
          {!loader2 && (
            <div className="d-flex flex-column gap-3">
              {allEvents?.map((data) => {
                return (
                  <>
                    <div className="d-flex">
                      <div className="card card-border bg-primary text-white mt-3 py-2 px-5 w-100p">
                        <p className="d-flex justify-content-center">
                          {dateFormatter(
                            data?.taskType === "call"
                              ? data?.scheduledate
                              : data?.taskType === "meeting"
                              ? data?.startTime
                              : data?.createdAt
                          )}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <span className="mt-3 text-dark ms-2 text-capitalize fw-semibold">
                          {data?.taskType}
                          <p className="fw-light">{data?.title}</p>
                        </span>
                        <span className="mt-4 text-dark ms-2 justify-content-end">
                          {data?.startTime
                            ? getTimeFormatter(data?.startTime)
                            : ""}
                          <p className="text-capitalize">
                            Priority : {data?.priority}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div
                      className="border-bottom"
                      style={{ marginTop: -10 }}
                    ></div>
                  </>
                );
              })}
            </div>
          )}

          {loader2 && <DataLoader />}
          {!loader2 && allEvents?.length === 0 && (
            <NoDataWithoutImage title="No schedule event" />
          )}

          {!loader2 && allEvents?.length > 0 && (
            <FooterPagination
              setpageLimit={seteventPageLimit}
              pageLimit={eventPageLimit}
              setPage={seteventPage}
              page={eventPage}
              totalPages={totaleventPages}
              count={eventCount}
              dataLength={allEvents?.length}
              align={"end"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TimelineCard;
