import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ManagePeople from "../../pages/Settings/ManagePeople/ManagePeople";

const ManagePeopleRoutes = {
  path: "/settings",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <ManagePeople />,
    },
    {
      path: "managepeople",
      element: <ManagePeople />,
    },
  ],
};

export default ManagePeopleRoutes;
