import React from 'react'
import { Link } from 'react-router-dom'
import Success from '../../assets/images/success_join.jpeg'

const SuccessfullyJoined = ({businessName}) => {
  return (
    <div className="w-100 mnh-100vh mt-10">
    <div className="container">
      <div className="d-flex flex-column align-items-center pt-8">
        <div className="circle p-10 bg-blue-light-5 w-400p h-400p">
          <img className="w-100" src={Success} alt="" />
        </div>
        <p className="fs-2 fw-medium text-dark mt-3 mb-2" style={{textAlign:"center"}}>Congratulations. You've joined {businessName}</p>
        {/* <p className="w-70" style={{textAlign:"center"}}>
          Hi, there your token token link has expired, because haven't use it.
          Token link expires in every 24 hours and can only be used once. You
          can create one by clicking on Request button too.
        </p> */}
        <Link to="/home" className="btn btn-rounded btn-primary py-2 px-8 my-5">
        Back to home
        </Link>
      </div>
    </div>
  </div>
  )
}

export default SuccessfullyJoined