import React from "react";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";

const MyComplaintSkeleton = () => {
  return (
    <div className="d-flex flex-column gap-3">
      <div className="card loading-skeleton p-3">
        <div className="d-flex justify-content-between gap-3">
          <div className="d-flex gap-2 w-100">
            <SkeletonConstant width="w-140p" height="h-130p" />
            <div className="d-flex flex-column gap-2 w-100">
              <SkeletonConstant width="w-120p" height="h-65p" />
              <SkeletonConstant width="w-120p" height="h-40p" />
              <SkeletonConstant width="w-80" height="h-100" />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between w-100">
            <SkeletonConstant
              width="w-120p"
              height="h-60p"
              additional="ms-auto"
            />
            <SkeletonConstant
              width="w-50"
              height="h-25p"
              additional="ms-auto"
            />
          </div>
        </div>
      </div>
      <div className="card loading-skeleton p-3">
        <div className="d-flex justify-content-between gap-3">
          <div className="d-flex gap-2 w-100">
            <SkeletonConstant width="w-140p" height="h-130p" />
            <div className="d-flex flex-column gap-2 w-100">
              <SkeletonConstant width="w-120p" height="h-65p" />
              <SkeletonConstant width="w-120p" height="h-40p" />
              <SkeletonConstant width="w-80" height="h-100" />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between w-100">
            <SkeletonConstant
              width="w-120p"
              height="h-60p"
              additional="ms-auto"
            />
            <SkeletonConstant
              width="w-50"
              height="h-25p"
              additional="ms-auto"
            />
          </div>
        </div>
      </div>
      <div className="card loading-skeleton p-3">
        <div className="d-flex justify-content-between gap-3">
          <div className="d-flex gap-2 w-100">
            <SkeletonConstant width="w-140p" height="h-130p" />
            <div className="d-flex flex-column gap-2 w-100">
              <SkeletonConstant width="w-120p" height="h-65p" />
              <SkeletonConstant width="w-120p" height="h-40p" />
              <SkeletonConstant width="w-80" height="h-100" />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between w-100">
            <SkeletonConstant
              width="w-120p"
              height="h-60p"
              additional="ms-auto"
            />
            <SkeletonConstant
              width="w-50"
              height="h-25p"
              additional="ms-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyComplaintSkeleton;
