import React, { useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../api/ApiCall";
import { ItemRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

const AddUnit = ({ setAddUnitModal, handleGetAllUnit }) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [unitName, setUnitName] = useState("");

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setAddUnitModal(false);
    setUnitName("");
  };

  const handleAddUnit = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      unitName,
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall("post", ItemRelatedEndPoints.addItemUnit, data);
    if (res?.success) {
      handleReset();
      toast.success("Add unit successfully!");
      handleGetAllUnit();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: 350 }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-4">
              <span>Add Unit</span>
            </div>
            <div className="row gx-3">
              <div className="col-sm-12">
                <label className="form-label">Unit Name</label>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter unit name"
                    value={unitName}
                    onChange={(e) => setUnitName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <div className="modal-footer align-items-center">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleReset}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleAddUnit}
            >
              {loader ? <ApiLoader /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUnit;
