import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  dateAndTimeFormatter,
  getName,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { CrmContactRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { navigateToContactOverview } from "../../../Navigation/Navigation";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const ContactTable = (props) => {
  const navigate = useNavigate();
  const {
    allContacts,
    page,
    pageLimit,
    handleGetContact,
    editContact,
    seteditContact,
  } = props;
  const { user } = useSelector((state) => state);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  const handleDeleteContact = async () => {
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      contactId: editContact?._id,
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.deleteContact,
      sendData
    );
    if (res?.success) {
      handleClose();
      handleGetContact();
      toast.success("Contact deleted successfully!");
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8">
          <thead>
            <tr className="text-center">
              <th>No .</th>
              <th style={{ textAlign: "left" }}>Contact Name</th>
              <th>Company</th>
              <th>Email</th>
              <th>Phone</th>
              <th style={{ textAlign: "left" }}>Converted By</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {allContacts?.map((contact, index) => (
              <tr className="text-center">
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td style={{ textAlign: "left" }}>
                  <span
                    className="text-capitalize text-truncate pointer text-link"
                    style={{ width: "300px" }}
                    onClick={() =>
                      navigate(
                        navigateToContactOverview({
                          id: contact?._id,
                        })
                      )
                    }
                  >
                    {contact?.name}
                  </span>
                </td>
                <td>{contact?.businessName ? contact?.businessName : "-"}</td>
                <td>{contact?.email ? contact?.email : "-"}</td>
                <td>
                  {" "}
                  {contact?.countryCode && ` + ${contact?.countryCode}`}
                  {contact?.phone}
                </td>
                <td style={{ textAlign: "left" }}>
                  <Avatar
                    name={contact?.createdBy?.name}
                    count={1}
                    image={contact?.createdBy?.image}
                    size="xss"
                    color="primary"
                  />
                  <span className="ms-1">
                    {getName(contact?.createdBy, user?._id)}
                  </span>
                </td>
                <td className={`text-capitalize`}>
                  {dateAndTimeFormatter(contact?.updatedAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text="Are You Sure You Want To Delete This Contact"
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleDeleteContact}
      />
    </>
  );
};

export default ContactTable;
