import React from "react";

const ComplaintsRateInfoCard = ({complaintCount}) => {
  const cards = [
    {
      title: "Total",
      count: complaintCount?.total,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            d="M10.5 19.6875C5.42588 19.6875 1.3125 15.5741 1.3125 10.5C1.3125 5.42588 5.42588 1.3125 10.5 1.3125C15.5741 1.3125 19.6875 5.42588 19.6875 10.5C19.6875 15.5741 15.5741 19.6875 10.5 19.6875ZM10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.299 4.70101 21 10.5 21Z"
            fill="white"
          />
          <path
            d="M5.62379 16.3181C5.93752 16.4995 6.33896 16.3923 6.52045 16.0786C7.31572 14.7038 8.80053 13.7812 10.5 13.7812C12.1995 13.7812 13.6844 14.7038 14.4796 16.0786C14.6611 16.3923 15.0626 16.4995 15.3763 16.3181C15.69 16.1366 15.7972 15.7351 15.6157 15.4214C14.5955 13.6577 12.687 12.4688 10.5 12.4688C8.31306 12.4688 6.40457 13.6577 5.38434 15.4214C5.20286 15.7351 5.31006 16.1366 5.62379 16.3181Z"
            fill="white"
          />
          <path
            d="M14.8002 5.31927C15.1244 5.15718 15.5186 5.28858 15.6807 5.61275C15.8428 5.93692 15.7114 6.33111 15.3872 6.4932L14.0635 7.15504C14.2949 7.50999 14.4375 7.99564 14.4375 8.53125C14.4375 9.61856 13.8498 10.5 13.125 10.5C12.4001 10.5 11.8125 9.61856 11.8125 8.53125C11.8125 8.20667 11.8648 7.90044 11.9576 7.63052C11.9287 7.59461 11.9032 7.5551 11.8818 7.51222C11.7197 7.18804 11.8511 6.79385 12.1752 6.63177L14.8002 5.31927Z"
            fill="white"
          />
          <path
            d="M6.19975 5.31927C5.87558 5.15718 5.48139 5.28858 5.3193 5.61275C5.15721 5.93692 5.28861 6.33111 5.61278 6.4932L6.93647 7.15504C6.70512 7.50999 6.56253 7.99564 6.56253 8.53125C6.56253 9.61856 7.15016 10.5 7.87503 10.5C8.59991 10.5 9.18753 9.61856 9.18753 8.53125C9.18753 8.20667 9.13517 7.90044 9.04242 7.63052C9.07129 7.59461 9.09679 7.5551 9.11823 7.51222C9.28032 7.18804 9.14892 6.79385 8.82475 6.63177L6.19975 5.31927Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#1ABC9C",
    },
    {
      title: "Open",
      count: complaintCount?.open,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
        >
          <path
            d="M21.6462 5.69757C21.9513 6.00267 21.9513 6.49733 21.6462 6.80243L10.7087 17.7399C10.5622 17.8864 10.3635 17.9688 10.1562 17.9688C9.94905 17.9688 9.75034 17.8864 9.60382 17.7399L4.13507 12.2712C3.82998 11.9661 3.82998 11.4714 4.13507 11.1663C4.44017 10.8612 4.93483 10.8612 5.23993 11.1663L10.1562 16.0826L20.5413 5.69757C20.8464 5.39248 21.3411 5.39248 21.6462 5.69757Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#298DFF",
    },
    {
      title: "Proccessing",
      count: complaintCount?.processing,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
        >
          <path
            d="M10.1115 1.21002C9.90851 1.19505 9.70455 1.1875 9.5 1.1875V0C9.73376 0 9.96686 0.00862361 10.1989 0.0257357L10.1115 1.21002ZM12.4916 1.7445C12.1108 1.59758 11.7196 1.47892 11.3213 1.38947L11.5815 0.230828C12.0367 0.333049 12.4837 0.468665 12.919 0.636565L12.4916 1.7445ZM14.1182 2.58841C13.9481 2.47477 13.7743 2.36773 13.5972 2.26739L14.1825 1.23417C14.385 1.34884 14.5836 1.47117 14.7779 1.60104C14.9723 1.7309 15.1613 1.86758 15.3447 2.0107L14.6141 2.94687C14.4537 2.82164 14.2883 2.70205 14.1182 2.58841ZM16.2962 4.7136C16.0611 4.37984 15.8018 4.06384 15.5203 3.76819L16.3804 2.94937C16.702 3.28725 16.9984 3.64839 17.2671 4.02982L16.2962 4.7136ZM17.1798 6.31894C17.1015 6.12997 17.0164 5.94442 16.9249 5.7626L17.9856 5.22869C18.0902 5.43649 18.1874 5.64855 18.2769 5.86451C18.3663 6.08047 18.4475 6.29913 18.5205 6.52002L17.393 6.89253C17.3291 6.69925 17.258 6.50792 17.1798 6.31894ZM17.81 9.29601C17.8 8.88791 17.7599 8.48109 17.6901 8.07888L18.8601 7.87587C18.9399 8.33553 18.9857 8.80046 18.9971 9.26685L17.81 9.29601ZM17.6528 11.1217C17.6927 10.9211 17.7251 10.7196 17.75 10.5175L18.9286 10.6629C18.9001 10.8938 18.8631 11.1241 18.8175 11.3534C18.7719 11.5826 18.7179 11.8096 18.6559 12.0338L17.5114 11.7171C17.5657 11.5209 17.6129 11.3223 17.6528 11.1217ZM16.5228 13.9472C16.7412 13.6023 16.9339 13.2418 17.0994 12.8686L18.185 13.3498C17.9959 13.7763 17.7757 14.1883 17.5261 14.5825L16.5228 13.9472ZM15.3778 15.3778C15.5225 15.2332 15.6614 15.0836 15.7943 14.9295L16.6935 15.7051C16.5415 15.8813 16.3828 16.0522 16.2175 16.2175L15.3778 15.3778Z"
            fill="white"
          />
          <path
            d="M9.5 1.1875C8.133 1.1875 6.7871 1.52463 5.58152 2.16903C4.37593 2.81343 3.34788 3.7452 2.58841 4.88182C1.82895 6.01844 1.36152 7.32482 1.22753 8.68523C1.09354 10.0456 1.29713 11.4181 1.82025 12.6811C2.34338 13.944 3.1699 15.0584 4.22661 15.9256C5.28331 16.7929 6.53758 17.3861 7.87831 17.6528C9.21905 17.9195 10.6049 17.8514 11.913 17.4546C13.2211 17.0577 14.4112 16.3444 15.3778 15.3778L16.2175 16.2175C15.1128 17.3222 13.7527 18.1374 12.2577 18.5909C10.7627 19.0444 9.17891 19.1222 7.64664 18.8175C6.11438 18.5127 4.68093 17.8347 3.47327 16.8436C2.2656 15.8525 1.32101 14.5789 0.723147 13.1355C0.125287 11.6921 -0.107383 10.1236 0.0457475 8.56884C0.198878 7.01408 0.733082 5.52107 1.60104 4.22208C2.469 2.92309 3.64392 1.8582 5.02173 1.12175C6.39955 0.385292 7.93772 0 9.5 0V1.1875Z"
            fill="white"
          />
          <path
            d="M8.90625 3.5625C9.23417 3.5625 9.5 3.82833 9.5 4.15625V10.3429L13.3571 12.547C13.6418 12.7097 13.7407 13.0724 13.578 13.3571C13.4153 13.6418 13.0526 13.7407 12.7679 13.578L8.61167 11.203C8.42667 11.0973 8.3125 10.9006 8.3125 10.6875V4.15625C8.3125 3.82833 8.57833 3.5625 8.90625 3.5625Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#FF9FA7",
    },
    {
      title: "Resolved",
      count: complaintCount?.resolved,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="12"
          viewBox="0 0 23 12"
          fill="none"
        >
          <path
            d="M17.0303 1.28033C17.3232 0.987437 17.3232 0.512563 17.0303 0.21967C16.7374 -0.0732233 16.2626 -0.0732233 15.9697 0.21967L6 10.1893L1.28033 5.46967C0.987437 5.17678 0.512563 5.17678 0.21967 5.46967C-0.0732233 5.76256 -0.0732233 6.23744 0.21967 6.53033L5.46967 11.7803C5.61032 11.921 5.80109 12 6 12C6.19891 12 6.38968 11.921 6.53033 11.7803L17.0303 1.28033Z"
            fill="white"
          />
          <path
            d="M10.7197 11.7803L9.375 10.4357L10.4357 9.375L11.25 10.1893L21.2197 0.21967C21.5126 -0.0732233 21.9874 -0.0732233 22.2803 0.21967C22.5732 0.512563 22.5732 0.987437 22.2803 1.28033L11.7803 11.7803C11.6397 11.921 11.4489 12 11.25 12C11.0511 12 10.8603 11.921 10.7197 11.7803Z"
            fill="white"
          />
          <path
            d="M6.53033 5.46967L7.875 6.81434L6.81434 7.875L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#FFC207",
    },
    {
      title: "Closed",
      count: complaintCount?.closed,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10 18.75C5.16751 18.75 1.25 14.8325 1.25 10C1.25 5.16751 5.16751 1.25 10 1.25C14.8325 1.25 18.75 5.16751 18.75 10C18.75 14.8325 14.8325 18.75 10 18.75ZM10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill="white"
          />
          <path
            d="M5.35599 11.959C5.65478 11.7862 6.03711 11.8883 6.20995 12.187C6.96735 13.4964 8.38145 14.375 10 14.375C11.6186 14.375 13.0327 13.4964 13.7901 12.187C13.963 11.8883 14.3453 11.7862 14.6441 11.959C14.9429 12.1318 15.045 12.5142 14.8721 12.813C13.9005 14.4926 12.0829 15.625 10 15.625C7.9172 15.625 6.09959 14.4926 5.12794 12.813C4.9551 12.5142 5.0572 12.1318 5.35599 11.959Z"
            fill="white"
          />
          <path
            d="M8.75 8.125C8.75 9.16053 8.19036 10 7.5 10C6.80964 10 6.25 9.16053 6.25 8.125C6.25 7.08947 6.80964 6.25 7.5 6.25C8.19036 6.25 8.75 7.08947 8.75 8.125Z"
            fill="white"
          />
          <path
            d="M13.75 8.125C13.75 9.16053 13.1904 10 12.5 10C11.8096 10 11.25 9.16053 11.25 8.125C11.25 7.08947 11.8096 6.25 12.5 6.25C13.1904 6.25 13.75 7.08947 13.75 8.125Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#6BB4BA",
    },
    {
      title: "Cancelled",
      count: complaintCount?.cancelled,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
        >
          <path
            d="M9.5 17.8125C4.90913 17.8125 1.1875 14.0909 1.1875 9.5C1.1875 4.90913 4.90913 1.1875 9.5 1.1875C14.0909 1.1875 17.8125 4.90913 17.8125 9.5C17.8125 14.0909 14.0909 17.8125 9.5 17.8125ZM9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
            fill="white"
          />
          <path
            d="M5.51766 5.51766C5.74953 5.28578 6.12547 5.28578 6.35734 5.51766L9.5 8.66031L12.6427 5.51766C12.8745 5.28578 13.2505 5.28578 13.4823 5.51766C13.7142 5.74953 13.7142 6.12547 13.4823 6.35734L10.3397 9.5L13.4823 12.6427C13.7142 12.8745 13.7142 13.2505 13.4823 13.4823C13.2505 13.7142 12.8745 13.7142 12.6427 13.4823L9.5 10.3397L6.35734 13.4823C6.12547 13.7142 5.74953 13.7142 5.51766 13.4823C5.28578 13.2505 5.28578 12.8745 5.51766 12.6427L8.66031 9.5L5.51766 6.35734C5.28578 6.12547 5.28578 5.74953 5.51766 5.51766Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#F31D2F",
    },
  ];
  return (
    <>
      {cards.map((card, index) => (
        <div
          className="card card-border"
          key={index}
          style={{ minWidth: "190px" }}
        >
          <div className="card-body">
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between text-dark gap-1">
                <p>{card?.title}</p>
                <span
                  className="rounded-top-end rounded-bottom-start px-2 py-1 text-white"
                  style={{ backgroundColor: card?.bgColor }}
                >
                  {card?.icon}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between text-dark gap-1">
                <p className="fw-semibold fs-5">{card?.count}</p>
                {/* <button className="btn btn-sm btn-icon">
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.84088 2.77838C8.17038 2.44887 8.70462 2.44887 9.03412 2.77838L19.1591 12.9034C19.4886 13.2329 19.4886 13.7671 19.1591 14.0966L9.03412 24.2216C8.70462 24.5511 8.17038 24.5511 7.84088 24.2216C7.51137 23.8921 7.51137 23.3579 7.84088 23.0284L17.3693 13.5L7.84088 3.97162C7.51137 3.64212 7.51137 3.10788 7.84088 2.77838Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ComplaintsRateInfoCard;
