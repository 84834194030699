import React from "react";
import NoData from "../../components/NoData/NoData";
import FolderCard from "../Folder/FolderCard";
import ListGridView from "../List/ListGridView";
import Pagination from "../../components/Pagination/Pagination";

const SpacesBody = (props) => {
  const {
    spaceData,
    loader,
    selectedTab,
    spacename,
    spaceId,
    handleOpenFolderInfo,
    handleOpenListInfo,
    setpermissionModalType,
    setpermissionData,
    handleCloseAllInfoModal,
    folderPageLimit,
    setfolderPageLimit,
    folderPageNumber,
    folderTotalPages,
    setfolderPageNumber,
    folderCount,
    ListPageLimit,
    setListPageLimit,
    ListPageNumber,
    setListPageNumber,
    listTotalPages,
    listCount,
    isModuleReadWriteAccessForSpace
  } = props;

  return (
    <div className="fm-body">
      <div data-simplebar className="nicescroll-bar simple-scrollbar">
        <div className="file-card-view">
          {selectedTab !== "LIST" &&
            spaceData?.folders?.data?.length > 0 &&
            !loader && (
              <div className="collapse-simple">
                <div className="card">
                  <div className="card-header">
                    <a
                      role="button"
                      data-bs-toggle="collapse"
                      href="#fm_collapse_2"
                      aria-expanded="true"
                    >
                      <h5 className="mb-0">
                        Folders{" "}
                        {spaceData?.folders?.data?.length > 0 && (
                          <span className="badge badge-md badge-pill badge-soft-primary ms-2">
                            {spaceData?.folders?.data?.length}
                          </span>
                        )}
                      </h5>
                    </a>
                  </div>

                  <div id="fm_collapse_2" className="collapse show">
                    <Pagination
                      setpageLimit={setfolderPageLimit}
                      pageLimit={folderPageLimit}
                      setPage={setfolderPageNumber}
                      page={folderPageNumber}
                      totalPages={folderTotalPages}
                      count={folderCount}
                      dataLength={spaceData?.folders?.data?.length}
                      align={"end"}
                    />
                    <div className="row gx-3 row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-2 w-90-sm">
                      <FolderCard
                        spaceData={spaceData}
                        spacename={spacename}
                        handleOpenFolderInfo={handleOpenFolderInfo}
                        setpermissionModalType={setpermissionModalType}
                        setpermissionData={setpermissionData}
                        handleCloseAllInfoModal={handleCloseAllInfoModal}
                        folderPageLimit={folderPageLimit}
                        setfolderPageLimit={setfolderPageLimit}
                        folderPageNumber={folderPageNumber}
                        setfolderPageNumber={setfolderPageNumber}
                        folderCount={folderCount}
                        folderTotalPages={folderTotalPages}
                        isModuleReadWriteAccessForSpace={isModuleReadWriteAccessForSpace}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          {selectedTab !== "FOLDER" &&
            spaceData?.lists?.data?.length > 0 &&
            !loader && (
              <div className="collapse-simple mt-1">
                <div className="card">
                  <div className="card-header">
                    <a
                      role="button"
                      data-bs-toggle="collapse"
                      href="#fm_collapse_3"
                      aria-expanded="true"
                    >
                      <h5 className="mb-0">
                        List{" "}
                        {spaceData?.lists?.data?.length > 0 && (
                          <span className="badge badge-md  badge-pill badge-soft-primary ms-2">
                            {spaceData?.lists?.data?.length}
                          </span>
                        )}
                      </h5>
                    </a>
                  </div>
                  <div id="fm_collapse_3" className="collapse show">
                    <Pagination
                      setpageLimit={setListPageLimit}
                      pageLimit={ListPageLimit}
                      setPage={setListPageNumber}
                      page={ListPageNumber}
                      totalPages={listTotalPages}
                      count={listCount}
                      dataLength={spaceData?.lists?.data?.length}
                      align={"end"}
                    />
                    <div className="row gx-3 row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-2 w-90-sm">
                      <ListGridView
                        spaceData={spaceData}
                        spacename={spacename}
                        spaceId={spaceId}
                        handleOpenListInfo={handleOpenListInfo}
                        setpermissionModalType={setpermissionModalType}
                        setpermissionData={setpermissionData}
                        handleCloseAllInfoModal={handleCloseAllInfoModal}
                        isModuleReadWriteAccessForSpace={isModuleReadWriteAccessForSpace}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        {selectedTab === "FOLDER" &&
          spaceData?.folders?.data?.length === 0 &&
          !loader && (
            <div className="d-flex justify-content-center mt-7">
              <NoData title="No Folder Found" />
            </div>
          )}
        {selectedTab === "LIST" &&
          spaceData?.lists?.data?.length === 0 &&
          !loader && (
            <div className="d-flex justify-content-center mt-7">
              <NoData title="No List Found" />
            </div>
          )}
      </div>
    </div>
  );
};

export default SpacesBody;
