import React, { useEffect, useRef, useState } from "react";
import InvoiceDetailsHeader from "./InvoiceDetailsHeader";
import InvoiceDetailsBody from "./InvoiceDetailsBody";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import {
  InventoryRelatedEndPoints,
  OrderRelatedEndPoints,
  VendorRelatedEndPoints,
} from "../../../../api/Endpoints";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { useLocation, useParams } from "react-router-dom";
import PrintInvoiceDetails from "./PrintInvoiceDetails";

const InvoiceDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [invoiceSettingDetails, setinvoiceSettingDetails] = useState([]);
  const componentRef = useRef();
  const [allTransaction, setallTransaction] = useState([]);

  const handleGetInvoiceDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 10,
      filters: {
        orderId: id,
      },
    };
    const res = await ApiCall(
      "post",
      OrderRelatedEndPoints.getOrderInvoice,
      data
    );
    if (res?.success) {
      setInvoiceDetails(res?.invoice?.data?.[0]);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetInvoiceSetting = async () => {
    startApiCall(null, setloader2);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.inventorySettings,
      data
    );
    if (res?.success) {
      setinvoiceSettingDetails(res?.setting);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetInvoiceDetails();
    handleGetInvoiceSetting();
    handleGetOrdersTransaction();
  }, []);

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  const handleGetOrdersTransaction = async () => {
    startApiCall(null, setloader3);
    const data = {
      filters: {
        orderId: id,
      },
      page: 1,
      limit: 5000,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorTransaction,
      data
    );
    if (res?.success) {
      setallTransaction(res?.transactions?.data);
      setloader3(false);
    } else {
      setloader3(false);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <InvoiceDetailsHeader
            handleGetInvoiceDetails={handleGetInvoiceDetails}
            componentRef={componentRef}
            locationData={locationData}
            handleGetInvoiceSetting={handleGetInvoiceSetting}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && !loader2 && (
                <InvoiceDetailsBody
                  invoiceDetails={invoiceDetails}
                  invoiceSettingDetails={invoiceSettingDetails}
                  allTransaction={allTransaction}
                />
              )}
              {(loader || loader2) && <DataLoader title="data is loading..." />}
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <PrintInvoiceDetails
            ref={componentRef}
            invoiceDetails={invoiceDetails}
            invoiceSettingDetails={invoiceSettingDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
