import React, { useState } from "react";
import { taskEndpoint } from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import SelectEmployeeModal from "../../components/SelectEmployeeModal/SelectEmployeeModal";
import { useParams } from "react-router-dom";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../components/Error/ErrorMessage";
import { toast } from "react-toastify";
import { Avatar } from "../../components/Avatar/Avatar";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";

function AddTask({ handleGetTask, modalId, listId, handleGetAllLists }) {
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  var date = reversesDateFormatter(new Date());
  const [taskName, settaskName] = useState("");
  const [taskdescription, settaskdescription] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [priority, setPriority] = useState("NO_PRIORITY");
  const [isSelectedEmployeeModal, setisSelectedEmployeeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [selectedEmpId, setselectedEmpId] = useState([]);

  const handleReset = () => {
    setErrorMessage("");
    setselectedEmployees([]);
    setselectedEmpId([]);
    settaskName("");
    settaskdescription("");
    setdueDate("");
    setPriority("NO_PRIORITY");
    setloader(false);
    const closeModalDom = document.getElementById(`${modalId}`);
    if (closeModalDom) closeModalDom.click();
  };

  const handleAssigntask = async (taskId) => {
    startApiCall(null, setloader);
    let temp = [];
    selectedEmpId?.map((employeeId) => {
      temp?.push({ employeeId, isRemoved: false });
    });
    const sendData = {
      taskId: taskId,
      employees: temp,
      userId: user?._id,
    };
    const res = await ApiCall("post", taskEndpoint.assignTask, sendData);
    if (res?.success) {
      setloader(false);
      if (modalId === "add_new_task_folderlist") {
        handleGetAllLists();
      } else {
        handleGetTask();
      }
      toast.success("Create Task successfully!");
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleCreatetask = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader);
    const sendData = {
      name: taskName,
      taskType: "maintask",
      taskAbilities: "business",
      description: taskdescription,
      createdBy: user?._id,
      assignedBy: [user?._id],
      dueDate: dueDate,
      priority: priority ? priority : "NO_PRIORITY",
      businessId: getCurrentWorkspaceId(),
      list: modalId === "add_new_task_folderlist" ? listId : id,
    };
    const res = await ApiCall("post", taskEndpoint.createTask, sendData);
    if (res?.success) {
      if (selectedEmployees?.length > 0) {
        handleAssigntask(res?.task?._id);
      } else {
        if (modalId === "add_new_task_folderlist") {
          handleGetAllLists();
        } else {
          handleGetTask();
        }
        toast.success("Create Task successfully!");
        handleReset();
      }
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };
  const handlePriorityChange = (event) => {
    const selectedPriority = event.target.value;
    setPriority(selectedPriority);
  };

  return (
    <div>
      <div
        id={`${modalId}`}
        className="modal add-new-contact"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        style={{ opacity: isSelectedEmployeeModal ? 0.8 : "" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="mb-3">Add New Task</h5>
              <form onSubmit={handleCreatetask}>
                <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-2">
                  <span>Basic Info</span>
                </div>
                <div className="row gx-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        onChange={(e) => {
                          settaskName(e.target.value);
                        }}
                        value={taskName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gx-3">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label">Note/Description</label>
                        <small className="text-muted">200</small>
                      </div>
                      <textarea
                        className="form-control"
                        rows="3"
                        onChange={(e) => {
                          settaskdescription(e.target.value);
                        }}
                        value={taskdescription}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row gx-3">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">Due Date</label>
                      <input
                        className="form-control"
                        name="single-date-pick"
                        type="date"
                        value={dueDate}
                        onChange={(e) => {
                          setdueDate(e.target.value);
                        }}
                        min={date}
                      />
                    </div>
                  </div>
                </div>

                <div className="row gx-3">
                  <div className="col-sm-12">
                    <div className="form-inline">
                      <div className="form-group">
                        <label className="form-label">Set priority:</label>
                        <div className="form-check form-check-inline ms-2">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc2"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              value="HIGH"
                              onChange={handlePriorityChange}
                              checked={priority === "HIGH"}
                            />
                            <label className="form-check-label">High</label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc3"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              value="MEDIUM"
                              onChange={handlePriorityChange}
                              checked={priority === "MEDIUM"}
                            />
                            <label className="form-check-label">Medium</label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc4"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              value="LOW"
                              onChange={handlePriorityChange}
                              checked={priority === "LOW"}
                            />
                            <label className="form-check-label">Low</label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc4"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              value="NO_PRIORITY"
                              onChange={handlePriorityChange}
                              checked={priority === "NO_PRIORITY"}
                            />
                            <label className="form-check-label">
                              No Priority
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="title title-xs title-wth-divider text-primary text-uppercase mt-2">
                  <span>Assign to</span>
                </div>
                <div className="repeater">
                  <div data-repeater-list="category-group">
                    <ul className="hk-list pointer">
                      {selectedEmployees?.map((emp, index) => {
                        return (
                          <CustomTooltip
                            text={emp?.name}
                            placement={index === 0 ? "right" : "bottom"}
                          >
                            <li key={index}>
                              <Avatar
                                name={emp?.name}
                                count={1}
                                image={emp?.image}
                                size="xs"
                              />
                            </li>
                          </CustomTooltip>
                        );
                      })}

                      <li
                        data-bs-toggle="modal"
                        data-bs-target="#select_employee"
                        onClick={() => {
                          setisSelectedEmployeeModal(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="currentColor"
                          class="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                      </li>
                    </ul>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleReset}
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loader}
                  >
                    {loader ? <ApiLoader /> : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SelectEmployeeModal
        setisSelectedEmployeeModal={setisSelectedEmployeeModal}
        isSelectedEmployeeModal={isSelectedEmployeeModal}
        listId={listId}
        user={user}
        selectedEmployees={selectedEmployees}
        setselectedEmployees={setselectedEmployees}
        selectedEmpId={selectedEmpId}
        setselectedEmpId={setselectedEmpId}
      />
    </div>
  );
}

export default AddTask;
