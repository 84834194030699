import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ComplaintsRate from "../../pages/Complaints/ComplaintsRate/ComplaintsRate";

const ComplaintsRateRoutes = {
  path: "/grievance",
  element: <Navbar />,
  children: [
    {
      path: "homepage",
      element: <ComplaintsRate />,
    },
  ],
};

export default ComplaintsRateRoutes;