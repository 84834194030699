import React from "react";

const DataLoader = ({ title }) => {
  return (
      <div className="spinnerContainer">
        <div className="spinner"></div>
        <div className="loader">
          <p>loading</p>
          <div className="words">
            <span className="word">images</span>
            <span className="word">Data</span>
            <span className="word">Files</span>
            <span className="word">Documents</span>
          </div>
        </div>
      </div>
  );
};

export default DataLoader;