import React, { useEffect, useState } from "react";
import { linupTaskEndpoints, taskEndpoint } from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import {
  getAvatarClass,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import TaskDetailsModal from "../Task/TaskDetailsModal/TaskDetailsModal";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";

const TaskActivity = ({ activeTab }) => {
  const user = useSelector((state) => state.user);
  const [allTasks, setAllTasks] = useState([]);
  const [loader, setloader] = useState(false);

  // ******** LINEUP TASK **********
  const [lineUpTask, setLineUpTask] = useState([]);
  const [loader2, setloader2] = useState(false);

  // ******** FOR UNSCHEDULE TASK **********
  const [showOpenTask, setShowOpentask] = useState(false);
  const [unscheduleTask, setUnscheduleTask] = useState([]);
  const [loader3, setloader3] = useState(false);

  // ******** TASKDETAILS MODAL **********
  const [taskDetailsModal, settaskDetailsModal] = useState(false);
  const [taskId, settaskId] = useState([]);

  const handleGetAllTasks = async (isSchedule) => {
    if (!isSchedule) {
      startApiCall(null, setloader);
    } else {
      startApiCall(null, setloader3);
    }
    const sendData = {
      page: 1,
      limit: 50,
      employeeId: user?._id,
      forLineUp: isSchedule ? false : true,
      unschedule: isSchedule,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (!isSchedule) {
      if (res?.success) {
        setAllTasks(res?.task?.data);
        setloader(false);
      } else {
        setloader(false);
      }
    } else {
      if (res?.success) {
        setUnscheduleTask(res?.task?.data);
        setloader3(false);
      } else {
        setloader3(false);
      }
    }
  };

  const handleGetLineUpTask = async () => {
    startApiCall(null, setloader2);
    const res = await ApiCall("get", linupTaskEndpoints.getTaskInLineUp, {
      params: {
        employeeId: user?._id,
        businessId: getCurrentWorkspaceId(),
        page: 1,
        limit: 50,
      },
    });
    if (res?.success) {
      setLineUpTask(res?.lineup?.lineup);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };
// ******** LINEUP TASK API CALL **********
  useEffect(() => {
    if (getCurrentWorkspaceId() && activeTab === "tab_block_2") {
      handleGetLineUpTask();
    }
  }, [activeTab]);

  const handleAddorRemoveLineUpTask = async (taskId, isAdding) => {
    const sendData = {
      employeeId: user?._id,
      businessId: getCurrentWorkspaceId(),
      taskId: taskId,
      isAdding: isAdding,
    };
    const res = await ApiCall(
      "post",
      linupTaskEndpoints.addTaskInLineUp,
      sendData
    );
    if (res?.success) {
      handleGetLineUpTask();
    }
  };

  const openDetailsPage = (task) => {
    settaskId(task);
    settaskDetailsModal(true);
  };

  return (
    <>
      <div className="d-flex mt-5">
        <h5 className="mb-3">
          LineUp{" "}
          {lineUpTask?.tasks?.length > 0 && (
            <span className="badge badge-sm badge-light ms-1">
              {lineUpTask?.tasks?.length}
            </span>
          )}
        </h5>
        &nbsp;&nbsp;&nbsp;
        {lineUpTask?.tasks?.length > 0 && (
          <div className="selectable-dropdown">
            <div className="dropdown selectable-dropdown">
              <span
                className="shadow me-2 fs-7 text-dark p-2 pointer mb-2"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                onClick={() => handleGetAllTasks(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                  />
                </svg>
                &nbsp; Add to line up
              </span>

              <div
                role="menu"
                className="dropdown-menu"
                style={{ maxHeight: "40vh", overflow: "auto", width: "30vw" }}
              >
                {loader ? (
                  <span className="text-center"> Loading...</span>
                ) : (
                  <>
                    {allTasks?.length === 0 ? (
                      <span className="text-center">No Tasks Found</span>
                    ) : (
                      <>
                        {allTasks?.map((data) => {
                          return (
                            <div className="d-flex justify-content-between">
                              <div className="dropdown-item text-capitalize text-truncate">
                                <div className="d-flex gap-2">
                                  <div className="d-flex mt-1">
                                    <CustomTooltip
                                      text={data?.status?.statusName}
                                      placement="right"
                                    >
                                      <svg
                                        className="circle me-2"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill={data?.status?.color}
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          width="15"
                                          height="15"
                                          fill={data?.status?.color}
                                        />
                                      </svg>
                                    </CustomTooltip>
                                    <h6
                                      className="text-dark text-truncate w-250p"
                                      onClick={() =>
                                        handleAddorRemoveLineUpTask(
                                          data?._id,
                                          true
                                        )
                                      }
                                    >
                                      {data?.name}
                                    </h6>
                                  </div>
                                  <div className="flex-grow-1"></div>
                                  <div className="avatar-group avatar-group-sm avatar-group-overlapped d-xl-flex d-none me-2">
                                    {data?.assignedTo
                                      ?.slice(0, 2)
                                      ?.map((assine) => {
                                        const nameInitial =
                                          assine?.name?.[0].toUpperCase();
                                        const avatarClass =
                                          getAvatarClass(nameInitial);
                                        return (
                                          <div
                                            key={assine?._id}
                                            className={`avatar avatar-xxs ${avatarClass} avatar-rounded font-1`}
                                          >
                                            <span className="initial-wrap">
                                              {nameInitial}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    {data?.assignedTo?.length > 2 && (
                                      <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                                        <span className="initial-wrap">
                                          + {data?.assignedTo?.length - 2}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {lineUpTask?.tasks?.length > 0 ? null : (
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card card-border mb-0">
              <div className="card-body p-2" style={{ background: "#ECECEC" }}>
                <div className="text-center pointer">
                  <div className="selectable-dropdown">
                    <div className="dropdown selectable-dropdown">
                      <span
                        className={`me-2 font-2 fw-medium  text-blue text-capitalize  rounded p-2`}
                        aria-expanded="false"
                        data-bs-toggle="dropdown"
                        onClick={() => {
                          if (getCurrentWorkspaceId()) {
                            handleGetAllTasks(false);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-lg me-2"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                          />
                        </svg>
                        &nbsp; Add your Most Important task here
                      </span>
                      <div
                        role="menu"
                        className="dropdown-menu"
                        style={{
                          maxHeight: "40vh",
                          overflow: "auto",
                          width: "30vw",
                        }}
                      >
                        {loader ? (
                          <span className="text-center"> Loading...</span>
                        ) : (
                          <>
                            {allTasks?.length === 0 ? (
                              <span className="text-center">
                                No Tasks Found
                              </span>
                            ) : (
                              <>
                                {allTasks?.map((data) => {
                                  return (
                                    <div className="d-flex justify-content-between">
                                      <div className="dropdown-item text-capitalize text-truncate">
                                        <div className="d-flex gap-2">
                                          <div className="d-flex mt-1">
                                            <CustomTooltip
                                              text={data?.status?.statusName}
                                              placement="right"
                                            >
                                              <svg
                                                className="circle me-2"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                                fill={data?.status?.color}
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <rect
                                                  width="15"
                                                  height="15"
                                                  fill={data?.status?.color}
                                                />
                                              </svg>
                                            </CustomTooltip>
                                            <h6
                                              className="text-dark text-truncate w-250p"
                                              onClick={() =>
                                                handleAddorRemoveLineUpTask(
                                                  data?._id,
                                                  true
                                                )
                                              }
                                            >
                                              {data?.name}
                                            </h6>
                                          </div>
                                          <div className="flex-grow-1"></div>
                                          <div className="avatar-group avatar-group-sm avatar-group-overlapped d-xl-flex d-none me-2">
                                            {data?.assignedTo
                                              ?.slice(0, 2)
                                              ?.map((assine) => {
                                                const nameInitial =
                                                  assine?.name?.[0].toUpperCase();
                                                const avatarClass =
                                                  getAvatarClass(nameInitial);
                                                return (
                                                  <div
                                                    key={assine?._id}
                                                    className={`avatar avatar-xxs ${avatarClass} avatar-rounded font-1`}
                                                  >
                                                    <span className="initial-wrap">
                                                      {nameInitial}
                                                    </span>
                                                  </div>
                                                );
                                              })}
                                            {data?.assignedTo?.length > 2 && (
                                              <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                                                <span className="initial-wrap">
                                                  +{" "}
                                                  {data?.assignedTo?.length - 2}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {lineUpTask?.tasks?.length > 0 && (
        <div className="row">
          <div className="d-flex col-md-12 mb-md-4 mb-3">
            <div className="card pointer w-100">
              <div className="row gx-3 row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-2 ps-4 gap-2">
                {lineUpTask?.tasks?.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className="card p-2 border-1 mt-1 w-245p"
                      style={{ background: "#ffffff" }}
                    >
                      <div className="card-body p-2">
                        <div className="d-flex gap-1 justify-content-between align-items-center">
                          <div
                            className="w-50"
                            onClick={() => openDetailsPage(data?._id)}
                          >
                            <div className="text-dark text-truncate">
                              <CustomTooltip
                                text={data?.status?.statusName}
                                placement="right"
                              >
                                <svg
                                  className="circle me-2"
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill={data?.status?.color}
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    width="15"
                                    height="15"
                                    fill={data?.status?.color}
                                  />
                                </svg>
                              </CustomTooltip>
                              {data?.name}
                            </div>
                          </div>
                          <span
                            className="pointer"
                            onClick={() =>
                              handleAddorRemoveLineUpTask(data?._id, false)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-x-lg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {loader2 && <DataLoader />}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex">
        <h4 className="mb-3">My Work</h4>
      </div>
      <div className="row">
        <div>
          {showOpenTask ? (
            <span
              className="text-primary pointer me-2"
              onClick={() => setShowOpentask(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
              </svg>
            </span>
          ) : (
            <span
              className="text-primary pointer me-2"
              onClick={() => {
                setShowOpentask(true);
                if (getCurrentWorkspaceId()) {
                  handleGetAllTasks(true);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#000"
                class="bi bi-caret-right-fill"
                viewBox="0 0 16 16"
              >
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
              </svg>
            </span>
          )}

          <span className="text-dark">Unscheduled Task</span>
        </div>

        {showOpenTask && (
          <>
            <div className="d-flex align-items-center justify-content-center w-50 mt-3 ms-5">
              <hr className="flex-1" style={{ backgroundColor: "#6c757d" }} />
              <span style={{ margin: "0 10px" }}>
                Tasks assigned to {user?.name} that have not been scheduled
              </span>
              <hr
                className="flex-1 divider"
                style={{ backgroundColor: "#6c757d" }}
              />
            </div>

            <div
              className="card border-1 w-50 p-2 mt-3 ms-5 ps-3 pointer mb-10"
              style={{
                maxHeight: "40vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="d-block">
                  {loader3 ? (
                    <span className="text-center"> Loading...</span>
                  ) : (
                    <>
                      {unscheduleTask?.length === 0 ? (
                        <span className="text-center">
                          No unscheduled tasks available.
                        </span>
                      ) : (
                        <>
                          {unscheduleTask?.map((tsk, index) => {
                            return (
                              <>
                                <div
                                  className="d-flex w-50"
                                  onClick={() => openDetailsPage(tsk?._id)}
                                >
                                  <CustomTooltip
                                    text={tsk?.status?.statusName}
                                    placement="right"
                                  >
                                    <svg
                                      className="circle me-2 mt-3"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill={tsk?.status?.color}
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        width="15"
                                        height="15"
                                        fill={tsk?.status?.color}
                                      />
                                    </svg>
                                  </CustomTooltip>
                                  <div className="ms-3">
                                    <h6 className="font-2 text-truncate w-75 mt-2">
                                      {tsk?.space?.name} /{" "}
                                      {tsk?.folder && `${tsk?.folder?.name} /`}{" "}
                                      {tsk?.list?.name}
                                    </h6>
                                    <br />
                                    <div
                                      style={{
                                        marginTop: -20,
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <h5 className="text-dark font-3 text-truncate w-450p">
                                        {tsk?.name}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                                {index !== allTasks?.length - 1 && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="650"
                                    height="2"
                                    viewBox="0 0 700 2"
                                    fill="none"
                                    style={{ marginTop: -10 }}
                                  >
                                    <path d="M0 1H700" stroke="#D9D9D9" />
                                  </svg>
                                )}
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {taskDetailsModal && (
        <TaskDetailsModal
          taskId={taskId}
          taskDetailsModal={taskDetailsModal}
          settaskDetailsModal={settaskDetailsModal}
        />
      )}
    </>
  );
};

export default TaskActivity;
