import React from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";

const CostStatisticsGraph = ({ costsData }) => {
  let maxCostPrice = 0;
  let maxCostPriceIndex = -1;

  costsData.forEach((data , index) => {
    const costPrice = data.totalCosts?.[0]?.costPrice || 0;
    if (costPrice > maxCostPrice) {
      maxCostPrice = costPrice;
      maxCostPriceIndex = index;
    }
  });

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };
  const lineChartData = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Cost statistics",
        data: costsData?.map((data) => data.totalCosts?.[0]?.costPrice),
        fill: false,
        borderColor: "#F31D2F",
        lineTension: 0.4,
        backgroundColor: "rgba(243, 29, 47, 0.25)",
      },
    ],
  };

  if (maxCostPriceIndex !== -1) {
    lineChartData.datasets[0].pointRadius = Array(7).fill(4);
    lineChartData.datasets[0].pointHoverRadius = Array(7).fill(4);
    lineChartData.datasets[0].pointRadius[maxCostPriceIndex] = 10;
    lineChartData.datasets[0].pointHoverRadius[maxCostPriceIndex] = 10;
  }

  return (
    <div className="d-flex justify-content-center h-100">
      <Line data={lineChartData} options={lineChartOptions} />
    </div>
  );
};

export default CostStatisticsGraph;
