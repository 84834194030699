import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { navigateToStaffSalaryDetails } from "../../../../Navigation/Navigation";

const SalaryTable = ({ allStaffSalary, page, pageLimit, selectedMonth }) => {
  const navigate = useNavigate();

  return (
    <table id="datable_1" className="table table-bordered">
      <thead>
        <tr className="text-center">
          <th>No.</th>
          <th style={{ textAlign: "left" }}>Employee</th>
          <th>Payment Type</th>
          <th>Settlement Date</th>
          <th>Salary Amount</th>
        </tr>
      </thead>
      <tbody>
        {allStaffSalary?.map((salary, index) => {
          return (
            <tr className="text-center">
              <td>{(page - 1) * pageLimit + (index + 1)} .</td>
              <td
                style={{ textAlign: "left" }}
                onClick={() =>
                  navigate(
                    navigateToStaffSalaryDetails({
                      id: salary?.staff?.employeeId?._id,
                      monthname: selectedMonth,
                    }),
                    {
                      state: {
                        employeeName: salary?.staff?.employeeId?.name,
                        image: salary?.staff?.employeeId?.image,
                      },
                    }
                  )
                }
              >
                <span
                  className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                  style={{ width: "240px" }}
                >
                  <Avatar
                    name={salary?.staff?.employeeId?.name}
                    count={1}
                    image={salary?.staff?.employeeId?.image}
                    size="xss"
                    color="primary"
                  />
                  &nbsp; {salary?.staff?.employeeId?.name}
                </span>
              </td>
              <td style={{ textTransform: "capitalize" }}>
                {salary?.staff?.paymentType}
              </td>
              <td>
                {salary?.staff?.settlementDate
                  ? `${salary?.staff?.settlementDate} of every month`
                  : "No settlement date"}
              </td>
              <td> ₹ {salary?.staff?.salary}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SalaryTable;
