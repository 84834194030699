import React from "react";
import {
  dateAndTimeFormatter,
  getName,
} from "../../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { Avatar } from "../../../../../components/Avatar/Avatar";

function OverviewTimeline({
  leadDetails,
  setmoreInfoModal,
  settimelineDetails,
  setupdatedForModal,
}) {
  const user = useSelector((state) => state.user);

  return (
    <>
      {" "}
      <div className="d-flex justify-content-between">
        <p className="text-semibold text-primary fs-4">
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.7057 10.3333C29.7057 11.7542 28.5432 12.9167 27.1224 12.9167C26.8899 12.9167 26.6703 12.8908 26.4636 12.8263L21.8653 17.4117C21.9299 17.6183 21.9557 17.8508 21.9557 18.0833C21.9557 19.5042 20.7932 20.6667 19.3724 20.6667C17.9516 20.6667 16.7891 19.5042 16.7891 18.0833C16.7891 17.8508 16.8149 17.6183 16.8795 17.4117L13.5857 14.1179C13.3791 14.1825 13.1466 14.2083 12.9141 14.2083C12.6816 14.2083 12.4491 14.1825 12.2424 14.1179L6.36531 20.0079C6.4299 20.2146 6.45573 20.4342 6.45573 20.6667C6.45573 22.0875 5.29323 23.25 3.8724 23.25C2.45156 23.25 1.28906 22.0875 1.28906 20.6667C1.28906 19.2458 2.45156 18.0833 3.8724 18.0833C4.1049 18.0833 4.32448 18.1092 4.53115 18.1737L10.4211 12.2967C10.3566 12.09 10.3307 11.8575 10.3307 11.625C10.3307 10.2042 11.4932 9.04167 12.9141 9.04167C14.3349 9.04167 15.4974 10.2042 15.4974 11.625C15.4974 11.8575 15.4716 12.09 15.407 12.2967L18.7007 15.5904C18.9074 15.5258 19.1399 15.5 19.3724 15.5C19.6049 15.5 19.8374 15.5258 20.0441 15.5904L24.6295 10.9921C24.5649 10.7854 24.5391 10.5658 24.5391 10.3333C24.5391 8.9125 25.7016 7.75 27.1224 7.75C28.5432 7.75 29.7057 8.9125 29.7057 10.3333Z"
              fill="currentColor"
            />
          </svg>
          &nbsp; Timeline
          {leadDetails?.leadLifeCycle?.length > 0 && (
            <span className="badge badge-md  badge-pill badge-soft-primary ms-2">
              {leadDetails?.leadLifeCycle?.length}
            </span>
          )}
        </p>
      </div>
      <table id="datable_1" className="table nowrap w-100 mt-3 mb-8">
        <thead
          style={{
            backgroundColor: "#EDF2FF",
            border: "#fff",
            borderRadius: "16px",
          }}
        >
          <tr className="text-center">
            <th style={{ borderBottom: "none" }}>Process No .</th>
            <th style={{ borderBottom: "none" }}>Date & Time</th>
            <th style={{ borderBottom: "none" }}>Action </th>
            <th style={{ borderBottom: "none", textAlign: "left" }}>
              Updated By
            </th>
            <th style={{ borderBottom: "none", textAlign: "left" }}>
              Updated for
            </th>
            <th style={{ borderBottom: "none" }}>More Info</th>
          </tr>
        </thead>
        <tbody>
          {leadDetails?.leadLifeCycle
            ?.slice()
            ?.reverse()
            ?.map((log, index) => (
              <tr className="text-center">
                <td>{leadDetails?.leadLifeCycle?.length - index}.</td>
                <td> {dateAndTimeFormatter(log?.date)}</td>
                <td className="text-capitalize text-dark">
                  {log?.description}

                  {log?.moreInfo?.[0]?.actionFromRef && (
                    <span className="badge badge-sm badge-primary ms-1 fw-bold">
                      {log?.moreInfo?.[0]?.actionFromRef === "Status"
                        ? "Auto"
                        : "Manual"}
                    </span>
                  )}
                </td>
                <td style={{ textAlign: "left" }}>
                  {" "}
                  <Avatar
                    name={log?.updatedBy?.name}
                    count={1}
                    image={log?.updatedBy?.image}
                    size="xss"
                    color="primary"
                  />
                  &nbsp;
                  {getName(log?.updatedBy, user?._id)}
                </td>
                <td style={{ textAlign: "left" }}>
                  {" "}
                  {log?.updatedFor?.length > 0 ? (
                    <>
                      {log?.updatedFor?.length === 1 ? (
                        <>
                          <Avatar
                            name={log?.updatedFor?.[0]?.name}
                            count={1}
                            image={log?.updatedFor?.[0]?.image}
                            size="xxs"
                            color="primary"
                          />
                          &nbsp;
                          {getName(log?.updatedFor?.[0], user?._id)}
                        </>
                      ) : (
                        <div className="avatar-group avatar-group-sm avatar-group-overlapped pointer">
                          {log?.updatedFor?.slice(0, 1)?.map((emp) => {
                            return (
                              <Avatar
                                name={emp?.name}
                                count={1}
                                image={emp?.image}
                                size="xxs"
                                color="primary"
                              />
                            );
                          })}
                          {log?.updatedFor?.length > 1 && (
                            <div
                              className="avatar avatar-xs avatar-soft-indigo avatar-rounded"
                              onClick={() => {
                                settimelineDetails(log);
                                setupdatedForModal(true);
                              }}
                            >
                              <span className="initial-wrap">
                                +{log?.updatedFor?.length - 1}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    "No Assignee"
                  )}
                </td>
                <td className="pointer">
                  {log?.moreInfo?.length > 0 &&
                  log?.action !== "assign participant" ? (
                    <svg
                      onClick={() => {
                        settimelineDetails(log);
                        setmoreInfoModal(true);
                      }}
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="11" cy="11" r="10.5" stroke="#3862DD" />
                      <path
                        d="M11.9224 8.0752V16H9.98145V8.0752H11.9224ZM9.84961 6.00244C9.84961 5.71436 9.94727 5.4751 10.1426 5.28467C10.3428 5.09424 10.6113 4.99902 10.9482 4.99902C11.2852 4.99902 11.5513 5.09424 11.7466 5.28467C11.9468 5.4751 12.0469 5.71436 12.0469 6.00244C12.0469 6.28564 11.9468 6.52246 11.7466 6.71289C11.5513 6.90332 11.2852 6.99854 10.9482 6.99854C10.6113 6.99854 10.3428 6.90332 10.1426 6.71289C9.94727 6.52246 9.84961 6.28564 9.84961 6.00244Z"
                        fill="#3862DD"
                      />
                    </svg>
                  ) : log?.action === "assign participant" ? (
                    <svg
                      onClick={() => {
                        settimelineDetails(log);
                        setmoreInfoModal(true);
                      }}
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="11" cy="11" r="10.5" stroke="#3862DD" />
                      <path
                        d="M11.9224 8.0752V16H9.98145V8.0752H11.9224ZM9.84961 6.00244C9.84961 5.71436 9.94727 5.4751 10.1426 5.28467C10.3428 5.09424 10.6113 4.99902 10.9482 4.99902C11.2852 4.99902 11.5513 5.09424 11.7466 5.28467C11.9468 5.4751 12.0469 5.71436 12.0469 6.00244C12.0469 6.28564 11.9468 6.52246 11.7466 6.71289C11.5513 6.90332 11.2852 6.99854 10.9482 6.99854C10.6113 6.99854 10.3428 6.90332 10.1426 6.71289C9.94727 6.52246 9.84961 6.28564 9.84961 6.00244Z"
                        fill="#3862DD"
                      />
                    </svg>
                  ) : log?.action === "mail" ? (
                    <svg
                      onClick={() => {
                        settimelineDetails(log);
                        setmoreInfoModal(true);
                      }}
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="11" cy="11" r="10.5" stroke="#3862DD" />
                      <path
                        d="M11.9224 8.0752V16H9.98145V8.0752H11.9224ZM9.84961 6.00244C9.84961 5.71436 9.94727 5.4751 10.1426 5.28467C10.3428 5.09424 10.6113 4.99902 10.9482 4.99902C11.2852 4.99902 11.5513 5.09424 11.7466 5.28467C11.9468 5.4751 12.0469 5.71436 12.0469 6.00244C12.0469 6.28564 11.9468 6.52246 11.7466 6.71289C11.5513 6.90332 11.2852 6.99854 10.9482 6.99854C10.6113 6.99854 10.3428 6.90332 10.1426 6.71289C9.94727 6.52246 9.84961 6.28564 9.84961 6.00244Z"
                        fill="#3862DD"
                      />
                    </svg>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

export default OverviewTimeline;
