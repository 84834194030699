import React, { useEffect, useState } from "react";
import GroupDetailsHeader from "./GroupDetailsHeader";
import { useParams } from "react-router-dom";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { activityGroupRelatedEndpoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";
import AddActivityStatus from "./AddActivityStatus";
import GroupDetailsBody from "./GroupDetailsBody";
import NoData from "../../../../components/NoData/NoData";
import TaskModal from "./TaskModal";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import { useSelector } from "react-redux";

const GroupDetails = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const { id } = useParams();
  const [loader, setloader] = useState(true);
  const [loader2, setloader2] = useState(true);
  const [groupDetails, setGroupDetails] = useState([]);
  const [selectStatus, setselectStatus] = useState("");
  const [createStatus, setcreateStatus] = useState(false);
  const [editStatus, seteditStatus] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [allStatus, setAllStatus] = useState([]);
  const [taskModal, setTaskModal] = useState(false);
  const [statusTask, setStatusTask] = useState([]);

  const isModuleReadWriteAccessForActivityGroup =
    allModuleAccess?.[PRIVATEROUTES.ACTIVITY_GROUP];

  const handleGetGroupDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 10,
      filters: {
        businessId: getCurrentWorkspaceId(),
        _id: id,
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivityGroup,
      data
    );
    if (res?.success) {
      setGroupDetails(res?.group?.data?.[0]);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetAllStatus = async () => {
    startApiCall(seterrorMessage, setloader2);
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
        activityGroupStatus: selectStatus?._id,
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivitySubStatus,
      data
    );
    if (res?.success) {
      setAllStatus(res?.status?.data);
      setloader2(false);
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && selectStatus !== "") {
      handleGetAllStatus();
    }
  }, [selectStatus]);

  useEffect(() => {
    handleGetGroupDetails();
  }, []);

  useEffect(() => {
    if (!selectStatus && groupDetails?.activityGroupStatus?.length > 0) {
      const status = groupDetails?.activityGroupStatus?.find(
        (data) => data?.name === "open"
      );
      setselectStatus(status);
    }
  }, [groupDetails]);

  const handleRefresh = () => {
    handleGetGroupDetails();
    handleGetAllStatus();
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <GroupDetailsHeader
            handleRefresh={handleRefresh}
            groupDetails={groupDetails}
            loader={loader}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <div className="d-flex flex-column gap-3">
                  <p>
                    These are types of status that you can switch between while
                    handling a client. There are 3 default status given. You can
                    customise activities within the status. For example- The
                    open status can have 2 sub status like - meetings and quotes
                  </p>
                  {!loader && (
                    <ul className="d-flex align-items-center mb-0 px-0">
                      {groupDetails?.activityGroupStatus?.map((item) => {
                        return (
                          <li key={item?._id}>
                            <span
                              className={`d-flex align-items-center rounded-0 border px-7 py-2 pointer text-capitalize ${
                                item.name === selectStatus?.name
                                  ? "border-primary text-primary"
                                  : ""
                              }`}
                              onClick={() => {
                                setselectStatus(item);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill={item?.color}
                                className="bi bi-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <circle cx="8" cy="8" r="8" />
                              </svg>
                              &nbsp;
                              {item?.name}&nbsp;
                              {item?.name === selectStatus?.name ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-caret-down-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-caret-right-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                              )}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {loader && (
                    <div className="d-flex gap-2">
                      <SkeletonConstant width="w-160p" height="h-40p" />
                      <SkeletonConstant width="w-160p" height="h-40p" />
                      <SkeletonConstant width="w-160p" height="h-40p" />
                    </div>
                  )}
                  {isModuleReadWriteAccessForActivityGroup && (
                    <div>
                      {!loader && (
                        <span
                          className="btn btn-lg btn-outline-light"
                          onClick={() => {
                            setcreateStatus(true);
                          }}
                        >
                          + Create Status under {selectStatus?.name} status
                        </span>
                      )}
                      {loader && (
                        <SkeletonConstant width="w-240p" height="h-40p" />
                      )}
                    </div>
                  )}
                  <GroupDetailsBody
                    allStatus={allStatus}
                    loader={loader2}
                    seteditStatus={seteditStatus}
                    setcreateStatus={setcreateStatus}
                    setTaskModal={setTaskModal}
                    setStatusTask={setStatusTask}
                    isModuleReadWriteAccessForActivityGroup={
                      isModuleReadWriteAccessForActivityGroup
                    }
                  />
                  {!loader && !loader2 && allStatus?.length === 0 && (
                    <NoData title="No Status added" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {createStatus && (
          <AddActivityStatus
            id={id}
            selectStatus={selectStatus}
            editStatus={editStatus}
            seteditStatus={seteditStatus}
            setcreateStatus={setcreateStatus}
            handleGetAllStatus={handleGetAllStatus}
            handleGetGroupDetails={handleGetGroupDetails}
          />
        )}

        {taskModal && (
          <TaskModal
            setTaskModal={setTaskModal}
            statusTask={statusTask}
            editStatus={editStatus}
          />
        )}
      </div>
    </div>
  );
};

export default GroupDetails;
