import React from "react";

function MulitpleStockTable({ materialInTable, handleDeleteItem }) {
  const handleDeleteClick = (index) => {
    handleDeleteItem(index);
  };

  return (
    <div className="contact-list-view mt-2 mh-200p overflow-auto">
      <div className="table-responsive">
        <table className="table table-bordered text-center">
          <thead className="sticky-top">
            <tr>
              <th>No.</th>
              <th className="text-start">Item</th>
              <th>Category</th>
              <th>Quantity</th>
              <th>Cost</th>
              {/* <th>Vendor</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {materialInTable?.map((item, index) => (
              <tr key={index} className="text-center">
                <td>{index + 1} .</td>
                <td>{item?.inventoryItem?.name}</td>
                <td>{item?.category}</td>
                <td>
                  {item.quantity} {item?.unit}
                </td>
                <td>₹ {item?.cost}</td>
                {/* <td>{item?.vendorId}</td> */}
                <td onClick={() => handleDeleteClick(index)}>
                  <span className="btn btn-icon btn-rounded btn-sm">
                    <span nonce="feather-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                      </svg>
                    </span>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MulitpleStockTable;
