import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { navigateToStaffAttendanceDetails } from "../../../../Navigation/Navigation";
import { convertTotalHoursToHoursAndMinutes } from "../../../../global-functions/globalFunctions";

const AttendanceMonthly = (props) => {
  const { staffData, page, pageLimit, selectedMonth } = props;
  const navigate = useNavigate();

  return (
    <>
      {/* <div className="d-flex gap-3">
        <div className="card py-2 px-4 text-center rounded-4">
          <p className="text-dark fs-6">Total Present</p>
          <p className="text-success">40/50</p>
        </div>
        <div className="card py-2 px-4 text-center rounded-4">
          <p className="text-dark fs-6">Total Leaves</p>
          <p className="text-danger">06 / 200</p>
        </div>
      </div> */}
      <table id="datable_1" className="table table-bordered mb-8">
        <thead>
          <tr className="text-center">
            <th>No.</th>
            <th style={{ textAlign: "left" }}>Employee</th>
            <th>Settlement Type</th>
            <th>Total working days</th>
            <th>Total days present</th>
            <th>Total LOP days</th>
            <th>Present full days</th>
            <th>Present half day</th>
            <th>Leaves</th>
            <th>Absent</th>

            <th>Total hours</th>
          </tr>
        </thead>
        <tbody>
          {staffData?.map((data, index) => (
            <tr className="text-center">
              <td>{(page - 1) * pageLimit + (index + 1)} .</td>
              <td
                style={{ textAlign: "left" }}
                onClick={() =>
                  navigate(
                    navigateToStaffAttendanceDetails({
                      id: data?.staff?.employeeId?._id,
                    }),
                    {
                      state: {
                        selectedMonth: selectedMonth,
                      },
                    }
                  )
                }
              >
                <span
                  className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                  style={{ width: "220px" }}
                >
                  <Avatar
                    name={data?.staff?.employeeId?.name}
                    count={1}
                    image={data?.staff?.employeeId?.image}
                    size="xss"
                    color="primary"
                  />
                  &nbsp;{data?.staff?.employeeId?.name}
                </span>
              </td>
              <td>
                {data?.staff?.settlementType === "30_days"
                  ? "30 days"
                  : data?.staff?.settlementType === "dailyWages"
                  ? "Daily"
                  : data?.staff?.settlementType === "weeklyOff"
                  ? "Weekly Off"
                  : data?.staff?.settlementType === "custom"
                  ? "Custom"
                  : "-"}
              </td>
              <td>{data?.totalWorkingDays}</td>
              <td>{data?.totalPresentDays}</td>
              <td>{data?.totalLops}</td>
              <td>{data?.totalFullDays}</td>
              <td>{data?.totalHalfDays}</td>
              <td>{data?.totalLeaveDays}</td>
              <td>{data?.totalAbsentDays}</td>
              <td>
                {data
                  ? convertTotalHoursToHoursAndMinutes(data?.totalHours)
                  : 0}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default AttendanceMonthly;
