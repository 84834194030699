import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  AccountRelatedEndPoints,
  ExpensRelatedEndPoints,
  VendorRelatedEndPoints,
} from "../../../api/Endpoints";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddExpense = ({ editExpense, setEditExpense, handleGetAllExpense }) => {
  const user = useSelector((state) => state.user);
  const currentDate = new Date();
  const [loader2, setloader2] = useState(false);
  const [allAccount, setAllAccount] = useState([]);
  const [subAccType, setSubAccType] = useState([]);
  const [filter, setFilter] = useState("");
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // expense account dropdown
  const [accountName, setAccountName] = useState("");
  const [expenseAccId, setExpenseAccId] = useState("");

  // Paid through dropdown
  const [paidThrough, setPaidThrough] = useState("");
  const [paidThroughId, setPaidThroughId] = useState("");

  // Vendor dropdown
  const [allVendors, setAllVendors] = useState([]);
  const [selectVendor, setSelectVendor] = useState("");
  const [vendorId, setVendorId] = useState("");
  const filteredVendor = filterSearchData(allVendors, filter);

  useEffect(() => {
    if (editExpense) {
      setDate(reversesDateFormatter(editExpense?.date));
      setAmount(editExpense?.amount);
      setAccountName(editExpense?.expenseAccount?.accountName);
      setExpenseAccId(editExpense?.expenseAccount?._id);
      setPaidThrough(editExpense?.paidThrough?.accountName);
      setPaidThroughId(editExpense?.paidThrough?._id);
      setSelectVendor(editExpense?.vendor?.name);
      setVendorId(editExpense?.vendor?._id);
      setInvoiceNo(editExpense?.invoiceNumber);
      setDescription(editExpense?.notes);
    }
  }, [editExpense]);

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setInvoiceNo("");
    setAmount("");
    setDate("");
    setDescription("");
    setSelectVendor("");
    setAccountName("");
    setPaidThrough("");
    setEditExpense("");
    const closeModalDom = document.getElementById("add_expense");
    if (closeModalDom) closeModalDom.click();
  };

  const handleGetAllAccount = async () => {
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", AccountRelatedEndPoints.getAccount, data);
    if (res?.success) {
      setAllAccount(res?.account?.data);
    }
  };

  const handleGetSubAccount = async () => {
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      AccountRelatedEndPoints.getSubAccountType,
      data
    );
    if (res?.success) {
      setSubAccType(res?.accountType?.data);
    }
  };

  const handleGetAllVendor = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.getVendor, data);
    if (res?.success) {
      setAllVendors(res?.vendor?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllAccount();
      handleGetSubAccount();
    }
  }, []);

  // ADD EXPENSE FUNCTION API CALL
  const handleAddExpense = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      date,
      expenseAccount: expenseAccId,
      amount,
      paidThrough: paidThroughId,
      vendor: vendorId,
      invoiceNumber: invoiceNo,
      notes: description,
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall("post", ExpensRelatedEndPoints.addExpense, data);
    if (res?.success) {
      handleReset();
      handleGetAllExpense();
      toast.success("Add expense successfully!");
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  // EDIT EXPENSE FUNCTION API CALL
  const handleEditExpense = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      expenseId: editExpense?._id,
      expenseAccount: expenseAccId,
      amount,
      paidThrough: paidThroughId,
      vendor: vendorId,
      invoiceNumber: invoiceNo,
      notes: description,
    };
    const res = await ApiCall("post", ExpensRelatedEndPoints.editExpense, data);
    if (res?.success) {
      handleReset();
      handleGetAllExpense();
      toast.success("Edit expense successfully!");
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  return (
    <div
      id="add_expense"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-575p"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{editExpense === "" ? "Add Expense" : "Edit Expense"}</h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editExpense === "") {
                  handleAddExpense(e);
                } else {
                  handleEditExpense(e);
                }
              }}
            >
              <div className="mh-500p nicescroll-bar simple-scrollbar">
                <div className="row gx-3 w-100 mx-auto">
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Date {reuiredField}</label>
                      <input
                        className="form-control"
                        type="date"
                        required
                        disabled={editExpense}
                        value={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                        max={reversesDateFormatter(currentDate)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">
                        Amount {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        value={amount}
                        onChange={(e) => {
                          setAmount(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">
                        Expense Account {reuiredField}
                      </label>
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100`}
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => setFilter("")}
                          >
                            {accountName ? (
                              <div className="d-flex justify-content-between">
                                {accountName}
                                {accountName !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-2"
                                    onClick={() => {
                                      setAccountName("");
                                      setFilter("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Select account"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search.."
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                              />
                            </form>
                            <div
                              className="ps-2 py-2 simple-scrollbar mh-200p"
                              style={{ overflowY: "scroll" }}
                            >
                              {subAccType
                                ?.filter(
                                  (data) =>
                                    data?.mainAccountTypeName === "expense"
                                )
                                ?.map((data) => {
                                  return (
                                    <div className="ps-1 pt-1 text-capitalize">
                                      <span className="text-dark fw-bold">
                                        {data?.subAcountTypeName}
                                      </span>
                                      <div className="text-dark">
                                        {allAccount
                                          ?.filter(
                                            (acc) =>
                                              acc?.mainAccoutType ===
                                                "expense" &&
                                              acc?.subAccountType ===
                                                data?.subAcountTypeName &&
                                              acc?.accountName
                                                .toLowerCase()
                                                .includes(filter.toLowerCase())
                                          )
                                          ?.map((acc) => (
                                            <div
                                              key={acc?._id}
                                              className={`${
                                                accountName === acc?.accountName
                                                  ? "subtext-bg px-2 py-1 rounded d-flex align-items-center justify-content-between"
                                                  : "dropdown-item"
                                              }`}
                                              onClick={() => {
                                                setAccountName(
                                                  acc?.accountName
                                                );
                                                setExpenseAccId(acc?._id);
                                              }}
                                            >
                                              {acc?.accountName}
                                              {accountName ===
                                                acc?.accountName && (
                                                <span className="btn-icon-wrap">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="#0000FF"
                                                    className="bi bi-check2"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                  </svg>
                                                </span>
                                              )}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">
                        Paid Through {reuiredField}
                      </label>
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100`}
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => setFilter("")}
                          >
                            {paidThrough ? (
                              <div className="d-flex justify-content-between">
                                {paidThrough}
                                {paidThrough !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-2"
                                    onClick={() => {
                                      setPaidThrough("");
                                      setFilter("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Select account"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search.."
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                              />
                            </form>
                            <div
                              className="ps-2 py-2 mh-200p simple-scrollbar"
                              style={{ overflowY: "scroll" }}
                            >
                              {subAccType
                                ?.filter(
                                  (data) =>
                                    data?.mainAccountTypeName !== "expense" &&
                                    data?.mainAccountTypeName !== "income"
                                )
                                ?.map((data) => {
                                  return (
                                    <div className="ps-1 pt-1 text-capitalize">
                                      <span className="text-dark fw-bold">
                                        {data?.subAcountTypeName}
                                      </span>
                                      <div className="text-dark">
                                        {allAccount
                                          ?.filter(
                                            (acc) =>
                                              acc?.mainAccoutType !==
                                                "expense" &&
                                              data?.mainAccountTypeName !==
                                                "income" &&
                                              acc?.subAccountType ===
                                                data?.subAcountTypeName &&
                                              acc?.accountName
                                                .toLowerCase()
                                                .includes(filter.toLowerCase())
                                          )
                                          ?.map((acc) => (
                                            <div
                                              key={acc?._id}
                                              className={`${
                                                paidThrough === acc?.accountName
                                                  ? "subtext-bg px-2 py-1 rounded d-flex align-items-center justify-content-between"
                                                  : "dropdown-item"
                                              }`}
                                              onClick={() => {
                                                setPaidThrough(
                                                  acc?.accountName
                                                );
                                                setPaidThroughId(acc?._id);
                                              }}
                                            >
                                              {acc?.accountName}
                                              {paidThrough ===
                                                acc?.accountName && (
                                                <span className="btn-icon-wrap ms-auto">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="#0000FF"
                                                    className="bi bi-check2"
                                                    viewBox="0 0 16 16"
                                                  >
                                                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                  </svg>
                                                </span>
                                              )}
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Vendor</label>
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100`}
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => {
                              handleGetAllVendor();
                              setFilter("");
                            }}
                          >
                            {selectVendor ? (
                              <div className="d-flex justify-content-between">
                                {selectVendor}
                                {selectVendor !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-2"
                                    onClick={() => {
                                      setSelectVendor("");
                                      setFilter("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </div>
                            ) : (
                              "Select Vendor"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search.."
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                              />
                            </form>
                            {loader2 ? (
                              <span className="d-flex text-center mt-1">
                                Loading...
                              </span>
                            ) : (
                              <div
                                className="ps-2 py-2 simple-scrollbar mh-200p"
                                style={{ overflowY: "scroll" }}
                              >
                                {filteredVendor?.map((vendor) => (
                                  <div
                                    className="dropdown-item text-capitalize"
                                    key={vendor._id}
                                    onClick={() => {
                                      setSelectVendor(vendor?.name);
                                      setVendorId(vendor?._id);
                                    }}
                                  >
                                    {vendor.name}
                                  </div>
                                ))}
                              </div>
                            )}
                            {!loader2 && filteredVendor?.length === 0 && (
                              <span className="d-flex text-center mt-1">
                                No vendor found
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label className="form-label">Invoice#</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        value={invoiceNo}
                        onChange={(e) => {
                          setInvoiceNo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                      ></textarea>
                    </div>
                  </div>
                </div>

                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loader ? (
                      <ApiLoader />
                    ) : editExpense === "" ? (
                      "Add"
                    ) : (
                      "Edit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpense;
