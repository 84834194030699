import React, { useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  startApiCall,
  getName,
  dateFormatter,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { complaintEndPoints } from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import ComplaintDetailsModal from "./ComplaintDetails";
import { toast } from "react-toastify";
import SmallLoader from "../../../components/Loaders/SmallLoader";

const AllComplaintsCard = ({
  status,
  allComplaints,
  handleGetComplaints,
  setimage,
  pageLimit,
  page,
  isModuleReadWriteAccessAllGrievance,
}) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [complaintId, setComplaintId] = useState([]);
  const [isDetailsModalOpen, setisDetailsModalOpen] = useState(false);
  const [complaintDetails, setComplaintDetails] = useState([]);

  const handleCloseDetailsModal = () => {
    setisDetailsModalOpen(!isDetailsModalOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
    seterrorMessage("");
    setloader(false);
  };

  const handleUpdateStatus = async (menuItem, id) => {
    startApiCall(seterrorMessage, setloader);
    const data = {
      status:
        menuItem === "Process"
          ? "processing"
          : menuItem === "Resolve"
          ? "resolved"
          : menuItem === "Close"
          ? "closed"
          : menuItem === "Cancel"
          ? "cancelled"
          : menuItem === "Reopen"
          ? "open"
          : "",
      complaintId: id,
      userId: user?._id,
      lifeCycleName:
        menuItem === "Process"
          ? "Grievance processing"
          : menuItem === "Resolve"
          ? "Grievance Resolved"
          : menuItem === "Close"
          ? "Grievance Closed"
          : menuItem === "Cancel"
          ? "Grievance Cancelled"
          : menuItem === "Reopen"
          ? "Grievance Reopened"
          : "",
    };
    const res = await ApiCall(
      "post",
      complaintEndPoints.changeComplaintStatus,
      data
    );
    if (res?.success) {
      toast.success("Complaint status changed successfully!");
      handleGetComplaints();
      handleClose();
    } else {
      setloader(false);
      seterrorMessage(res?.error);
    }
  };

  return (
    <>
      <div className="contact-list-view mb-10">
        {allComplaints?.map((data, index) => {
          let openActionTime = "";
          let proccessActionTime = "";
          let resolvedActionTime = "";
          let closeActionTime = "";
          let cancelActionTime = "";

          const lastComplaintProcessingIndex =
            data?.complaintLifeCycle
              .map((item) => item?.lifeCycleName)
              .lastIndexOf("Grievance Reopened") !== -1
              ? data?.complaintLifeCycle
                  .map((item) => item?.lifeCycleName)
                  .lastIndexOf("Grievance Reopened")
              : data?.complaintLifeCycle
                  .map((item) => item?.lifeCycleName)
                  .lastIndexOf("Grievance Raised");

          const newComplaintLifeCycle = data.complaintLifeCycle.slice(
            lastComplaintProcessingIndex
          );
          newComplaintLifeCycle.map((lifeCycleEntry) => {
            if (lifeCycleEntry?.status === "open") {
              openActionTime = dateFormatter(lifeCycleEntry?.actionTime);
            } else if (lifeCycleEntry?.status === "processing") {
              proccessActionTime = dateFormatter(lifeCycleEntry?.actionTime);
            } else if (lifeCycleEntry?.status === "resolved") {
              resolvedActionTime = dateFormatter(lifeCycleEntry?.actionTime);
            } else if (lifeCycleEntry?.status === "closed") {
              closeActionTime = dateFormatter(lifeCycleEntry?.actionTime);
            } else if (lifeCycleEntry?.status === "cancelled") {
              cancelActionTime = dateFormatter(lifeCycleEntry?.actionTime);
            } else {
              return null;
            }
          });

          return (
            <div key={data._id} className=" d-flex">
              <span>
                <div className=" pt-2">
                  {(page - 1) * pageLimit + (index + 1)} . &nbsp;
                </div>
              </span>

              <div className="card py-3 px-4 my-complaint-card border-primary ">
                <div className="position-relative">
                  <span
                    className="position-absolute"
                    style={{ top: 0, right: "12px" }}
                  >
                    <div
                      className="text-decoration-underline pointer text-primary fw-semibold fs-8 d-flex align-items-center gap-1"
                      onClick={() => {
                        setComplaintDetails(data);
                        setisDetailsModalOpen(!isDetailsModalOpen);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="bi bi-info-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                      </svg>
                      <p>View Details</p>
                    </div>
                  </span>
                  <div className="row w-100">
                    <div className="col-lg-5 col-md-12 d-flex flex-column gap-3">
                      <div className="d-flex align-items-center gap-2">
                        <Avatar
                          name={
                            data?.raisedBy?.name ? data?.raisedBy?.name : "A"
                          }
                          image={
                            data?.raisedBy?.image ? data?.raisedBy?.image : ""
                          }
                          count={1}
                          size="xs"
                          color="primary"
                        />
                        <span>
                          <p className="text-dark fs-5">
                            {" "}
                            {data?.raisedBy
                              ? getName(data?.raisedBy, user?._id)
                              : "Anonymous"}
                          </p>
                          <p className="fs-7">
                            {" "}
                            Registered on :{" "}
                            {dateAndTimeFormatter(data?.createdAt)}
                          </p>
                        </span>
                      </div>
                      <span>
                        <p className="text-dark fs-4 fw-500 text-truncate w-400p">
                          Title : {data?.title}
                        </p>
                        <h6>Description : {data?.description}</h6>
                        {data?.images?.length > 0 ? (
                          <div className="d-flex align-items-center gap-3">
                            <span className="d-flex align-items-center gap-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M7.21772 6.8171C7.21772 7.81339 6.41007 8.62104 5.41378 8.62104C4.41749 8.62104 3.60984 7.81339 3.60984 6.8171C3.60984 5.82081 4.41749 5.01316 5.41378 5.01316C6.41007 5.01316 7.21772 5.82081 7.21772 6.8171Z"
                                  fill="#6F6F6F"
                                />
                                <path
                                  d="M2.40721 1.40527C1.07882 1.40527 0.00195312 2.48214 0.00195312 3.81053V15.8368C0.00195312 17.1652 1.07882 18.2421 2.40721 18.2421H16.8387C18.1671 18.2421 19.244 17.1652 19.244 15.8368V3.81053C19.244 2.48214 18.1671 1.40527 16.8387 1.40527H2.40721ZM16.8387 2.6079C17.5029 2.6079 18.0414 3.14634 18.0414 3.81053V11.6276L13.4998 9.28585C13.2683 9.1701 12.9887 9.21548 12.8057 9.39849L8.34373 13.8604L5.14601 11.7286C4.90752 11.5696 4.58995 11.6011 4.38727 11.8037L1.20458 14.6342V3.81053C1.20458 3.14634 1.74302 2.6079 2.40721 2.6079H16.8387Z"
                                  fill="#6F6F6F"
                                />
                              </svg>
                            </span>
                            <span
                              className="text-decoration-underline pointer text-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#view_img_modal"
                              onClick={() => {
                                setimage(data?.images?.[0]);
                              }}
                            >
                              View Images
                            </span>
                          </div>
                        ) : (
                          <p>No Image</p>
                        )}
                      </span>
                    </div>

                    <div className="col-lg-7 col-md-12 d-flex flex-column align-items-end justify-content-evenly gap-3 px-0 pt-lg-8">
                      <div className="d-flex flex-wrap">
                        {status === "open" && (
                          <>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Opened</p>
                                <p className="fs-8">{openActionTime}</p>
                              </div>
                            </span>
                          </>
                        )}
                        {status === "processing" && (
                          <>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Opened</p>
                                <p className="fs-8">{openActionTime}</p>
                              </div>
                            </span>
                            <svg
                              style={{ marginTop: "12px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="49"
                              height="2"
                              viewBox="0 0 49 2"
                              fill="none"
                            >
                              <path d="M0 1L49 1" stroke="#6F6F6F" />
                            </svg>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Processing</p>
                                <p className="fs-8">{proccessActionTime}</p>
                              </div>
                            </span>
                          </>
                        )}
                        {status === "resolved" && (
                          <>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Opened</p>
                                <p className="fs-8">{openActionTime}</p>
                              </div>
                            </span>
                            <svg
                              style={{ marginTop: "12px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="49"
                              height="2"
                              viewBox="0 0 49 2"
                              fill="none"
                            >
                              <path d="M0 1L49 1" stroke="#6F6F6F" />
                            </svg>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Processing</p>
                                <p className="fs-8">{proccessActionTime}</p>
                              </div>
                            </span>
                            <svg
                              style={{ marginTop: "12px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="49"
                              height="2"
                              viewBox="0 0 49 2"
                              fill="none"
                            >
                              <path d="M0 1L49 1" stroke="#6F6F6F" />
                            </svg>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Resolved</p>
                                <p className="fs-8">{resolvedActionTime}</p>
                              </div>
                            </span>
                          </>
                        )}
                        {status === "closed" && (
                          <>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Opened</p>
                                <p className="fs-8">{openActionTime}</p>
                              </div>
                            </span>
                            <svg
                              style={{ marginTop: "12px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="49"
                              height="2"
                              viewBox="0 0 49 2"
                              fill="none"
                            >
                              <path d="M0 1L49 1" stroke="#6F6F6F" />
                            </svg>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Processing</p>
                                <p className="fs-8">{proccessActionTime}</p>
                              </div>
                            </span>
                            <svg
                              style={{ marginTop: "12px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="49"
                              height="2"
                              viewBox="0 0 49 2"
                              fill="none"
                            >
                              <path d="M0 1L49 1" stroke="#6F6F6F" />
                            </svg>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Resolved</p>
                                <p className="fs-8">{resolvedActionTime}</p>
                              </div>
                            </span>
                            <svg
                              style={{ marginTop: "12px" }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="49"
                              height="2"
                              viewBox="0 0 49 2"
                              fill="none"
                            >
                              <path d="M0 1L49 1" stroke="#6F6F6F" />
                            </svg>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Closed</p>
                                <p className="fs-8">{closeActionTime}</p>
                              </div>
                            </span>
                          </>
                        )}
                        {status === "cancelled" && (
                          <>
                            <span className="d-flex gap-1">
                              <svg
                                className="mt-1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  fill="white"
                                  stroke="#00C172"
                                />
                              </svg>
                              <div className="d-flex flex-column">
                                <p className="text-dark fw-light">Cancelled</p>
                                <p className="fs-8">{cancelActionTime}</p>
                              </div>
                            </span>
                          </>
                        )}
                        {status === "" && (
                          <span className="d-flex gap-1 text-capitalize">
                            <svg
                              className="mt-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <circle
                                cx="8"
                                cy="8"
                                r="7.5"
                                fill="white"
                                stroke="#00C172"
                              />
                            </svg>
                            <div className="d-flex flex-column">
                              <p className="text-dark fw-light">
                                {data?.status === "open"
                                  ? "Opened"
                                  : data?.status}
                              </p>
                              <p className="fs-8">
                                {dateFormatter(data?.updatedAt)}
                              </p>
                            </div>
                          </span>
                        )}

                        {/* // ) : (
            //   status !== "resolved" && (
            //     <button
            //       type="submit"
            //       className="btn btn-sm btn-secondary"
            //       onClick={() => {
            //         setIsOpen(true);
            //         setComplaintId(data?._id);
            //       }}
            //     >
            //       {status === "pending" ? "Acknowledge" : "Resolve"}
            //     </button>
            //   )
            // )} */}
                      </div>
                      {data?.status === "closed" && (
                        <>
                          {data?.rating?.rating !== undefined && (
                            <span className="text-dark">
                              Rating : {data?.rating?.rating}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 54 51"
                                fill="none"
                                className="ms-1"
                              >
                                <path
                                  d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
                                  fill="#FFCE56"
                                  stroke="#FFCE56"
                                  stroke-width="2"
                                />
                              </svg>
                            </span>
                          )}
                        </>
                      )}

                      {isModuleReadWriteAccessAllGrievance && (
                        <div className="selectable-dropdown">
                          <div className="dropdown selectable-dropdown">
                            {!loader[index] && (
                              <span
                                className={`d-flex align-items-center justify-content-center gap-1 fw-medium dropdown-toggle text-capitalize btn-sm shadow-sm shadow-hover border ${
                                  data?.status === "processing"
                                    ? "text-purple border-purple"
                                    : data?.status === "resolved"
                                    ? "text-primary border-primary"
                                    : data?.status === "closed"
                                    ? "text-success border-success"
                                    : data?.status === "cancelled"
                                    ? "text-danger border-danger"
                                    : data?.status === "open"
                                    ? "text-sky border-sky"
                                    : "text-black"
                                }`}
                                aria-expanded="false"
                                type="button"
                                data-bs-toggle="dropdown"
                              >
                                {data?.status}
                              </span>
                            )}

                            {loader[index] && <SmallLoader />}
                            {!loader[index] && (
                              <div
                                role="menu"
                                className="dropdown-menu"
                                style={{
                                  maxHeight: "40vh",
                                  overflow: "auto",
                                }}
                              >
                                <span
                                  key="Process"
                                  className={`dropdown-item text-capitalize d-flex align-items-center gap-1 ${
                                    data?.status === "open"
                                      ? "text-purple"
                                      : "text-disabled"
                                  }`}
                                  onClick={() => {
                                    if (data?.status === "open") {
                                      handleUpdateStatus("Process", data?._id);
                                    }
                                  }}
                                >
                                  Process
                                </span>
                                <span
                                  key="Resolve"
                                  className={`dropdown-item text-capitalize d-flex align-items-center gap-1 ${
                                    data?.status === "processing"
                                      ? "text-primary"
                                      : "text-disabled"
                                  }`}
                                  onClick={() => {
                                    if (data?.status === "processing") {
                                      handleUpdateStatus("Resolve", data?._id);
                                    }
                                  }}
                                >
                                  Resolve
                                </span>
                                <span
                                  key="Close"
                                  className={`dropdown-item text-capitalize d-flex align-items-center gap-1 ${
                                    data?.status === "resolved"
                                      ? "text-success"
                                      : "text-disabled"
                                  }`}
                                  onClick={() => {
                                    if (data?.status === "resolved") {
                                      handleUpdateStatus("Close", data?._id);
                                    }
                                  }}
                                >
                                  Close
                                </span>
                                <span
                                  key="Cancel"
                                  className={`dropdown-item text-capitalize d-flex align-items-center gap-1 ${
                                    data?.status === "resolved" ||
                                    data?.status === "processing" ||
                                    data?.status === "open"
                                      ? "text-danger"
                                      : "text-disabled"
                                  }`}
                                  onClick={() => {
                                    if (
                                      data?.status === "resolved" ||
                                      data?.status === "processing" ||
                                      data?.status === "open"
                                    ) {
                                      handleUpdateStatus("Cancel", data?._id);
                                    }
                                  }}
                                >
                                  Cancel
                                </span>
                                <span
                                  key="Reopen"
                                  className={`dropdown-item text-capitalize d-flex align-items-center gap-1 ${
                                    data?.status === "closed" ||
                                    data?.status === "cancelled"
                                      ? "text-sky"
                                      : "text-disabled"
                                  }`}
                                  onClick={() => {
                                    if (
                                      data?.status === "closed" ||
                                      data?.status === "cancelled"
                                    ) {
                                      handleUpdateStatus("Reopen", data?._id);
                                    }
                                  }}
                                >
                                  Reopen
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <ConfirmModal
          isOpen={isOpen}
          onClose={handleClose}
          text={
            status === "pending"
              ? "Are You Sure You Want To Acknowledge This Complaint"
              : "Are You Sure You Want To Resolve This Complaint"
          }
          loader={loader}
          errorMessage={errorMessage}
          handleConfirm={handleUpdateStatus}
        />
      </div>
      {isDetailsModalOpen && (
        <ComplaintDetailsModal
          handleCloseDetailsModal={handleCloseDetailsModal}
          complaintDetails={complaintDetails}
          user={user}
        />
      )}
    </>
  );
};

export default AllComplaintsCard;
