import React, { useEffect, useState } from "react";
import loginImg from "../../../../assets/images/logo/bizhoodfullLogo.png";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { InventorySettingsRelatedEndPoints } from "../../../../api/Endpoints";

const PrintSalaryDetails = React.forwardRef((props, ref) => {
  const {
    salaryDetails,
    locationData,
    selectedMonth,
    totalDeductionAmount,
    totalAbsentAmount,
    totalPayableAmount,
  } = props;
  const [loader, setloader] = useState(false);
  const [bussinessProfileDetails, setBussinessProfileDetails] = useState([]);

  // GET INVOICE SETTING FUNCTION
  const handleGetBussinessInventoryDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.getInvoiceSetting,
      data
    );
    if (res?.success) {
      setBussinessProfileDetails(res?.setting);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetBussinessInventoryDetails();
    }
  }, []);
  return (
    <div ref={ref}>
      <div className="d-flex justify-content-between col-12">
        <div className="col-6 p-3 ms-2 mt-2">
          <img
            className="w-50"
            src={bussinessProfileDetails?.logo}
            alt="logo"
          />
        </div>
        <div className="col-6 p-3 mt-7">
          <div>{bussinessProfileDetails?.fullAddress}</div>

          <a href={bussinessProfileDetails?.website}>
            {bussinessProfileDetails?.website}
          </a>
        </div>
      </div>
      <p className="ms-9 ps-2 w-20 text-dark mt-7">Employee Summary</p>
      <div className="ms-8">
        <div className="d-flex col-md-8 ps-3 mt-2">
          <div className="col-md-6">Name</div>
          <div className="d-flex col-md-6">
            : &nbsp;{locationData?.employeeName}
          </div>
        </div>
        <div className="d-flex col-md-8 ps-3 mt-2">
          <div className="col-md-6">Pay Period</div>
          <div className="col-md-6">: &nbsp;{selectedMonth}</div>
        </div>
        <div className="d-flex col-md-8 ps-3 mt-2">
          <div className="col-md-6">Payment Type</div>
          <div className="col-md-6">
            : &nbsp;
            <span className="text-capitalize">
              {salaryDetails?.paymentType === "30_days"
                ? "30 days"
                : salaryDetails?.paymentType}
            </span>
          </div>
        </div>
        <div className="d-flex col-md-8 ps-3 mt-2">
          <div className="col-md-6">Settlement Date</div>
          <div className="col-md-6">
            : &nbsp;
            {salaryDetails?.settlementDate
              ? `${salaryDetails?.settlementDate} of every month`
              : "No Settlement date"}
          </div>
        </div>
      </div>
      <h3 className="mt-4 ms-10">Salary Slip</h3>
      <table
        id="datable_1"
        className="table nowrap w-75 table-bordered ms-12 me-5 mt-3"
      >
        <thead>
          <tr>
            <th>Description</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Monthly Salary</td>
            <td>
              ₹{" "}
              {salaryDetails?.monthlySalary
                ? salaryDetails?.monthlySalary?.toFixed(2)
                : 0}
            </td>
          </tr>
          <tr>
            <td>Payable Salary</td>
            <td>
              ₹{" "}
              {salaryDetails?.payableSalary
                ? salaryDetails?.payableSalary?.toFixed(2)
                : 0}
            </td>
          </tr>
          <tr>
            <td>Attendance Deductions</td>
            <td>₹ {totalAbsentAmount ? totalAbsentAmount?.toFixed(2) : 0}</td>
          </tr>
          <tr>
            <td>Other Deductions</td>
            <td>₹ {totalDeductionAmount ? totalDeductionAmount : 0}</td>
          </tr>
          {/* <tr>
            <td>Advance</td>
            <td>
              ₹ {salaryDetails?.advance ? salaryDetails?.advance.toFixed(2) : 0}
            </td>
          </tr> */}
          <tr>
            <td>Paid</td>
            <td>
              ₹ {salaryDetails?.paid ? salaryDetails?.paid.toFixed(2) : 0}
            </td>
          </tr>
          <tr>
            <td>Due</td>
            <td>₹ {(totalPayableAmount - salaryDetails?.paid)?.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Total Payble Salary</td>
            <td>
              ₹{" "}
              {totalDeductionAmount || totalAbsentAmount
                ? (
                    salaryDetails?.payableSalary -
                    totalDeductionAmount -
                    totalAbsentAmount
                  )?.toFixed(2)
                : salaryDetails?.payableSalary
                ? salaryDetails?.payableSalary?.toFixed(2)
                : 0}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});

export default PrintSalaryDetails;
