import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ChartOfAccount from "../../pages/AccountManagement/ChartOfAccounts/ChartOfAccount";

const ChartOfAccountRoutes = {
  path: "/account",
  element: <Navbar />,
  children: [
    {
      path: "chartofaccounts",
      element: <ChartOfAccount />,
    },
  ],
};

export default ChartOfAccountRoutes;
