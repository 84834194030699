const constants = {
  MAX_LENGTH: 35,
  SPACE_MAX_LENGTH: 25,
  ROLE_MAX_LENGTH: 15,
  FIRST_NAME_MAX_LENGTH: 12,
  LAST_NAME_MAX_LENGTH: 18,
  FULL_NAME_LENGTH: 30,
  ATTACHMENTLIMIT_TASK:3,
  DESCRIPTION_REASON: 120,
  HOLIDAY_MAX_LENGTH: 24,
};

export default constants;
