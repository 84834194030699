import { monthNames } from "../../../../global-functions/globalFunctions";
import { Avatar } from "../../../../components/Avatar/Avatar";

const AttendanceDetailsCalendar = (props) => {
  const {
    currentYear,
    currentMonth,
    firstDayOfMonth,
    attendanceDetails,
    presentDays,
    setCurrentMonth,
    setCurrentYear,
    absentDays,
    setSelectedDate,
    selectedDate,
    halfDay,
    leavedays,
    holiday,
  } = props;
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const handleDateClick = (day) => {
    const selectedDateObj = new Date(currentYear, currentMonth, day + 1);
    const formattedDate = selectedDateObj.toISOString().split("T")[0];
    setSelectedDate(formattedDate);
  };
  const handleCheckNextMonthButtonValidation = () => {
    let calendarSwitchingMonth = new Date(
      currentYear,
      currentMonth
    ).toLocaleDateString("en-US", {
      month: "long",
      year: "numeric",
    });

    let latestMonth =
      monthNames[new Date().getMonth()] + " " + new Date().getFullYear();
    return calendarSwitchingMonth === latestMonth ? true : false;
  };

  const handleColorForDates = (formattedDate) => {
    const todayDate = new Date().toISOString().split("T")[0];
    let d = new Date(formattedDate);

    // Check if formattedDate is greater than todayDate and none of the other conditions are true
    if (
      formattedDate > todayDate &&
      !presentDays?.includes(formattedDate) &&
      !leavedays?.includes(formattedDate) &&
      !halfDay?.includes(formattedDate) &&
      !absentDays?.includes(formattedDate) &&
      !holiday?.includes(formattedDate) &&
      !attendanceDetails?.staff?.offDays?.includes(daysOfWeek[d.getDay()])
    ) {
      return "bg-grey-light-5 text-disabled";
    }

    // ******** WEEK OF COLOR ********
    if (attendanceDetails?.staff?.offDays?.includes(daysOfWeek[d.getDay()])) {
      return "calender-weeklyoff text-white";
    } else if (presentDays?.includes(formattedDate)) {
      return "calender-present text-white";
    } else if (leavedays?.includes(formattedDate)) {
      return "calender-leave text-white";
    } else if (halfDay?.includes(formattedDate)) {
      return "calender-halfday text-white";
    } else if (absentDays?.includes(formattedDate)) {
      return "calender-absent text-white";
    } else if (holiday?.includes(formattedDate)) {
      return "calender-holiday text-white";
    }
  };

  const generateCalendar = () => {
    const calendar = [];

    calendar.push(
      <tr key="header">
        {daysOfWeek.map((day) => (
          <th className="ps-1 pe-1" key={day}>
            {day}
          </th>
        ))}
      </tr>
    );

    let dayCounter = 1;

    for (let i = 0; i < 6; i++) {
      const row = [];

      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDayOfMonth) || dayCounter > daysInMonth) {
          row.push(<td key={`empty-${j}`}></td>);
        } else {
          const day = dayCounter;
          const currentDate = new Date(currentYear, currentMonth, day);
          const isCurrentDay =
            currentDate.toDateString() === new Date().toDateString();
          const selectedDateObj = new Date(currentYear, currentMonth, day + 1);
          const formattedDate = selectedDateObj.toISOString().split("T")[0];
          const todayDate = new Date().toISOString().split("T")[0];
          row.push(
            <td key={`day-${dayCounter}`} className="px-0 m-auto">
              <div
                className={`d-flex align-items-center justify-content-center mx-auto circle pointer ${handleColorForDates(
                  formattedDate
                )} ${
                  selectedDate === formattedDate ? "bg-primary text-white" : ""
                } ${
                  isCurrentDay
                    ? "border border-primary text-dark"
                    : formattedDate <= todayDate
                    ? "text-dark"
                    : ""
                }
                
                `}
                style={{
                  height: "30px",
                  width: "30px",
                  boxShadow:
                    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                }}
                // formattedDate > todayDate &&
                // !leavedays?.includes(formattedDate) &&
                // !holiday?.includes(formattedDate)
                //   ? "bg-grey-light-5 text-black"
                //   : ""

                onClick={() => {
                  if (
                    formattedDate <= todayDate ||
                    holiday?.includes(formattedDate)
                  ) {
                    handleDateClick(day);
                  }
                }}
              >
                {selectedDate === formattedDate ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>
                ) : (
                  day
                )}
              </div>
            </td>
          );
          dayCounter++;
        }
      }

      calendar.push(<tr key={`row-${i}`}>{row}</tr>);
    }

    return calendar;
  };

  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
    setSelectedDate("");
  };

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
    setSelectedDate("");
  };

  return (
    <div className="d-flex flex-column align-items-center text-center text-dark border rounded-4 p-4 pb-0 w-100 h-100%">
      <p className="fw-semibold">
        <Avatar
          name={attendanceDetails?.staff?.employeeId?.name}
          count={1}
          size="xss"
          color="primary"
        />
        &nbsp;
        {attendanceDetails?.staff?.employeeId?.name} Attendance{" "}
      </p>
      <div className="d-flex flex-wrap align-items-center gap-3 mt-3">
        <span className="d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            className="bi bi-circle-fill text-success"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
          <p className="fs-8 mb-0">Present</p>
        </span>
        <span className="d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="#FFAC99"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
          <p className="fs-8 mb-0">Absent</p>
        </span>
        <span className="d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="#FB1010"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
          <p className="fs-8 mb-0">Leave</p>
        </span>
        <span className="d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="#FFC207"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
          <p className="fs-8 mb-0">Half day</p>
        </span>
        <span className="d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="#C45AD0"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
          <p className="fs-8 mb-0">Weekly Off</p>
        </span>
        <span className="d-flex align-items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="rgb(221, 97, 69)"
            className="bi bi-circle-fill"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="8" r="8" />
          </svg>
          <p className="fs-8 mb-0">Holiday</p>
        </span>
      </div>

      <div className="rounded-4 mt-2 w-100">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex justify-content-between">
            <button
              onClick={goToPreviousMonth}
              className="btn btn-icon btn-rounded"
            >
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                </svg>
              </span>
            </button>
            <h4 className="mt-1">
              {new Date(currentYear, currentMonth).toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </h4>
            <button
              disabled={handleCheckNextMonthButtonValidation()}
              onClick={goToNextMonth}
              className="btn btn-icon btn-rounded"
            >
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-caret-right-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
              </span>
            </button>
          </div>
          <table className="table mb-0 text-center">
            <thead>{generateCalendar()[0]}</thead>
            <tbody>{generateCalendar().slice(1)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default AttendanceDetailsCalendar;
