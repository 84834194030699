import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import MyTasks from "../../pages/MyTasks/MyTasks";

const MyTasksRoutes = {
  path: "/task",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <MyTasks />,
    },
    {
      path: "mytasks",
      element: <MyTasks />,
    },
  ],
};

export default MyTasksRoutes;
