import React from "react";
import { navigateToLeaveHistory } from "../../../../../Navigation/Navigation";
import { Avatar } from "../../../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";

const LeaveHistoryDetailsHeader = ({
  locationData,
  setApplyLeave,
  selectedMonth,
  handleMonthChange,
  monthNames,
  handleGetLeaveDetailsForStaff,
}) => {
  const navigate = useNavigate();
  
  return (
    <>
      <header className="todo-header">
        <div className="d-flex align-items-center gap-2">
          <span
            className="pointer"
            onClick={() => navigate(navigateToLeaveHistory())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </span>
          <Avatar
            name={locationData?.name}
            count={1}
            size="xss"
            color="primary"
            image={locationData?.image}
          />
          <p className="text-dark text-capitalize fs-5 fw-light">
            {locationData?.name}
          </p>
          <div className="ms-3">
            <button
              className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
              onClick={() => setApplyLeave(true)}
            >
              Add Leave
            </button>
          </div>
        </div>
        <div className="todo-options-wrap">
          <div>
            <select
              className="form-select form-select"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {monthNames?.map((mon) => {
                return <option>{mon}</option>;
              })}
            </select>
          </div>
          <div className="v-separator d-lg-inline-block d-none"></div>
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
            data-bs-original-title="Refresh"
          >
            <span
              className="btn-icon-wrap"
              onClick={handleGetLeaveDetailsForStaff}
            >
              <span className="feather-icon">
                <i className="ri-refresh-line"></i>
              </span>
            </span>
          </span>
        </div>
      </header>
    </>
  );
};

export default LeaveHistoryDetailsHeader;
