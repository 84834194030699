import React from "react";
import { convertAmountLocal } from "../../../global-functions/globalFunctions";

function CashInHandHeader({
  setcashModal,
  handleGetCashCounterTransaction,
  transactionsAmount,
  settypeFilter,
  typeFilter,
  setstartDate,
  startDate,
  endDate,
  setendDate,
  currentDate,
  handleResetFilters,
  loader,
  isModuleReadWriteAccessForCashInHand,
  currentWorkSpaceRole,
}) {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1>Cash In Hand</h1>

          <h5 className="text-primary mb-0  font-mon ms-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-wallet"
              viewBox="0 0 16 16"
            >
              <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a2 2 0 0 1-1-.268M1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1" />
            </svg>
            &nbsp;
            {convertAmountLocal(transactionsAmount || 0)}
          </h5>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="me-3">
          <button
            className="btn btn-md btn-primary flex-shrink-0 d-lg-inline-block header-button-shadow"
            onClick={() => {
              setcashModal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#fff"
              className="bi bi-wallet2"
              viewBox="0 0 16 16"
            >
              <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
            </svg>
            &nbsp; Adjust Cash
          </button>
        </div>

        <div className="me-2">
          <select
            value={typeFilter}
            onChange={(e) => settypeFilter(e.target.value)}
            className="form-select form-select"
          >
            <option value="">All</option>
            <option value="income">Cash In</option>
            <option value="expense">Cash Out</option>
          </select>
        </div>
        {isModuleReadWriteAccessForCashInHand && (
          <>
            <input
              className="form-control w-150p"
              name="single-date-pick"
              type="date"
              value={startDate}
              onChange={(e) => {
                setstartDate(e.target.value);
              }}
              max={endDate}
            />
            <p className="mt-2 ms-2 me-2 fw-bold text-dark">to</p>
            <input
              className="form-control w-150p"
              name="single-date-pick"
              type="date"
              value={endDate}
              onChange={(e) => {
                setendDate(e.target.value);
              }}
              min={startDate}
              max={currentDate}
            />
            <button
              disabled={loader}
              onClick={handleGetCashCounterTransaction}
              className="btn btn-primary btn-xs btn-outline-light flex-shrink-0 d-lg-inline-block   ms-2 pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                fill="#000"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </>
        )}

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleResetFilters}>
            <span className="feather-icon">
              <i
                className="ri-refresh-line"
                title="Refresh Transaction list"
              ></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
}

export default CashInHandHeader;
