import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import {
  getCurrentWorkspaceId,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { CashInOutRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

function AddCashModal({
  setcashModal,
  handleGetCashCounterTransaction,
  currentDate,
  editCash,
  setEditCash,
}) {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [type, settype] = useState("in");
  const [date, setdate] = useState(currentDate);
  const [errorMessage, setErrorMessage] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const currentTime = moment().format("HH:mm");
  const [currentType, setCurrentType] = useState("");

  useEffect(() => {
    if (editCash) {
      settype(editCash?.accountHeader === "income" ? "in" : "out");
      setDescription(editCash?.description);
      setdate(reversesDateFormatter(editCash?.date));
      setAmount(editCash?.amountReceived);
      setCurrentType(editCash?.accountHeader === "income" ? "in" : "out");
    }
  }, [editCash]);

  const handleReset = () => {
    settype("in");
    setdate(currentDate);
    setErrorMessage("");
    setDescription("");
    setAmount("");
    setcashModal(false);
    setEditCash(null);
    setloader(false);
  };

  // GET ADD CASH  API CALL
  const handleAdjustCash = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      amount,
      date: date + " " + currentTime,
      isAdding: type === "in" ? true : false,
      transactionDoneBy: user?._id,
      description,
    };
    const res = await ApiCall(
      "post",
      CashInOutRelatedEndPoints.adjustCash,
      data
    );
    if (res?.success) {
      if (type === "in") {
        toast.success("Cash In Successfully");
      } else {
        toast.success("Cash Out Successfully");
      }
      handleGetCashCounterTransaction();
      handleReset();
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  const handleEditCash = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    let debitFrom = editCash?.debitFrom,
      debitRefKey = editCash?.debitRefKey,
      creditRefKey = editCash?.creditRefKey,
      creditTo = editCash?.creditTo,
      tempRef,
      tempFrom;

    if (currentType !== type) {
      tempRef = editCash?.creditRefKey ? editCash?.creditRefKey : null;
      tempFrom = editCash?.creditTo ? editCash?.creditTo : null;
      creditRefKey = editCash?.debitRefKey ? editCash?.debitRefKey : null;
      creditTo = editCash?.debitFrom ? editCash?.debitFrom : null;
      debitRefKey = tempRef;
      debitFrom = tempFrom;
    }
    const data = {
      mainTransactionId: editCash?._id,
      accountHeader: type === "in" ? "income" : "expense",
      oldAccountHeader: editCash?.accountHeader,
      date: date + " " + currentTime,
      oldMainAmount: editCash?.amountReceived,
      oldAmountReceived: editCash?.amountReceived,
      mainAmount: parseFloat(amount),
      amountReceived: parseFloat(amount),
      changedAmount: Math.abs(parseFloat(amount) - editCash?.amountReceived),
      description,
      businessId: getCurrentWorkspaceId(),
      debitFrom,
      debitRefKey,
      creditRefKey,
      creditTo,
    };
    const res = await ApiCall(
      "post",
      CashInOutRelatedEndPoints.editTransaction,
      data
    );
    if (res?.success) {
      toast.success("Edit Transaction Successfully");
      handleGetCashCounterTransaction();
      handleReset();
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{editCash ? "Edit Cash In/ Out" : "Cash In/ Out"}</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (editCash) {
                  handleEditCash(e);
                } else {
                  handleAdjustCash(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="d-flex justify-content-start gap-2 mb-3 mt-2">
                  <button
                    type="button"
                    className={`border rounded-4 p-2 bg-white ${
                      type === "in" ? "border-primary" : ""
                    }`}
                    onClick={() => settype("in")}
                  >
                    <span className="border px-2 me-2 bg-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="#FFFFFF"
                        className="bi bi-plus-lg mb-1"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                        />
                      </svg>
                    </span>
                    Cash In
                  </button>
                  <button
                    type="button"
                    className={`border rounded-4 p-2 bg-white ${
                      type === "out" ? "border-primary" : ""
                    }`}
                    onClick={() => settype("out")}
                  >
                    <span className="border px-2 me-2 bg-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="#FFFFFF"
                        className="bi bi-dash-lg mb-1 "
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                        />
                      </svg>
                    </span>
                    Cash Out
                  </button>
                </div>

                <div className="col-sm-6 mt-2">
                  <div className="form-group">
                    <label className="form-label">
                      Amount ₹ {reuiredField}
                    </label>

                    <input
                      className="form-control w-100"
                      type="number"
                      required
                      value={amount}
                      placeholder="Enter Amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-6 mt-2">
                  <div className="form-group">
                    <label className="form-label">Date {reuiredField}</label>

                    <input
                      className="form-control w-100"
                      type="date"
                      required
                      value={date}
                      onChange={(e) => setdate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <div className="form-group">
                    <label className="form-label"> Note : {reuiredField}</label>

                    <textarea
                      className="form-control"
                      rows="3"
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      value={description}
                      maxLength={500}
                      required
                      placeholder="Enter Note here"
                    ></textarea>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              </div>
              <div className="modal-footer align-items-center mt-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader}
                >
                  {loader ? (
                    <ApiLoader />
                  ) : type === "out" ? (
                    "Cash Out"
                  ) : (
                    "Cash In"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCashModal;
