import React, { useEffect, useState } from "react";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";

const CartSection = (props) => {
  const {
    selectedCustomer,
    setselectedCustomer,
    selectedMenuItems,
    handleAddItem,
    handleRemoveItem,
    handleDeleteItem,
    setPayloadItems,
    setsubTotal,
    subTotal,
    setpayableAmount,
    uniqueItems,
    setUniqueItems,
    customerData,
    setcustomerData,
    allCustomers,
    setaddCustomerModal,
    setcustomerType,
    customerType,
    setcostPrice,
    locationData,
    handleGetAllCustomer,
    customerDataLoader,
    orderDetailsLoader,
    isModuleReadWriteAccessForOrder,
    setcustomizeModal,
    setcustomizesItemId,
    getCustomizationItem,
    removeCustomizationItem,
    customizedItem,
    calculateCustomizeCostPrice,
    orderDetails,
  } = props;
  const [customerNameFilter, setcustomerNameFilter] = useState("");

  const filteredCustomers = allCustomers?.filter(
    (cust) =>
      cust?.name?.toLowerCase()?.includes(customerNameFilter?.toLowerCase()) ||
      cust?.phone?.includes(customerNameFilter)
  );

  useEffect(() => {
    const updatedUniqueItems = selectedMenuItems?.reduce((acc, item) => {
      const existingItem = acc?.find(
        (uniqueItem) => uniqueItem?._id === item?._id
      );

      if (existingItem) {
        existingItem.count += 1;
        existingItem.itemPrice =
          existingItem?.sellingPrice * existingItem?.count;
        existingItem.customizedItems = existingItem?.customizedItems;
      } else {
        acc.push({
          ...item,
          count: 1,
          itemPrice: item?.sellingPrice,
        });
      }

      return acc;
    }, []);

    const updatedPayloadItems = updatedUniqueItems?.map(({ _id, count }) => ({
      productId: _id,
      quantity: count,
    }));

    setPayloadItems(updatedPayloadItems);
    setUniqueItems(updatedUniqueItems);
  }, [selectedMenuItems]);

  useEffect(() => {
    const total = uniqueItems.reduce((subtotal, item) => {
      return subtotal + item?.itemPrice + calculateCustomizeTotal(item?._id);
    }, 0);
    const CostPricetotal = uniqueItems.reduce((costprice, item) => {
      return costprice + item?.costPrice * item.count;
    }, 0);

    setcostPrice(
      parseInt(CostPricetotal?.toFixed(2)) + calculateCustomizeCostPrice()
    );
    setpayableAmount(total);
    setsubTotal(total.toFixed(2));
  }, [uniqueItems, customizedItem]);

  const calculateCustomizeTotal = (productId) => {
    const total = getCustomizationItem(productId).reduce((accumulator, dt) => {
      const value = dt.price;
      return accumulator + parseInt(value);
    }, 0);
    return parseInt(total);
  };

  return (
    <div className="d-flex flex-column gap-3 h-100">
      {!orderDetailsLoader && (
        <>
          <form className="d-flex gap-2" style={{ width: "98.5%" }}>
            <div className="selectable-dropdown w-65">
              <div className="dropdown selectable-dropdown w-100">
                <span
                  className={`me-2 fs-7 fw-medium ${
                    customerType === "guest" ? "text-disabled" : "text-dark"
                  } text-capitalize border rounded-4 w-100`}
                  style={{ padding: "0.8rem 0.5rem" }}
                  aria-expanded="false"
                  type="button"
                  data-bs-toggle="dropdown"
                  onClick={() => {
                    handleGetAllCustomer();
                    setcustomerNameFilter("");
                  }}
                  disabled={customerType === "guest"}
                >
                  {selectedCustomer ? (
                    <>
                      {customerData?.name}
                      {selectedCustomer !== "" && (
                        <button
                          type="button"
                          className="btn-close btn-sm ms-1"
                          onClick={() => {
                            setselectedCustomer("");
                            setcustomerNameFilter("");
                            setcustomerData([]);
                            setcustomerType("");
                          }}
                          aria-label="Close"
                        ></button>
                      )}
                    </>
                  ) : (
                    "Select Customer"
                  )}
                </span>
                <div role="menu" className="dropdown-menu">
                  <form className="d-sm-block d-none" role="search">
                    <input
                      type="text"
                      placeholder="Search.."
                      className="form-control"
                      value={customerNameFilter}
                      onChange={(e) => setcustomerNameFilter(e.target.value)}
                    />
                  </form>
                  {customerDataLoader ? (
                    <span className="d-flex text-center mt-1">Loading...</span>
                  ) : (
                    <div
                      style={{
                        maxHeight: "30vh",
                        overflow: "auto",
                      }}
                    >
                      {filteredCustomers?.map((customer) => (
                        <div
                          className="dropdown-item text-capitalize"
                          key={customer._id}
                          onClick={() => {
                            setselectedCustomer(customer._id);
                            setcustomerData(customer);
                            setcustomerType("customer");
                          }}
                        >
                          {customer?.name} ({customer?.phone})
                        </div>
                      ))}
                    </div>
                  )}
                  {!customerDataLoader && filteredCustomers?.length === 0 && (
                    <span className="d-flex text-center mt-1">
                      No Customers found
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 w-35">
              <button
                className={`btn btn-xs w-55 ${
                  customerType === "guest"
                    ? "btn-primary text-white"
                    : customerType === "customer"
                    ? "bg-light text-disabled border-dark"
                    : "btn border-dark text-dark"
                } d-lg-inline-block`}
                disabled={customerType === "customer"}
                type="button"
                onClick={() => {
                  setcustomerType(customerType === "" ? "guest" : "");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  fill="currentColor"
                  class="bi bi-person"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                </svg>
                &nbsp;Guest
              </button>
              {isModuleReadWriteAccessForOrder && (
                <button
                  className="btn btn-xs btn-outline-secondary d-lg-inline-block w-45 border-dark"
                  type="button"
                  onClick={() => {
                    setaddCustomerModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 13 13"
                    fill="current"
                    className="mb-1"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.5 0C6.79915 0 7.04167 0.242512 7.04167 0.541667V5.95833H12.4583C12.7575 5.95833 13 6.20085 13 6.5C13 6.79915 12.7575 7.04167 12.4583 7.04167H7.04167V12.4583C7.04167 12.7575 6.79915 13 6.5 13C6.20085 13 5.95833 12.7575 5.95833 12.4583V7.04167H0.541667C0.242512 7.04167 0 6.79915 0 6.5C0 6.20085 0.242512 5.95833 0.541667 5.95833H5.95833V0.541667C5.95833 0.242512 6.20085 0 6.5 0Z"
                      fill="black"
                    />
                  </svg>
                  &nbsp;Add
                </button>
              )}
            </div>
          </form>

          {orderDetails?.tableId?.tableNumber && (
            <div className="d-flex justify-content-center">
              <div
                className="alert alert-info text-primary fs-7"
                role="alert"
                style={{ marginBottom: 0 }}
              >
                Selected Table Number :
                <strong> {orderDetails?.tableId?.tableNumber}</strong>
              </div>
            </div>
          )}

          <div
            className="d-flex flex-column gap-2 overflow-y-scroll"
            style={{ height: orderDetails?.tableId?.tableNumber ? "40vh" : "50vh" }}
          >
            {uniqueItems?.map((item, index) => {
              return (
                <div className="d-flex align-items-center" key={index}>
                  <div
                    className="form-control"
                    style={{ padding: "0.6rem 0.5rem" }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0  text-truncate mw-175p">
                        {index + 1}. {item?.productName}
                      </p>
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-xs border h-25p py-0 rounded-end-0"
                            onClick={() => {
                              handleRemoveItem(item);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="2"
                              viewBox="0 0 10 2"
                              fill="none"
                            >
                              <path
                                d="M1.01172 1.16675H8.74249"
                                stroke="black"
                                strokeWidth="1.16617"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <span
                            className="btn btn-sm text-white"
                            style={{
                              background: "#FA2A2A",
                              borderRadius: " 5.58333px",
                              boxShadow:
                                "inset 0px 5.58333px 5.58333px rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            {item?.count}
                          </span>
                          <button
                            className="btn btn-xs border h-25p py-0 rounded-start-0"
                            onClick={() => handleAddItem(item)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="13"
                              viewBox="0 0 15 13"
                              fill="none"
                            >
                              <path
                                d="M7.79883 3.05322V10.3813"
                                stroke="black"
                                strokeWidth="0.956384"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M3.84766 6.72681H11.99"
                                stroke="black"
                                strokeWidth="0.956384"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                        <p className="mb-0">₹ {item?.itemPrice}</p>
                      </div>
                    </div>

                    <div className="col-sm-12 ">
                      {getCustomizationItem(item?._id)?.map((material) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-x-circle pointer text-danger"
                                viewBox="0 0 16 16"
                                onClick={() => {
                                  removeCustomizationItem(material);
                                }}
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                              </svg>
                              <p className="mb-1 ms-2 text-capitalize text-smoke text-truncate mw-125p font-2">
                                {material?.quantity +
                                  " " +
                                  material?.unit?.name}
                                . {material?.inventoryItem?.name}
                              </p>
                            </div>

                            <div className="d-flex align-items-center justify-content-end gap-2">
                              <p className="mb-0 text-smoke font-2">
                                + &nbsp; ₹ {material?.price}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="col-sm-12 d-flex justify-content-between">
                      <button
                        className="btn btn-xs order-customize-button"
                        onClick={() => {
                          setcustomizeModal(true);
                          setcustomizesItemId(item?._id);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          class="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                        &nbsp; Additionals
                      </button>
                      {getCustomizationItem(item?._id)?.length > 0 && (
                        <div className="d-flex align-items-center justify-content-end gap-2">
                          <p className="mb-0 fw-bold">
                            ₹{" "}
                            {parseInt(item?.itemPrice) +
                              calculateCustomizeTotal(item?._id)}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <span
                    className="btn btn-icon"
                    onClick={() => {
                      handleDeleteItem(item);
                    }}
                  >
                    <span className="feather-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="currentColor"
                      >
                        <path
                          d="M8.53125 1.3125H12.4688C12.8312 1.3125 13.125 1.60631 13.125 1.96875V3.28125H7.875V1.96875C7.875 1.60631 8.16881 1.3125 8.53125 1.3125ZM14.4375 3.28125V1.96875C14.4375 0.88144 13.5561 0 12.4688 0H8.53125C7.44394 0 6.5625 0.881439 6.5625 1.96875V3.28125H3.28867C3.28421 3.2812 3.27974 3.2812 3.27526 3.28125H1.96875C1.60631 3.28125 1.3125 3.57506 1.3125 3.9375C1.3125 4.29994 1.60631 4.59375 1.96875 4.59375H2.6754L3.79465 18.5843C3.90379 19.9485 5.04272 21 6.41129 21H14.5887C15.9573 21 17.0962 19.9485 17.2053 18.5843L18.3246 4.59375H19.0312C19.3937 4.59375 19.6875 4.29994 19.6875 3.9375C19.6875 3.57506 19.3937 3.28125 19.0312 3.28125H17.7247C17.7203 3.2812 17.7158 3.2812 17.7113 3.28125H14.4375ZM17.0079 4.59375L15.897 18.4797C15.8425 19.1618 15.273 19.6875 14.5887 19.6875H6.41129C5.727 19.6875 5.15754 19.1618 5.10297 18.4797L3.9921 4.59375H17.0079ZM7.18021 5.90738C7.54203 5.8861 7.85258 6.16215 7.87387 6.52396L8.53012 17.6802C8.5514 18.042 8.27535 18.3526 7.91354 18.3739C7.55172 18.3952 7.24117 18.1191 7.21988 17.7573L6.56363 6.60104C6.54235 6.23922 6.8184 5.92867 7.18021 5.90738ZM13.8198 5.90738C14.1816 5.92867 14.4577 6.23922 14.4364 6.60104L13.7801 17.7573C13.7588 18.1191 13.4483 18.3952 13.0865 18.3739C12.7247 18.3526 12.4486 18.042 12.4699 17.6802L13.1261 6.52396C13.1474 6.16215 13.458 5.8861 13.8198 5.90738ZM10.5 5.90625C10.8624 5.90625 11.1562 6.20006 11.1562 6.5625V17.7188C11.1562 18.0812 10.8624 18.375 10.5 18.375C10.1376 18.375 9.84375 18.0812 9.84375 17.7188V6.5625C9.84375 6.20006 10.1376 5.90625 10.5 5.90625Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
          <div className="d-flex flex-column gap-3">
            <div
              className="d-flex flex-column gap-2 border py-3 px-3 text-dark"
              style={{ height: "15vh", overflowY: "scroll" }}
            >
              <div className="d-flex justify-content-between">
                <p>Subtotal</p>
                <p>₹ {subTotal ? subTotal : 0}</p>
              </div>
              {isModuleReadWriteAccessForOrder && (
                <div className="d-flex justify-content-end w-100 mt-2">
                  <button
                    className="btn btn-sm btn-primary"
                    type="submit"
                    data-bs-toggle="modal"
                    data-bs-target="#check_order_modal"
                    disabled={
                      !selectedMenuItems?.length ||
                      (customerType === "" && !customerData?.length)
                    }
                  >
                    Checkout&nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 5C0 4.72386 0.223858 4.5 0.5 4.5L12.2929 4.5L9.14645 1.35355C8.95118 1.15829 8.95118 0.841708 9.14645 0.646446C9.34171 0.451183 9.65829 0.451183 9.85355 0.646446L13.8536 4.64645C14.0488 4.84171 14.0488 5.15829 13.8536 5.35355L9.85355 9.35355C9.65829 9.54882 9.34171 9.54882 9.14645 9.35355C8.95118 9.15829 8.95118 8.84171 9.14645 8.64645L12.2929 5.5L0.5 5.5C0.223858 5.5 0 5.27614 0 5Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {orderDetailsLoader && <DataLoader title="data is loading..." />}
    </div>
  );
};

export default CartSection;
