import React, { useEffect, useState } from "react";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { VendorRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import NoData from "../../../components/NoData/NoData";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import VendorReportTable from "./VendorReportTable";
import VendorReportHeader from "./VendorReportHeader";

function VendorReport() {
  const [loader, setloader] = useState(false);
  const [allVendors, setAllVendors] = useState([]);
  const [search, setSearch] = useState("");

  const year = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const lastDayOfMonth = new Date(year, thisMonth + 1, 0);
  const formattedStartDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-01`;
  const formattedEndDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;
  const [startDate, setstartDate] = useState(formattedStartDate);
  const [endDate, setendDate] = useState(formattedEndDate);

  // GET ALL VENDOR FUNCTION API CALL
  const handleGetAllVendor = async (e) => {
    e && e.preventDefault();
    startApiCall(null, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorReport,
      data
    );
    if (res?.success) {
      setAllVendors(res?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllVendor();
      closeAllModals();
    }
  }, [startDate, endDate]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <VendorReportHeader
            handleGetAllVendor={handleGetAllVendor}
            search={search}
            setSearch={setSearch}
            startDate={startDate}
            setstartDate={setstartDate}
            endDate={endDate}
            setendDate={setendDate}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allVendors?.length > 0 && (
                <VendorReportTable allVendors={allVendors} />
              )}

              {loader && <DataLoader title="data is loading..." />}
              {!loader && allVendors?.length === 0 && (
                <NoData title="No vendor report" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorReport;
