import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Onboarding from "../../pages/StaffManagement/StaffOnboarding/Onboarding";


const StaffOnboardingRoutes = {
  path: "/staff",
  element: <Navbar />,
  children: [
    {
      path: '',
      element: <Onboarding/>,
    },
    {
        path: 'onboarding',
        element: <Onboarding />,
      },
  ],
};

export default StaffOnboardingRoutes;