import React from "react";
import { convertAmountLocal } from "../../../global-functions/globalFunctions";

function VendorReportTable({ allVendors }) {
  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-5">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th style={{ textAlign: "left" }}>Name</th>
              <th>Total Amount</th>
              <th>Total Paid</th>
              <th>Total Due </th>
              <th>Total Advance </th>
            </tr>
          </thead>
          <tbody>
            {allVendors?.map((vendor, index) => {
              return (
                <tr className="text-center">
                  <td>{index + 1} .</td>
                  <td style={{ textAlign: "left" }}>
                    <span
                      className="d-inline-block text-truncate text-capitalize text-dark fw-bold"
                      style={{ width: "200px" }}
                    >
                      {vendor?.vendorName}
                    </span>
                  </td>

                  <td className="text-primary font-mon fs-6">
                    {convertAmountLocal(
                      vendor?.totalAmount ? vendor?.totalAmount : 0
                    )}
                  </td>
                  <td className="text-success font-mon fs-6">
                    {convertAmountLocal(
                      vendor?.totalAmountPaid ? vendor?.totalAmountPaid : 0
                    )}
                  </td>
                  <td className="text-danger font-mon fs-6">
                    {convertAmountLocal(
                      vendor?.totalDueAmount ? vendor?.totalDueAmount : 0
                    )}
                  </td>
                  <td className="text-cyan font-mon fs-6">
                    {convertAmountLocal(
                      vendor?.totalAdvance ? vendor?.totalAdvance : 0
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default VendorReportTable;
