import React, { useEffect, useState } from "react";
import ToDoHeader from "./ToDoHeader";
import ToDoTable from "./ToDoTable";
import AddTaskModal from "./AddTaskModal";
import {
  getAllWorkspaceStatusBySpace,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { listEndpoint, taskEndpoint } from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import NoTask from "../Task/NoTask";
import FooterPagination from "../../components/Pagination/FooterPagination";
import { DueDate } from "../Task/Filters/DueDate";
import { DateRange } from "../Task/Filters/DateRange";
import { useSelector } from "react-redux";
import TaskCountCards from "./TaskCountCards";
import SkeletonConstant from "../../components/Constant/SkeletonConstant";

const MyToDoList = () => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allList, setAllList] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [search, setSearch] = useState("");
  const [editTask, setEdittask] = useState("");
  const [taskStatus, settaskStatus] = useState([]);
  const [taskStatusLoader, settaskStatusLoader] = useState(false);
  const taskFilters = ["Date Range", "Due Date"];
  const [selectedFilterType, setselectedFilterType] = useState("");
  const [isFilteredModal, setisFilteredModal] = useState(false);
  const [isFilterReset, setisFilterReset] = useState(false);
  const [dueDateForFilter, setdueDateForFilter] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [selectedTaskStatus, setselectedTaskStatus] = useState({
    statusName: "All Status",
    color: "#c8c8c8",
  });
  const [statusId, setStatusId] = useState({
    openStatusId: "",
    closedStatusId: "",
  });
  const [taskCount, settaskCount] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllList = async () => {
    const data = {
      pageNumber: 1,
      pageLimit: 100,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", listEndpoint.getAllList, data);
    if (res?.success) {
      setAllList(res?.list?.data);

      const spaceId = res?.list?.data?.[0]?.spaceId?._id;
      if (spaceId) {
        handleGetAllTaskStatus(spaceId);
      }
    }
  };

  useEffect(() => {
    handleGetAllList();
  }, []);

  const handleGetAllTaskStatus = async (spaceId) => {
    startApiCall(null, settaskStatusLoader);
    const res = await getAllWorkspaceStatusBySpace(spaceId);
    settaskStatus([...res]);
    const statusIds = {
      openStatusId: null,
      closedStatusId: null,
    };
    res.forEach((status) => {
      if (status?.statusName === "closed") {
        statusIds.closedStatusId = status?._id || null;
      } else if (status?.statusName === "open") {
        statusIds.openStatusId = status?._id || null;
      }
    });
    setStatusId(statusIds);
    settaskStatusLoader(false);
  };
  const handleGetTaskCount = async () => {
    startApiCall(null, setloader2);
    const data = {
      employeeId: user?._id,
      businessId: getCurrentWorkspaceId(),
      startDate: startDate,
      endDate: endDate,
    };
    const res = await ApiCall("post", taskEndpoint.taskCounts, data);
    if (res?.success) {
      settaskCount(res?.task);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const handleGetTask = async (e) => {
    e && e.preventDefault();
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        list: allList?.[0]?._id,
        taskType: "maintask",
        searchKey: search,
        startDate: startDate,
        endDate: endDate,
        dueDate: dueDateForFilter,
        status:
          selectedTaskStatus?.statusName === "All Status"
            ? ""
            : selectedTaskStatus?._id,
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, data);
    if (res?.success) {
      setisFilteredModal(false);
      setisFilterReset(false);
      setAllTask(res?.task?.data);
      setcount(res?.task?.totalCounts);
      settotalPages(res?.task?.totalPages);
      handleGetTaskCount();
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetTask();
  }, [page, pageLimit, search, selectedTaskStatus]);

  useEffect(() => {
    handleGetTaskCount();
  }, []);

  useEffect(() => {
    if (isFilterReset) {
      handleGetTask();
    }
  }, [isFilterReset]);

  const handleResetFilters = (type, isReset) => {
    if (type === "Date Range") {
      setstartDate("");
      setendDate("");
    } else if (type === "Due Date") {
      setdueDateForFilter("");
    }
    setisFilteredModal(false);
    setisFilterReset(isReset);
  };

  const handleChangeStatus = (status) => {
    setselectedTaskStatus(status);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ToDoHeader
            search={search}
            setSearch={setSearch}
            setPage={setPage}
            handleGetTask={handleGetTask}
            handleGetTaskCount={handleGetTaskCount}
            taskFilters={taskFilters}
            setselectedFilterType={setselectedFilterType}
            setisFilteredModal={setisFilteredModal}
            taskStatus={taskStatus}
            selectedTaskStatus={selectedTaskStatus}
            taskStatusLoader={taskStatusLoader}
            handleChangeStatus={handleChangeStatus}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div
                className="d-flex align-items-center gap-3 simple-scrollbar"
                style={{
                  overflowX: "scroll",
                  justifyContent: "start",
                }}
              >
                {!loader2 && <TaskCountCards taskCount={taskCount} />}
                {loader2 && (
                  <div className="d-flex align-items-center gap-3">
                    <div className="card p-3 gap-3 loading-skeleton w-200p h-100p">
                      <div className="d-flex justify-content-between">
                        <SkeletonConstant width="w-70p" height="h-30p" />
                        <SkeletonConstant width="w-70p" height="h-30p" />
                      </div>
                      <SkeletonConstant width="w-40p" height="h-30p" />
                    </div>
                    <div className="card p-3 gap-3 loading-skeleton w-200p h-100p">
                      <div className="d-flex justify-content-between">
                        <SkeletonConstant width="w-70p" height="h-30p" />
                        <SkeletonConstant width="w-70p" height="h-30p" />
                      </div>
                      <SkeletonConstant width="w-40p" height="h-30p" />
                    </div>
                    <div className="card p-3 gap-3 loading-skeleton w-200p h-100p">
                      <div className="d-flex justify-content-between">
                        <SkeletonConstant width="w-70p" height="h-30p" />
                        <SkeletonConstant width="w-70p" height="h-30p" />
                      </div>
                      <SkeletonConstant width="w-40p" height="h-30p" />
                    </div>
                  </div>
                )}
              </div>
              {!loader && allTask?.length > 0 && (
                <ToDoTable
                  allTask={allTask}
                  page={page}
                  pageLimit={pageLimit}
                  setEdittask={setEdittask}
                  handleGetTask={handleGetTask}
                  statusId={statusId}
                />
              )}
              {!loader && allTask?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allTask?.length}
                  align={"end"}
                />
              )}
              {!loader && allTask?.length === 0 && search === "" && <NoTask />}
              {loader && <DataLoader title="Your task of list is loading..." />}
            </div>
          </div>
        </div>
        <AddTaskModal
          editTask={editTask}
          setEdittask={setEdittask}
          handleGetTask={handleGetTask}
          allList={allList}
        />
      </div>
      {/* ************ FILTER TASK *********** */}
      {isFilteredModal && (
        <div
          className={`modal fade add-new-contact mt-10 ${
            isFilteredModal ? "show" : ""
          }`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: isFilteredModal ? "block" : "none" }}
        >
          <form onSubmit={handleGetTask}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <h5>Filter by {selectedFilterType}</h5>
                  <div className="dropdown-divider"></div>
                  {selectedFilterType === "Date Range" && (
                    <DateRange
                      startDate={startDate}
                      setstartDate={setstartDate}
                      endDate={endDate}
                      setendDate={setendDate}
                      mainLoader={loader}
                      handleResetFilters={handleResetFilters}
                    />
                  )}
                  {selectedFilterType === "Due Date" && (
                    <DueDate
                      setdueDateForFilter={setdueDateForFilter}
                      dueDateForFilter={dueDateForFilter}
                      mainLoader={loader}
                      handleResetFilters={handleResetFilters}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default MyToDoList;
