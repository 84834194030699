import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Customer from "../../pages/AccountManagement/Customers/Customer";

const CustomerRoutes = {
  path: "/account",
  element: <Navbar />,
  children: [
    {
      path: "allcustomers",
      element: <Customer />,
    },
  ],
};

export default CustomerRoutes;
