import React, { useEffect, useState } from "react";
import EmployeeTable from "./EmployeeTable";
import { startApiCall } from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { managementEndpoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";
import NoData from "../../../components/NoData/NoData";
import EmployeeHeader from "./EmployeeHeader";
import FooterPagination from "../../../components/Pagination/FooterPagination";

const Employee = () => {
  const user = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [allEmployees, setAllEmployees] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllEmployees = async () => {
    // console.log("called from employee--------");

    startApiCall(seterrorMessage, setLoader);
    const data = {
      pageLimit: pageLimit,
      pageNumber: page,
      searchKey: search,
      s2adminId: user?._id,
    };
    const res = await ApiCall(
      "post",
      managementEndpoints.getAllEmployees,
      data
    );
    if (res?.success) {
      setAllEmployees(res?.employee?.data);
      setCount(res?.employee?.totalCounts);
      settotalPages(res?.employee?.totalPages);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllEmployees();
  }, [search, page, pageLimit]);

  return (
    <>
      <div className="todoapp-wrap">
        <div className="todoapp-content">
          <div className="todoapp-detail-wrap">
            <EmployeeHeader
              search={search}
              setSearch={setSearch}
              handleGetAllEmployees={handleGetAllEmployees}
              loader={loader}
              count={count}
              allEmployees={allEmployees}
              setPage={setPage}
            />
            <div className="todo-body">
              <div data-simplebar className="nicescroll-bar">
                <div className="todo-list-view">
                  {allEmployees?.length > 0 && !loader && (
                    <EmployeeTable
                      allEmployees={allEmployees}
                      pageLimit={pageLimit}
                      page={page}
                      user={user}
                    />
                  )}
                  {allEmployees?.length === 0 && !loader && <NoData />}

                  {loader && <DataLoader title="data is loading..." />}
                </div>
                {!loader && (
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allEmployees?.length}
                    align={"end"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employee;
