import React, { useEffect, useState } from "react";
import AddMeeting from "./AddMeeting";
import MeetingHeader from "./MeetingHeader";
import MeetingCard from "./MeetingCard";
import {
  closeAllModals,
  dateFormatter,
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { MeetingRelatedEndPoints } from "../../../api/Endpoints";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import UserInfoModal from "../../../components/UserInfoModal/UserInfoModal";
import { useLocation, useNavigate } from "react-router-dom";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import { DateRange } from "../../Task/Filters/DateRange";
import CustomDateFilterMeeting from "./CustomDateFilterMeeting";

const Meeting = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const user = useSelector((state) => state.user);
  const [errorMessage, seterrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [allMeeting, setallMeeting] = useState(null);
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [totalPages, settotalPages] = useState(0);
  const [meetingCount, setmeetingCount] = useState(0);
  const [selectedStatus, setselectedStatus] = useState("all");
  const [isConfirmDelete, setisConfirmDelete] = useState(false);
  const [editableMeetingDetails, seteditableMeetingDetails] = useState(null);
  const [userInfoData, setuserInfoData] = useState([]);
  const location = useLocation();
  const [locationData, setlocationData] = useState(null);
  const navigate = useNavigate();
  const [moduleType, setmoduleType] = useState("");
  const [dateType, setdateType] = useState("Today");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dateModal, setdateModal] = useState(false);
  const [isFilterReset, setisFilterReset] = useState(false);

  // ******* DATE *********
  const currentDate = new Date();
  const tomorrowDate = new Date();
  tomorrowDate.setDate(currentDate.getDate() + 1);
  var yesterday = new Date();
  yesterday.setDate(currentDate.getDate() - 1);

  const handleResetFilters = (type, isReset) => {
    setstartDate("");
    setendDate("");
    setdateModal(false);
    setisFilterReset(isReset);
  };

  useEffect(() => {
    setlocationData(location?.state);
    setmoduleType(location?.state ? location?.state?.moduleType : "");
  }, []);

  const isModuleReadWriteAccessMeeting =
    allModuleAccess?.[PRIVATEROUTES.MEETING];

  const meetingStatus = [
    {
      color: "#1944c2",
      statusName: "All",
      value: "all",
    },
    {
      color: "#3862DD",
      statusName: "Upcoming",
      value: "upcoming",
    },
    {
      color: "#FFC700",
      statusName: "inprogress",
      value: "inprogress",
    },
    {
      color: "rgb(36 202 38)",
      statusName: "completed",
      value: "completed",
    },
    {
      color: "rgb(239 13 39)",
      statusName: "cancelled",
      value: "cancelled",
    },
  ];

  //  ***---- GET MEETING API----***
  const handleGetMeeting = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: selectedStatus === "all" ? "" : selectedStatus,
        isDeleted: false,
        participants: user?._id,
        moduleId: location?.state ? location?.state?.moduleId : "",
        moduleType: location?.state ? location?.state?.moduleType : moduleType,
        startDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Tomorrow"
            ? reversesDateFormatter(tomorrowDate)
            : dateType === "Date Range"
            ? startDate
            : "",
        endDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Tomorrow"
            ? reversesDateFormatter(tomorrowDate)
            : dateType === "Date Range"
            ? endDate
            : "",
      },
      searchKey: search,
    };
    const res = await ApiCall("post", MeetingRelatedEndPoints.getMeeting, data);
    if (res?.success) {
      setallMeeting(res?.meetings?.data);
      settotalPages(res?.meetings?.totalPages);
      setmeetingCount(res?.meetings?.totalCounts);
      setdateModal(false);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  //  ***---- CALL GET MEETING ----***
  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetMeeting();
    }
  }, [search, selectedStatus, page, pageLimit, moduleType]);

  //  ***----MEETING SEARCH SET----***
  const handleSearchSet = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  //  ***---- CHANGE MEETING STATUS API----***
  const handleChangeStatus = async (meetingId, status) => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      meetingId,
      status,
      employeeId: user?._id,
    };
    const res = await ApiCall(
      "post",
      MeetingRelatedEndPoints.changeMeetingStatus,
      data
    );
    if (res?.success) {
      toast.success("Meeting Cancelled successfully");
      handleGetMeeting();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  //  ***---- DELETE MEETING CLOSE MODAL ----***
  const handleClose = () => {
    setisConfirmDelete(false);
    seterrorMessage("");
    setLoader(false);
  };

  //  ***---- DELETE MEETING API----***
  const handleDeleteMeeting = async () => {
    startApiCall(seterrorMessage, setLoader);
    const res = await ApiCall("delete", MeetingRelatedEndPoints.deleteMeeting, {
      params: { meetingId: editableMeetingDetails?._id, actionBy: user?._id },
    });
    if (res?.success) {
      toast.success("Meeting Deleted successfully");
      seteditableMeetingDetails(null);
      handleGetMeeting();
      setisConfirmDelete(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleIsConfirmEdit = (meeting) => {
    seteditableMeetingDetails(meeting);
  };

  const handleChangeType = (type) => {
    setlocationData(null);
    navigate(location.pathname, { state: null });
    setmoduleType(type);
  };

  useEffect(() => {
    if (isFilterReset) {
      handleGetMeeting();
    } else if (dateType !== "Date Range") {
      handleResetFilters();
      handleGetMeeting();
    }
    closeAllModals();
  }, [isFilterReset, dateType]);

  return (
    <>
      <div className="todoapp-wrap">
        <div className="todoapp-content">
          <div className="todoapp-detail-wrap">
            <MeetingHeader
              handleGetMeeting={handleGetMeeting}
              search={search}
              handleSearchSet={handleSearchSet}
              meetingCount={meetingCount}
              meetingStatus={meetingStatus}
              selectedStatus={selectedStatus}
              setselectedStatus={setselectedStatus}
              locationData={locationData}
              navigate={navigate}
              handleIsConfirmEdit={handleIsConfirmEdit}
              handleChangeType={handleChangeType}
              moduleType={moduleType}
              isModuleReadWriteAccessMeeting={isModuleReadWriteAccessMeeting}
              dateType={dateType}
              setdateType={setdateType}
              setdateModal={setdateModal}
            />
            <div className="todo-body  mb-5">
              <div data-simplebar className="nicescroll-bar simple-scrollbar">
                {!loader && allMeeting?.length > 0 && (
                  <>
                    <div className="d-flex border-bottom mb-4 justify-content-between ">
                      {" "}
                      <h5 className="mb-4">
                        {dateType === "Today" || (!startDate && !endDate)
                          ? "Today"
                          : dateType === "Tomorrow" || (!startDate && !endDate)
                          ? "Tomorrow"
                          : `${dateFormatter(startDate)} -
                                  ${dateFormatter(endDate)}`}
                      </h5>
                      {(dateType === "Today" || dateType === "Tomorrow") && (
                        <h5 className="mb-4">
                          {dateType === "Today"
                            ? dateFormatter(currentDate)
                            : dateFormatter(tomorrowDate)}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 25 27"
                            fill="none"
                            className="mb-1"
                          >
                            <path
                              d="M17.1875 11.4916C17.1875 11.0408 17.5373 10.6754 17.9688 10.6754H19.5312C19.9627 10.6754 20.3125 11.0408 20.3125 11.4916V13.1241C20.3125 13.5749 19.9627 13.9403 19.5312 13.9403H17.9688C17.5373 13.9403 17.1875 13.5749 17.1875 13.1241V11.4916Z"
                              fill="black"
                            />
                            <path
                              d="M12.5 11.4916C12.5 11.0408 12.8498 10.6754 13.2812 10.6754H14.8438C15.2752 10.6754 15.625 11.0408 15.625 11.4916V13.1241C15.625 13.5749 15.2752 13.9403 14.8438 13.9403H13.2812C12.8498 13.9403 12.5 13.5749 12.5 13.1241V11.4916Z"
                              fill="black"
                            />
                            <path
                              d="M4.6875 16.389C4.6875 15.9382 5.03728 15.5728 5.46875 15.5728H7.03125C7.46272 15.5728 7.8125 15.9382 7.8125 16.389V18.0215C7.8125 18.4723 7.46272 18.8377 7.03125 18.8377H5.46875C5.03728 18.8377 4.6875 18.4723 4.6875 18.0215V16.389Z"
                              fill="black"
                            />
                            <path
                              d="M9.375 16.389C9.375 15.9382 9.72478 15.5728 10.1562 15.5728H11.7188C12.1502 15.5728 12.5 15.9382 12.5 16.389V18.0215C12.5 18.4723 12.1502 18.8377 11.7188 18.8377H10.1562C9.72478 18.8377 9.375 18.4723 9.375 18.0215V16.389Z"
                              fill="black"
                            />
                            <path
                              d="M5.46875 0.880615C5.90022 0.880615 6.25 1.24605 6.25 1.69685V2.51308H18.75V1.69685C18.75 1.24605 19.0998 0.880615 19.5312 0.880615C19.9627 0.880615 20.3125 1.24605 20.3125 1.69685V2.51308H21.875C23.6009 2.51308 25 3.97483 25 5.778V23.7351C25 25.5383 23.6009 27 21.875 27H3.125C1.39911 27 0 25.5383 0 23.7351V5.778C0 3.97484 1.39911 2.51308 3.125 2.51308H4.6875V1.69685C4.6875 1.24605 5.03728 0.880615 5.46875 0.880615ZM1.5625 7.41047V23.7351C1.5625 24.6367 2.26206 25.3676 3.125 25.3676H21.875C22.7379 25.3676 23.4375 24.6367 23.4375 23.7351V7.41047H1.5625Z"
                              fill="black"
                            />
                          </svg>
                        </h5>
                      )}
                    </div>
                    {allMeeting?.map((meeting, index) => {
                      return (
                        <MeetingCard
                          meeting={meeting}
                          index={index}
                          page={page}
                          pageLimit={pageLimit}
                          user={user}
                          handleChangeStatus={handleChangeStatus}
                          setisConfirmDelete={setisConfirmDelete}
                          handleIsConfirmEdit={handleIsConfirmEdit}
                          setuserInfoData={setuserInfoData}
                          navigate={navigate}
                          isModuleReadWriteAccessMeeting={
                            isModuleReadWriteAccessMeeting
                          }
                        />
                      );
                    })}
                  </>
                )}

                {!loader && allMeeting?.length > 0 && (
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={meetingCount}
                    dataLength={allMeeting?.length}
                    align={"end"}
                  />
                )}
                {loader && <DataLoader title="data is loading..." />}
                {!loader && allMeeting?.length === 0 && (
                  <NoData title={`No Meetings`} />
                )}
              </div>
            </div>
          </div>

          {
            <AddMeeting
              handleGetMeeting={handleGetMeeting}
              editableMeetingDetails={editableMeetingDetails}
              seteditableMeetingDetails={seteditableMeetingDetails}
              locationData={locationData}
            />
          }

          {isConfirmDelete && (
            <ConfirmModal
              isOpen={isConfirmDelete}
              onClose={handleClose}
              text={`Are you sure you want to delete Meeting ?`}
              loader={loader}
              errorMessage={errorMessage}
              handleConfirm={handleDeleteMeeting}
            />
          )}
          <UserInfoModal user={userInfoData} />

          <CustomDateFilterMeeting
            startDate={startDate}
            setstartDate={setstartDate}
            endDate={endDate}
            setendDate={setendDate}
            mainLoader={loader}
            handleResetFilters={handleResetFilters}
            dateModal={dateModal}
            handleGetMeeting={handleGetMeeting}
            dateType={dateType}
            setdateType={setdateType}
          />
        </div>
      </div>
    </>
  );
};

export default Meeting;
