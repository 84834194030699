import React, { useEffect, useState } from "react";
import { StatusRelatedEndPoints } from "../../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";

const CreateStatus = ({
  id,
  selectStatus,
  editAllStatus,
  seteditAllStatus,
  handleRefresh,
  setcreateTask,
  setcreateStatus,
  settaskArray,
  taskArray,
}) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deadline, setDeadline] = useState({ days: "2", hours: "" });

  const [statusName, setStatusName] = useState("");

  useEffect(() => {
    if (editAllStatus) {
      setStatusName(editAllStatus?.name);
      settaskArray(editAllStatus?.activities);
      setDeadline({ days: "", hours: editAllStatus?.deadline });
    }
  }, [editAllStatus]);

  const handleReset = () => {
    setcreateStatus(false);
    setloader(false);
    setErrorMessage("");
    seteditAllStatus("");
    settaskArray([]);
  };

  const handleCreateStatus = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      name: statusName,
      statusGroupId: id,
      businessId: getCurrentWorkspaceId(),
      initialStatusId: selectStatus?._id,
      createdBy: user?._id,
      isGrouped: true,
      activities: taskArray,
      deadline:
        24 * parseInt(deadline?.days === "" ? 0 : deadline?.days) +
        parseInt(deadline?.hours === "" ? 0 : deadline?.hours),
    };
    const res = await ApiCall(
      "post",
      StatusRelatedEndPoints.addStatus,
      sendData
    );
    if (res?.success) {
      toast.success("Create status successfully!");
      handleReset();
      handleRefresh();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      name: statusName,
      activityStatusId: editAllStatus?._id,
      isGrouped: true,
      activities: taskArray,
      deadline:
        24 * parseInt(deadline?.days === "" ? 0 : deadline?.days) +
        parseInt(deadline?.hours === "" ? 0 : deadline?.hours),
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.editStatus, data);
    if (res?.success) {
      toast.success("Edit group successfully!");
      handleReset();
      handleRefresh();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleDeleteItem = (index) => {
    const updatedTasks = [...taskArray];
    updatedTasks.splice(index, 1);
    settaskArray(updatedTasks);
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editAllStatus !== ""
                ? `Edit status under ${selectStatus?.name}`
                : `Create status under ${selectStatus?.name}`}
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editAllStatus === "") {
                  handleCreateStatus(e);
                } else {
                  handleEditGroup(e);
                }
              }}
            >
              <div className="mh-450p overflow-y-scroll simple-scrollbar">
                <div className="row gx-3 px-3 w-100 mx-auto">
                  <div className="col-md-8">
                    <div className="form-group mb-2">
                      <label className="form-label">Status Name</label>
                      <input
                        className="form-control"
                        type="text"
                        value={statusName}
                        maxLength={21}
                        onChange={(e) => {
                          setStatusName(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  {editAllStatus?.name !== "open" && (
                    <div className="col-12">
                      <div className="row bg-light rounded-5 pt-1 mb-1">
                        {taskArray?.length > 0 && (
                          <>
                            <span className="fs-7 text-primary">
                              Add Deadline for these activities {reuiredField}
                            </span>

                            <div className="d-flex col-12 gap-2">
                              <div className="form-group mb-2">
                                <label className="form-label font-2">
                                  Deadline in Days
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Deadline Days"
                                  value={deadline.days}
                                  onChange={(e) =>
                                    setDeadline({
                                      ...deadline,
                                      days: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="form-group mb-2">
                                <label className="form-label font-2">
                                  Deadline in Hours
                                </label>
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Deadline Hours"
                                  value={deadline.hours}
                                  onChange={(e) =>
                                    setDeadline({
                                      ...deadline,
                                      hours: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <span className="font-2 fw-bold mb-2">
                              Total Deadline Hours : &nbsp;
                              {24 *
                                parseInt(
                                  deadline?.days === "" ? 0 : deadline?.days
                                ) +
                                parseInt(
                                  deadline?.hours === "" ? 0 : deadline?.hours
                                )}{" "}
                              Hours
                            </span>
                          </>
                        )}
                      </div>

                      <label className="form-label">
                        Status switching Activity
                      </label>

                      {taskArray?.length > 0 && (
                        <div className="mb-2">
                          <div class="row w-100 mx-auto">
                            <div class="col">
                              <p>Sl.no</p>
                            </div>
                            <div class="col">
                              <p>Title</p>
                            </div>
                            <div class="col">
                              <p>Type</p>
                            </div>
                            <div class="col">
                              <p>Action</p>
                            </div>
                          </div>
                          <div className="border-bottom my-2"></div>
                          <div
                            className="overflow-y-scroll simple-scrollbar"
                            style={{ maxHeight: "60px" }}
                          >
                            {taskArray?.map((task, index) => (
                              <div class="row mb-2 w-100 mx-auto">
                                <div class="col">
                                  <p>{index + 1}.</p>
                                </div>
                                <div class="col">
                                  <p className="text-capitalize">
                                    {task?.name}
                                  </p>
                                </div>
                                <div class="col">
                                  <div class="flex-container space-between">
                                    <p className="text-capitalize">
                                      {task?.taskType}
                                    </p>
                                  </div>
                                </div>
                                <div class="col ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-trash3 text-danger ms-3 pointer"
                                    viewBox="0 0 16 16"
                                    onClick={() => handleDeleteItem(index)}
                                  >
                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                  </svg>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      <div>
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            setcreateTask(true);
                          }}
                          disabled={!statusName}
                        >
                          {taskArray?.length > 0
                            ? "Add Another Activity"
                            : "Add Activity"}
                        </button>{" "}
                        &nbsp;( Activities are automatically appended upon the
                        execution of this status.)
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    !statusName ||
                    (taskArray?.length > 0 &&
                      !deadline?.days &&
                      !deadline?.hours)
                  }
                >
                  {loader ? (
                    <ApiLoader />
                  ) : editAllStatus === "" ? (
                    "Add"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStatus;
