import React from "react";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";

const AttendanceSkeleton = () => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex gap-3">
        <div className="card loading-skeleton p-3">
          <SkeletonConstant width="w-120p" height="h-45p" />
        </div>
        <div className="card loading-skeleton p-3">
          <SkeletonConstant width="w-120p" height="h-45p" />
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>
              <SkeletonConstant
                width="w-40p"
                height="h-25p"
                additional="mx-auto"
              />
            </th>
            <th>
              <SkeletonConstant width="w-100p" height="h-25p" />
            </th>
            <th>
              <SkeletonConstant
                width="w-100p"
                height="h-25p"
                additional="mx-auto"
              />
            </th>
            <th>
              <SkeletonConstant
                width="w-100p"
                height="h-25p"
                additional="mx-auto"
              />
            </th>
            <th>
              <SkeletonConstant
                width="w-100p"
                height="h-25p"
                additional="mx-auto"
              />
            </th>
            <th>
              <SkeletonConstant
                width="w-100p"
                height="h-25p"
                additional="mx-auto"
              />
            </th>
            <th>
              <SkeletonConstant
                width="w-100p"
                height="h-25p"
                additional="mx-auto"
              />
            </th>
            <th>
              <SkeletonConstant
                width="w-100p"
                height="h-25p"
                additional="mx-auto"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant width="w-220p" height="h-35p" />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-120p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
          </tr>
          <tr>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant width="w-220p" height="h-35p" />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-120p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
          </tr>
          <tr>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant width="w-220p" height="h-35p" />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-120p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
          </tr>
          <tr>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant width="w-220p" height="h-35p" />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-120p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
          </tr>
          <tr>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant width="w-220p" height="h-35p" />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-120p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
          </tr>
          <tr>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant width="w-220p" height="h-35p" />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-100p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-120p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
            <td>
              <SkeletonConstant
                width="w-40p"
                height="h-35p"
                additional="mx-auto"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AttendanceSkeleton;
