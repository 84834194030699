import React from "react";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { getName } from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

function StaffTable(props) {
  const {
    approvedStaff,
    setemployeeData,
    page,
    pageLimit,
    setmodalType,
    setisEditOnboarding,
    isModuleReadWriteAccessForOnboarding,
    setEditStaffModal,
    seteditSalary,
  } = props;
  const { user } = useSelector((state) => state);

  return (
    <>
      <table id="datable_1" className="table nowrap w-100 mb-5">
        <thead style={{ textAlign: "center" }}>
          <tr>
            <th>No.</th>
            <th style={{ textAlign: "left" }}>Employee</th>
            <th>Email</th>
            <th>Phone</th>
            {isModuleReadWriteAccessForOnboarding && <th>Status</th>}
            {isModuleReadWriteAccessForOnboarding && <th>Action</th>}
            {/* <th>Change Shift</th> */}
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {approvedStaff?.map((data, index) => {
            return (
              <tr key={index}>
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td className="text-capitalize" style={{ textAlign: "left" }}>
                  <span
                    className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                    style={{ width: "200px" }}
                  >
                    <CustomTooltip
                      text={
                        data?.employeeId
                          ? data?.employeeId?.name
                          : "This user is not signup yet"
                      }
                      placement="right"
                    >
                      <Avatar
                        name={data?.employeeId ? data?.employeeId?.name : "I"}
                        count={1}
                        image={data?.employeeId?.image}
                        size="xss"
                        color="primary"
                      />
                    </CustomTooltip>
                    &nbsp;{" "}
                    {data?.employeeId
                      ? getName(data?.employeeId, user?._id)
                      : "Invited User"}
                  </span>
                </td>
                <td>{data?.employeeId?.email}</td>
                <td>
                  {data?.employeeId?.phone ? data?.employeeId?.phone : "-"}
                </td>
                {isModuleReadWriteAccessForOnboarding && (
                  <>
                    {data?.isStaff === false ? (
                      <td>
                        <span
                          className="btn-icon-wrap text-primary pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#attendance_modal"
                          onClick={() => {
                            setemployeeData(data);
                            setmodalType("add");
                          }}
                        >
                          <span className="feather-icon">
                            <i
                              className="ri-add-circle-fill"
                              style={{ fontSize: "30px" }}
                            ></i>
                          </span>
                        </span>
                      </td>
                    ) : (
                      <td>
                        <span className="btn-icon-wrap text-success">
                          <span className="feather-icon">
                            <i
                              className="ri-checkbox-circle-fill"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </span>
                        </span>
                      </td>
                    )}
                  </>
                )}
                {isModuleReadWriteAccessForOnboarding && (
                  <td>
                    {data?.isStaff ? (
                      <span className="feather-icon pointer">
                        <i
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="ri-more-fill"
                        ></i>
                        <div className="dropdown-menu text-start">
                          <div
                            className="dropdown-item d-flex align-items-center text-start"
                            onClick={() => {
                              setisEditOnboarding(true);
                              setemployeeData(data);
                            }}
                          >
                            <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-gear"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                </svg>
                              </span>
                            </span>
                            Settings
                          </div>
                          <div
                            className="dropdown-item d-flex align-items-center text-start"
                            onClick={() => {
                              setEditStaffModal(true);
                              setemployeeData(data);
                            }}
                          >
                            <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-x-circle text-danger"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                              </span>
                            </span>
                            Remove Staff
                          </div>
                        <div
                            className="dropdown-item d-flex align-items-center text-start"
                            onClick={() => {
                              setEditStaffModal(true);
                              setemployeeData(data);
                              seteditSalary(true);
                            }}
                          >
                            <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-cash-stack"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                                  <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2z" />
                                </svg>
                              </span>
                            </span>
                            Edit Salary
                          </div> 
                        </div>
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default StaffTable;
