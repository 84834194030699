import React from "react";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { navigateToAllLeads } from "../../../../../Navigation/Navigation";
import { toast } from "react-toastify";

const OverviewHeader = ({
  setEmailDetailsModal,
  componentRef,
  handleGetLeadDetails,
  leadDetails,
  id,
  setConvertDeal,
  location,
}) => {
  const navigate = useNavigate();

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <button
            className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
            onClick={() =>
              navigate(navigateToAllLeads(), {
                state: {
                  startDate: location?.state?.startDate,
                  endDate: location?.state?.endDate,
                  status: location?.state?.status,
                  subStatus: location?.state?.subStatus,
                  _id: location?.state?._id,
                  name: location?.state?.name,
                  page: location?.state?.page,
                },
              })
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <p className="mb-0">Back</p>
          </button>
          <div className="v-separator d-lg-inline-block"></div>
          <h1 className="header-title-font ">{leadDetails?.title}</h1>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="ms-3">
          <ReactToPrint
            trigger={() => (
              <button className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-printer me-2"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                  <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                </svg>
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
        <div className="ms-3">
          <button
            className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
            onClick={() => setConvertDeal(true)}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.1758 14.625H20.8242V8.61211C20.8242 8.30273 20.5725 8.05078 20.2629 8.05078H13.5V7.69922H20.2629C20.7661 7.69922 21.1758 8.10867 21.1758 8.61211V14.625Z"
                fill="currentColor"
              />
              <path
                d="M18.3789 21.1758H11.241C10.7378 21.1758 10.3281 20.7663 10.3281 20.2629V16.125H10.6797V20.2629C10.6797 20.5723 10.9314 20.8242 11.241 20.8242H18.3063L20.8281 18.3021V15.75H21.1797V18.375C21.1797 18.4216 21.1612 18.4664 21.1281 18.4992L18.5031 21.1242C18.4703 21.1573 18.4255 21.1758 18.3789 21.1758Z"
                fill="currentColor"
              />
              <path
                d="M18.3789 21.1758C18.3562 21.1758 18.3334 21.1713 18.3116 21.1624C18.246 21.1352 18.2031 21.071 18.2031 21V19.1121C18.2031 18.6089 18.6126 18.1992 19.116 18.1992H21.0039C21.0749 18.1992 21.1391 18.2421 21.1663 18.3077C21.1935 18.3734 21.1785 18.4491 21.1281 18.4992L18.5031 21.1242C18.4696 21.158 18.4246 21.1758 18.3789 21.1758ZM19.116 18.5508C18.8066 18.5508 18.5547 18.8025 18.5547 19.1121V20.5758L20.5795 18.551H19.116V18.5508Z"
                fill="currentColor"
              />
              <path
                d="M18.75 10.3242H14.625V10.6758H18.75V10.3242Z"
                fill="currentColor"
              />
              <path
                d="M16.5 12.9492H13.5V13.3008H16.5V12.9492Z"
                fill="currentColor"
              />
              <path
                d="M18.75 12.9492H17.25V13.3008H18.75V12.9492Z"
                fill="currentColor"
              />
              <path
                d="M14.625 15.5742H12.75V15.9258H14.625V15.5742Z"
                fill="currentColor"
              />
              <path
                d="M18.75 15.5742H15.375V15.9258H18.75V15.5742Z"
                fill="currentColor"
              />
              <path
                d="M16.5 18.1992H12.75V18.5508H16.5V18.1992Z"
                fill="currentColor"
              />
              <path
                d="M10.8789 16.3008H3.74102C3.23781 16.3008 2.82812 15.8913 2.82812 15.3879V3.73711C2.82812 3.23391 3.23758 2.82422 3.74102 2.82422H12.767C13.2702 2.82422 13.6799 3.23367 13.6799 3.73711V9.75H13.3284V3.73711C13.3284 3.42773 13.0766 3.17578 12.767 3.17578H3.74102C3.43164 3.17578 3.17969 3.4275 3.17969 3.73711V15.3881C3.17969 15.6975 3.43141 15.9495 3.74102 15.9495H10.8062L13.3281 13.4271V10.875H13.6797V13.5C13.6797 13.5466 13.6612 13.5914 13.6281 13.6242L11.0031 16.2492C10.9703 16.2823 10.9255 16.3008 10.8789 16.3008Z"
                fill="currentColor"
              />
              <path
                d="M10.8789 16.3008C10.8562 16.3008 10.8334 16.2963 10.8116 16.2874C10.746 16.2602 10.7031 16.196 10.7031 16.125V14.2371C10.7031 13.7339 11.1126 13.3242 11.616 13.3242H13.5039C13.5749 13.3242 13.6391 13.3671 13.6663 13.4327C13.6935 13.4984 13.6785 13.5741 13.6281 13.6242L11.0031 16.2492C10.9696 16.283 10.9246 16.3008 10.8789 16.3008ZM11.616 13.6758C11.3066 13.6758 11.0547 13.9275 11.0547 14.2371V15.7008L13.0795 13.676H11.616V13.6758Z"
                fill="currentColor"
              />
              <path
                d="M6.375 5.44922H5.25V5.80078H6.375V5.44922Z"
                fill="currentColor"
              />
              <path
                d="M11.25 5.44922H7.125V5.80078H11.25V5.44922Z"
                fill="currentColor"
              />
              <path
                d="M9 8.07422H5.25V8.42578H9V8.07422Z"
                fill="currentColor"
              />
              <path
                d="M11.25 8.07422H9.75V8.42578H11.25V8.07422Z"
                fill="currentColor"
              />
              <path
                d="M7.125 10.6992H5.25V11.0508H7.125V10.6992Z"
                fill="currentColor"
              />
              <path
                d="M11.25 10.6992H7.875V11.0508H11.25V10.6992Z"
                fill="currentColor"
              />
              <path
                d="M9 13.3242H5.25V13.6758H9V13.3242Z"
                fill="currentColor"
              />
              <path
                d="M19.4992 6.55072C19.4542 6.55072 19.4092 6.53361 19.375 6.49916L17.875 4.99916L18.1237 4.75049L19.4995 6.12627L20.8752 4.75049L21.1239 4.99916L19.6239 6.49916C19.5892 6.53361 19.5442 6.55072 19.4992 6.55072Z"
                fill="currentColor"
              />
              <path
                d="M19.6758 6.375H19.3242V3.75211C19.3242 3.4343 19.0657 3.17578 18.7479 3.17578H15V2.82422H18.7479C19.2595 2.82422 19.6758 3.24047 19.6758 3.75211V6.375Z"
                fill="currentColor"
              />
              <path
                d="M5.875 19.2492L4.49922 17.8734L3.12344 19.2492L2.875 19.0007L4.375 17.5007C4.44367 17.4321 4.555 17.4321 4.62367 17.5007L6.12367 19.0007L5.875 19.2492Z"
                fill="currentColor"
              />
              <path
                d="M9.00391 21.1758H5.25602C4.74437 21.1758 4.32812 20.7595 4.32812 20.2479V17.625H4.67969V20.2479C4.67969 20.5657 4.9382 20.8242 5.25602 20.8242H9.00391V21.1758Z"
                fill="currentColor"
              />
            </svg>{" "}
            &nbsp; Convert
          </button>
        </div>
        <div className="ms-3">
          <button
            className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
            onClick={() => {
              if (leadDetails?.email) {
                setEmailDetailsModal(true);
              } else {
                toast.warning(
                  "Email does not exist! Please add an email first by editing the existing lead."
                );
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              className="bi bi-envelope me-2"
              viewBox="0 0 16 16"
            >
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
            </svg>
            Send Email
          </button>
        </div>

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetLeadDetails}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default OverviewHeader;
