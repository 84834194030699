import React from "react";

const OrderHeader = (props) => {
  const { handleGetItemCategory, setSelectedCategory, locationData } = props;

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <a className="todoapp-title link-dark">
          <h5 className="header-title-font mt-2 ">
            {locationData?.orderId ? "Edit Order" : "Place order"}
          </h5>
          {/* <button
                  className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block ms-3"
                  data-bs-toggle="modal"
                  data-bs-target="#add_new_category"
                >
                  Add Category
                </button> */}
        </a>
      </div>
      <div className="todo-options-wrap">
        <div className="v-separator d-lg-inline-block d-none"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={() => {
            handleGetItemCategory();
            setSelectedCategory("");
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default OrderHeader;
