import React, { useEffect, useState } from "react";
import { taskEndpoint } from "../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { useSelector } from "react-redux";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const status = [
  {
    name: "Active",
    value: "active",
    color: "#0D6EFD",
  },
  {
    name: "Done",
    value: "done",
    color: "#FFC400",
  },
  {
    name: "Completed",
    value: "completed",
    color: "#32CD32",
  },
  {
    name: "Cancelled",
    value: "cancelled",
    color: "#FF0101",
  },
];

function AddStatus(props) {
  const {
    handlTaskStatus,
    setaddStatusModal,
    seteditStatusModal,
    addStatusModal,
    editStatusModal,
    idividualStatusData,
    spaceId,
  } = props;
  const [statusName, setstatusName] = useState("");
  const user = useSelector((state) => state.user);
  const [statusType, setstatusType] = useState("active");
  const [statusColor, setstatusColor] = useState("#0D6EFD");
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //* Color Selector *//
  const handleChange = (e) => {
    setstatusColor(e.target.value);
  };

  const handleReset = async () => {
    setloader(false);
    setstatusName("");
    setstatusColor("#0D6EFD");
    setstatusType("active");
    setErrorMessage("");
    setaddStatusModal(false);
    seteditStatusModal(false);
  };

  //*   CREATE STATUS    *//

  const handleCreateStatus = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader);
    const sendData = {
      isDefault: false,
      statusName: statusName,
      businessId: getCurrentWorkspaceId(),
      spaceId: spaceId,
      color: statusColor,
      createdBy: user?._id,
      statusType: statusType,
    };
    const res = await ApiCall("post", taskEndpoint.createStatus, sendData);
    if (res?.success) {
      toast.success("Status Created successfully!");
      handlTaskStatus();
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  //*   EDIT STATUS    *//
  const handleEditStatus = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader);
    const sendData = {
      taskStatusId: idividualStatusData?._id,
      statusType: statusType,
      statusName: statusName,
      color: statusColor,
    };
    const res = await ApiCall("post", taskEndpoint.editStatus, sendData);
    if (res?.success) {
      toast.success("Status Edited successfully!");
      handlTaskStatus();
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (editStatusModal) {
      setstatusName(idividualStatusData?.statusName);
      setstatusType(idividualStatusData?.status_type);
      setstatusColor(idividualStatusData?.color);
    }
  }, [editStatusModal]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-3">
              {addStatusModal ? "Add Staus" : "Edit Status"}
            </h5>
            <form
              onSubmit={(e) => {
                if (addStatusModal) {
                  handleCreateStatus(e);
                } else {
                  handleEditStatus(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      onChange={(e) => {
                        setstatusName(e.target.value);
                      }}
                      value={statusName}
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Choose Category</label>
                    <select
                      className="form-select form-select-md"
                      value={statusType}
                      onChange={(event) => setstatusType(event.target.value)}
                    >
                      {status?.map((st) => {
                        return <option value={st?.value}>{st.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group ms-3">
                    <label className="form-label row ms-1">
                      Choose Color &nbsp;
                    </label>
                    <label
                      className="ps-2 pe-2 pb-1 rounded-2 dropdown-toggle align-items-center justify-content-center"
                      style={{ border: `1px solid ${statusColor}` }}
                    >
                      <span
                        class="badge badge-indicator badge-indicator-xl"
                        style={{ background: statusColor }}
                      ></span>
                      &nbsp;
                      <span className="ms-2">{statusColor}</span>
                      <input
                        type="color"
                        value={statusColor}
                        onChange={handleChange}
                        style={{
                          left: "0",
                          opacity: "0",
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : addStatusModal ? "Add" : "Edit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStatus;
