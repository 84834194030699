import React, { useEffect } from "react";
import LogsTab from "../../../../components/LogsTab/LogsTab";
import { Link } from "react-router-dom";
import AttachmentSection from "./AttachmentSection";
import CommentSection from "./CommentSection";

const DetailsRight = (props) => {
  const {
    allComments,
    taskEditLoader,
    handleGetAllComments,
    handleGetLogs,
    taskAllLogs,
    handleShowReply,
    showAddReply,
    setShowAddReply,
    reply,
    setReply,
    handleAddComment,
    taskDetails,
    user,
    handleGetTaskDetails,
  } = props;


  return (
    <div className="w-50 d-flex flex-column h-475p py-2 px-4 border-start">
      <ul
        className="nav nav-justified nav-light nav-tabs nav-segmented-tabs active-theme"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 100,
          backgroundColor: "white",
        }}
      >
            <li className="nav-item">
          <Link
            className="nav-link active  d-flex align-items-center justify-content-center gap-1"
            data-bs-toggle="tab"
            to="#tab_comments"
          >
            <span className="nav-link-text badge-on-text">Comments</span>
            {allComments?.length > 0 && (
              <span className="badge badge-sm badge-light">
                {allComments?.length}
              </span>
            )}
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" data-bs-toggle="tab" to="#tab_attachment">
            <span className="nav-link-text badge-on-text">Attachment</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link"
            data-bs-toggle="tab"
            to="#tab_activity"
          >
            <span className="nav-link-text badge-on-text">Activity</span>
          </Link>
        </li>
    

     
      </ul>

      <div
        className="tab-content mt-0 simple-scrollbar"
        style={{ overflow: "auto" }}
      >
         {/* ******** COMMENT SECTION ********* */}
         <div className="tab-pane fade  active show" id="tab_comments">
          <CommentSection
            handleGetAllComments={handleGetAllComments}
            taskEditLoader={taskEditLoader}
            allComments={allComments}
            handleShowReply={handleShowReply}
            setReply={setReply}
            setShowAddReply={setShowAddReply}
            handleAddComment={handleAddComment}
            showAddReply={showAddReply}
            reply={reply}
          />
        </div>
           {/* ******** ATTACHMENT SECTION ********* */}
           <div className="tab-pane fade" id="tab_attachment">
          <AttachmentSection
            taskDetails={taskDetails}
            user={user}
            handleGetTaskDetails={handleGetTaskDetails}
          />
        </div>
        {/* ******** ACTIVITY SECTION ********* */}
        <div className="tab-pane fade" id="tab_activity">
          <div className="d-flex justify-content-between">
            {taskEditLoader && <span>Loading...</span>}
            <div className="d-flex align-items-center justify-content-end w-100 mt-1">
              <span
                className="btn btn-sm btn-outline-light btn-icon btn-rounded"
                onClick={handleGetLogs}
              >
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-clockwise"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                    />
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                  </svg>
                </span>
              </span>
            </div>
          </div>
          <LogsTab logData={taskAllLogs} />
        </div>

       
     
      </div>
    </div>
  );
};

export default DetailsRight;
