import React, { useEffect, useState } from "react";
import CustomTooltip from "../../../../../../components/CustomTooltip/CustomTooltip";
import LeaveCalendar from "./LeaveCalendar";
import {
  dateFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../../../global-functions/globalFunctions";
import { leaveRelatedEndPoints } from "../../../../../../api/Endpoints";
import ApiCall from "../../../../../../api/ApiCall";
import ErrorMessage from "../../../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../../../components/Loaders/ApiLoader/ApiLoader";
import { toast } from "react-toastify";
import constants from "../../../../../../components/Constant/Constants";

const ApplyLeave = ({ setApplyLeave, id, handleGetLeaveDetailsForStaff, totalLeaveTaken }) => {
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);
  const [allLeaveTypes, setAllLeaveTypes] = useState([]);
  const [selectLeaveType, setSelectLeaveType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [description, setDescription] = useState("");
  const [allowedDays, setAllowedDays] = useState(0);
  const [previouLeaveDetails, setPreviousLeaveDetails] = useState([]);

  const updateAllowedDays = (leaveTypeId) => {
    const selectedLeaveType = allLeaveTypes?.find(
      (type) => type._id === leaveTypeId
    );
    if (selectedLeaveType) {
      setAllowedDays(selectedLeaveType.allowedDays);
    } else {
      setAllowedDays(0);
    }
  };

  const handleReset = () => {
    setErrorMessage("");
    setloader2(false);
    setApplyLeave(false);
  };

  const handleGetAllLeaveTypes = async () => {
    startApiCall(null, setloader);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.getLeaveTypes,
      sendData
    );
    if (res?.success) {
      setAllLeaveTypes(res?.leaveType);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllLeaveTypes();
    }
  }, []);

  const handleApplyLeave = async () => {
    startApiCall(setErrorMessage, setloader2);
    if (selectedDate?.length > allowedDays) {
      setloader2(false);
      setErrorMessage(
        `You can't select more than ${allowedDays} dates for this type`
      );
    } else {
      const data = {
        employeeId: id,
        leaveType: selectLeaveType,
        businessId: getCurrentWorkspaceId(),
        description,
        dates: selectedDate,
      };
      const res = await ApiCall("post", leaveRelatedEndPoints.applyLeave, data);
      if (res?.success) {
        handleReset();
        toast.success("Apply leave successfully!");
        handleGetLeaveDetailsForStaff();
      } else {
        setloader2(false);
        setErrorMessage(res.error);
      }
    }
  };

  const totalAllowedDays = allLeaveTypes?.reduce(
    (sum, data) => sum + data?.allowedDays,
    0
  );

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered "
        role="document"
        style={{ maxWidth: "85%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={handleReset}>
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Apply Leave</h5>
              &nbsp;
              <CustomTooltip
                text="Select Date and Apply for leave by clicking the Add button on the screen"
                placement="right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-info-circle-fill mt-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
              </CustomTooltip>
            </span>
            <div className="border-bottom mb-2"></div>
            <div className="row mh-500p overflow-auto">
              <div className="col-lg-6 col-md-12">
                <LeaveCalendar
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                  allowedDays={allowedDays}
                  id={id}
                  previouLeaveDetails={previouLeaveDetails}
                  setPreviousLeaveDetails={setPreviousLeaveDetails}
                />
              </div>
              <div className="col-lg-6 col-md-12 border-start">
                <div className="mt-5 text-dark">
                  Selected Date :{" "}
                  {selectedDate?.length === 0 && "No Date Selected"}
                </div>
                <div className="d-flex flex-wrap justify-content-start mt-1">
                  {selectedDate?.map((date, index) => (
                    <div key={index} className="col-md-3 mb-2">
                      <span
                        className="btn d-flex align-items-center gap-1 mt-1 border w-140p bg-primary"
                        style={{ color: "#ffffff" }}
                      >
                        {dateFormatter(date)}
                        <span className="initial-wrap w-30p">
                          <svg
                            onClick={() => {
                              const updatedDates = [...selectedDate];
                              updatedDates.splice(index, 1);
                              setSelectedDate(updatedDates);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            className="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                          </svg>
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
                <div className="row mt-8">
                  {!loader && (
                    <div className="row">
                      <div className="form-group d-flex gap-2 justify-content-start">
                        <label className="form-label mt-1">
                          Total leaves applied & taken :{" "}
                          {totalAllowedDays
                            ? `${totalLeaveTaken} / ${totalAllowedDays}`
                            : "No leaves taken"}
                        </label>
                      </div>
                    </div>
                  )}
                  {loader && (
                    <span className="text-dark mb-3">Loading......</span>
                  )}
                  <div className="col-md-12" style={{marginTop: -8}}>
                    <div className="form-group d-flex gap-2 justify-content-start">
                      <label className="form-label mt-1">
                        Choose Leave type :{" "}
                      </label>

                      <div className="form-group">
                        <div className="todo-toolbar">
                          <select
                            className="form-select form-select-md w-200p"
                            value={selectLeaveType}
                            onChange={(e) => {
                              setSelectLeaveType(e.target.value);
                              updateAllowedDays(e.target.value);
                            }}
                          >
                            <option value="">Select type</option>
                            {allLeaveTypes?.map((data) => {
                              return (
                                <option value={data?._id}>
                                  {data.leaveName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      {selectLeaveType !== "" && (
                        <span className="mt-1 text-dark d-flex gap-1">
                          {allowedDays}
                          <CustomTooltip
                            text={`You can't select more than ${allowedDays} date for this type`}
                            placement="top"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="blue"
                              class="bi bi-info-circle-fill mt-1"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                            </svg>
                          </CustomTooltip>
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="form-group justify-content-start"
                    style={{ marginTop: -20 }}
                  >
                    <label className="form-label mt-1">Description : </label>
                    <textarea
                      className="form-control"
                      rows="3"
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      value={description}
                      maxLength={constants.DESCRIPTION_REASON}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <div className="modal-footer align-items-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleReset}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={
                  selectedDate?.length === 0 ||
                  selectLeaveType === "" ||
                  !description
                }
                className="btn btn-primary btn-add-tasklist"
                onClick={handleApplyLeave}
              >
                {loader2 ? <ApiLoader /> : "Apply Leave"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyLeave;
