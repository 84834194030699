import React, { useEffect, useState } from "react";
import StockDetailsHeader from "./StockDetailsHeader";
import StockDetailsTable from "./StockDetailsTable";
import AddStock from "../../ManageStock/AddStock";
import { useLocation, useParams } from "react-router-dom";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import {
  OrderRelatedEndPoints,
  StockRelatedEndPoints,
  VendorRelatedEndPoints,
} from "../../../../api/Endpoints";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import CustomDateFilterStock from "../../ManageStock/CustomDateFilterStock";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import AddVendor from "../../../Vendors/AddVendor/AddVendor";
import ReasonDetails from "../../ManageStock/ReasonDetails";
import BIllImagesModal from "./BIllImagesModal";

const StockDetails = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const { id } = useParams();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [stockDetails, setStockDetails] = useState([]);
  const [editStock, setEditStock] = useState("");
  const [imageData, setImageData] = useState("");
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [stockCount, setStockCount] = useState();
  const [stockType, setStockType] = useState("");
  const [showReason, setShowReason] = useState(false);
  // Vendors
  const [allVendors, setAllVendors] = useState([]);
  const [vendorLoader, setvendorLoader] = useState(false);
  const [editVendor, setEditVendor] = useState("");
  const [vendorDetails, setVendorDetails] = useState(false);
  // FILTER
  const currentDate = new Date();
  const [dateType, setdateType] = useState("Today");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dateModal, setdateModal] = useState(false);

  const initialValue = 0;

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(50);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForRawMaterial =
    allModuleAccess?.[PRIVATEROUTES.RAW_MATERIALS];

  const handleResetFilters = () => {
    setstartDate("");
    setendDate("");
    setdateModal(false);
  };

  const handleGetStockCount = async () => {
    startApiCall(null, setloader2);
    const data = {
      inventoryId: id,
      startDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "Date Range"
          ? startDate
          : "",
      endDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "Date Range"
          ? endDate
          : "",
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      StockRelatedEndPoints.getStockCount,
      data
    );
    if (res?.success) {
      setStockCount(res?.stock);
      setdateModal(false);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const handleGetLogs = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        itemId: id,
        isAdded: stockType === "new" ? true : stockType === "out" ? false : "",
        startDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Date Range"
            ? startDate
            : "",
        endDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Date Range"
            ? endDate
            : "",
      },
    };
    const res = await ApiCall(
      "post",
      OrderRelatedEndPoints.getInventoryLogs,
      data
    );
    if (res?.success) {
      setStockDetails(res?.logs?.data);
      setcount(res?.logs?.totalCounts);
      settotalPages(res?.logs?.totalPages);
      setdateModal(false);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (dateType !== "Date Range") {
      handleGetLogs();
      handleGetStockCount();
    } else if (dateType === "Date Range" && startDate && endDate) {
      handleGetLogs();
      handleGetStockCount();
    }
  }, [page, pageLimit, stockType, dateType]);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleRefresh = () => {
    handleGetLogs();
    handleGetStockCount();
  };

  const handleGetAllVendor = async () => {
    startApiCall(null, setvendorLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.getVendor, data);
    if (res?.success) {
      setAllVendors(res?.vendor?.data);
      setvendorLoader(false);
    } else {
      setvendorLoader(false);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <StockDetailsHeader
            handleGetLogs={handleRefresh}
            locationData={locationData}
            dateType={dateType}
            setdateType={setdateType}
            setdateModal={setdateModal}
            stockType={stockType}
            setStockType={setStockType}
            handleResetFilters={handleResetFilters}
            isModuleReadWriteAccessForRawMaterial={
              isModuleReadWriteAccessForRawMaterial
            }
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="d-flex gap-3">
                <div className="card py-3 px-4 text-center rounded-4 count-box">
                  <p className="text-dark fs-6 fw-medium">Opening stock</p>
                  <p className="text-primary">
                    {stockCount?.openingStock
                      ? stockCount?.openingStock?.toFixed(2)
                      : initialValue.toFixed(2)}{" "}
                    {locationData?.unit}
                  </p>
                </div>
                <div className="card py-3 px-4 text-center rounded-4 count-box">
                  <p className="text-dark fs-6 fw-medium">Stock added</p>
                  <p className="text-success">
                    {stockCount?.totalStockAdded
                      ? stockCount?.totalStockAdded
                      : initialValue.toFixed(2)}{" "}
                    {locationData?.unit}
                  </p>
                </div>
                <div className="card py-3 px-4 text-center rounded-4 count-box">
                  <p className="text-dark fs-6 fw-medium">Stock used</p>
                  <p className="text-danger">
                    {stockCount?.totalStockUsed
                      ? stockCount?.totalStockUsed?.toFixed(2)
                      : initialValue.toFixed(2)}{" "}
                    {locationData?.unit}
                  </p>
                </div>
                <div className="card py-3 px-4 text-center rounded-4 count-box">
                  <p className="text-dark fs-6 fw-medium">Closing stock</p>
                  <p className="text-primary">
                    {stockCount?.closingStock
                      ? stockCount?.closingStock?.toFixed(2)
                      : initialValue.toFixed(2)}{" "}
                    {locationData?.unit}
                  </p>
                </div>
              </div>

              {!loader && stockDetails?.length > 0 && (
                <StockDetailsTable
                  stockDetails={stockDetails}
                  setEditStock={setEditStock}
                  page={page}
                  pageLimit={pageLimit}
                  id={id}
                  setShowReason={setShowReason}
                  setImageData={setImageData}
                  isModuleReadWriteAccessForRawMaterial={
                    isModuleReadWriteAccessForRawMaterial
                  }
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && stockDetails?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={stockDetails?.length}
                  align={"end"}
                />
              )}
              {!loader && stockDetails?.length === 0 && (
                <NoData title="No stock added" />
              )}
            </div>
          </div>
        </div>
        <CustomDateFilterStock
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader}
          handleResetFilters={handleResetFilters}
          dateModal={dateModal}
          handleGetAllStock={handleRefresh}
          dateType={dateType}
          setdateType={setdateType}
          setdateModal={setdateModal}
        />
        <AddStock
          id={id}
          itemName={locationData?.name}
          isManageStock={false}
          handleGetLogs={handleRefresh}
          setEditStock={setEditStock}
          editStock={editStock}
          locationData={locationData}
          handleGetAllVendor={handleGetAllVendor}
          allVendors={allVendors}
          vendorLoader={vendorLoader}
          setVendorDetails={setVendorDetails}
        />
        <AddVendor
          handleGetAllVendor={handleGetAllVendor}
          setEditVendor={setEditVendor}
          editVendor={editVendor}
        />
        {showReason && (
          <ReasonDetails
            setShowReason={setShowReason}
            showReason={showReason}
          />
        )}
        <BIllImagesModal editStock={imageData} setEditStock={setImageData} />
      </div>
    </div>
  );
};

export default StockDetails;
