import React, { useEffect, useRef, useState } from "react";
import OverviewHeader from "./OverviewHeader";
import DetailsLeftCard from "./DetailsLeftCard";
import AddMeetingCrm from "./MeetingCard/AddMeetingCrm";
import AddTaskCrm from "./TaskCard/AddTaskCrm";
import CreateLead from "../../CreateLead";
import ConvertDeal from "./ConvertDeal";
import EmailDetailsModal from "../../EmailDetailsModal";
import PrintLeadsOverview from "./PrintLeadsOverview";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import { useLocation, useParams } from "react-router-dom";
import ApiCall from "../../../../../api/ApiCall";
import {
  CrmTaskRelatedEndPoints,
  LeadRelatedEndPoints,
  StatusRelatedEndPoints,
} from "../../../../../api/Endpoints";
import TaskActionWarningModal from "./TaskActionWarning/TaskActionWarningModal";
import CreateCalls from "./CallsCard/CreateCalls";
import CallDetailsModal from "./CallsCard/CallDetailsModal";
import AssigneGroupModal from "../../../Assignee/AssigneGroupModal";
import AssigneeEmployeeInLead from "../../../Assignee/AssigneeEmployeeInLead";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TimelineMoreInfoModal from "./TimelineMoreInfoModal";
import LeadStatusChangeModal from "./LeadStatusChangeModal";
import AddProductModal from "./TaskDetails/AddProductModal";
import UpdatedForModal from "./UpdatedForModal";
import ImageViewModal from "./ActivityMandatoryTask/ImageViewModal";

const Overview = () => {
  const { id } = useParams();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [emailDetailsModal, setEmailDetailsModal] = useState(false);
  const componentRef = useRef();
  const [leadDetails, setLeadDetails] = useState([]);
  const [editLeadData, setEditLeadData] = useState("");
  const [loader, setloader] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [subStatus, setSubStatus] = useState([]);
  const [taskLoader, settaskLoader] = useState(false);
  const [taskWarningModal, settaskWarningModal] = useState(false);
  const [activityDetails, setactivityDetails] = useState("");
  const [editLeadParticipant, seteditLeadParticipant] = useState("");
  const [editGroup, seteditGroup] = useState("");
  const [moreInfoModal, setmoreInfoModal] = useState(false);
  const [timelineDetails, settimelineDetails] = useState(null);
  const [statusWarningDetails, setstatusWarningDetails] = useState([]);
  const [allActivity, setallActivity] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedParticipantId, setselectedParticipantId] = useState([]);
  const [deadline, setDeadline] = useState(0);
  const [productModal, setProductModal] = useState(false);
  const [mandatoryLoader, setmandatoryLoader] = useState(false);
  const [allEmployees, setallEmployees] = useState([]);
  const [totalCounts, setTotalCounts] = useState([]);
  const [updatedForModal, setupdatedForModal] = useState(false);
  const [imageData, setimageData] = useState([]);
  const [imageModal, setimageModal] = useState(false);
  const [convertDeal, setConvertDeal] = useState(false);

  // Assign Group and Assign Employee
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [selectedEmpId, setselectedEmpId] = useState([]);
  const [groupAssigneeModal, setgroupAssigneeModal] = useState(false);
  const [selectedGroups, setselectedGroups] = useState([]);
  const [selectedGroupId, setselectedGroupId] = useState([]);
  const [changeStatusloader, setChangeStatusLoader] = useState(false);

  // FOR task
  const [editTask, seteditTask] = useState("");
  const [editMeeting, seteditMeeting] = useState("");
  const [loader2, setloader2] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    seterrorMessage("");
    setallEmployees([]);
    setselectedParticipantId([]);
    setChangeStatusLoader(false);
  };

  const handleChangeStatus = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setChangeStatusLoader);
    const temp = allEmployees?.map((emp) => emp._id);
    const data = {
      actionBy: user?._id,
      leadIdOrDealId: id,
      statusId: statusId?._id,
      task: [],
      deadline: parseInt(deadline) || 0,

      assignees:
        statusId?.activities?.length > 0
          ? selectedParticipantId?.length > 0
            ? selectedParticipantId
            : temp
          : [],
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.changeLeadStatus,
      data
    );
    if (res?.success) {
      handleClose();
      handleGetLeadDetails();
      handleGetAllTask();
    } else {
      seterrorMessage(res.error);
      setChangeStatusLoader(false);
    }
  };

  //NEW STATES
  const handleGetAllStatus = async () => {
    startApiCall(null, setStatusLoader);
    const data = {
      page: 1,
      limit: 1000,
      filters: {
        statusGroupId: leadDetails?.groupId,
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getStatus, data);
    if (res?.success) {
      setSubStatus(res?.result?.data);
      setStatusLoader(false);
    } else {
      setStatusLoader(false);
    }
  };

  useEffect(() => {
    if (leadDetails?.groupId) {
      handleGetAllStatus();
    }
  }, [leadDetails]);

  const handleReset = () => {
    setselectedEmpId([]);
    setselectedEmployees([]);
    setassigneeModal(false);
  };

  //Add Participant to Lead
  const handleAddParticipantToLead = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      leadOrDealId: id,
      employeeIds: selectedEmpId,
      participantsGroupActionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.addParticipantInLead,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetLeadDetails();
      toast.success("Participant added successfully!");
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  // GET LEAD DETAILS FUNCTION API CALL
  const handleGetLeadDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      leadId: id,
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.getLeadDetails,
      data
    );
    if (res?.success) {
      setLeadDetails(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetLeadDetails();
    closeAllModals();
  }, []);

  // GET MEETING,CALL,TASK FUNCTION API CALL
  const handleGetAllTask = async () => {
    startApiCall(null, settaskLoader);
    const data = {
      page: 1,
      limit: 5000,
      sortBy: "createdAt",
      // employeeId: user?._id,
      filters: {
        actionOn: id,
        isDeleted: false,
        relatedTo: "Lead",
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", CrmTaskRelatedEndPoints.getTask, data);
    if (res?.success) {
      const activity = res?.result?.data;
      setallActivity(activity);
      setTotalCounts(res?.result);
      settaskWarningModal(false);
      settaskLoader(false);
    } else {
      settaskLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllTask();
  }, []);

  const handleRefresh = () => {
    handleGetLeadDetails();
    handleGetAllTask();
  };

  const handleGetMandatoryTask = async (id, statusID, activity, statusName) => {
    startApiCall(null, setmandatoryLoader);
    const data = {
      activityId: id,
      activityStatusId: statusID,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.checkMandatoryTask,
      data
    );
    if (res?.success) {
      const taskToBeDone = findMandatoryItems(res?.result);
      if (taskToBeDone?.some((task) => Object.values(task)[0])) {
        seteditTask({
          task: taskToBeDone,
          statusID,
          activityId: id,
          activity: activity,
          statusName,
        });
        const closeModalDom = document.getElementById("call_details_modal");
        if (closeModalDom) closeModalDom.click();
        setstatusWarningDetails(activity);
        setactivityDetails(activity);
        settaskWarningModal(true);
      } else {
        handleEditActivity(activity, statusID, activity?.taskType);
      }
      setmandatoryLoader(false);
    } else {
      setmandatoryLoader(false);
    }
  };

  const handleEditActivity = async (task, status, taskType) => {
    startApiCall(seterrorMessage, setmandatoryLoader);
    const sendData = {
      leadTaskId: task?._id,
      taskType: taskType,
      title: task?.title,
      dueDate: task?.dueDate,
      priority: task?.priority,
      relatedTo: task?.relatedTo,
      activityStatus: status,
      actionOn: task?.actionOn?.id,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.editTask,
      sendData
    );
    if (res?.success) {
      setmandatoryLoader(false);
      handleGetAllTask();
      toast.success("Status changed successfully!");
    } else {
      setmandatoryLoader(false);
      seterrorMessage(res.error);
    }
  };

  function findMandatoryItems(data) {
    let tasks = [];

    data?.map((item) => {
      const key = Object.keys(item)[0];
      const mandatory = item[key]?.mandatory;

      if (mandatory) {
        tasks.push({
          [key]: item[key]?.mandatory,
          description: item[key]?.description,
        });
      }
    });

    return tasks;
  }

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <OverviewHeader
            setEmailDetailsModal={setEmailDetailsModal}
            componentRef={componentRef}
            handleGetLeadDetails={handleRefresh}
            leadDetails={leadDetails}
            id={id}
            setConvertDeal={setConvertDeal}
            location={location}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar py-2 px-3">
              <div className="contact-list-view">
                <div className="d-flex">
                  <DetailsLeftCard
                    subStatus={subStatus}
                    leadDetails={leadDetails}
                    statusLoader={statusLoader}
                    id={id}
                    handleGetLeadDetails={handleGetLeadDetails}
                    loader={loader}
                    taskLoader={taskLoader}
                    setEditLeadData={setEditLeadData}
                    allActivity={allActivity}
                    setgroupAssigneeModal={setgroupAssigneeModal}
                    setassigneeModal={setassigneeModal}
                    setactivityDetails={setactivityDetails}
                    seteditLeadParticipant={seteditLeadParticipant}
                    seteditGroup={seteditGroup}
                    setmoreInfoModal={setmoreInfoModal}
                    settimelineDetails={settimelineDetails}
                    setIsOpen={setIsOpen}
                    setStatusId={setStatusId}
                    totalCounts={totalCounts}
                    setProductModal={setProductModal}
                    setupdatedForModal={setupdatedForModal}
                    handleGetMandatoryTask={handleGetMandatoryTask}
                  />
                </div>
              </div>
              {emailDetailsModal && (
                <EmailDetailsModal
                  setEmailDetailsModal={setEmailDetailsModal}
                  leadDetails={leadDetails}
                  id={id}
                  handleGetLeadDetails={handleGetLeadDetails}
                />
              )}
            </div>
          </div>
        </div>

        <CreateCalls
          handleGetAllTask={handleGetAllTask}
          leadDetails={leadDetails}
          handleRefresh={handleRefresh}
          isLeadDeal={true}
        />
        <AddMeetingCrm
          handleGetMeeting={handleRefresh}
          editMeeting={editMeeting}
          seteditMeeting={seteditMeeting}
          isLeadDeal={true}
          leadDetails={leadDetails}
          id={id}
        />

        <AddTaskCrm
          handleGetTask={handleRefresh}
          seteditTask={seteditTask}
          editTask={""}
          isLeadDeal={true}
          leadDetails={leadDetails}
          id={id}
        />

        <CreateLead
          editLeadData={editLeadData}
          setEditLeadData={setEditLeadData}
          handleGetLeadDetails={handleGetLeadDetails}
        />
        {convertDeal && (
          <ConvertDeal
            leadDetails={leadDetails}
            id={id}
            setConvertDeal={setConvertDeal}
          />
        )}
        {taskWarningModal && (
          <TaskActionWarningModal
            settaskWarningModal={settaskWarningModal}
            editTask={editTask}
            statusWarningDetails={statusWarningDetails}
            handleGetAllTask={handleGetAllTask}
            handleEditActivity={handleEditActivity}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}

        <CallDetailsModal
          subStatus={subStatus}
          leadDetails={leadDetails}
          statusLoader={statusLoader}
          loader={loader}
          activityDetails={activityDetails}
          setactivityDetails={setactivityDetails}
          handleAllTask={handleGetAllTask}
          seteditTask={seteditTask}
          settaskWarningModal={settaskWarningModal}
          setstatusWarningDetails={setstatusWarningDetails}
          editTask={editTask}
          relatedTo="Lead"
          handleGetMandatoryTask={handleGetMandatoryTask}
          setimageData={setimageData}
          setimageModal={setimageModal}
        />

        {assigneeModal && (
          <AssigneeEmployeeInLead
            setassigneeModal={setassigneeModal}
            assigneeModal={assigneeModal}
            selectedEmployees={selectedEmployees}
            setselectedEmployees={setselectedEmployees}
            selectedEmpId={selectedEmpId}
            setselectedEmpId={setselectedEmpId}
            handleAddParticipant={handleAddParticipantToLead}
            editData={editLeadParticipant}
            loader2={loader2}
            leadDetails={leadDetails}
          />
        )}
        <AssigneGroupModal
          selectedGroups={selectedGroups}
          setselectedGroups={setselectedGroups}
          groupAssigneeModal={groupAssigneeModal}
          setgroupAssigneeModal={setgroupAssigneeModal}
          selectedGroupId={selectedGroupId}
          setselectedGroupId={setselectedGroupId}
          leadDetails={leadDetails}
          editGroup={editGroup}
          handleGetLeadDetails={handleGetLeadDetails}
        />
        {moreInfoModal && (
          <TimelineMoreInfoModal
            setmoreInfoModal={setmoreInfoModal}
            timelineDetails={timelineDetails}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {updatedForModal && (
          <UpdatedForModal
            setupdatedForModal={setupdatedForModal}
            timelineDetails={timelineDetails}
          />
        )}
        {isOpen && (
          <LeadStatusChangeModal
            onClose={handleClose}
            text={
              <span>
                Are you sure you want change status to{" "}
                <span className="fw-bold text-capitalize">
                  {statusId?.name}
                </span>{" "}
                ?{" "}
              </span>
            }
            loader={changeStatusloader}
            errorMessage={errorMessage}
            handleConfirm={handleChangeStatus}
            statusId={statusId}
            leadDetails={leadDetails}
            selectedEmpId={selectedParticipantId}
            setselectedEmpId={setselectedParticipantId}
            setDeadline={setDeadline}
            deadline={deadline}
            allEmployees={allEmployees}
            setallEmployees={setallEmployees}
          />
        )}
        {imageModal && (
          <ImageViewModal
            imageData={imageData}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {/* Product modal */}
        {productModal && <AddProductModal setProductModal={setProductModal} />}
      </div>
      <div style={{ display: "none" }}>
        <PrintLeadsOverview
          ref={componentRef}
          leadDetails={leadDetails}
          allActivity={allActivity}
        />
      </div>
    </div>
  );
};

export default Overview;
