import React, { useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  CrmParticipantsGroup,
  LeadRelatedEndPoints,
} from "../../../api/Endpoints";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AssignGroup = ({ leadId, handleGetAllLeads }) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [groupLoader, setGroupLoader] = useState(false);
  const [selectGroup, setSelectGroup] = useState("");
  const [selectGrpId, setSelectGrpId] = useState("");
  const [filterGrp, setFilterGrp] = useState("");
  const [allGroup, setallGroup] = useState([]);
  const filterParticipantGroup = allGroup?.filter((grp) =>
    grp?.name?.toLowerCase()?.includes(filterGrp.toLowerCase())
  );

  const handleGetAllGroups = async () => {
    startApiCall(null, setGroupLoader);
    const data = {
      page: 1,
      limit: 500,
      filters: { businessId: getCurrentWorkspaceId(), searchKey: "" },
    };
    const res = await ApiCall("post", CrmParticipantsGroup.getGroup, data);
    if (res?.success) {
      setallGroup(res?.result?.data);
      setGroupLoader(false);
    } else {
      setGroupLoader(false);
    }
  };

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setSelectGroup("");
    setSelectGrpId("");
    const closeModalDom = document.getElementById("assign_group");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAssignGroup = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      leadId,
      leadGroupId: selectGrpId,
      leadGroupActionBy: user?._id
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.editLead, data);
    if (res?.success) {
      toast.success("Assign to group successfully!");
      handleReset();
      handleGetAllLeads();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="assign_group"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content" style={{overflow:"visible"}}>
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Assign Group</h5>
            <div className="border-bottom mb-3"></div>
            <form onSubmit={handleAssignGroup}>
              <div className="mnh-100p">
                <div className="row gx-3 px-3 w-100">
                  <div className="col-sm-12">
                    <label className="form-label">Group</label>
                    <div className="form-group">
                      <div className="selectable-dropdown">
                        <div className="dropdown selectable-dropdown">
                          <span
                            className="me-2 fs-7 fw-medium  text-dark text-capitalize border rounded px-4 py-2 w-100"
                            aria-expanded="false"
                            type="button"
                            data-bs-toggle="dropdown"
                            onClick={() => {
                              handleGetAllGroups();
                              setFilterGrp("");
                            }}
                          >
                            {selectGroup ? (
                              <>
                                {selectGroup}
                                {selectGroup !== "" && (
                                  <button
                                    type="button"
                                    className="btn-close btn-sm ms-2"
                                    onClick={() => {
                                      setSelectGroup("");
                                      setFilterGrp("");
                                    }}
                                    aria-label="Close"
                                  ></button>
                                )}
                              </>
                            ) : (
                              "Select group"
                            )}
                          </span>
                          <div role="menu" className="dropdown-menu">
                            <form className="d-sm-block d-none" role="search">
                              <input
                                type="text"
                                className="form-control h-25"
                                placeholder="Search.."
                                value={filterGrp}
                                onChange={(e) => setFilterGrp(e.target.value)}
                              />
                            </form>
                            {groupLoader ? (
                              <span className="d-flex text-center mt-1">
                                Loading...
                              </span>
                            ) : (
                              <div
                                style={{
                                  maxHeight: "22vh",
                                  overflow: "auto",
                                }}
                              >
                                {filterParticipantGroup?.map((grp, index) => (
                                  <div
                                    className="dropdown-item text-capitalize"
                                    key={grp.name}
                                    onClick={() => {
                                      setSelectGroup(grp.name);
                                      setSelectGrpId(grp?._id);
                                    }}
                                  >
                                    {index + 1}. {grp.name}
                                  </div>
                                ))}
                              </div>
                            )}
                            {!groupLoader &&
                              filterParticipantGroup?.length === 0 && (
                                <span className="d-flex text-center mt-1">
                                  No group found
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={!selectGroup}>
                  {loader ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignGroup;
