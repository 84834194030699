import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import AllBusiness from "../../pages/Approval/AllBusiness/AllBusiness";


const BusinessApprovalsRoutes = {
  path: "/approval",
  element: <Navbar />,
  children: [
    {
      path: '',
      element: <AllBusiness/>,
    },
    {
        path: 'allbusiness',
        element: <AllBusiness />,
      },
  ],
};

export default BusinessApprovalsRoutes;