import React, { useEffect, useState } from "react";
import { CustomTab } from "../../../../components/CustomTab/CustomTab";
import AllRequest from "./AllRequest";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { leaveRelatedEndPoints } from "../../../../api/Endpoints";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import FooterPagination from "../../../../components/Pagination/FooterPagination";

const LeaveRequest = () => {
  const [status, setStatus] = useState("pending");
  const [loader, setLoader] = useState(false);
  const [allLeaveRequest, setAllLeaveRequest] = useState([]);
  const [search, setSearch] = useState();

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleTabChange = (status) => {
    setStatus(status);
  };

  const allTabs = [
    {
      name: "Approved",
      value: "approved",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-success">
          {allLeaveRequest?.approvedLeave}
        </span>
      ),
    },
    {
      name: "Pending",
      value: "pending",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-warning">
          {allLeaveRequest?.pendingLeave}
        </span>
      ),
    },
    {
      name: "Rejected",
      value: "rejected",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-danger">
          {allLeaveRequest?.rejecteddLeave}
        </span>
      ),
    },
  ];

  // GET ALL LEAVE REQUEST FUNCTION API CALL
  const handleGetAllLeaveRequest = async () => {
    startApiCall(null, setLoader);
    const sendData = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: status,
        searchKey: search,
      },
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.getLeaveRequest,
      sendData
    );
    if (res?.success) {
      setAllLeaveRequest(res?.leave);
      setcount(res?.leave?.data?.totalCounts);
      settotalPages(res?.leave?.data?.totalPages);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllLeaveRequest();
      closeAllModals();
    }
  }, [search, status, page, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <span className="todoapp-title link-dark">
              <h1>Leave Request</h1>
            </span>
            <div className="todo-options-wrap">
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                data-bs-original-title="Refresh"
              >
                <span
                  className="btn-icon-wrap"
                  onClick={handleGetAllLeaveRequest}
                >
                  <span className="feather-icon">
                    <i className="ri-refresh-line"></i>
                  </span>
                </span>
              </span>
              <div className="v-separator d-lg-inline-block d-none"></div>
              <form className="d-sm-block d-none me-2" role="search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search employee"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                />
              </form>
            </div>
          </header>
          {/* *******CUSTOM TAB******** */}
          <CustomTab
            allTabs={allTabs}
            currentTab={status}
            handleTabChange={handleTabChange}
            isShowCount={true}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view d-flex flex-column gap-3 mb-8">
                {!loader && allLeaveRequest?.data?.data?.length > 0 && (
                  <AllRequest
                    allLeaveRequest={allLeaveRequest}
                    status={status}
                    handleGetAllLeaveRequest={handleGetAllLeaveRequest}
                  />
                )}
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allLeaveRequest?.data?.data?.length}
                  align={"end"}
                />
                {!loader && allLeaveRequest?.data?.data?.length === 0 && (
                  <NoData title={`No ${status} leave request`} />
                )}
                {loader && <DataLoader title="data is loading..." />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveRequest;
