import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ApiCall from "../../api/ApiCall";
import { invitationWorkspaceEndPoints } from "../../api/Endpoints";
import { Avatar } from "../../components/Avatar/Avatar";
import ConfirmModal from "../../components/ConfirmationModal/ConfirmModal";
import {
  dateAndTimeFormatter,
  getAllWorkspaceRequests,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";

const Invitations = ({ setbusinessRequest, user, businessRequest }) => {
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isOpenConfirmationModal, setisOpenConfirmationModal] = useState(false);
  const [isApproved, setisApproved] = useState(false);
  const [selectedBusinessId, setselectedBusinessId] = useState("");

  const getRequest = async () => {
    startApiCall(seterrorMessage, setLoader);
    let req = await getAllWorkspaceRequests(user?._id);
    setbusinessRequest(req);
    setLoader(false);
  };

  useEffect(() => {
    // if (getCurrentWorkspaceId()) {
    getRequest();
    // }
  }, []);

  const handleClose = () => {
    setisOpenConfirmationModal(false);
    seterrorMessage("");
    setLoader(false);
  };

  const handleApproveOrRejectRequest = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      businessId: selectedBusinessId,
      isApproved,
      employeeId: user?._id,
    };
    const res = await ApiCall(
      "post",
      invitationWorkspaceEndPoints.approveorRejectWorkspaceRequestFromInApp,
      data
    );
    if (res?.success) {
      getRequest();
      handleClose();
      toast.success(
        `Invitation ${isApproved ? "Approved" : "Reject"} successfully!`
      );
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <>
      <div className="dropdown-menu dropdown-menu-end invitation-dropdown mnw-300p">
        <div className="p-2">
          <div className="media">
            <div className="media-body">
              <div className="dropdown">
                <span className="d-block link-dark fw-medium fs-5">
                  Workspace Invitations
                  <span onClick={getRequest} className="ms-1 fw-bold pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-clockwise"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                      />
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                    </svg>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="dropdown-divider"></div>
        {loader && <p className="text-dark text-center">Refresh...</p>}

        <div
          className="mh-330p simplebar-hide-scrollbar"
          style={{ overflowY: "scroll" }}
        >
          {businessRequest?.map((data) => {
            return (
              <Link className="dropdown-item p-2 border mb-2" key={data?._id}>
                <div>
                  <div className="d-flex align-items-center gap-1">
                    <Avatar
                      name={data?.businessId?.name}
                      count={1}
                      image={data?.businessId?.image}
                      size="xs"
                      color="primary"
                    />
                    <div>
                      <p className="fs-7 text-truncate">
                        {data?.businessId?.name}
                      </p>
                      <p className="fs-8">
                        {dateAndTimeFormatter(data?.updatedAt)}
                        {data?.isExpired && (
                          <span className="badge badge-pill badge-sm badge-soft-danger ms-2">
                            expired
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  {!data?.isExpired && (
                    <div className="d-flex align-items-center justify-content-end gap-1">
                      <span
                        className="btn btn-icon btn-sm btn-rounded btn-success d-grid"
                        style={{ placeItems: "center" }}
                        onClick={() => {
                          setselectedBusinessId(data?.businessId?._id);
                          setisApproved(true);
                          setisOpenConfirmationModal(true);
                        }}
                      >
                        <i className="ri-check-line"></i>
                      </span>
                      <span
                        className="btn btn-icon btn-sm btn-rounded btn-danger d-grid"
                        style={{ placeItems: "center" }}
                        onClick={() => {
                          setselectedBusinessId(data?.businessId?._id);
                          setisApproved(false);
                          setisOpenConfirmationModal(true);
                        }}
                      >
                        <i className="ri-close-line"></i>
                      </span>
                    </div>
                  )}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <ConfirmModal
        isOpen={isOpenConfirmationModal}
        onClose={handleClose}
        text={`Are you sure you want to ${
          isApproved ? "Approved" : "Reject"
        } workspace request ?`}
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleApproveOrRejectRequest}
      />
    </>
  );
};

export default Invitations;
