import React from "react";
import { dateAndTimeFormatter } from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";

const ItemDetails = ({ itemDetails, setOpenItemDetails, showImageInput }) => {
  return (
    <div className="details-modal">
      <div className="nicescroll-bar">
        <div className="d-flex justify-content-between">
          <span className="file-name">Item Details</span>
          <button
            onClick={() => setOpenItemDetails(false)}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="tab-content mt-1">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple d-flex flex-column gap-3 h-100 nicescroll-bar simple-scrollbar p-0">
              {showImageInput && (
                <>
                  {itemDetails?.images?.[0] && (
                    <div className="d-flex justify-content-center mt-2">
                      <img
                        className="rounded"
                        height="120"
                        width="120"
                        src={itemDetails?.images?.[0]}
                        alt=""
                        style={{ border: "1px solid #333333" }}
                      />
                    </div>
                  )}
                </>
              )}
              <div className="card bg-light px-3 rounded-5 mt-2 mb-8">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#fl_info"
                    aria-expanded="true"
                    className="fs-6"
                  >
                    Details
                  </a>
                </div>
                <div id="fl_info" className="collapse show">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span className="me-0">Name : </span>
                        <span className="text-capitalize">
                          {itemDetails?.itemName}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Code : </span>
                        <span className="text-capitalize">
                          {itemDetails?.itemCode}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Type : </span>
                        <span className="text-capitalize">
                          {itemDetails?.itemType === "good"
                            ? "Goods"
                            : "Services"}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Unit : </span>
                        <span className="text-capitalize">
                          {itemDetails?.unit}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Selling Price : </span>
                        <span className="text-capitalize">
                          {itemDetails?.salePrice}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Created By : </span>
                        <span className="text-capitalize">
                          <Avatar
                            name={itemDetails?.createdBy?.name}
                            count={1}
                            image={itemDetails?.createdBy?.image}
                            size="xss"
                            color="primary"
                          />
                          &nbsp;{itemDetails?.createdBy?.name}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Created At : </span>
                        <span className="text-capitalize">
                          {dateAndTimeFormatter(itemDetails?.createdAt)}
                        </span>
                      </li>
                      <li>
                        <span className="me-0">Updated At : </span>
                        <span className="text-capitalize">
                          {dateAndTimeFormatter(itemDetails?.updatedAt)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
