import React from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const EditStaff = (props) => {
  const {
    isOpen,
    onClose,
    text,
    loader,
    errorMessage,
    handleConfirm,
    header,
    salaryData,
    setSalaryData,
    employeeData,
    editSalary,
  } = props;

  return (
    <div
      id="confirm"
      className={`modal fade add-new-contact mt-30 ${isOpen ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{
        display: isOpen ? "block" : "none",
        pointerEvents: loader ? "none" : "",
      }}
      aria-hidden="true"
    >
      <form>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="mb-4 text_center">{header}</h5>
              <div className="row gx-3">
                <label className="form-label text_center">{text}</label>
              </div>
              {editSalary && (
                <div className="d-flex justify-content-center col-12 row gx-3">
                  <div className="form-group col-6 mt-3">
                    <label className="form-label">Pervious Salary ₹</label>
                    <br />
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Salary"
                      disabled
                      value={employeeData?.staff?.salary}
                    />
                  </div>
                  <div className="form-group col-6 mt-3">
                    <label className="form-label">Current Salary ₹</label>
                    <br />
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Salary"
                      required
                      value={salaryData}
                      onChange={(e) => setSalaryData(e.target.value)}
                    />
                  </div>
                </div>
              )}
              <div className="text_center"></div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
            <div
              className="px-4 pb-4"
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                disabled={loader}
                className="btn btn-rounded btn-secondary col-sm-5"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                disabled={loader}
                className="btn btn-rounded btn-primary col-sm-5"
                onClick={handleConfirm}
              >
                {loader ? <ApiLoader /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditStaff;
