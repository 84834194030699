import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CrmContactRelatedEndPoints } from "../../../api/Endpoints";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import allCountries from "../../../components/Countries/Countries";

const CreateContact = ({
  handleGetContact,
  editContactData,
  setEditContactData,
}) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //ABOUT
  const [name, setName] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [contactRole, setcontactRole] = useState("");
  const [description, setdescription] = useState("");
  const [websiteLink, setwebsiteLink] = useState("");

  // Contacts Details
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setemail] = useState("");
  const [address, setAddress] = useState({
    state: "",
    city: "",
    pinCode: "",
    address: "",
  });

  //Additional
  const [noOfEmployees, setnoOfEmployees] = useState("");
  const [annualRevenue, setannualRevenue] = useState("");
  const [industry, setindustry] = useState("");
  const [isIndividual, setisIndividual] = useState(false);
  const [countryCode, setcountryCode] = useState("");
  const [codeFilter, setcodeFilter] = useState("");

  const filteredCountries = allCountries?.filter(
    (country) =>
      country?.code?.includes(codeFilter) ||
      country?.country?.toLowerCase()?.includes(codeFilter?.toLowerCase())
  );

  //Address
  const handleAddress = (e) => {
    const { name, value } = e.target;
    setAddress({
      ...address,
      [name]: value,
    });
  };

  // ADD CONTACT IN CRM
  const handleAddContact = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      name: name,
      phone: phoneNumber,
      email: email,
      isInvidual: true,
      businessName: companyName,
      countryCode: countryCode,
      source: "",
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
      websiteLink: websiteLink,
      description: description,
      address: address.address,
      state: address.state,
      city: address.city,
      pinCode: address.pinCode,
      numberOfEmployees: noOfEmployees,
      annualRevenue: annualRevenue,
      industry: industry,
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.addContact,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetContact();
      toast.success("Contact added successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };
  // EDIT CONTACT IN CRM
  const handleEditContact = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      contactId: editContactData?._id,
      name: name,
      phone: phoneNumber,
      email: email,
      businessName: companyName,
      countryCode: countryCode,
      source: "",
      websiteLink: websiteLink,
      description: description,
      address: address.address,
      state: address.state,
      city: address.city,
      pinCode: address.pinCode,
      numberOfEmployees: noOfEmployees,
      annualRevenue: annualRevenue,
      industry: industry,
      updatedBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.editContact,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetContact();
      toast.success("Contact edited successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setName("");
    setcompanyName("");
    setcontactRole("");
    setdescription("");
    setwebsiteLink("");
    setphoneNumber("");
    setemail("");
    setAddress({
      state: "",
      city: "",
      pinCode: "",
      address: "",
    });
    setnoOfEmployees("");
    setindustry("");
    setannualRevenue("");
    setEditContactData("");
    setisIndividual(false);
    const closeModalDom = document.getElementById("create_new_contact");
    if (closeModalDom) closeModalDom.click();
  };

  useEffect(() => {
    if (editContactData) {
      setName(editContactData?.name);
      // setcontactRole(editContactData)
      setcompanyName(editContactData?.businessName);
      setdescription(editContactData?.description);
      setwebsiteLink(editContactData?.websiteLink);
      setphoneNumber(editContactData?.phone);
      setemail(editContactData?.email);
      setcountryCode(editContactData?.countryCode);
      setAddress({
        state: editContactData?.state,
        city: editContactData?.city,
        pinCode: editContactData?.pinCode,
        address: editContactData?.address,
      });
      setnoOfEmployees(editContactData?.numberOfEmployees);
      setannualRevenue(editContactData?.annualRevenue);
      setindustry(editContactData?.industry);
      setisIndividual(editContactData?.isInvidual);
    }
  }, [editContactData]);
  return (
    <div
      id="create_new_contact"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "60%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editContactData === "" ? "Create" : "Edit"} Contact
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editContactData) {
                  handleEditContact(e);
                } else {
                  handleAddContact(e);
                }
              }}
            >
              <div className="mh-450p nicescroll-bar simple-scrollbar">
                <div className="row gx-3 px-3 w-100">
                  <div
                    className="d-flex justify-content-start mt-1 mb-3 fs-5 text-dark"
                    style={{ backgroundColor: "#E3EDFF" }}
                  >
                    About
                  </div>
                  {/* <div className="fs-6 d-flex align-items-end justify-content-end gap-2">
                    <label className="mb-1">Individual</label>
                    <div class="form-check form-switch">
                      <input
                        className={`form-check-input`}
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        checked={isIndividual}
                        onClick={(e) => setisIndividual(!isIndividual)}
                      />
                    </div>
                  </div> */}
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Name {reuiredField}</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        placeholder="name"
                        name="firstName"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">Company Name </label>
                      <input
                        className="form-control"
                        placeholder="company name"
                        type="text"
                        value={companyName}
                        onChange={(e) => {
                          setcompanyName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Your Role/Occupation{" "}
                      </label>
                      <input
                        className="form-control"
                        placeholder="role"
                        type="text"
                        value={contactRole}
                        onChange={(e) => {
                          setcontactRole(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label className="form-label">Website link</label>
                      <input
                        className="form-control"
                        placeholder="website link"
                        type="text"
                        value={websiteLink}
                        onChange={(e) => {
                          setwebsiteLink(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <input
                        className="form-control"
                        placeholder="description"
                        type="text"
                        value={description}
                        onChange={(e) => {
                          setdescription(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {/* contact */}
                  <div
                    className="d-flex justify-content-start mt-3 mb-3 fs-5 text-dark"
                    style={{ backgroundColor: "#E3EDFF" }}
                  >
                    Contact Details
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Phone Number {reuiredField}
                      </label>
                      <div className="d-flex">
                        <div className="selectable-dropdown">
                          <div className="dropdown selectable-dropdown">
                            <span
                              className={
                                "me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-110p"
                              }
                              aria-expanded="false"
                              type="button"
                              data-bs-toggle="dropdown"
                              onClick={() => setcodeFilter("")}
                            >
                              {countryCode ? (
                                <>
                                  + {countryCode}
                                  {countryCode !== "" && (
                                    <button
                                      type="button"
                                      className="btn-close btn-sm ms-1"
                                      onClick={() => {
                                        setcountryCode("");
                                        setcodeFilter("");
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  )}
                                </>
                              ) : (
                                "Code"
                              )}
                            </span>
                            <div role="menu" className="dropdown-menu">
                              <form className="d-sm-block d-none" role="search">
                                <input
                                  type="text"
                                  placeholder="Search.."
                                  className="form-control"
                                  value={codeFilter}
                                  onChange={(e) =>
                                    setcodeFilter(e.target.value)
                                  }
                                />
                              </form>
                              <div
                                style={{
                                  maxHeight: "28vh",
                                  overflow: "auto",
                                  zIndex: 9999,
                                }}
                              >
                                {filteredCountries?.map((country) => (
                                  <div
                                    className="dropdown-item text-Capitalize"
                                    key={country.country}
                                    onClick={() => setcountryCode(country.code)}
                                  >
                                    <span>
                                      {" "}
                                      + {country?.code} &nbsp;{" "}
                                      {country?.country}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <input
                          className="form-control"
                          type="tel"
                          title="Please enter a phone number"
                          placeholder="phone number"
                          required
                          value={phoneNumber}
                          disabled={!countryCode}
                          maxLength="15"
                          onChange={(e) => {
                            setphoneNumber(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Email id {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        placeholder="email"
                        type="text"
                        required
                        value={email}
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Address </label>
                      <input
                        className="form-control"
                        placeholder="address"
                        type="text"
                        name="address"
                        value={address.address}
                        onChange={handleAddress}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">State</label>
                      <input
                        className="form-control"
                        placeholder="state"
                        type="text"
                        name="state"
                        value={address.state}
                        onChange={handleAddress}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">City</label>
                      <input
                        className="form-control"
                        placeholder="city"
                        type="text"
                        name="city"
                        value={address.city}
                        onChange={handleAddress}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">Pin code</label>
                      <input
                        className="form-control"
                        placeholder="Pin Code"
                        type="number"
                        name="pinCode"
                        value={address.pinCode}
                        onChange={handleAddress}
                      />
                    </div>
                  </div>
                  {/* Additional */}
                  <div
                    className="d-flex justify-content-start mt-3 mb-3 fs-5 text-dark"
                    style={{ backgroundColor: "#E3EDFF" }}
                  >
                    Additional
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label className="form-label">No. of Employees</label>
                      <input
                        className="form-control"
                        placeholder="number"
                        type="number"
                        value={noOfEmployees}
                        onChange={(e) => {
                          setnoOfEmployees(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label className="form-label">Annual Revenue</label>
                      <input
                        className="form-control"
                        placeholder="revenue"
                        type="number"
                        value={annualRevenue}
                        onChange={(e) => {
                          setannualRevenue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label className="form-label">Industry</label>
                      <input
                        className="form-control"
                        placeholder="industry"
                        type="text"
                        value={industry}
                        onChange={(e) => {
                          setindustry(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateContact;
