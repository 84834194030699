import React from "react";

const ItemCategoryHeader = ({
  search,
  handleSearch,
  handleGetItemCategory,
  isModuleReadWriteAccessForCategoryItems,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <a className="todoapp-title link-dark">
          <h5 className="header-title-font  mt-2">Categories</h5>
          {isModuleReadWriteAccessForCategoryItems && (
            <button
              className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block ms-3  header-button-shadow"
              data-bs-toggle="modal"
              data-bs-target="#add_new_category"
            >
              Add Category
            </button>
          )}
        </a>
      </div>
      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={() => {
            handleGetItemCategory();
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-lg-block d-sm-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
          />
        </form>
      </div>
    </header>
  );
};

export default ItemCategoryHeader;
