import React, { useState } from "react";
import AddressDetails from "./AddressDetails";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { CustomerRelatedEndPoints } from "../../../../api/Endpoints";
import ItemsTableInvoice from "./ItemsTableInvoice";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

const GenerateInvoiceBody = (props) => {
  const {
    invoiceDate,
    setinvoiceDate,
    dueDate,
    setdueDate,
    selectedCustomer,
    setselectedCustomer,
    setinvoiceNumber,
    invoiceNumber,
    setdescription,
    description,
    shippingAddress,
    setshippingAddress,
    billingAddress,
    setbillingAddress,
    invoiceItems,
    handleAddInovices,
    calculateTotalSum,
    errorMessage,
    setTermsAndConditions,
    termsAndConditions,
    items,
    locationData,
    customerData,
    setcustomerData,
    quoteDetailsData
  } = props;
  const [loader2, setloader2] = useState(false);
  const [allCustomers, setallCustomers] = useState([]);
  const [customerNameFilter, setcustomerNameFilter] = useState("");

  const filteredCustomers = filterSearchData(allCustomers, customerNameFilter);

  const handleGetAllCustomer = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      CustomerRelatedEndPoints.getCustomer,
      data
    );
    if (res?.success) {
      setallCustomers(res?.customer?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <div className="row">
          <div className="col-md-9 p-5">
            <div className="d-flex flex-column gap-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row w-100 align-items-center">
                    <div className="col-sm-4">
                      <label>Customer Name {reuiredField}</label>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-group mb-0">
                        <div className="todo-toolbar">
                          <div className="selectable-dropdown">
                            <div className="dropdown selectable-dropdown">
                              <span
                                className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-200p mt-2 ${
                                  quoteDetailsData ? "bg-light text-disabled" : ""
                                }`}
                                aria-expanded="false"
                                type="button"
                                data-bs-toggle="dropdown"
                                disabled={locationData?.id}
                                onClick={() => {
                                  handleGetAllCustomer();
                                  setcustomerNameFilter("");
                                }}
                              >
                                {selectedCustomer ? (
                                  <div className="d-flex justify-content-between">
                                    {quoteDetailsData ? customerData : customerData?.name}
                                    {selectedCustomer !== "" && !quoteDetailsData && (
                                      <button
                                        type="button"
                                        className="btn-close btn-sm ms-1"
                                        onClick={() => {
                                          setselectedCustomer("");
                                          setcustomerNameFilter("");
                                          setcustomerData([]);
                                          setshippingAddress({
                                            country: "",
                                            city: "",
                                            zipCode: "",
                                            address: "",
                                          });
                                          setbillingAddress({
                                            country: "",
                                            city: "",
                                            zipCode: "",
                                            address: "",
                                          });
                                        }}
                                        aria-label="Close"
                                      ></button>
                                    )}
                                  </div>
                                ) : (
                                  "Select Customer"
                                )}
                              </span>
                              <div role="menu" className="dropdown-menu ">
                                <form
                                  className="d-sm-block d-none"
                                  role="search"
                                >
                                  <input
                                    type="text"
                                    placeholder="Search.."
                                    className="form-control"
                                    value={customerNameFilter}
                                    onChange={(e) =>
                                      setcustomerNameFilter(e.target.value)
                                    }
                                  />
                                </form>
                                {loader2 ? (
                                  <span className="d-flex text-center mt-1">
                                    Loading...
                                  </span>
                                ) : (
                                  <div
                                    style={{
                                      maxHeight: "30vh",
                                      overflow: "auto",
                                    }}
                                  >
                                    {filteredCustomers?.map((customer) => (
                                      <div
                                        className="dropdown-item text-capitalize"
                                        key={customer._id}
                                        onClick={() => {
                                          setselectedCustomer(customer._id);
                                          setcustomerData(customer);
                                        }}
                                      >
                                        {customer?.name}
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {!loader2 && filteredCustomers?.length === 0 && (
                                <span className="d-flex text-center mt-1">
                                  No Customer found
                                </span>
                              )}
                              </div>
                            </div>
                          </div>
                          {/* <select
                            required
                            name="unittype"
                            className="form-select form-select-md"
                            value={selectedCustomer}
                            disabled={locationData?.id}
                            onChange={(e) => {
                              setselectedCustomer(e.target.value);
                              handleCustomerSelection(e.target.value);
                            }}
                          >
                            <option value="">Select Customer</option>
                            {allCustomers?.map((customer) => {
                              return (
                                <option
                                  value={customer?._id}
                                  onClick={() => {
                                    setcustomerData(customer);
                                  }}
                                >
                                  {customer?.name}
                                </option>
                              );
                            })}
                          </select> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row w-100 align-items-center">
                    <div className="col-sm-4">
                      <label>Invoice date {reuiredField}</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="date"
                        value={invoiceDate}
                        onChange={(e) => {
                          setinvoiceDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row w-100 align-items-center">
                    <div className="col-sm-4">
                      <label>Invoice number {reuiredField}</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="text"
                        value={invoiceNumber}
                        onChange={(e) => {
                          setinvoiceNumber(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row w-100 align-items-center">
                    <div className="col-sm-4">
                      <label>Due date {reuiredField}</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        className="form-control"
                        type="date"
                        min={invoiceDate}
                        value={dueDate}
                        onChange={(e) => {
                          setdueDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <ItemsTableInvoice
                invoiceItems={invoiceItems}
                locationData={locationData}
                reuiredField={reuiredField}
              />

              <div className="d-flex justify-content-end me-4">
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_invoice_item"
                >
                  + Add Item
                </button>
              </div>
              <div className="row mb-3 mt-3">
                <div className="col-md-6">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between border text-dark px-3 py-2">
                      <p>Shipping Amount</p>
                      <p>0</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between border text-dark fw-semibold px-3 py-2">
                      <p>Total Amount</p>
                      <p>{calculateTotalSum()}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <textarea
                    className="form-control"
                    placeholder="Add a message"
                    rows="3"
                    value={description}
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <textarea
                    className="form-control"
                    placeholder="Add terms and conditions"
                    rows="3"
                    value={termsAndConditions}
                    onChange={(e) => {
                      setTermsAndConditions(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex modal-footer align-items-center justify-content-end ">
                <button
                  className="btn btn-primary"
                  onClick={handleAddInovices}
                  disabled={
                    !invoiceDate ||
                    !dueDate ||
                    !selectedCustomer ||
                    !invoiceNumber ||
                    !items.length
                  }
                >
                  Create Invoice
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3 p-2">
            <AddressDetails
              shippingAddress={shippingAddress}
              setshippingAddress={setshippingAddress}
              billingAddress={billingAddress}
              setbillingAddress={setbillingAddress}
              customerData={customerData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateInvoiceBody;
