import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../api/ApiCall";
import {
  CrmContactRelatedEndPoints,
  DealRelatedEndPoints,
  LeadRelatedEndPoints,
  StatusRelatedEndPoints,
} from "../../../../../api/Endpoints";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ApiLoader from "../../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../../components/Error/ErrorMessage";
import { useNavigate } from "react-router-dom";
import {
  navigateToContactOverview,
  navigateToDealOverview,
} from "../../../../../Navigation/Navigation";

const ConvertDeal = ({ leadDetails, id, setConvertDeal }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  var date = reversesDateFormatter(new Date());
  const [isChecked, setIsChecked] = useState(false);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState(leadDetails?.name ? leadDetails?.name : leadDetails?.title || "");
  const [dealName, setDealName] = useState("");
  const [amount, setAmount] = useState(0);
  const [closingDate, setClosingDate] = useState("");
  const [note, setNote] = useState("");
  const [allGroups, setAllGroups] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [selectStatus, setSelectStatus] = useState("");
  const [group, setGroup] = useState("");
  const [filter, setFilter] = useState("");
  const [groupId, setGroupId] = useState("");
  const filteredGroup = allGroups?.filter((grp) =>
    grp?.name?.toLowerCase()?.includes(filter.toLowerCase())
  );

  const handleReset = () => {
    setDealName("");
    setAmount(0);
    setErrorMessage("");
    setClosingDate("");
    setloader(false);
    setGroupId("");
    setGroup("");
    setSelectStatus("");
    setIsChecked(false);
    setNote("");
    setConvertDeal(false);
  };

  // CONVERT TO CONTACT API FUNCTION CALL
  const handleConvertToContact = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      leadId: id,
      convertedBy: user?._id,
      contactName: name,
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.convertToContact,
      data
    );
    if (res?.success) {
      toast.success("Convert to Contact successfully!");
      navigate(navigateToContactOverview({ id: res?.result?._id }));
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // CREATE DEAL API FUNCTION CALL
  const handleConvertToDeal = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      leadId: id,
      convertedBy: user?._id,
      groupId: groupId,
      subStatusId: selectStatus,
      contactName: name,
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.convertToDeal, data);
    if (res?.success) {
      if (isChecked) {
        toast.success("Deal created successfully!");
      }
      navigate(navigateToDealOverview({ id: res?.result?._id }));
      handleAddPayMentToDeal(e, res?.result?._id);
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleAddPayMentToDeal = async (e, dealId) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      dealId,
      amount: amount,
      title: dealName,
      closingDate: closingDate,
      actionBy: user?._id,
      note,
    };
    const res = await ApiCall("post", DealRelatedEndPoints.editDeal, data);
    if (res?.success) {
      // toast.success("Convert to Deal successfully!");
      handleReset();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  // GET ALL GROUPS  FUNCTION API CALL
  const handleGetGroups = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 300,
      filters: {
        businessId: getCurrentWorkspaceId(),
        moduleType: "Deal",
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getGropus, data);
    if (res?.success) {
      setAllGroups(res?.result?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  // GET SUB STATUS FROM INITIAL GROUP
  const handleGetAllStatus = async () => {
    const data = {
      page: 1,
      limit: 50,
      filters: {
        statusGroupId: groupId,
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getStatus, data);
    if (res?.success) {
      setSubStatus(res?.result?.data);
    }
  };

  useEffect(() => {
    if (groupId !== "") {
      handleGetAllStatus();
    }
  }, [groupId]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              Convert Lead
              <span className="text-primary fs-6 ms-1 text-capitalize">
                ({leadDetails?.title})
              </span>
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (isChecked) {
                  handleConvertToDeal(e);
                } else {
                  handleConvertToContact(e);
                }
              }}
            >
              <div className="mh-450p overflow-scroll">
                <div className="row gx-3 px-3 w-100">
                  <div className="d-flex flex-column gap-2 mb-3">
                    <span className="text-dark">
                      Convert Into Contact
                      {/* <span className="btn btn-sm btn-outline-light ms-2 bg-secondary-light-3 text-dark">
                      {leadDetails?.title}
                    </span> */}
                    </span>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">
                          Name {reuiredField}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          required
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <span className="text-dark">
                      <input
                        type="checkbox"
                        className="form-check-input pointer me-2"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                      />
                      Create a new Deal
                    </span>
                  </div>
                  {isChecked && (
                    <>
                      <div className="col-sm-6">
                        <label className="form-label">
                          Select group {reuiredField}
                        </label>
                        <div className="form-group">
                          <div className="selectable-dropdown">
                            <div className="dropdown selectable-dropdown">
                              <span
                                className="me-2 fs-7 fw-medium  text-dark text-capitalize border rounded px-4 py-2 w-100"
                                aria-expanded="false"
                                type="button"
                                data-bs-toggle="dropdown"
                                onClick={() => {
                                  handleGetGroups();
                                  setFilter("");
                                }}
                              >
                                {group ? (
                                  <>
                                    {group}
                                    {group !== "" && (
                                      <button
                                        type="button"
                                        className="btn-close btn-sm ms-2"
                                        onClick={() => {
                                          setGroup("");
                                          setFilter("");
                                          setSelectStatus("");
                                        }}
                                        aria-label="Close"
                                      ></button>
                                    )}
                                  </>
                                ) : (
                                  "Select group"
                                )}
                              </span>
                              <div role="menu" className="dropdown-menu">
                                <form
                                  className="d-sm-block d-none"
                                  role="search"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search.."
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                  />
                                </form>
                                {loader2 ? (
                                  <span className="d-flex text-center mt-1">
                                    Loading...
                                  </span>
                                ) : (
                                  <div
                                    style={{
                                      maxHeight: "25vh",
                                      overflow: "auto",
                                    }}
                                  >
                                    {filteredGroup?.map((grp, index) => (
                                      <div
                                        className="dropdown-item text-capitalize"
                                        key={grp.name}
                                        onClick={() => {
                                          setGroup(grp.name);
                                          setGroupId(grp?._id);
                                        }}
                                      >
                                        {index + 1}. {grp.name}
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {!loader2 && filteredGroup?.length === 0 && (
                                  <span className="d-flex text-center mt-1">
                                    No group found
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            Select Status {reuiredField}
                          </label>
                          <div className="form-group">
                            <div className="todo-toolbar">
                              <select
                                className="form-select form-select-md text-capitalize"
                                disabled={group === ""}
                                name="leadStatus"
                                required
                                value={selectStatus}
                                onChange={(e) =>
                                  setSelectStatus(e.target.value)
                                }
                              >
                                <option value="">Select Status</option>
                                {subStatus?.map((data) => {
                                  return (
                                    <option value={data?._id}>
                                      {data?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            Deal Name {reuiredField}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            required
                            value={dealName}
                            onChange={(e) => setDealName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="form-label">
                            Closing Date {reuiredField}
                          </label>
                          <input
                            className="form-control"
                            type="date"
                            required
                            value={closingDate}
                            onChange={(e) => setClosingDate(e.target.value)}
                            min={date}
                          />
                        </div>
                      </div>
                      <div className="col-sm-7">
                        <div className="form-group">
                          <label className="form-label">
                            Amount {reuiredField}
                          </label>
                          <div className="col-sm-8 d-flex align-items-center border rounded-5 w-280p text-dark ">
                            <span className="d-flex align-items-center justify-content-center py-2 px-4">
                              INR ₹
                            </span>

                            <div className="d-flex align-items-center justify-content-between w-180p py-1 border-start px-3">
                              <input
                                className="form-control border-0"
                                type="number"
                                required
                                placeholder="Price"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="form-group">
                          <div className="form-label-group">
                            <label className="form-label">
                              Note/Description
                            </label>
                          </div>
                          <textarea
                            className="form-control"
                            rows="3"
                            onChange={(e) => {
                              setNote(e.target.value);
                            }}
                            value={note}
                          ></textarea>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isChecked && (!groupId || !selectStatus)}
                >
                  {loader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConvertDeal;
