import React from "react";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Navbar from "../../components/Navbar/Navbar";
import Profile from "../../pages/Profile/Profile";

const HomeRoutes = {
  path: "/",
  element: <Navbar/>,
  children: [
    {
      path: "",
      element: <Dashboard />,
    },
    {
      path: "home",
      element: <Dashboard />,
    },
    {
      path: "profile",
      element: <Profile />,
    },

  ],
};
export default HomeRoutes;
