import React, { useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";

function SubPaymentModal({ setsubPaymentModal, setpaymentData, paymentData }) {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rcvAmount, setRcvAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("bank-transfer");
  const [transactionNo, setTransactionNo] = useState("");

  const allPaymentMode = [
    {
      name: "Bank Transfer",
      value: "bank-transfer",
    },
    {
      name: "Cash",
      value: "cash",
    },
    {
      name: "UPI",
      value: "upi",
    },
  ];

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setRcvAmount("");
    setPaymentMode("bank-transfer");
    setTransactionNo("");
    setsubPaymentModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const paymentDataObj = {
      paymentAmount: rcvAmount,
      paymentType: paymentMode,
      referenceNumber: transactionNo,
    };

    setpaymentData([...paymentData, paymentDataObj]);

    handleReset();
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Payment</h5>
            <div className="border-bottom mb-3"></div>
            <form onSubmit={handleSubmit}>
              <div className="row gx-3">
                <div className="col-sm-6 mt-3">
                  <label className="text-dark fw-300 fs-6">
                    Amount Received
                  </label>
                  <input
                    className="form-control mt-2"
                    type="number"
                    required
                    value={rcvAmount}
                    onChange={(e) => {
                      setRcvAmount(e.target.value);
                    }}
                  />
                </div>
                <div className="col-sm-6 mt-3">
                  <label className="text-dark fw-250 fs-6">Payment mode</label>
                  <div className="form-group mt-2">
                    <select
                      className="form-select form-select-md p-2"
                      value={paymentMode}
                      onChange={(e) => {
                        setPaymentMode(e.target.value);
                      }}
                    >
                      {allPaymentMode?.map((data) => {
                        return (
                          <option value={data?.value}>{data?.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {paymentMode !== "cash" && (
                <div className="row gx-3">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="text-dark fw-300 fs-6">
                        Reference number
                      </label>
                      <input
                        className="form-control mt-2"
                        type="text"
                        value={transactionNo}
                        onChange={(e) => {
                          setTransactionNo(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader}
                >
                  {loader ? <ApiLoader /> : "Pay"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubPaymentModal;
