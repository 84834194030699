import React, { useEffect, useState } from "react";
import ExpensesHeader from "./ExpensesHeader";
import AddExpense from "./AddExpense";
import AllExpensesTable from "./AllExpensesTable";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { ExpensRelatedEndPoints } from "../../../api/Endpoints";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";

const AllExpenses = () => {
  const [loader, setloader] = useState(false);
  const [search, setsearch] = useState("");
  const [allExpense, setAllExpense] = useState([]);
  const [editExpense, setEditExpense] = useState("");

  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllExpense = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        isDeleted: false,
      },
    };
    const res = await ApiCall("post", ExpensRelatedEndPoints.getExpense, data);
    if (res?.success) {
      setAllExpense(res?.expenses?.data);
      setcount(res?.expenses?.totalCounts);
      settotalPages(res?.expenses?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllExpense();
    }
  }, [search, page, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ExpensesHeader
            search={search}
            setsearch={setsearch}
            setPage={setPage}
            handleGetAllExpense={handleGetAllExpense}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allExpense?.length > 0 && (
                <AllExpensesTable
                  allExpense={allExpense}
                  page={page}
                  pageLimit={pageLimit}
                  editExpense={editExpense}
                  setEditExpense={setEditExpense}
                  handleGetAllExpense={handleGetAllExpense}
                />
              )}
              {!loader && allExpense?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allExpense?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allExpense?.length === 0 && (
                <NoData title="No Expense Added" />
              )}
            </div>
          </div>
        </div>
        <AddExpense
          editExpense={editExpense}
          setEditExpense={setEditExpense}
          handleGetAllExpense={handleGetAllExpense}
        />
      </div>
    </div>
  );
};

export default AllExpenses;
