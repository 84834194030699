import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import RoleList from "../../pages/WorkspaceRole/RoleList/RoleList";

const RoleListRoutes = {
  path: "/role",
  element: <Navbar />,
  children: [
    {
        path: 'rolelist',
        element: <RoleList />,
      },
  ],
};

export default RoleListRoutes;