import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  dateAndTimeFormatter,
  dateFormatter,
  getName,
} from "../../../../../global-functions/globalFunctions";
import { Avatar } from "../../../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { navigateToDealOverview } from "../../../../../Navigation/Navigation";
import NoData from "../../../../../components/NoData/NoData";

const DealsCarousel = ({ recentDeals }) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0);
  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + recentDeals.length) % recentDeals?.length
    );
  };
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % recentDeals?.length);
  };

  return (
    <div className="custom-carousel">
      {recentDeals?.length > 0 && (
        <>
          <div
            className="custom-carousel-inner"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {recentDeals?.map((lead, index) => (
              <div
                key={index}
                className={`custom-carousel-item ${
                  index === currentIndex ? "active" : ""
                }`}
              >
                <div className="card">
                  <div className="card-body d-flex flex-column gap-3 ms-5 h-350p overflow-y-scroll simple-scrollbar">
                    <div className="d-flex align-items-center gap-2">
                      <span className="fs-5 text-dark d-flex ">
                        Last &nbsp;
                        {`${index + 1} / ${recentDeals?.length}`}
                        &nbsp; Recent Open Deals
                      </span>
                    </div>
                    <div className="row gx-3">
                      <div className="col-4 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-person"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                        </svg>
                        &nbsp;Name :
                      </div>
                      <div className="col-8 d-flex align-items-center gap-5">
                        <span className="text-dark text-capitalize text-truncate w-200p">
                          {lead?.title ? lead?.title : "NA"}
                        </span>

                        <span
                          className="btn btn-sm btn-icon btn-rounded btn-flush-light border"
                          onClick={() => {
                            navigate(
                              navigateToDealOverview({
                                id: lead?._id,
                              })
                            );
                          }}
                        >
                          <span className="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-right-short"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-4 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          class="bi bi-calendar"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                        </svg>
                        &nbsp;Created Date :
                      </div>
                      <div className="col-8 text-dark">
                        {dateAndTimeFormatter(lead?.createdAt)}
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-4 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          class="bi bi-calendar2-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.146 8.146a.5.5 0 0 1 .708 0L8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 0 1 0-.708" />
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                          <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                        &nbsp;Closing Date :
                      </div>
                      <div className="col-8 text-dark">
                        {dateFormatter(lead?.closingDate)}
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-4 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-person"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                        </svg>
                        &nbsp;Added by :
                      </div>
                      <div className="col-8 text-dark">
                        <Avatar
                          name={
                            lead?.createdBy
                              ? lead?.createdBy?.name
                              : lead?.convertedBy?.name
                          }
                          count={1}
                          image={
                            lead?.createdBy
                              ? lead?.createdBy?.image
                              : lead?.convertedBy?.image
                          }
                          size="xss"
                          color="primary"
                        />
                        <span className="ms-1">
                          {getName(
                            lead?.createdBy
                              ? lead?.createdBy
                              : lead?.convertedBy,
                            user?._id
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-4 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          class="bi bi-currency-rupee"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                        </svg>
                        &nbsp;Amount :
                      </div>
                      <div className="col-8 text-dark">
                        {lead?.amount ? lead?.amount : "NA"}
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-4 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-headset"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5" />
                        </svg>
                        &nbsp;Assignees :
                      </div>
                      <div className="col-8">
                        <div className="d-flex align-items-center gap-2 text-dark">
                          {lead?.assignedTo?.length > 0 ? (
                            <>
                              {lead?.assignedTo?.length === 1 ? (
                                <>
                                  <Avatar
                                    name={lead?.assignedTo?.[0]?.name}
                                    count={1}
                                    image=""
                                    size="xss"
                                    color="primary"
                                  />
                                  {getName(lead?.assignedTo?.[0], user?._id)}
                                </>
                              ) : (
                                <div className="avatar-group avatar-group-sm avatar-group-overlapped pointer font-2">
                                  {lead?.assignedTo?.slice(0, 3)?.map((emp) => {
                                    return (
                                      <Avatar
                                        name={emp?.name}
                                        count={1}
                                        image={emp?.image}
                                        size="xxs"
                                        color="primary"
                                      />
                                    );
                                  })}
                                  {lead?.assignedTo?.length > 3 && (
                                    <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                                      <span className="initial-wrap">
                                        +{lead?.assignedTo?.length - 3}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          ) : (
                            "No Assignee"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-4 d-flex align-items-center">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_7757_18200)">
                            <path
                              d="M19.5001 19.7778C19.361 19.6465 19.2772 19.467 19.2661 19.276C19.2549 19.085 19.3172 18.8969 19.4401 18.7503C20.2969 17.7568 20.9433 16.5998 21.3404 15.3494C21.7374 14.099 21.8768 12.7811 21.7501 11.4753C21.4536 9.03309 20.2447 6.79327 18.366 5.20496C16.4872 3.61666 14.0775 2.79729 11.62 2.91115C9.16248 3.02501 6.83885 4.06369 5.11503 5.81891C3.3912 7.57413 2.39463 9.91613 2.32514 12.3753C2.32657 14.7208 3.17939 16.9861 4.72514 18.7503C4.84351 18.8936 4.90445 19.0758 4.89613 19.2615C4.88782 19.4472 4.81084 19.6231 4.68014 19.7553C4.60625 19.8294 4.51766 19.8873 4.42007 19.9251C4.32249 19.963 4.21806 19.9799 4.11351 19.975C4.00896 19.9701 3.9066 19.9433 3.81302 19.8964C3.71944 19.8495 3.6367 19.7836 3.57014 19.7028C1.73011 17.5866 0.752963 14.8559 0.832641 12.0528C0.875407 9.06911 2.10169 6.22462 4.24171 4.14508C6.38173 2.06553 9.26021 0.921282 12.2439 0.964048C15.2276 1.00681 18.0721 2.23309 20.1516 4.37312C22.2312 6.51314 23.3754 9.39161 23.3326 12.3753C23.3388 15.0757 22.3663 17.6869 20.5951 19.7253C20.5286 19.8037 20.4467 19.8676 20.3545 19.913C20.2623 19.9585 20.1617 19.9844 20.059 19.9894C19.9564 19.9943 19.8538 19.978 19.7576 19.9417C19.6615 19.9053 19.5738 19.8495 19.5001 19.7778Z"
                              fill="#979797"
                            />
                            <path
                              d="M12.0742 15C11.4809 15 10.9009 14.8241 10.4075 14.4944C9.91416 14.1648 9.52964 13.6962 9.30258 13.1481C9.07552 12.5999 9.01611 11.9967 9.13186 11.4147C9.24762 10.8328 9.53334 10.2982 9.9529 9.87868C10.3725 9.45912 10.907 9.1734 11.4889 9.05765C12.0709 8.94189 12.6741 9.0013 13.2223 9.22836C13.7704 9.45543 14.239 9.83994 14.5686 10.3333C14.8983 10.8266 15.0742 11.4067 15.0742 12C15.0742 12.7957 14.7581 13.5587 14.1955 14.1213C13.6329 14.6839 12.8699 15 12.0742 15ZM12.0742 10.5C11.7775 10.5 11.4875 10.588 11.2409 10.7528C10.9942 10.9176 10.8019 11.1519 10.6884 11.426C10.5749 11.7001 10.5452 12.0017 10.603 12.2926C10.6609 12.5836 10.8038 12.8509 11.0136 13.0607C11.2233 13.2704 11.4906 13.4133 11.7816 13.4712C12.0726 13.5291 12.3742 13.4994 12.6482 13.3858C12.9223 13.2723 13.1566 13.08 13.3214 12.8334C13.4862 12.5867 13.5742 12.2967 13.5742 12C13.5742 11.6022 13.4162 11.2206 13.1349 10.9393C12.8536 10.658 12.472 10.5 12.0742 10.5Z"
                              fill="#979797"
                            />
                            <path
                              d="M12.0742 22.875C11.8753 22.875 11.6845 22.796 11.5439 22.6553C11.4032 22.5147 11.3242 22.3239 11.3242 22.125V14.625H12.8242V22.125C12.8242 22.3239 12.7452 22.5147 12.6045 22.6553C12.4639 22.796 12.2731 22.875 12.0742 22.875Z"
                              fill="#979797"
                            />
                            <path
                              d="M15 21.75H9C8.58579 21.75 8.25 22.0858 8.25 22.5C8.25 22.9142 8.58579 23.25 9 23.25H15C15.4142 23.25 15.75 22.9142 15.75 22.5C15.75 22.0858 15.4142 21.75 15 21.75Z"
                              fill="#979797"
                            />
                            <path
                              d="M6.39954 16.988C5.45132 15.9218 4.82693 14.6072 4.59966 13.1985C4.37239 11.7899 4.55166 10.3456 5.11644 9.03528C5.68122 7.72494 6.60807 6.6029 7.78819 5.80087C8.96831 4.99883 10.3527 4.55008 11.779 4.50728C13.2052 4.46449 14.614 4.82942 15.8401 5.55926C17.0662 6.28909 18.0587 7.35354 18.701 8.62765C19.3433 9.90176 19.6089 11.3327 19.4665 12.7524C19.3241 14.1722 18.7796 15.5218 17.897 16.643C17.8316 16.7272 17.7489 16.7965 17.6545 16.8462C17.5602 16.8959 17.4563 16.925 17.3498 16.9313C17.2433 16.9377 17.1367 16.9213 17.0371 16.8833C16.9375 16.8452 16.8471 16.7863 16.772 16.7105C16.6511 16.5829 16.5788 16.4168 16.5679 16.2414C16.5569 16.0659 16.6079 15.8922 16.712 15.7505C17.4191 14.8553 17.8564 13.7771 17.9726 12.6423C18.0887 11.5075 17.879 10.3631 17.3679 9.3432C16.8568 8.32334 16.0656 7.47031 15.087 6.88411C14.1084 6.29791 12.9829 6.00284 11.8426 6.03348C10.7022 6.06413 9.59427 6.41922 8.64855 7.05713C7.70283 7.69504 6.95856 8.58934 6.503 9.63517C6.04743 10.681 5.89944 11.835 6.07639 12.962C6.25333 14.0889 6.74787 15.1421 7.50204 15.998C7.62228 16.1348 7.68859 16.3108 7.68859 16.493C7.68859 16.6752 7.62228 16.8511 7.50204 16.988C7.43181 17.0641 7.34657 17.1249 7.25169 17.1664C7.15682 17.208 7.05437 17.2294 6.95079 17.2294C6.84721 17.2294 6.74476 17.208 6.64988 17.1664C6.55501 17.1249 6.46977 17.0641 6.39954 16.988Z"
                              fill="#979797"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7757_18200">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        &nbsp;Source :
                      </div>
                      <div className="col-8 text-dark">
                        {lead?.source ? lead?.source : "NA"}
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-4 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-border-width"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 3.5A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-2zm0 5A.5.5 0 0 1 .5 8h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1zm0 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z" />
                        </svg>
                        &nbsp;Status :
                      </div>
                      <div className="col-8 text-dark text-capitalize">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={lead?.initialStatusId?.color}
                          className="bi bi-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                        &nbsp;
                        {lead?.subStatusId?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {currentIndex > 0 && (
            <span className="custom-carousel-control-prev" onClick={prevSlide}>
              <span
                className="custom-carousel-control-prev-icon"
                aria-hidden="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                  />
                </svg>
              </span>
              <span className="sr-only"></span>
            </span>
          )}
          {currentIndex !== recentDeals?.length - 1 && (
            <span className="custom-carousel-control-next" onClick={nextSlide}>
              <span
                className="custom-carousel-control-next-icon"
                aria-hidden="true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                  />
                </svg>
              </span>
              <span className="sr-only"></span>
            </span>
          )}
        </>
      )}
      {recentDeals?.length === 0 && <NoData title="No Open deals recently" />}
    </div>
  );
};

export default DealsCarousel;
