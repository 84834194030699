import React, { useEffect, useState } from "react";
import OrderHeader from "./OrderHeader";
import MenuCardsSection from "./MenuCardsSection";
import CartSection from "./CartSection";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  InventoryCustomerRelatedEndPoints,
  InventoryRelatedEndPoints,
  OrderRelatedEndPoints,
  ProductCategoryRelatedEndPoints,
} from "../../../api/Endpoints";
import OrderConfirmModal from "./OrderConfirmModal/OrderConfirmModal";
import { toast } from "react-toastify";
import AddCustomer from "../Customer/AddCustomer";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToOrderHistory } from "../../../Navigation/Navigation";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import CustomizeOrderModal from "./CustomizeOrderModal";
import SelectTableModal from "./SelectTableModal";

const Order = () => {
  const { user } = useSelector((state) => state);
  const { allModuleAccess } = useSelector((state) => state);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [customerDataLoader, setCustomerDataLoader] = useState(false);
  const [orderDetailsLoader, setOrderDetailsLoader] = useState(false);
  const [selectedCustomer, setselectedCustomer] = useState("");
  const [selectedMenuItems, setselectedMenuItems] = useState([]);
  const [subTotal, setsubTotal] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [payloadItems, setPayloadItems] = useState([]);
  const [payableAmount, setpayableAmount] = useState("");
  const [uniqueItems, setUniqueItems] = useState([]);
  const [customerData, setcustomerData] = useState([]);
  const [categoryItemsData, setcategoryItemsData] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [itemCategory, setItemCategory] = useState([]);
  const [addCustomerModal, setaddCustomerModal] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [customerType, setcustomerType] = useState("");
  const [costPrice, setcostPrice] = useState("");
  const [orderDetails, setOrderDetails] = useState([]);
  const [allChargesData, setAllChargesData] = useState([]);
  const [addedItem, setaddedItem] = useState([]);
  // SELECT TABLE
  const [tableId, setTableId] = useState("");
  const [tableData, setTableData] = useState("");
  const [type, setType] = useState("");
  const [noOfPeople, setNoOfPeople] = useState("");

  //Customize Modal
  const [customizeModal, setcustomizeModal] = useState(false);
  const [customizesItemId, setcustomizesItemId] = useState("");
  const [customizedItem, setcustomizedItem] = useState([]);

  const handleResetTableData = () => {
    setNoOfPeople("");
    setType("");
    setTableId("");
    setTableData("");
    const closeModalDom = document.getElementById("add_table_data");
    if (closeModalDom) closeModalDom.click();
  };

  const getCustomizationItem = (productId) => {
    const tempItem = customizedItem?.filter(
      (item) => item?.productId === productId
    );
    return tempItem;
  };

  const removeCustomizationItem = (inventory) => {
    const tempItem = customizedItem?.filter((item) => item !== inventory);
    setcustomizedItem(tempItem);
  };

  const isModuleReadWriteAccessForOrder =
    allModuleAccess?.[PRIVATEROUTES.ORDER];

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  const updateValueForItem = (targetItem, newValue) => {
    let updatedCharges = allChargesData?.map((item) =>
      item._id === targetItem ? { ...item, value: newValue } : item
    );
    setAllChargesData(updatedCharges);
  };

  const handleProductClick = (selectedProduct) => {
    const updatedMenuItems = [...selectedMenuItems, selectedProduct];
    setselectedMenuItems(updatedMenuItems);
  };

  //   Function for get customer
  const handleGetAllCustomer = async () => {
    startApiCall(null, setCustomerDataLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
        isDeleted: false,
      },
    };
    const res = await ApiCall(
      "post",
      InventoryCustomerRelatedEndPoints.getCustomer,
      data
    );
    if (res?.success) {
      setAllCustomers(res?.buyer?.data);
      setCustomerDataLoader(false);
    } else {
      setCustomerDataLoader(false);
    }
  };

  //  Function for food category
  const handleGetItemCategory = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
      },
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.getProductCategory,
      data
    );
    if (res?.success) {
      setItemCategory(res?.categories?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetItemCategory();
      closeAllModals();
    }
  }, []);

  //   Function for dish inside category
  const handleGetCategoryItems = async () => {
    startApiCall(null, setloader2);
    const data = {
      filters: {
        productCategory: selectedCategory,
        isDeleted: "false",
        searchKey: search,
        businessId: getCurrentWorkspaceId(),
      },
      page: 1,
      limit: 5000,
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.getCategoryItems,
      data
    );
    if (res?.success) {
      setcategoryItemsData(res?.item?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetCategoryItems();
  }, [selectedCategory, search]);

  //   Function for Add Remove Order Item
  const handleAddItem = (item) => {
    setselectedMenuItems((prevItems) => [...prevItems, { ...item }]);
  };

  const handleRemoveItem = (item) => {
    setselectedMenuItems((prevItems) => {
      const index = prevItems.findIndex(
        (prevItem) => prevItem?._id === item?._id
      );

      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }

      return prevItems;
    });
  };

  const handleDeleteItem = (item) => {
    setselectedMenuItems((prevItems) =>
      prevItems.filter((prevItem) => prevItem?._id !== item?._id)
    );
  };

  const handleReset = () => {
    const closeModalDom = document.getElementById("check_order_modal");
    if (closeModalDom) {
      closeModalDom.click();
    }
    seterrorMessage("");
    setloader3(false);
  };

  const charges = allChargesData?.map((data) => {
    return {
      chargeId: data?._id,
      amount: data?.value,
    };
  });

  const calculateCustomizeCostPrice = () => {
    const total = customizedItem?.reduce((cost, item) => {
      const value = item?.costPrice;
      return cost + parseInt(value);
    }, 0);
    return parseInt(total);
  };

  // CREATE ORDER FUNCTION API CALL
  const handleCreateOrder = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader3);
    const data = {
      businessId: getCurrentWorkspaceId(),
      products: payloadItems,
      amount: payableAmount,
      buyer: customerData?._id,
      status: "pending",
      costPrice,
      charges: charges,
      subTotal,
      createdBy: user?._id,
      isCustomization: customizedItem ? true : false,
      customization: customizedItem,
      tableId,
      numberOfPeople: noOfPeople,
      typeOfCustomer: type,
      isApproved: "approved",
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.createOrder, data);
    if (res?.success) {
      toast.success("Order has been Created Successfully");
      navigate(navigateToOrderHistory());
      handleReset();
      handleResetTableData();
    } else {
      setloader3(false);
      seterrorMessage(res?.error);
    }
  };

  // EDIT ORDER FUNCTION API CALL
  const handleEditOrder = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader3);
    const newItems = payloadItems?.filter((item) => {
      if (!addedItem.some((added) => added?.productId === item?.productId)) {
        return true;
      } else {
        const correspondingItem = addedItem.find(
          (added) => added?.productId === item?.productId
        );
        if (correspondingItem) {
          if (correspondingItem.quantity < item.quantity) {
            return true;
          }
          return false;
        }
      }
    });
    const newItemsWithQuantity = newItems.map((item) => {
      const correspondingItem = addedItem.find(
        (added) => added?.productId === item?.productId
      );
      if (correspondingItem) {
        return {
          ...item,
          quantity: item.quantity - correspondingItem.quantity,
        };
      }
      return item;
    });

    const newItemsPayload = newItemsWithQuantity.map((item) => ({
      quantity: item?.quantity,
      productId: item?.productId,
    }));

    const data = {
      orderId: locationData?.orderId,
      buyer: customerData?._id,
      costPrice,
      charges: charges,
      subTotal,
      products: payloadItems,
      amount: payableAmount,
      actionBy: user?._id,
      tableId,
      numberOfPeople: noOfPeople,
      typeOfCustomer: type,
      lastAddedItem: newItemsPayload,
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.editOrder, data);
    if (res?.success) {
      toast.success("Edit Order Successfully");
      navigate(navigateToOrderHistory());
      handleReset();
      handleResetTableData();
    } else {
      setloader3(false);
      seterrorMessage(res?.error);
    }
  };

  const handleGetAllCharges = async () => {
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        isVisible: true,
      },
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getAllCharges,
      data
    );
    if (res?.success) {
      setAllChargesData(res?.charges);
    }
  };

  useEffect(() => {
    if (!locationData) {
      handleGetAllCharges();
    }
  }, [locationData]);

  const handleGetOrderDetails = async () => {
    startApiCall(null, setOrderDetailsLoader);
    const data = {
      page: 1,
      limit: 5,
      filters: {
        businessId: getCurrentWorkspaceId(),
        _id: locationData?.orderId,
        isApproved: "approved",
      },
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.getOrders, data);
    if (res?.success) {
      setOrderDetails(res?.orders?.data?.[0]);
      setOrderDetailsLoader(false);
    } else {
      setOrderDetailsLoader(false);
    }
  };

  useEffect(() => {
    if (locationData?.orderId) {
      handleGetOrderDetails();
    }
  }, [locationData]);

  useEffect(() => {
    if (locationData?.orderId && orderDetails?.products) {
      setcustomizedItem(orderDetails?.customization);
      setselectedCustomer(orderDetails?.buyer);
      setcustomerData(orderDetails?.buyer);
      setcustomerType(orderDetails?.buyer ? "customer" : "guest");

      const updatedUniqueItems = orderDetails?.products?.reduce((acc, item) => {
        const repeatedItems = Array.from({ length: item?.quantity }, () => ({
          ...item?.productId,
          quantity: 1,
          _id: item?.productId?._id,
        }));
        acc = acc.concat(repeatedItems);

        return acc;
      }, []);
      const payloadItemDetails = updatedUniqueItems?.map((product) => ({
        quantity: product?.quantity,
        productId: product?._id,
      }));

      const updatedChargeItems = orderDetails?.charges?.reduce((acc, item) => {
        acc.push({
          ...item?.chargeId,
          value: item?.amount,
          chargeId: item?._id,
        });

        return acc;
      }, []);

      const uniqueAddedItems = orderDetails?.products?.map((product) => ({
        quantity: product?.quantity,
        productId: product?.productId?._id,
      }));

      setAllChargesData(updatedChargeItems);
      setselectedMenuItems(updatedUniqueItems);
      setaddedItem(uniqueAddedItems);
      setPayloadItems(payloadItemDetails);
      setpayableAmount(orderDetails?.amount);
      setNoOfPeople(orderDetails?.numberOfPeople);
      setTableId(orderDetails?.tableId?._id);
      setTableData(orderDetails?.tableId?.tableName);
      setType(orderDetails?.typeOfCustomer);
    }
  }, [locationData, orderDetails]);
  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <OrderHeader
            handleGetItemCategory={handleGetItemCategory}
            handleGetCategoryItems={handleGetCategoryItems}
            setSelectedCategory={setSelectedCategory}
            itemCategory={itemCategory}
            locationData={locationData}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <div className="row gx-3" style={{ height: "75vh" }}>
                  <div className="col-lg-8 col-md-6 border-end px-3">
                    <MenuCardsSection
                      itemCategory={itemCategory}
                      categoryItemsData={categoryItemsData}
                      loader={loader}
                      loader2={loader2}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      handleProductClick={handleProductClick}
                      search={search}
                      setSearch={setSearch}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6 border-start px-3">
                    <CartSection
                      selectedCustomer={selectedCustomer}
                      setselectedCustomer={setselectedCustomer}
                      selectedMenuItems={selectedMenuItems}
                      handleAddItem={handleAddItem}
                      handleRemoveItem={handleRemoveItem}
                      handleDeleteItem={handleDeleteItem}
                      setPayloadItems={setPayloadItems}
                      setsubTotal={setsubTotal}
                      subTotal={subTotal}
                      setpayableAmount={setpayableAmount}
                      uniqueItems={uniqueItems}
                      setUniqueItems={setUniqueItems}
                      setcustomerData={setcustomerData}
                      customerData={customerData}
                      allCustomers={allCustomers}
                      setaddCustomerModal={setaddCustomerModal}
                      setcustomerType={setcustomerType}
                      customerType={customerType}
                      setcostPrice={setcostPrice}
                      locationData={locationData}
                      handleGetAllCustomer={handleGetAllCustomer}
                      customerDataLoader={customerDataLoader}
                      orderDetailsLoader={orderDetailsLoader}
                      setcustomizeModal={setcustomizeModal}
                      setcustomizesItemId={setcustomizesItemId}
                      getCustomizationItem={getCustomizationItem}
                      removeCustomizationItem={removeCustomizationItem}
                      isModuleReadWriteAccessForOrder={
                        isModuleReadWriteAccessForOrder
                      }
                      customizedItem={customizedItem}
                      calculateCustomizeCostPrice={calculateCustomizeCostPrice}
                      orderDetails={orderDetails}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderConfirmModal
        uniqueItems={uniqueItems}
        subTotal={subTotal}
        payableAmount={payableAmount}
        customerData={customerData}
        handleCreateOrder={handleCreateOrder}
        loader3={loader3}
        errorMessage={errorMessage}
        handleReset={handleReset}
        customerType={customerType}
        allChargesData={allChargesData}
        setpayableAmount={setpayableAmount}
        updateValueForItem={updateValueForItem}
        locationData={locationData}
        handleEditOrder={handleEditOrder}
      />
      <SelectTableModal
        tableId={tableId}
        setTableId={setTableId}
        type={type}
        setType={setType}
        noOfPeople={noOfPeople}
        setNoOfPeople={setNoOfPeople}
        tableData={tableData}
        setTableData={setTableData}
        handleCreateOrder={handleCreateOrder}
        loader3={loader3}
        locationData={locationData}
        handleEditOrder={handleEditOrder}
        handleResetTableData={handleResetTableData}
      />
      {addCustomerModal && (
        <AddCustomer
          setaddCustomerModal={setaddCustomerModal}
          handleGetAllCustomer={handleGetAllCustomer}
          isEdit={false}
          customerData={customerData}
          setisEdit={setisEdit}
        />
      )}
      {customizeModal && (
        <CustomizeOrderModal
          setcustomizeModal={setcustomizeModal}
          customizesItemId={customizesItemId}
          setcustomizesItemId={setcustomizesItemId}
          setcustomizedItem={setcustomizedItem}
          customizedItem={customizedItem}
        />
      )}
    </div>
  );
};

export default Order;
