import React, { useEffect, useState } from "react";
import LeadHeader from "./LeadHeader";
import LeadsTable from "./LeadsTable";
import CreateLead from "./CreateLead";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  getWorkSpaceRole,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { LeadRelatedEndPoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import NoData from "../../../components/NoData/NoData";
import AssignGroup from "./AssignGroup";
import ImportCsv from "./ImportCsv";
import AssigneeModal from "../Assignee/AssigneeModal";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import LastActivity from "./LastActivity";
import ExportCsv from "./ExportCsv";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddParticipants from "../Group/GroupStatus/AddParticipants";

const Leads = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmpId, setselectedEmpId] = useState([]);
  const [selectedEmpIdForApi, setSelectedEmpIdForApi] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [countLoader, setcountLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [leadId, setLeadId] = useState("");
  const [editLeadData, setEditLeadData] = useState("");
  const [statusFilter, setstatusFilter] = useState("");
  const [importCsv, setImportCsv] = useState(false);
  const [isBusinessWise, setIsBusinessWise] = useState(false);
  const [businessWise, setBusinessWise] = useState(false);
  const [csvLeads, setCsvLeads] = useState([]);
  const [csvLimit, setCsvLimit] = useState("");
  const [exportCsvModal, setexportCsvModal] = useState(false);
  const [countOverview, setcountOverview] = useState(null);
  const [errorMessage, seterrorMessage] = useState("");
  const currentWorkSpaceRole = getWorkSpaceRole(user?.permissions, "roleName");
  const [subStatus, setsubStatus] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [isBusinessWiseInitialized, setIsBusinessWiseInitialized] =
    useState(false);
  const [allLeadSource, setAllLeadSource] = useState([]);
  const [leadSource, setleadSource] = useState("");

  // ****** Bulk Assignee ******
  const [checkedLeads, setCheckedLeads] = useState({});
  const [showCheckBox, setshowCheckBox] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [addParticipant, setAddParticipant] = useState(false);
  const [selectedAssigneeId, setselectedAssigneeId] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForLeads =
    allModuleAccess?.[PRIVATEROUTES.LEADS];

  const handleClose = () => {
    setPage(1);
    setselectedAssigneeId([]);
    setCheckedLeads({});
    setshowCheckBox(false);
    setAddParticipant(false);
    seterrorMessage("");
  };

  const handleGetAllLeads = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      employeeId:
        currentWorkSpaceRole === "owner" && isBusinessWise && !location?.state
          ? ""
          : !isModuleReadWriteAccessForLeads
          ? user?._id
          : businessWise
          ? ""
          : selectedEmpIdForApi?.length === 0
          ? user?._id
          : selectedEmpIdForApi?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "",
        subStatusId: subStatus?._id,
        searchKey: search,
        initialStatusName: statusFilter !== "unassigned" ? statusFilter : "",
        unassigned: statusFilter === "unassigned" ? true : false,
        updateEndDate: endDate,
        updateStartDate: startDate,
        source: leadSource
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLead, data);
    if (res?.success) {
      setAllLeads(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetCsvLeads = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: csvLimit,
      limit: "1000",
      employeeId: !isModuleReadWriteAccessForLeads
        ? user?._id
        : businessWise
        ? ""
        : selectedEmpIdForApi?.length === 0
        ? user?._id
        : selectedEmpIdForApi?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.exportLead, data);
    if (res?.success) {
      setCsvLeads(res?.result);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const handleFilterAssignee = () => {
    setassigneeModal(false);
    setSelectedEmpIdForApi(selectedEmpId);
    setBusinessWise(isBusinessWise);
  };

  const resetSelectedEmployee = () => {
    setselectedEmpId([]);
    setSelectedEmpIdForApi([]);
    navigate({ ...location, state: null });
    setIsBusinessWiseInitialized(false);
  };

  //Add Participant to Lead
  const handleAddParticipantToLead = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    const leadIds = Object.values(checkedLeads).reduce(
      (acc, curr) => [...acc, ...curr],
      []
    );
    const data = {
      leadIds: leadIds,
      employeeIds: selectedAssigneeId,
      updatedBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.assignEmployeeLead,
      data
    );
    if (res?.success) {
      handleClose();
      handleGetAllLeads();
      toast.success("Participant assigned successfully!");
      setloader2(false);
    } else {
      seterrorMessage(res?.error);
      setloader2(false);
    }
  };

  //Lead Count
  const leadOverviewCount = async () => {
    startApiCall(null, setcountLoader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        assignedTo:
          currentWorkSpaceRole === "owner" && isBusinessWise
            ? ""
            : !isModuleReadWriteAccessForLeads
            ? user?._id
            : businessWise
            ? ""
            : selectedEmpIdForApi?.length === 0
            ? user?._id
            : selectedEmpIdForApi?._id,
        updateEndDate: endDate,
        updateStartDate: startDate,
        subStatusId: subStatus?._id,
        source: leadSource
      },
      type: "lead",
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.leadContactConversionCount,
      data
    );
    if (res?.success) {
      setcountOverview(res?.result);
      setcountLoader(false);
    } else {
      setcountLoader(false);
    }
  };

  useEffect(() => {
    if (selectedEmpIdForApi?.length === 0 && location?.state?._id) {
      setSelectedEmpIdForApi(location?.state);
      setselectedEmpId(location?.state);
      setstatusFilter(location?.state?.status);
      setsubStatus(location?.state?.subStatus);
      setstartDate(location?.state?.startDate);
      setendDate(location?.state?.endDate);
      setPage(location?.state?.page ? location?.state?.page : 1);
    }
  }, [location]);

  useEffect(() => {
    if (currentWorkSpaceRole === "owner" && !location?.state?._id) {
      setIsBusinessWise(true);
      setBusinessWise(true);
    }
    setIsBusinessWiseInitialized(true);
  }, [currentWorkSpaceRole, location]);

  useEffect(() => {
    if (getCurrentWorkspaceId() && isBusinessWiseInitialized) {
      if (location?.state?._id) {
        if (selectedEmpIdForApi?._id) {
          handleGetAllLeads();
          leadOverviewCount();
        }
      } else {
        handleGetAllLeads();
        leadOverviewCount();
      }
    }
    closeAllModals();
  }, [
    search,
    page,
    pageLimit,
    statusFilter,
    selectedEmpIdForApi,
    businessWise,
    subStatus,
    isBusinessWiseInitialized,
    leadSource
  ]);

  const handleGetLeadSource = async () => {
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLeadSource, data);
    if (res?.success) {
      setAllLeadSource(res?.result?.data);
    }
  };

  useEffect(() => {
    handleGetLeadSource();
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <LeadHeader
            search={search}
            setSearch={setSearch}
            setPage={setPage}
            handleGetAllLeads={handleGetAllLeads}
            setstatusFilter={setstatusFilter}
            statusFilter={statusFilter}
            setImportCsv={setImportCsv}
            setassigneeModal={setassigneeModal}
            isModuleReadWriteAccessForLeads={isModuleReadWriteAccessForLeads}
            setexportCsvModal={setexportCsvModal}
            setshowCheckBox={setshowCheckBox}
            showCheckBox={showCheckBox}
            setCheckedLeads={setCheckedLeads}
            setstartDate={setstartDate}
            setendDate={setendDate}
            leadOverviewCount={leadOverviewCount}
          />
          <div className="d-flex justify-content-end gap-1 me-2 mt-2">
            <div className="me-2">
              <div className="todo-toolbar">
                <select
                  className="form-select form-select-md"
                  name="leadSource"
                  value={leadSource}
                  onChange={(e) => {
                    setleadSource(e.target.value);
                  }}
                >
                  <option value="">Select Source</option>
                  <option value="website">1. Website</option>
                  {allLeadSource?.map((data, index) => {
                    return (
                      <option value={data?.name}>
                        {index + 2}. {data?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <input
              className="form-control w-140p"
              name="single-date-pick"
              type="date"
              value={startDate}
              onChange={(e) => {
                setstartDate(e.target.value);
              }}
              max={endDate}
            />
            <p className="mt-2 ms-2 me-2 fw-bold text-dark">to</p>
            <input
              className="form-control w-140p"
              name="single-date-pick"
              type="date"
              value={endDate}
              onChange={(e) => {
                setendDate(e.target.value);
              }}
              min={startDate}
            />

            <button
              disabled={loader}
              onClick={() => {
                leadOverviewCount();
                handleGetAllLeads();
              }}
              className="btn btn-primary btn-xs btn-outline-light flex-shrink-0 d-lg-inline-block   ms-2 pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="14"
                fill="#000"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
          <div className="d-flex gap-1 ms-2">
            {subStatus && (
              <div className="d-flex justify-content-end">
                <span
                  className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                  style={{
                    backgroundColor: "#b2b2b2",
                    color: "#ffff",
                    zIndex: 9999,
                  }}
                >
                  {subStatus?.name}
                  <span className="initial-wrap w-30p">
                    <svg
                      onClick={() => {
                        setsubStatus("");
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </span>
                </span>
              </div>
            )}
            {isBusinessWise && !selectedEmpId?.name && (
              <div className="d-flex justify-content-end">
                <span
                  className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                  style={{
                    backgroundColor: "#b2b2b2",
                    color: "#ffff",
                    zIndex: 9999,
                  }}
                >
                  Business Wise
                  <span className="initial-wrap w-30p">
                    <svg
                      onClick={() => {
                        setIsBusinessWise(false);
                        setBusinessWise(false);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </span>
                </span>
              </div>
            )}
            {selectedEmpId?.name && !isBusinessWise && (
              <div className="d-flex justify-content-end">
                <span
                  className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                  style={{
                    backgroundColor: "#b2b2b2",
                    color: "#ffff",
                    zIndex: 9999,
                  }}
                >
                  {selectedEmpId?.name}
                  <span className="initial-wrap w-30p">
                    <svg
                      onClick={() => {
                        resetSelectedEmployee();
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#fff"
                      className="bi bi-x-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </span>
                </span>
              </div>
            )}
          </div>
          {Object.keys(checkedLeads)?.length > 0 && (
            <button
              className="btn btn-md btn-primary flex-shrink-0 d-lg-inline-block floating-button-crm-group"
              onClick={() => {
                setAddParticipant(true);
              }}
            >
              Add{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                />
              </svg>
            </button>
          )}
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allLeads?.length > 0 && (
                <LeadsTable
                  allLeads={allLeads}
                  page={page}
                  pageLimit={pageLimit}
                  countOverview={countOverview}
                  setCheckedLeads={setCheckedLeads}
                  checkedLeads={checkedLeads}
                  showCheckBox={showCheckBox}
                  selectAllChecked={selectAllChecked}
                  setSelectAllChecked={setSelectAllChecked}
                  loader={countLoader}
                  startDate={startDate}
                  endDate={endDate}
                  location={location}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allLeads?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allLeads?.length}
                  align={"end"}
                />
              )}
              {!loader && allLeads?.length === 0 && (
                <NoData title="No Lead added" />
              )}
            </div>
          </div>
        </div>
        <CreateLead
          handleGetAllLeads={handleGetAllLeads}
          editLeadData={editLeadData}
          setEditLeadData={setEditLeadData}
        />
        {assigneeModal && (
          <AssigneeModal
            setassigneeModal={setassigneeModal}
            assigneeModal={assigneeModal}
            selectedEmpId={selectedEmpId}
            setselectedEmpId={setselectedEmpId}
            handleRefresh={handleFilterAssignee}
            isBusinessWise={isBusinessWise}
            setIsBusinessWise={setIsBusinessWise}
          />
        )}
        <AssignGroup leadId={leadId} handleGetAllLeads={handleGetAllLeads} />
        {addParticipant && (
          <AddParticipants
            addParticipant={addParticipant}
            setAddParticipant={setAddParticipant}
            selectedAssigneeId={selectedAssigneeId}
            setselectedAssigneeId={setselectedAssigneeId}
            handleClose={handleClose}
            handleAddParticipant={handleAddParticipantToLead}
            groupDetails={allLeads}
            loader2={loader2}
            errorMessage={errorMessage}
            isSingle={false}
          />
        )}
        {importCsv && (
          <ImportCsv
            setImportCsv={setImportCsv}
            handleGetAllLeads={handleGetAllLeads}
          />
        )}
        {exportCsvModal && (
          <ExportCsv
            setexportCsvModal={setexportCsvModal}
            csvLimit={csvLimit}
            setCsvLimit={setCsvLimit}
            loader={loader2}
            handleGetCsvLeads={handleGetCsvLeads}
            exportCsvModal={exportCsvModal}
            csvLeads={csvLeads}
            setCsvLeads={setCsvLeads}
            count={count}
          />
        )}
        <LastActivity
          setEditLeadData={setEditLeadData}
          editLeadData={editLeadData}
        />
      </div>
    </div>
  );
};

export default Leads;
