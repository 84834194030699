import React, { useState } from "react";
import LoginLeft from "../Login/LoginLeft";
import ConfirmPassword from "./ConfirmPassword";
import OtpVerify from "./OtpVerify";
import EmailVerify from "./EmailVerify";
import ApiCall from "../../../api/ApiCall";
import { forgotPasswordEndpoints } from "../../../api/Endpoints";
import { useNavigate } from "react-router-dom";
import { startApiCall } from "../../../global-functions/globalFunctions";
import AuthenticateFooter from "../../../components/Footers/AuthenticateFooter";

const Reset = () => {
  const navigate = useNavigate();
  const [firstStep, setfirstStep] = useState(true);
  const [secondStep, setsecondStep] = useState(false);
  const [thirdStep, setthirdStep] = useState(false);
  const [otpString, setOtpString] = useState("");
  const [otp, setotp] = useState(new Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);

  const completeFirstStep = () => {
    setfirstStep(false);
    setsecondStep(true);
    setthirdStep(false);
  };

  const completeSecondStep = () => {
    setfirstStep(false);
    setsecondStep(false);
    setthirdStep(true);
  };

  const handleEmailVerify = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    const res = await ApiCall("post", forgotPasswordEndpoints.sendOtpForResetPassword, { email });
    if (res?.success) {
      setotp(new Array(6).fill(""))
      setOtpString("");
      setloader2(false);
      completeFirstStep();
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <>
      <div className="hk-wrapper hk-pg-auth" data-footer="simple">
        <div className="hk-pg-wrapper pb-7">
          <div className="hk-pg-body p-0">
            <div className="container-xxl">
              <div className="row">
                <LoginLeft />
                {firstStep && !secondStep && !thirdStep && (
                  <EmailVerify
                    setEmail={setEmail}
                    email={email}
                    errorMessage={errorMessage}
                    loader={loader2}
                    handleEmailVerify={handleEmailVerify}
                  />
                )}
                {!firstStep && secondStep && !thirdStep && (
                  <OtpVerify
                    completeSecondStep={completeSecondStep}
                    email={email}
                    errorMessage={errorMessage}
                    seterrorMessage={seterrorMessage}
                    loader={loader}
                    setloader={setloader}
                    otp={otp}
                    setotp={setotp}
                    otpString={otpString}
                    setOtpString={setOtpString}
                    handleEmailVerify={handleEmailVerify}
                    loader2={loader2}
                  />
                )}
                {!firstStep && !secondStep && thirdStep && (
                  <ConfirmPassword
                    password={password}
                    setPassword={setPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    errorMessage={errorMessage}
                    seterrorMessage={seterrorMessage}
                    loader={loader}
                    setloader={setloader}
                    navigate={navigate}
                    otpString={otpString}
                  />
                )}
              </div>
            </div>
          </div>
          <AuthenticateFooter />
        </div>
      </div>
    </>
  );
};

export default Reset;
