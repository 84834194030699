import React, { useEffect, useState } from "react";
import { navigateToShiftTiming } from "../../../../Navigation/Navigation";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useLocation } from "react-router-dom";

const ShiftDetailsHeader = ({
  navigate,
  currentDate,
  currentMonth,
  handlePrevMonth,
  handleNextMonth,
  handleGetAllShiftsofMonth,
  isModuleReadWriteAccessShift,
}) => {
  const today = new Date();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center gap-1 text-dark me-2 col-sm-6">
        {isModuleReadWriteAccessShift && (
          <>
            <button
              className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
              onClick={() => navigate(navigateToShiftTiming())}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
              <p className="mb-0">Back</p>
            </button>
            <div className="v-separator d-lg-inline-block d-none"></div>
          </>
        )}

        <span className="d-flex align-items-center text-capitalize">
          <Avatar
            name={locationData?.name}
            count={1}
            size="xxs"
            color="primary"
            image={locationData?.image}
          />
          &nbsp;&nbsp;
          <span>{locationData?.name}</span>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="d-flex me-2 border rounded-2 p-1">
          <button
            className="btn btn-icon btn-rounded"
            onClick={handlePrevMonth}
            disabled={currentMonth === today.getMonth()}
          >
            <span className="feather-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-left-fill"
                viewBox="0 0 16 16"
              >
                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
              </svg>
            </span>
          </button>

          <span className="mt-2">
            {new Date(
              currentDate.getFullYear(),
              currentMonth
            ).toLocaleDateString("en-US", { month: "long" })}
          </span>

          <button
            className="btn btn-icon btn-rounded"
            onClick={handleNextMonth}
          >
            <span className="feather-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-right-fill"
                viewBox="0 0 16 16"
              >
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
              </svg>
            </span>
          </button>
        </div>

        <div className="v-separator d-lg-inline-block d-none"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0 mt-1"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={handleGetAllShiftsofMonth}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default ShiftDetailsHeader;
