import React from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";

const SalesStatisticsGraph = ({ salesData }) => {

  let maxRevenue = 0;
  let maxRevenueIndex = -1;

  salesData.forEach((data , index) => {
    const revenue = data.totalCosts?.[0]?.revenue || 0;
    if (revenue > maxRevenue) {
      maxRevenue = revenue;
      maxRevenueIndex = index;
    }
  });

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };
  const lineChartData = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Sales statistics",
        data: salesData?.map((data) => data.totalCosts?.[0]?.revenue),
        fill: false,
        borderColor: "#289E6E",
        backgroundColor: "rgba(40, 158, 110, 0.25)",
        lineTension: 0.4,
      },
    ],
  };

  if (maxRevenueIndex !== -1) {
    lineChartData.datasets[0].pointRadius = Array(7).fill(4);
    lineChartData.datasets[0].pointHoverRadius = Array(7).fill(4);
    lineChartData.datasets[0].pointRadius[maxRevenueIndex] = 10;
    lineChartData.datasets[0].pointHoverRadius[maxRevenueIndex] = 10;
  }

  return (
    <div className="d-flex justify-content-center h-100">
      <Line data={lineChartData} options={lineChartOptions} />
    </div>
  );
};

export default SalesStatisticsGraph;
