import React, { useEffect, useState } from "react";
import { salaryRelatedEndPoints } from "../../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  monthNames,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import SalaryDetailsContent from "./SalaryDetailsContent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { navigateToStaffSalary } from "../../../../Navigation/Navigation";
import EditSalaryDetails from "./EditSalaryDetails";
import { Avatar } from "../../../../components/Avatar/Avatar";
import SalaryInfoDetails from "./SalaryInfoDetails";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import { useSelector } from "react-redux";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";
import SalaryDetailsSkeleton from "./SalaryDetailsSkeleton";
import moment from "moment";

const SalaryDetails = () => {
  const navigate = useNavigate();
  const { allModuleAccess } = useSelector((state) => state);
  const { id, monthname } = useParams();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [salaryDetails, setSalaryDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);

  const isModuleReadWriteAccessForSalary =
    allModuleAccess?.[PRIVATEROUTES.SALARY];

  // EDIT SALARY DETAILS
  const [isEditSalary, setisEditSalary] = useState(false);
  const [infoDetails, setInfoDetails] = useState(false);
  const [salaryInsightData, setSalaryInsightData] = useState([]);
  const [infoData, setInfoData] = useState({ type: "" });
  const [leavePresentDetails, setLeavePresentDetails] = useState([]);
  const [salaryData, setSalaryData] = useState("");

  // MONTHLY FILTER
  const today = new Date();
  // today.setMonth(today.getMonth() - 1);
  const [selectedMonth, setSelectedMonth] = useState(monthname);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const year = today.getFullYear();
  const thisMonth = today.getMonth();
  const date = new Date(`${monthname} 1, ${year}`);
  const monthNumber = date.getMonth() + 1;
  const previousMonthFirstDay = new Date(year, thisMonth, 1);
  const previousMonthLastDay = new Date(year, thisMonth + 1, 0);
  const formattedMonth =
    monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
  const initialSetMonthFormat = `${year}-${formattedMonth}`;
  const [monthFormat, setMonthFormat] = useState(initialSetMonthFormat);
  const isCurrentMonth = selectedMonth === monthNames[today.getMonth()];

  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  //Week of Days
  const currentDate = new Date(formattedDate);
  const selectedDate = new Date(monthFormat + "-01");
  const monthIndex = selectedDate.getMonth();
  const firstDayOfCurrentMonth = new Date(year, monthIndex, 1);
  const lastDayOfCurrentMonth = new Date(year, monthIndex + 1, 0);

  const returnDay = (day) => {
    switch (day) {
      case "Sun":
        return 0;
      case "Mon":
        return 1;
      case "Tue":
        return 2;
      case "Wed":
        return 3;
      case "Thu":
        return 4;
      case "Fri":
        return 5;
      case "Sat":
        return 6;
      default:
        return -1; // or any other value to indicate an invalid day
    }
  };

  const weekends = salaryDetails?.weekDaysOff?.map((day) => returnDay(day));

  const getWeekendDaysCount = (startDate, endDate, weekOffArray) => {
    // //console.log(startDate, endDate, weekOffArray);
    let currentDate = moment(startDate);
    let count = 0;

    while (currentDate?.isSameOrBefore(endDate, "day")) {
      if (weekOffArray?.some((day) => day === currentDate?.day())) {
        count++;
      }
      currentDate?.add(1, "days");
    }

    return count;
  };

  const weekendDays = getWeekendDaysCount(
    firstDayOfCurrentMonth,
    lastDayOfCurrentMonth,
    weekends
  );

  const formattedStartDate = `${previousMonthFirstDay.getFullYear()}-${(monthname
    ? monthNumber
    : previousMonthFirstDay.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-01`;

  const lastDayOfMonth = (year, month) => new Date(year, month, 0).getDate();
  const formattedEndDate = `${previousMonthLastDay.getFullYear()}-${(monthname
    ? monthNumber
    : previousMonthLastDay.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth(
    previousMonthLastDay.getFullYear(),
    monthNumber
  )
    .toString()
    .padStart(2, "0")}`;

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
    const selectedMonthIndex = monthNames.indexOf(selectedMonth);
    if (selectedMonthIndex !== -1) {
      const month = selectedMonthIndex + 1;
      const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay
        .toString()
        .padStart(2, "0")}`;
      const formattedMonth = `${year}-${month.toString().padStart(2, "0")}`;
      setStartDate(startDate);
      setEndDate(endDate);
      setMonthFormat(formattedMonth);
    }
  };

  // GET SALARY DETAILS FUNCTION API CALL
  const handleGetSalaryDetails = async () => {
    startApiCall(null, setLoader);
    const sendData = {
      employeeId: id,
      businessId: getCurrentWorkspaceId(),
      startDate: startDate ? startDate : formattedStartDate,
      endDate: endDate ? endDate : formattedEndDate,
    };
    const res = await ApiCall(
      "post",
      salaryRelatedEndPoints.getSalaryDetails,
      sendData
    );
    if (res?.success) {
      setSalaryDetails(res?.result);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  // GET SALARY INSIGHTS FUNCTION API CALL
  const handleGetSalaryInsights = async () => {
    startApiCall(null, setLoader3);
    const sendData = {
      employeeId: id,
      businessId: getCurrentWorkspaceId(),
      month: monthFormat,
    };
    const res = await ApiCall(
      "post",
      salaryRelatedEndPoints.getSalaryInsight,
      sendData
    );
    if (res?.success) {
      setSalaryInsightData(res?.response);
      setLoader3(false);
    } else {
      setLoader3(false);
    }
  };

  useEffect(() => {
    handleGetSalaryDetails();
    handleGetSalaryInsights();
  }, [selectedMonth]);

  // GET SALARY COUNTS FUNCTION API CALL
  const handleGetSalaryCounts = async () => {
    startApiCall(null, setLoader2);
    const sendData = {
      employeeId: id,
      businessId: getCurrentWorkspaceId(),
      startDate: startDate ? startDate : formattedStartDate,
      endDate: endDate ? endDate : formattedEndDate,
    };
    const res = await ApiCall(
      "post",
      salaryRelatedEndPoints.salaryTotalCount,
      sendData
    );
    if (res?.success) {
      const sortedDetails = res?.salary?.data?.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
      setLeavePresentDetails(sortedDetails);
      setSalaryData(res?.salary);
      setLoader2(false);
    } else {
      setLoader2(false);
    }
  };

  useEffect(() => {
    if (infoData?.type === "payable" || infoData?.type === "deduction") {
      handleGetSalaryCounts();
    }
  }, [infoData, selectedMonth]);

  const handleRefresh = () => {
    handleGetSalaryDetails();
    handleGetSalaryInsights();
  };

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center gap-1 text-dark me-2 col-sm-6">
              <div
                className="pointer"
                onClick={() => navigate(navigateToStaffSalary())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </div>
              {!loader && (
                <span className="d-flex align-items-center gap-2 text-capitalize">
                  <Avatar
                    name={locationData?.employeeName}
                    count={1}
                    size="xss"
                    color="primary"
                    image={locationData?.image}
                  />
                  <p>
                    {salaryDetails?.employeeName
                      ? salaryDetails?.employeeName
                      : locationData?.employeeName}
                  </p>
                </span>
              )}
              {(loader || loader3) && (
                <SkeletonConstant width="w-200p" height="h-35p" />
              )}
            </div>
            <div className="todo-options-wrap">
              <div className="me-4">
                {!loader && (
                  <select
                    className="form-select form-select"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                  >
                    {monthNames?.map((mon, index) => {
                      return <option key={index}>{mon}</option>;
                    })}
                  </select>
                )}
                {(loader || loader3) && (
                  <SkeletonConstant width="w-140p" height="h-35p" />
                )}
              </div>
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="dsdsd"
                data-bs-original-title="Refresh"
              >
                <span className="btn-icon-wrap" onClick={handleRefresh}>
                  <span className="feather-icon">
                    <i
                      className="ri-refresh-line"
                      title="Refresh business list"
                    ></i>
                  </span>
                </span>
              </span>
              <div className="v-separator d-lg-inline-block d-none"></div>
            </div>
          </header>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                {!loader && (
                  <SalaryDetailsContent
                    salaryDetails={salaryDetails}
                    setisEditSalary={setisEditSalary}
                    id={id}
                    formattedStartDate={formattedStartDate}
                    formattedDate={formattedDate}
                    handleGetSalaryDetails={handleGetSalaryDetails}
                    locationData={locationData}
                    selectedMonth={selectedMonth}
                    setInfoDetails={setInfoDetails}
                    salaryInsightData={salaryInsightData}
                    setInfoData={setInfoData}
                    isModuleReadWriteAccessForSalary={
                      isModuleReadWriteAccessForSalary
                    }
                  />
                )}
                {(loader || loader3) && <SalaryDetailsSkeleton />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEditSalary && (
        <EditSalaryDetails
          setisEditSalary={setisEditSalary}
          id={id}
          monthFormat={monthFormat}
          handleRefresh={handleRefresh}
          salaryInsightData={salaryInsightData}
          formattedDate={formattedDate}
        />
      )}
      {infoDetails && (
        <SalaryInfoDetails
          setInfoDetails={setInfoDetails}
          infoData={infoData}
          setInfoData={setInfoData}
          leavePresentDetails={leavePresentDetails}
          loader2={loader2}
          salaryDetails={salaryDetails}
          salaryData={salaryData}
          daysOffCount={weekendDays}
        />
      )}
    </div>
  );
};

export default SalaryDetails;
