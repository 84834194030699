import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../../../components/Error/ErrorMessage";
import { startApiCall } from "../../../../../../global-functions/globalFunctions";
import NoDataWithoutImage from "../../../../../../components/NoData/NoDataWithoutImage";
import DataLoader from "../../../../../../components/Loaders/DataLoader/DataLoader";

function DetectLocationModal({
  setautomaticLocationModal,
  description,
  setmapLink,
  mapLink,
}) {
  const [location, setLocation] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;

  const handleReset = () => {
    setautomaticLocationModal(false);
    setLocation(null);
    setmapLink("");
    setErrorMessage("");
  };
  function convertManualLocationToCoordinates(manualLocation) {
    const match = manualLocation?.match(regex);
    if (match && match.length === 3) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return { latitude, longitude };
    }
    return null;
  }

  useEffect(() => {
    if (mapLink && mapLink?.match(regex)) {
      setLocation(convertManualLocationToCoordinates(mapLink));
    }
  }, [mapLink]);

  function generateGoogleMapsLink(latitude, longitude) {
    return `https://www.google.com/maps?q=${latitude},${longitude}`;
  }

  const fetchLocation = () => {
    startApiCall(null, setLoading);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setmapLink(generateGoogleMapsLink(latitude, longitude));
          setLoading(false);
        },
        (error) => {
          alert(error.message);
          setLoading(false);
        }
      );
    } else {
      setErrorMessage("Geolocation is not supported by your browser.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocation();
  }, []);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content p-2" style={{ overflow: "visible" }}>
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2 text-capitalize">Location Insights</h5>
            <form
              onSubmit={() => {
                setautomaticLocationModal(false);
                setLocation(null);
              }}
            >
              <div className="row gx-3 px-3 w-100 mt-4 d-flex">
                {description && (
                  <div className="d-flex justify-content-start">
                    <div
                      className="alert alert-secondary fs-7 px-2 py-2 mt-1"
                      role="alert"
                    >
                      <strong>Description: </strong> {description}
                    </div>
                  </div>
                )}
                <div className="d-flex flex-column col-6">
                  <div className="mt-4">
                    <div className="form-group">
                      <label className="form-label d-flex justify-content-between">
                        Map Link{" "}
                        <span
                          className="text-primary pointer"
                          onClick={() => {
                            fetchLocation();
                          }}
                        >
                          Change Location{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-geo"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"
                            />
                          </svg>
                        </span>
                      </label>
                      <textarea
                        className="form-control mt-2"
                        rows="3"
                        name="mapLink"
                        pattern="https://.*"
                        value={mapLink}
                        onChange={(e) => {
                          setmapLink(e.target.value);
                        }}
                        required
                        placeholder="Map Link"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column col-6">
                  {loading && (
                    <div className="W-100 h-300p d-flex flex-column justify-content-center align-items-center text-primary">
                      {" "}
                      <DataLoader />
                      <span className="mt-3"> Detecting location</span>
                    </div>
                  )}
                  {!loading && location?.latitude && location?.longitude && (
                    <iframe
                      title="Google Map"
                      width="100%"
                      height="300"
                      frameborder="0"
                      style={{ border: 0 }}
                      src={`https://www.google.com/maps?q=${location?.latitude},${location?.longitude}&output=embed`}
                      allowfullscreen
                    ></iframe>
                  )}
                  {!loading && !location?.latitude && !location?.longitude && (
                    <div className="W-100 h-300p d-flex justify-content-center align-items-center">
                      {" "}
                      <NoDataWithoutImage
                        title="No Location Found"
                        description="Try changing your location permission."
                      />{" "}
                    </div>
                  )}
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="d-flex justify-content-end mt-4">
                  <button type="submit" className="btn btn-primary">
                    Submit Location
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetectLocationModal;
