import React, { useEffect, useState } from "react";
import {
  getLocationUrl,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { LocationRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { Link } from "react-router-dom";
import ErrorMessage from "../../../components/Error/ErrorMessage";

const EditLocation = (props) => {
  const {
    isEditLocation,
    setIsEditLocation,
    seteditLocationData,
    editLocationData,
    handleGetAllLocation,
  } = props;
  const [locationName, setlocationName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  const handleReset = () => {
    setloader(false);
    setIsEditLocation(false);
    seteditLocationData([]);
    setlocationName("");
    setErrorMessage("");
  };

  useEffect(() => {
    if (editLocationData) {
      setlocationName(editLocationData?.locationName);
    }
  }, [editLocationData]);

  const handleEditLocation = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      locationId: editLocationData?._id,
      locationName: locationName,
    };

    const res = await ApiCall(
      "post",
      LocationRelatedEndPoints.editLocation,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetAllLocation();
      toast.success("Edit Location Successfully");
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  return (
    <div
      id="edit_location"
      className={`custom-modal modal fade add-new-contact ${
        isEditLocation ? "show" : ""
      }`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: isEditLocation ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Edit Location</h5>
            <div className="border-bottom mb-3"></div>
            <form onSubmit={handleEditLocation}>
              <div className="form-group">
                <label className="form-label">Location name</label>
                <input
                  className="form-control"
                  type="text"
                  autoFocus
                  placeholder="Enter location name"
                  required
                  onChange={(e) => {
                    setlocationName(e.target.value);
                  }}
                  value={locationName}
                />
              </div>
              <div className="form-group mt-2">
                <label className="form-label">Location : </label>
                <Link
                  to={getLocationUrl(
                    editLocationData?.latitude,
                    editLocationData?.longitude
                  )}
                  className="text-primary text-underline"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-geo-alt"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                  &nbsp;Check location
                </Link>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!locationName}
                >
                  {loader ? <ApiLoader /> : "Edit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLocation;
