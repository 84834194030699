import React from "react";

const ActivityGroupHeader = ({
  handleGetAllGroup,
  selectType,
  setSelectType,
  search,
  handleSearch,
  isModuleReadWriteAccessForActivityGroup,
}) => {
  const allType = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Meeting",
      value: "meeting",
    },
    {
      name: "Call",
      value: "call",
    },
    {
      name: "Task",
      value: "task",
    },
  ];

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1 className="header-title-font">Activity Groups</h1>
          {isModuleReadWriteAccessForActivityGroup && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                data-bs-toggle="modal"
                data-bs-target="#add_activity_group"
              >
                Create Group
              </button>
            </div>
          )}
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="me-2">
          <select
            className="form-select form-select"
            value={selectType}
            onChange={(e) => {
              setSelectType(e.target.value);
            }}
          >
            {allType?.map((status) => {
              return <option value={status?.value}>{status?.name}</option>;
            })}
          </select>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllGroup}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-lg-block" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
          />
        </form>
      </div>
    </header>
  );
};

export default ActivityGroupHeader;
