import React, { useEffect, useState } from "react";
import NoticeDetailsCard from "./NoticeDetailsCard";
import { startApiCall } from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { noticeRelatedEndPoints } from "../../../../api/Endpoints";
import { useLocation, useParams } from "react-router-dom";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";
import EditNotice from "./EditNotice";
import NoticeDetailsHeader from "./NoticeDetailsHeader";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

const NoticeDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [noticeDetails, setNoticeDetails] = useState([]);
  const [editNoticeData, setEditNoticeData] = useState([]);

  const isModuleReadWriteAccessNotice = allModuleAccess?.[PRIVATEROUTES.NOTICE];

  const handleGetNoticeDetails = async () => {
    startApiCall(null, setloader);
    const res = await ApiCall("get", noticeRelatedEndPoints.getNoticeById, {
      params: {
        noticeId: id,
        viwerId: user?._id,
      },
    });
    if (res?.success) {
      setNoticeDetails(res?.notice);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetNoticeDetails();
  }, []);

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <NoticeDetailsHeader
            locationData={locationData}
            handleGetNoticeDetails={handleGetNoticeDetails}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="contact-list-view">
                {!loader && noticeDetails && (
                  <NoticeDetailsCard
                    noticeDetails={noticeDetails}
                    setEditNoticeData={setEditNoticeData}
                    editNoticeData={editNoticeData}
                    id={id}
                    handleGetNoticeDetails={handleGetNoticeDetails}
                    user={user}
                    isModuleReadWriteAccessNotice={
                      isModuleReadWriteAccessNotice
                    }
                  />
                )}
                {loader && <DataLoader title="data is loading..." />}
              </div>
            </div>
          </div>
        </div>
        <EditNotice
          editNoticeData={editNoticeData}
          handleGetNoticeDetails={handleGetNoticeDetails}
          setEditNoticeData={setEditNoticeData}
          locationData={locationData}
        />
      </div>
    </div>
  );
};

export default NoticeDetails;
