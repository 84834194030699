import React from 'react'
import NoTaskSvg from "../../../assets/images/notask.svg";
const NoComplaint = () => {
    return (
        <div className="d-flex flex-column gap-2 align-items-center w-100 px-6 py-3">
        <img className="w-200p mb-2" src={NoTaskSvg} alt="" />
        <p className="text-dark fw-semibold fs-4">
          Congratulations, You do not have any complaints
        </p>
        <p className="text-dark fs-5">
          You can raise complaint whenever you want
        </p>
      </div>
      )
  
}

export default NoComplaint