import React, { useState } from "react";
import {
  convertAmountLocal,
  convertTimeAndDateMoment,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ItemsInfoModal from "../OrderHistory/ItemsInfoModal";
import { OrderRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { useSelector } from "react-redux";

const OrderApprovalTable = ({
  allOrders,
  page,
  pageLimit,
  handleGetOrders,
  itemDetails,
  setItemDetails,
  setisCustomerModal,
  isModuleReadWriteAccessForOrderApprovals,
}) => {
  const { user } = useSelector((state) => state);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirm, setisConfirm] = useState(false);
  const [isApproved, setisApproved] = useState("");

  const openDetailsModal = (order) => {
    setItemDetails(order);
    setIsOpenDetailsModal(true);
  };

  // GET ORDER APPROVAL  API CALL
  const handleOrderApproval = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      orderId: itemDetails?._id,
      isApproved,
      actionBy: user?._id,
      businessId:getCurrentWorkspaceId()
    };
    const res = await ApiCall(
      "post",
      OrderRelatedEndPoints.updateOrderApproval,
      data
    );
    if (res?.success) {
      toast.success(
        isApproved
          ? "Order Approved Successfully"
          : "Order Rejected Successfully"
      );
      handleGetOrders();
      setItemDetails(null);
      handleClose();
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  const handleClose = () => {
    setisConfirm(false);
  };
  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-5">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th>Order Timing</th>
              <th>Order No.</th>
              <th>Table</th>
              <th>Customer</th>
              <th>Dish (Items)</th>
              <th>Order Amount</th>
              <th>Type</th>
              <th>Status</th>
              {isModuleReadWriteAccessForOrderApprovals && <th>Action</th>}
            </tr>
          </thead>
          <tbody className="text-dark">
            {allOrders?.map((order, index) => {
              return (
                <tr key={index} className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td>{convertTimeAndDateMoment(order?.updatedAt)}</td>
                  <td>{order?.orderNumber}</td>
                  <td>{order?.tableId?.tableName?order?.tableId?.tableName:"-"}</td>
                  <td>
                    {order?.buyer?.phone}
                    &nbsp;
                    <svg
                      onClick={() => {
                        setItemDetails(order);
                        setisCustomerModal(true);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle pointer text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </td>
                  <td className="text-primary pointer underline">
                    {order?.products?.length === 1
                      ? "1 Item"
                      : "+ " + order?.products?.length + " Items"}
                    &nbsp;
                    <svg
                      onClick={() => {
                        openDetailsModal(order);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle pointer text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </td>
                  <td>
                    {convertAmountLocal(order?.amount)}&nbsp;
                    <svg
                      onClick={() => {
                        openDetailsModal(order);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle pointer text-primary"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg>
                  </td>
                  <td className="text-capitalize">{order?.orderType}</td>
                  <td className="text-capitalize">{order?.isApproved}</td>
                  {isModuleReadWriteAccessForOrderApprovals && (
                    <td>
                      {order?.isApproved === "pending" && (
                        <svg
                          onClick={() => {
                            setisApproved("rejected");
                            setItemDetails(order);
                            setisConfirm(true);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          title="Reject Order"
                          width="22"
                          height="22"
                          fill="#d42f37"
                          class="bi bi-x-circle-fill pointer"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                        </svg>
                      )}
                      <svg
                        onClick={() => {
                          setisApproved("approved");
                          setItemDetails(order);
                          setisConfirm(true);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        title="Approve Order"
                        width="22"
                        height="22"
                        fill="#07bc0c"
                        className="bi bi-check-circle-fill ms-2 pointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isOpenDetailsModal && (
        <ItemsInfoModal
          setIsOpenDetailsModal={setIsOpenDetailsModal}
          itemDetails={itemDetails}
        />
      )}
      <ConfirmModal
        isOpen={isConfirm}
        onClose={handleClose}
        text={`Are you sure? You want to ${
          isApproved === "approved" ? "Approve" : "Reject"
        } this Order : ${itemDetails?.orderNumber}`}
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleOrderApproval}
      />
    </>
  );
};

export default OrderApprovalTable;
