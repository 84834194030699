import React from "react";

const ErrorMessage = ({ errorMessage }) => {
  return (
    <div className="d-flex justify-content-center mb-2">
      <i
        className="ri-error-warning-line"
        style={{ color: "red", marginRight: "4px" }}
      ></i>
      <p className="link-danger text-capitalize">{errorMessage}</p>
    </div>
  );
};

export default ErrorMessage;
