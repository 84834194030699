import React, { useState } from "react";
import DetectLocationModal from "../ActivityMandatoryTask/DetectLocationModal";
import CustomTooltip from "../../../../../../components/CustomTooltip/CustomTooltip";
import {
  handleUploadInDirectAwsAttachment,
  startApiCall,
} from "../../../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { CrmTaskRelatedEndPoints } from "../../../../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiCall from "../../../../../../api/ApiCall";
import ManualLocationModal from "../ActivityMandatoryTask/ManualLocationModal";
import ApiLoader from "../../../../../../components/Loaders/ApiLoader/ApiLoader";

function TaskActionWarningModal({
  settaskWarningModal,
  editTask,
  statusWarningDetails,
  handleGetAllTask,
  handleEditActivity,
  setimageData,
  setimageModal,
}) {
  const user = useSelector((state) => state.user);
  const [automaticLocationModal, setautomaticLocationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activityLoader, setactivityLoader] = useState(false);
  const [description, setdescription] = useState("");
  const [mapLink, setmapLink] = useState("");
  const [manualLocation, setmanualLocation] = useState(false);
  const [attachments, setAttachments] = useState({ image: [], document: [] });
  const [fileData, setfileData] = useState([]);
  const [locationData, setLocationData] = useState({
    areaName: "",
    flatHouseNo: "",
    city: "",
    state: "",
    updatedBy: user?._id,
  });

  const handleClose = () => {
    setLocationData({
      areaName: "",
      flatHouseNo: "",
      city: "",
      state: "",
      updatedBy: user?._id,
    });
    setimageData([]);
    setfileData([]);
    setAttachments({ image: [], document: [] });
    setdescription("");
    setmapLink("");
    settaskWarningModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(mapLink);
  };

  const handlePreviewFileInLocal = (files) => {
    if (!files?.url) {
      let img = URL.createObjectURL(files);
      return img;
    } else {
      return files?.url;
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileType = e.target.name;
    if (file) {
      const newAttachment = {
        url: file,
        name: file.name,
        type: file.type,
        updatedBy: user?._id,
      };
      setAttachments((prevAttachments) => ({
        ...prevAttachments,
        [fileType]: [...prevAttachments[fileType], newAttachment],
      }));
      setfileData((prevAttachments) => [...prevAttachments, file]);
    }
  };
  const handleDelete = (type) => {
    if (type === "image") {
      setAttachments({ image: [], document: attachments?.document });
      setfileData((prevFileData) =>
        prevFileData?.filter((file) => !file.type?.includes("image"))
      );
    } else if (type === "document") {
      setAttachments({ image: attachments?.image, document: [] });
      setfileData((prevFileData) =>
        prevFileData?.filter((file) => file.type?.includes("image"))
      );
    }
  };

  const uploadAttachmentData = async (e) => {
    startApiCall(null, setactivityLoader);
    e.preventDefault();
    try {
      const reqBodies = [];
      if (mapLink) {
        const location = {
          data: {
            url: [{ mapLink, updatedBy: user?._id }],
          },
          attachmentType: "automaticlocation",
        };
        reqBodies.push(location);
      }

      if (locationData?.areaName) {
        const manualLocation = {
          data: {
            url: [{ locationData, updatedBy: user?._id }],
          },
          attachmentType: "manuallocation",
        };
        reqBodies.push(manualLocation);
      }

      if (fileData?.length > 0) {
        let isError = false;
        let uploaded;
        let temp = [];
        try {
          for (let i = 0; i < fileData.length; i++) {
            uploaded = await handleUploadInDirectAwsAttachment(fileData[i]);
            if (uploaded?.Location) {
              temp.push({
                url: uploaded?.Location,
                name: fileData[i]?.name,
                type: fileData[i]?.type,
                updatedBy: user?._id,
              });
            }
          }
        } catch (error) {
          isError = true;
          setactivityLoader(false);
          setErrorMessage("Attachment uploading failed, try again later.");
        }
        if (!isError) {
          const documentAttachment = {
            data: {
              url: temp,
            },
            attachmentType: "file",
          };
          reqBodies.push(documentAttachment);
        }
      }

      if (description) {
        const noteAttachment = {
          data: {
            url: [{ description: description, updatedBy: user?._id }],
          },
          attachmentType: "note",
        };
        reqBodies.push(noteAttachment);
      }

      await Promise.all(
        reqBodies.map(async (reqBody) => {
          const sendData = {
            leadTaskId: editTask?.activityId,
            actionBy: user?._id,
            isUploading: true,
            attachment: reqBody,
            activityStatus: editTask?.statusID,
          };

          const res = await ApiCall(
            "post",
            CrmTaskRelatedEndPoints.uploadAttachment,
            sendData
          );

          if (!res?.success) {
            throw new Error(res.error);
          }
        })
      );

      handleEditActivity(
        editTask?.activity,
        editTask?.statusID,
        editTask?.activity?.taskType
      );
      handleClose();
      toast.success("Attachments added successfully!");
      handleGetAllTask();
    } catch (error) {
      console.error("Error handling attachments:", error);
      setErrorMessage(
        error.message || "Attachment uploading failed, try again later."
      );
    } finally {
      setactivityLoader(false);
    }
  };

  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content p-2">
            <form onSubmit={uploadAttachmentData}>
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close mt-2 pe-3"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <span className="d-flex">
                  <h5 className="ps-2 text-capitalize">
                    Status mandatory task
                  </h5>{" "}
                  <span className="text-capitalize font-3 ms-3" type="button">
                    {" "}
                    <strong> Status: </strong>
                    <span className="font-3 fw-bold">
                      {editTask?.statusName}
                    </span>
                  </span>
                </span>

                {editTask?.task?.length > 0 && (
                  <div className="d-flex justify-content-center mt-2">
                    <div
                      className="alert alert-info text-primary fs-7"
                      role="alert"
                    >
                      <strong>Note:</strong> These tasks are mandatory to
                      complete in order to change the status.
                    </div>
                  </div>
                )}
                <div className="d-flex flex-column">
                  {editTask?.task?.filter(
                    (loc) => Object.keys(loc)[0] === "note"
                  )?.length > 0 && (
                    <div className="form-group">
                      <label className="fs-7 mb-2 d-flex align-items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-lg"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                        Add Note
                      </label>
                      <textarea
                        class="form-control"
                        placeholder={
                          editTask?.task?.filter(
                            (loc) => Object.keys(loc)[0] === "note"
                          )?.[0]?.description
                        }
                        required
                        rows="2"
                        value={description}
                        onChange={(e) => {
                          setdescription(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  )}
                  {editTask?.task?.filter(
                    (loc) => Object.keys(loc)[0] === "location"
                  )?.length > 0 && (
                    <div className="form-group">
                      <label className="fs-7 d-flex align-items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-geo-alt-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                        </svg>
                        Location Details
                      </label>
                      <p className="text-secondary fs-8 mb-2">
                        {
                          editTask?.task?.filter(
                            (loc) => Object.keys(loc)[0] === "location"
                          )?.[0]?.description
                        }
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          {!mapLink && (
                            <div class="card py-2 px-3 mb-0">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="#3862DD"
                                    class="bi bi-crosshair"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8.5.5a.5.5 0 0 0-1 0v.518A7 7 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7 7 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7 7 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7 7 0 0 0 8.5 1.018zm-6.48 7A6 6 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6 6 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6 6 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6 6 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1zM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                                  </svg>
                                  <div className="d-flex flex-column">
                                    <p className="mb-0 fs-7">Your Location</p>
                                    <span className="fw-400 text-center">
                                      Using GPS
                                    </span>
                                  </div>
                                </div>
                                <span
                                  className="btn btn-sm btn-outline-primary"
                                  onClick={() => {
                                    setautomaticLocationModal(true);
                                  }}
                                >
                                  GRANT
                                </span>
                              </div>
                            </div>
                          )}
                          {mapLink && (
                            <div class="card py-2 px-3 mb-0">
                              <div className="d-flex align-items-center justify-content-between h-45p">
                                <div className="d-flex align-items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    class="bi bi-check-circle-fill text-success"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>
                                  <div className="d-flex flex-column">
                                    <p className="mb-0 fs-7">Your Location</p>
                                    <span className="fs-7 text-success">
                                      Location Fetched
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-1">
                                  <CustomTooltip
                                    text="View Address"
                                    placement="left"
                                  >
                                    <a
                                      className="fw-bold mb-2"
                                      target="_blank"
                                      href={mapLink}
                                    >
                                      <span className="btn btn-icon btn-sm rounded-circle btn-light">
                                        <span className="feather-icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-eye"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </CustomTooltip>

                                  <CustomTooltip
                                    text="Edit Location"
                                    placement="left"
                                  >
                                    <span
                                      className="btn btn-icon btn-sm rounded-circle btn-soft-primary"
                                      onClick={() => {
                                        setautomaticLocationModal(true);
                                      }}
                                    >
                                      <span className="feather-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-pencil-square"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                          />
                                        </svg>
                                      </span>
                                    </span>
                                  </CustomTooltip>

                                  <CustomTooltip
                                    text="Copy Address"
                                    placement="left"
                                  >
                                    <span
                                      className="btn btn-icon btn-sm rounded-circle btn-light"
                                      onClick={() => {
                                        copyToClipboard();
                                      }}
                                    >
                                      <span className="feather-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-copy"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                          />
                                        </svg>
                                      </span>
                                    </span>
                                  </CustomTooltip>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          {!locationData?.areaName && (
                            <div class="card py-2 px-3 mb-0">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="#3862DD"
                                    class="bi bi-plus-lg"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                                    />
                                  </svg>
                                  <div className="d-flex flex-column">
                                    <p className="mb-0 fs-7">Add Address</p>
                                    <span className="fw-400 text-center">
                                      Manually
                                    </span>
                                  </div>
                                </div>
                                <span
                                  className="btn btn-sm btn-outline-primary"
                                  onClick={() => {
                                    setmanualLocation(true);
                                  }}
                                >
                                  GRANT
                                </span>
                              </div>
                            </div>
                          )}

                          {locationData?.areaName && (
                            <div class="card py-2 px-3 mb-0">
                              <div className="d-flex gap-2">
                                <span className="h-45p overflow-y-scroll simple-scrollbar text-dark w-80">
                                  {locationData?.areaName},{" "}
                                  {locationData?.flatHouseNo},{" "}
                                  {locationData?.city}, {locationData?.state}
                                </span>

                                <div className="d-flex align-items-center gap-1 w-20">
                                  <CustomTooltip
                                    text="Edit Address"
                                    placement="left"
                                  >
                                    <span
                                      className="btn btn-sm btn-icon rounded-circle btn-soft-primary"
                                      onClick={() => {
                                        setmanualLocation(true);
                                      }}
                                    >
                                      <span className="feather-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-pencil-square"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                          />
                                        </svg>
                                      </span>
                                    </span>
                                  </CustomTooltip>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {editTask?.task?.filter(
                    (loc) => Object.keys(loc)[0] === "file"
                  )?.length > 0 && (
                    <div className="form-group">
                      <label className="fs-7 d-flex align-items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-folder2-open"
                          viewBox="0 0 16 16"
                        >
                          <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7z" />
                        </svg>
                        Files Upload
                      </label>
                      <p className="text-secondary fs-8 mb-2">
                        {
                          editTask?.task?.filter(
                            (loc) => Object.keys(loc)[0] === "file"
                          )?.[0]?.description
                        }
                      </p>
                      <div className="row">
                        <div className="col-md-6">
                          {attachments?.image?.length === 0 && (
                            <>
                              <input
                                type="file"
                                name="image"
                                id="attachment"
                                accept="image/*"
                                className="input-file justify-content-end  w-10"
                                onChange={handleFileChange}
                              />
                              <label
                                htmlFor="attachment"
                                className="btn btn-outline-primary w-100"
                              >
                                <span className="d-flex align-items-center gap-3 ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    fill="currentColor"
                                    class="bi bi-upload"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                  </svg>
                                  <span>Upload Image</span>
                                </span>
                              </label>
                            </>
                          )}

                          {attachments?.image?.length > 0 && (
                            <div className="d-flex flex-column gap-2 mh-125p overflow-y-scroll simple-scrollbar mt-2">
                              <div className="border rounded px-3 py-2 d-flex align-items-center justify-content-between gap-3">
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    className="border-dashed rounded h-40p w-40p"
                                    src={handlePreviewFileInLocal(
                                      attachments?.image?.[0]?.url
                                    )}
                                    alt="image"
                                  />
                                  <span className="w-100p text-truncate">
                                    {attachments?.image?.[0]?.name}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center gap-1">
                                  <CustomTooltip
                                    text="View Image"
                                    placement="left"
                                  >
                                    <span
                                      className="btn btn-icon btn-sm rounded-circle btn-light"
                                      onClick={() => {
                                        setimageData(
                                          handlePreviewFileInLocal(
                                            attachments?.image?.[0]?.url
                                          )
                                        );
                                        setimageModal(true);
                                      }}
                                    >
                                      <span className="feather-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-eye"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                        </svg>
                                      </span>
                                    </span>
                                  </CustomTooltip>

                                  <CustomTooltip
                                    text="Download Image"
                                    placement="left"
                                  >
                                    <a
                                      href={handlePreviewFileInLocal(
                                        attachments?.image?.[0]?.url
                                      )}
                                      download={attachments?.image?.[0]?.name}
                                    >
                                      <span className="btn btn-icon btn-sm rounded-circle btn-soft-primary">
                                        <span className="feather-icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-download"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </CustomTooltip>

                                  <CustomTooltip
                                    text="Remove Image"
                                    placement="left"
                                  >
                                    <span
                                      className="btn btn-icon btn-sm rounded-circle btn-soft-danger"
                                      onClick={() => {
                                        handleDelete("image");
                                      }}
                                    >
                                      <span className="feather-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </CustomTooltip>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          {attachments?.document?.length === 0 && (
                            <>
                              <input
                                type="file"
                                name="document"
                                id="document"
                                accept=".pdf,.doc,.docx,.xls,.xlsx"
                                className="input-file justify-content-end w-10"
                                onChange={handleFileChange}
                              />
                              <label
                                htmlFor="document"
                                className="btn btn-outline-primary w-100"
                              >
                                <span className="d-flex align-items-center gap-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="26"
                                    height="26"
                                    fill="currentColor"
                                    className="bi bi-upload"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                  </svg>
                                  <span>Upload Document</span>
                                </span>
                              </label>
                            </>
                          )}

                          {attachments?.document?.length > 0 && (
                            <div className="d-flex flex-column gap-2 mh-125p overflow-y-scroll simple-scrollbar mt-2">
                              <div className="border rounded px-3 py-2 d-flex align-items-center justify-content-between gap-3">
                                <div className="d-flex align-items-center gap-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    fill="currentColor"
                                    class="bi bi-file-earmark-pdf"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                    <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                                  </svg>
                                  <span className="w-100p text-truncate">
                                    {attachments?.document?.[0]?.name}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center gap-1">
                                  {/* <CustomTooltip
                                    text="View Document"
                                    placement="left"
                                  >
                                    <span className="btn btn-icon btn-sm rounded-circle btn-light">
                                      <span className="feather-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-eye"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                        </svg>
                                      </span>
                                    </span>
                                  </CustomTooltip> */}

                                  <CustomTooltip
                                    text="Download Document"
                                    placement="left"
                                  >
                                    <a
                                      href={attachments?.document?.[0]?.url}
                                      download={
                                        attachments?.document?.[0]?.name
                                      }
                                    >
                                      <span className="btn btn-icon btn-sm rounded-circle btn-soft-primary">
                                        <span className="feather-icon">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-download"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                          </svg>
                                        </span>
                                      </span>
                                    </a>
                                  </CustomTooltip>

                                  <CustomTooltip
                                    text="Remove Document"
                                    placement="left"
                                  >
                                    <span
                                      className="btn btn-icon btn-sm rounded-circle btn-soft-danger"
                                      onClick={() => {
                                        handleDelete("document");
                                      }}
                                    >
                                      <span className="feather-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </CustomTooltip>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-add-tasklist"
                  disabled={
                    (editTask?.task?.filter(
                      (loc) => Object.keys(loc)[0] === "location"
                    )?.length > 0 &&
                      (!mapLink || !locationData)) ||
                    (editTask?.task?.filter(
                      (loc) => Object.keys(loc)[0] === "file"
                    )?.length > 0 &&
                      fileData?.length === 0)
                  }
                >
                  {activityLoader ? <ApiLoader /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {automaticLocationModal && (
        <DetectLocationModal
          setautomaticLocationModal={setautomaticLocationModal}
          handleGetAllTask={handleGetAllTask}
          allActivity={statusWarningDetails}
          description={
            editTask?.task?.filter(
              (loc) => Object.keys(loc)[0] === "location"
            )?.[0]?.description
          }
          setmapLink={setmapLink}
          mapLink={mapLink}
        />
      )}

      {manualLocation && (
        <ManualLocationModal
          setmanualLocation={setmanualLocation}
          handleGetAllTask={handleGetAllTask}
          allActivity={
            editTask?.task?.filter(
              (loc) => Object.keys(loc)[0] === "location"
            )?.[0]?.description
          }
          description={""}
          setLocationData={setLocationData}
          locationData={locationData}
        />
      )}
    </>
  );
}

export default TaskActionWarningModal;
