import React from "react";
import ApiLoader from "../../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../../components/Error/ErrorMessage";
import CustomTooltip from "../../../../../components/CustomTooltip/CustomTooltip";
import { reuiredField } from "../../../../../global-functions/globalFunctions";

function AddItemStepThree(props) {
  const {
    selectSetup2,
    sellingPrice,
    costPrice,
    setsellingPrice,
    setcostPrice,
    handleAddItems,
    loader,
    errorMessage,
    completeThirdStep,
    handleEditItems,
    isEdit,
    timetoCook,
    setTimetoCook,
  } = props;

  return (
    <div>
      <div className="col-sm-12 fs-5 d-flex align-items-start justify-content-start mt-3">
        <label className="text-daek">Step 3 | Pricing</label>
      </div>
      <div className="form-group d-flex col-sm-12 mt-2">
        <div className="col-sm-4">
          <label className="form-label fs-6 mt-2 text-secondary">
            Average Time To Cook {reuiredField}:
          </label>
        </div>
        <div className="col-sm-8 d-flex align-items-center border rounded-5 w-280p text-dark ">
          <span className="d-flex align-items-center justify-content-center py-2 px-4">
            Time{" "}
            <CustomTooltip
              text={"This field is required to be filled in minutes"}
              placement="right"
            >
              &nbsp;(min)
            </CustomTooltip>
          </span>

          <div className="d-flex align-items-center justify-content-between w-180p py-1 border-start px-4">
            <input
              className="form-control border-0"
              type="number"
              required
              placeholder="Time"
              value={timetoCook}
              onChange={(e) => {
                setTimetoCook(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="form-group d-flex col-sm-12 mt-2">
        <div className="col-sm-4">
          <label className="form-label fs-6 mt-2 text-secondary">
            Average Cost of production {reuiredField}:
          </label>
        </div>
        <div className="col-sm-8 d-flex align-items-center border rounded-5 w-280p text-dark ">
          <span className="d-flex align-items-center justify-content-center py-2 px-4">
            INR ₹
          </span>

          <div className="d-flex align-items-center justify-content-between w-180p py-1 border-start px-3">
            <input
              className="form-control border-0"
              type="number"
              required
              placeholder="Price"
              value={costPrice}
              onChange={(e) => {
                setcostPrice(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-group d-flex col-sm-12 mt-2">
        <div className="col-sm-4">
          <label className="form-label fs-6 mt-2 text-secondary">
            Selling Price {reuiredField}:
          </label>
        </div>
        <div className="col-sm-8 d-flex align-items-center border rounded-5 w-280p text-dark ">
          <span className="d-flex align-items-center justify-content-center py-2 px-4">
            INR ₹
          </span>

          <div className="d-flex align-items-center justify-content-between w-180p py-1 border-start px-4">
            <input
              className="form-control border-0"
              type="number"
              required
              placeholder="Price"
              value={sellingPrice}
              onChange={(e) => {
                setsellingPrice(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className="form-group d-flex col-sm-12 mt-2">
        <div className="col-sm-5">
          <label className="form-label fs-5 mt-2 text-secondary">
            Gst (%) :
          </label>
        </div>
        <div className="col-sm-7 d-flex align-items-center border rounded-5 w-280p text-dark ">
          <span className="d-flex align-items-center justify-content-center py-2 px-4">
            INR ₹
          </span>

          <div className="d-flex align-items-center justify-content-between w-180p py-2 border-start px-4">
            <input
              className="form-control border-0"
              type="number"
              required
              placeholder="Price"
              
            />
          </div>
        </div>
      </div> */}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      <div className="modal-footer align-items-center  justify-content-between mt-2">
        <span
          onClick={selectSetup2}
          className="d-flex align-items-center gap-1 text-dark pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-double-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          <span>Previous</span>
        </span>
        <button
          onClick={() => {
            if (isEdit) {
              handleEditItems();
            } else {
              handleAddItems();
            }
          }}
          className="btn btn-primary"
          disabled={!completeThirdStep}
        >
          {loader ? <ApiLoader /> : isEdit ? "Edit" : "Add"}
        </button>
      </div>
    </div>
  );
}

export default AddItemStepThree;
