import React, { useState } from "react";
import { LeadRelatedEndPoints } from "../../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  handleUploadInDirectAwsAttachment,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";

const ContactEmailModal = ({
  setEmailDetailsModal,
  contactDetails,
  id,
  locationData,
  handleGetLogs,
  handleGetContactById
}) => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [receipient, setReceipient] = useState(contactDetails?.email || "");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [attachments, setAttachments] = useState([]);

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setReceipient("");
    setSubject("");
    setEmailDetailsModal(false);
  };

  const handleSendEmail = async () => {
    let isError = false;
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    let uploaded;
    if (attachments?.length) {
      try {
        for (let i = 0; i < attachments.length; i++) {
          uploaded = await handleUploadInDirectAwsAttachment(attachments[i]);
          if (uploaded?.Location) {
            temp.push({ url: uploaded.Location, name: attachments[i].name });
          }
        }
      } catch (error) {
        isError = true;
        setloader(false);
        setErrorMessage("Attachment uploading failed, try again later.");
      }
    }
    if (!isError) {
      const data = {
        emailBody: description,
        recipient: contactDetails?.email,
        name: contactDetails?.name,
        contactEmail: user?.email,
        contactPhone: user?.phone,
        subject,
        attachment: temp,
        referenceId: id,
        referenceKey: "Contact",
        businessId: getCurrentWorkspaceId(),
        createdBy: user?._id,
      };
      const res = await ApiCall(
        "post",
        LeadRelatedEndPoints.sendEmailToLead,
        data
      );
      if (res?.success) {
        toast.success("Send email successfully!");
        handleReset();
        handleGetContactById();
        if (locationData?.contactDetails) {
          handleGetLogs();
        }
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!attachments.find((att) => att.name === file.name)) {
      setAttachments((prevAttachments) => [...prevAttachments, file]);
    }
  };

  const handleRemoveAttachment = (index) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };
  return (
    <>
      <div className="details-modal w-700p">
        <div className="nicescroll-bar simple-scrollbar">
          <div className="d-flex justify-content-between">
            <span className="file-name">Send Email To Contact</span>
            <button
              onClick={() => handleReset()}
              type="button"
              className="info-close btn-close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="border-bottom mt-2"></div>
          <div className="h-80 overflow-auto">
            <div className="d-flex justify-content-between">
              <span className="mt-2 fs-5">To : {contactDetails?.email}</span>
            </div>
            <div className="border-bottom mt-2"></div>
            <div className="d-flex justify-content-start">
              <h5 className="mt-3 fs-6">Subject : </h5>
              <input
                type="text"
                className="mt-2 fs-6 w-85"
                required
                style={{ border: "none", outline: "none" }}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className="border-bottom mt-2"></div>
            <div
              className="mt-2 overflow-y-scroll"
              style={{ height: attachments?.length > 0 ? "25vh" : "45vh" }}
            >
              <textarea
                className="w-100"
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                  border: "none",
                  outline: "none",
                  height: attachments?.length > 0 ? "25vh" : "45vh",
                }}
              ></textarea>
            </div>
            <div className="d-flex mt-2 gap-2">
              {attachments.map((att, index) => (
                <div
                  key={index}
                  className="w-130p text-center shadow-hover p-1 border d-flex flex-column gap-1"
                >
                  <div className="w-100 h-70p d-flex align-items-center justify-content-center p-2 border">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="currentColor"
                      className="bi bi-file-earmark"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                    </svg>
                  </div>
                  <p className="text-dark fs-7 text-truncate mw-150p">
                    {att?.name}
                  </p>
                  <span className="btn btn-sm btn-rounded btn-icon btn-outline-danger">
                    <span
                      title="Delete File"
                      className="feather-icon"
                      onClick={() => handleRemoveAttachment(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                      </svg>
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>

          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
          <div className="border-top mt-2"></div>
          <div className="d-flex justify-content-between mt-4 gap-2 dropdown-footer mb-0">
            <input
              type="file"
              name="image"
              id="attachment"
              accept="/*"
              className="input-file justify-content-end"
              onChange={handleFileChange}
            />
            <label htmlFor={"attachment"} className="pointer text-center">
              <span className="btn btn-sm btn-outline-light">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="14"
                  height="14"
                  className="me-1"
                >
                  <path d="M14.8287 7.75737L9.1718 13.4142C8.78127 13.8047 8.78127 14.4379 9.1718 14.8284C9.56232 15.219 10.1955 15.219 10.586 14.8284L16.2429 9.17158C17.4144 8.00001 17.4144 6.10052 16.2429 4.92894C15.0713 3.75737 13.1718 3.75737 12.0002 4.92894L6.34337 10.5858C4.39075 12.5384 4.39075 15.7042 6.34337 17.6569C8.29599 19.6095 11.4618 19.6095 13.4144 17.6569L19.0713 12L20.4855 13.4142L14.8287 19.0711C12.095 21.8047 7.66283 21.8047 4.92916 19.0711C2.19549 16.3374 2.19549 11.9053 4.92916 9.17158L10.586 3.51473C12.5386 1.56211 15.7045 1.56211 17.6571 3.51473C19.6097 5.46735 19.6097 8.63317 17.6571 10.5858L12.0002 16.2427C10.8287 17.4142 8.92916 17.4142 7.75759 16.2427C6.58601 15.0711 6.58601 13.1716 7.75759 12L13.4144 6.34316L14.8287 7.75737Z"></path>
                </svg>{" "}
                Attachment
              </span>
            </label>

            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSendEmail}
              disabled={!description || !subject}
            >
              {loader ? <ApiLoader /> : "Send"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactEmailModal;
