import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import AllItems from "../../pages/AccountManagement/Items/AllItems";

const ItemRoutes = {
  path: "/account",
  element: <Navbar />,
  children: [
    {
      path: "allitems",
      element: <AllItems />,
    },
  ],
};

export default ItemRoutes;
