import React from "react";

function OrderSuccessModal({ setorderSuccess }) {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered mw-325p  order-confirm-modal"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box">
              <i className="material-icons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-check2 text-white mb-20"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                </svg>
              </i>
            </div>
            <h4 className="modal-title w-100">Awesome!</h4>
          </div>
          <div className="modal-body">
            <p className="text-center">Your Order Has Been Confirmed</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success btn-block"
              onClick={() => {
                setorderSuccess(false);
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccessModal;
