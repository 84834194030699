import React from "react";

function ActivityTimelineMoreInfoModal({
  setTimelineMoreInfo,
  timeLineDetails,
}) {
  const closeModal = () => {
    setTimelineMoreInfo(false);
  };
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered w-700p  order-confirm-modal"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={closeModal}>
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">
                {timeLineDetails?.moreInfo?.[0]?.attachmentType} Info
              </h5>
            </span>
            <div className="border-bottom mb-3"></div>
            <form>
              {timeLineDetails?.moreInfo?.[0]?.notesText?.[0]?.description && (
                <div className="row gx-3 px-3 w-100">
                  <div className="d-flex flex-column gap-1 font-3">
                    <span>
                      <strong>Note: &nbsp;</strong>

                      {
                        timeLineDetails?.moreInfo?.[0]?.notesText?.[0]
                          ?.description
                      }
                    </span>
                  </div>
                </div>
              )}
              {timeLineDetails?.moreInfo?.[0]?.locationAddress?.[0]
                ?.mapLink && (
                <div className="row gx-3 px-3 w-100">
                  <div className="d-flex flex-column gap-1 font-3">
                    <span>
                      {" "}
                      <strong>Map Link: &nbsp;</strong>
                      <a
                        target="_blank"
                        href={
                          timeLineDetails?.moreInfo?.[0]?.locationAddress?.[0]
                            ?.mapLink
                        }
                      >
                        {
                          timeLineDetails?.moreInfo?.[0]?.locationAddress?.[0]
                            ?.mapLink
                        }
                      </a>
                    </span>
                  </div>
                </div>
              )}
              {timeLineDetails?.moreInfo?.[0]?.locationAddress?.[0]
                ?.locationData && (
                <div className="row gx-3 px-3 w-100">
                  <div className="d-flex flex-column gap-1 font-3">
                    <span>
                      {" "}
                      <strong>Area Name: &nbsp;</strong>
                      {
                        timeLineDetails?.moreInfo?.[0]?.locationAddress?.[0]
                          ?.locationData?.areaName
                      }
                    </span>
                    <span>
                      {" "}
                      <strong>City: &nbsp;</strong>
                      {
                        timeLineDetails?.moreInfo?.[0]?.locationAddress?.[0]
                          ?.locationData?.city
                      }
                    </span>
                    <span>
                      {" "}
                      <strong>Flat House No: &nbsp;</strong>
                      {
                        timeLineDetails?.moreInfo?.[0]?.locationAddress?.[0]
                          ?.locationData?.flatHouseNo
                      }
                    </span>
                    <span>
                      {" "}
                      <strong>State: &nbsp;</strong>
                      {
                        timeLineDetails?.moreInfo?.[0]?.locationAddress?.[0]
                          ?.locationData?.state
                      }
                    </span>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityTimelineMoreInfoModal;
