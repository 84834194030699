import React, { useState } from "react";
import { toast } from "react-toastify";
import { startApiCall } from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { leaveRelatedEndPoints } from "../../../../api/Endpoints";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";

const TypeTable = ({
  allLeaveTypes,
  editLeaveType,
  setEditLeaveType,
  handleGetAllLeaveTypes,
  isModuleReadWriteAccessForLeaveType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  const handleDeleteLeaveType = async () => {
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      leaveTypeId: editLeaveType?._id,
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.deleteLeaveType,
      sendData
    );
    if (res?.success) {
      toast.success("Type deleted successfully");
      handleGetAllLeaveTypes();
      handleClose();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <>
      <div className="rounded-5 border overflow-hidden">
        <table
          id="datable_1"
          className="table table-bordered table-striped mb-0"
        >
          <thead>
            <tr className="text-center">
              <th scope="col">No.</th>
              <th scope="col">Leave types</th>
              <th scope="col">No. of days</th>
              {isModuleReadWriteAccessForLeaveType && (
                <th scope="col">Action</th>
              )}
            </tr>
          </thead>
          <tbody>
            {allLeaveTypes?.map((data, index) => {
              return (
                <tr className="text-center">
                  <td scope="row">{index + 1} .</td>
                  <td>{data.leaveName}</td>
                  <td>{data.allowedDays}</td>
                  {isModuleReadWriteAccessForLeaveType && (
                    <td>
                      <span className="feather-icon pointer">
                        {data?.leaveName === "Unpaid" ? (
                          "-"
                        ) : (
                          <i
                            data-bs-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="ri-more-fill"
                          ></i>
                        )}
                        <div className="dropdown-menu">
                          <div
                            className="dropdown-item d-flex align-items-center"
                            data-bs-toggle="modal"
                            data-bs-target="#add_leave_types"
                            onClick={() => {
                              setEditLeaveType(data);
                            }}
                          >
                            <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </span>
                            </span>
                            <p>Edit</p>
                          </div>
                          {/* <div
                            className="dropdown-item d-flex align-items-center"
                            onClick={() => {
                              setIsOpen(true);
                              setEditLeaveType(data);
                            }}
                          >
                            <span
                              className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                              </span>
                            </span>
                            <p>Delete</p>
                          </div> */}
                        </div>
                      </span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text="Are You Sure You Want To Delete This Leave type"
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleDeleteLeaveType}
      />
    </>
  );
};

export default TypeTable;
