import React, { useEffect, useState } from "react";
import {
  customTimeFormater,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { ShiftRelatedEndPoints } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

const AddShiftType = (props) => {
  const {
    handleGetAllShifts,
    editShiftDetails,
    seteditShiftDetails,
    isAddShift,
    setisAddShift,
    allShifts,
  } = props;

  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [shiftName, setShiftName] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [minutes, setminutes] = useState("");
  const [selectedHours, setSelectedHours] = useState("");
  const [isDefault, setisDefault] = useState(false);
  const [shiftDay, setshiftDay] = useState("sameday");

  useEffect(() => {
    if (editShiftDetails) {
      setShiftName(editShiftDetails?.shiftName);
      setStartTime(customTimeFormater(editShiftDetails?.startTime));
      setEndTime(customTimeFormater(editShiftDetails?.endTime));
      setisDefault(editShiftDetails?.isDefault);
      setshiftDay(editShiftDetails?.shiftEndDay);
    }
  }, [editShiftDetails]);

  const returnMainDate = async (timeString) => {
    const currentDate = new Date();
    const tomorrowDate = new Date(
      new Date(currentDate).setDate(currentDate.getDate() + 1)
    );
    const [hours, minutes] = timeString.split(":");
    currentDate.setHours(hours, minutes);
    tomorrowDate.setHours(hours, minutes);
    return currentDate;
  };
  const returnNextDate = async (timeString) => {
    const currentDate = new Date();
    const tomorrowDate = new Date(
      new Date(currentDate).setDate(currentDate.getDate() + 1)
    );
    const [hours, minutes] = timeString.split(":");
    tomorrowDate.setHours(hours, minutes);
    return tomorrowDate;
  };

  const returnCalculatedMinutes = async () => {
    const loginTime = await returnMainDate(startTime);
    const logoutTime =
      shiftDay === "sameday"
        ? await returnMainDate(endTime)
        : await returnNextDate(endTime);
    const duration = moment.duration(logoutTime - loginTime);
    const sessionMinutes = duration.asMinutes().toFixed(2);
    const sessionHours = duration.asHours().toFixed(2);
    setSelectedHours(sessionHours);
    setminutes(sessionMinutes);
    return sessionMinutes;
  };

  // const returnCalculatedMinutes = async () => {
  //   const loginTime = await returnMainDate(startTime);
  //   const logoutTime = await returnMainDate(endTime);
  //   const duration = moment.duration(logoutTime - loginTime);
  //   const sessionMinutes = duration.asMinutes().toFixed(2);
  //   const sessionHours = duration.asHours().toFixed(2);
  //   setSelectedHours(sessionHours);
  //   setminutes(sessionMinutes);
  //   return sessionMinutes;
  // };

  useEffect(() => {
    if (startTime && endTime) {
      returnCalculatedMinutes();
    }
  }, [startTime, endTime, shiftDay]);

  const handleReset = () => {
    setisAddShift(false);
    setErrorMessage("");
    setloader(false);
    setShiftName("");
    setStartTime("");
    setEndTime("");
    setSelectedHours("");
    setisDefault(false);
    seteditShiftDetails(null);
    const closeModalDom = document.getElementById("add_shift_type");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddShiftType = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      shiftName,
      startTime,
      endTime,
      totalHours: selectedHours,
      businessId: getCurrentWorkspaceId(),
      isDefault: allShifts?.length === 0 ? true : isDefault,
      shiftEndDay: shiftDay,
    };

    const res = await ApiCall(
      "post",
      ShiftRelatedEndPoints.addShiftTiming,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetAllShifts();
      toast.success("Shift Updated Successfully");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };
  const handleEditShiftType = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      shiftTimingId: editShiftDetails?._id,
      shiftName,
      startTime,
      endTime,
      totalHours: selectedHours,
      isDefault: isDefault,
    };
    const res = await ApiCall(
      "post",
      ShiftRelatedEndPoints.editShiftTiming,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetAllShifts();
      toast.success("Shift Updated Successfully");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_shift_type"
      className={`custom-modal modal fade add-new-contact ${
        isAddShift ? "show" : ""
      }`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: isAddShift ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{!editShiftDetails ? "Add Shift" : "Edit Shift"}</h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={
                !editShiftDetails ? handleAddShiftType : handleEditShiftType
              }
            >
              <div className="mh-600p nicescroll-bar simple-scrollbar">
                <div className="d-flex flex-column">
                  <div className="form-group">
                    <label className="form-label">Shift name</label>
                    <input
                      className="form-control"
                      type="text"
                      autoFocus
                      placeholder="Enter shift name"
                      required
                      onChange={(e) => {
                        setShiftName(e.target.value);
                      }}
                      value={shiftName}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="pointer"
                      type="checkbox"
                      placeholder="Enter shift name"
                      checked={isDefault || allShifts?.length === 0}
                      onChange={(e) => {
                        setisDefault(e.target.checked);
                      }}
                      disabled={allShifts?.length === 0}
                      value={isDefault}
                    />
                    &nbsp;
                    <span className="text-dark"> Set as default shift</span>
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Select start time and end time
                    </label>

                    <span className="d-flex flex-column gap-1">
                      <div className="d-flex align-items-center border rounded-5 col-sm-9 gap-2 text-dark">
                        <span className="d-flex align-items-center justify-content-center py-2 px-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-clock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>
                        </span>
                        <div className="d-flex align-items-center justify-content-between py-2  border-start border-end px-4">
                          <input
                            type="time"
                            name="starttime"
                            className="border-0"
                            required
                            disabled={editShiftDetails}
                            value={startTime}
                            onChange={(e) => {
                              setStartTime(e.target.value);
                            }}
                          />
                        </div>
                        <select name="Type" className="border-0" disabled>
                          <option value={"sameday"}>Same Day</option>
                          <option value={"nextday"}>Next day</option>
                        </select>
                      </div>
                      <div className="d-flex align-items-center border rounded-5 col-sm-9 gap-2 text-dark">
                        <span className="d-flex align-items-center justify-content-center py-2 px-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-clock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>
                        </span>
                        <div className="d-flex align-items-center justify-content-between py-2  border-start border-end px-4">
                          <input
                            type="time"
                            name="endtime"
                            className="border-0"
                            required
                            value={endTime}
                            disabled={!startTime || editShiftDetails}
                            onChange={(e) => {
                              setEndTime(e.target.value);
                            }}
                          />
                        </div>
                        <select
                          name="Type"
                          className="border-0"
                          onChange={(e) => {
                            setshiftDay(e.target.value);
                          }}
                          value={shiftDay}
                          disabled={!startTime || editShiftDetails}
                        >
                          <option value={"sameday"}>Same Day</option>
                          <option value={"nextday"}>Next day</option>
                        </select>
                      </div>
                    </span>
                  </div>
                  {selectedHours && (
                    <>
                      <div className="d-flex flex-column gap-1 mt-2">
                        <p className="fs-7">Total Hours</p>
                      </div>
                      <div className="d-flex align-items-center border rounded-5 w-200p text-dark mb-3">
                        <span className="d-flex align-items-center justify-content-center py-2 px-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-clock"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                          </svg>
                        </span>
                        <div
                          className={`d-flex align-items-center justify-content-between ${
                            selectedHours > 0 ? "w-140p" : "mnw-175p"
                          } py-2 border-start px-4 `}
                        >
                          <p>
                            {" "}
                            {selectedHours > 0 ? (
                              `${selectedHours} Hrs`
                            ) : (
                              <span className="text-danger">Invalid Time</span>
                            )}{" "}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={selectedHours < 0}
                  >
                    {loader ? <ApiLoader /> : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShiftType;
