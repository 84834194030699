import React from "react";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";
import { dateFormatter } from "../../../../global-functions/globalFunctions";

const AnalyticsHeader = ({
  handleRefresh,
  setFilterType,
  filterType,
  loader,
  loader2,
  loader3,
  loader4,
  setdateModal,
  selectEmployee,
  setselectEmployee,
  allEmployees,
  startDate,
  endDate,
  currentMonthStartDate,
  previousMonthStartDate,
  currentMonthEndDate,
  previousMonthEndDate,
  formattedweekStartDate,
  formattedlastweekStartDate,
  formattedweekEndDate,
  formattedlastweekEndDate,
  setassigneeModal,
  isModuleReadWriteAccessForAnalytics,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title link-dark">
          <h5 className="header-title-font mt-2">Overview</h5>
          <div className="d-flex align-items-center gap-1 p-3 text-dark justify-content-end">
            <span className="text-primary fw-semibold">
              {" "}
              {filterType === "thismonth"
                ? dateFormatter(currentMonthStartDate)
                : filterType === "lastmonth"
                ? dateFormatter(previousMonthStartDate)
                : filterType === "thisweek"
                ? dateFormatter(formattedweekStartDate)
                : filterType === "Date Range"
                ? dateFormatter(startDate)
                : dateFormatter(formattedlastweekStartDate)}
            </span>
            <span>to</span>
            <span className="text-primary fw-semibold">
              {filterType === "thismonth"
                ? dateFormatter(currentMonthEndDate)
                : filterType === "lastmonth"
                ? dateFormatter(previousMonthEndDate)
                : filterType === "thisweek"
                ? dateFormatter(formattedweekEndDate)
                : filterType === "Date Range"
                ? dateFormatter(endDate)
                : dateFormatter(formattedlastweekEndDate)}
            </span>
          </div>
          {filterType === "Date Range" && (
            <svg
              onClick={() => {
                setdateModal(true);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-pencil-square text-primary pointer"
              viewBox="0 0 16 16"
            >
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
              />
            </svg>
          )}
        </span>
      </div>

      <div className="todo-options-wrap">
        {isModuleReadWriteAccessForAnalytics && (
          <button
            className="btn btn-sm btn-outline-light ms-1 fs-9 me-3"
            onClick={() => {
              setassigneeModal(true);
            }}
          >
            Select Employee
          </button>
        )}

        {!loader && !loader2 && !loader3 && !loader4 && (
          <div className="todo-toolbar">
            <select
              className="form-select form-select-md "
              value={filterType}
              onChange={(e) => {
                if (e.target.value === "Date Range") {
                  setdateModal(true);
                  setFilterType(e.target.value);
                } else {
                  setFilterType(e.target.value);
                }
              }}
            >
              <option value="thismonth">This Month</option>
              <option value="lastmonth">Last Month</option>
              <option value="thisweek">This Week</option>
              <option value="lastweek">Last Week</option>
              <option value="Date Range">Date Range </option>
            </select>
          </div>
        )}

        {loader && loader2 && loader3 && loader4 && (
          <div className="w-140p border rounded p-2 loading-skeleton">
            <SkeletonConstant width="w-100" height="h-25p" />
          </div>
        )}
        <div className="v-separator d-lg-inline-block d-none"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={handleRefresh}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default AnalyticsHeader;
