import React from "react";
import {
  dateFormatter,
  getTimeFormatter,
} from "../../../../global-functions/globalFunctions";

function ShiftDetailsTable(props) {
  const {
    shiftsofMonth,
    setdayShiftData,
    setchangeShiftModal,
    isModuleReadWriteAccessShift,
  } = props;
  const today = new Date();

  return (
    <table id="datable_1" className="table table-bordered ">
      <thead>
        <tr className="text-center">
          <th>Date</th>
          <th>Current Shift</th>
          <th>Shift hours</th>
          <th>Working hours</th>
          {isModuleReadWriteAccessShift && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {shiftsofMonth?.map((data) => {
          var dayOfWeek = new Date(data?.date)?.getDay();
          var daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          var dayName = daysOfWeek[dayOfWeek];

          return (
            <tr
              className={`text-center ${
                dateFormatter(today) === dateFormatter(data?.date)
                  ? "text-primary fw-semibold"
                  : ""
              }`}
            >
              <td>
                {dayName}, {dateFormatter(data?.date)}
              </td>
              <td>{data?.shiftName}</td>
              <td>
                {getTimeFormatter(new Date(data?.startTime))} -{" "}
                {getTimeFormatter(new Date(data?.endTime))}
              </td>
              <td>{data?.totalHours} Hrs</td>
              {isModuleReadWriteAccessShift && (
                <td className="text-primary ">
                  {new Date(data?.date) > today ? (
                    <span
                      className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2 pointer`}
                      onClick={() => {
                        setdayShiftData(data);
                        setchangeShiftModal(true);
                      }}
                    >
                      <span className="text-primary"> Change Shift &nbsp;</span>
                    </span>
                  ) : (
                    "-"
                  )}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ShiftDetailsTable;
