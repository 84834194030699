import React, { useState } from "react";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { PollRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";

const AddVote = ({ poll, handleGetPolls, user }) => {
  const [checkedOption, setcheckedOption] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const endApiCall = () => {
    setcheckedOption(null);
    seterrorMessage("");
    setLoader(false);
    const closeModalDom = document.getElementById("add_new_vote");
    if (closeModalDom) {
      closeModalDom.click();
    }
  };

  const handleAddVote = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      pollId: poll?._id,
      voterId: user?._id,
      pollOptionId: checkedOption?._id,
    };
    const res = await ApiCall("post", PollRelatedEndPoints.addVote, payload);
    if (res?.success) {
      toast.success("Your vote added successfully.");
      handleGetPolls();
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };
  return (
    <div
      id="add_new_vote"
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={endApiCall}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-1 fw-300">Add Vote</h5>

            <form onSubmit={handleAddVote}>
              <div className="border-top p-3 d-flex flex-column gap-3">
                <div className="d-flex justify-content-end">
                  <p className="fs-7">
                    Published on : {dateAndTimeFormatter(poll?.createdAt)}
                  </p>
                </div>
                <p className="text-dark fw-semibold">{poll?.title}</p>
                {poll?.options?.map((opt) => {
                  return (
                    <div className="d-flex align-items-center gap-2">
                      <input
                        className="pointer"
                        type="radio"
                        checked={checkedOption === opt}
                        onClick={() => {
                          setcheckedOption(opt);
                        }}
                      />
                      <p className="text-dark">{opt?.name}</p>
                      <div
                        class="progress w-60"
                        style={{ backgroundColor: "#c8c8c8" }}
                      >
                        <div
                          class="progress-bar progress-bar-md bg-success"
                          role="progressbar"
                          aria-valuenow="90"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${opt?.percentage}%` }}
                        ></div>
                      </div>
                      <p className="text-dark">{opt?.percentage}%</p>
                    </div>
                  );
                })}

                <p className="text-end">
                  Total Votes : {poll?.votedBy?.length}
                </p>
                {checkedOption && (
                  <div
                    className="alert alert-success text-primary fs-7"
                    role="alert"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="rgb(36 202 38)"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    &nbsp; Your Option: <strong>{checkedOption?.name}</strong>
                  </div>
                )}
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  disabled={loader}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={endApiCall}
                >
                  Cancel
                </button>
                <button
                  disabled={loader || !checkedOption}
                  type="submit"
                  className="btn btn-primary"
                >
                  {loader ? <ApiLoader /> : "Submit Vote"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVote;
