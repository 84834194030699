import React, { useState } from "react";
import {
  getName,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { ItemRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import ItemDetails from "./ItemDetails";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const ItemsTable = ({
  allItems,
  setEditItemDetails,
  editItemDetails,
  handleGetAllItems,
  page,
  pageLimit,
  showImageInput,
  isModuleReadWriteAccessItems,
}) => {
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openItemDetails, setOpenItemDetails] = useState(false);
  const [itemDetails, setItemDetails] = useState([]);

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  // DELETE ITEMS FUNCTION API CALL
  const handleDeleteItem = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      itemId: editItemDetails,
    };
    const res = await ApiCall("post", ItemRelatedEndPoints.deleteItem, data);
    if (res?.success) {
      toast.success("Item deleted successfully!");
      handleGetAllItems();
      handleClose();
    } else {
      setloader(false);
      setErrorMessage("");
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th style={{ textAlign: "left" }}>Name</th>
              <th>Type</th>
              <th>Item Code</th>
              <th>Created By</th>
              <th>Cost Price</th>
              <th>Selling Price</th>
              <th>Unit</th>
              {isModuleReadWriteAccessItems && <th scope="col">Action</th>}
            </tr>
          </thead>
          <tbody>
            {allItems?.map((item, index) => {
              return (
                <tr className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td style={{ textAlign: "left" }}>
                    <span
                      className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                      style={{ width: "300px" }}
                      onClick={() => {
                        setItemDetails(item);
                        setOpenItemDetails(!openItemDetails);
                      }}
                    >
                      {item?.itemName}
                      {showImageInput && (
                        <>
                          {item?.images?.[0] && (
                            <img
                              className="ms-2 rounded"
                              width="25"
                              height="25"
                              src={item?.images?.[0]}
                              alt="img"
                              style={{ border: "1px solid #333333" }}
                            />
                          )}
                        </>
                      )}
                    </span>
                  </td>
                  <td>
                    <span className="text-capitalize">
                      {item?.itemType === "good" ? "Goods" : "Services"}
                    </span>
                  </td>
                  <td>{item?.itemCode}</td>
                  <td>
                    {" "}
                    {item?.createdBy ? (
                      <>
                        <Avatar
                          name={item?.createdBy?.name}
                          count={1}
                          image={item?.createdBy?.image}
                          size="xxs"
                          color="primary"
                        />
                        {getName(item?.createdBy?.name, user?._id)}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{item?.costPrice}</td>
                  <td>{item?.salePrice}</td>
                  <td>{item?.unit}</td>
                  {isModuleReadWriteAccessItems && (
                    <td>
                      <span className="feather-icon pointer">
                        <i
                          data-bs-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="ri-more-fill"
                        ></i>
                        <div className="dropdown-menu">
                          <div
                            className="dropdown-item d-flex align-items-center"
                            data-bs-toggle="modal"
                            data-bs-target="#add_item"
                            onClick={() => {
                              setEditItemDetails(item);
                            }}
                          >
                            <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              </span>
                            </span>
                            <p>Edit</p>
                          </div>
                          <div
                            className="dropdown-item d-flex align-items-center"
                            onClick={() => {
                              setIsOpen(true);
                              setEditItemDetails(item?._id);
                            }}
                          >
                            <span
                              className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                            >
                              <span className="btn-icon-wrap">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                </svg>
                              </span>
                            </span>
                            <p>Delete</p>
                          </div>
                        </div>
                      </span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {openItemDetails && (
        <ItemDetails
          itemDetails={itemDetails}
          setOpenItemDetails={setOpenItemDetails}
          showImageInput={showImageInput}
        />
      )}
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text="Are You Sure You Want To Delete This Item"
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleDeleteItem}
      />
    </>
  );
};

export default ItemsTable;
