import React, { useEffect, useState } from "react";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceTable from "./InvoiceTable";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { OrderRelatedEndPoints } from "../../../../api/Endpoints";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import NoData from "../../../../components/NoData/NoData";
import CustomDateFilterStock from "../../ManageStock/CustomDateFilterStock";
import PreviousTransactionInvoice from "./PreviousTransactionInvoice";

const AllInvoices = () => {
  const [loader, setloader] = useState(false);
  const [allInvoice, setAllInvoice] = useState([]);
  const [search, setSearch] = useState("");
  const [selectStatus, setSelectStatus] = useState("");
  const [invoiceData, setInvoiceData] = useState("");

  const [previousTransaction, setpreviousTransaction] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allTransaction, setallTransaction] = useState([]);

  // FILTER
  const currentDate = new Date();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(currentDate.getDate() - 1);
  const [dateType, setdateType] = useState("Today");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dateModal, setdateModal] = useState(false);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(50);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllInvoice = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        orderStatus: selectStatus,
        startDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Yesterday"
            ? reversesDateFormatter(yesterdayDate)
            : dateType === "Date Range"
            ? startDate
            : "",
        endDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Yesterday"
            ? reversesDateFormatter(yesterdayDate)
            : dateType === "Date Range"
            ? endDate
            : "",
      },
    };
    const res = await ApiCall(
      "post",
      OrderRelatedEndPoints.getOrderInvoice,
      data
    );
    if (res?.success) {
      setAllInvoice(res?.invoice?.data);
      setcount(res?.invoice?.totalCounts);
      settotalPages(res?.invoice?.totalPages);
      setdateModal(false);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && dateType !== "Date Range") {
      handleGetAllInvoice();
    } else if (
      getCurrentWorkspaceId() &&
      dateType === "Date Range" &&
      startDate &&
      endDate
    ) {
      handleGetAllInvoice();
    }
  }, [page, pageLimit, search, dateType, selectStatus]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleResetFilters = () => {
    setstartDate("");
    setendDate("");
    setdateModal(false);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <InvoiceHeader
            handleGetAllInvoice={handleGetAllInvoice}
            search={search}
            handleSearch={handleSearch}
            selectStatus={selectStatus}
            setSelectStatus={setSelectStatus}
            dateType={dateType}
            setdateType={setdateType}
            setdateModal={setdateModal}
            handleResetFilters={handleResetFilters}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && allInvoice?.length > 0 && (
                <InvoiceTable
                  allInvoice={allInvoice}
                  page={page}
                  pageLimit={pageLimit}
                  setpreviousTransaction={setpreviousTransaction}
                  setInvoiceData={setInvoiceData}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allInvoice?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allInvoice?.length}
                  align={"end"}
                />
              )}
              {!loader && allInvoice?.length === 0 && (
                <NoData title="No Invoice found" />
              )}
            </div>
          </div>
        </div>
        <CustomDateFilterStock
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader}
          handleResetFilters={handleResetFilters}
          dateModal={dateModal}
          handleGetAllStock={handleGetAllInvoice}
          dateType={dateType}
          setdateType={setdateType}
          setdateModal={setdateModal}
        />
        {previousTransaction && (
          <PreviousTransactionInvoice
            setpreviousTransaction={setpreviousTransaction}
            allTransaction={allTransaction}
            loader={loader2}
            setallTransaction={setallTransaction}
            setloader2={setloader2}
            invoiceData={invoiceData}
            allInvoice={allInvoice}
            setInvoiceData={setInvoiceData}
            isInvoice={true}
          />
        )}
      </div>
    </div>
  );
};

export default AllInvoices;
