import React from "react";

export const Priority = (props) => {
  const { handleChangePriority, selectedTaskPriority } = props;
  const taskPriority = [
    {
      name: "All Priority",
      value: "",
      color: "#c8c8c8",
    },
    {
      name: "No Priority",
      value: "NO_PRIORITY",
      color: "#585656",
    },
    {
      name: "Low",
      value: "LOW",
      color: "#00C172",
    },
    {
      name: "Medium",
      value: "MEDIUM",
      color: "#FFB156",
    },
    {
      name: "High",
      value: "HIGH",
      color: "#FB1010",
    },
  ];

  return (
    <div className="selectable-dropdown">
      <div className="dropdown selectable-dropdown">
        <span
          style={{ border: `1px solid ${selectedTaskPriority?.color}` }}
          className={`me-2 fs-7 fw-medium text-dark text-capitalize rounded p-2`}
          aria-expanded="false"
          type="button"
          data-bs-toggle="dropdown"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            className="bi bi-flag-fill"
            fill={selectedTaskPriority?.color}
            viewBox="0 0 16 16"
          >
            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
          </svg>
          &nbsp;
          <span className="dropdown-text"> {selectedTaskPriority?.name}</span>
        </span>
        <div
          role="menu"
          className="dropdown-menu"
          style={{ maxHeight: "40vh", overflow: "auto" }}
        >
          {taskPriority?.map((pr, index) => (
            <div
              onClick={() => {
                handleChangePriority(pr);
              }}
              className="dropdown-item text-capitalize"
              key={index}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                className="bi bi-flag-fill"
                fill={pr?.color}
                viewBox="0 0 16 16"
              >
                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
              </svg>
              &nbsp;&nbsp;
              {pr?.name}&nbsp;&nbsp;
              {pr?.name === selectedTaskPriority?.name && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#68a660"
                  className="bi bi-check2"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                </svg>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
