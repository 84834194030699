import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import CrmMeetings from "../../pages/CRM/CrmMeetings/CrmMeetings";

const CrmMeetingRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "allmeetings",
      element: <CrmMeetings />,
    },
  ],
};

export default CrmMeetingRoutes;
