import React, { useEffect, useRef, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../../api/ApiCall";
import { transactionRelatedEndPoints } from "../../../../api/Endpoints";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import PrintSalaryDetails from "./PrintSalaryDetails";

const SalaryDetailsContent = ({
  salaryDetails,
  setisEditSalary,
  id,
  formattedStartDate,
  formattedDate,
  handleGetSalaryDetails,
  locationData,
  selectedMonth,
  setInfoDetails,
  salaryInsightData,
  setInfoData,
  isModuleReadWriteAccessForSalary,
}) => {
  const user = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [settleAmount, setSettleAmount] = useState("");
  const [settlementType, setSettlementType] = useState("bank-transfer");
  const [transactionType, setTransactionType] = useState("salary");
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const componentRef = useRef();

  const totalDeductionAmount = salaryInsightData?.deduction
  ? salaryInsightData.deduction.reduce((sum, data) => sum + data?.amount, 0)
  : 0;

  const totalPayableAmount =
    totalDeductionAmount || salaryDetails?.deduction
      ? (
          salaryDetails?.payableSalary -
          totalDeductionAmount -
          salaryDetails?.deduction
        )?.toFixed(2)
      : salaryDetails?.payableSalary
      ? salaryDetails?.payableSalary?.toFixed(2)
      : 0;

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setSettleAmount("");
    setSettlementType("bank-transfer");
    setTransactionType("salary");
  };

  const allTrasactionType = [
    {
      name: "Salary",
      value: "salary",
    },
    // {
    //   name: "Advance",
    //   value: "advance",
    // },
    // {
    //   name: "Other",
    //   value: "other",
    // },
  ];

  const allSettlementType = [
    {
      name: "Bank Transfer",
      value: "bank-transfer",
    },
    {
      name: "Cash",
      value: "cash",
    },
    {
      name: "UPI",
      value: "upi",
    },
  ];

  // SETTLEMENT OF SALARY FUNCTION API CALL
  const handleSettleSalaryAmount = async () => {
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      StaffId: salaryDetails?.staffId,
      settlementType: settlementType,
      businessId: getCurrentWorkspaceId(),
      actionBy: user?._id,
      settlementAmmount: settleAmount,
      isPaid: true,
      employeeId: id,
      startDate: formattedStartDate,
      endDate: formattedDate,
      transactionType,
    };
    const res = await ApiCall(
      "post",
      transactionRelatedEndPoints.settleSalary,
      sendData
    );
    if (res?.success) {
      setIsOpen(false);
      handleReset();
      handleGetSalaryDetails();
      toast.success("Salary settlement successfully");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSettleAmount((totalPayableAmount - salaryDetails?.paid)?.toFixed(2));
    }
  }, [isOpen]);

  return (
    <div className="container">
      <div className="row w-100">
        <div className="col-lg-6 px-5 border-end">
          <div className="row mb-3">
            <div className="col-sm-6 px-2">
              <div className="border card py-3 text-center w-100">
                <p className="text-dark">Amount Paid</p>
                <p className="text-success">
                  INR{" "}
                  {salaryDetails?.paid ? salaryDetails?.paid?.toFixed(2) : 0}
                </p>
              </div>
            </div>
            <div className="col-sm-6 px-2">
              <div className="border card py-3 text-center w-100">
                <p className="text-dark">Amount Due</p>
                <p className="text-danger">
                  INR {totalPayableAmount ? (totalPayableAmount - salaryDetails?.paid)?.toFixed(2) : 0}
                </p>
              </div>
            </div>
          </div>
          <div className="card px-4 pt-3 pb-0">
            <div className="d-flex justify-content-between">
              <p className="text-dark text-center fw-semibold pb-3">
                Salary Breakdown
              </p>
            </div>
            <div className="border-bottom mb-3"></div>
            <table className="table">
              <tbody>
                <tr>
                  <td>Monthly Salary</td>
                  <td className="text-center border-start">
                    ₹{" "}
                    {salaryDetails?.monthlySalary
                      ? salaryDetails?.monthlySalary?.toFixed(2)
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td>Payable Salary</td>
                  <td className="text-center border-start">
                    ₹{" "}
                    {salaryDetails?.payableSalary
                      ? salaryDetails?.payableSalary?.toFixed(2)
                      : 0}
                    &nbsp;&nbsp;
                    {salaryDetails?.payableSalary !== 0 && (
                      <span
                        className="pointer"
                        onClick={() => {
                          setInfoDetails(true);
                          setInfoData({ type: "payable" });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="blue"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Attendance Deduction</td>
                  <td className="text-center border-start">
                    ₹{" "}
                    {salaryDetails?.deduction
                      ? salaryDetails?.deduction?.toFixed(2)
                      : 0}
                    &nbsp;&nbsp;
                    {salaryDetails?.deduction !== 0 && (
                      <span
                        className="pointer"
                        onClick={() => {
                          setInfoDetails(true);
                          setInfoData({ type: "deduction" });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="blue"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    Other Deduction
                    {isModuleReadWriteAccessForSalary && (
                      <>
                        {salaryDetails?.payableSalary !== 0 && (
                          <span
                            className="text-primary pointer ms-2"
                            onClick={() => {
                              setisEditSalary(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-plus-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                            </svg>
                          </span>
                        )}
                      </>
                    )}
                  </td>
                  <td className="text-center border-start">
                    ₹ {totalDeductionAmount ? totalDeductionAmount : 0}
                    {totalDeductionAmount !== 0 && (
                      <span
                        className="pointer ms-2"
                        onClick={() => {
                          setInfoDetails(true);
                          setInfoData({ ...salaryInsightData, type: "Hr" });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="blue"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                        </svg>
                      </span>
                    )}
                  </td>
                </tr>
                <tr className="text-dark">
                  <td>Total Payable Amount</td>
                  <td className="text-center border-start">
                    ₹ {totalPayableAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <div className="card border-blue w-220p pointer p-2 mt-2">
              <ReactToPrint
                trigger={() => (
                  <span className="text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                    &nbsp; Download salary slip
                  </span>
                )}
                content={() => componentRef.current}
              />
            </div>
            <div style={{ display: "none" }}>
              <PrintSalaryDetails
                ref={componentRef}
                salaryDetails={salaryDetails}
                locationData={locationData}
                selectedMonth={selectedMonth}
                totalDeductionAmount={totalDeductionAmount}
                totalAbsentAmount={salaryDetails?.deduction}
                totalPayableAmount={totalPayableAmount}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-5">
          {isModuleReadWriteAccessForSalary && (
            <div className="card px-5 py-3 position-relative">
              <div className="d-flex justify-content-between">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 31 31"
                    fill="none"
                  >
                    <path
                      d="M15.5 15.5C19.7802 15.5 23.25 12.0302 23.25 7.75C23.25 3.46979 19.7802 0 15.5 0C11.2198 0 7.75 3.46979 7.75 7.75C7.75 12.0302 11.2198 15.5 15.5 15.5ZM20.6667 7.75C20.6667 10.6035 18.3535 12.9167 15.5 12.9167C12.6465 12.9167 10.3333 10.6035 10.3333 7.75C10.3333 4.89653 12.6465 2.58333 15.5 2.58333C18.3535 2.58333 20.6667 4.89653 20.6667 7.75Z"
                      fill="#3862DD"
                    />
                    <path
                      d="M31 28.4167C31 31 28.4167 31 28.4167 31H2.58333C2.58333 31 0 31 0 28.4167C0 25.8333 2.58333 18.0833 15.5 18.0833C28.4167 18.0833 31 25.8333 31 28.4167ZM28.4166 28.4077C28.4129 27.7701 28.0194 25.8603 26.267 24.1079C24.582 22.4229 21.4135 20.6667 15.5 20.6667C9.58644 20.6667 6.41791 22.4229 4.73292 24.1079C2.98053 25.8603 2.58701 27.7701 2.58333 28.4077H28.4166Z"
                      fill="#3862DD"
                    />
                  </svg>
                  &nbsp;&nbsp;&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    className="mt-2"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.3125 6.5C0.3125 6.13756 0.606313 5.84375 0.96875 5.84375L16.4469 5.84375L12.3172 1.71404C12.0609 1.45776 12.0609 1.04224 12.3172 0.785961C12.5735 0.529678 12.989 0.529678 13.2453 0.785961L18.4953 6.03596C18.7516 6.29224 18.7516 6.70776 18.4953 6.96404L13.2453 12.214C12.989 12.4703 12.5735 12.4703 12.3172 12.214C12.0609 11.9578 12.0609 11.5422 12.3172 11.286L16.4469 7.15625L0.96875 7.15625C0.606313 7.15625 0.3125 6.86244 0.3125 6.5Z"
                      fill="black"
                    />
                  </svg>
                  &nbsp;&nbsp;&nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 31 31"
                    fill="none"
                  >
                    <path
                      d="M15.5 15.5C19.7802 15.5 23.25 12.0302 23.25 7.75C23.25 3.46979 19.7802 0 15.5 0C11.2198 0 7.75 3.46979 7.75 7.75C7.75 12.0302 11.2198 15.5 15.5 15.5ZM20.6667 7.75C20.6667 10.6035 18.3535 12.9167 15.5 12.9167C12.6465 12.9167 10.3333 10.6035 10.3333 7.75C10.3333 4.89653 12.6465 2.58333 15.5 2.58333C18.3535 2.58333 20.6667 4.89653 20.6667 7.75Z"
                      fill="#3862DD"
                    />
                    <path
                      d="M31 28.4167C31 31 28.4167 31 28.4167 31H2.58333C2.58333 31 0 31 0 28.4167C0 25.8333 2.58333 18.0833 15.5 18.0833C28.4167 18.0833 31 25.8333 31 28.4167ZM28.4166 28.4077C28.4129 27.7701 28.0194 25.8603 26.267 24.1079C24.582 22.4229 21.4135 20.6667 15.5 20.6667C9.58644 20.6667 6.41791 22.4229 4.73292 24.1079C2.98053 25.8603 2.58701 27.7701 2.58333 28.4077H28.4166Z"
                      fill="#3862DD"
                    />
                  </svg>
                </div>
                <button
                  className="btn btn-primary"
                  disabled={!salaryDetails?.payableSalary}
                  onClick={() => {
                    setIsOpen(!isOpen);
                    handleReset();
                  }}
                >
                  Settle Salary
                </button>
              </div>
              {isOpen && (
                <div
                  className="card position-absolute p-4 w-95"
                  style={{ zIndex: 999, top: "90%", right: "5%" }}
                >
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Settlement Type</label>
                      <select
                        className="form-select form-select-md p-2"
                        value={settlementType}
                        onChange={(e) => setSettlementType(e.target.value)}
                      >
                        {allSettlementType?.map((data) => {
                          return (
                            <option value={data?.value}>{data?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Transaction Type</label>
                      <select
                        className="form-select form-select-md p-2"
                        value={transactionType}
                        onChange={(e) => setTransactionType(e.target.value)}
                      >
                        {allTrasactionType?.map((data) => {
                          return (
                            <option value={data?.value}>{data?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="form-label">Settlement Amount</label>
                    <div className="d-flex align-items-center border rounded-5 w-250p h-45p text-dark">
                      <span className="d-flex align-items-center justify-content-center py-2 px-4">
                        INR
                      </span>
                      <div className="d-flex align-items-center justify-content-between w-180p py-2 border-start px-2">
                        <input
                          className="form-control border-0 py-1"
                          type="number"
                          required
                          value={settleAmount}
                          onChange={(e) => setSettleAmount(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-md mt-3"
                      type="submit"
                      disabled={!settleAmount}
                      onClick={() => handleSettleSalaryAmount()}
                    >
                      {loader ? <ApiLoader /> : "Settle"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="card px-4 pt-3 pb-0 mb-5">
            <p className="text-dark fw-semibold">Profile Information</p>
            <table className="table">
              <tbody>
                <tr>
                  <td>Payment Type</td>
                  <td className="text-center">-</td>
                  <td className="text-end text-capitalize">
                    {salaryDetails?.paymentType}
                  </td>
                </tr>
                <tr>
                  <td>Settlement date</td>
                  <td className="text-center">-</td>
                  <td className="text-end">
                    {salaryDetails?.settlementDate
                      ? `${salaryDetails?.settlementDate} of every month`
                      : "No Settlement date"}
                  </td>
                </tr>
                <tr>
                  <td>Wage type</td>
                  <td className="text-center">-</td>
                  <td
                    className="text-end"
                    style={{ textTransform: "capitalize" }}
                  >
                    {salaryDetails?.wageType === "30_days"
                      ? "30 days"
                      : salaryDetails?.wageType}
                  </td>
                </tr>
                <tr>
                  <td>Working hours</td>
                  <td className="text-center">-</td>
                  <td className="text-end">
                    {salaryDetails?.workingHours
                      ? `${salaryDetails?.workingHours} hours`
                      : 0}
                  </td>
                </tr>
                {/* <tr>
                  <td>Start time</td>
                  <td className="text-center">-</td>
                  <td className="text-end">
                    {dateAndTimeFormatter(
                      salaryDetails?.shiftTimings?.[0]?.shiftId?.startTime
                    ).match(/\d{2}:\d{2} [apAP][mM]/)}
                  </td>
                </tr>
                <tr>
                  <td>End time</td>
                  <td className="text-center">-</td>
                  <td className="text-end">
                    {dateAndTimeFormatter(
                      salaryDetails?.shiftTimings?.[0]?.shiftId?.endTime
                    ).match(/\d{2}:\d{2} [apAP][mM]/)}
                  </td>
                </tr> */}
                <tr>
                  <td>Weekly days off</td>
                  <td className="text-center">-</td>
                  <td className="text-end">
                    {salaryDetails?.weekDaysOff?.join(",")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryDetailsContent;
