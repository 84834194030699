import axios from "axios";
import { VERSION_URL } from "./base";
import { resolveErrorMessage } from "./service";
import { toast } from "react-toastify";

const API = axios.create({ baseURL: VERSION_URL });

const VersionApiCall = async (type, route, data) => {
  try {
    let response;
    const apiFunctions = {
      post: API.post,
      get: API.get,
      patch: API.patch,
      delete: API.delete,
    };
    response = await apiFunctions[type](route, data);
    var success = true;
    if (response?.data?.success) {
      return response?.data;
    }
  } catch (error) {
    success = false;
    toast.error(`Something went wrong, ${resolveErrorMessage(error)}`);
    return {
      success,
      error: resolveErrorMessage(error),
      status: error?.response?.status,
    };
  }
};

export default VersionApiCall;
