import React from "react";
import { Avatar } from "../../../../../components/Avatar/Avatar";
import { getName } from "../../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";

function UpdatedForModal({ timelineDetails, setupdatedForModal }) {
  const user = useSelector((state) => state.user);

  const closeModal = () => {
    setupdatedForModal(false);
  };
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered w-700p  order-confirm-modal"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={closeModal}>
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Updated For</h5>
            </span>
            <div className="border-bottom mb-3"></div>
            <form>
              <div className="row gx-3 px-3 w-100  mh-500p overflow-auto">
                <p className="font-4 text-capitalize mt-1 ">
                  {timelineDetails?.updatedFor?.map((item, index) => {
                    return (
                      <span className="text-dark mt-3  d-flex">
                        {index + 1}.&nbsp;
                        <Avatar
                          name={item?.name}
                          count={1}
                          image={item?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(item, user?._id)}
                        <br />
                      </span>
                    );
                  })}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatedForModal;
