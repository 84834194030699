import React from "react";

const ToDoHeader = ({
  search,
  setSearch,
  setPage,
  handleGetTask,
  handleGetTaskCount,
  taskFilters,
  setselectedFilterType,
  setisFilteredModal,
  taskStatus,
  selectedTaskStatus,
  taskStatusLoader,
  handleChangeStatus,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title link-dark">
          <h1>Todo list</h1>
          <div className="ms-3">
            <button
              className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
              data-bs-toggle="modal"
              data-bs-target="#add_todo_task"
            >
              Add Task
            </button>
          </div>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="selectable-dropdown">
          <div className="dropdown selectable-dropdown">
            <span
              style={{ border: `1px solid ${selectedTaskStatus?.color}` }}
              className={`me-2 fs-7 fw-medium  text-dark text-capitalize rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
              // onClick={handleGetAllTaskStatus}
            >
              <svg
                className="circle"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill={selectedTaskStatus?.color}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="15" height="15" fill={selectedTaskStatus?.color} />
              </svg>
              &nbsp; {selectedTaskStatus?.statusName}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              {taskStatusLoader ? (
                <span className="text-center">Loading...</span>
              ) : (
                <>
                  <div
                    onClick={() => {
                      handleChangeStatus({
                        statusName: "All Status",
                        color: "#c8c8c8",
                      });
                    }}
                    className="dropdown-item text-capitalize"
                  >
                    <svg
                      className="circle"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="#c8c8c8"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="15" height="15" />
                    </svg>
                    &nbsp;&nbsp; All Status&nbsp;
                    {selectedTaskStatus?.statusName === "All Status" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#68a660"
                        className="bi bi-check2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                      </svg>
                    )}
                  </div>
                  {taskStatus?.map((status, index) => (
                    <div
                      onClick={() => {
                        handleChangeStatus(status);
                      }}
                      className="dropdown-item text-capitalize"
                      key={index}
                    >
                      <svg
                        className="circle"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill={status?.color}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="15" height="15" fill={status?.color} />
                      </svg>
                      &nbsp;&nbsp;
                      {status?.statusName}&nbsp;&nbsp;
                      {status?.statusName ===
                        selectedTaskStatus?.statusName && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#68a660"
                          className="bi bi-check2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                        </svg>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="selectable-dropdown me-2">
          <div className="dropdown selectable-dropdown">
            <span
              className={`me-2 fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp; Filter
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              {taskFilters?.map((filters, index) => (
                <div className="d-flex justify-content-between">
                  <div
                    className="dropdown-item text-capitalize"
                    key={index}
                    value={filters}
                    onClick={() => {
                      setselectedFilterType(filters);
                      setisFilteredModal(true);
                    }}
                  >
                    {index + 1}. {filters}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="refresh"
          data-bs-original-title="Refresh"
          onClick={() => {
            handleGetTask();
            handleGetTaskCount();
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none me-2" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search task"
            onChange={(e) => {
              setPage(1);
              setSearch(e.target.value);
            }}
            value={search}
          />
        </form>
      </div>
    </header>
  );
};

export default ToDoHeader;
