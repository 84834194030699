import React from "react";
import {
  dateAndTimeFormatter,
} from "../../../global-functions/globalFunctions";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import AssigneEmployeeDetails from "../../../components/AssigneEmployeeDetails/AssigneEmployeeDetails";
import { Avatar } from "../../../components/Avatar/Avatar";

const ListDetails = ({ listTaskData, page, pageLimit, setuserInfoData }) => {
  return (
    <>
      <table id="datable_1" className="table nowrap w-100 mb-5">
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th className="w-25">Task Status</th>
            <th>Last Updated</th>
            <th>Permissions</th>
          </tr>
        </thead>
        <tbody>
          {listTaskData?.map((data, index) => {
            return (
              <tr key={index}>
                <td>{(page - 1) * pageLimit + (index + 1)}.</td>
                <td>
                  <div className="media align-items-center">
                    <div className="media-body">
                      <span
                        style={{ width: "200px" }}
                        className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                      >
                        {data?.list?.name}
                      </span>
                      <div className="fs-7">
                        <span className="table-link-text link-medium-em">
                          {data?.list?.isFoldered ? (
                            <>
                              <div className="avatar avatar-icon avatar-soft-blue avatar-xxs me-2">
                                <span className="initial-wrap">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="14"
                                    height="14"
                                  >
                                    <path d="M22 11V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V11H22ZM22 9H2V4C2 3.44772 2.44772 3 3 3H10.4142L12.4142 5H21C21.5523 5 22 5.44772 22 6V9Z"></path>
                                  </svg>
                                </span>
                              </div>
                              {data?.list?.folderId?.name}
                            </>
                          ) : (
                            <>
                              <div className="avatar avatar-icon avatar-soft-purple avatar-xxs me-2">
                                <span className="initial-wrap">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="14"
                                    height="14"
                                  >
                                    <path d="M12.6727 1.61162 20.7999 9H17.8267L12 3.70302 6 9.15757V19.0001H11V21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001 11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162ZM14 11H23V18H14V11ZM16 13V16H21V13H16ZM24 21H13V19H24V21Z"></path>
                                  </svg>
                                </span>
                              </div>
                              {data?.list?.spaceId?.name}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="progress-lb-wrap mb-2">
                    <div className="d-flex align-items-center">
                      <div
                        style={{ backgroundColor: "#c8c8c8" }}
                        className="progress progress-bar-rounded progress-bar-xs flex-1"
                      >
                        <div
                          className="progress-bar bg-green-dark-1"
                          role="progressbar"
                          aria-valuenow={Math.round(
                            (data?.completedTask /
                              (data?.completedTask + data?.openTask)) *
                              100
                          )}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{
                            width: `${
                              (data?.completedTask /
                                (data?.completedTask + data?.openTask)) *
                              100
                            }%`,
                          }}
                        ></div>
                      </div>
                      {data?.completedTask !== 0 || data?.openTask !== 0 ? (
                        <div className="fs-8 ms-3">
                          {Math.round(
                            (data?.completedTask /
                              (data?.completedTask + data?.openTask)) *
                              100
                          )}
                          %
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-1">
                    Completed : {data?.completedTask} &nbsp; Open :{" "}
                    {data?.openTask}
                  </div>
                </td>
                <td>{dateAndTimeFormatter(data?.list?.updatedAt)}</td>
                <td>
                  {!data?.list?.isPrivate && (
                    <span className="badge badge-md badge-pill badge-soft-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-globe"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                      </svg>
                      &nbsp; Public
                    </span>
                  )}
                  {data?.list?.isPrivate &&
                    data?.list?.privateListAccess?.length > 0 && (
                      <div className="avatar-group avatar-group-sm avatar-group-overlapped d-xl-flex d-none">
                        {data?.list?.privateListAccess
                          ?.slice(0, 3)
                          .map((lst) => {
                            return (
                              <CustomTooltip text={lst.name} placement="bottom">
                                <span
                                  data-bs-toggle="modal"
                                  data-bs-target="#user_information_modal"
                                  onClick={() => {
                                    setuserInfoData(lst);
                                  }}
                                >
                                  <Avatar
                                    name={lst?.name}
                                    count={1}
                                    image={lst?.image}
                                    size="xss"
                                    color="primary"
                                  />
                                </span>
                              </CustomTooltip>
                            );
                          })}
                        {data?.list?.privateListAccess?.length > 3 && (
                          <CustomTooltip text="view all" placement="bottom">
                            <div className="selectable-dropdown">
                              <div className="dropdown selectable-dropdown">
                                <span
                                  className="avatar avatar-xs avatar-soft-indigo avatar-rounded pointer"
                                  data-bs-toggle="dropdown"
                                >
                                  <span className="initial-wrap">
                                    +{data?.list?.privateListAccess?.length - 3}
                                  </span>
                                </span>
                                <AssigneEmployeeDetails
                                  taskDetails={data?.list?.privateListAccess}
                                />
                              </div>
                            </div>
                          </CustomTooltip>
                        )}
                      </div>
                    )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ListDetails;
