import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../../components/Loaders/ApiLoader/ApiLoader";
import {
  getAvatarClass,
  getCurrentWorkspaceId,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import ActivityAssignParticipant from "./ActivityMandatoryTask/ActivityAssignParticipant";
import CustomTooltip from "../../../../../components/CustomTooltip/CustomTooltip";
import { CrmParticipantsGroup } from "../../../../../api/Endpoints";
import ApiCall from "../../../../../api/ApiCall";
import SkeletonConstant from "../../../../../components/Constant/SkeletonConstant";
import { Avatar } from "../../../../../components/Avatar/Avatar";

const LeadStatusChangeModal = (props) => {
  const {
    onClose,
    text,
    loader,
    errorMessage,
    handleConfirm,
    statusId,
    leadDetails,
    setselectedEmpId,
    selectedEmpId,
    setDeadline,
    deadline,
    closingDate,
    setclosingDate,
    expectedRevenue,
    setexpectedRevenue,
    type,
    setallEmployees,
    allEmployees,
  } = props;
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [activityLoader, setactivityLoader] = useState(false);
  const [allGroupEmployees, setallGroupEmployees] = useState(true);
  const [loader2, setloader2] = useState(false);
  const [selectedAssignee, setselectedAssignee] = useState(false);

  useEffect(() => {
    setDeadline(statusId?.deadline);
  }, []);

  const handleGetGroupEmployees = async () => {
    startApiCall(null, setloader2);
    const allIds = leadDetails?.participantsGroupId?.map((item) => item._id);
    const data = {
      participantsGroupIds: allIds,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      CrmParticipantsGroup.getLeadGroupParticipant,
      data
    );
    if (res?.success) {
      setallEmployees(res?.result);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (leadDetails?.assignedTo?.length === 0 || selectedAssignee) {
      handleGetGroupEmployees();
    }
  }, [leadDetails, selectedAssignee]);

  useEffect(() => {
    if (leadDetails?.assignedTo?.length > 0) {
      setselectedEmpId(leadDetails?.assignedTo?.map((item) => item?._id));
      setselectedEmployees(leadDetails?.assignedTo?.map((item) => item));
    }
  }, [leadDetails]);

  useEffect(() => {
    if (type === "deal" && statusId?.initialStatusId?.name === "close won") {
      setclosingDate(reversesDateFormatter(leadDetails?.closingDate));
      setexpectedRevenue(leadDetails?.amount || 0);
    }
  }, [type, statusId]);

  useEffect(() => {
    if (allEmployees?.length > 0 && !selectedAssignee) {
      setselectedEmpId(allEmployees?.map((item) => item?._id));
      setselectedEmployees(allEmployees?.map((item) => item));
    }
  }, [allEmployees]);

  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="mb-4 text_center">Confirmation</h5>
              <form onSubmit={handleConfirm}>
                <div className="row gx-3">
                  <label className="form-label text_center font-3">
                    {text}
                  </label>
                </div>
                {type === "deal" &&
                  statusId?.initialStatusId?.name === "close won" && (
                    <div className="d-flex border-bottom col-12 gap-2">
                      <div className="form-group col-6">
                        <label className="form-label font-2">
                          Closing Date
                        </label>
                        <br />
                        <input
                          className="form-control"
                          type="date"
                          placeholder="Closing date"
                          required
                          value={closingDate}
                          onChange={(e) => setclosingDate(e.target.value)}
                        />
                      </div>

                      <div className="form-group col-6">
                        <label className="form-label font-2">
                          Expected Revenue
                        </label>
                        <br />
                        <input
                          className="form-control "
                          type="number"
                          required
                          placeholder="Expected Revenue"
                          value={expectedRevenue}
                          onChange={(e) => setexpectedRevenue(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                {statusId?.activities?.length > 0 && (
                  <>
                    {" "}
                    <div className="d-flex row border-bottom justify-content-center">
                      <span className="font-3 text-primary d-flex justify-content-center mt-2">
                        Add Deadline for these activities {reuiredField}
                      </span>

                      <div className="d-flex justify-content-center col-12 gap-2">
                        <div className="form-group">
                          <label className="form-label font-2">
                            Deadline in Hours
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Deadline Hours"
                            value={deadline}
                            required
                            onChange={(e) => setDeadline(e.target.value)}
                          />
                        </div>
                      </div>
                      <span className="font-2 fw-bold mb-2  d-flex justify-content-center ">
                        Total Deadline Hours : &nbsp;
                        {deadline} Hours
                      </span>
                    </div>
                    <div
                      className="col-12 d-flex justify-content-center"
                      style={{ marginTop: "20px" }}
                    >
                      {!selectedAssignee && (
                        <div className="d-flex row">
                          <span className="font-3 text-primary d-flex justify-content-center">
                            Default Assignees {reuiredField}{" "}
                          </span>

                          <span className="font-3 text-dark mt-4 d-flex justify-content-center">
                            <input
                              className="form-check-input success pointer"
                              type="checkbox"
                              onChange={() => {
                                setallGroupEmployees(!allGroupEmployees);
                              }}
                              checked={allGroupEmployees}
                              disabled
                            />
                            &nbsp; All participant in the group &nbsp;{" "}
                            <span
                              className="text-primary text-decoration-underline pointer"
                              onClick={() => {
                                if (allGroupEmployees) {
                                  setassigneeModal(true);
                                  setselectedAssignee(true);
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                />
                              </svg>
                            </span>
                          </span>
                          {!loader2 && (
                            <>
                              <div className="d-flex justify-content-center col-12">
                                {/* {allEmployees?.length > 0 ? (
                                  <div className="avatar-group avatar-group-md avatar-group-overlapped me-3 pointer mt-2">
                                    {allEmployees
                                      ?.slice(0, 4)
                                      ?.map((emp, index) => {
                                        let name = emp?.name;
                                        const nameInitial =
                                          name?.[0]?.toUpperCase();
                                        const avatarClass =
                                          getAvatarClass(nameInitial);

                                        return (
                                          <Avatar
                                            name={emp?.name}
                                            count={1}
                                            image={emp?.image}
                                            size="xxs"
                                            color="primary"
                                          />
                                        );
                                      })}
                                    {allEmployees?.length > 4 && (
                                      <div className="avatar avatar-md avatar-soft-indigo avatar-rounded">
                                        <span className="initial-wrap">
                                          +{allEmployees.length - 4}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ) : ( */}
                                <div className="avatar-group avatar-group-md avatar-group-overlapped me-3 pointer mt-2">
                                  {selectedEmployees
                                    ?.slice(0, 4)
                                    ?.map((emp, index) => {
                                      let name = emp?.name;
                                      const nameInitial =
                                        name?.[0]?.toUpperCase();
                                      const avatarClass =
                                        getAvatarClass(nameInitial);

                                      return (
                                        <Avatar
                                          name={emp?.name}
                                          count={1}
                                          image={emp?.image}
                                          size="xxs"
                                          color="primary"
                                        />
                                      );
                                    })}
                                  {selectedEmployees?.length > 4 && (
                                    <div className="avatar avatar-md avatar-soft-indigo avatar-rounded">
                                      <span className="initial-wrap">
                                        +{selectedEmployees?.length - 4}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                {/* )} */}
                              </div>
                            </>
                          )}
                          {loader2 && (
                            <div className="d-flex justify-content-center align-content-center align-items-center">
                              <SkeletonConstant
                                width="w-150p"
                                height="h-50p"
                                additional="rounded-10 ms-2 col-12"
                              />
                            </div>
                          )}
                        </div>
                      )}

                      <span className="mt-2">
                        {selectedAssignee && selectedEmployees?.length > 0 && (
                          <>
                            <span className="font-3 text-primary col-12">
                              Selected Assignees {reuiredField}{" "}
                              <span className="text-primary text-decoration-underline pointer">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                  onClick={() => {
                                    if (allGroupEmployees) {
                                      setassigneeModal(true);
                                    }
                                  }}
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                              </span>
                            </span>

                            <div className="d-flex justify-content-center col-12">
                              <div className="avatar-group avatar-group-md avatar-group-overlapped me-3 pointer mt-2">
                                {selectedEmployees?.slice(0, 4)?.map((emp) => {
                                  return (
                                    <Avatar
                                      name={emp?.name}
                                      count={1}
                                      image={emp?.image}
                                      size="xxs"
                                      color="primary"
                                    />
                                  );
                                })}
                                {selectedEmployees?.length > 4 && (
                                  <div className="avatar avatar-md avatar-soft-indigo avatar-rounded">
                                    <span className="initial-wrap">
                                      +{selectedEmployees.length - 4}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {!allGroupEmployees && (
                          <div
                            onClick={() => {
                              setassigneeModal(true);
                            }}
                            className="mt-4 pointer"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="36"
                              height="36"
                              fill="currentColor"
                              class="bi bi-plus-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                            </svg>
                          </div>
                        )}
                      </span>
                    </div>
                  </>
                )}

                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="d-flex justify-content-center mt-4 gap-3">
                  <button
                    type="button"
                    disabled={loader}
                    className="btn btn-rounded btn-secondary w-30"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={
                      loader ||
                      loader2 ||
                      (statusId?.activities?.length > 0 && !deadline)
                    }
                    className="btn btn-rounded btn-primary w-30"
                  >
                    {loader ? <ApiLoader /> : "Confirm"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {assigneeModal && (
        <ActivityAssignParticipant
          setassigneeModal={setassigneeModal}
          addParticipant={assigneeModal}
          selectedAssigneeId={selectedEmpId}
          setselectedAssigneeId={setselectedEmpId}
          loader2={activityLoader}
          errorMessage={errorMessage}
          allGroupMembers={statusId?.assignees}
          isSingle={true}
          leadDetails={leadDetails}
          setselectedEmployees={setselectedEmployees}
          selectedEmployees={selectedEmployees}
          type="status"
          allGroupEmployees={allGroupEmployees}
        />
      )}
    </>
  );
};

export default LeadStatusChangeModal;
