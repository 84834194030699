import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import TaskAnalysis from "../../pages/TaskAnalysis/TaskAnalysis";
import TaskAnalysisDetails from "../../pages/TaskAnalysis/TaskAnalysisDetails/TaskAnalysisDetails";

const TaskAnalysisRoutes = {
  path: "/task",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <TaskAnalysis />,
    },
    {
      path: "taskanalysis",
      element: <TaskAnalysis />,
    },
    {
      path: "taskanalysis/:id",
      element: <TaskAnalysisDetails />,
    },
  ],
};

export default TaskAnalysisRoutes;
