import React, { useEffect, useState } from "react";
import RegisterNewComplaint from "./RegisterNewComplaint";
import MyComplaintCard from "./MyComplaintCard";
import { useSelector } from "react-redux";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import * as endPoint from "../../../api/Endpoints";
import MyComplaintSkeleton from "./MyComplaintSkeleton";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import NoComplaint from "./NoComplaint";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import MyComplaintsHeader from "./MyComplaintsHeader";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

function MyComplaint() {
  const { allModuleAccess, user } = useSelector((state) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [complaints, setcomplaints] = useState([]);
  const [pageLimit, setpageLimit] = useState(50);
  const [page, setpage] = useState(1);
  const [count, setcount] = useState();
  const [searchKey, setsearchKey] = useState("");
  const [totalPages, settotalPages] = useState();
  const [totalCounts, settotalCounts] = useState();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [isToggled, setIsToggled] = useState("");
  const [anonymousData, setanonymousData] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [moduleType, setmoduleType] = useState("");
  const [locationData, setlocationData] = useState(null);

  const isModuleReadWriteAccessMyGrievance =
    allModuleAccess?.[PRIVATEROUTES.MY_GRIEVANCE];

  const handleClose = () => {
    setIsOpen(false);
    seterrorMessage("");
    setloader(false);
    handleGetAnonymous();
    setAllComplaints();
  };

  const handleToggle = () => {
    setIsOpen(true);
    if (anonymousData?.isAnonymous) {
      setIsToggled(false);
    } else {
      setIsToggled(true);
    }
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleTabChange = (status) => {
    setStatus(status);
  };

  const allTabs = [
    {
      name: "All",
      value: "",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-info">
          {count?.all}
        </span>
      ),
    },
    {
      name: "Open",
      value: "open",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-warning">
          {count?.open}
        </span>
      ),
    },
    {
      name: "Processing",
      value: "processing",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-primary">
          {count?.processing}
        </span>
      ),
    },
    {
      name: "Resolved",
      value: "resolved",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-success">
          {count?.resolved}
        </span>
      ),
    },
    {
      name: "Closed",
      value: "closed",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-success">
          {count?.closed}
        </span>
      ),
    },
    {
      name: "Cancel",
      value: "cancelled",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-success">
          {" "}
          {count?.cancelled}
        </span>
      ),
    },
  ];

  const setAllComplaints = async () => {
    startApiCall(null, setloader);
    setcomplaints([]);
    const sendData = {
      page: page,
      limit: pageLimit,
      filters: {
        raisedBy: user?._id,
        businessId: getCurrentWorkspaceId(),
        status: status,
        category: location?.state ? location?.state?.moduleType : moduleType,
        complaintOn: location?.state ? location?.state?.moduleId : "",
      },
      searchKey: searchKey,
    };
    const res = await ApiCall(
      "post",
      endPoint.complaintEndPoints.getComplaints,
      sendData
    );
    if (res?.success) {
      setcount(res?.data?.counts);
      setcomplaints(res?.data?.data);
      settotalPages(res?.data?.totalPages);
      settotalCounts(res?.data?.totalCounts);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  const handleGetAnonymous = async () => {
    startApiCall(null, setloader2);
    setcomplaints([]);
    const sendData = {
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
      page: 1,
      limit: 50,
    };
    const res = await ApiCall(
      "post",
      endPoint.complaintEndPoints.getAnonymous,
      sendData
    );
    if (res?.success) {
      setanonymousData(res?.configuration?.data?.[0]);
      setloader2(false);
    } else {
      setloader(false);
    }
  };

  const handelEditAnonymous = async () => {
    startApiCall(null, setloader);
    setcomplaints([]);
    const sendData = {
      configurationId: anonymousData?._id,
      isAnonymous: isToggled,
    };
    const res = await ApiCall(
      "post",
      endPoint.complaintEndPoints.editAnonymous,
      sendData
    );
    if (res?.success) {
      toast.success("Anonymous status changes successfully");
      setAllComplaints();
      handleClose();
    } else {
      setloader(false);
    }
  };
  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      setAllComplaints();
      closeAllModals();
    }
  }, [searchKey, page, pageLimit, status, moduleType]);

  useEffect(() => {
    handleGetAnonymous();
    setlocationData(location?.state);
    setmoduleType(location?.state ? location?.state?.moduleType : "");
  }, []);

  useEffect(() => {
    setIsToggled(anonymousData?.isAnonymous === true ? true : false);
  }, [anonymousData]);

  const handleChangeType = (type) => {
    setlocationData(null);
    navigate(location.pathname, { state: null });
    setmoduleType(type);
    setpage(1);
  };

  return (
    <>
      <div
        className="todoapp-wrap"
        style={{ pointerEvents: loader ? "none" : "" }}
      >
        <div class="todoapp-content">
          <div class="todoapp-detail-wrap">
            <MyComplaintsHeader
              setpage={setpage}
              setsearchKey={setsearchKey}
              isToggled={isToggled}
              isSearchOpen={isSearchOpen}
              toggleSearch={toggleSearch}
              handleToggle={handleToggle}
              moduleType={moduleType}
              locationData={locationData}
              handleChangeType={handleChangeType}
              searchKey={searchKey}
              isModuleReadWriteAccessMyGrievance={
                isModuleReadWriteAccessMyGrievance
              }
            />
            <CustomTab
              allTabs={allTabs}
              currentTab={status}
              handleTabChange={handleTabChange}
              isShowCount={true}
            />
            {!loader && !complaints?.length && <NoComplaint />}
            <div className="todo-body">
              <div
                data-simplebar
                className="nicescroll-bar max-height-300 simple-scrollbar"
              >
                {loader ? (
                  <MyComplaintSkeleton />
                ) : (
                  complaints?.map((complaint, index) => {
                    return (
                      <MyComplaintCard
                        complaint={complaint}
                        setAllComplaints={setAllComplaints}
                        index={index}
                        page={page}
                        pageLimit={pageLimit}
                        isModuleReadWriteAccessMyGrievance={
                          isModuleReadWriteAccessMyGrievance
                        }
                      />
                    );
                  })
                )}
              </div>
            </div>
          </div>
          {!loader && complaints?.length > 0 && (
            <FooterPagination
              setpageLimit={setpageLimit}
              pageLimit={pageLimit}
              setPage={setpage}
              page={page}
              totalPages={totalPages}
              count={totalCounts}
              dataLength={complaints?.length}
              align={"end"}
            />
          )}
        </div>
        {isOpen && (
          <ConfirmModal
            isOpen={isOpen}
            onClose={handleClose}
            text={
              isToggled
                ? `Are You Sure You Want To Switch OFF  Anonymous`
                : `Are You Sure You Want To Switch ON Anonymous`
            }
            loader={loader}
            errorMessage={errorMessage}
            handleConfirm={handelEditAnonymous}
          />
        )}

        <RegisterNewComplaint
          setAllComplaints={setAllComplaints}
          anonymousData={anonymousData}
        />
      </div>
    </>
  );
}

export default MyComplaint;
