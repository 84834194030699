import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import CreateBills from "../../pages/AccountManagement/Bills/CreateBills/CreateBills";
import AllBills from "../../pages/AccountManagement/Bills/AllBills";
import BillDetails from "../../pages/AccountManagement/Bills/BillDetails/BillDetails";

const BillsRoutes = {
  path: "/account",
  element: <Navbar />,
  children: [
    {
      path: "allbills",
      element: <AllBills />,
    },
    {
      path: "createbills",
      element: <CreateBills />,
    },
    {
      path: "billdetails/:id",
      element: <BillDetails />,
    },
  ],
};

export default BillsRoutes;
