import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { LeadRelatedEndPoints } from "../../../../api/Endpoints";
import { useSelector } from "react-redux";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";

const AddLeadSource = ({
  handleGetAllLeadSource,
  editLeadSource,
  setEditLeadSource,
}) => {
  const user = useSelector((state) => state.user);

  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [leadSourceName, setleadSourceName] = useState("");
  const [leadSourceDescription, setleadSourceDescription] = useState("");

  const handleReset = () => {
    setloader(false);
    setleadSourceName("");
    setleadSourceDescription("");
    setEditLeadSource("");
    seterrorMessage("");
  };

  // *** ADD LEAD SOURCE ***//
  const handleAddLeadSource = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      name: leadSourceName,
      description: leadSourceDescription,
      createdBy: user?._id,
      isDefault: false,
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.addLeadSource, data);
    if (res?.success) {
      toast.success("Lead Source Added successfully");
      handleReset();
      handleGetAllLeadSource();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  // *** EDIT LEAD SOURCE ***//
  const handleEditLeadSource = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      name: leadSourceName,
      description: leadSourceDescription,
      actionBy: user?._id,
      sourceId: editLeadSource?._id,
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.editLeadSource,
      data
    );
    if (res?.success) {
      toast.success("Lead Source Edited successfully");
      handleReset();
      handleGetAllLeadSource();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };
  useEffect(() => {
    if (editLeadSource !== "") {
      setleadSourceName(editLeadSource?.name);
      setleadSourceDescription(editLeadSource?.description);
    }
  }, [editLeadSource]);

  return (
    <div
      id="create_new_lead_source"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editLeadSource === "" ? "Create" : "Edit"} Lead Source
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editLeadSource === "") {
                  handleAddLeadSource(e);
                } else {
                  handleEditLeadSource(e);
                }
              }}
              // onSubmit={(e) => handleAddLeadSource(e)}
            >
              <div className="form-group">
                <label>Source</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Source type.."
                  value={leadSourceName}
                  onChange={(e) => {
                    setleadSourceName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  class="form-control"
                  placeholder="Write about lead source.."
                  rows="3"
                  value={leadSourceDescription}
                  onChange={(e) => {
                    setleadSourceDescription(e.target.value);
                  }}
                ></textarea>
              </div>

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  {loader ? (
                    <ApiLoader />
                  ) : editLeadSource === "" ? (
                    "Create"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLeadSource;
