import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { restaurantTableRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";

const AddTable = ({
  handleGetAllTable,
  setselectedTable,
  selectedTable,
  setisAddTable,
}) => {
  const [tableDetails, settableDetails] = useState({
    name: "",
    number: "",
    location: "",
    assignedPerson: "",
    sittingCap: "",
    floor: "",
  });
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (selectedTable) {
      settableDetails({
        name: selectedTable?.tableName,
        number: selectedTable?.tableNumber,
        location: selectedTable?.location,
        assignedPerson: selectedTable?.assignedPerson,
        sittingCap: selectedTable?.sittingCapacity,
        floor: selectedTable?.floor,
      });
    }
  }, [selectedTable]);

  const handleReset = () => {
    setErrorMessage("");
    settableDetails({
      name: "",
      number: "",
      location: "",
      assignedPerson: "",
      sittingCap: "",
      floor: "",
    });
    setloader(false);
    setselectedTable(null);
    setisAddTable(false);
  };

  // GET ADD TABLE  API CALL
  const handleAddTable = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      location: tableDetails?.location,
      floor: tableDetails?.floor,
      tableNumber: tableDetails?.number,
      tableName: tableDetails?.name,
      sittingCapacity: tableDetails?.sittingCap,
      assignedPerson: tableDetails?.assignedPerson,
    };
    const res = await ApiCall(
      "post",
      restaurantTableRelatedEndPoints.addTable,
      data
    );
    if (res?.success) {
      handleGetAllTable();
      toast.success("Add Table Successfully");
      handleReset();
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  // GET EDIT TABLE  API CALL
  const handleEditTable = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      tableId: selectedTable?._id,
      location: tableDetails?.location,
      floor: tableDetails?.floor,
      tableNumber: tableDetails?.number,
      tableName: tableDetails?.name,
      sittingCapacity: tableDetails?.sittingCap,
      assignedPerson: tableDetails?.assignedPerson,
    };
    const res = await ApiCall(
      "post",
      restaurantTableRelatedEndPoints.editTable,
      data
    );
    if (res?.success) {
      handleGetAllTable();
      toast.success("Edit Table Successfully");
      handleReset();
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    settableDetails({
      ...tableDetails,
      [name]: value,
    });
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">{selectedTable ? "Edit" : "Add"} Table</h5>
            <div className="border-bottom mb-3"></div>
            <form onSubmit={selectedTable?handleEditTable:handleAddTable}>
              <div className="row gx-3 px-3 w-100">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Table No {reuiredField}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="number"
                      placeholder="Enter Table Number *"
                      id="number"
                      value={tableDetails?.number}
                      onChange={handleValueChange}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Table Name {reuiredField}<span style={{fontSize:"11px"}}> (Display Name)</span> 
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="name"
                      placeholder="Enter Table Name *"
                      id="name"
                      value={tableDetails?.name}
                      onChange={handleValueChange}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Floor {reuiredField}</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="floor"
                      placeholder="Enter Floor *"
                      id="floor"
                      value={tableDetails?.floor}
                      onChange={handleValueChange}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Location {reuiredField}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="location"
                      placeholder="Enter Location *"
                      id="location"
                      value={tableDetails?.location}
                      onChange={handleValueChange}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Assign Person {reuiredField}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="assignedPerson"
                      placeholder="Enter Person Name *"
                      id="assignedPerson"
                      value={tableDetails?.assignedPerson}
                      onChange={handleValueChange}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Sitting Capacity {reuiredField}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      required
                      name="sittingCap"
                      placeholder="Enter Table Capacity *"
                      id="sittingCap"
                      value={tableDetails?.sittingCap}
                      onChange={handleValueChange}
                    />{" "}
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                {selectedTable ? (
                  <button
                    disabled={loader}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {loader ? <ApiLoader /> : "Edit"}
                  </button>
                ) : (
                  <button
                    disabled={loader}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {loader ? <ApiLoader /> : "Add"}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTable;
