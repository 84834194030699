import { combineReducers } from "redux";
import approvedWorkspaces from "./allApprovedWorkspaces";
import allModuleAccess from "./allModuleAccess";
import allModulePermission from "./allModulePermission";
import pendingWorkspaces from "./allPendingWorkspaces";
import allReducers from "./allReducers";
import authenticateUser from "./authenticateUser";
import allConfiguration from "./allConfiguration";
import refreshNavbar from "./refreshNavbar";

const reducers = combineReducers({
  amount: allReducers,
  user: authenticateUser,
  approvedWorkspace: approvedWorkspaces,
  pendingWorkspaces: pendingWorkspaces,
  allModulePermission: allModulePermission,
  allModuleAccess: allModuleAccess,
  configuration: allConfiguration,
  navbar: refreshNavbar,
});

export default reducers;
