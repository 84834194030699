import React from "react";
import { useNavigate } from "react-router-dom";
import { redirectToPrivacyPolicy } from "../../Navigation/Navigation";

const DashboardFooter = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="hk-footer">
        <footer className="container-xxl footer">
          <div className="row">
            <div className="col-xl-12 d-flex justify-content-end">
              <p className="footer-text">
                <span className="footer-link-sep">|</span>
                <span className="copy-text">
                  Sociohood Technologies pvt. ltd. © 2023 All rights reserved.
                </span>{" "}
                <span className="footer-link-sep">|</span>
                <span
                  className="text-primary pointer"
                  onClick={() => {
                    navigate(redirectToPrivacyPolicy());
                  }}
                >
                  Privacy Policy
                </span>
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default DashboardFooter;
