import React, { useState } from "react";
import { Avatar } from "../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  switchIntoAnotherWorkspace,
} from "../../global-functions/globalFunctions";
import WorkspaceDetailsModal from "./WorkspaceDetailsModal";

const WorkspaceTableView = ({ loader, allBusiness, page, pageLimit }) => {
  const [isDetailsModalOpen, setisDetailsModalOpen] = useState(false);
  const [selectWorkspace, setselectWorkspace] = useState([]);

  const handleDetailsDrawer = () => {
    setisDetailsModalOpen(!isDetailsModalOpen);
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-5">
          <thead>
            <tr className="text-center">
              <th>No.</th>
              <th style={{ textAlign: "left" }}>Name</th>
              <th>Date Created</th>
              <th>Unique Code</th>
              <th>Status</th>
              <th style={{ textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          {!loader && (
            <tbody>
              {allBusiness?.map((business, index) => {
                return (
                  <tr key={index} className="text-center">
                    <td> {(page - 1) * pageLimit + (index + 1)} .</td>
                    <td style={{ textAlign: "left" }}>
                      <span
                        className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                        style={{ width: "300px" }}
                        onClick={() => {
                          setselectWorkspace(business);
                          setisDetailsModalOpen(!isDetailsModalOpen);
                        }}
                      >
                        <Avatar
                          name={business?.name}
                          count={1}
                          image={business?.image}
                          size="xss"
                          color="primary"
                        />
                        &nbsp; {business?.name}
                      </span>
                    </td>
                    <td>{dateAndTimeFormatter(business?.createdAt)}</td>
                    <td>{business?.businessUniqueId}</td>

                    <td>
                      <span
                        className={`badge badge-soft-${
                          business?.approvalRequest?.status === "approved"
                            ? "green"
                            : business?.approvalRequest?.status === "pending"
                            ? "yellow"
                            : "red"
                        } my-1  me-2`}
                      >
                        {business?.approvalRequest?.status}
                      </span>
                    </td>
                    {business?.approvalRequest?.status === "approved" ? (
                      <td style={{ textAlign: "center" }}>
                        {business?._id === getCurrentWorkspaceId() ? (
                          <>
                            <span className="badge badge-green badge-indicator badge-indicator-lg me-2"></span>
                            <span className="nav-link-text  me-1">
                              Activated
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              onClick={() => {
                                switchIntoAnotherWorkspace(business?._id);
                              }}
                              className="icon me-2 btn btn-icon btn-rounded btn-soft-primary"
                            >
                              <span className="feather-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-arrow-clockwise"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                                  />
                                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg>
                              </span>
                            </span>
                          </>
                        )}
                      </td>
                    ) : (
                      <td style={{ textAlign: "center" }}>-</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      {isDetailsModalOpen && (
        <WorkspaceDetailsModal
          handleDetailsDrawer={handleDetailsDrawer}
          selectWorkspace={selectWorkspace}
        />
      )}
    </>
  );
};

export default WorkspaceTableView;
