import React from "react";

function AllComplaintsHeader(props) {
  const {
    setPage,
    handleGetComplaints,
    search,
    setSearch,
    moduleType,
    handleChangeType,
  } = props;
  return (
    <header className="todo-header">
      <a className="todoapp-title link-dark">
        <h1>All Grievance</h1>
      </a>
      <div className="todo-options-wrap">
        <div className="selectable-dropdown me-2">
          <div className="dropdown selectable-dropdown">
            <span
              className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Type :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp; {moduleType === "" ? "All" : moduleType}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("");
                  }}
                >
                  1. All
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "Space" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("Space");
                  }}
                >
                  2. Space
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "Task" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("Task");
                  }}
                >
                  3. Task
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block d-none me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetComplaints}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none me-2" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search Grievance"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
          />
        </form>
      </div>
    </header>
  );
}

export default AllComplaintsHeader;
