let host = window.location.href;
let isProduction = host?.includes("bizhood") ? true : false;
console.log(
  isProduction
    ? "🚨🚨🚨🚨🚨🚨LIVE🚨🚨🚨🚨🚨🚨"
    : "🚧🚧🚧🚧🚧DEVELOPMENT🚧🚧🚧🚧🚧🚧"
);

// ********* BASE URL ********
export const BASE_URL = isProduction
  ? "https://api.bizhood.in/api"
  : "https://apidevtask.sociohood.com/api";

// ********* INVITATION URL ********
export const INVITATION_URL = isProduction
  ? "https://bizhood.in/workspace/invitations"
  : "https://devtaskmanager.sociohood.com/workspace/invitations";

// ********* VERSION URL ********
export const VERSION_URL = isProduction
  ? "https://versionprod.sociohood.com/api"
  : "https://version.sociohood.com/api";

export const ACTIVE_VERSION = isProduction ? "1.0.Production" : "1.1";

// ********* AWS BUCKET NAME ********
export const AWS_BUCKET_NAME = isProduction
  ? "bizhood-task-manager-dashboard"
  : "sociohood-development-bucket";


  // ********* QR URL ********
export const QR_URL = isProduction
? "https://menu.bizhood.in"
: "https://devmenu.bizhood.in";

