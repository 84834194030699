import React, { useEffect, useState } from "react";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { taskEndpoint } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { useLocation, useParams } from "react-router-dom";
import NoData from "../../../components/NoData/NoData";
import TaskAnalyticsDetailsBody from "./TaskAnalyticsDetailsBody";
import TaskAnalysisDetailsHeader from "./TaskAnalysisDetailsHeader";
import moment from "moment";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import TaskDetailsModal from "../../Task/TaskDetailsModal/TaskDetailsModal";
import { AssignEmployee } from "../../Task/AssignEmployee";
import ChangeDuedate from "../../../components/ChangeDuedate/ChangeDuedate";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import AddTaskInMyTasks from "../../MyTasks/AddTaskInMyTasks";

const TaskAnalysisDetails = () => {
  const { allModuleAccess, user } = useSelector((state) => state);
  const { id } = useParams();
  const location = useLocation();
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [loader, setloader] = useState(false);
  const [locationData, setlocationData] = useState([]);
  const [allTaskOverview, setallTaskOverview] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [listOrSpaceId, setListOrSpaceId] = useState("");

  //Task
  const [loading, setloading] = useState(false);
  const [taskData, settaskData] = useState([]);
  const [isAssigneeEmployeeModal, setisAssigneeEmployeeModal] = useState(false);
  const [editDueDate, seteditDuedDate] = useState(false);
  const [taskId, settaskId] = useState([]);
  const [selectedTaskPriority, setselectedTaskPriority] = useState({
    name: "All Priority",
    color: "#c8c8c8",
    value: "",
  });

  const [selectedTaskStatus, setselectedTaskStatus] = useState({
    statusName: "All Status",
    color: "#c8c8c8",
  });

  const [selectedTab, setSelectedTab] = useState("All");
  const [taskDetailsModal, settaskDetailsModal] = useState(false);
  const [selectedTask, setselectedTask] = useState([]);
  const [analyticsView, setAnalyticsView] = useState(false);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForTaskAnalysis =
    allModuleAccess?.[PRIVATEROUTES.Task_Overview];

  useEffect(() => {
    if (startDate === "" && endDate === "") {
      setstartDate(
        localStorage.getItem("bizStartDate")
          ? localStorage.getItem("bizStartDate")
          : currentDate
      );
      setendDate(
        localStorage.getItem("bizEndDate")
          ? localStorage.getItem("bizEndDate")
          : currentDate
      );
    }
  }, []);

  useEffect(() => {
    if (location) {
      setlocationData(location?.state);
    }
  }, [location]);

  const handleGetAllTaskList = async () => {
    startApiCall(null, setloader);
    const sendData = {
      startDate: startDate,
      endDate: endDate,
      businessId: getCurrentWorkspaceId(),
      employeeId: id,
      searchKey: "",
    };
    const res = await ApiCall(
      "post",
      taskEndpoint.employeeTaskOverview,
      sendData
    );
    if (res?.success) {
      setallTaskOverview(res?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetTask = async (e) => {
    startApiCall(null, setloading);
    const sendData = {
      page: page,
      limit: pageLimit,
      employeeId: id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        taskType: "maintask",
        searchKey: "",
        startDate: startDate,
        endDate: endDate,
        dueDate: "",
        priority: selectedTaskPriority?.value,
        assignedTo: id,
        status:
          selectedTaskStatus?.statusName === "All Status"
            ? ""
            : selectedTaskStatus?._id,
        status_type: selectedTab === "All" ? "" : selectedTab,
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      settaskData(res?.task?.data);
      setcount(res?.task?.totalCounts);
      settotalPages(res?.task?.totalPages);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  const handleRefresh = () => {
    handleGetTask();
    handleGetAllTaskList();
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && startDate && endDate) {
      handleGetAllTaskList();
    }
  }, [startDate, endDate, analyticsView]);

  useEffect(() => {
    if (getCurrentWorkspaceId() && !analyticsView && startDate && endDate) {
      handleGetTask();
      closeAllModals();
    }
  }, [selectedTab, analyticsView, page, pageLimit, startDate, endDate]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <TaskAnalysisDetailsHeader
            user={user}
            locationData={locationData}
            handleGetAllTaskList={handleGetAllTaskList}
            setStartDate={setstartDate}
            startDate={startDate}
            endDate={endDate}
            setEndDate={setendDate}
            loader={loader}
            currentDate={currentDate}
            analyticsView={analyticsView}
            handleGetTask={handleGetTask}
            isModuleReadWriteAccessForTaskAnalysis={
              isModuleReadWriteAccessForTaskAnalysis
            }
            setTaskData={settaskData}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <TaskAnalyticsDetailsBody
                  allTaskOverview={allTaskOverview?.[0]}
                  id={id}
                  loader={loading}
                  loader2={loader}
                  taskData={taskData}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  settaskData={settaskData}
                  page={page}
                  pageLimit={pageLimit}
                  handleGetTask={handleGetTask}
                  setpageLimit={setpageLimit}
                  count={count}
                  setPage={setPage}
                  totalPages={totalPages}
                  settaskDetailsModal={settaskDetailsModal}
                  setselectedTask={setselectedTask}
                  setAnalyticsView={setAnalyticsView}
                  analyticsView={analyticsView}
                  setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
                  listOrSpaceId={listOrSpaceId}
                  setListOrSpaceId={setListOrSpaceId}
                  seteditDuedDate={seteditDuedDate}
                  settaskId={settaskId}
                />
              </div>
              {(loader || loading) && (
                <DataLoader title="Your task data is loading..." />
              )}
              {!loader &&
                !loading &&
                allTaskOverview?.length === 0 &&
                analyticsView && <NoData />}
              {!loading &&
                !loader &&
                taskData?.length === 0 &&
                !analyticsView && <NoData />}
            </div>
          </div>
        </div>
        {taskDetailsModal && (
          <TaskDetailsModal
            taskId={selectedTask?._id}
            taskDetailsModal={taskDetailsModal}
            settaskDetailsModal={settaskDetailsModal}
            isTask={true}
            handleGetTask={handleGetTask}
          />
        )}
        {isAssigneeEmployeeModal && (
          <AssignEmployee
            setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
            isAssigneeEmployeeModal={isAssigneeEmployeeModal}
            listId={listOrSpaceId}
            user={user}
            selectedTask={selectedTask}
            handleGetTask={handleGetTask}
          />
        )}
        {editDueDate && (
          <ChangeDuedate
            seteditDuedDate={seteditDuedDate}
            taskId={taskId}
            editDueDate={editDueDate}
            taskType={taskId?.taskType}
            handleGetTaskDetails={handleGetTask}
          />
        )}
        <AddTaskInMyTasks handleGetTask={handleRefresh} />
      </div>
    </div>
  );
};

export default TaskAnalysisDetails;
