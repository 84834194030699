import React, { useEffect, useState } from "react";
import PlaceOrderForCustomerHeader from "./PlaceOrderForCustomerHeader";
import { startApiCall } from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import {
  InventoryRelatedEndPoints,
  OrderRelatedEndPoints,
  ProductCategoryRelatedEndPoints,
} from "../../../../api/Endpoints";
import CustomerMenuCardSection from "./CustomerMenuCardSection";
import CustomerCartSection from "./CustomerCartSection";
import CustomerOrderConfirmModal from "./CustomerOrderConfirmModal";
import { useSelector } from "react-redux";
import CustomerInfoModal from "./CustomerInfoModal";
import { useParams } from "react-router-dom";
import OrderSuccessModal from "./OrderSuccessModal";
import NoData from "../../../../components/NoData/NoData";

function PlaceOrderForCustomer() {
  const { workspaceId } = useParams();
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [itemCategory, setItemCategory] = useState([]);
  const [categoryItemsData, setcategoryItemsData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [search, setSearch] = useState("");
  const [subTotal, setsubTotal] = useState("");
  const [costPrice, setcostPrice] = useState("");
  const [payloadItems, setPayloadItems] = useState([]);
  const [payableAmount, setpayableAmount] = useState("");
  const [uniqueItems, setUniqueItems] = useState([]);
  const [selectedMenuItems, setselectedMenuItems] = useState([]);
  const [allChargesData, setAllChargesData] = useState([]);
  const [addCustomerModal, setaddCustomerModal] = useState(false);
  const [customerId, setcustomerId] = useState("");
  const [conformOrder, setconformOrder] = useState(false);
  const [orderSuccess, setorderSuccess] = useState(false);
  const [orderPreference, setorderPreference] = useState("dining");
  const [isBusinessInvalid, setisBusinessInvalid] = useState(false);

  const charges = allChargesData?.map((data) => {
    return {
      chargeId: data?._id,
      amount: data?.value,
    };
  });

  const updateValueForItem = (targetItem, newValue) => {
    let updatedCharges = allChargesData?.map((item) =>
      item._id === targetItem ? { ...item, value: newValue } : item
    );
    setAllChargesData(updatedCharges);
  };

  const handleProductClick = (selectedProduct) => {
    const updatedMenuItems = [...selectedMenuItems, selectedProduct];
    setselectedMenuItems(updatedMenuItems);
  };

  //   Function for Add or Remove or Delete or Reset Order Item
  const handleAddItem = (item) => {
    setselectedMenuItems((prevItems) => [...prevItems, { ...item }]);
  };

  const handleRemoveItem = (item) => {
    setselectedMenuItems((prevItems) => {
      const index = prevItems.findIndex(
        (prevItem) => prevItem?._id === item?._id
      );

      if (index !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      }

      return prevItems;
    });
  };
  const handleDeleteItem = (item) => {
    setselectedMenuItems((prevItems) =>
      prevItems.filter((prevItem) => prevItem?._id !== item?._id)
    );
  };

  const handleReset = () => {
    setconformOrder(false);
    setcostPrice("");
    setsubTotal("");
    setPayloadItems("");
    setpayableAmount("");
    setselectedMenuItems([]);
    handleGetItemCategory();
    setSearch("");
    seterrorMessage("");
    setcustomerId("");
    setloader3(false);
  };

  //  Function for all charges
  const handleGetAllCharges = async () => {
    const data = {
      filters: {
        businessId: workspaceId,
        isVisible: true,
      },
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getAllCharges,
      data
    );
    if (res?.success) {
      setAllChargesData(res?.charges);
    } else {
      setisBusinessInvalid(true);
    }
  };

  //  Function for food category
  const handleGetItemCategory = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: workspaceId,
        searchKey: "",
      },
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.getProductCategory,
      data
    );
    if (res?.success) {
      setItemCategory(res?.categories?.data);
      setloader(false);
    } else {
      setloader(false);
      setisBusinessInvalid(true);
    }
  };
  //  Function for Dish in food category
  const handleGetCategoryItems = async () => {
    startApiCall(null, setloader2);
    const data = {
      filters: {
        productCategory: selectedCategory,
        isDeleted: "false",
        searchKey: search,
        businessId: workspaceId,
      },
      page: 1,
      limit: 5000,
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.getCategoryItems,
      data
    );
    if (res?.success) {
      setcategoryItemsData(res?.item?.data);
      setloader2(false);
    } else {
      setloader2(false);
      setisBusinessInvalid(true);
    }
  };

  useEffect(() => {
    if (workspaceId) {
      handleGetItemCategory();
      handleGetAllCharges();
    }
  }, []);

  useEffect(() => {
    handleGetCategoryItems();
  }, [selectedCategory, search]);

  // CREATE ORDER FUNCTION API CALL
  const handleCreateOrder = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader3);
    const data = {
      businessId: workspaceId,
      products: payloadItems,
      amount: payableAmount,
      buyer: customerId,
      status: "pending",
      costPrice,
      charges: charges,
      subTotal,
      createdBy: user?._id,
      isCustomization: false,
      customization: "",
      isApproved: "pending",
      orderType: orderPreference,
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.createOrder, data);
    if (res?.success) {
      handleReset();
      setorderSuccess(true);
    } else {
      setloader3(false);
      seterrorMessage(res?.error);
    }
  };

  return (
    <div className="taskboardapp-wrap">
      <div className="taskboardapp-content">
        <div className="taskboardapp-detail-wrap">
          <PlaceOrderForCustomerHeader
            setSelectedCategory={setSelectedCategory}
            handleGetItemCategory={handleGetItemCategory}
          />

          <div className="taskboardapp-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <div
                  className="row gx-3 overflow-y-auto"
                  style={{ height: "95vh" }}
                >
                  {isBusinessInvalid && (
                    <NoData
                      title="No Restaurant Found"
                      description={
                        "Try adjusting your url or filter to find what you're looking for."
                      }
                    />
                  )}
                  {!isBusinessInvalid && (
                    <>
                      <div className="col-lg-8 col-md-6 border-end px-3 ">
                        <CustomerMenuCardSection
                          itemCategory={itemCategory}
                          categoryItemsData={categoryItemsData}
                          loader={loader}
                          loader2={loader2}
                          selectedCategory={selectedCategory}
                          setSelectedCategory={setSelectedCategory}
                          handleProductClick={handleProductClick}
                          search={search}
                          setSearch={setSearch}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 border-start px-3">
                        <CustomerCartSection
                          selectedMenuItems={selectedMenuItems}
                          handleAddItem={handleAddItem}
                          handleRemoveItem={handleRemoveItem}
                          handleDeleteItem={handleDeleteItem}
                          setPayloadItems={setPayloadItems}
                          setsubTotal={setsubTotal}
                          subTotal={subTotal}
                          setpayableAmount={setpayableAmount}
                          uniqueItems={uniqueItems}
                          setUniqueItems={setUniqueItems}
                          setcostPrice={setcostPrice}
                          setaddCustomerModal={setaddCustomerModal}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {conformOrder && (
        <CustomerOrderConfirmModal
          uniqueItems={uniqueItems}
          handleCreateOrder={handleCreateOrder}
          allChargesData={allChargesData}
          subTotal={subTotal}
          setpayableAmount={setpayableAmount}
          handleReset={handleReset}
          updateValueForItem={updateValueForItem}
          payableAmount={payableAmount}
          errorMessage={errorMessage}
          loader3={loader3}
          setconformOrder={setconformOrder}
          orderPreference={orderPreference}
          setorderPreference={setorderPreference}
        />
      )}

      {addCustomerModal && (
        <CustomerInfoModal
          setaddCustomerModal={setaddCustomerModal}
          setcustomerId={setcustomerId}
          setconformOrder={setconformOrder}
        />
      )}
      {orderSuccess && <OrderSuccessModal setorderSuccess={setorderSuccess} />}
    </div>
  );
}

export default PlaceOrderForCustomer;
