import React, { useState } from "react";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { getName } from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { Avatar } from "../../../components/Avatar/Avatar";

function PreviousTransactionModal({
  setpreviousTransaction,
  allTransaction,
  loader,
  handleGetOrdersTransaction
}) {
  const user = useSelector((state) => state.user);

  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setpreviousTransaction(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5>Previous Transaction</h5>
              <div className="border-bottom mb-3"></div>
              <form>
                <div className="row gx-3">
                  {!loader && allTransaction?.length > 0 && (
                    <div className="contact-list-view mh-400p w-600p overflow-auto">
                      <table
                        id="datable_1"
                        className="table nowrap  mb-3 text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sl No.</th>
                            <th>Amount</th>
                            <th> Payment Mode</th>
                            <th> Reference Number</th>
                            <th> Action By</th>
                          </tr>
                        </thead>

                        <tbody>
                          {allTransaction?.map((data, index) => (
                            <tr class="mb-00">
                              <td>{index + 1} .</td>
                              <td class="text-left mb-00">
                                ₹ {data?.amountReceived}
                              </td>
                              <td class="text-right mb-00 text-capitalize">
                                {data?.paymentMode}
                              </td>
                              <td class="text-right mb-00">
                                {data?.transactionNumber}
                              </td>
                              <td class="text-right mb-00">
                                {" "}
                                <Avatar
                                  name={data?.transactionDoneBy?.name}
                                  count={1}
                                  image={data?.transactionDoneBy?.image}
                                  size="xxs"
                                  color="primary"
                                />
                                &nbsp;
                                {getName(data?.transactionDoneBy, user?._id)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {loader && <DataLoader title="data is loading..." />}
                  {!loader && allTransaction?.length === 0 && (
                    <NoData title="No Transactions Added" />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreviousTransactionModal;
