import React from "react";
import { Avatar } from "../../components/Avatar/Avatar";
import paySVG from "../../assets/images/pay.svg";
import { getTotalOfItems } from "../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";
import { navigateToVendorDetails } from "../../Navigation/Navigation";

const VendorDetailsModal = ({ setVendorDetails, addStockRes }) => {
  const navigate = useNavigate();
  const handleReset = () => {
    setVendorDetails(false);
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered mw-775p"
        role="document"
      >
        <div className="modal-content px-3">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Vendor details</h5>
            <div className="border-bottom mb-2"></div>
            <form>
              <div className="row gx-3 px-2">
                <div
                  className="row border mt-1 py-3 px-3 gx-3 gy-2"
                  style={{ background: "#F3F5FA" }}
                >
                  <div className="col-sm-6">
                    <span className="d-flex align-items-center gap-2">
                      <p>Vendor Name :</p>
                      <p className="text-dark d-flex align-items-center gap-1">
                        <Avatar
                          name={addStockRes[0]?.vendorId?.name}
                          count={1}
                          image={
                            addStockRes[0]?.vendorId?.image
                              ? addStockRes[0]?.vendorId?.image
                              : null
                          }
                          size="xss"
                          color="primary"
                        />
                        {addStockRes[0]?.vendorId?.name}
                      </p>
                    </span>
                  </div>
                  <div className="col-sm-6">
                    <span className="d-flex align-items-center gap-2">
                      <p>Vendor Id / Challan / Bill :</p>
                      <p className="text-dark">{addStockRes[0]?.billNumber}</p>
                    </span>
                  </div>
                  <div className="col-sm-6">
                    <span className="d-flex align-items-center gap-2">
                      <p>Phone :</p>
                      <p className="text-dark">
                        {addStockRes[0]?.vendorId?.phone}
                      </p>
                    </span>
                  </div>
                  <div className="col-sm-6">
                    <span className="d-flex align-items-center gap-2">
                      <p>Total Amount :</p>
                      <p className="text-primary fw-bold ">
                        ₹{getTotalOfItems(addStockRes, "price")}
                      </p>
                    </span>
                  </div>
                </div>
                <p className="mt-4 mb-2" style={{ marginLeft: -8 }}>
                  Order History
                </p>
                <div
                  className="border simple-scrollbar"
                  style={{ overflowY: "scroll", height: "194px" }}
                >
                  <table className="table text-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-start">No.</th>
                        <th className="text-start">Item Name ( Code )</th>
                        <th className="text-start">Quantity</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addStockRes?.map((dt, index) => {
                        return (
                          <tr>
                            <td className="text-start">{index+1}.</td>
                            <td className="text-start">{dt?.inventoryId?.name} ( {dt?.inventoryId?.inventoryItemCode} )</td>
                            <td className="text-start">{dt?.addedQuantity}</td>
                            <td className="text-primary fw-bold">
                              ₹{dt?.price}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary px-4"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  <span className="d-flex align-items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-skip-start"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 4a.5.5 0 0 1 1 0v3.248l6.267-3.636c.52-.302 1.233.043 1.233.696v7.384c0 .653-.713.998-1.233.696L5 8.752V12a.5.5 0 0 1-1 0zm7.5.633L5.696 8l5.804 3.367z" />
                    </svg>
                    Skip
                  </span>
                </button>
                <button
                  type="submit"
                  className="btn btn-primary px-4"
                  onClick={() =>
                    navigate(
                      navigateToVendorDetails({
                        id: addStockRes[0]?.vendorId?._id,
                      })
                    )
                  }
                >
                  <span className="d-flex align-items-center gap-1">
                    Pay
                    <img
                      style={{
                        height: "16px",
                        width: "16px",
                        filter: "invert(100%)",
                      }}
                      src={paySVG}
                      alt=""
                    />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorDetailsModal;
