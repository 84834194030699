import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Group from "../../pages/CRM/Group/Group";
import GroupStatus from "../../pages/CRM/Group/GroupStatus/GroupStatus";

const CrmGroupRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "groups",
      element: <Group />,
    },
    {
      path: "groups/groupstatus/:id",
      element: <GroupStatus />,
    },
  ],
};

export default CrmGroupRoutes;
