import React from "react";
import "chart.js/auto";
import { Line } from "react-chartjs-2";

const MonthlyStatisticsGraph = ({ monthlyStatistics, year, selectedMonth, monthNames }) => {
  const daysInMonth = new Date(year, monthNames.indexOf(selectedMonth) + 1, 0).getDate();

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };
  const lineChartData = {
    labels: Array.from({ length: daysInMonth }, (_, index) =>
    new Date(year, monthNames.indexOf(selectedMonth), index + 1).toLocaleDateString("en-US", {
      day: "numeric",
    })
  ),
    datasets: [
      {
        label: "Sales statistics",
        data: monthlyStatistics?.map((data) => data.totalCosts?.[0]?.revenue),
        fill: false,
        borderColor: "#289E6E",
        lineTension: 0.4,
        backgroundColor: "rgba(40, 158, 110, 0.25)",
      },
      {
        label: "Cost statistics",
        data: monthlyStatistics?.map((data) => data.totalCosts?.[0]?.costPrice),
        fill: false,
        borderColor: "#F31D2F",
        lineTension: 0.4,
        backgroundColor: "rgba(243, 29, 47, 0.25)",
      },
    ],
  };
  return (
    <div className="d-flex justify-content-center h-100">
      <Line data={lineChartData} options={lineChartOptions} />
    </div>
  );
};

export default MonthlyStatisticsGraph;
