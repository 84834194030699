import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  findClosestTime,
  getCallReminderWithInHour,
  getCurrentWorkspaceId,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../../api/ApiCall";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  CrmContactRelatedEndPoints,
  LeadRelatedEndPoints,
  CrmTaskRelatedEndPoints,
  DealRelatedEndPoints,
} from "../../../../../../api/Endpoints";
import ApiLoader from "../../../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../../../components/Error/ErrorMessage";
import { Avatar } from "../../../../../../components/Avatar/Avatar";
import CustomTooltip from "../../../../../../components/CustomTooltip/CustomTooltip";
import moment from "moment";
import { actionCreators } from "../../../../../../redux-setup";
import PRIVATEROUTES from "../../../../../../components/Constant/Route-Constants";

const AddCalls = ({
  handleRefresh,
  seteditCall,
  editCall,
  isLeadDeal,
  leadDetails,
  id,
  setassigneeModal,
  selectedEmployees,
  setselectedEmployees,
  setselectedEmpId,
  selectedEmpId,
  setdetailInfo,
  groupLoader,
  allGroups,
  description,
  setdescription,
}) => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [title, settitle] = useState("");
  var today = reversesDateFormatter(new Date());
  var currentTime = moment().format("HH:mm");
  const [startTime, setstartTime] = useState();
  const [reminder, setreminder] = useState("");
  const [date, setdate] = useState(today);
  const [relatedTo, setrelatedTo] = useState("Contact");
  const currentDate = new Date();
  const [type, settype] = useState("incoming");
  const [totalLeads, setTotalLeads] = useState(0);
  const [pageForLeads, setPageForLeads] = useState(0);
  const [initialLoad, setInitialLoad] = useState(false);
  const isModuleReadWriteAccessForCalls =
    allModuleAccess?.[PRIVATEROUTES.CRM_CALLS];

  //Activity Group And Status

  const [selectedGroup, setselectedGroup] = useState("");
  const [selectedActivityStatus, setselectedActivityStatus] = useState("");

  //Select Contact
  const [contactLoader, setcontactLoader] = useState(false);
  const [selectContact, setselectContact] = useState("");
  const [filter, setFilter] = useState("");
  const [allContacts, setallContacts] = useState([]);
  const [contactId, setcontactId] = useState("");
  const filteredContacts = filterSearchData(allContacts, filter);

  //Select Lead
  const [leadLoader, setleadLoader] = useState(false);
  const [selectlead, setselectlead] = useState("");
  const [filterLeads, setFilterLeads] = useState("");
  const [allLeads, setallLeads] = useState([]);
  const [leadId, setleadId] = useState("");

  // Select Deal
  const [dealLoader, setdealLoader] = useState(false);
  const [selectdeal, setselectdeal] = useState("");
  const [filterDeals, setFilterDeals] = useState("");
  const [allDeals, setallDeals] = useState([]);
  const [dealId, setdealId] = useState("");
  const filtereddeals = allDeals?.filter((data) =>
    data?.title?.toLowerCase()?.includes(filterDeals.toLowerCase())
  );

  const handleGetAllTaskWithInHour = async () => {
    startApiCall(null, setloader2);
    const assignees = !isModuleReadWriteAccessForCalls ? [user?._id] : "";

    const res = await getCallReminderWithInHour(assignees);
    if (res) {
      dispatch(actionCreators.refreshNavbar(findClosestTime(currentDate, res)));
    }
  };

  const handleReset = () => {
    setloader(false);
    settitle("");
    setdate(today);
    setstartTime(currentTime);
    seteditCall("");
    setreminder("");
    setErrorMessage("");
    setleadId("");
    setselectlead("");
    setrelatedTo("Contact");
    setcontactId("");
    setselectContact("");
    setselectedEmployees([]);
    setselectedEmpId([]);
    setselectedGroup("");
    settype("incoming");
    setselectedActivityStatus("");
    setdescription("");
    const closeModalDom = document.getElementById("create_call_modal");
    if (closeModalDom) closeModalDom.click();
  };

  //Contacts
  const handleGetContact = async () => {
    startApiCall(null, setcontactLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isRemoved: false,
      },
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.getContact,
      data
    );
    if (res?.success) {
      setallContacts(res?.result?.data);
      setcontactLoader(false);
    } else {
      setcontactLoader(false);
    }
  };

  const handleGetLeads = async () => {
    const isNewSearch = pageForLeads === 1;

    if (isNewSearch) {
      setInitialLoad(true);
    } else {
      setleadLoader(true);
    }
    const data = {
      page: pageForLeads,
      limit: 50,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "lead",
        searchKey: filterLeads,
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLead, data);
    if (res?.success) {
      if (isNewSearch) {
        setallLeads(res?.result?.data);
      } else {
        setallLeads((prev) => [...prev, ...res?.result?.data]);
      }
      setTotalLeads(res?.result?.totalCount);
    }
    setInitialLoad(false);
    setleadLoader(false);
  };

  const handleScroll = (e) => {
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight + 1 >= target.scrollHeight &&
      !leadLoader &&
      totalLeads !== allLeads.length
    ) {
      setPageForLeads((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (pageForLeads > 0) {
      handleGetLeads();
    }
  }, [pageForLeads, filterLeads]);

  // Deals
  const handleGetAllDeals = async () => {
    startApiCall(null, setdealLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "deal",
      },
    };
    const res = await ApiCall("post", DealRelatedEndPoints.getDeal, data);
    if (res?.success) {
      setallDeals(res?.result?.data);
      setdealLoader(false);
    } else {
      setdealLoader(false);
    }
  };

  // ADD CALL IN CRM

  const handleAddCall = async (e) => {
    // e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const timeString =
      new Date(date)?.toISOString().split("T")[0] + "T" + startTime;
    const selectedTime = new Date(timeString);
    if (selectedTime < currentDate) {
      setErrorMessage("Please select a time that is not in the past");
      setloader(false);
    } else {
      const sendData = {
        taskType: "call",
        title: title,
        activityDate: date,
        relatedTo:
          isLeadDeal && leadDetails?.status === "lead"
            ? "Lead"
            : isLeadDeal && leadDetails?.status === "deal"
            ? "Deal"
            : relatedTo === "Lead"
            ? "Lead"
            : relatedTo === "Deal"
            ? "Deal"
            : relatedTo,
        startTime: startTime,
        actionOn: isLeadDeal
          ? id
          : relatedTo === "Contact"
          ? contactId
          : relatedTo === "Lead"
          ? leadId
          : dealId,
        actionBy: user?._id,
        businessId: getCurrentWorkspaceId(),
        reminder: reminder,
        priority: "low",
        isLog: false,
        callType: type,
        assignees: selectedEmpId,
        activityGroup: selectedGroup,
        notes: description,
        createdBy: user?._id,
      };
      const res = await ApiCall(
        "post",
        CrmTaskRelatedEndPoints.addTask,
        sendData
      );
      if (res?.success) {
        handleReset();
        handleRefresh();
        handleGetAllTaskWithInHour();
        toast.success("Call created successfully!");
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    }
  };

  // useEffect(() => {
  //   if (relatedTo === "Contact") {
  //     handleGetContact();
  //   } else if (relatedTo === "Lead") {
  //     handleGetLeads();
  //   }
  // }, [relatedTo]);

  // const handleGetAllStatus = async (id) => {
  //   startApiCall(setErrorMessage, setloader3);
  //   const data = {
  //     page: 1,
  //     limit: 200,
  //     filters: {
  //       businessId: getCurrentWorkspaceId(),
  //       activityGroupId: selectedGroup,
  //     },
  //   };
  //   const res = await ApiCall(
  //     "post",
  //     activityGroupRelatedEndpoints.getActivitySubStatus,
  //     data
  //   );
  //   if (res?.success) {
  //     setallStatus(res?.status?.data);
  //     setloader3(false);
  //   } else {
  //     setloader3(false);
  //     // setloader3(res?.error);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedGroup !== "") {
  //     handleGetAllStatus();
  //   }
  // }, [selectedGroup]);

  // useEffect(() => {
  //   if (allStatus?.length > 0) {
  //     setselectedActivityStatus(allStatus?.[0]);
  //   }
  // }, [allStatus]);

  return (
    <>
      <div>
        <div className="row gx-4 px-2 w-100">
          <div className="col-sm-6 mb-3">
            <label className="form-label">Call Title {reuiredField}</label>

            <input
              className="form-control p-2"
              type="text"
              placeholder="Add a call title"
              required
              value={title}
              onChange={(e) => {
                settitle(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-6 mb-3">
            <label className="form-label">Call Participant</label>

            <div className="d-flex align-items-center repeater border rounded-5 w-100 text-dark">
              <div data-repeater-list="category-group" className="h-80">
                <ul className="hk-list pointer">
                  {selectedEmployees?.map((emp, index) => {
                    return (
                      <CustomTooltip
                        text={emp?.name}
                        placement={index === 0 ? "right" : "bottom"}
                      >
                        <li
                          key={index}
                          className="ms-2"
                          style={{ padding: "0.48rem" }}
                        >
                          <Avatar
                            name={emp?.name}
                            count={1}
                            image={emp?.image}
                            size="xxs"
                          />
                        </li>
                      </CustomTooltip>
                    );
                  })}

                  <li
                    className="ms-2 pointer"
                    style={{ padding: "0.48rem" }}
                    onClick={() => {
                      if (!contactId && !leadId && !dealId && !isLeadDeal) {
                        toast.warning(
                          "Please select a Deal, Lead or Contact before assigning a participant to the call."
                        );
                      } else {
                        setassigneeModal(true);
                      }
                    }}
                  >
                    {selectedEmpId?.length === 0 ? (
                      "Assign Participant:"
                    ) : (
                      <span className="text-primary">
                        {selectedEmpId?.length} Participant Selected
                      </span>
                    )}{" "}
                    &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-plus-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="form-group">
                <label className="form-label">Choose Type {reuiredField}</label>
                <div className="todo-toolbar">
                  <select
                    className="form-select form-select-md p-2"
                    value={type}
                    onChange={(e) => {
                      settype(e.target.value);
                    }}
                  >
                    <option value="">Type</option>
                    <option value="outgoing">Outgoing Call </option>
                    <option value="incoming">Incoming Call </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <label className="form-label">
              Choose Activity Status group {reuiredField}
            </label>
            <div className="form-group">
              <div className="todo-toolbar">
                <select
                  className="form-select form-select-md"
                  name="leadStatusGroup"
                  required
                  value={selectedGroup}
                  onChange={(e) => {
                    setselectedGroup(e.target.value);
                  }}
                >
                  <option value="">Select Group</option>
                  {groupLoader && <option disabled>Loading Groups...</option>}
                  {!groupLoader && (
                    <>
                      {allGroups?.map((item, index) => {
                        return (
                          <option value={item?._id}>
                            {index + 1}. {item?.name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
          {!isLeadDeal && (
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-label">Related To</label>
                <div className="form-group">
                  <div className="todo-toolbar">
                    <select
                      className="form-select form-select-md"
                      value={relatedTo}
                      onChange={(e) => {
                        setrelatedTo(e.target.value);
                      }}
                    >
                      <option value="Contact">Contact</option>
                      <option value="Lead">Lead</option>
                      <option value="Deal">Deal</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isLeadDeal && (
            <>
              {relatedTo === "Contact" && (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Select Contact </label>
                    <div className="selectable-dropdown w-100">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100  `}
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          onClick={() => {
                            setFilter("");
                            handleGetContact();
                          }}
                        >
                          {selectContact ? (
                            <>
                              {selectContact}
                              {selectContact !== "" && (
                                <button
                                  type="button"
                                  className="btn-close btn-sm ms-2"
                                  onClick={() => {
                                    setselectContact("");
                                    setcontactId("");
                                    setFilter("");
                                  }}
                                  aria-label="Close"
                                ></button>
                              )}
                            </>
                          ) : (
                            "Select Contact"
                          )}
                        </span>
                        <div role="menu" className="dropdown-menu">
                          <form className="d-sm-block d-none" role="search">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search.."
                              value={filter}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </form>
                          {contactLoader ? (
                            <span className="d-flex text-center mt-1">
                              Loading...
                            </span>
                          ) : (
                            <div
                              style={{
                                maxHeight: "12rem",
                                overflow: "auto",
                              }}
                            >
                              {filteredContacts?.map((contact, index) => (
                                <div
                                  className={`dropdown-item text-capitalize`}
                                  key={contact?._id}
                                  onClick={() => {
                                    setselectContact(contact?.name);
                                    setcontactId(contact?._id);
                                    setdetailInfo(contact);
                                  }}
                                >
                                  {index + 1}. {contact?.name}
                                </div>
                              ))}
                            </div>
                          )}

                          {!contactLoader && filteredContacts?.length === 0 && (
                            <span className="d-flex text-center mt-1">
                              No Contacts found
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {relatedTo === "Lead" && (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Select Leads </label>
                    <div className="selectable-dropdown w-100">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100  `}
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          onClick={() => {
                            setFilterLeads("");
                            setPageForLeads(1);
                          }}
                        >
                          {selectlead ? (
                            <>
                              {selectlead}
                              {selectlead !== "" && (
                                <button
                                  type="button"
                                  className="btn-close btn-sm ms-2"
                                  onClick={() => {
                                    setselectlead("");
                                    setleadId("");
                                    setFilterLeads("");
                                  }}
                                  aria-label="Close"
                                ></button>
                              )}
                            </>
                          ) : (
                            "Select Lead"
                          )}
                        </span>
                        <div role="menu" className="dropdown-menu">
                          <form className="d-sm-block d-none" role="search">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search.."
                              value={filterLeads}
                              onChange={(e) => {
                                setFilterLeads(e.target.value);
                                setPageForLeads(1);
                              }}
                            />
                          </form>
                          {initialLoad ? (
                            <span className="d-flex text-center mt-1">
                              Loading...
                            </span>
                          ) : (
                            <div
                              onScroll={handleScroll}
                              style={{ maxHeight: "16vh", overflow: "auto" }}
                            >
                              {allLeads?.map((lead, index) => (
                                <div
                                  className={`dropdown-item text-capitalize`}
                                  key={lead?._id}
                                  onClick={() => {
                                    setselectlead(lead?.title);
                                    setleadId(lead?._id);
                                    setdetailInfo(lead);
                                  }}
                                >
                                  {index + 1}. {lead?.title}
                                </div>
                              ))}
                            </div>
                          )}

                          {!initialLoad && allLeads?.length === 0 && (
                            <span className="d-flex text-center mt-1">
                              No Leads found
                            </span>
                          )}
                          {leadLoader && (
                            <span className="d-flex text-center mt-1">
                              Loading more leads...
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {relatedTo === "Deal" && (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Select Deal </label>
                    <div className="selectable-dropdown w-100">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                            editCall ? "bg-light text-disabled" : ""
                          } `}
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          disabled={editCall}
                          onClick={() => {
                            setFilterDeals("");
                            handleGetAllDeals();
                          }}
                        >
                          {selectdeal ? (
                            <>
                              {selectdeal}
                              {selectdeal !== "" && !editCall && (
                                <button
                                  type="button"
                                  className="btn-close btn-sm ms-2"
                                  onClick={() => {
                                    setselectdeal("");
                                    setdealId("");
                                    setFilterDeals("");
                                  }}
                                  aria-label="Close"
                                ></button>
                              )}
                            </>
                          ) : (
                            "Select Deal"
                          )}
                        </span>
                        <div role="menu" className="dropdown-menu">
                          <form className="d-sm-block d-none" role="search">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search.."
                              value={filterDeals}
                              onChange={(e) => setFilterDeals(e.target.value)}
                            />
                          </form>
                          {dealLoader ? (
                            <span className="d-flex text-center mt-1">
                              Loading...
                            </span>
                          ) : (
                            <div
                              style={{
                                maxHeight: "13vh",
                                overflow: "auto",
                              }}
                            >
                              {filtereddeals?.map((deal, index) => (
                                <div
                                  className={`dropdown-item text-capitalize`}
                                  key={deal?._id}
                                  onClick={() => {
                                    setselectdeal(deal?.title);
                                    setdealId(deal?._id);
                                    setdetailInfo(deal);
                                  }}
                                >
                                  {index + 1}. {deal?.title}
                                </div>
                              ))}
                            </div>
                          )}

                          {!dealLoader && filtereddeals?.length === 0 && (
                            <span className="d-flex text-center mt-1">
                              No Deals found
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="row gx-4 px-2 w-100">
          <div className="col-sm-6">
            <label className="form-label">Schedule Date {reuiredField}</label>

            <div className="form-group">
              <input
                className="form-control p-2"
                type="date"
                required
                value={date}
                min={reversesDateFormatter(currentDate)}
                onChange={(e) => {
                  setdate(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-sm-6">
            <label className="form-label">Schedule Time {reuiredField}</label>
            <div className="form-group">
              <input
                className="form-control p-2"
                type="time"
                placeholder="Time"
                value={startTime}
                onChange={(e) => {
                  setstartTime(e.target.value);
                }}
              />
            </div>
          </div>
          {/* <div className="col-sm-6">
            <label className="form-label">
              Choose Activity Status group {reuiredField}
            </label>
            <div className="form-group">
              <div className="todo-toolbar">
                <select
                  className="form-select form-select-md"
                  name="leadStatusGroup"
                  required
                  value={selectedGroup}
                  onChange={(e) => {
                    setselectedGroup(e.target.value);
                  }}
                >
                  <option value="">Select Group</option>
                  {loader2 && <option disabled>Loading Groups...</option>}
                  {!loader2 && (
                    <>
                      {allGroups?.map((item, index) => {
                        return (
                          <option value={item?._id}>
                            {index + 1}. {item?.name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </select>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row gx-4 px-2 w-100">
          <div className="col-sm-6">
            <div className="form-group">
              <div className="form-group">
                <label className="form-label">Reminder</label>
                <div className="todo-toolbar">
                  <select
                    className="form-select form-select-md p-2"
                    value={reminder}
                    onChange={(e) => {
                      setreminder(e.target.value);
                    }}
                    required
                  >
                    <option value="">Select</option>
                    <option value="5">5 mins</option>
                    <option value="10">10 mins</option>
                    <option value="15">15 mins</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <div className="form-group">
                <label className="form-label">Note</label>
                <div className="todo-toolbar">
                  <textarea
                    className="form-control"
                    rows="3"
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                    value={description}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row gx-4 px-2 w-100">
        <div className="col-sm-6">
          <div className="form-group">
            <div className="form-group">
              <div className="todo-toolbar">
                <select
                  className="form-select form-select-md p-2"
                  value={reminder}
                  onChange={(e) => {
                    setreminder(e.target.value);
                  }}
                  required
                >
                  <option value="">Select</option>
                  <option value="5">5 mins</option>
                  <option value="10">10 mins</option>
                  <option value="15">15 mins</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div> */}
        {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
        <div className="d-flex justify-content-end ">
          <button
            type="button"
            className="btn btn-primary"
            disabled={
              loader ||
              !startTime ||
              title === "" ||
              date === "" ||
              (!isLeadDeal &&
                ((relatedTo === "Contact" && !selectContact) ||
                  (relatedTo === "Lead" && !selectlead) ||
                  (relatedTo === "Deal" && !selectdeal)))
            }
            onClick={() => {
              handleAddCall();
            }}
          >
            {loader ? <ApiLoader /> : "Save"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddCalls;
