import React, { useEffect, useState } from "react";
import {
  dateFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { graphEndPoints } from "../../../../api/Endpoints";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import NoData from "../../../../components/NoData/NoData";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";

const MonthlyTaskGraph = ({ user }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = months[new Date().getMonth()];
  const [statusGraphData, setstatusGraphData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [loader, setloader] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const year = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const lastDayOfMonth = new Date(year, thisMonth + 1, 0);
  const formattedStartDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-01`;
  const formattedEndDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;

  const handleTaskStatusGraph = async (e) => {
    startApiCall(null, setloader);
    const res = await ApiCall("get", graphEndPoints.taskStatusGraph, {
      params: {
        businessId: getCurrentWorkspaceId(),
        employeeId: user?._id,
        startDate: startDate ? startDate : formattedStartDate,
        endDate: endDate ? endDate : formattedEndDate,
        showAll: false,
      },
    });
    if (res?.success) {
      setstatusGraphData(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleTaskStatusGraph();
    }
  }, [selectedMonth]);

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);

    const selectedMonthIndex = months.indexOf(selectedMonth);
    if (selectedMonthIndex !== -1) {
      const month = selectedMonthIndex + 1;
      const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay
        .toString()
        .padStart(2, "0")}`;

      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const barChartOptions = {
    plugins: {
      title: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
      },
    },
  };

  const barChartData = {
    labels: statusGraphData?.map((data) => {
      return dateFormatter(data.date);
    }),

    datasets: [
      {
        label: "Completed",
        data: statusGraphData?.map((data) => data.complete),
        backgroundColor: ["#3862DD"],
      },
      {
        label: "Open",
        data: statusGraphData?.map((data) => data.open),
        backgroundColor: ["#DDF1FC"],
      },
    ],
  };

  return (
    <>
      {!loader && (
        <>
          <div className="card-header card-header-action p-0 pb-2">
            <h5 style={{ width: "60%" }}>Monthly Task</h5>
            <select
              className="form-select"
              style={{ width: "20%" }}
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {/* <option value={"selectmonth"}>Select Month</option> */}
              {months.map((mon, index) => (
                <option key={index} value={mon}>
                  {mon}
                </option>
              ))}
            </select>
          </div>
          {statusGraphData?.length === 0 ? (
            <NoData title="No tasks found" />
          ) : (
            <Chart type="bar" data={barChartData} options={barChartOptions} />
          )}
        </>
      )}
      {loader && (
        <div className="loading-skeleton p-3 card gap-3">
          <div className="loading-skeleton card-header card-header-action p-0 pb-2">
            <div className="d-flex justify-content-between w-100">
              <SkeletonConstant width="w-200p" height="h-25p" />
              <SkeletonConstant width="w-200p" height="h-25p" />
            </div>
          </div>
          <SkeletonConstant width="w-100" height="h-385p" />
        </div>
      )}
    </>
  );
};

export default MonthlyTaskGraph;
