import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import {
  convertTotalHoursToHoursAndMinutes,
  convertWithMomentForInput,
  customTimeFormater,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { attendanceRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import VerifyImageAndLocation from "./VerifyImageAndLocation";
import { Avatar } from "../../../../components/Avatar/Avatar";

const VerifyAttendanceDetails = (props) => {
  const {
    width,
    individualStaffData,
    handleStaffAttendancePerDay,
    handleGetAttendanceCount,
    setIsOpen,
    dailyDateFilter,
  } = props;
  const { user } = useSelector((state) => state);
  const [loginTime, setloginTime] = useState("");
  const [logoutTime, setlogoutTime] = useState("");
  const [loader2, setloader2] = useState(false);
  const [workingHours, setworkingHours] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [attendanceStatus, setattendanceStatus] = useState("present");
  const [lastLogoutTime, setlastLogoutTime] = useState("");
  const [imageAndLocation, setimageAndLocation] = useState([]);

  useEffect(() => {
    let totalMinutes = 0;
    individualStaffData?.attendances?.sessions?.map((session) => {
      const logintime = new Date(session?.loginTime);
      const logouttime = new Date(
        session?.logoutTime ? session?.logoutTime : logoutTime
      );
      const differenceInMilliseconds = logouttime - logintime;
      const differenceInMinutes = differenceInMilliseconds / (1000 * 60);
      totalMinutes += differenceInMinutes;
    });
    const totalHours = Math?.round(totalMinutes) / 60;
    const wholeHours = Math.floor(totalHours);
    const remainingMinutes = Math?.round(totalMinutes) % 60;

    const decimalMinutes = remainingMinutes / 60;
    const result = wholeHours + decimalMinutes;
    const roundedResult = Math?.round(result * 100) / 100;
    setworkingHours(roundedResult);
  }, [logoutTime, individualStaffData?.attendances?.sessions]);

  const handleCloseAttendanceVerify = () => {
    setIsOpen(false);
    seterrorMessage("");
    setloader2(false);
    setworkingHours("");
    setlogoutTime("");
  };

  const handleEditSession = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      attendanceId: individualStaffData?.attendances?._id,
      businessId: getCurrentWorkspaceId(),
      editorId: user?._id,
      isPresent: true,
      logoutTime: logoutTime?.split("T")[1],
      overTimeHours: "",
      isVerified: false,
      presentStatus: attendanceStatus,
      logoutDate: logoutTime?.split("T")[0],
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.editAttendance,
      data
    );
    if (res?.success) {
      handleVerifyAttendance();
    } else {
      seterrorMessage(res.error);
      setloader2(false);
    }
  };
  const handleVerifyAttendance = async (e) => {
    if (individualStaffData?.attendances?.isLeave) {
      e.preventDefault();
    }
    startApiCall(null, setloader2);
    const data = {
      attendanceId: individualStaffData?.attendances?._id,
      isVerified: true,
      verifiedWorkingHours: workingHours,
      verifiedBy: user?._id,
      employeeId: individualStaffData?.staff?.employeeId?._id,
      businessId: getCurrentWorkspaceId(),
      verifiedCompletedJobs: "",
      totalHours: "0",
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.verifyAttendance,
      data
    );
    if (res?.success) {
      toast.success("Attendance Verified");
      handleGetAttendanceCount();
      handleStaffAttendancePerDay();
      handleCloseAttendanceVerify();
      setloader2(false);
    } else {
      seterrorMessage(res.error);
      setloader2(false);
    }
  };
  useEffect(() => {
    let lastLogout = "";
    let firstLogin = "";

    if (
      individualStaffData?.attendances?.sessions &&
      individualStaffData?.attendances?.sessions?.length > 0
    ) {
      const firstSession = individualStaffData?.attendances?.sessions[0];
      const lastSession =
        individualStaffData?.attendances.sessions[
          individualStaffData?.attendances?.sessions?.length - 1
        ];
        firstLogin =convertWithMomentForInput(lastSession?.loginTime)
        lastLogout = lastSession?.logoutTime?convertWithMomentForInput(lastSession?.logoutTime):""
        
    }
    // setworkingHours(individualStaffData?.attendances?.totalHours);
    setloginTime(firstLogin);
    setlogoutTime(lastLogout);
    setlastLogoutTime(lastLogout);
  }, [individualStaffData]);

  const handleAttendanceChange = (event) => {
    const selectedAttendance = event.target.value;
    setattendanceStatus(selectedAttendance);
  };

  console.log("individualStaffData", individualStaffData);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: width }}
      >
        <form
          onSubmit={(e) => {
            if (!individualStaffData?.attendances?.isLeave) {
              handleEditSession(e);
            } else {
              handleVerifyAttendance(e);
            }
          }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <h4 className="mb-4 text_center">
                Verify attendance for{" "}
                <Avatar
                  name={individualStaffData?.staff?.employeeId?.name}
                  count={1}
                  image={individualStaffData?.staff?.employeeId?.image}
                  size="xxs"
                  color="primary"
                />{" "}
                <span className="text-muted">
                  {individualStaffData?.staff?.employeeId?.name}
                </span>
              </h4>
              {!individualStaffData?.attendances?.isLeave && (
                <div className="d-flex justify-content-center mt-2">
                  <div
                    className="alert alert-info text-primary fs-7"
                    role="alert"
                  >
                    <strong>Note:</strong> The current working hours are
                    displayed in the input field. You can modify this value if
                    needed.
                  </div>
                </div>
              )}

              <div className="row gx-3">
                <label className="form-label text_center">
                  {individualStaffData?.attendances?.isLeave
                    ? "Are You Sure You Want To Verfiy This Staff's Leave ?"
                    : "Are You Sure You Want To Verfiy This Staff's Attendance ?"}
                </label>
              </div>
              {!individualStaffData?.attendances?.isLeave && (
                <div className="row">
                  <div className="col-sm-12 mt-1">
                    <div className="form-group d-flex justify-content-center">
                      <label className="form-label pt-2 me-1 fs-7 mt-1">
                        First Login Date & Time :{" "}
                      </label>
                      <div className="d-flex align-items-center border rounded-5 text-dark ">
                        <div className="d-flex align-items-center justify-content-between">
                          <input
                            className="form-control border-0 text-dark"
                            type="datetime-local"
                            required
                            placeholder="LogoutTime"
                            disabled
                            value={loginTime}
                            onChange={(e) => {
                              setloginTime(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 mt-1">
                    <div className="form-group d-flex justify-content-center">
                      <label className="form-label pt-2 me-1 fs-7 mt-1">
                        Last Logout Date & Time :
                      </label>
                      <div className="d-flex align-items-center border rounded-5 text-dark ">
                        <div className="d-flex align-items-center justify-content-between">
                          <input
                            className="form-control border-0 text-dark"
                            type="datetime-local"
                            required
                            placeholder="LogoutTime"
                            disabled={lastLogoutTime}
                            value={logoutTime}
                            min={loginTime}
                            onChange={(e) => {
                              setlogoutTime(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!individualStaffData?.attendances?.isLeave && logoutTime && (
                <div className="col-sm-12 mt-1 d-flex justify-content-center gap-3">
                  <div className="form-group d-flex justify-content-center ">
                    <label className="form-label pt-2 me-2 fs-7 mt-1">
                      Working Hours :
                    </label>
                    <div className="d-flex align-items-center border rounded-5 text-dark">
                      <span className="d-flex align-items-center justify-content-center py-1 px-2 fs-7">
                        Hours
                      </span>

                      <div className="d-flex align-items-center justify-content-between w-120p py-1 border-start px-1">
                        <input
                          className="form-control border-0"
                          type="text"
                          required
                          placeholder="Hours"
                          value={workingHours}
                          onChange={(e) => {
                            setworkingHours(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center border rounded-5 text-dark mw-225p p-2 text-disabled h-50p">
                    {convertTotalHoursToHoursAndMinutes(workingHours)}
                  </div>
                </div>
              )}

              {!individualStaffData?.attendances?.isLeave && (
                <div className="row gx-3 mt-1">
                  <div className="col-sm-12">
                    <div className="form-inline">
                      <div className="form-group  d-flex justify-content-center">
                        <label className="form-label">Set Attendance:</label>
                        <div className="form-check form-check-inline ms-2">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc2"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              value="present"
                              onChange={handleAttendanceChange}
                              checked={attendanceStatus === "present"}
                              required
                            />
                            <label className="form-check-label">Present</label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc3"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              value="absent"
                              onChange={handleAttendanceChange}
                              checked={attendanceStatus === "absent"}
                              required
                            />
                            <label className="form-check-label">Absent</label>
                          </div>
                        </div>
                        <div className="form-check form-check-inline">
                          <div className="custom-control custom-radio radio-primary">
                            <input
                              type="radio"
                              id="customRadioc4"
                              name="customRadioc2"
                              className="form-check-input pointer"
                              value="half_day"
                              onChange={handleAttendanceChange}
                              checked={attendanceStatus === "half_day"}
                              required
                            />
                            <label className="form-check-label">Half day</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="text_center"></div>
              {(individualStaffData?.attendances?.gpsLocation ||
                individualStaffData?.attendances?.image) && (
                <div
                  className="text_center text-primary pointer text-underline"
                  data-bs-toggle="modal"
                  data-bs-target="#attendance_imglocation_modal"
                  onClick={() => {
                    setimageAndLocation(individualStaffData?.attendances);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-info-circl me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                  </svg>
                  See More
                </div>
              )}

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
            <div
              className="px-4 pb-4"
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                disabled={loader2}
                className="btn btn-rounded btn-secondary col-sm-5"
                onClick={handleCloseAttendanceVerify}
              >
                Cancel
              </button>
              <button
                disabled={loader2}
                className="btn btn-rounded btn-primary col-sm-5"
                type="submit"
              >
                {loader2 ? <ApiLoader /> : "Verify"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <VerifyImageAndLocation
        imageAndLocation={imageAndLocation}
        setimageAndLocation={setimageAndLocation}
      />
    </div>
  );
};

export default VerifyAttendanceDetails;
