import React, { useState } from "react";
import HolidayCalendar from "./HolidayCalendar";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import {
  dateFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { HolidayRelatedEndPoints } from "../../api/Endpoints";
import { useSelector } from "react-redux";
import ErrorMessage from "../../components/Error/ErrorMessage";
import { toast } from "react-toastify";
import constants from "../../components/Constant/Constants";

const AddHoliday = ({ setAddHoliday, handleGetAllHolidays }) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState([]);
  const [holidayName, setHolidayName] = useState("");

  const resetModal = () => {
    setloader(false);
    setErrorMessage("");
    setAddHoliday(false);
    setSelectedDate([]);
    setHolidayName("");
  };


  const handleAddHoliday = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      dates: selectedDate,
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
      holidayName,
    };
    const res = await ApiCall(
      "post",
      HolidayRelatedEndPoints.createHolidays,
      data
    );
    if (res?.success) {
      toast.success("Create holiday successfully!");
      resetModal();
      handleGetAllHolidays();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered "
        role="document"
        style={{ maxWidth: "85%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={resetModal}>
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">
                Add Holiday
              </h5>
              &nbsp;
              <CustomTooltip
                text="Select Date and Create Holiday by clicking the Add button on the screen"
                placement="right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-info-circle-fill mt-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
              </CustomTooltip>
            </span>
            <div className="border-bottom mb-2"></div>
            <div className="row mh-500p overflow-auto">
              <div className="col-lg-6 col-md-12">
                <HolidayCalendar
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                />
              </div>
              <div className="col-lg-6 col-md-12 border-start">
                <div className="mt-5 text-dark">
                  Selected Date :{" "}
                  {selectedDate?.length === 0 && "No Selected Date"}
                </div>
                <div className="d-flex flex-wrap justify-content-start mt-1">
                  {selectedDate?.map((date, index) => (
                    <div key={index} className="col-md-3 mb-2">
                      <span
                        className="btn d-flex align-items-center gap-1 mt-1 border w-140p bg-primary"
                        style={{ color: "#ffffff" }}
                      >
                        {dateFormatter(date)}
                        <span className="initial-wrap w-30p">
                          <svg
                            onClick={() => {
                              const updatedDates = [...selectedDate];
                              updatedDates.splice(index, 1);
                              setSelectedDate(updatedDates);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#fff"
                            className="bi bi-x-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                          </svg>
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
                <div className="row mt-10">
                  <div className="col-md-12">
                    <div className="form-group d-flex gap-2 justify-content-center">
                      <label className="form-label mt-1">Holiday Name : </label>
                      <input
                        className="form-control w-50"
                        type="text"
                        value={holidayName}
                        onChange={(e) => setHolidayName(e.target.value)}
                        placeholder="Enter holiday name"
                        maxLength={constants.HOLIDAY_MAX_LENGTH}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <div className="modal-footer align-items-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={resetModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!holidayName || selectedDate?.length === 0}
                className="btn btn-primary btn-add-tasklist"
                onClick={handleAddHoliday}
              >
                {loader ? <ApiLoader /> : "Add Holiday"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHoliday;
