import React, { useEffect, useState } from "react";
import CreditNoteDetailsHeader from "./CreditNoteDetailsHeader";
import CreditNotDetailsBody from "./CreditNotDetailsBody";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { useParams } from "react-router-dom";
import { startApiCall } from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { CreditNoteRelatedEndPoints } from "../../../../api/Endpoints";

const CreditNoteDetails = () => {
  const { id } = useParams();
  const [loader, setloader] = useState(false);
  const [creditNotesDetails, setCreditNotesDetails] = useState([]);

  // GET CREDIT NOTE DETAILS FUNCTION API CALL
  const handleGetCreditnotesDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      creditNoteId: id,
    };
    const res = await ApiCall(
      "post",
      CreditNoteRelatedEndPoints.getCreditNoteById,
      data
    );
    if (res?.success) {
      setCreditNotesDetails(res?.invoice);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetCreditnotesDetails();
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <CreditNoteDetailsHeader
            handleGetCreditnotesDetails={handleGetCreditnotesDetails}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && (
                <CreditNotDetailsBody
                  creditNotesDetails={creditNotesDetails}
                  id={id}
                  handleGetCreditnotesDetails={handleGetCreditnotesDetails}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditNoteDetails;
