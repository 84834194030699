import React, { useEffect, useState } from "react";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import LocationHeader from "./LocationHeader";
import LocationTable from "./LocationTable";
import EditLocation from "./EditLocation";
import LocationSkeleton from "./LocationSkeleton";
import { LocationRelatedEndPoints } from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { toast } from "react-toastify";
import NoData from "../../../components/NoData/NoData";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const Location = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditLocation, setIsEditLocation] = useState(false);
  const [isLocation, setIsLocation] = useState([]);
  const [editLocationData, seteditLocationData] = useState([]);

  const isModuleReadWriteAccessLocation =
    allModuleAccess?.[PRIVATEROUTES.LOCATION];

  const handleGetAllLocation = async () => {
    startApiCall(null, setloader);
    const res = await ApiCall("get", LocationRelatedEndPoints.getLocation, {
      params: {
        businessId: getCurrentWorkspaceId(),
      },
    });
    if (res?.success) {
      setIsLocation(res?.locations);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  useEffect(() => {
    handleGetAllLocation();
    closeAllModals();
  }, []);

  const handleDeleteLocation = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      isAdding: false,
      locationId: editLocationData?._id,
    };

    const res = await ApiCall(
      "post",
      LocationRelatedEndPoints.deleteLocation,
      data
    );
    if (res?.success) {
      handleClose();
      handleGetAllLocation();
      toast.success("Deleted Location Successfully");
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <LocationHeader handleGetAllLocation={handleGetAllLocation} />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                {!loader && isLocation?.length > 0 && (
                  <LocationTable
                    setIsOpen={setIsOpen}
                    setIsEditLocation={setIsEditLocation}
                    isLocation={isLocation}
                    seteditLocationData={seteditLocationData}
                    isModuleReadWriteAccessLocation={
                      isModuleReadWriteAccessLocation
                    }
                  />
                )}
                {!loader && isLocation?.length === 0 && <NoData />}
                {loader && <LocationSkeleton />}
              </div>

              <ConfirmModal
                isOpen={isOpen}
                onClose={handleClose}
                text="Are You Sure You Want To Delete This Location"
                loader={loader}
                errorMessage={errorMessage}
                handleConfirm={handleDeleteLocation}
              />
            </div>
          </div>
        </div>
        {isEditLocation && (
          <EditLocation
            isEditLocation={isEditLocation}
            setIsEditLocation={setIsEditLocation}
            seteditLocationData={seteditLocationData}
            editLocationData={editLocationData}
            handleGetAllLocation={handleGetAllLocation}
          />
        )}
      </div>
    </div>
  );
};

export default Location;
