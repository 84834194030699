import React, { useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const ImageModal = ({ image, setimage }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setloader] = useState(false);

  return (
    <>
      <div
        id="view_img_modal"
        className="modal fade add-new-task"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setimage([]);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5>Complaint Image</h5>
              <div className="border-bottom mb-2"></div>
              <form>
                <div>
                  <img
                    className="w-100 rounded h-450p"
                    style={{
                      backgroundRepeat: "no-repeat",
                      objectFit: "contain",
                      backgroundPosition: "center",
                    }}
                    src={image}
                    alt=""
                  />
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageModal;
