import React from "react";

const RawMaterialHeader = ({
  search,
  setSearch,
  setPage,
  setSelectCategory,
  isModuleReadWriteAccessForRawMaterial,
  handleRefresh,
  setstockType,
  setpageLimit,
}) => {
  const allCategories = [
    {
      name: "Vegetables",
    },
    {
      name: "Non-veg",
    },
    {
      name: "Ingredients",
    },
    {
      name: "Others",
    },
  ];

  const handleReset = () => {
    localStorage.setItem("rawMaterialCategory", "");
    setSelectCategory("");
    localStorage.setItem("rawMaterialStockType", "");
    setstockType("");
    localStorage.setItem("rawMaterialPage", 1);
    setPage(1);
    localStorage.setItem("rawMaterialPageLimit", 50);
    setpageLimit(50);
    localStorage.setItem("searchKey", "");
    setSearch("");
  };

  const clearSearch = () => {
    setSearch("");
    localStorage.setItem("searchKey", "");
  };

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1 className="header-title-font ">All Raw Materials</h1>
          {isModuleReadWriteAccessForRawMaterial && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                data-bs-toggle="modal"
                data-bs-target="#add_raw_material"
              >
                Add Raw Material
              </button>
            </div>
          )}
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="todo-toolbar me-3">
          <select
            className="form-select form-select-md"
            required
            value={
              localStorage.getItem("rawMaterialCategory") === ""
                ? ""
                : localStorage.getItem("rawMaterialCategory")
            }
            onChange={(e) => {
              localStorage.setItem("rawMaterialCategory", e.target.value);
              localStorage.setItem("rawMaterialPage", 1);
              setSelectCategory(e.target.value);
            }}
          >
            <option value="">All</option>
            {allCategories?.map((cat) => {
              return <option value={cat?.name}>{cat?.name}</option>;
            })}
          </select>
        </div>
        <div className="todo-toolbar me-3">
          <select
            className="form-select form-select-md"
            required
            value={
              localStorage.getItem("rawMaterialStockType") === ""
                ? ""
                : localStorage.getItem("rawMaterialStockType")
            }
            onChange={(e) => {
              localStorage.setItem("rawMaterialStockType", e.target.value);
              localStorage.setItem("rawMaterialPage", 1);
              setstockType(e.target.value);
            }}
          >
            <option value="">All Stock</option>
            <option value="low">Low Stock</option>
          </select>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span
            className="btn-icon-wrap"
            onClick={() => {
              handleRefresh();
              handleReset();
            }}
          >
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={
              localStorage.getItem("searchKey") === ""
                ? ""
                : localStorage.getItem("searchKey")
            }
            onChange={(e) => {
              localStorage.setItem("searchKey", e.target.value);
              setPage(1);
              setSearch(e.target.value);
            }}
            style={{ paddingRight: "30px" }}
          />
          {search && (
            <button
              type="button"
              onClick={clearSearch}
              style={{
                position: "absolute",
                right: "30px",
                top: "48%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "20px",
                padding: "0",
              }}
            >
              &times;
            </button>
          )}
        </form>
      </div>
    </header>
  );
};

export default RawMaterialHeader;
