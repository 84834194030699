import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers";

export default configureStore({
  reducer: reducers,
});

//**********HOW TO DISPATCH IT IMPLEMENTATION *********
  /* <button
              onClick={()=>{
                // dispatch(actionCreators.depositMoney())
                withDrawMoney(100)
              }}
              >
                  decrease
                </button>
                <button
                   onClick={()=>{
                    // dispatch(actionCreators.depositMoney())
                    depositMoney(100)
                  }}
                >
                  increase
                </button> */
  /* {amount} */
 
// **********IMPORT********
// import { useSelector,useDispatch } from 'react-redux'
// import  {bindActionCreators} from 'redux'
// import { actionCreators } from "../../../redux-setup";

// *********DEFINE*******
// const amount = useSelector(state=>state.amount)
// const dispatch  = useDispatch()
// const {depositMoney,withDrawMoney} = bindActionCreators(actionCreators,dispatch)
