import React from "react";
import { navigateToAllInvoices } from "../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

const GenerateInvoiceHeader = () => {
  const navigate = useNavigate();

  return (
    <>
      <header className="todo-header ">
        <div className="d-flex align-items-center gap-3">
          <div
            className="pointer"
            onClick={() => {
              navigate(navigateToAllInvoices());
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </div>
          <span className="todoapp-title link-dark">
            <h1>Generate Invoice</h1>
          </span>
        </div>
      </header>
    </>
  );
};

export default GenerateInvoiceHeader;
