import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { salaryRelatedEndPoints } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

const EditSalaryDetails = ({
  setisEditSalary,
  handleRefresh,
  id,
  salaryDetails,
  monthFormat,
  salaryInsightData,
  formattedDate
}) => {
  // const currentDate = new Date();
  // const year = currentDate.getFullYear();
  // const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  // const day = String(currentDate.getDate()).padStart(2, "0");
  // const formattedDate = `${year}-${month}-${day}`;

  const [advance, setAdvance] = useState();
  const [advanceName, setAdvanceName] = useState("");
  const [deduction, setDeduction] = useState();
  const [deductionName, setDeductionName] = useState("");
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const previousDeductionData = salaryInsightData?.deduction?.map((data) => ({
    name: data?.name,
    amount: data?.amount,
    date: data?.date?.split("T")[0],
  })) || [];  

  const previousAdvanceData = salaryInsightData?.advance?.map((data) => ({
    name: data?.name,
    amount: data?.amount,
    date: data?.date?.split("T")[0],
  })) || [];
  

  const handleReset = () => {
    setisEditSalary(false);
    setloader(false);
    setErrorMessage("");
  };

  // EDIT SALARY DETAILS FUNCTION API CALL
  const handleEditSalary = async () => {
    startApiCall(setErrorMessage, setloader);
    const deductionsArray = [
      {
        name: deductionName,
        amount: deduction,
        date: formattedDate,
      }
    ]
    const advanceArray = [
      {
        name: advanceName,
        amount: advance,
        date: formattedDate,
      },
    ];
    const data = {
      employeeId: id,
      businessId: getCurrentWorkspaceId(),
      // payableSalary: salaryDetails?.payableSalary ? salaryDetails?.payableSalary : 0,
      month: monthFormat,
      deduction: [...previousDeductionData,...deductionsArray],
      // advance: [...previousAdvanceData,...advanceArray],
    };
    const res = await ApiCall(
      "patch",
      salaryRelatedEndPoints.editSalaryInsight,
      data
    );
    if (res?.success) {
      handleReset();
      toast.success("Deduction added successfully!");
      handleRefresh();
    } else {
      setloader(false);
      setErrorMessage("");
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: 480 }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-4">
              <span>Add Deductions</span>
            </div>
            <form>
              {/* <div className="row gx-3">
                <div className="col-sm-4 mt-1">
                  <label className="form-label">Advance Name : </label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="text"
                    value={advanceName}
                    onChange={(e) => setAdvanceName(e.target.value)}
                  />
                </div>
              </div>
              <div className="row gx-3 mt-2">
                <div className="col-sm-4 mt-2">
                  <label className="form-label">Advance Amount : </label>
                </div>
                <div className="col-sm-8 mt-1">
                  <input
                    className="form-control"
                    type="number"
                    value={advance}
                    onChange={(e) => setAdvance(e.target.value)}
                  />
                </div>
              </div> */}
              <div className="row gx-3 mt-2">
                <div className="col-sm-4 mt-1">
                  <label className="form-label">Deduction Name : </label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="text"
                    value={deductionName}
                    onChange={(e) => setDeductionName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="row gx-3 mt-2">
                <div className="col-sm-4 mt-1">
                  <label className="form-label">Other Deduction : </label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="number"
                    value={deduction}
                    onChange={(e) => setDeduction(e.target.value)}
                    required
                  />
                </div>
              </div>
            </form>
          </div>
          {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

          <div className="modal-footer align-items-center">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={handleReset}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => handleEditSalary()}
            >
              {loader ? <ApiLoader /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSalaryDetails;
