import React, { useEffect, useState } from "react";
import {
  dateFormatter,
  getCurrentWorkspaceId,
  getTimeAgo,
  startApiCall,
  getName,
} from "../../global-functions/globalFunctions";
import { useParams } from "react-router-dom";
import ApiCall from "../../api/ApiCall";
import { logsEndPoints } from "../../api/Endpoints";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import { Avatar } from "../../components/Avatar/Avatar";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../components/NoData/NoData";
import Pagination from "../../components/Pagination/Pagination";
import LogsHeader from "./LogsHeader";
import { useSelector } from "react-redux";
import FooterPagination from "../../components/Pagination/FooterPagination";

function Logs() {
  const user = useSelector((state) => state.user);
  const { id, module } = useParams();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [logData, setlogData] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  // ******* DATE *********
  var date = new Date();
  var yesterday = new Date();
  yesterday.setDate(date.getDate() - 1);

  // ******* PAGINATION *********
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(50);
  const [totalPages, settotalPages] = useState(0);

  const handleGetLogs = async () => {
    startApiCall(null, setloader);
    const sendData = {
      page: page,
      limit: pageLimit,
      businessId: getCurrentWorkspaceId(),
      moduleId: module !== "Workspace" ? id : "",
      moduleType: module !== "Workspace" ? module : "",
      startDate: startDate,
      endDate: endDate,
    };
    const res = await ApiCall("post", logsEndPoints.getLogs, sendData);
    if (res?.success) {
      setlogData(res?.logs?.data);
      settotalPages(res?.logs?.totalPages);
      setCount(res?.logs?.totalCounts);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetLogs();
  }, [page, pageLimit]);

  const groupedLogs = logData?.reduce((groups, log) => {
    const createdAtDate = new Date(log?.createdAt).toDateString();
    if (!groups[createdAtDate]) {
      groups[createdAtDate] = [];
    }
    groups[createdAtDate].push(log);
    return groups;
  }, {});

  return (
    <div className="todoapp-wrap task-info todoapp-info">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <LogsHeader
            startDate={startDate}
            setstartDate={setstartDate}
            endDate={endDate}
            setendDate={setendDate}
            handleGetLogs={handleGetLogs}
            module={module}
            loader2={loader2}
          />
          <div className="todo-body pb-8">
            <div className="nicescroll-bar">
              <div className="py-1 container">
                {loader && <DataLoader title="Logs is loading..." />}
                {!loader && logData?.length === 0 && (
                  <div className="d-flex justify-content-center">
                    <NoData title="No Logs Found" />
                  </div>
                )}
                {!loader &&
                  logData?.length > 0 &&
                  Object?.entries(groupedLogs)?.map(
                    ([createdAtDate, logsForDate]) => (
                      <>
                        <div className="d-flex ailgn-items-center justify-content-between">
                          <div className="w-40">
                            <hr className="bg-grey-light-1" />
                          </div>
                          <div className="text-center text-dark fs-5 py-1">
                            {" "}
                            {dateFormatter(createdAtDate) ===
                            dateFormatter(date)
                              ? "Today"
                              : dateFormatter(createdAtDate) ===
                                dateFormatter(yesterday)
                              ? "Yesterday"
                              : dateFormatter(createdAtDate)}
                          </div>
                          <div className="w-40">
                            <hr className="bg-grey-light-1" />
                          </div>
                        </div>
                        {logsForDate?.map((log) => (
                          <div className="d-flex flex-column gap-2 mt-2">
                            <div className="d-flex align-items-center gap-3 p-2 rounded-4 bg-light shadow-hover-sm">
                              <span
                                className="avatar avatar-sm  text-white"
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                }}
                              >
                                <CustomTooltip
                                  text={
                                    log?.actionBy?.name
                                      ? log?.actionBy?.name
                                      : log?.actionOn?.name
                                  }
                                  placement="bottom"
                                >
                                  <Avatar
                                    name={
                                      log?.actionBy?.name
                                        ? log?.actionBy?.name
                                        : log?.actionOn?.name
                                    }
                                    count={1}
                                    image={
                                      log?.actionBy?.image
                                        ? log?.actionBy?.image
                                        : log?.actionOn?.image
                                    }
                                    size="xs"
                                  />
                                </CustomTooltip>
                              </span>

                              <div className="d-flex flex-column">
                                <p className="text-capitalize fs-7">
                                  <span className="text-medium-em font-2">
                                    {log?.actionTitle}
                                  </span>
                                  &nbsp;&nbsp;
                                  <span className="text-body font-2">
                                    {getTimeAgo(log?.createdAt)}
                                  </span>
                                </p>
                                <p className="text-dark">
                                  <span className="text-primary font-3">
                                    {getName(log?.actionBy, user?._id)}
                                    &nbsp;
                                  </span>

                                  <span className="text-high-em font-3 ">
                                    {log?.actionDescription}
                                  </span>

                                  {log?.actionOn && (
                                    <span className="text-primary font-3">
                                      &nbsp;
                                      <CustomTooltip
                                        text={log?.actionOn?.name}
                                        placement="bottom"
                                      >
                                        <Avatar
                                          name={log?.actionOn?.name}
                                          count={1}
                                          image={log?.actionOn?.image}
                                          size="xxs"
                                        />
                                      </CustomTooltip>
                                      &nbsp;
                                      {getName(log?.actionOn, user?._id)}
                                      &nbsp;
                                    </span>
                                  )}
                                  {/* {module === "Workspace" && (
                                    <span className="text-high-em font-3 ">
                                      &nbsp;of Folder1(Folder)
                                    </span>
                                  )} */}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )
                  )}
                {!loader && logData?.length > 0 && (
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={logData?.length}
                    align={"end"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logs;
