import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Performance from "../../pages/TaskAnalysis/Report/Performance";

const PerformanceRoute = {
  path: "/task",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <Performance />,
    },
    {
      path: "performance",
      element: <Performance />,
    },
  ],
};

export default PerformanceRoute;
