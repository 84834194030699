import React, { useState } from "react";
import {
  convertAmountLocal,
  dateAndTimeFormatter,
  getName,
} from "../../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  navigateToManageInvoiceDetails,
  navigateToVendorDetails,
} from "../../../Navigation/Navigation";
import { useSelector } from "react-redux";

const ManageStockTable = ({
  allStock,
  page,
  pageLimit,
  setEditStock,
  isModuleReadWriteAccessForAllStocks,
  setShowReason,
  setImageData
}) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [showGroupedStocks, setshowGroupedStocks] = useState(false);

  const toggleGroupedStocks = (stockId) => {
    setshowGroupedStocks((prevState) => ({
      ...prevState,
      [stockId]: !prevState[stockId],
    }));
  };

  const getTotalCountOfStockIn = (inv) => {
    const total = inv.reduce((accumulator, dt) => {
      const value = dt.price;
      return accumulator + parseInt(value);
    }, 0);
    return parseInt(total);
  };
  return (
    <div className="contact-list-view">
      <table id="datable_1" className="table nowrap w-100 mb-8">
        <thead>
          <tr className="text-center">
            <th>No</th>
            <th style={{ textAlign: "left" }}>Item Name</th>
            <th>Code</th>
            <th>Date</th>
            <th>Action By</th>
            <th>Category</th>
            <th>Quantity</th>
            <th>Stock Price</th>
            <th>Bill/Id/Challan</th>
            <th>Reason</th>
            <th>Order No</th>
            <th>Vendor</th>
            {/* {isModuleReadWriteAccessForAllStocks && <th>Action</th>} */}
          </tr>
        </thead>
        <tbody>
          {allStock?.map((stock, index) => {
            return (
              <>
                <tr className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td className="text-capitalize" style={{ textAlign: "left" }}>
                    {stock?.inventories.length === 1 && (
                      <>
                        {stock?.isAdded ? (
                          <span className="border px-2 me-2 bg-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              fill="#FFFFFF"
                              className="bi bi-plus-lg mb-1"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span className="border px-2 me-2 bg-danger">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill="#FFFFFF"
                              className="bi bi-dash-lg mb-1"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                              />
                            </svg>
                          </span>
                        )}
                      </>
                    )}
                    {stock?.inventories.length > 1 ? (
                      <>
                        <span
                          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                          onClick={() => {
                            toggleGroupedStocks(stock?._id);
                          }}
                        >
                          <span className="btn-icon-wrap">
                            {showGroupedStocks[stock?._id] ? (
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 23 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.8882 16.6574L4.39657 9.11624C3.63082 8.22668 4.26928 6.84936 5.44299 6.85884L18.5474 6.96478C19.7211 6.97426 20.3372 8.36173 19.5572 9.23879L12.9444 16.674C12.3951 17.2917 11.4275 17.2838 10.8882 16.6574Z"
                                  fill="black"
                                />
                              </svg>
                            ) : (
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16.6924 12.0348L9.15544 18.6297C8.26639 19.4076 6.875 18.7762 6.875 17.5949L6.875 4.40522C6.875 3.22388 8.26639 2.59251 9.15544 3.37043L16.6924 9.96525C17.3185 10.5131 17.3185 11.487 16.6924 12.0348Z"
                                  fill="black"
                                />
                              </svg>
                            )}
                          </span>
                        </span>
                        <span className="bg-success px-2 py-1  text-white ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#FFFFFF"
                            className="bi bi-plus-lg mb-1"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                            />
                          </svg>{" "}
                          {stock?.inventories.length} items
                        </span>
                      </>
                    ) : (
                      <span className="text-link">
                        {stock?.inventories?.[0]?.itemId?.name}
                      </span>
                    )}
                  </td>

                  <td>
                    {stock?.inventories.length > 1
                      ? "-"
                      : stock?.inventories?.[0]?.itemId?.inventoryItemCode}
                  </td>
                  <td>{dateAndTimeFormatter(stock?.createdAt)}</td>
                  <td>
                    {stock?.createdBy?.name ? (
                      <>
                        <Avatar
                          name={stock?.createdBy?.name}
                          count={1}
                          image={stock?.createdBy?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(stock?.createdBy, user?._id)}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-capitalize">
                    {stock?.inventories.length > 1
                      ? "Multiple"
                      : stock?.inventories?.[0]?.itemId?.category}
                  </td>
                  <td>
                    {stock?.inventories.length > 1 ? (
                      "-"
                    ) : (
                      <>
                        {Number(stock?.inventories?.[0]?.quantity).toFixed(2)}{" "}
                        {stock?.inventories?.[0]?.unit?.name}
                      </>
                    )}
                  </td>
                  <td className="text-dark font-mon font-3">
                    {stock?.inventories.length > 1
                      ? convertAmountLocal(
                          getTotalCountOfStockIn(stock?.inventories)
                        )
                      : stock?.inventories?.[0]?.price > 0
                      ? convertAmountLocal(stock?.inventories?.[0]?.price)
                      : "-"}
                  </td>
                  <td className="text-dark font-mon font-3">
                    {stock?.inventories?.[0]?.stockId?.billNumber ? (
                      <>
                        {stock?.inventories?.[0]?.stockId?.billNumber}&nbsp;
                        {stock?.images?.length > 0 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-info-circle-fill text-primary pointer"
                            viewBox="0 0 16 16"
                            data-bs-toggle="modal"
                            data-bs-target="#bill_img_modal"
                            onClick={() => {
                              setImageData(stock);
                            }}
                          >
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                          </svg>
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {stock?.isAdded === false && !stock?.orderId ? (
                      <span
                        onClick={() =>
                          setShowReason({ show: true, stock: stock })
                        }
                      >
                        <i
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="ri-information-line info-icon"
                        ></i>
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>
                    {stock?.orderId ? (
                      <span
                        className="pointer text-primary"
                        onClick={() =>
                          navigate(
                            navigateToManageInvoiceDetails({
                              id: stock?.orderId?._id,
                            }),
                            {
                              state: { subHeader: "Manage Stock" },
                            }
                          )
                        }
                      >
                        {stock?.orderId?.orderNumber
                          ?.toString()
                          .padStart(3, "0")}
                        <span className="btn btn-icon btn-rounded btn-sm">
                          <span nonce="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill="#3862DD"
                              className="bi bi-box-arrow-up-right mb-2"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                              />
                              <path
                                fill-rule="evenodd"
                                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td style={{ textAlign: "start" }}>
                    {/* {stock?.inventories?.length === 1 && stock?.isAdded === true
                      ? stock?.inventories?.[0]?.stockId?.vendorId?.name
                      : "-"} */}
                    {stock?.isAdded === true ? (
                      <span
                        className="text-truncate pointer text-link"
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                          navigate(
                            navigateToVendorDetails({
                              id: stock?.inventories?.[0]?.stockId?.vendorId
                                ?._id,
                            })
                          );
                        }}
                      >
                        <Avatar
                          name={
                            stock?.inventories?.[0]?.stockId?.vendorId?.name
                          }
                          count={1}
                          image={
                            stock?.inventories?.[0]?.stockId?.vendorId?.image
                          }
                          size="xxs"
                          color="primary"
                        />

                        <span className="ms-1">
                          {stock?.inventories?.[0]?.stockId?.vendorId?.name}{" "}
                        </span>
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  {/* {isModuleReadWriteAccessForAllStocks && (
                    <td>
                      {stock?.inventories.length > 1 ? (
                        "-"
                      ) : (
                        <>
                          {stock?.isAdded ? (
                            <span
                              className="feather-icon pointer text-primary"
                              data-bs-toggle="modal"
                              data-bs-target="#add_stock"
                              onClick={() => {
                                setEditStock({
                                  stock: stock?.inventories?.[0],
                                  logsId: stock?._id,
                                });
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                />
                              </svg>
                            </span>
                          ) : (
                            "-"
                          )}
                        </>
                      )}
                    </td>
                  )} */}
                </tr>

                {/* *********************GROPUED STOCKS ******************* */}
                {showGroupedStocks[stock?._id] && (
                  <>
                    {stock?.inventories?.map((grupStck, grupIndex) => (
                      <tr className="text-center">
                        <td>
                          {(page - 1) * pageLimit + (index + 1)}.{grupIndex + 1}{" "}
                          .
                        </td>
                        <td
                          className="text-capitalize text-dark"
                          style={{ textAlign: "left" }}
                        >
                          <span className="border px-2 me-2 bg-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              fill="#FFFFFF"
                              className="bi bi-plus-lg mb-1"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                              />
                            </svg>
                          </span>
                          {grupStck?.itemId?.name}
                        </td>
                        <td>{grupStck?.itemId?.inventoryItemCode}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{grupStck?.itemId?.category}</td>
                        <td>
                          {grupStck?.quantity} {grupStck?.unit?.name}
                        </td>
                        <td className="text-dark font-mon">
                          {convertAmountLocal(grupStck?.price)}
                        </td>
                        <td>-</td>
                        <td>-</td>
                        <td>
                          {stock?.orderId ? (
                            <span
                              className="pointer text-primary"
                              onClick={() =>
                                navigate(
                                  navigateToManageInvoiceDetails({
                                    id: stock?.orderId?._id,
                                  }),
                                  {
                                    state: { subHeader: "Manage Stock" },
                                  }
                                )
                              }
                            >
                              {stock?.orderId?.orderNumber
                                ?.toString()
                                .padStart(3, "0")}
                              <span className="btn btn-icon btn-rounded btn-sm">
                                <span nonce="feather-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    fill="#3862DD"
                                    className="bi bi-box-arrow-up-right mb-2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                                    />
                                    <path
                                      fill-rule="evenodd"
                                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>-</td>
                        {/* <td>-</td> */}
                        {/* {isModuleReadWriteAccessForAllStocks && (
                          <td>
                            {stock?.isAdded ? (
                              <span
                                className="feather-icon pointer text-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#add_stock"
                                onClick={() => {
                                  setEditStock({
                                    stock: grupStck,
                                    logsId: stock?._id,
                                  });
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                        )} */}
                      </tr>
                    ))}
                  </>
                )}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ManageStockTable;
