import React, { useState } from "react";
import hoverSound from "../../../assets/sounds/hover-sound.mpeg";
import { useSelector } from "react-redux";
import complaint_img from "../../../assets/images/complaint.jpg";
import {
  dateAndTimeFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import * as endPoint from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import ComplaintDetailsModal from "../AllComplaints/ComplaintDetails";
import EditComplaint from "./EditComplaint";
import { toast } from "react-toastify";
import Rating from "./Rating";

const MyComplaintCard = (props) => {
  const {
    complaint,
    setAllComplaints,
    page,
    index,
    pageLimit,
    isModuleReadWriteAccessMyGrievance,
  } = props;
  const [loader, setloader] = useState(false);
  const [isDetailsModalOpen, setisDetailsModalOpen] = useState(false);
  const [complaintVar, setcomplaintVar] = useState(complaint);
  const [isEditModal, setisEditModal] = useState(false);
  const [ratingModal, setRatingModal] = useState(false);
  const user = useSelector((state) => state.user);

  const handleCloseDetailsModal = () => {
    setisDetailsModalOpen(!isDetailsModalOpen);
  };
  const handleCloseEditModal = () => {
    setisEditModal(false);
  };

  const handleChangeStatus = async (val) => {
    startApiCall(null, setloader);
    setloader(true);
    // console.log(loader);
    const sendData = {
      status: val === "Reopen" ? "open" : "cancelled",
      lifeCycleName:
        val === "Reopen" ? "Grievance Reopened" : "Grievance Cancelled",
      userId: user._id,
      complaintId: complaint._id,
    };

    const res = await ApiCall(
      "post",
      endPoint.complaintEndPoints.changeComplaintStatus,
      sendData
    );
    if (res?.success) {
      toast.success(
        `Complaint ${val === "Reopen" ? "reopend" : "cancelled"} successfully!`
      );
      setAllComplaints();
      setcomplaintVar(res?.complaint);
      setloader(false);
    } else {
      console.log(res?.error);
      setloader(false);
    }
  };

  // const handleMouseEnter = () => {
  //   setIsHovered(true);
  //   playHoverSound();
  // };

  // const handleMouseLeave = () => {
  //   setIsHovered(false);
  // };
  const playHoverSound = () => {
    const audio = new Audio(hoverSound);
    audio.play().catch((error) => {
      console.error("Failed to play audio:", error);
    });
  };

  const lastComplaintProcessingIndex = complaint.complaintLifeCycle
    .map((item) => item.lifeCycleName)
    .lastIndexOf(
      "Grievance Reopened" ? "Grievance Reopened" : "Grievance processing"
    );

  const newComplaintLifeCycle = complaint.complaintLifeCycle.slice(
    lastComplaintProcessingIndex
  );

  return (
    <div key={complaint._id} className="complaint-wrapper d-flex">
      <span>
        <div className="complaint-index pt-2">
          {(page - 1) * pageLimit + (index + 1)} . &nbsp;
        </div>
      </span>

      <div
        className="card card-border p-3 my-complaint-card"
        style={{
          borderRadius: "15px",
          border: "1px solid #3862DD",
        }}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
      >
        <div className="position-relative">
          <span className="position-absolute" style={{ top: 0, right: "12px" }}>
            <span
              className="text-decoration-underline pointer ms-3 text-primary fs-7 fw-medium"
              onClick={() => {
                // setComplaintDetails(data);
                playHoverSound();
                setisDetailsModalOpen(!isDetailsModalOpen);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-info-circle me-1 mb-1"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
              </svg>
              View Details
            </span>
            {/* {isModuleReadWriteAccessMyGrievance && ( */}
            <div className="d-flex gap-2 justify-content-end">
              <input
                type="button"
                className={`btn btn-sm ${
                  complaintVar?.status === "closed" ||
                  complaintVar?.status === "cancelled"
                    ? "btn-outline-primary"
                    : "btn-outline-danger"
                }`}
                disabled={loader}
                value={
                  complaintVar?.status === "closed" ||
                  complaintVar?.status === "cancelled"
                    ? "Reopen"
                    : "Cancel"
                }
                onClick={(e) => handleChangeStatus(e.target.value)}
              />
            </div>
            {/* )} */}
          </span>
          {/* {isModuleReadWriteAccessMyGrievance && ( */}
          <>
            {(complaint?.status === "open" ||
              complaint?.status === "processing" ||
              complaint?.status === "resolved") && (
              <span
                className="position-absolute badge text-dark border border-primary bg-white circle d-flex align-items-center justify-content-center mnw-0 p-0"
                style={{
                  height: "27px",
                  width: "27px",
                  top: "-25px",
                  right: "-25px",
                }}
                onClick={() => setisEditModal(true)}
              >
                <label htmlFor="profilePhoto" className="pointer text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="blue"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                </label>
              </span>
            )}
          </>
          {/* )} */}

          <div className="row justify-content-between">
            <div className="col-lg-9 col-md-10">
              <div className="d-flex align-items-center">
                <div className="image-container">
                  <span className="image-link pointer">
                    <img
                      src={
                        complaint?.images.length
                          ? complaint?.images?.[0]
                          : complaint_img
                      }
                      alt=""
                      style={{ width: "118px", height: "130px" }}
                    />
                    {/* <div className="image-overlay">
                    <div className="click-here">Click Here</div>
                  </div> */}
                  </span>
                  {/* <input
                  type="file"
                  accept="image/*"
                  className="file-input"
                  ref={fileInputRef}
                  onChange={setFileForUpload}
                /> */}
                </div>

                <span className="fw-400 ms-2">
                  <h4>{complaintVar?.title}</h4>
                  <div className="complaint-description">
                    <p className="font-3 text-truncate w-350p">
                      {complaintVar?.description}
                    </p>
                    {/* <div className="see-more-button" >See More</div> */}
                  </div>

                  {complaint?.status !== "cancelled" && (
                    <div className="row align-items-center mt-2 ms-1">
                      <div className="col-md-3 col-sm-6 px-0">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="4.5"
                                fill="#00C172"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="216"
                              height="2"
                              viewBox="0 0 416 2"
                              fill="none"
                            >
                              <path
                                d="M0.5 1H415.5"
                                stroke={
                                  newComplaintLifeCycle?.some(
                                    (ls) =>
                                      ls.lifeCycleName ===
                                        "Grievance processing" &&
                                      ls?.status === "processing"
                                  )
                                    ? "#00C172"
                                    : "#979797"
                                }
                              />
                            </svg>
                          </div>
                          <span>Open</span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 px-0">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="4.5"
                                fill={
                                  newComplaintLifeCycle?.some(
                                    (ls) =>
                                      ls.lifeCycleName ===
                                        "Grievance processing" &&
                                      ls?.status === "processing"
                                  )
                                    ? "#00C172"
                                    : "#979797"
                                }
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="216"
                              height="2"
                              viewBox="0 0 416 2"
                              fill="none"
                            >
                              <path
                                d="M0.5 1H415.5"
                                stroke={
                                  newComplaintLifeCycle?.some(
                                    (ls) =>
                                      ls.lifeCycleName ===
                                        "Grievance Resolved" &&
                                      ls?.status === "resolved"
                                  )
                                    ? "#00C172"
                                    : "#979797"
                                }
                              />
                            </svg>
                          </div>
                          <span>Processing</span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 px-0">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="4.5"
                                fill={
                                  newComplaintLifeCycle?.some(
                                    (ls) =>
                                      ls.lifeCycleName ===
                                        "Grievance Resolved" &&
                                      ls?.status === "resolved"
                                  )
                                    ? "#00C172"
                                    : "#979797"
                                }
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="216"
                              height="2"
                              viewBox="0 0 416 2"
                              fill="none"
                            >
                              <path
                                d="M0.5 1H415.5"
                                stroke={
                                  newComplaintLifeCycle?.some(
                                    (ls) =>
                                      ls.lifeCycleName === "Grievance Closed" &&
                                      ls?.status === "closed"
                                  )
                                    ? "#00C172"
                                    : "#979797"
                                }
                              />
                            </svg>
                          </div>
                          <span>Resolved</span>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 px-0">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="4.5"
                                fill={
                                  newComplaintLifeCycle?.some(
                                    (ls) =>
                                      ls.lifeCycleName === "Grievance Closed" &&
                                      ls?.status === "closed"
                                  )
                                    ? "#00C172"
                                    : "#979797"
                                }
                              />
                            </svg>
                          </div>
                          <span>Closed</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {complaint?.status === "cancelled" && (
                    <>
                      <div className="d-flex flex-row align-items-center mt-2  justify-content-start ps-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="9"
                          height="9"
                          viewBox="0 0 9 9"
                          fill="none"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" fill={"red"} />
                        </svg>
                        &nbsp;
                      </div>
                      <div className="col-sm-12 d-flex flex-row align-items-center font-3 mt-2 justify-content-start">
                        <span>Cancelled</span>
                      </div>
                    </>
                  )}
                </span>
              </div>
            </div>
            <div className="col-lg-3 d-flex flex-wrap align-items-end">
              {complaint?.status === "closed" && (
                <>
                  {complaint?.rating?.rating === undefined ? (
                    <span
                      className="text-end w-100 mt-10 pointer text-primary"
                      onClick={() => {
                        setRatingModal(true);
                        setcomplaintVar(complaint?._id);
                      }}
                    >
                      Rate your experience
                    </span>
                  ) : (
                    <span className="text-end w-100 mt-10 text-dark fs-6">
                      Rating : {complaint?.rating?.rating}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 54 51"
                        fill="none"
                        className="ms-1 mb-1"
                      >
                        <path
                          d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
                          fill="#FFCE56"
                          stroke="#FFCE56"
                          stroke-width="2"
                        />
                      </svg>
                    </span>
                  )}
                </>
              )}
              <p className="text-end w-100">
                Registered on: {dateAndTimeFormatter(complaintVar?.createdAt)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {isEditModal && (
        <EditComplaint
          handleCloseEditModal={handleCloseEditModal}
          complaint={complaintVar}
          setcomplaintVar={setcomplaintVar}
          isEditModal={isEditModal}
        />
      )}
      {isDetailsModalOpen && (
        <ComplaintDetailsModal
          handleCloseDetailsModal={handleCloseDetailsModal}
          complaintDetails={complaintVar}
          user={user}
        />
      )}
      {ratingModal && (
        <Rating
          setRatingModal={setRatingModal}
          ratingModal={ratingModal}
          complaintVar={complaintVar}
          user={user}
          setAllComplaints={setAllComplaints}
        />
      )}
    </div>
  );
};

export default MyComplaintCard;
