import React, { useEffect, useState } from "react";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { LocationRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";

function SalaryMethod(props) {
  const {
    selectSetup2,
    handleAddShift,
    loader,
    errorMessage,
    completeThirdStep,
    salary,
    setsalary,
    advance,
    setadvance,
    deposit,
    setdeposit,
    modalType,
    handleEditShift,
    selectedOption,
  } = props;

  return (
    <div
      className="d-flex flex-column justify-content-between border rounded-7 p-4 w-100 gap-4 mh-450p"
      style={{ overflow: "auto" }}
    >
      <div className="d-flex flex-column gap-3">
        <div className="col-sm-12">
          <div className="form-group">
            <label className="form-label">Employee’s Salary </label>
            <div className="d-flex align-items-center border rounded-5 w-280p text-dark">
              <span className="d-flex align-items-center justify-content-center py-2 px-4">
                INR
              </span>

              <div className="d-flex align-items-center justify-content-between w-180p py-2 border-start px-4">
                <input
                  className="form-control border-0"
                  type="number"
                  required
                  placeholder="Salary"
                  value={salary}
                  onChange={(e) => {
                    setsalary(e.target.value);
                  }}
                  disabled={modalType === "edit"}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-sm-12">
          <div className="form-group">
            <label className="form-label">
              Deposit
              <p className="fs-7">
                Please enter any amount you owe the employee that is pending
              </p>
            </label>

            <div className="d-flex align-items-center border rounded-5 w-280p text-dark">
              <span className="d-flex align-items-center justify-content-center py-2 px-4">
                INR
              </span>

              <div className="d-flex align-items-center justify-content-between w-180p py-2 border-start px-4">
                <input
                  className="form-control border-0"
                  type="number"
                  required
                  placeholder="Deposit"
                  value={deposit}
                  onChange={(e) => {
                    setdeposit(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="form-group">
            <label className="form-label">
              Advance
              <p className="fs-7">
                Please enter any advances you have paid the employee
              </p>
            </label>

            <div className="d-flex align-items-center border rounded-5 w-280p text-dark">
              <span className="d-flex align-items-center justify-content-center py-2 px-4">
                INR
              </span>

              <div className="d-flex align-items-center justify-content-between w-180p py-2 border-start px-4">
                <input
                  className="form-control border-0"
                  type="number"
                  required
                  placeholder="Advance"
                  value={advance}
                  onChange={(e) => {
                    setadvance(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="alert alert-info text-primary fs-7" role="alert">
          <strong>Note:</strong>&nbsp;
          {selectedOption === "monthly"
            ? "Your pay will be calculated each month."
            : "This salary will be utilized as an hourly rate. The daily wage will be determined by multiplying the hours worked by this hourly rate."}
        </div>
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      <div className="d-flex align-items-center justify-content-between mt-4 pointer">
        <span
          onClick={() => selectSetup2()}
          className="d-flex align-items-center gap-1 text-dark"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-double-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          <span>Previous</span>
        </span>
        <div className="d-flex align-items-center justify-content-end mt-2">
          <button
            className="btn btn-sm btn-primary px-4"
            onClick={() => {
              if (modalType === "add") {
                handleAddShift();
              } else {
                handleEditShift();
              }
            }}
            disabled={!completeThirdStep}
          >
            {loader ? <ApiLoader /> : modalType === "add" ? "Add" : "Edit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SalaryMethod;
