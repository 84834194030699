import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";
import ApiCall from "../../../api/ApiCall";
import { businessEndpoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

function AddAccount({
  setaddAccountModal,
  handleGetBusinessDetails,
  businessDetails,
  editAccountDetails,
  seteditAccountDetails,
}) {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [accountHolderName, setaccountHolderName] = useState("");
  const [accountNumber, setaccountNumber] = useState("");
  const [ifscCode, setifscCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setaccountHolderName("");
    setifscCode("");
    setaccountNumber("");
    seteditAccountDetails("");
    setaddAccountModal(false);
  };

  useEffect(() => {
    if (editAccountDetails) {
      setaccountHolderName(editAccountDetails?.accountHolderName);
      setaccountNumber(editAccountDetails?.accountNumber);
      setifscCode(editAccountDetails?.ifscCode);
    }
  }, [editAccountDetails]);

  const handleAddSettings = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const updatedBankAccounts = [
      ...(businessDetails?.businessId?.bankAccounts || []),
      {
        accountHolderName: accountHolderName,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
      },
    ];
    const data = {
      businessId: getCurrentWorkspaceId(),
      bankAccounts: updatedBankAccounts,
      updatedBy: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.editBusiness, data);
    if (res?.success) {
      handleGetBusinessDetails();
      handleReset();
      toast.success("Account Details added successfully!");
      setloader(false);
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };
  const handleEditSettings = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const updatedBankAccounts = businessDetails?.businessId?.bankAccounts.map(
      (account) => {
        if (account._id === editAccountDetails._id) {
          return {
            ...account,
            accountHolderName: accountHolderName,
            accountNumber: accountNumber,
            ifscCode: ifscCode,
            _id: editAccountDetails._id,
          };
        }
        return account;
      }
    );
    const data = {
      businessId: getCurrentWorkspaceId(),
      bankAccounts: updatedBankAccounts,
      updatedBy: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.editBusiness, data);
    if (res?.success) {
      handleGetBusinessDetails();
      handleReset();
      toast.success("Account Details added successfully!");
      setloader(false);
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-md modal-dialog-centered"
        role="document"
      >
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Add Account</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (editAccountDetails !== "") {
                  handleEditSettings(e);
                } else {
                  handleAddSettings(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="col-sm-12">
                  <label className="text-dark fw-300 fs-6">
                    Account Holder Name {reuiredField}
                  </label>
                  <input
                    className="form-control w-100"
                    type="text"
                    required
                    value={accountHolderName}
                    onChange={(e) => setaccountHolderName(e.target.value)}
                  />
                </div>
                <div className="col-sm-12 mt-2">
                  <label className="text-dark fw-300 fs-6">
                    Account Number {reuiredField}
                  </label>
                  <input
                    className="form-control w-100"
                    type="number"
                    required
                    value={accountNumber}
                    onChange={(e) => setaccountNumber(e.target.value)}
                  />
                </div>
                <div className="col-sm-12 mt-2">
                  <label className="text-dark fw-300 fs-6">
                    IFSC Code {reuiredField}
                  </label>
                  <input
                    className="form-control w-100"
                    type="text"
                    required
                    value={ifscCode}
                    onChange={(e) => setifscCode(e.target.value)}
                  />
                </div>

                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!accountNumber || !accountHolderName || !ifscCode}
                >
                  {loader ? <ApiLoader /> : editAccountDetails ? "Edit" : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAccount;
