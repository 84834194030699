import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import EmployeeCrmCounts from "../../pages/CRM/EmployeeCrmCount/EmployeeCrmCounts";

const EmployeeCrmCountRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "employeeanalytics",
      element: <EmployeeCrmCounts />,
    },
  ],
};

export default EmployeeCrmCountRoutes;
