import React, { useEffect, useState } from "react";
import AddType from "./AddType";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { leaveRelatedEndPoints } from "../../../../api/Endpoints";
import TypeTable from "./TypeTable";
import NoData from "../../../../components/NoData/NoData";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

const LeaveType = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [allLeaveTypes, setAllLeaveTypes] = useState([]);
  const [loader, setloader] = useState(true);
  const [editLeaveType, setEditLeaveType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const isModuleReadWriteAccessForLeaveType =
    allModuleAccess?.[PRIVATEROUTES.LEAVE_TYPE];

  const handleGetAllLeaveTypes = async () => {
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      leaveRelatedEndPoints.getLeaveTypes,
      sendData
    );
    if (res?.success) {
      setAllLeaveTypes(res?.leaveType);
      setloader(false);
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllLeaveTypes();
      closeAllModals();
    }
  }, []);

  return (
    <>
      <div className="todoapp-wrap">
        <div className="todoapp-content">
          <div className="todoapp-detail-wrap">
            <header className="todo-header">
              <div className="d-flex align-items-center">
                <span className="todoapp-title  link-dark">
                  <h1>Leave Types</h1>
                  {isModuleReadWriteAccessForLeaveType && (
                    <div className="ms-3">
                      <button
                        className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
                        data-bs-toggle="modal"
                        data-bs-target="#add_leave_types"
                      >
                        Add Leave type
                      </button>
                    </div>
                  )}
                </span>
              </div>
              <div className="todo-options-wrap">
                <span
                  className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="dsdsd"
                  data-bs-original-title="Refresh"
                >
                  <span
                    className="btn-icon-wrap"
                    onClick={handleGetAllLeaveTypes}
                  >
                    <span className="feather-icon">
                      <i
                        className="ri-refresh-line"
                        title="Refresh business list"
                      ></i>
                    </span>
                  </span>
                </span>
              </div>
            </header>
            <div className="todo-body">
              <div data-simplebar className="nicescroll-bar">
                <div className="contact-list-view">
                  {!loader && allLeaveTypes?.length > 0 && (
                    <TypeTable
                      allLeaveTypes={allLeaveTypes}
                      editLeaveType={editLeaveType}
                      setEditLeaveType={setEditLeaveType}
                      handleGetAllLeaveTypes={handleGetAllLeaveTypes}
                      isModuleReadWriteAccessForLeaveType={
                        isModuleReadWriteAccessForLeaveType
                      }
                    />
                  )}
                  {loader && <DataLoader title="data is loading..." />}
                  {!loader && allLeaveTypes?.length === 0 && <NoData />}
                </div>
              </div>
            </div>
          </div>
          <AddType
            handleGetAllLeaveTypes={handleGetAllLeaveTypes}
            editLeaveType={editLeaveType}
            setEditLeaveType={setEditLeaveType}
          />
        </div>
      </div>
    </>
  );
};

export default LeaveType;
