import React from "react";
import { Link } from "react-router-dom";
import TokenImage from "../../assets/images/token_expired.jpeg";

const TokenExpired = () => {
  return (
    <div className="w-100 mnh-100vh mt-10">
      <div className="container">
        <div className="d-flex flex-column align-items-center pt-6">
          <div className="circle p-10 bg-blue-light-5 w-400p h-400p">
            <img className="w-100" src={TokenImage} alt="token expired" />
          </div>
          <p
            className="fs-1 fw-medium text-dark my-2"
            style={{ textAlign: "center" }}
          >
            INVALID / EXPIRED TOKEN.
          </p>
          <p className="w-60" style={{ textAlign: "center" }}>
            Hi, there your token token link has expired, because haven't use it.
            Token link expires in every 30 minutes and can only be used once. You
            can create one by clicking on Request button too.
          </p>
          <Link
            to="/home"
            className="btn btn-rounded btn-primary px-8 py-2 my-5"
          >
            Back to home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TokenExpired;
