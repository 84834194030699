import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Meeting from "../../pages/Communication/AllMeeting/Meeting";

const MeetingRoutes = {
  path: "/communication",
  element: <Navbar />,
  children: [
    {
      path: 'meetings',
      element: <Meeting />,
    },
  ],
};

export default MeetingRoutes;
