import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import CreditNotes from "../../pages/AccountManagement/CreditNote/CreditNotes";
import CreditNoteDetails from "../../pages/AccountManagement/CreditNote/CreditNoteDetails/CreditNoteDetails";
import AddCreditNote from "../../pages/AccountManagement/CreditNote/AddCreditNote/AddCreditNote";

const CreditNotesRoutes = {
  path: "/account",
  element: <Navbar />,
  children: [
    {
      path: "allcreditnotes",
      element: <CreditNotes />,
    },
    {
      path: "creditnotesdetails/:id",
      element: <CreditNoteDetails />,
    },
    {
      path: "addcreditnote",
      element: <AddCreditNote />,
    },
  ],
};

export default CreditNotesRoutes;
