import React, { useEffect, useState } from "react";
import DashboardHeader from "./Dashboard-header";
import DashboardBody from "./DashboardBody/Dashboard-body";
import DashboardFooter from "./Dashboard-footer";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState("tab_block_1");

  return (
    <>
      <div className="container-xxl">
        <DashboardHeader
          user={user}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
        <DashboardBody activeTab={activeTab} />
      </div>
      <DashboardFooter />
    </>
  );
};

export default Dashboard;
