import React, { useEffect, useRef, useState } from "react";
import ContactTimelineHeader from "./ContactTimelineHeader";
import TimelineCard from "./TimelineCard";
import ContactEmailModal from "../ContactEmailModal";
import PrintContact from "../PrintContact";
import { useLocation, useParams } from "react-router-dom";
import { startApiCall } from "../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../api/ApiCall";
import {
  CrmTaskRelatedEndPoints,
  LeadRelatedEndPoints,
} from "../../../../../api/Endpoints";

const ContactTimeline = () => {
  const { id, uniqueid } = useParams();
  const componentRef = useRef();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [emailDetailsModal, setEmailDetailsModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allLogs, setAllLogs] = useState([]);
  const [allEvents, setAllEvents] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // *****EVENT PAGINATION ******
  const [eventPage, seteventPage] = useState(1);
  const [eventPageLimit, seteventPageLimit] = useState(10);
  const [eventCount, seteventCount] = useState(0);
  const [totaleventPages, settotaleventPages] = useState(0);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleGetLogs = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        referenceId: id,
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLeadLogs, data);
    if (res?.success) {
      setAllLogs(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetLeadTasks = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: eventPage,
      limit: eventPageLimit,
      sortBy: "startTime",
      filters: {
        actionOn: id,
      },
    };
    const res = await ApiCall("post", CrmTaskRelatedEndPoints.getTask, data);
    if (res?.success) {
      setAllEvents(res?.result?.data);
      seteventCount(res?.result?.totalCounts);
      settotaleventPages(res?.result?.totalPages);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetLogs();
  }, [page, pageLimit]);

  useEffect(() => {
    handleGetLeadTasks();
  }, [eventPage, eventPageLimit]);

  const handleRefresh = () => {
    handleGetLogs();
    handleGetLeadTasks();
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ContactTimelineHeader
            setEmailDetailsModal={setEmailDetailsModal}
            componentRef={componentRef}
            id={id}
            handleRefresh={handleRefresh}
            uniqueid={uniqueid}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="contact-list-view ">
                <TimelineCard
                  allLogs={allLogs}
                  allEvents={allEvents}
                  loader={loader}
                  loader2={loader2}
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  seteventPage={seteventPage}
                  seteventPageLimit={seteventPageLimit}
                  eventPage={eventPage}
                  eventPageLimit={eventPageLimit}
                  eventCount={eventCount}
                  totaleventPages={totaleventPages}
                />
              </div>

              {emailDetailsModal && (
                <ContactEmailModal
                  setEmailDetailsModal={setEmailDetailsModal}
                  contactDetails={locationData?.contactDetails}
                  id={id}
                  handleGetLogs={handleGetLogs}
                  locationData={locationData}
                />
              )}
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <PrintContact ref={componentRef} />
        </div>
      </div>
    </div>
  );
};

export default ContactTimeline;
