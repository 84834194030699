import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToParticipantsGroups } from "../../../../Navigation/Navigation";

const GroupDetailsHeader = ({
  setAddparticipant,
  handleRefresh,
  locationData,
  isModuleReadWriteAccessForParticipantGroup,
}) => {
  const navigate = useNavigate();

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <button
            className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
            onClick={() => navigate(navigateToParticipantsGroups())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <p className="mb-0">Back</p>
          </button>
          <div className="v-separator d-lg-inline-block"></div>
          <h1 className="header-title-font text-capitalize">
            {locationData?.name}{" "}
          </h1>
          {isModuleReadWriteAccessForParticipantGroup && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                // data-bs-toggle="modal"
                // data-bs-target="#add_participant"
                onClick={() => {
                  setAddparticipant(true);
                }}
              >
                Add Participants
              </button>
            </div>
          )}
        </span>
      </div>
      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleRefresh}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
      </div>
    </header>
  );
};

export default GroupDetailsHeader;
