import React from "react";

const CreateEvent = () => {
  return (
    <div
      id="create_new_event"
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-4">Create New Event</h5>
            <form>
              <div className="row gx-3">
                <div className="col-sm-12 form-group">
                  <label className="form-label">Name</label>
                  <input className="form-control  cal-event-name" type="text" />
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12 form-group">
                  <div className="form-label-group">
                    <label>Note/Description</label>
                    <small className="text-muted">200</small>
                  </div>
                  <textarea className="form-control" rows="3"></textarea>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Start Date</label>
                    <input
                      className="form-control cal-event-date-start"
                      name="single-date-pick"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Start Time</label>
                    <input
                      className="form-control input-single-timepicker"
                      name="input-timepicker"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">End Date</label>
                    <input
                      className="form-control cal-event-date-end"
                      name="single-date-pick"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">End Time</label>
                    <input
                      className="form-control input-single-timepicker"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <label className="form-label">Location</label>
                  <div className="form-group">
                    <input className="form-control" type="text" />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-5">
                  <div className="form-group">
                    <label className="form-label">Category</label>
                    <input className="form-control" type="text" />
                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="form-group">
                    <label className="form-label">Visibility</label>
                    <div className="d-flex">
                      <select className="form-select me-3">
                        <option selected="" value="2">
                          Public
                        </option>
                        <option value="1">Private</option>
                      </select>
                      <div className="input-group color-picker w-auto">
                        <span className="input-group-text colorpicker-input-addon rounded-3">
                          <i></i>
                        </span>
                        <input
                          type="text"
                          className="form-control cal-event-color w-0 h-0 position-absolute opacity-0"
                          value="#009B84"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-inline">
                    <div className="form-group mt-3">
                      <label className="form-label">Set priority:</label>
                      <div className="form-check form-check-inline">
                        <div className="form-check">
                          <input
                            type="radio"
                            id="customRadioc1"
                            name="customRadioc2"
                            className="form-check-input"
                            checked=""
                          />
                          <label className="form-check-label">Urgent</label>
                        </div>
                      </div>
                      <div className="form-check form-check-inline">
                        <div className="form-check">
                          <input
                            type="radio"
                            id="customRadioc2"
                            name="customRadioc2"
                            className="form-check-input"
                          />
                          <label className="form-check-label">High</label>
                        </div>
                      </div>
                      <div className="form-check form-check-inline">
                        <div className="form-check">
                          <input
                            type="radio"
                            id="customRadioc3"
                            name="customRadioc2"
                            className="form-check-input"
                          />
                          <label className="form-check-label">Medium</label>
                        </div>
                      </div>
                      <div className="form-check form-check-inline">
                        <div className="form-check">
                          <input
                            type="radio"
                            id="customRadioc4"
                            name="customRadioc2"
                            className="form-check-input"
                          />
                          <label className="form-check-label">Low</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer align-items-center">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Discard
            </button>
            <button
              id="add_event"
              type="button"
              className="btn btn-primary fc-addEventButton-button"
              data-bs-dismiss="modal"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
