import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import MyToDoList from "../../pages/MyToDo/MyToDoList";

const MyTodoRoutes = {
  path: "/mytodo",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <MyToDoList />,
    },
  ],
};

export default MyTodoRoutes;
