import React, { useEffect, useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";
import ApiCall from "../../../api/ApiCall";
import { noticeRelatedEndPoints } from "../../../api/Endpoints";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import NoData from "../../../components/NoData/NoData";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";

const NoticeCard = () => {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [allNotice, setAllNotice] = useState([]);
  const [page, setPage] = useState(1);

  const handleGetAllNotices = async () => {
    startApiCall(null, setloader);
    const sendData = {
      page: page,
      limit: 1,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isDeleted: false,
        publishStatus: "published",
        searchKey: "",
        employeeId: user?._id,
        moduleId: "",
        noticeType: "",
      },
    };
    const res = await ApiCall(
      "post",
      noticeRelatedEndPoints.getAllNotices,
      sendData
    );

    if (res?.success) {
      setAllNotice(res?.notice?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllNotices();
    }
  }, []);
  return (
    <div className="card card-border mnh-350p">
      <div className="card-header">
        {!loader && <h5>Recent Notice</h5>}
        {loader && <h5 className="bg-skeleton w-200p h-30p"></h5>}
      </div>
      <>
        {!loader && allNotice?.length > 0 && (
          <div className="card-body">
            <div className="d-flex flex-column gap-1">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <Avatar
                    name={allNotice?.[0]?.raisedBy?.name}
                    image={allNotice?.[0]?.raisedBy?.image}
                    count={1}
                    size="xss"
                    color="primary"
                  />
                  <div className="d-flex flex-column text-dark">
                    <p>{allNotice?.[0]?.raisedBy?.name}</p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      className="bi bi-eye-slash-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                    &nbsp; {allNotice?.[0]?.unseenBy}
                  </span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M11.8125 9C11.8125 10.5533 10.5533 11.8125 9 11.8125C7.4467 11.8125 6.1875 10.5533 6.1875 9C6.1875 7.4467 7.4467 6.1875 9 6.1875C10.5533 6.1875 11.8125 7.4467 11.8125 9Z"
                        fill="#6F6F6F"
                      />
                      <path
                        d="M0 9C0 9 3.375 2.8125 9 2.8125C14.625 2.8125 18 9 18 9C18 9 14.625 15.1875 9 15.1875C3.375 15.1875 0 9 0 9ZM9 12.9375C11.1746 12.9375 12.9375 11.1746 12.9375 9C12.9375 6.82538 11.1746 5.0625 9 5.0625C6.82538 5.0625 5.0625 6.82538 5.0625 9C5.0625 11.1746 6.82538 12.9375 9 12.9375Z"
                        fill="#6F6F6F"
                      />
                    </svg>
                    &nbsp; {allNotice?.[0]?.seenBy}
                  </span>
                </div>
              </div>
              <div
                className="bg-light py-2 px-3 rounded-5 text-dark mb-2 h-120p simple-scrollbar mt-2"
                style={{ overflowY: "scroll" }}
              >
                <p className="fw-bold text-capitalize">
                  {allNotice?.[0]?.title}
                </p>
                <p className="fs-7 text-capitalize">
                  {allNotice?.[0]?.description}
                </p>
              </div>
              <div className="d-flex gap-3 align-items-center">
                <img
                  className="w-80p rounded-5"
                  src={allNotice?.[0]?.images[0]}
                  alt=""
                />
                {/* <div className="border rounded-5 py-2 px-3 d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="#E5C0C0"
                  className="bi bi-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z" />
                </svg>
                <div className="d-flex flex-column">
                  <p className="fw-bold text-dark fs-7">Invitation Card.pdf</p>
                  <p className="fs-8">| Size: 50 KB</p>
                </div>
              </div>
              <span className="text-primary text-decoration-underline">
                View
              </span>
            </div> */}
              </div>
              <div className="d-flex justify-content-end">
                <p className="fs-8">
                  Published on :{" "}
                  {dateAndTimeFormatter(allNotice?.[0]?.createdAt)}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="d-flex justify-content-center">
          {loader && (
            <div className="loading-skeleton w-100 d-flex flex-column gap-2 p-3">
              <div className="d-flex justify-content-between gap-3">
                <SkeletonConstant width="w-100p" height="h-30p" />
                <SkeletonConstant width="w-100p" height="h-30p" />
              </div>
              <SkeletonConstant width="w-100" height="h-120p" />
              <SkeletonConstant width="w-80p" height="h-60p" />
              <SkeletonConstant
                width="w-300p"
                height="h-25p"
                additional="ms-auto"
              />
            </div>
          )}
          {!loader && allNotice?.length === 0 && (
            <NoData title={`No Poll`} description={"There are no polls"} />
          )}
        </div>
      </>
    </div>
  );
};

export default NoticeCard;
