import React, { useEffect, useState } from "react";
import MulitpleStockTable from "./MulitpleStockTable";
import {
  getCurrentWorkspaceId,
  startApiCall,
  filterSearchData,
  reuiredField,
  handleUploadInDirectAws,
  handlePreviewFileInLocal,
} from "../../../global-functions/globalFunctions";
import {
  InventoryRelatedEndPoints,
  StockRelatedEndPoints,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { useSelector } from "react-redux";

function AddMultipleStock({
  setmultipleStockModal,
  handleGetAllStock,
  handleGetAllVendor,
  vendorLoader,
  allVendors,
}) {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [allMaterial, setAllMaterial] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectItem, setSelectItem] = useState("");
  const [unit, setUnit] = useState("");
  const [unitId, setunitId] = useState("");
  const [quantity, setquantity] = useState("");
  const [cost, setcost] = useState("");
  const [materialInTable, setmaterialInTable] = useState([]);
  const [inventoryId, setInventoryId] = useState("");
  const [inventoryItems, setinventoryItems] = useState([]);
  const [billNumber, setbillNumber] = useState("");
  const [image1, setimage1] = useState("");
  const [image2, setimage2] = useState("");
  const [image3, setimage3] = useState("");

  //Select Vendor
  const [selectedVendor, setselectedVendor] = useState("");
  const [vendorData, setvendorData] = useState("");
  const [vendorfFlter, setVendorFilter] = useState("");
  const filteredVendors = filterSearchData(allVendors, vendorfFlter);

  const allCategories = [
    {
      name: "Vegetables",
    },
    {
      name: "Non-veg",
    },
    {
      name: "Ingredients",
    },
    {
      name: "Others",
    },
  ];

  const filteredInvItems = filterSearchData(allMaterial, filter);

  const handleGetAllMaterial = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
        category: selectCategory,
      },
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getInventory,
      data
    );
    if (res?.success) {
      setAllMaterial(res?.inventory?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (selectCategory) {
      handleGetAllMaterial();
    }
  }, [selectCategory]);

  const handleReset = () => {
    setloader(false);
    setSelectCategory("");
    setSelectItem("");
    setquantity("");
    setcost("");
    setInventoryId("");
    // setvendorData("");
    // setselectedVendor("");
  };

  const handleResetImage = () => {
    setimage1("");
    setimage2("");
    setimage3("");
  }

  const handleAddItem = () => {
    startApiCall(null, setloader);

    const tableItem = {
      inventoryItem: {
        name: selectItem,
        _id: inventoryId,
      },
      quantity: quantity,
      cost: cost,
      category: selectCategory,
      unit: unit,
    };

    const inventoryItem = {
      itemId: inventoryId,
      quantity: quantity,
      price: cost,
      unit: unitId,
      perUnitPrice: cost / quantity,
    };
    setinventoryItems((prevItem) => [...prevItem, inventoryItem]);
    setmaterialInTable((prevItems) => [...prevItems, tableItem]);
    handleReset();
  };

  const handleDeleteItem = (index) => {
    const updatedMaterialInTable = [...materialInTable];
    const removeItem = updatedMaterialInTable.splice(index, 1)[0];
    setmaterialInTable(updatedMaterialInTable);

    const removeInventoryItems = inventoryItems?.filter(
      (item) => item?.itemId !== removeItem?.inventoryItem?._id
    );
    setinventoryItems(removeInventoryItems);
  };

  const totalStockPrice = materialInTable?.reduce(
    (acc, val) => acc + (parseFloat(val?.cost) || 0),
    0
  );

  // ADD MULTIPLE STOCK FUNCTION API CALL
  const handleAddMultipleStock = async () => {
    let isError = false;
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    if (image1 !== "" && image2 === "" && image3 === "") {
      try {
        const uploaded = await handleUploadInDirectAws(image1);
        if (uploaded.Location) {
          temp.push(uploaded.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    } else if (image1 !== "" && image2 !== "" && image3 === "") {
      try {
        const uploaded1 = await handleUploadInDirectAws(image1);
        const uploaded2 = await handleUploadInDirectAws(image2);
        if (uploaded1.Location && uploaded2?.Location) {
          temp.push(uploaded1.Location, uploaded2.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    } else if (image1 !== "" && image2 !== "" && image3 !== "") {
      try {
        const uploaded1 = await handleUploadInDirectAws(image1);
        const uploaded2 = await handleUploadInDirectAws(image2);
        const uploaded3 = await handleUploadInDirectAws(image3);
        if (uploaded1.Location && uploaded2?.Location && uploaded3.Location) {
          temp.push(uploaded1.Location, uploaded2.Location, uploaded3.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    }
    if (!isError) {
      const data = {
        stockType: "new",
        inventories: inventoryItems,
        businessId: getCurrentWorkspaceId(),
        billNumber: billNumber,
        isAdded: true,
        transactionType: "bulk",
        createdBy: user?._id,
        vendorId: selectedVendor,
        totalAmount: totalStockPrice,
        paymentMode: "",
        images: temp,
      };
      const res = await ApiCall("post", StockRelatedEndPoints.addStock, data);
      if (res?.success) {
        toast.success("Added stock successfully!");
        handleGetAllStock();
        handleReset();
        handleResetImage();
        setmultipleStockModal(false);
      } else {
        setloader(false);
        setErrorMessage(res?.error);
      }
    }
  };

  const handleChangeInput = async (field, value) => {
    const newValue = value.replace(/\D/g, "");
    switch (field) {
      // case "quantity":
      //   setquantity(newValue);
      //   break;
      case "cost":
        setcost(newValue);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setmultipleStockModal(false);
                handleReset();
                setmaterialInTable([]);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Add Multiple Stock</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            {materialInTable?.length > 0 && (
              <div className="d-flex justify-content-center mt-2">
                <div
                  className="alert alert-info text-primary fs-7"
                  role="alert"
                >
                  Selected Vendor :<strong> {vendorData}</strong>
                </div>
                <div
                  className="alert alert-info text-primary fs-7"
                  role="alert"
                >
                  Bill No. :<strong> {billNumber}</strong>
                </div>
              </div>
            )}
            {materialInTable?.length > 0 && (
              <MulitpleStockTable
                materialInTable={materialInTable}
                handleDeleteItem={handleDeleteItem}
              />
            )}
            <div className="border-bottom"></div>
            <div className="row gx-3 px-1 w-100 mt-3">
              {materialInTable?.length === 0 && (
                <div className="d-flex col-sm-6">
                  <span className="text-dark w-40 fw-lighter mt-1">
                    Select Vendor {reuiredField}
                    <span
                      className="pointer ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#add_vendor"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="primary"
                        class="bi bi-plus-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                      </svg>
                    </span>
                  </span>
                  &nbsp;
                  <div className="selectable-dropdown w-60">
                    <div className="dropdown selectable-dropdown">
                      <span
                        className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 `}
                        aria-expanded="false"
                        type="button"
                        data-bs-toggle="dropdown"
                        onClick={() => {
                          handleGetAllVendor();
                          setVendorFilter("");
                        }}
                      >
                        {selectedVendor ? (
                          <div className="d-flex justify-content-between">
                            {vendorData}
                            {selectedVendor !== "" && (
                              <button
                                type="button"
                                className="btn-close btn-sm ms-1"
                                onClick={() => {
                                  setselectedVendor("");
                                  setVendorFilter("");
                                  setvendorData("");
                                }}
                                aria-label="Close"
                              ></button>
                            )}
                          </div>
                        ) : (
                          "Select Vendor"
                        )}
                      </span>
                      <div role="menu" className="dropdown-menu ">
                        <form className="d-sm-block d-none" role="search">
                          <input
                            type="text"
                            placeholder="Search.."
                            className="form-control"
                            value={vendorfFlter}
                            onChange={(e) => setVendorFilter(e.target.value)}
                          />
                        </form>
                        {vendorLoader ? (
                          <span className="d-flex text-center mt-1">
                            Loading...
                          </span>
                        ) : (
                          <div
                            style={{
                              maxHeight: "20vh",
                              overflow: "auto",
                            }}
                          >
                            {filteredVendors?.map((vendor, index) => (
                              <div
                                className="dropdown-item text-capitalize"
                                key={vendor._id}
                                onClick={() => {
                                  setselectedVendor(vendor._id);
                                  setvendorData(vendor?.name);
                                }}
                              >
                                {index + 1}. {vendor?.name}
                              </div>
                            ))}
                          </div>
                        )}
                        {!vendorLoader && filteredVendors?.length === 0 && (
                          <span className="d-flex text-center mt-1">
                            No Vendors found
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex col-sm-6">
                <span className="text-dark w-40 fw-lighter mt-1">
                  Select Category {reuiredField}:
                </span>
                &nbsp;
                <select
                  className="form-select w-60"
                  required
                  value={selectCategory}
                  onChange={(e) => {
                    setSelectCategory(e.target.value);
                  }}
                >
                  <option value="">Select Category {reuiredField}</option>
                  {allCategories?.map((cat) => {
                    return <option value={cat?.name}>{cat?.name}</option>;
                  })}
                </select>
              </div>
              <div
                className={`d-flex col-sm-6 ${
                  materialInTable?.length === 0 ? "mt-4" : ""
                }`}
              >
                <span className="text-dark w-40 fw-lighter mt-2">
                  Select Item {reuiredField}:
                </span>
                &nbsp;
                <div className="selectable-dropdown w-60 mt-1">
                  <div className="dropdown selectable-dropdown">
                    <span
                      className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100  ${
                        selectCategory === "" ? "bg-light text-disabled" : ""
                      }`}
                      aria-expanded="false"
                      type="button"
                      data-bs-toggle="dropdown"
                      disabled={selectCategory === ""}
                      onClick={() => setFilter("")}
                    >
                      {selectItem ? (
                        <>
                          {selectItem}
                          {selectItem !== "" && (
                            <button
                              type="button"
                              className="btn-close btn-sm ms-2"
                              onClick={() => {
                                setSelectItem("");
                                setFilter("");
                                setUnit("");
                              }}
                              aria-label="Close"
                            ></button>
                          )}
                        </>
                      ) : (
                        "Select item"
                      )}
                    </span>
                    <div role="menu" className="dropdown-menu">
                      <form className="d-sm-block d-none" role="search">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search.."
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}
                        />
                      </form>
                      {loader2 ? (
                        <span className="d-flex text-center mt-1">
                          Loading...
                        </span>
                      ) : (
                        <div
                          style={{
                            maxHeight: "20vh",
                            overflow: "auto",
                          }}
                        >
                          {filteredInvItems?.map((inv) => (
                            <div
                              className={`dropdown-item text-capitalize  ${
                                materialInTable?.find(
                                  (item) => item?.inventoryItem?._id == inv?._id
                                )
                                  ? "disabled"
                                  : ""
                              }`}
                              key={inv._id}
                              onClick={() => {
                                setSelectItem(inv.name);
                                setInventoryId(inv?._id);
                                setUnit(inv?.unit?.name);
                                setunitId(inv?.unit?._id);
                              }}
                            >
                              {inv.name}
                            </div>
                          ))}
                        </div>
                      )}

                      {!filteredInvItems && (
                        <span className="d-flex text-center mt-1">
                          No Inventory found
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {materialInTable?.length === 0 && (
                <div className="d-flex col-sm-6 mt-4">
                  <span className="text-dark w-40 fw-lighter mt-1">
                    Vendor Id/Bill/Challan {reuiredField}:
                  </span>
                  &nbsp;
                  <input
                    type="text"
                    className="form-control w-60"
                    placeholder="Enter Bill Number"
                    value={billNumber}
                    onChange={(e) => setbillNumber(e.target.value)}
                  />
                </div>
              )}

              {materialInTable?.length === 0 && (
                <div className="row gx-3 mt-2 ">
                  <label className="form-label">Upload Bill Attachment</label>

                  <div className="d-flex dropify-square gap-3 mt-3">
                    {image1 ? (
                      <>
                        <input
                          type="file"
                          name="image"
                          id="multiplefile1"
                          accept="image/png,image/jpeg"
                          className="input-file"
                          disabled={image1}
                          onChange={(event) => {
                            setimage1(event);
                          }}
                        />
                        <label
                          htmlFor="multiplefile1"
                          className="position-relative pointer"
                        >
                          <img
                            htmlFor="multiplefile1"
                            id="local_show_img_1"
                            src={handlePreviewFileInLocal(image1)}
                            className="dropify-wrapper text-center"
                            alt="img1"
                          />
                          <span
                            className="position-top-end-overflow"
                            onClick={() => {
                              setimage1("");
                              setimage2("");
                              setimage3("");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </span>
                        </label>
                      </>
                    ) : (
                      <span className="dropify-wrapper text-center">
                        <input
                          type="file"
                          name="image"
                          id="multiplefile1"
                          accept="image/png,image/jpeg"
                          className="input-file"
                          onChange={(event) => {
                            setimage1(event);
                          }}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-upload"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                        </svg>
                        <label
                          htmlFor="multiplefile1"
                          className="pointer text-center"
                        >
                          Upload Image
                        </label>
                      </span>
                    )}
                    {image2 ? (
                      <>
                        <input
                          type="file"
                          name="image"
                          id="multiplefile2"
                          accept="image/png,image/jpeg"
                          className="input-file"
                          disabled={image2}
                          onChange={(event) => {
                            setimage2(event);
                          }}
                        />
                        <label
                          htmlFor="multiplefile2"
                          className="position-relative pointer"
                        >
                          <img
                            htmlFor="multiplefile2"
                            id="local_show_img_2"
                            src={handlePreviewFileInLocal(image2)}
                            className="dropify-wrapper text-center"
                            alt="img2"
                          />
                          <span
                            className="position-top-end-overflow"
                            onClick={() => {
                              setimage2("");
                              setimage3("");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </span>
                        </label>
                      </>
                    ) : (
                      <span
                        className={`dropify-wrapper text-center ${
                          !image1 ? "bg-light text-disabled" : ""
                        }`}
                      >
                        <input
                          type="file"
                          name="image"
                          id="multiplefile2"
                          accept="image/png,image/jpeg"
                          className="input-file"
                          disabled={!image1}
                          onChange={(event) => {
                            setimage2(event);
                          }}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-upload"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                        </svg>
                        <label
                          htmlFor="multiplefile2"
                          className="pointer text-center"
                        >
                          Upload Image
                        </label>
                      </span>
                    )}
                    {image3 ? (
                      <>
                        <input
                          type="file"
                          name="image"
                          id="multiplefile3"
                          accept="image/png,image/jpeg"
                          className="input-file"
                          disabled={image3}
                          onChange={(event) => {
                            setimage3(event);
                          }}
                        />
                        <label
                          htmlFor="multiplefile3"
                          className="position-relative pointer"
                        >
                          <img
                            htmlFor="multiplefile3"
                            id="local_show_img_3"
                            src={handlePreviewFileInLocal(image3)}
                            className="dropify-wrapper text-center"
                            alt="img3"
                          />
                          <span
                            className="position-top-end-overflow"
                            onClick={() => {
                              setimage3("");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-x"
                              viewBox="0 0 16 16"
                            >
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                          </span>
                        </label>
                      </>
                    ) : (
                      <span
                        className={`dropify-wrapper text-center ${
                          !image2 ? "bg-light text-disabled" : ""
                        }`}
                      >
                        <input
                          type="file"
                          name="image"
                          id="multiplefile3"
                          accept="image/png,image/jpeg"
                          className="input-file"
                          disabled={!image2}
                          onChange={(event) => {
                            setimage3(event);
                          }}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-upload"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                        </svg>
                        <label
                          htmlFor="multiplefile3"
                          className="pointer text-center"
                        >
                          Upload Image
                        </label>
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="d-flex col-sm-6 mt-4">
                <span className="text-dark w-40 fw-lighter mt-1">
                  Quantity {reuiredField}:
                </span>
                &nbsp;
                <input
                  type="number"
                  className="form-control w-60"
                  placeholder="Quantity.."
                  value={quantity}
                  pattern="[0-9.]*"
                  onChange={(e) => setquantity(e.target.value)}
                />
              </div>

              <div className="d-flex col-sm-6 mt-4">
                <span className="text-dark w-40 fw-lighter mt-1">
                  Unit {reuiredField}:
                </span>
                &nbsp;
                <input
                  type="text"
                  className="form-control w-60"
                  placeholder="Unit.."
                  value={unit}
                  disabled
                />
              </div>
              <div className="d-flex col-sm-6 mt-4">
                <span className="text-dark w-40 fw-lighter mt-1">
                  Cost {reuiredField}:
                </span>
                &nbsp;
                <input
                  type="number"
                  className="form-control w-60"
                  placeholder="Cost.."
                  value={cost}
                  onChange={(e) => handleChangeInput("cost", e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!inventoryId || !quantity || !cost || !vendorData}
                  onClick={handleAddItem}
                >
                  Add Product
                </button>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center mt-3 justify-content-between ">
                <span className="text-dark fw-semibold">
                  No of stock added : {materialInTable?.length}
                </span>
                <div>
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    disabled={loader}
                    onClick={() => {
                      handleReset();
                      setmultipleStockModal(false);
                    }}
                  >
                    Discard
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary me-2"
                    disabled={!materialInTable.length || loader}
                    onClick={() => {
                      handleAddMultipleStock();
                    }}
                  >
                    {loader ? <ApiLoader /> : "Finish"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMultipleStock;
