import React, { useEffect, useState } from "react";
import StockHeader from "./StockHeader";
import ManageStockTable from "./ManageStockTable";
import AddStock from "./AddStock";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  OrderRelatedEndPoints,
  VendorRelatedEndPoints,
} from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import NoData from "../../../components/NoData/NoData";
import CustomDateFilterStock from "./CustomDateFilterStock";
import AddMultipleStock from "./AddMultipleStock";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import AddVendor from "../../Vendors/AddVendor/AddVendor";
import VendorDetailsModal from "../../Vendors/VendorDetailsModal";
import ReasonDetails from "./ReasonDetails";
import BIllImagesModal from "../RawMaterial/StockDetails/BIllImagesModal";

const ManageStock = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [allStock, setAllStock] = useState([]);
  const [editStock, setEditStock] = useState("");
  const [imageData, setImageData] = useState("");
  const [dateType, setdateType] = useState("Today");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dateModal, setdateModal] = useState(false);
  const [selectCategory, setSelectCategory] = useState("");
  const [search, setSearch] = useState("");
  const [stockType, setStockType] = useState("");
  const [showReason, setShowReason] = useState(false);
  // Vendors
  const [allVendors, setAllVendors] = useState([]);
  const [vendorLoader, setvendorLoader] = useState(false);
  const [editVendor, setEditVendor] = useState("");
  const [vendorDetails, setVendorDetails] = useState(false);
  const [addStockRes, setaddStockRes] = useState(null);

  const isModuleReadWriteAccessForAllStocks =
    allModuleAccess?.[PRIVATEROUTES.MANAGE_STOCK];

  // ******* DATE *********
  const currentDate = new Date();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(currentDate.getDate() - 1);
  const [multipleStockModal, setmultipleStockModal] = useState(false);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  const handleResetFilters = () => {
    setstartDate("");
    setendDate("");
    setdateModal(false);
  };

  const handleGetAllStock = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isAdded: stockType === "new" ? true : stockType === "out" ? false : "",
        startDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Yesterday"
            ? reversesDateFormatter(yesterdayDate)
            : dateType === "Date Range"
            ? startDate
            : "",
        endDate:
          dateType === "Today"
            ? reversesDateFormatter(currentDate)
            : dateType === "Yesterday"
            ? reversesDateFormatter(yesterdayDate)
            : dateType === "Date Range"
            ? endDate
            : "",
        searchKey: search,
        category: selectCategory,
      },
    };
    const res = await ApiCall(
      "post",
      OrderRelatedEndPoints.getInventoryLogs,
      data
    );
    if (res?.success) {
      setAllStock(res?.logs?.data);
      setcount(res?.logs?.totalCounts);
      settotalPages(res?.logs?.totalPages);
      setdateModal(false);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && dateType !== "Date Range") {
      handleGetAllStock();
    } else if (
      getCurrentWorkspaceId() &&
      dateType === "Date Range" &&
      startDate &&
      endDate
    ) {
      handleGetAllStock();
    }
    closeAllModals();
  }, [page, pageLimit, search, selectCategory, stockType, dateType]);

  const handleGetAllVendor = async () => {
    startApiCall(null, setvendorLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.getVendor, data);
    if (res?.success) {
      setAllVendors(res?.vendor?.data);
      setvendorLoader(false);
    } else {
      setvendorLoader(false);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <StockHeader
            handleGetAllStock={handleGetAllStock}
            dateType={dateType}
            setdateType={setdateType}
            setdateModal={setdateModal}
            setSelectCategory={setSelectCategory}
            selectCategory={selectCategory}
            handleSearch={handleSearch}
            search={search}
            stockType={stockType}
            setStockType={setStockType}
            setmultipleStockModal={setmultipleStockModal}
            handleResetFilters={handleResetFilters}
            isModuleReadWriteAccessForAllStocks={
              isModuleReadWriteAccessForAllStocks
            }
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allStock?.length > 0 && (
                <ManageStockTable
                  allStock={allStock}
                  page={page}
                  pageLimit={pageLimit}
                  setEditStock={setEditStock}
                  setImageData={setImageData}
                  isModuleReadWriteAccessForAllStocks={
                    isModuleReadWriteAccessForAllStocks
                  }
                  setShowReason={setShowReason}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allStock?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allStock?.length}
                  align={"end"}
                />
              )}
              {!loader && allStock?.length === 0 && (
                <NoData title="No stock added" />
              )}
            </div>
          </div>
        </div>
        <CustomDateFilterStock
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader}
          handleResetFilters={handleResetFilters}
          dateModal={dateModal}
          handleGetAllStock={handleGetAllStock}
          dateType={dateType}
          setdateType={setdateType}
          setdateModal={setdateModal}
        />
        <AddStock
          handleGetAllStock={handleGetAllStock}
          isManageStock={true}
          setEditStock={setEditStock}
          editStock={editStock}
          handleGetAllVendor={handleGetAllVendor}
          allVendors={allVendors}
          vendorLoader={vendorLoader}
          setVendorDetails={setVendorDetails}
          setaddStockRes={setaddStockRes}
        />
        {multipleStockModal && (
          <AddMultipleStock
            setmultipleStockModal={setmultipleStockModal}
            handleGetAllStock={handleGetAllStock}
            handleGetAllVendor={handleGetAllVendor}
            allVendors={allVendors}
            vendorLoader={vendorLoader}
          />
        )}
        <AddVendor
          handleGetAllVendor={handleGetAllVendor}
          setEditVendor={setEditVendor}
          editVendor={editVendor}
        />
        {vendorDetails && (
          <VendorDetailsModal
            setVendorDetails={setVendorDetails}
            addStockRes={addStockRes}
          />
        )}
        {showReason && (
          <ReasonDetails
            setShowReason={setShowReason}
            showReason={showReason}
          />
        )}
        <BIllImagesModal editStock={imageData} setEditStock={setImageData} />
      </div>
    </div>
  );
};

export default ManageStock;
