import React, { useEffect } from "react";
import {
  convertCsvName,
  dateAndTimeFormatter,
  reuiredField,
} from "../../../global-functions/globalFunctions";
import SmallLoader from "../../../components/Loaders/SmallLoader";

function ExportCsv({
  setexportCsvModal,
  csvLimit,
  setCsvLimit,
  loader,
  handleGetCsvLeads,
  count,
  exportCsvModal,
  csvLeads,
  setCsvLeads,
}) {
  const handleClose = () => {
    setexportCsvModal(false);
    setCsvLimit("");
    setCsvLeads([]);
  };

  useEffect(() => {
    if (exportCsvModal && csvLimit > 0) {
      handleGetCsvLeads();
    }
  }, [exportCsvModal, csvLimit]);

  const downloadCSV = (e) => {
    e.preventDefault();
    let csvData = [];
    let additionalFields = new Set();

    // Prepare CSV data
    csvLeads?.forEach((dt, index) => {
      let leadData = {
        "Sr. No.": index + 1,
        title: dt.title,
        name: dt.name || "",
        email: dt.email || "",
        phone: dt.phone,
        Source: dt.source,
        "Current Status": dt.subStatusId?.name || "",
        address: dt.address || "",
        state: dt.state || "",
        city: dt.city || "",
        pincode: dt.pinCode || "",
        role: dt.role || "",
        "Date Created": dateAndTimeFormatter(dt.createdAt),
      };

      // Add additional info fields
      dt?.additionalInfo?.forEach((fld) => {
        const fieldName = convertCsvName(fld?.fieldName) || "Unknown Field";
        leadData[fieldName] = fld?.fieldValue || "";
        additionalFields.add(fieldName);
      });

      csvData.push(leadData);
    });

    // Extract headers from the data array
    const headers = [
      "Sr. No.",
      "title",
      "name",
      "email",
      "phone",
      "Source",
      "Current Status",
      "address",
      "state",
      "city",
      "pincode",
      "role",
      "Date Created",
      ...Array.from(additionalFields),
    ];

    // Convert header and data to CSV format
    const headerRow = headers.map((header) => `"${header}"`).join(",");
    const dataRows = csvData.map((obj) =>
      headers.map((header) => `"${obj[header] || ""}"`).join(",")
    );

    const csvContent = `data:text/csv;charset=utf-8,${headerRow}\n${dataRows.join(
      "\n"
    )}`;
    // Create a data URI and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "All_Leads.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateRangeOptions = () => {
    const totalLeads = count || 0;
    const options = [];
    for (let i = 0; i < totalLeads; i += 1000) {
      const start = i + 1;
      const end = Math.min(i + 1000, totalLeads);
      options.push(`${start}-${end}`);
    }
    return options;
  };

  const handleRangeChange = (e) => {
    const selectedRange = e.target.value;
    setCsvLimit(selectedRange);
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered w-500p"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body ">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2 text-capitalize">Export CSV File</h5>
            <div className="border-bottom"></div>
            <form onSubmit={downloadCSV}>
              <div className="d-flex justify-content-center mt-2">
                <div
                  className="alert alert-info text-primary fs-7"
                  role="alert"
                >
                  <strong>Note:</strong> You can download only CSV files with up
                  to <strong className="font-3">1,000</strong> leads at a time.
                  Select the range of leads to download from the dropdown below.
                </div>
              </div>
              <div className="row gx-3 px-3 mt-3 overflow-auto mh-600p">
                <div className="d-flex justify-content-center">
                  <div className="form-group">
                    <label className="form-label font-4">
                      Select range of leads to download {reuiredField}
                    </label>
                    <div className="form-group">
                      <div className="todo-toolbar">
                        <select
                          className="form-select form-select-md h-40"
                          name="leadSource"
                          required
                          value={csvLimit}
                          onChange={handleRangeChange}
                        >
                          <option value="">Select Range</option>
                          {generateRangeOptions().map((range, index) => (
                            <option key={index} value={index + 1}>
                              {range}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {loader && (
                <div className="d-flex flex-column mb-3">
                  <div className="col-12 d-flex justify-content-center h-20p py-1">
                    <SmallLoader />
                  </div>
                  <div className="col-12 d-flex justify-content-center">
                    Fetching data ...
                  </div>
                </div>
              )}
              {!loader && csvLeads?.length > 0 && (
                <div className="d-flex justify-content-center mt-3 mb-3">
                  <p className="text-dark me-2">
                    Your data is ready. Click the icon to download.
                  </p>
                  <span
                    className="download-icon text-primary"
                    onClick={downloadCSV}
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                    </svg>
                  </span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportCsv;
