import React, { useState } from "react";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../components/Error/ErrorMessage";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { folderEndpoint } from "../../api/Endpoints";
import PermissionPage from "../../components/PermisionModal/PermissionPage";
import { toast } from "react-toastify";
import constants from "../../components/Constant/Constants";

function AddFolder(props) {
  const { user, spaceId, handleGetFolderList } = props;
  const [folderName, setfolderName] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [makePrivate, setmakePrivate] = useState(true);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [removedEmployee, setremovedEmployee] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [closeModal, setcloseModal] = useState(false);

  const handleReset = () => {
    setfolderName("");
    setmakePrivate(false);
    seterrorMessage("");
    setloader(false);
    setSelectedEmployees([]);
    setcloseModal(true);
    const closeModalDom = document.getElementById("add_new_folder");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddEemployeeInFolder = async (id) => {
    startApiCall(seterrorMessage, setloader);
    let tempForSelected = [];
    let tempForRemoved = [];
    if (makePrivate) {
      selectedEmployees?.map((employeeId) => {
        tempForSelected.push({
          employeeId,
          isRemoved: false,
        });
      });
      removedEmployee?.map((employeeId) => {
        tempForRemoved.push({
          employeeId,
          isRemoved: true,
        });
      });
    }
    const sendData = {
      employees: tempForSelected?.concat(tempForRemoved),
      folderId: id,
      isPrivate: true,
      userId: user?._id,
    };
    const res = await ApiCall("post", folderEndpoint.addEmployee, sendData);
    if (res?.success) {
      handleGetFolderList();
      handleReset();
      toast.success("Add Folder successfully!");
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleCreateFolder = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const sendData = {
      spaceId: spaceId,
      name: folderName,
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
    };
    const res = await ApiCall("post", folderEndpoint.createFolder, sendData);
    if (res?.success) {
      if (makePrivate) {
        handleAddEemployeeInFolder(res?.folder?._id);
      } else {
        handleGetFolderList();
        handleReset();
        toast.success("Add Folder successfully!");
      }
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_new_folder"
      className="modal fade add-new-task"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" onClick={handleReset}>
                ×
              </span>
            </button>
            <h5>Add New Folder</h5>
            <p className="mb-4">
              You are granted limited license only for purposes of viewing the
              material contained on this Website.
            </p>
            <form onSubmit={handleCreateFolder}>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control task-name"
                      type="text"
                      required
                      value={folderName}
                      onChange={(e) => {
                        setfolderName(e.target.value);
                      }}
                      maxLength={constants.MAX_LENGTH}
                    />
                  </div>
                </div>
                <PermissionPage
                  makePrivate={makePrivate}
                  setmakePrivate={setmakePrivate}
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                  removedEmployee={removedEmployee}
                  setremovedEmployee={setremovedEmployee}
                  searchKey={searchKey}
                  setsearchKey={setsearchKey}
                  type="folder"
                  closeModal={closeModal}
                  infoData={spaceId}
                  isAddFolder={true}
                />
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFolder;
