import React from "react";

const StockHeader = ({
  handleGetAllStock,
  dateType,
  setdateType,
  setdateModal,
  selectCategory,
  setSelectCategory,
  search,
  handleSearch,
  stockType,
  setStockType,
  setmultipleStockModal,
  handleResetFilters,
  isModuleReadWriteAccessForAllStocks,
}) => {
  const allCategories = [
    {
      name: "Vegetables",
    },
    {
      name: "Non-veg",
    },
    {
      name: "Ingredients",
    },
    {
      name: "Others",
    },
  ];

  const allStockType = [
    {
      name: "Stock In",
      value: "new",
    },
    {
      name: "Stock Out",
      value: "out",
    },
  ];

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1 className="header-title-font">All Stocks Transactions</h1>
          {isModuleReadWriteAccessForAllStocks && (
            <>
              <div className="ms-3">
                <button
                  className="btn btn-xs btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                  data-bs-toggle="modal"
                  data-bs-target="#add_stock"
                >
                  Add / Reduce Stock
                </button>
              </div>
              <div className="ms-3">
                <button
                  className="btn btn-xs btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                  onClick={() => {
                    setmultipleStockModal(true);
                  }}
                >
                  Add Multiple Stock
                </button>
              </div>
            </>
          )}
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="todo-toolbar me-2">
          <select
            className="form-select form-select-sm mt-1"
            required
            value={selectCategory}
            onChange={(e) => {
              setSelectCategory(e.target.value);
            }}
          >
            <option value="">All Category</option>
            {allCategories?.map((cat) => {
              return <option value={cat?.name}>{cat?.name}</option>;
            })}
          </select>
        </div>
        <div className="todo-toolbar me-2">
          <select
            className="form-select form-select-sm mt-1"
            required
            value={stockType}
            onChange={(e) => {
              setStockType(e.target.value);
            }}
          >
            <option value="">All type</option>
            {allStockType?.map((data) => {
              return <option value={data?.value}>{data?.name}</option>;
            })}
          </select>
        </div>
        <div className="selectable-dropdown me-1">
          <div className="dropdown selectable-dropdown">
            <span
              className={`fs-7 fw-medium text-dark text-capitalize border rounded p-1 mt-1 px-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Date :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp;<span className="dropdown-text"> {dateType}</span>
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Today" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Today");
                    handleResetFilters();
                  }}
                >
                  1. Today
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Yesterday" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Yesterday");
                    handleResetFilters();
                  }}
                >
                  2. Yesterday
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Date Range" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Date Range");
                    setdateModal(true);
                  }}
                >
                  3. Date Range
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span
            className="btn-icon-wrap"
            onClick={() => {
              setdateType("Today");
              handleResetFilters();
              handleGetAllStock();
            }}
          >
            <span className="feather-icon">
              <i
                className="ri-refresh-line"
                title="Reset Filters & Refresh Stock list"
              ></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-lg-block d-sm-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
          />
        </form>
      </div>
    </header>
  );
};

export default StockHeader;
