import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  getAllEmployees,
  getAvatarClass,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import { Avatar } from "../../../../components/Avatar/Avatar";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

const AddParticipants = ({
  addParticipant,
  selectedAssigneeId,
  setselectedAssigneeId,
  handleClose,
  loader2,
  errorMessage,
  handleAddParticipant,
  allGroupMembers,
  filteredEmployeeIds,
  isSingle,
}) => {
  const user = useSelector((state) => state.user);
  const [allEmployees, setallEmployees] = useState([]);
  const [loader, setloader] = useState(false);
  const [searchKey, setsearchKey] = useState("");
  const filteredEmployees = filterSearchData(allEmployees, searchKey);

  const handleGetAllEmployee = async () => {
    startApiCall(null, setloader);
    const data = {
      pageLimit: 50000,
      pageNumber: 1,
      s2adminId: user?._id,
      searchKey: "",
      businessId: getCurrentWorkspaceId(),
    };

    const res = await getAllEmployees(data);
    setallEmployees(res?.data);
    setloader(false);
  };

  useEffect(() => {
    if (addParticipant) {
      handleGetAllEmployee();
    }
  }, [addParticipant]);

  const toggleEmployeeSelection = (emp) => {
    if (selectedAssigneeId?.includes(emp)) {
      const selectedAssigneeIds = selectedAssigneeId?.filter(
        (id) => id !== emp
      );
      setselectedAssigneeId([...selectedAssigneeIds]);
    } else {
      const selectedAssigneeIds = [...selectedAssigneeId, emp];
      setselectedAssigneeId([...selectedAssigneeIds]);
    }
  };

  const getEmployeeData = () => {
    let temp = [];

    selectedAssigneeId?.map((ae) => {
      allEmployees?.map((emp) => {
        if (emp?._id === ae) {
          temp.push(emp);
        }
      });
    });

    return temp;
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" onClick={handleClose} className="btn-close">
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-3">Add Participant</h5>
            <div className="border-bottom mb-3 p-0"></div>
            <form onSubmit={handleAddParticipant}>
              <div className="row gx-3 px-3">
                <div className="col-sm-12 d-flex justify-content-end align-items-center">
                  <span className="input-affix-wrapper input-search affix-border">
                    <input
                      type="text"
                      className="form-control bg-transparent"
                      data-navbar-search-close="false"
                      placeholder="Search People"
                      aria-label="Search"
                      value={searchKey}
                      onChange={(e) => {
                        setsearchKey(e.target.value);
                      }}
                    />
                    <span className="input-suffix">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              </div>

              <div className="collapse-simple gx-3 px-3">
                <div className="card">
                  <div className="card-header">
                    <span
                      role="button"
                      data-bs-toggle="collapse"
                      href="#space_members"
                      aria-expanded="true"
                      className="fw-light text-uppercase"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-people-fill"
                          viewBox="0 2 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>{" "}
                        People
                        <span class="badge badge-pill badge-sm badge-soft-primary ms-1">
                          {allEmployees?.length}
                        </span>
                      </span>

                      <div className="avatar-group avatar-group-sm avatar-group-overlapped me-6 pointer">
                        {getEmployeeData()
                          ?.slice(0, 4)
                          ?.map((emp, index) => {
                            let name = emp?.name;
                            const nameInitial = name?.[0]?.toUpperCase();
                            const avatarClass = getAvatarClass(nameInitial);
                            return (
                              <div
                                key={index}
                                className={`avatar avatar-xs ${avatarClass} avatar-rounded`}
                              >
                                <span className="initial-wrap">
                                  {nameInitial}
                                </span>
                              </div>
                            );
                          })}
                        {getEmployeeData()?.length > 4 && (
                          <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                            <span className="initial-wrap">
                              +{getEmployeeData().length - 4}
                            </span>
                          </div>
                        )}
                      </div>
                    </span>
                  </div>

                  <div id="space_members" className="collapse show">
                    <div className="card-body mh-300p overflow-auto">
                      {!loader && filteredEmployees?.length > 0 && (
                        <ul className="activity-list list-group list-group-flush">
                          {filteredEmployees?.map((employee) => {
                            return (
                              <>
                                <li
                                  className="list-group-item"
                                  key={employee.id}
                                >
                                  <div className="media">
                                    <div className="media-head">
                                      <Avatar
                                        name={employee?.name}
                                        count={1}
                                        image={employee?.image}
                                        size="xss"
                                        color="primary"
                                      />
                                    </div>
                                    <div className="media-body row">
                                      <div className="col-md-8 d-flex justify-content-between">
                                        <span
                                          className="text-dark"
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {employee.name}
                                        </span>
                                        {/* <span
                                          className={`badge badge-soft-primary`}
                                        >
                                          {getWorkSpaceRole(
                                            employee?.permissions,
                                            "roleName"
                                          )}
                                        </span> */}
                                      </div>

                                      <div className="col-md-4 d-flex justify-content-center">
                                        <input
                                          className="form-check-input success pointer"
                                          type="checkbox"
                                          disabled={allGroupMembers?.some(
                                            (participant) =>
                                              participant?._id === employee?._id
                                          )}
                                          checked={selectedAssigneeId?.includes(
                                            employee?._id
                                          )}
                                          onChange={() =>
                                            toggleEmployeeSelection(
                                              employee?._id
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      )}
                      {!loader && filteredEmployees?.length === 0 && (
                        <p className="text-capitalize text-center text-dark">
                          No employee found
                        </p>
                      )}

                      {loader && <DataLoader />}
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary btn-add-tasklist"
                  disabled={isSingle ? filteredEmployeeIds?.length === 0 : ""}
                >
                  {loader2 ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddParticipants;
