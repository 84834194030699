import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { ItemRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";

const AddItemInBills = ({
  billItems,
  setBillItems,
  setitems,
  editItem,
  setEditItem,
}) => {
  const [itemName, setItemName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [unit, setUnit] = useState("");
  const [itemData, setitemData] = useState([]);
  const [loader, setloader] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [quantity, setquantity] = useState("");
  const [total, settotal] = useState(0);
  const [filter, setFilter] = useState("");

  const filteredItems = allItems?.filter((data) =>
    data?.itemName?.toLowerCase()?.includes(filter.toLowerCase())
  );

  console.log("edit", editItem);

  useEffect(() => {
    if (editItem) {
      setitemData(editItem?.itemId?.itemName);
      setItemName(editItem?.itemId?._id);
      setItemCode(editItem?.itemId?.itemCode);
      setUnit(editItem?.itemId?.unit);
      setSalePrice(editItem?.rate);
      setquantity(editItem?.quantity);
      settotal(editItem?.total);
    }
  }, [editItem]);

  const handleReset = () => {
    setloader(false);
    // setItemName("");
    setItemCode("");
    setUnit("");
    setSalePrice("");
    setquantity("");
    setitemData("");
    settotal(0);
    setEditItem("");
    const closeModalDom = document.getElementById("add_bill_item");
    if (closeModalDom) {
      closeModalDom.click();
    }
  };

  const handleItemSelection = (selectedValue) => {
    const selectedItem = allItems.find((item) => item?._id === selectedValue);
    if (selectedItem) {
      setitemData(selectedItem);
    }
  };

  useEffect(() => {
    if (itemData) {
      setItemCode(itemData?.itemCode);
      setSalePrice(itemData?.salePrice);
      setUnit(itemData?.unit);
    }
  }, [itemData]);

  useEffect(() => {
    if (itemData?.salePrice !== salePrice) {
      settotal(salePrice * quantity || 0);
    } else {
      settotal(itemData?.salePrice * quantity || 0);
    }
  }, [itemData, quantity, salePrice]);

  const handleAddItem = () => {
    const newItem = {
      itemId: {
        itemName: editItem ? editItem?.itemId?.itemName : itemData?.itemName,
        itemCode,
        unit,
        _id: itemName,
      },
      rate: salePrice,
      quantity,
      total,
    };

    const item = {
      itemId: itemName,
      quantity: quantity,
      rate: salePrice,
      total: total,
    };

    const updatedItems = billItems.map((item) => {
      if (editItem && item.itemId.itemName === editItem.itemId.itemName) {
        return {
          ...item,
          rate: salePrice,
          quantity,
          total,
        };
      } else {
        return item;
      }
    });

    if (!editItem) {
      // setQuoteItems([...quoteItems, newItem]);
      setBillItems((prevItems) => [...prevItems, newItem]);
      setitems((prevItems) => [...prevItems, item]);
    } else {
      setBillItems(updatedItems);
      setitems(
        updatedItems?.map((item) => ({
          itemId: item?.itemId?._id,
          quantity: item?.quantity,
          rate: item?.rate,
          total: item?.total,
        }))
      );
    }

    handleReset();
  };

  const handleGetAllItems = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", ItemRelatedEndPoints.getItem, data);
    if (res?.success) {
      setAllItems(res?.item?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // useEffect(() => {
  //   if (getCurrentWorkspaceId()) {
  //     handleGetAllItems();
  //   }
  // }, []);

  return (
    <div
      id="add_bill_item"
      className="modal fade add-new-task "
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "50%" }}
      >
        <div className="modal-content mnh-375p">
          <div className="modal-body">
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>{editItem !== "" ? "Edit Item" : "Add Item"}</h5>
            <div className="border-top mb-3"></div>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <div class="form-group row w-100 mb-0">
                    <div className="col-sm-4">
                      <label class="form-label">Item name</label>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-group">
                        <div className="selectable-dropdown">
                          <div className="dropdown selectable-dropdown">
                            <span
                              className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                                editItem ? "bg-light text-disabled" : ""
                              }`}
                              aria-expanded="false"
                              type="button"
                              data-bs-toggle="dropdown"
                              disabled={editItem}
                              onClick={() => {
                                handleGetAllItems();
                                setFilter("");
                              }}
                            >
                              {itemData?.length !== 0 ? (
                                <div className="d-flex justify-content-between">
                                  {editItem ? itemData : itemData?.itemName}
                                  {itemData !== "" && (
                                    <button
                                      type="button"
                                      className="btn-close btn-sm ms-2"
                                      onClick={() => {
                                        setitemData([]);
                                        setFilter("");
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  )}
                                </div>
                              ) : (
                                "Select item"
                              )}
                            </span>
                            <div role="menu" className="dropdown-menu">
                              <form className="d-sm-block d-none" role="search">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search.."
                                  value={filter}
                                  onChange={(e) => setFilter(e.target.value)}
                                />
                              </form>
                              {loader ? (
                                <span className="d-flex text-center mt-1">
                                  Loading...
                                </span>
                              ) : (
                                <div
                                  style={{
                                    maxHeight: "25vh",
                                    overflow: "auto",
                                  }}
                                >
                                  {filteredItems?.map((item) => {
                                    const isDisabled = billItems?.some((data) =>
                                      data?.itemId?._id?.includes(item?._id)
                                    );
                                    return (
                                      <div
                                        className={`dropdown-item text-capitalize ${
                                          isDisabled
                                            ? "bg-light text-disabled"
                                            : ""
                                        }`}
                                        key={item._id}
                                        onClick={() => {
                                          if (!isDisabled) {
                                            setitemData(item);
                                            setItemName(item?._id);
                                          }
                                        }}
                                        disabled={isDisabled}
                                      >
                                        {item.itemName}
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {!loader && filteredItems?.length === 0 && (
                                <span className="d-flex text-center mt-1">
                                  No item found
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="todo-toolbar">
                          <select
                            required
                            disabled={editItem}
                            name="itemName"
                            className="form-select form-select-md"
                            value={itemName}
                            onChange={(e) => {
                              setItemName(e.target.value);
                              handleItemSelection(e.target.value);
                            }}
                          >
                            <option value="">Select Item</option>
                            {allItems?.map((item) => {
                              const isDisabled = billItems?.some((data) =>
                                data?.itemId?._id?.includes(item?._id)
                              );
                              return (
                                <option
                                  key={item?._id}
                                  value={item?._id}
                                  disabled={isDisabled}
                                >
                                  {item?.itemName}
                                </option>
                              );
                            })}
                          </select>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Item code</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control"
                        type="text"
                        value={itemCode}
                        disabled
                        onChange={(e) => {
                          setItemCode(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Selling price</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control"
                        type="number"
                        value={salePrice}
                        onChange={(e) => {
                          setSalePrice(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Unit</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control"
                        type="text"
                        disabled
                        value={unit}
                        onChange={(e) => {
                          setUnit(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Quantity</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control"
                        type="text"
                        value={quantity}
                        onChange={(e) => {
                          setquantity(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group row w-100">
                    <div className="col-sm-4">
                      <label class="form-label">Total</label>
                    </div>
                    <div className="col-sm-8">
                      <input
                        class="form-control"
                        type="text"
                        value={total}
                        onChange={(e) => {
                          settotal(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex modal-footer align-items-center justify-content-end gap-3 mt-5">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                >
                  Discard
                </button>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleAddItem}
                  disabled={!quantity}
                >
                  {editItem ? "Edit" : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItemInBills;
