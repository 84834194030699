import React from "react";

const TaskAnalysisHeader = (props) => {
  const {
    handleGetAllEmployees,
    currentDate,
    startDate,
    endDate,
    setstartDate,
    setendDate,
    loader,
    setsearch,
    search,
  } = props;
  return (
    <header className="todo-header">
      <h5 className="task-text mt-2">Task Overview</h5>
      <div className="todo-options-wrap">
        <input
          className="form-control w-150p"
          name="single-date-pick"
          type="date"
          value={startDate || localStorage.getItem("bizStartDate")}
          onChange={(e) => {
            setstartDate(e.target.value);
            localStorage.setItem("bizStartDate", e.target.value);
          }}
          max={endDate}
        />
        <p className="mt-2 ms-2 me-2 fw-bold text-dark">to</p>
        <input
          className="form-control w-150p"
          name="single-date-pick"
          type="date"
          value={endDate || localStorage.getItem("bizEndDate")}
          onChange={(e) => {
            setendDate(e.target.value);
            localStorage.setItem("bizEndDate", e.target.value);
          }}
          min={startDate}
          max={currentDate}
        />
        <button
          disabled={loader}
          onClick={handleGetAllEmployees}
          className="btn btn-primary btn-xs btn-outline-light flex-shrink-0 d-lg-inline-block   ms-2 pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            fill="#000"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
        </button>

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={() => {
            if (startDate !== currentDate && endDate !== currentDate) {
              localStorage.removeItem("bizStartDate");
              localStorage.removeItem("bizEndDate");
              setstartDate(currentDate);
              setendDate(currentDate);
            } else {
              handleGetAllEmployees();
            }
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search By Name"
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default TaskAnalysisHeader;
