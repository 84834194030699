import React, { useState } from "react";
import {
  convertAmountLocal,
  dateAndTimeFormatter,
  getName,
} from "../../../global-functions/globalFunctions";
import { Avatar } from "../../../components/Avatar/Avatar";
import NoData from "../../../components/NoData/NoData";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import { useSelector } from "react-redux";

export const skeletonsCountCard = (number) => {
  return Array.from({ length: number }, (_, index) => (
    <div
      key={index}
      className="card p-2 loading-skeleton"
      style={{ minWidth: "150px" }}
    >
      <SkeletonConstant width="w-100" height="h-50p" />
    </div>
  ));
};

const VendorTrscnDetails = ({
  vendorParentTransaction,
  page,
  pageLimit,
  handleGetVendorSubtransaction,
  vendorSubTransaction,
  loader4,
  loader3,
  loader5,
  setVendorPaymentModal,
  vendorAmounts,
  setiamgesData,
}) => {
  const user = useSelector((state) => state.user);
  const [showSubTransaction, setshowSubTransaction] = useState(false);

  const toggleSubTansaction = (subId) => {
    setshowSubTransaction((prevState) => ({
      ...prevState,
      [subId]: !prevState[subId],
    }));
  };
  return (
    <div className="col-md-12 col-sm-12">
      {!loader5 && (
        <div className="d-flex gap-3">
          <div className="card rounded-3">
            <div className="text-center py-2 px-4">
              <p className="text-dark fs-7">Total Amount</p>
              <p className="text-primary fs-6 font-mon mt-1">
                {convertAmountLocal(vendorAmounts?.totalAmount) || 0}{" "}
              </p>
            </div>
          </div>
          <div className="card rounded-3">
            <div className="text-center py-2 px-4">
              <p className="text-dark fs-7">Total Paid</p>
              <p className="fs-6 font-mon mt-1" style={{ color: "green" }}>
                {convertAmountLocal(vendorAmounts?.totalAmountPaid) || 0}{" "}
              </p>
            </div>
          </div>
          <div className="card rounded-3">
            <div className="text-center py-2 px-4">
              <p className="text-dark fs-7">Total Due</p>
              <p className="text-danger fs-6 font-mon mt-1">
                {convertAmountLocal(vendorAmounts?.totalDueAmount) || 0}{" "}
              </p>
            </div>
          </div>
          <div className="card rounded-3">
            <div className="text-center py-2 px-4">
              <p className="text-dark fs-7">Total Advance</p>
              <p className="text-primary fs-6 font-mon mt-1">
                {convertAmountLocal(vendorAmounts?.totalAdvance) || 0}{" "}
              </p>
            </div>
          </div>
          <div
            className="card rounded-3 border-primary btn btn-sm"
            style={{ background: "#E3EDFF" }}
          >
            <div
              className="d-flex text-center ps-5 pe-6 m-auto"
              onClick={() =>
                setVendorPaymentModal({ show: true, isAdvance: true })
              }
            >
              <span className="feather-icon pt-1">
                <svg
                  width="14"
                  height="9"
                  viewBox="0 0 14 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.50221 1.51281C8.40928 1.47119 8.3 1.53893 8.3 1.64668V2.64996C8.3 2.9261 8.07614 3.14996 7.8 3.14996H1.5V6.04996H7.8C8.07614 6.04996 8.3 6.27382 8.3 6.54996V7.55323C8.3 7.66098 8.40928 7.72872 8.5022 7.6871L12.4857 4.75431C12.4994 4.74422 12.5137 4.73483 12.5283 4.72619C12.6239 4.66988 12.6239 4.53003 12.5283 4.47372C12.5137 4.46508 12.4994 4.45569 12.4857 4.4456L8.50221 1.51281ZM7.3 1.64668C7.3 0.75125 8.2694 0.213817 9.02467 0.658915C9.03934 0.667557 9.05355 0.676942 9.06726 0.687033L13.0608 3.62717C13.7797 4.07433 13.7797 5.12558 13.0608 5.57274L9.06726 8.51288C9.05355 8.52297 9.03934 8.53236 9.02467 8.541C8.2694 8.98609 7.3 8.44866 7.3 7.55323V7.04996H1C0.723858 7.04996 0.5 6.8261 0.5 6.54996V2.64996C0.5 2.37382 0.723858 2.14996 1 2.14996H7.3V1.64668Z"
                    fill="#3862DD"
                  />
                </svg>
              </span>

              <p className="text-primary text-capitalize font-3">
                Pay
                <br />
                Advance
              </p>
            </div>
          </div>
        </div>
      )}

      {loader5 && <div className="d-flex gap-3">{skeletonsCountCard(5)}</div>}
      <div
        className="overflow-y-scroll simple-scrollbar"
        style={{ maxHeight: "60vh" }}
      >
        {!loader3 && vendorParentTransaction?.length > 0 && (
          <table
            id="datable_1"
            className="table table-sm nowrap text-center mb-8"
          >
            <thead>
              <tr>
                <th>No.</th>
                {/* <th>Stock code</th> */}
                <th>Bill</th>
                <th>T-Number</th>
                <th>Transaction by</th>
                <th>Total Amount</th>
                <th>Total Due</th>
                <th>Total Paid</th>
                <th>Transactions</th>
                <th>Mode</th>
                <th>Action</th>
              </tr>
            </thead>
            {vendorParentTransaction?.map((trnsctn, index) => {
              return (
                <tbody className="collapse-simple">
                  <tr>
                    <td>{(page - 1) * pageLimit + (index + 1)}.</td>
                    {/* <td>104</td> */}
                    <td>
                      {trnsctn?.billNumber ? trnsctn?.billNumber : "-"}{" "}
                      {trnsctn?.images?.length > 0 && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-info-circle-fill text-primary pointer"
                          viewBox="0 0 16 16"
                          data-bs-toggle="modal"
                          data-bs-target="#bill_img_modal"
                          onClick={() => {
                            setiamgesData(trnsctn);
                          }}
                        >
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                        </svg>
                      )}
                    </td>
                    <td>
                      {trnsctn?.transactionNumber
                        ? trnsctn?.transactionNumber
                        : "-"}
                    </td>
                    <td>
                      {trnsctn?.transactionDoneBy ? (
                        <>
                          <Avatar
                            name={trnsctn?.transactionDoneBy?.name}
                            count={1}
                            image={trnsctn?.transactionDoneBy?.image}
                            size="xxs"
                            color="primary"
                          />
                          &nbsp;
                          {getName(trnsctn?.transactionDoneBy?.name, user?._id)}
                        </>
                      ) : (
                        "-"
                      )}
                      &nbsp;
                      {dateAndTimeFormatter(trnsctn?.createdAt)}
                    </td>
                    <td className="text-primary  font-mon fs-6">
                      {convertAmountLocal(trnsctn?.mainAmount)}
                    </td>

                    <td className="text-danger fs-6 font-mon">
                      {convertAmountLocal(trnsctn?.dueAmount)}
                    </td>
                    <td className="fs-6 font-mon" style={{ color: "green" }}>
                      {convertAmountLocal(
                        trnsctn?.mainAmount - trnsctn?.dueAmount
                      )}
                    </td>

                    <td>
                      {trnsctn?.childTransactions?.length > 0 ? (
                        <span
                          className="d-flex gap-1 align-items-center justify-content-center text-primary"
                          onClick={() => {
                            handleGetVendorSubtransaction(trnsctn?._id);
                            toggleSubTansaction(trnsctn?._id);
                          }}
                        >
                          {trnsctn?.childTransactions?.length}&nbsp;
                          <span
                            className="icon btn btn-xs btn-icon btn-rounded"
                            style={{ background: "#E3EDFF" }}
                          >
                            <span className="feather-icon">
                              <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.54669 11.1984L2.76428 5.73283C2.20015 5.08811 2.658 4.0791 3.51468 4.0791H13.0795C13.9362 4.0791 14.3941 5.08811 13.8299 5.73283L9.04751 11.1984C8.65024 11.6525 7.94395 11.6525 7.54669 11.1984Z"
                                  fill="#3862DD"
                                />
                              </svg>
                            </span>
                          </span>
                        </span>
                      ) : (
                        "One Time"
                      )}
                    </td>
                    <td>
                      {trnsctn?.paymentMode ? trnsctn?.paymentMode : "NA"}
                    </td>
                    <td>
                      {trnsctn?.dueAmount > 0 ? (
                        <button
                          className="btn btn-xs btn-primary"
                          onClick={() => {
                            setVendorPaymentModal({
                              show: true,
                              isAdvance: false,
                              data: trnsctn,
                            });
                          }}
                        >
                          Pay
                        </button>
                      ) : (
                        <span className="fw-bold font-mon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#5dde57"
                            class="bi bi-check2-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                            <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                          </svg>
                          <span className="ms-1 text-success">PAID</span>{" "}
                        </span>
                      )}{" "}
                    </td>
                  </tr>
                  {showSubTransaction[trnsctn?._id] &&
                    !loader4[trnsctn?._id] && (
                      <tr key={trnsctn?._id}>
                        <td colSpan={10} className="py-0">
                          <div className="mh-200p overflow-y-scroll simple-scrollbar">
                            <table
                              id="datable_1"
                              className="table table-sm nowrap text-center mb-0"
                              style={{ background: "#F5F8FF" }}
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <div className="d-flex align-items-center justify-content-center gap-1">
                                      <svg
                                        width="17"
                                        height="17"
                                        viewBox="0 0 17 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8.8065 4.90571L13.5517 10.4037C14.1114 11.0522 13.6467 12.0581 12.79 12.0523L3.22542 11.9873C2.36875 11.9815 1.91777 10.9694 2.48627 10.3285L7.30572 4.89551C7.70606 4.4442 8.41234 4.449 8.8065 4.90571Z"
                                          fill="#3862DD"
                                        />
                                      </svg>
                                      Transactions{" "}
                                      <span className="text-smoke">
                                        # {trnsctn?.billNumber}
                                      </span>
                                    </div>
                                  </th>
                                  <th>T-Number</th>
                                  <th>Transaction By</th>
                                  <th>Paid Amount</th>
                                  <th>Due amount</th>
                                  <th>Mode</th>
                                </tr>
                              </thead>
                              {vendorSubTransaction[trnsctn?._id]?.map(
                                (subTrnsctn, subindex) => {
                                  return (
                                    <tbody>
                                      <tr>
                                        <td>
                                          {(page - 1) * pageLimit + (index + 1)}
                                          .{subindex + 1}. &nbsp;
                                        </td>
                                        <td>
                                          {subTrnsctn?.transactionNumber
                                            ? subTrnsctn?.transactionNumber
                                            : "-"}
                                        </td>

                                        <td>
                                          {subTrnsctn?.transactionDoneBy ? (
                                            <>
                                              <Avatar
                                                name={
                                                  subTrnsctn?.transactionDoneBy
                                                    ?.name
                                                }
                                                count={1}
                                                image={
                                                  subTrnsctn?.transactionDoneBy
                                                    ?.image
                                                }
                                                size="xxs"
                                                color="primary"
                                              />
                                              &nbsp;
                                              {getName(
                                                subTrnsctn?.transactionDoneBy
                                                  ?.name,
                                                user?._id
                                              )}
                                              &nbsp;
                                              {dateAndTimeFormatter(
                                                subTrnsctn?.createdAt
                                              )}
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </td>

                                        <td>
                                          <span
                                            className="fs-6 font-mon"
                                            style={{ color: "green" }}
                                          >
                                            {convertAmountLocal(
                                              subTrnsctn?.amountReceived
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          <span className="text-danger fs-6 font-mon">
                                            {convertAmountLocal(
                                              subTrnsctn?.dueAmount
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          {subTrnsctn?.paymentMode
                                            ? subTrnsctn?.paymentMode
                                            : "NA"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                }
                              )}
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}
                  {showSubTransaction[trnsctn?._id] &&
                    loader4[trnsctn?._id] && (
                      <tr colSpan={10}>
                        <td className="text-center">
                          <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton "></span>
                        </td>

                        <td className="text-center">
                          <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                        </td>
                        <td className="text-center">
                          <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                        </td>
                        <td className="text-center">
                          <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                        </td>
                        <td className="text-center">
                          <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                        </td>
                        <td className="text-center">
                          <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                        </td>
                        <td className="text-center">
                          <span class="placeholder-glow d-inline-block w-100 bg-light task-table-skeleton"></span>
                        </td>
                      </tr>
                    )}
                </tbody>
              );
            })}
          </table>
        )}
        {!loader3 && vendorParentTransaction?.length === 0 && (
          <NoData title={`No Transactions Found`} />
        )}
        {loader3 && <DataLoader title="Vendor's transactions is loading..." />}
      </div>
    </div>
  );
};

export default VendorTrscnDetails;
