import React, { useEffect, useState } from "react";
import TaskAnalysisHeader from "./TaskAnalysisHeader";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import NoData from "../../components/NoData/NoData";
import { useNavigate } from "react-router-dom";
import { navigateToTaskAnalysisDetails } from "../../Navigation/Navigation";
import TaskAnalysisBody from "../Task/AllTask/TaskAnalysisBody";
import { taskEndpoint } from "../../api/Endpoints";
import ApiCall from "../../api/ApiCall";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import EmployeeActivityModal from "./Report/EmployeeActivityModal";
import PRIVATEROUTES from "../../components/Constant/Route-Constants";

const TaskAnalysis = () => {
  const { allModuleAccess, user } = useSelector((state) => state);
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [allTaskOverview, setallTaskOverview] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [currentDate, setCurrentDate] = useState(today);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [userData, setuserData] = useState([]);
  const [activityModal, setactivityModal] = useState(false);
  const [search, setsearch] = useState("");

  const isModuleReadWriteAccessForTaskAnakysis =
    allModuleAccess?.[PRIVATEROUTES.Task_Overview];

  const handleGetAllEmployees = async () => {
    startApiCall(null, setloader);
    const sendData = {
      startDate: startDate,
      endDate: endDate,
      businessId: getCurrentWorkspaceId(),
      employeeId: "",
      searchKey: search,
    };
    const res = await ApiCall(
      "post",
      taskEndpoint.employeeTaskOverview,
      sendData
    );
    if (res?.success) {
      const sortedData = res.data.sort((a, b) => b.finalScore - a.finalScore);
      const combinedData = sortedData?.map((item) => ({
        ...item,
        dataType: "analytics",
      }));
      const combinedCheckData = res?.check
        ?.filter((task) => !task?.hasTask)
        ?.map((item) => ({
          ...item,
          dataType: "check",
        }));
      setallTaskOverview([...combinedData, ...combinedCheckData]);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && startDate !== "" && endDate !== "") {
      handleGetAllEmployees();
      closeAllModals();
    }
  }, [startDate, endDate, search]);

  useEffect(() => {
    setCurrentDate(today);
  }, []);

  useEffect(() => {
    if (!isModuleReadWriteAccessForTaskAnakysis) {
      navigate(navigateToTaskAnalysisDetails({ id: user?._id }));
    }
  }, []);

  useEffect(() => {
    if (startDate === "" && endDate === "") {
      setstartDate(
        localStorage.getItem("bizStartDate")
          ? localStorage.getItem("bizStartDate")
          : currentDate
      );
      setendDate(
        localStorage.getItem("bizEndDate")
          ? localStorage.getItem("bizEndDate")
          : currentDate
      );
    }
  }, [startDate, endDate, currentDate]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <TaskAnalysisHeader
            handleGetAllEmployees={handleGetAllEmployees}
            currentDate={currentDate}
            startDate={startDate}
            endDate={endDate}
            setstartDate={setstartDate}
            setendDate={setendDate}
            setsearch={setsearch}
            search={search}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {allTaskOverview?.length > 0 && !loader && (
                <TaskAnalysisBody
                  allTaskOverview={allTaskOverview}
                  setuserData={setuserData}
                  setactivityModal={setactivityModal}
                />
              )}

              {loader && (
                <DataLoader title="Your Employee of list is loading..." />
              )}
              {!loader && allTaskOverview?.check?.length === 0 && (
                <NoData title="No Employees" />
              )}
            </div>
          </div>
        </div>
        {activityModal && (
          <EmployeeActivityModal
            userData={userData}
            activityModal={activityModal}
            setactivityModal={setactivityModal}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </div>
    </div>
  );
};

export default TaskAnalysis;
