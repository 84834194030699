import React, { useEffect } from "react";

const AddressDetails = (props) => {
  const {
    shippingAddress,
    setshippingAddress,
    billingAddress,
    setbillingAddress,
    customerData,
  } = props;

  useEffect(() => {
    if (customerData) {
      setbillingAddress({
        country: customerData?.billingAddress?.country,
        city: customerData?.billingAddress?.city,
        zipCode: customerData?.billingAddress?.pinCode,
        address: customerData?.billingAddress?.street,
      });
      setshippingAddress({
        country: customerData?.shippingAddress?.country,
        city: customerData?.shippingAddress?.city,
        zipCode: customerData?.shippingAddress?.pinCode,
        address: customerData?.shippingAddress?.street,
      });
    }
  }, [customerData]);

  const handleChangeBillingAddress = (e) => {
    const { name, value } = e.target;
    setbillingAddress({
      ...billingAddress,
      [name]: value,
    });
  };

  const handleChangeShippingAddress = (e) => {
    const { name, value } = e.target;
    setshippingAddress({
      ...shippingAddress,
      [name]: value,
    });
  };
  return (
    <>
      <div className="d-flex flex-column gap-2">
        <div className="row w-100">
          <div className="col-lg-12">
            <p className="text-dark fw-semibold mb-3">Billing Address</p>
            <div className="d-flex flex-column">
              <div className="form-group row w-100">
                <div className="col-sm-4">
                  <label className="form-label">Country</label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="text"
                    name="country"
                    value={billingAddress.country}
                    onChange={handleChangeBillingAddress}
                  />
                </div>
              </div>
              <div className="form-group row w-100">
                <div className="col-sm-4">
                  <label className="form-label">City</label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="text"
                    name="city"
                    value={billingAddress.city}
                    onChange={handleChangeBillingAddress}
                  />
                </div>
              </div>
              <div className="form-group row w-100">
                <div className="col-sm-4">
                  <label className="form-label">Zip code</label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="text"
                    name="zipCode"
                    value={billingAddress.zipCode}
                    onChange={handleChangeBillingAddress}
                  />
                </div>
              </div>
              <div className="form-group row w-100">
                <div className="col-sm-4">
                  <label className="form-label">Address</label>
                </div>
                <div className="col-sm-8">
                  <textarea
                    className="form-control"
                    rows="2"
                    name="address"
                    value={billingAddress.address}
                    onChange={handleChangeBillingAddress}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <p className="text-dark fw-semibold mb-3">Shipping Address</p>
            <div className="d-flex flex-column">
              <div className="form-group row w-100">
                <div className="col-sm-4">
                  <label className="form-label">Country</label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="text"
                    name="country"
                    value={shippingAddress.country}
                    onChange={handleChangeShippingAddress}
                  />
                </div>
              </div>
              <div className="form-group row w-100">
                <div className="col-sm-4">
                  <label className="form-label">City</label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="text"
                    name="city"
                    value={shippingAddress.city}
                    onChange={handleChangeShippingAddress}
                  />
                </div>
              </div>
              <div className="form-group row w-100">
                <div className="col-sm-4">
                  <label className="form-label">Zip code</label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="text"
                    name="zipCode"
                    value={shippingAddress.zipCode}
                    onChange={handleChangeShippingAddress}
                  />
                </div>
              </div>
              <div className="form-group row w-100">
                <div className="col-sm-4">
                  <label className="form-label">Address</label>
                </div>
                <div className="col-sm-8">
                  <textarea
                    className="form-control"
                    rows="2"
                    name="address"
                    value={shippingAddress.address}
                    onChange={handleChangeShippingAddress}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-dark">
          <span className="fw-semibold">GST : </span>{" "}
          {customerData?.gst ? customerData?.gst : "-"}
        </p>
      </div>
    </>
  );
};

export default AddressDetails;
