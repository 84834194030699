import React from "react";

function CustomerDetailsModal({ setisCustomerModal, itemDetails }) {
  // console.log("first",itemDetails)
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisCustomerModal(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Customer Information</h5>
            <div className="border-bottom mb-2"></div>
            <p>Name :&nbsp;{itemDetails?.buyer?.name}</p>
            <p>Phone :&nbsp;{itemDetails?.buyer?.phone}</p>
            <p>Email :&nbsp;{itemDetails?.buyer?.email}</p>
            <p>Address :&nbsp;{itemDetails?.buyer?.address}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerDetailsModal;
