import React, { useEffect, useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { OrderRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

const EditPayment = ({
  setEditPayment,
  editTransData,
  handleGetOrdersTransaction,
  allInvoice,
  currentOrderIndex,
}) => {
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMode, setPaymentMode] = useState("bank-transfer");

  useEffect(() => {
    if (editTransData) {
      setPaymentMode(editTransData?.paymentMode);
    }
  }, [editTransData]);

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setPaymentMode("bank-transfer");
    setEditPayment(false);
  };

  const allPaymentMode = [
    {
      name: "Bank Transfer",
      value: "bank-transfer",
    },
    {
      name: "Cash",
      value: "cash",
    },
    {
      name: "UPI",
      value: "upi",
    },
  ];

  const handleEditPayment = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      mainTransactionId: editTransData?._id,
      paymentMode: paymentMode,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      OrderRelatedEndPoints.editPaymentMode,
      data
    );
    if (res?.success) {
      toast.success("Edit payment mode successfully!");
      // setpreviousTransaction(false);
      handleGetOrdersTransaction(allInvoice[currentOrderIndex]?.orderId._id);
      handleReset();
    } else {
      setErrorMessage(res.error);
      setloader(false);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "30%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>
              Edit Payment <span className="font-3 text-secondary"></span>
            </h5>
            <div className="border-bottom mb-3"></div>
            <form onSubmit={handleEditPayment}>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <label className="text-dark fw-250 fs-6">Payment mode</label>
                  <div className="form-group mt-2">
                    <select
                      className="form-select form-select-md p-2"
                      value={paymentMode}
                      onChange={(e) => {
                        setPaymentMode(e.target.value);
                      }}
                    >
                      {allPaymentMode?.map((data) => {
                        return (
                          <option value={data?.value}>{data?.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader}
                >
                  {loader ? <ApiLoader /> : "Edit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPayment;
