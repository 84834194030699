import React, { useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { startApiCall } from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { RatingRelatedEndPoints } from "../../../api/Endpoints";
import constants from "../../../components/Constant/Constants";
import { toast } from "react-toastify";

const Rating = ({
  setRatingModal,
  ratingModal,
  complaintVar,
  user,
  setAllComplaints,
}) => {
  const [loader, setloader] = useState(false);
  const [description, setDescription] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [star, setStar] = useState(0);

  const handleReset = () => {
    seterrorMessage("");
    setloader(false);
    setRatingModal(false);
    setDescription("");
    setStar(0);
  };

  const handleStarClick = (value) => {
    // setStar(value);
    const newStar = value === star ? 0 : value;
    setStar(newStar);
  };

  const handleAddRating = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const data = {
      complaintId: complaintVar,
      rating: star.toString(),
      review: description,
      complainerId: user?._id,
    };
    const res = await ApiCall("post", RatingRelatedEndPoints.addRating, data);
    if (res?.success) {
      handleReset();
      toast.success("Add rating successfully!");
      setAllComplaints();
    } else {
      seterrorMessage(res?.error);
      setloader(false);
    }
  };

  return (
    <div
      id="confirm"
      className={`modal fade add-new-contact ${ratingModal ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{
        display: ratingModal ? "block" : "none",
        pointerEvents: loader ? "none" : "",
      }}
    >
      <div className="modal-dialog modal-dialog-centered my-4" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Rate us</h5>
            <div className="border-bottom mb-2"></div>
            <form onSubmit={handleAddRating}>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <span className="d-flex justify-content-center mt-2 gap-2 pointer">
                      {[1, 2, 3, 4, 5]?.map((value) => {
                        return (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 54 51"
                            fill="none"
                            onClick={() => handleStarClick(value)}
                          >
                            <path
                              d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
                              fill={value <= star ? "#FFCE56" : "none"}
                              stroke="#FFCE56"
                              stroke-width="2"
                            />
                          </svg>
                        );
                      })}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Review</label>
                    <textarea
                      className="form-control"
                      rows="4"
                      required
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      value={description}
                      maxLength={constants.DESCRIPTION_REASON}
                    ></textarea>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-md btn-primary ">
                  {loader ? <ApiLoader /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rating;
