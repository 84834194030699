import React from "react";
// import loginImg from "../../../assets/images/login.svg";
import loginImg from "../../../assets/images/logo/bizhoodfullLogo.png";

const LoginLeft = () => {
  return (
    <div className="col-xl-7 col-lg-6 d-lg-block d-none v-separator separator-md">
      <div className="auth-content py-md-0 py-8">
        <div className="row">
          <div className="col-xxl-9 col-xl-8 col-lg-11 text-center mx-auto">
            <img src={loginImg} className="img-fluid login-image" alt="login" />
           
            {/* <p className="w-xxxl-70 w-100 mx-auto login-description">
              A stellar task management application simplifies task tracking and
              employee management. Seamlessly organize assignments, monitor
              progress, and delegate tasks effortlessly. Streamline
              communication, set priorities, and enhance productivity with an
              intuitive interface, making it the ultimate tool for efficient
              task maintenance and workforce coordination.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLeft;
