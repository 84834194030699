import React, { useState } from "react";
import ProfileSubMenu from "./ProfileSubMenu";
import NotificationSubMenu from "./NotificationSubMenu";
import { Avatar } from "../../Avatar/Avatar";
import InviteWorkspace from "../../../pages/InviteWorkspace/InviteWorkspace";
import JoinWorkspace from "../../../pages/JoinWorkspace/JoinWorkspace";
import ThemeOptions from "../../CustomizeTheme/ThemeOptions";
import Invitations from "../../../pages/InviteWorkspace/Invitations";
import { getCurrentWorkspaceId } from "../../../global-functions/globalFunctions";

const NavbarRight = ({ user }) => {
  const [isOpenInviteWorkspaceModal, setisOpenInviteWorkspaceModal] =
    useState(false);
  const [isOpenJoinWorkspaceModal, setisOpenJoinWorkspaceModal] =
    useState(false);
  const [businessRequest, setbusinessRequest] = useState([]);
  const [isShowLog, setisShowLog] = useState(false);
  return (
    <>
      <div className="nav-end-wrap">
        <ul className="navbar-nav flex-row">
          <li className="nav-item d-none-sm">
            <ThemeOptions />
          </li>
          <li className="nav-item">
            <div className="dropdown ps-2">
              {businessRequest?.length > 0 && (
                <span
                  className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover dropdown-toggle no-caret"
                  data-bs-toggle="dropdown"
                  data-dropdown-animation
                  role="button"
                  data-bs-auto-close="outside"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span className="icon">
                    <span className="position-relative">
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-envelope-paper"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2H4Zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6v-2.55Zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5.417Zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267v2.55Zm13 .566v5.734l-4.778-2.867L15 7.383Zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083l6.965 4.18ZM1 13.116V7.383l4.778 2.867L1 13.117Z" />
                        </svg>
                      </span>

                      <span
                        className="badge badge-primary position-top-end-overflow-1 rounded-pill pulse mnw-0 d-grid p-0"
                        style={{
                          height: "16px",
                          width: "16px",
                          fontSize: "12px",
                          placeItems: "center",
                        }}
                      >
                        {businessRequest?.length}
                      </span>
                    </span>
                  </span>
                </span>
              )}
              <Invitations
                user={user}
                businessRequest={businessRequest}
                setbusinessRequest={setbusinessRequest}
              />
            </div>
          </li>
          {getCurrentWorkspaceId() && (
            <li className="nav-item">
              <div className="dropdown dropdown-notifications">
                <span
                  className="btn btn-icon btn-rounded btn-flush-dark flush-soft-hover dropdown-toggle no-caret"
                  data-bs-toggle="dropdown"
                  data-dropdown-animation
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    setisShowLog(!isShowLog);
                  }}
                >
                  <span className="icon">
                    <span className="position-relative">
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-clock-history"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                          <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                      </span>
                      <span className="badge badge-success badge-indicator position-top-end-overflow-1"></span>
                    </span>
                  </span>
                </span>

                <NotificationSubMenu
                  setisShowLog={setisShowLog}
                  isShowLog={isShowLog}
                />
              </div>
            </li>
          )}
          <li className="nav-item">
            <div className="dropdown ps-2">
              <span
                className=" dropdown-toggle no-caret"
                data-bs-toggle="dropdown"
                data-dropdown-animation
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon">
                  <span className="position-relative">
                    <span className="feather-icon">
                      <div className="media-head me-1">
                        <Avatar
                          name={user?.name}
                          count={1}
                          image={user?.image}
                          size="xs"
                          color="primary"
                        />
                      </div>
                    </span>
                    <span
                      className="badge badge-indicator position-bottom-end-overflow-1 element-to-animate"
                      style={{ padding: "4px", backgroundColor: "#16db8c" }}
                    ></span>
                  </span>
                </span>
              </span>
              <ProfileSubMenu
                user={user}
                setisOpenInviteWorkspaceModal={setisOpenInviteWorkspaceModal}
                setisOpenJoinWorkspaceModal={setisOpenJoinWorkspaceModal}
              />
            </div>
          </li>
        </ul>
      </div>
      {isOpenInviteWorkspaceModal && (
        <InviteWorkspace
          isOpenInviteWorkspaceModal={isOpenInviteWorkspaceModal}
          setisOpenInviteWorkspaceModal={setisOpenInviteWorkspaceModal}
          user={user}
        />
      )}
      {isOpenJoinWorkspaceModal && (
        <JoinWorkspace
          isOpenJoinWorkspaceModal={isOpenJoinWorkspaceModal}
          setisOpenJoinWorkspaceModal={setisOpenJoinWorkspaceModal}
          user={user}
        />
      )}
    </>
  );
};

export default NavbarRight;