import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Task from "../../pages/Task/Task";
import Spaces from "../../pages/Spaces/Spaces";
import List from "../../pages/List/List";

const SpacesRoutes = {
  path: "/spaces",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <Spaces />,
    },
    {
      path: "allspaces",
      element: <Spaces />,
    },
    {
      path: "individualspace/:spacename/:spaceId",
      element: <Spaces />,
    },
    {
      path: "folderList/:id/:spaceId/:spacename/:foldername",
      element: <List />,
    },
    {
      path: "alltasklist/:id",
      element: <Task />,
    },
  ],
};

export default SpacesRoutes;
