import React from "react";
import { navigateToIndividualSpaces } from "../../Navigation/Navigation";

const ListHeader = (props) => {
  const {
    search,
    setSearch,
    spacename,
    spaceId,
    foldername,
    handleGetAllLists,
    navigate,
    setPage,
    isModuleReadWriteAccessForSpace,
  } = props;
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title link-dark text-capitalize">
          <button
            className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
            onClick={() => {
              navigate(
                navigateToIndividualSpaces({
                  spacename: spacename,
                  id: spaceId,
                })
              );
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <p className="mb-0">Back</p>
          </button>
          <div className="v-separator d-lg-inline-block d-none"></div>
        </span>
        {isModuleReadWriteAccessForSpace && (
          <button
            className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block ms-3"
            data-bs-toggle="modal"
            data-bs-target="#add_folder_list"
          >
            Create New List
          </button>
        )}
      </div>

      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllLists}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>

        <form className="d-sm-block d-none me-2" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search by list name"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default ListHeader;
