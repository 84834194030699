import React from "react";

export const CustomTab = ({
  allTabs,
  currentTab,
  handleTabChange,
  isShowCount,
}) => {
  return (
    <div className="hk-pg-header pg-header-wth-tab pt-1 pointer">
      <ul className="nav nav-line nav-light nav-tabs">
        {allTabs?.map((tab, index) => {
          return (
            <li className="nav-item" key={index}>
              <span
                className={`nav-link ${
                  currentTab === tab?.value ? "active" : ""
                }`}
                onClick={() => handleTabChange(tab?.value)}
              >
                <span className="nav-link-text">
                  {tab?.name}&nbsp;
                  {isShowCount && tab?.count}
                </span>
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
