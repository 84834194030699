import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { useSelector } from "react-redux";
import { CrmParticipantsGroup } from "../../../api/Endpoints";
import { toast } from "react-toastify";

function AddParticipantGroup({
  setaddGroup,
  handleGetAllGroups,
  editData,
  seteditData,
}) {
  const user = useSelector((state) => state.user);
  const [name, setname] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const handleReset = () => {
    seterrorMessage("");
    setLoader(false);
    setname("");
    setaddGroup(false);
    seteditData("");
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);

    const sendData = {
      name: name,
      admins: [user?._id],
      managers: [],
      employees: [],
      businessId: getCurrentWorkspaceId(),
      createdBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmParticipantsGroup.addParticipantGroup,
      sendData
    );
    if (res?.success) {
      handleReset();
      toast.success("Added Group successfully!");
      handleGetAllGroups();
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);

    const sendData = {
      name: name,
      leadGroupId: editData?._id,
      actionBy: user?._id,
    };
    const res = await ApiCall("post", CrmParticipantsGroup.editGroup, sendData);
    if (res?.success) {
      handleReset();
      toast.success("Edited Group successfully!");
      handleGetAllGroups();
    } else {
      setLoader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (editData) {
      setname(editData?.name);
    }
  }, [editData]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered my-4" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={handleReset}>
              <span aria-hidden="true">x</span>
            </button>
            <h5 className="mb-2">{editData ? "Edit Group" : "Add Group"}</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (editData) {
                  handleEditGroup(e);
                } else {
                  handleAddGroup(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Group Name</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Name"
                      required
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleReset}
                >
                  Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : editData ? "Edit" : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddParticipantGroup;
