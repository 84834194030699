import React, { useEffect, useState } from "react";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { workspaceRoleEndPoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import RoleListTable from "./RoleListTable";
import NoData from "../../../components/NoData/NoData";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import AddRoleModalView from "../AddRole/AddRoleModalView";

const RoleList = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [roleListData, setRoleListData] = useState([]);
  const [moduleData, setmoduleData] = useState("");
  const [search, setSearch] = useState("");
  const [roleModal, setroleModal] = useState(false);

  const isModuleReadWriteAccessForRolelist =
    allModuleAccess?.[PRIVATEROUTES.ROLE_LIST];

  const handleGetAllRole = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      searchKey: search,
    };
    const res = await ApiCall("post", workspaceRoleEndPoints.getAllRole, data);
    if (res?.success) {
      setRoleListData(res?.role);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllRole();
      closeAllModals();
    }
  }, [search]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <a className="todoapp-title  link-dark">
                <h1>Rolelist</h1>
              </a>
              {isModuleReadWriteAccessForRolelist && (
                <div className="ms-3">
                  <button
                    className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
                    onClick={() => {
                      setroleModal(true);
                    }}
                  >
                    Add Role
                  </button>
                </div>
              )}
            </div>

            {roleListData?.length > 0 && (
              <div className="d-flex align-items-center">
                <span className="d-md-inline">Total Rolelist:</span>
                <span className="text-dark fs-5 fw-medium ps-2">
                  {roleListData?.length}
                </span>
              </div>
            )}

            <div className="todo-options-wrap">
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="dsdsd"
                data-bs-original-title="Refresh"
              >
                <span className="btn-icon-wrap" onClick={handleGetAllRole}>
                  <span className="feather-icon">
                    <i
                      className="ri-refresh-line"
                      title="Refresh business list"
                    ></i>
                  </span>
                </span>
              </span>
              <div className="v-separator d-lg-inline-block d-none"></div>
              <form className="d-sm-block d-none" role="search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </form>
            </div>
          </header>

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="todo-list-view">
                {roleListData?.length > 0 && !loader && (
                  <RoleListTable
                    roleListData={roleListData}
                    user={user}
                    handleGetAllRole={handleGetAllRole}
                    isModuleReadWriteAccessForRolelist={
                      isModuleReadWriteAccessForRolelist
                    }
                    setmoduleData={setmoduleData}
                    moduleData={moduleData}
                    setroleModal={setroleModal}
                  />
                )}
                {loader && <DataLoader />}
                {!loader && roleListData?.length === 0 && <NoData />}
              </div>
            </div>
          </div>
        </div>
        {roleModal && (
          <AddRoleModalView
            moduleData={moduleData}
            setmoduleData={setmoduleData}
            setroleModal={setroleModal}
            handleGetAllRole={handleGetAllRole}
          />
        )}
      </div>
    </div>
  );
};

export default RoleList;
