const pendingWorkspaces = (state = {}, action) => {
    if (action.type === "pendingWorkspaces") {
      return state  =  action.payload;
    }
   else {
      return state;
    }
  };

  export default pendingWorkspaces;
  