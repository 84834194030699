import React, { useEffect, useState } from "react";
import CategoryItemsHeader from "./CategoryItemsHeader";
import CategoryItemsTable from "./CategoryItemsTable";
import AddItems from "./AddItems/AddItems";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { ProductCategoryRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import NoData from "../../../../components/NoData/NoData";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import CopyItem from "./CopyItem/CopyItem";

function CategoryItems() {
  const { allModuleAccess } = useSelector((state) => state);
  const { category, id } = useParams();
  const [itemModal, setitemModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [categoryItemsData, setcategoryItemsData] = useState([]);
  const [count, setcount] = useState(0);
  const [isEdit, setisEdit] = useState(false);
  const [slectedItem, setslectedItem] = useState([]);
  const [search, setsearch] = useState("");
  const [copyModal, setCopyModal] = useState(false);
  const [copyItems, setCopyItems] = useState([]);

  const isModuleReadWriteAccessForCategoryItems =
    allModuleAccess?.[PRIVATEROUTES.MENU_ITEMS];

  const handleGetCategoryItems = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        productCategory: id,
        isDeleted: "false",
        searchKey: search,
        businessId: getCurrentWorkspaceId(),
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.getCategoryItems,
      data
    );
    if (res?.success) {
      setcategoryItemsData(res?.item?.data);
      setcount(res?.item);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetCategoryItems();
    closeAllModals();
  }, [search, page, pageLimit]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <CategoryItemsHeader
            setitemModal={setitemModal}
            handleGetCategoryItems={handleGetCategoryItems}
            category={category}
            setsearch={setsearch}
            search={search}
            setPage={setPage}
            isModuleReadWriteAccessForCategoryItems={
              isModuleReadWriteAccessForCategoryItems
            }
            setCopyModal={setCopyModal}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && categoryItemsData?.length > 0 && (
                <CategoryItemsTable
                  categoryItemsData={categoryItemsData}
                  page={page}
                  pageLimit={pageLimit}
                  handleGetCategoryItems={handleGetCategoryItems}
                  setitemModal={setitemModal}
                  setisEdit={setisEdit}
                  setslectedItem={setslectedItem}
                  slectedItem={slectedItem}
                  isModuleReadWriteAccessForCategoryItems={
                    isModuleReadWriteAccessForCategoryItems
                  }
                />
              )}

              {loader && <DataLoader title="data is loading..." />}
              {!loader && categoryItemsData.length === 0 && (
                <NoData title="No Items Added" />
              )}
              {!loader && categoryItemsData?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={count?.totalPages}
                  count={count?.totalCounts}
                  dataLength={categoryItemsData?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {itemModal && (
        <AddItems
          setitemModal={setitemModal}
          handleGetCategoryItems={handleGetCategoryItems}
          isEdit={isEdit}
          slectedItem={slectedItem}
          setisEdit={setisEdit}
          copyItems={copyItems}
        />
      )}

      {copyModal && (
        <CopyItem
          setCopyModal={setCopyModal}
          setitemModal={setitemModal}
          setCopyItems={setCopyItems}
          id={id}
          category={category}
        />
      )}
    </div>
  );
}

export default CategoryItems;
