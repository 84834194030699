import React, { useEffect, useRef, useState } from "react";
import ContactOverviewHeader from "./ContactOverviewHeader";
import ContactDetailsLeft from "./ContactDetailsLeft";
import AddMeetingCrm from "../../../Leads/LeadsDetails/LeadOverview/MeetingCard/AddMeetingCrm";
import AddCalls from "../../../Leads/LeadsDetails/LeadOverview/CallsCard/AddCalls";
import AddTaskCrm from "../../../Leads/LeadsDetails/LeadOverview/TaskCard/AddTaskCrm";
import CreateContact from "../../CreateContact";
import CreateDeal from "../../../Deals/CreateDeal";
import ContactEmailModal from "../ContactEmailModal";
import PrintContact from "../PrintContact";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../api/ApiCall";
import {
  CrmContactRelatedEndPoints,
  CrmTaskRelatedEndPoints,
  DealRelatedEndPoints,
} from "../../../../../api/Endpoints";
import { useParams } from "react-router-dom";
import TimelineMoreInfoModal from "../../../Leads/LeadsDetails/LeadOverview/TimelineMoreInfoModal";
import CreateCalls from "../../../Leads/LeadsDetails/LeadOverview/CallsCard/CreateCalls";
import TaskActionWarningModal from "../../../Leads/LeadsDetails/LeadOverview/TaskActionWarning/TaskActionWarningModal";
import CallDetailsModal from "../../../Leads/LeadsDetails/LeadOverview/CallsCard/CallDetailsModal";
import DealDetails from "./DealDetails";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ImageViewModal from "../../../Leads/LeadsDetails/LeadOverview/ActivityMandatoryTask/ImageViewModal";

const ContactOverview = () => {
  const componentRef = useRef();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [dealsLoader, setdealsLoader] = useState(false);
  const [taskLoader, settaskLoader] = useState(false);
  const [contactDetails, setcontactDetails] = useState([]);
  const [editContact, seteditContact] = useState("");
  const [emailDetailsModal, setEmailDetailsModal] = useState(false);
  const [allActivity, setallActivity] = useState([]);
  const [moreInfoModal, setmoreInfoModal] = useState(false);
  const [timelineDetails, settimelineDetails] = useState(null);
  const [editContactData, setEditContactData] = useState("");
  const [taskWarningModal, settaskWarningModal] = useState(false);
  const [statusWarningDetails, setstatusWarningDetails] = useState([]);
  const [activityDetails, setactivityDetails] = useState("");
  const [dealCount, setDealCount] = useState(null);
  const [mandatoryLoader, setmandatoryLoader] = useState(false);
  const [imageData, setimageData] = useState([]);
  const [imageModal, setimageModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [totalCounts, setTotalCounts] = useState([]);

  // FOR task
  const [editTask, seteditTask] = useState("");
  // For meeting
  const [editMeeting, seteditMeeting] = useState("");
  // For deals
  const [allDeals, setAllDeals] = useState([]);
  const [editDealData, setEditDealData] = useState("");
  const [showDealDetails, setShowDealDetails] = useState(false);

  const handleGetAllDeals = async () => {
    startApiCall(null, setdealsLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "deal",
        contactId: id,
      },
    };
    const res = await ApiCall("post", DealRelatedEndPoints.getDeal, data);
    if (res?.success) {
      setAllDeals(res?.result?.data);
      setdealsLoader(false);
    } else {
      setdealsLoader(false);
    }
  };

  // GET CONTACT DETAILS FUNCTION API CALL
  const handleGetContactById = async () => {
    startApiCall(null, setloader);
    const data = {
      contactId: id,
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.contactById,
      data
    );
    if (res?.success) {
      setcontactDetails(res?.result?.contact);
      setDealCount(res?.result?.dealCount);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // GET MEETING,CALL,TASK FUNCTION API CALL
  const handleGetAllTask = async () => {
    startApiCall(null, settaskLoader);
    const data = {
      page: 1,
      limit: 5000,
      sortBy: "createdAt",
      filters: {
        actionOn: id,
        isDeleted: false,
        relatedTo: "Contact",
      },
    };
    const res = await ApiCall("post", CrmTaskRelatedEndPoints.getTask, data);
    if (res?.success) {
      const activity = res?.result?.data;
      setallActivity(activity);
      setTotalCounts(res?.result);
      settaskLoader(false);
    } else {
      settaskLoader(false);
    }
  };

  useEffect(() => {
    handleGetContactById();
    handleGetAllTask();
    // handleGetAllDeals();
  }, []);

  const handleRefresh = async () => {
    handleGetContactById();
    handleGetAllTask();
    // handleGetAllDeals();
  };

  const handleGetMandatoryTask = async (id, statusID, activity, statusName) => {
    startApiCall(null, setmandatoryLoader);
    const data = {
      activityId: id,
      activityStatusId: statusID,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.checkMandatoryTask,
      data
    );
    if (res?.success) {
      const taskToBeDone = findMandatoryItems(res?.result);
      if (taskToBeDone?.some((task) => Object.values(task)[0])) {
        seteditTask({
          task: taskToBeDone,
          statusID,
          activityId: id,
          activity: activity,
          statusName,
        });
        const closeModalDom = document.getElementById("call_details_modal");
        if (closeModalDom) closeModalDom.click();
        setstatusWarningDetails(activity);
        setactivityDetails(activity);
        settaskWarningModal(true);
      } else {
        handleEditActivity(activity, statusID, activity?.taskType);
      }
      setmandatoryLoader(false);
    } else {
      setmandatoryLoader(false);
    }
  };

  const handleEditActivity = async (task, status, taskType) => {
    startApiCall(seterrorMessage, setmandatoryLoader);
    const sendData = {
      leadTaskId: task?._id,
      taskType: taskType,
      title: task?.title,
      dueDate: task?.dueDate,
      priority: task?.priority,
      relatedTo: task?.relatedTo,
      activityStatus: status,
      actionOn: task?.actionOn?.id,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.editTask,
      sendData
    );
    if (res?.success) {
      setmandatoryLoader(false);
      handleGetAllTask();
      toast.success("Status changed successfully!");
    } else {
      setmandatoryLoader(false);
      seterrorMessage(res.error);
    }
  };

  function findMandatoryItems(data) {
    let tasks = [];

    data?.map((item) => {
      const key = Object.keys(item)[0];
      const mandatory = item[key]?.mandatory;

      if (mandatory) {
        tasks.push({
          [key]: item[key]?.mandatory,
          description: item[key]?.description,
        });
      }
    });

    return tasks;
  }

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ContactOverviewHeader
            componentRef={componentRef}
            setEmailDetailsModal={setEmailDetailsModal}
            id={id}
            contactDetails={contactDetails}
            handleRefresh={handleRefresh}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar py-2 px-3">
              <div className="contact-list-view">
                <div className="d-flex gap-2">
                  <ContactDetailsLeft
                    taskLoader={taskLoader}
                    contactDetails={contactDetails}
                    loader={loader}
                    allActivity={allActivity}
                    setmoreInfoModal={setmoreInfoModal}
                    settimelineDetails={settimelineDetails}
                    setEditContactData={setEditContactData}
                    id={id}
                    handleGetContactById={handleGetContactById}
                    setactivityDetails={setactivityDetails}
                    dealCount={dealCount}
                    setShowDealDetails={setShowDealDetails}
                    handleGetMandatoryTask={handleGetMandatoryTask}
                    totalCounts={totalCounts}
                  />
                </div>
              </div>
              {emailDetailsModal && (
                <ContactEmailModal
                  setEmailDetailsModal={setEmailDetailsModal}
                  contactDetails={contactDetails}
                  id={id}
                  handleGetContactById={handleGetContactById}
                />
              )}
            </div>
          </div>
        </div>

        <CreateCalls
          handleGetAllTask={handleGetAllTask}
          leadDetails={contactDetails}
          handleRefresh={handleRefresh}
          isLeadDeal={true}
        />
        <AddMeetingCrm
          handleGetMeeting={handleRefresh}
          editMeeting={editMeeting}
          seteditMeeting={seteditMeeting}
          isLeadDeal={true}
          leadDetails={contactDetails}
          id={id}
        />

        <AddTaskCrm
          handleGetTask={handleRefresh}
          seteditTask={seteditTask}
          editTask={""}
          isLeadDeal={true}
          leadDetails={contactDetails}
          id={id}
        />
        <CallDetailsModal
          leadDetails={contactDetails}
          loader={loader}
          activityDetails={activityDetails}
          setactivityDetails={setactivityDetails}
          seteditTask={seteditTask}
          settaskWarningModal={settaskWarningModal}
          setstatusWarningDetails={setstatusWarningDetails}
          handleAllTask={handleGetAllTask}
          handleGetMandatoryTask={handleGetMandatoryTask}
          setimageData={setimageData}
          setimageModal={setimageModal}
          relatedTo="Contact"
        />
        <CreateContact
          editContactData={editContactData}
          setEditContactData={setEditContactData}
          handleGetContact={handleGetContactById}
        />
        <CreateDeal
          handleGetAllDeals={handleGetAllDeals}
          editDealData={editDealData}
          setEditDealData={setEditDealData}
          isLeadDeal={true}
          id={id}
        />
        <CreateContact
          handleGetContact={handleGetContactById}
          editContact={editContact}
          seteditContact={seteditContact}
        />
        {taskWarningModal && (
          <TaskActionWarningModal
            settaskWarningModal={settaskWarningModal}
            editTask={editTask}
            statusWarningDetails={statusWarningDetails}
            handleGetAllTask={handleGetAllTask}
            handleEditActivity={handleEditActivity}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {moreInfoModal && (
          <TimelineMoreInfoModal
            setmoreInfoModal={setmoreInfoModal}
            timelineDetails={timelineDetails}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {imageModal && (
          <ImageViewModal
            imageData={imageData}
            setimageData={setimageData}
            setimageModal={setimageModal}
          />
        )}
        {showDealDetails && (
          <DealDetails setShowDealDetails={setShowDealDetails} id={id} />
        )}
        <div style={{ display: "none" }}>
          <PrintContact
            ref={componentRef}
            contactDetails={contactDetails}
            allActivity={allActivity}
            dealCount={dealCount}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactOverview;
