import React, { useEffect, useState } from "react";
import ManagePeopleTable from "./ManagePeopleTable";
import {
  getCurrentWorkspaceId,
  startApiCall,
  closeAllModals,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { managementEndpoints } from "../../../api/Endpoints";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import { useSelector } from "react-redux";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import ManagePeopleHeader from "./ManagePeopleHeader";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const ManagePeople = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [allJoinBusinessRequest, setAllJoinBusinessRequest] = useState([]);
  const [status, setStatus] = useState("");
  const [count, setcount] = useState({});
  const [joiningType, setjoiningType] = useState("");
  const [searchKey, setsearchKey] = useState("");

  const isModuleReadWriteAccess =
    allModuleAccess?.[PRIVATEROUTES.MANAGE_PEOPLE];

  const allTabs = [
    {
      name: "All Employees",
      value: "",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-primary">
          {count?.all}
        </span>
      ),
    },
    {
      name: "Invited People",
      value: "invite",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-warning">
          {count?.pendingInvites}
        </span>
      ),
    },
    {
      name: "Joining Requests",
      value: "join",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-warning">
          {count?.joinRequest}
        </span>
      ),
    },
  ];

  const handleTabChange = (type) => {
    setjoiningType(type);
  };

  const handleGetJoinBusinessRequest = async () => {
    startApiCall(null, setLoader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      status: joiningType === "" ? "" : "pending",
      type: joiningType,
      searchKey,
    };
    const res = await ApiCall(
      "post",
      managementEndpoints.getAllManagePeople,
      data
    );
    if (res?.success) {
      setcount(res?.employee?.counts);
      setAllJoinBusinessRequest(res?.employee?.employee);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetJoinBusinessRequest();
      closeAllModals();
    }
  }, [joiningType, searchKey]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ManagePeopleHeader
            handleGetJoinBusinessRequest={handleGetJoinBusinessRequest}
            setsearchKey={setsearchKey}
            searchKey={searchKey}
          />
          <CustomTab
            allTabs={allTabs}
            currentTab={joiningType}
            handleTabChange={handleTabChange}
            isShowCount={true}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                {!loader && allJoinBusinessRequest?.length > 0 && (
                  <ManagePeopleTable
                    allJoinBusinessRequest={allJoinBusinessRequest}
                    status={status}
                    handleGetJoinBusinessRequest={handleGetJoinBusinessRequest}
                    joiningType={joiningType}
                    user={user}
                    isModuleReadWriteAccess={isModuleReadWriteAccess}
                  />
                )}
                {loader && <DataLoader title="data is loading..." />}
                {!loader && allJoinBusinessRequest?.length === 0 && <NoData />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePeople;
