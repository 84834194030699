import React from "react";
import { dateAndTimeFormatter } from "../../../../global-functions/globalFunctions";

const ShiftTable = ({
  allShifts,
  page,
  pageLimit,
  seteditShiftDetails,
  setisAddShift,
  isModuleReadWriteAccessShift,
}) => {
  return (
    <>
      <table id="datable_1" className="table no-wrap w-100">
        <thead>
          <tr className="text-center">
            <th>No.</th>
            <th>Shift Name</th>
            <th>Working hours</th>
            <th>Start Time</th>
            <th>End Time</th>
            {isModuleReadWriteAccessShift && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {allShifts?.map((data, index) => {
            return (
              <tr className="text-center">
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td>
                  {data?.shiftName}
                  {data?.isDefault && (
                    <span className="badge badge-sm badge-primary ms-3">
                      default
                    </span>
                  )}
                </td>
                <td>{data?.totalHours} Hrs</td>
                <td>
                  {dateAndTimeFormatter(data?.startTime).split(",")[1]?.trim()}
                </td>
                <td>
                  {dateAndTimeFormatter(data?.endTime).split(",")[1]?.trim()}
                </td>
                {isModuleReadWriteAccessShift && (
                  <td>
                    <i
                      onClick={() => {
                        setisAddShift(true);
                        seteditShiftDetails(data);
                      }}
                      className="fa fa-pencil-square-o me-3 text-primary pointer"
                    ></i>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ShiftTable;
