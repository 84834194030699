import React, { useState } from "react";
import logo from "../../../../assets/images/logo/bizhoodfullLogo.png";
import CreditNoteItemTable from "./CreditNoteItemTable";
import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  dateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { CreditNoteRelatedEndPoints } from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import SmallLoader from "../../../../components/Loaders/SmallLoader";

const CreditNotDetailsBody = ({
  creditNotesDetails,
  id,
  handleGetCreditnotesDetails,
}) => {
  const [loader, setloader] = useState(false);
  const [status, setStatus] = useState("");

  const allStatus = [
    {
      name: "Approved",
      value: "approved",
    },
    {
      name: "Rejected",
      value: "rejected",
    },
  ];

  const handleChangeStatus = async (status) => {
    startApiCall(null, setloader);
    const data = {
      creditNoteId: id,
      status,
    };
    const res = await ApiCall(
      "post",
      CreditNoteRelatedEndPoints.changeCreditNoteStatus,
      data
    );
    if (res?.success) {
      toast.success("Change status successfully!");
      handleGetCreditnotesDetails();
    } else {
      setloader(false);
    }
  };

  return (
    <div className="d-flex gap-3">
      <div
        className="col-sm-8 simple-scrollbar"
        style={{ maxHeight: "75vh", overflow: "auto" }}
      >
        <div className="card p-4 d-flex flex-column gap-2 border-ligth rounded-0">
          <div className="d-flex justify-content-between">
            <div className="col-sm-6">
              <img src={logo} alt="sociohoodlogo" className="mw-175p mh-150p" />
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <div className="d-block">
                <div className="text-primary fw-semibold fs-4 mt-2 ">
                  Credit Note
                </div>
                <div>#{creditNotesDetails?.creditNoteNumber}</div>
                <div>
                  Invoice no : {creditNotesDetails?.invoiceId?.invoiceNumber}
                </div>
                <div>
                  Date Created : {dateFormatter(creditNotesDetails?.createdAt)}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <span className="text-capitalize">
              Credits to : {creditNotesDetails?.customerId?.name}
            </span>
          </div>
          <div className="mt-1">
            <span className="text-capitalize">
              Reason : {creditNotesDetails?.reason}.
            </span>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <div
              className="alert alert-light text-dark fs-7 w-100 "
              role="alert"
            >
              <div className="p-2 d-flex gap-3">
                <div className="col-sm-6">
                  <strong>Billing Address:</strong>
                  {creditNotesDetails?.customerId?.billingAddress ? (
                    <>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          City :{" "}
                          {creditNotesDetails?.customerId?.billingAddress?.city}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          PinCode :
                          {
                            creditNotesDetails?.customerId?.billingAddress
                              ?.pinCode
                          }
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          Country :
                          {
                            creditNotesDetails?.customerId?.billingAddress
                              ?.country
                          }
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          {
                            creditNotesDetails?.customerId?.billingAddress
                              ?.street
                          }
                        </span>
                      </div>
                    </>
                  ) : (
                    <span className="ps-2">No Billing Address</span>
                  )}
                </div>
                <div className="col-sm-6">
                  <strong>Shipping Address:</strong>
                  {creditNotesDetails?.customerId?.shippingAddress ? (
                    <>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          City :
                          {
                            creditNotesDetails?.customerId?.shippingAddress
                              ?.city
                          }
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          PinCode :
                          {
                            creditNotesDetails?.customerId?.shippingAddress
                              ?.pinCode
                          }
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          Country :
                          {
                            creditNotesDetails?.customerId?.shippingAddress
                              ?.country
                          }
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          {
                            creditNotesDetails?.customerId?.shippingAddress
                              ?.street
                          }
                        </span>
                      </div>
                    </>
                  ) : (
                    <span className="ps-2">No Shipping Address</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="simple-scrollbar" style={{ maxHeight: "200px", overflow: "auto" }}>
            <CreditNoteItemTable creditNotesDetails={creditNotesDetails} />
          </div>
          <div className="d-flex justify-content-end">
            <span>Sub Total : {creditNotesDetails?.amount}</span>
          </div>
        </div>
      </div>
      <div
        className="col-sm-4 p-4 border-start"
        style={{ maxHeight: "75vh", overflow: "auto" }}
      >
        <div className="d-flex gap-2">
          <span className="text-dark text-capitalize gap-2">
            Created by :&nbsp;
            <Avatar
              name={creditNotesDetails?.createdBy?.name}
              count={1}
              //   image={quoteDetailsData?.createdBy?.image}
              size="xss"
              color="primary"
            />
            &nbsp;&nbsp;
            {creditNotesDetails?.createdBy?.name}
          </span>
        </div>
        <div className="d-flex gap-3 mt-1">
          <span className="text-dark text-capitalize">Status :</span>
          <div className="selectable-dropdown">
            <div className="dropdown selectable-dropdown">
              {!loader && (
                <span
                  className={` d-flex align-items-center justify-content-center gap-1 fw-medium dropdown-toggle text-dark text-capitalize`}
                  aria-expanded="false"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  {creditNotesDetails?.status}
                </span>
              )}
              {loader && <SmallLoader />}
              <div role="menu" className="dropdown-menu">
                {allStatus?.map((sts) => {
                  return (
                    <button
                      className="dropdown-item"
                      disabled={creditNotesDetails?.status === sts?.value}
                      onClick={() => {
                        handleChangeStatus(sts?.value);
                      }}
                    >
                      {sts?.value === "approved" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="#12802B"
                          className="bi bi-check-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="#AC2222"
                          className="bi bi-x-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      )}
                      &nbsp;&nbsp;
                      {sts?.name}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditNotDetailsBody;
