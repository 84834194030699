import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  getName,
} from "../../../../global-functions/globalFunctions";
import { navigateToDealOverview } from "../../../../Navigation/Navigation";

const DealCountTable = ({ loader4, allDeals }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  return (
    <>
      {loader4 && <DataLoader title="data is loading..." />}
      {!loader4 && allDeals?.length > 0 && (
        <table id="datable_1" className="table nowrap w-100 mb-5">
          <thead>
            <tr className="text-center">
              <th>No .</th>
              <th style={{ textAlign: "left" }}>Deal Name</th>
              <th className="px-1">Amount</th>
              <th className="px-1">Closing Amount</th>
              <th>Contact</th>
              <th>Source</th>
              <th>Status Group</th>
              <th>Stage</th>
              <th>Added By</th>
              <th>Last updated</th>
              <th>View Details</th>
            </tr>
          </thead>
          <tbody>
            {allDeals?.map((data, index) => {
              return (
                <tr className="text-center">
                  <td>{index + 1} .</td>
                  <td style={{ textAlign: "left" }}>
                    <div
                      className="text-capitalize text-truncate text-dark"
                      style={{ width: "200px" }}
                    >
                      {data?.title}
                    </div>
                  </td>
                  <td className="font-mon fs-6 text-teal px-1">
                    {data?.amount ? `₹ ${data?.amount}` : "-"}
                  </td>
                  <td className="font-mon fs-6 text-teal px-1">
                    {data?.closingAmount ? `₹ ${data?.closingAmount}` : "-"}
                  </td>
                  <td className="text-capitalize">
                    {data?.contactId ? data?.contactId?.name : "-"}
                  </td>
                  <td>{data?.source}</td>
                  <td>{data?.groupId?.name}</td>
                  <td>
                    <span
                      className="badge badge-md ms-auto w-100"
                      style={{
                        backgroundColor: `${
                          data?.initialStatusId?.color || "blue"
                        }`,
                      }}
                    >
                      {data?.subStatusId?.name}
                    </span>
                  </td>
                  <td>
                    <Avatar
                      name={
                        data?.createdBy
                          ? data?.createdBy?.name
                          : data?.convertedBy?.name
                      }
                      count={1}
                      image={
                        data?.createdBy
                          ? data?.createdBy?.image
                          : data?.convertedBy?.image
                      }
                      size="xss"
                      color="primary"
                    />
                    <span className="ms-1">
                      {getName(
                        data?.createdBy ? data?.createdBy : data?.convertedBy,
                        user?._id
                      )}
                    </span>
                  </td>
                  <td>{dateAndTimeFormatter(data?.updatedAt)}</td>
                  <td>
                    <span
                      className="btn btn-icon btn-rounded btn-flush-light"
                      onClick={() =>
                        navigate(
                          navigateToDealOverview({
                            id: data?._id,
                          })
                        )
                      }
                    >
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-right-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                          />
                        </svg>
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {!loader4 && allDeals?.length === 0 && <NoData title="No Deals added" />}
    </>
  );
};

export default DealCountTable;
