import React, { useEffect, useState } from "react";
import ApiCall from "../../../api/ApiCall";
import {
  customTimeFormater,
  dateFormatter,
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { toast } from "react-toastify";
import { MeetingRelatedEndPoints } from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const AddMeeting = (props) => {
  const {
    handleGetMeeting,
    editableMeetingDetails,
    seteditableMeetingDetails,
    locationData,
  } = props;
  const user = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [meeting, setmeeting] = useState({
    title: "",
    startdate: "",
    startTime: "",
    endTime: "",
    addressType: "",
    offsiteAddress: "",
    onsiteLink: "",
    description: "",
  });
  const currentDate = new Date();

  useEffect(() => {
    if (editableMeetingDetails?.meetingTitle) {
      setmeeting({
        title: editableMeetingDetails?.meetingTitle,
        startdate: reversesDateFormatter(editableMeetingDetails?.scheduleTime),
        startTime: customTimeFormater(editableMeetingDetails?.scheduleTime),
        endTime: customTimeFormater(editableMeetingDetails?.endMeetingTime),
        addressType: editableMeetingDetails?.addressType,
        offsiteAddress: editableMeetingDetails?.onsiteAddress,
        onsiteLink: editableMeetingDetails?.offsiteAddress,
        description: editableMeetingDetails?.description,
      });
    } else {
      resetData();
    }
  }, [editableMeetingDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setmeeting({
      ...meeting,
      [name]: value,
    });
  };

  const resetData = () => {
    setmeeting({
      title: "",
      startdate: "",
      startTime: "",
      endTime: "",
      addressType: "",
      offsiteAddress: "",
      onsiteLink: "",
      description: "",
    });
  };

  const endApiCall = () => {
    resetData();
    seterrorMessage("");
    setLoader(false);
    seteditableMeetingDetails(null);
    const closeModalDom = document.getElementById("add_new_meeting");
    if (closeModalDom) {
      closeModalDom.click();
    }
  };

  const handleAddMeeting = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      schedulingDate: meeting.startdate,
      schedulingTime: meeting.startTime,
      title: meeting.title,
      endDate: meeting.startdate,
      endTime: meeting.endTime,
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      addressType: meeting.addressType,
      description: meeting.description,
      offsiteAddress: meeting.onsiteLink,
      onsiteAddress: meeting.offsiteAddress,
      moduleType: locationData ? locationData?.moduleType : "Business",
      moduleId: locationData ? locationData?.moduleId : "",
    };
    const res = await ApiCall(
      "post",
      MeetingRelatedEndPoints.addMeeting,
      payload
    );
    if (res?.success) {
      toast.success("Meeting has been scheduled successfully!");
      handleGetMeeting();
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleEditMeeting = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      schedulingDate: meeting.startdate,
      schedulingTime: meeting.startTime,
      title: meeting.title,
      endDate: meeting.startdate,
      endTime: meeting.endTime,
      meetingId: editableMeetingDetails?._id,
      addressType: meeting.addressType,
      description: meeting.description,
      offsiteAddress: meeting.offsiteAddress,
      onsiteAddress: meeting.onsiteLink,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "patch",
      MeetingRelatedEndPoints.editMeeting,
      payload
    );
    if (res?.success) {
      toast.success("Meeting Updated successfully!");
      handleGetMeeting();
      endApiCall();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  return (
    <div
      id="add_new_meeting"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{
        pointerEvents: loader ? "none" : "",
      }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={endApiCall}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-2 fw-300">
              {editableMeetingDetails?.meetingTitle
                ? "Edit Meeting"
                : "Schedule meeting"}
            </h5>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="450"
              height="2"
              viewBox="0 0 450 2"
              fill="none"
            >
              <path d="M0 1H700" stroke="#D9D9D9" />
            </svg>
            <div className="d-flex justify-content-center mt-2">
              {editableMeetingDetails?.meetingTitle ? (
                <div
                  className="alert alert-info text-primary fs-7"
                  role="alert"
                >
                  <strong>Note:</strong> Meeting will be scheduled for{" "}
                  <span className="fw-bold">
                    {editableMeetingDetails?.moduleId?.name
                      ? editableMeetingDetails?.moduleId?.name
                      : "Workspace"}
                  </span>{" "}
                  members only.
                </div>
              ) : (
                <div
                  className="alert alert-info text-primary fs-7"
                  role="alert"
                >
                  <strong>Note:</strong> Meeting will be scheduled for{" "}
                  <span className="fw-bold">
                    {locationData ? locationData?.moduleName : "Workspace"}
                  </span>{" "}
                  members only.
                </div>
              )}
            </div>
            <form
              onSubmit={
                editableMeetingDetails?.meetingTitle
                  ? handleEditMeeting
                  : handleAddMeeting
              }
            >
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <span className="text-dark fw-300 fs-5">Meeting Title</span>
                    <input
                      className="form-control"
                      type="text"
                      name="title"
                      placeholder="Enter Title"
                      required
                      onChange={handleChange}
                      value={meeting.title}
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3 mt-1">
                <div className="col-sm-4">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="date"
                      name="startdate"
                      onChange={handleChange}
                      required
                      value={meeting.startdate}
                      min={reversesDateFormatter(currentDate)}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="time"
                      name="startTime"
                      onChange={handleChange}
                      required
                      value={meeting.startTime}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="endTime"
                      onChange={handleChange}
                      type="time"
                      required
                      value={meeting.endTime}
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <span className="text-dark fw-300 fs-6">
                  Choose Address Type
                </span>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="todo-toolbar mt-2">
                      <select
                        required
                        value={meeting.addressType}
                        name="addressType"
                        onChange={handleChange}
                        className="form-select form-select-md "
                      >
                        <option selected value="">
                          Choose Type
                        </option>
                        <option selected value="offsite">
                          Offsite &nbsp;(Virtual)
                        </option>
                        <option selected value="onsite">
                          Onsite &nbsp;(Physical)
                        </option>
                        <option selected value="both">
                          Both
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                {meeting?.addressType !== "" && (
                  <div className="col-sm-9">
                    <div className="form-group">
                      <div className="todo-toolbar mt-2">
                        {(meeting?.addressType == "onsite" ||
                          meeting?.addressType === "both") && (
                          <input
                            className="form-control"
                            name="offsiteAddress"
                            type="text"
                            placeholder="Enter onsite (physical) Address"
                            onChange={handleChange}
                            pattern="[0-9A-Za-z\s\-,]+"
                            required={
                              meeting?.addressType === "onsite" ||
                              meeting?.addressType === "both"
                            }
                            value={meeting.offsiteAddress}
                            maxLength={50}
                          />
                        )}
                        {(meeting?.addressType == "offsite" ||
                          meeting?.addressType === "both") && (
                          <input
                            className="form-control mt-2"
                            name="onsiteLink"
                            type="url"
                            onChange={handleChange}
                            placeholder="Enter offsite (virtual) Link"
                            pattern="https://.*"
                            title="Please enter a valid meet link."
                            required={
                              meeting?.addressType === "offsite" ||
                              meeting?.addressType === "both"
                            }
                            maxLength={50}
                            value={meeting.onsiteLink}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      onChange={handleChange}
                      name="description"
                      value={meeting.description}
                    ></textarea>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  disabled={loader}
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={endApiCall}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? (
                    <ApiLoader />
                  ) : editableMeetingDetails?.meetingTitle ? (
                    "Edit"
                  ) : (
                    "Schedule"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMeeting;
