import React from "react";
import {
  handlePreviewFileInLocal,
  reuiredField,
} from "../../../../../global-functions/globalFunctions";

function AddItemStepOne(props) {
  const {
    image,
    setimage,
    setdishName,
    setdishDescription,
    dishName,
    dishDescription,
    dishTypeVeg,
    setdishTypeVeg,
    selectSetup2,
    slectedItem,
    isEdit,
    completeFirstStep,
  } = props;

  const handlePreviewFileInLocalEdit = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const img = URL.createObjectURL(event.target.files[0]);
      return img;
    } else if (slectedItem?.images?.[0]) {
      // Return the URL of the existing image if no new file is selected
      return slectedItem?.images?.[0];
    } else {
      return null;
    }
  };

  const setFileForUpload = (event) => {
    setimage(event);
  };
  return (
    <div>
      <div className="d-flex justify-content-between mt-2 pt-3 pb-3">
        <div className="fs-5 d-flex align-items-start justify-content-start">
          <label className="text-dark">Step 1 | Item Information</label>
        </div>
        <div className="fs-6 d-flex align-items-end justify-content-end gap-2">
          <label className="mb-1">{dishTypeVeg ? "Veg" : "Non Veg"}</label>
          <div class="form-check form-switch">
            <input
              className={`form-check-input btn ${
                dishTypeVeg === true ? "bg-success" : "bg-danger"
              }`}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={dishTypeVeg}
              onClick={(e) => setdishTypeVeg(!dishTypeVeg)}
            />
          </div>
        </div>
      </div>
      <div className="d-flex col-sm-12 ">
        <div className="col-sm-4">
          <div className="col-md-12">
            <div className="form-group row w-100">
              <div className="col-sm-12 fs-5 d-flex align-items-start justify-content-start">
                <label className="fw-lighter mb-1">Item Image</label>
              </div>
              <div className="col-sm-12">
                <div className="d-flex align-items-center justify-content-center dropify-square">
                  {image ? (
                    <>
                      <input
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        className="input-file"
                        onChange={setFileForUpload}
                      />
                      <label
                        htmlFor="file"
                        className="position-relative pointer"
                      >
                        <img
                          htmlFor="file"
                          id="local_show_img"
                          src={
                            isEdit
                              ? handlePreviewFileInLocalEdit(image)
                              : handlePreviewFileInLocal(image)
                          }
                          style={{ padding: 5 }}
                          className="dropify-wrapper text-center"
                          alt="img"
                        />
                        <span
                          className="position-top-end-overflow"
                          onClick={() => {
                            setimage("");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      </label>
                    </>
                  ) : (
                    <span
                      className={`border border-black text-center d-flex align-items-center justify-content-center w-250p h-190p rounded-5`}
                    >
                      <input
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        className="input-file"
                        onChange={setFileForUpload}
                      />
                      <label
                        htmlFor="file"
                        className={`text-center fs-5 pointer gap-4`}
                      >
                        <div className="d-flex align-items-center justify-content-center p-4">
                          <svg
                            width="80"
                            height="80"
                            viewBox="0 0 60 60"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.125 22.5C12.0895 22.5 11.25 23.3395 11.25 24.375V54.375C11.25 55.4105 12.0895 56.25 13.125 56.25H46.875C47.9105 56.25 48.75 55.4105 48.75 54.375V24.375C48.75 23.3395 47.9105 22.5 46.875 22.5H39.375C38.3395 22.5 37.5 21.6605 37.5 20.625C37.5 19.5895 38.3395 18.75 39.375 18.75H46.875C49.9816 18.75 52.5 21.2684 52.5 24.375V54.375C52.5 57.4816 49.9816 60 46.875 60H13.125C10.0184 60 7.5 57.4816 7.5 54.375V24.375C7.5 21.2684 10.0184 18.75 13.125 18.75H20.625C21.6605 18.75 22.5 19.5895 22.5 20.625C22.5 21.6605 21.6605 22.5 20.625 22.5H13.125Z"
                              fill="black"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M28.6742 0.549175C29.4064 -0.183058 30.5936 -0.183058 31.3258 0.549175L42.5758 11.7992C43.3081 12.5314 43.3081 13.7186 42.5758 14.4508C41.8436 15.1831 40.6564 15.1831 39.9242 14.4508L31.875 6.40165V39.375C31.875 40.4105 31.0355 41.25 30 41.25C28.9645 41.25 28.125 40.4105 28.125 39.375V6.40165L20.0758 14.4508C19.3436 15.1831 18.1564 15.1831 17.4242 14.4508C16.6919 13.7186 16.6919 12.5314 17.4242 11.7992L28.6742 0.549175Z"
                              fill="black"
                            />
                          </svg>
                        </div>
                        Upload Image
                      </label>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="row gx-3">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label fw-lighter">
                  Item Name {reuiredField}
                </label>
                <input
                  className="form-control rounded-5"
                  type="text"
                  required
                  onChange={(e) => {
                    setdishName(e.target.value);
                  }}
                  value={dishName}
                />
              </div>
            </div>
          </div>
          <div className="row gx-3">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-label fw-lighter">Description</label>
                <textarea
                  className="form-control rounded-5"
                  rows="3"
                  placeholder="Type here"
                  onChange={(e) => {
                    setdishDescription(e.target.value);
                  }}
                  value={dishDescription}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer align-items-center mt-2">
        <button
          onClick={selectSetup2}
          className="btn btn-md btn-primary"
          disabled={!completeFirstStep}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default AddItemStepOne;
