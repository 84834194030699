import React from "react";
import { skeletonsTable } from "../../../../components/Constant/Skeleton";
import { dateAndTimeFormatter, getName } from "../../../../global-functions/globalFunctions";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const GroupDetailsBody = ({
  allStatus,
  seteditStatus,
  loader,
  setcreateStatus,
  setTaskModal,
  setStatusTask,
  isModuleReadWriteAccessForActivityGroup,
}) => {
  const user = useSelector((state) => state.user);
  return (
    <div className="simple-scrollbar" style={{ overflowY: "scroll" }}>
      {!loader && allStatus?.length > 0 && (
        <table id="datable_1" className="table nowrap w-100 text-center mb-0">
          <thead>
            <tr className="text-center">
              <th>No .</th>
              <th className="text-start">Step Name</th>
              <th>Created on</th>
              <th>Created by</th>
              <th>Tasks</th>
              {isModuleReadWriteAccessForActivityGroup && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {allStatus?.map((data, index) => {
              return (
                <tr>
                  <td>{index + 1} .</td>
                  <td className="text-start">
                    <div className="text-capitalize text-truncate pointer mw-300p">
                      {data?.name}
                    </div>
                  </td>
                  <td>{dateAndTimeFormatter(data?.createdAt)}</td>
                  <td>
                    {!data?.isDefault ? (
                      <>
                        <Avatar
                          name={data?.createdBy?.name}
                          count={1}
                          image={data?.createdBy?.image}
                          size="xss"
                          color="primary"
                        />
                        <span className="ms-1">{getName(data?.createdBy, user?._id)}</span>
                      </>
                    ) : (
                      "System"
                    )}
                  </td>
                  <td>
                    {data?.tasks?.length === 0 ? (
                      "-"
                    ) : (
                      <span
                        className="btn btn-icon circle btn-sm btn-soft-primary"
                        onClick={() => {
                          seteditStatus(data);
                          setStatusTask(data?.tasks);
                          setTaskModal(true);
                        }}
                      >
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-list-task"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"
                            />
                            <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z" />
                            <path
                              fill-rule="evenodd"
                              d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"
                            />
                          </svg>
                        </span>
                      </span>
                    )}
                  </td>
                  {isModuleReadWriteAccessForActivityGroup && (
                    <td>
                      <span
                        className="btn btn-icon"
                        onClick={() => {
                          seteditStatus(data);
                          setcreateStatus(true);
                        }}
                      >
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </span>
                      </span>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {loader && skeletonsTable(5, 5)}
    </div>
  );
};

export default GroupDetailsBody;
