import React, { useEffect, useState } from "react";
import {
  dateFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../api/ApiCall";
import { taskEndpoint } from "../../api/Endpoints";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import TaskDetailsModal from "../../pages/Task/TaskDetailsModal/TaskDetailsModal";

const SmallCalendar = () => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [allTasks, setAllTasks] = useState([]);
  const [showAllTasks, setShowAllTasks] = useState(false);

  const currentDate = new Date();
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  // ********* FOR TASK DETAILS MODAL ********
  const [taskDetailsModal, settaskDetailsModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState([]);

  // ****** SET LIST AND SPACENAME ******
  const [listName, setListName] = useState("");
  const [spaceName, setSpaceName] = useState("");

  const handleGetAllTasks = async () => {
    startApiCall(null, setloader);
    let dueDate;
    if (selectedDate) {
      dueDate = selectedDate;
    } else {
      const currentDateObj = new Date();
      dueDate = currentDateObj.toISOString().split("T")[0];
    }
    const sendData = {
      page: 1,
      limit: 50,
      filters: {
        dueDate: dueDate,
        assignedTo: user?._id,
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      setAllTasks(res?.task?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllTasks();
    }
  }, [selectedDate]);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  const handleDateClick = (day) => {
    const selectedDateObj = new Date(currentYear, currentMonth, day + 1);
    const formattedDate = selectedDateObj.toISOString().split("T")[0];
    setSelectedDate(formattedDate);
    setShowAllTasks(false);
  };

  const generateCalendar = () => {
    const calendar = [];

    calendar.push(
      <tr key="header">
        {daysOfWeek.map((day) => (
          <th className="ps-1 pe-1" key={day}>
            {day}
          </th>
        ))}
      </tr>
    );

    let dayCounter = 1;

    for (let i = 0; i < 6; i++) {
      const row = [];

      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDayOfMonth) || dayCounter > daysInMonth) {
          row.push(<td key={`empty-${j}`}></td>);
        } else {
          const day = dayCounter;
          const currentDate = new Date(currentYear, currentMonth, day);
          const isCurrentDay =
            currentDate.toDateString() === new Date().toDateString();
          const selectedDateObj = new Date(currentYear, currentMonth, day + 1);
          const formattedDate = selectedDateObj.toISOString().split("T")[0];

          row.push(
            <td
              className="px-0 py-1"
              style={{ paddingBlock: "0.65rem" }}
              key={`day-${dayCounter}`}
            >
              <div
                className={`align-items-center justify-content-center mx-auto circle pointer ${
                  selectedDate === formattedDate ? "bg-primary text-white" : ""
                } ${isCurrentDay ? "border border-primary" : ""}`}
                style={{
                  height: "26px",
                  width: "26px",
                }}
                onClick={() => {
                  handleDateClick(day);
                  // setTaskView(true);
                }}
              >
                {day}
              </div>
            </td>
          );
          dayCounter++;
        }
      }

      calendar.push(<tr key={`row-${i}`}>{row}</tr>);
    }

    return calendar;
  };

  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const openDetailsModal = (tsk) => {
    setSelectedTask(tsk);
    settaskDetailsModal(true);
  };

  return (
    <>
      <div className="border rounded-4 p-3">
        <div className="d-flex flex-column align-items-center">
          {/* <span className="card card-border shadow-sm">
            <div className="card-body"> */}
          <p className="mt-2 mb-2">
            Tasks assigned to <span className="text-dark">{user?.name}</span>{" "}
            that have been scheduled
          </p>
          {/* </div>
          </span> */}
          <div className="d-flex align-items-center justify-content-between">
            <button
              onClick={goToPreviousMonth}
              className="btn btn-icon btn-rounded btn-sm"
            >
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-caret-left-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                </svg>
              </span>
            </button>
            <h4 className="mb-0">
              {new Date(currentYear, currentMonth).toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </h4>
            <button
              onClick={goToNextMonth}
              className="btn btn-icon btn-rounded btn-sm"
            >
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-caret-right-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
              </span>
            </button>
          </div>
          <table className="table text-center">
            <thead>{generateCalendar()[0]}</thead>
            <tbody>{generateCalendar().slice(1)}</tbody>
          </table>
        </div>
        <span
          className="card card-border mb-0 overflow-hidden"
          style={{ maxWidth: "100%" }}
        >
          <div
            className="card-body py-1 "
            style={{
              maxWidth: "100%",
              maxHeight: "30vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {loader ? (
              <span className="d-flex justify-content-center">
                Searching Tasks...
              </span>
            ) : (
              <>
                {allTasks?.length === 0 ? (
                  <div className="d-flex justify-content-center">
                    No Tasks found
                  </div>
                ) : (
                  <>
                    {allTasks?.length > 0 && (
                      <>
                        {allTasks?.slice(0, 2)?.map((tsk) => {
                          return (
                            <div
                              className="d-flex align-items-center justify-content-between gap-2 mt-1"
                              key={tsk?._id}
                            >
                              <span className="d-flex align-items-center gap-2 w-100">
                                <p>{dateFormatter(tsk?.dueDate)}</p>
                                <p>:</p>
                                <CustomTooltip
                                  placement="left"
                                  text="view task details"
                                >
                                  <div
                                    className="text-dark text-capitalize pointer fs-6 text-truncate mw-400p"
                                    onClick={() => {
                                      openDetailsModal(tsk?._id);
                                      setListName(tsk?.list?.name);
                                      setSpaceName(tsk?.space?.name);
                                    }}
                                  >
                                    {tsk?.name}
                                  </div>
                                </CustomTooltip>
                              </span>
                            </div>
                          );
                        })}
                        {showAllTasks &&
                          allTasks?.slice(2)?.map((tsk) => {
                            return (
                              <div
                                className="d-flex align-items-center justify-content-between gap-2 mt-1"
                                key={tsk?._id}
                              >
                                <div className="d-flex align-items-center gap-2 w-100">
                                  <p>{dateFormatter(tsk?.dueDate)}</p>
                                  <p>:</p>
                                  <CustomTooltip
                                    placement="left"
                                    text="view task details"
                                  >
                                    <div
                                      className="text-dark text-capitalize pointer fs-6 text-truncate mw-400p"
                                      onClick={() => {
                                        openDetailsModal(tsk?._id);
                                        setListName(tsk?.list?.name);
                                        setSpaceName(tsk?.space?.name);
                                      }}
                                    >
                                      {tsk?.name}
                                    </div>
                                  </CustomTooltip>
                                </div>
                              </div>
                            );
                          })}
                        <div className="d-flex justify-content-end gap-3">
                          {allTasks?.length > 2 && (
                            <>
                              {showAllTasks ? (
                                <span
                                  className="pointer"
                                  onClick={() => setShowAllTasks(false)}
                                >
                                  See less
                                </span>
                              ) : (
                                <span
                                  className="pointer text-primary"
                                  onClick={() => setShowAllTasks(true)}
                                >
                                  + {allTasks.length - 2} more tasks
                                </span>
                              )}
                              <span>
                                <span className="feather-icon">
                                  {showAllTasks ? (
                                    <span
                                      className="feather-icon pointer"
                                      onClick={() => setShowAllTasks(false)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="16"
                                        width="16"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z"></path>
                                      </svg>
                                    </span>
                                  ) : (
                                    <span
                                      className="feather-icon pointer"
                                      onClick={() => setShowAllTasks(true)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="16"
                                        width="16"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"></path>
                                      </svg>
                                    </span>
                                  )}
                                </span>
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </span>
      </div>
      {taskDetailsModal && (
        <TaskDetailsModal
          taskId={selectedTask}
          taskDetailsModal={taskDetailsModal}
          settaskDetailsModal={settaskDetailsModal}
          listName={listName}
          spaceName={spaceName}
          isShowSpace={true}
        />
      )}
    </>
  );
};

export default SmallCalendar;
