import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import CashInHand from "../../pages/InventoryManagement/CashInHand/CashInHand";

const CashInHandRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "cashinhand",
      element: <CashInHand />,
    },
  ],
};

export default CashInHandRoutes;
