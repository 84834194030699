import React, { useState } from "react";
import CategoryItemMoreInfoModal from "./CategoryItemMoreInfoModal";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";
import {
  getName,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { toast } from "react-toastify";
import { ProductCategoryRelatedEndPoints } from "../../../../api/Endpoints";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

function CategoryItemsTable({
  categoryItemsData,
  page,
  pageLimit,
  handleGetCategoryItems,
  setitemModal,
  setisEdit,
  setslectedItem,
  slectedItem,
  isModuleReadWriteAccessForCategoryItems,
}) {
  const user = useSelector((state) => state.user);
  const [isDetailsModalOpen, setisDetailsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const handleDetailsDrawer = () => {
    setisDetailsModalOpen(!isDetailsModalOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
    seterrorMessage("");
    setLoader(false);
  };

  const handleDeteleCatergoryItem = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      productId: slectedItem?._id,
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.deleteMenuItem,
      data
    );
    if (res?.success) {
      toast.success("Role Delete Successfully");
      handleGetCategoryItems();
      handleClose();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };
  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8">
          <thead>
            <tr className="text-center">
              <th>No.</th>
              <th className=" text-start">Item Name</th>
              <th className=" text-start">Created By</th>
              <th>No. Of Raw Material</th>
              <th>Time To Cook</th>
              <th>Cost Price</th>
              <th>Selling Price</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {categoryItemsData?.map((item, index) => (
              <tr className="text-center">
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td className="text-capitalize text-start">
                  {item?.images?.[0] && (
                    <>
                      <img
                        className="ms-2 rounded"
                        width="25"
                        height="25"
                        src={item?.images?.[0]}
                        alt="img"
                        style={{ border: "1px solid #333333" }}
                      />
                      &nbsp;&nbsp;
                    </>
                  )}
                  {item?.productName}
                </td>
                <td>
                  {" "}
                  {item?.createdBy ? (
                    <>
                      <Avatar
                        name={item?.createdBy?.name}
                        count={1}
                        image={item?.createdBy?.image}
                        size="xxs"
                        color="primary"
                      />
                      &nbsp;
                      {getName(item?.createdBy, user?._id)}
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td>{item?.items?.length}</td>
                <td>
                  {item?.avgCookingTimeInMin ? item?.avgCookingTimeInMin : 0}{" "}
                  min
                </td>

                <td>₹ {item?.costPrice}</td>
                <td>₹ {item?.sellingPrice}</td>
                <td>
                  <span className="feather-icon pointer">
                    <i
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="ri-more-fill"
                    ></i>
                    <div className="dropdown-menu">
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          setslectedItem(item);
                          setisDetailsModalOpen(true);
                        }}
                      >
                        <span
                          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                        >
                          <span className="btn-icon-wrap">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                            </svg>
                          </span>
                        </span>
                        More Info
                      </div>
                      {isModuleReadWriteAccessForCategoryItems && (
                        <div
                          className="dropdown-item"
                          onClick={() => {
                            setslectedItem(item);
                            setisEdit(true);
                            setitemModal(true);
                          }}
                        >
                          <span
                            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="20"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </span>
                          </span>
                          Edit Item
                        </div>
                      )}
                      {isModuleReadWriteAccessForCategoryItems && (
                        <div
                          className="dropdown-item"
                          onClick={() => {
                            setIsOpen(true);
                            setslectedItem(item);
                          }}
                        >
                          <span
                            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                              </svg>
                            </span>
                          </span>
                          Delete Item
                        </div>
                      )}
                    </div>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDetailsModalOpen && (
        <CategoryItemMoreInfoModal
          slectedItem={slectedItem}
          handleDetailsDrawer={handleDetailsDrawer}
        />
      )}
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text="Are You Sure You Want To Delete This Item"
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleDeteleCatergoryItem}
      />
    </>
  );
}

export default CategoryItemsTable;
