import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { LocationRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";

function AttendanceMethod(props) {
  const {
    selectSetup,
    selectSetup3,
    selectedAttendanceOption,
    setSelectedAttendanceOption,
    selfgpsOptions,
    setselfGpsOptions,
    autogpsOptions,
    setautoGpsOptions,
    completeSecondStep,
    selectedLocation,
    setselectedLocation,
    checkInDistance,
    setcheckInDistance,
  } = props;
  const [loader2, setloader2] = useState(false);
  const [allLocation, setallLocation] = useState([]);

  const distance = [10, 30, 50, 100, 200];

  const handleRadioChange = (event) => {
    setSelectedAttendanceOption(event.target.value);
    setselfGpsOptions({
      gpsTracking: false,
      liveSelfie: false,
      gpsLocking: false,
    });
    setautoGpsOptions({
      gpsTracking: false,
      liveSelfie: false,
      gpsLocking: false,
    });
  };
  const handleCheckboxChange = (option) => {
    setselfGpsOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };
  const handleAutoCheckboxChange = (option) => {
    setautoGpsOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  };

  const handleGetAllLocation = async () => {
    startApiCall(null, setloader2);

    const res = await ApiCall("get", LocationRelatedEndPoints.getLocation, {
      params: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
      },
    });
    if (res?.success) {
      setallLocation(res?.locations);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetAllLocation();
  }, []);

  return (
    <div
      className="d-flex flex-column justify-content-between border rounded-7 p-4 w-100 gap-4 mh-450p"
      style={{ overflow: "auto" }}
    >
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column">
          <p className="text-dark fs-6 ">Attendance method</p>
          <p className="fs-7 mt-1">
            Choose a method by which you would like to keep a tarck of
            employee’s daily attendance
          </p>
        </div>
        <div className="d-flex flex-column">
          <div className="form-check">
            <input
              className="form-check-input circle pointer"
              type="radio"
              id="radio31"
              name="attendanceMethod"
              value="selfAttendance"
              checked={selectedAttendanceOption === "selfAttendance"}
              onChange={handleRadioChange}
            />
            <label className="form-check-label fs-6 text-dark ">
              Self Attendance
            </label>
          </div>
          <p className="fs-7 ms-4">
            In this type of attendance the employee marks their presence
            themselves and the admin verifies the attendance sheet for all
            employees
          </p>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-10">
              <div className="d-flex flex-column mt-1">
                <div className="form-check">
                  <input
                    className="form-check-input pointer"
                    type="checkbox"
                    name="option1"
                    value="gpsTracking"
                    disabled={selectedAttendanceOption !== "selfAttendance"}
                    checked={selfgpsOptions?.gpsTracking}
                    onChange={() => handleCheckboxChange("gpsTracking")}
                  />
                  <label className="form-check-label text-dark ">
                    Gps tracking
                  </label>
                </div>
                <p className="fs-7 ms-4">
                  In this type of attendance the Admin will get notified if the
                  employee logs in and out from an unfamiliar location{" "}
                </p>
              </div>
              <div className="d-flex flex-column mt-1">
                <div className="form-check">
                  <input
                    className="form-check-input pointer"
                    type="checkbox"
                    name="option2"
                    value="liveSelfie"
                    disabled={selectedAttendanceOption !== "selfAttendance"}
                    checked={selfgpsOptions.liveSelfie}
                    onChange={() => handleCheckboxChange("liveSelfie")}
                  />
                  <label className="form-check-label text-dark ">
                    Live selfie
                  </label>
                </div>
                <p className="fs-7 ms-4">
                  In this type of attendance the employee will be asked to take
                  a selfie everytime they log in and log out which will later be
                  verified by the admin
                </p>
              </div>
              <div className="d-flex flex-column mt-1">
                <div className="form-check">
                  <input
                    className="form-check-input pointer"
                    type="checkbox"
                    name="option3"
                    value="gpsLocking"
                    disabled={selectedAttendanceOption !== "selfAttendance"}
                    checked={selfgpsOptions.gpsLocking}
                    onChange={() => handleCheckboxChange("gpsLocking")}
                  />
                  <label className="form-check-label text-dark">
                    Gps Locking
                  </label>
                </div>
                <p className="fs-7 ms-4">
                  In this type of attendance the employee will be able to log in
                  and out within a specified radius of the registered location.
                </p>
              </div>
              {selfgpsOptions.gpsLocking && (
                <div className="d-flex col-sm-12 mt-3">
                  <div className="col-sm-6">
                    <div className="form-check">
                      <label className="form-check-label text-dark">
                        Choose location
                      </label>
                    </div>
                    <p className="fs-7 ms-4">
                      Select a location from where your employee will be working
                    </p>
                    {allLocation?.length > 0 ? (
                      <div className="d-flex align-items-center ms-4">
                        <select
                          name="Type"
                          className="p-2"
                          value={selectedLocation}
                          onChange={(e) => {
                            setselectedLocation(e.target.value);
                          }}
                        >
                          <option value={""}>Select Location</option>
                          {allLocation?.map((loc) => (
                            <option value={loc?._id}>
                              {loc?.locationName}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : (
                      <span className="text-danger ms-4">
                        {" "}
                        No Location Added
                      </span>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <div className="form-check">
                      <label className="form-check-label text-dark">
                        Check-in radius
                      </label>
                    </div>
                    <p className="fs-7 ms-4">
                      The employee will check-in with in the radius of the
                      allocated location only
                    </p>
                    <div className="d-flex align-items-center ms-4">
                      <select
                        name="Type"
                        className="p-2"
                        value={checkInDistance}
                        disabled={!selectedLocation}
                        onChange={(e) => {
                          setcheckInDistance(e.target.value);
                        }}
                      >
                        <option value={""}>Select Radius</option>
                        {distance?.map((dis) => (
                          <option value={dis}>{dis} m</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="form-check">
          <input
            className="form-check-input circle pointer"
            type="radio"
            id="radio2"
            name="attendanceMethod"
            value="manual"
            checked={selectedAttendanceOption === "manual"}
            onChange={handleRadioChange}
          />
          <label className="form-check-label fs-6 text-dark">Manual</label>
        </div>
        <p className="fs-7 ms-4">
          In this type of attendance the admin will manually mark each employees
          absence and presence everyday.
        </p>
      </div>
      {/* <div className="d-flex flex-column ">
        <div className="form-check">
          <input
            className="form-check-input circle pointer"
            type="radio"
            id="radio3"
            name="attendanceMethod"
            value="autoMarking"
            checked={selectedAttendanceOption === "autoMarking"}
            onChange={handleRadioChange}
          />
          <label className="form-check-label fs-6 text-dark ">
            Auto Marking
          </label>
        </div>
        <p className="fs-7 ms-4">
          In this type of attendance the employee marks their presence
          themselves and the admin verifies the attendance sheet for all
          employees
        </p>
      </div> */}
      {/* <div class="container">
        <div class="row justify-content-center">
          <div class="col-10">
            <div className="d-flex flex-column mt-1">
              <div className="form-check">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="check2"
                  name="option2"
                  value="gpsTracking"
                  disabled={selectedAttendanceOption !== "autoMarking"}
                  checked={autogpsOptions.gpsTracking}
                  onChange={() => handleAutoCheckboxChange("gpsTracking")}
                />
                <label className="form-check-label text-dark ">
                  Gps tracking
                </label>
              </div>
              <p className="fs-7 ms-4">
                In this type of attendance the Admin will get notified if the
                employee logs in and out from an unfamiliar location{" "}
              </p>
            </div>
            <div className="d-flex flex-column mt-1">
              <div className="form-check">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="check3"
                  name="option3"
                  value="liveSelfie"
                  disabled={selectedAttendanceOption !== "autoMarking"}
                  checked={autogpsOptions.liveSelfie}
                  onChange={() => handleAutoCheckboxChange("liveSelfie")}
                />
                <label className="form-check-label text-dark ">
                  Live selfie
                </label>
              </div>
              <p className="fs-7 ms-4">
                In this type of attendance the employee will be asked to take a
                selfie everytime they log in and log out which will later be
                verified by the admin
              </p>
            </div>
            <div className="d-flex flex-column mt-1">
              <div className="form-check">
                <input
                  className="form-check-input pointer"
                  type="checkbox"
                  id="check4"
                  name="option4"
                  value="gpsLocking"
                  disabled={selectedAttendanceOption !== "autoMarking"}
                  checked={autogpsOptions.gpsLocking}
                  onChange={() => handleAutoCheckboxChange("gpsLocking")}
                />
                <label className="form-check-label text-dark">
                  Gps Locking
                </label>
              </div>
              <p className="fs-7 ms-4">
                In this type of attendance the employee will be able to log in
                and out within a specified radius of the registered location.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="d-flex align-items-center justify-content-between mt-4 pointer">
        <span
          onClick={() => selectSetup()}
          className="d-flex align-items-center gap-1 text-dark"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-double-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
            <path
              fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          <span>Previous</span>
        </span>
        <button
          className="btn btn-sm btn-primary px-4"
          disabled={
            !completeSecondStep ||
            (selfgpsOptions.gpsLocking === true && !selectedLocation)
          }
          onClick={selectSetup3}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default AttendanceMethod;
