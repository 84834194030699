import React from "react";
import logo from "../../../../assets/images/logo/bizhoodfullLogo.png";
import QuoteDetailsItemTable from "./QuoteDetailsItemTable";
import { dateFormatter } from "../../../../global-functions/globalFunctions";
import { Avatar } from "../../../../components/Avatar/Avatar";

function QuotesDetailsBody({ quoteDetailsData }) {
  return (
    <div className="d-flex gap-3">
      <div
        className="col-sm-8 simple-scrollbar"
        style={{ maxHeight: "75vh", overflow: "auto" }}
      >
        <div className="card p-4 d-flex flex-column gap-2 border-ligth rounded-0">
          <div className="d-flex justify-content-between">
            <div className="col-sm-6">
              <img src={logo} alt="sociohoodlogo" className="mw-175p mh-150p" />
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <div className="d-block">
                <div className="text-primary fw-semibold fs-4 mt-2 ">Quote</div>
                <div>#{quoteDetailsData?.quoteNumber}</div>
                <div>
                  Date Created : {dateFormatter(quoteDetailsData?.quoteDate)}
                </div>
                <div>
                  Expiry Date : {dateFormatter(quoteDetailsData?.expiryDate)}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <span className="text-capitalize">
              Bill to : {quoteDetailsData?.customerId?.name}
            </span>
          </div>
          <div className="mt-1">
            <span className="text-capitalize">
              Subject : {quoteDetailsData?.subject}.
            </span>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <div
              className="alert alert-light text-dark fs-7 w-100 "
              role="alert"
            >
              <div className="p-2 d-flex gap-3">
                <div className="col-md-6">
                  <strong>Billing Address:</strong>
                  {quoteDetailsData?.billingAddress ? (
                    <>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          City : {quoteDetailsData?.billingAddress?.city}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          PinCode : {quoteDetailsData?.billingAddress?.pinCode}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          Country : {quoteDetailsData?.billingAddress?.country}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          {quoteDetailsData?.billingAddress?.street}
                        </span>
                      </div>
                    </>
                  ) : (
                    <span className="ps-2">No Billing Address</span>
                  )}
                </div>
                <div className="col-md-6">
                  <strong>Shipping Address:</strong>
                  {quoteDetailsData?.shippingAddress ? (
                    <>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          City : {quoteDetailsData?.shippingAddress?.city}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          PinCode : {quoteDetailsData?.shippingAddress?.pinCode}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          Country : {quoteDetailsData?.shippingAddress?.city}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="text-capitalize">
                          {quoteDetailsData?.shippingAddress?.street}
                        </span>
                      </div>
                    </>
                  ) : (
                    <span className="ps-2">No Shipping Address</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={{ maxHeight: "200px", overflow: "auto" }}>
            <QuoteDetailsItemTable quoteDetailsData={quoteDetailsData} />
          </div>

          <div className="d-flex justify-content-end">
            <span>Shipping charges : 0</span>
          </div>
          <div className="d-flex justify-content-end">
            <span>Sub Total : ₹ {quoteDetailsData?.totalAmount}</span>
          </div>
        </div>
      </div>
      <div
        className="col-sm-4 p-4 border-start"
        style={{ maxHeight: "75vh", overflow: "auto" }}
      >
        <div className="d-flex gap-2">
          <span className="text-dark text-capitalize">Quote Status : Sent</span>
        </div>
        <div className="d-flex gap-2">
          <span className="text-dark text-capitalize gap-2">
            Created by :{" "}
            <Avatar
              name={quoteDetailsData?.createdBy?.name}
              count={1}
              image={quoteDetailsData?.createdBy?.image}
              size="xss"
              color="primary"
            />
            &nbsp;&nbsp; {quoteDetailsData?.createdBy?.name}
          </span>
        </div>
        <div className="card d-flex flex-column gap-2 border-ligth rounded-0 p-3 mt-3 mnh-200p">
          <div className="d-flex gap-2">
            <span className="text-dark text-capitalize gap-2">
              Terms & Condition
            </span>
          </div>
          {quoteDetailsData?.termsAndConditions}
        </div>
      </div>
    </div>
  );
}

export default QuotesDetailsBody;
