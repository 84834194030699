import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import MyComplaint from "../../pages/Complaints/MyComplaints/MyComplaints";

const MyComplaintRoutes = {
  path: "/grievance",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <MyComplaint />,
    },
    {
      path: "mygrievance",
      element: <MyComplaint />,
    },
  ],
};

export default MyComplaintRoutes;
