import React from "react";
import SkeletonConstant from "../../components/Constant/SkeletonConstant";

const HolidaySkeleton = () => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>
            <SkeletonConstant
              width="w-50p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-110p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-110p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-80p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-110p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-190p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-200"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-400p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-190p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-200"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-400p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-190p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-200"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-400p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-190p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-200"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-400p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-190p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-200"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-400p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-190p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-200"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-400p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-190p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-140p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-200"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-400p"
              height="h-35p"
              additional="mx-auto"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default HolidaySkeleton;
