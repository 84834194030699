import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  getAvatarClass,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import {
  CrmParticipantsGroup,
  LeadRelatedEndPoints,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { Avatar } from "../../../components/Avatar/Avatar";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function AssigneGroupModal({
  groupAssigneeModal,
  setgroupAssigneeModal,
  selectedGroups,
  setselectedGroups,
  selectedGroupId,
  setselectedGroupId,
  leadDetails,
  editGroup,
  handleGetLeadDetails,
}) {
  const { user } = useSelector((state) => state);
  const [searchKey, setsearchKey] = useState("");
  const [loader, setloader] = useState(false);
  const [allGroups, setallGroups] = useState([]);
  const [loader2, setloader2] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(5000);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const closeModal = (e) => {
    e && e.preventDefault();
    if (!editGroup) {
      setallGroups([]);
      setselectedGroupId([]);
    }
    setgroupAssigneeModal(false);
    setsearchKey("");
    const closeModalDom = document.getElementById(`select_group_crm`);
    if (closeModalDom) closeModalDom.click();
  };

  const handleGetAllGroups = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: { businessId: getCurrentWorkspaceId(), searchKey: searchKey },
    };
    const res = await ApiCall("post", CrmParticipantsGroup.getGroup, data);
    if (res?.success) {
      setallGroups(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleAddGroup = async () => {
    startApiCall(null, setloader2);
    const data = {
      leadOrDealId: leadDetails?._id,
      participantsGroupIds: [selectedGroupId?._id],
      participantsGroupActionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.addParticipantGroupInLead,
      data
    );
    const ids = [
      ...selectedGroupId?.admins?.map((admin) => admin?._id),
      ...selectedGroupId?.employees?.map((employee) => employee?._id),
      ...selectedGroupId?.managers?.map((manager) => manager?._id),
    ];
    if (res?.success) {
      handleAddParticipantToLead(ids);
    } else {
      setloader2(false);
    }
  };

  //Add Participant to Lead
  const handleAddParticipantToLead = async (ids) => {
    startApiCall(null, setloader2);
    const data = {
      leadOrDealId: leadDetails?._id,
      employeeIds: ids,
      participantsGroupActionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.addParticipantInLead,
      data
    );
    if (res?.success) {
      closeModal();
      handleGetLeadDetails();
      toast.success("Group has been added into Lead");
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const filteredGroups = filterSearchData(allGroups, searchKey);

  useEffect(() => {
    if (groupAssigneeModal) {
      handleGetAllGroups();
    }
  }, [groupAssigneeModal]);

  const handleRadioChange = (grp) => {
    setErrorMessage && setErrorMessage("");
    setselectedGroupId(grp);
  };
  // useEffect(() => {
  //   let temp = [];
  //   selectedGroupId?.map((ae) => {
  //     allGroups?.map((grp) => {
  //       if (grp?._id === ae) {
  //         temp.push(grp);
  //       }
  //     });
  //   });
  //   setselectedGroups(temp);
  // }, [selectedGroupId]);

  useEffect(() => {
    if (
      leadDetails?.participantsGroupId ||
      leadDetails?.participantsGroupId?.length > 0
    ) {
      setselectedGroupId(leadDetails?.[0]);
    }
  }, [leadDetails]);

  useEffect(() => {
    if (editGroup) {
      const groupIds = editGroup?.[0];
      setselectedGroupId(groupIds);
    }
  }, [editGroup]);

  return (
    <div
      id="select_group_crm"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      style={{
        display: groupAssigneeModal ? "block" : "none",
        pointerEvents: loader ? "none" : "",
      }}
      aria-hidden="true"
    >
      <form onSubmit={closeModal}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                className="btn-close mt-2 pe-3"
                type="button"
                onClick={closeModal}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="mb-3">Select Group</h5>
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-end align-items-center">
                  <span className="input-affix-wrapper input-search affix-border">
                    <input
                      type="text"
                      className="form-control bg-transparent"
                      data-navbar-search-close="false"
                      placeholder="Search Group"
                      aria-label="Search"
                      value={searchKey}
                      onChange={(e) => {
                        setsearchKey(e.target.value);
                      }}
                    />
                    <span className="input-suffix">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              </div>

              <div className="collapse-simple">
                <div className="card">
                  <div className="card-header">
                    <span
                      role="button"
                      data-bs-toggle="collapse"
                      href="#space_members"
                      aria-expanded="true"
                      className="fw-light text-uppercase"
                    >
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 21V20C8 19.4696 8.21071 18.9609 8.58579 18.5858C8.96086 18.2107 9.46957 18 10 18H14C14.5304 18 15.0391 18.2107 15.4142 18.5858C15.7893 18.9609 16 19.4696 16 20V21M17 10H19C19.5304 10 20.0391 10.2107 20.4142 10.5858C20.7893 10.9609 21 11.4696 21 12V13M3 13V12C3 11.4696 3.21071 10.9609 3.58579 10.5858C3.96086 10.2107 4.46957 10 5 10H7M10 13C10 13.5304 10.2107 14.0391 10.5858 14.4142C10.9609 14.7893 11.4696 15 12 15C12.5304 15 13.0391 14.7893 13.4142 14.4142C13.7893 14.0391 14 13.5304 14 13C14 12.4696 13.7893 11.9609 13.4142 11.5858C13.0391 11.2107 12.5304 11 12 11C11.4696 11 10.9609 11.2107 10.5858 11.5858C10.2107 11.9609 10 12.4696 10 13ZM15 5C15 5.53043 15.2107 6.03914 15.5858 6.41421C15.9609 6.78929 16.4696 7 17 7C17.5304 7 18.0391 6.78929 18.4142 6.41421C18.7893 6.03914 19 5.53043 19 5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3C16.4696 3 15.9609 3.21071 15.5858 3.58579C15.2107 3.96086 15 4.46957 15 5ZM5 5C5 5.53043 5.21071 6.03914 5.58579 6.41421C5.96086 6.78929 6.46957 7 7 7C7.53043 7 8.03914 6.78929 8.41421 6.41421C8.78929 6.03914 9 5.53043 9 5C9 4.46957 8.78929 3.96086 8.41421 3.58579C8.03914 3.21071 7.53043 3 7 3C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5Z"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        Group
                        <span class="badge badge-pill badge-sm badge-soft-primary ms-1">
                          {allGroups?.length}
                        </span>
                      </span>

                      <div className="avatar-group avatar-group-sm avatar-group-overlapped me-6 pointer">
                        {selectedGroups?.slice(0, 4)?.map((grp, index) => {
                          let name = grp?.name;
                          const nameInitial = name?.[0]?.toUpperCase();
                          const avatarClass = getAvatarClass(nameInitial);

                          return (
                            <CustomTooltip text={grp?.name} placement="bottom">
                              <div
                                key={index}
                                className={`avatar avatar-xxs ${avatarClass} avatar-rounded`}
                              >
                                <span className="initial-wrap">
                                  {nameInitial}
                                </span>
                              </div>
                            </CustomTooltip>
                          );
                        })}
                        {selectedGroups?.length > 4 && (
                          <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                            <span className="initial-wrap">
                              +{selectedGroups.length - 4}
                            </span>
                          </div>
                        )}
                      </div>
                    </span>
                  </div>

                  <div
                    id="space_members"
                    className="collapse show mh-430p "
                    style={{ overflowY: "scroll" }}
                  >
                    <div className="card-body">
                      {!loader && filteredGroups?.length > 0 && (
                        <ul className="activity-list list-group list-group-flush">
                          {filteredGroups?.map((group) => {
                            const nameInitial = group?.name?.[0].toUpperCase();
                            const avatarClass = getAvatarClass(nameInitial);
                            return (
                              <li className="list-group-item" key={group._id}>
                                <div className="media">
                                  <div className="media-head">
                                    <Avatar
                                      name={group?.name}
                                      count={1}
                                      image={group?.image}
                                      size="xss"
                                      color="primary"
                                    />
                                  </div>
                                  <div className="media-body row">
                                    <div className="col-md-8 d-flex justify-content-between">
                                      <span
                                        className="text-dark"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {group?.name} (
                                        {group?.admins?.length +
                                          group?.managers?.length +
                                          group?.employees?.length}{" "}
                                        People)
                                      </span>
                                    </div>

                                    <div className="col-md-4 d-flex justify-content-center">
                                      <input
                                        className="form-check-input success pointer"
                                        type="radio"
                                        value=""
                                        onChange={() =>
                                          handleRadioChange(group)
                                        }
                                        checked={
                                          selectedGroupId?._id === group?._id
                                        }
                                        // disabled={editGroup?.filter(
                                        //   (id) => id === group?._id
                                        // )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {!loader && filteredGroups?.length === 0 && (
                        <p className="text-capitalize text-center text-dark">
                          No groups found
                        </p>
                      )}

                      {loader && <DataLoader />}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                {/* <button
                  className="btn btn-secondary me-2"
                  type="button"
                  onClick={closeModal}
                >
                  Close
                </button> */}
                <button
                  type="submit"
                  onClick={() => {
                    handleAddGroup();
                  }}
                  disabled={loader || loader2 || selectedGroupId?.length === 0}
                  className="btn btn-primary btn-add-tasklist"
                >
                  {loader2 ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AssigneGroupModal;
