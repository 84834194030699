import React, { useEffect, useState } from "react";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../../../global-functions/globalFunctions";
import { activityGroupRelatedEndpoints } from "../../../../../../api/Endpoints";
import ApiCall from "../../../../../../api/ApiCall";
import NoData from "../../../../../../components/NoData/NoData";
import DataLoader from "../../../../../../components/Loaders/DataLoader/DataLoader";
import { Avatar } from "../../../../../../components/Avatar/Avatar";
import CustomTooltip from "../../../../../../components/CustomTooltip/CustomTooltip";

function DetailsModalAdditional({
  selectTab,
  allActivity,
  setimageData,
  setimageModal,
}) {
  const [statusLoader, setStatusLoader] = useState(false);
  const [status, setStatus] = useState([]);

  //NEW STATES
  const handleGetAllStatus = async (id) => {
    startApiCall(null, setStatusLoader);
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
        activityGroupId: allActivity?.activityGroup?._id,
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivitySubStatus,
      data
    );
    if (res?.success) {
      setStatus(res?.status?.data);
      setStatusLoader(false);
    } else {
      setStatusLoader(false);
    }
  };

  const mandatoryIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="currentColor"
      class="bi bi-check-square text-success ms-2"
      viewBox="0 0 16 16"
    >
      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
      <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
    </svg>
  );

  useEffect(() => {
    if (selectTab === "Attachments") {
      handleGetAllStatus();
    }
  }, [selectTab]);

  const renderMandatoryStatus = (tasks) => {
    return (
      <>
        <strong>Mandatory Task</strong>
        {Object.entries(tasks).map(([key, task]) => {
          if (task.mandatory) {
            return (
              <div key={key} className="d-flex align-items-center p-2">
                {mandatoryIcon}
                <span className="ms-2">{key}</span>
              </div>
            );
          }
          return null;
        })}
      </>
    );
  };

  return (
    <div className="row gx-3 px-3 w-100 mh-500p overflow-auto ">
      {!statusLoader && status?.length > 0 && (
        <>
          {status?.map((data) => {
            const noteAttachments = allActivity?.filter(
              (attachment) =>
                attachment?.attachmentType === "note" &&
                attachment?.activityStatus === data?._id
            );
            const file = allActivity?.filter(
              (attachment) =>
                attachment?.attachmentType === "file" &&
                attachment?.activityStatus === data?._id
            );

            const automaticLocation = allActivity?.filter(
              (attachment) =>
                attachment?.attachmentType === "automaticlocation" &&
                attachment?.activityStatus === data?._id
            );
            const manualLocation = allActivity?.filter(
              (attachment) =>
                attachment?.attachmentType === "manuallocation" &&
                attachment?.activityStatus === data?._id
            );

            return (
              <>
                {(noteAttachments?.length > 0 ||
                  file?.length > 0 ||
                  automaticLocation?.length > 0 ||
                  manualLocation?.length > 0) && (
                  <div className="collapse-simple border-bottom">
                    <div className="card">
                      <div className="card-header">
                        <a
                          role="button"
                          data-bs-toggle="collapse"
                          href={`#${data?.name?.replace(/\s+/g, "")}`}
                          aria-expanded="true"
                        >
                          <h5
                            className={`mb-0 text-capitalize ${
                              allActivity?.activityStatus?._id === data?._id
                                ? "text-success "
                                : "text-primary"
                            }`}
                          >
                            <span
                              class="badge badge-indicator badge-indicator-xl"
                              style={{
                                backgroundColor: `${
                                  allActivity?.activityStatus?._id === data?._id
                                    ? "#32CD32"
                                    : "#0D6EFD"
                                }`,
                              }}
                            ></span>
                            &nbsp;
                            {data?.name} &nbsp;
                            {data?.tasks && (
                              <CustomTooltip
                                text={
                                  data?.tasks &&
                                  !Object.values(data?.tasks)?.some(
                                    (task) => task.mandatory
                                  )
                                    ? "No Mandatory Task"
                                    : renderMandatoryStatus(data?.tasks)
                                }
                                placement="right"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="mb-2"
                                >
                                  <circle
                                    cx="11"
                                    cy="11"
                                    r="10.5"
                                    stroke="#3862DD"
                                  />
                                  <path
                                    d="M11.9224 8.0752V16H9.98145V8.0752H11.9224ZM9.84961 6.00244C9.84961 5.71436 9.94727 5.4751 10.1426 5.28467C10.3428 5.09424 10.6113 4.99902 10.9482 4.99902C11.2852 4.99902 11.5513 5.09424 11.7466 5.28467C11.9468 5.4751 12.0469 5.71436 12.0469 6.00244C12.0469 6.28564 11.9468 6.52246 11.7466 6.71289C11.5513 6.90332 11.2852 6.99854 10.9482 6.99854C10.6113 6.99854 10.3428 6.90332 10.1426 6.71289C9.94727 6.52246 9.84961 6.28564 9.84961 6.00244Z"
                                    fill="#3862DD"
                                  />
                                </svg>
                              </CustomTooltip>
                            )}
                          </h5>
                        </a>
                      </div>
                      <div
                        id={`${data?.name?.replace(/\s+/g, "")}`}
                        className="collapse show"
                      >
                        {(noteAttachments?.length > 0 ||
                          file?.length > 0 ||
                          automaticLocation?.length > 0 ||
                          manualLocation?.length > 0) && (
                          <div className="row gx-3 row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-2 w-90-sm">
                            <div className="row gx-3 px-3 w-100">
                              <div className=" mt-2">
                                <label
                                  className="form-check-label d-flex justify-content-between"
                                  htmlFor="assignCard"
                                >
                                  <span> Location details </span>
                                </label>
                                <div className="col-12 d-flex gap-2">
                                  <div className="col-6">
                                    {" "}
                                    <div
                                      className="activity-details-card mt-3 gap-5 col-6"
                                      id="locationAvailable"
                                    >
                                      {automaticLocation?.length > 0 &&
                                        automaticLocation?.[0]?.data?.url?.[0]
                                          ?.mapLink && (
                                          <>
                                            <div className="col-12 d-flex flex-column gap-3 mt-1">
                                              <div className="d-flex align-items-center w-100 px-2 ">
                                                <label className="form-label font-2 mt-2">
                                                  Updated By :
                                                </label>
                                                <span className="text-dark ms-2">
                                                  <Avatar
                                                    name={
                                                      automaticLocation?.[0]
                                                        ?.data?.url?.[0]
                                                        ?.updatedBy?.name
                                                    }
                                                    count={1}
                                                    image={
                                                      automaticLocation?.[0]
                                                        ?.data?.url?.[0]
                                                        ?.updatedBy?.image
                                                    }
                                                    size="xxs"
                                                    color="primary"
                                                  />
                                                  <span className="ms-1">
                                                    {
                                                      automaticLocation?.[0]
                                                        ?.data?.url?.[0]
                                                        ?.updatedBy?.name
                                                    }
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-12 d-flex flex-column gap-3 mt-1">
                                              <div className="d-flex align-items-center w-100 px-2 ">
                                                <label className="form-label font-2 mt-2">
                                                  Updated At :
                                                </label>
                                                <span className="text-dark ms-2">
                                                  {dateAndTimeFormatter(
                                                    automaticLocation?.[0]?.data
                                                      ?.url?.[0]?.updatedAt
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-12 d-flex gap-2 mt-4">
                                              <div className="col-12">
                                                <div className="d-flex align-items-center border-bottom rounded-0 w-100 text-dark">
                                                  <div className="d-flex  w-100 px-2 text-truncate text-align-center justify-content-center">
                                                    <a
                                                      className="fw-bold w-100 mb-2 d-flex align-content-center justify-content-center"
                                                      target="_blank"
                                                      href={
                                                        automaticLocation?.[0]
                                                          ?.data?.url?.[0]
                                                          ?.mapLink
                                                      }
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-geo"
                                                        viewBox="0 0 16 16"
                                                      >
                                                        <path
                                                          fill-rule="evenodd"
                                                          d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"
                                                        />
                                                      </svg>
                                                      View Added Location
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {automaticLocation?.length === 0 &&
                                        !automaticLocation?.[0]?.data?.url?.[0]
                                          ?.mapLink && (
                                          <div className="d-flex flex-column col-12 align-content-center align-items-center h-100">
                                            <span className="text-secondary p-4 mt-3 font-4">
                                              No Location added
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    {" "}
                                    <div
                                      className="activity-details-card mt-3 gap-2 col-6 overflow-auto"
                                      id="manualLocationAvailable"
                                    >
                                      {manualLocation?.length > 0 &&
                                        manualLocation?.[0]?.data?.url?.[0]
                                          ?.locationData && (
                                          <>
                                            <div className="col-12 d-flex flex-column gap-3 mt-1">
                                              <div className="d-flex align-items-center w-100 px-2 ">
                                                <label className="form-label font-2 mt-2">
                                                  Updated By :
                                                </label>
                                                <span className="text-dark ms-2">
                                                  <Avatar
                                                    name={
                                                      manualLocation?.[0]?.data
                                                        ?.url?.[0]?.updatedBy
                                                        ?.name
                                                    }
                                                    count={1}
                                                    image={
                                                      manualLocation?.[0]?.data
                                                        ?.url?.[0]?.updatedBy
                                                        ?.image
                                                    }
                                                    size="xxs"
                                                    color="primary"
                                                  />
                                                  <span className="ms-1">
                                                    {
                                                      manualLocation?.[0]?.data
                                                        ?.url?.[0]?.updatedBy
                                                        ?.name
                                                    }
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-12 d-flex flex-column gap-3 mt-1">
                                              <div className="d-flex align-items-center w-100 px-2 ">
                                                <label className="form-label font-2 mt-2">
                                                  Updated At :
                                                </label>
                                                <span className="text-dark ms-2">
                                                  {dateAndTimeFormatter(
                                                    manualLocation?.[0]?.data
                                                      ?.url?.[0]?.updatedAt
                                                  )}
                                                </span>
                                              </div>
                                            </div>
                                            <div className="col-12 d-flex flex-column gap-2 mt-1">
                                              <label className="form-label font-2">
                                                Area Name
                                              </label>
                                              <div className="d-flex align-items-center border-bottom rounded-0 w-100 text-dark">
                                                <div className="d-flex align-items-center justify-content-between w-100 px-2 text-truncate">
                                                  <span className="px-2">
                                                    {
                                                      manualLocation?.[0]?.data
                                                        ?.url?.[0]?.locationData
                                                        ?.areaName
                                                    }
                                                    ,{" "}
                                                    {
                                                      manualLocation?.[0]?.data
                                                        ?.url?.[0]?.locationData
                                                        ?.areaName
                                                    }
                                                    ,{" "}
                                                    {
                                                      manualLocation?.[0]?.data
                                                        ?.url?.[0]?.locationData
                                                        ?.areaName
                                                    }
                                                    ,{" "}
                                                    {
                                                      manualLocation?.[0]?.data
                                                        ?.url?.[0]?.locationData
                                                        ?.areaName
                                                    }
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      {manualLocation?.length === 0 &&
                                        !manualLocation?.[0]?.data?.url?.[0]
                                          ?.locationData && (
                                          <div className="d-flex flex-column col-12 align-content-center align-items-center h-100">
                                            <span className="text-secondary p-4 mt-3 font-4">
                                              No Manual location added
                                            </span>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row gx-3 px-3 w-100 mt-3">
                              <div className="col-sm-6 mt-2 mb-2">
                                <label
                                  className="form-check-label d-flex "
                                  htmlFor="fileCard"
                                >
                                  Files Uploaded{" "}
                                </label>
                                <div
                                  className="activity-details-card mt-3 mh-350p overflow-auto"
                                  id="fileCard"
                                >
                                  {(file?.[0]?.data?.url?.length === 0 ||
                                    file?.length === 0) && (
                                    <div className="d-flex flex-column col-12 align-content-center align-items-center h-100">
                                      <span className="text-secondary p-4 mt-3 font-4">
                                        No Files added
                                      </span>
                                    </div>
                                  )}

                                  {file?.length > 0 && (
                                    <>
                                      <div className="col-12 d-flex flex-column gap-3 mt-1">
                                        <div className="d-flex align-items-center w-100 px-2 ">
                                          <label className="form-label font-2 mt-2">
                                            Updated By :
                                          </label>
                                          <span className="text-dark ms-2">
                                            <Avatar
                                              name={
                                                file?.[0]?.data?.url?.[0]
                                                  ?.updatedBy?.name
                                              }
                                              count={1}
                                              image={
                                                file?.[0]?.data?.url?.[0]
                                                  ?.updatedBy?.image
                                              }
                                              size="xxs"
                                              color="primary"
                                            />
                                            <span className="ms-1">
                                              {
                                                file?.[0]?.data?.url?.[0]
                                                  ?.updatedBy?.name
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-12 d-flex flex-column gap-3 mt-1">
                                        <div className="d-flex align-items-center w-100 px-2 ">
                                          <label className="form-label font-2 mt-2">
                                            Updated At :
                                          </label>
                                          <span className="text-dark ms-2">
                                            {dateAndTimeFormatter(
                                              file?.[0]?.data?.url?.[0]
                                                ?.updatedAt
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="px-1 py-2">
                                        {file?.[0]?.data?.url?.map((att) => {
                                          return (
                                            <div className="activity-details-image-upload-card mt-2 h-50p">
                                              <div className="row gx-3 px-3 w-100 d-flex">
                                                <div className="col-2">
                                                  {att?.type?.includes(
                                                    "image"
                                                  ) ? (
                                                    <img
                                                      htmlFor="file"
                                                      id="local_show_img"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#attachment_img_modal"
                                                      src={att?.url}
                                                      style={{
                                                        padding: 5,
                                                      }}
                                                      onClick={() => {
                                                        setimageData(att);
                                                      }}
                                                      className="dropify-wrapper text-center w-30p h-30p mt-2 pointer"
                                                      alt="img"
                                                    />
                                                  ) : (
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="26"
                                                      height="26"
                                                      fill="currentColor"
                                                      class="bi bi-file-earmark-arrow-up"
                                                      viewBox="0 0 16 16"
                                                      className="mt-2 mb-1"
                                                    >
                                                      <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z" />
                                                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                    </svg>
                                                  )}
                                                </div>
                                                <div
                                                  className="col-7 w-150p text-truncate mt-2"
                                                  style={{ overflow: "hidden" }}
                                                >
                                                  <span>{att?.name}</span>
                                                </div>
                                                <div className="d-flex align-items-center gap-1 col-3">
                                                  {att?.type?.includes(
                                                    "image"
                                                  ) && (
                                                    <CustomTooltip
                                                      text="View Image"
                                                      placement="left"
                                                    >
                                                      <span
                                                        className="btn btn-icon btn-sm rounded-circle btn-light"
                                                        onClick={() => {
                                                          setimageData(
                                                            att?.url
                                                          );
                                                          setimageModal(true);
                                                        }}
                                                      >
                                                        <span className="feather-icon">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            class="bi bi-eye"
                                                            viewBox="0 0 16 16"
                                                          >
                                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </CustomTooltip>
                                                  )}

                                                  <CustomTooltip
                                                    text="Download Image"
                                                    placement="left"
                                                  >
                                                    <a
                                                      href={att?.url}
                                                      download={att?.name}
                                                    >
                                                      <span className="btn btn-icon btn-sm rounded-circle btn-soft-primary">
                                                        <span className="feather-icon">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            class="bi bi-download"
                                                            viewBox="0 0 16 16"
                                                          >
                                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                                                          </svg>
                                                        </span>
                                                      </span>
                                                    </a>
                                                  </CustomTooltip>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-6 mt-2 mb-2">
                                <label
                                  className="form-check-label d-flex"
                                  htmlFor="noteCard"
                                >
                                  Note{" "}
                                </label>

                                <div
                                  className="activity-details-card mt-3 overflow-auto"
                                  id="noteCard"
                                >
                                  {noteAttachments?.length > 0 && (
                                    <>
                                      <div className="col-12 d-flex flex-column gap-3 mt-1">
                                        <div className="d-flex align-items-center w-100 px-2 ">
                                          <label className="form-label font-2 mt-2">
                                            Updated By :
                                          </label>
                                          <span className="text-dark ms-2">
                                            <Avatar
                                              name={
                                                noteAttachments?.[0]?.data
                                                  ?.url?.[0]?.updatedBy?.name
                                              }
                                              count={1}
                                              image={
                                                noteAttachments?.[0]?.data
                                                  ?.url?.[0]?.updatedBy?.image
                                              }
                                              size="xxs"
                                              color="primary"
                                            />
                                            <span className="ms-1">
                                              {
                                                noteAttachments?.[0]?.data
                                                  ?.url?.[0]?.updatedBy?.name
                                              }
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-12 d-flex flex-column gap-3 mt-1">
                                        <div className="d-flex align-items-center w-100 px-2 ">
                                          <label className="form-label font-2 mt-2">
                                            Updated At :
                                          </label>
                                          <span className="text-dark ms-2">
                                            {dateAndTimeFormatter(
                                              noteAttachments?.[0]?.data
                                                ?.url?.[0]?.updatedAt
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                      <span className="text-secondary p-2 mt-3">
                                        <strong>Note</strong>:&nbsp;
                                        {
                                          noteAttachments?.[0]?.data?.url?.[0]
                                            ?.description
                                        }
                                      </span>
                                    </>
                                  )}
                                  {noteAttachments?.length === 0 && (
                                    <div className="d-flex flex-column col-12 align-content-center align-items-center h-100">
                                      <span className="text-secondary p-4 mt-3 font-4">
                                        No Notes found
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })}
          {allActivity?.length === 0 && (
            <NoData
              title=" No Results found.."
              description="Try adding a file, location, or note"
            />
          )}
        </>
      )}
      {!statusLoader && status?.length === 0 && (
        <NoData title={"No Status found.."} />
      )}
      {statusLoader && <DataLoader />}
    </div>
  );
}

export default DetailsModalAdditional;
