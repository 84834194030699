import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToAllVendors } from "../../../Navigation/Navigation";

const VendorDetailsHeader = ({
  handleRefresh,
  selectTab,
  startDate,
  setstartDate,
  setPage,
  endDate,
  setendDate,
}) => {
  const navigate = useNavigate();

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title link-dark">
          <button
            className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
            onClick={() => navigate(navigateToAllVendors())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <p className="mb-0">Back</p>
          </button>
          <div className="v-separator d-lg-inline-block"></div>
          <h1 className="header-title-font ">Vendor Details</h1>
        </span>
      </div>
      <div className="todo-options-wrap">
        {selectTab === "Transaction history" && (
          <>
            <div className="d-flex w-260p">
              <label className="mt-2">Start Date :</label>
              <input
                type="date"
                className="form-control w-65 ms-1"
                value={startDate}
                onChange={(e) => {
                  setstartDate(e.target.value);
                }}
              />
            </div>
            <div className="d-flex w-260p ms-2">
              <label className="mt-2">End Date :</label>
              <input
                type="date"
                className="form-control w-65 ms-1"
                value={endDate}
                onChange={(e) => {
                  setPage(1);
                  setendDate(e.target.value);
                }}
              />
            </div>
          </>
        )}

        <div className="v-separator d-lg-inline-block"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span
            className="btn-icon-wrap"
            onClick={() => {
              handleRefresh();
            }}
          >
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default VendorDetailsHeader;
