import React, { useEffect, useState } from "react";
import {
  reuiredField,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { VendorRelatedEndPoints } from "../../../../api/Endpoints";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import { toast } from "react-toastify";

const AddAccountDetails = ({
  id,
  handleGetVendorDetails,
  vendorDetails,
  editAccountDetails,
  seteditAccountDetails,
}) => {
  const [accHolderName, setAccHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editAccountDetails) {
      setAccHolderName(editAccountDetails?.accountHolderName);
      setAccountNumber(editAccountDetails?.accountNumber);
      setIfscCode(editAccountDetails?.ifscCode);
    }
  }, [editAccountDetails]);

  const handleReset = () => {
    setLoader(false);
    setErrorMessage("");
    setAccHolderName("");
    setIfscCode("");
    setAccountNumber("");
    seteditAccountDetails("");
    const closeModalDom = document.getElementById("add_account_details");
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddAccountDetails = async (e) => {
    e.preventDefault();
    startApiCall(null, setLoader);
    const updatedBankAccounts = [
      ...(vendorDetails?.bankAccounts || []),
      {
        accountHolderName: accHolderName,
        accountNumber: accountNumber,
        ifscCode: ifscCode,
      },
    ];
    const data = {
      vendorId: id,
      bankAccounts: updatedBankAccounts,
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.editVendor, data);
    if (res?.success) {
      handleReset();
      handleGetVendorDetails();
      toast.success("Account details added successfully!");
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };
  const handleEditAccountDetails = async (e) => {
    e.preventDefault();
    startApiCall(null, setLoader);
    const updatedBankAccounts = vendorDetails?.bankAccounts.map((account) => {
      if (account._id === editAccountDetails._id) {
        return {
          ...account,
          accountHolderName: accHolderName,
          accountNumber: accountNumber,
          ifscCode: ifscCode,
          _id: editAccountDetails._id,
        };
      }
      return account;
    });
    const data = {
      vendorId: id,
      bankAccounts: updatedBankAccounts,
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.editVendor, data);
    if (res?.success) {
      handleReset();
      handleGetVendorDetails();
      toast.success("Account details added successfully!");
    } else {
      setLoader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_account_details"
      className="modal fade add-new-task "
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ zIndex: 9999 }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content mnh-375p px-3">
          <div className="modal-body">
            <button
              className="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>
              {editAccountDetails === "" ? "Add" : "Edit"} Account Details
            </h5>
            <div className="border-top mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editAccountDetails !== "") {
                  handleEditAccountDetails(e);
                } else {
                  handleAddAccountDetails(e);
                }
              }}
            >
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label class="form-label">
                      Account Holder Name {reuiredField}
                    </label>
                    <input
                      class="form-control"
                      type="text"
                      value={accHolderName}
                      required
                      onChange={(e) => {
                        setAccHolderName(e.target.value);
                      }}
                      placeholder="Enter Account holder name"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label class="form-label">
                      Account Number {reuiredField}
                    </label>
                    <input
                      class="form-control"
                      type="number"
                      value={accountNumber}
                      required
                      onChange={(e) => {
                        setAccountNumber(e.target.value);
                      }}
                      placeholder="Enter Account number"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label class="form-label">IFSC Code {reuiredField}</label>
                    <input
                      class="form-control"
                      type="text"
                      value={ifscCode}
                      required
                      onChange={(e) => {
                        setIfscCode(e.target.value);
                      }}
                      placeholder="Enter ifsc code"
                    />
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex modal-footer align-items-center justify-content-end mt-5">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleReset}
                >
                  Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? (
                    <ApiLoader />
                  ) : editAccountDetails ? (
                    "Edit"
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccountDetails;
