import React from 'react'
import NoTaskSvg from "../../assets/images/notask.svg";


const NoTask = () => {
    return (
      <div className="d-flex flex-column gap-2 align-items-center w-100 px-6 py-3">
      <img className="w-380p mb-2" src={NoTaskSvg} alt="" />
      <p className="text-dark fw-semibold fs-4">
        Woohoo, you're all done
      </p>
      <p className="text-dark fs-5">
        Tasks that are this List will appear here
      </p>
    </div>
    )
}

export default NoTask