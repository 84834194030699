import React from "react";

const ManageDevices = ({ setShowConfirm }) => {

  const handleReset = () => {
    const closeModalDom = document.getElementById("manage_devices");
    if (closeModalDom) closeModalDom.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    handleReset();
    setShowConfirm(true)
  }

  return (
    <div
      id="manage_devices"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered "
        role="document"
        style={{ maxWidth: "55%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Your Devices</h5>

            <form onSubmit={handleSubmit}>
              <div className="row gx-3 px-3">
                <div className="mt-2">
                  You're signed in on these devices or have been in last 28 days
                </div>
                <div className="card rounded-5 mt-2 shadow-none p-3">
                  <div className="card-body p-2">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          fill="currentColor"
                          class="bi bi-laptop"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5" />
                        </svg>
                        <div className="d-flex flex-column">
                          <span className="text-dark fs-6">
                            1 session on Windows Computer
                          </span>
                          <span className="fs-7 fw-medium mb-0">
                            Uttar pradesh, India
                          </span>
                        </div>
                      </div>
                      <div>
                        <span
                          className="btn btn-icon btn-rounded del-button text-white bg-primary"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                        >
                          <span className="btn-icon-wrap">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card rounded-5 mt-2 shadow-none p-3">
                  <div className="card-body p-2">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center gap-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          fill="currentColor"
                          class="bi bi-laptop"
                          viewBox="0 0 16 16"
                        >
                          <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5" />
                        </svg>
                        <div className="d-flex flex-column">
                          <span className="text-dark fs-6">
                            1 session on Windows Computer
                          </span>
                          <span className="fs-7 fw-medium mb-0">
                            Uttar pradesh, India
                          </span>
                        </div>
                      </div>
                      <div>
                        <span
                          className="btn btn-icon btn-rounded del-button text-white bg-primary"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                        >
                          <span className="btn-icon-wrap">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                            </svg>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button type="submit" className="btn btn-primary btn-lg">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDevices;
