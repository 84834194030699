import React, { useEffect, useState } from "react";
import { startApiCall } from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import {
  folderEndpoint,
  listEndpoint,
  spaceEndpoint,
} from "../../api/Endpoints";
import PermissionPage from "../../components/PermisionModal/PermissionPage";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../components/Error/ErrorMessage";

function PermisionModal({ infoData, handleGetFolderList, type, user }) {
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [makePrivate, setmakePrivate] = useState(false);
  const [searchKey, setsearchKey] = useState("");
  const [closeModal, setcloseModal] = useState(false);
  const [removedEmployee, setremovedEmployee] = useState([]);

  const endApiCall = () => {
    setcloseModal(!closeModal);
    setloader(false);
    seterrorMessage("");
    setmakePrivate("");
    setSelectedEmployees([]);
    setsearchKey("");
    const closeModalDom = document.getElementById(`share_permission`);
    if (closeModalDom) closeModalDom.click();
  };

  const handleReset = () => {
    setcloseModal(!closeModal);
    setloader(false);
    seterrorMessage("");
    setsearchKey("");
    const closeModalDom = document.getElementById(`share_permission`);
    if (closeModalDom) closeModalDom.click();
  };

  const handleAddOrRemoveEmployee = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader);
    let tempForSelected = [];
    let tempForRemoved = [];
    selectedEmployees?.map((employeeId) => {
      tempForSelected.push({
        employeeId,
        isRemoved: false,
      });
    });
    removedEmployee?.map((employeeId) => {
      tempForRemoved.push({
        employeeId,
        isRemoved: true,
      });
    });

    let data = {};
    let endpoint = "";

    if (type === "space") {
      data = {
        employees: makePrivate ? tempForSelected?.concat(tempForRemoved) : [],
        spaceId: infoData?._id,
        isPrivate: makePrivate ? makePrivate : false,
        userId: user?._id,
      };
      endpoint = spaceEndpoint.addRemoveEmployeesInSpcae;
    } else if (type === "folder") {
      data = {
        employees: makePrivate ? tempForSelected?.concat(tempForRemoved) : [],
        folderId: infoData?._id,
        isPrivate: makePrivate,
        userId: user?._id,
      };
      endpoint = folderEndpoint.addEmployee;
    } else {
      data = {
        employees: makePrivate ? tempForSelected?.concat(tempForRemoved) : [],
        listId: infoData?._id,
        isPrivate: makePrivate,
        userId: user?._id,
      };
      endpoint = listEndpoint.addRemoveEmployee;
    }
    const res = await ApiCall("post", endpoint, data);
    if (res?.success) {
      endApiCall();
      handleGetFolderList();
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  return (
    <div
      id="share_permission"
      className="modal fade add-new-task"
      tabindex="-1"
      role="dialog"
      // data-bs-backdrop="static"
      aria-hidden="true"
      style={{
        pointerEvents: loader ? "none" : "",
      }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              disabled={loader}
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="text-capitalize">{type} - Share & Permission</h5>
            <form onSubmit={handleAddOrRemoveEmployee}>
              <div className="row gx-3">
                <PermissionPage
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                  makePrivate={makePrivate}
                  setmakePrivate={setmakePrivate}
                  searchKey={searchKey}
                  setsearchKey={setsearchKey}
                  infoData={infoData}
                  type={type}
                  user={user}
                  setErrorMessage={seterrorMessage}
                  closeModal={closeModal}
                  removedEmployee={removedEmployee}
                  setremovedEmployee={setremovedEmployee}
                />
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                  disabled={loader}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  {loader ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermisionModal;
