import React, { useEffect, useState } from "react";
import CreateBillsheaader from "./CreateBillsheaader";
import CreateBillsBody from "./CreateBillsBody";
import AddItemInBills from "./AddItemInBills";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { BillRelatedEndPoints } from "../../../../api/Endpoints";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateToAllBills } from "../../../../Navigation/Navigation";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";

const CreateBills = () => {
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const navigate = useNavigate();
  const [selectedVendor, setselectedVendor] = useState("");
  const [vendorData, setvendorData] = useState("");
  const [billDate, setBillDate] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [discount, setDiscount] = useState("");
  const [adjustment, setadjustment] = useState(0);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [billItems, setBillItems] = useState([]);
  const [items, setitems] = useState([]);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [subject, setSubject] = useState("");
  const [editBills, setEditBills] = useState("");
  const [editItem, setEditItem] = useState("");

  useEffect(() => {
    setlocationData(location?.state);
  }, []);

  const handleReset = () => {
    seterrorMessage("");
    setloader3(false);
    setBillDate("");
    setdueDate("");
    setBillNumber("");
    setDiscount("");
    setadjustment("");
    setitems("");
    setBillItems([]);
    navigate(navigateToAllBills());
  };

  const calculateTotalSum = () => {
    const sum = billItems
      ?.map((item) => parseFloat(item?.total || 0))
      ?.reduce((acc, val) => acc + val, 0);
    const discountedAmount = sum - (sum * parseFloat(discount || 0)) / 100;
    const adjustedAmount = discountedAmount - parseFloat(adjustment || 0);
    return adjustedAmount.toFixed(2);
  };

  // CREATE BILL FUNCTION API CALL
  const handleAddBill = async () => {
    startApiCall(seterrorMessage, setloader3);
    const data = {
      vendorId: selectedVendor,
      billNumber,
      billDate,
      dueDate,
      items: items,
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      subject,
      termsAndConditions,
      totalAmount: calculateTotalSum(),
      discount,
      adjustment,
    };
    const res = await ApiCall("post", BillRelatedEndPoints.createBill, data);
    if (res?.success) {
      handleReset();
    } else {
      setloader3(false);
      seterrorMessage(res.error);
    }
  };

  // EDIT BILL FUNCTION API CALL
  const handleEditBill = async () => {
    startApiCall(seterrorMessage, setloader3);
    const data = {
      billId: locationData?.id,
      dueDate,
      items: items,
      subject,
      termsAndConditions,
      totalAmount: calculateTotalSum(),
      discount,
      adjustment,
    };
    const res = await ApiCall("post", BillRelatedEndPoints.editBill, data);
    if (res?.success) {
      handleReset();
    } else {
      setloader3(false);
      seterrorMessage(res.error);
    }
  };

  // GET BILL FUNCTION API CALL
  const handleGetBillDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      billId: locationData?.id,
    };
    const res = await ApiCall("post", BillRelatedEndPoints.getBillById, data);
    if (res?.success) {
      setEditBills(res?.bill);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (locationData?.id) {
      handleGetBillDetails();
    }
  }, [locationData]);

  // SET ALL VALUE WHEN EDIT BILL
  useEffect(() => {
    if (locationData?.id) {
      setvendorData(editBills?.vendorId?.name);
      setselectedVendor(editBills?.vendorId?._id);
      setBillNumber(editBills?.billNumber);
      setBillDate(reversesDateFormatter(editBills?.billDate));
      setdueDate(reversesDateFormatter(editBills?.dueDate));
      setBillItems(editBills?.items);
      const mappedItems = editBills?.items?.map((item) => ({
        itemId: item?.itemId?._id,
        quantity: item?.quantity,
        rate: item?.rate,
        total: item?.total,
      }));
      setitems(mappedItems);
      setTermsAndConditions(editBills?.termsAndConditions);
      setDiscount(editBills?.discount);
      setadjustment(editBills?.adjustment);
    }
  }, [locationData, editBills]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <CreateBillsheaader locationData={locationData} />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && (
                <CreateBillsBody
                  selectedVendor={selectedVendor}
                  setselectedVendor={setselectedVendor}
                  billDate={billDate}
                  setBillDate={setBillDate}
                  dueDate={dueDate}
                  setdueDate={setdueDate}
                  billNumber={billNumber}
                  setBillNumber={setBillNumber}
                  adjustment={adjustment}
                  setadjustment={setadjustment}
                  discount={discount}
                  setDiscount={setDiscount}
                  errorMessage={errorMessage}
                  items={items}
                  billItems={billItems}
                  termsAndConditions={termsAndConditions}
                  setTermsAndConditions={setTermsAndConditions}
                  handleAddBill={handleAddBill}
                  calculateTotalSum={calculateTotalSum}
                  setEditItem={setEditItem}
                  locationData={locationData}
                  loader3={loader3}
                  handleEditBill={handleEditBill}
                  setvendorData={setvendorData}
                  vendorData={vendorData}
                  editBills={editBills}
                  subject={subject}
                  setSubject={setSubject}
                />
              )}
              {loader && <DataLoader title="Edit Bill..." />}
            </div>
          </div>
        </div>
        <AddItemInBills
          setBillItems={setBillItems}
          billItems={billItems}
          setitems={setitems}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      </div>
    </div>
  );
};

export default CreateBills;
