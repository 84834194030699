import React from "react";
import ReactToPrint from "react-to-print";
import {
  navigateToAllInventoryInvoices,
  navigateToCustomerOrderDetails,
  navigateToManageStock,
  navigateToOrderHistory,
} from "../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

const InvoiceDetailsHeader = ({
  handleGetInvoiceDetails,
  componentRef,
  locationData,
  handleGetInvoiceSetting,
}) => {
  const navigate = useNavigate();
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center gap-1">
        <span className="todoapp-title link-dark">
          {locationData && locationData?.subHeader !== "" && (
            <>
              <button
                className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
                onClick={() => {
                  if (locationData?.subHeader === "Customer") {
                    navigate(
                      navigateToCustomerOrderDetails({
                        id: locationData?.buyerId,
                      })
                    );
                  } else if (locationData?.subHeader === "Manage Stock") {
                    navigate(navigateToManageStock());
                  } else if (locationData?.subHeader === "All Invoice") {
                    navigate(navigateToAllInventoryInvoices());
                  } else {
                    navigate(navigateToOrderHistory(), {
                      state: locationData.data,
                    });
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  fill="currentColor"
                  class="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
                <p className="mb-0">Back</p>
              </button>
              <div className="v-separator d-lg-inline-block"></div>
            </>
          )}

          <h1 className="header-title-font"> Invoice Details</h1>
        </span>
      </div>
      <div className="todo-options-wrap">
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-primary btn-sm">Download As PDF</button>
          )}
          content={() => componentRef.current}
        />
        <div className="v-separator d-lg-inline-block d-none"></div>

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span
            className="btn-icon-wrap"
            onClick={() => {
              handleGetInvoiceSetting();
              handleGetInvoiceDetails();
            }}
          >
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default InvoiceDetailsHeader;
