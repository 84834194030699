import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToVendorsAnalytics } from "../../../Navigation/Navigation";

function VendorReportHeader({
  handleGetAllVendor,
  startDate,
  setstartDate,
  endDate,
  setendDate,
}) {
  const navigate = useNavigate();
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1>Vendors Report</h1>
          <div className="ms-3">
            <button
              className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
              onClick={() =>
                navigate(navigateToVendorsAnalytics(), {
                  state: {
                    startDate: startDate,
                    endDate: endDate,
                  },
                })
              }
            >
              Analytics
            </button>
          </div>
        </span>
      </div>
      <div className="todo-options-wrap">
        <>
          <div className="d-flex w-260p">
            <label className="mt-2">Start Date :</label>
            <input
              type="date"
              className="form-control w-65 ms-1"
              value={startDate}
              onChange={(e) => {
                setstartDate(e.target.value);
              }}
            />
          </div>
          <div className="d-flex w-260p ms-2">
            <label className="mt-2">End Date :</label>
            <input
              type="date"
              className="form-control w-65 ms-1"
              value={endDate}
              onChange={(e) => {
                setendDate(e.target.value);
              }}
            />
          </div>
        </>

        <div className="v-separator d-lg-inline-block d-none"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllVendor}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
}

export default VendorReportHeader;
