import React, { useEffect, useState } from "react";
import {
  getAllWorkspaceStatusBySpace,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { taskEndpoint } from "../../api/Endpoints";
import { useSelector } from "react-redux";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../components/NoData/NoData";
import TaskHeader from "../Task/TaskHeader";
import ResetFilterWithChip from "../Task/Filters/ResetFilterWithChip";
import TaskListTable from "../Task/Views/TaskListTable";
import FooterPagination from "../../components/Pagination/FooterPagination";
import CalendarView from "../Task/Views/CalendarView";
import NoTask from "../Task/NoTask";
import { DueDate } from "../Task/Filters/DueDate";
import { DateRange } from "../Task/Filters/DateRange";
import ChangeDuedate from "../../components/ChangeDuedate/ChangeDuedate";
import TaskDetailsModal from "../Task/TaskDetailsModal/TaskDetailsModal";
import UserInfoModal from "../../components/UserInfoModal/UserInfoModal";
import { AssignEmployee } from "../Task/AssignEmployee";
import AddTaskInMyTasks from "./AddTaskInMyTasks";
import MyTaskInfoCards from "./MyTaskInfoCards";

const MyTasks = () => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [taskData, settaskData] = useState([]);
  const [search, setSearch] = useState("");
  const [subTask, setsubTask] = useState([]);
  const [selectedFilterType, setselectedFilterType] = useState("");
  const taskFilters = ["Date Range", "Due Date"];
  const [dueDateForFilter, setdueDateForFilter] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [taskStatus, settaskStatus] = useState([]);
  const [taskStatusLoader, settaskStatusLoader] = useState(false);
  const [selectedTaskStatus, setselectedTaskStatus] = useState({
    statusName: "All Status",
    color: "#c8c8c8",
  });
  const [selectedTaskPriority, setselectedTaskPriority] = useState({
    name: "All Priority",
    color: "#c8c8c8",
    value: "",
  });
  const [isFilteredModal, setisFilteredModal] = useState(false);
  const [isFilterReset, setisFilterReset] = useState(false);
  const [selectedTask, setselectedTask] = useState([]);
  const [taskDetailsModal, settaskDetailsModal] = useState(false);
  const [isAssigneeEmployeeModal, setisAssigneeEmployeeModal] = useState(false);
  const [taskCount, settaskCount] = useState([]);
  const [selectedView, setSelectedView] = useState(
    localStorage.getItem("taskview")
      ? localStorage.getItem("taskview")
      : "Table View"
  );

  const [editDueDate, seteditDuedDate] = useState(false);
  const [taskId, settaskId] = useState([]);

  // ****** SET LIST AND SPACENAME ******
  const [listName, setListName] = useState("");
  const [spaceName, setSpaceName] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // ****** FOR USER DETILS ******
  const [userInfoData, setuserInfoData] = useState([]);

  const [listOrSpaceId, setListOrSpaceId] = useState("");

  const handleChangePriority = (pr) => {
    setselectedTaskPriority(pr);
  };
  const handleChangeStatus = (status) => {
    setselectedTaskStatus(status);
  };
  const handleRefresh = () => {
    handleGetTask();
    handleGetTaskCount();
  };

  const handleGetAllTaskStatus = async (spaceId) => {
    startApiCall(null, settaskStatusLoader);
    const res = await getAllWorkspaceStatusBySpace(spaceId);
    settaskStatus([...res]);
    settaskStatusLoader(false);
  };

  const handleSubTask = async (taskId) => {
    seterrorMessage("");
    setloader3((prevLoading) => ({
      ...prevLoading,
      [taskId]: true,
    }));
    setTimeout(() => {
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    }, 50000);
    const sendData = {
      page: 1,
      limit: 500,
      employeeId: user?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        maintaskId: taskId,
        taskType: "subtask",
        assignedTo: user?._id,
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      setsubTask((prevSubtaskData) => ({
        ...prevSubtaskData,
        [taskId]: res?.task?.data,
      }));
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
    } else {
      setloader3((prevLoading) => ({
        ...prevLoading,
        [taskId]: false,
      }));
      seterrorMessage(res.error);
    }
  };

  const handleGetTaskCount = async () => {
    startApiCall(null, setloader2);
    const data = {
      employeeId: user?._id,
      businessId: getCurrentWorkspaceId(),
      startDate: startDate,
      endDate: endDate,
    };
    const res = await ApiCall("post", taskEndpoint.taskCounts, data);
    if (res?.success) {
      settaskCount(res?.task);
      setloader2(false);
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetTask = async (e) => {
    e && e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    const sendData = {
      page: page,
      limit: pageLimit,
      employeeId: user?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        taskType: "maintask",
        searchKey: search,
        startDate: startDate,
        endDate: endDate,
        dueDate: dueDateForFilter,
        priority: selectedTaskPriority?.value,
        assignedTo: user?._id,
        status:
          selectedTaskStatus?.statusName === "All Status"
            ? ""
            : selectedTaskStatus?._id,
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      setisFilteredModal(false);
      setisFilterReset(false);
      settaskData(res?.task?.data);
      setcount(res?.task?.totalCounts);
      settotalPages(res?.task?.totalPages);
      handleGetTaskCount();
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetTask();
    }
  }, [
    search,
    selectedTaskStatus,
    selectedTaskPriority,
    selectedView,
    page,
    pageLimit,
  ]);

  useEffect(() => {
    if (isFilterReset && getCurrentWorkspaceId()) {
      handleGetTask();
    }
  }, [isFilterReset]);

  const handleResetFilters = (type, isReset) => {
    if (type === "Date Range") {
      setstartDate("");
      setendDate("");
    } else if (type === "Due Date") {
      setdueDateForFilter("");
    } else if (type === "Status") {
      setselectedTaskStatus({
        statusName: "All Status",
        color: "#c8c8c8",
      });
    } else if (type === "Priority") {
      setselectedTaskPriority({
        name: "All Priority",
        color: "#c8c8c8",
        value: "",
      });
    }
    setisFilteredModal(false);
    setisFilterReset(isReset);
  };

  return (
    <div className="todoapp-wrap">
      <div class="todoapp-content">
        <div class="todoapp-detail-wrap">
          <TaskHeader
            taskFilters={taskFilters}
            setselectedFilterType={setselectedFilterType}
            setSearch={setSearch}
            search={search}
            handleGetTask={handleGetTask}
            handleGetAllTaskStatus={handleGetAllTaskStatus}
            taskStatus={taskStatus}
            taskStatusLoader={taskStatusLoader}
            handleChangeStatus={handleChangeStatus}
            selectedTaskStatus={selectedTaskStatus}
            handleChangePriority={handleChangePriority}
            selectedTaskPriority={selectedTaskPriority}
            setisFilteredModal={setisFilteredModal}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            setPage={setPage}
            isAssigned={true}
            data={taskData}
          />
          <ResetFilterWithChip
            dueDateForFilter={dueDateForFilter}
            startDate={startDate}
            endDate={endDate}
            selectedTaskStatus={selectedTaskStatus}
            selectedTaskPriority={selectedTaskPriority}
            handleResetFilters={handleResetFilters}
          />
          <div className="todo-body">
            <div data-simplebar class="nicescroll-bar simple-scrollbar">
              {selectedView === "Table View" &&
                !loader &&
                taskData?.length > 0 && (
                  <>
                    <div
                      className="d-flex align-items-center gap-3 simple-scrollbar"
                      style={{
                        overflowX: "scroll",
                        justifyContent: "space-between",
                      }}
                    >
                      <MyTaskInfoCards taskCount={taskCount} />
                    </div>

                    <TaskListTable
                      taskData={taskData}
                      handleGetTask={handleGetTask}
                      subTask={subTask}
                      handleSubTask={handleSubTask}
                      loader3={loader3}
                      setloader3={setloader3}
                      setselectedTask={setselectedTask}
                      settaskDetailsModal={settaskDetailsModal}
                      handleGetAllTaskStatus={handleGetAllTaskStatus}
                      taskStatus={taskStatus}
                      taskStatusLoader={taskStatusLoader}
                      setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
                      page={page}
                      pageLimit={pageLimit}
                      setuserInfoData={setuserInfoData}
                      seteditDuedDate={seteditDuedDate}
                      settaskId={settaskId}
                      isAssigned={true}
                      setListOrSpaceId={setListOrSpaceId}
                      listOrSpaceId={listOrSpaceId}
                      setListName={setListName}
                      setSpaceName={setSpaceName}
                    />
                    <FooterPagination
                      setpageLimit={setpageLimit}
                      pageLimit={pageLimit}
                      setPage={setPage}
                      page={page}
                      totalPages={totalPages}
                      count={count}
                      dataLength={taskData?.length}
                      align={"start"}
                    />
                  </>
                )}
              {selectedView === "Calendar View" &&
                !loader &&
                taskData?.length > 0 && (
                  <CalendarView
                    taskData={taskData}
                    settaskDetailsModal={settaskDetailsModal}
                    setselectedTask={setselectedTask}
                  />
                )}
              {loader && <DataLoader title="Your task of list is loading..." />}
              {!loader && taskData?.length === 0 && search === "" && <NoTask />}
              {!loader && search !== "" && taskData?.length === 0 && (
                <NoData title="No Tasks Found" />
              )}
              <div
                className="position-fixed bottom-0 end-0 p-2"
                style={{
                  position: "fixed",
                  zIndex: "9999",
                }}
              >
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_task_mytask"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>{" "}
                  task
                </button>
              </div>
            </div>
          </div>
        </div>
        <AddTaskInMyTasks handleGetTask={handleGetTask} />
      </div>

      {/* ************ FILTER TASK *********** */}
      {isFilteredModal && (
        <div
          className={`modal fade add-new-contact mt-10 ${
            isFilteredModal ? "show" : ""
          }`}
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
          style={{ display: isFilteredModal ? "block" : "none" }}
        >
          <form onSubmit={handleGetTask}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <h5>Filter by {selectedFilterType}</h5>
                  <div className="dropdown-divider"></div>
                  {selectedFilterType === "Date Range" && (
                    <DateRange
                      startDate={startDate}
                      setstartDate={setstartDate}
                      endDate={endDate}
                      setendDate={setendDate}
                      mainLoader={loader}
                      handleResetFilters={handleResetFilters}
                    />
                  )}
                  {selectedFilterType === "Due Date" && (
                    <DueDate
                      setdueDateForFilter={setdueDateForFilter}
                      dueDateForFilter={dueDateForFilter}
                      mainLoader={loader}
                      handleResetFilters={handleResetFilters}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}

      {/* ************ CHANGE DUE DATE *********** */}
      {editDueDate && (
        <ChangeDuedate
          seteditDuedDate={seteditDuedDate}
          taskId={taskId}
          editDueDate={editDueDate}
          taskType={taskId?.taskType}
          handleGetTaskDetails={handleGetTask}
        />
      )}
      {/* ************  TASK DETAILS *********** */}
      {taskDetailsModal && (
        <TaskDetailsModal
          taskId={
            selectedView === "Calendar View" ? selectedTask : selectedTask?._id
          }
          taskDetailsModal={taskDetailsModal}
          settaskDetailsModal={settaskDetailsModal}
          listName={listName}
          spaceName={spaceName}
          isShowSpace={true}
          isTask={true}
          handleGetTask={handleGetTask}
        />
      )}
      {isAssigneeEmployeeModal && (
        <AssignEmployee
          setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
          isAssigneeEmployeeModal={isAssigneeEmployeeModal}
          listId={listOrSpaceId}
          user={user}
          selectedTask={selectedTask}
          handleGetTask={handleGetTask}
        />
      )}
      <UserInfoModal user={userInfoData} />
    </div>
  );
};

export default MyTasks;
