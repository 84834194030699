import React from "react";

function QuoteDetailsItemTable({ quoteDetailsData }) {
  return (
    <table id="datable_1" className="table nowrap w-100 mb-5">
      <thead>
        <tr className="text-center">
          <th>No</th>
          <th>Item Name</th>
          <th>Rate</th>
          <th>Quantity</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {quoteDetailsData?.items?.map((item, index) => {
          return (
            <tr className="text-center">
              <td>{index + 1} .</td>
              <td>
                <span className="text-capitalize">
                  {item?.itemId?.itemName}
                </span>
              </td>
              <td>₹ {item?.rate}</td>
              <td>{item?.quantity}</td>
              <td>₹ {item?.total}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default QuoteDetailsItemTable;
