import React, { useEffect, useRef, useState } from "react";
import BillDetailsHeader from "./BillDetailsHeader";
import BillDetailsBody from "./BillDetailsBody";
import { useParams } from "react-router-dom";
import { startApiCall } from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { BillRelatedEndPoints } from "../../../../api/Endpoints";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import PrintBillDetails from "./PrintBillDetails";

const BillDetails = () => {
  const [isBillDetails, setIsBillDetails] = useState([]);
  const { id } = useParams();
  const [loader, setloader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const componentRef = useRef();

  const handleGetBillDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      billId: id,
    };
    const res = await ApiCall("post", BillRelatedEndPoints.getBillById, data);
    if (res?.success) {
      setIsBillDetails(res?.bill);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };
  useEffect(() => {
    handleGetBillDetails();
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <BillDetailsHeader handleGetBillDetails={handleGetBillDetails} componentRef={componentRef} />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && <BillDetailsBody isBillDetails={isBillDetails} />}
              {loader && <DataLoader title="Data is loading.." />}
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <PrintBillDetails ref={componentRef} isBillDetails={isBillDetails} />
        </div>
      </div>
    </div>
  );
};

export default BillDetails;
