import React, { useEffect, useState } from "react";
import GroupHeader from "./GroupHeader";
import GroupTable from "./GroupTable";
import CreateGroup from "./CreateGroup";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { StatusRelatedEndPoints } from "../../../api/Endpoints";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import { useSelector } from "react-redux";

const Group = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [search, setsearch] = useState("");
  const [errorMessage, seterrorMessage] = useState("");

  const [allGroups, setAllGroups] = useState([]);
  const [editAllGroups, seteditAllGroups] = useState("");

  const [moduleType, setModuleType] = useState("");

  const isModuleReadWriteAccessForStatusGroup =
    allModuleAccess?.[PRIVATEROUTES.STATUS_GROUP];

  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetAllGroup = async () => {
    startApiCall(seterrorMessage, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        moduleType,
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getGropus, data);
    if (res?.success) {
      setAllGroups(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllGroup();
      closeAllModals();
    }
  }, [search, page, pageLimit, moduleType]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <GroupHeader
            handleGetAllGroup={handleGetAllGroup}
            search={search}
            setsearch={setsearch}
            setPage={setPage}
            moduleType={moduleType}
            setModuleType={setModuleType}
            isModuleReadWriteAccessForStatusGroup={
              isModuleReadWriteAccessForStatusGroup
            }
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {!loader && allGroups?.length > 0 && (
                <GroupTable
                  allGroups={allGroups}
                  page={page}
                  pageLimit={pageLimit}
                  seteditAllGroups={seteditAllGroups}
                  user={user}
                  isModuleReadWriteAccessForStatusGroup={
                    isModuleReadWriteAccessForStatusGroup
                  }
                />
              )}
              {!loader && allGroups?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allGroups?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allGroups?.length === 0 && (
                <NoData title="No groups added" />
              )}
            </div>
          </div>
        </div>
        <CreateGroup
          handleGetAllGroup={handleGetAllGroup}
          editAllGroups={editAllGroups}
          seteditAllGroups={seteditAllGroups}
        />
      </div>
    </div>
  );
};

export default Group;
