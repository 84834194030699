import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Leads from "../../pages/CRM/Leads/Leads";
import Overview from "../../pages/CRM/Leads/LeadsDetails/LeadOverview/Overview";

const LeadsRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "allleads",
      element: <Leads />,
    },
    {
      path: "allleads/leadoverview/:id",
      element: <Overview />,
    },
  ],
};

export default LeadsRoutes;
