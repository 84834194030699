import React from "react";

function AddCreditAddressDetails(props) {
  const { customerData, locationData, creditNotesDetails } = props;
  return (
    <div className="d-flex flex-column gap-2">
      <div className="card d-flex flex-column gap-2 border-ligth rounded-0 p-3 mt-3 mnh-200p">
        <div className="d-flex gap-2">
          <span className="text-dark text-capitalize gap-2">
            Billing Address
          </span>
        </div>
        {customerData?.billingAddress ||
        creditNotesDetails?.customerId?.billingAddress ? (
          <>
            <div className="d-flex">
              <span className="text-capitalize">
                City :{" "}
                {locationData
                  ? creditNotesDetails?.customerId?.billingAddress?.city
                  : customerData?.billingAddress?.city}
              </span>
            </div>
            <div className="d-flex">
              <span className="text-capitalize">
                PinCode :{" "}
                {locationData
                  ? creditNotesDetails?.customerId?.billingAddress?.pinCode
                  : customerData?.billingAddress?.pinCode}
              </span>
            </div>
            <div className="d-flex">
              <span className="text-capitalize">
                Country :{" "}
                {locationData
                  ? creditNotesDetails?.customerId?.billingAddress?.country
                  : customerData?.billingAddress?.country}
              </span>
            </div>
            <div className="d-flex">
              <span className="text-capitalize">
                {locationData
                  ? creditNotesDetails?.customerId?.billingAddress?.street
                  : customerData?.billingAddress?.street}
              </span>
            </div>
          </>
        ) : (
          <span>No Billing Address</span>
        )}
      </div>
      <div className="card d-flex flex-column gap-2 border-ligth rounded-0 p-3 mt-3 mnh-200p">
        <div className="d-flex gap-2">
          <span className="text-dark text-capitalize gap-2">
            Shipping Address
          </span>
        </div>
        {customerData?.shippingAddress ||
        creditNotesDetails?.customerId?.shippingAddress ? (
          <>
            <div className="d-flex">
              <span className="text-capitalize">
                City :{" "}
                {locationData
                  ? creditNotesDetails?.customerId?.shippingAddress?.city
                  : customerData?.shippingAddress?.city}
              </span>
            </div>
            <div className="d-flex">
              <span className="text-capitalize">
                PinCode :{" "}
                {locationData
                  ? creditNotesDetails?.customerId?.shippingAddress?.pinCode
                  : customerData?.shippingAddress?.pinCode}
              </span>
            </div>
            <div className="d-flex">
              <span className="text-capitalize">
                Country :{" "}
                {locationData
                  ? creditNotesDetails?.customerId?.shippingAddress?.country
                  : customerData?.shippingAddress?.country}
              </span>
            </div>
            <div className="d-flex">
              <span className="text-capitalize">
                {locationData
                  ? creditNotesDetails?.customerId?.shippingAddress?.street
                  : customerData?.shippingAddress?.street}
              </span>
            </div>
          </>
        ) : (
          <span>No Shipping Address</span>
        )}
      </div>

      <p className="text-dark">
        <span className="fw-semibold">GST : </span>{" "}
        {customerData?.gst ? customerData?.gst : "-"}
      </p>
    </div>
  );
}

export default AddCreditAddressDetails;
