import React from "react";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";

const AllShiftsSkeleton = () => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>
            <SkeletonConstant
              width="w-50p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-140p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-160p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-100p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-100p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
          <th>
            <SkeletonConstant
              width="w-100p"
              height="h-25p"
              additional="mx-auto"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-160p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-160p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-160p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-160p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-160p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-160p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-160p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
        <tr>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-160p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-100p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
          <td>
            <SkeletonConstant
              width="w-50p"
              height="h-30p"
              additional="mx-auto"
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AllShiftsSkeleton;
