import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { toast } from "react-toastify";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { workspaceRoleEndPoints } from "../../../api/Endpoints";
import constants from "../../../components/Constant/Constants";

const AddRoleModal = (props) => {
  const {
    readAccessItems,
    readWriteAcessItems,
    setreadAccessItems,
    setreadWriteAcessItems,
    user,
    moduleData,
    setroleNameModal,
    setroleModal,
    setmoduleData,
    handleGetAllRole,
  } = props;
  const [roleName, setRoleName] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  useEffect(() => {
    if (moduleData !== "") {
      setRoleName(moduleData?.roleName);
    }
  }, [moduleData]);

  const endApiCall = () => {
    setRoleName("");
    setreadAccessItems(["Spaces"]);
    setreadWriteAcessItems([]);
    setmoduleData("");
    seterrorMessage("");
    setLoader(false);
    setroleNameModal(false);
    setroleModal();
  };

  const handleReset = () => {
    setRoleName("");
    seterrorMessage("");
    setLoader(false);
    setroleNameModal(false);
  };

  const readModules = readAccessItems?.map((moduleName) => ({
    moduleName,
    permissionType: "read",
  }));
  const readWriteModules = readWriteAcessItems?.map((moduleName) => ({
    moduleName,
    permissionType: "read-write",
  }));

  const allModules = [...readModules, ...readWriteModules];

  const handleEditRolePermission = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const data = {
      modules: allModules,
      roleName: roleName,
      roleId: moduleData?._id,
    };
    const res = await ApiCall(
      "post",
      workspaceRoleEndPoints.editRolePermissions,
      data
    );
    if (res?.success) {
      toast.success("Role edited successfully!");
      endApiCall();
      handleGetAllRole();
    } else {
      seterrorMessage(res?.error);
      setLoader(false);
    }
  };

  const handleAddRole = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const data = {
      modules: allModules,
      businessId: getCurrentWorkspaceId(),
      roleName: roleName,
      createdBy: user?._id,
    };
    const res = await ApiCall("post", workspaceRoleEndPoints.createRole, data);
    if (res?.success) {
      toast.success("Role added successfully!");
      endApiCall();
      handleGetAllRole();
    } else {
      seterrorMessage(res?.error);
      setLoader(false);
    }
  };

  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="mb-4">
                {moduleData !== "" ? "Edit Role" : "Add Role"}
              </h5>
              <form
                onSubmit={(e) => {
                  if (moduleData !== "") {
                    handleEditRolePermission(e);
                  } else {
                    handleAddRole(e);
                  }
                }}
              >
                <div className="row gx-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      {allModules?.length === 0 ? (
                        <>
                          <span className="title-lg  mt-1 mb-2">Note : </span>
                          <div className="card card-border note-block bg-danger-light-5 text-center">
                            <div className="card-body">
                              <p>You Have To Selected Atleast One Module</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="d-flex selected-items mh-450p overflow-auto">
                          {readAccessItems?.length > 0 && (
                            <div className="col-md-6">
                              <p
                                style={{ marginLeft: "25px", paddingBottom: 5 }}
                              >
                                Read Items:
                              </p>
                              <ul>
                                {readAccessItems?.map((item, index) => (
                                  <li key={index}>
                                    {index + 1}. &nbsp;{item}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                          {readWriteAcessItems?.length > 0 && (
                            <div className="col-md-6">
                              <p
                                style={{ marginLeft: "25px", paddingBottom: 5 }}
                              >
                                Read-Write Items:
                              </p>
                              <ul>
                                {readWriteAcessItems?.map((item, index) => (
                                  <li key={index}>
                                    {index + 1}. &nbsp;{item}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}

                      <label className="form-label">Role Name</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        value={roleName}
                        onChange={(event) => setRoleName(event.target.value)}
                        maxLength={constants.ROLE_MAX_LENGTH}
                      />
                    </div>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-add-tasklist"
                    disabled={allModules?.length === 0 || loader}
                  >
                    {loader ? <ApiLoader /> : "Add Role"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRoleModal;
