import React, { useState } from "react";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { InventoryRelatedEndPoints } from "../../../api/Endpoints";

function CustomizeOrderModal({
  setcustomizeModal,
  customizesItemId,
  setcustomizesItemId,
  setcustomizedItem,
  customizedItem,
}) {
  const [selectCategory, setSelectCategory] = useState("");
  const [allMaterial, setAllMaterial] = useState([]);
  const [price, setPrice] = useState();
  const [selectItem, setSelectItem] = useState("");
  const [filter, setFilter] = useState("");
  const [inventoryId, setInventoryId] = useState("");
  const [quantity, setQuantity] = useState("");
  const [loader2, setloader2] = useState(false);
  const filteredInvItems = filterSearchData(allMaterial, filter);
  const [unit, setUnit] = useState("");
  const [unitId, setUnitId] = useState("");
  const [inventoryCostPrice, setinventoryCostPrice] = useState(0);

  const handleGetAllMaterial = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
        category: selectCategory,
      },
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getInventory,
      data
    );
    if (res?.success) {
      setAllMaterial(res?.inventory?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const allCategories = [
    {
      name: "Vegetables",
    },
    {
      name: "Non-veg",
    },
    {
      name: "Ingredients",
    },
    {
      name: "Others",
    },
  ];
  const handleReset = () => {
    setloader2(false);
    setUnit("");
    setQuantity("");
    setUnitId("");
    setSelectItem("");
    setInventoryId("");
    setPrice("");
    setSelectCategory();
    setcustomizeModal(false);
    setcustomizesItemId("");
  };

  const handleAddCustomizedItem = (e) => {
    e.preventDefault();
    const customizedItemObject = {
      inventoryItem: {
        name: selectItem,
        _id: inventoryId,
      },
      unit: {
        name: unit,
        _id: unitId,
      },
      quantity: quantity,
      price: price,
      productId: customizesItemId,
      costPrice: inventoryCostPrice,
    };

    setcustomizedItem((prevItems) => [...prevItems, customizedItemObject]);
    handleReset();
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        role="document"
      >
        <div className="modal-content p-3">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleReset();
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Customise your Order</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <form onSubmit={handleAddCustomizedItem}>
              <div className="row gx-3 px-3 w-100">
                <div className="col-sm-6">
                  <label className="form-label">Category {reuiredField}</label>
                  <div className="form-group">
                    <div className="todo-toolbar">
                      <select
                        className="form-select form-select-md"
                        required
                        value={selectCategory}
                        onChange={(e) => {
                          setSelectCategory(e.target.value);
                        }}
                      >
                        <option value="">Select Category</option>
                        {allCategories?.map((cat) => {
                          return <option value={cat?.name}>{cat?.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <label className="text-dark fw-300 fs-6 mb-2">
                    Item {reuiredField}
                  </label>
                  <div className="form-group">
                    <div className="selectable-dropdown">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                            selectCategory === ""
                              ? "bg-light text-disabled"
                              : ""
                          }`}
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          disabled={selectCategory === ""}
                          onClick={() => {
                            handleGetAllMaterial();
                            setFilter("");
                          }}
                          style={{ width: "175px" }}
                        >
                          {selectItem ? (
                            <>
                              {selectItem}
                              {selectItem !== "" && (
                                <button
                                  type="button"
                                  className="btn-close btn-sm ms-2"
                                  onClick={() => {
                                    setSelectItem("");
                                    setFilter("");
                                  }}
                                  aria-label="Close"
                                ></button>
                              )}
                            </>
                          ) : (
                            "Select item"
                          )}
                        </span>
                        <div role="menu" className="dropdown-menu">
                          <form className="d-sm-block d-none" role="search">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search.."
                              value={filter}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </form>
                          {loader2 ? (
                            <span className="d-flex text-center mt-1">
                              Loading...
                            </span>
                          ) : (
                            <div
                              style={{
                                maxHeight: "26vh",
                                overflow: "auto",
                              }}
                            >
                              {filteredInvItems?.map((inv) => (
                                <div
                                  className="dropdown-item text-capitalize"
                                  key={inv.name}
                                  onClick={() => {
                                    setSelectItem(inv.name);
                                    setInventoryId(inv?._id);
                                    setUnit(inv?.unit?.name);
                                    setUnitId(inv?.unit?._id);
                                    setinventoryCostPrice(
                                      inv?.costPricePerUnit
                                    );
                                  }}
                                >
                                  {inv.name}
                                </div>
                              ))}
                            </div>
                          )}

                          {!loader2 && allMaterial?.length === 0 && (
                            <span className="d-flex text-center mt-1">
                              No Inventory found
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Unit</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={unit}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      Quantity {reuiredField}
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      required
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-10">
                  <label className="text-dark fw-300 fs-6">
                    { unit!==""?`Additional Cost ( Cost Price : ₹ ${inventoryCostPrice?.toFixed(2) || 0} / ${unit} )`:"Additional Cost" } {reuiredField}
                  </label>
                  <input
                    className="form-control mt-2"
                    type="number"
                    required
                    value={price}
                    min={1}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
                <div className="modal-footer align-items-center mt-3 justify-content-end">
                  <div>
                    <button type="submit" className="btn btn-primary">
                      Add item
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomizeOrderModal;
