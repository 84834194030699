import React from "react";
import { navigateToIndividualSpaces } from "../../../Navigation/Navigation";

const MeetingHeader = (props) => {
  const {
    handleGetMeeting,
    search,
    handleSearchSet,
    meetingCount,
    meetingStatus,
    selectedStatus,
    setselectedStatus,
    locationData,
    navigate,
    handleIsConfirmEdit,
    moduleType,
    handleChangeType,
    isModuleReadWriteAccessMeeting,
    dateType,
    setdateType,
    setdateModal,
  } = props;

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <a className="todoapp-title link-dark">
          <h1 className="font-4 me-2 ms-1">Meeting</h1>
          {locationData && (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-geo-alt"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              <span
                onClick={() => {
                  if (locationData?.moduleType === "Space") {
                    navigate(
                      navigateToIndividualSpaces({
                        spacename: locationData?.moduleName,
                        id: locationData?.moduleId,
                      })
                    );
                  }
                }}
                className="text-primary ms-1 pointer switch-text"
              >
                {locationData?.moduleName}
              </span>
            </>
          )}
          {isModuleReadWriteAccessMeeting && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
                data-bs-toggle="modal"
                onClick={() => {
                  handleIsConfirmEdit(null);
                }}
                data-bs-target="#add_new_meeting"
              >
                Schedule Meeting
              </button>
            </div>
          )}
        </a>
      </div>
      <div className="d-flex align-items-center">
        <span className="d-md-inline text-capitalize">
          {selectedStatus} Meeting : {meetingCount}
        </span>
        <span className="text-dark fs-5 fw-medium ps-2"></span>
      </div>

      <div className="todo-options-wrap">
        <div className="selectable-dropdown me-1">
          <div className="dropdown selectable-dropdown">
            <span
              className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Date :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp; {dateType}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Today" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Today");
                  }}
                >
                  1. Today
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Tomorrow" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Tomorrow");
                  }}
                >
                  2. Tomorrow
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Date Range" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Date Range");
                    setdateModal(true);
                  }}
                >
                  3. Date Range
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="selectable-dropdown">
          <div className="dropdown selectable-dropdown">
            <span
              className={`me-2 fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Status :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp; {selectedStatus}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              {meetingStatus?.map((status, index) => (
                <div className="d-flex justify-content-between">
                  <div
                    className="dropdown-item text-capitalize"
                    key={index}
                    value={status?.value}
                    onClick={() => {
                      setselectedStatus(status?.value);
                    }}
                  >
                    {selectedStatus === status?.value ? (
                      <svg
                        fill={status?.color}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        class="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill={status?.color}
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="circle"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill={status?.color}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="15" height="15" fill={status?.color} />
                      </svg>
                    )}
                    &nbsp;
                    {status?.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="selectable-dropdown">
          <div className="dropdown selectable-dropdown">
            <span
              className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Type :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp;{" "}
              {moduleType === ""
                ? "All"
                : moduleType === "Business"
                ? "Workspace"
                : moduleType}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("");
                  }}
                >
                  1. All
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "Business" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("Business");
                  }}
                >
                  2. Workspace
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    moduleType === "Space" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    handleChangeType("Space");
                  }}
                >
                  3. Space
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="refresh meeting list"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon" onClick={handleGetMeeting}>
              <i className="ri-refresh-line" title="Refresh Meeting"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search by title"
            value={search}
            onChange={handleSearchSet}
          />
        </form>
      </div>
      {/* <div className="todo-options-wrap gap-3">
        <span className="position-relative">
          <div>
            <button className="btn btn-primary btn-add-tasklist">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                className="me-2"
              >
                <path
                  d="M4 0C2.89543 0 2 0.895431 2 2V3.13328L1.05882 3.63525C0.407097 3.98283 0 4.66133 0 5.39995V14C0 15.1045 0.895431 16 2 16H14C15.1046 16 16 15.1045 16 14V5.39995C16 4.66133 15.5929 3.98283 14.9412 3.63525L14 3.13328V2C14 0.895431 13.1046 0 12 0H4ZM14 4.26662L14.4706 4.5176C14.7965 4.69139 15 5.03064 15 5.39995V6.21686L14 6.81686V4.26662ZM13 7.41686L9.25 9.66686L8 8.91686L6.75 9.66686L3 7.41686V2C3 1.44772 3.44772 1 4 1H12C12.5523 1 13 1.44772 13 2V7.41686ZM2 6.81686L1 6.21686V5.39995C1 5.03064 1.20355 4.69139 1.52941 4.5176L2 4.26662V6.81686ZM15 7.38305V13.1169L10.2218 10.25L15 7.38305ZM14.9653 14.2622C14.85 14.6873 14.4615 15 14 15H2C1.53846 15 1.14995 14.6873 1.03474 14.2622L8 10.083L14.9653 14.2622ZM1 13.1169V7.38305L5.77817 10.25L1 13.1169Z"
                  fill="white"
                />
              </svg>
              Invitations
            </button>
          </div>
          <span
            className="badge badge-primary position-top-end-overflow rounded-pill pulse mnw-0 d-grid p-0"
            style={{
              height: "16px",
              width: "16px",
              fontSize: "14px",
              placeItems: "center",
              backgroundColor: "#E3EDFF",
            }}
          >
            <span style={{ color: "#3862DD" }}>5</span>
          </span>
        </span>
      </div> */}
    </header>
  );
};

export default MeetingHeader;
