import React, { useEffect, useState } from "react";
import StatusBody from "./StatusBody";
import AddStatus from "./AddStatus";
import {
  getAllWorkspaceStatusBySpace,
  isCurrentWorkspaceDefault,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { useParams } from "react-router-dom";

function Status({ isModuleReadWriteAccessForSpace }) {
  const user = useSelector((state) => state.user);
  const [loader, setLoader] = useState(false);
  const [allStatus, setAllStatus] = useState([]);
  const [addStatusModal, setaddStatusModal] = useState(false);
  const { spaceId } = useParams();

  const myApproveWorkspaces = useSelector((state) => state.approvedWorkspace);
  const currentWorkspaceId = localStorage.getItem("current-workspace");

  //* Edit *//
  const [idividualStatusData, setidividualStatusData] = useState([]);
  const [editStatusModal, seteditStatusModal] = useState(false);

  //* Get All Status *//
  const handlTaskStatus = async () => {
    startApiCall(null, setLoader);
    const res = await getAllWorkspaceStatusBySpace(spaceId);
    setAllStatus(res);
    setLoader(false);
  };

  useEffect(() => {
    if (spaceId) {
      handlTaskStatus();
    }
  }, [spaceId]);

  return (
    <div
      id="space_status"
      className="modal fade add-new-task"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "80%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="text-capitalize mb-4">
              Space Status
              {!isCurrentWorkspaceDefault(
                myApproveWorkspaces,
                currentWorkspaceId
              ) && (
                <>
                  {isModuleReadWriteAccessForSpace && (
                    <button
                      className="btn btn-sm btn-outline-secondary ms-2"
                      type="button"
                      onClick={() => {
                        setaddStatusModal(true);
                      }}
                    >
                      Create New
                    </button>
                  )}
                </>
              )}
            </h5>

            {loader && <DataLoader title="Loading status..." />}
            {!loader && allStatus?.length === 0 && (
              <NoData title="No Status Found" />
            )}
            {!loader && allStatus?.length > 0 && (
              <StatusBody
                allStatus={allStatus}
                seteditStatusModal={seteditStatusModal}
                setidividualStatusData={setidividualStatusData}
                handlTaskStatus={handlTaskStatus}
                idividualStatusData={idividualStatusData}
                isModuleReadWriteAccessForSpace={
                  isModuleReadWriteAccessForSpace
                }
              />
            )}
            {(addStatusModal || editStatusModal) && (
              <AddStatus
                handlTaskStatus={handlTaskStatus}
                addStatusModal={addStatusModal}
                setaddStatusModal={setaddStatusModal}
                editStatusModal={editStatusModal}
                seteditStatusModal={seteditStatusModal}
                idividualStatusData={idividualStatusData}
                spaceId={spaceId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Status;
