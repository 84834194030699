import React, { useState } from "react";

function MoreDetails({ setisMoreDetails, selectedTransaction }) {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisMoreDetails(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>More Details</h5>
            <div className="border-bottom mb-2"></div>
            <h4>Note :&nbsp;{selectedTransaction?.description}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoreDetails;
