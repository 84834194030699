import React from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const ConfirmRemoveEmployee = (props) => {
  const {
    isOpen,
    onClose,
    loader,
    errorMessage,
    handleConfirm,
    isReason,
    setIsReason,
  } = props;

  return (
    <div
      id="remove_employee"
      className={`modal fade add-new-contact mt-30 ${isOpen ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{
        display: isOpen ? "block" : "none",
        pointerEvents: loader ? "none" : "",
      }}
      aria-hidden="true"
    >
      <form onSubmit={handleConfirm}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body px-5">
              <h5 className="mb-4 text_center">Remove Employee</h5>
              <div className="row gx-3">
                <div className="form-group">
                  <label className="form-label">Reason</label>
                  <textarea
                    rows="3"
                    className="form-control"
                    required
                    value={isReason}
                    onChange={(e) => {
                      setIsReason(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="text_center"></div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
            <div
              className="px-4 pb-4"
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                disabled={loader}
                className="btn btn-rounded btn-secondary col-sm-5"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loader}
                className="btn btn-rounded btn-primary col-sm-5"
              >
                {loader ? <ApiLoader /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConfirmRemoveEmployee;
