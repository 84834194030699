import React from "react";
import { CustomChip } from "../../../components/CustomChip";

const ResetFilterWithChip = (props) => {
  const {
    selectedAssigneeId,
    dueDateForFilter,
    startDate,
    endDate,
    selectedTaskPriority,
    selectedTaskStatus,
    handleResetFilters,
  } = props;

  const resetData = (type) => {
    handleResetFilters(type,true);
  };
  return (
    <div className="d-flex align-items-center justify-content-end pt-1 pe-3">
      {selectedAssigneeId?.length > 0 && (
        <CustomChip type={"Assignee"} resetData={resetData} />
      )}
      {(startDate !== "" || endDate !== "") && (
        <CustomChip type={"Date Range"} resetData={resetData} />
      )}
      {dueDateForFilter !== "" && (
        <CustomChip type={"Due Date"} resetData={resetData} />
      )}
      {selectedTaskStatus?.statusName !== "All Status" && (
        <CustomChip type={"Status"} resetData={resetData} />
      )}
      {selectedTaskPriority?.name !== "All Priority" && (
        <CustomChip type={"Priority"} resetData={resetData} />
      )}
    </div>
  );
};

export default ResetFilterWithChip;
