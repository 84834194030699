import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";

function CustomerOrderConfirmModal({
  uniqueItems,
  handleCreateOrder,
  allChargesData,
  subTotal,
  setpayableAmount,
  updateValueForItem,
  payableAmount,
  errorMessage,
  setconformOrder,
  loader3,
  orderPreference,
  setorderPreference,
}) {
  useEffect(() => {
    let totalAmountOnly = 0;
    let totalPercentageAmountOnly = parseInt(subTotal);

    allChargesData?.map((ch) => {
      if (ch.chargeType === "percentage") {
        if (ch.add) {
          totalPercentageAmountOnly += (subTotal * ch.value) / 100;
        } else {
          totalPercentageAmountOnly -= (subTotal * ch.value) / 100;
        }
      } else {
        if (ch.add) {
          totalAmountOnly += parseInt(ch.value);
        } else {
          totalAmountOnly -= parseInt(ch.value);
        }
      }
    });
    let finalAmount = parseInt(totalAmountOnly + totalPercentageAmountOnly);
    setpayableAmount(finalAmount.toFixed(2));
  }, [allChargesData, subTotal]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered mw-625p"
        role="document"
      >
        <div className="modal-content px-3">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setconformOrder(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">Check order</h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                handleCreateOrder(e);
              }}
            >
              <div className="d-flex flex-column gap-3 mb-3">
                <div className="d-flex justify-content-start gap-2 mb-3 mt-2">
                  <button
                    type="button"
                    className={`border rounded-4 p-2 bg-white ${
                      orderPreference === "dining" ? "border-primary" : ""
                    }`}
                    onClick={() => setorderPreference("dining")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M8.2683 12.1466L13.4147 13.0002L20.4858 20.0712L19.0716 21.4854L12.0005 14.4144L4.92946 21.4854L3.51525 20.0712L12.854 10.7324C12.2664 9.27549 12.8738 7.17715 14.4754 5.57554C16.428 3.62292 19.119 3.14805 20.4858 4.51488C21.8526 5.88172 21.3778 8.57267 19.4251 10.5253C17.8235 12.1269 15.7252 12.7343 14.2683 12.1466ZM4.22235 3.80777L10.9399 10.5253L8.11144 13.3537L4.22235 9.46463C2.66026 7.90253 2.66026 5.36987 4.22235 3.80777ZM18.0109 9.11107C19.2682 7.85386 19.5274 6.38488 19.0716 5.92909C18.6158 5.47331 17.1468 5.73254 15.8896 6.98975C14.6324 8.24697 14.3732 9.71595 14.829 10.1717C15.2847 10.6275 16.7537 10.3683 18.0109 9.11107Z"></path>
                    </svg>
                    &nbsp; Dining
                  </button>
                  <button
                    type="button"
                    className={`border rounded-4  bg-white ${
                      orderPreference === "take away" ? "border-primary" : ""
                    }`}
                    onClick={() => setorderPreference("take away")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-bag"
                      viewBox="0 0 18 18"
                    >
                      <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z" />
                    </svg>
                    &nbsp;Take Away
                  </button>
                </div>
                <div
                  className="border simple-scrollbar"
                  style={{ overflowY: "scroll", height: "194px" }}
                >
                  <table className="table text-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-start">No.</th>
                        <th className="text-start">Item</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uniqueItems?.map((item, index) => {
                        return (
                          <tr>
                            <td className="text-start">{index + 1}</td>
                            <td className="text-start">{item?.productName}</td>
                            <td>{item?.count}</td>
                            <td>₹ {item?.sellingPrice}</td>
                            <td>₹ {item?.itemPrice}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="py-2 px-3" style={{ background: "#E3EDFF" }}>
                  <div className="d-flex justify-content-between text-dark">
                    <p>Subtotal :</p>
                    <p>₹ {subTotal}</p>
                  </div>
                  {allChargesData?.length > 0 && (
                    <div
                      className="d-flex flex-column gap-1 pt-1 pb-1 overflow-y-scroll"
                      style={{ height: "20vh" }}
                    >
                      {allChargesData?.map((charge) => {
                        return (
                          <div
                            className="d-flex justify-content-between border mt-1 py-1 px-2 h-40p"
                            style={{ background: "#EAF1FF" }}
                          >
                            <p className="mt-1 fs-7 text-capitalize">
                              (<span>{charge?.add ? "+" : "-"}</span>){" "}
                              {charge?.chargeName} &nbsp;
                              {charge?.chargeType === "percentage" ? "%" : "₹"}
                            </p>
                            <span className="d-flex gap-2">
                              <input
                                className="form-control w-100p"
                                type="number"
                                min="0"
                                value={charge?.value}
                                onChange={(e) =>
                                  updateValueForItem(
                                    charge?._id,
                                    e.target.value
                                  )
                                }
                              />
                              {/* <span
                          className="mt-1 text-danger pointer"
                          onClick={() => toggleCancelIcon("packingCharges")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </span> */}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="d-flex justify-content-between text-dark">
                    <p>Amount Payable : </p>
                    <p>₹ {payableAmount}</p>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loader3}
                >
                  {loader3 ? <ApiLoader /> : "Confirm order"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerOrderConfirmModal;
