import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  navigateToFolderList,
  navigateToIndividualSpaces,
} from "../../Navigation/Navigation";
import { Priority } from "./Filters/Priority";
import { Status } from "./Filters/Status";
import { dateFormatter } from "../../global-functions/globalFunctions";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";

const TaskHeader = (props) => {
  const {
    location,
    locationData,
    taskFilters,
    setselectedFilterType,
    setSearch,
    search,
    handleGetTask,
    taskStatusLoader,
    taskStatus,
    handleGetAllTaskStatus,
    handleChangeStatus,
    selectedTaskStatus,
    selectedTaskPriority,
    handleChangePriority,
    selectedView,
    setSelectedView,
    setisFilteredModal,
    setPage,
    isAssigned,
    data,
    AllTask,
  } = props;
  const navigate = useNavigate();
  const allViews = ["Table View", "Board View", "Calendar View"];
  const filteredViews =
    isAssigned || AllTask
      ? allViews.filter((view) => view !== "Board View")
      : allViews;

  const rightIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-chevron-compact-right mt-1"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
      />
    </svg>
  );
  const downloadCSV = () => {
    let csvData = [];
    data?.map((dt, index) => {
      csvData.push({
        "Sr. No.": index + 1,
        "Task Id": dt.taskReferenceId,
        "Task Name": dt.name,
        "Date Created": dateFormatter(dt.createdAt),
        "Due Date": dt.dueDate ? dateFormatter(dt.dueDate) : "-",
        Priority: dt.priority,
        Status: dt?.status?.statusName,
      });
    });
    // Extract headers from the first object in the data array
    const headers = Object.keys(csvData[0]);
    // Convert header and data to CSV format
    const headerRow = headers.map((header) => `"${header}"`).join(",");
    const dataRows = csvData.map((obj) =>
      headers.map((header) => `"${obj[header]}"`).join(",")
    );
    const csvContent = `data:text/csv;charset=utf-8,${headerRow}\n${dataRows.join(
      "\n"
    )}`;
    // Create a data URI and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "my_task.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center ">
        {!isAssigned && (
          <>
            <button
              className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
              onClick={() => {
                if (locationData?.foldername) {
                  navigate(
                    navigateToFolderList({
                      id: locationData?.id,
                      spaceId: locationData?.spaceId,
                      spacename: locationData?.spacename,
                      foldername: locationData?.foldername,
                    })
                  );
                } else if (AllTask) {
                  navigate("/home");
                } else {
                  navigate(
                    navigateToIndividualSpaces({
                      spacename: locationData?.spacename,
                      id: locationData?.spaceId,
                    })
                  );
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                class="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
              <p className="mb-0">Back</p>
            </button>
            <div className="v-separator d-lg-inline-block d-none"></div>
          </>
        )}

        {isAssigned && <h5 className="task-text mt-2">My Tasks</h5>}
        {AllTask && <h5 className="task-text mt-2">All Tasks</h5>}
      </div>
      <div className="todo-options-wrap">
        <div className="me-2">
          <select
            className="form-select form-select-sm p-2"
            value={localStorage.getItem("taskview")}
            onChange={(event) => {
              setSelectedView(event.target.value);
              localStorage.setItem("taskview", event.target.value);
            }}
          >
            {filteredViews?.map((vw) => {
              return <option value={vw}>{vw}</option>;
            })}
          </select>
        </div>

        {/* ************ PRIORITY WISE FILTERS *********** */}
        {selectedView !== "Board View" && (
          <Priority
            selectedTaskPriority={selectedTaskPriority}
            handleChangePriority={handleChangePriority}
          />
        )}
        {/* ************ STATUS WISE FILTERS *********** */}
        {selectedView !== "Board View" && !isAssigned && !AllTask && (
          <Status
            selectedTaskStatus={selectedTaskStatus}
            handleChangeStatus={handleChangeStatus}
            taskStatus={taskStatus}
            handleGetAllTaskStatus={handleGetAllTaskStatus}
            taskStatusLoader={taskStatusLoader}
          />
        )}
        {/* ************ OTHER FILTERS *********** */}
        {selectedView !== "Board View" && (
          <div className="selectable-dropdown">
            <div className="dropdown selectable-dropdown">
              <span
                className={`me-2 fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
                aria-expanded="false"
                type="button"
                data-bs-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>
                &nbsp;<span className="dropdown-text"> Filter</span>
              </span>
              <div
                role="menu"
                className="dropdown-menu"
                style={{ maxHeight: "40vh", overflow: "auto" }}
              >
                {taskFilters?.map((filters, index) => (
                  <div className="d-flex justify-content-between">
                    <div
                      className="dropdown-item text-capitalize"
                      key={index}
                      value={filters}
                      onClick={() => {
                        setselectedFilterType(filters);
                        setisFilteredModal(true);
                      }}
                    >
                      {index + 1}. {filters}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {/* ************ REFRESH TASK *********** */}
        {selectedView !== "Board View" && (
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block d-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="refresh"
            data-bs-original-title="Refresh"
          >
            <span className="btn-icon-wrap" onClick={handleGetTask}>
              <span className="feather-icon">
                <i
                  className="ri-refresh-line"
                  title="Refresh business list"
                ></i>
              </span>
            </span>
          </span>
        )}
        {data?.length > 0 && (
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block d-none"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="download"
            data-bs-original-title="Download"
          >
            <span className="btn-icon-wrap" onClick={downloadCSV}>
              <span className="feather-icon">
                <i className="ri-download-line" title="Download Task"></i>
              </span>
            </span>
          </span>
        )}
        {/* ************ SEARCH TASK *********** */}
        {selectedView !== "Board View" && (
          <>
            <div className="v-separator d-lg-inline-block d-none"></div>
            <form className="d-sm-block d-none" role="search">
              <input
                type="text"
                className="form-control"
                placeholder="Search by task name"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(1);
                }}
              />
            </form>
          </>
        )}
      </div>
    </header>
  );
};

export default TaskHeader;
