import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToVendorDetails } from "../../Navigation/Navigation";
import {
  dateAndTimeFormatter,
  getName,
} from "../../global-functions/globalFunctions";
import { Avatar } from "../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

function VendorsTable({ allVendors, page, pageLimit }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-5">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th style={{ textAlign: "left" }}>Name</th>
              <th>Phone</th>
              <th>Email Id</th>
              <th>Created By</th>
              <th>Date Created</th>
            </tr>
          </thead>
          <tbody>
            {allVendors?.map((vendor, index) => {
              return (
                <tr className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td
                    style={{ textAlign: "left" }}
                    onClick={() => {
                      navigate(navigateToVendorDetails({ id: vendor?._id }));
                    }}
                  >
                    <span
                      className="d-inline-block text-truncate text-capitalize pointer text-link pointer"
                      style={{ width: "200px" }}
                    >
                      {vendor?.name}
                    </span>
                  </td>
                  <td>{vendor?.phone}</td>
                  <td>{vendor?.email ? vendor?.email : "-"}</td>
                  <td>
                    <Avatar
                      name={vendor?.createdBy?.name}
                      count={1}
                      image={vendor?.createdBy?.image}
                      size="xss"
                      color="primary"
                    />
                    <span className="text-capitalize ms-2">
                      {getName(vendor?.createdBy, user?._id)}
                    </span>
                  </td>
                  <td>{dateAndTimeFormatter(vendor?.createdAt)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default VendorsTable;
