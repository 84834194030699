import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  CrmSettingsRelatedEndPoints,
  LeadRelatedEndPoints,
  StatusRelatedEndPoints,
} from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import AssigneeEmpCsv from "./AssigneeEmpCsv";
import { Avatar } from "../../../components/Avatar/Avatar";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import UploadProgressModal from "./UploadProgressModal";
import Papa from "papaparse";

const ImportCsv = ({ setImportCsv, handleGetAllLeads }) => {
  const { user } = useSelector((state) => state);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderGroup, setLoaderGroup] = useState(false);
  const [loaderSub, setloaderSub] = useState(false);
  const [allStatusGroup, setallStatusGroup] = useState([]);
  const [allSubGroup, setallSubGroup] = useState(null);
  const [leadStatusGroup, setleadStatusGroup] = useState("");
  const [leadSource, setleadSource] = useState("");
  const [currentStatus, setcurrentStatus] = useState("");
  const [csvFile, setCsvFile] = useState("");
  const [allLeadSource, setAllLeadSource] = useState([]);
  const [loader2, setLoader2] = useState(false);
  const [allFields, setallFields] = useState([]);
  const [progressModal, setProgressModal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [totalTimeout, setTotalTimeout] = useState(0);

  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [selectedEmpId, setselectedEmpId] = useState([]);

  //Progress bar
  const [isUploading, setIsUploading] = useState(false);

  const handleReset = () => {
    setLoaderGroup(false);
    setCsvFile("");
    setleadStatusGroup("");
    setleadSource("");
    setImportCsv(false);
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file && file.type === "text/csv") {
  //     setCsvFile(file);
  //     const reader = new FileReader();

  //     reader.readAsText(file);
  //   } else {
  //     toast.warn("Please upload a valid CSV file.");
  //   }
  // };

  const handleFileChange = async (e) => {
    seterrorMessage("");
    const file = e.target.files[0];
    if (file && file.type === "text/csv") {
      setCsvFile(file);

      Papa?.parse(file, {
        complete: (results) => {
          const rows = results?.data;
          const headers = rows[0]?.map((header) =>
            header?.replace(/"/g, "").trim()
          );

          const titleIndex = headers?.indexOf("title");
          const phoneIndex = headers?.indexOf("phone");
          // const requiredFields = allFields
          //   ?.filter((field) => field?.isRequired === true)
          //   ?.map((fld) => fld?.csvColumnName);

          // const additionalFields = requiredFields?.map((field) =>
          //   headers?.indexOf(field)
          // );
          if (titleIndex === -1 || phoneIndex === -1) {
            seterrorMessage(`CSV file must contain title and phone columns.`);
            setCsvFile("");
            return;
          }

          const totalRows = rows.length - 1;
          if (totalRows > 10600) {
            seterrorMessage("CSV file must not contain more than 10,000 rows.");
            setCsvFile("");
            return;
          }

          setTotalRows(totalRows);
          const timeoutPerChunk = 2000; // 2 seconds for every 1000 rows
          const rowsPerChunk = 1000;
          const totalChunks = Math.ceil(totalRows / rowsPerChunk);
          const totalTimeout = totalChunks * timeoutPerChunk;
          setTotalTimeout(totalTimeout);

          const validRows = rows.filter((row, index) => {
            if (index === 0) return false;
            if (!row || row?.length === 0) return false;
            const columns = row?.map((col) => col?.trim());
            const isEmptyRow = columns?.every((col) => col === "");
            return !isEmptyRow;
          });

          const hasTitleAndPhoneInEveryRow = validRows?.every((row) => {
            const columns = row?.map((col) => col?.trim());
            return columns[titleIndex] !== "" && columns[phoneIndex] !== "";
          });

          if (!hasTitleAndPhoneInEveryRow) {
            seterrorMessage(
              "Title and phone are missing in one or more rows of the CSV file. Please download the dummy file"
            );
            setCsvFile("");
            return;
          }
        },
        error: (error) => {
          seterrorMessage(`Error parsing CSV file: ${error.message}`);
          setCsvFile("");
        },
      });
    } else {
      toast?.warn("Please upload a valid CSV file.");
    }
  };

  const handleGetLeadStatusGroup = async () => {
    startApiCall(null, setLoaderGroup);
    const data = {
      page: 1,
      limit: 300,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
        moduleType: "Lead",
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getGropus, data);
    if (res?.success) {
      setallStatusGroup(res?.result?.data);
      setLoaderGroup(false);
    } else {
      setLoaderGroup(false);
    }
  };

  const handleGetLeadSource = async () => {
    const data = {
      page: 1,
      limit: 200,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLeadSource, data);
    if (res?.success) {
      setAllLeadSource(res?.result?.data);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetLeadStatusGroup();
      handleGetLeadSource();
    }
  }, []);

  const handleGetAllSubStatus = async () => {
    startApiCall(null, setloaderSub);
    const data = {
      page: 1,
      limit: 200,
      filters: {
        statusGroupId: leadStatusGroup,
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getStatus, data);
    if (res?.success) {
      setallSubGroup(res?.result?.data);
      setloaderSub(false);
    } else {
      setloaderSub(false);
    }
  };

  useEffect(() => {
    if (leadStatusGroup !== "") {
      handleGetAllSubStatus();
    }
  }, [leadStatusGroup]);

  const downloadDummyCSVFile = () => {
    const staticData = {
      title: "abc",
      phone: "12345678909",
      email: "ghghg@gmail.com",
      source: "Self",
      businessName: "",
      websiteLink: "",
      description: "",
      address: "dfffgd",
      state: "gvgh",
      city: "cg",
      pincode: "434354",
      role: "Customer",
    };

    // Convert static data to CSV format
    const headers = Object.keys(staticData);
    const headerRow = headers.map((header) => `"${header}"`).join(",");
    const dataRow = headers
      .map((header) => `"${staticData[header]}"`)
      .join(",");
    const csvContent = `data:text/csv;charset=utf-8,${headerRow}\n${dataRow}`;

    // Create a data URI and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "dummy_leads.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleUploadCsv = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setProgressModal(true);
    startApiCall(seterrorMessage, setLoader);
    const formData = new FormData();
    formData.append("image", csvFile);
    formData.append("businessId", getCurrentWorkspaceId());
    formData.append("groupId", leadStatusGroup);
    formData.append("source", leadSource);
    formData.append("createdBy", user?._id);
    formData.append("status[]", currentStatus);
    selectedEmpId.forEach((empId) => formData.append("assignedTo[]", empId));
    // formData.append("assignedTo[]", selectedEmpId);
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.uploadCsvForLead,
      formData
    );
    setTimeout(() => {
      if (res?.success) {
        setIsUploading(false);
        setProgressModal(false);
        handleReset();
        handleGetAllLeads();

        toast.success("Upload CSV successfully!");
      } else {
        setIsUploading(false);
        setProgressModal(false);
        setLoader(false);
        seterrorMessage(res.error);
      }
    }, totalTimeout);
  };

  const handleGetDynamicFields = async () => {
    startApiCall(null, setLoader2);
    const data = {
      page: 1,
      limit: 1000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        feildFor: "Lead",
      },
    };
    const res = await ApiCall(
      "post",
      CrmSettingsRelatedEndPoints.getAllFields,
      data
    );
    if (res?.success) {
      setallFields(res?.customField?.data);
      setLoader2(false);
    } else {
      setLoader2(false);
    }
  };

  // useEffect(() => {
  //   if (getCurrentWorkspaceId()) {
  //     handleGetDynamicFields();
  //   }
  // }, []);

  useEffect(() => {
    if (allSubGroup?.length > 0) {
      setcurrentStatus(allSubGroup?.[0]?._id);
    }
  }, [allSubGroup]);

  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: "50%" }}
        >
          <div className="modal-content">
            <div className="modal-body ">
              <button
                type="button"
                className="btn-close mt-2 pe-3"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="ps-2 text-capitalize">Upload CSV File</h5>
              <div className="border-bottom"></div>
              <form onSubmit={handleUploadCsv}>
                <div className="row gx-3 px-3 mt-3 overflow-auto mh-600p">
                  <div className="d-flex justify-content-end mb-3">
                    <div
                      className="border p-2 rounded-5 pointer bg-primary text-white px-3"
                      onClick={downloadDummyCSVFile}
                    >
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-file-earmark-bar-graph"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10 13.5a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5z" />
                          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                        </svg>
                      </span>
                      Download sample
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <input
                      type="file"
                      name="image"
                      id="attachment"
                      accept=".csv"
                      className="input-file justify-content-end"
                      disabled={isUploading}
                      onChange={handleFileChange}
                    />
                    <label htmlFor="attachment">
                      <div className="activity-details-image-card d-flex flex-column justify-content-center align-items-center h-100 w-150p px-2 py-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="currentColor"
                          className="bi bi-upload me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                        </svg>
                        <span className="fs-7 mt-2">Choose files</span>
                        <div className="fs-7 pointer justify-content-center align-items-center mt-3 pointer bg-cyan px-3 py-1 text-white rounded-10">
                          Browse files
                        </div>
                      </div>
                    </label>
                  </div>
                  {csvFile && (
                    <div className="d-flex justify-content-center h-100">
                      <div className="activity-details-image-upload-card px-3 mt-2 w-auto d-flex justify-content-center align-items-center">
                        {csvFile?.name}
                        <button
                          type="button"
                          onClick={() => setCsvFile("")}
                          style={{
                            marginTop: "26px",
                            marginLeft: "15px",
                            transform: "translateY(-50%)",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "20px",
                            padding: "0",
                          }}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  )}
                  {csvFile && (
                    <>
                      <div className="col-sm-6 mt-2">
                        <label className="form-label">
                          Choose Status group {reuiredField}
                        </label>
                        <div className="form-group">
                          <div className="todo-toolbar">
                            <select
                              className="form-select form-select-md"
                              name="leadStatusGroup"
                              required
                              value={leadStatusGroup}
                              onChange={(e) => {
                                setleadStatusGroup(e.target.value);
                              }}
                            >
                              <option value="">Choose Status Group</option>
                              {loaderGroup && (
                                <option disabled>Loading Groups...</option>
                              )}
                              {!loaderGroup && (
                                <>
                                  {allStatusGroup?.map((item, index) => {
                                    return (
                                      <option value={item?._id}>
                                        {index + 1}. {item?.name}
                                      </option>
                                    );
                                  })}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-6 mt-2">
                        <label className="form-label">Current Status</label>
                        <div className="form-group">
                          <div className="todo-toolbar">
                            <select
                              className="form-select form-select-md"
                              name="currentStatus"
                              required
                              disabled={leadStatusGroup === ""}
                              value={currentStatus}
                              onChange={(e) => {
                                setcurrentStatus(e.target.value);
                              }}
                            >
                              <option value="">Select Current Status</option>
                              {loaderSub && (
                                <option disabled>Loading Groups...</option>
                              )}
                              {!loaderSub && (
                                <>
                                  {allSubGroup?.map((item, index) => {
                                    return (
                                      <option value={item?._id}>
                                        {index + 1}. {item?.name}
                                      </option>
                                    );
                                  })}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-sm-6 mt-2">
                        <div className="form-group">
                          <label className="form-label">
                            Lead Source {reuiredField}
                          </label>
                          <div className="form-group">
                            <div className="todo-toolbar">
                              <select
                                className="form-select form-select-md"
                                name="leadSource"
                                required
                                value={leadSource}
                                onChange={(e) => {
                                  setleadSource(e.target.value);
                                }}
                              >
                                <option value="">Select Source</option>
                                {allLeadSource?.map((data, index) => {
                                  return (
                                    <option value={data?.name}>
                                      {index + 1}. {data?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 mt-2">
                        <label className="form-label">
                          Assign Participant {reuiredField}
                        </label>
                        <div className="d-flex align-items-center repeater border rounded-5 w-100 text-dark">
                          <div
                            data-repeater-list="category-group"
                            className="h-80"
                          >
                            <ul className="hk-list pointer">
                              {selectedEmployees?.map((emp, index) => {
                                return (
                                  <CustomTooltip
                                    text={emp?.name}
                                    placement={index === 0 ? "right" : "top"}
                                  >
                                    <li
                                      key={index}
                                      className="ms-2"
                                      style={{ padding: "0.48rem" }}
                                    >
                                      <Avatar
                                        name={emp?.name}
                                        count={1}
                                        image={emp?.image}
                                        size="xxs"
                                      />
                                    </li>
                                  </CustomTooltip>
                                );
                              })}
                              <li
                                className="ms-2 pointer"
                                style={{ padding: "0.48rem" }}
                                onClick={() => {
                                  setassigneeModal(true);
                                }}
                              >
                                {selectedEmpId?.length === 0 ? (
                                  "Assign Participant:"
                                ) : (
                                  <span className="text-primary">
                                    {selectedEmpId?.length} Participant Selected
                                  </span>
                                )}
                                &nbsp; &nbsp;
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                  className="bi bi-plus-circle"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      !leadSource ||
                      !leadStatusGroup ||
                      !csvFile ||
                      selectedEmpId?.length === 0
                    }
                  >
                    {loader ? <ApiLoader /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {assigneeModal && (
        <AssigneeEmpCsv
          setassigneeModal={setassigneeModal}
          assigneeModal={assigneeModal}
          selectedEmpId={selectedEmpId}
          setselectedEmpId={setselectedEmpId}
          selectedEmployees={selectedEmployees}
          setselectedEmployees={setselectedEmployees}
        />
      )}
      {progressModal && (
        <UploadProgressModal
          totalTimeout={totalTimeout}
          isUploading={isUploading}
        />
      )}
    </>
  );
};

export default ImportCsv;
