import React from "react";

const PauseAllNotificationsModal = ({ showModal, handleToggleModal }) => {
  return (
    <>
      <div
        id="pause_all_notifications"
        className={`modal fade add-new-contact ${showModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div
          className="modal-dialog modal-dialog-centered mw-700p"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body px-6 py-4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleToggleModal}
              >
                <span aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                  </svg>
                </span>
              </button>
              <h5 className="header_dark mb-5">Pause all notifications</h5>
              <form>
                <div className="mh-500p nicescroll-bar simple-scrollbar">
                  <div className="d-flex flex-column gap-3 mb-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="until_4_hours"
                        value="option1"
                      />
                      <label class="form-check-label" htmlFor="until_4_hours">
                        Until 4 hours
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="until_6_hours"
                        value="option2"
                      />
                      <label class="form-check-label" htmlFor="until_6_hours">
                        Until 6 hours
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="until_8_hours"
                        value="option3"
                      />
                      <label class="form-check-label" htmlFor="until_8_hours">
                        Until 8 hours
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="manually"
                        value="option4"
                        checked
                      />
                      <label class="form-check-label" htmlFor="manually">
                        Until I turn it on manually
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer border-0 py-0">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    // disabled={!}
                  >
                    Save changes
                    {/* {loader ? (
                      <ApiLoader />
                    ) : edit === "" ? (
                      "Save changes"
                    ) : (
                      "Edit changes"
                    )} */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PauseAllNotificationsModal;
