import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  handleModalMutation,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../../api/ApiCall";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../../../components/Error/ErrorMessage";
import {
  CrmContactRelatedEndPoints,
  LeadRelatedEndPoints,
  CrmTaskRelatedEndPoints,
  activityGroupRelatedEndpoints,
  DealRelatedEndPoints,
} from "../../../../../../api/Endpoints";
import { useSelector } from "react-redux";
import ApiLoader from "../../../../../../components/Loaders/ApiLoader/ApiLoader";
import CustomTooltip from "../../../../../../components/CustomTooltip/CustomTooltip";
import { Avatar } from "../../../../../../components/Avatar/Avatar";
import moment from "moment";
import ActivityAssignParticipant from "../ActivityMandatoryTask/ActivityAssignParticipant";

const AddMeetingCrm = ({
  handleGetMeeting,
  editMeeting,
  seteditMeeting,
  isLeadDeal,
  leadDetails,
  id,
}) => {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  var today = reversesDateFormatter(new Date());
  var currentTime = moment().format("HH:mm");
  const endTimeAddOneHour = moment().add(1, "hour").format("HH:mm");
  const [title, settitle] = useState("");
  const [date, setdate] = useState(today);
  const [startTime, setstartTime] = useState(currentTime);
  const [endTime, setendTime] = useState(endTimeAddOneHour);
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmployees, setselectedEmployees] = useState([]);
  const [selectedEmpId, setselectedEmpId] = useState([]);
  const [detailInfo, setdetailInfo] = useState([]);
  const [description, setdescription] = useState("");

  //Activity Group And Status
  const [loader2, setloader2] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroup, setselectedGroup] = useState("");
  const [selectedActivityStatus, setselectedActivityStatus] = useState("");

  //Select Contact
  const [contactLoader, setcontactLoader] = useState(false);
  const [selectContact, setselectContact] = useState("");
  const [filter, setFilter] = useState("");
  const [allContacts, setallContacts] = useState([]);
  const [contactId, setcontactId] = useState("");
  const filteredContacts = filterSearchData(allContacts, filter);
  const [relatedTo, setrelatedTo] = useState("Contact");

  //Select Lead
  const [leadLoader, setleadLoader] = useState(false);
  const [selectlead, setselectlead] = useState("");
  const [filterLeads, setFilterLeads] = useState("");
  const [allLeads, setallLeads] = useState([]);
  const [leadId, setleadId] = useState("");
  const filteredleads = allLeads?.filter((data) =>
    data?.title?.toLowerCase()?.includes(filterLeads.toLowerCase())
  );

  // Select Deal
  const [dealLoader, setdealLoader] = useState(false);
  const [selectdeal, setselectdeal] = useState("");
  const [filterDeals, setFilterDeals] = useState("");
  const [allDeals, setallDeals] = useState([]);
  const [dealId, setdealId] = useState("");
  const filtereddeals = allDeals?.filter((data) =>
    data?.title?.toLowerCase()?.includes(filterDeals.toLowerCase())
  );

  // Contact
  const handleGetContact = async () => {
    startApiCall(null, setcontactLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isRemoved: false,
      },
    };
    const res = await ApiCall(
      "post",
      CrmContactRelatedEndPoints.getContact,
      data
    );
    if (res?.success) {
      setallContacts(res?.result?.data);
      setcontactLoader(false);
    } else {
      setcontactLoader(false);
    }
  };
  // Leads
  const handleGetLeads = async () => {
    startApiCall(null, setleadLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "lead",
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLead, data);
    if (res?.success) {
      setallLeads(res?.result?.data);
      setleadLoader(false);
    } else {
      setleadLoader(false);
    }
  };
  // Deals
  const handleGetAllDeals = async () => {
    startApiCall(null, setdealLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "deal",
      },
    };
    const res = await ApiCall("post", DealRelatedEndPoints.getDeal, data);
    if (res?.success) {
      setallDeals(res?.result?.data);
      setdealLoader(false);
    } else {
      setdealLoader(false);
    }
  };

  // ADD MEETING IN CRM
  const handleAddMeeting = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      taskType: "meeting",
      title: title,
      activityDate: date,
      relatedTo:
        isLeadDeal && leadDetails?.status === "lead"
          ? "Lead"
          : isLeadDeal && leadDetails?.status === "deal"
          ? "Deal"
          : relatedTo === "Lead"
          ? "Lead"
          : relatedTo === "Deal"
          ? "Deal"
          : relatedTo,
      startTime: startTime,
      endTime: endTime,
      actionOn: isLeadDeal
        ? id
        : relatedTo === "Contact"
        ? contactId
        : relatedTo === "Lead"
        ? leadId
        : dealId,
      actionBy: user?._id,
      businessId: getCurrentWorkspaceId(),
      priority: "low",
      assignees: selectedEmpId,
      activityGroup: selectedGroup,
      notes: description,
      createdBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmTaskRelatedEndPoints.addTask,
      sendData
    );
    if (res?.success) {
      handleReset();
      handleGetMeeting();
      toast.success("Meeting created successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleReset = () => {
    setloader(false);
    setErrorMessage();
    settitle("");
    setdate(today);
    setstartTime(currentTime);
    setendTime(endTimeAddOneHour);
    seteditMeeting("");
    setleadId("");
    setselectlead("");
    setrelatedTo("Contact");
    setcontactId("");
    setselectContact("");
    setselectedEmployees([]);
    setselectedEmpId([]);
    setselectedGroup("");
    setselectedActivityStatus("");
    setdescription("");
    const closeModalDom = document.getElementById("meeting_modal");
    if (closeModalDom) closeModalDom.click();
  };

  const handleGetAllGroup = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        activityType: "meeting",
      },
    };
    const res = await ApiCall(
      "post",
      activityGroupRelatedEndpoints.getActivityGroup,
      data
    );
    if (res?.success) {
      setAllGroups(res?.group?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    const observerRef = handleModalMutation("meeting_modal", handleGetAllGroup);

    return () => {
      observerRef.disconnect();
    };
  }, []);

  // const handleGetAllStatus = async (id) => {
  //   startApiCall(setErrorMessage, setloader3);
  //   const data = {
  //     page: 1,
  //     limit: 200,
  //     filters: {
  //       businessId: getCurrentWorkspaceId(),
  //       activityGroupId: selectedGroup,
  //     },
  //   };
  //   const res = await ApiCall(
  //     "post",
  //     activityGroupRelatedEndpoints.getActivitySubStatus,
  //     data
  //   );
  //   if (res?.success) {
  //     setallStatus(res?.status?.data);
  //     setloader3(false);
  //   } else {
  //     setloader3(false);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedGroup !== "") {
  //     handleGetAllStatus();
  //   }
  // }, [selectedGroup]);

  // useEffect(() => {
  //   if (allStatus?.length > 0) {
  //     setselectedActivityStatus(allStatus?.[0]);
  //   }
  // }, [allStatus]);

  const handleClose = () => {
    setassigneeModal(false);
  };

  return (
    <>
      <div
        id="meeting_modal"
        className="modal fade add-new-contact"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content" style={{ overflow: "visible" }}>
            <div className="modal-body">
              <button
                type="button"
                className="btn-close mt-2 pe-3"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="ps-2">
                {editMeeting === "" ? "Schedule Meeting" : "Update Meeting"}
              </h5>
              <div className="border-bottom mb-3"></div>
              <form
                onSubmit={(e) => {
                  handleAddMeeting(e);
                }}
              >
                <div className="row gx-3 px-3 w-100">
                  <div className="col-sm-6 mb-3">
                    <label className="form-label">
                      Meeting Title {reuiredField}
                    </label>
                    <input
                      className="form-control p-2"
                      type="text"
                      placeholder="Enter Meeting Agenda"
                      required
                      value={title}
                      onChange={(e) => {
                        settitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-6 mb-3">
                    <label className="form-label">Meeting Participant</label>
                    <div className="d-flex align-items-center repeater border rounded-5 w-100 text-dark">
                      <div data-repeater-list="category-group" className="h-80">
                        <ul className="hk-list pointer">
                          {selectedEmployees?.map((emp, index) => {
                            return (
                              <CustomTooltip
                                text={emp?.name}
                                placement={index === 0 ? "right" : "bottom"}
                              >
                                <li
                                  key={index}
                                  className="ms-2"
                                  style={{ padding: "0.48rem" }}
                                >
                                  <Avatar
                                    name={emp?.name}
                                    count={1}
                                    image={emp?.image}
                                    size="xxs"
                                  />
                                </li>
                              </CustomTooltip>
                            );
                          })}

                          <li
                            className="ms-2 pointer"
                            style={{ padding: "0.48rem" }}
                            onClick={() => {
                              if (
                                !contactId &&
                                !leadId &&
                                !dealId &&
                                !isLeadDeal
                              ) {
                                toast.warning(
                                  "Please select a Deal, Lead or Contact before assigning a participant to the call."
                                );
                              } else {
                                setassigneeModal(true);
                              }
                            }}
                          >
                            {selectedEmpId?.length === 0 ? (
                              "Assign Participant:"
                            ) : (
                              <span className="text-primary">
                                {selectedEmpId?.length} Participant Selected
                              </span>
                            )}{" "}
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              className="bi bi-plus-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {!isLeadDeal && (
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="form-label">Related To</label>
                        <div className="form-group">
                          <div className="todo-toolbar">
                            <select
                              className="form-select form-select-md"
                              value={relatedTo}
                              disabled={editMeeting}
                              onChange={(e) => {
                                setrelatedTo(e.target.value);
                              }}
                            >
                              <option value="Contact">Contact</option>
                              <option value="Lead">Lead</option>
                              <option value="Deal">Deal</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isLeadDeal && (
                    <>
                      {relatedTo === "Contact" && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label">
                              Select Contact{" "}
                            </label>
                            <div className="selectable-dropdown w-100">
                              <div className="dropdown selectable-dropdown">
                                <span
                                  className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                                    editMeeting ? "bg-light text-disabled" : ""
                                  }`}
                                  aria-expanded="false"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  disabled={editMeeting}
                                  onClick={() => {
                                    setFilter("");
                                    handleGetContact();
                                  }}
                                >
                                  {selectContact ? (
                                    <>
                                      {selectContact}
                                      {selectContact !== "" && !editMeeting && (
                                        <button
                                          type="button"
                                          className="btn-close btn-sm ms-2"
                                          onClick={() => {
                                            setselectContact("");
                                            setcontactId("");
                                            setFilter("");
                                          }}
                                          aria-label="Close"
                                        ></button>
                                      )}
                                    </>
                                  ) : (
                                    "Select Contact"
                                  )}
                                </span>
                                <div role="menu" className="dropdown-menu">
                                  <form
                                    className="d-sm-block d-none"
                                    role="search"
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search.."
                                      value={filter}
                                      onChange={(e) =>
                                        setFilter(e.target.value)
                                      }
                                    />
                                  </form>
                                  {contactLoader ? (
                                    <span className="d-flex text-center mt-1">
                                      Loading...
                                    </span>
                                  ) : (
                                    <div
                                      style={{
                                        maxHeight: "22vh",
                                        overflow: "auto",
                                      }}
                                    >
                                      {filteredContacts?.map(
                                        (contact, index) => (
                                          <div
                                            className={`dropdown-item text-capitalize`}
                                            key={contact?.name}
                                            onClick={() => {
                                              setselectContact(contact?.name);
                                              setcontactId(contact?._id);
                                              setdetailInfo(contact);
                                            }}
                                          >
                                            {index + 1}. {contact?.name}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}

                                  {!contactLoader &&
                                    filteredContacts?.length === 0 && (
                                      <span className="d-flex text-center mt-1">
                                        No Contacts found
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {relatedTo === "Lead" && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label">Select Leads </label>
                            <div className="selectable-dropdown w-100">
                              <div className="dropdown selectable-dropdown">
                                <span
                                  className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                                    editMeeting ? "bg-light text-disabled" : ""
                                  }`}
                                  aria-expanded="false"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  disabled={editMeeting}
                                  onClick={() => {
                                    setFilterLeads("");
                                    handleGetLeads();
                                  }}
                                >
                                  {selectlead ? (
                                    <>
                                      {selectlead}
                                      {selectlead !== "" && !editMeeting && (
                                        <button
                                          type="button"
                                          className="btn-close btn-sm ms-2"
                                          onClick={() => {
                                            setselectlead("");
                                            setleadId("");
                                            setFilterLeads("");
                                          }}
                                          aria-label="Close"
                                        ></button>
                                      )}
                                    </>
                                  ) : (
                                    "Select Lead"
                                  )}
                                </span>
                                <div role="menu" className="dropdown-menu">
                                  <form
                                    className="d-sm-block d-none"
                                    role="search"
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search.."
                                      value={filterLeads}
                                      onChange={(e) =>
                                        setFilterLeads(e.target.value)
                                      }
                                    />
                                  </form>
                                  {leadLoader ? (
                                    <span className="d-flex text-center mt-1">
                                      Loading...
                                    </span>
                                  ) : (
                                    <div
                                      style={{
                                        maxHeight: "22vh",
                                        overflow: "auto",
                                      }}
                                    >
                                      {filteredleads?.map((lead, index) => (
                                        <div
                                          className={`dropdown-item text-capitalize`}
                                          key={lead?._id}
                                          onClick={() => {
                                            setselectlead(lead?.title);
                                            setleadId(lead?._id);
                                            setdetailInfo(lead);
                                          }}
                                        >
                                          {index + 1}. {lead?.title}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                  {!leadLoader &&
                                    filteredleads?.length === 0 && (
                                      <span className="d-flex text-center mt-1">
                                        No Leads found
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {relatedTo === "Deal" && (
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label">Select Deal </label>
                            <div className="selectable-dropdown w-100">
                              <div className="dropdown selectable-dropdown">
                                <span
                                  className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                                    editMeeting ? "bg-light text-disabled" : ""
                                  } `}
                                  aria-expanded="false"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  disabled={editMeeting}
                                  onClick={() => {
                                    setFilterDeals("");
                                    handleGetAllDeals();
                                  }}
                                >
                                  {selectdeal ? (
                                    <>
                                      {selectdeal}
                                      {selectdeal !== "" && !editMeeting && (
                                        <button
                                          type="button"
                                          className="btn-close btn-sm ms-2"
                                          onClick={() => {
                                            setselectdeal("");
                                            setdealId("");
                                            setFilterDeals("");
                                          }}
                                          aria-label="Close"
                                        ></button>
                                      )}
                                    </>
                                  ) : (
                                    "Select Deal"
                                  )}
                                </span>
                                <div role="menu" className="dropdown-menu">
                                  <form
                                    className="d-sm-block d-none"
                                    role="search"
                                  >
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search.."
                                      value={filterDeals}
                                      onChange={(e) =>
                                        setFilterDeals(e.target.value)
                                      }
                                    />
                                  </form>
                                  {dealLoader ? (
                                    <span className="d-flex text-center mt-1">
                                      Loading...
                                    </span>
                                  ) : (
                                    <div
                                      style={{
                                        maxHeight: "22vh",
                                        overflow: "auto",
                                      }}
                                    >
                                      {filtereddeals?.map((deal, index) => (
                                        <div
                                          className={`dropdown-item text-capitalize`}
                                          key={deal?._id}
                                          onClick={() => {
                                            setselectdeal(deal?.title);
                                            setdealId(deal?._id);
                                            setdetailInfo(deal);
                                          }}
                                        >
                                          {index + 1}. {deal?.title}
                                        </div>
                                      ))}
                                    </div>
                                  )}

                                  {!dealLoader &&
                                    filtereddeals?.length === 0 && (
                                      <span className="d-flex text-center mt-1">
                                        No Deals found
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Schedule Date {reuiredField}
                      </label>
                      <input
                        className="form-control p-2"
                        type="date"
                        required
                        value={date}
                        onChange={(e) => {
                          setdate(e.target.value);
                        }}
                        min={today}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">
                      Choose Activity Status group {reuiredField}
                    </label>
                    <div className="form-group">
                      <div className="todo-toolbar">
                        <select
                          className="form-select form-select-md"
                          name="leadStatusGroup"
                          required
                          value={selectedGroup}
                          onChange={(e) => {
                            setselectedGroup(e.target.value);
                          }}
                        >
                          <option value="">Select Group</option>
                          {loader2 && (
                            <option disabled>Loading Groups...</option>
                          )}
                          {!loader2 && (
                            <>
                              {allGroups?.map((item, index) => {
                                return (
                                  <option value={item?._id}>
                                    {index + 1}. {item?.name}
                                  </option>
                                );
                              })}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        Start Time {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        placeholder="Start Time"
                        type="time"
                        required
                        value={startTime}
                        onChange={(e) => {
                          setstartTime(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        End Time {reuiredField}
                      </label>
                      <input
                        className="form-control"
                        type="time"
                        required
                        value={endTime}
                        onChange={(e) => {
                          setendTime(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gx-3 px-3 w-100">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <div className="form-group">
                        <label className="form-label">Note</label>
                        <div className="todo-toolbar">
                          <textarea
                            className="form-control"
                            rows="3"
                            onChange={(e) => {
                              setdescription(e.target.value);
                            }}
                            value={description}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="selectable-dropdown">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className={` me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100  ${
                            selectedGroup === "" ? "bg-light text-disabled" : ""
                          }`}
                          aria-expanded="false"
                          type="button"
                          data-bs-toggle="dropdown"
                          disabled={selectedGroup === ""}
                          onClick={() => {
                            handleGetAllStatus(selectedGroupId);
                          }}
                        >
                          {selectedActivityStatus ? (
                            <>
                              {selectedActivityStatus?.name}
                              {selectedActivityStatus !== "" && (
                                <button
                                  type="button"
                                  className="btn-close btn-sm ms-2"
                                  onClick={() => {
                                    setselectedActivityStatus("");
                                  }}
                                  aria-label="Close"
                                ></button>
                              )}
                            </>
                          ) : (
                            <>Select Activity Status {reuiredField}</>
                          )}
                        </span>

                        <div
                          role="menu"
                          className="dropdown-menu"
                          style={{
                            maxHeight: "40vh",
                            overflow: "auto",
                          }}
                        >
                          {loader2 ? (
                            <span className="text-center">Loading...</span>
                          ) : (
                            <>
                              {allStatus?.map((status) => (
                                <span
                                  className="dropdown-item text-capitalize d-flex align-items-center"
                                  onClick={() => {
                                    setselectedActivityStatus(status);
                                  }}
                                >
                                  {status?.name}
                                </span>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-sm-6">
                    <label className="form-label">
                      Choose Current Status {reuiredField}
                    </label>
                    <div className="form-group">
                      <div className="todo-toolbar">
                        <select
                          className="form-select form-select-md"
                          name="currentStatus"
                          required
                          disabled={selectedGroup === ""}
                          value={selectedCurrentStatus}
                          onChange={(e) => {
                            setselectedCurrentStatus(e.target.value);
                          }}
                        >
                          <option value="">Select Current Status</option>
                          {loader3 && (
                            <option disabled>Loading Status...</option>
                          )}
                          {!loader3 && (
                            <>
                              {allStatus?.map((item, index) => {
                                return (
                                  <option value={item?._id}>
                                    {index + 1}. {item?.name}
                                  </option>
                                );
                              })}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                  </div> */}
                </div>

                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={
                      loader ||
                      (!isLeadDeal &&
                        ((relatedTo === "Contact" && !selectContact) ||
                          (relatedTo === "Lead" && !selectlead) ||
                          (relatedTo === "Deal" && !selectdeal)))
                    }
                  >
                    {loader ? <ApiLoader /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
            {assigneeModal && (
              <ActivityAssignParticipant
                setassigneeModal={setassigneeModal}
                addParticipant={assigneeModal}
                selectedAssigneeId={selectedEmpId}
                setselectedAssigneeId={setselectedEmpId}
                handleClose={handleClose}
                handleAddParticipant={handleClose}
                loader2={loader}
                errorMessage={errorMessage}
                //  allGroupMembers={activityDetails?.assignees}
                isSingle={true}
                leadDetails={detailInfo}
                setselectedEmployees={setselectedEmployees}
                selectedEmployees={selectedEmployees}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMeetingCrm;
