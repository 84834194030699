import React, { useState } from "react";
import {
  convertAmountLocal,
  dateAndTimeFormatter,
  getName,
} from "../../../global-functions/globalFunctions";
import ItemsInfoModal from "./ItemsInfoModal";
import { useNavigate } from "react-router-dom";
import {
  navigateToManageInvoiceDetails,
  navigateToOrder,
} from "../../../Navigation/Navigation";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const OrderHistoryTable = ({
  isOrder,
  page,
  pageLimit,
  locationData,
  setpaymentModal,
  setorderData,
  setIsOpen,
  isModuleReadWriteAccessForOrderHistory,
  setreturnOrder,
  componentRef,
}) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [itemDetails, setItemDetails] = useState([]);
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);

  const updateOrderData = (newOrderData) => {
    setorderData(newOrderData);
  };

  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8 text-center">
          <thead>
            <tr>
              <th>Sl No.</th>
              <th>Order No.</th>
              <th>Order date</th>
              <th>Action by</th>
              <th>Customer Name</th>
              <th>Table</th>
              <th>Items</th>
              <th>Amount</th>
              <th>Payment Status</th>
              <th>Invoice</th>
              {isModuleReadWriteAccessForOrderHistory && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {isOrder?.map((item, index) => {
              return (
                <tr key={item?._id}>
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td>{item?.orderNumber.toString().padStart(3, "0")}</td>
                  <td>{dateAndTimeFormatter(item?.createdAt)}</td>
                  <td className="text-capitalize">
                    {item?.actionBy ? (
                      <>
                        <Avatar
                          name={item?.actionBy?.name}
                          count={1}
                          image={item?.actionBy?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(item?.actionBy, user?._id)}
                      </>
                    ) : item?.createdBy ? (
                      <>
                        <Avatar
                          name={item?.createdBy?.name}
                          count={1}
                          image={item?.createdBy?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(item?.createdBy, user?._id)}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{item?.buyer ? item?.buyer?.name : "Guest"}</td>
                  <td>
                    {item?.tableId?.tableName ? item?.tableId?.tableName : "-"}
                  </td>
                  <td>
                    <div
                      className="pointer d-flex align-items-center justify-content-center gap-1"
                      onClick={() => {
                        setIsOpenDetailsModal(true);
                        setItemDetails(item);
                      }}
                    >
                      {item?.products?.length}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="#3862DD"
                        class="bi bi-info-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                      </svg>
                    </div>
                  </td>
                  <td className="text-dark font-3 font-mon">
                    {convertAmountLocal(item?.amount)}
                  </td>
                  <td>
                    <p
                      className={`text-capitalize ${
                        item?.status === "confirmed"
                          ? "text-success"
                          : item?.status === "pending"
                          ? "text-warning"
                          : item?.status === "cancelled"
                          ? "text-danger"
                          : "text-danger"
                      }`}
                    >
                      {item?.status === "confirmed" ? "Paid" : item?.status}
                    </p>
                  </td>
                  <td
                    onClick={() => {
                      // if (
                      //   item?.status === "confirmed" ||
                      //   item?.status === "returned"
                      // ) {
                      navigate(
                        navigateToManageInvoiceDetails({ id: item?._id }),
                        {
                          state: {
                            subHeader: "Order History",
                            data: locationData ? locationData : "",
                          },
                        }
                      );
                      // }
                    }}
                  >
                    {/* {item?.status === "confirmed" ||
                    item?.status === "returned" ? ( */}
                    <span className="btn btn-icon btn-rounded btn-sm">
                      <span nonce="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="#3862DD"
                          class="bi bi-box-arrow-up-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                          />
                        </svg>
                      </span>
                    </span>
                   {/* ) : (
                      "-"
                    )} */}
                  </td>

                  {isModuleReadWriteAccessForOrderHistory && (
                    <td>
                      {item?.status === "confirmed"  || item?.status === "cancelled" ||
                      item?.status === "returned" ? (
                        "-"
                      ) : (
                        <span className="feather-icon pointer">
                          <i
                            data-bs-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="ri-more-fill"
                            onClick={() => {
                              updateOrderData(item);
                            }}
                          ></i>
                          <div className="dropdown-menu">
                            <div
                              className="dropdown-item d-flex align-items-center"
                              type="submit"
                              data-bs-toggle="modal"
                              data-bs-target="#kot_print_modal"
                            >
                              <span className="d-flex">
                                <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                                  <span className="btn-icon-wrap">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="17"
                                      fill="currentColor"
                                      class="bi bi-printer-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                                      <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                    </svg>
                                  </span>
                                </span>
                                <p className="mt-3">Print KOT</p>
                              </span>
                            </div>
                            <div
                              className="dropdown-item d-flex align-items-center"
                              onClick={() => {
                                setpaymentModal(true);
                              }}
                            >
                              <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                                <span className="btn-icon-wrap">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-credit-card-2-back-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1z" />
                                  </svg>
                                </span>
                              </span>
                              <p>Pay Amount</p>
                            </div>
                            <div
                              className="dropdown-item d-flex align-items-center"
                              onClick={() => {
                                navigate(navigateToOrder(), {
                                  state: { orderId: item?._id },
                                });
                              }}
                            >
                              <span
                                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <span className="btn-icon-wrap">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="20"
                                    fill="currentColor"
                                    class="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <p>Edit</p>
                            </div>
                            <div
                              className="dropdown-item d-flex align-items-center"
                              onClick={() => {
                                setreturnOrder(true);
                              }}
                            >
                              <span
                                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <span className="btn-icon-wrap">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-bag-x-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0M6.854 8.146a.5.5 0 1 0-.708.708L7.293 10l-1.147 1.146a.5.5 0 0 0 .708.708L8 10.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 10l1.147-1.146a.5.5 0 0 0-.708-.708L8 9.293z"
                                    />
                                  </svg>
                                </span>
                              </span>
                              <p>Return Order</p>
                            </div>
                            <div
                              className="dropdown-item d-flex align-items-center"
                              onClick={() => {
                                setIsOpen(true);
                              }}
                            >
                              <span
                                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                              >
                                <span className="btn-icon-wrap">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-x-circle"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                  </svg>
                                </span>
                              </span>
                              <p>Cancel</p>
                            </div>
                          </div>
                        </span>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isOpenDetailsModal && (
        <ItemsInfoModal
          setIsOpenDetailsModal={setIsOpenDetailsModal}
          itemDetails={itemDetails}
        />
      )}
    </>
  );
};

export default OrderHistoryTable;
