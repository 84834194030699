import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import OrderApprovals from "../../pages/InventoryManagement/OrderApprovals/Order";

const OrderApprovalsRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "orderapprovals",
      element: <OrderApprovals />,
    },
  ],
};

export default OrderApprovalsRoutes;
