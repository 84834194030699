import React from "react";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";

const AttendanceDetailsSkeleton = () => {
  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <div className="row w-100">
            <div className="col-lg-5">
              <div className="card h-100 loading-skeleton p-3 d-flex flex-column gap-3">
                <SkeletonConstant
                  width="w-50"
                  height="h-50p"
                  additional="mx-auto"
                />
                <div className="d-flex gap-3">
                  <SkeletonConstant width="w-20" height="h-30p" />
                  <SkeletonConstant width="w-20" height="h-30p" />
                  <SkeletonConstant width="w-20" height="h-30p" />
                  <SkeletonConstant width="w-20" height="h-30p" />
                  <SkeletonConstant width="w-20" height="h-30p" />
                </div>
                <SkeletonConstant
                  width="w-50"
                  height="h-40p"
                  additional="mx-auto"
                />
                <div className="d-flex flex-column gap-3">
                  <SkeletonConstant width="w-100" height="h-35p" />
                  <SkeletonConstant width="w-100" height="h-35p" />
                  <SkeletonConstant width="w-100" height="h-35p" />
                  <SkeletonConstant width="w-100" height="h-35p" />
                  <SkeletonConstant width="w-100" height="h-35p" />
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="h-100 loading-skeleton d-flex flex-column gap-3">
                <div className="card loading-skeleton p-3">
                  <div className="d-flex justify-content-between gap-3">
                    <SkeletonConstant width="w-30" height="h-80p" />
                    <SkeletonConstant width="w-30" height="h-80p" />
                    <SkeletonConstant width="w-30" height="h-80p" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7">
                    <div className="card loading-skeleton p-3 h-100">
                      <div className="d-flex flex-column gap-3">
                        <SkeletonConstant width="w-100" height="h-40p" />
                        <SkeletonConstant width="w-100" height="h-40p" />
                        <SkeletonConstant width="w-100" height="h-40p" />
                        <SkeletonConstant width="w-100" height="h-40p" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="card loading-skeleton p-3 h-100">
                      <div className="d-flex flex-column gap-3">
                        <SkeletonConstant width="w-100" height="h-40p" />
                        <SkeletonConstant width="w-100" height="h-40p" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceDetailsSkeleton;
