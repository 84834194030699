import React from "react";
import SuccessPopup from "../SuccessPopup";

const ConfirmRemoveDevices = ({ setShowConfirm }) => {
  const handleConfirm = (e) => {
    e.preventDefault();
    setShowConfirm(false);
  };

  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: "45%" }}
        >
          <div className="modal-content p-3">
            <div className="modal-body pb-0">
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <form onSubmit={handleConfirm}>
                <div className="row gx-3 px-3 p-3">
                  <span className="d-flex justify-content-center mt-4 text-dark">
                    Are you sure you want to remove window computer device
                  </span>
                </div>
                <div className="d-flex justify-content-center gap-3 mt-5 pb-8">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-20"
                    onClick={() => {
                      setShowConfirm(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary w-20"
                    data-bs-dismiss="modal"
                    data-bs-toggle="modal"
                    data-bs-target="#device_success"
                  >
                    Yes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SuccessPopup modalId={"device_success"} text="1 device was remove successfully" />
    </>
  );
};

export default ConfirmRemoveDevices;
