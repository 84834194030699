import React from "react";

const RatingCard = ({ rating }) => {

  const starColors = [
    "#ff4545",
    "#ffa534",
    "#ffe234",
    "#b7dd29",
    "#57e32c"
  ];

  // const getStarColor = (index) => {
  //   const defaultColor = "#cccccc";
  //   return index < rating ? starColors[index] : defaultColor;
  // };
  const getStarColor = (index) => {
    const defaultColor = "#cccccc";
    const fullStars = Math.floor(rating);
    const hasHalfStar = index === fullStars && rating % 1 !== 0;

    if (index < fullStars) {
      return starColors[index];
    } else if (hasHalfStar) {
      return defaultColor
    } else {
      return defaultColor;
    }
  };

  return (
    <div className="card card-border">
      <div className="card-body d-flex flex-column gap-3 align-items-center text-dark">
        <p className="fs-5 fw-semibold">User Satisfaction rating</p>
        <p className="fw-bold fs-2">{rating > 0   ? rating?.toFixed(1) : 0} / 5</p>
        <div className="d-flex gap-1">
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="51"
            viewBox="0 0 54 51"
            fill="none"
          >
            <path
              d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
              fill="#ff4545"
              stroke="#ff4545"
              stroke-width="2"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="51"
            viewBox="0 0 54 51"
            fill="none"
          >
            <path
              d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
              fill="#ffa534"
              stroke="#ffa534"
              stroke-width="2"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="51"
            viewBox="0 0 54 51"
            fill="none"
          >
            <path
              d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
              fill="#ffe234"
              stroke="#ffe234"
              stroke-width="2"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="51"
            viewBox="0 0 54 51"
            fill="none"
          >
            <path
              d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
              fill="#b7dd29"
              stroke="##b7dd29"
              stroke-width="2"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="54"
            height="51"
            viewBox="0 0 54 51"
            fill="none"
          >
            <path
              d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
              fill="#57e32c"
              stroke="#57e32c"
              stroke-width="2"
            />
          </svg> */}
          {[...Array(5)].map((_, index) => (
            <svg
              key={index}
              xmlns="http://www.w3.org/2000/svg"
              width="54"
              height="51"
              viewBox="0 0 54 51"
              fill="none"
            >
              <path
                d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
                fill={getStarColor(index)}
                stroke={getStarColor(index)}
                strokeWidth="2"
              />
            </svg>
          ))}
        </div>
        <p>
          This rating is based on the average number of grievances that were
          closed against the average number of grievances recieved
        </p>
      </div>
    </div>
  );
};

export default RatingCard;
