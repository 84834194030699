import React from "react";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";

const SalaryDetailsSkeleton = () => {
  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="d-flex flex-column gap-3 px-3">
          <div className="d-flex gap-3">
            <div className="card loading-skeleton p-3 w-100">
              <SkeletonConstant
                width="w-50"
                height="h-45p"
                additional="mx-auto"
              />
            </div>
            <div className="card loading-skeleton p-3 w-100">
              <SkeletonConstant
                width="w-50"
                height="h-45p"
                additional="mx-auto"
              />
            </div>
          </div>
          <div className="card loading-skeleton p-3 gap-3">
            <SkeletonConstant width="w-200p" height="h-40p" />
            <div className="d-flex justify-content-between gap-3">
              <SkeletonConstant width="w-280p" height="h-30p" />
              <SkeletonConstant width="w-200p" height="h-30p" />
            </div>
            <div className="d-flex justify-content-between gap-3">
              <SkeletonConstant width="w-280p" height="h-30p" />
              <SkeletonConstant width="w-200p" height="h-30p" />
            </div>
            <div className="d-flex justify-content-between gap-3">
              <SkeletonConstant width="w-280p" height="h-30p" />
              <SkeletonConstant width="w-200p" height="h-30p" />
            </div>
            <div className="d-flex justify-content-between gap-3">
              <SkeletonConstant width="w-280p" height="h-30p" />
              <SkeletonConstant width="w-200p" height="h-30p" />
            </div>
            <div className="d-flex justify-content-between gap-3">
              <SkeletonConstant width="w-280p" height="h-30p" />
              <SkeletonConstant width="w-200p" height="h-30p" />
            </div>
          </div>
          <div className="card loading-skeleton p-3 w-50 mx-auto">
            <SkeletonConstant
              width="w-90"
              height="h-35p"
              additional="mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="d-flex flex-column gap-3 px-3">
          <div className="card loading-skeleton p-3">
            <div className="d-flex justify-content-between gap-3 px-3">
              <SkeletonConstant width="w-170p" height="h-40p" />
              <SkeletonConstant width="w-170p" height="h-40p" />
            </div>
          </div>
          <div className="card loading-skeleton gap-3 p-3">
            <SkeletonConstant width="w-200p" height="h-30p" />
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between gap-3">
                <SkeletonConstant width="w-220p" height="h-30p" />
                <SkeletonConstant width="w-200p" height="h-30p" />
              </div>
              <div className="d-flex justify-content-between gap-3">
                <SkeletonConstant width="w-220p" height="h-30p" />
                <SkeletonConstant width="w-200p" height="h-30p" />
              </div>
              <div className="d-flex justify-content-between gap-3">
                <SkeletonConstant width="w-220p" height="h-30p" />
                <SkeletonConstant width="w-200p" height="h-30p" />
              </div>
              <div className="d-flex justify-content-between gap-3">
                <SkeletonConstant width="w-220p" height="h-30p" />
                <SkeletonConstant width="w-200p" height="h-30p" />
              </div>
              <div className="d-flex justify-content-between gap-3">
                <SkeletonConstant width="w-220p" height="h-30p" />
                <SkeletonConstant width="w-200p" height="h-30p" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryDetailsSkeleton;
