import React, { useEffect, useState } from "react";
import OrderDetailsTable from "./OrderDetailsTable";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { OrderRelatedEndPoints } from "../../../../api/Endpoints";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import { useNavigate, useParams } from "react-router-dom";
import { navigateToInventoryCustomer } from "../../../../Navigation/Navigation";

const CustomerOrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [allOrder, setAllOrders] = useState([]);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetOrderDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        buyer: id,
        isApproved:"approved"
      },
    };
    const res = await ApiCall("post", OrderRelatedEndPoints.getOrders, data);
    if (res?.success) {
      setAllOrders(res?.orders?.data);
      setcount(res?.orders?.totalCounts);
      settotalPages(res?.orders?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetOrderDetails();
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <span className="todoapp-title link-dark">
                <button
                  className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
                  onClick={() => navigate(navigateToInventoryCustomer())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    class="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                  <p className="mb-0">Back</p>
                </button>
                <div className="v-separator d-lg-inline-block"></div>
                <h1 className="header-title-font">Customer Order Details</h1>
              </span>
            </div>
            <div className="todo-options-wrap">
              <div className="v-separator d-lg-inline-block d-none"></div>
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="dsdsd"
                data-bs-original-title="Refresh"
              >
                <span className="btn-icon-wrap" onClick={handleGetOrderDetails}>
                  <span className="feather-icon">
                    <i
                      className="ri-refresh-line"
                      title="Refresh business list"
                    ></i>
                  </span>
                </span>
              </span>
            </div>
          </header>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allOrder?.length > 0 && (
                <OrderDetailsTable
                  allOrder={allOrder}
                  page={page}
                  pageLimit={pageLimit}
                  id={id}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allOrder?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allOrder?.length}
                  align={"end"}
                />
              )}
              {!loader && allOrder?.length === 0 && (
                <NoData title="No Order added" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerOrderDetails;
