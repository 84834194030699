import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Vendors from "../../pages/Vendors/Vendors";
import VendorDetails from "../../pages/Vendors/VendorDetails/VendorDetails";
import VendorReport from "../../pages/Vendors/VendorReport/VendorReport";
import Analytics from "../../pages/Vendors/Analytics/Analytics";

const VendorRoutes = {
  path: "/vendor",
  element: <Navbar />,
  children: [
    {
      path: "allvendors",
      element: <Vendors />,
    },
    {
      path: "report",
      element: <VendorReport />,
    },
    {
      path: "allvendors/vendordetails/:id",
      element: <VendorDetails />,
    },
    {
      path: "allvendors/analytics",
      element: <Analytics />,
    },
  ],
};

export default VendorRoutes;
