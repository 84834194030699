import React, { useEffect, useState } from "react";
import NoticeHeader from "./NoticeHeader";
import AddNotice from "./AddNotice";
import { CustomTab } from "../../../../components/CustomTab/CustomTab";
import NoticeCard from "./NoticeCard";
import { useLocation, useNavigate } from "react-router-dom";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { noticeRelatedEndPoints } from "../../../../api/Endpoints";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

const Notice = () => {
  const { allModuleAccess, user } = useSelector((state) => state);

  const navigate = useNavigate();
  const [status, setStatus] = useState("published");
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [loader, setloader] = useState(false);
  const [allNotice, setAllNotice] = useState([]);
  const [noticeCount, setNoticeCount] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [moduleType, setmoduleType] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessNotice = allModuleAccess?.[PRIVATEROUTES.NOTICE];

  const handleTabChange = (status) => {
    setStatus(status);
  };

  const handleSearchSet = (e) => {
    setSearchKey(e.target.value);
    setPage(1);
  };

  const allTabs = [
    {
      name: "Published",
      value: "published",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-info">
          {noticeCount?.publishCounts}
        </span>
      ),
    },
    {
      name: "Draft",
      value: "unpublished",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-warning">
          {noticeCount?.unpublishCounts}
        </span>
      ),
    },
    {
      name: "Delete",
      value: "",
      count: (
        <span className="badge badge-pill badge-sm badge-soft-danger">
          {noticeCount?.deleteCounts}
        </span>
      ),
    },
  ];

  const handleGetAllNotices = async () => {
    startApiCall(null, setloader);
    const sendData = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        isDeleted: status === "" ? true : false,
        publishStatus: status,
        searchKey: searchKey,
        employeeId: user?._id,
        moduleId: location?.state ? location?.state?.moduleId : "",
        noticeType: location?.state ? location?.state?.moduleType : moduleType,
      },
    };
    const res = await ApiCall(
      "post",
      noticeRelatedEndPoints.getAllNotices,
      sendData
    );
    const res2 = await ApiCall("post", noticeRelatedEndPoints.getNoticeCount, {
      businessId: getCurrentWorkspaceId(),
      employeeId: user?._id,
      moduleId: location?.state ? location?.state?.moduleId : "",
      noticeType: location?.state ? location?.state?.moduleType : moduleType,
    });
    if (res?.success) {
      setNoticeCount(res2?.notice);
      setAllNotice(res?.notice?.data);
      setcount(res?.notice?.totalCounts);
      settotalPages(res?.notice?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllNotices();
      closeAllModals();
    }
  }, [searchKey, status, page, pageLimit, moduleType]);

  useEffect(() => {
    setlocationData(location?.state);
    setmoduleType(location?.state ? location?.state?.moduleType : "");
  }, []);

  const handleChangeType = (type) => {
    setlocationData(null);
    navigate(location.pathname, { state: null });
    setmoduleType(type);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <NoticeHeader
            handleGetAllNotices={handleGetAllNotices}
            searchKey={searchKey}
            handleSearchSet={handleSearchSet}
            locationData={locationData}
            handleChangeType={handleChangeType}
            moduleType={moduleType}
            navigate={navigate}
            isModuleReadWriteAccessNotice={isModuleReadWriteAccessNotice}
          />

          {/* *******CUSTOM TAB******** */}
          <CustomTab
            allTabs={allTabs}
            currentTab={status}
            handleTabChange={handleTabChange}
            isShowCount={true}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view mb-8">
                {!loader && allNotice?.length > 0 && (
                  <NoticeCard
                    allNotice={allNotice}
                    locationData={locationData}
                    page={page}
                    pageLimit={pageLimit}
                  />
                )}
                {!loader && allNotice?.length > 0 && (
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allNotice?.length}
                    align={"end"}
                  />
                )}
                {loader && <DataLoader title="data is loading..." />}
                {!loader && allNotice?.length === 0 && (
                  <NoData title="No Notice Found" />
                )}
              </div>
            </div>
          </div>
        </div>
        <AddNotice
          locationData={locationData}
          modalId={"add_new_notice"}
          handleGetAllNotices={handleGetAllNotices}
        />
      </div>
    </div>
  );
};

export default Notice;
