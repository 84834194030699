import React from "react";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import {
  getName,
  getTimeFormatter,
} from "../../../../global-functions/globalFunctions";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navigateToShiftDetails } from "../../../../Navigation/Navigation";

function StaffAllShiftTable(props) {
  const {
    approvedStaff,
    page,
    pageLimit,
    // isModuleReadWriteAccessForOnboarding,
  } = props;
  const { user } = useSelector((state) => state);
  const navigate = useNavigate();

  return (
    <>
      <table id="datable_1" className="table nowrap w-100 mb-5">
        <thead style={{ textAlign: "center" }}>
          <tr>
            <th>No.</th>
            <th style={{ textAlign: "left" }}>Employee</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Shift</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody style={{ textAlign: "center" }}>
          {approvedStaff?.map((data, index) => {
            return (
              <tr key={index}>
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td className="text-capitalize" style={{ textAlign: "left" }}>
                  <span
                    className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                    style={{ width: "200px" }}
                  >
                    <CustomTooltip
                      text={
                        data?.employeeId
                          ? data?.employeeId?.name
                          : "This user is not signup yet"
                      }
                      placement="right"
                    >
                      <Avatar
                        name={data?.employeeId ? data?.employeeId?.name : "I"}
                        count={1}
                        image={data?.employeeId?.image}
                        size="xss"
                        color="primary"
                      />
                    </CustomTooltip>
                    &nbsp;{" "}
                    {data?.employeeId
                      ? getName(data?.employeeId, user?._id)
                      : "Invited User"}
                  </span>
                </td>
                <td>{data?.employeeId?.email}</td>
                <td>
                  {data?.employeeId?.phone ? data?.employeeId?.phone : "-"}
                </td>
                <td>
                  {getTimeFormatter(
                    new Date(data?.shiftTimings?.[0]?.shiftId?.startTime)
                  )}{" "}
                  -{" "}
                  {getTimeFormatter(
                    new Date(data?.shiftTimings?.[0]?.shiftId?.endTime)
                  )}
                </td>
                <td>
                  <div
                    className="text-primary pointer"
                    onClick={() => {
                      navigate(
                        navigateToShiftDetails({
                          id: data?._id,
                        }),
                        {
                          state: {
                            name: data?.employeeId?.name,
                            image: data?.employeeId?.image,
                          },
                        }
                      );
                    }}
                  >
                    View Shift
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default StaffAllShiftTable;
