import React, { useEffect, useState } from "react";
import ComplaintsRateInfoCard from "./ComplaintsRateInfoCard";
import LineChart from "./LineChart";
import RatingCard from "./RatingCard";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  RatingRelatedEndPoints,
  complaintEndPoints,
} from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import { skeletonsCountCard } from "../../../components/Constant/Skeleton";

const ComplaintsRate = () => {
  const [loader, setloader] = useState(false);
  const currentYear = new Date().getFullYear().toString();
  const [complaintCount, setcomplaintCount] = useState([]);
  const [totalComplaint, setTotalComplaint] = useState([]);
  const [rating, setRating] = useState(0);

  const handleGetComplaintCounts = async () => {
    startApiCall(null, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall("post", complaintEndPoints.complaintCounts, data);
    if (res?.success) {
      setcomplaintCount(res?.complaint);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetMonthWiseComplaintCounts = async () => {
    startApiCall(null, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      year: currentYear,
    };
    const res = await ApiCall(
      "post",
      complaintEndPoints.monthWiseComplaintCounts,
      data
    );
    if (res?.success) {
      setTotalComplaint(res?.complaint);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleGetComplaintRating = async () => {
    startApiCall(null, setloader);
    const res = await ApiCall("get", RatingRelatedEndPoints.getAverageRating, {
      params: { businessId: getCurrentWorkspaceId() },
    });
    if (res?.success) {
      setRating(res?.average);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetComplaintCounts();
    handleGetMonthWiseComplaintCounts();
    handleGetComplaintRating();
  }, []);

  const handleRefresh = () => {
    handleGetComplaintCounts();
    handleGetMonthWiseComplaintCounts();
    handleGetComplaintRating();
  };

  const maxComplaintMonth = totalComplaint?.reduce((maxMonth, currentMonth) => {
    return currentMonth.total > maxMonth.total ? currentMonth : maxMonth;
  }, totalComplaint[0]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <span className="todoapp-title link-dark">
              <h1>Grievance Rate</h1>
            </span>
            <div className="todo-options-wrap">
              <div className="v-separator d-lg-inline-block d-none"></div>
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="dsdsd"
                data-bs-original-title="Refresh"
              >
                <span className="btn-icon-wrap" onClick={handleRefresh}>
                  <span className="feather-icon">
                    <i
                      className="ri-refresh-line"
                      title="Refresh business list"
                    ></i>
                  </span>
                </span>
              </span>
            </div>
          </header>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                {!loader && (
                  <>
                    <div
                      className="d-flex align-items-center gap-3 simple-scrollbar"
                      style={{ overflowX: "scroll" }}
                    >
                      <ComplaintsRateInfoCard complaintCount={complaintCount} />
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="card card-border">
                          <div className="card-body text-center d-flex flex-column gap-2 py-2">
                            <LineChart totalComplaint={totalComplaint} />
                            <p>
                              <span className="fw-semibold text-dark">
                                Details:&nbsp;
                              </span>
                              This graph shows the amount of grievances you
                              received each year. You received maximum
                              grievances in the month of{" "}
                              <span className="text-dark">
                                {maxComplaintMonth?.month}, i.e.
                              </span>{" "}
                              <span className="text-dark">
                                {maxComplaintMonth?.total}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <RatingCard rating={rating} />
                      </div>
                    </div>
                  </>
                )}
                {loader && (
                  <div className="d-flex flex-column ">
                    <div className="d-flex w-100 gap-3">
                      {skeletonsCountCard(6)}
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="card loading-skeleton p-3 gap-3">
                          <SkeletonConstant width="w-100" height="h-280p" />
                          <SkeletonConstant width="w-100" height="h-30p" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="card loading-skeleton p-3 gap-3">
                          <SkeletonConstant
                            width="w-80"
                            height="h-30p"
                            additional="mx-auto"
                          />
                          <SkeletonConstant
                            width="w-100p"
                            height="h-40p"
                            additional="mx-auto"
                          />
                          <SkeletonConstant
                            width="w-80"
                            height="h-60p"
                            additional="mx-auto"
                          />
                          <SkeletonConstant width="w-100" height="h-70p" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplaintsRate;
