import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import AnalyticsNewUI from "../../pages/CRM/CrmAnalytics/CrmAnalyticsNewUI/AnalyticsNewUI";

const CrmAnalyticsRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "analytics",
      element: <AnalyticsNewUI />,
    },
  ],
};

export default CrmAnalyticsRoutes;
