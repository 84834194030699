import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Customer from "../../pages/InventoryManagement/Customer/Customer";
import CustomerOrderDetails from "../../pages/InventoryManagement/Customer/CustomerOrderDetails/CustomerOrderDetails";
import InvoiceDetails from "../../pages/InventoryManagement/AllOrders/InvoiceDetails/InvoiceDetails";

const InventoryCustomerRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "customer",
      element: <Customer />,
    },
    {
      path: "customer/customerorderdetails/:id",
      element: <CustomerOrderDetails />,
    },
    {
      path: "customer/invoicedetails/:id",
      element: <InvoiceDetails />,
    },
  ],
};

export default InventoryCustomerRoutes;
