import React from "react";
import {
  dateFormatter,
  getName,
} from "../../../../global-functions/globalFunctions";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const InvoiceDetailsBody = ({
  invoiceDetails,
  invoiceSettingDetails,
  allTransaction,
}) => {
  const user = useSelector((state) => state.user);

  const calculatePercentageAmount = (charge) => {
    let subTotal = invoiceDetails?.orderId?.subTotal || 0;
    if (charge?.chargeId?.chargeType === "percentage") {
      const percentageValue = charge?.amount || 0;
      return (subTotal * percentageValue) / 100;
    }
    return 0;
  };

  const getCustomizationItem = (productId) => {
    const tempItem = invoiceDetails?.orderId?.customization?.filter(
      (inventory) => inventory?.productId === productId
    );
    return tempItem;
  };

  const calculateCustomizeTotal = (productId) => {
    const total = getCustomizationItem(productId).reduce((accumulator, dt) => {
      const value = dt.price;
      return accumulator + parseInt(value);
    }, 0);
    return parseInt(total);
  };

  return (
    <div className="d-flex gap-3">
      <div
        className="col-sm-12 simple-scrollbar"
        style={{ maxHeight: "75vh", minHeight: "75vh", overflow: "auto" }}
      >
        <div className="card p-4 px-6 d-flex flex-column gap-2 border-ligth rounded-0">
          <div className="d-flex justify-content-between">
            <div className="col-sm-6">
              {invoiceSettingDetails?.logo && (
                <img
                  src={invoiceSettingDetails?.logo}
                  alt="sociohoodlogo"
                  className="mw-175p mh-125p"
                />
              )}
              <div className="text-capitalize">
                {invoiceSettingDetails?.businessName}
              </div>
              {invoiceSettingDetails?.businessEmail && (
                <div>Email : {invoiceSettingDetails?.businessEmail}</div>
              )}
              {invoiceSettingDetails?.businessPhone && (
                <div className="text-capitalize">
                  Phone : {invoiceSettingDetails?.businessPhone}
                </div>
              )}
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <div className="d-block">
                <div className="text-primary fw-semibold fs-4 mt-4 ">
                  INVOICE
                </div>
                <div className="text-primary">
                  # {invoiceDetails?.invoiceNumber}
                </div>
                <div>GST : {invoiceSettingDetails?.businessGSTNumber}</div>
                <div>
                  Date Created : {dateFormatter(invoiceDetails?.createdAt)}
                </div>
                {invoiceDetails?.orderId?.actionBy && (
                  <div>
                    Created By :{" "}
                    <Avatar
                      name={
                        invoiceDetails?.orderId?.actionBy
                          ? invoiceDetails?.orderId?.actionBy?.name
                          : invoiceDetails?.orderId?.createdBy?.name
                      }
                      count={1}
                      image={
                        invoiceDetails?.orderId?.actionBy
                          ? invoiceDetails?.orderId?.actionBy?.image
                          : invoiceDetails?.orderId?.createdBy?.image
                      }
                      size="xxs"
                      color="primary"
                    />
                    &nbsp;
                    {invoiceDetails?.orderId?.actionBy
                      ? invoiceDetails?.orderId?.actionBy?.name
                      : invoiceDetails?.orderId?.createdBy?.name}
                  </div>
                )}
                <div className="text-capitalize">
                  Status :{" "}
                  <span
                    className={`${
                      invoiceDetails?.paymentStatus === "paid"
                        ? "text-success fw-semibold"
                        : "text-danger fw-semibold"
                    } `}
                  >
                    {invoiceDetails?.paymentStatus
                      ? invoiceDetails?.paymentStatus
                      : invoiceDetails?.orderStatus}
                  </span>
                </div>

                {invoiceDetails?.paymentType && (
                  <div className="text-capitalize">
                    Payment Mode :{" "}
                    {invoiceDetails?.paymentType
                      ? invoiceDetails?.paymentType
                      : "-"}
                  </div>
                )}

                {invoiceDetails?.referenceNumber && (
                  <div className="text-capitalize">
                    Reference Number : {invoiceDetails?.referenceNumber}
                  </div>
                )}
              </div>
            </div>
          </div>
          {invoiceDetails?.orderId?.status === "returned" && (
            <div className="d-flex justify-content-start mt-2">
              Return Reason : {invoiceDetails?.orderId?.reason}
            </div>
          )}
          <div className="d-flex justify-content-center mt-2">
            <div
              className="alert alert-info text-dark fs-7 w-100 fw-bold"
              role="alert"
            >
              <div className="p-2 d-block gap-8">
                <div className="d-flex">
                  Customer Name :{" "}
                  {invoiceDetails?.orderId?.buyer
                    ? invoiceDetails?.orderId?.buyer?.name
                    : "Guest"}
                </div>
                {invoiceDetails?.orderId?.buyer && (
                  <div className="d-flex mt-2">
                    Customer Phone :{" "}
                    {invoiceDetails?.orderId?.buyer
                      ? invoiceDetails?.orderId?.buyer?.phone
                      : "-"}
                  </div>
                )}
                {invoiceDetails?.orderId?.tableId?.tableName && (
                  <div className="d-flex mt-2">
                    Table :{" "}
                    {invoiceDetails?.orderId?.tableId?.tableName
                      ? invoiceDetails?.orderId?.tableId?.tableName
                      : "-"}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div style={{ maxHeight: "50vh", overflow: "auto" }}>
            <table
              id="datable_1"
              className="table nowrap w-100 mb-3 table-bordered"
            >
              <thead>
                <tr className="text-center bg-primary">
                  <th className="text-white">No</th>
                  <th className="text-white">Item Name</th>
                  <th className="text-white">Code</th>
                  <th className="text-white">Quantity</th>
                  <th className="text-white">Rate</th>
                  <th className="text-white">Total</th>
                </tr>
              </thead>
              <tbody>
                {invoiceDetails?.orderId?.products?.map((data, index) => {
                  return (
                    <>
                      <tr className="text-center">
                        <td>{index + 1} .</td>
                        <td>{data?.productId?.productName}</td>
                        <td>{data?.productId?.productCode}</td>
                        <td>{data?.quantity}</td>
                        <td>₹ {data?.productId?.sellingPrice}</td>
                        <td>
                          ₹ {data?.productId?.sellingPrice * data?.quantity}
                        </td>
                      </tr>

                      {getCustomizationItem(data?.productId?._id).map(
                        (material) => {
                          return (
                            <tr>
                              <td
                                colSpan="6"
                                className="text-end justify-content-end"
                              >
                                <div className="d-flex align-items-center justify-content-end gap-6 me-7">
                                  <div className="d-flex">
                                    <p className="mb-1 ms-2 text-capitalize text-truncate mw-125p fs-7">
                                      {material?.quantity +
                                        " " +
                                        material?.unit?.name}
                                      . {material?.inventoryItem?.name}
                                    </p>
                                  </div>

                                  <div className="d-flex align-items-center justify-content-end gap-2">
                                    <p className="mb-0 fs-7">
                                      + &nbsp; ₹ {material?.price}
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}

                      {getCustomizationItem(data?.productId?._id)?.length >
                        0 && (
                        <tr className="odd bg-grey-light-5 mh-100p text-dark ">
                          <td></td>
                          <td colSpan="4" className="text-end fw-bold">
                            Total{" "}
                            <span className="font-2">
                              (Including additional price)
                            </span>
                          </td>

                          <td className="text-center fw-bold">
                            ₹{" "}
                            {parseInt(
                              data?.productId?.sellingPrice * data?.quantity
                            ) + calculateCustomizeTotal(data?.productId?._id)}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end">
            <div className="text-dark fw-bold w-90 text-end">
              Subtotal : &nbsp;
            </div>
            <div className="text-dark fw-bold w-10">
              ₹ {invoiceDetails?.orderId?.subTotal?.toFixed(2)}
            </div>
          </div>

          {invoiceDetails?.orderId?.charges?.map((chrg) => (
            <div className="d-flex justify-content-end ">
              <div className="text-dark text-capitalize fw-bold w-90 text-end">
                {chrg?.chargeId?.chargeName}
                {chrg?.chargeId?.chargeType === "percentage" && (
                  <span className="ms-1 fs-8 mb-1">({chrg?.amount}%)</span>
                )}
                : &nbsp;
              </div>
              <div className="text-dark fw-bold w-10">
                ₹{" "}
                {chrg?.chargeId?.chargeType === "amount"
                  ? chrg?.amount?.toFixed(2)
                  : calculatePercentageAmount(chrg).toFixed(2)}{" "}
              </div>
            </div>
          ))}

          <div className="d-flex justify-content-end">
            <div className="text-primary fw-bold w-90 text-end">
              Total : &nbsp;
            </div>
            <div className="text-primary fw-bold w-10">
              ₹ {invoiceDetails?.orderId?.amount?.toFixed(2)}
            </div>
          </div>
          {invoiceDetails?.receivedAmount &&
            invoiceDetails?.receivedAmount !==
              invoiceDetails?.orderId?.amount && (
              <div className="d-flex justify-content-end">
                <div className="text-dark fw-bold w-90 text-end">
                  Received Amount : &nbsp;
                </div>
                <div className="text-dark fw-bold w-10">
                  ₹ {invoiceDetails?.receivedAmount?.toFixed(2)}
                </div>
              </div>
            )}

       {allTransaction?.length >0 &&
       <>
       <span className="d-flex justify-content-center">
            <h5>Order Transactions</h5>
          </span>
          {allTransaction?.length > 0 && (
            <div className="d-flex justify-content-center">
              <div className="contact-list-view mh-400p w-600p overflow-auto">
                <table
                  id="datable_1"
                  className="table table-bordered nowrap  mb-3 text-center"
                >
                  <thead>
                    <tr>
                      <th>Sl No.</th>
                      <th>Amount</th>
                      <th> Payment Mode</th>
                    </tr>
                  </thead>

                  <tbody>
                    {allTransaction?.map((data, index) => (
                      <tr class="mb-00">
                        <td>{index + 1} .</td>
                        <td class="text-left mb-00">
                          ₹ {data?.amountReceived}
                        </td>
                        <td class="text-right mb-00 text-capitalize">
                          {data?.paymentMode}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          </>}
          {/* {allTransaction?.length === 0 && (
            <span className="d-flex justify-content-center text-dark">
              No Transaction Added
            </span>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetailsBody;
