import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import InventoryAnalytics from "../../pages/InventoryManagement/InventoryAnalytics/InventoryAnalytics";

const InventoryAnalyticsRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "inventoryanalytics",
      element: <InventoryAnalytics />,
    },
  ],
};

export default InventoryAnalyticsRoutes;
