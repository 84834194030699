import React, { useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import { getTimeAgo } from "../../../global-functions/globalFunctions";

const ReplyModal = ({ setShowReply, comments }) => {
  const [replyInput, setReplyInput] = useState(false);


  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        // style={{ maxWidth: width }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-4">
              <span>
                Replies&nbsp;
                <span className="badge badge-sm badge-blue">
                  {comments?.replies?.length}
                </span>
              </span>
            </div>
            {/* {errorMessage && <ErrorMessage errorMessage={errorMessage} />} */}
            <div
              className="d-flex flex-column gap-3 mh-400p simple-scrollbar"
              style={{ overflow: "auto" }}
            >
              {comments?.replies?.map((rp) => {
                return (
                  <div className="border d-flex flex-column p-2 rounded-4 gap-1 bg-light">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-1">
                        <span className="cm-name d-flex align-items-center gap-1 fw-medium mb-1">
                          <Avatar
                            name={rp?.commentator?.name}
                            count={1}
                            image={rp?.commentator?.image}
                            size="xxs"
                            color="primary"
                          />
                          {rp?.commentator?.name}
                          <span className="font-2 ms-3">
                          {getTimeAgo(rp?.time)}
                          </span>
                        </span>
                      </div>
                    </div>

                    <span className="ms-1 text-dark">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-chat-left-text"
                        viewBox="0 0 16 16"
                      >
                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                      </svg>
                      &nbsp;
                      {rp?.comment}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="modal-footer align-items-center">
            <button
              onClick={() => {
                setShowReply(false);
              }}
              className="btn btn-sm btn-primary"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplyModal;
{
  /* <div className="d-flex align-items-center justify-content-between gap-3">
<span
  className="text-dark pointer"
  onClick={() => setReplyInput(true)}
>
  Reply
</span>
{replyInput && (
  <div className="d-flex align-items-center gap-1">
    <input className="form-control py-0 px-2" type="text" />
    <button
      className="btn btn-xs btn-light"
      onClick={() => setReplyInput(false)}
    >
      Cancel
    </button>
    <button
      className="btn btn-xs btn-primary"
      onClick={() => setReplyInput(false)}
    >
      Send
    </button>
  </div>
)}
</div> */
}
