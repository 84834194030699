import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  getAvatarClass,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../api/ApiCall";
import { taskEndpoint } from "../../api/Endpoints";

const KanbanView = (props) => {
  const {
    selectedSpace,
    id,
    selectedView,
    taskData,
    settaskData,
    setselectedTask,
    settaskDetailsModal,
  } = props;
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);

  const handleGetUniversalTasks = async () => {
    startApiCall(null, setloader);
    const sendData = {
      pageNumber: 1,
      pageLimit: 50,
      filters: {
        list: selectedView === "Board View" ? id : "",
      },
    };
    const res = await ApiCall("post", taskEndpoint.allTaskStatusWise, sendData);
    if (res?.success) {
      settaskData(res?.tasks);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetUniversalTasks();
  }, [selectedSpace, selectedView]);

  const handleEditTask = async (statusId, taskId) => {
    const sendData = {
      taskId: taskId,
      status: statusId,
      userId: user?._id,
    };
    const res = await ApiCall("post", taskEndpoint.editTask, sendData);
    if (res?.success) {
      handleGetUniversalTasks();
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceColumnIndex = result.source.droppableId;
    const destinationColumnIndex = result.destination.droppableId;

    // If the task is dropped in the same column
    if (sourceColumnIndex === destinationColumnIndex) {
      const newTasks = [...taskData[sourceColumnIndex].tasks.data];
      const [reorderedTask] = newTasks.splice(result.source.index, 1);
      newTasks.splice(result.destination.index, 0, reorderedTask);

      const newColumns = [...taskData];
      newColumns[sourceColumnIndex].tasks.data = newTasks;
      settaskData(newColumns);
    } else {
      const sourceTasks = [...taskData[sourceColumnIndex].tasks.data];
      const destinationTasks = [...taskData[destinationColumnIndex].tasks.data];
      const [movedTask] = sourceTasks.splice(result.source.index, 1);
      destinationTasks.splice(result.destination.index, 0, movedTask);
      const newColumns = [...taskData];
      newColumns[sourceColumnIndex].tasks.data = sourceTasks;
      newColumns[destinationColumnIndex].tasks.data = destinationTasks;
      settaskData(newColumns);
      // Call the edit api when dragging to different column
      const destColumn = newColumns[destinationColumnIndex];
      handleEditTask(destColumn?.status?._id, movedTask?._id);
    }
  };

  const openDetailsPage = (task) => {
    setselectedTask(task);
    settaskDetailsModal(true);
  };

  return (
    <div
      className="w-100 d-flex gap-4 align-items-start simplebar-hide-scrollbar px-4 py-2 simplebar-side-scrollbar"
      style={{ overflow: "auto" }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        {taskData?.map((column, columnIndex) => {
          return (
            <div
              className={`custom-shadow-top-${
                column?.status?.statusName === "open"
                  ? "primary"
                  : column?.status?.statusName === "done"
                  ? "success"
                  : column?.status?.statusName === "reopen"
                  ? "blue"
                  : column?.status?.statusName === "closed"
                  ? "danger"
                  : "warning"
              } p-0 d-flex flex-column gap-3 align-items-center justify-content-center w-250p mnw-250p`}
              key={columnIndex}
            >
              <div className="shadow px-4 py-2 rounded d-flex align-items-center gap-2 w-100">
                <p>{column?.status?.statusName?.toUpperCase()}</p>
                <span className="badge badge-pill badge-sm badge-soft-primary">
                  {column?.tasks?.totalCounts}
                </span>
              </div>
              <Droppable droppableId={String(columnIndex)}>
                {(provided, snapshot) => {
                  return (
                    <div
                      className="simplebar-hide-scrollbar py-2 simplebar-hide-scrollbar w-100 shadow-hover-sm rounded"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? "rgba(242, 242, 242, 0.25)"
                          : "",
                        width: 250,
                        overflow: "auto",
                        height: "70vh",
                      }}
                    >
                      <div
                        className="h-100 custom-scrollbar w-100 p-0"
                        style={{ overflow: "auto" }}
                      >
                        {column?.tasks?.data?.map((item, index) => {
                          return (
                            <Draggable
                              key={item?._id}
                              draggableId={item?._id}
                              index={index}
                              taskId={item?._id}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <>
                                    <div
                                      onClick={() => {
                                        openDetailsPage(item);
                                      }}
                                      className="card p-2 mb-2 text-dark gap-1"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        color: "white",
                                        height: "11vh",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <div className="font-3 text-truncate">
                                        {item?.name}
                                      </div>
                                      <div className="d-flex justify-content-between mt-2">
                                        <div className="d-flex align-items-center gap-1">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            className="bi bi-flag-fill me-2"
                                            fill={
                                              item?.priority === "LOW"
                                                ? "#0D6EFD"
                                                : item?.priority === "MEDIUM"
                                                ? "#FFC400"
                                                : item?.priority ===
                                                  "NO_PRIORITY"
                                                ? "#505050"
                                                : "#FF0101"
                                            }
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                                          </svg>
                                        </div>
                                        <div className="avatar-group avatar-group-sm avatar-group-overlapped d-xl-flex d-none me-2">
                                          {item?.assignedTo
                                            ?.slice(0, 2)
                                            ?.map((assine) => {
                                              const nameInitial =
                                                assine?.name?.[0].toUpperCase();
                                              const avatarClass =
                                                getAvatarClass(nameInitial);
                                              return (
                                                <div
                                                  key={assine?._id}
                                                  className={`avatar avatar-xxs ${avatarClass} avatar-rounded font-1`}
                                                  style={{
                                                    width: "1.7rem",
                                                    height: "1.7rem",
                                                  }}
                                                >
                                                  <span className="initial-wrap">
                                                    {nameInitial}
                                                  </span>
                                                </div>
                                              );
                                            })}
                                          {item?.assignedTo?.length > 2 && (
                                            <div
                                              className="avatar avatar-xs avatar-soft-indigo avatar-rounded"
                                              style={{
                                                width: "1.7rem",
                                                height: "1.7rem",
                                              }}
                                            >
                                              <span className="initial-wrap">
                                                + {item?.assignedTo?.length - 2}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                      </div>
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
};

export default KanbanView;
