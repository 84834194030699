import React from "react";
import { getAvatarClass } from "../../global-functions/globalFunctions";

export const Avatar = ({ name, count, size, color, image }) => {
  const nameInitial = name?.[0]?.toUpperCase();
  const avatarClass = getAvatarClass(nameInitial);

  return (
    <div className={`avatar ${avatarClass} avatar-${size} avatar-rounded`}>
      <span className="initial-wrap" style={{ textTransform: "capitalize" }}>
        {image ? (
          <img
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "50%",
              objectFit: "cover",
              backgroundSize:"cover"
            }}
            src={image}
            alt="Sociohood technologies"
          />
        ) : (
          nameInitial
        )}
      </span>
    </div>
  );
};
