import React, { useEffect, useState } from "react";
import RawMaterialHeader from "./RawMaterialHeader";
import RawMaterialTable from "./RawMaterialTable";
import AddRawMaterial from "./AddRawMaterial";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import {
  InventoryRelatedEndPoints,
  VendorRelatedEndPoints,
} from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import StockChangeModal from "./StockChangeModal";
import AddVendor from "../../Vendors/AddVendor/AddVendor";

const RawMaterial = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [search, setSearch] = useState("");
  const [loader, setloader] = useState(false);
  const [selectCategory, setSelectCategory] = useState(
    localStorage.getItem("rawMaterialCategory")
      ? localStorage.getItem("rawMaterialCategory")
      : ""
  );
  const [allMaterial, setAllMaterial] = useState([]);
  const [editMaterial, setEditMaterial] = useState("");
  const [isChangeStockModal, setisChangeStockModal] = useState("");
  const [selectedItem, setselectedItem] = useState(null);

  // Vendors
  const [allVendors, setAllVendors] = useState([]);
  const [vendorLoader, setvendorLoader] = useState(false);
  const [editVendor, setEditVendor] = useState("");
  const [stockType, setstockType] = useState(
    localStorage.getItem("rawMaterialStockType")
      ? localStorage.getItem("rawMaterialStockType")
      : ""
  );

  // ****** PAGINATION ******
  const [page, setPage] = useState(
    parseInt(localStorage.getItem("rawMaterialPage"))
      ? parseInt(localStorage.getItem("rawMaterialPage"))
      : 1
  );
  const [pageLimit, setpageLimit] = useState(
    parseInt(localStorage.getItem("rawMaterialPageLimit"))
      ? parseInt(localStorage.getItem("rawMaterialPageLimit"))
      : 50
  );
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForRawMaterial =
    allModuleAccess?.[PRIVATEROUTES.RAW_MATERIALS];

  const handleRefresh = () => {
    if (stockType === "") {
      handleGetAllMaterial();
    } else {
      handleGetAllLowStock();
    }
  };

  // GET RAWMATERIAL FUNCTION API CALL------
  const handleGetAllMaterial = async () => {
    startApiCall(null, setloader);

    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: localStorage.getItem("searchKey")
          ? localStorage.getItem("searchKey")
          : "",
        category: selectCategory,
      },
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getInventory,
      data
    );
    if (res?.success) {
      setAllMaterial(res?.inventory?.data);
      setcount(res?.inventory?.totalCounts);
      settotalPages(res?.inventory?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // GET RAWMATERIAL FUNCTION API CALL
  const handleGetAllLowStock = async () => {
    startApiCall(null, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      InventoryRelatedEndPoints.getLowStock,
      data
    );
    if (res?.success) {
      setAllMaterial(res?.stocks);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (stockType === "") {
      handleGetAllMaterial();
    } else {
      handleGetAllLowStock();
    }
    closeAllModals();
  }, [stockType, page, pageLimit, search, selectCategory]);

  // useEffect(() => {
  //   if (getCurrentWorkspaceId()) {
  //     handleGetAllMaterial();
  //   }
  // }, [page, pageLimit, search, selectCategory]);

  const handleGetAllVendor = async () => {
    startApiCall(null, setvendorLoader);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.getVendor, data);
    if (res?.success) {
      setAllVendors(res?.vendor?.data);
      setvendorLoader(false);
    } else {
      setvendorLoader(false);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <RawMaterialHeader
            search={search}
            setSearch={setSearch}
            setPage={setPage}
            setSelectCategory={setSelectCategory}
            isModuleReadWriteAccessForRawMaterial={
              isModuleReadWriteAccessForRawMaterial
            }
            handleRefresh={handleRefresh}
            setstockType={setstockType}
            setpageLimit={setpageLimit}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allMaterial?.length > 0 && (
                <RawMaterialTable
                  page={page}
                  pageLimit={pageLimit}
                  allMaterial={allMaterial}
                  setEditMaterial={setEditMaterial}
                  isModuleReadWriteAccessForRawMaterial={
                    isModuleReadWriteAccessForRawMaterial
                  }
                  setisChangeStockModal={setisChangeStockModal}
                  setselectedItem={setselectedItem}
                  stockType={stockType}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allMaterial?.length > 0 && stockType === "" && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allMaterial?.length}
                  align={"end"}
                />
              )}
              {!loader && allMaterial?.length === 0 && (
                <NoData title="No Raw materials added" />
              )}
            </div>
          </div>
        </div>
        <StockChangeModal
          setisChangeStockModal={setisChangeStockModal}
          isChangeStockModal={isChangeStockModal}
          selectedItem={selectedItem}
          handleGetAllVendor={handleGetAllVendor}
          allVendors={allVendors}
          vendorLoader={vendorLoader}
          handleGetAllMaterial={handleGetAllMaterial}
        />
        <AddRawMaterial
          editMaterial={editMaterial}
          setEditMaterial={setEditMaterial}
          handleGetAllMaterial={handleGetAllMaterial}
          handleGetAllVendor={handleGetAllVendor}
          allVendors={allVendors}
          vendorLoader={vendorLoader}
        />
        <AddVendor
          handleGetAllVendor={handleGetAllVendor}
          setEditVendor={setEditVendor}
          editVendor={editVendor}
        />
      </div>
    </div>
  );
};

export default RawMaterial;
