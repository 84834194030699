import React, { useEffect, useState } from "react";
import InventoryAnalyticsHeader from "./InventoryAnalyticsHeader";
import SalesStatisticsGraph from "./SalesStatisticsGraph";
import CostStatisticsGraph from "./CostStatisticsGraph";
import OverviewInfoCards from "./OverviewInfoCards";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  monthNames,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { InventoryAnalyticsRelatedEndPoints } from "../../../api/Endpoints";
import CustomDateFilterStock from "../ManageStock/CustomDateFilterStock";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";
import MonthlyStatisticsGraph from "./MonthlyStatisticsGraph";

const InventoryAnalytics = () => {
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [loader4, setloader4] = useState(false);
  const [loader5, setloader5] = useState(false);
  const [monthlyLoader, setmonthlyLoader] = useState(false);
  const [salesOverview, setSalesOverview] = useState([]);
  const [stockOverview, setStockOverview] = useState([]);
  const [customerOverview, setCustomerOverview] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [costsData, setCostsData] = useState([]);
  const [topSellingItem, setTopSellingItem] = useState([]);

  const [filterType, setFilterType] = useState("monthly");
  const [salesGraphFilter, setSalesGraphFilter] = useState("This Week");
  const filterData = [{ name: "This Week" }, { name: "Last Week" }];
  const [dateType, setdateType] = useState("Today");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dateModal, setdateModal] = useState(false);
  const [monthlyStatistics, setmonthlyStatistics] = useState([]);

  const currentMonth = monthNames[new Date().getMonth()];
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const year = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const lastDayOfMonth = new Date(year, thisMonth + 1, 0);
  const formattedStartDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-01`;
  const formattedEndDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;

  // ******* DATE *********
  const currentDate = new Date();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(currentDate.getDate() - 1);

  const dayOfWeek = currentDate.getDay();
  const weekStartDate = new Date(currentDate);
  weekStartDate.setDate(currentDate.getDate() - dayOfWeek);

  const weekEndDate = new Date(currentDate);
  weekEndDate.setDate(currentDate.getDate() + (6 - dayOfWeek));

  const lastWeekStartDate = new Date(currentDate);
  lastWeekStartDate.setDate(currentDate.getDate() - (dayOfWeek + 7));

  const lastWeekEndDate = new Date(currentDate);
  lastWeekEndDate.setDate(currentDate.getDate() - dayOfWeek - 1);

  const formattedweekStartDate = weekStartDate.toISOString().split("T")[0];
  const formattedlastweekStartDate = lastWeekStartDate
    .toISOString()
    .split("T")[0];
  const formattedweekEndDate = weekEndDate.toISOString().split("T")[0];
  const formattedlastweekEndDate = lastWeekEndDate.toISOString().split("T")[0];

  const handleResetFilters = () => {
    setstartDate("");
    setendDate("");
    setdateModal(false);
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);

    const selectedMonthIndex = monthNames.indexOf(selectedMonth);
    if (selectedMonthIndex !== -1) {
      const month = selectedMonthIndex + 1;
      const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay
        .toString()
        .padStart(2, "0")}`;

      setstartDate(startDate);
      setendDate(endDate);
    }
  };

  // Get Sales Overview Function
  const handleGetSalesOverview = async () => {
    startApiCall(null, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? startDate
          : "",
      endDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? endDate
          : "",
    };
    const res = await ApiCall(
      "post",
      InventoryAnalyticsRelatedEndPoints.getSalesOverview,
      data
    );
    if (res?.success) {
      setSalesOverview(res?.data);
      setdateModal(false);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // Get Stock Overview Function
  const handleGetStockOverview = async () => {
    startApiCall(null, setloader2);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? startDate
          : "",
      endDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? endDate
          : "",
    };
    const res = await ApiCall(
      "post",
      InventoryAnalyticsRelatedEndPoints.getStockOverview,
      data
    );
    if (res?.success) {
      setStockOverview(res?.data);
      setdateModal(false);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  // Get Customer Overview Function
  const handleGetCustomerOverview = async () => {
    startApiCall(null, setloader3);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? startDate
          : "",
      endDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? endDate
          : "",
    };
    const res = await ApiCall(
      "post",
      InventoryAnalyticsRelatedEndPoints.getCustomerOverview,
      data
    );
    if (res?.success) {
      setCustomerOverview(res?.data);
      setdateModal(false);
      setloader3(false);
    } else {
      setloader3(false);
    }
  };

  // Get Top Selling Item Function
  const handleGetTopSellingItem = async () => {
    startApiCall(null, setloader4);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? startDate
          : "",
      endDate:
        dateType === "Today"
          ? reversesDateFormatter(currentDate)
          : dateType === "yesterday"
          ? reversesDateFormatter(yesterdayDate)
          : dateType === "Date Range"
          ? endDate
          : "",
    };
    const res = await ApiCall(
      "post",
      InventoryAnalyticsRelatedEndPoints.getTopSellingItem,
      data
    );
    if (res?.success) {
      setTopSellingItem(res?.data);
      setloader4(false);
      setdateModal(false);
    }
  };

  // Get Sales Statistics Function
  const handleGetSalesStatistics = async () => {
    startApiCall(null, setloader5);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        salesGraphFilter === "This Week"
          ? formattedweekStartDate
          : formattedlastweekStartDate,
      endDate:
        salesGraphFilter === "This Week"
          ? formattedweekEndDate
          : formattedlastweekEndDate,
    };
    const res = await ApiCall(
      "post",
      InventoryAnalyticsRelatedEndPoints.getSalesStatistics,
      data
    );
    if (res?.success) {
      setSalesData(res?.data);
      setCostsData(res?.data);
      setloader5(false);
      setdateModal(false);
    } else {
      setloader5(false);
    }
  };

  // Get Monthly Cost and Sale Statistics Function
  const handleGetMonthlyStatistics = async () => {
    startApiCall(null, setmonthlyLoader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate: startDate ? startDate : formattedStartDate,
      endDate: endDate ? endDate : formattedEndDate,
    };
    const res = await ApiCall(
      "post",
      InventoryAnalyticsRelatedEndPoints.getSalesStatistics,
      data
    );
    if (res?.success) {
      setmonthlyStatistics(res?.data);
      setmonthlyLoader(false);
    } else {
      setmonthlyLoader(false);
    }
  };

  useEffect(() => {
    handleGetSalesStatistics();
  }, [salesGraphFilter]);

  useEffect(() => {
    handleGetMonthlyStatistics();
  }, [selectedMonth]);

  const handleRefresh = () => {
    handleGetSalesOverview();
    handleGetStockOverview();
    handleGetCustomerOverview();
    handleGetTopSellingItem();
    handleGetSalesStatistics();
    handleGetMonthlyStatistics();
  };
  const handleDateRangeUpdate = () => {
    handleGetSalesOverview();
    handleGetStockOverview();
    handleGetCustomerOverview();
    handleGetTopSellingItem();
  };

  useEffect(() => {
    if (dateType !== "Date Range") {
      handleDateRangeUpdate();
    } else if (dateType === "Date Range" && startDate && endDate) {
      handleDateRangeUpdate();
    }
    closeAllModals();
  }, [dateType]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <InventoryAnalyticsHeader handleRefresh={handleRefresh} />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <div className="d-flex flex-column gap-3">
                  <OverviewInfoCards
                    salesOverview={salesOverview}
                    stockOverview={stockOverview}
                    customerOverview={customerOverview}
                    loader={loader}
                    loader2={loader2}
                    loader3={loader3}
                    loader4={loader4}
                    topSellingItem={topSellingItem}
                    dateType={dateType}
                    currentDate={currentDate}
                    yesterdayDate={yesterdayDate}
                    startDate={startDate}
                    endDate={endDate}
                    setdateType={setdateType}
                    setdateModal={setdateModal}
                    handleResetFilters={handleResetFilters}
                  />
                  {filterType === "monthly" && (
                    <div className="card rounded-10">
                      <div className="card-header">
                        <p className="fs-5 text-dark fs-semibold">
                          Monthly Statistics
                        </p>
                        {!monthlyLoader && (
                          <div className="d-flex gap-2">
                            <select
                              className="form-select form-select-sm w-110p"
                              value={filterType}
                              onChange={(e) => setFilterType(e.target.value)}
                            >
                              <option value="monthly">Monthly</option>
                              <option value="weekly">Weekly</option>
                            </select>
                            <select
                              className="form-select form-select-sm w-110p"
                              value={selectedMonth}
                              onChange={handleMonthChange}
                            >
                              {monthNames?.map((mon) => {
                                return <option>{mon}</option>;
                              })}
                            </select>
                          </div>
                        )}
                        {monthlyLoader && (
                          <div className="d-flex gap-2 w-240p border rounded p-2 loading-skeleton">
                            <SkeletonConstant width="w-100" height="h-25p" />
                            <SkeletonConstant width="w-100" height="h-25p" />
                          </div>
                        )}
                      </div>
                      {!monthlyLoader && (
                        <div className="card-body">
                          <div style={{ height: "40vh" }}>
                            <MonthlyStatisticsGraph
                              monthlyStatistics={monthlyStatistics}
                              year={year}
                              selectedMonth={selectedMonth}
                              monthNames={monthNames}
                            />
                          </div>
                        </div>
                      )}
                      {monthlyLoader && (
                        <div className="card-body">
                          <SkeletonConstant width="w-100" height="h-320p" />
                        </div>
                      )}
                    </div>
                  )}
                  {filterType === "weekly" && (
                    <div className="card rounded-10">
                      <div className="card-header">
                        <p className="fs-5 text-dark fs-semibold">
                          Weekly Statistics
                        </p>
                        {!loader5 && (
                          <div className="d-flex gap-2">
                            <select
                              className="form-select form-select-sm w-110p"
                              value={filterType}
                              onChange={(e) => setFilterType(e.target.value)}
                            >
                              <option value="monthly">Monthly</option>
                              <option value="weekly">Weekly</option>
                            </select>
                            <select
                              className="form-select form-select-sm w-110p"
                              value={salesGraphFilter}
                              onChange={(e) =>
                                setSalesGraphFilter(e.target.value)
                              }
                            >
                              {filterData?.map((data) => {
                                return (
                                  <option value={data?.name}>
                                    {data?.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                        {loader5 && (
                          <div className="d-flex gap-2 w-240p border rounded p-2 loading-skeleton">
                            <SkeletonConstant width="w-100" height="h-25p" />
                            <SkeletonConstant width="w-100" height="h-25p" />
                          </div>
                        )}
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-6 border-end">
                            {!loader5 && (
                              <>
                                <div className="d-flex align-items-center justify-content-between gap-3">
                                  <p className="text-dark fw-semibold fs-6">
                                    Sales statistics
                                  </p>
                                </div>
                                <div style={{ height: "40vh" }}>
                                  <SalesStatisticsGraph salesData={salesData} />
                                </div>
                              </>
                            )}
                            {loader5 && (
                              <div className="card rounded-10 loading-skeleton p-3">
                                <div className="d-flex flex-column gap-3">
                                  <div className="d-flex justify-content-between">
                                    <SkeletonConstant
                                      width="w-120p"
                                      height="h-35p"
                                    />
                                  </div>
                                  <SkeletonConstant
                                    width="w-100"
                                    height="h-220p"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6">
                            {!loader5 && (
                              <>
                                <div className="d-flex align-items-center justify-content-between gap-3">
                                  <p className="text-dark fw-semibold fs-6">
                                    Cost statistics
                                  </p>
                                </div>
                                <div style={{ height: "40vh" }}>
                                  <CostStatisticsGraph costsData={costsData} />
                                </div>
                              </>
                            )}
                            {loader5 && (
                              <div className="card rounded-10 loading-skeleton p-3">
                                <div className="d-flex flex-column gap-3">
                                  <div className="d-flex justify-content-between">
                                    <SkeletonConstant
                                      width="w-120p"
                                      height="h-35p"
                                    />
                                  </div>
                                  <SkeletonConstant
                                    width="w-100"
                                    height="h-220p"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomDateFilterStock
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader}
          handleResetFilters={handleResetFilters}
          dateModal={dateModal}
          handleGetAllStock={handleDateRangeUpdate}
          dateType={dateType}
          setdateType={setdateType}
          setdateModal={setdateModal}
        />
      </div>
    </div>
  );
};

export default InventoryAnalytics;
