import React, { useEffect, useState } from "react";
import NoData from "../../../components/NoData/NoData";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import CashInHandHeader from "./CashInHandHeader";
import CashInHandTable from "./CashInHandTable";
import AddCashModal from "./AddCashModal";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import ApiCall from "../../../api/ApiCall";
import {
  closeAllModals,
  convertAmountLocal,
  getCurrentWorkspaceId,
  getWorkSpaceRole,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import {
  CashInOutRelatedEndPoints,
  VendorRelatedEndPoints,
} from "../../../api/Endpoints";
import MoreDetails from "./MoreDetails";
import moment from "moment";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import { toast } from "react-toastify";

function CashInHand() {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const startDateDefault = moment(new Date())
    .subtract(15, "days")
    .format("YYYY-MM-DD");
  const currentDate = moment(new Date()).format("YYYY-MM-DD");
  const [transactions, settransactions] = useState(null);
  const [search, setSearch] = useState("");
  const [cashModal, setcashModal] = useState(false);
  const [transactionsAmount, settransactionsAmount] = useState(0);
  const [typeFilter, settypeFilter] = useState("");
  const [isMoreDetails, setisMoreDetails] = useState(false);
  const [selectedTransaction, setselectedTransaction] = useState(null);
  const [startDate, setstartDate] = useState(currentDate);
  const [endDate, setendDate] = useState(currentDate);
  const [totalData, settotalData] = useState(null);
  const [editCash, setEditCash] = useState(null);
  const [isDelete, setisDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const currentWorkSpaceRole = getWorkSpaceRole(user?.permissions, "roleName");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForCashInHand =
    allModuleAccess?.[PRIVATEROUTES.CASHINHAND];

  const handleClose = () => {
    setisDelete(false);
    setErrorMessage("");
    setloader2(false);
  };

  const handleResetFilters = (type, isReset) => {
    setstartDate(currentDate);
    setendDate(currentDate);
    handleGetCashCounterTransaction();
  };

  // GET AMOUNT  API CALL
  const handleGetCashCounterAmount = async () => {
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate,
      endDate,
    };
    const res = await ApiCall(
      "post",
      CashInOutRelatedEndPoints.getCashCounterAmount,
      data
    );
    if (res?.success) {
      settransactionsAmount(res?.cash?.cashCount);
      settotalData(res?.data?.[0]);
    } else {
      //NOTHING HAPPEND
    }
  };

  // GET ALL Transaction  API CALL
  const handleGetCashCounterTransaction = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        accountHeader: typeFilter,
        paymentMode: "cash",
        businessId: getCurrentWorkspaceId(),
        startDate,
        endDate,
      },
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorTransaction,
      data
    );
    if (res?.success) {
      handleGetCashCounterAmount();
      settransactions(res?.transactions?.data);
      setcount(res?.transactions?.totalCounts);
      settotalPages(res?.transactions?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  useEffect(() => {
    handleGetCashCounterTransaction();
    closeAllModals();
  }, [page, typeFilter, pageLimit]);

  const handleDeleteTransaction = async () => {
    startApiCall(setErrorMessage, setloader2);
    const data = {
      mainTransactionId: editCash?._id,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      CashInOutRelatedEndPoints.deleteTransaction,
      data
    );
    if (res?.success) {
      toast.success("Transaction Deleted Successfully!");
      handleGetCashCounterTransaction();
      handleGetCashCounterAmount();
      handleClose();
    } else {
      setErrorMessage(res.error);
      setloader2(false);
    }
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <CashInHandHeader
            search={search}
            setSearch={setSearch}
            setcashModal={setcashModal}
            handleGetCashCounterTransaction={handleGetCashCounterTransaction}
            transactionsAmount={transactionsAmount}
            settypeFilter={settypeFilter}
            typeFilter={typeFilter}
            startDate={startDate}
            setstartDate={setstartDate}
            endDate={endDate}
            setendDate={setendDate}
            currentDate={currentDate}
            handleResetFilters={handleResetFilters}
            loader={loader}
            isModuleReadWriteAccessForCashInHand={
              isModuleReadWriteAccessForCashInHand
            }
            currentWorkSpaceRole={currentWorkSpaceRole}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="d-flex gap-3">
                <div className="card py-3 px-4 text-center rounded-4 count-box">
                  <p className="text-dark fs-6 fw-medium">Total IN</p>
                  <p style={{ color: "green" }}>
                    {convertAmountLocal(totalData?.totalCashIn || 0)}
                  </p>
                </div>
                <div className="card py-3 px-4 text-center rounded-4 count-box">
                  <p className="text-dark fs-6 fw-medium">Total OUT</p>
                  <p className="text-danger">
                    {convertAmountLocal(totalData?.totalCashOut || 0)}
                  </p>
                </div>
              </div>
              {!loader && transactions?.length > 0 && (
                <CashInHandTable
                  transactions={transactions}
                  page={page}
                  pageLimit={pageLimit}
                  setisMoreDetails={setisMoreDetails}
                  setselectedTransaction={setselectedTransaction}
                  setcashModal={setcashModal}
                  setEditCash={setEditCash}
                  setisDelete={setisDelete}
                  currentWorkSpaceRole={currentWorkSpaceRole}
                  isModuleReadWriteAccessForCashInHand={
                    isModuleReadWriteAccessForCashInHand
                  }
                />
              )}

              {loader && <DataLoader title="data is loading..." />}
              {!loader && transactions?.length === 0 && (
                <NoData title="No Transactions found" />
              )}
              {!loader && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={transactions?.length}
                  align={"end"}
                />
              )}
            </div>
          </div>
        </div>
        {cashModal && (
          <AddCashModal
            handleGetCashCounterTransaction={handleGetCashCounterTransaction}
            setcashModal={setcashModal}
            currentDate={currentDate}
            editCash={editCash}
            setEditCash={setEditCash}
          />
        )}
        {isMoreDetails && (
          <MoreDetails
            setisMoreDetails={setisMoreDetails}
            selectedTransaction={selectedTransaction}
          />
        )}
        <ConfirmModal
          isOpen={isDelete}
          onClose={handleClose}
          text="Are you sure you want to delete this Transaction ?"
          loader={loader2}
          errorMessage={errorMessage}
          handleConfirm={handleDeleteTransaction}
        />
      </div>
    </div>
  );
}

export default CashInHand;
