import React, { useEffect, useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  dateFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { taskEndpoint } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";

const EmployeeActivityModal = ({
  userData,
  setactivityModal,
  activityModal,
  startDate,
  endDate,
}) => {
  const [selectedTab, setSelectedTab] = useState("All");
  const today = new Date().toISOString().split("T")[0];
  const [currentDate, setCurrentDate] = useState(today);

  //Task
  const [loading, setloading] = useState(false);
  const [taskData, settaskData] = useState([]);
  const [selectedTaskPriority, setselectedTaskPriority] = useState({
    name: "All Priority",
    color: "#c8c8c8",
    value: "",
  });

  const [selectedTaskStatus, setselectedTaskStatus] = useState({
    statusName: "All Status",
    color: "#c8c8c8",
  });

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleGetTask = async (e) => {
    startApiCall(null, setloading);
    const sendData = {
      page: page,
      limit: pageLimit,
      employeeId: userData?.employeeId,
      filters: {
        businessId: getCurrentWorkspaceId(),
        taskType: "maintask",
        searchKey: "",
        startDate: startDate,
        endDate: endDate,
        dueDate: "",
        priority: selectedTaskPriority?.value,
        assignedTo: userData?.employeeId,
        status:
          selectedTaskStatus?.statusName === "All Status"
            ? ""
            : selectedTaskStatus?._id,
        status_type: selectedTab === "All" ? "" : selectedTab,
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      settaskData(res?.task?.data);
      setcount(res?.task?.totalCounts);
      settotalPages(res?.task?.totalPages);
      setloading(false);
    } else {
      setloading(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && activityModal) {
      handleGetTask();
    }
  }, [selectedTab, page, pageLimit]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "825px" }}
      >
        <div className="modal-content">
          <div className="modal-body p-4">
            <div className="d-flex flex-column gap-3">
              <div className="d-flex align-items-start gap-3">
                <div className="d-flex align-items-center justify-content-between gap-3 w-100">
                  <div className="d-flex align-items-center gap-2 flex-wrap">
                    <Avatar
                      name={userData?.employeeName}
                      count={1}
                      image={userData?.employeeImage}
                      size="sm"
                      color="primary"
                    />
                    <div className="d-flex flex-column gap-1">
                      <p
                        className="mb-0 h5 fw-semibold"
                        id="name_dropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {userData?.employeeName}
                      </p>

                      <div className="d-flex flex-wrap gap-6 text-dark">
                        <p className="d-flex align-items-center gap-1 fs-7 ms-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#FFC207"
                            class="bi bi-star-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                          {userData?.permissions?.[0]?.roleName}
                        </p>
                        {/* <p className="d-flex align-items-center gap-1 position-relative fs-7">
                          <span
                            className="text-secondary position-absolute text-nowrap fs-8"
                            style={{
                              top: "-10px",
                              left: "54px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            Email address
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#AC00FD"
                            className="bi bi-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" />
                          </svg>
                          dimpleverma2804@gmail.com
                        </p> */}
                        {/* <p className="d-flex align-items-center gap-1 position-relative fs-7">
                          <span
                            className="text-secondary position-absolute text-nowrap fs-8"
                            style={{
                              top: "-10px",
                              left: "50px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            Managed by
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#AC00FD"
                            className="bi bi-circle-fill"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" />
                          </svg>
                          Sandip Kedia
                        </p> */}
                      </div>
                    </div>
                  </div>
                  {/* <span
                    className="badge px-3 fs-6 rounded-5"
                    style={{
                      background: "#AAF7D8",
                      color: "#1B802B",
                      minWidth: "82px",
                    }}
                  >
                    Online
                  </span> */}
                </div>
                <span
                  className="btn-close pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setactivityModal(false);
                  }}
                ></span>
              </div>
              <div className="d-flex justify-content-between gap-3 flex-wrap">
                <ul className="nav nav-tabs border-bottom-transparent mb-0">
                  <li className="nav-item pointer">
                    <span
                      className={`nav-link text-secondary py-0 d-flex align-items-center gap-1 ${
                        selectedTab === "All" ? "task-tab-active" : ""
                      }`}
                      onClick={() => {
                        setPage(1);
                        setSelectedTab("All");
                      }}
                    >
                      All
                    </span>
                  </li>
                  <li className="nav-item pointer">
                    <span
                      className={`nav-link text-secondary py-0 d-flex align-items-center gap-1 ${
                        selectedTab === "active" ? "task-tab-active" : ""
                      }`}
                      onClick={() => {
                        setPage(1);
                        settaskData([]);
                        setSelectedTab("active");
                      }}
                    >
                      Active
                    </span>
                  </li>
                  <li className="nav-item pointer">
                    <span
                      className={`nav-link text-secondary py-0 d-flex align-items-center gap-1 ${
                        selectedTab === "done" ? "task-tab-active" : ""
                      }`}
                      onClick={() => {
                        setPage(1);
                        settaskData([]);
                        setSelectedTab("done");
                      }}
                    >
                      Done
                    </span>
                  </li>
                  <li className="nav-item pointer">
                    <span
                      className={`nav-link text-secondary py-0 d-flex align-items-center gap-1 ${
                        selectedTab === "completed" ? "task-tab-active" : ""
                      }`}
                      onClick={() => {
                        setPage(1);
                        settaskData([]);
                        setSelectedTab("completed");
                      }}
                    >
                      Completed
                    </span>
                  </li>
                </ul>
              </div>
              {!loading && taskData?.length > 0 && (
                <div className="p-3 pt-0 d-flex flex-column gap-2 h-365p overflow-auto simple-scrollbar">
                  {taskData?.map?.((task, index) => (
                    <div className="row border-bottom pb-1 mb-5" key={index}>
                      <div className="col-md-1">
                        {" "}
                        {(page - 1) * pageLimit + (index + 1)}{" "}
                      </div>
                      <div className="col-md-7">
                        <p className="text-dark mb-2 w-100 text-truncate">
                          {task?.name}
                        </p>
                        <p className="d-flex align-items-center gap-1 mb-1 bg-light p-1 rounded-2 w-100">
                          {task?.folder && (
                            <div className="w-50 d-flex align-items-center">
                              <div className="avatar avatar-icon avatar-xxs avatar-soft-warning">
                                <span className="initial-wrap">
                                  <i
                                    className="ri-folder-2-fill"
                                    style={{ fontSize: "8px" }}
                                  ></i>
                                </span>
                              </div>
                              <span className="fs-7 ms-1 text-truncate w-100 overflow-hidden text-overflow-ellipsis white-space-nowrap">
                                {task?.folder?.name}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="13"
                                fill="currentColor"
                                className="bi bi-chevron-right"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                                />
                              </svg>
                            </div>
                          )}
                          <div className="w-50 d-flex align-items-center">
                            <div className="avatar avatar-icon avatar-soft-blue avatar-xxs">
                              <span className="initial-wrap">
                                <i
                                  className="ri-file-text-fill"
                                  style={{ fontSize: "8px" }}
                                ></i>
                              </span>
                            </div>
                            <span className="fs-7 ms-1 text-truncate w-100 overflow-hidden text-overflow-ellipsis white-space-nowrap">
                              {task?.list?.name}
                            </span>
                          </div>
                        </p>
                      </div>
                      <div className="col-md-2">
                        <p className="mb-1">Due Date</p>
                        <p className="text-dark">
                          {task?.dueDate
                            ? dateFormatter(task?.dueDate)
                            : "No Due Date"}
                        </p>
                      </div>
                      <div className="col-md-2">
                        <p className="mb-1">Status</p>
                        <p className="text-dark text-capitalize">
                          <svg
                            className="circle"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill={task?.status?.color}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="15"
                              height="15"
                              fill={task?.status?.color}
                            />
                          </svg>
                          &nbsp;
                          {task?.status?.statusName}
                        </p>
                      </div>
                    </div>
                  ))}
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={taskData?.length}
                    align={"end"}
                  />
                </div>
              )}
              {loading && (
                <div className="p-3 pt-0 d-flex flex-column gap-2 h-365p overflow-auto simple-scrollbar">
                  <DataLoader title="Your task of list is loading..." />
                </div>
              )}
              {!loading && taskData?.length === 0 && (
                <div className="p-3 pt-0 d-flex flex-column gap-2 h-365p overflow-auto simple-scrollbar">
                  {" "}
                  <NoData />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeActivityModal;
