import React from "react";

const ManagePeopleHeader = ({
  handleGetJoinBusinessRequest,
  searchKey,
  setsearchKey,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <a className="todoapp-title  link-dark">
          <h1>Manage People</h1>
        </a>
      </div>
      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span
            className="btn-icon-wrap"
            onClick={handleGetJoinBusinessRequest}
          >
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="search by email"
            value={searchKey}
            onChange={(e) => {
              setsearchKey(e.target.value);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default ManagePeopleHeader;
