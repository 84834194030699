import React from "react";
import Pagination from "../Pagination/Pagination";

function FooterPagination(props) {
  const {
    setpageLimit,
    pageLimit,
    setPage,
    page,
    totalPages,
    count,
    dataLength,
    align,
  } = props;
  return (
    <div
      className="fixed-bottom bg-white p-2 border-top "
      style={{
        position: "absolute",
      }}
    >
      <Pagination
        setpageLimit={setpageLimit}
        pageLimit={pageLimit}
        setPage={setPage}
        page={page}
        totalPages={totalPages}
        count={count}
        dataLength={dataLength}
        align={align}
      />
    </div>
  );
}

export default FooterPagination;
