import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Deals from "../../pages/CRM/Deals/Deals";
import DealOverview from "../../pages/CRM/Deals/DealDetails/DealOverview/DealOverview";

const DealsRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "alldeals",
      element: <Deals />,
    },
    {
      path: "alldeals/dealoverview/:id",
      element: <DealOverview />,
    },
  ],
};

export default DealsRoutes;
