import React, { useEffect, useState } from "react";
import ApiCall from "../../api/ApiCall";
import { taskEndpoint } from "../../api/Endpoints";
import { Avatar } from "../../components/Avatar/Avatar";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import {
  filterSearchData,
  getAllEmployees,
  getAvatarClass,
  getCurrentWorkspaceId,
  getWorkSpaceRole,
  isEmployeeOwner,
  startApiCall,
} from "../../global-functions/globalFunctions";

export const AssignEmployee = (props) => {
  const {
    user,
    listId,
    isAssigneeEmployeeModal,
    setisAssigneeEmployeeModal,
    selectedTask,
    handleGetTask,
  } = props;

  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allEmp, setallEmp] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [selectedAssigneeId, setselectedAssigneeId] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [removedEmployee, setremovedEmployee] = useState([]);

  const filteredEmployees = filterSearchData(allEmp, searchKey);

  const closeModal = () => {
    seterrorMessage("");
    setisAssigneeEmployeeModal(false);
    setselectedAssigneeId([]);
  };
  useEffect(() => {
    let temp = [];
    selectedTask?.assignedTo?.map((emp) => {
      temp.push(emp?._id);
    });
    setselectedAssigneeId(temp);
  }, [selectedTask]);

  const handleGetAllEmployee = async () => {
    startApiCall(null, setloader);
    let data = {
      pageLimit: 50000,
      pageNumber: 1,
      s2adminId: user?._id,
      searchKey: searchKey,
      businessId: getCurrentWorkspaceId(),
      accessFor: "forAssignTask",
      listId,
    };
    const res = await getAllEmployees(data);
    setallEmp(res?.data);
    setloader(false);
  };

  useEffect(() => {
    handleGetAllEmployee();
  }, []);

  // const toggleEmployeeSelection = (emp) => {
  //   if (selectedAssigneeId?.includes(emp)) {
  //     selectedAssigneeId?.splice(selectedAssigneeId?.indexOf(emp), 1);
  //     setselectedAssigneeId([...selectedAssigneeId]);
  //       removedEmployee?.push(emp);
  //   } else if (!selectedAssigneeId?.includes(emp)) {
  //     selectedAssigneeId?.push(emp);
  //     setselectedAssigneeId([...selectedAssigneeId]);
  //     if (removedEmployee?.length > 0) {
  //       removedEmployee?.splice(removedEmployee?.indexOf(emp), 1);
  //       setremovedEmployee([...removedEmployee]);
  //     }
  //   }
  // };

  const toggleEmployeeSelection = (emp) => {
    const newExistAssineEmps = selectedTask.assignedTo?.some(
      (assignedEmp) => assignedEmp?._id === emp
    );
  
    if (selectedAssigneeId?.includes(emp)) {
      const selectedAssigneeIds = selectedAssigneeId?.filter((id) => id !== emp);
      setselectedAssigneeId([...selectedAssigneeIds]);
      
      if (newExistAssineEmps) {
        const removedEmployees = removedEmployee?.filter((id) => id !== emp);
        setremovedEmployee([...removedEmployees, emp]);
      }
    } else {
      const selectedAssigneeIds = [...selectedAssigneeId, emp];
      setselectedAssigneeId([...selectedAssigneeIds]);
      
      if (removedEmployee?.includes(emp)) {
        const removedEmployees = removedEmployee?.filter((id) => id !== emp);
        setremovedEmployee([...removedEmployees]);
      }
    }
  };

  const handleAssignee = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    let tempForSelected = [];
    let tempForRemoved = [];
    selectedAssigneeId?.map((employeeId) => {
      tempForSelected.push({
        employeeId,
        isRemoved: false,
      });
    });
    removedEmployee?.map((employeeId) => {
      tempForRemoved.push({
        employeeId,
        isRemoved: true,
      });
    });

    const sendData = {
      taskId: selectedTask?._id,
      employees: tempForSelected?.concat(tempForRemoved),
      userId: user?._id,
    };
    const res = await ApiCall("post", taskEndpoint.assignTask, sendData);
    if (res?.success) {
      closeModal();
      handleGetTask();
      setloader2(false);
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };

  const getEmployeeData = () => {
    let temp = [];

    selectedAssigneeId?.map((ae) => {
      allEmp?.map((emp) => {
        if (emp?._id === ae) {
          temp.push(emp);
        }
      });
    });

    return temp;
  };
  return (
    <div
      id="join_workspace"
      className={`custom-modal modal fade add-new-contact ${
        isAssigneeEmployeeModal ? "show" : ""
      }`}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: isAssigneeEmployeeModal ? "block" : "none" }}
    >
      <form onSubmit={handleAssignee}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body p-4 d-flex flex-column overflow-hidden">
              {" "}
              <button
                disabled={loader}
                onClick={closeModal}
                type="button"
                className="btn-close"
              >
                <span aria-hidden="true">x</span>
              </button>
              <h5 className="mb-2">Assignee Employee</h5>
              <div className="dropdown-divider"></div>
              <span>Task ID : {selectedTask?.taskReferenceId}</span>
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-end align-items-center">
                  <span className="input-affix-wrapper input-search affix-border">
                    <input
                      type="text"
                      className="form-control bg-transparent"
                      data-navbar-search-close="false"
                      placeholder="Search People"
                      aria-label="Search"
                      value={searchKey}
                      onChange={(e) => {
                        setsearchKey(e.target.value);
                      }}
                    />
                    <span className="input-suffix">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              {!loader && (
                <div className="collapse-simple ">
                  <div className="card">
                    <div className="card-header">
                      <span
                        role="button"
                        data-bs-toggle="collapse"
                        href="#space_members"
                        aria-expanded="true"
                        className="fw-light text-uppercase"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-people-fill"
                            viewBox="0 2 16 16"
                          >
                            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                          </svg>{" "}
                          Assignees
                          <span class="badge badge-pill badge-sm badge-soft-primary ms-1">
                            {allEmp?.length}
                          </span>
                        </span>
                        <div className="avatar-group avatar-group-sm avatar-group-overlapped me-6 pointer">
                          {getEmployeeData()
                            ?.slice(0, 4)
                            ?.map((emp, index) => {
                              let name = emp?.name;
                              const nameInitial = name?.[0]?.toUpperCase();
                              const avatarClass = getAvatarClass(nameInitial);
                              return (
                                <div
                                  key={index}
                                  className={`avatar avatar-xs ${avatarClass} avatar-rounded`}
                                >
                                  <span className="initial-wrap">
                                    {nameInitial}
                                  </span>
                                </div>
                              );
                            })}
                          {getEmployeeData()?.length > 4 && (
                            <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                              <span className="initial-wrap">
                                +{getEmployeeData().length - 4}
                              </span>
                            </div>
                          )}
                        </div>
                      </span>
                    </div>

                    <div id="space_members" className="collapse show">
                      <div className="card-body mh-300p overflow-auto">
                        {!loader && filteredEmployees?.length > 0 && (
                          <ul className="activity-list list-group list-group-flush">
                            {filteredEmployees?.map((employee) => {
                              return (
                                <>
                                  <li
                                    className="list-group-item"
                                    key={employee.id}
                                  >
                                    <div className="media">
                                      <div className="media-head">
                                        <Avatar
                                          name={employee?.name}
                                          count={1}
                                          image={employee?.image}
                                          size="xss"
                                          color="primary"
                                        />
                                      </div>
                                      <div className="media-body row">
                                        <div className="col-md-8 d-flex justify-content-between">
                                          <span
                                            className="text-dark"
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {employee.name}
                                          </span>
                                          <span
                                            className={`badge badge-soft-primary`}
                                          >
                                            {getWorkSpaceRole(
                                              employee?.permissions,
                                              "roleName"
                                            )}
                                          </span>
                                        </div>

                                        <div className="col-md-4 d-flex justify-content-center">
                                          <input
                                            className="form-check-input success pointer"
                                            type="checkbox"
                                            checked={selectedAssigneeId?.includes(
                                              employee?._id
                                            )}
                                            onChange={() =>
                                              toggleEmployeeSelection(
                                                employee?._id
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        )}
                        {!loader && filteredEmployees?.length === 0 && (
                          <p className="text-capitalize text-center text-dark">
                            No employee found
                          </p>
                        )}

                        {loader && <DataLoader />}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {loader && <DataLoader />}
            </div>
            <div className="modal-footer align-items-center">
              <button
                type="button"
                disabled={loader}
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                disabled={loader}
                type="submit"
                className="btn btn-primary"
              >
                {loader2 ? <ApiLoader /> : "Add"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
