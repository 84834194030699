import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Salary from "../../pages/StaffManagement/StaffSalary/Salary/Salary";
import SalaryDetails from "../../pages/StaffManagement/StaffSalary/SalaryDetails/SalaryDetails";

const StaffSalaryRoutes = {
  path: "/staff",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <Salary />,
    },
    {
      path: "salary",
      element: <Salary />,
    },
    {
      path: "salary/salarydetails/:id/:monthname",
      element: <SalaryDetails />,
    },
  ],
};

export default StaffSalaryRoutes;
