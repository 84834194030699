import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Location from "../../pages/StaffManagement/Location/Location";

const StaffLocationRoutes = {
  path: "/staff",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <Location />,
    },
    {
      path: "alllocation",
      element: <Location />,
    },
  ],
};

export default StaffLocationRoutes;
