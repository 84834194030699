import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToAllTaskList } from "../../Navigation/Navigation";
import FormatDate from "../../components/FormatDate/FormatDate";
function ListGridView({
  spaceData,
  spaceId,
  spacename,
  handleOpenListInfo,
  setpermissionModalType,
  setpermissionData,
  handleCloseAllInfoModal,
  isModuleReadWriteAccessForSpace,
  id,
  foldername,
}) {
  const navigate = useNavigate();

  return (
    <>
      {spaceData?.lists?.data?.map((list, index) => (
        <div className="col" key={index}>
          <div className="card file-compact-card card-border">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div className="media fmapp-info-trigger">
                  <div
                    className="media-head me-3 pointer"
                    onClick={() => {
                      navigate(navigateToAllTaskList({ id: list?._id }), {
                        state: {
                          spacename,
                          spaceId,
                          list: list?.name,
                          foldername,
                          id,
                        },
                      });
                    }}
                  >
                    <div className="avatar avatar-icon avatar-soft-blue avatar-sm">
                      <span className="initial-wrap">
                        <i className="ri-file-text-fill"></i>
                      </span>
                    </div>
                  </div>
                  <div
                    className="media-body pointer"
                    onClick={() => {
                      navigate(navigateToAllTaskList({ id: list?._id }), {
                        state: {
                          spacename,
                          spaceId,
                          list: list?.name,
                          foldername,
                          id,
                        },
                      });
                    }}
                  >
                    <div
                      className="file-name w-130p text-dark"
                      style={{
                        textTransform: "capitalize",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {list?.name}
                    </div>
                    <div className="text-truncate fs-8 mb-2">
                      {list?.taskCount ? list?.taskCount : "No"} Task
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <span
                    className="btn btn-xs btn-icon btn-flush-dark btn-rounded flush-soft-hover flex-shrink-0"
                    href="#"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span
                      className="icon"
                      onClick={() => {
                        setpermissionModalType("list");
                      }}
                    >
                      <span className="feather-icon">
                        <i className="ri-more-2-line"></i>
                      </span>
                    </span>
                  </span>
                  <div className="dropdown-menu">
                    <span
                      className="dropdown-item"
                      onClick={() => {
                        setpermissionData(list);
                        handleOpenListInfo();
                      }}
                    >
                      <span className="feather-icon dropdown-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                      </span>
                      <span>View Details</span>
                    </span>
                    {isModuleReadWriteAccessForSpace && (
                      <span
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#share_permission"
                        onClick={() => {
                          handleCloseAllInfoModal();
                          setpermissionData(list);
                        }}
                      >
                        <span className="feather-icon dropdown-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-share"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                          </svg>
                        </span>
                        <span>Share & Permission</span>
                      </span>
                    )}

                    {foldername && (
                      <span
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#add_new_task_folderlist"
                        onClick={() => setpermissionData(list)}
                      >
                        <span className="feather-icon dropdown-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-plus-circle"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                        </span>
                        <span>Add Task </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="d-flex flex-column gap-2"
                onClick={() => {
                  navigate(navigateToAllTaskList({ id: list?._id }), {
                    state: {
                      spacename,
                      spaceId,
                      list: list?.name,
                      foldername,
                      id,
                    },
                  });
                }}
              >
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex justify-content-between">
                    <p className="text-dark">Active</p>
                    <p>{list?.taskCounts?.active}</p>
                  </div>
                  <div
                    className="progress rounded-4"
                    style={{
                      height: "10px",
                      background: "#e1e1e1",
                    }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          (list?.taskCounts?.active / list?.taskCounts?.total) *
                          100
                        }%`,
                        backgroundColor: "#99B3FF",
                      }}
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex justify-content-between">
                    <p className="text-dark">Done</p>
                    <p>{list?.taskCounts?.done}</p>
                  </div>
                  <div
                    className="progress rounded-4"
                    style={{
                      height: "10px",
                      background: "#e1e1e1",
                    }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          (list?.taskCounts?.done / list?.taskCounts?.total) *
                          100
                        }%`,
                        backgroundColor: "#99B3FF",
                      }}
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex justify-content-between">
                    <p className="text-dark">Completed</p>
                    <p>{list?.taskCounts?.completed}</p>
                  </div>
                  <div
                    className="progress rounded-4"
                    style={{
                      height: "10px",
                      background: "#e1e1e1",
                    }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${
                          (list?.taskCounts?.completed /
                            list?.taskCounts?.total) *
                          100
                        }%`,
                        backgroundColor: "#99B3FF",
                      }}
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="m-0" style={{ borderTop: "1px solid" }} />
            <div className="media-footer text-muted justify-content-between">
              <div className="avatar-group avatar-group-sm avatar-group-overlapped me-2">
                {list?.isPrivate ? (
                  <span className="badge badge-md badge-pill badge-soft-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-lock"
                      viewBox="0 0 16 16"
                      stroke-width="25"
                    >
                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                    </svg>
                    &nbsp; Private
                  </span>
                ) : (
                  <span className="badge badge-md badge-pill badge-soft-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-globe"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
                    </svg>
                    &nbsp; Public
                  </span>
                )}
              </div>
              <div className="d-flex align-items-center">
                <p className="font-2 fw-light me-1">
                  {FormatDate(list?.updatedAt)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ListGridView;
