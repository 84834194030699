import React, { useEffect, useState } from "react";
import { CustomTab } from "../../../components/CustomTab/CustomTab";
import CrmSettingsHeader from "./CrmSettingsHeader";
import CrmLeadSource from "./CrmLeadSource/CrmLeadSource";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import {
  CrmSettingsRelatedEndPoints,
  LeadRelatedEndPoints,
  businessEndpoints,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import AddLeadSource from "./CrmLeadSource/AddLeadSource";
import { toast } from "react-toastify";
import CrmLeadGenerationTarget from "./CrmLeadGenerationTarget/CrmLeadGenerationTarget";
import { useSelector } from "react-redux";
import CrmDynamicFields from "./CrmDynamicFields/CrmDynamicFields";
import CreateDynamicFields from "./CrmDynamicFields/CreateDynamicFields";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import CrmLeadVisibility from "./CrmLeadVisibility/CrmLeadVisibility";

const CrmSettings = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);
  const [loader2, setloader2] = useState(false);
  const [businessDetails, setBusinessDetails] = useState([]);
  const [dynamicFieldsModal, setdynamicFieldsModal] = useState(false);
  const [editData, seteditData] = useState("");
  const [allFields, setallFields] = useState([]);
  const [allLeadSource, setAllLeadSource] = useState([]);
  const [editLeadSource, setEditLeadSource] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  // ******DYNAMIC FIELDS PAGINATION ******
  const [fieldsCount, setfieldsCount] = useState(0);
  const [fieldsTotalPages, setfieldsTotalPages] = useState(0);

  const isModuleReadWriteAccessForSetting =
    allModuleAccess?.[PRIVATEROUTES.CRM_SETTINGS];

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
  };

  // Tab
  const [status, setStatus] = useState("leadSource");
  const handleTabChange = (status) => {
    setStatus(status);
  };
  const allTabs = [
    {
      name: "Lead / Deal Source",
      value: "leadSource",
    },
    {
      name: "Generation Target",
      value: "generationTarget",
    },
    {
      name: "Dynamic Fields",
      value: "dynamicFields",
    },
    {
      name: "Lead Visibility",
      value: "leadVisibility",
    },
  ];

  const handleRefresh = () => {
    handleGetAllLeadSource();
  };

  const handleGetDynamicFields = async () => {
    startApiCall(null, setloader3);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      CrmSettingsRelatedEndPoints.getAllFields,
      data
    );
    if (res?.success) {
      setallFields(res?.customField?.data);
      setfieldsCount(res?.customField?.totalCounts);
      setfieldsTotalPages(res?.customField?.totalPages);
      setloader3(false);
    } else {
      setloader3(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId() && status === "dynamicFields") {
      handleGetDynamicFields();
    }
  }, [status, page, pageLimit]);

  const handleGetAllLeadSource = async () => {
    startApiCall(null, setloader);
    const sendData = {
      page: 1,
      limit: 50,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.getLeadSource,
      sendData
    );
    if (res?.success) {
      setAllLeadSource(res?.result?.data);
      settotalPages(res?.result?.totalPages);
      setcount(res?.result?.totalCounts);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  useEffect(() => {
    if (getCurrentWorkspaceId() && status === "leadSource") {
      handleGetAllLeadSource();
    }
  }, [page, pageLimit]);

  const handleDeleteLeadSource = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      leadOrDealId: editLeadSource?._id,
      type: "lead",
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.removeLeadSource,
      data
    );
    if (res?.success) {
      toast.success("Lead Source deleted successfully!");
      handleGetAllLeadSource();
      handleClose();
    } else {
      setloader(false);
      setErrorMessage("");
    }
  };
  const handleGetBusinessDetails = async () => {
    startApiCall(null, setloader2);
    const data = {
      businessId: getCurrentWorkspaceId(),
      employeeId: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.getBusinessById, data);
    if (res?.success) {
      setBusinessDetails(res?.business?.businessId);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (status === "generationTarget" || status === "leadVisibility") {
      handleGetBusinessDetails();
      closeAllModals();
    }
  }, [status]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <CrmSettingsHeader
            handleRefresh={handleRefresh}
            status={status}
            handleGetBusinessDetails={handleGetBusinessDetails}
            setdynamicFieldsModal={setdynamicFieldsModal}
            handleGetDynamicFields={handleGetDynamicFields}
            isModuleReadWriteAccessForSetting={
              isModuleReadWriteAccessForSetting
            }
          />

          <CustomTab
            allTabs={allTabs}
            currentTab={status}
            handleTabChange={handleTabChange}
            isShowCount={true}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              {status === "leadSource" && (
                <>
                  {!loader && allLeadSource?.length > 0 && (
                    <CrmLeadSource
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      handleClose={handleClose}
                      allLeadSource={allLeadSource}
                      page={page}
                      pageLimit={pageLimit}
                      handleDeleteLeadSource={handleDeleteLeadSource}
                      loader={loader}
                      errorMessage={errorMessage}
                      setEditLeadSource={setEditLeadSource}
                      isModuleReadWriteAccessForSetting={
                        isModuleReadWriteAccessForSetting
                      }
                    />
                  )}
                  {!loader && allLeadSource?.length > 0 && (
                    <FooterPagination
                      setpageLimit={setpageLimit}
                      pageLimit={pageLimit}
                      setPage={setPage}
                      page={page}
                      totalPages={totalPages}
                      count={count}
                      dataLength={allLeadSource?.length}
                      align={"end"}
                    />
                  )}
                  {loader && <DataLoader title="data is loading..." />}
                  {!loader && allLeadSource?.length === 0 && (
                    <NoData title="No Lead Source Added" />
                  )}
                </>
              )}

              {status === "generationTarget" && (
                <>
                  {loader2 ? (
                    <DataLoader title="data is loading..." />
                  ) : (
                    <CrmLeadGenerationTarget
                      businessDetails={businessDetails}
                      handleGetBusinessDetails={handleGetBusinessDetails}
                      isModuleReadWriteAccessForSetting={
                        isModuleReadWriteAccessForSetting
                      }
                    />
                  )}
                </>
              )}

              {status === "dynamicFields" && (
                <>
                  <CrmDynamicFields
                    allFields={allFields}
                    setdynamicFieldsModal={setdynamicFieldsModal}
                    seteditData={seteditData}
                    totalPages={fieldsTotalPages}
                    count={fieldsCount}
                    editData={editData}
                    loader3={loader3}
                    page={page}
                    setPage={setPage}
                    pageLimit={pageLimit}
                    setpageLimit={setpageLimit}
                    handleGetDynamicFields={handleGetDynamicFields}
                    isModuleReadWriteAccessForSetting={
                      isModuleReadWriteAccessForSetting
                    }
                  />
                </>
              )}
              {status === "leadVisibility" && (
                <>
                  {loader2 ? (
                    <DataLoader title="data is loading..." />
                  ) : (
                    <CrmLeadVisibility
                      businessDetails={businessDetails}
                      handleGetBusinessDetails={handleGetBusinessDetails}
                      isModuleReadWriteAccessForSetting={
                        isModuleReadWriteAccessForSetting
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        {dynamicFieldsModal && (
          <CreateDynamicFields
            setdynamicFieldsModal={setdynamicFieldsModal}
            editData={editData}
            seteditData={seteditData}
            handleGetDynamicFields={handleGetDynamicFields}
          />
        )}
        <AddLeadSource
          handleGetAllLeadSource={handleGetAllLeadSource}
          editLeadSource={editLeadSource}
          setEditLeadSource={setEditLeadSource}
        />
      </div>
    </div>
  );
};

export default CrmSettings;
