import React, { useEffect, useState } from "react";
import ShiftDetailsHeader from "./ShiftDetailsHeader";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ShiftDetailsTable from "./ShiftDetailsTable";
import { ShiftRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import NoData from "../../../../components/NoData/NoData";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import ChangeShiftModal from "./ChangeShiftModal";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

function ShiftDetails() {
  const { id } = useParams();
  const { allModuleAccess } = useSelector((state) => state);
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [loader, setloader] = useState(false);
  const [shiftsofMonth, setshiftsofMonth] = useState([]);
  const [changeShiftModal, setchangeShiftModal] = useState(false);
  const [dayShiftData, setdayShiftData] = useState([]);

  const isModuleReadWriteAccessShift = allModuleAccess?.[PRIVATEROUTES.SHIFT];

  const startDate = new Date(currentDate.getFullYear(), currentMonth, 1) + 1;
  const endDate = new Date(currentDate.getFullYear(), currentMonth + 1, 0) + 1;

  const handlePrevMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));
    setCurrentDate(new Date(currentDate.getFullYear(), currentMonth - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => (prevMonth === 11 ? 0 : prevMonth + 1));
    setCurrentDate(new Date(currentDate.getFullYear(), currentMonth + 1, 1));
  };

  const handleGetAllShiftsofMonth = async () => {
    startApiCall(null, setloader);
    const sendData = {
      staffId: id,
      startDate: reversesDateFormatter(startDate),
      endDate: reversesDateFormatter(endDate),
    };
    const res = await ApiCall(
      "post",
      ShiftRelatedEndPoints.shiftsofMonth,
      sendData
    );
    if (res?.success) {
      setshiftsofMonth(res?.staff);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllShiftsofMonth();
    }
  }, [currentMonth]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ShiftDetailsHeader
            navigate={navigate}
            currentDate={currentDate}
            currentMonth={currentMonth}
            handlePrevMonth={handlePrevMonth}
            handleNextMonth={handleNextMonth}
            handleGetAllShiftsofMonth={handleGetAllShiftsofMonth}
            isModuleReadWriteAccessShift={isModuleReadWriteAccessShift}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="contact-list-view mb-8">
                {!loader && shiftsofMonth?.length > 0 && (
                  <ShiftDetailsTable
                    shiftsofMonth={shiftsofMonth}
                    setdayShiftData={setdayShiftData}
                    setchangeShiftModal={setchangeShiftModal}
                    isModuleReadWriteAccessShift={isModuleReadWriteAccessShift}
                  />
                )}
                {!loader && shiftsofMonth?.length == 0 && <NoData />}
                {loader && <DataLoader title="data is loading..." />}
              </div>
            </div>
          </div>
        </div>
      </div>
      {changeShiftModal && (
        <ChangeShiftModal
          dayShiftData={dayShiftData}
          handleGetAllShiftsofMonth={handleGetAllShiftsofMonth}
          setchangeShiftModal={setchangeShiftModal}
        />
      )}
    </div>
  );
}

export default ShiftDetails;
