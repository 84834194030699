import React from "react";
import LoginLeft from "./LoginLeft";
import LoginRight from "./LoginRight";
import AuthenticateFooter from "../../../components/Footers/AuthenticateFooter";


const Login = () => {
  return (
    <>
      <div className="hk-wrapper hk-pg-auth" data-footer="simple">
        <div className="hk-pg-wrapper pb-7">
          <div className="hk-pg-body p-0">
            <div className="container-xxl">
              <div className="row">
                <LoginLeft /> 
                <LoginRight />
              </div>
            </div>
          </div>
          <AuthenticateFooter />
        </div>
      </div>
    </>
  );
};

export default Login;