import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import LeaveRequest from "../../pages/StaffManagement/StaffLeave/LeaveRequest/LeaveRequest";

const LeaveRequestRoutes = {
  path: "/leave",
  element: <Navbar />,
  children: [
    {
      path: "leaverequest",
      element: <LeaveRequest />,
    },
  ],
};

export default LeaveRequestRoutes;
