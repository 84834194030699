import React, { useEffect, useState } from "react";
import AttendanceMonthly from "./AttendanceMonthly";
import AttendanceHeader from "./AttendanceHeader";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  monthNames,
  reversesDateFormatter,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { attendanceRelatedEndPoints } from "../../../../api/Endpoints";
import AttendanceDaily from "./AttendanceDaily";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import VerifyAttendanceModal from "./VerifyAttendanceModal";
import ApiCall from "../../../../api/ApiCall";
import NoData from "../../../../components/NoData/NoData";
import MarkAbsentModal from "./MarkAbsentModal";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import { useNavigate } from "react-router-dom";
import { navigateToStaffAttendanceDetails } from "../../../../Navigation/Navigation";
import AttendanceSkeleton from "./AttendanceSkeleton";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";

const Attendance = () => {
  const { allModuleAccess, user } = useSelector((state) => state);
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [page, setpage] = useState(1);
  const [staffData, setstaffData] = useState([]);
  const [attendanceView, setattendanceView] = useState("Daily");
  const [search, setsearch] = useState("");
  const [pageLimit, setpageLimit] = useState(50);
  const [totalPages, settotalPages] = useState(0);
  const [count, setcount] = useState([]);
  const [allCounts, setallCounts] = useState([]);
  const [absentModal, setabsentModal] = useState(false);
  const [lopModal, setlopModal] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const handleClose = () => {
    setlopModal(false);
    seterrorMessage("");
  };

  const isModuleReadWriteAccessForAttendance =
    allModuleAccess?.[PRIVATEROUTES.ATTENDANCE];

  /************* MONTHLY************/
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const currentMonth = monthNames[new Date().getMonth()];
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const year = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const lastDayOfMonth = new Date(year, thisMonth + 1, 0);
  const formattedStartDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-01`;
  const formattedEndDate = `${year}-${(thisMonth + 1)
    .toString()
    .padStart(2, "0")}-${lastDayOfMonth.getDate().toString().padStart(2, "0")}`;

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);

    const selectedMonthIndex = monthNames.indexOf(selectedMonth);
    if (selectedMonthIndex !== -1) {
      const month = selectedMonthIndex + 1;
      const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay
        .toString()
        .padStart(2, "0")}`;

      setstartDate(startDate);
      setendDate(endDate);
    }
  };

  /***********DAILY *********/
  const currentDate = new Date();
  const [dailyAttendanceData, setdailyAttendanceData] = useState([]);
  const [dailyDateFilter, setdailyDateFilter] = useState(
    reversesDateFormatter(currentDate)
  );
  const [individualStaffData, setindividualStaffData] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleGetAllStaffAttendance = async () => {
    startApiCall(null, setloader);
    const data = {
      filters: {
        startDate: startDate ? startDate : formattedStartDate,
        endDate: endDate ? endDate : formattedEndDate,
        searchKey: search,
      },
      page: page,
      limit: pageLimit,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.getAttendance,
      data
    );
    if (res?.success) {
      setstaffData(res?.attendance?.staffAttendance);
      setcount(res?.attendance?.totalCounts);
      settotalPages(res?.attendance?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  const handleStaffAttendancePerDay = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      businessId: getCurrentWorkspaceId(),
      date: dailyDateFilter,
      searchKey: search,
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.getAttendancePerDay,
      data
    );
    if (res?.success) {
      setdailyAttendanceData(res?.attendance?.staffAttendance);
      setcount(res?.attendance?.totalCounts);
      settotalPages(res?.attendance?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  const handleGetAttendanceCount = async () => {
    startApiCall(null, setloader2);
    const data = {
      businessId: getCurrentWorkspaceId(),
      date: dailyDateFilter,
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.attendanceCounts,
      data
    );
    if (res?.success) {
      setallCounts(res?.attendance);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const handleEditAttendance = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader3);
    const data = {
      attendanceId: individualStaffData?.attendances?._id,
      businessId: getCurrentWorkspaceId(),
      editorId: user?._id,
      isPresent: true,
      isLop: individualStaffData?.attendances?.isLop ? false : true,
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.editAttendance,
      data
    );
    if (res?.success) {
      handleStaffAttendancePerDay();
      handleClose();
      setloader3(false);
    } else {
      seterrorMessage(res.error);
      setloader3(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      if (attendanceView === "Monthly") {
        handleGetAllStaffAttendance();
      } else if (attendanceView === "Daily") {
        handleStaffAttendancePerDay();
        handleGetAttendanceCount();
      }
      closeAllModals();
    }
  }, [selectedMonth, attendanceView, search, page, pageLimit, dailyDateFilter]);

  useEffect(() => {
    if (!isModuleReadWriteAccessForAttendance) {
      navigate(navigateToStaffAttendanceDetails({ id: user?._id }));
    }
  }, [isModuleReadWriteAccessForAttendance]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <AttendanceHeader
            selectedMonth={selectedMonth}
            handleMonthChange={handleMonthChange}
            setattendanceView={setattendanceView}
            attendanceView={attendanceView}
            handleStaffAttendancePerDay={handleStaffAttendancePerDay}
            handleGetAllStaffAttendance={handleGetAllStaffAttendance}
            dailyDateFilter={dailyDateFilter}
            setdailyDateFilter={setdailyDateFilter}
            search={search}
            setsearch={setsearch}
            setpage={setpage}
            thisMonth={thisMonth}
            staffData={staffData}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="contact-list-view">
                {!loader &&
                  attendanceView === "Monthly" &&
                  staffData.length > 0 && (
                    <AttendanceMonthly
                      staffData={staffData}
                      page={page}
                      pageLimit={pageLimit}
                      selectedMonth={selectedMonth}
                    />
                  )}

                {!loader &&
                  attendanceView === "Daily" &&
                  dailyAttendanceData?.length > 0 && (
                    <AttendanceDaily
                      dailyAttendanceData={dailyAttendanceData}
                      page={page}
                      pageLimit={pageLimit}
                      allCounts={allCounts}
                      setindividualStaffData={setindividualStaffData}
                      setIsOpen={setIsOpen}
                      dailyDateFilter={dailyDateFilter}
                      setlopModal={setlopModal}
                      setabsentModal={setabsentModal}
                      isModuleReadWriteAccessForAttendance={
                        isModuleReadWriteAccessForAttendance
                      }
                    />
                  )}

                {loader && <AttendanceSkeleton />}

                {!loader &&
                  attendanceView === "Monthly" &&
                  staffData?.length === 0 && <NoData />}
                {!loader && (
                  <>
                    {attendanceView === "Daily" &&
                      !dailyAttendanceData?.length && <NoData />}
                  </>
                )}

                {!loader &&
                  ((attendanceView === "Daily" &&
                    dailyAttendanceData?.length > 0) ||
                    (attendanceView === "Monthly" &&
                      staffData?.length > 0)) && (
                    <FooterPagination
                      setpageLimit={setpageLimit}
                      pageLimit={pageLimit}
                      setPage={setpage}
                      page={page}
                      totalPages={totalPages}
                      count={count}
                      dataLength={
                        attendanceView === "Monthly"
                          ? staffData.length
                          : dailyAttendanceData.length
                      }
                      align="end"
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal
          isOpen={lopModal}
          onClose={handleClose}
          text={
            individualStaffData?.attendances?.isLop
              ? "Are you sure you want to revoke the Loss of Pay (LOP) status for this staff member?"
              : "Are You Sure You Want To Mark Loss Of Pay For This Staff ?"
          }
          loader={loader3}
          errorMessage={errorMessage}
          handleConfirm={handleEditAttendance}
        />
        {isOpen && (
          <VerifyAttendanceModal
            width="50%"
            individualStaffData={individualStaffData}
            handleStaffAttendancePerDay={handleStaffAttendancePerDay}
            handleGetAttendanceCount={handleGetAttendanceCount}
            setIsOpen={setIsOpen}
            dailyDateFilter={dailyDateFilter}
          />
        )}
        {absentModal && (
          <MarkAbsentModal
            width="40%"
            setabsentModal={setabsentModal}
            individualStaffData={individualStaffData}
            handleStaffAttendancePerDay={handleStaffAttendancePerDay}
            handleGetAttendanceCount={handleGetAttendanceCount}
            dailyDateFilter={dailyDateFilter}
          />
        )}
      </div>
    </div>
  );
};

export default Attendance;
