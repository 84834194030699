import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import CrmCalls from "../../pages/CRM/CrmCalls/CrmCalls";

const CrmCallsRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "allcalls",
      element: <CrmCalls />,
    },
  ],
};

export default CrmCallsRoutes;