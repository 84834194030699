import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import AllExpenses from "../../pages/AccountManagement/AllExpenses/AllExpenses";

const AllExpensesRoutes = {
  path: "/account",
  element: <Navbar />,
  children: [
    {
      path: "allexpenses",
      element: <AllExpenses />,
    },
  ],
};

export default AllExpensesRoutes;
