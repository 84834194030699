import React, { useState } from "react";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { toast } from "react-toastify";
import { businessEndpoints } from "../../../../api/Endpoints";
import { useSelector } from "react-redux";

function CrmLeadVisibility({
  handleGetBusinessDetails,
  businessDetails,
  isModuleReadWriteAccessForSetting,
}) {
  const { user } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [pendingVisibility, setPendingVisibility] = useState(null);

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setLoader(false);
    setPendingVisibility(null);
  };

  const handleToggleVisibility = () => {
    setPendingVisibility(!businessDetails?.leadVisibleToAll);
    setIsOpen(true);
  };

  const handleVisibilityOfField = async () => {
    startApiCall(setErrorMessage, setLoader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      leadVisibleToAll: pendingVisibility,
      updatedBy: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.editBusiness, data);
    if (res?.success) {
      handleClose();
      handleGetBusinessDetails();
      toast.success("Lead Visibility Changed successfully!");
    } else {
      setLoader(false);
      setErrorMessage(res?.error);
    }
  };

  return (
    <>
      <div className="contact-list-view">
        <div className="container py-4">
          <div className="card">
            <div className="card-body d-flex justify-content-start">
              <div className="d-flex justify-content-start gap-2">
                <span className="h-80p w-140p bg-light rounded-10 d-flex align-items-center justify-content-center position-relative">
                  <div className="form-check form-switch d-flex justify-content-center">
                    <input
                      className={`form-check-input btn ${
                        businessDetails?.leadVisibleToAll
                          ? "bg-success"
                          : "bg-danger"
                      }`}
                      type="checkbox"
                      role="switch"
                      id="SwitchCheckDefault"
                      disabled={!isModuleReadWriteAccessForSetting}
                      checked={businessDetails?.leadVisibleToAll}
                      onClick={() => handleToggleVisibility()}
                    />
                  </div>
                </span>
                <div className="d-flex justify-content-center mt-2">
                  <div
                    className="alert alert-info text-primary fs-7"
                    role="alert"
                  >
                    <strong>Note:</strong> Toggling this switch will change the
                    visibility of leads. If the switch is turned on, all leads
                    that are not assigned to any participant group will be
                    visible to everyone. If the switch is turned off, only
                    assigned leads will be visible.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text={
          pendingVisibility
            ? "Are you sure you want to show the leads that are not assigned to any participant group?"
            : "Are you sure you want to hide the leads that are not assigned to any participant group?"
        }
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleVisibilityOfField}
      />
    </>
  );
}

export default CrmLeadVisibility;
