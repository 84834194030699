import React from "react";

function EmployeeCrmCountHeader({
  allStatusGroup,
  leadStatusGroup,
  handleSelectChange,
  loaderGroup,
  initialStatuses,
  selectedInitialStatus,
  handleStatusChange,
  startDate,
  setstartDate,
  endDate,
  setendDate,
  today,
  loader,
  handleGetAllEmployeeCount,
}) {
  return (
    <header className="todo-header">
      <span className="todoapp-title link-dark">
        <h1>Employee Analytics</h1>
      </span>
      <div className="todo-options-wrap d-flex">
        <div className="me-2">
          <select
            className="form-select form-select-sm"
            name="leadStatusGroup"
            required
            value={allStatusGroup?.findIndex(
              (group) => group === leadStatusGroup || ""
            )}
            onChange={handleSelectChange}
          >
            <option value="">Choose Status Group</option>
            {loaderGroup && <option disabled>Loading Groups...</option>}
            {!loaderGroup && (
              <>
                {allStatusGroup?.map((item, index) => (
                  <option key={index} value={index}>
                    {index + 1}. {item.name}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        <div className="me-2">
          <select
            disabled={initialStatuses?.length === 0}
            className="form-select form-select-sm"
            name="leadStatusGroup"
            required
            value={selectedInitialStatus}
            onChange={(e) => {
              handleStatusChange(e);
            }}
          >
            <option value="">Choose Initial Status </option>
            {initialStatuses?.length > 0 && (
              <>
                {initialStatuses?.map((item, index) => {
                  return (
                    <option value={item?._id}>
                      {index + 1}. {item?.name}
                    </option>
                  );
                })}
              </>
            )}
          </select>
        </div>
        <div className="ms-2 d-flex">
          <input
            className="form-control w-150p"
            name="single-date-pick"
            type="date"
            value={startDate}
            onChange={(e) => {
              setstartDate(e.target.value);
            }}
            max={endDate}
          />
          <p className="mt-2 ms-2 me-2 fw-bold text-dark">to</p>
          <input
            className="form-control w-150p"
            name="single-date-pick"
            type="date"
            value={endDate}
            onChange={(e) => {
              setendDate(e.target.value);
            }}
            min={startDate}
            max={today}
          />
          <button
            disabled={loader}
            onClick={handleGetAllEmployeeCount}
            className="btn btn-primary btn-xs btn-outline-light flex-shrink-0 d-lg-inline-block   ms-2 pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              fill="#000"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </button>
        </div>
      </div>
    </header>
  );
}

export default EmployeeCrmCountHeader;
