import React, { useEffect, useState } from "react";
import OverviewAnalytics from "./OverviewTab/OverviewAnalytics";
import {
  getCurrentWorkspaceId,
  getWorkSpaceRole,
  reversesDateFormatter,
  startApiCall,
  closeAllModals,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import {
  DealRelatedEndPoints,
  LeadRelatedEndPoints,
} from "../../../../api/Endpoints";
import { useSelector } from "react-redux";
import CustomDateFilterStock from "../../../InventoryManagement/ManageStock/CustomDateFilterStock";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import AssigneeModal from "../../Assignee/AssigneeModal";
import AnalyticsHeader from "./AnalyticsHeader";

const AnalyticsNewUI = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [assigneeModal, setassigneeModal] = useState(false);
  const [selectedEmpId, setselectedEmpId] = useState([]);
  const [selectedEmpIdForApi, setSelectedEmpIdForApi] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [loader6, setLoader6] = useState(false);
  const [loader7, setLoader7] = useState(false);
  const [allEmployees, setallEmployees] = useState([]);
  const [allLeadsContacts, setAllLeadsContacts] = useState([]);
  const [activityProgress, setactivityProgress] = useState([]);
  const [leadCount, setleadCount] = useState([]);
  const [leadtoContactCount, setleadtoContactCount] = useState([]);
  const [recentLeads, setrecentLeads] = useState([]);
  const [recentContacts, setRecentContacts] = useState([]);
  const [recentDeals, setRecentDeals] = useState([]);
  const [upcomingActivity, setupcomingActivity] = useState([]);
  const [leadGenerationCount, setleadGenerationCount] = useState([]);
  const [selectEmployee, setselectEmployee] = useState("");
  const [filterType, setFilterType] = useState("thismonth");
  const [activeTab, setActiveTab] = useState("Leads this month");
  const [isBusinessWise, setIsBusinessWise] = useState(null);
  const currentWorkSpaceRole = getWorkSpaceRole(user?.permissions, "roleName");

  useEffect(() => {
    if (currentWorkSpaceRole === "owner") {
      setIsBusinessWise(true);
    } else {
      setIsBusinessWise(isBusinessWise);
    }
    closeAllModals();
  }, [currentWorkSpaceRole]);

  const isModuleReadWriteAccessForAnalytics =
    allModuleAccess?.[PRIVATEROUTES.CRM_ANALYTICS];
  // ******* DATE *********
  const currentDate = new Date();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(currentDate.getDate() - 1);

  const currentMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const currentMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const previousMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const previousMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );

  const dayOfWeek = currentDate.getDay();
  const weekStartDate = new Date(currentDate);
  weekStartDate.setDate(currentDate.getDate() - dayOfWeek);

  const weekEndDate = new Date(currentDate);
  weekEndDate.setDate(currentDate.getDate() + (6 - dayOfWeek));

  const lastWeekStartDate = new Date(currentDate);
  lastWeekStartDate.setDate(currentDate.getDate() - (dayOfWeek + 7));

  const lastWeekEndDate = new Date(currentDate);
  lastWeekEndDate.setDate(currentDate.getDate() - dayOfWeek - 1);

  const formattedweekStartDate = weekStartDate.toISOString().split("T")[0];
  const formattedlastweekStartDate = lastWeekStartDate
    .toISOString()
    .split("T")[0];
  const formattedweekEndDate = weekEndDate.toISOString().split("T")[0];
  const formattedlastweekEndDate = lastWeekEndDate.toISOString().split("T")[0];

  //Custom Date Modal
  const [dateModal, setdateModal] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState(reversesDateFormatter(currentDate));

  const resetDateRangeModal = () => {
    setdateModal(false);
  };

  // FUNCTION FOR GRAPH DATA
  const handleGetLeadsAndContacts = async () => {
    startApiCall(null, setLoader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        filterType === "thismonth"
          ? reversesDateFormatter(currentMonthStartDate)
          : filterType === "lastmonth"
          ? reversesDateFormatter(previousMonthStartDate)
          : filterType === "thisweek"
          ? formattedweekStartDate
          : filterType === "Date Range"
          ? startDate
          : formattedlastweekStartDate,
      endDate:
        filterType === "thismonth"
          ? reversesDateFormatter(currentMonthEndDate)
          : filterType === "lastmonth"
          ? reversesDateFormatter(previousMonthEndDate)
          : filterType === "thisweek"
          ? formattedweekEndDate
          : filterType === "Date Range"
          ? endDate
          : formattedlastweekEndDate,
      assignedTo: isBusinessWise
        ? ""
        : !isModuleReadWriteAccessForAnalytics
        ? [user?._id]
        : isBusinessWise
        ? ""
        : selectedEmpIdForApi?.length === 0
        ? [user?._id]
        : [selectedEmpIdForApi?._id],
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLeadCount, data);
    if (res?.success) {
      setAllLeadsContacts(res?.result);
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  // GET LEAD LOST / WON COUNT
  const handleGeLeadWonCount = async () => {
    startApiCall(null, setLoader2);
    const data = {
      filters: {
        assignedTo: isBusinessWise
          ? ""
          : !isModuleReadWriteAccessForAnalytics
          ? user?._id
          : isBusinessWise
          ? ""
          : selectedEmpIdForApi?.length === 0
          ? user?._id
          : selectedEmpIdForApi?._id,
        businessId: getCurrentWorkspaceId(),
        startDate:
          filterType === "thismonth"
            ? reversesDateFormatter(currentMonthStartDate)
            : filterType === "lastmonth"
            ? reversesDateFormatter(previousMonthStartDate)
            : filterType === "thisweek"
            ? formattedweekStartDate
            : filterType === "Date Range"
            ? startDate
            : formattedlastweekStartDate,
        endDate:
          filterType === "thismonth"
            ? reversesDateFormatter(currentMonthEndDate)
            : filterType === "lastmonth"
            ? reversesDateFormatter(previousMonthEndDate)
            : filterType === "thisweek"
            ? formattedweekEndDate
            : filterType === "Date Range"
            ? endDate
            : formattedlastweekEndDate,
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.allCounts, data);
    if (res?.success) {
      setleadCount(res?.result);
      setLoader2(false);
    } else {
      setLoader2(false);
    }
  };

  // GET LAST 5 OPEN LEADS
  const handleGetRecentLead = async () => {
    startApiCall(null, setLoader3);
    const data = {
      page: 1,
      limit: 5,
      employeeId: isBusinessWise
        ? ""
        : !isModuleReadWriteAccessForAnalytics
        ? user?._id
        : isBusinessWise
        ? ""
        : selectedEmpIdForApi?.length === 0
        ? user?._id
        : selectedEmpIdForApi?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        initialStatusName: "open",
        status: "lead",
        unassigned: false,
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.getLead, data);
    if (res?.success) {
      setrecentLeads(res?.result?.data);
      setLoader3(false);
    } else {
      setLoader3(false);
    }
  };
  // GET LAST ADDED CONTACTS
  const handleGetRecentContactsCount = async () => {
    startApiCall(null, setLoader3);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        assignedTo: isBusinessWise
          ? ""
          : !isModuleReadWriteAccessForAnalytics
          ? [user?._id]
          : isBusinessWise
          ? ""
          : selectedEmpIdForApi?.length === 0
          ? [user?._id]
          : [selectedEmpIdForApi?._id],
      },
    };
    const res = await ApiCall("post", LeadRelatedEndPoints.recentContact, data);
    if (res?.success) {
      setRecentContacts(res?.data);
      setLoader3(false);
    } else {
      setLoader3(false);
    }
  };
  // GET LAST 5 OPEN DEALS
  const handleGetRecentDealsCount = async () => {
    startApiCall(null, setLoader3);
    const data = {
      page: 1,
      limit: 5,
      employeeId: isBusinessWise
        ? ""
        : !isModuleReadWriteAccessForAnalytics
        ? user?._id
        : isBusinessWise
        ? ""
        : selectedEmpIdForApi?.length === 0
        ? user?._id
        : selectedEmpIdForApi?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        initialStatusName: "open",
        status: "deal",
      },
    };
    const res = await ApiCall("post", DealRelatedEndPoints.getDeal, data);
    if (res?.success) {
      setRecentDeals(res?.result?.data);
      setLoader3(false);
    } else {
      setLoader3(false);
    }
  };

  // GET LEAD TO CONTACT CONVERSION
  const handleLeadContactConversionCount = async () => {
    startApiCall(null, setLoader4);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        startDate:
          filterType === "thismonth"
            ? reversesDateFormatter(currentMonthStartDate)
            : filterType === "lastmonth"
            ? reversesDateFormatter(previousMonthStartDate)
            : filterType === "thisweek"
            ? formattedweekStartDate
            : filterType === "Date Range"
            ? startDate
            : formattedlastweekStartDate,
        endDate:
          filterType === "thismonth"
            ? reversesDateFormatter(currentMonthEndDate)
            : filterType === "lastmonth"
            ? reversesDateFormatter(previousMonthEndDate)
            : filterType === "thisweek"
            ? formattedweekEndDate
            : filterType === "Date Range"
            ? endDate
            : formattedlastweekEndDate,
        assignedTo: isBusinessWise
          ? ""
          : !isModuleReadWriteAccessForAnalytics
          ? user?._id
          : isBusinessWise
          ? ""
          : selectedEmpIdForApi?.length === 0
          ? user?._id
          : selectedEmpIdForApi?._id,
      },
      type:
        activeTab === "Leads this month"
          ? "lead"
          : activeTab === "Contacts"
          ? "contact"
          : activeTab === "Deals this month"
          ? "deal"
          : "lead",
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.leadContactConversionCount,
      data
    );
    if (res?.success) {
      setleadtoContactCount(res?.result);
      setLoader4(false);
    } else {
      setLoader4(false);
    }
  };
  // Get UPCOMING ACTIVITY
  const getUpcomingActivities = async () => {
    startApiCall(null, setLoader5);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        filterType === "thismonth"
          ? reversesDateFormatter(currentMonthStartDate)
          : filterType === "lastmonth"
          ? reversesDateFormatter(previousMonthStartDate)
          : filterType === "thisweek"
          ? formattedweekStartDate
          : filterType === "Date Range"
          ? startDate
          : formattedlastweekStartDate,
      endDate:
        filterType === "thismonth"
          ? reversesDateFormatter(currentMonthEndDate)
          : filterType === "lastmonth"
          ? reversesDateFormatter(previousMonthEndDate)
          : filterType === "thisweek"
          ? formattedweekEndDate
          : filterType === "Date Range"
          ? endDate
          : formattedlastweekEndDate,
      limit: 5,
      type:
        activeTab === "Leads this month"
          ? "Lead"
          : activeTab === "Contacts"
          ? "Contact"
          : "Deal",
      assignedTo: isBusinessWise
        ? ""
        : !isModuleReadWriteAccessForAnalytics
        ? [user?._id]
        : isBusinessWise
        ? ""
        : selectedEmpIdForApi?.length === 0
        ? [user?._id]
        : [selectedEmpIdForApi?._id],
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.getUpcomingActivity,
      data
    );
    if (res?.success) {
      setupcomingActivity(res?.activities);
      setactivityProgress(res);
      setLoader5(false);
    } else {
      setLoader5(false);
    }
  };
  // Get LEAD, CONTACT, DEAL GENERATION COUNT
  const getLeadGenerationCount = async () => {
    startApiCall(null, setLoader6);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate:
        filterType === "thismonth"
          ? reversesDateFormatter(currentMonthStartDate)
          : filterType === "lastmonth"
          ? reversesDateFormatter(previousMonthStartDate)
          : filterType === "thisweek"
          ? formattedweekStartDate
          : filterType === "Date Range"
          ? startDate
          : formattedlastweekStartDate,
      endDate:
        filterType === "thismonth"
          ? reversesDateFormatter(currentMonthEndDate)
          : filterType === "lastmonth"
          ? reversesDateFormatter(previousMonthEndDate)
          : filterType === "thisweek"
          ? formattedweekEndDate
          : filterType === "Date Range"
          ? endDate
          : formattedlastweekEndDate,
      assignedTo: isBusinessWise
        ? ""
        : !isModuleReadWriteAccessForAnalytics
        ? [user?._id]
        : isBusinessWise
        ? ""
        : selectedEmpIdForApi?.length === 0
        ? [user?._id]
        : [selectedEmpIdForApi?._id],
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.leadGeneratedCount,
      data
    );
    if (res?.success) {
      setleadGenerationCount(res?.data);
      setLoader6(false);
    } else {
      setLoader6(false);
    }
  };
  useEffect(() => {
    if (filterType === "Date Range" && !startDate) {
      //nothing
    } else if (isBusinessWise !== null && !assigneeModal) {
      handleGetLeadsAndContacts();
      handleGeLeadWonCount();
      handleLeadContactConversionCount();
      getUpcomingActivities();
      getLeadGenerationCount();

      if (activeTab === "Leads this month") {
        handleGetRecentLead();
      } else if (activeTab === "Contacts") {
        handleGetRecentContactsCount();
      } else if (activeTab === "Deals this month") {
        handleGetRecentDealsCount();
      }
    }
  }, [
    filterType,
    selectedEmpIdForApi,
    activeTab,
    isBusinessWise,
    assigneeModal,
  ]);

  const handleRefresh = () => {
    handleGetLeadsAndContacts();
    handleGeLeadWonCount();
    handleLeadContactConversionCount();
    getUpcomingActivities();
    getLeadGenerationCount();
    if (activeTab === "Leads this month") {
      handleGetRecentLead();
      resetDateRangeModal();
    } else if (activeTab === "Contacts") {
      handleGetRecentContactsCount();
      resetDateRangeModal();
    } else if (activeTab === "Deals this month") {
      handleGetRecentDealsCount();
      resetDateRangeModal();
    }
  };
  const handleFilterAssignee = () => {
    setdateModal(false);
    setassigneeModal(false);
    setIsBusinessWise(isBusinessWise);
    setSelectedEmpIdForApi(selectedEmpId);
  };

  const resetDateRange = () => {
    setstartDate("");
    setendDate("");
    setFilterType("thismonth");
  };
  const resetSelectedEmployee = () => {
    setselectedEmpId([]);
    setSelectedEmpIdForApi([]);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <AnalyticsHeader
            handleRefresh={handleRefresh}
            filterType={filterType}
            setFilterType={setFilterType}
            setdateModal={setdateModal}
            selectEmployee={selectEmployee}
            setselectEmployee={setselectEmployee}
            allEmployees={allEmployees}
            startDate={startDate}
            endDate={endDate}
            currentMonthStartDate={currentMonthStartDate}
            previousMonthStartDate={previousMonthStartDate}
            currentMonthEndDate={currentMonthEndDate}
            previousMonthEndDate={previousMonthEndDate}
            formattedweekStartDate={formattedweekStartDate}
            formattedlastweekStartDate={formattedlastweekStartDate}
            formattedweekEndDate={formattedweekEndDate}
            formattedlastweekEndDate={formattedlastweekEndDate}
            setassigneeModal={setassigneeModal}
            isModuleReadWriteAccessForAnalytics={
              isModuleReadWriteAccessForAnalytics
            }
          />
          {(isBusinessWise ||
            selectedEmpId?.name ||
            filterType === "Date Range") && (
            <div className="card position-sticky py-2 px-2 rounded-0">
              <div
                className="d-flex justify-content-end mb-2"
                style={{
                  width: "100%",
                  maxHeight: "150px",
                  overflow: "auto",
                }}
              >
                <div className="d-flex flex-wrap">
                  {isBusinessWise && !selectedEmpId?.name && (
                    <span
                      className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                      style={{
                        backgroundColor: "#3862DD",
                        color: "#ffff",
                        zIndex: 9999,
                      }}
                    >
                      Business Wise
                      <span className="initial-wrap w-30p">
                        <svg
                          onClick={() => {
                            setIsBusinessWise(false);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-x-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                      </span>
                    </span>
                  )}
                  {selectedEmpId?.name && (
                    <span
                      className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                      style={{
                        backgroundColor: "#3862DD",
                        color: "#ffff",
                        zIndex: 9999,
                      }}
                    >
                      {selectedEmpId?.name}
                      <span className="initial-wrap w-30p">
                        <svg
                          onClick={() => {
                            resetSelectedEmployee();
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#fff"
                          className="bi bi-x-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                      </span>
                    </span>
                  )}

                  {filterType === "Date Range" && (
                    <span
                      className="btn btn-sm btn-rounded d-flex align-items-center gap-1 shadow-hover me-1 mt-2 border"
                      style={{ backgroundColor: "#3862DD", color: "#ffff" }}
                    >
                      Date Range
                      <span className="initial-wrap w-30p">
                        <svg
                          onClick={() => {
                            resetDateRange();
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x-circle-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="todo-body">
            <div
              data-simplebar
              className="nicescroll-bar simple-scrollbar px-3 py-2"
            >
              <div className="contact-list-view">
                <OverviewAnalytics
                  allLeadsContacts={allLeadsContacts}
                  filterType={filterType}
                  leadCount={leadCount}
                  recentLeads={recentLeads}
                  recentContacts={recentContacts}
                  recentDeals={recentDeals}
                  leadtoContactCount={leadtoContactCount}
                  upcomingActivity={upcomingActivity}
                  leadGenerationCount={leadGenerationCount}
                  loader={loader}
                  loader2={loader2}
                  loader3={loader3}
                  loader4={loader4}
                  loader5={loader5}
                  loader6={loader6}
                  activityProgress={activityProgress}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomDateFilterStock
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader7}
          dateModal={dateModal}
          handleGetAllStock={handleRefresh}
          dateType={filterType}
          setdateModal={setdateModal}
        />
      </div>
      {assigneeModal && (
        <AssigneeModal
          setassigneeModal={setassigneeModal}
          assigneeModal={assigneeModal}
          selectedEmpId={selectedEmpId}
          setselectedEmpId={setselectedEmpId}
          handleRefresh={handleFilterAssignee}
          isBusinessWise={isBusinessWise}
          setIsBusinessWise={setIsBusinessWise}
          currentWorkSpaceRole={currentWorkSpaceRole}
        />
      )}
    </div>
  );
};

export default AnalyticsNewUI;
