import React, { useEffect, useState } from "react";
import StatusHeader from "./StatusHeader";
import StatusBody from "./StatusBody";
import CreateStatus from "./CreateStatus";
import { useParams } from "react-router-dom";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { StatusRelatedEndPoints } from "../../../../api/Endpoints";
import SkeletonConstant from "../../../../components/Constant/SkeletonConstant";
import CreateTaskForStatus from "./CreateTaskForStatus";
import NoData from "../../../../components/NoData/NoData";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import { useSelector } from "react-redux";

const GroupStatus = () => {
  const { user , allModuleAccess } = useSelector((state) => state);
  const { id } = useParams();
  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(200);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const [loader, setloader] = useState(true);
  const [loader2, setloader2] = useState(true);
  const [loader3, setloader3] = useState(true);
  const [errorMessage, seterrorMessage] = useState("");
  const [createTask, setcreateTask] = useState(false);
  const [createStatus, setcreateStatus] = useState(false);
  const [taskArray, settaskArray] = useState([]);

  const [group, setGroup] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [bluePrintData, setBluePrintData] = useState([]);
  const [selectStatus, setselectStatus] = useState("");

  const [editAllStatus, seteditAllStatus] = useState("");

  const isModuleReadWriteAccessForStatusGroup =
    allModuleAccess?.[PRIVATEROUTES.STATUS_GROUP];

  const handleGetGroupById = async () => {
    startApiCall(seterrorMessage, setloader2);
    const data = {
      groupId: id,
    };
    const res = await ApiCall(
      "post",
      StatusRelatedEndPoints.getGroupById,
      data
    );
    if (res?.success) {
      setGroup(res?.result);
      setloader2(false);
    } else {
      setloader2(false);
      seterrorMessage(res.error);
    }
  };
  useEffect(() => {
    handleGetGroupById();
  }, []);

  const handleGetAllStatus = async () => {
    startApiCall(seterrorMessage, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        statusGroupId: id,
        initialStatusId: selectStatus?._id,
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getStatus, data);
    if (res?.success) {
      setAllStatus(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (!selectStatus && group?.InitialStatus?.length > 0) {
      const status = group?.InitialStatus?.find(
        (data) => data?.name === "open"
      );
      setselectStatus(status);
    }
  }, [group]);

  useEffect(() => {
    if (getCurrentWorkspaceId() && selectStatus !== "") {
      handleGetAllStatus();
    }
  }, [page, pageLimit, selectStatus]);

  const handleGetAllBluprints = async () => {
    startApiCall(seterrorMessage, setloader3);
    const data = {
      page: page,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
        groupId: id,
      },
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.getStatus, data);
    if (res?.success) {
      setBluePrintData(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader3(false);
    } else {
      setloader3(false);
      seterrorMessage(res.error);
    }
  };
  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllBluprints();
    }
  }, [page, pageLimit]);

  const handleRefresh = () => {
    handleGetGroupById();
    handleGetAllStatus();
    handleGetAllBluprints();
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <StatusHeader
            handleRefresh={handleRefresh}
            group={group}
            loader2={loader2}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <div className="d-flex flex-column gap-3">
                  <p>
                    These are types of status that you can switch between while
                    handling a client. There are 3 default status given. You can
                    customise activities within the status.
                    {/* For example- The open
                    status can have 2 sub status like - meetings and quotes */}
                  </p>
                  {!loader2 && (
                    <ul className="d-flex align-items-center mb-0 px-0">
                      {group?.InitialStatus?.map((item) => {
                        return (
                          <li key={item?._id}>
                            <span
                              className={`d-flex align-items-center rounded-0 border px-7 py-2 pointer text-capitalize ${
                                item.name === selectStatus?.name
                                  ? "border-primary text-primary"
                                  : ""
                              }`}
                              onClick={() => {
                                setselectStatus(item);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill={item?.color}
                                className="bi bi-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <circle cx="8" cy="8" r="8" />
                              </svg>{" "}
                              &nbsp;
                              {item?.name}&nbsp;
                              {item?.name === selectStatus?.name ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-caret-down-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-caret-right-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                </svg>
                              )}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {loader2 && (
                    <div className="d-flex gap-2">
                      <SkeletonConstant width="w-160p" height="h-40p" />
                      <SkeletonConstant width="w-160p" height="h-40p" />
                      <SkeletonConstant width="w-160p" height="h-40p" />
                      <SkeletonConstant width="w-160p" height="h-40p" />
                      <SkeletonConstant width="w-160p" height="h-40p" />
                    </div>
                  )}
                  {isModuleReadWriteAccessForStatusGroup && (
                    <div>
                      {!loader2 && (
                        <span
                          className="btn btn-lg btn-primary"
                          onClick={() => {
                            setcreateStatus(true);
                          }}
                        >
                          + Create Step under {selectStatus?.name} status
                        </span>
                      )}
                      {loader2 && (
                        <SkeletonConstant width="w-240p" height="h-40p" />
                      )}
                    </div>
                  )}
                  <StatusBody
                    group={group}
                    allStatus={allStatus}
                    seteditAllStatus={seteditAllStatus}
                    loader={loader}
                    bluePrintData={bluePrintData}
                    loader3={loader3}
                    setcreateStatus={setcreateStatus}
                    user={user}
                    isModuleReadWriteAccessForStatusGroup={isModuleReadWriteAccessForStatusGroup}
                  />
                  {!loader && !loader2 && allStatus?.length === 0 && (
                    <NoData title="No Status added" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {createStatus && (
          <CreateStatus
            id={id}
            selectStatus={selectStatus}
            editAllStatus={editAllStatus}
            seteditAllStatus={seteditAllStatus}
            handleRefresh={handleRefresh}
            setcreateTask={setcreateTask}
            setcreateStatus={setcreateStatus}
            settaskArray={settaskArray}
            taskArray={taskArray}
          />
        )}

        {createTask && (
          <CreateTaskForStatus
            setcreateTask={setcreateTask}
            settaskArray={settaskArray}
            taskArray={taskArray}
          />
        )}
      </div>
    </div>
  );
};

export default GroupStatus;
