import React, { useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import CreateEvent from "./CreateEvent";
import SetReminder from "./SetReminder";
import {
  getAllEmployees,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { taskEndpoint } from "../../api/Endpoints";
import { useSelector } from "react-redux";
import TaskDetailsModal from "../Task/TaskDetailsModal/TaskDetailsModal";

const Calendars = () => {
  const user = useSelector((state) => state.user);
  const localizer = momentLocalizer(moment);
  const [errorMessage, seterrorMessage] = useState("");
  const [page, setpage] = useState(1);
  const [loader, setloader] = useState(false);
  const [taskData, settaskData] = useState([]);
  const [employeeData, setemployeeData] = useState(null);
  const [employee, setemployee] = useState("selectemployee");
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [calendarView, setCalendarView] = useState("month");
  const [taskId, settaskId] = useState([]);
  const [taskDetailsModal, settaskDetailsModal] = useState(false);

  // FORMAT DATE FOR CALENDAR EVENT

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return new Date(year, month, day, hours, minutes, 0, 0);
  }
  // CALENDAR EVENT
  const events = taskData?.map((task) => ({
    title: (
      <>
        {calendarView === "day" && (
          <>
            <span
              className="btn btn-xs btn-icon btn-flush-dark btn-rounded flush-soft-hover flex-shrink-0"
              href="#"
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => {
                settaskId(task?._id);
              }}
            >
              <span className="icon">
                <span className="feather-icon">
                  <i className="ri-more-2-line"></i>
                </span>
              </span>
            </span>
            <div className="dropdown-menu">
              <span
                className="dropdown-item"
                // data-bs-toggle="modal"
                // data-bs-target="#taskdetails_modal"
                onClick={() => {
                  settaskDetailsModal(true);
                }}
              >
                <span className="feather-icon dropdown-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-info-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                </span>
                <span>View Details</span>
              </span>
            </div>
          </>
        )}

        {task?.name}
      </>
    ),
    start: formatDate(task?.createdAt),
    end: task?.dueDate
      ? formatDate(task?.dueDate)
      : formatDate(task?.createdAt),
  }));

  // CUSTOM TOOLBAR FOR CALENDAR
  const CustomToolbar = ({ label, onView, onNavigate }) => {
    const handleNavigate = (action) => {
      let newDate = moment(selectedDate);
      if (action === "TODAY") {
        newDate = moment();
      } else if (action === "PREV") {
        calendarView === "month"
          ? newDate.subtract(1, "month")
          : calendarView === "day"
          ? newDate.subtract(1, "day")
          : newDate.subtract(1, "week");
      } else if (action === "NEXT") {
        calendarView === "month"
          ? newDate.add(1, "month")
          : calendarView === "day"
          ? newDate.add(1, "day")
          : newDate.add(1, "week");
      }

      onNavigate(action, newDate.toDate());
      setSelectedDate(newDate.toDate());
    };

    return (
      <div className="custom-toolbar">
        <div className="toolbar-left">
          <button
            className="toolbar-button"
            onClick={() => handleNavigate("TODAY")}
          >
            Today
          </button>
          <button
            className="toolbar-button"
            onClick={() => handleNavigate("PREV")}
          >
            Back
          </button>
          <button
            className="toolbar-button"
            onClick={() => handleNavigate("NEXT")}
          >
            Next
          </button>
          <button className="toolbar-button">
            <input
              type="date"
              id="datePicker"
              value={moment(selectedDate).format("YYYY-MM-DD")}
              onChange={handleDateChange}
              style={{
                border: "none",
                width: "20px",
              }}
            />
          </button>
        </div>
        <div className="toolbar-center">{label}</div>
        <div className="toolbar-right">
          <div className="me-2">
            <select
              className="form-select form-select-md"
              value={employee}
              onChange={(event) => setemployee(event.target.value)}
            >
              <option value="selectemployee">Select Employee</option>
              {employeeData?.map((employee) => (
                <option value={employee?._id} key={employee?._id}>
                  {employee?.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className="toolbar-button"
            onClick={() => setCalendarView("month")}
          >
            Month
          </button>
          <button
            className="toolbar-button"
            onClick={() => setCalendarView("week")}
          >
            Week
          </button>
          <button
            className="toolbar-button"
            onClick={() => setCalendarView("day")}
          >
            Day
          </button>
          <button
            className="toolbar-button"
            onClick={() => setCalendarView("agenda")}
          >
            Agenda
          </button>
        </div>
      </div>
    );
  };

  const handleSelectEvent = (event) => {
    setSelectedDate(event.start);
    setCalendarView("day");
  };
  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(new Date(newDate));
    setCalendarView("day");
  };

  const handleGetTask = async () => {
    startApiCall(seterrorMessage, setloader);
    const sendData = {
      page: page,
      limit: 100,
      filters: {
        businessId: getCurrentWorkspaceId(),
        assignedTo: employee !== "selectemployee" ? employee : "",
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      settaskData(res?.task?.data);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  const handleGetAllEmployee = async () => {
    startApiCall(null, setloader);
    const res = await getAllEmployees({
      pageLimit: 500000,
      pageNumber: 1,
      s2adminId: user?._id,
      businessId: getCurrentWorkspaceId(),
    });
    setemployeeData(res?.data);
    setloader(false);
  };

  useEffect(() => {
    handleGetTask();
  }, [employee]);

  useEffect(() => {
    handleGetAllEmployee();
  }, []);

  return (
    <div className="calendarapp-wrap">
      <div className="calendarapp-content">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{
            width: "100%",
            height: "95%",
            marginTop: "15px",
          }}
          components={{
            toolbar: CustomToolbar,
          }}
          date={selectedDate}
          view={calendarView}
          onSelectEvent={handleSelectEvent}
          dateRangeContainerStyle={{
            backgroundColor: "red",
            color: "red",
          }}
        />
      </div>

      <CreateEvent />
      <SetReminder />
      {taskDetailsModal && (
        <TaskDetailsModal
          taskId={taskId}
          taskDetailsModal={taskDetailsModal}
          settaskDetailsModal={settaskDetailsModal}
        />
      )}

      <div
        id="add_new_cat"
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h6 className="text-uppercase fw-bold mb-3">Add Category</h6>
              <form>
                <div className="row gx-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Category Name"
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-primary float-end"
                  data-bs-dismiss="modal"
                >
                  Add
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendars;
