import React, { useEffect, useState } from "react";
import {
  getAllWorkspaceStatusBySpace,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../api/ApiCall";
import { logsEndPoints, taskEndpoint } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { AssignEmployee } from "../AssignEmployee";
import DetailsHeader from "./DetailsHeader";
import DetailsLeft from "./DetailsLeft";
import RenameDetails from "../../../components/EditDetails/RenameDetails";
import DetailsRight from "./DetailsRight/DetailsRight";
import ReplyModal from "./ReplyModal";
import { useParams } from "react-router-dom";

const TaskDetailsModal = (props) => {
  const {
    taskId,
    taskDetailsModal,
    settaskDetailsModal,
    listName,
    spaceName,
    isShowSpace,
    isTask,
    handleGetTask,
  } = props;
  const user = useSelector((state) => state.user);
  const [taskDetails, settaskDetails] = useState([]);
  const [taskStatus, settaskStatus] = useState([]);
  const [pleaseWait, setpleaseWait] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  // console.log("taskId", taskDetails);
  // ************EDIT DETAILS**********
  const [taskStatusLoader, settaskStatusLoader] = useState(false);
  const [taskEditLoader, settaskEditLoader] = useState(false);
  const [isAssigneeEmployeeModal, setisAssigneeEmployeeModal] = useState(false);
  const [isEdited, setisEdited] = useState(false);
  const [editedTaskOrSubTask, seteditedTaskOrSubTask] = useState([]);
  const [dueDate, setdueDate] = useState("");
  const [showReply, setShowReply] = useState(false);
  const [isEditDescription, setIsEditDescription] = useState(false);

  // ************COMMENT**********
  const [allComments, setallComments] = useState([]);
  const [comment, setcomment] = useState("");

  // ************SUBTASK**********
  const [isSubTaskAdd, setisSubTaskAdd] = useState(false);
  const [subTaskName, setsubTaskName] = useState("");
  const [subTaskLoader, setsubTaskLoader] = useState(false);
  const [allSubTask, setallSubTask] = useState([]);

  // ************LOG**********
  const [taskAllLogs, settaskAllLogs] = useState([]);
  const [selectedComment, setselectedComment] = useState([]);

  // ************REPLY**********
  const [showAddReply, setShowAddReply] = useState();
  const [reply, setReply] = useState("");

  const handleReset = (e) => {
    settaskDetailsModal(false);
  };

  const taskPriority = [
    {
      name: "No Priority",
      value: "NO_PRIORITY",
      color: "#585656",
    },
    {
      name: "Low",
      value: "LOW",
      color: "#00C172",
    },
    {
      name: "Medium",
      value: "MEDIUM",
      color: "#FFB156",
    },
    {
      name: "High",
      value: "HIGH",
      color: "#FB1010",
    },
  ];

  const handleShowReply = (comment) => {
    setselectedComment(comment);
    setShowReply(true);
  };

  // ********* GET ALL COMMENTS FUNCTION API CALL ********
  const handleGetAllComments = async () => {
    startApiCall(seterrorMessage, settaskEditLoader);
    const res = await ApiCall("get", taskEndpoint.getComments, {
      params: {
        taskId: taskId,
        page: 1,
        limit: 100,
      },
    });
    if (res?.success) {
      setallComments(res?.comments?.data);
      settaskEditLoader(false);
      seterrorMessage("");
    } else {
      settaskEditLoader(false);
      seterrorMessage(res?.error);
    }
  };

  // ********* GET SUBTASK API CALL FUNCTION API CALL ********
  const handleSubTask = async () => {
    startApiCall(null, setsubTaskLoader);
    const sendData = {
      page: 1,
      limit: 50,
      filters: {
        maintaskId: taskDetails?._id,
        taskType: "subtask",
        isDeleted: false,
      },
    };
    const res = await ApiCall("post", taskEndpoint.getTask, sendData);
    if (res?.success) {
      setallSubTask(res?.task?.data);
      setsubTaskLoader(false);
    } else {
      setsubTaskLoader(false);
      seterrorMessage(res.error);
    }
  };

  // ********* TASK DETAILS FUNCITON FUNCTION API CALL ********
  const handleGetTaskDetails = async () => {
    startApiCall(null, setpleaseWait);
    const res = await ApiCall("post", taskEndpoint.taskDetails, {
      taskId,
      userId: user?._id,
    });
    if (res?.success) {
      settaskDetails(res?.task);
      setpleaseWait(false);
    } else {
      setpleaseWait(false);
      seterrorMessage(res?.error);
    }
  };

  // ********* GET ALL SPACE WISE STATUS FUNCTION API CALL ********
  const handleGetAllSpaceStatus = async () => {
    startApiCall(seterrorMessage, settaskStatusLoader);
    const res = await getAllWorkspaceStatusBySpace(taskDetails?.space);
    settaskStatus([...res]);
    settaskStatusLoader(false);
  };

  // ********* EDIT TASK FUNCTION API CALL ********
  const handleEditTask = async (taskId, type, value) => {
    startApiCall(seterrorMessage, settaskEditLoader);
    const sendData = {
      taskId:
        type === "name" || type === "description"
          ? editedTaskOrSubTask?._id
          : taskId,
      name: type === "name" ? value : "",
      status: type === "status" ? value : "",
      userId: user?._id,
      priority: type === "priority" ? value : "",
      dueDate: type === "due" ? dueDate : "",
      description: type === "description" ? value : "",
    };
    const res = await ApiCall("post", taskEndpoint.editTask, sendData);
    if (res?.success) {
      settaskEditLoader(false);
      setisEdited(false);
      setIsEditDescription(false);
      toast.success("Edit Task successfully!");
      handleGetTaskDetails();
      if(isTask){
        handleGetTask();
      }
    } else {
      settaskEditLoader(false);
      seterrorMessage(res.error);
    }
  };

  // ********* USEEFFECT FOR API CALL ********
  useEffect(() => {
    if (taskDetails?._id) {
      handleSubTask();
      handleGetLogs()
      handleGetAllComments();
    }
  }, [taskDetails]);

  // ********* API CALL AFTER OR BEFORE COMPONENT REFRESH********
  useEffect(() => {
    handleGetTaskDetails();
  }, [taskDetailsModal]);

  // ********* GET ADD COMMENT FUNCTION API CALL ********
  const handleAddComment = async (e, commentId) => {
    e.preventDefault();
    startApiCall(seterrorMessage, settaskEditLoader);
    const isReply = typeof showAddReply === "number";
    const sendData = {
      taskId: taskDetails?._id,
      commentType: isReply ? "reply" : "maincomment",
      commentator: user?._id,
      comment: isReply ? reply : comment,
      isReply: isReply ? true : false,
      mainCommentId: isReply ? commentId : "",
    };
    const res = await ApiCall("post", taskEndpoint.addComment, sendData);
    if (res?.success) {
      handleGetAllComments();
      toast.success(`Add ${isReply ? "Reply" : "Comment"} Successfully!`);
      setcomment("");
      if (isReply) {
        setShowAddReply();
        setReply("");
      }
      settaskEditLoader(false);
    } else {
      settaskEditLoader(false);
      seterrorMessage(res?.error);
    }
  };

  // ********* GET ADD SUB TASK FUNCTION API CALL ********
  const handleCreateSubtask = async () => {
    startApiCall(null, setsubTaskLoader);
    const sendData = {
      name: subTaskName,
      taskType: "subtask",
      taskAbilities: "business",
      createdBy: user?._id,
      assignedBy: [user?._id],
      businessId: getCurrentWorkspaceId(),
      list: taskDetails?.list,
      maintaskId: taskDetails?._id,
    };
    const res = await ApiCall("post", taskEndpoint.createTask, sendData);
    if (res?.success) {
      handleSubTask();
      setsubTaskName("");
      setisSubTaskAdd(false);
      toast.success("Create Subtask successfully!");
      setsubTaskLoader(false);
    } else {
      setsubTaskLoader(false);
      seterrorMessage(res.error);
    }
  };

  // ********* DELETE SUB TASK FUNCTION API CALL ********
  const handleDeleteSubTask = async (taskId) => {
    let sendData = {
      taskId,
      actionBy: user?._id,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall("post", taskEndpoint.deleteTask, sendData);
    if (res?.success) {
      seterrorMessage("");
      toast.success("Task Deleted successfully!");
      handleSubTask();
    } else {
      seterrorMessage(res.error);
    }
  };

  // ********* GET LOG FUNCTION API CALL ********
  const handleGetLogs = async () => {
    startApiCall(null, settaskEditLoader);
    const sendData = {
      page: 1,
      limit: 2000,
      businessId: getCurrentWorkspaceId(),
      moduleId: taskDetails?._id,
      moduleType: "Task",
    };
    const res = await ApiCall("post", logsEndPoints.getLogs, sendData);
    if (res?.success) {
      settaskAllLogs(res?.logs?.data);
      settaskEditLoader(false);
    } else {
      settaskEditLoader(false);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "90%" }}
        role="document"
      >
        <div className="modal-content">
          <div
            className={
              pleaseWait
                ? "d-flex flex-column loading-skeleton"
                : "d-flex flex-column"
            }
          >
            <>
              <DetailsHeader
                taskDetails={taskDetails}
                taskStatusLoader={taskStatusLoader}
                handleEditTask={handleEditTask}
                handleReset={handleReset}
                handleGetAllSpaceStatus={handleGetAllSpaceStatus}
                taskStatus={taskStatus}
                taskPriority={taskPriority}
                setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
                setdueDate={setdueDate}
                dueDate={dueDate}
                pleaseWait={pleaseWait}
                user={user}
                handleGetTaskDetails={handleGetTaskDetails}
                listName={listName}
                spaceName={spaceName}
                isShowSpace={isShowSpace}
              />
              <div className="d-flex w-100">
                <DetailsLeft
                  setisEdited={setisEdited}
                  taskDetails={taskDetails}
                  seteditedTaskOrSubTask={seteditedTaskOrSubTask}
                  comment={comment}
                  setcomment={setcomment}
                  handleAddComment={handleAddComment}
                  seterrorMessage={seterrorMessage}
                  taskEditLoader={taskEditLoader}
                  setisSubTaskAdd={setisSubTaskAdd}
                  isSubTaskAdd={isSubTaskAdd}
                  subTaskName={subTaskName}
                  setsubTaskName={setsubTaskName}
                  handleCreateSubtask={handleCreateSubtask}
                  subTaskLoader={subTaskLoader}
                  allSubTask={allSubTask}
                  handleDeleteSubTask={handleDeleteSubTask}
                  pleaseWait={pleaseWait}
                  setIsEditDescription={setIsEditDescription}
                />
                <DetailsRight
                  allComments={allComments}
                  taskEditLoader={taskEditLoader}
                  handleGetAllComments={handleGetAllComments}
                  taskAllLogs={taskAllLogs}
                  handleGetLogs={handleGetLogs}
                  pleaseWait={pleaseWait}
                  handleShowReply={handleShowReply}
                  showAddReply={showAddReply}
                  setShowAddReply={setShowAddReply}
                  reply={reply}
                  setReply={setReply}
                  handleAddComment={handleAddComment}
                  taskDetails={taskDetails}
                  user={user}
                  handleGetTaskDetails={handleGetTaskDetails}
                />
                {isAssigneeEmployeeModal && (
                  <AssignEmployee
                    setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
                    isAssigneeEmployeeModal={isAssigneeEmployeeModal}
                    listId={taskDetails?.list}
                    user={user}
                    selectedTask={taskDetails}
                    handleGetTask={handleGetTaskDetails}
                  />
                )}
              </div>
              {isEdited && (
                <RenameDetails
                  width="40%"
                  title={"edit task details"}
                  setisEdited={setisEdited}
                  value={editedTaskOrSubTask}
                  handleRename={handleEditTask}
                  errorMessage={errorMessage}
                  loader={taskEditLoader}
                  isEditDescription={isEditDescription}
                />
              )}
              {showReply && (
                <ReplyModal
                  setShowReply={setShowReply}
                  comments={selectedComment}
                />
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailsModal;
