import React from "react";
import completed from "../../../assets/images/GIF/completed.gif";
import inprogress from "../../../assets/images/GIF/inprogress.gif";
import open_task from "../../../assets/images/GIF/open_task.gif";
import total_task from "../../../assets/images/GIF/total_task.gif";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import GaugeChart from "./GaugeChart";
import TaskAnalysisTableView from "./TaskAnalysisTableView";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";

const TaskAnalyticsDetailsBody = ({
  allTaskOverview,
  loader,
  taskData,
  selectedTab,
  setSelectedTab,
  settaskData,
  page,
  pageLimit,
  handleGetTask,
  setpageLimit,
  count,
  setPage,
  totalPages,
  settaskDetailsModal,
  setselectedTask,
  setAnalyticsView,
  analyticsView,
  setisAssigneeEmployeeModal,
  listOrSpaceId,
  setListOrSpaceId,
  seteditDuedDate,
  settaskId,
  loader2,
}) => {
  const doughnutOptions = {
    responsive: true,
    cutout: "60%",
    plugins: {
      legend: {
        position: "bottom",
      },
      datalabels: false,
    },
  };
  const chartData = {
    labels: ["Open", "Done", "Completed"],
    datasets: [
      {
        label: "Task Count",
        data: [
          allTaskOverview?.statuses?.active,
          allTaskOverview?.statuses?.done,
          allTaskOverview?.statuses?.completed,
        ],
        backgroundColor: ["#0D6EFD", "#FFC400", "#FF0101"],
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3 col-sm-6">
          <div className="card p-3">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="d-flex flex-column gap-1">
                <p className="mb-0" style={{ color: "#4B4DB8" }}>
                  Total Tasks
                </p>
                <p className="mb-0 fs-5">
                  {!loader2 &&
                    (allTaskOverview?.statuses?.all
                      ? allTaskOverview?.statuses?.all
                      : 0)}
                  {loader2 && <SkeletonConstant width="w-40" height="h-25p" />}
                </p>
              </div>
              <img
                style={{ height: "48px", width: "48px" }}
                src={total_task}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="card p-3">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="d-flex flex-column gap-1">
                <p className="mb-0" style={{ color: "#4B4DB8" }}>
                  Open
                </p>
                <p className="mb-0 fs-5">
                  {!loader2 &&
                    (allTaskOverview?.statuses?.active
                      ? allTaskOverview?.statuses?.active
                      : 0)}
                  {loader2 && <SkeletonConstant width="w-40" height="h-25p" />}
                </p>
              </div>
              <img
                style={{ height: "48px", width: "48px" }}
                src={open_task}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="card p-3">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="d-flex flex-column gap-1">
                <p className="mb-0" style={{ color: "#4B4DB8" }}>
                  Done
                </p>
                <p className="mb-0 fs-5">
                  {!loader2 &&
                    (allTaskOverview?.statuses?.done
                      ? allTaskOverview?.statuses?.done
                      : 0)}
                  {loader2 && <SkeletonConstant width="w-40" height="h-25p" />}
                </p>
              </div>
              <img
                style={{ height: "48px", width: "48px" }}
                src={inprogress}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6">
          <div className="card p-3">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="d-flex flex-column gap-1">
                <p className="mb-0" style={{ color: "#4B4DB8" }}>
                  Completed
                </p>
                <p className="mb-0 fs-5">
                  {!loader2 &&
                    (allTaskOverview?.statuses?.completed
                      ? allTaskOverview?.statuses?.completed
                      : 0)}
                  {loader2 && <SkeletonConstant width="w-40" height="h-25p" />}
                </p>
              </div>
              <img
                style={{ height: "48px", width: "48px" }}
                src={completed}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 d-flex justify-content-end">
        <div className="me-2 d-flex align-items-center">
          <div className="form-check form-switch">
            <input
              className="form-check-input pointer"
              type="checkbox"
              id="viewSwitch"
              disabled={loader}
              checked={!analyticsView}
              onChange={() => setAnalyticsView(!analyticsView)}
            />
            <label className="form-check-label" htmlFor="viewSwitch">
              {analyticsView ? "Analytics View" : "Table View"}
            </label>
          </div>
        </div>
      </div>
      {!loader2 && analyticsView && allTaskOverview && (
        <>
          <div className="d-flex align-items-center justify-content-center">
            <div
              style={{
                maxWidth: "380px",
                maxHeight: "380px",
                position: "relative",
              }}
            >
              <Doughnut data={chartData} options={doughnutOptions} />
              <span
                className="d-flex flex-column align-items-center"
                style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <p
                  className="fs-5 fw-semibold text-dark"
                  style={{ textShadow: "0 0 1px #fff" }}
                >
                  Total
                </p>
                <p
                  className="fs-4 text-dark fw-semibold"
                  style={{ textShadow: "0 0 1px #fff" }}
                >
                  {allTaskOverview?.statuses?.all}
                </p>
              </span>
            </div>
          </div>
          <div>
            <p className="fs-5 fw-semibold text-dark">SLA Report</p>
            <div className="row">
              <div className="col-md-6">
                <div className="card h-275p overflow-hidden">
                  <div className="card-body d-flex align-items-start justify-content-center">
                    <div className="mw-500p mh-400p">
                      <GaugeChart
                        filled={25}
                        allTaskOverview={allTaskOverview}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card bg-light h-275p">
                  <div className="card-body d-flex flex-column gap-3">
                    <p className="text-dark fw-semibold fs-4">Status</p>
                    <div className="d-flex align-items-center justify-content-between gap-2 fs-5">
                      <p>Total Tasks</p>
                      <p className="text-dark fw-semibold">
                        {allTaskOverview?.statuses?.all}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-2 fs-5">
                      <p>Due Date</p>
                      <p className="text-dark fw-semibold">
                        {allTaskOverview?.withinDueDate}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-2 fs-5">
                      <p>Beyond Deadline</p>
                      <p className="text-dark fw-semibold">
                        {allTaskOverview?.beyondDueDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!analyticsView && (
        <TaskAnalysisTableView
          loader={loader}
          loader2={loader2}
          taskData={taskData}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          settaskData={settaskData}
          page={page}
          pageLimit={pageLimit}
          handleGetTask={handleGetTask}
          setpageLimit={setpageLimit}
          count={count}
          setPage={setPage}
          totalPages={totalPages}
          settaskDetailsModal={settaskDetailsModal}
          setselectedTask={setselectedTask}
          setisAssigneeEmployeeModal={setisAssigneeEmployeeModal}
          listOrSpaceId={listOrSpaceId}
          setListOrSpaceId={setListOrSpaceId}
          seteditDuedDate={seteditDuedDate}
          settaskId={settaskId}
        />
      )}
    </div>
  );
};

export default TaskAnalyticsDetailsBody;
