import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  handleDeleteAwsImage,
  handlePreviewFileInLocal,
  handleUploadInDirectAws,
  reuiredField,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { toast } from "react-toastify";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ApiCall from "../../../../api/ApiCall";
import { ProductCategoryRelatedEndPoints } from "../../../../api/Endpoints";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

const AddCategory = ({
  handleGetItemCategory,
  editItemCategory,
  setEditItemCategory,
}) => {
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");

  const handlePreviewFileInLocalEdit = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const img = URL.createObjectURL(event.target.files[0]);
      return img;
    } else if (editItemCategory?.images?.[0]) {
      // Return the URL of the existing image if no new file is selected
      return editItemCategory?.images?.[0];
    } else {
      return null;
    }
  };

  const setFileForUpload = (event) => {
    setImage(event);
  };

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setImage("");
    setTitle("");
    setEditItemCategory("");
    const closeModalDom = document.getElementById("add_new_category");
    if (closeModalDom) closeModalDom.click();
  };

  useEffect(() => {
    if (editItemCategory) {
      setTitle(editItemCategory?.categoryName);
      setImage(
        editItemCategory?.images?.[0] ? editItemCategory?.images?.[0] : ""
      );
    }
  }, [editItemCategory]);

  const handleAddItemCategory = async (e) => {
    e.preventDefault();
    let isError = false;
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    if (image) {
      try {
        const uploaded = await handleUploadInDirectAws(image);
        if (uploaded.Location) {
          temp.push(uploaded.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    }
    if (!isError) {
      const sendData = {
        businessId: getCurrentWorkspaceId(),
        categoryName: title,
        images: temp,
      };
      const res = await ApiCall(
        "post",
        ProductCategoryRelatedEndPoints.addProductCategory,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Add category successfully!");
        handleGetItemCategory();
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    }
  };

  const handleEditItemCategory = async (e) => {
    e.preventDefault();
    let isError = false;
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    if (image === "") {
      temp.push();
    } else if (editItemCategory?.images?.[0]) {
      temp.push(image);
    } else if (image) {
      try {
        const uploaded = await handleUploadInDirectAws(image);
        if (uploaded.Location) {
          temp.push(uploaded.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    }
    if (!isError) {
      const sendData = {
        productCategoryId: editItemCategory._id,
        categoryName: title,
        images: temp,
      };
      const res = await ApiCall(
        "post",
        ProductCategoryRelatedEndPoints.ediProductCategory,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Category edited successfully!");
        handleGetItemCategory();
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    }
  };

  return (
    <div
      id="add_new_category"
      className="modal fade add-new-task"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content px-3">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editItemCategory !== "" ? "Edit Category" : "Add Category"}
            </h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (editItemCategory !== "") {
                  handleEditItemCategory(e);
                } else {
                  handleAddItemCategory(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="col-12 pt-3">
                  <div className="form-group">
                    <label className="form-label">
                      Category Name {reuiredField}
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Enter category name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">
                      Upload Image {reuiredField}
                    </label>
                    <p className="fs-7">
                      You can upload any relevant image for the better
                      understanding of your employees
                    </p>
                    <div className="d-flex dropify-square mt-3">
                      {image ? (
                        <>
                          <input
                            type="file"
                            name="image"
                            id="file"
                            accept="image/png,image/jpeg"
                            className="input-file"
                            disabled={image}
                            onChange={setFileForUpload}
                          />
                          <label
                            htmlFor="file"
                            className="position-relative pointer"
                          >
                            <img
                              htmlFor="file"
                              id="local_show_img"
                              // src={handlePreviewFileInLocal(image)}
                              src={
                                editItemCategory == ""
                                  ? handlePreviewFileInLocal(image)
                                  : handlePreviewFileInLocalEdit(image)
                              }
                              style={{ padding: 5 }}
                              className="dropify-wrapper text-center"
                              alt="img"
                            />
                            <span
                              className="position-top-end-overflow"
                              onClick={() => {
                                setImage("");
                                if (editItemCategory?.images?.[0]) {
                                  handleDeleteAwsImage(image);
                                }
                                setEditItemCategory((prevData) => ({
                                  ...prevData,
                                  images: [null],
                                }));
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-x"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                              </svg>
                            </span>
                          </label>
                        </>
                      ) : (
                        <span className="dropify-wrapper text-center">
                          <input
                            type="file"
                            name="image"
                            id="file"
                            accept="image/png,image/jpeg"
                            className="input-file"
                            onChange={setFileForUpload}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-upload"
                            viewBox="0 0 16 16"
                          >
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                          </svg>
                          <label htmlFor="file" className="pointer text-center">
                            Upload Image
                          </label>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!(title && image)}
                >
                  {loader ? (
                    <ApiLoader />
                  ) : editItemCategory !== "" ? (
                    "Edit"
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
