import React from "react";
import { dateFormatter, getName } from "../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { Avatar } from "../../components/Avatar/Avatar";

const HolidayTable = ({
  allHoliday,
  page,
  pageLimit,
  isModuleReadWriteAccessForHoliday,
}) => {
  const { user } = useSelector((state) => state);

  const groupedHolidays = {};
  allHoliday?.forEach((data) => {
    const holidayName = data?.holidayName;
    const date = dateFormatter(data?.date);

    if (groupedHolidays[holidayName]) {
      groupedHolidays[holidayName].dates.push(date);
    } else {
      groupedHolidays[holidayName] = {
        dates: [date],
        createdBy: data?.createdBy,
        createdAt: dateFormatter(data?.createdAt),
      };
    }
  });

  Object.keys(groupedHolidays)?.forEach((holidayName) => {
    groupedHolidays[holidayName]?.dates?.sort(
      (a, b) => new Date(a) - new Date(b)
    );
  });

  return (
    <>
      <table id="datable_1" className="table w-100 mb-5">
        <thead>
          <tr className="text-center">
            <th>No.</th>
            {isModuleReadWriteAccessForHoliday && (
              <>
                <th>Created By</th>
                <th>Created At</th>
              </>
            )}
            <th>Name</th>
            <th>Holiday Date</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedHolidays)?.map((holidayName, index) => {
            const holidayData = groupedHolidays[holidayName];

            return (
              <tr className="text-center" key={index}>
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                {isModuleReadWriteAccessForHoliday && (
                  <>
                    <td>
                      <span className="d-inline-block">
                        <Avatar
                          name={holidayData?.createdBy?.name}
                          count={1}
                          image={holidayData?.createdBy?.image}
                          size="xss"
                          color="primary"
                        />
                        &nbsp; {getName(holidayData?.createdBy, user?._id)}
                      </span>
                    </td>
                    <td>{holidayData?.createdAt}</td>
                  </>
                )}
                <td>
                  <span className="text-capitalize">{holidayName}</span>
                </td>
                <td>{holidayData?.dates.join(", ")}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default HolidayTable;
