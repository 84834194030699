import React, { useEffect, useState } from "react";
import {
  dateAndTimeFormatter,
  dateFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { ShiftRelatedEndPoints } from "../../../../api/Endpoints";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

function ChangeShiftModal(props) {
  const { dayShiftData, handleGetAllShiftsofMonth, setchangeShiftModal } =
    props;
  const { id } = useParams();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [allShifts, setAllShifts] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedShiftData, setselectedShiftData] = useState([]);
  const [selectedShift, setselectedShift] = useState("");

  // console.log("dayShiftData", dayShiftData);

  const handleGetAllShifts = async () => {
    startApiCall(null, setloader);
    const sendData = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      ShiftRelatedEndPoints.getStaffShift,
      sendData
    );
    if (res?.success) {
      setAllShifts(res?.staff?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetAllShifts();
  }, []);

  // CHANGE STAFF SHIFT FUNCTION API CALL
  const handleAddShiftToStaff = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader2);
    const data = {
      staffId: id,
      shiftTimings: [
        {
          shiftId: selectedShiftData?._id,
          shiftType: "multiple",
          startDate: dayShiftData?.date,
          endDate: dayShiftData?.date,
        },
      ],
      previousShiftId: dayShiftData?.shiftId,
    };
    const res = await ApiCall(
      "post",
      ShiftRelatedEndPoints.addShiftToStaff,
      data
    );
    if (res?.success) {
      setchangeShiftModal(false);
      handleGetAllShiftsofMonth();
      toast.success("Change shift successfully!");
      setloader2(false);
    } else {
      setErrorMessage(res?.error);
      setloader2(false);
    }
  };

  useEffect(() => {
    if (dayShiftData) {
      setselectedShift(dayShiftData?.shiftId);
    }
  }, [dayShiftData]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg "
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setchangeShiftModal(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-2">
              Change Shift For {dateFormatter(dayShiftData?.date)}
            </h5>
            <div className="border-bottom"></div>
            <form onSubmit={handleAddShiftToStaff}>
              {!loader && allShifts?.length > 0 && (
                <>
                  <div className="mh-350p overflow-auto">
                    <table id="datable_1" className="table ">
                      <thead>
                        <tr>
                          <th colSpan={6}>Choose a shift</th>
                        </tr>
                      </thead>
                      {allShifts?.map((shift, index) => (
                        <tbody>
                          <tr className="text-start">
                            <td>{index + 1}.</td>
                            <td className="text-capitalize">
                              {shift?.shiftName}{" "}
                            </td>
                            <td>
                              {dateAndTimeFormatter(shift?.startTime)
                                .split(",")[1]
                                ?.trim()}
                            </td>
                            <td>
                              {dateAndTimeFormatter(shift?.endTime)
                                .split(",")[1]
                                ?.trim()}
                            </td>
                            <td>{shift?.totalHours} Hrs</td>
                            <td>
                              <input
                                type="checkbox"
                                className="form-check-input pointer"
                                value=""
                                checked={selectedShift === shift?._id}
                                onClick={() => {
                                  setselectedShift(shift?._id);
                                  setselectedShiftData(shift);
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </div>
                  {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                  <div className="modal-footer align-items-center">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={selectedShift === dayShiftData?.shiftId}
                    >
                      {loader2 ? <ApiLoader /> : "Save Changes"}
                    </button>
                  </div>
                </>
              )}
              {loader && (
                <div className="d-flex justify-content-center p-4">
                  <DataLoader />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeShiftModal;
