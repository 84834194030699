import React, { useState, useEffect } from "react";

const ThemeOptions = () => {
  const storedTheme = localStorage.getItem("selectedTheme") || "default";
  const [selectedColor, setSelectedColor] = useState(storedTheme);

  const applyTheme = (color) => {
    document.querySelector("body").setAttribute("data-theme", color);
    localStorage.setItem("selectedTheme", color);
  };

  const handleColorChange = (e) => {
    const selectedColor = e.target.value;
    setSelectedColor(selectedColor);
    applyTheme(selectedColor);
  };

  useEffect(() => {
    applyTheme(selectedColor);
  }, [selectedColor]);

  return (
    <div className="me-2">
      <select value={selectedColor} onChange={handleColorChange} className="form-select form-select text-capitalize py-1">
        <option value="default">Default</option>
        <option value="purple">Purple</option>
        <option value="yellow">Yellow</option>
        <option value="green">Green</option>
        <option value="red">Red</option>
      </select>
    </div>
  );
};

export default ThemeOptions;
