import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../../../components/Loaders/ApiLoader/ApiLoader";
import { useSelector } from "react-redux";

function ManualLocationModal({
  setmanualLocation,
  allActivity,
  description,
  setLocationData,
  locationData,
}) {
  const user = useSelector((state) => state.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [activityLoader, setactivityLoader] = useState(false);

  const handleReset = () => {
    setmanualLocation(false);
    setLocationData({
      areaName: "",
      flatHouseNo: "",
      city: "",
      state: "",
      updatedBy: user?._id,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocationData({
      ...locationData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (allActivity) {
      const location = allActivity?.attachments?.filter(
        (attachment) =>
          attachment?.attachmentType === "manuallocation" &&
          attachment?.activityStatus === allActivity?.activityStatus?._id
      );

      setLocationData({
        areaName: location?.[0]?.data?.url?.[0]?.locationData?.areaName,
        flatHouseNo: location?.[0]?.data?.url?.[0]?.locationData?.flatHouseNo,
        city: location?.[0]?.data?.url?.[0]?.locationData?.city,
        state: location?.[0]?.data?.url?.[0]?.locationData?.state,
      });
    }
  }, [allActivity]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div className="modal-content p-2" style={{ overflow: "visible" }}>
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2 text-capitalize">Location Insights</h5>
            <form
              onSubmit={() => {
                setmanualLocation(false);
              }}
            >
              <div className="row gx-3 px-3 w-100 mt-4 d-flex flex-column">
                {description && (
                  <div className="d-flex justify-content-start">
                    <div
                      className="alert alert-secondary fs-7 px-2 py-2 mt-1"
                      role="alert"
                    >
                      <strong>Description: </strong> {description}
                    </div>
                  </div>
                )}

                <div className="col-8">
                  <div className="form-group">
                    <label className="form-label">Your Location</label>
                    <textarea
                      className="form-control mt-2"
                      rows="3"
                      name="areaName"
                      value={locationData.areaName}
                      onChange={handleChange}
                      required
                      placeholder="Area name"
                    ></textarea>
                  </div>
                </div>
                <div className="col-8">
                  <div className="form-group">
                    <label className="form-label">Complete address</label>
                    <div className="d-flex flex-column align-items-center repeater border  py-3 px-3 rounded-5 w-100 ">
                      <textarea
                        className="form-control mt-2 border-0 border-bottom rounded-0"
                        rows="3"
                        name="flatHouseNo"
                        value={locationData.flatHouseNo}
                        onChange={handleChange}
                        required
                        placeholder="Flat House/No"
                      ></textarea>
                      <textarea
                        className="form-control mt-2 border-0 border-bottom rounded-0"
                        rows="3"
                        name="city"
                        value={locationData.city}
                        onChange={handleChange}
                        required
                        placeholder="City"
                      ></textarea>
                      <textarea
                        className="form-control mt-2 border-0 border-bottom rounded-0"
                        rows="3"
                        name="state"
                        value={locationData.state}
                        onChange={handleChange}
                        required
                        placeholder="State"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="d-flex justify-content-end mt-2">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={activityLoader}
                >
                  {activityLoader ? <ApiLoader /> : "Submit Location"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManualLocationModal;
