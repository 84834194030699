import React from "react";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

const ChangeRoleModal = (props) => {
  const {
    isOpen,
    onClose,
    text,
    loader,
    errorMessage,
    handleConfirm,
    setselectedRole,
    selectedRole,
  } = props;

  return (
    <div
      id="confirm"
      className={`modal fade add-new-contact mt-30 ${isOpen ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{
        display: isOpen ? "block" : "none",
        pointerEvents: loader ? "none" : "",
      }}
      aria-hidden="true"
    >
      <form>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h5 className="mb-4 text_center">Change Role</h5>
              <div className="form-group  d-flex justify-content-center">

                <div className="form-check form-check-inline">
                  <div className="custom-control custom-radio radio-primary">
                    <input
                      type="radio"
                      id="customRadioc3"
                      name="customRadioc2"
                      className="form-check-input pointer mt-1"
                      value="admins"
                      onChange={(e)=>{
                        setselectedRole(e.target.value)
                      }}
                        checked={selectedRole === "admins"}
                      required
                    />
                    <label className="form-check-label">Admin</label>
                  </div>
                  <div className="custom-control custom-radio radio-primary">
                    <input
                      type="radio"
                      id="customRadioc3"
                      name="customRadioc2"
                      className="form-check-input pointer  mt-1"
                      value="managers"
                      onChange={(e)=>{
                        setselectedRole(e.target.value)
                      }}
                        checked={selectedRole === "managers"}
                      required
                    />
                    <label className="form-check-label">Manager</label>
                  </div>
                  <div className="custom-control custom-radio radio-primary">
                    <input
                      type="radio"
                      id="customRadioc3"
                      name="customRadioc2"
                      className="form-check-input pointer  mt-1"
                        value="employee"
                        onChange={(e)=>{
                            setselectedRole(e.target.value)
                          }}
                        checked={selectedRole === "employee"}
                    />
                    <label className="form-check-label">Employee</label>
                  </div>
                </div>
              </div>
              <div className="text_center"></div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            </div>
            <div
              className="px-4 pb-4"
              style={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <button
                type="button"
                disabled={loader}
                className="btn btn-rounded btn-secondary col-sm-5"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                disabled={loader}
                className="btn btn-rounded btn-primary col-sm-5"
                onClick={handleConfirm}
              >
                {loader ? <ApiLoader /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChangeRoleModal;
