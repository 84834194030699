import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import InventorySetings from "../../pages/InventoryManagement/InventorySetings/InventorySetings";

const InventorySettingsRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "inventorysettings",
      element: <InventorySetings />,
    },
  ],
};

export default InventorySettingsRoutes;
