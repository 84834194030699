import React, { useState } from "react";
import {
  copyToClipboard,
  dateAndTimeFormatter,
  getName,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { CrmSettingsRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal";
import { toast } from "react-toastify";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";
import NoData from "../../../../components/NoData/NoData";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";

function CrmDynamicFields({
  setdynamicFieldsModal,
  seteditData,
  allFields,
  totalPages,
  count,
  loader3,
  editData,
  handleGetDynamicFields,
  isModuleReadWriteAccessForSetting,
  page,
  setPage,
  pageLimit,
  setpageLimit,
}) {
  const user = useSelector((state) => state.user);
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [copy, setcopy] = useState({
    isTrue: false,
    item: "",
  });
  const handleCopy = (text) => {
    setcopy({ isTrue: true, item: text });
    copyToClipboard(text);
    setTimeout(() => {
      setcopy({ isTrue: false, item: text });
    }, 800);
  };

  const handleClose = () => {
    setIsOpen(false);
    setErrorMessage("");
    setloader(false);
    seteditData("");
  };

  const handleVisibilityOfField = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      id: editData?._id,
      isVisible: editData?.isVisible ? false : true,
      isRequired: editData?.isVisible ? false : editData?.isRequired,
    };
    const res = await ApiCall(
      "post",
      CrmSettingsRelatedEndPoints.editDynamicField,
      data
    );
    if (res?.success) {
      handleClose();
      handleGetDynamicFields();
      toast.success("Field Visibility Changed successfully!");
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  const handleToggleVisibility = (field) => {
    seteditData(field);
    setConfirmAction(() => handleVisibilityOfField);
    setIsOpen(true);
  };

  return (
    <>
      {!loader3 && allFields?.length > 0 && (
        <>
          <div className="contact-list-view mb-7">
            <table id="datable_1" className="table text-center">
              <thead>
                <tr className="nowrap">
                  <th>No.</th>
                  {/* <th className="text-start">Type</th> */}
                  <th>Field Name</th>
                  <th>Csv Column</th>
                  <th>Input Type</th>
                  <th>Required</th>
                  <th>Placement</th>
                  <th>Visibility</th>
                  <th>Last Updated</th>
                  <th>Action By</th>
                  {isModuleReadWriteAccessForSetting && <th>Action</th>}
                </tr>
              </thead>
              <tbody>
                {allFields?.map((data, index) => {
                  return (
                    <tr>
                      <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                      {/* <td className="text-start">{data?.feildFor}</td> */}
                      <td>{data?.feildName}</td>
                      <td>
                        {data?.csvColumnName}{" "}
                        <CustomTooltip
                          text={
                            copy?.isTrue && copy?.item === data?.csvColumnName
                              ? "Copied"
                              : "Click to copy"
                          }
                          placement="right"
                          additional="font-1"
                        >
                          <div
                            className="text-center"
                            onClick={() => {
                              handleCopy(data?.csvColumnName);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                              fill="currentColor"
                              class="bi bi-copy text-primary"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                              />
                            </svg>
                          </div>
                        </CustomTooltip>
                      </td>
                      <td className="text-capitalize">{data?.feildType}</td>
                      <td>{data?.isRequired ? "True" : "False"}</td>
                      <td className="text-capitalize">{data?.placement}</td>
                      <td>
                        <div class="form-check form-switch d-flex justify-content-center">
                          <input
                            className={`form-check-input btn ${
                              data?.isVisible === true
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            disabled={!isModuleReadWriteAccessForSetting}
                            checked={data?.isVisible}
                            onClick={() => handleToggleVisibility(data)}
                          />
                        </div>
                      </td>
                      <td>{dateAndTimeFormatter(data?.updatedAt)}</td>
                      <td>
                        {data?.createdBy ? (
                          <>
                            <Avatar
                              name={data?.createdBy?.name}
                              count={1}
                              image={data?.createdBy?.image}
                              size="xxs"
                              color="primary"
                            />
                            &nbsp;
                            {getName(data?.createdBy, user?._id)}
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      {isModuleReadWriteAccessForSetting && (
                        <td className="text-center">
                          <span className="feather-icon pointer">
                            <i
                              data-bs-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className="ri-more-fill"
                            ></i>
                            <div className="dropdown-menu text-start">
                              <div
                                className="dropdown-item d-flex align-items-center text-start"
                                onClick={() => {
                                  seteditData(data);
                                  setdynamicFieldsModal(true);
                                }}
                              >
                                <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                                  <span className="btn-icon-wrap">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-pencil"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                    </svg>
                                  </span>
                                </span>
                                Edit
                              </div>
                            </div>
                          </span>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <FooterPagination
            setpageLimit={setpageLimit}
            pageLimit={pageLimit}
            setPage={setPage}
            page={page}
            totalPages={totalPages}
            count={count}
            dataLength={allFields?.length}
            align={"end"}
          />
        </>
      )}

      {!loader3 && allFields?.length === 0 && (
        <NoData title="No Fields Added" />
      )}
      {loader3 && <DataLoader title="data is loading..." />}

      <ConfirmModal
        isOpen={isOpen}
        onClose={handleClose}
        text={
          editData?.isVisible
            ? "Are You Sure You Want To Hide This Field"
            : "Are You Sure You Want To Show This Field"
        }
        loader={loader}
        errorMessage={errorMessage}
        handleConfirm={handleVisibilityOfField}
      />
    </>
  );
}

export default CrmDynamicFields;
