import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import RawMaterial from "../../pages/InventoryManagement/RawMaterial/RawMaterial";
import StockDetails from "../../pages/InventoryManagement/RawMaterial/StockDetails/StockDetails";

const RawMaterialRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <RawMaterial />,
    },
    {
      path: "rawmaterial",
      element: <RawMaterial />,
    },
    {
      path: "rawmaterial/stockdetails/:id",
      element: <StockDetails />,
    },
  ],
};

export default RawMaterialRoutes;
