import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../pages/Authenticate/Login/Login";
import Reset from "../../pages/Authenticate/Reset/Reset";
import Signup from "../../pages/Authenticate/Signup/Signup";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />
      <Route exact path="/reset" element={<Reset />} />
    </Routes>
  );
};

export default AuthRoutes;
