import React from "react";
import allModules from "../../../Navigation/AllModules";
import { getCurrentWorkspaceId } from "../../../global-functions/globalFunctions";

function SubModuleTable(props) {
  const {
    readAccessItems,
    setreadAccessItems,
    readWriteAcessItems,
    setreadWriteAcessItems,
    selectReadAccessItems,
    selectReadWriteAccessItems,
    selectedModule,
    moduleName,
  } = props;

  const isAllReadWriteAccessItems = (items) => {
    return items?.subItems?.every((i) => readWriteAcessItems?.includes(i.name));
  };

  const isAllReadAccessItems = (items) => {
    return items?.subItems?.every((i) => readAccessItems?.includes(i.name));
  };

  return (
    <div className="todo-body">
      <div data-simplebar>
        {getCurrentWorkspaceId() && (
          <div className="todo-list-view">
            {allModules?.[0]?.menuItems
              ?.filter(
                (m) =>
                  m?.subItems && m.isRole === true && m?.name === moduleName
              )
              .map((items, index) => {
                const showSelectedModuleName =
                  !selectedModule || selectedModule === module?.name;
                return (
                  showSelectedModuleName && (
                    <div className="collapse-simple" key={index}>
                      <div className="card">
                        <div className="card-header">
                          <div
                            role="button"
                            data-bs-toggle="collapse"
                            href="#fm_collapse_static"
                            aria-expanded="false"
                          >
                            <h5 className="mb-0">
                              <span style={{ marginRight: 10 }}>
                                {" "}
                                {items?.svg}
                              </span>
                              {items?.name}
                            </h5>
                          </div>
                        </div>
                        <div
                          id={`fm_collapse_${items?.name}`}
                          className="collapse show"
                        >
                          <div className="row gx-3 row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-2">
                            {items?.subItems?.length > 0 && (
                              <table
                                id="datable_1"
                                className="table nowrap w-100 mb-5"
                              >
                                <thead style={{ textAlign: "center" }}>
                                  <tr>
                                    <th>Sr No.</th>
                                    <th>Submodule Name</th>
                                    <th>
                                      Read &nbsp;
                                      <input
                                        className="form-check-input success"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        checked={isAllReadAccessItems(items)}
                                        disabled={isAllReadWriteAccessItems(
                                          items
                                        )}
                                        onClick={() => {
                                          selectReadAccessItems(items);
                                        }}
                                      />
                                    </th>
                                    <th>
                                      Read-Write &nbsp;
                                      <input
                                        className="form-check-input success"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        checked={isAllReadWriteAccessItems(
                                          items
                                        )}
                                        disabled={isAllReadAccessItems(items)}
                                        onClick={() => {
                                          selectReadWriteAccessItems(items);
                                        }}
                                      />
                                    </th>
                                  </tr>
                                </thead>

                                <tbody style={{ textAlign: "center" }}>
                                  {items?.subItems?.map((submodule, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}.</td>
                                        <td>
                                          <span className="table-link-text link-high-em text-primary">
                                            {submodule?.name}
                                          </span>
                                        </td>
                                        <td>
                                          &nbsp;
                                          <input
                                            className="form-check-input success"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                            checked={
                                              isAllReadAccessItems(items) ||
                                              readAccessItems.includes(
                                                submodule?.name
                                              )
                                            }
                                            disabled={readWriteAcessItems.includes(
                                              submodule?.name
                                            )}
                                            key={index}
                                            onChange={() => {
                                              const newReadAccessItems = [
                                                ...readAccessItems,
                                              ];
                                              if (
                                                newReadAccessItems.includes(
                                                  submodule?.name
                                                )
                                              ) {
                                                newReadAccessItems.splice(
                                                  newReadAccessItems.indexOf(
                                                    submodule?.name
                                                  ),
                                                  1
                                                );
                                              } else {
                                                newReadAccessItems.push(
                                                  submodule?.name
                                                );
                                              }
                                              setreadAccessItems(
                                                newReadAccessItems
                                              );
                                            }}
                                          />
                                        </td>
                                        <td>
                                          <div className="fs-7">
                                            &nbsp;
                                            <input
                                              className="form-check-input success"
                                              type="checkbox"
                                              value=""
                                              id="flexCheckDefault"
                                              checked={
                                                isAllReadWriteAccessItems(
                                                  items
                                                ) ||
                                                readWriteAcessItems.includes(
                                                  submodule?.name
                                                )
                                              }
                                              disabled={readAccessItems.includes(
                                                submodule?.name
                                              )}
                                              onChange={() => {
                                                const newReadWriteAccessItems =
                                                  [...readWriteAcessItems];
                                                if (
                                                  newReadWriteAccessItems.includes(
                                                    submodule?.name
                                                  )
                                                ) {
                                                  newReadWriteAccessItems.splice(
                                                    newReadWriteAccessItems.indexOf(
                                                      submodule?.name
                                                    ),
                                                    1
                                                  );
                                                } else {
                                                  newReadWriteAccessItems.push(
                                                    submodule?.name
                                                  );
                                                }
                                                setreadWriteAcessItems(
                                                  newReadWriteAccessItems
                                                );
                                              }}
                                            />
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            {allModules?.[0]?.menuItems
              ?.filter(
                (m) =>
                  m?.isRole === true && !m?.subItems && m?.name === moduleName
              )
              .map((items, index) => {
                const isReadAccessChecked = readAccessItems.includes(
                  items.name
                );
                const isReadWriteAccessChecked = readWriteAcessItems.includes(
                  items.name
                );
                const showSelectedItemsNamae =
                  !selectedModule || selectedModule === items?.name;
                return (
                  showSelectedItemsNamae && (
                    <div
                      className="d-flex mt-3  justify-content-between"
                      key={index}
                    >
                      <h5 className="mb-0">
                        <span style={{ marginRight: 10 }}>{items?.svg}</span>
                        {items?.name}
                      </h5>
                      <div>
                        Read &nbsp;
                        <input
                          className="form-check-input success"
                          type="checkbox"
                          id={`readCheckbox_${items.name}`}
                          checked={
                            items?.name === "Spaces"
                              ? true
                              : isReadAccessChecked
                          }
                          // disabled={isReadWriteAccessChecked || !isReadWrite}
                          disabled
                          onChange={() => {
                            const newReadAccessItems = isReadAccessChecked
                              ? readAccessItems.filter(
                                  (item) => item !== items.name
                                )
                              : [...readAccessItems, items.name];

                            setreadAccessItems(newReadAccessItems);
                          }}
                        />
                      </div>
                      <div style={{ marginRight: "10%" }}>
                        Read-Write&nbsp;
                        <input
                          className="form-check-input success"
                          type="checkbox"
                          id={`readWriteCheckbox_${items.name}`}
                          checked={isReadWriteAccessChecked}
                          // onChange={() => {
                          //   const newReadWriteAccessItems =
                          //     isReadWriteAccessChecked
                          //       ? readWriteAcessItems.filter(
                          //           (item) => item !== items.name
                          //         )
                          //       : [...readWriteAcessItems, items.name];
                          //   if (isReadAccessChecked) {
                          //     readAccessItems.splice(
                          //       readAccessItems.indexOf(items?.name),
                          //       1
                          //     );
                          //   }
                          //   setreadWriteAcessItems(newReadWriteAccessItems);
                          // }}
                          onChange={() => {
                            const newReadWriteAccessItems =
                              isReadWriteAccessChecked
                                ? readWriteAcessItems.filter(
                                    (item) => item !== items.name
                                  )
                                : [...readWriteAcessItems, items.name];

                            // Check if it's the "Spaces" module
                            if (items.name === "Spaces") {
                              const newReadAccessItems =
                                isReadWriteAccessChecked
                                  ? [...readAccessItems, "Spaces"]
                                  : readAccessItems.filter(
                                      (item) => item !== "Spaces"
                                    );
                              setreadAccessItems(newReadAccessItems);
                            }

                            setreadWriteAcessItems(newReadWriteAccessItems);
                          }}
                        />
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
}

export default SubModuleTable;
