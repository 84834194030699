import React from "react";

function OtherSettingTable({
  businessDetails,
  seteditAccountDetails,
  setaddAccountModal,
}) {
  return (
    <div className="contact-list-view">
      <table id="datable_1" className="table nowrap w-100 mb-5">
        <thead>
          <tr className="text-center">
            <th>No</th>
            <th style={{ textAlign: "left" }}>Account Holder Name</th>
            <th>Account Number</th>
            <th>IFSC Code </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {businessDetails?.businessId?.bankAccounts?.map((bnk, index) => (
            <tr className="text-center">
              <td>{index + 1} .</td>
              <td style={{ textAlign: "left" }}>
                <span
                  className="d-inline-block text-truncate text-capitalize text-dark"
                  style={{ width: "200px" }}
                >
                  {bnk?.accountHolderName}
                </span>
              </td>
              <td>{bnk?.accountNumber}</td>
              <td>{bnk?.ifscCode}</td>

              <td
                onClick={() => {
                  setaddAccountModal(true);
                  seteditAccountDetails(bnk);
                }}
              >
                <span className="btn-icon-wrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="20"
                    fill="currentColor"
                    class="bi bi-pencil-square text-primary pointer"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path
                      fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default OtherSettingTable;
