import React, { useEffect, useState } from "react";
import InvoiceDetailsHeader from "./InvoiceDetailsHeader";
import InvoiceDetailsBody from "./InvoiceDetailsBody";
import { startApiCall } from "../../../../global-functions/globalFunctions";
import { useParams } from "react-router-dom";
import ApiCall from "../../../../api/ApiCall";
import { InvoicesRelatedEndPoints } from "../../../../api/Endpoints";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import AddPayment from "./AddPayment";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

function InvoiceDetails() {
  const { allModuleAccess } = useSelector((state) => state);
  const { id } = useParams();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);

  const isModuleReadWriteAccessInvoice = allModuleAccess?.[PRIVATEROUTES.INVOICES]

  // GET INVOICE DETAILS FUNCTION API CALL
  const handleGetInvoiceDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      invoiceId: id,
    };
    const res = await ApiCall(
      "post",
      InvoicesRelatedEndPoints.getInvoiceById,
      data
    );
    if (res?.success) {
      setInvoiceDetails(res?.invoice);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  // GET TRANSACTIONS FUNCTION API CALL
  const handleGetTransactions = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 50,
      filters: {
        invoiceId: id,
      },
    };
    const res = await ApiCall(
      "post",
      InvoicesRelatedEndPoints.getTransactions,
      data
    );
    if (res?.success) {
      setAllTransactions(res?.transaction?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetInvoiceDetails();
    handleGetTransactions();
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <InvoiceDetailsHeader
            handleGetInvoiceDetails={handleGetInvoiceDetails}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && (
                <InvoiceDetailsBody
                  invoiceDetails={invoiceDetails}
                  allTransactions={allTransactions}
                  handleGetTransactions={handleGetTransactions}
                  loader2={loader2}
                  isModuleReadWriteAccessInvoice={isModuleReadWriteAccessInvoice}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
            </div>
          </div>
        </div>
        <AddPayment
          id={id}
          invoiceDetails={invoiceDetails}
          handleGetTransactions={handleGetTransactions}
          handleGetInvoiceDetails={handleGetInvoiceDetails}
        />
      </div>
    </div>
  );
}

export default InvoiceDetails;
