import React from "react";
import { dateFormatter } from "../../../../global-functions/globalFunctions";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";

const SalaryInfoDetails = ({
  setInfoDetails,
  infoData,
  setInfoData,
  leavePresentDetails,
  loader2,
  salaryDetails,
  salaryData,
  daysOffCount,
}) => {
  const attendanceDeduction = leavePresentDetails?.filter(
    (data) =>
      data?.status !== "holiday" &&
      data?.status !== "present" &&
      data?.isPaidLeave === false
  );
  // CALCULATE TOTAL WORKING HOURS
  const calculateTotalWorkingHrs = leavePresentDetails
    ?.filter(
      (data) =>
        // data?.status === "half_day" ||
        data?.status === "present" &&
        typeof salaryDetails?.workingHours === "number" &&
        typeof data?.verifiedHours === "number" &&
        salaryDetails?.workingHours > data?.verifiedHours
    )
    ?.map((data) => salaryDetails?.workingHours - data?.verifiedHours)
    ?.reduce(
      (total, difference) =>
        total + (typeof difference === "number" ? difference : 0),
      0
    );

  const totalWeekoffSalary = daysOffCount * salaryData?.salary?.toFixed(2);

  const handleReset = () => {
    setInfoDetails(false);
    setInfoData({ type: "" });
  };

  // infodata?.type === "Hr"
  const totalHrDeductions = infoData?.deduction?.reduce(
    (sum, data) => sum + data?.amount,
    0
  );

  // infodata?.type === "payable"
  const totalPresentSalaryCalculate = leavePresentDetails?.reduce(
    (sum, data) => sum + data?.payableSalary,
    0
  );

  // SALARY CALCULATE FOR BOTH PRESSENT AND WEEK OFF
  const presentAndWeekOffCalculate =
    totalPresentSalaryCalculate + totalWeekoffSalary;

  // CALCULATE TOTAL DEDUCTIONS AMOUNT
  const totalSalaryForLeave = leavePresentDetails
    ?.filter(
      (data) => data?.status !== "holiday" && data?.status !== "present"
      // && data?.status !== "half_day"
    )
    ?.reduce((sum, data) => sum + data?.deduction, 0);

  // CALCULATE DEDUCTIONS AMOUNT IF VERIFIEDHOUS < WORKINGHOURS
  const totalDeductionsForPresent = leavePresentDetails
    ?.filter(
      (data) => data?.status === "present"
      // || data?.status === "half_day"
    )
    ?.reduce((sum, data) => sum + data?.deduction, 0);

  const totalLeaveDeductions = totalSalaryForLeave + totalDeductionsForPresent;

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: 650 }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={handleReset}>
              <span aria-hidden="true">×</span>
            </button>
            <div className="title title-sm text-primary text-uppercase mt-1 mb-3">
              <span>
                {infoData?.type === "Hr"
                  ? "Other Deductions"
                  : infoData?.type === "payable"
                  ? "Payable Salary Details"
                  : "Attendance Deductions"}
              </span>
            </div>
            <div className="border-bottom mb-3"></div>
            <div
              className="ps-2 pe-2"
              style={{ maxHeight: 400, overflow: "auto" }}
            >
              {!loader2 && (
                <table
                  id="datable_1"
                  className="table table-bordered no-wrap w-100"
                >
                  {infoData?.type === "deduction" ? (
                    <>
                      {attendanceDeduction?.length > 0 && (
                        <thead>
                          <tr className="text-center">
                            <th>Date</th>
                            <th>
                              {infoData?.type === "Hr" ? "Reason" : "Status"}
                            </th>
                            {infoData?.type === "Hr" && <th>Amount</th>}
                            {infoData?.type !== "Hr" && <th>Verified Hours</th>}
                          </tr>
                        </thead>
                      )}
                    </>
                  ) : (
                    <thead>
                      <tr className="text-center">
                        <th>Date</th>
                        <th>{infoData?.type === "Hr" ? "Reason" : "Status"}</th>
                        {infoData?.type === "Hr" && <th>Amount</th>}
                        {infoData?.type !== "Hr" && <th>Verified Hours</th>}
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    {infoData?.type === "Hr" && (
                      <>
                        {infoData?.deduction?.map((data) => {
                          return (
                            <tr className="text-center">
                              <td>{dateFormatter(data?.date)}</td>
                              <td>
                                <span className="text-capitalize">
                                  {data?.name}
                                </span>
                              </td>
                              <td>₹ {data?.amount}</td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                    {infoData?.type === "payable" && (
                      <>
                        {leavePresentDetails
                          ?.filter(
                            (data) =>
                              data?.status !== "absent" ||
                              data?.isPaidLeave === true
                          )
                          ?.map((data) => {
                            return (
                              <tr className="text-center">
                                <td>{dateFormatter(data?.date)}</td>
                                <td>
                                  <span className="text-capitalize">
                                    {data?.status === "half_day"
                                      ? "Half day"
                                      : data?.status === "absent" &&
                                        data?.isPaidLeave === true
                                      ? `${data?.status}(paid leave)`
                                      : data?.status}
                                  </span>
                                </td>
                                {/* <td>₹ {data?.payableSalary?.toFixed(2)}</td> */}
                                <td>
                                  {data?.verifiedHours === 0
                                    ? "0"
                                    : `${data?.verifiedHours} hrs`}
                                </td>
                              </tr>
                            );
                          })}
                      </>
                    )}
                    {infoData?.type === "deduction" && (
                      <>
                        {attendanceDeduction?.map((data) => {
                          return (
                            <tr className="text-center">
                              <td>{dateFormatter(data?.date)}</td>
                              <td>
                                <span className="text-capitalize">
                                  {data?.isLeave === true
                                    ? "Leave"
                                    : data?.status === "half_day"
                                    ? "Half day"
                                    : data?.status}
                                </span>
                              </td>
                              {/* <td>₹ {data?.deduction?.toFixed(2)}</td> */}
                              <td>
                                {data?.verifiedHours === 0
                                  ? "0"
                                  : `${data?.verifiedHours} hrs`}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </table>
              )}
              {loader2 && infoData?.type !== "Hr" && <DataLoader />}
              {/* {!loader2 &&
                (totalSalaryForLeave === 0 || totalPresentSalaryCalculate === 0) &&
                "No Data Found"} */}
            </div>
            {!loader2 && infoData?.type === "payable" && (
              <>
                {salaryDetails?.wageType !== "weeklyOff" && (
                  <div className="d-flex justify-content-center mt-2">
                    <div
                      className="alert alert-info text-primary fs-7"
                      role="alert"
                    >
                      <strong>
                        No. of Weekoff days : {daysOffCount}
                      </strong>{" "}
                      .
                      <strong>
                        Total Salary For Weekoff days : ₹{" "}
                        {totalWeekoffSalary?.toFixed(2)}
                      </strong>
                    </div>
                  </div>
                )}
              </>
            )}
            {!loader2 && infoData?.type === "deduction" && (
              <>
                {calculateTotalWorkingHrs !== 0 && (
                  <div className="d-flex justify-content-center mt-2">
                    <div
                      className="alert alert-info text-primary fs-7"
                      role="alert"
                    >
                      <strong>
                        Your hours fall short (
                        {calculateTotalWorkingHrs?.toFixed(2)} hrs), resulting
                        in a ₹ {totalDeductionsForPresent?.toFixed(2)}{" "}
                        deduction. Kindly ensure fulfillment to avoid future
                        deductions.
                      </strong>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="d-flex justify-content-end align-content-end me-2">
              <span className="text-dark">
                Total Amount : ₹{" "}
                {infoData?.type === "Hr"
                  ? totalHrDeductions?.toFixed(2)
                  : infoData?.type === "payable"
                  ? salaryDetails?.wageType !== "weeklyOff"
                    ? presentAndWeekOffCalculate?.toFixed(2)
                    : totalPresentSalaryCalculate?.toFixed(2)
                  : totalLeaveDeductions?.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryInfoDetails;
