import React, { useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { startApiCall } from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import * as endPoint from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/Error/ErrorMessage";

const EditComplaint = ({
  handleCloseEditModal,
  complaint,
  setcomplaintVar,
  isEditModal,
}) => {
  const [loader, setloader] = useState(false);
  const [complaintTitle, setComplaintTitle] = useState(complaint?.title);
  const [errorMessage, seterrorMessage] = useState("");
  const [complaintDescription, setcomplaintDescription] = useState(
    complaint?.description
  );

  const handleEditComplaint = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    if (complaint?.status === "closed" || complaint?.status === "cancelled") {
      toast.error("action not permitted");
      handleCloseEditModal();
      setloader(false);
    } else {
      const sendData = {
        title: complaintTitle,
        description: complaintDescription,
        complaintId: complaint?._id,
      };
      const res = await ApiCall(
        "post",
        endPoint.complaintEndPoints.editComplaint,
        sendData
      );
      if (res?.success) {
        setcomplaintVar(res?.complaint);
        handleCloseEditModal();
        toast.success("Edit complaint successfully!");
        setloader(false);
      } else {
        seterrorMessage(res?.error);
        setloader(false);
      }
    }
  };

  return (
    <div
      id="confirm"
      className={`modal fade add-new-contact ${isEditModal ? "show" : ""}`}
      tabIndex="-1"
      role="dialog"
      style={{
        display: isEditModal ? "block" : "none",
        pointerEvents: loader ? "none" : "",
      }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseEditModal}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-3">Edit Grievance</h5>
            <form onSubmit={handleEditComplaint}>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Title </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      value={complaintTitle}
                      onChange={(event) =>
                        setComplaintTitle(event.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      rows="4"
                      required
                      onChange={(e) => {
                        setcomplaintDescription(e.target.value);
                      }}
                      value={complaintDescription}
                    ></textarea>
                  </div>
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseEditModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-md btn-primary ">
                  {loader ? <ApiLoader /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditComplaint;
