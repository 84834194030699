import React, { useEffect, useState } from "react";
import MoreDetailsCard from "./MoreDetailsCard";
import {
  getCurrentWorkspaceId,
  monthNames,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import { attendanceRelatedEndPoints } from "../../../../api/Endpoints";
import { useLocation, useParams } from "react-router-dom";
import StatCard from "./StatCard";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import AttendanceDetailsHeader from "./AttendanceDetailsHeader";
import AttendanceDetailsCalendar from "./AttendanceCalendar";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";
import NoData from "../../../../components/NoData/NoData";
import AttendanceDetailsSkeleton from "./AttendanceDetailsSkeleton";

const AttendanceDetails = () => {
  const { id } = useParams();
  const { allModuleAccess } = useSelector((state) => state);
  const location = useLocation();
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [attendanceDetailsCount, setattendanceDetailsCount] = useState([]);
  const [attendanceDetails, setattendanceDetails] = useState([]);
  const [attendanceOverview, setattendanceOverview] = useState([]);
  const today = new Date();
  const isModuleReadWriteAccessForAttendance =
    allModuleAccess?.[PRIVATEROUTES.ATTENDANCE];

  /********** CALENDER *********/
  const currentDate = new Date();
  const [presentDays, setpresentDays] = useState([]);
  const [halfDay, sethalfDay] = useState([]);
  const [absentDays, setabsentDays] = useState([]);
  const [holiday, setholiday] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    location?.state?.selectedDate
  );
  const [leavedays, setleavedays] = useState([]);
  const selectedMonthIndex = monthNames.indexOf(location?.state?.selectedMonth);
  const selectedDateofMonth = new Date(
    location?.state?.selectedDate
  ).getMonth();
  const [currentMonth, setCurrentMonth] = useState(
    location?.state?.selectedMonth
      ? selectedMonthIndex
      : location?.state?.selectedDate
      ? selectedDateofMonth
      : currentDate.getMonth()
  );
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
  const month = currentMonth + 1;
  const startDate = `${currentYear}-${month.toString().padStart(2, "0")}-01`;
  const lastDay = new Date(currentYear, month, 0).getDate();
  const endDate = `${currentYear}-${month.toString().padStart(2, "0")}-${lastDay
    .toString()
    .padStart(2, "0")}`;

  const handleGetAttendanceDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      // leaveFilter: {
      //   employeeId: id,
      //   businessId: getCurrentWorkspaceId(),
      //   startDate: startDate,
      //   endDate: endDate,
      // },
      attendanceFilters: {
        employeeId: id,
        businessId: getCurrentWorkspaceId(),
        startDate: startDate,
        endDate: endDate,
      },
      page: 1,
      limit: 500,
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.attendanceDetails,
      data
    );
    if (res?.success) {
      setattendanceDetailsCount(res?.attendanceDetails?.totalCounts);
      setattendanceDetails(res?.attendanceDetails);
      setloader(false);
    } else {
      setloader(false);
    }
  };
  useEffect(() => {
    let temp1 = [];
    let temp2 = [];
    let temp3 = [];
    let temp4 = [];
    let temp5 = [];
    attendanceDetails?.present?.map((pd) => {
      temp1.push(pd?.split("T")[0]);
    });

    attendanceDetails?.leave?.map((ld) => {
      temp2.push(ld?.split("T")[0]);
    });
    attendanceDetails?.halfday?.map((ld) => {
      temp3.push(ld?.split("T")[0]);
    });
    attendanceDetails?.absent?.map((ld) => {
      temp4.push(ld?.split("T")[0]);
    });
    attendanceDetails?.holiday?.map((ld) => {
      temp5.push(ld?.split("T")[0]);
    });

    setpresentDays(temp1);
    setleavedays(temp2);
    sethalfDay(temp3);
    setabsentDays(temp4);
    setholiday(temp5);
  }, [attendanceDetails]);

  useEffect(() => {
    handleGetAttendanceDetails();
  }, [currentMonth]);

  const handleGetAttendanceOverview = async () => {
    startApiCall(null, setloader2);
    const data = {
      employeeId: id,
      businessId: getCurrentWorkspaceId(),
      dateFordetails: selectedDate ? selectedDate : currentDate,
      dateForHourCount: selectedDate ? selectedDate : currentDate,
      dateForSessions: selectedDate ? selectedDate : currentDate,
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.attendanceOverview,
      data
    );
    if (res?.success) {
      setattendanceOverview(res?.salary);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAttendanceOverview();
    }
  }, [selectedDate]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          {attendanceDetails?.staff && !loader && !loader2 && (
            <AttendanceDetailsHeader
              attendanceDetails={attendanceDetails}
              handleGetAttendanceDetails={handleGetAttendanceDetails}
              handleGetAttendanceOverview={handleGetAttendanceOverview}
              currentYear={currentYear}
              currentMonth={currentMonth}
              selectedDate={selectedDate}
              isModuleReadWriteAccessForAttendance={
                isModuleReadWriteAccessForAttendance
              }
            />
          )}
          {(loader || loader2) && (
            <div className="loading-skeleton d-flex justify-content-between p-3 gap-5 border-bottom">
              <div className="bg-skeleton h-40p w-20"></div>
              <div className="bg-skeleton h-40p w-20"></div>
            </div>
          )}
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view container">
                {attendanceDetails?.staff && !loader && !loader2 && (
                  <div className="row w-100">
                    <div className="col-lg-5 mb-3">
                      <AttendanceDetailsCalendar
                        attendanceDetails={attendanceDetails}
                        currentDate={currentDate}
                        currentMonth={currentMonth}
                        currentYear={currentYear}
                        presentDays={presentDays}
                        halfDay={halfDay}
                        setCurrentYear={setCurrentYear}
                        setCurrentMonth={setCurrentMonth}
                        firstDayOfMonth={firstDayOfMonth}
                        absentDays={absentDays}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        leavedays={leavedays}
                        holiday={holiday}
                      />
                    </div>
                    <div className="col-lg-7 justify-content-center">
                      <MoreDetailsCard
                        attendanceDetailsCount={attendanceDetailsCount}
                        attendanceDetails={attendanceDetails}
                        attendanceOverview={attendanceOverview}
                        today={today}
                        startDate={startDate}
                        selectedDate={selectedDate}
                        holiday={holiday}
                      />
                      <StatCard
                        attendanceOverview={attendanceOverview}
                        attendanceDetailsCount={attendanceDetailsCount}
                        attendanceDetails={attendanceDetails}
                        today={today}
                        startDate={startDate}
                      />
                    </div>
                  </div>
                )}
                {(loader || loader2) && <AttendanceDetailsSkeleton />}
                {!loader && !loader2 && !attendanceDetails?.staff && <NoData />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceDetails;
