import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToManageStockDetails } from "../../../Navigation/Navigation";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  convertAmountLocal,
  getName,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";

const RawMaterialTable = ({
  allMaterial,
  page,
  pageLimit,
  setEditMaterial,
  isModuleReadWriteAccessForRawMaterial,
  setisChangeStockModal,
  setselectedItem,
  stockType
}) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  return (
    <div className="contact-list-view">
      <table id="datable_1" className="table nowrap w-100 mb-8">
        <thead>
          <tr className="text-center">
            <th>No .</th>
            <th style={{ textAlign: "left" }}>Name</th>
            <th>Status</th>
            <th>Code</th>
            <th>Category</th>
            <th>Current Stock</th>
            <th>Price / Unit</th>
            <th>Created By</th>
            {isModuleReadWriteAccessForRawMaterial && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {allMaterial?.map((inv, index) => {
            return (
              <tr className="text-center" key={index}>
                <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                <td style={{ textAlign: "left" }}>
                  <span
                    className="text-capitalize text-truncate text-link"
                    style={{ width: "300px" }}
                    onClick={() => {
                      localStorage.setItem("rawMaterialPage", page)
                      localStorage.setItem("rawMaterialPageLimit", pageLimit)
                      navigate(navigateToManageStockDetails({ id: inv?._id }), {
                        state: {
                          name: inv?.name,
                          code: inv?.inventoryItemCode,
                          unit: inv?.unit?.name,
                          unitId: inv?.unit?._id,
                        },
                      });
                    }}
                  >
                    {inv?.name}
                  </span>
                </td>
                <td>
                  {inv?.currentStock <= inv?.lowInventoryCount ? (
                    <span class="badge badge-pill badge-danger pulse">Low</span>
                  ) : (
                    <span
                      style={{ backgroundColor: "#07bc0c" }}
                      class="badge badge-pill badge-success"
                    >
                      IN
                    </span>
                  )}
                </td>

                <td> {inv?.inventoryItemCode}</td>

                <td>{inv?.category}</td>
                <td>
                  <div className="d-flex justify-content-evenly pointer">
                    {isModuleReadWriteAccessForRawMaterial && (
                      <span
                        onClick={() => {
                          setisChangeStockModal("out");
                          setselectedItem(inv);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#change_stock"
                        className="border px-2 me-2 bg-danger"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          fill="#FFFFFF"
                          className="bi bi-dash-lg mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                          />
                        </svg>
                      </span>
                    )}
                    <p className="w-100p text-primary fw-semibold">
                      {inv?.currentStock?.toFixed(2)}
                    </p>
                    {isModuleReadWriteAccessForRawMaterial && (
                      <span
                        onClick={() => {
                          setisChangeStockModal("in");
                          setselectedItem(inv);
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#change_stock"
                        className="border px-2 ms-2 bg-success"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="#FFFFFF"
                          className="bi bi-plus-lg mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                </td>
                <td className="text-dark">
                  <span className="font-mon font-3">
                    {convertAmountLocal(inv?.costPricePerUnit) || "NA"}
                  </span>{" "}
                  /
                  <span className="font-2 text-secondary">
                    {inv?.unit?.name}
                  </span>
                </td>
                <td>
                  {inv?.createdBy ? (
                    <>
                      <Avatar
                        name={inv?.createdBy?.name}
                        count={1}
                        image={inv?.createdBy?.image}
                        size="xxs"
                        color="primary"
                      />
                      &nbsp;
                      {getName(inv?.createdBy, user?._id)}
                    </>
                  ) : (
                    "-"
                  )}
                </td>

                {/* <span className="feather-icon pointer">
                    <i
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="ri-more-fill"
                    ></i>
                    <div className="dropdown-menu">
                      <div
                        className="dropdown-item"
                        onClick={() =>
                          navigate(
                            navigateToManageStockDetails({ id: inv?._id }),
                            {
                              state: {
                                name: inv?.name,
                                code: inv?.inventoryItemCode,
                                unit: inv?.unit?.name,
                                unitId: inv?.unit?._id,
                              },
                            }
                          )
                        }
                      >
                        <span
                          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                        >
                          <span className="btn-icon-wrap">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              class="bi bi-info-circle"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                            </svg>
                          </span>
                        </span>
                        View details
                      </div>
                      {isModuleReadWriteAccessForRawMaterial && (
                        <div
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#add_raw_material"
                          onClick={() => {
                            setEditMaterial(inv);
                          }}
                        >
                          <span
                            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                          >
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </span>
                          </span>
                          Edit material
                        </div>
                      )}
                    </div>
                  </span> */}
                  
                {isModuleReadWriteAccessForRawMaterial && (
                  <td>
                    <span
                      className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#add_raw_material"
                      onClick={() => {
                        setEditMaterial(inv);
                      }}
                    >
                      <span className="btn-icon-wrap">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </span>
                    </span>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RawMaterialTable;
