import React from "react";

const OrderApprovalHeader = (props) => {
  const {
    searchKey,
    setsearchKey,
    endDate,
    setendDate,
    startDate,
    setstartDate,
    loader,
    handleResetFilters,
    currentDate,
    handleGetOrders,
    typeFilter,
    settypeFilter
  } = props;

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <a className="todoapp-title link-dark">
          <h5 className="header-title-font mt-2 ">Order Approvals</h5>
        </a>
      </div>
      <div className="todo-options-wrap">
        <form className="d-lg-block d-sm-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search Order"
            value={searchKey}
            onChange={(e) => {
              setsearchKey(e.target.value);
            }}
          />
        </form>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <div className="me-2">
          <select
            value={typeFilter}
            onChange={(e) => settypeFilter(e.target.value)}
            className="form-select form-select"
          >
            <option value="pending">Pending</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
        <input
          className="form-control w-150p"
          name="single-date-pick"
          type="date"
          value={startDate}
          onChange={(e) => {
            setstartDate(e.target.value);
          }}
          max={endDate}
        />
        <p className="mt-2 ms-2 me-2 fw-bold text-dark">to</p>
        <input
          className="form-control w-150p"
          name="single-date-pick"
          type="date"
          value={endDate}
          onChange={(e) => {
            setendDate(e.target.value);
          }}
          min={startDate}
          max={currentDate}
        />
        <button
          disabled={loader}
          onClick={handleGetOrders}
          className="btn btn-primary btn-xs btn-outline-light flex-shrink-0 d-lg-inline-block   ms-2 pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            fill="#000"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
        </button>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleResetFilters}>
            <span className="feather-icon">
              <i
                className="ri-refresh-line"
                title="Refresh Transaction list"
              ></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default OrderApprovalHeader;
