import React from "react";

const Pagination = (props) => {
  const numbers = [10, 30, 50, 80,100];
  const {
    page,
    setPage,
    pageLimit,
    setpageLimit,
    totalPages,
    count,
    dataLength,
    align,
  } = props;

  const handlePageChange = (isIncreament) => {
    setPage(isIncreament ? page + 1 : page - 1);
  };
  const handlePaginationCalculation = () => {
    let start = (page - 1) * pageLimit + 1;
    let end = start + dataLength - 1;
    let overall = `${start}-${end} of ${count}`;
    return overall;
  };

  return (
    <div className={`pagination-wrap justify-content-${align} pointer me-4`}>
      <span className="text-dark me-1">Row per page : </span>
      <select
        className="form-select form-select-md mw-75p me-2 pointer"
        value={pageLimit}
        onChange={(event) => {
          setPage(1);
          setpageLimit(event.target.value);
        }}
      >
        {numbers?.map((no) => {
          return <option value={no}>{no}</option>;
        })}
      </select>
      <p className="text-dark me-2">{handlePaginationCalculation()}</p>
      <div className="row ms-1">
        <ul className="pagination custom-pagination pagination-simple mb-0 justify-content-center justify-content-md-end">
          <li
            onClick={() => {
              if (page !== 1) {
                handlePageChange(false);
              }
            }}
            className={`paginate_button page-item previous ${
              page === 1 ? "disabled" : ""
            }`}
          >
            <span className="page-link">
              <i className="ri-arrow-left-s-line"></i>
            </span>
          </li>
          <li
            onClick={() => {
              if (totalPages !== page) {
                handlePageChange(true);
              }
            }}
            className={`paginate_button page-item next ${
              totalPages === page ? "disabled" : ""
            }`}
          >
            <span className="page-link">
              <i className="ri-arrow-right-s-line"></i>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;

// FOR PAGE NUMBER
{
  /* <li
className={`paginate_button page-item ${
  pg === page ? "active" : ""
}`}
>
<span className="page-link">{pg}</span>
</li> */
}
