import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Invoices from "../../pages/AccountManagement/Invoices/Invoices";
import InvoiceDetails from "../../pages/AccountManagement/Invoices/InvoiceDetails/InvoiceDetails";
import GenerateInvoice from "../../pages/AccountManagement/Invoices/GenerateInvoice/GenerateInvoice";

const InvoiceRoutes = {
  path: "/account",
  element: <Navbar />,
  children: [
    {
      path: "allinvoices",
      element: <Invoices />,
    },
    {
      path: 'invoicedetails/:id',
      element: <InvoiceDetails />,
    },
    {
      path: "generateinvoice",
      element: <GenerateInvoice />,
    },
  ],
};

export default InvoiceRoutes;
