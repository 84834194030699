import React from "react";

function BIllImagesModal({ editStock, setEditStock }) {
  return (
    <>
      <div
        id="bill_img_modal"
        className="modal fade add-new-task"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          style={{ maxWidth: "860px" }}
          role="document"
        >
          <div className="modal-content overflow-auto">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setEditStock("");
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5>Bill Images</h5>
              <div className="border-bottom mb-2"></div>
              <form>
                <div className="row">
                  <div className="col-md-4 px-2">
                    <div className="d-flex flex-column align-items-center gap-1">
                      <p className="mb-0 fw-bold text-dark text-uppercase">
                        Image 1
                      </p>

                      {editStock?.images?.[0] ? (
                        <img
                          className="p-2 w-100 h-420p"
                          style={{
                            backgroundRepeat: "no-repeat",
                            objectFit: "contain",
                            backgroundPosition: "center",
                          }}
                          src={editStock?.images?.[0]}
                          alt="Image1"
                        />
                      ) : (
                        <span className="w-100 h-420p p-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi bi-person-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 px-2">
                    <div className="d-flex flex-column align-items-center gap-1">
                      <p className="mb-0 fw-bold text-dark text-uppercase">
                        Image 2
                      </p>

                      {editStock?.images?.[1] ? (
                        <img
                          className="p-2 w-100 h-420p"
                          style={{
                            backgroundRepeat: "no-repeat",
                            objectFit: "contain",
                            backgroundPosition: "center",
                          }}
                          src={editStock?.images?.[1]}
                          alt="Image2"
                        />
                      ) : (
                        <span className="w-100 h-420p p-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi bi-person-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 px-2">
                    <div className="d-flex flex-column align-items-center gap-1">
                      <p className="mb-0 fw-bold text-dark text-uppercase">
                        Image 3
                      </p>

                      {editStock?.images?.[2] ? (
                        <img
                          className="p-2 w-100 h-420p"
                          style={{
                            backgroundRepeat: "no-repeat",
                            objectFit: "contain",
                            backgroundPosition: "center",
                          }}
                          src={editStock?.images?.[2]}
                          alt="Image3"
                        />
                      ) : (
                        <span className="w-100 h-420p p-10">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi bi-person-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BIllImagesModal;
