import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { dateAndTimeFormatter } from "../../../../global-functions/globalFunctions";

const InvoiceDetails = ({ invoiceDetails, setInvoiceModal, setEditInvoiceDetails }) => {
  return (
    <div className="d-flex flex-column gap-2">
      <div className="card rounded-7">
        <div className="card-body">
          <div className="d-flex justify-content-between ">
            <span className="fs-4" style={{ color: "#525252" }}>
              Invoice Details
            </span>
            <div>
              <span
                className="btn btn-outline-secondary rounded-7 d-flex align-items-center"
                onClick={() => {
                  setInvoiceModal(true);
                  setEditInvoiceDetails(invoiceDetails);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-pencil"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                </svg>
                &nbsp;Edit
              </span>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-2">
              <div className="d-flex align-items-center h-100">Added by :</div>
            </div>
            <div className="col-10">
              <div className="d-flex align-items-center justify-content-start gap-2">
                <Avatar
                  name={invoiceDetails?.createdBy?.name}
                  count={1}
                  image={invoiceDetails?.createdBy?.image}
                  size="sm"
                  color="primary"
                />
                <p className="fs-5 mb-0 text-dark">
                  {invoiceDetails?.createdBy?.name}
                </p>
              </div>
            </div>
            <div className="col-2">Invoice name :</div>
            <div className="col-10">
              <p className="fs-5 mb-0 text-dark">
                {invoiceDetails?.invoiceTitle}
              </p>
            </div>
            <div className="col-2">Date created :</div>
            <div className="col-10">
              <p className="mb-0 text-dark">
                {dateAndTimeFormatter(invoiceDetails?.createdAt)}
              </p>
            </div>
            <div className="col-2">Last update at :</div>
            <div className="col-10">
              <p className="mb-0 text-dark">
                {dateAndTimeFormatter(invoiceDetails?.updatedAt)}
              </p>
            </div>
            <div className="col-2">Last update by :</div>
            <div className="col-10">
            <div className="d-flex align-items-center justify-content-start gap-2">
                <Avatar
                  name={invoiceDetails?.actionBy?.name}
                  count={1}
                  image={invoiceDetails?.actionBy?.image}
                  size="xxs"
                  color="primary"
                />
                <p className="mb-0">
                {invoiceDetails?.actionBy?.name}
                </p>
              </div>
              
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
