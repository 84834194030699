import React from "react";
import { Link } from "react-router-dom";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const EmailVerify = (props) => {
  const { setEmail, email, errorMessage, loader,handleEmailVerify } = props;


  return (
    <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 position-relative mx-auto">
      <div className="auth-content py-md-0 py-8">
        <form className="w-100" onSubmit={handleEmailVerify}>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h4 className="mb-4">Forgot Password ?</h4>
              <div className="row gx-3">
                <div className="form-group col-lg-12">
                  <div className="form-label-group">
                    <label>Enter your email</label>
                  </div>
                  <input
                    className="form-control"
                    placeholder="sandip@gmail.com"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    type="text"
                    required
                  />
                </div>
              </div>

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

              <button
                type="submit"
                className="btn btn-primary btn-uppercase btn-block mt-2"
              >
                {loader ? <ApiLoader /> : "Send OTP"}
              </button>
              <p className="p-xs mt-2 text-center">
                Back to{" "}
                <Link to="/login">
                  <u>Login</u>
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailVerify;
