import React from "react";

const WorkspaceHeader = (props) => {
  const {
    isOpenSideDrawer,
    setisOpenSideDrawer,
    handleGetAllBusiness,
    handleSearchSet,
    searchKey,
    filterStatus,
    businessCount,
  } = props;

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <a className="todoapp-title link-dark">
          <h1>
            {filterStatus === ""
              ? "All Workspaces"
              : filterStatus === "approved"
              ? "Active Workspaces"
              : filterStatus === "pending"
              ? "Pending Workspaces"
              : "Reject Workspaces"}
          </h1>
          <span
            className={`badge badge-pill badge-sm badge-soft-${
              filterStatus === ""
                ? "primary"
                : filterStatus === "approved"
                ? "success"
                : filterStatus === "pending"
                ? "warning"
                : "danger"
            } ms-2`}
          >
            {businessCount ? businessCount : 0}
          </span>
          <div className="ms-3">
            <button
              className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
              data-bs-toggle="modal"
              data-bs-target="#add_new_business"
            >
              Create New Workspace
            </button>
          </div>
        </a>
      </div>
      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="refresh"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllBusiness}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>

        <form className="d-sm-block d-none me-2" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search workspace"
            onChange={handleSearchSet}
            value={searchKey}
          />
        </form>
        {/* <Pagination /> */}
      </div>
      <div
        className="hk-sidebar-togglable"
        onClick={() => {
          setisOpenSideDrawer(!isOpenSideDrawer);
        }}
      >
        {isOpenSideDrawer ? (
          <i className="ri-arrow-left-s-line"></i>
        ) : (
          <i className="ri-arrow-right-s-line"></i>
        )}
      </div>
    </header>
  );
};

export default WorkspaceHeader;
