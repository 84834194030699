import React from "react";

const SettingsHeader = ({ handleRefresh }) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1 className="header-title-font">Inventory Settings</h1>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="v-separator d-lg-inline-block d-none"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleRefresh}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default SettingsHeader;
