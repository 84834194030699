import React from "react";
import { Link } from "react-router-dom";
import { getLocationUrl } from "../../../global-functions/globalFunctions";

const LocationTable = ({
  setIsOpen,
  setIsEditLocation,
  isLocation,
  seteditLocationData,
  isModuleReadWriteAccessLocation,
}) => {
  return (
    <table id="datable_1" className="table w-100 mb-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>Location Name</th>
          <th className="text-center">Location</th>
          {isModuleReadWriteAccessLocation && (
            <th className="text-center">Action</th>
          )}
        </tr>
      </thead>
      <tbody>
        {isLocation?.map((data, index) => {
          return (
            <tr>
              <td>{index + 1} .</td>
              <td>{data?.locationName}</td>
              <td className="text-center">
                <Link
                  to={getLocationUrl(data?.latitude, data?.longitude)}
                  className="text-primary text-underline"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-geo-alt"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                  &nbsp;Check location
                </Link>
              </td>
              {isModuleReadWriteAccessLocation && (
                <td className="text-center">
                  <span className="feather-icon pointer">
                    <i
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="ri-more-fill"
                    ></i>
                    <div className="dropdown-menu text-start">
                      <div
                        className="dropdown-item d-flex align-items-center text-start"
                        onClick={() => {
                          setIsEditLocation(true);
                          seteditLocationData(data);
                        }}
                      >
                        <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                          <span className="btn-icon-wrap">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-pencil"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                            </svg>
                          </span>
                        </span>
                        Edit
                      </div>
                      <div
                        className="dropdown-item d-flex align-items-center text-start"
                        onClick={() => {
                          setIsOpen(true);
                          seteditLocationData(data);
                        }}
                      >
                        <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger">
                          <span className="btn-icon-wrap">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>
                          </span>
                        </span>
                        Delete
                      </div>
                    </div>
                  </span>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LocationTable;
