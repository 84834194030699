import React from "react";

const OrderHistoryHeader = ({
  handleRefresh,
  search,
  handleSearch,
  dateType,
  setdateType,
  setdateModal,
  selectStatus,
  setSelectStatus,
  locationData,
  handleResetFilters,
}) => {
  const currentDate = new Date();

  const allStatus = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Confirmed",
      value: "confirmed",
    },
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Cancelled",
      value: "cancelled",
    },
    {
      name: "Returned",
      value: "returned",
    },
  ];

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title link-dark">
          <h5 className="header-title-font mt-2">Order history</h5>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="me-2">
          <select
            className="form-select form-select"
            value={selectStatus}
            onChange={(e) => {
              setSelectStatus(e.target.value);
            }}
          >
            {allStatus?.map((status) => {
              return <option value={status?.value}>{status?.name}</option>;
            })}
          </select>
        </div>
        {!locationData && (
          <div className="selectable-dropdown me-1">
            <div className="dropdown selectable-dropdown">
              <span
                className={`fs-7 fw-medium  ${
                  locationData ? "text-disabled" : "text-dark"
                } text-capitalize border rounded p-2`}
                aria-expanded="false"
                type="button"
                data-bs-toggle="dropdown"
              >
                <span
                  className={` ${
                    locationData ? "text-disabled" : "text-secondary"
                  }`}
                >
                  {" "}
                  Date :&nbsp;
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-filter"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                </svg>
                &nbsp; <span className="dropdown-text">{dateType}</span>
              </span>
              <div
                role="menu"
                className="dropdown-menu"
                style={{ maxHeight: "40vh", overflow: "auto" }}
              >
                <div className="d-flex justify-content-between">
                  <div
                    className={`dropdown-item text-capitalize ${
                      dateType === "Today" ? "fw-bold" : ""
                    }`}
                    onClick={() => {
                      setdateType("Today");
                      handleResetFilters();
                    }}
                  >
                    1. Today
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div
                    className={`dropdown-item text-capitalize ${
                      dateType === "Yesterday" ? "fw-bold" : ""
                    }`}
                    onClick={() => {
                      setdateType("Yesterday");
                      handleResetFilters();
                    }}
                  >
                    2. Yesterday
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div
                    className={`dropdown-item text-capitalize ${
                      dateType === "Date Range" ? "fw-bold" : ""
                    }`}
                    onClick={() => {
                      setdateType("Date Range");
                      setdateModal(true);
                    }}
                  >
                    3. Date Range
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={() => {
            handleRefresh();
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-lg-block d-sm-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
          />
        </form>
      </div>
    </header>
  );
};

export default OrderHistoryHeader;
