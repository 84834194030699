import React from "react";

const TaskCountCards = ({ taskCount }) => {
  const cards = [
    {
      title: "Total",
      count: taskCount?.allCounts,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="24"
          viewBox="0 0 21 24"
          fill="none"
        >
          <path
            d="M14.2453 12.3797C14.5016 12.636 14.5016 13.0515 14.2453 13.3078L10.3078 17.2453C10.1847 17.3684 10.0178 17.4375 9.84375 17.4375C9.6697 17.4375 9.50278 17.3684 9.37971 17.2453L7.41096 15.2765C7.15468 15.0203 7.15468 14.6047 7.41096 14.3485C7.66724 14.0922 8.08276 14.0922 8.33904 14.3485L9.84375 15.8532L13.3172 12.3797C13.5735 12.1234 13.989 12.1234 14.2453 12.3797Z"
            fill="white"
          />
          <path
            d="M4.59375 3C4.95619 3 5.25 3.29381 5.25 3.65625V4.3125H15.75V3.65625C15.75 3.29381 16.0438 3 16.4062 3C16.7687 3 17.0625 3.29381 17.0625 3.65625V4.3125H18.375C19.8247 4.3125 21 5.48775 21 6.9375V21.375C21 22.8247 19.8247 24 18.375 24H2.625C1.17525 24 0 22.8247 0 21.375V6.9375C0 5.48775 1.17525 4.3125 2.625 4.3125H3.9375V3.65625C3.9375 3.29381 4.23131 3 4.59375 3ZM1.3125 8.25V21.375C1.3125 22.0999 1.90013 22.6875 2.625 22.6875H18.375C19.0999 22.6875 19.6875 22.0999 19.6875 21.375V8.25H1.3125Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#298DFF",
    },
    {
      title: "Open",
      count: taskCount?.pendingCounts,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
        >
          <path
            d="M10.1115 1.21002C9.90851 1.19505 9.70455 1.1875 9.5 1.1875V0C9.73376 0 9.96686 0.00862361 10.1989 0.0257357L10.1115 1.21002ZM12.4916 1.7445C12.1108 1.59758 11.7196 1.47892 11.3213 1.38947L11.5815 0.230828C12.0367 0.333049 12.4837 0.468665 12.919 0.636565L12.4916 1.7445ZM14.1182 2.58841C13.9481 2.47477 13.7743 2.36773 13.5972 2.26739L14.1825 1.23417C14.385 1.34884 14.5836 1.47117 14.7779 1.60104C14.9723 1.7309 15.1613 1.86758 15.3447 2.0107L14.6141 2.94687C14.4537 2.82164 14.2883 2.70205 14.1182 2.58841ZM16.2962 4.7136C16.0611 4.37984 15.8018 4.06384 15.5203 3.76819L16.3804 2.94937C16.702 3.28725 16.9984 3.64839 17.2671 4.02982L16.2962 4.7136ZM17.1798 6.31894C17.1015 6.12997 17.0164 5.94442 16.9249 5.7626L17.9856 5.22869C18.0902 5.43649 18.1874 5.64855 18.2769 5.86451C18.3663 6.08047 18.4475 6.29913 18.5205 6.52002L17.393 6.89253C17.3291 6.69925 17.258 6.50792 17.1798 6.31894ZM17.81 9.29601C17.8 8.88791 17.7599 8.48109 17.6901 8.07888L18.8601 7.87587C18.9399 8.33553 18.9857 8.80046 18.9971 9.26685L17.81 9.29601ZM17.6528 11.1217C17.6927 10.9211 17.7251 10.7196 17.75 10.5175L18.9286 10.6629C18.9001 10.8938 18.8631 11.1241 18.8175 11.3534C18.7719 11.5826 18.7179 11.8096 18.6559 12.0338L17.5114 11.7171C17.5657 11.5209 17.6129 11.3223 17.6528 11.1217ZM16.5228 13.9472C16.7412 13.6023 16.9339 13.2418 17.0994 12.8686L18.185 13.3498C17.9959 13.7763 17.7757 14.1883 17.5261 14.5825L16.5228 13.9472ZM15.3778 15.3778C15.5225 15.2332 15.6614 15.0836 15.7943 14.9295L16.6935 15.7051C16.5415 15.8813 16.3828 16.0522 16.2175 16.2175L15.3778 15.3778Z"
            fill="white"
          />
          <path
            d="M9.5 1.1875C8.133 1.1875 6.7871 1.52463 5.58152 2.16903C4.37593 2.81343 3.34788 3.7452 2.58841 4.88182C1.82895 6.01844 1.36152 7.32482 1.22753 8.68523C1.09354 10.0456 1.29713 11.4181 1.82025 12.6811C2.34338 13.944 3.1699 15.0584 4.22661 15.9256C5.28331 16.7929 6.53758 17.3861 7.87831 17.6528C9.21905 17.9195 10.6049 17.8514 11.913 17.4546C13.2211 17.0577 14.4112 16.3444 15.3778 15.3778L16.2175 16.2175C15.1128 17.3222 13.7527 18.1374 12.2577 18.5909C10.7627 19.0444 9.17891 19.1222 7.64664 18.8175C6.11438 18.5127 4.68093 17.8347 3.47327 16.8436C2.2656 15.8525 1.32101 14.5789 0.723147 13.1355C0.125287 11.6921 -0.107383 10.1236 0.0457475 8.56884C0.198878 7.01408 0.733082 5.52107 1.60104 4.22208C2.469 2.92309 3.64392 1.8582 5.02173 1.12175C6.39955 0.385292 7.93772 0 9.5 0V1.1875Z"
            fill="white"
          />
          <path
            d="M8.90625 3.5625C9.23417 3.5625 9.5 3.82833 9.5 4.15625V10.3429L13.3571 12.547C13.6418 12.7097 13.7407 13.0724 13.578 13.3571C13.4153 13.6418 13.0526 13.7407 12.7679 13.578L8.61167 11.203C8.42667 11.0973 8.3125 10.9006 8.3125 10.6875V4.15625C8.3125 3.82833 8.57833 3.5625 8.90625 3.5625Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#ececec",
    },
    {
      title: "Closed",
      count: taskCount?.completedCounts,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="12"
          viewBox="0 0 23 12"
          fill="none"
        >
          <path
            d="M17.0303 1.28033C17.3232 0.987437 17.3232 0.512563 17.0303 0.21967C16.7374 -0.0732233 16.2626 -0.0732233 15.9697 0.21967L6 10.1893L1.28033 5.46967C0.987437 5.17678 0.512563 5.17678 0.21967 5.46967C-0.0732233 5.76256 -0.0732233 6.23744 0.21967 6.53033L5.46967 11.7803C5.61032 11.921 5.80109 12 6 12C6.19891 12 6.38968 11.921 6.53033 11.7803L17.0303 1.28033Z"
            fill="white"
          />
          <path
            d="M10.7197 11.7803L9.375 10.4357L10.4357 9.375L11.25 10.1893L21.2197 0.21967C21.5126 -0.0732233 21.9874 -0.0732233 22.2803 0.21967C22.5732 0.512563 22.5732 0.987437 22.2803 1.28033L11.7803 11.7803C11.6397 11.921 11.4489 12 11.25 12C11.0511 12 10.8603 11.921 10.7197 11.7803Z"
            fill="white"
          />
          <path
            d="M6.53033 5.46967L7.875 6.81434L6.81434 7.875L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967Z"
            fill="white"
          />
        </svg>
      ),
      bgColor: "#00C172",
    },
  ];
  return (
    <>
      {cards.map((card, index) => (
        <div
          className="card card-border"
          key={index}
          style={{ minWidth: "200px" }}
        >
          <div className="card-body">
            <div className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between text-dark gap-1">
                <p>{card?.title}</p>
                <span
                  className="rounded-top-end rounded-bottom-start px-2 py-1 text-white"
                  style={{ backgroundColor: card?.bgColor }}
                >
                  {card?.icon}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-between text-dark gap-1">
                <p className="fw-semibold fs-5">{card?.count}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TaskCountCards;
