import React from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  getName,
} from "../../../global-functions/globalFunctions";
import LogsTab from "../../../components/LogsTab/LogsTab";

const ComplaintDetailsModal = ({
  handleCloseDetailsModal,
  complaintDetails,
  user,
}) => {
  return (
    <div className="details-modal">
      <div data-simplebar className="nicescroll-bar">
        <div className="d-flex justify-content-between">
          <span className="file-name">Grievance Details</span>
          <button
            onClick={handleCloseDetailsModal}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <ul className="nav nav-justified nav-light nav-tabs nav-segmented-tabs active-theme mt-5">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="tab"
              href="#tab_info"
            >
              <span className="nav-link-text">Details</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" href="#tab_activity">
              <span className="nav-link-text">Activity</span>
            </a>
          </li>
        </ul>
        <div className="tab-content mt-2">
          <div className="tab-pane fade show active" id="tab_info">
            <div className="collapse-simple">
              <div className="card">
                <div id="fl_info" className="collapse show">
                  <div className="card-body">
                    <ul className="fm-info">
                      <li>
                        <span>Title : </span>
                        <span>{complaintDetails?.title}</span>
                      </li>
                      <li>
                        <span>Created by :</span>
                        <span>
                          {complaintDetails?.raisedBy && (
                            <Avatar
                              name={complaintDetails?.raisedBy?.name}
                              count={1}
                              image={complaintDetails?.raisedBy?.image}
                              size="xss"
                              color="primary"
                            />
                          )}
                          &nbsp;
                          {complaintDetails?.raisedBy
                            ? getName(complaintDetails?.raisedBy, user?._id)
                            : "Anonymous"}
                        </span>
                      </li>
                      <li>
                        <span>Date Created :</span>
                        <span>
                          {dateAndTimeFormatter(complaintDetails?.createdAt)}
                        </span>
                      </li>
                      <li>
                        <span>Date Modified :</span>
                        <span>
                          {dateAndTimeFormatter(complaintDetails?.updatedAt)}
                        </span>
                      </li>

                      <li>
                        <span>Category :</span>
                        <span>{complaintDetails?.category}</span>
                      </li>

                      <li>
                        <span>Current Status :</span>
                        <span
                          className={`badge badge-soft-${
                            complaintDetails?.status === "pending"
                              ? "warning"
                              : complaintDetails?.status === "acknowledge"
                              ? "primary"
                              : "success"
                          } my-1  me-2`}
                        >
                          {complaintDetails?.status}
                        </span>
                      </li>
                      <li>
                        <span>Description :</span>
                        <span>{complaintDetails?.description}</span>
                      </li>
                      {complaintDetails?.status === "closed" && (
                        <>
                          <li className="d-flex">
                            <span>Rating :</span>
                            <span className="d-flex gap-1">
                              {complaintDetails?.rating?.rating ===
                              undefined ? (
                                "No rating"
                              ) : (
                                <>
                                  {complaintDetails?.rating?.rating}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 54 51"
                                    fill="none"
                                    className="mt-1"
                                  >
                                    <path
                                      d="M27 3.23607L32.3353 19.6565L32.5598 20.3475H33.2864H50.5519L36.5838 30.4959L35.996 30.923L36.2205 31.614L41.5559 48.0344L27.5878 37.886L27 37.459L26.4122 37.886L12.4441 48.0344L17.7795 31.614L18.004 30.923L17.4162 30.4959L3.4481 20.3475H20.7136H21.4402L21.6647 19.6565L27 3.23607Z"
                                      fill="#FFCE56"
                                      stroke="#FFCE56"
                                      stroke-width="2"
                                    />
                                  </svg>
                                </>
                              )}
                            </span>
                          </li>
                          <li>
                            <span>Review :</span>
                            <span className="text-capitalize">
                              {complaintDetails?.rating?.rating ===
                              undefined ? (
                                "No review"
                              ) : (
                                <>{complaintDetails?.rating?.review}</>
                              )}
                            </span>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="tab_activity">
            <div className="collapse-simple">
              <div className="card">
                <div className="card-header">
                  <a
                    role="button"
                    data-bs-toggle="collapse"
                    href="#act_info1"
                    aria-expanded="true"
                  >
                    Lifecycle details
                  </a>
                </div>
                <div id="act_info1" className="collapse show">
                  <div className="card-body">
                    <ul className="activity-list list-group list-group-flush">
                      {complaintDetails?.complaintLifeCycle?.map((clf) => {
                        return (
                          <li className="list-group-item">
                            <div className="media">
                              <div className="media-head">
                                <div className="avatar avatar-sm avatar-primary avatar-rounded">
                                  <Avatar
                                    name={
                                      clf?.actionBy?.name
                                        ? clf?.actionBy?.name
                                        : "A"
                                    }
                                    count={1}
                                    image={
                                      clf?.actionBy?.image
                                        ? clf?.actionBy?.image
                                        : ""
                                    }
                                    size="xs"
                                  />
                                </div>
                              </div>
                              <div className="media-body">
                                <p>
                                  <span className="text-dark">
                                    {clf?.lifeCycleName}
                                  </span>{" "}
                                  <span className="text-blue">
                                    by&nbsp;
                                    {clf?.actionBy?.name
                                      ? getName(clf?.actionBy, user?._id)
                                      : "Anonymous"}
                                  </span>
                                </p>
                                <div className="last-activity-time">
                                  {dateAndTimeFormatter(clf?.actionTime)}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplaintDetailsModal;
