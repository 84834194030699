import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Employee from "../../pages/Approval/Employee/Employee";

const EmployeeApprovalsRoutes = {
  path: "/approval",
  element: <Navbar />,
  children: [
    {
      path: '',
      element: <Employee />,
    },
    {
        path: 'employee',
        element: <Employee />,
      },
  ],
};

export default EmployeeApprovalsRoutes;