import React, { useEffect, useState } from "react";
import QuotesDetailsHeader from "./QuotesDetailsHeader";
import QuotesDetailsBody from "./QuotesDetailsBody";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { QuotesRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import { useParams } from "react-router-dom";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";

function QuotesDetails() {
  const { id } = useParams();
  const [loader, setloader] = useState(false);
  const [quoteDetailsData, setquoteDetailsData] = useState([]);

  // GET UOTE DETAILS FUNCTION API CALL
  const handleGetQuoteDetails = async () => {
    startApiCall(null, setloader);
    const data = {
      quoteId: id,
    };
    const res = await ApiCall(
      "post",
      QuotesRelatedEndPoints.getQuoteById,
      data
    );
    if (res?.success) {
      setquoteDetailsData(res?.quotes);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetQuoteDetails();
    }
  }, []);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <QuotesDetailsHeader handleGetQuoteDetails={handleGetQuoteDetails} />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && quoteDetailsData && (
                <QuotesDetailsBody quoteDetailsData={quoteDetailsData} />
              )}

              {loader && <DataLoader title="data is loading..." />}
              {!loader && !quoteDetailsData && (
                <NoData title="No Inovices Added" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuotesDetails;
