import React from "react";
import { Avatar } from "../../../../components/Avatar/Avatar";
import logo from "../../../../assets/images/logo/bizhoodfullLogo.png";
import { dateFormatter } from "../../../../global-functions/globalFunctions";

const BillDetailsBody = ({ isBillDetails }) => {
  return (
    <div className="row w-100">
      <div className="col-lg-8 col-md-7">
        <div className="card p-4 d-flex flex-column gap-2 border-ligth h-100">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex justify-content-between align-items-end gap-3">
              <img src={logo} alt="sociohoodlogo" className="mw-175p mh-150p" />
              <div className="text-primary fw-semibold fs-4 mt-2">
                Bill# {isBillDetails?.billNumber}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <p>Kolkata</p>
                <p>India</p>
                <p>9100000000</p>
                <p>testtechnixia@gmail.com</p>
              </div>
              <div>
                <div className="d-flex justify-content-between gap-5">
                  <p>Vendor :</p>
                  <span>
                    <Avatar
                      name={isBillDetails?.vendorId?.name}
                      count={1}
                      // image={isBillDetails?.vendorId?.image}
                      size="xss"
                      color="primary"
                    />
                    &nbsp;{isBillDetails?.vendorId?.name}
                  </span>
                </div>
                <div className="d-flex justify-content-between gap-5">
                  <p>Bill date :</p>
                  <p>{dateFormatter(isBillDetails?.billDate)}</p>
                </div>
                <div className="d-flex justify-content-between gap-5">
                  <p>Due date :</p>
                  <p>{dateFormatter(isBillDetails?.dueDate)}</p>
                </div>
              </div>
            </div>
          </div>

          <div
            className="simple-scrollbar"
            style={{ maxHeight: "200px", overflow: "auto" }}
          >
            <table id="datable_1" className="table nowrap w-100 mb-5">
              <thead>
                <tr className="text-center">
                  <th>No</th>
                  <th>Item Name</th>
                  <th>Quantity</th>
                  <th>Rate</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {isBillDetails?.items?.map((data, index) => {
                  return (
                    <tr className="text-center">
                      <td>{index + 1} .</td>
                      <td>
                        <span className="text-capitalize">
                          {data?.itemId?.itemName}
                        </span>
                      </td>
                      <td>{data?.quantity}</td>
                      <td>{data?.rate}</td>
                      <td>{data?.total}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-end align-items-end flex-column">
            <div className="d-flex justify-content-between gap-5">
              <p>Discount :</p>
              <p>{isBillDetails?.discount}%</p>
            </div>
            <div className="d-flex justify-content-between gap-5 text-dark">
              <p>Total :</p>
              <p>{isBillDetails?.totalAmount}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-5">
        <div className="d-flex flex-column gap-2 p-4 bg-light h-100">
          <div className="text-dark text-capitalize gap-2">
            Created By :&nbsp;
            <Avatar
              name={isBillDetails?.createdBy?.name}
              count={1}
              image={isBillDetails?.createdBy?.image}
              size="xss"
              color="primary"
            />
            &nbsp;{isBillDetails?.createdBy?.name}
          </div>
          <div className="d-flex justify-content-between gap-2">
            <span className="text-dark text-capitalize">
              Status : {isBillDetails?.status}
            </span>
            {/* <span
                        className="btn btn-sm btn-primary"
                        //   data-bs-toggle="modal"
                        //   data-bs-target="#add_payment"
                      >
                        Add Payment
                      </span> */}
          </div>
          {/* <div className="d-flex justify-content-between">
                      <h5>Payment History</h5>
                      <span
                        className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="dsdsd"
                        data-bs-original-title="Refresh"
                        style={{ marginTop: -5 }}
                      >
                        <span className="btn-icon-wrap">
                          <span className="feather-icon">
                            <i
                              className="ri-refresh-line"
                              title="Refresh business list"
                            ></i>
                          </span>
                        </span>
                      </span>
                    </div> */}
          {/* <div className="card gap-3 border-ligth p-3">
                      <div className="d-flex justify-content-between gap-3">
                        <strong className="text-dark">Amount received :</strong>
                        <strong className="text-dark">1000</strong>
                      </div>
                      <div className="d-flex justify-content-between gap-3">
                        <span>Payment date :</span>
                        <span>31/12/2023</span>
                      </div>
                      <div className="d-flex justify-content-between gap-3">
                        <span>Transaction number :</span>
                        <span>4444</span>
                      </div>
                      <div className="d-flex justify-content-between gap-3">
                        <span className="text-capitalize">Payment Mode :</span>
                        <span className="text-capitalize">Bank Transfer</span>
                      </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default BillDetailsBody;
