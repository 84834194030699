import React from "react";
import { reversesDateFormatter } from "../../global-functions/globalFunctions";

const DeadlineFilter = ({
  setDateFilter,
  deadlineFilter,
  setdeadlineFilter,
  handleRefresh,
}) => {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              className="btn-close mt-2 pe-3"
              type="button"
              onClick={() => {
                setDateFilter(false);
                setdeadlineFilter("");
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Deadline Date</h5>
            <div className="border-bottom"></div>
            <div className="row gx-3 mt-3 px-3">
              <div className="col-12">
                <div className="form-group">
                  <label className="form-label">Select Date</label>
                  <input
                    className="form-control"
                    name="single-date-pick"
                    type="date"
                    min={reversesDateFormatter(new Date())}
                    value={deadlineFilter}
                    onChange={(e) => {
                      setdeadlineFilter(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer align-items-center mt-3">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setDateFilter(false);
                  setdeadlineFilter("");
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleRefresh}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeadlineFilter;
