import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import LeaveHistory from "../../pages/StaffManagement/StaffLeave/LeaveHistory/LeaveHistory";
import LeaveHistoryDetails from "../../pages/StaffManagement/StaffLeave/LeaveHistory/LeaveHistoryDetails/LeaveHistoryDetails";

const LeaveHistoryRoutes = {
  path: "/leave",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <LeaveHistory />,
    },
    {
      path: "leavehistory",
      element: <LeaveHistory />,
    },
    {
      path: "leavehistory/leavedetails/:id/:monthname",
      element: <LeaveHistoryDetails />,
    },
  ],
};

export default LeaveHistoryRoutes;
