import React, { useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import { getCurrentWorkspaceId, startApiCall } from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { AccountRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";

const AddAccountType = ({ setAddTypeModal, allMainAccountType, handleGetSubAccount }) => {
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [typeName, setTypeName] = useState("");
  const [selectType, setSelectType] = useState("");

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setAddTypeModal(false);
    setTypeName("");
    setSelectType("");
  };

  const handleAddSubAccountType = async () => {
    startApiCall(setErrorMessage, setloader);
    const data = {
      mainAccountTypeName: selectType,
      subAcountTypeName: typeName,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      AccountRelatedEndPoints.addSubAccountType,
      data
    );
    if (res?.success) {
      handleReset();
      handleGetSubAccount();
      toast.success("Add subaccount type successfully!");
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: 450 }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-4">
              <span>Add Subaccount Type</span>
            </div>
            <div className="row gx-3">
              <div className="col-sm-6">
                <label className="form-label">Select Type</label>
                <div className="form-group">
                  <div className="todo-toolbar">
                    <select
                      className="form-select form-select-md"
                      value={selectType}
                      onChange={(e) => setSelectType(e.target.value)}
                    >
                      <option>Select type</option>
                      {allMainAccountType?.map((data) => {
                        return (
                          <option value={data?.value}>{data?.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <label className="form-label">Type Name</label>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter type name"
                    value={typeName}
                    onChange={(e) => setTypeName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <div className="modal-footer align-items-center">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleReset}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleAddSubAccountType}
              >
                {loader ? <ApiLoader /> : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccountType;
