import React, { useEffect, useState } from "react";
import {
  dateAndTimeFormatter,
  getName,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { navigateToGroupsDetails } from "../../../Navigation/Navigation";

function ParticipantsGroupsTable({
  setaddGroup,
  seteditData,
  allGroup,
  page,
  pageLimit,
  checkedGroups,
  setCheckedGroups,
  setshowCheckBox,
  showCheckBox,
  selectAllChecked,
  setSelectAllChecked,
  isModuleReadWriteAccessForParticipantGroup,
}) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const handleGroupCheckboxChange = (group) => {
    const currentPageCheckedGroups = checkedGroups[page] || [];
    const groupIndex = currentPageCheckedGroups.indexOf(group._id);

    if (groupIndex === -1) {
      const newCheckedGroups = {
        ...checkedGroups,
        [page]: [...currentPageCheckedGroups, group._id],
      };
      setCheckedGroups(newCheckedGroups);
    } else {
      const newCheckedGroups = {
        ...checkedGroups,
        [page]: [
          ...currentPageCheckedGroups.slice(0, groupIndex),
          ...currentPageCheckedGroups.slice(groupIndex + 1),
        ],
      };
      setCheckedGroups(newCheckedGroups);
    }
  };

  const handleSelectAllCheckboxChange = () => {
    let newCheckedGroups = { ...checkedGroups };
    let newSelectAllChecked = { ...selectAllChecked };

    if (newSelectAllChecked[page]) {
      newCheckedGroups[page] = [];
      newSelectAllChecked = { ...newSelectAllChecked, [page]: false };
    } else {
      newCheckedGroups[page] = allGroup.map((grp) => grp._id);
      newSelectAllChecked = { ...newSelectAllChecked, [page]: true };
    }

    setCheckedGroups(newCheckedGroups);
    setSelectAllChecked(newSelectAllChecked);
  };
  useEffect(() => {
    const currentPageCheckedGroups = checkedGroups[page] || [];
    const allGroupsOnPageChecked = allGroup.every((grp) =>
      currentPageCheckedGroups.includes(grp._id)
    );

    setSelectAllChecked((prevSelectAllChecked) => ({
      ...prevSelectAllChecked,
      [page]: allGroupsOnPageChecked,
    }));
  }, [page, allGroup, checkedGroups]);

  return (
    <div className="contact-list-view">
      <table id="datable_1" className="table nowrap w-100 mb-8">
        <thead>
          <tr>
            <th>No .</th>
            <th style={{ textAlign: "left" }}>
              {showCheckBox && (
                <input
                  type="checkbox"
                  className="form-check-input pointer"
                  onChange={handleSelectAllCheckboxChange}
                  checked={selectAllChecked[page]}
                />
              )}
              &nbsp; Group Name
            </th>
            <th className="text-center">Date Created</th>
            <th>Creator</th>
            <th>Participants</th>
            {isModuleReadWriteAccessForParticipantGroup && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {allGroup?.map((grp, index) => (
            <tr>
              <td>{(page - 1) * pageLimit + (index + 1)} .</td>
              <td style={{ textAlign: "left" }}>
                {showCheckBox && (
                  <input
                    type="checkbox"
                    className="form-check-input pointer"
                    value={grp._id}
                    onChange={() => handleGroupCheckboxChange(grp)}
                    checked={(checkedGroups[page] || []).includes(grp._id)}
                  />
                )}{" "}
                <span
                  className="text-capitalize text-truncate text-link"
                  style={{ width: "300px" }}
                  onClick={() =>
                    navigate(navigateToGroupsDetails({ id: grp?._id }), {
                      state: {
                        name: grp?.name,
                      },
                    })
                  }
                >
                  &nbsp;
                  {grp?.name}
                </span>
              </td>
              <td className="text-center text-uppercase">
                {dateAndTimeFormatter(grp?.createdAt)}
              </td>
              <td>
                <Avatar
                  name={grp?.createdBy?.name}
                  count={1}
                  image={grp?.createdBy?.image}
                  size="xxs"
                  color="primary"
                />
                &nbsp;{getName(grp?.createdBy, user?._id)}
              </td>
              <td>
                <p>Admins : {grp?.admins?.length}</p>
                <p>Managers : {grp?.managers?.length}</p>
                <p>Members : {grp?.employees?.length}</p>
              </td>

              {isModuleReadWriteAccessForParticipantGroup && (
                <td>
                  <span
                    className={`btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button ${
                      showCheckBox ? "text-disabled" : "text-primary"
                    } `}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => {
                      if (!showCheckBox) {
                        seteditData(grp);
                        setaddGroup(true);
                      } else {
                        //nothing
                      }
                    }}
                  >
                    <span className="btn-icon-wrap">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                        />
                      </svg>
                    </span>
                  </span>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ParticipantsGroupsTable;
