import React, { useEffect, useState } from "react";
import CustomerTable from "./CustomerTable";
import AddCustomer from "./AddCustomer/AddCustomer";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { CustomerRelatedEndPoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

const Customer = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [editCustomer, setEditCustomer] = useState("");
  const [search, setSearch] = useState("");
  const [customerType, setcustomerType] = useState("");

  const isModuleReadWriteAccessCustomers =
    allModuleAccess?.[PRIVATEROUTES.CUSTOMERS];

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleChangeType = (type) => {
    setcustomerType(type);
  };

  const handleGetAllCustomer = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        customerType: customerType,
      },
    };
    const res = await ApiCall(
      "post",
      CustomerRelatedEndPoints.getCustomer,
      data
    );
    if (res?.success) {
      setAllCustomers(res?.customer?.data);
      setcount(res?.customer?.totalCounts);
      settotalPages(res?.customer?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllCustomer();
    }
  }, [page, pageLimit, search, customerType]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <span className="todoapp-title  link-dark">
                <h1>All Customers</h1>
                {isModuleReadWriteAccessCustomers && (
                  <div className="ms-3">
                    <button
                      className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
                      data-bs-toggle="modal"
                      data-bs-target="#add_customer"
                    >
                      Add Customer
                    </button>
                  </div>
                )}
              </span>
            </div>
            <div className="todo-options-wrap">
              <div className="selectable-dropdown">
                <div className="dropdown selectable-dropdown">
                  <span
                    className={`fs-7 fw-medium  text-dark text-capitalize border rounded p-2`}
                    aria-expanded="false"
                    type="button"
                    data-bs-toggle="dropdown"
                  >
                    <span className="text-secondary"> Customer :&nbsp;</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-filter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                    </svg>
                    &nbsp;{" "}
                    {customerType === ""
                      ? "All"
                      : customerType === "business"
                      ? "Business"
                      : customerType === "individual"
                      ? "Individual"
                      : customerType}
                  </span>
                  <div
                    role="menu"
                    className="dropdown-menu"
                    style={{ maxHeight: "40vh", overflow: "auto" }}
                  >
                    <div className="d-flex justify-content-between">
                      <div
                        className={`dropdown-item text-capitalize ${
                          customerType === "" ? "fw-bold" : ""
                        }`}
                        onClick={() => {
                          handleChangeType("");
                        }}
                      >
                        1. All
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div
                        className={`dropdown-item text-capitalize ${
                          customerType === "business" ? "fw-bold" : ""
                        }`}
                        onClick={() => {
                          handleChangeType("business");
                        }}
                      >
                        2. Business
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div
                        className={`dropdown-item text-capitalize ${
                          customerType === "individual" ? "fw-bold" : ""
                        }`}
                        onClick={() => {
                          handleChangeType("individual");
                        }}
                      >
                        3. Individual
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="dsdsd"
                data-bs-original-title="Refresh"
              >
                <span className="btn-icon-wrap" onClick={handleGetAllCustomer}>
                  <span className="feather-icon">
                    <i
                      className="ri-refresh-line"
                      title="Refresh business list"
                    ></i>
                  </span>
                </span>
              </span>

              <div className="v-separator d-lg-inline-block d-none"></div>
              <form className="d-sm-block d-none" role="search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                />
              </form>
            </div>
          </header>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allCustomers?.length > 0 && (
                <CustomerTable
                  allCustomers={allCustomers}
                  setEditCustomer={setEditCustomer}
                  editCustomer={editCustomer}
                  handleGetAllCustomer={handleGetAllCustomer}
                  page={page}
                  pageLimit={pageLimit}
                  isModuleReadWriteAccessCustomers={isModuleReadWriteAccessCustomers}
                />
              )}
              {!loader && allCustomers?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allCustomers?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allCustomers?.length === 0 && (
                <NoData title="No customer added" />
              )}
            </div>
          </div>
        </div>
        <AddCustomer
          handleGetAllCustomer={handleGetAllCustomer}
          editCustomer={editCustomer}
          setEditCustomer={setEditCustomer}
        />
      </div>
    </div>
  );
};

export default Customer;
