import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToTaskAnalysis } from "../../../Navigation/Navigation";
import { Avatar } from "../../../components/Avatar/Avatar";

function TaskAnalysisDetailsHeader({
  user,
  locationData,
  handleGetAllTaskList,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  loader,
  currentDate,
  isModuleReadWriteAccessForTaskAnalysis,
  analyticsView,
  handleGetTask,
  setTaskData,
}) {
  const navigate = useNavigate();

  const resetDates = () => {
    localStorage.removeItem("bizStartDate");
    localStorage.removeItem("bizEndDate");
    setStartDate(currentDate);
    setEndDate(currentDate);
  };

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setStartDate(value);
    localStorage.setItem("bizStartDate", value);
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setEndDate(value);
    localStorage.setItem("bizEndDate", value);
  };

  const handleRefresh = () => {
    if (analyticsView) {
      if (startDate !== currentDate || endDate !== currentDate) {
        resetDates();
      } else {
        handleGetAllTaskList();
      }
    } else {
      if (startDate !== currentDate || endDate !== currentDate) {
        resetDates();
      } else {
        handleGetTask();
        handleGetAllTaskList();
      }
    }
  };
  return (
    <header className="todo-header">
      <span className="todoapp-title link-dark ext-capitalize">
        {isModuleReadWriteAccessForTaskAnalysis && (
          <>
            <button
              className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
              onClick={() => navigate(navigateToTaskAnalysis())}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
              <p className="mb-0">Back</p>
            </button>
            <div className="v-separator d-lg-inline-block d-none"></div>
          </>
        )}
        <Avatar
          name={
            isModuleReadWriteAccessForTaskAnalysis
              ? locationData?.name
              : user?.name
          }
          count={1}
          size="xxs"
          color="primary"
          image={
            isModuleReadWriteAccessForTaskAnalysis
              ? locationData?.image
              : user?.image
          }
        />
        &nbsp;
        <p className="text-capitalize">
          {isModuleReadWriteAccessForTaskAnalysis
            ? locationData?.name
            : user?.name}
        </p>
      </span>

      <div className="todo-options-wrap">
        <input
          className="form-control w-150p"
          name="single-date-pick"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          max={endDate}
        />
        <p className="mt-2 ms-2 me-2 fw-bold text-dark">to</p>
        <input
          className="form-control w-150p"
          name="single-date-pick"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          min={startDate}
          max={currentDate}
        />
        <button
          disabled={loader}
          onClick={analyticsView ? handleGetAllTaskList : handleGetTask}
          className="btn btn-primary btn-xs btn-outline-light flex-shrink-0 d-lg-inline-block ms-2 pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="14"
            fill="#000"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
          </svg>
        </button>

        <div className="v-separator d-lg-inline-block d-none"></div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Refresh"
          onClick={handleRefresh}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
}

export default TaskAnalysisDetailsHeader;
