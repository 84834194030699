import React from "react";
import Calendars from "../../pages/Calendar/Calendar";
import Navbar from "../../components/Navbar/Navbar";

const CalendarRoutes = {
  path: "/calendar",
  element: <Navbar />,
  children: [
    {
      path: '',
      element: <Calendars />,
    },
  ],
};

export default CalendarRoutes;