import React, { useEffect, useState } from "react";
import VendorsHeader from "./VendorsHeader";
import VendorsTable from "./VendorsTable";
import AddVendor from "./AddVendor/AddVendor";
import DataLoader from "../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../components/NoData/NoData";
import FooterPagination from "../../components/Pagination/FooterPagination";
import {
  getCurrentWorkspaceId,
  startApiCall,
  closeAllModals,
} from "../../global-functions/globalFunctions";
import ApiCall from "../../api/ApiCall";
import { VendorRelatedEndPoints } from "../../api/Endpoints";

function Vendors() {
  const [loader, setloader] = useState(false);
  const [allVendors, setAllVendors] = useState([]);
  const [editVendor, setEditVendor] = useState("");
  const [search, setSearch] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // GET ALL VENDOR FUNCTION API CALL
  const handleGetAllVendor = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
      },
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.getVendor, data);
    if (res?.success) {
      setAllVendors(res?.vendor?.data);
      setcount(res?.vendor?.totalCounts);
      settotalPages(res?.vendor?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllVendor();
      closeAllModals();
    }
  }, [page, pageLimit, search]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <VendorsHeader
            handleGetAllVendor={handleGetAllVendor}
            search={search}
            setSearch={setSearch}
            setPage={setPage}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allVendors?.length > 0 && (
                <VendorsTable
                  allVendors={allVendors}
                  page={page}
                  pageLimit={pageLimit}
                />
              )}
              {!loader && allVendors?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allVendors?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allVendors?.length === 0 && (
                <NoData title="No vendor added" />
              )}
            </div>
          </div>
        </div>
        <AddVendor
          handleGetAllVendor={handleGetAllVendor}
          setEditVendor={setEditVendor}
          editVendor={editVendor}
        />
      </div>
    </div>
  );
}

export default Vendors;
