import React, { useEffect, useState } from "react";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import { Avatar } from "../../../../components/Avatar/Avatar";
import {
  getCurrentWorkspaceId,
  getName,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import ApiCall from "../../../../api/ApiCall";
import {
  LocationRelatedEndPoints,
  attendanceRelatedEndPoints,
} from "../../../../api/Endpoints";
import { toast } from "react-toastify";

function EditAttendanceMethod(props) {
  const {
    isEditOnboarding,
    setisEditOnboarding,
    employeeData,
    setemployeeData,
    handleGetApprovedEmployee,
  } = props;
  const user = useSelector((state) => state.user);
  const [selfAttendance, setselfAttendance] = useState({
    is: false,
    gps: false,
    selfie: false,
    gpsLocking: false,
  });
  const [manualAttendance, setmanualAttendance] = useState(false);
  const [loader, setloader] = useState(false);
  const [selectedLocation, setselectedLocation] = useState("");
  const [checkInDistance, setcheckInDistance] = useState("");
  const [loader2, setloader2] = useState(false);
  const [allLocation, setallLocation] = useState([]);
  const distance = [10, 30, 50, 100, 200];

  useEffect(() => {
    setselfAttendance({
      is:
        employeeData?.staff?.attendanceMethod === "selfAttendance"
          ? true
          : false,
      gps:
        employeeData?.staff?.attendanceMethod === "selfAttendance"
          ? employeeData?.staff?.isGpsTracking
          : false,
      selfie:
        employeeData?.staff?.attendanceMethod === "selfAttendance"
          ? employeeData?.staff?.isLiveSelfie
          : false,
      gpsLocking:
        employeeData?.staff?.attendanceMethod === "selfAttendance"
          ? employeeData?.staff?.isGpsLocking
          : false,
    });
    setmanualAttendance(
      employeeData?.staff?.attendanceMethod === "manual" ? true : false
    );
    setselectedLocation(employeeData?.staff?.location);
    setcheckInDistance(employeeData?.staff?.allowedLoginDistance);
  }, [employeeData]);

  const closeAttendanceMethodModal = () => {
    setisEditOnboarding(false);
    setemployeeData("");
  };
  const checkManualAttendance = () => {
    setmanualAttendance(true);
    setselfAttendance({ is: false, gps: false, selfie: false });
  };

  const handleUpdateAttendanceMethod = async () => {
    startApiCall(null, setloader);
    const sendData = {
      staffId: employeeData?.staff?._id,
      employeeId: employeeData?.employeeId?._id,
      businessId: getCurrentWorkspaceId(),
      actionBy: user?._id,
      attendanceMethod: manualAttendance ? "manual" : "selfAttendance",
      isGpsTracking: selfAttendance?.is ? selfAttendance?.gps : false,
      isLiveSelfie: selfAttendance?.is ? selfAttendance?.selfie : false,
      isGpsLocking: selfAttendance?.is ? selfAttendance?.gpsLocking : false,
      location: selectedLocation,
      allowedLoginDistance: checkInDistance,
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.editStaff,
      sendData
    );
    if (res?.success) {
      setloader(false);
      handleGetApprovedEmployee();
      toast.success("Attendance Method Update successfully!");
      closeAttendanceMethodModal();
    } else {
      setloader(false);
    }
  };

  const handleGetAllLocation = async () => {
    startApiCall(null, setloader2);

    const res = await ApiCall("get", LocationRelatedEndPoints.getLocation, {
      params: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
      },
    });
    if (res?.success) {
      setallLocation(res?.locations);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetAllLocation();
  }, []);

  return (
    <div
      className="custom-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: "80%" }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeAttendanceMethodModal}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="text-capitalize mb-2">Edit Attendance Method</h5>
            <div className="border-top mb-2 p-1 mb-1"></div>
            <span className="pointer text-dark">
              Selected Staff :
              <span>
                <CustomTooltip
                  text={
                    employeeData
                      ? employeeData?.employeeId?.name
                      : "This user is not signup yet"
                  }
                  placement="bottom"
                >
                  <span className="ms-2 font-3">
                    <Avatar
                      name={employeeData ? employeeData?.employeeId?.name : "I"}
                      count={1}
                      image={
                        employeeData
                          ? employeeData?.employeeId?.name?.image
                          : ""
                      }
                      size="xss"
                      color="primary"
                    />
                    &nbsp;{getName(employeeData?.employeeId, user?._id)}
                  </span>
                </CustomTooltip>
              </span>
            </span>
            <div className="d-flex gap-3 p-2 px-2">
              <div
                className="d-flex flex-column justify-content-between border rounded-7 p-4 w-100 gap-4 mh-450p"
                style={{ overflow: "auto" }}
              >
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column">
                    <p className="text-dark fs-6 ">Attendance method</p>
                    <p className="fs-7 mt-1">
                      Choose a method by which you would like to keep a tarck of
                      employee’s daily attendance
                    </p>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="form-check">
                      <input
                        className="form-check-input circle pointer"
                        type="radio"
                        id="radio31"
                        name="attendanceMethod"
                        value="selfAttendance"
                        checked={selfAttendance.is}
                        onChange={() => {
                          setmanualAttendance(false);
                          setselfAttendance({ is: true });
                        }}
                      />
                      <label className="form-check-label fs-6 text-dark ">
                        Self Attendance
                      </label>
                    </div>
                    <p className="fs-7 ms-4">
                      In this type of attendance the employee marks their
                      presence themselves and the admin verifies the attendance
                      sheet for all employees
                    </p>
                  </div>
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-10">
                        <div className="d-flex flex-column mt-1">
                          <div className="form-check">
                            <input
                              className="form-check-input pointer"
                              type="checkbox"
                              name="option1"
                              value="gpsTracking"
                              disabled={!selfAttendance.is}
                              checked={selfAttendance?.gps}
                              onChange={(e) => {
                                setselfAttendance({
                                  ...selfAttendance,
                                  gps: e.target.checked,
                                });
                              }}
                            />
                            <label className="form-check-label text-dark ">
                              Gps tracking
                            </label>
                          </div>
                          <p className="fs-7 ms-4">
                            In this type of attendance the Admin will get
                            notified if the employee logs in and out from an
                            unfamiliar location{" "}
                          </p>
                        </div>
                        <div className="d-flex flex-column mt-1">
                          <div className="form-check">
                            <input
                              className="form-check-input pointer"
                              type="checkbox"
                              name="option2"
                              value="liveSelfie"
                              disabled={!selfAttendance.is}
                              checked={selfAttendance?.selfie}
                              onChange={(e) => {
                                setselfAttendance({
                                  ...selfAttendance,
                                  selfie: e.target.checked,
                                });
                              }}
                            />
                            <label className="form-check-label text-dark ">
                              Live selfie
                            </label>
                          </div>
                          <p className="fs-7 ms-4">
                            In this type of attendance the employee will be
                            asked to take a selfie everytime they log in and log
                            out which will later be verified by the admin
                          </p>
                        </div>
                        <div className="d-flex flex-column mt-1">
                          <div className="form-check">
                            <input
                              className="form-check-input pointer"
                              type="checkbox"
                              name="option3"
                              value="gpsLocking"
                              disabled={!selfAttendance.is}
                              checked={selfAttendance.gpsLocking}
                              onChange={(e) => {
                                setselfAttendance({
                                  ...selfAttendance,
                                  gpsLocking: e.target.checked,
                                });
                              }}
                            />
                            <label className="form-check-label text-dark">
                              Gps Locking
                            </label>
                          </div>
                          <p className="fs-7 ms-4">
                            In this type of attendance the employee will be able
                            to log in and out within a specified radius of the
                            registered location.
                          </p>
                        </div>
                        {selfAttendance.gpsLocking && (
                          <div className="d-flex col-sm-12 mt-3">
                            <div className="col-sm-6">
                              <div className="form-check">
                                <label className="form-check-label text-dark">
                                  Choose location
                                </label>
                              </div>
                              <p className="fs-7 ms-4">
                                Select a location from where your employee will
                                be working
                              </p>
                              {allLocation?.length > 0 ? (
                                <div className="d-flex align-items-center ms-4">
                                  <select
                                    name="Type"
                                    className="p-2"
                                    value={selectedLocation}
                                    onChange={(e) => {
                                      setselectedLocation(e.target.value);
                                    }}
                                  >
                                    <option value={""}>Select Location</option>
                                    {allLocation?.map((loc) => (
                                      <option value={loc?._id}>
                                        {loc?.locationName}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              ) : (
                                <span className="text-danger ms-4">
                                  {" "}
                                  No Location Added
                                </span>
                              )}
                            </div>
                            <div className="col-sm-6">
                              <div className="form-check">
                                <label className="form-check-label text-dark">
                                  Check-in radius
                                </label>
                              </div>
                              <p className="fs-7 ms-4">
                                The employee will check-in with in the radius of
                                the allocated location only
                              </p>
                              <div className="d-flex align-items-center ms-4">
                                <select
                                  name="Type"
                                  className="p-2"
                                  value={checkInDistance}
                                  disabled={!selectedLocation}
                                  onChange={(e) => {
                                    setcheckInDistance(e.target.value);
                                  }}
                                >
                                  <option value={""}>Select Radius</option>
                                  {distance?.map((dis) => (
                                    <option value={dis}>{dis} m</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="form-check">
                    <input
                      className="form-check-input circle pointer"
                      type="radio"
                      id="radio2"
                      name="attendanceMethod"
                      value="manual"
                      checked={manualAttendance}
                      onChange={checkManualAttendance}
                    />
                    <label className="form-check-label fs-6 text-dark">
                      Manual
                    </label>
                  </div>
                  <p className="fs-7 ms-4">
                    In this type of attendance the admin will manually mark each
                    employees absence and presence everyday.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer align-items-center">
            <button
              type="button"
              // disabled={loader}
              className="btn btn-secondary"
              onClick={closeAttendanceMethodModal}
            >
              Discard
            </button>
            <button
              disabled={
                loader ||
                (selfAttendance.is === false && manualAttendance === false) ||
                (selfAttendance.gpsLocking === true && !selectedLocation)
              }
              type="submit"
              onClick={handleUpdateAttendanceMethod}
              className="btn btn-primary"
            >
              {loader ? <ApiLoader /> : "Change Method"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAttendanceMethod;
