import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../../global-functions/globalFunctions";
import ApiCall from "../../../../../api/ApiCall";
import { ProductCategoryRelatedEndPoints } from "../../../../../api/Endpoints";

const CopyItem = ({ setCopyModal, setitemModal, setCopyItems, category, id }) => {
  const [loader, setloader] = useState(false);
  const [itemCategory, setItemCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(id);

  const [selectedDish, setSelectedDish] = useState("");
  const [loader2, setloader2] = useState(false);
  const [categoriesItem, setCategoriesItem] = useState([]);
  const [dishData, setDishData] = useState("");
  const [filter, setFilter] = useState("");
  const filteredDishes = categoriesItem?.filter((cat) =>
    cat?.productName?.toLowerCase()?.includes(filter.toLowerCase())
  );

  const handleReset = () => {
    setSelectedCategory("");
    setDishData("");
    setSelectedDish("");
    setCopyModal(false);
  };

  const handleGetItemCategory = async () => {
    startApiCall(null, setloader);
    const data = {
      page: 1,
      limit: 100,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: "",
      },
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.getProductCategory,
      data
    );
    if (res?.success) {
      setItemCategory(res?.categories?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetItemCategory();
  }, []);

  const handleGetCategoryItems = async () => {
    startApiCall(null, setloader2);
    const data = {
      filters: {
        productCategory: selectedCategory === category ? id : selectedCategory,
        isDeleted: "false",
        businessId: getCurrentWorkspaceId(),
      },
      page: 1,
      limit: 500,
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.getCategoryItems,
      data
    );
    if (res?.success) {
      setCategoriesItem(res?.item?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      handleGetCategoryItems();
    }
  }, [selectedCategory]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3" style={{overflow:"visible"}}>
          <div className="modal-body pb-0">
            <button type="button" className="btn-close" onClick={handleReset}>
              <span aria-hidden="true">×</span>
            </button>
            <span class="d-flex">
              <h5 class="text-capitalize mb-2">Copy Item</h5>
              &nbsp;
            </span>
            <div className="border-bottom"></div>
            <div>
              <p className="alert alert-info text-primary fs-7 mb-0 py-1 px-2 mt-3">
                Select a dish and effortlessly copy its ingredients with just a
                click!
              </p>
              <div className="row py-3 g-3">
                <div className="col-md-6 col-sm-12">
                  <label className="form-label">Categories</label>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    disabled={loader}
                  >
                    {itemCategory?.map((item) => {
                      return (
                        <option key={item?._id} value={item?._id}>
                          {item?.categoryName}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Dishes</label>
                    <div className="selectable-dropdown">
                      <div className="dropdown selectable-dropdown">
                        <span
                          className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-100 ${
                            selectedCategory === ""
                              ? "bg-light text-disabled"
                              : ""
                          }`}
                          aria-expanded="false"
                          type="button"
                          required
                          data-bs-toggle="dropdown"
                          disabled={selectedCategory === ""}
                          onClick={() => {
                            handleGetCategoryItems();
                            setFilter("");
                          }}
                        >
                          {selectedDish ? (
                            <div className="d-flex justify-content-between">
                              {dishData}
                              <button
                                type="button"
                                className="btn-close btn-sm ms-1"
                                onClick={() => {
                                  setSelectedDish("");
                                  setFilter("");
                                  setDishData("");
                                  setCopyItems([]);
                                }}
                                aria-label="Close"
                              ></button>
                            </div>
                          ) : (
                            "Select item"
                          )}
                        </span>

                        <div role="menu" className="dropdown-menu ">
                          <form className="d-sm-block d-none" role="search">
                            <input
                              type="text"
                              placeholder="Search.."
                              className="form-control"
                              value={filter}
                              onChange={(e) => setFilter(e.target.value)}
                            />
                          </form>
                          {loader2 ? (
                            <span className="d-flex text-center mt-1">
                              Loading...
                            </span>
                          ) : (
                            <div
                              style={{
                                maxHeight: "20vh",
                                overflow: "auto",
                              }}
                            >
                              {filteredDishes?.map((item, index) => (
                                <div
                                  className="dropdown-item text-capitalize"
                                  key={item._id}
                                  onClick={(e) => {
                                    setSelectedDish(item?._id);
                                    setDishData(item?.productName);
                                    setCopyItems(item);
                                  }}
                                >
                                  {index + 1}. {item?.productName}
                                </div>
                              ))}
                            </div>
                          )}
                          {!loader2 && filteredDishes?.length === 0 && (
                            <span className="d-flex text-center mt-1">
                              No Items found
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer align-items-center mt-2">
              <button
                className="btn btn-md btn-primary"
                onClick={() => {
                  setCopyModal(false);
                  setitemModal(true);
                }}
                disabled={!selectedDish}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyItem;
