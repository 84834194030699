import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import CrmSettings from "../../pages/CRM/CrmSettings/CrmSettings";

const CrmSettingsRoutes = {
  path: "/crm",
  element: <Navbar />,
  children: [
    {
      path: "settings",
      element: <CrmSettings />,
    },
  ],
};

export default CrmSettingsRoutes;
