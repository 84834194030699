import React, { useState } from "react";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import {
  OrderRelatedEndPoints,
  VendorRelatedEndPoints,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PreviousTransactionModal from "./PreviousTransactionModal";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";

function PaymentModal({
  setpaymentModal,
  orderData,
  setsubPaymentModal,
  paymentData,
  setpaymentData,
  handleGetOrders,
}) {
  const { user } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rcvAmount, setRcvAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("bank-transfer");
  const [transactionNo, setTransactionNo] = useState("");
  const [paymentStatus, setpaymentStatus] = useState(false);
  const [previousTransaction, setpreviousTransaction] = useState(false);
  const [allTransaction, setallTransaction] = useState([]);
  const [totalReceivedAmount, setTotalReceivedAmount] = useState(0);

  const allPaymentMode = [
    {
      name: "Bank Transfer",
      value: "bank-transfer",
    },
    {
      name: "Cash",
      value: "cash",
    },
    {
      name: "UPI",
      value: "upi",
    },
  ];

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setRcvAmount("");
    setPaymentMode("bank-transfer");
    setTransactionNo("");
    setpaymentData([]);
    setpaymentModal(false);
  };

  //   GET TRANSACTION FUNCTION API CALL
  const handleGetOrdersTransaction = async () => {
    startApiCall(null, setloader2);
    const data = {
      filters: {
        orderId: orderData?._id,
      },
      page: 1,
      limit: 5000,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorTransaction,
      data
    );
    if (res?.success) {
      setallTransaction(res?.transactions?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    handleGetOrdersTransaction();
  }, []);

  //   ADD TRANSACTION FUNCTION API CALL
  const handleAddTransaction = async (e) => {
    e.preventDefault();
    if (
      calculateTotalPaymentAmount() + parseFloat(rcvAmount) >
      orderData?.amount - calculateTotalReceived()
    ) {
      setErrorMessage(
        "The transaction amount can't be more than the order amount."
      );
      setloader(false);
    } else {
      startApiCall(setErrorMessage, setloader);
      const paymentDetails = {
        paymentAmount: rcvAmount,
        paymentType: paymentMode,
        referenceNumber: transactionNo,
      };
      const updatedPaymentData = [...paymentData, paymentDetails];
      const sendData = {
        orderId: orderData?._id,
        orderStatus: paymentStatus ? "confirmed" : "due",
        paymentStatus: paymentStatus ? "paid" : "due",
        receivedAmount: orderData?.amount.toString(),
        payment: updatedPaymentData,
        businessId: getCurrentWorkspaceId(),
        senderBankDetails: {},
        receiverBankDetails: {},
        transactionDoneBy: user?._id,
        actionBy: user?._id,
      };
      const res = await ApiCall(
        "post",
        OrderRelatedEndPoints.collectPayment,
        sendData
      );
      if (res?.success) {
        handleReset();
        handleGetOrders();
        toast.success("Payment successfully!");
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    }
  };

  // useEffect(() => {
  //   setRcvAmount(orderData?.amount.toString());
  // }, [orderData]);

  const calculateTotalPaymentAmount = () => {
    return paymentData?.reduce(
      (acc, transaction) => acc + parseFloat(transaction?.paymentAmount),
      0
    );
  };

  const calculateTotalReceived = () => {
    return allTransaction?.reduce(
      (acc, transaction) => acc + transaction?.amountReceived,
      0
    );
  };
  useEffect(() => {
    setpaymentStatus(orderData?.amount <= totalReceivedAmount);
  }, [totalReceivedAmount]);

  useEffect(() => {
    setTotalReceivedAmount(
      calculateTotalPaymentAmount() +
        calculateTotalReceived() +
        parseFloat(rcvAmount)
    );
  }, [paymentData, allTransaction, rcvAmount]);

  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleReset();
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5>
                Payment{" "}
                <span className="font-3 text-secondary">
                  (Order No: {orderData?.orderNumber})
                </span>
              </h5>
              <div className="border-bottom mb-3"></div>
              <form onSubmit={handleAddTransaction}>
                <div className="row gx-3">
                  <div className="col-sm-12 d-flex justify-content-between ">
                    <div>
                      {!loader2 && allTransaction?.length > 0 && (
                        <p className="font-3">
                          Previous Transactions{" "}
                          <span className="badge badge-md badge-pill badge-soft-primary ms-1 me-1">
                            {allTransaction?.length}
                          </span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="pointer"
                            onClick={() => {
                              setpreviousTransaction(true);
                            }}
                          >
                            <circle cx="11" cy="11" r="10.5" stroke="#3862DD" />
                            <path
                              d="M11.9224 8.0752V16H9.98145V8.0752H11.9224ZM9.84961 6.00244C9.84961 5.71436 9.94727 5.4751 10.1426 5.28467C10.3428 5.09424 10.6113 4.99902 10.9482 4.99902C11.2852 4.99902 11.5513 5.09424 11.7466 5.28467C11.9468 5.4751 12.0469 5.71436 12.0469 6.00244C12.0469 6.28564 11.9468 6.52246 11.7466 6.71289C11.5513 6.90332 11.2852 6.99854 10.9482 6.99854C10.6113 6.99854 10.3428 6.90332 10.1426 6.71289C9.94727 6.52246 9.84961 6.28564 9.84961 6.00244Z"
                              fill="#3862DD"
                            />
                          </svg>
                        </p>
                      )}
                      {loader2 && (
                        <SkeletonConstant width="w-175p" height="h-25p" />
                      )}
                    </div>

                    <div className="font-3 d-flex justify-content-end">
                      <div className="d-flex align-items-end justify-content-end gap-2">
                        <label className=" text-secondary">
                          {paymentStatus ? "Paid" : "Due"}
                        </label>
                        <div class="form-check form-switch">
                          <input
                            className={`form-check-input btn p-2 ${
                              paymentStatus === true
                                ? "bg-success"
                                : "bg-danger"
                            }`}
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={paymentStatus}
                            onClick={(e) => setpaymentStatus(!paymentStatus)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 d-flex justify-content-center mt-3 gap-3">
                    <p className="fw-bold  font-3 text-success">
                      Paid : ₹ {calculateTotalReceived()}
                    </p>
                    <p className="fw-bold  font-3 text-primary">
                      Total : ₹ {orderData?.amount}
                    </p>

                    <p className="fw-bold  font-3 text-danger">
                      Payment Due : ₹{" "}
                      {orderData?.amount - calculateTotalReceived() > 0
                        ? orderData?.amount - calculateTotalReceived()
                        : 0}
                    </p>
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label className="text-dark fw-300 fs-6">
                      Amount Received
                    </label>
                    <input
                      className="form-control mt-2"
                      type="number"
                      required
                      value={rcvAmount}
                      onChange={(e) => {
                        setRcvAmount(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-6 mt-3">
                    <label className="text-dark fw-250 fs-6">
                      Payment mode
                    </label>
                    <div className="form-group mt-2">
                      <select
                        className="form-select form-select-md p-2"
                        value={paymentMode}
                        onChange={(e) => {
                          setPaymentMode(e.target.value);
                        }}
                      >
                        {allPaymentMode?.map((data) => {
                          return (
                            <option value={data?.value}>{data?.name}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {paymentMode !== "cash" && (
                  <div className="row gx-3">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-dark fw-300 fs-6">
                          Reference number
                        </label>
                        <input
                          className="form-control mt-2"
                          type="text"
                          value={transactionNo}
                          onChange={(e) => {
                            setTransactionNo(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {rcvAmount < orderData?.amount && (
                  <div className="d-flex justify-content-center mt-2">
                    <div
                      className="alert alert-info text-primary fs-7"
                      role="alert"
                    >
                      <strong>Note:</strong> Do you want to pay the remaining
                      amount with another payment method?{" "}
                      <button
                        type="button"
                        className="btn btn-xs btn-outline-primary"
                        onClick={() => {
                          setsubPaymentModal(true);
                        }}
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                )}
                {rcvAmount < orderData?.amount && paymentData?.length > 0 && (
                  <div className="contact-list-view mh-200p overflow-auto">
                    <table
                      id="datable_1"
                      className="table nowrap w-100 mb-3 text-center"
                    >
                      <thead>
                        <tr>
                          <th>Sl No.</th>
                          <th>Amount</th>
                          <th> Payment Mode</th>
                          <th> Reference Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentData?.map((data, index) => (
                          <tr class="mb-00">
                            <td>{index + 1}.</td>
                            <td class="text-left mb-00">
                              ₹ {data?.paymentAmount}
                            </td>
                            <td class="text-right mb-00">
                              {data?.paymentType}
                            </td>
                            <td class="text-right mb-00">
                              {data?.referenceNumber
                                ? data?.referenceNumber
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handleReset();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loader}
                  >
                    {loader ? <ApiLoader /> : "Pay"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {previousTransaction && (
        <PreviousTransactionModal
          orderData={orderData}
          setpreviousTransaction={setpreviousTransaction}
          allTransaction={allTransaction}
          loader={loader2}
          handleGetOrdersTransaction={handleGetOrdersTransaction}
        />
      )}
    </>
  );
}

export default PaymentModal;
