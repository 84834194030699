import React, { useEffect, useState } from "react";
import BillsHeader from "./BillsHeader";
import BillsTable from "./BillsTable";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { BillRelatedEndPoints } from "../../../api/Endpoints";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import { CustomChip } from "../../../components/CustomChip";
import BillFilter from "./BillFilter";

const AllBills = () => {
  const [loader, setloader] = useState(false);
  const [search, setsearch] = useState("");
  const [allBills, setallBills] = useState([]);

  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // ** FILTER **
  const [isFilterReset, setisFilterReset] = useState(false);
  const [filterType, setfilterType] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [dateModal, setdateModal] = useState(false);

  const resetData = (type) => {
    handleResetFilters(type, true);
  };

  const handleResetFilters = (type, isReset) => {
    if (type === "Date Range") {
      setstartDate("");
      setendDate("");
    } else if (type === "Due Date") {
      setdueDate("");
    }
    setdateModal(false);
    setisFilterReset(isReset);
  };

  const handleFilterType = (type) => {
    setfilterType(type);
  };

  // GET BILLS FUNCTION API CALL
  const handleGetAllBills = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        startDate: startDate,
        endDate: endDate,
        dueDate: dueDate,
      },
    };
    const res = await ApiCall("post", BillRelatedEndPoints.getBill, data);
    if (res?.success) {
      setallBills(res?.bill?.data);
      setcount(res?.bill?.totalCounts);
      settotalPages(res?.bill?.totalPages);
      setdateModal(false);
      setisFilterReset(false);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllBills();
    }
  }, [search, page, pageLimit]);

  useEffect(() => {
    if (isFilterReset) {
      handleGetAllBills();
    }
  }, [isFilterReset]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <BillsHeader
            setsearch={setsearch}
            search={search}
            setPage={setPage}
            handleGetAllBills={handleGetAllBills}
            filterType={filterType}
            handleFilterType={handleFilterType}
            setdateModal={setdateModal}
            setdueDate={setdueDate}
            setstartDate={setstartDate}
            setendDate={setendDate}
          />
          <div className="d-flex align-items-center justify-content-end pt-1 pe-3">
            {" "}
            {(startDate !== "" || endDate !== "") && (
              <CustomChip type={"Date Range"} resetData={resetData} />
            )}
            {dueDate !== "" && (
              <CustomChip type={"Due Date"} resetData={resetData} />
            )}
          </div>

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allBills?.length > 0 && (
                <BillsTable
                  allBills={allBills}
                  page={page}
                  pageLimit={pageLimit}
                  handleGetAllBills={handleGetAllBills}
                />
              )}
              {!loader && allBills?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allBills?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allBills?.length === 0 && (
                <NoData title="No Bills Added" />
              )}
            </div>
          </div>
        </div>
        <BillFilter
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader}
          setdueDate={setdueDate}
          dueDate={dueDate}
          handleResetFilters={handleResetFilters}
          dateModal={dateModal}
          handleGetAllBills={handleGetAllBills}
          filterType={filterType}
        />
      </div>
    </div>
  );
};

export default AllBills;
