import React from "react";

const SmallLoader = () => {
  return (
    <>
      <div
        class="spinner-grow text-success"
        style={{ height: "9px", width: "9px" }}
        role="status"
      />
      <div
        class="spinner-grow text-primary ms-1"
        style={{ height: "9px", width: "9px" }}
        role="status"
      />
      <div
        class="spinner-grow text-danger ms-1"
        style={{ height: "9px", width: "9px" }}
        role="status"
      />
    </>
  );
};

export default SmallLoader;
