import React from "react";
import {
  convertAmountLocal,
  dateAndTimeFormatter,
  getName,
} from "../../../../global-functions/globalFunctions";
import { navigateToManageInvoiceDetails } from "../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import { Avatar } from "../../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";

const StockDetailsTable = ({
  stockDetails,
  setEditStock,
  page,
  pageLimit,
  id,
  setShowReason,
  setImageData,
  isModuleReadWriteAccessForRawMaterial,
}) => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  return (
    <>
      <div className="contact-list-view">
        <table id="datable_1" className="table nowrap w-100 mb-8">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th style={{ textAlign: "left" }}>Stock record</th>
              <th>Date Created</th>
              <th>Action By</th>
              <th>Quantity</th>
              <th>Stock Price</th>
              <th>Bill/Id/Challan</th>
              <th>Reason</th>
              <th>Order No</th>
              <th>Vendor</th>
              {isModuleReadWriteAccessForRawMaterial && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {stockDetails?.map((data, index) => {
              const showAddedStock = data?.inventories?.find(
                (item) => item?.itemId?._id === id
              );
              return (
                <tr className="text-center">
                  <td>{(page - 1) * pageLimit + (index + 1)} .</td>
                  <td className="text-capitalize" style={{ textAlign: "left" }}>
                    <span
                      className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize"
                      style={{ width: "200px" }}
                    >
                      {data?.isAdded ? (
                        <span className="border px-2 me-2 bg-success">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="#FFFFFF"
                            className="bi bi-plus-lg mb-1"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                            />
                          </svg>
                        </span>
                      ) : (
                        <span className="border px-2 me-2 bg-danger">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            fill="#FFFFFF"
                            className="bi bi-dash-lg mb-1"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"
                            />
                          </svg>
                        </span>
                      )}
                      {data?.isAdded
                        ? `Stock ${data?.description}`
                        : "Stock used"}
                    </span>
                  </td>
                  <td>
                    {dateAndTimeFormatter(
                      data?.isAdded ? data?.createdAt : data?.updatedAt
                    )}
                  </td>
                  <td className="text-capitalize">
                    {data?.createdBy?.name ? (
                      <>
                        <Avatar
                          name={data?.createdBy?.name}
                          count={1}
                          image={data?.createdBy?.image}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {getName(data?.createdBy, user?._id)}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{showAddedStock?.quantity?.toFixed(2)}</td>
                  <td className="font-mon text-dark font-3-3">
                    {showAddedStock?.price > 0
                      ? convertAmountLocal(showAddedStock?.price)
                      : "-"}
                  </td>
                  <td className="text-dark font-3">
                    {showAddedStock?.stockId?.billNumber ? (
                      <>
                        {showAddedStock?.stockId?.billNumber}&nbsp;
                        {data?.images?.length > 0 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-info-circle-fill text-primary pointer"
                            viewBox="0 0 16 16"
                            data-bs-toggle="modal"
                            data-bs-target="#bill_img_modal"
                            onClick={() => {
                              setImageData(data);
                            }}
                          >
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                          </svg>
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {data?.isAdded === false && !data?.orderId ? (
                      <span
                        onClick={() =>
                          setShowReason({ show: true, stock: data })
                        }
                      >
                        <i
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="ri-information-line info-icon"
                        ></i>
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td>
                    {data?.orderId ? (
                      <span
                        className="pointer text-primary"
                        onClick={() =>
                          navigate(
                            navigateToManageInvoiceDetails({
                              id: data?.orderId?._id,
                            }),
                            {
                              state: { subHeader: "Manage Stock" },
                            }
                          )
                        }
                      >
                        {data?.orderId?.orderNumber
                          ?.toString()
                          .padStart(3, "0")}
                        <span className="btn btn-icon btn-rounded btn-sm">
                          <span nonce="feather-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="10"
                              fill="#3862DD"
                              className="bi bi-box-arrow-up-right mb-2"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                              />
                              <path
                                fill-rule="evenodd"
                                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                              />
                            </svg>
                          </span>
                        </span>
                      </span>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <Avatar
                      name={showAddedStock?.stockId?.vendorId?.name}
                      count={1}
                      image={showAddedStock?.stockId?.vendorId?.image}
                      size="xxs"
                      color="primary"
                    />
                    <span className="ms-1">
                      {showAddedStock?.stockId?.vendorId?.name
                        ? showAddedStock?.stockId?.vendorId?.name
                        : "-"}
                    </span>
                  </td>
                  {isModuleReadWriteAccessForRawMaterial && (
                    <td>
                      {data?.isAdded ? (
                        <span
                          className="feather-icon pointer text-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#add_stock"
                          onClick={() => {
                            setEditStock({
                              stock: showAddedStock,
                              logsId: data?._id,
                              data: data,
                            });
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-pencil-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                            <path
                              fill-rule="evenodd"
                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                            />
                          </svg>
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StockDetailsTable;
