import React from "react";
import { useNavigate } from "react-router-dom";
import {
  navigateToMyGrievance,
  navigateToMeeting,
  navigateToNotice,
  navigateToPoll,
} from "../../Navigation/Navigation";
import { useSelector } from "react-redux";
import { isCurrentWorkspaceDefault } from "../../global-functions/globalFunctions";

const SpaceHeader = ({
  search,
  setSearch,
  handleGetFolderList,
  setSelectedTab,
  selectedTab,
  spaceData,
  handleOpenSpaceInfo,
  setpermissionModalType,
  setpermissionData,
  handleCloseAllInfoModal,
  setfolderPageNumber,
  setListPageNumber,
  isModuleReadWriteAccessForSpace,
}) => {
  const handleModeChange = (mode) => {
    setSelectedTab(mode);
  };
  const headerFilter = ["ALL", "FOLDER", "LIST"];
  const navigate = useNavigate();
  const myApproveWorkspaces = useSelector((state) => state.approvedWorkspace);
  const currentWorkspaceId = localStorage.getItem("current-workspace");

  return (
    <header className="fm-header">
      <div className="d-flex align-items-center justify-content-start">
        <span className="fmapp-title link-dark text-capitalize">
          <sapn
            className="fs-5"
            style={{
              maxWidth: "195px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {spaceData?.name}
          </sapn>
        </span>
        {!isCurrentWorkspaceDefault(
          myApproveWorkspaces,
          currentWorkspaceId
        ) && (
          <>
            {isModuleReadWriteAccessForSpace && (
              <button
                className="btn btn-xs btn-outline-secondary dropdown-toggle ms-2"
                data-bs-toggle="dropdown"
              >
                Create New
              </button>
            )}
          </>
        )}

        <div role="menu" className="dropdown-menu">
          <button
            className="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#add_new_folder"
          >
            New Folder
          </button>
          <button
            className="dropdown-item"
            data-bs-toggle="modal"
            data-bs-target="#add_space_list"
          >
            New List
          </button>
        </div>
      </div>
      <div
        className="btn-group btn-sm d-sm-flex d-none"
        role="group"
        aria-label="Basic outlined example"
      >
        {headerFilter?.map((name) => {
          return (
            <button
              onClick={() => {
                handleModeChange(name);
              }}
              type="button"
              className={`btn btn-outline-light fs-7 ${
                selectedTab === name ? "active" : ""
              }`}
              key={name}
            >
              {name}
            </button>
          );
        })}
      </div>
      <div className="d-flex align-items-center">
        <button
          className="btn btn-xs btn-outline-secondary ms-1 fs-9"
          data-bs-toggle="dropdown"
        >
          Communications
          {/* <span className="badge badge-sm badge-pill badge-soft-primary ms-2 fs-9">
            1
          </span> */}
        </button>
        <div role="menu" className="dropdown-menu">
          <button
            className="dropdown-item"
            onClick={() => {
              navigate(navigateToMyGrievance(), {
                state: {
                  moduleName: spaceData?.name,
                  moduleId: spaceData?._id,
                  moduleType: "Space",
                },
              });
            }}
          >
            1. Grievance
          </button>

          <button
            className="dropdown-item"
            onClick={() => {
              navigate(navigateToMeeting(), {
                state: {
                  moduleName: spaceData?.name,
                  moduleId: spaceData?._id,
                  moduleType: "Space",
                },
              });
            }}
          >
            2. Meeting
          </button>
          <button
            className="dropdown-item"
            onClick={() => {
              navigate(navigateToNotice(), {
                state: {
                  moduleName: spaceData?.name,
                  moduleId: spaceData?._id,
                  moduleType: "Space",
                },
              });
            }}
          >
            3. Notice
          </button>
          <button
            className="dropdown-item"
            onClick={() => {
              navigate(navigateToPoll(), {
                state: {
                  moduleName: spaceData?.name,
                  moduleId: spaceData?._id,
                  moduleType: "Space",
                },
              });
            }}
          >
            4. Poll
          </button>
        </div>
      </div>
      <div className="ms-1">
        {spaceData?.isPrivate && (
          <span className="badge badge-md badge-pill badge-soft-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-lock"
              viewBox="0 0 16 16"
              stroke-width="25"
            >
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
            </svg>
            &nbsp; Private
          </span>
        )}
        {!spaceData?.isPrivate && (
          <span className="badge badge-md badge-pill badge-soft-primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-globe"
              viewBox="0 0 16 16"
            >
              <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
            </svg>
            &nbsp; Public
          </span>
        )}
      </div>
      <div className="fm-options-wrap">
        <div>
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
            data-bs-placement="top"
            data-bs-original-title="Setting"
            data-bs-toggle="dropdown"
          >
            <span className="btn-icon-wrap">
              <span className="feather-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-gear"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                  <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                </svg>
              </span>
            </span>
          </span>
          <div role="menu" className="dropdown-menu">
            <span
              className="dropdown-item"
              onClick={() => {
                setpermissionData(spaceData);
                setpermissionModalType("space");
                handleOpenSpaceInfo();
              }}
            >
              <span className="feather-icon dropdown-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-info-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
              </span>
              <span>Space Info</span>
            </span>
            {isModuleReadWriteAccessForSpace && (
              <button
                className="dropdown-item mt-1"
                data-bs-toggle="modal"
                data-bs-target="#share_permission"
                onClick={() => {
                  handleCloseAllInfoModal();
                  setpermissionData(spaceData);
                  setpermissionModalType("space");
                }}
              >
                <span className="feather-icon dropdown-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-share"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
                  </svg>
                </span>
                Share & Permission
              </button>
            )}
            <button
              data-bs-toggle="modal"
              data-bs-target="#space_status"
              className="dropdown-item mt-1"
            >
              <span className="feather-icon dropdown-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-border-width"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 3.5A.5.5 0 0 1 .5 3h15a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-2zm0 5A.5.5 0 0 1 .5 8h15a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1zm0 4a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5z" />
                </svg>
              </span>
              Manage Status
            </button>
          </div>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Refresh Space Page"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetFolderList}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>

        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search By Name"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setfolderPageNumber(1);
              setListPageNumber(1);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default SpaceHeader;
