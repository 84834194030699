import React from "react";
import {
  dateAndTimeFormatter,
  dateFormatter,
} from "../../../global-functions/globalFunctions";

const PrintKOT = React.forwardRef((props, ref) => {
  const { orderData, printOption } = props;
  const productData =
    printOption === "Full KOT" ? orderData?.products : orderData?.lastAddedItem;

  return (
    <div ref={ref}>
      <div class="invoice-wrapper">
        <div className="invoice">
          <table>
            <tbody>
              <tr>
                <td class="text-center" colspan="10">
                  <p class="text-uppercase fw-bold mb-0-5">KOT</p>
                  <h6 class="text-uppercase fw-bold mb-0">High Sky</h6>
                  <p class="text-uppercase fw-bold mb-0">Cafe & Restaurant</p>
                </td>
              </tr>

              <tr class="border-top">
                <td class="text-left">
                  <p class="w-100 overflow-hide nowrap mb-0-5">
                    Table :&nbsp;{" "}
                    {orderData?.tableId?.tableName
                      ? orderData?.tableId?.tableName
                      : "-"}
                  </p>
                  <p class="w-100 overflow-hide nowrap mb-0-5">
                    Waiter :&nbsp;
                    {orderData?.tableId?.assignedPerson
                      ? orderData?.tableId?.assignedPerson
                      : "-"}
                  </p>
                </td>
                <td class="text-right">
                  <p class="w-100 overflow-hide nowrap mb-0-5">
                    KOT No. : {orderData?.KOTNumber}
                  </p>
                  <p class="w-100 overflow-hide nowrap mb-0-5">
                    Order No. : {orderData?.orderNumber}
                  </p>
                  <p class="w-100 overflow-hide nowrap mb-0-5">
                    Date : {dateFormatter(orderData?.createdAt)}
                  </p>
                  <p class="w-100 overflow-hide nowrap mb-0-5">
                    Time :&nbsp;
                    {dateAndTimeFormatter(orderData?.createdAt)
                      ?.split(",")[1]
                      ?.trim()}
                  </p>
                </td>
              </tr>
              <tr>
                <td colSpan="10">
                  <hr class="border-bottom w-100 mb-0-5 mt-0" />
                </td>
              </tr>

              <tr>
                <td class="text-left">
                  <p class="w-100 overflow-hide nowrap mb-0-5">
                    S. Description
                  </p>
                </td>
                <td class="text-right">
                  <p class="w-100 overflow-hide nowrap mb-0-5">Qty</p>
                </td>
              </tr>
              <tr>
                <td colSpan="10">
                  <hr class="border-bottom w-100 mb-0-5 mt-0" />
                </td>
              </tr>

              {productData?.map((data, index) => (
                <tr class="mb-00">
                  <td colspan="4" class="text-left mb-00">
                  <p class="w-100 mb-00 fs-md">
                      {index + 1}.
                       {data?.productId?.productName}
                    </p>
                  </td>
                  <td class="text-right mb-00">
                    <p class="w-100 overflow-hide nowrap mb-00 fs-md">
                      {data?.quantity}
                    </p>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="10">
                  <hr class="border-bottom w-100 mb-0-5 mt-0-5" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export default PrintKOT;
