import React, { useState } from "react";
import {
  dateAndTimeFormatter,
  dateFormatter,
} from "../../../../../global-functions/globalFunctions";
import { Avatar } from "../../../../../components/Avatar/Avatar";
import LeaveDetailsModal from "./LeaveDetailsModal";

const LeaveDetailsData = ({ leaveDetails }) => {
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [leaveRequestDetails, setLeaveRequestDetails] = useState([]);

  const pendingLeaves = leaveDetails?.filter(
    (data) => data?.status === "pending"
  );
  const previousLeaves = leaveDetails?.filter(
    (data) => data?.status !== "pending"
  );

  return (
    <>
      {pendingLeaves?.length > 0 && (
        <>
          <h5 className="fs-4 fw-400">Pending Leaves</h5>
          <div className="d-flex flex-column gap-1">
            {pendingLeaves?.map((leave) => {
              return (
                <div className="d-flex align-items-center justify-content-between border rounded-4 shadow p-3">
                  <div className="d-flex align-items-center gap-2">
                    <span className="pointer">
                      <Avatar
                        name={leave?.employeeId?.name}
                        count={1}
                        image={leave?.employeeId?.image}
                        size="sm"
                        color="primary"
                      />
                    </span>
                    <div className="d-flex flex-column">
                      <p className="text-dark fs-5 fw-light">
                        {leave?.employeeId?.name}
                      </p>
                      <p
                        className="fs-7"
                        style={{ textTransform: "capitalize" }}
                      >
                        {leave?.leaveType?.leaveName}
                      </p>
                      <span>
                        Requested on : {dateAndTimeFormatter(leave?.createdAt)}
                      </span>
                      <span className="d-flex">
                        Leave Dates :
                        <span className="ms-2 text-dark">
                          {leave?.dates?.slice(0, 6)?.map((data, index) => (
                            <span key={index}>
                              {dateFormatter(data)}
                              {index < Math.min(5, leave.dates.length - 1) &&
                                ", "}
                            </span>
                          ))}
                        </span>
                        {leave?.dates?.length > 6 && (
                          <button
                            className="btn btn-link ms-2 p-0"
                            onClick={() => {
                              setLeaveRequestDetails(leave);
                              setIsOpenDetailsModal(!isOpenDetailsModal);
                            }}
                          >
                            + {leave?.dates?.length - 6} More
                          </button>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="d-block align-items-center gap-4">
                    <div className="d-flex">
                      <div
                        className="card my-auto px-3 py-1"
                        style={{ background: "#ECECEC", minWidth: "200px" }}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chat-left-dots mt-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                          <span className="text-truncate text-dark fs-7 w-250p">
                            {leave?.description}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column ms-3 mt-1">
                        <span className="badge badge-soft-yellow my-1 me-2">
                          {leave?.status}
                        </span>
                      </div>
                    </div>
                    <span className="d-flex justify-content-end">
                      <div
                        className="text-decoration-underline pointer text-primary fw-semibold fs-8 d-flex align-items-center gap-1 mt-3"
                        onClick={() => {
                          setLeaveRequestDetails(leave);
                          setIsOpenDetailsModal(!isOpenDetailsModal);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <p>View Details</p>
                      </div>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {previousLeaves?.length > 0 && (
        <>
          <h5 className="fs-4 fw-400">Previous Leaves</h5>
          <div className="d-flex flex-column gap-1">
            {previousLeaves?.map((data) => {
              return (
                <div className="d-flex align-items-center justify-content-between border rounded-4 shadow p-3">
                  <div className="d-flex align-items-center gap-2">
                    <span className="pointer">
                      <Avatar
                        name={data?.employeeId?.name}
                        count={1}
                        image={data?.employeeId?.image}
                        size="sm"
                        color="primary"
                      />
                    </span>
                    <div className="d-flex flex-column">
                      <p className="text-dark fs-5 fw-light">
                        {data?.employeeId?.name}
                      </p>
                      <p
                        className="fs-7"
                        style={{ textTransform: "capitalize" }}
                      >
                        {data?.leaveType?.leaveName}
                      </p>
                      <span>
                        Requested on : {dateAndTimeFormatter(data?.createdAt)}
                      </span>
                      {data?.status === "approved" && (
                      <span className="text-dark">
                        Total leaves taken : {data?.dates?.length}
                      </span>
                      )}
                    </div>
                  </div>
                  <div className="d-block align-items-center gap-4">
                    <div className="d-flex mt-1">
                      <div
                        className="card my-auto px-3 py-1"
                        style={{ background: "#ECECEC", minWidth: "200px" }}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chat-left-dots mt-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                          <span className="text-truncate text-dark fs-7 w-250p">
                            {data?.description}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column ms-3 mt-1">
                        <span
                          className={`badge badge-soft-${
                            data?.status === "approved" ? "green" : "red"
                          }`}
                        >
                          {data?.status}
                        </span>
                      </div>
                    </div>
                    <span className="d-flex justify-content-end">
                      <div
                        className="text-decoration-underline pointer text-primary fw-semibold fs-8 d-flex align-items-center gap-1 mt-3"
                        onClick={() => {
                          setLeaveRequestDetails(data);
                          setIsOpenDetailsModal(!isOpenDetailsModal);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-info-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                        <p>View Details</p>
                      </div>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {isOpenDetailsModal && (
        <LeaveDetailsModal
          leaveRequestDetails={leaveRequestDetails}
          setIsOpenDetailsModal={setIsOpenDetailsModal}
        />
      )}
    </>
  );
};

export default LeaveDetailsData;
