import React, { useState } from "react";
import ApiCall from "../../../api/ApiCall";
import { INVITATION_URL } from "../../../api/base";
import { businessEndpoints, managementEndpoints } from "../../../api/Endpoints";
import { Avatar } from "../../../components/Avatar/Avatar";
import ChangeRoleModal from "../../../components/ChangeRoleModal/ChangeRoleModal";
import ConfirmModal from "../../../components/ConfirmationModal/ConfirmModal";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  getName,
  isCurrentWorkspaceDefault,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import InviteWorkspace from "../../InviteWorkspace/InviteWorkspace";
import { useSelector } from "react-redux";
import ConfirmRemoveEmployee from "./ConfirmRemoveEmployee";
import { toast } from "react-toastify";

const ManagePeopleTable = (props) => {
  const {
    allJoinBusinessRequest,
    handleGetJoinBusinessRequest,
    user,
    isModuleReadWriteAccess,
  } = props;
  const myApproveWorkspaces = useSelector((state) => state.approvedWorkspace);
  const currentWorkspaceId = localStorage.getItem("current-workspace");
  const [isChangeRoleModal, setisChangeRoleModal] = useState(false);
  const [selectedUser, setselectedUser] = useState(null);
  const [selectedRowType, setselectedRowType] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [isConfirmModalOpen, setisConfirmModalOpen] = useState(false);
  const [title, settitle] = useState("");
  const [isOpenInviteWorkspaceModal, setisOpenInviteWorkspaceModal] =
    useState(false);

  const [removeEmployeeModalOpen, setremoveEmployeeModalOpen] = useState(false);
  const [isReason, setIsReason] = useState("");
  const [isEmployee, setIsEmployee] = useState("");
  console.log("isEmployee : ", isEmployee);

  const handleChangeRoleModal = (user) => {
    setselectedUser(user);
    setisChangeRoleModal(true);
  };

  const handleJoinRequest = (user, type) => {
    setselectedRowType(type);
    setselectedUser(user);
    setisChangeRoleModal(true);
  };

  const handleClose = () => {
    setisConfirmModalOpen(false);
    seterrorMessage("");
    setLoader(false);
  };

  const handleRemoveOrResendInvitation = async () => {
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      documentId: selectedUser?._id,
      resend: title === "resend" ? true : false,
      isDeleted: title === "remove" ? true : false,
      baseUrl: INVITATION_URL,
      resenderId: user?._id,
    };
    const res = await ApiCall(
      "post",
      businessEndpoints.editManagePeople,
      payload
    );
    if (res?.success) {
      setLoader(false);
      handleGetJoinBusinessRequest();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  const handleRemoveEmployeeClose = () => {
    setremoveEmployeeModalOpen(false);
    seterrorMessage("");
    setLoader2(false);
    setIsEmployee("")
  };

  const handleRemoveEmployee = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader2);
    const payload = {
      businessId: getCurrentWorkspaceId(),
      employeeId: isEmployee?.employee?._id,
      userId: user?._id,
      permission: isEmployee?.permission?._id,
      reason: isReason,
    };
    const res = await ApiCall(
      "post",
      managementEndpoints.removeEmployee,
      payload
    );
    if (res?.success) {
      setLoader2(false);
      toast.success("Employee removed successfully!");
      setIsEmployee("")
      handleGetJoinBusinessRequest();
    } else {
      seterrorMessage(res.error);
      setLoader2(false);
    }
  };

  return (
    <>
      <table id="datable_1" className="table nowrap w-100 mb-5">
        <thead className="text-center">
          <tr>
            <th>No.</th>
            <th style={{ textAlign: "left" }}>Name</th>
            <th>Email</th>
            <th>Joined / Requested On</th>
            <th>Type</th>
            <th>Status</th>
            <th>Role</th>
            {!isCurrentWorkspaceDefault(
              myApproveWorkspaces,
              currentWorkspaceId
            ) && <>{isModuleReadWriteAccess && <th>Actions</th>}</>}
          </tr>
        </thead>
        <tbody className="text-center">
          {allJoinBusinessRequest?.map((emp, index) => {
            return (
              <tr key={index}>
                <td> {index + 1}.</td>

                <td style={{ textAlign: "left" }}>
                  <span
                    className="d-inline-block text-dark-100 text-truncate fw-light text-capitalize pointer"
                    style={{ width: "200px" }}
                  >
                    <CustomTooltip
                      text={
                        emp?.employee
                          ? emp?.employee?.name
                          : "This user is not signup yet"
                      }
                      placement="right"
                    >
                      <Avatar
                        name={emp?.employee ? emp?.employee?.name : "I"}
                        count={1}
                        image={emp?.employee?.image}
                        size="xss"
                        color="primary"
                      />
                    </CustomTooltip>
                    &nbsp;{" "}
                    {emp?.employee
                      ? getName(emp?.employee, user?._id)
                      : "Invited User"}
                  </span>
                </td>
                <td>{emp?.employee ? emp?.employee?.email : emp?.email}</td>
                <td>
                  {dateAndTimeFormatter(
                    emp?.actionTime ? emp?.actionTime : emp?.createdAt
                  )}
                </td>
                <td>
                  {" "}
                  <span className="text-capitalize ">{emp?.type}</span>
                </td>
                <td>
                  <span
                    className={`badge badge-soft-${
                      emp?.status === "approved"
                        ? "green"
                        : emp?.status === "pending"
                        ? "yellow"
                        : "red"
                    } my-1  me-2`}
                  >
                    {emp?.status}
                  </span>
                </td>

                <td>
                  <span className="text-capitalize ">
                    {emp?.permission?.roleName
                      ? emp?.permission?.roleName
                      : "-"}
                  </span>
                </td>
                {!isCurrentWorkspaceDefault(
                  myApproveWorkspaces,
                  currentWorkspaceId
                ) && (
                  <>
                    {isModuleReadWriteAccess && (
                      <>
                        {emp?.type === "invite" &&
                          emp?.status === "approved" && (
                            <td>
                              <>
                                <i
                                  data-bs-toggle="dropdown"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  className="ri-more-fill"
                                ></i>
                                <div className="dropdown-menu">
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      handleChangeRoleModal(emp);
                                    }}
                                  >
                                    <span
                                      className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                    >
                                      <span className="btn-icon-wrap">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="23"
                                          height="20"
                                          fill="currentColor"
                                          className="bi bi-person-gear"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                                        </svg>
                                      </span>
                                    </span>
                                    Change Role
                                  </div>
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      setIsEmployee(emp);
                                      setremoveEmployeeModalOpen(true);
                                    }}
                                  >
                                    <span
                                      className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                    >
                                      <span className="btn-icon-wrap">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-person-fill-slash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M13.879 10.414a2.501 2.501 0 0 0-3.465 3.465zm.707.707-3.465 3.465a2.501 2.501 0 0 0 3.465-3.465m-4.56-1.096a3.5 3.5 0 1 1 4.949 4.95 3.5 3.5 0 0 1-4.95-4.95ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                        </svg>
                                      </span>
                                    </span>
                                    Remove Employee
                                  </div>
                                </div>
                              </>
                            </td>
                          )}
                        {emp?.type === "join" && emp?.status === "approved" && (
                          <td>
                            {emp?.permission?.roleName !== "owner" ? (
                              <>
                                <i
                                  data-bs-toggle="dropdown"
                                  role="button"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  className="ri-more-fill"
                                ></i>
                                <div className="dropdown-menu">
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      handleChangeRoleModal(emp);
                                    }}
                                  >
                                    <span
                                      className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                    >
                                      <span className="btn-icon-wrap">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="23"
                                          height="20"
                                          fill="currentColor"
                                          className="bi bi-person-gear"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                                        </svg>
                                      </span>
                                    </span>
                                    Change Role
                                  </div>
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      setIsEmployee(emp);
                                      setremoveEmployeeModalOpen(true);
                                    }}
                                  >
                                    <span
                                      className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                    >
                                      <span className="btn-icon-wrap">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-person-fill-slash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M13.879 10.414a2.501 2.501 0 0 0-3.465 3.465zm.707.707-3.465 3.465a2.501 2.501 0 0 0 3.465-3.465m-4.56-1.096a3.5 3.5 0 1 1 4.949 4.95 3.5 3.5 0 0 1-4.95-4.95ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                                        </svg>
                                      </span>
                                    </span>
                                    Remove Employee
                                  </div>
                                </div>
                              
                              </>
                            ) : (
                              "-"
                            )}
                          </td>
                        )}
                        {emp?.type === "invite" &&
                          (emp?.status === "pending" ||
                            emp?.status === "rejected") && (
                            <td>
                              <i
                                data-bs-toggle="dropdown"
                                role="button"
                                aria-haspopup="true"
                                aria-expanded="false"
                                className="ri-more-fill"
                              ></i>
                              <div className="dropdown-menu">
                                {emp?.status !== "rejected" && (
                                  <div
                                    className="dropdown-item"
                                    onClick={() => {
                                      handleChangeRoleModal(emp);
                                    }}
                                  >
                                    <span
                                      className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                    >
                                      <span className="btn-icon-wrap">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="23"
                                          height="20"
                                          fill="currentColor"
                                          className="bi bi-person-gear"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                                        </svg>
                                      </span>
                                    </span>
                                    Change Role
                                  </div>
                                )}
                                <div
                                  className="dropdown-item"
                                  onClick={() => {
                                    if (emp?.status === "rejected") {
                                      setselectedUser(emp);
                                      setisOpenInviteWorkspaceModal(true);
                                    } else {
                                      setselectedUser(emp);
                                      settitle("resend");
                                      setisConfirmModalOpen(true);
                                    }
                                  }}
                                >
                                  <span
                                    className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="btn-icon-wrap">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-chat-square-text"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                        <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                      </svg>
                                    </span>
                                  </span>
                                  Resend Invitation
                                </div>
                                <div
                                  className="dropdown-item"
                                  onClick={() => {
                                    setselectedUser(emp);
                                    settitle("remove");
                                    setisConfirmModalOpen(true);
                                  }}
                                >
                                  <span
                                    className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span className="btn-icon-wrap text-danger">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="23"
                                        height="20"
                                        fill="currentColor"
                                        className="bi bi-person-x"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-.646-4.854.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 0 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 .708-.708Z" />
                                      </svg>
                                    </span>
                                  </span>
                                  Remove Invitation
                                </div>
                              </div>
                            </td>
                          )}

                        {emp?.type === "join" && emp?.status === "rejected" && (
                          <td>
                            <i
                              data-bs-toggle="dropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                              className="ri-more-fill"
                            ></i>
                            <div className="dropdown-menu">
                              <div
                                className="dropdown-item"
                                onClick={() => {
                                  setselectedUser(emp);
                                  setisOpenInviteWorkspaceModal(true);
                                }}
                              >
                                <span
                                  className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                >
                                  <span className="btn-icon-wrap">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="20"
                                      fill="currentColor"
                                      className="bi bi-chat-square-text"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                      <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                    </svg>
                                  </span>
                                </span>
                                Send Invitation
                              </div>
                            </div>
                          </td>
                        )}
                        {emp?.type === "join" && emp?.status === "pending" && (
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <div className="d-flex">
                                <span
                                  className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover text-success"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  onClick={() => {
                                    handleJoinRequest(emp, "joinApproved");
                                  }}
                                >
                                  <span className="btn-icon-wrap">
                                    <span className="feather-icon">
                                      <i
                                        className="ri-checkbox-circle-fill"
                                        style={{ fontSize: "25px" }}
                                      ></i>
                                    </span>
                                  </span>
                                </span>
                                <span
                                  className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-danger"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  onClick={() => {
                                    handleJoinRequest(emp, "joinRejected");
                                  }}
                                >
                                  <span className="btn-icon-wrap">
                                    <span className="feather-icon ">
                                      <i
                                        className="ri-close-circle-fill"
                                        style={{ fontSize: "25px" }}
                                      ></i>
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </td>
                        )}
                      </>
                    )}
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isChangeRoleModal && (
        <ChangeRoleModal
          title={
            selectedRowType === "joinApproved"
              ? "Confirmation of Approve People"
              : selectedRowType === "joinRejected"
              ? "Confirmation of Reject People"
              : null
          }
          isChangeRoleModal={isChangeRoleModal}
          setisChangeRoleModal={setisChangeRoleModal}
          user={selectedUser}
          handleRefresh={handleGetJoinBusinessRequest}
          type={selectedRowType}
          currentUserId={user?._id}
        />
      )}

      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={handleClose}
          text={`Are you sure you want to ${title} Invitation ?`}
          loader={loader}
          errorMessage={errorMessage}
          handleConfirm={handleRemoveOrResendInvitation}
        />
      )}

      {removeEmployeeModalOpen && (
        <ConfirmRemoveEmployee
          isOpen={removeEmployeeModalOpen}
          onClose={handleRemoveEmployeeClose}
          loader={loader2}
          errorMessage={errorMessage}
          handleConfirm={handleRemoveEmployee}
          isReason={isReason}
          setIsReason={setIsReason}
        />
      )}

      <InviteWorkspace
        isOpenInviteWorkspaceModal={isOpenInviteWorkspaceModal}
        setisOpenInviteWorkspaceModal={setisOpenInviteWorkspaceModal}
        user={user}
        senderEmail={selectedUser?.email}
      />
    </>
  );
};

export default ManagePeopleTable;
