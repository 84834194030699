import React, { useEffect, useState } from "react";
import AddCategory from "./AddCategory";
import ItemCategoryHeader from "./ItemCategoryHeader";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import { ProductCategoryRelatedEndPoints } from "../../../../api/Endpoints";
import { useNavigate } from "react-router-dom";
import { navigateToCategoryWiseItems } from "../../../../Navigation/Navigation";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

const ItemCategory = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [search, setSearch] = useState("");
  const [itemCategory, setItemCategory] = useState([]);
  const [editItemCategory, setEditItemCategory] = useState("");

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForCategoryItems =
    allModuleAccess?.[PRIVATEROUTES.MENU_ITEMS];

  const handleGetItemCategory = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
      },
    };
    const res = await ApiCall(
      "post",
      ProductCategoryRelatedEndPoints.getProductCategory,
      data
    );
    if (res?.success) {
      setItemCategory(res?.categories?.data);
      setcount(res?.categories?.totalCounts);
      settotalPages(res?.categories?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    handleGetItemCategory();
    closeAllModals();
  }, [page, pageLimit, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ItemCategoryHeader
            search={search}
            handleSearch={handleSearch}
            handleGetItemCategory={handleGetItemCategory}
            isModuleReadWriteAccessForCategoryItems={
              isModuleReadWriteAccessForCategoryItems
            }
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view mb-7">
                {!loader && itemCategory?.length > 0 && (
                  <div className="row gx-3">
                    {itemCategory?.map((item) => {
                      return (
                        <div className="col-lg-2 col-md-4 col-sm-6">
                          <div
                            className="card w-100 shadow-hover-lg hover-card mb-3"
                            key={item?._id}
                          >
                            <div
                              className="card-header pointer p-0"
                              onClick={() => {
                                navigate(
                                  navigateToCategoryWiseItems({
                                    category: item?.categoryName,
                                    id: item?._id,
                                  })
                                );
                              }}
                            >
                              <div className="overflow-hidden w-100 h-185p rounded rounded-bottom-0 d-flex align-items-center justify-content-center bg-light mx-auto">
                                <img
                                  className="w-100 h-100 objetFit-cover scale-hover"
                                  src={item?.images}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="card-body py-2">
                              <div className="d-flex align-items-center justify-content-between gap-1">
                                <p
                                  className="card-title mb-0 text-truncate pointer"
                                  onClick={() => {
                                    navigate(
                                      navigateToCategoryWiseItems({
                                        category: item?.categoryName,
                                        id: item?._id,
                                      })
                                    );
                                  }}
                                >
                                  {item?.categoryName}
                                </p>
                                {isModuleReadWriteAccessForCategoryItems && (
                                  <div>
                                    <span
                                      className="btn btn-icon btn-sm btn-rounded btn-outline-light show-hover"
                                      data-bs-toggle="modal"
                                      data-bs-target="#add_new_category"
                                      onClick={() => {
                                        setEditItemCategory(item);
                                      }}
                                    >
                                      <span className="feather-icon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-pencil"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                                        </svg>
                                      </span>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {!loader && itemCategory?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={itemCategory?.length}
                  align={"end"}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && itemCategory?.length === 0 && (
                <NoData title="No Item category added" />
              )}
            </div>
          </div>
        </div>
        <AddCategory
          handleGetItemCategory={handleGetItemCategory}
          editItemCategory={editItemCategory}
          setEditItemCategory={setEditItemCategory}
        />
      </div>
    </div>
  );
};

export default ItemCategory;
