import React, { useState } from "react";
import { Link } from "react-router-dom";
import ApiCall from "../../../api/ApiCall";
import { forgotPasswordEndpoints } from "../../../api/Endpoints";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import { startApiCall } from "../../../global-functions/globalFunctions";

const ConfirmPassword = (props) => {
  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    errorMessage,
    seterrorMessage,
    loader,
    navigate,
    setloader,
    otpString,
  } = props;

  const [isShowPassowrd, setisShowPassowrd] = useState(false);

  const handleShowPassword = () => {
    setisShowPassowrd(!isShowPassowrd);
  };
  const handleResetPassword = async (e) => {
    seterrorMessage("");
    e.preventDefault();
    if (password !== confirmPassword) {
      seterrorMessage("Password and confirm password must be same.");
    } else {
      startApiCall(seterrorMessage, setloader);
      const sendData = {
        password: password,
        otp: otpString,
      };
      const res = await ApiCall(
        "post",
        forgotPasswordEndpoints.resetPassword,
        sendData
      );
      if (res?.success) {
        setloader(false);
        navigate("/login");
      } else {
        setloader(false);
        seterrorMessage(res.error);
      }
    }
  };

  return (
    <>
      <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 position-relative mx-auto">
        <div className="auth-content py-md-0 py-8">
          <form className="w-100" onSubmit={handleResetPassword}>
            <div className="row">
              <div className="col-lg-10 mx-auto">
                <h4 className="mb-4">Confirm your password </h4>
                <div className="row gx-3">
                  <div className="form-group col-lg-12">
                    <div className="form-label-group">
                      <label>Password</label>
                    </div>
                    <div className="input-group password-check">
                      <span className="input-affix-wrapper">
                        <input
                          className="form-control"
                          placeholder="Enter Password"
                          type={isShowPassowrd ? "text" : "password"}
                          required
                          pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                          title="Password must be minimum eight characters, at least one letter, one number and one special character."
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                        />
                        <div className="input-suffix text-muted">
                          <span
                            className="feather-icon"
                            onClick={handleShowPassword}
                            style={{ cursor: "pointer" }}
                          >
                            <i
                              className={
                                isShowPassowrd
                                  ? "ri-eye-line"
                                  : "ri-eye-off-line"
                              }
                            ></i>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="form-label-group">
                      <label>Confirm Password</label>
                    </div>

                    <div className="input-group password-check">
                      <span className="input-affix-wrapper">
                        <input
                          className="form-control"
                          placeholder="Enter Password"
                          type="password"
                          required
                          pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                          title="Password must be minimum eight characters, at least one letter, one number and one special character."
                          value={confirmPassword}
                          onChange={(event) =>
                            setConfirmPassword(event.target.value)
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {errorMessage && (
                  <div className="d-flex justify-content-center">
                    <i
                      className="ri-error-warning-line"
                      style={{ color: "red", fontSize: "24px" }}
                    ></i>
                    &nbsp;&nbsp;
                    <p className="link-danger mt-1">{errorMessage}</p>
                  </div>
                )}

                <button
                  type="submit"
                  className="btn btn-primary btn-uppercase btn-block mt-2"
                >
                  {loader ? <ApiLoader /> : "Confirm Password"}
                </button>
                <p className="p-xs mt-2 text-center">
                  Back to{" "}
                  <Link to="/login">
                    <u>Login</u>
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ConfirmPassword;
