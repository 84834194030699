import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Attendance from "../../pages/StaffManagement/StaffAttendance/Attendance/Attendance";
import AttendanceDetails from "../../pages/StaffManagement/StaffAttendance/AttendanceDetails/AttendanceDetails";

const StaffAttendanceRoutes = {
  path: "/staff",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <Attendance />,
    },
    {
      path: "attendance",
      element: <Attendance />,
    },
    {
      path: "attendance/attendancedetails/:id",
      element: <AttendanceDetails />,
    },
  ],
};

export default StaffAttendanceRoutes;
