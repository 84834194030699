import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Poll from "../../pages/Communication/AllPolls/Poll"

const PollRoutes = {
  path: "/communication",
  element: <Navbar />,
  children: [
    {
      path: 'polls',
      element: <Poll />,
    },
  ],
};

export default PollRoutes;
