import React from "react";

const SetReminder = () => {
  return (
    <div
      id="set_new_reminder"
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="mb-4">Create a Reminder</h5>
            <form>
              <div className="row gx-3">
                <div className="col-sm-12 form-group">
                  <label className="form-label">Name</label>
                  <input className="form-control" type="text" />
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12 form-group">
                  <div className="form-label-group">
                    <label>Note/Description</label>
                    <small className="text-muted">200</small>
                  </div>
                  <textarea className="form-control" rows="3"></textarea>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Start Date</label>
                    <input
                      className="form-control"
                      name="single-date-pick"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">Start Time</label>
                    <input
                      className="form-control input-single-timepicker"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-3">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Remind</label>
                    <select className="form-control form-select me-20">
                      <option selected="">Daily</option>
                      <option value="1">Monthly</option>
                      <option value="2">Weekly</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer align-items-center">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Discard
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetReminder;
