import React, { useEffect, useState } from "react";
import ErrorMessage from "../Error/ErrorMessage";
import ApiLoader from "../Loaders/ApiLoader/ApiLoader";

const RenameDetails = (props) => {
  const {
    title,
    errorMessage,
    value,
    setisEdited,
    width,
    handleRename,
    loader,
    isEditDescription
  } = props;

  const [name, setname] = useState("");

  useEffect(() => {
    setname(isEditDescription ? value.description : value?.name);
  }, [value]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: width }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="title title-xs title-wth-divider text-primary text-uppercase mt-1 mb-4">
              <span>{title}</span>
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <div className="row gx-3">
              <div className="col-sm-12">
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={isEditDescription?"Enter Task Description":"Enter Task Name"}
                    required
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer align-items-center">
            <button
              type="button"
              className="btn btn-secondary"
              disabled={loader}
              onClick={() => {
                setisEdited(false);
              }}
            >
              Cancel
            </button>
            <button
              disabled={name === ""}
              onClick={() => {
                handleRename(null, isEditDescription ? "description":"name", name);
              }}
              className="btn btn-primary"
            >
              {loader ? <ApiLoader /> : "Update"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenameDetails;
