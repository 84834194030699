import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ItemCategory from "../../pages/InventoryManagement/InventoryItems/ItemCategories/ItemCategory";
import CategoryItems from "../../pages/InventoryManagement/InventoryItems/CategoryItems/CategoryItems";

const ItemCategoryRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "itemcategories",
      element: <ItemCategory />,
    },
    {
      path: "itemcategories/:category/:id",
      element: <CategoryItems />,
    },
  ],
};

export default ItemCategoryRoutes;
