import React, { useEffect, useState } from "react";
import { Avatar } from "../../../components/Avatar/Avatar";
import { useSelector } from "react-redux";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { PollRelatedEndPoints } from "../../../api/Endpoints";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import { useNavigate } from "react-router-dom";
import { navigateToPoll } from "../../../Navigation/Navigation";
import NoData from "../../../components/NoData/NoData";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";

const PollCard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [loader, setLoader] = useState(false);
  const [allPolls, setallPolls] = useState(null);
  const [page, setPage] = useState(1);
  const [errorMessage, seterrorMessage] = useState("");

  //  ***---- GET POLL API----***
  const handleGetPolls = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      page: page,
      limit: 1,
      filters: {
        businessId: getCurrentWorkspaceId(),
        createdBy: "",
        isDeleted: false,
        moduleId: "",
        moduleType: "",
        searchKey: "",
        employees: user?._id,
      },
    };
    const res = await ApiCall("post", PollRelatedEndPoints.getPoll, data);
    if (res?.success) {
      setallPolls(res?.polls?.data);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetPolls();
    }
  }, []);

  const isAlreadyVoted = () => {
    let isVoted = false;
    allPolls?.[0]?.votedBy?.some((emp) => {
      if (emp?._id === user?._id) {
        return (isVoted = true);
      }
    });
    return isVoted;
  };

  const isPollExpired = () => {
    return new Date().toISOString() >= allPolls?.[0]?.expiresAt ? true : false;
  };

  return (
    <div className="card card-border mnh-350p">
      <div className="card-header">
        {!loader && <h5>Latest Polls</h5>}
        {loader && <SkeletonConstant width="w-200p" height="h-30p" />}
      </div>
      <>
        {!loader && allPolls?.length > 0 && (
          <div className="card-body">
            <div className="d-flex flex-column gap-1">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <Avatar
                    name={allPolls?.[0]?.createdBy?.name}
                    image={allPolls?.[0]?.createdBy?.image}
                    count={1}
                    size="xss"
                    color="primary"
                  />
                  <div className="d-flex flex-column text-dark">
                    <p>{allPolls?.[0]?.createdBy?.name}</p>
                  </div>
                </div>
                <p className="fs-8">
                  Published on :{" "}
                  {dateAndTimeFormatter(allPolls?.[0]?.createdAt)}
                </p>
              </div>
              <span
                className="py-2 px-3 rounded-5 text-dark mb-1 h-75p simple-scrollbar overflow-auto "
                style={{ overflowY: "scroll" }}
              >
                <p className="text-dark fw-semibold">{allPolls?.[0]?.title}</p>
              </span>
              <div className="d-flex flex-column mh-100p mnh-100p overflow-auto ">
                {allPolls?.[0]?.options?.map((opt) => (
                  <div className="form-check mt-xxl-1 ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      class="bi bi-square-fill text-primary mb-3"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2z" />
                    </svg>
                    &nbsp;
                    <label className="form-check-label mw-300p text-truncate ms-1">
                      {opt?.name}
                    </label>
                  </div>
                ))}
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-3">
                  {allPolls?.[0]?.votedBy?.length > 0 && (
                    <span className="avatar-group avatar-group-sm avatar-group-overlapped">
                      {allPolls?.[0]?.votedBy?.map((emp) => {
                        return (
                          <div className="avatar avatar-rounded pointer">
                            <CustomTooltip text={emp?.name} placement="bottom">
                              <Avatar
                                name={emp?.name}
                                count={1}
                                image={emp?.image}
                                size="xss"
                                color="primary"
                              />
                            </CustomTooltip>
                          </div>
                        );
                      })}
                    </span>
                  )}
                  <p>
                    Total Votes :{" "}
                    <span className="text-primary">
                      {allPolls?.[0]?.votedBy?.length}
                    </span>{" "}
                  </p>
                  {!allPolls?.[0]?.isClosed && (
                    <p>
                      {isPollExpired() ? (
                        <span className="text-danger">Poll is Expired</span>
                      ) : (
                        <p>
                          Expired Date :{" "}
                          {dateAndTimeFormatter(allPolls?.[0]?.expiresAt)}
                        </p>
                      )}
                    </p>
                  )}
                  {allPolls?.[0]?.isClosed && (
                    <span className="text-danger">Poll is Closed</span>
                  )}
                </div>
                {!isPollExpired() && !allPolls?.[0]?.isClosed && (
                  <div>
                    {isAlreadyVoted() ? (
                      <span
                        style={{ cursor: "no-drop" }}
                        className="btn btn-secondary btn-sm"
                      >
                        Voted
                      </span>
                    ) : (
                      <span
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          navigate(navigateToPoll());
                        }}
                      >
                        Go to Vote
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="d-flex justify-content-center">
          {loader && (
            <div className="loading-skeleton w-100 d-flex flex-column gap-2 p-3">
              <div className="d-flex justify-content-between gap-3">
                <SkeletonConstant width="w-100p" height="h-30p" />
                <SkeletonConstant width="w-220p" height="h-25p" />
              </div>
              <SkeletonConstant width="w-100" height="h-75p" />
              <SkeletonConstant
                width="w-100"
                height="h-100p"
                additional="mt-3"
              />
            </div>
          )}
          {!loader && allPolls?.length === 0 && (
            <NoData title={`No Poll`} description={"There are no polls"} />
          )}
        </div>
      </>
    </div>
  );
};

export default PollCard;
