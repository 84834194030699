import React, { useEffect, useState } from "react";
import DataLoader from "../../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../../components/NoData/NoData";
import {
  getName,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import { Avatar } from "../../../../components/Avatar/Avatar";
import ApiCall from "../../../../api/ApiCall";
import { VendorRelatedEndPoints } from "../../../../api/Endpoints";
import EditPayment from "../EditPayment";

const PreviousTransactionInvoice = ({
  setpreviousTransaction,
  allTransaction,
  loader,
  setallTransaction,
  setloader2,
  invoiceData,
  allInvoice,
  setInvoiceData,
  isInvoice,
}) => {
  const user = useSelector((state) => state.user);
  const [currentOrderIndex, setCurrentOrderIndex] = useState(
    invoiceData?.index
  );
  const [editPayment, setEditPayment] = useState(false);
  const [editTransData, setEditTransData] = useState(null);

  const handleGetOrdersTransaction = async (orderId) => {
    startApiCall(null, setloader2);
    const data = {
      filters: {
        orderId,
      },
      page: 1,
      limit: 5000,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorTransaction,
      data
    );
    if (res?.success) {
      setallTransaction(res?.transactions?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (allInvoice.length > 0) {
      handleGetOrdersTransaction(
        allInvoice[currentOrderIndex]?.orderId._id
        // isInvoice
        //   ? allInvoice[currentOrderIndex]?.orderId._id
        //   : allInvoice[currentOrderIndex]._id
      );
    }
  }, [currentOrderIndex, allInvoice]);

  const handleNext = () => {
    if (currentOrderIndex < allInvoice.length - 1) {
      setCurrentOrderIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        setInvoiceData({ data: allInvoice[newIndex], index: newIndex });
        return newIndex;
      });
    }
  };

  const handlePrevious = () => {
    if (currentOrderIndex > 0) {
      setCurrentOrderIndex((prevIndex) => {
        const newIndex = prevIndex - 1;
        setInvoiceData({ data: allInvoice[newIndex], index: newIndex });
        return newIndex;
      });
    }
  };

  console.log(
    "allInvoice[currentOrderIndex]?.orderId._id",
    allInvoice[currentOrderIndex]?.orderId._id
  );

  return (
    <>
      <div
        className="custom-modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setpreviousTransaction(false);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="d-flex">
                Previous Transaction{" "}
                <p className="text-primary ms-1">
                  ({invoiceData?.data?.invoiceNumber})
                </p>
              </h5>
              <div className="border-bottom mb-3"></div>
              <>
                <div className="row gx-3">
                  {!loader && allTransaction?.length > 0 && (
                    <div className="contact-list-view mh-400p w-100 overflow-auto">
                      <table
                        id="datable_1"
                        className="table nowrap  mb-3 text-center"
                      >
                        <thead>
                          <tr>
                            <th>Sl No.</th>
                            <th>Amount</th>
                            <th> Payment Mode</th>
                            <th> Reference Number</th>
                            <th> Action By</th>
                          </tr>
                        </thead>

                        <tbody>
                          {allTransaction?.map((data, index) => (
                            <tr class="mb-00">
                              <td>{index + 1} .</td>
                              <td class="text-left mb-00">
                                ₹ {data?.amountReceived}
                              </td>
                              <td class="text-right mb-00 text-capitalize">
                                {data?.paymentMode}
                                <span className="feather-icon pointer">
                                  <span className="btn-icon-wrap">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      title="Edit Table"
                                      className="bi bi-pencil-square ms-2 text-primary"
                                      viewBox="0 0 16 16"
                                      onClick={() => {
                                        setEditTransData(data);
                                        setEditPayment(true);
                                      }}
                                    >
                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                      />
                                    </svg>
                                  </span>
                                </span>
                              </td>
                              <td class="text-right mb-00">
                                {data?.transactionNumber}
                              </td>
                              <td class="text-right mb-00">
                                {" "}
                                <Avatar
                                  name={data?.transactionDoneBy?.name}
                                  count={1}
                                  image={data?.transactionDoneBy?.image}
                                  size="xxs"
                                  color="primary"
                                />
                                &nbsp;
                                {getName(data?.transactionDoneBy, user?._id)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {loader && <DataLoader title="data is loading..." />}
                  {!loader && allTransaction?.length === 0 && (
                    <NoData title="No Transactions Added" />
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    className="btn btn-primary"
                    onClick={handlePrevious}
                    disabled={currentOrderIndex === 0}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-left-circle me-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                      />
                    </svg>
                    Previous
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleNext}
                    disabled={allInvoice?.length - 1 === currentOrderIndex}
                  >
                    Next{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right-circle ms-2"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                      />
                    </svg>
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {editPayment && (
        <EditPayment
          setEditPayment={setEditPayment}
          editTransData={editTransData}
          handleGetOrdersTransaction={handleGetOrdersTransaction}
          allInvoice={allInvoice}
          currentOrderIndex={currentOrderIndex}
        />
      )}
    </>
  );
};

export default PreviousTransactionInvoice;
