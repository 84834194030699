import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  reuiredField,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { InventorySettingsRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiCall from "../../../api/ApiCall";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";

const AddCharges = ({
  handleGetBussinessInventoryDetails,
  handleGetOrderChargesDetails,
  editCharges,
  seteditCharges,
}) => {
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [chargeName, setChargeName] = useState("");
  const [chargeType, setChargeType] = useState("percentage");
  const [isVisible, setIsVisible] = useState(true);
  const [value, setValue] = useState("");
  const [add, setAdd] = useState("add");

  useEffect(() => {
    if (editCharges) {
      setChargeName(editCharges?.chargeName);
      setChargeType(editCharges?.chargeType);
      setIsVisible(editCharges?.isVisible);
      setValue(editCharges?.value);
      setAdd(editCharges?.add ? "add" : "");
    }
  }, [editCharges]);

  const handleReset = () => {
    setChargeName("");
    setChargeType("percentage");
    setIsVisible(true);
    setValue("");
    setAdd("add");
    seteditCharges("");
    setErrorMessage("");
    setloader(false);
    const closeModalDom = document.getElementById("add_new_charge");
    if (closeModalDom) closeModalDom.click();
  };

  // ADD ORDER CHARGES FUNCTION
  const handleAddCharge = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      businessId: getCurrentWorkspaceId(),
      chargeName: chargeName,
      chargeType: chargeType,
      isVisible: isVisible,
      value: value,
      add: add === "add" ? true : false,
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.addCharge,
      sendData
    );
    if (res?.success) {
      handleReset();
      toast.success("Add charge successfully!");
      handleGetBussinessInventoryDetails();
      handleGetOrderChargesDetails();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };
  // EDIT ORDER CHARGES FUNCTION
  const handleEditCharge = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      chargeId: editCharges?._id,
      chargeName: chargeName,
      chargeType: chargeType,
      isVisible: isVisible,
      value: value,
      add: add === "add" ? true : false,
    };
    const res = await ApiCall(
      "post",
      InventorySettingsRelatedEndPoints.editCharge,
      data
    );
    if (res?.success) {
      toast.success("Order Charges Updated successfully!");
      handleReset();
      handleGetBussinessInventoryDetails();
      handleGetOrderChargesDetails();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="add_new_charge"
      className="modal fade add-new-task"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content px-2">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editCharges === "" ? "Add" : "Edit"}&nbsp; Additional Charge
            </h5>
            <div className="border-bottom mb-2"></div>
            <form
              onSubmit={(e) => {
                if (editCharges === "") {
                  handleAddCharge(e);
                } else {
                  handleEditCharge(e);
                }
              }}
            >
              <div className="row gx-3">
                <div className="col-12">
                  <div className="row gx-5 justify-content-between">
                    <div className="col-8">
                      <div className="form-group">
                        <div className="d-flex gap-3 mb-2">
                          <span
                            onClick={() => {
                              setAdd("add");
                            }}
                            className={`btn ${
                              add === "add"
                                ? "btn-outline-primary"
                                : "btn-outline-secondary"
                            }`}
                          >
                            Add
                          </span>
                          <span
                            onClick={() => {
                              setAdd("");
                            }}
                            className={`btn ${
                              add === "add"
                                ? "btn-outline-secondary"
                                : "btn-outline-primary"
                            }`}
                          >
                            Subtract
                          </span>
                        </div>
                        <p className="text-secondary fs-8">
                          {`The chargable amount will ${
                            add === "add" ? "add with" : "subtract from"
                          } the cart value
                          on using this charge`}
                        </p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="form-group d-flex gap-3 justify-content-end">
                        <label class="form-check-label">Visibility</label>
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            checked={isVisible}
                            onClick={() => {
                              setIsVisible(!isVisible);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="form-group">
                    <label className="form-label">
                      Charge Title {reuiredField}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={chargeName}
                      required
                      onChange={(e) => {
                        setChargeName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">
                      Charge Type {reuiredField}
                    </label>
                    <select
                      className="form-select form-select text-capitalize"
                      value={chargeType}
                      onChange={(e) => {
                        setChargeType(e.target.value);
                      }}
                    >
                      <option value="percentage">percentage</option>
                      <option value="amount">amount</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label className="form-label">
                      Charge Amount ({chargeType === "percentage" ? "%" : "₹"}){" "}
                      {reuiredField}
                    </label>
                    <input
                      type="number"
                      class="form-control"
                      value={value}
                      required
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center px-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    loader ||
                    (!editCharges ? !(chargeName && value && isVisible) : "")
                  }
                >
                  {loader ? (
                    <ApiLoader />
                  ) : editCharges === "" ? (
                    "Save"
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCharges;
