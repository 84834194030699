import React, { useState } from "react";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import allCountries from "../../../components/Countries/Countries";
import {
  handlePreviewFileInLocal,
  reuiredField,
} from "../../../global-functions/globalFunctions";

const VendorInfo = (props) => {
  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    setcountryCode,
    countryCode,
    panNo,
    setPanNo,
    selectSetup2,
    editVendor,
    completeFirstStep,
    image,
    setimage,
    setFileForUpload,
  } = props;

  const [codeFilter, setcodeFilter] = useState("");
  const filteredCountries = allCountries?.filter(
    (country) =>
      country?.code?.includes(codeFilter) ||
      country?.country?.toLowerCase()?.includes(codeFilter?.toLowerCase())
  );

  return (
    <div className="d-flex flex-column justify-content-between border rounded-7 p-4 w-100 mnh-350p h-100">
      <div className="d-flex flex-column gap-2">
        {!editVendor && (
          <div className="d-flex align-items-center justify-content-center dropify-square">
            {image ? (
              <>
                <input
                  type="file"
                  name="image"
                  id="file"
                  accept="image/png,image/jpeg"
                  className="input-file"
                  onChange={setFileForUpload}
                />
                <label htmlFor="file" className="position-relative pointer">
                  <img
                    htmlFor="file"
                    id="local_show_img"
                    src={handlePreviewFileInLocal(image)}
                    style={{ padding: 5 }}
                    className="dropify-wrapper text-center"
                    alt="img"
                  />
                  <span
                    className="position-top-end-overflow"
                    onClick={() => setimage("")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </span>
                </label>
              </>
            ) : (
              <span className="dropify-wrapper text-center">
                <input
                  type="file"
                  name="image"
                  id="file"
                  accept="image/png,image/jpeg"
                  className="input-file"
                  onChange={setFileForUpload}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-upload"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>
                <label htmlFor="file" className="pointer text-center">
                  Upload Image
                </label>
              </span>
            )}
          </div>
        )}
        <div className="d-flex gap-2 mt-2">
          <span className="text-dark w-20">Name {reuiredField}</span>
          &nbsp;
          <input
            className="form-control w-50"
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter Name"
          />
        </div>
        <div className="d-flex gap-2 mt-2">
          <span className="text-dark w-20">Phone {reuiredField}</span>
          &nbsp;
          <div className="selectable-dropdown">
            <div className="dropdown selectable-dropdown">
              <span
                className={
                  "me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-110p "
                }
                aria-expanded="false"
                type="button"
                data-bs-toggle="dropdown"
                onClick={() => setcodeFilter("")}
              >
                {countryCode ? (
                  <div className="d-flex justify-content-between">
                    + {countryCode}
                    {countryCode !== "" && (
                      <button
                        type="button"
                        className="btn-close btn-sm ms-1"
                        onClick={() => {
                          setcountryCode("");
                          setcodeFilter("");
                        }}
                        aria-label="Close"
                      ></button>
                    )}
                  </div>
                ) : (
                  "Code"
                )}
              </span>
              <div role="menu" className="dropdown-menu">
                <form className="d-sm-block d-none" role="search">
                  <input
                    type="text"
                    placeholder="Search.."
                    className="form-control"
                    value={codeFilter}
                    onChange={(e) => setcodeFilter(e.target.value)}
                  />
                </form>
                <div
                  style={{
                    maxHeight: "24vh",
                    overflow: "auto",
                    zIndex: 9999,
                  }}
                >
                  {filteredCountries?.map((country) => (
                    <div
                      className="dropdown-item text-Capitalize"
                      key={country.country}
                      onClick={() => setcountryCode(country.code)}
                    >
                      <span>
                        {" "}
                        + {country?.code} &nbsp; {country?.country}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <input
            className="form-control w-50 "
            type="number"
            pattern="[0-9]{10}"
            title="Please enter a 10-digit phone number"
            required
            value={phone}
            disabled={!countryCode}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter Phone No."
          />
        </div>
        <div className="d-flex gap-2 mt-2">
          <span className="text-dark w-20">Email</span>
          &nbsp;
          <input
            className="form-control w-50"
            type="email"
            required
            disabled={editVendor}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter Email"
          />
        </div>
        <div className="d-flex gap-2 mt-2">
          <span className="text-dark w-20">Pan No </span>
          &nbsp;
          <input
            className="form-control w-50"
            type="text"
            required
            value={panNo}
            onChange={(e) => setPanNo(e.target.value)}
            placeholder="Enter Pan No"
          />
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end mt-2">
        <button
          className="btn btn-sm btn-primary px-4"
          onClick={selectSetup2}
          disabled={!completeFirstStep}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default VendorInfo;
