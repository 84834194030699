import React from "react";
import NoData from "../../../../components/NoData/NoData";

const TaskModal = ({ setTaskModal, statusTask, editStatus }) => {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: "500px"}}>
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              onClick={() => setTaskModal(false)}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="d-flex ps-2">
              Mandatory Tasks &nbsp;
              <p className="text-primary fs-6 mt-1">({editStatus?.name})</p>
            </h5>
            <div className="border-bottom mb-0"></div>
            <div className="d-flex flex-column mh-450p overflow-auto simple-scrollbar">
              {statusTask?.file?.mandatory === true ? (
                <div className="collapse-simple">
                  <div className="card">
                    <div className="card-header">
                      <span
                        role="button"
                        data-bs-toggle="collapse"
                        href="#file"
                        aria-expanded="true"
                        className="fw-light text-uppercase"
                      >
                        <p className="fw-semibold mb-0 px-2">File :</p>
                      </span>
                    </div>
                    <div id="file" className="collapse show">
                      <div className="d-flex align-items-center gap-2 px-2">
                        <span>Mandatory :</span>
                        <span>
                          {statusTask?.file?.mandatory === true ? (
                            <p className="mb-0 text-success">True</p>
                          ) : (
                            <p className="mb-0 text-danger">False</p>
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="px-2">Description :</span>
                        <span className="bg-light p-2 mh-100p overflow-auto simple-scrollbar mb-0">
                          {statusTask?.file?.description}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {statusTask?.location?.mandatory === true ? (
                <div className="collapse-simple">
                  <div className="card">
                    <div className="card-header">
                      <span
                        role="button"
                        data-bs-toggle="collapse"
                        href="#location"
                        aria-expanded="true"
                        className="fw-light text-uppercase"
                      >
                        <p className="fw-semibold mb-0 px-2">Location :</p>
                      </span>
                    </div>
                    <div id="location" className="collapse show">
                      <div className="d-flex align-items-center gap-2 px-2">
                        <span>Mandatory :</span>
                        <span>
                          {statusTask?.location?.mandatory === true ? (
                            <p className="mb-0 text-success">True</p>
                          ) : (
                            <p className="mb-0 text-danger">False</p>
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="px-2">Description :</span>
                        <span className="bg-light p-2 mh-100p overflow-auto simple-scrollbar mb-0">
                          {statusTask?.location?.description}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {statusTask?.note?.mandatory === true ? (
                <div className="collapse-simple">
                  <div className="card">
                    <div className="card-header">
                      <span
                        role="button"
                        data-bs-toggle="collapse"
                        href="#note"
                        aria-expanded="true"
                        className="fw-light text-uppercase"
                      >
                        <p className="fw-semibold mb-0 px-2">Note :</p>
                      </span>
                    </div>
                    <div id="note" className="collapse show">
                      <div className="d-flex align-items-center gap-2 px-2">
                        <span>Mandatory :</span>
                        <span>
                          {statusTask?.note?.mandatory === true ? (
                            <p className="mb-0 text-success">True</p>
                          ) : (
                            <p className="mb-0 text-danger">False</p>
                          )}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="px-2">Description :</span>
                        <span className="bg-light p-2 mh-100p overflow-auto simple-scrollbar mb-0">
                          {statusTask?.note?.description}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {statusTask?.file?.mandatory === false &&
                statusTask?.location?.mandatory === false &&
                statusTask?.note?.mandatory === false && (
                  <NoData title="No mandatory tasks" />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskModal;
