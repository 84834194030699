import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  dateFormatter,
  getAllMyBusinessesUsingFilter,
  getAllStatusBusinesses,
  getCurrentWorkspaceId,
  startApiCall,
  switchIntoAnotherWorkspace,
} from "../../../global-functions/globalFunctions";
import { actionCreators } from "../../../redux-setup";

const BusinessDropDown = ({ user, myApproveWorkspaces }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const handleBusiness = async () => {
    setLoader(true);
    const allWorkspaces = await getAllStatusBusinesses(user?._id);
    let allPendingWorkspaces = await getAllMyBusinessesUsingFilter(
      allWorkspaces?.business,
      "pending"
    );
    let allApprovedWorkspaces = await getAllMyBusinessesUsingFilter(
      allWorkspaces?.business,
      "approved"
    );
    dispatch(actionCreators.myPendingWorkspaces(allPendingWorkspaces));
    dispatch(actionCreators.myApproveWorkspaces(allApprovedWorkspaces));
    setLoader(false);
  };

  return (
    <>
      <div
        className="dropdown-menu dropdown-menu-end business-dropdown"
        style={{ width: "300px" }}
      >
        <div className="p-2">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p className="fw-light text-light fs-6">
              Your Workspaces
              <span onClick={handleBusiness} className="ms-1 fw-bold pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-clockwise"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                  />
                  <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                </svg>
              </span>
            </p>
            <p className=" fw-semibold">
              <span className="icon">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-briefcase"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                  </svg>
                </span>
              </span>
            </p>
          </div>
          <div className="dropdown-divider"></div>
          {loader && <p className="text-dark text-center">Loading...</p>}
          <div
          className="mh-350p simplebar-hide-scrollbar"
          style={{ overflowY: "scroll" }}
        >
          {myApproveWorkspaces?.map((business, index) => {
            return (
              <div
                key={index}
                className="media align-items-center active-user mt-2 py-1"
              >
                <div className="media-body">
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex">
                      <span className="icon me-2 btn btn-icon btn-rounded btn-soft-primary">
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-briefcase "
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                          </svg>
                        </span>
                      </span>

                      <div>
                        <p
                          className={
                            getCurrentWorkspaceId() === business?._id
                              ? "d-flex align-items-center link-dark text-medium-em fw-light"
                              : "d-flex align-items-center link-dark text-medium-em"
                          }
                        >
                          {business?.name}
                        </p>
                        <span className="d-block fs-8 link-secondary">
                          <p>{dateFormatter(business?.createdAt)}</p>
                        </span>
                      </div>
                    </div>

                    <input
                      className="form-check-input circle success cursor pointer"
                      type="checkbox"
                      onChange={() => {
                        switchIntoAnotherWorkspace(business?._id);
                      }}
                      checked={getCurrentWorkspaceId() === business?._id}
                    />
                  </div>
                </div>
              </div>
            );
          })}
</div>
          <Link
            to="/workspace"
            className="btn btn-block btn-outline-light btn-sm mt-2"
          >
            <span>
              <span className="icon">
                <span className="feather-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-gear-wide-connected"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z" />
                  </svg>
                </span>
              </span>
              <span>Manage Workspaces</span>
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BusinessDropDown;
