import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { VendorRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import ItemsTableBills from "./ItemsTableBills";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";

const CreateBillsBody = ({
  selectedVendor,
  setselectedVendor,
  billDate,
  setBillDate,
  dueDate,
  setdueDate,
  billNumber,
  setBillNumber,
  adjustment,
  setadjustment,
  discount,
  setDiscount,
  errorMessage,
  items,
  billItems,
  setTermsAndConditions,
  termsAndConditions,
  handleAddBill,
  calculateTotalSum,
  setEditItem,
  locationData,
  handleEditBill,
  loader3,
  vendorData,
  setvendorData,
  editBills,
  subject,
  setSubject,
}) => {
  const [loader2, setloader2] = useState(false);
  const [allVendors, setAllVendors] = useState([]);
  const [filter, setFilter] = useState("");

  const filteredVendors = filterSearchData(allVendors, filter);

  // GET ALL VENDOR FUNCTION API CALL
  const handleGetAllVendor = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall("post", VendorRelatedEndPoints.getVendor, data);
    if (res?.success) {
      setAllVendors(res?.vendor?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  return (
    <div className="contact-list-view">
      <div className="row">
        <div className="col-md-9 p-5 pt-3">
          <div className="d-flex flex-column gap-3">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row w-100 align-items-center">
                  <div className="col-sm-4">
                    <label>Vendor Name </label>
                  </div>
                  <div className="col-sm-8">
                    <div className="form-group mb-0">
                      <div className="todo-toolbar">
                        <div className="selectable-dropdown">
                          <div className="dropdown selectable-dropdown">
                            <span
                              className={`me-2 fs-7 fw-medium text-dark text-capitalize border rounded p-2 w-200p mt-2 ${
                                editBills ? "bg-light text-disabled" : ""
                              }`}
                              aria-expanded="false"
                              type="button"
                              data-bs-toggle="dropdown"
                              disabled={locationData?.id}
                              onClick={() => {
                                handleGetAllVendor();
                                setFilter("");
                              }}
                            >
                              {selectedVendor ? (
                                <div className="d-flex justify-content-between">
                                  {vendorData}
                                  {selectedVendor !== "" && !editBills && (
                                    <button
                                      type="button"
                                      className="btn-close btn-sm ms-1"
                                      onClick={() => {
                                        setselectedVendor("");
                                        setFilter("");
                                        setvendorData("");
                                      }}
                                      aria-label="Close"
                                    ></button>
                                  )}
                                </div>
                              ) : (
                                "Select Vendor"
                              )}
                            </span>
                            <div role="menu" className="dropdown-menu ">
                              <form className="d-sm-block d-none" role="search">
                                <input
                                  type="text"
                                  placeholder="Search.."
                                  className="form-control"
                                  value={filter}
                                  onChange={(e) => setFilter(e.target.value)}
                                />
                              </form>
                              {loader2 ? (
                                <span className="d-flex text-center mt-1">
                                  Loading...
                                </span>
                              ) : (
                                <div
                                  style={{
                                    maxHeight: "30vh",
                                    overflow: "auto",
                                  }}
                                >
                                  {filteredVendors?.map((vendor) => (
                                    <div
                                      className="dropdown-item text-capitalize"
                                      key={vendor._id}
                                      onClick={() => {
                                        setselectedVendor(vendor._id);
                                        setvendorData(vendor?.name);
                                      }}
                                    >
                                      {vendor?.name}
                                    </div>
                                  ))}
                                </div>
                              )}
                              {!loader2 && filteredVendors?.length === 0 && (
                                <span className="d-flex text-center mt-1">
                                  No Vendors found
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row w-100 align-items-center">
                  <div className="col-sm-4">
                    <label>Bill date</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="date"
                      disabled={locationData?.id}
                      value={billDate}
                      onChange={(e) => {
                        setBillDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row w-100 align-items-center">
                  <div className="col-sm-4">
                    <label>Bill number </label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="text"
                      disabled={locationData?.id}
                      value={billNumber}
                      onChange={(e) => {
                        setBillNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row w-100 align-items-center">
                  <div className="col-sm-4">
                    <label>Due date </label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="date"
                      min={billDate}
                      value={dueDate}
                      onChange={(e) => {
                        setdueDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div class="form-group row">
                  <div className="col-md-2 col-sm-4">
                    <label className="form-label">Bill subject</label>
                  </div>
                  <div className="col-md-10 col-sm-8">
                    <div className="d-flex align-items-start">
                      <textarea
                        class="form-control"
                        placeholder="Add subject"
                        rows="2"
                        value={subject}
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ItemsTableBills
              billItems={billItems}
              setEditItem={setEditItem}
              locationData={locationData}
            />

            <div className="d-flex justify-content-end me-4">
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#add_bill_item"
              >
                + Add Item
              </button>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group d-flex align-items-center justify-content-between gap-3">
                  <label>Discount</label>
                  <input
                    className="form-control w-75"
                    type="number"
                    placeholder="discount in %"
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group d-flex align-items-center justify-content-between gap-3">
                  <label>Adjustment</label>
                  <input
                    className="form-control w-75"
                    type="number"
                    value={adjustment}
                    onChange={(e) => {
                      setadjustment(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  placeholder="Add terms and conditions"
                  rows="3"
                  value={termsAndConditions}
                  onChange={(e) => {
                    setTermsAndConditions(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center justify-content-between border text-dark fw-semibold px-3 py-2">
                    <p>Total Amount</p>
                    <p>{calculateTotalSum()}</p>
                  </div>
                </div>
              </div>
            </div>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <div className="d-flex modal-footer align-items-center justify-content-end ">
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (locationData?.id) {
                    handleEditBill();
                  } else {
                    handleAddBill();
                  }
                }}
                disabled={
                  !billDate ||
                  !dueDate ||
                  !selectedVendor ||
                  !billNumber ||
                  !items.length
                }
              >
                {loader3 ? (
                  <ApiLoader />
                ) : locationData?.id ? (
                  "Edit Bill"
                ) : (
                  "Create Bill"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBillsBody;
