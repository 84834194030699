import React, { useEffect, useState } from "react";
import GroupDetailsHeader from "./GroupDetailsHeader";
import ParticipantTable from "./ParticipantTable";
import LeadCountTable from "./LeadCountTable";
import GroupLogs from "./GroupLogs";
import AddParticipants from "../../Group/GroupStatus/AddParticipants";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ApiCall from "../../../../api/ApiCall";
import {
  CrmParticipantsGroup,
  DealRelatedEndPoints,
  LeadRelatedEndPoints,
} from "../../../../api/Endpoints";
import { toast } from "react-toastify";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import FooterPagination from "../../../../components/Pagination/FooterPagination";
import DealCountTable from "./DealCountTable";
import PRIVATEROUTES from "../../../../components/Constant/Route-Constants";

const GroupDetails = () => {
  const { user, allModuleAccess } = useSelector((state) => state);
  const { id } = useParams();
  const location = useLocation();
  const [locationData, setlocationData] = useState([]);
  const [selectTab, setSelectTab] = useState("Leads");
  const [addParticipant, setAddparticipant] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedAssigneeId, setselectedAssigneeId] = useState([]);
  const [groupDetails, setGroupDetails] = useState([]);
  const [groupLogs, setGroupLogs] = useState([]);
  const [allGroupMembers, setallGroupMembers] = useState([]);
  const [allLeads, setAllLeads] = useState([]);
  const [allDeals, setAllDeals] = useState([]);
  const [loader, setloader] = useState(false);
  const [loader3, setloader3] = useState(false);
  const [loader4, setloader4] = useState(false);

  const isModuleReadWriteAccessForParticipantGroup =
    allModuleAccess?.[PRIVATEROUTES.PARTICIPANT_GROUP];

  // ******* PAGINATION *********
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(50);
  const [totalPages, settotalPages] = useState(0);

  const [count2, setCount2] = useState(0);
  const [page2, setPage2] = useState(1);
  const [pageLimit2, setpageLimit2] = useState(30);
  const [totalPages2, settotalPages2] = useState(0);

  const tabs = [
    {
      name: "Leads",
      isCount: true,
      count: allLeads?.length,
    },
    {
      name: "Deals",
      isCount: true,
      count: allDeals?.length,
    },
    {
      name: "Activity Logs",
      isCount: false,
    },
    {
      name: "Participants",
      isCount: false,
    },
  ];

  const handleTabChange = (mode) => {
    setSelectTab(mode);
  };

  const handleClose = () => {
    setErrorMessage("");
    setloader2(false);
    setAddparticipant(false);
    setselectedAssigneeId([]);
  };

  const handleGetAllParticipants = async () => {
    startApiCall(null, setloader);
    const res = await ApiCall("post", CrmParticipantsGroup.getGroupById, {
      leadGroupId: id,
    });
    if (res?.success) {
      const mergedArray = [
        ...(res?.result?.admins || []),
        ...(res?.result?.managers || []),
        ...(res?.result?.employees || []),
      ];
      const updatedArray = mergedArray.map((item) => ({
        ...item,
        role: res?.result?.admins?.some((admin) => admin?._id === item?._id)
          ? "admins"
          : res?.result?.managers?.some((manager) => manager?._id === item?._id)
          ? "managers"
          : "employee",
      }));
      setallGroupMembers(updatedArray);
      setGroupDetails(res?.result);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (selectTab === "Participants") {
      handleGetAllParticipants();
      handleGetAllLeads();
    } else if (selectTab === "Activity Logs") {
      handleGetGroupLogs();
    } else if (selectTab === "Leads") {
      handleGetAllLeads();
      handleGetAllDeals();
    }
  }, [selectTab, page, pageLimit]);

  useEffect(() => {
    if (selectTab === "Deals") {
      handleGetAllDeals();
    }
  }, [selectTab, page2, pageLimit2])

  const employeeIds = allGroupMembers?.map((data) => data?._id);
  const filteredEmployeeIds = selectedAssigneeId.filter(
    (id) => !employeeIds.includes(id)
  );

  // ADD PARTICIPANT FUNCTION API FOR CALL
  const handleAddParticipant = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader2);
    const data = {
      leadGroupId: id,
      employees: filteredEmployeeIds,
      actionBy: user?._id,
      isAdded: true,
    };
    const res = await ApiCall(
      "post",
      CrmParticipantsGroup.addParticipant,
      data
    );
    if (res?.success) {
      handleClose();
      handleGetAllParticipants();
      toast.success("Add Participants successfully!");
    } else {
      setloader2(false);
      setErrorMessage(res.error);
    }
  };

  useEffect(() => {
    let temp = [];
    allGroupMembers?.map((emp) => {
      temp.push(emp?._id);
    });
    setselectedAssigneeId(temp);
  }, [groupDetails, addParticipant]);

  useEffect(() => {
    setlocationData(location?.state);
  }, [location?.state]);

  const handleGetGroupLogs = async () => {
    startApiCall(null, setloader3);
    const data = {
      page: page,
      limit: pageLimit,
      filters: { leadGroupId: id },
    };
    const res = await ApiCall("post", CrmParticipantsGroup.getGrouplogs, data);
    if (res?.success) {
      setGroupLogs(res?.result?.data);
      setCount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader3(false);
    } else {
      setloader3(false);
    }
  };

  const handleGetAllLeads = async () => {
    startApiCall(null, setloader2);
    const data = { 
      employeeId: user?._id,
      page: page,
      limit: pageLimit,
      filters: {
        participantsGroupId: id,
      },
    };
    const res = await ApiCall(
      "post",
      LeadRelatedEndPoints.getLead,
      data
    );
    if (res?.success) {
      setAllLeads(res?.result?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const handleGetAllDeals = async () => {
    startApiCall(null, setloader4);
    const data = {
      page: page2,
      limit: pageLimit2,
      employeeId: user?._id,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "deal",
        participantsGroupId: id,
      },
    };
    const res = await ApiCall("post", DealRelatedEndPoints.getDeal, data);
    if (res?.success) {
      setAllDeals(res?.result?.data);
      setCount2(res?.result?.totalCounts);
      settotalPages2(res?.result?.totalPages);
      setloader4(false);
    } else {
      setloader4(false);
    }
  };

  const handleRefresh = () => {
    handleGetAllParticipants();
    handleGetGroupLogs();
    handleGetAllLeads();
    handleGetAllDeals();
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <GroupDetailsHeader
            setAddparticipant={setAddparticipant}
            handleRefresh={handleRefresh}
            locationData={locationData}
            isModuleReadWriteAccessForParticipantGroup={isModuleReadWriteAccessForParticipantGroup}
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <div className="d-flex justify-content-center mb-3">
                  <div className="tabs px-2 py-2">
                    {tabs?.map((name) => {
                      return (
                        <span
                          onClick={() => {
                            handleTabChange(name?.name);
                          }}
                          className={`${
                            selectTab === name?.name ? "select" : ""
                          } pointer`}
                          key={name?.name}
                          style={{ position: "relative" }}
                        >
                          {name?.name}{" "}
                          {name.isCount && (
                            <span className="ms-1 badge badge-green badge-sm badge-soft-primary">
                              {name.count}
                            </span>
                          )}
                        </span>
                      );
                    })}
                  </div>
                </div>

                {selectTab === "Participants" && (
                  <ParticipantTable
                    loader={loader}
                    id={id}
                    handleGetAllParticipants={handleGetAllParticipants}
                    groupDetails={groupDetails}
                    allGroupMembers={allGroupMembers}
                    user={user}
                    isModuleReadWriteAccessForParticipantGroup={isModuleReadWriteAccessForParticipantGroup}
                  />
                )}
                {selectTab === "Activity Logs" && (
                  <>
                    <GroupLogs
                      id={id}
                      selectTab={selectTab}
                      groupLogs={groupLogs}
                      loader={loader3}
                    />
                    {groupLogs?.length > 0 && (
                      <FooterPagination
                        setpageLimit={setpageLimit}
                        pageLimit={pageLimit}
                        setPage={setPage}
                        page={page}
                        totalPages={totalPages}
                        count={count}
                        dataLength={groupLogs?.length}
                        align={"end"}
                      />
                    )}
                  </>
                )}
                {selectTab === "Leads" && (
                  <>
                    <LeadCountTable allLeads={allLeads} loader={loader2} />
                    {allLeads?.length > 0 && (
                      <FooterPagination
                        setpageLimit={setpageLimit}
                        pageLimit={pageLimit}
                        setPage={setPage}
                        page={page}
                        totalPages={totalPages}
                        count={count}
                        dataLength={allLeads?.length}
                        align={"end"}
                      />
                    )}
                  </>
                )}
                {selectTab === "Deals" && (
                  <>
                    <DealCountTable loader4={loader4} allDeals={allDeals} />
                    {allDeals?.length > 0 && (
                      <FooterPagination
                        setpageLimit={setpageLimit2}
                        pageLimit={pageLimit2}
                        setPage={setPage2}
                        page={page2}
                        totalPages={totalPages2}
                        count={count2}
                        dataLength={allDeals?.length}
                        align={"end"}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {addParticipant && (
          <AddParticipants
            addParticipant={addParticipant}
            selectedAssigneeId={selectedAssigneeId}
            setselectedAssigneeId={setselectedAssigneeId}
            handleClose={handleClose}
            loader2={loader2}
            errorMessage={errorMessage}
            handleAddParticipant={handleAddParticipant}
            allGroupMembers={allGroupMembers}
            filteredEmployeeIds={filteredEmployeeIds}
            isSingle={true}
          />
        )}
      </div>
    </div>
  );
};

export default GroupDetails;
