import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Quotes from "../../pages/AccountManagement/Quotes/Quotes";
import QuotesDetails from "../../pages/AccountManagement/Quotes/QuotesDetails.jsx/QuotesDetails";
import AddQuote from "../../pages/AccountManagement/Quotes/AddQuote/AddQuote";

const QuotesRoutes = {
  path: "/account",
  element: <Navbar />,
  children: [
    {
      path: "allquotes",
      element: <Quotes />,
    },
    {
      path: "quotesdetails/:id",
      element: <QuotesDetails />,
    },
    {
      path: "addquote",
      element: <AddQuote />,
    },
  ],
};

export default QuotesRoutes;
