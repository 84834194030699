import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Holiday from "../../pages/Holiday/Holiday";

const HolidayRoutes = {
  path: "/staff",
  element: <Navbar />,
  children: [
    {
      path: '',
      element: <Holiday />,
    },
    {
      path: 'holiday',
      element: <Holiday />,
    },
  ],
};

export default HolidayRoutes;