import React from "react";
import { Link } from "react-router-dom";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const SignupRight = (props) => {
  const { setEmail, email, errorMessage, loader, handleEmailVerify } = props;

  return (
    <div className="col-xl-5 col-lg-6 col-md-7 col-sm-10 position-relative mx-auto">
      <div className="auth-content py-md-0 py-8">
        <form className="w-100" onSubmit={handleEmailVerify}>
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <h4 className="text-center mb-4">Sign up to Sociohood</h4>
              <div className="row gx-3">
                <div className="form-group col-lg-12">
                  <label className="form-label">Email</label>
                  <input
                    className="form-control"
                    placeholder="Enter your email address"
                    value={email}
                    required
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

              <button
                type="submit"
                className="btn btn-primary  btn-uppercase btn-block"
              >
                {loader ? <ApiLoader /> : "Verify Email"}
              </button>
              <p className="p-xs mt-2 text-center">
                Already a member ? <Link to="/login">Log In</Link>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupRight;
