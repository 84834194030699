import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";
import ApiCall from "../../../api/ApiCall";
import { StatusRelatedEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const CreateGroup = ({
  handleGetAllGroup,
  editAllGroups,
  seteditAllGroups,
}) => {
  const user = useSelector((state) => state.user);

  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [groupName, setgroupName] = useState("");
  const [moduleType, setModuleType] = useState("Lead");

  useEffect(() => {
    if (editAllGroups) {
      setgroupName(editAllGroups?.name);
      setModuleType(editAllGroups?.moduleType);
    }
  }, [editAllGroups]);

  const handleReset = () => {
    setloader(false);
    setErrorMessage("");
    setgroupName("");
    setModuleType("Deal");
    seteditAllGroups("");
    const closeModalDom = document.getElementById("create_new_group");
    if (closeModalDom) closeModalDom.click();
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const sendData = {
      name: groupName,
      moduleType: moduleType,
      createdBy: user?._id,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.addGroup, sendData);
    if (res?.success) {
      toast.success("Create group successfully!");
      handleReset();
      handleGetAllGroup();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      name: groupName,
      groupStatusId: editAllGroups?._id,
      // lead: editAllGroups?.leads,
      // activityStatus: editAllGroups?.,
    };
    const res = await ApiCall("post", StatusRelatedEndPoints.editGroup, data);
    if (res?.success) {
      toast.success("Edit group successfully!");
      handleReset();
      handleGetAllGroup();
    } else {
      setloader(false);
      setErrorMessage(res.error);
    }
  };

  return (
    <div
      id="create_new_group"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close mt-2 pe-3"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5 className="ps-2">
              {editAllGroups !== "" ? "Edit Group" : "Create Group"}
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (editAllGroups === "") {
                  handleCreateGroup(e);
                } else {
                  handleEditGroup(e);
                }
              }}
            >
              <div className="mh-450p nicescroll-bar simple-scrollbar">
                <div className="row gx-3 px-3 w-100">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Group Name</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        value={groupName}
                        onChange={(e) => {
                          setgroupName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Module type</label>
                      <select
                        className="form-select form-select-md"
                        value={moduleType}
                        onChange={(e) => setModuleType(e.target.value)}
                        disabled={editAllGroups && moduleType.length > 0}
                      >
                        <option value="Lead">Lead</option>
                        <option value="Deal">Deal</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer align-items-center mt-3">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!groupName}
                >
                  {loader ? (
                    <ApiLoader />
                  ) : editAllGroups === "" ? (
                    "Add"
                  ) : (
                    "Edit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateGroup;
