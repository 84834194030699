import React, { useEffect, useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import NoData from "../../../components/NoData/NoData";

const OrderCharges = ({
  loader,
  orderCharges,
  seteditCharges,
  handleEditBussinessInventoryDetails,
  isModuleReadWriteAccessForSettings,
}) => {
  const [toggleButton, setToggleButton] = useState(false);

  return (
    <form
      className="border rounded-10 p-4"
      onSubmit={(e) => {
        handleEditBussinessInventoryDetails(e);
      }}
    >
      <div className="row gx-5">
        <div className="col-12">
          <div className="row gx-5 justify-content-center">
            <div className="col-6">
              <div className="form-group">
                <label className="form-label">
                  Additional charges/Discounts
                </label>
                <p className="fs-7 mb-3">
                  You can add different types of charges you want to add or
                  subtract from the items. For example : shipping charges,
                  packing charges, disocunts etc. This can be added or removed
                  from the bill after check out.
                </p>
              </div>
            </div>
            <div className="col-6">
              {isModuleReadWriteAccessForSettings && (
                <div className="d-flex justify-content-end gap-3">
                  {(orderCharges.length === 0 || toggleButton) && (
                    <span
                      className="btn btn-outline-light"
                      data-bs-toggle="modal"
                      data-bs-target="#add_new_charge"
                    >
                      + Create Charges
                    </span>
                  )}

                  {orderCharges?.length > 0 && (
                    <span
                      class="btn btn-icon btn-rounded btn-outline-light"
                      onClick={() => {
                        setToggleButton(!toggleButton);
                      }}
                    >
                      <span className="feather-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-pencil"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                        </svg>
                      </span>
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {!loader && orderCharges?.length > 0 && (
          <>
            {orderCharges?.map((charge, index) => {
              return (
                <div className="col-8">
                  <div className="d-flex align-items-center gap-3">
                    <div className="form-group">
                      <label className="form-label">{index + 1}.</label>
                    </div>
                    <div className="form-group d-flex align-items-center gap-1 w-100">
                      <label className="form-label w-45">
                        {charge?.isVisible ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-eye-slash-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7 7 0 0 0 2.79-.588M5.21 3.088A7 7 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12z" />
                          </svg>
                        )}
                        &nbsp;&nbsp;
                        {charge?.chargeName}
                      </label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text rounded-top-end-0 rounded-bottom-end-0">
                            {charge?.chargeType === "amount" ? "₹" : "%"}
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          disabled={charge?.value || charge?.value === 0}
                          value={charge?.value}
                        />
                      </div>
                    </div>
                    {isModuleReadWriteAccessForSettings && (
                      <div className="form-group">
                        {toggleButton && (
                          <span
                            className="btn btn-icon btn-rounded btn-light btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#add_new_charge"
                            onClick={() => {
                              seteditCharges(charge);
                            }}
                          >
                            <span className="feather-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-pencil"
                                viewBox="0 0 16 16"
                              >
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                              </svg>
                            </span>
                          </span>
                        )}
                      </div>
                    )}
                    {/* <div className="form-group">
                  <span
                    className="btn btn-icon btn-rounded btn-light btn-sm"
                    onClick={() => {}}
                  >
                    <span className="feather-icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                      </svg>
                    </span>
                  </span>
                </div> */}

                    {/* <div className="form-group">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" />
                    <label class="form-check-label">Visible</label>
                  </div>
                </div> */}
                  </div>
                </div>
              );
            })}
          </>
        )}
        {!loader && orderCharges?.length === 0 && (
          <NoData title="No charge/discount added" />
        )}
        {isModuleReadWriteAccessForSettings && (
          <div className="col-12">
            <div className="d-flex justify-content-end">
              {toggleButton && (
                <>
                  {orderCharges?.length !== 0 && (
                    <button type="submit" className="btn btn-primary">
                      {loader ? <ApiLoader /> : "Save"}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default OrderCharges;
