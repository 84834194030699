import React from "react";

const ItemsTableBills = ({ billItems, setEditItem, locationData }) => {
  return (
    <>
      <table className="table table-bordered text-center">
        {billItems?.length > 0 ? (
          <>
            <thead>
              <tr>
                <th>Item name</th>
                <th>Item code</th>
                <th>Unit</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Total</th>
                {locationData?.id && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {billItems?.map((item) => {
                return (
                  <tr>
                    <td>{item?.itemId?.itemName}</td>
                    <td>{item?.itemId?.itemCode}</td>
                    <td>{item?.itemId?.unit}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.rate}</td>
                    <td>{item?.total?.toFixed(2)}</td>
                    {locationData?.id && (
                      <td>
                        <div
                          className="d-flex"
                          data-bs-toggle="modal"
                          data-bs-target="#add_bill_item"
                          onClick={() => setEditItem(item)}
                        >
                          <span className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover del-button text-primary">
                            <span className="btn-icon-wrap">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="16"
                                fill="currentColor"
                                class="bi bi-pencil-square"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                />
                              </svg>
                            </span>
                          </span>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </>
        ) : (
          <div className="no-data-wrapper p-7">
            <h3 className="fw-semibold ">
              No item Added{" "}
              <span className="fw-normal fs-5">(Please Add item)</span>
              {/* {asteriskReuired} */}
            </h3>
          </div>
        )}
      </table>
    </>
  );
};

export default ItemsTableBills;
