import React, { useEffect, useState } from "react";
import InvoicesHeader from "./InvoicesHeader";
import InvoicesTable from "./InvoicesTable";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { InvoicesRelatedEndPoints } from "../../../api/Endpoints";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import NoData from "../../../components/NoData/NoData";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import InvoiceDateFilter from "./InoviceDateFilter";
import { CustomChip } from "../../../components/CustomChip";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants"

function Invoices() {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [search, setsearch] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [allInvoicesData, setallInvoicesData] = useState([]);
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState("");
  const [paymentStatus, setpaymentStatus] = useState("");
  const [isFilterReset, setisFilterReset] = useState(false);
  const [filterType, setfilterType] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [dateModal, setdateModal] = useState(false);

  const isModuleReadWriteAccessInvoice = allModuleAccess?.[PRIVATEROUTES.INVOICES]

  const resetData = (type) => {
    handleResetFilters(type, true);
  };

  const handleResetFilters = (type, isReset) => {
    if (type === "Date Range") {
      setstartDate("");
      setendDate("");
    } else if (type === "Due Date") {
      setdueDate("");
    }
    setdateModal(false);
    setisFilterReset(isReset);
  };

  const handleFilterType = (type) => {
    setfilterType(type);
  };
  const handleChangeType = (type) => {
    setpaymentStatus(type);
  };

  // ** PAGINATION **
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  // GET ALL INVOICE FUNCTION API CALL
  const handleGetAllInovices = async () => {
    startApiCall(seterrorMessage, setloader);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        status: paymentStatus,
        startDate: startDate,
        endDate: endDate,
        dueDate: dueDate,
      },
      page: page,
      limit: pageLimit,
    };
    const res = await ApiCall(
      "post",
      InvoicesRelatedEndPoints.getInvoice,
      data
    );
    const res2 = await ApiCall(
      "post",
      InvoicesRelatedEndPoints.getTotalAmount,
      {
        businessId: getCurrentWorkspaceId(),
      }
    );
    if (res?.success) {
      setInvoiceTotalAmount(res2?.invoice);
      setallInvoicesData(res?.item?.data);
      setcount(res?.item?.totalCounts);
      settotalPages(res?.item?.totalPages);
      setdateModal(false);
      setisFilterReset(false);
      setloader(false);
    } else {
      setloader(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllInovices();
    }
  }, [search, page, pageLimit, paymentStatus]);

  useEffect(() => {
    if (isFilterReset) {
      handleGetAllInovices();
    }
  }, [isFilterReset]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <InvoicesHeader
            search={search}
            setsearch={setsearch}
            handleGetAllInovices={handleGetAllInovices}
            setPage={setPage}
            paymentStatus={paymentStatus}
            handleChangeType={handleChangeType}
            filterType={filterType}
            handleFilterType={handleFilterType}
            setdateModal={setdateModal}
            setdueDate={setdueDate}
            setstartDate={setstartDate}
            setendDate={setendDate}
            isModuleReadWriteAccessInvoice={isModuleReadWriteAccessInvoice}
          />
          <div className="d-flex align-items-center justify-content-end pt-1 pe-3">
            {" "}
            {(startDate !== "" || endDate !== "") && (
              <CustomChip type={"Date Range"} resetData={resetData} />
            )}
            {dueDate !== "" && (
              <CustomChip type={"Due Date"} resetData={resetData} />
            )}
          </div>

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              <div className="d-flex gap-2">
                <div className="d-flex gap-3">
                  <div className="card py-2 px-4 text-center rounded-4">
                    <p className="text-dark fs-6">Total Amount</p>
                    <p className="text-teal fw-semibold">
                      ₹ {invoiceTotalAmount?.totalAmount?.toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="card py-2 px-4 text-center rounded-4">
                    <p className="text-dark fs-6">Total Receive </p>
                    <p className="text-teal fw-semibold">
                      ₹ {invoiceTotalAmount?.totalRecievedAmount?.toFixed(2)}
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3">
                  <div className="card py-2 px-4 text-center rounded-4">
                    <p className="text-dark fs-6">Total Due</p>
                    <p className="text-teal fw-semibold">
                      ₹ {invoiceTotalAmount?.totalDueAmount?.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
              {!loader && allInvoicesData?.length > 0 && (
                <InvoicesTable
                  allInvoicesData={allInvoicesData}
                  page={page}
                  pageLimit={pageLimit}
                  handleGetAllInovices={handleGetAllInovices}
                  isModuleReadWriteAccessInvoice={isModuleReadWriteAccessInvoice}
                />
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allInvoicesData?.length > 0 && (
                <FooterPagination
                  setpageLimit={setpageLimit}
                  pageLimit={pageLimit}
                  setPage={setPage}
                  page={page}
                  totalPages={totalPages}
                  count={count}
                  dataLength={allInvoicesData?.length}
                  align={"end"}
                />
              )}
              {!loader && allInvoicesData?.length === 0 && (
                <NoData title="No Inovices Added" />
              )}
            </div>
          </div>
        </div>
        <InvoiceDateFilter
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader}
          setdueDate={setdueDate}
          dueDate={dueDate}
          handleResetFilters={handleResetFilters}
          dateModal={dateModal}
          handleGetAllInovices={handleGetAllInovices}
          filterType={filterType}
        />
      </div>
    </div>
  );
}

export default Invoices;
