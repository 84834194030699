import React from "react";

function ImageViewModal({ imageData, setimageData, setimageModal }) {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-md"
        style={{ maxWidth: "360px" }}
        role="document"
      >
        <div className="modal-content overflow-auto">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setimageData("");
                setimageModal(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>Image</h5>
            <div className="border-bottom mb-2"></div>
            <form>
              <div className="row">
                <div className="col-md-12 px-2">
                  <div className="d-flex flex-column align-items-center gap-1">
                    {imageData ? (
                      <img
                        className="p-2 w-100 h-420p"
                        style={{
                          backgroundRepeat: "no-repeat",
                          objectFit: "contain",
                          backgroundPosition: "center",
                        }}
                        src={imageData}
                        alt="Image1"
                      />
                    ) : (
                      <span className="w-100 h-420p p-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="bi bi-person-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageViewModal;
