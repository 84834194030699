import React from "react";
import { navigateToNotice } from "../../../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";

const NoticeDetailsHeader = ({ locationData, handleGetNoticeDetails }) => {
  const navigate = useNavigate();

  return (
      <header className="todo-header">
        <div className="d-flex align-items-center gap-2">
          <span
            className="pointer"
            onClick={() => {
              if (locationData) {
                navigate(navigateToNotice(), {
                  state: {
                    moduleType: locationData?.moduleType,
                    moduleId: locationData?.moduleId,
                    moduleName: locationData?.moduleName,
                  },
                });
              } else {
                navigate(navigateToNotice());
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
          </span>
          <a className="todoapp-title link-dark">
            <h1>Notice Details</h1>
          </a>
          {locationData && (
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-geo-alt ms-2"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              <span className="text-primary ms-1 pointer text-capitalize">
                {locationData?.moduleName}
              </span>
            </span>
          )}
        </div>
        <div className="todo-options-wrap">
          <span
            className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Refresh Notice Details"
            data-bs-original-title="Refresh"
          >
            <span className="btn-icon-wrap" onClick={handleGetNoticeDetails}>
              <span className="feather-icon">
                <i
                  className="ri-refresh-line"
                  title="Refresh business list"
                ></i>
              </span>
            </span>
          </span>
        </div>
      </header>
  );
};

export default NoticeDetailsHeader;
