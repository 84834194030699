import React, { useEffect, useState } from "react";
import meetingImg from "../../../assets/images/meetingImg.png";
import {
  getCurrentWorkspaceId,
  getTimeFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { MeetingRelatedEndPoints } from "../../../api/Endpoints";
import { useSelector } from "react-redux";
import { Avatar } from "../../../components/Avatar/Avatar";
import NoData from "../../../components/NoData/NoData";
import SkeletonConstant from "../../../components/Constant/SkeletonConstant";

const MeetingCard = () => {
  const arr = [1, 2, 3, 4, 5, 6];
  const currentDate = new Date();
  const user = useSelector((state) => state.user);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [allMeeting, setallMeeting] = useState(null);

  const handleGetMeeting = async () => {
    startApiCall(seterrorMessage, setLoader);
    const data = {
      page: page,
      limit: 5000,
      filters: {
        businessId: getCurrentWorkspaceId(),
        status: "",
        isDeleted: false,
        moduleId: "",
        moduleType: "",
        startDate: currentDate,
        endDate: currentDate,
        participants: user?._id,
      },
      searchKey: "",
    };
    const res = await ApiCall("post", MeetingRelatedEndPoints.getMeeting, data);
    if (res?.success) {
      setallMeeting(res?.meetings?.data);
      settotalPages(res?.meetings?.totalPages);
      setLoader(false);
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };
  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetMeeting();
    }
  }, []);

  const handleStatusWiseColor = (status) => {
    let card = {};
    if (status === "upcoming") {
      card = {
        color: "#3862DD",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#3862DD"
            className="bi bi-alarm mb-1"
            viewBox="0 0 16 16"
          >
            <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z" />
            <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z" />
          </svg>
        ),
      };
    } else if (status === "completed") {
      card = {
        color: "rgb(36 202 38)",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-check-circle mb-1"
            viewBox="0 0 16 16"
            color="rgb(36 202 38)"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        ),
      };
    } else if (status === "cancelled") {
      card = {
        color: "rgb(239 13 39)",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-circle mb-1"
            viewBox="0 0 16 16"
            color="rgb(239 13 39)"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        ),
      };
    } else if (status === "inprogress") {
      card = {
        color: "#FFC700",
        svg: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            className="mb-1"
          >
            <path
              d="M2.75877 16.553C2.45402 16.553 2.20697 16.3059 2.20697 16.0012C2.20697 15.6964 2.45402 15.4494 2.75877 15.4494H3.86237V14.3458C3.86237 12.3702 5.01594 10.6652 6.68378 9.86545C7.00371 9.71204 7.17317 9.44925 7.17317 9.21491V8.44058C7.17317 8.20625 7.00371 7.94345 6.68378 7.79004C5.01594 6.99034 3.86237 5.28529 3.86237 3.30974V2.20614H2.75877C2.45402 2.20614 2.20697 1.95909 2.20697 1.65434C2.20697 1.34959 2.45402 1.10254 2.75877 1.10254L14.8984 1.10256C15.2031 1.10257 15.4502 1.34962 15.4502 1.65437C15.4502 1.95912 15.2031 2.20617 14.8984 2.20616L13.7948 2.20615V3.30974C13.7948 5.28529 12.6412 6.99034 10.9734 7.79004C10.6534 7.94345 10.484 8.20625 10.484 8.44058V9.21491C10.484 9.44925 10.6534 9.71204 10.9734 9.86545C12.6412 10.6652 13.7948 12.3702 13.7948 14.3458V15.4494H14.8984C15.2031 15.4494 15.4502 15.6964 15.4502 16.0012C15.4502 16.3059 15.2031 16.553 14.8984 16.553H2.75877ZM4.96597 2.20616V3.30976C4.96597 3.90204 5.09927 4.46334 5.33764 4.96518H12.3195C12.5579 4.46334 12.6912 3.90204 12.6912 3.30976V2.20616H4.96597ZM8.27677 9.21493C8.27677 9.9877 7.7491 10.5786 7.16092 10.8606C5.86141 11.4837 4.96597 12.8107 4.96597 14.3458C4.96597 14.3458 5.9214 12.9126 8.27677 12.7133V9.21493ZM9.38036 9.21493V12.7133C11.7357 12.9126 12.6912 14.3458 12.6912 14.3458C12.6912 12.8107 11.7957 11.4837 10.4962 10.8606C9.90803 10.5786 9.38036 9.9877 9.38036 9.21493Z"
              fill="#FFC700"
            />
          </svg>
        ),
      };
    }
    return card;
  };

  return (
    <>
      <div className="card-header card-header-action">
        {!loader && (
          <h5>
            Today’s meetings&nbsp;
            {allMeeting?.length > 0 && (
              <span className="badge badge-md badge-pill badge-soft-primary ms-2">
                {allMeeting?.length}
              </span>
            )}
          </h5>
        )}
        {loader && <SkeletonConstant width="w-200p" height="h-35p" />}
      </div>
      <div className="card-body">
        <div
          className="d-flex gap-3 w-100 simple-scrollbar"
          style={{ overflowX: "scroll" }}
        >
          {!loader &&
            allMeeting?.length > 0 &&
            allMeeting?.map((meeting) => {
              return (
                <div
                  className="card card-border mnw-375p"
                  style={{
                    border: `0.981px solid ${
                      handleStatusWiseColor(meeting?.status).color
                    }`,
                  }}
                >
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <img className="w-240p" src={meetingImg} alt="" />
                    </div>
                    <div className="d-flex flex-column text-dark fs-7 gap-1">
                      <div className="d-flex justify-content-between gap-5">
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="#C45AD0"
                            className="bi bi-circle-fill me-1"
                            viewBox="0 0 16 16"
                          >
                            <circle cx="8" cy="8" r="8" />
                          </svg>
                          &nbsp;Hosted by{" "}
                          <Avatar
                            name={meeting?.createdBy?.name}
                            count={1}
                            image={meeting?.createdBy?.image}
                            size="xxs"
                            color="primary"
                          />{" "}
                          {meeting?.createdBy?.name}
                        </p>
                        <p> {getTimeFormatter(meeting?.scheduleTime)}</p>
                      </div>
                      <div className="d-flex justify-content-between mt-1">
                        <p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              d="M4.88934 2.29109V0.504883H0.423828V4.9704H2.21003V10.329H0.423828V14.7945H4.88934V13.0083H10.248V14.7945H14.7135V10.329H12.9273V4.9704H14.7135V0.504883H10.248V2.29109H4.88934ZM10.248 3.18419V4.9704H12.0342V10.329H10.248V12.1152H4.88934V10.329H3.10314V4.9704H4.88934V3.18419H10.248ZM11.1411 1.39799H13.8204V4.07729H11.1411V1.39799ZM13.8204 11.2221V13.9014H11.1411V11.2221H13.8204ZM3.99624 13.9014H1.31693V11.2221H3.99624V13.9014ZM1.31693 4.07729V1.39799H3.99624V4.07729H1.31693Z"
                              fill="black"
                            />
                          </svg>
                          &nbsp;
                          <span className="fw-bold text-capitalize ms-1">
                            {meeting?.meetingTitle}
                          </span>
                        </p>
                        <p className="me-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 16 15"
                            fill="none"
                          >
                            <path
                              d="M7.93979 3.19045C7.93979 2.93904 7.73598 2.73523 7.48456 2.73523C7.23315 2.73523 7.02934 2.93904 7.02934 3.19045V8.19788C7.02934 8.36124 7.11688 8.51208 7.25871 8.59313L10.4453 10.414C10.6635 10.5387 10.9416 10.4629 11.0664 10.2446C11.1911 10.0263 11.1153 9.74826 10.897 9.62353L7.93979 7.93371V3.19045Z"
                              fill="black"
                            />
                            <path
                              d="M7.93979 14.571C11.9624 14.571 15.2233 11.31 15.2233 7.28744C15.2233 3.26486 11.9624 0.00390625 7.93979 0.00390625C3.9172 0.00390625 0.65625 3.26486 0.65625 7.28744C0.65625 11.31 3.9172 14.571 7.93979 14.571ZM14.3129 7.28744C14.3129 10.8072 11.4595 13.6605 7.93979 13.6605C4.42002 13.6605 1.56669 10.8072 1.56669 7.28744C1.56669 3.76768 4.42002 0.914348 7.93979 0.914348C11.4595 0.914348 14.3129 3.76768 14.3129 7.28744Z"
                              fill="black"
                            />
                          </svg>
                        </p>
                      </div>
                      <div className="d-flex justify-content-between mt-1">
                        <p className="d-flex gap-1">
                          {(meeting?.addressType === "onsite" ||
                            meeting?.addressType === "both") && (
                            <span className="mt-2 pointer me-1 d-flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-house me-2"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                              </svg>
                              <h6 className="mw-150p text-truncate">
                                {meeting?.onsiteAddress}
                              </h6>
                            </span>
                          )}
                          {meeting?.addressType === "both" && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="2"
                              height="30"
                              viewBox="0 0 2 30"
                              fill="none"
                            >
                              <path
                                d="M0.822266 0.791016V29.7292"
                                stroke="#979797"
                                stroke-width="0.980955"
                              />
                            </svg>
                          )}
                          {(meeting?.addressType === "offsite" ||
                            meeting?.addressType === "both") && (
                            <a
                              href={meeting?.offsiteAddress}
                              className="mt-2 text-decoration-underline pointer d-flex"
                              style={{ color: "#3862DD" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-link-45deg me-2 mt-1"
                                viewBox="0 0 16 16"
                              >
                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                              </svg>
                              <h6 className="mw-150p text-truncate text-primary">
                                {meeting?.offsiteAddress}
                              </h6>
                            </a>
                          )}
                        </p>
                        <p className="mt-2">
                          {getTimeFormatter(meeting?.endMeetingTime)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {loader && (
          <div className="loading-skeleton w-100 px-3 pb-3">
            <SkeletonConstant width="w-375p" height="h-150p" />
          </div>
        )}
        {!loader && allMeeting?.length === 0 && (
          <NoData
            title={`No Meetings`}
            description={"There are no meetings for today"}
          />
        )}
      </div>
    </>
  );
};

export default MeetingCard;
