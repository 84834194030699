import React from "react";

const PaymentSuccess = ({ setSuccessPayment, addPaymentRes }) => {
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-3">
          <div className="modal-body">
            <div className="d-flex flex-column align-items-center gap-4">
              <span className="icon p-5 circle shadow m-auto bg-success text-white fw-bold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  fill="currentColor"
                  class="bi bi-check2"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                </svg>
              </span>
              <div className="d-flex flex-column align-items-center gap-1 text-dark">
                <p className="font-7 fw-bold">₹ {addPaymentRes?.amountReceived}</p>
                <p className="font-5 fw-semibold">Payment Successful!</p>
                <p>The payment has been done successfully.</p>
                <p>Thanks for being there with us.</p>
              </div>
              <button
                className="btn btn-success px-5"
                type="button"
                onClick={() => setSuccessPayment(false)}
              >
                <span aria-hidden="true">Done</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
