import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import OrderHistory from "../../pages/InventoryManagement/OrderHistory/OrderHistory";
import AllInvoices from "../../pages/InventoryManagement/OrderHistory/AllInvoice/AllInvoices";

const OrderHistoryRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "orderhistory",
      element: <OrderHistory />,
    },
    {
      path: "allinvoices",
      element: <AllInvoices />,
    },
  ],
};

export default OrderHistoryRoutes;
