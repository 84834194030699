import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import { noticeRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import {
  dateAndTimeFormatter,
  getCurrentWorkspaceId,
  handleDeleteAwsImage,
  handleUploadInDirectAws,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { toast } from "react-toastify";

const EditNotice = ({
  editNoticeData,
  handleGetNoticeDetails,
  setEditNoticeData,
  locationData,
}) => {
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setimage] = useState("");

  const handlePreviewFileInLocal = (event) => {
    if (event?.target?.files && event.target.files.length > 0) {
      const img = URL.createObjectURL(event.target.files[0]);
      return img;
    } else if (editNoticeData?.images?.[0]) {
      // Return the URL of the existing image if no new file is selected
      return editNoticeData?.images?.[0];
    } else {
      return null; // or handle the case where there are no files
    }
  };

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setTitle("");
    setDescription("");
    setimage("");
    setEditNoticeData([]);
    const closeModalDom = document.getElementById("edit_notice");
    if (closeModalDom) closeModalDom.click();
  };

  const setFileForUpload = (event) => {
    setimage(event);
  };

  useEffect(() => {
    setTitle(editNoticeData?.title);
    setDescription(editNoticeData?.description);
    setimage(editNoticeData?.images?.[0] ? editNoticeData?.images?.[0] : "");
  }, [editNoticeData]);

  const handleEditNotice = async (e) => {
    e.preventDefault();
    let isError = false;
    startApiCall(setErrorMessage, setloader);
    let temp = [];
    if (image === "") {
      temp.push();
    } else if (editNoticeData?.images?.[0]) {
      temp.push(image);
    } else if (image) {
      try {
        const uploaded = await handleUploadInDirectAws(image);
        if (uploaded.Location) {
          temp.push(uploaded.Location);
        }
      } catch (error) {
        isError = true;
        toast.error("Image uploading failed , try after sometimes.");
        setloader(false);
      }
    }
    if (!isError) {
      const sendData = {
        noticeId: editNoticeData?._id,
        title: title,
        description: description,
        noticeType: editNoticeData?.noticeType,
        moduleId: editNoticeData?.noticeType === "Business" ? "" : editNoticeData?.moduleId?._id,
        businessId: getCurrentWorkspaceId(),
        images: temp,
      };
      const res = await ApiCall(
        "patch",
        noticeRelatedEndPoints.editNotice,
        sendData
      );
      if (res?.success) {
        handleReset();
        toast.success("Edit notice successfully!");
        handleGetNoticeDetails();
      } else {
        setloader(false);
        setErrorMessage(res.error);
      }
    }
  };

  return (
    <>
      <div
        id="edit_notice"
        className="modal fade add-new-contact"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered my-4"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleReset}
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5>Edit Notice</h5>
              <div className="border-bottom mb-2"></div>
              <form onSubmit={handleEditNotice}>
                <div className="d-flex align-items-center justify-content-center gap-3">
                  <span className="text-dark">
                    Published on : {dateAndTimeFormatter(editNoticeData?.createdAt)}
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-center dropify-square mt-3">
                  {image ? (
                    <>
                      <input
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        className="input-file"
                        disabled={image}
                        onChange={setFileForUpload}
                      />
                      <label
                        htmlFor="file"
                        className="position-relative pointer"
                      >
                        <img
                          htmlFor="file"
                          id="local_show_img"
                          src={handlePreviewFileInLocal(image)}
                          style={{ padding: 5 }}
                          className="dropify-wrapper text-center"
                          alt="img"
                        />
                        <span
                          className="position-top-end-overflow"
                          onClick={() => {
                            setimage("");
                            if(editNoticeData?.images?.[0]){
                              handleDeleteAwsImage(image)
                            }
                            setEditNoticeData((prevData) => ({
                              ...prevData,
                              images: [null],
                            }));
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </span>
                      </label>
                    </>
                  ) : (
                    <span className="dropify-wrapper text-center">
                      <input
                        type="file"
                        name="image"
                        id="file"
                        accept="image/png,image/jpeg"
                        className="input-file"
                        onChange={setFileForUpload}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-upload"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                      </svg>
                      <label htmlFor="file" className="pointer text-center">
                        Upload Image
                      </label>
                    </span>
                  )}
                </div>
                <div className="row gx-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Title</label>
                      <input
                        className="form-control"
                        type="text"
                        required
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        value={title}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gx-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        rows="3"
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        value={description}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                <div className="modal-footer align-items-center">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={handleReset}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    {loader ? <ApiLoader /> : "Edit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNotice;
