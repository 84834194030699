import React, { useEffect, useState } from "react";
import ErrorMessage from "../../../../components/Error/ErrorMessage";
import ApiLoader from "../../../../components/Loaders/ApiLoader/ApiLoader";
import {
  convertTotalHoursToHoursAndMinutes,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import { attendanceRelatedEndPoints } from "../../../../api/Endpoints";
import ApiCall from "../../../../api/ApiCall";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Avatar } from "../../../../components/Avatar/Avatar";

const MarkAbsentModal = (props) => {
  const {
    width,
    individualStaffData,
    handleStaffAttendancePerDay,
    handleGetAttendanceCount,
    setabsentModal,
    dailyDateFilter,
  } = props;
  const { user } = useSelector((state) => state);
  const [loader2, setloader2] = useState(false);
  const [workingHours, setworkingHours] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [attendanceStatus, setattendanceStatus] = useState("absent");

  useEffect(() => {
    if (individualStaffData?.attendances) {
      if (individualStaffData?.attendances?.isLeave) {
        setattendanceStatus("leave");
      }
    }
  }, [individualStaffData]);

  const handleCloseAttendanceVerify = () => {
    setabsentModal(false);
    seterrorMessage("");
    setloader2(false);
    setworkingHours("");
  };

  const handleMarkAbsent = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      employeeId: individualStaffData?.staff?.employeeId?._id,
      date: dailyDateFilter,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.markAbsent,
      data
    );
    if (res?.success) {
      const attendanceId = res?.attendance?._id;
      handleVerifyAttendance(attendanceId,e);
    } else {
      seterrorMessage(res.error);
      setloader2(false);
    }
  };

  const handleMarkAsPresent = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      employeeId: individualStaffData?.staff?.employeeId?._id,
      date: dailyDateFilter,
      totalHours: workingHours,
      presentStatus: attendanceStatus,
      businessId: getCurrentWorkspaceId(),
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.markAsPresent,
      data
    );
    if (res?.success) {
      const attendanceId = res?.attendance?._id;
      handleVerifyAttendance(attendanceId,e);
    } else {
      seterrorMessage(res.error);
      setloader2(false);
    }
  };

  const handleVerifyAttendance = async (attendanceId,e) => {
    e.preventDefault();
    startApiCall(null, setloader2);
    const data = {
      attendanceId: individualStaffData?.attendances
        ? individualStaffData?.attendances?._id
        : attendanceId,
      isVerified: true,
      verifiedWorkingHours: workingHours ? workingHours : 0,
      verifiedBy: user?._id,
      employeeId: individualStaffData?.staff?.employeeId?._id,
      businessId: getCurrentWorkspaceId(),
      verifiedCompletedJobs: "",
      totalHours: "0",
    };
    const res = await ApiCall(
      "post",
      attendanceRelatedEndPoints.verifyAttendance,
      data
    );
    if (res?.success) {
      toast.success("Attendance Verified Successfully");
      handleGetAttendanceCount();
      handleStaffAttendancePerDay();
      handleCloseAttendanceVerify();
      setloader2(false);
    } else {
      seterrorMessage(res.error);
      setloader2(false);
    }
  };

  const handleAttendanceChange = (event) => {
    seterrorMessage("");
    const selectedAttendance = event.target.value;
    setattendanceStatus(selectedAttendance);
  };
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        style={{ maxWidth: width }}
      >
        <form
          onSubmit={(e) => {
            if (attendanceStatus === "absent") {
              handleMarkAbsent(e);
            } else if (attendanceStatus === "leave") {
              handleVerifyAttendance(individualStaffData?.attendances?._id,e);
            } else {
              handleMarkAsPresent(e);
            }
          }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-content">
                <div className="modal-body">
                  <h4 className="mb-4 text_center">
                    Verify attendance for{" "}
                    <Avatar
                      name={individualStaffData?.staff?.employeeId?.name}
                      count={1}
                      image={individualStaffData?.staff?.employeeId?.image}
                      size="xxs"
                      color="primary"
                    />{" "}
                    <span className="text-muted">
                      {individualStaffData?.staff?.employeeId?.name}
                    </span>
                  </h4>
                  <div className="d-flex justify-content-center mt-2">
                    <div
                      className="alert alert-info text-primary fs-7"
                      role="alert"
                    >
                      <strong>Note:</strong> The current working hours are
                      displayed in the input field. You can modify this value if
                      needed.
                    </div>
                  </div>

                  <div className="row gx-3">
                    <label className="form-label text_center">
                      {individualStaffData?.attendances?.isLeave
                        ? "Are You Sure You Want To Verfiy This Staff's Leave ?"
                        : "Are You Sure You Want To Verfiy This Staff's Attendance ?"}
                    </label>
                  </div>
                  <div className="row gx-3">
                    <span className="text-danger text_center">
                      {individualStaffData?.attendances?.isLeave
                        ? "The Employee is on leave"
                        : "No session activity found"}{" "}
                      ({dailyDateFilter})
                    </span>
                  </div>

                  <div className="row gx-3 mt-5">
                    <div className="col-sm-12">
                      <div className="form-inline">
                        <div className="form-group  d-flex justify-content-center">
                          <label className="form-label">Set Attendance:</label>
                          <div className="form-check form-check-inline ms-2">
                            <div className="custom-control custom-radio radio-primary">
                              <input
                                type="radio"
                                className="form-check-input pointer"
                                value="present"
                                onChange={handleAttendanceChange}
                                checked={attendanceStatus === "present"}
                                required
                              />
                              <label className="form-check-label">
                                Present
                              </label>
                            </div>
                          </div>
                          <div className="form-check form-check-inline">
                            {individualStaffData?.attendances?.isLeave ? (
                              <div className="custom-control custom-radio radio-primary">
                                <input
                                  type="radio"
                                  className="form-check-input pointer"
                                  value="leave"
                                  onChange={handleAttendanceChange}
                                  checked={attendanceStatus === "leave"}
                                  required
                                />
                                <label className="form-check-label">
                                  On Leave
                                </label>
                              </div>
                            ) : (
                              <div className="custom-control custom-radio radio-primary">
                                <input
                                  type="radio"
                                  className="form-check-input pointer"
                                  value="absent"
                                  onChange={handleAttendanceChange}
                                  checked={attendanceStatus === "absent"}
                                  required
                                />
                                <label className="form-check-label">
                                  {individualStaffData?.attendances?.isLeave
                                    ? "On Leave"
                                    : "Absent"}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="form-check form-check-inline">
                            <div className="custom-control custom-radio radio-primary">
                              <input
                                type="radio"
                                className="form-check-input pointer"
                                value="half_day"
                                onChange={handleAttendanceChange}
                                checked={attendanceStatus === "half_day"}
                                required
                              />
                              <label className="form-check-label">
                                Half day
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {attendanceStatus !== "absent" &&
                    attendanceStatus !== "leave" && (
                      <div className="col-sm-12 d-flex justify-content-center gap-3">
                        <div className="form-group d-flex justify-content-center ">
                          <label className="form-label pt-2 me-2 fs-7">
                            Working Hours :
                          </label>
                          <div className="d-flex align-items-center border rounded-5 text-dark">
                            <span className="d-flex align-items-center justify-content-center py-1 px-2 fs-7">
                              Hours
                            </span>

                            <div className="d-flex align-items-center justify-content-between w-120p py-1 border-start px-1">
                              <input
                                className="form-control border-0"
                                type="text"
                                required={attendanceStatus !== "absent"}
                                placeholder="Hours"
                                value={workingHours}
                                onChange={(e) => {
                                  setworkingHours(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center border rounded-5 text-dark mw-225p p-2 text-disabled h-50p">
                          {convertTotalHoursToHoursAndMinutes(workingHours)}
                        </div>
                      </div>
                    )}
                  {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                  <div
                    className="px-4 pb-4"
                    style={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      type="button"
                      disabled={loader2}
                      className="btn btn-rounded btn-secondary col-sm-5"
                      onClick={handleCloseAttendanceVerify}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={loader2}
                      type="submit"
                      className="btn btn-rounded btn-primary col-sm-5"
                    >
                      {loader2 ? <ApiLoader /> : "Verify"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MarkAbsentModal;
