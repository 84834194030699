import React from "react";

const SalaryHeader = ({
  selectedMonth,
  handleMonthChange,
  monthNames,
  handleGetAllStaffSalary,
  setSearch,
  search,
  thisMonth,
  setPage,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1>Staff Salary Details</h1>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="">
          <select
            className="form-select form-select"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            {monthNames?.map((mon, index) => {
              return (
                <option key={index} disabled={index > thisMonth}>
                  {mon}
                </option>
              );
            })}
          </select>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllStaffSalary}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none me-2" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search staff"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
          />
        </form>
      </div>
    </header>
  );
};

export default SalaryHeader;
