import React, { useEffect, useState } from "react";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../../global-functions/globalFunctions";
import ApiCall from "../../../../api/ApiCall";
import {
  CustomerRelatedEndPoints,
  InvoicesRelatedEndPoints,
} from "../../../../api/Endpoints";
import AddCreditItemTable from "./AddCreditItemTable";
import AddCreditAddressDetails from "./AddCreditAddressDetails";
import ErrorMessage from "../../../../components/Error/ErrorMessage";

function AddCreditNoteBody(props) {
  const {
    selectedCustomer,
    setselectedCustomer,
    selectedInvoice,
    setselectedInvoice,
    creditNoteNumber,
    setcreditNoteNumber,
    invoiceItems,
    calculateTotalSum,
    setreason,
    reason,
    handleAddCreditNote,
    errorMessage,
    locationData,
    creditNotesDetails,
    adjustment,
    setadjustment,
    handleEditCreditNotes,
  } = props;
  const [loader2, setloader2] = useState(false);
  const [allCustomers, setallCustomers] = useState([]);
  const [customerData, setcustomerData] = useState([]);
  const [allInvoicesData, setallInvoicesData] = useState([]);

  const handleCustomerSelection = (selectedValue) => {
    const selectedCustomer = allCustomers.find(
      (customer) => customer._id === selectedValue
    );

    if (selectedCustomer) {
      setcustomerData(selectedCustomer);
    }
  };

  const handleGetAllCustomer = async () => {
    startApiCall(null, setloader2);
    const data = {
      page: 1,
      limit: 500,
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
    };
    const res = await ApiCall(
      "post",
      CustomerRelatedEndPoints.getCustomer,
      data
    );
    if (res?.success) {
      setallCustomers(res?.customer?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };
  const handleGetAllInovices = async () => {
    startApiCall(null, setloader2);
    const data = {
      filters: {
        businessId: getCurrentWorkspaceId(),
      },
      page: 1,
      limit: 5000,
    };
    const res = await ApiCall(
      "post",
      InvoicesRelatedEndPoints.getInvoice,
      data
    );

    if (res?.success) {
      setallInvoicesData(res?.item?.data);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllCustomer();
      handleGetAllInovices();
    }
  }, []);

  return (
    <div className="contact-list-view">
      <div className="row">
        <div className="col-md-9 p-5">
          <div className="d-flex flex-column gap-3">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row w-100 align-items-center">
                  <div className="col-sm-4">
                    <label>Customer Name</label>
                  </div>
                  <div className="col-sm-8">
                    <div className="form-group mb-0">
                      <div className="todo-toolbar">
                        <select
                          required
                          name="unittype"
                          className="form-select form-select-md"
                          disabled={locationData?.id}
                          value={selectedCustomer}
                          onChange={(e) => {
                            setselectedCustomer(e.target.value);

                            handleCustomerSelection(e.target.value);
                          }}
                        >
                          <option value="">Select Customer</option>
                          {allCustomers?.map((customer) => {
                            return (
                              <option value={customer?._id}>
                                {customer?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row w-100 align-items-center">
                  <div className="col-sm-4">
                    <label>Credit Note Number</label>
                  </div>
                  <div className="col-sm-8">
                    <input
                      className="form-control"
                      type="text"
                      disabled={locationData?.id}
                      value={creditNoteNumber}
                      onChange={(e) => {
                        setcreditNoteNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row w-100 align-items-center">
                  <div className="col-sm-4">
                    <label>Invoice number</label>
                  </div>
                  <div className="col-sm-8">
                    <select
                      required
                      name="unittype"
                      className="form-select form-select-md"
                      disabled={locationData?.id}
                      value={selectedInvoice}
                      onChange={(e) => {
                        setselectedInvoice(e.target.value);
                      }}
                    >
                      <option value="">Select Invoice</option>
                      {allInvoicesData?.map((invoice) => {
                        return (
                          <option value={invoice?._id}>
                            {invoice?.invoiceNumber}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <AddCreditItemTable invoiceItems={invoiceItems} />

            <div className="d-flex justify-content-end me-4">
              <button
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#add_creditnote_item"
              >
                + Add Item
              </button>
            </div>
            <div className="col-md-6 mt-2">
              <div className="form-group row w-100 align-items-center">
                <div className="col-sm-4">
                  <label>Adjustment</label>
                </div>
                <div className="col-sm-8">
                  <input
                    className="form-control"
                    type="number"
                    value={adjustment}
                    onChange={(e) => {
                      setadjustment(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3 mt-3">
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  placeholder="Add a reason"
                  rows="3"
                  value={reason}
                  onChange={(e) => {
                    setreason(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center justify-content-between border text-dark fw-semibold px-3 py-2 h-85p">
                    <p>Total Amount</p>
                    <p>₹ {calculateTotalSum()}</p>
                  </div>
                </div>
              </div>
            </div>

            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            <div className="d-flex modal-footer align-items-center justify-content-end ">
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (locationData?.type === "Edit") {
                    handleEditCreditNotes();
                  } else {
                    handleAddCreditNote();
                  }
                }}
                disabled={
                  !selectedCustomer ||
                  !selectedInvoice ||
                  !creditNoteNumber ||
                  !invoiceItems ||
                  !reason
                }
              >
                {locationData ? "Edit Credit Note" : "Create Credit Note"}
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-3 p-2">
          <AddCreditAddressDetails
            customerData={customerData}
            creditNotesDetails={creditNotesDetails}
            locationData={locationData}
          />
        </div>
      </div>
    </div>
  );
}

export default AddCreditNoteBody;
