import React, { useEffect, useState } from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import {
  getCurrentWorkspaceId,
  reuiredField,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import { useNavigate } from "react-router-dom";
import { navigateToOtherSettings } from "../../../Navigation/Navigation";
import {
  VendorRelatedEndPoints,
  businessEndpoints,
} from "../../../api/Endpoints";
import ApiCall from "../../../api/ApiCall";
import { useSelector } from "react-redux";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";

const VendorPayment = ({
  setVendorPaymentModal,
  vendorDetails,
  setSuccessPayment,
  vendorPaymentModal,
  id,
  setaddPaymentRes,
  handleGetVendorSubtransaction,
  handleGetVendortransaction,
  handleGetVendorAmounts,
}) => {
  const { show, isAdvance } = vendorPaymentModal || {};
  const { user } = useSelector((state) => state);
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  var currentDate = reversesDateFormatter(new Date());
  const [paymentMode, setPaymentMode] = useState("cash");
  const [amount, setAmount] = useState("");
  const [upiId, setUpiId] = useState("");
  const [loader2, setloader2] = useState(false);
  const [businessDetails, setBusinessDetails] = useState([]);
  const [selectedVendorAccount, setselectedVendorAccount] = useState("");
  const [selectedAccountDetails, setselectedAccountDetails] = useState("");
  const [senderAccount, setsenderAccount] = useState("");
  const [senderAccountDetails, setsenderAccountDetails] = useState("");


  const handleVendorAccountSelection = (account) => {
    const selectedItem = vendorDetails?.bankAccounts.find(
      (item) => item?._id === account
    );
    if (selectedItem) {
      setselectedAccountDetails(selectedItem);
    }
  };
  const handleSenderAccountSelection = (account) => {
    const selectedItem = businessDetails?.businessId?.bankAccounts.find(
      (item) => item?._id === account
    );
    if (selectedItem) {
      setsenderAccountDetails(selectedItem);
    }
  };

  const handleReset = () => {
    setErrorMessage("");
    setloader(false);
    setAmount("");
    setPaymentMode("cash");
    setselectedVendorAccount("");
    setselectedAccountDetails("");
    setVendorPaymentModal(false);
  };

  const handleGetBusinessDetails = async () => {
    startApiCall(null, setloader2);
    const data = {
      businessId: getCurrentWorkspaceId(),
      employeeId: user?._id,
    };
    const res = await ApiCall("post", businessEndpoints.getBusinessById, data);
    if (res?.success) {
      setBusinessDetails(res?.business);
      setloader2(false);
    } else {
      setloader2(false);
    }
  };

  const handleAddAdvancePayment = async (e) => {
    e.preventDefault();
    startApiCall(null, setloader);
    const data = {
      date: currentDate,
      paymentMode,
      creditRefKey: "Vendor",
      debitRefKey: "Business",
      creditTo: id,
      debitFrom: getCurrentWorkspaceId(),
      businessId: getCurrentWorkspaceId(),
      amountReceived: amount,
      senderBankDetails: {
        ifscCode:
          paymentMode === "bank-transfer"
            ? selectedAccountDetails?.ifscCode
            : "",
        accountNo:
          paymentMode === "bank-transfer"
            ? selectedAccountDetails?.accountNumber
            : "",
        name:
          paymentMode === "bank-transfer"
            ? selectedAccountDetails?.accountHolderName
            : "",
      },
      receiverBankDetails: {
        ifscCode:
          paymentMode === "bank-transfer" ? senderAccountDetails?.ifscCode : "",
        accountNo:
          paymentMode === "bank-transfer"
            ? senderAccountDetails?.accountNumber
            : "",
        name:
          paymentMode === "bank-transfer"
            ? senderAccountDetails?.accountHolderName
            : "",
      },
      transactionDoneBy: user?._id,
      upiId,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.advancePayment,
      data
    );
    if (res?.success) {
      handleGetVendorAmounts();
      handleGetVendortransaction();
      setaddPaymentRes(res?.data);
      handleReset();
      setSuccessPayment(true);
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  const handleAddInstallment = async (e) => {
    e.preventDefault();
    startApiCall(setErrorMessage, setloader);
    const data = {
      transactionId: vendorPaymentModal?.data?._id,
      date: currentDate,
      paymentMode,
      amountReceived: amount,
      senderBankDetails: {
        ifscCode:
          paymentMode === "bank-transfer"
            ? selectedAccountDetails?.ifscCode
            : "",
        accountNo:
          paymentMode === "bank-transfer"
            ? selectedAccountDetails?.accountNumber
            : "",
        name:
          paymentMode === "bank-transfer"
            ? selectedAccountDetails?.accountHolderName
            : "",
      },
      receiverBankDetails: {
        ifscCode:
          paymentMode === "bank-transfer" ? senderAccountDetails?.ifscCode : "",
        accountNo:
          paymentMode === "bank-transfer"
            ? senderAccountDetails?.accountNumber
            : "",
        name:
          paymentMode === "bank-transfer"
            ? senderAccountDetails?.accountHolderName
            : "",
      },
      transactionDoneBy: user?._id,
      upiId,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.addInstallment,
      data
    );
    if (res?.success) {
      setaddPaymentRes(res?.data);
      handleReset();
      handleGetVendorSubtransaction();
      handleGetVendortransaction();
      handleGetVendorAmounts();
    } else {
      setloader(false);
      setErrorMessage(res?.error);
    }
  };

  useEffect(() => {
    if (vendorPaymentModal) {
      handleGetBusinessDetails();
    }
  }, []);
  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-2">
          <div className="modal-body pb-0">
            <button
              type="button"
              className="btn-close mt-2 pe-2"
              onClick={handleReset}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h5>
              {isAdvance
                ? "Pay Advance"
                : `Pay  ${
                    vendorPaymentModal?.data?.billNumber
                      ? `(${vendorPaymentModal?.data?.billNumber})`
                      : ""
                  } `}
            </h5>
            <div className="border-bottom mb-3"></div>
            <form
              onSubmit={(e) => {
                if (isAdvance) {
                  handleAddAdvancePayment(e);
                } else {
                  handleAddInstallment(e);
                }
              }}
            >
              <div className="mh-500p overflow-auto">
                <div className="row gx-3 px-2 w-100">
                  <div className="col-sm-6">
                    <label className="form-label">
                      Payment mode {reuiredField}
                    </label>
                    <div className="form-group">
                      <select
                        className="form-select form-select-md"
                        required
                        value={paymentMode}
                        onChange={(e) => {
                          setPaymentMode(e.target.value);
                        }}
                      >
                        <option value="cash">Cash</option>
                        <option value="bank-transfer">Bank Transfer</option>
                        <option value="upi">UPI</option>
                        {!isAdvance && <option value="advance">Advance</option>}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">Amount {reuiredField}</label>
                    <input
                      className="form-control"
                      type="number"
                      required
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      placeholder="Enter amount"
                    />
                  </div>
                  {paymentMode === "bank-transfer" && (
                    <>
                      <label className="title title-md title-wth-divider text-primary mb-2">
                        <span>Vendor Account Details</span>
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group mt-2">
                          <select
                            className="form-select form-select-md"
                            value={selectedVendorAccount}
                            onChange={(e) => {
                              setselectedVendorAccount(e.target.value);
                              handleVendorAccountSelection(e.target.value);
                            }}
                          >
                            <option value="">Select Vendor Account</option>
                            {vendorDetails?.bankAccounts?.length > 0 && (
                              <>
                                {vendorDetails?.bankAccounts?.map((bnk) => (
                                  <option value={bnk?._id}>
                                    {bnk?.accountHolderName} (
                                    {bnk?.accountNumber})
                                  </option>
                                ))}
                              </>
                            )}
                          </select>
                          {vendorDetails?.bankAccounts?.length === 0 && (
                            <div className="d-flex col-sm-12 justify-content-start">
                              <div
                                className="alert text-link pointer text-primary fs-7"
                                data-bs-toggle="modal"
                                data-bs-target="#add_account_details"
                              >
                                Vendor Account Not Added , Add Account Details
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <label className="title title-md title-wth-divider text-primary mb-2">
                        <span>Sendor Account Details</span>
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group mt-2">
                          <select
                            className="form-select form-select-md"
                            value={senderAccount}
                            onChange={(e) => {
                              setsenderAccount(e.target.value);
                              handleSenderAccountSelection(e.target.value);
                            }}
                          >
                            <option value="">Select Sender's Account</option>
                            {businessDetails?.businessId?.bankAccounts?.length >
                              0 && (
                              <>
                                {businessDetails?.businessId?.bankAccounts?.map(
                                  (bnk) => (
                                    <option value={bnk?._id}>
                                      {bnk?.accountHolderName} (
                                      {bnk?.accountNumber})
                                    </option>
                                  )
                                )}
                              </>
                            )}
                          </select>
                          {businessDetails?.businessId?.bankAccounts?.length ===
                            0 && (
                            <div className="d-flex col-sm-12 justify-content-center">
                              <div
                                className="alert text-link pointer text-primary fs-7"
                                role="alert"
                                onClick={() => {
                                  navigate(navigateToOtherSettings());
                                }}
                              >
                                Sender Account Not Added , Add Account Details
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {paymentMode === "upi" && (
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label class="form-label">
                          Enter UPI Id {reuiredField}
                        </label>
                        <input
                          class="form-control"
                          type="text"
                          value={upiId}
                          required
                          onChange={(e) => {
                            setUpiId(e.target.value);
                          }}
                          placeholder="Enter upi id"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
              <div className="modal-footer align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={
                    loader ||
                    (paymentMode === "bank-transfer" &&
                      (!selectedVendorAccount || !senderAccount))
                  }
                >
                  {loader ? <ApiLoader /> : "Pay"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorPayment;
