import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import ManageStock from "../../pages/InventoryManagement/ManageStock/ManageStock";

const ManageStockRoutes = {
  path: "/inventory",
  element: <Navbar />,
  children: [
    {
      path: "managestock",
      element: <ManageStock />,
    },
  ],
};

export default ManageStockRoutes;