import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Logs from "../../pages/Logs/Logs";

const LogsRoutes = {
  path: "/log",
  element: <Navbar />,
  children: [
    {
      path: "",
      element: <Logs />,
    },
    {
      path: ":module/:id",
      element: <Logs />,
    },
  ],
};

export default LogsRoutes;
