import React, { useEffect, useState } from "react";
import CustomerTable from "./CustomerTable";
import AddCustomer from "./AddCustomer";
import { InventoryCustomerRelatedEndPoints } from "../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

function Customer() {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [addCustomerModal, setaddCustomerModal] = useState(false);
  const [search, setSearch] = useState("");
  const [allCustomers, setAllCustomers] = useState([]);
  const [customerData, setcustomerData] = useState("");
  const [isEdit, setisEdit] = useState(false);

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(10);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const isModuleReadWriteAccessForCustomer =
    allModuleAccess?.[PRIVATEROUTES.CUSTOMER];

  const handleGetAllCustomer = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: {
        businessId: getCurrentWorkspaceId(),
        searchKey: search,
        isDeleted: false,
      },
    };
    const res = await ApiCall(
      "post",
      InventoryCustomerRelatedEndPoints.getCustomer,
      data
    );
    if (res?.success) {
      setAllCustomers(res?.buyer?.data);
      setcount(res?.buyer?.totalCounts);
      settotalPages(res?.buyer?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllCustomer();
    }
  }, [page, pageLimit, search]);
  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <span className="todoapp-title  link-dark">
                <h1 className="header-title-font ">All Customers</h1>
                {isModuleReadWriteAccessForCustomer && (
                  <div className="ms-3">
                    <button
                      className="btn btn-sm btn-outline-light flex-shrink-0 d-lg-inline-block header-button-shadow"
                      onClick={() => {
                        setaddCustomerModal(true);
                      }}
                    >
                      Add Customer
                    </button>
                  </div>
                )}
              </span>
            </div>
            <div className="todo-options-wrap">
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="dsdsd"
                data-bs-original-title="Refresh"
              >
                <span className="btn-icon-wrap" onClick={handleGetAllCustomer}>
                  <span className="feather-icon">
                    <i
                      className="ri-refresh-line"
                      title="Refresh business list"
                    ></i>
                  </span>
                </span>
              </span>

              <div className="v-separator d-lg-inline-block d-none"></div>
              <form className="d-sm-block d-none" role="search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                />
              </form>
            </div>
          </header>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allCustomers?.length > 0 && (
                <>
                  <CustomerTable
                    allCustomers={allCustomers}
                    setcustomerData={setcustomerData}
                    customerData={customerData}
                    handleGetAllCustomer={handleGetAllCustomer}
                    setisEdit={setisEdit}
                    setaddCustomerModal={setaddCustomerModal}
                    page={page}
                    pageLimit={pageLimit}
                    isModuleReadWriteAccessForCustomer={
                      isModuleReadWriteAccessForCustomer
                    }
                  />
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allCustomers?.length}
                    align={"end"}
                  />
                </>
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allCustomers?.length === 0 && (
                <NoData title="No customer added" />
              )}
            </div>
          </div>
        </div>
      </div>
      {addCustomerModal && (
        <AddCustomer
          setaddCustomerModal={setaddCustomerModal}
          handleGetAllCustomer={handleGetAllCustomer}
          isEdit={isEdit}
          customerData={customerData}
          setisEdit={setisEdit}
        />
      )}
    </div>
  );
}

export default Customer;
