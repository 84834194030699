import React from "react";
import ApiLoader from "../../components/Loaders/ApiLoader/ApiLoader";

function LogsHeader(props) {
  const {
    startDate,
    setstartDate,
    endDate,
    setendDate,
    handleGetLogs,
    module,
    loader2,
  } = props;
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center flex-1">
        <span className="todoapp-title link-dark">
          <h1>{`${module} Activity`}</h1>
        </span>
      </div>
      <div className="fm-options-wrap d-flex align-items-end justify-content-end">
        <label className="form-label me-1 pt-1">Start Date</label>
        <input
          className="form-control me-1 w-25"
          name="single-date-pick"
          type="date"
          value={startDate}
          onChange={(e) => {
            setstartDate(e.target.value);
          }}
        />
        <label className="form-label me-1 pt-1">End Date</label>
        <input
          className="form-control me-1 w-25"
          name="single-date-pick"
          type="date"
          value={endDate}
          onChange={(e) => {
            setendDate(e.target.value);
          }}
          min={startDate}
        />
        <button
          type="button"
          disabled={startDate === "" || endDate === ""}
          className="btn btn-md btn-primary"
          onClick={handleGetLogs}
        >
          {loader2 ? <ApiLoader /> : "Filter"}
        </button>
      </div>
      <div className="v-separator d-lg-inline-block d-none"></div>
      <span
        className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Refresh Space Page"
        data-bs-original-title="Refresh"
      >
        <span className="btn-icon-wrap" onClick={handleGetLogs}>
          <span className="feather-icon">
            <i className="ri-refresh-line" title="Refresh business list"></i>
          </span>
        </span>
      </span>
    </header>
  );
}

export default LogsHeader;
