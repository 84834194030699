import React, { useEffect, useState } from "react";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  reversesDateFormatter,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { taskEndpoint } from "../../../api/Endpoints";
import CustomDateFilterStock from "../../InventoryManagement/ManageStock/CustomDateFilterStock";
import PerformanceBody from "./PerformanceBody";
import PerformanceHeader from "./PerformanceHeader";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";
import { useSelector } from "react-redux";

const Performance = () => {
  const { allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [searchKey, setsearchKey] = useState("");

  const [allAnalytics, setAllAnalytics] = useState([]);
  const [allAnalyticsCheck, setAllAnalyticsCheck] = useState([]);
  const [performanceKey, setperformanceKey] = useState("completed");
  const [dateModal, setdateModal] = useState(false);

  const [filterType, setFilterType] = useState("thismonth");

  const currentDate = new Date();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(currentDate.getDate() - 1);

  const isModuleReadWriteAccessForPerformance =
    allModuleAccess?.[PRIVATEROUTES.Performance];

  const currentMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const currentMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  const previousMonthStartDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );
  const previousMonthEndDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    0
  );

  const dayOfWeek = currentDate.getDay();
  const weekStartDate = new Date(currentDate);
  weekStartDate.setDate(currentDate.getDate() - dayOfWeek);

  const weekEndDate = new Date(currentDate);
  weekEndDate.setDate(currentDate.getDate() + (6 - dayOfWeek));

  const lastWeekStartDate = new Date(currentDate);
  lastWeekStartDate.setDate(currentDate.getDate() - (dayOfWeek + 7));

  const lastWeekEndDate = new Date(currentDate);
  lastWeekEndDate.setDate(currentDate.getDate() - dayOfWeek - 1);

  const formattedweekStartDate = weekStartDate.toISOString().split("T")[0];
  const formattedlastweekStartDate = lastWeekStartDate
    .toISOString()
    .split("T")[0];
  const formattedweekEndDate = weekEndDate.toISOString().split("T")[0];
  const formattedlastweekEndDate = lastWeekEndDate.toISOString().split("T")[0];

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState(reversesDateFormatter(currentDate));

  const handleGetTaskAnalytics = async () => {
    startApiCall(null, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      employeeId: "",
      searchKey,
      startDate:
        filterType === "thismonth"
          ? reversesDateFormatter(currentMonthStartDate)
          : filterType === "lastmonth"
          ? reversesDateFormatter(previousMonthStartDate)
          : filterType === "thisweek"
          ? formattedweekStartDate
          : filterType === "Date Range"
          ? startDate
          : formattedlastweekStartDate,
      endDate:
        filterType === "thismonth"
          ? reversesDateFormatter(currentMonthEndDate)
          : filterType === "lastmonth"
          ? reversesDateFormatter(previousMonthEndDate)
          : filterType === "thisweek"
          ? formattedweekEndDate
          : filterType === "Date Range"
          ? endDate
          : formattedlastweekEndDate,
    };
    const res = await ApiCall("post", taskEndpoint.employeeTaskOverview, data);

    if (res?.success) {
      const sortedData = res.data.sort((a, b) => {
        if (a.finalScore === b.finalScore) {
          return b.statuses.all - a.statuses.all;
        }
        return b.finalScore - a.finalScore;
      });
      setdateModal(false);
      setperformanceKey(res.performanceKey);
      const combinedData = sortedData?.map((item) => ({
        ...item,
        dataType: "analytics",
      }));
      const combinedCheckData = res?.check
        ?.filter((task) => !task?.hasTask)
        ?.map((item) => ({
          ...item,
          dataType: "check",
        }));
      setAllAnalytics([...combinedData, ...combinedCheckData]);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (filterType === "Date Range" && !startDate) {
      //nothing
    } else if (getCurrentWorkspaceId()) {
      handleGetTaskAnalytics();
      closeAllModals();
    }
  }, [filterType, searchKey]);

  const handleRefresh = () => {
    handleGetTaskAnalytics();
    setsearchKey("");
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <PerformanceHeader
            filterType={filterType}
            setFilterType={setFilterType}
            handleRefresh={handleRefresh}
            setdateModal={setdateModal}
            searchKey={searchKey}
            setsearchKey={setsearchKey}
          />

          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar simple-scrollbar">
              <div className="contact-list-view">
                <PerformanceBody
                  allAnalytics={allAnalytics}
                  loader={loader}
                  isModuleReadWriteAccessForPerformance={
                    isModuleReadWriteAccessForPerformance
                  }
                  performanceKey={performanceKey}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomDateFilterStock
          startDate={startDate}
          setstartDate={setstartDate}
          endDate={endDate}
          setendDate={setendDate}
          mainLoader={loader}
          dateModal={dateModal}
          handleGetAllStock={handleRefresh}
          dateType={filterType}
          setdateModal={setdateModal}
        />
      </div>
    </div>
  );
};

export default Performance;
