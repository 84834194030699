import React, { useEffect, useState } from "react";
import {
  dateAndTimeFormatter,
  dateFormatter,
  getTimeFormatter,
} from "../../../../global-functions/globalFunctions";
import { Avatar } from "../../../../components/Avatar/Avatar";

const MoreDetailsCard = (props) => {
  const {
    attendanceDetailsCount,
    attendanceDetails,
    attendanceOverview,
    today,
    startDate,
    selectedDate,
    holiday,
  } = props;
  const [loginTime, setloginTime] = useState("");
  const [logoutTime, setlogoutTime] = useState("");

  const monthAndYear = new Date(startDate);
  let d = new Date(selectedDate);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    let lastLogout = "";
    let firstLogin = "";
    if (
      attendanceOverview?.dateDetails?.sessions &&
      attendanceOverview?.dateDetails?.sessions?.length > 0
    ) {
      const firstSession = attendanceOverview?.dateDetails?.sessions[0];
      const lastSession =
        attendanceOverview?.dateDetails.sessions[
          attendanceOverview?.dateDetails?.sessions?.length - 1
        ];

      lastLogout = lastSession?.logoutTime || "";
      firstLogin = firstSession?.loginTime || "";
    }

    setloginTime(firstLogin ? firstLogin : "");
    setlogoutTime(lastLogout ? lastLogout : "");
  }, [attendanceOverview]);

  const presentShift = attendanceDetails?.staff?.shiftTimings.find(
    (shift) => shift.shiftType === "present"
  );

  return (
    <>
      {attendanceOverview?.dateDetails?.presentStatus === "present" && (
        <div
          className="card rounded-4 overflow-hidden w-100"
          style={{
            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            className="py-3 px-4"
            style={{ borderLeft: "20px solid #00C172" }}
          >
            <div className="d-flex justify-content-between">
              <p className="fs-5 text-primary fw-medium text-capitalize">
                {attendanceOverview?.dateDetails
                  ? dateFormatter(attendanceOverview?.dateDetails?.date)
                  : dateFormatter(today)}{" "}
                :{" "}
                {attendanceOverview?.dateDetails?.presentStatus === "present" &&
                  "Present"}
              </p>
              {attendanceOverview?.dateDetails?.isLop && (
                <p className="font-3 text-danger fw-medium text-capitalize">
                  Marked LOP
                </p>
              )}

              <p className="mt-1 fs-6">
                Verified by : &nbsp;
                {attendanceOverview?.dateDetails?.isVerified ? (
                  <>
                    <Avatar
                      name={attendanceOverview?.dateDetails?.verifiedBy?.name}
                      count={1}
                      size="xxs"
                      color="primary"
                    />
                    &nbsp;
                    <span className="text-dark">
                      {attendanceOverview?.dateDetails?.verifiedBy?.name}
                    </span>
                    &nbsp;
                  </>
                ) : (
                  <span className="text-red fw-semibold">Pending</span>
                )}
              </p>
            </div>
            <div className="d-flex align-items-center gap-7 mt-3">
              <div className="d-flex gap-2">
                <span
                  className="icon rounded-4 btn btn-sm"
                  style={{ background: "#E3EDFF" }}
                >
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  </span>
                </span>
                <span className="fs-7 text-dark">
                  <p className="fw-medium">Shift timing</p>
                  <p>
                    {attendanceOverview?.dateDetails?.staffShift
                      ? getTimeFormatter(
                          attendanceOverview?.dateDetails?.staffShift?.startTime
                        )
                      : presentShift
                      ? getTimeFormatter(
                          attendanceDetails?.staff?.shiftTimings?.startTime
                        )
                      : "-"}{" "}
                    -{" "}
                    {attendanceOverview?.dateDetails?.staffShift
                      ? getTimeFormatter(
                          attendanceOverview?.dateDetails?.staffShift?.endTime
                        )
                      : presentShift
                      ? getTimeFormatter(
                          attendanceDetails?.staff?.shiftTimings?.endTime
                        )
                      : "-"}
                  </p>
                </span>
              </div>
              <div className="d-flex gap-2">
                <span
                  className="icon rounded-4 btn btn-sm"
                  style={{ background: "#E3EDFF" }}
                >
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  </span>
                </span>
                <span className="fs-7 text-dark">
                  <p className="fw-medium">Log in</p>
                  <p>
                    {loginTime
                      ? dateAndTimeFormatter(loginTime)?.split(",")[1]?.trim()
                      : "-"}
                  </p>
                </span>
              </div>
              <div className="d-flex gap-2">
                <span
                  className="icon rounded-4 btn btn-sm"
                  style={{ background: "#E3EDFF" }}
                >
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  </span>
                </span>
                <span className="fs-7 text-dark">
                  <p className="fw-medium">Log out</p>
                  <p>
                    {logoutTime
                      ? dateAndTimeFormatter(logoutTime)?.split(",")[1]?.trim()
                      : "On duty"}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {attendanceOverview?.dateDetails?.presentStatus === "absent" &&
        !attendanceOverview?.dateDetails?.isLeave && (
          <div
            className="card rounded-4 overflow-hidden w-100"
            style={{
              boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              className="pt-2 pb-1 px-4 text-dark d-flex flex-column gap-2"
              style={{ borderLeft: "20px solid #FFAC99" }}
            >
              <p className="text-primary fs-5 fw-medium">
                {attendanceOverview?.dateDetails
                  ? dateFormatter(attendanceOverview?.dateDetails?.date)
                  : dateFormatter(today)}
                : Absent &nbsp;{" "}
                <span className="fs-7 text-secondary">
                  Verified by : &nbsp;
                  {attendanceOverview?.dateDetails?.isVerified ? (
                    <>
                      <Avatar
                        name={attendanceOverview?.dateDetails?.verifiedBy?.name}
                        count={1}
                        size="xxs"
                        color="primary"
                      />
                      &nbsp;
                      {attendanceOverview?.dateDetails?.verifiedBy?.name} &nbsp;
                    </>
                  ) : (
                    "Pending"
                  )}
                </span>
              </p>

              <div className="d-flex justify-content-center">
                <div className="alert alert-danger text-dark fs-7" role="alert">
                  <strong>Note:</strong>{" "}
                  {attendanceDetails?.staff?.employeeId?.name} is currently
                  unavailable or has not provided leave information. (Employee
                  is marked as absent)
                </div>
              </div>
            </div>
          </div>
        )}
      {attendanceOverview?.dateDetails?.presentStatus === "half_day" && (
        <div
          className="card rounded-4 overflow-hidden w-100"
          style={{
            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            className="py-2 px-4"
            style={{ borderLeft: "20px solid #FFC207" }}
          >
            <p className=" fs-5 text-primary fw-medium">
              {attendanceOverview?.dateDetails
                ? dateFormatter(attendanceOverview?.dateDetails?.date)
                : dateFormatter(today)}{" "}
              :{" "}
              {attendanceOverview?.dateDetails?.presentStatus === "half_day" &&
                "Half Day"}
            </p>
            {attendanceOverview?.dateDetails?.isLop && (
              <p className="font-3 text-danger fw-medium text-capitalize">
                Marked LOP
              </p>
            )}
            <p className="mb-2 fs-7">
              Verified by : &nbsp;
              {attendanceOverview?.dateDetails?.isVerified ? (
                <>
                  <Avatar
                    name={attendanceOverview?.dateDetails?.verifiedBy?.name}
                    count={1}
                    size="xxs"
                    color="primary"
                  />
                  &nbsp;
                  {attendanceOverview?.dateDetails?.verifiedBy?.name}
                </>
              ) : (
                "Pending"
              )}
            </p>
            <div className="d-flex align-items-center gap-7">
              <div className="d-flex gap-2">
                <span
                  className="icon rounded-4 btn btn-sm"
                  style={{ background: "#E3EDFF" }}
                >
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  </span>
                </span>
                <span className="fs-7 text-dark">
                  <p className="fw-medium">Shift timing</p>
                  <p>
                    {attendanceOverview?.dateDetails?.staffShift
                      ? getTimeFormatter(
                          attendanceOverview?.dateDetails?.staffShift?.startTime
                        )
                      : presentShift
                      ? getTimeFormatter(
                          attendanceDetails?.staff?.shiftTimings?.startTime
                        )
                      : "-"}{" "}
                    -{" "}
                    {attendanceOverview?.dateDetails?.staffShift
                      ? getTimeFormatter(
                          attendanceOverview?.dateDetails?.staffShift?.endTime
                        )
                      : presentShift
                      ? getTimeFormatter(
                          attendanceDetails?.staff?.shiftTimings?.endTime
                        )
                      : "-"}
                  </p>
                </span>
              </div>
              <div className="d-flex gap-2">
                <span
                  className="icon rounded-4 btn btn-sm"
                  style={{ background: "#E3EDFF" }}
                >
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  </span>
                </span>
                <span className="fs-7 text-dark">
                  <p className="fw-medium">Log in</p>
                  <p>
                    {loginTime
                      ? dateAndTimeFormatter(loginTime)?.split(",")[1]?.trim()
                      : "-"}{" "}
                  </p>
                </span>
              </div>
              <div className="d-flex gap-2">
                <span
                  className="icon rounded-4 btn btn-sm"
                  style={{ background: "#E3EDFF" }}
                >
                  <span className="feather-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                  </span>
                </span>
                <span className="fs-7 text-dark">
                  <p className="fw-medium">Log out</p>
                  <p>
                    {logoutTime
                      ? dateAndTimeFormatter(logoutTime)?.split(",")[1]?.trim()
                      : "On duty"}{" "}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {attendanceOverview?.dateDetails?.presentStatus === "absent" &&
        attendanceOverview?.dateDetails?.isLeave && (
          <div
            className="card rounded-4 overflow-hidden w-100"
            style={{
              boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              className="pt-2 pb-3 px-4 text-dark d-flex flex-column gap-2"
              style={{ borderLeft: "20px solid #FB1010" }}
            >
              <p className="text-primary fs-5 fw-medium">
                {attendanceOverview?.dateDetails
                  ? dateFormatter(attendanceOverview?.dateDetails?.date)
                  : dateFormatter(today)}
                : Leave &nbsp;{" "}
                <span className="badge badge-md badge-pill badge-soft-primary">
                  {attendanceOverview?.leaveData?.leaveType?.leaveName}
                </span>
              </p>
              <div className="d-flex justify-content-between gap-3">
                <span className="d-flex flex-column gap-2 text-capitalize">
                  <p>
                    Leave title :{" "}
                    {attendanceOverview?.leaveData?.description
                      ? attendanceOverview?.leaveData?.description
                      : "-"}
                  </p>
                  <p>
                    Requested on :{" "}
                    {attendanceOverview?.leaveData?.createdAt
                      ? dateFormatter(attendanceOverview?.leaveData?.createdAt)
                      : "-"}
                  </p>
                </span>
                <span className="d-flex flex-column gap-2 col-sm-5">
                  <p>
                    Approved by :{" "}
                    {attendanceOverview?.leaveData?.status === "pending" ? (
                      "pending"
                    ) : attendanceOverview?.leaveData?.actionBy?.name ? (
                      <>
                        <Avatar
                          name={attendanceOverview?.leaveData?.actionBy?.name}
                          count={1}
                          size="xxs"
                          color="primary"
                        />
                        &nbsp;
                        {attendanceOverview?.leaveData?.actionBy?.name}
                      </>
                    ) : (
                      "-"
                    )}
                  </p>
                  <p>
                    Approved on :{" "}
                    {attendanceOverview?.leaveData?.actionOn
                      ? dateFormatter(attendanceOverview?.leaveData?.actionOn)
                      : " -"}{" "}
                  </p>
                </span>
              </div>
            </div>
          </div>
        )}
      {(attendanceDetails?.staff?.offDays?.includes(daysOfWeek[d.getDay()]) ||
        holiday?.includes(selectedDate)) && (
        <div
          className="card rounded-4 overflow-hidden w-100"
          style={{
            boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div
            className="pt-2 pb-3 px-4 text-dark"
            style={{
              borderLeft: `${
                holiday?.includes(selectedDate)
                  ? " 20px solid rgb(221, 97, 69)"
                  : "20px solid #C45AD0"
              }`,
            }}
          >
            <p className="text-primary fs-5 fw-medium">
              {selectedDate
                ? dateFormatter(selectedDate)
                : dateFormatter(today)}{" "}
              : Holiday
            </p>
            <div className="border-start border-dark h-45p ms-1"></div>
            <p>
              {holiday?.includes(selectedDate) ? (
                <span className="fw-bold text-capitalize">
                  {attendanceOverview?.holiday?.holidayName}
                </span>
              ) : (
                "Weekly Off"
              )}
            </p>
          </div>
        </div>
      )}

      <div
        className="card rounded-4 py-3 px-4 w-100"
        style={{
          boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        <p className="fs-6 text-primary fw-medium">
          {" "}
          {monthAndYear.toLocaleDateString(undefined, {
            month: "long",
            year: "numeric",
          })}{" "}
          Summary
        </p>
        <div className="d-flex align-items-center justify-content-between gap-3">
          <div className="d-flex flex-column align-items-center text-dark fw-medium">
            <p>Total working days</p>
            <strong>{attendanceDetailsCount?.totalWorkingDays}</strong>
          </div>
          <div className="d-flex flex-column align-items-center text-dark fw-medium">
            <p>Present days</p>
            <strong>{attendanceDetailsCount?.totalPresentDay}</strong>
          </div>
          <div className="d-flex flex-column align-items-center text-dark fw-medium">
            <p>Absent days</p>
            <strong>{attendanceDetailsCount?.absentCount}</strong>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoreDetailsCard;
