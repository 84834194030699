import React from "react";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

export const DueDate = (props) => {
  const {
    dueDateForFilter,
    setdueDateForFilter,
    mainLoader,
    handleResetFilters,
  } = props;

  return (
    <div className="form-group">
      <label className="form-label">Select Due Date</label>
      <input
        className="form-control"
        name="single-date-pick"
        type="date"
        value={dueDateForFilter}
        onChange={(e) => {
          setdueDateForFilter(e.target.value);
        }}
      />
      <div className="modal-footer align-items-center">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            handleResetFilters("Due Date",false);
          }}
        >
          Close
        </button>
        <button
          type="submit"
          disabled={dueDateForFilter === ""}
          className="btn btn-primary"
        >
          {mainLoader ? <ApiLoader /> : "Filter"}
        </button>
      </div>
    </div>
  );
};
