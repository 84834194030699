import React, { useEffect, useState } from "react";
import ParticipantsGroupsHeader from "./ParticipantsGroupsHeader";
import ParticipantsGroupsTable from "./ParticipantsGroupsTable";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import AddParticipantGroup from "./AddParticipantGroup";
import {
  closeAllModals,
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { CrmParticipantsGroup } from "../../../api/Endpoints";
import FooterPagination from "../../../components/Pagination/FooterPagination";
import NoData from "../../../components/NoData/NoData";
import AddParticipants from "../Group/GroupStatus/AddParticipants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PRIVATEROUTES from "../../../components/Constant/Route-Constants";

function ParticipantsGroups() {
  const { user, allModuleAccess } = useSelector((state) => state);
  const [loader, setloader] = useState(false);
  const [allGroup, setallGroup] = useState([]);
  const [search, setSearch] = useState("");
  const [addGroup, setaddGroup] = useState(false);
  const [editData, seteditData] = useState("");
  const [checkedGroups, setCheckedGroups] = useState({});
  const [addParticipant, setAddParticipant] = useState(false);
  const [selectedAssigneeId, setselectedAssigneeId] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [loader2, setLoader2] = useState(false);
  const [showCheckBox, setshowCheckBox] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const isModuleReadWriteAccessForParticipantGroup =
    allModuleAccess?.[PRIVATEROUTES.PARTICIPANT_GROUP];

  // ****** PAGINATION ******
  const [page, setPage] = useState(1);
  const [pageLimit, setpageLimit] = useState(30);
  const [count, setcount] = useState(0);
  const [totalPages, settotalPages] = useState(0);

  const handleClose = () => {
    setAddParticipant(false);
  };

  const handleReset = () => {
    setLoader2(false);
    seterrorMessage("");
    setPage(1);
    setselectedAssigneeId([]);
    setCheckedGroups([]);
    seterrorMessage("");
    setshowCheckBox(false);
    setAddParticipant(false);
  };

  const handleGetAllGroups = async () => {
    startApiCall(null, setloader);
    const data = {
      page: page,
      limit: pageLimit,
      filters: { businessId: getCurrentWorkspaceId(), searchKey: search },
    };
    const res = await ApiCall("post", CrmParticipantsGroup.getGroup, data);
    if (res?.success) {
      setallGroup(res?.result?.data);
      setcount(res?.result?.totalCounts);
      settotalPages(res?.result?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  const handleAddParticipant = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader2);

    const leadGroupIds = Object.values(checkedGroups).reduce(
      (acc, curr) => [...acc, ...curr],
      []
    );

    const sendData = {
      leadGroupIds,
      employees: selectedAssigneeId,
      actionBy: user?._id,
    };
    const res = await ApiCall(
      "post",
      CrmParticipantsGroup.addMultipleParticipant,
      sendData
    );
    if (res?.success) {
      handleReset();
      toast.success("Added Participants to Group successfully!");
      handleGetAllGroups();
    } else {
      setLoader2(false);
      seterrorMessage(res.error);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId) {
      handleGetAllGroups();
      closeAllModals();
    }
  }, [search, page, pageLimit]);

  useEffect(() => {
    let temp = [];
    allGroup?.participants?.map((emp) => {
      temp.push(emp?._id);
    });
    setselectedAssigneeId(temp);
  }, [allGroup]);

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <ParticipantsGroupsHeader
            setaddGroup={setaddGroup}
            setSearch={setSearch}
            search={search}
            setPage={setPage}
            handleGetAllGroups={handleGetAllGroups}
            setAddParticipant={setAddParticipant}
            setshowCheckBox={setshowCheckBox}
            showCheckBox={showCheckBox}
            checkedGroups={checkedGroups}
            setCheckedGroups={setCheckedGroups}
            isModuleReadWriteAccessForParticipantGroup={
              isModuleReadWriteAccessForParticipantGroup
            }
          />
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allGroup.length > 0 && (
                <>
                  <ParticipantsGroupsTable
                    seteditData={seteditData}
                    setaddGroup={setaddGroup}
                    allGroup={allGroup}
                    page={page}
                    pageLimit={pageLimit}
                    setCheckedGroups={setCheckedGroups}
                    checkedGroups={checkedGroups}
                    showCheckBox={showCheckBox}
                    setshowCheckBox={setshowCheckBox}
                    selectAllChecked={selectAllChecked}
                    setSelectAllChecked={setSelectAllChecked}
                    isModuleReadWriteAccessForParticipantGroup={
                      isModuleReadWriteAccessForParticipantGroup
                    }
                  />
                  <FooterPagination
                    setpageLimit={setpageLimit}
                    pageLimit={pageLimit}
                    setPage={setPage}
                    page={page}
                    totalPages={totalPages}
                    count={count}
                    dataLength={allGroup?.length}
                    align={"end"}
                  />
                </>
              )}

              {loader && <DataLoader title="data is loading..." />}
              {!loader && allGroup?.length === 0 && (
                <NoData title="No groups added" />
              )}
            </div>
          </div>
        </div>
        {addGroup && (
          <AddParticipantGroup
            setaddGroup={setaddGroup}
            handleGetAllGroups={handleGetAllGroups}
            editData={editData}
            seteditData={seteditData}
          />
        )}
        {addParticipant && (
          <AddParticipants
            addParticipant={addParticipant}
            setAddParticipant={setAddParticipant}
            selectedAssigneeId={selectedAssigneeId}
            setselectedAssigneeId={setselectedAssigneeId}
            handleClose={handleClose}
            handleAddParticipant={handleAddParticipant}
            groupDetails={allGroup}
            loader2={loader2}
            errorMessage={errorMessage}
            isSingle={false}
          />
        )}
      </div>
    </div>
  );
}

export default ParticipantsGroups;
