import React from "react";

const TableData = ({
  allTables,
  page,
  pageLimit,
  setselectedTable,
  setisAddTable,
  setisDelete,
  isModuleReadWriteAccessForTables,
}) => {
  return (
    <table id="datable_1" className="table nowrap w-100 mb-8">
      <thead>
        <tr className="text-center">
          <th>No.</th>
          <th>Table Name</th>
          <th>Table Number</th>
          <th>Floor</th>
          <th>Location</th>
          <th>Sitting Capacity</th>
          <th>Assigned Person</th>
          <th>Status</th>
          {isModuleReadWriteAccessForTables && <th>Action</th>}
        </tr>
      </thead>
      <tbody>
        {allTables?.map((tb, index) => {
          return (
            <tr key={index} className="text-center text-dark">
              <td>{(page - 1) * pageLimit + (index + 1)} .</td>
              <td>{tb?.tableName}</td>
              <td>{tb?.tableNumber}</td>

              <td>{tb?.floor}</td>
              <td> {tb?.location}</td>
              <td> {tb?.sittingCapacity}</td>
              <td> {tb?.assignedPerson ? tb?.assignedPerson : "-"}</td>
              <td>
                {tb?.currentOrder ? (
                  <span
                    style={{ backgroundColor: "#07bc0c" }}
                    class="badge badge-pill badge-success pulse"
                  >
                    Booked
                  </span>
                ) : (
                  <span class="badge badge-pill badge-danger">Vacant</span>
                )}
              </td>
              {isModuleReadWriteAccessForTables && (
                <td>
                  <span className="feather-icon pointer">
                    <span className="btn-icon-wrap">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="20"
                        fill="currentColor"
                        title="Edit Table"
                        className="bi bi-pencil-square me-2 text-primary"
                        viewBox="0 0 16 16"
                        onClick={() => {
                          setselectedTable(tb);
                          setisAddTable(true);
                        }}
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>
                      <svg
                        onClick={() => {
                          setselectedTable(tb);
                          setisDelete(true);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        title="Delete Table"
                        fill="currentColor"
                        className="bi bi-trash text-danger"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                      </svg>
                    </span>
                  </span>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableData;
