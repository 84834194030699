import React, { useState } from "react";
import ChangePassword from "./ChangePassword";
import ManageDevices from "./ManageDevices";
import ConfirmRemoveDevices from "./ConfirmRemoveDevices";
import { dateAndTimeFormatter } from "../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";

const Security = () => {
  const { user } = useSelector((state) => state);
  const [showConfirm, setShowConfirm] = useState(false);
  const [firstStep, setfirstStep] = useState(true);

  return (
    <>
      <div className="d-flex flex-column gap-2">
        <span className="text-dark fs-4 fw-semibold mb-0">
          Password and Security
        </span>
        <span className="fs-4 mb-0">Manage your password login and recovery methods</span>
        {/* <div className="card rounded-7">
          <div className="card-body">
            <div className="d-flex flex-column">
              <span className="fs-4" style={{ color: "#525252" }}>
                Recent security alrets
              </span>
              <span className="mt-2">No security alret in last 10 days</span>
            </div>
          </div>
        </div> */}
        <div className="card rounded-7">
          <div className="card-body">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <span className="fs-4" style={{ color: "#525252" }}>
                  Change Password
                </span>
                <div>
                  <span
                    className="btn btn-outline-secondary rounded-7 d-flex align-items-center"
                    data-bs-toggle="modal"
                    data-bs-target="#change_password"
                    onClick={() => setfirstStep(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                    </svg>
                    &nbsp;Edit
                  </span>
                </div>
              </div>
              <span className="mt-2">Last changed on {dateAndTimeFormatter(user?.updatedAt)}</span>
            </div>
          </div>
        </div>
        {/* <span className="fs-4">Manage Your Devices</span>
        <div className="card rounded-7">
          <div className="card-body">
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between ">
                <span className="fs-4" style={{ color: "#525252" }}>
                  Your Devices
                </span>
                <div>
                  <span
                    className="btn btn-outline-secondary rounded-7 d-flex align-items-center"
                    data-bs-toggle="modal"
                    data-bs-target="#manage_devices"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325" />
                    </svg>
                    &nbsp;Edit
                  </span>
                </div>
              </div>
              <span className="mt-2">Last activity at 04.34 pm</span>
            </div>
          </div>
        </div> */}
      </div>
      <ChangePassword firstStep={firstStep} setfirstStep={setfirstStep} />
      <ManageDevices setShowConfirm={setShowConfirm} />
      {showConfirm && (
        <ConfirmRemoveDevices setShowConfirm={setShowConfirm} />
      )}
    </>
  );
};

export default Security;
