import React, { useState } from "react";

const HolidayCalendar = ({ selectedDate, setSelectedDate }) => {
  const currentDate = new Date();
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth());
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];

  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

  const handleDateClick = (day) => {
    const selectedDateObj = new Date(currentYear, currentMonth, day + 1);
    const formattedDate = selectedDateObj.toISOString().split("T")[0];
    if (selectedDate.includes(formattedDate)) {
      const updatedDates = selectedDate?.filter(
        (date) => date !== formattedDate
      );
      setSelectedDate(updatedDates);
    } else {
      setSelectedDate([...selectedDate, formattedDate]);
    }
  };

  const generateCalendar = () => {
    const calendar = [];

    calendar.push(
      <tr key="header">
        {daysOfWeek.map((day) => (
          <th className="ps-1 pe-1" key={day}>
            {day}
          </th>
        ))}
      </tr>
    );

    let dayCounter = 1;

    for (let i = 0; i < 6; i++) {
      const row = [];

      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDayOfMonth) || dayCounter > daysInMonth) {
          row.push(<td key={`empty-${j}`}></td>);
        } else {
          const day = dayCounter;
          const selectedDateObj = new Date(currentYear, currentMonth, day + 1);
          const formattedDate = selectedDateObj.toISOString().split("T")[0];
          const todayDate = new Date().toISOString().split("T")[0] + 1;
          const isNextDay = dayCounter === new Date().getDate() + 1 && currentMonth === currentDate.getMonth();
          
          row.push(
            <td style={{ paddingBlock: "0.65rem" }} key={`day-${dayCounter}`}>
              <div
                className={`align-items-center justify-content-center mx-auto circle pointer ${
                  selectedDate?.includes(formattedDate)
                    ? "bg-primary text-white"
                    : formattedDate >= todayDate 
                    ? "text-dark"
                    : ""
                } ${isNextDay ? "border border-primary" : ""}`}
                style={{
                  height: "26px",
                  width: "26px",
                  boxShadow:
                    "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
                }}
                onClick={() => {
                  if (formattedDate >= todayDate) {
                    handleDateClick(day);
                  }
                }}
              >
                {day}
              </div>
            </td>
          );
          dayCounter++;
        }
      }

      calendar.push(<tr key={`row-${i}`}>{row}</tr>);
    }

    return calendar;
  };

  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between">
          <button
            onClick={goToPreviousMonth}
            disabled={
              currentMonth === currentDate.getMonth() &&
              currentYear === currentDate.getFullYear()
            }
            className="btn btn-icon btn-rounded"
          >
            <span className="feather-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-left-fill"
                viewBox="0 0 16 16"
              >
                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
              </svg>
            </span>
          </button>
          <h4 className="mt-1">
            {new Date(currentYear, currentMonth).toLocaleDateString("en-US", {
              month: "long",
              year: "numeric",
            })}
          </h4>
          <button onClick={goToNextMonth} className="btn btn-icon btn-rounded">
            <span className="feather-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-right-fill"
                viewBox="0 0 16 16"
              >
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
              </svg>
            </span>
          </button>
        </div>
        <table className="table text-center">
          <thead>{generateCalendar()[0]}</thead>
          <tbody>{generateCalendar().slice(1)}</tbody>
        </table>
      </div>
    </div>
  );
};

export default HolidayCalendar;
