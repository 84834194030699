import React, { useEffect, useState } from "react";
import ApiCall from "../../../api/ApiCall";
import { VendorRelatedEndPoints } from "../../../api/Endpoints";
import {
  getCurrentWorkspaceId,
  startApiCall,
} from "../../../global-functions/globalFunctions";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { useLocation, useNavigate } from "react-router-dom";
import DataLoader from "../../../components/Loaders/DataLoader/DataLoader";
import NoData from "../../../components/NoData/NoData";
import { navigateToVendorsReport } from "../../../Navigation/Navigation";

const Analytics = () => {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [allVendors, setAllVendors] = useState([]);
  const location = useLocation();

  const handleGetAllVendor = async () => {
    startApiCall(null, setloader);
    const data = {
      businessId: getCurrentWorkspaceId(),
      startDate: location?.state?.startDate,
      endDate: location?.state?.endDate,
    };
    const res = await ApiCall(
      "post",
      VendorRelatedEndPoints.getVendorReport,
      data
    );
    if (res?.success) {
      setAllVendors(res?.data);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (getCurrentWorkspaceId()) {
      handleGetAllVendor();
    }
  }, []);

  const barChartOptions = {
    plugins: {
      title: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: true,
        },
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
      },
    },
  };

  const barChartData = {
    labels: allVendors?.map((data) => data?.vendorName),

    datasets: [
      {
        label: "Total Amount",
        data: allVendors?.map((data) =>
          data?.totalAmount ? data?.totalAmount : 0
        ),
        backgroundColor: ["#3862DD"],
      },
      {
        label: "Total Paid",
        data: allVendors?.map((data) =>
          data?.totalAmountPaid ? data?.totalAmountPaid : 0
        ),
        backgroundColor: ["#00d67f"],
      },
      {
        label: "Total Due",
        data: allVendors?.map((data) =>
          data?.totalDueAmount ? data?.totalDueAmount : 0
        ),
        backgroundColor: ["#ff0000"],
      },
      {
        label: "Total Advance",
        data: allVendors?.map((data) =>
          data?.totalAdvance ? data?.totalAdvance : 0
        ),
        backgroundColor: ["#18ddef"],
      },
    ],
  };

  return (
    <div className="todoapp-wrap">
      <div className="todoapp-content">
        <div className="todoapp-detail-wrap">
          <header className="todo-header">
            <div className="d-flex align-items-center">
              <span className="todoapp-title link-dark">
                <button
                  className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
                  onClick={() => navigate(navigateToVendorsReport())}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill="currentColor"
                    class="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                  <p className="mb-0">Back</p>
                </button>
                <div className="v-separator d-lg-inline-block"></div>
                <h1>Analytics</h1>
              </span>
            </div>
            <div className="todo-options-wrap">
              <div className="v-separator d-lg-inline-block d-none"></div>
              <span
                className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="dsdsd"
                data-bs-original-title="Refresh"
              >
                <span className="btn-icon-wrap" onClick={handleGetAllVendor}>
                  <span className="feather-icon">
                    <i
                      className="ri-refresh-line"
                      title="Refresh business list"
                    ></i>
                  </span>
                </span>
              </span>
            </div>
          </header>
          <div className="todo-body">
            <div data-simplebar className="nicescroll-bar">
              {!loader && allVendors?.length > 0 && (
                <div className="d-flex justify-content-center w-100" style={{ height: "80vh" }}>
                  <Chart
                    type="bar"
                    data={barChartData}
                    options={barChartOptions}
                  />
                </div>
              )}
              {loader && <DataLoader title="data is loading..." />}
              {!loader && allVendors?.length === 0 && (
                <NoData title="No analytics report" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
