import React from "react";

const ItemsInfoModal = ({ setIsOpenDetailsModal, itemDetails }) => {
  const calculatePercentageAmount = (charge) => {
    let subTotal = itemDetails?.subTotal || 0;
    if (charge?.chargeId?.chargeType === "percentage") {
      const percentageValue = charge?.amount || 0;
      return (subTotal * percentageValue) / 100;
    }
    return 0;
  };

  const getCustomizationItem = (productId) => {
    const tempItem = itemDetails?.customization?.filter(
      (inventory) => inventory?.productId === productId
    );
    return tempItem;
  };

  const calculateCustomizeTotal = (productId) => {
    const total = getCustomizationItem(productId).reduce((accumulator, dt) => {
      const value = dt.price;
      return accumulator + parseInt(value);
    }, 0);
    return parseInt(total);
  };

  return (
    <div className="details-modal">
      <div className="p-3 h-90">
        <div className="d-flex justify-content-between">
          <span className="file-name">Items</span>
          <button
            onClick={() => setIsOpenDetailsModal(false)}
            type="button"
            className="info-close btn-close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="d-flex flex-column justify-content-between gap-3 h-100">
          <div
            className="d-flex flex-column gap-3 h-100 simple-scrollbar pt-3"
            style={{ overflow: "scroll" }}
          >
            {itemDetails?.products?.map((item, index) => {
              return (
                <div className="form-control">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className=" text-truncate mw-175p">
                      {index + 1}. {item?.productId?.productName}
                    </p>
                    <div className="d-flex align-items-center justify-content-between gap-3 w-40">
                      <span
                        className="btn btn-xs text-white"
                        style={{
                          background: "#FA2A2A",
                          borderRadius: " 5.58333px",
                          boxShadow:
                            "inset 0px 5.58333px 5.58333px rgba(0, 0, 0, 0.25)",
                        }}
                      >
                        {item?.quantity}
                      </span>
                      <p>
                        ₹
                        {(
                          item?.productId?.sellingPrice * item?.quantity
                        ).toFixed(2)}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 ">
                    {getCustomizationItem(item?.productId?._id)?.map(
                      (material) => {
                        return (
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex">
                              <p className="mb-1 ms-2 text-capitalize text-smoke text-truncate mw-125p font-2">
                                {material?.quantity +
                                  " " +
                                  material?.unit?.name}
                                . {material?.inventoryItem?.name}
                              </p>
                            </div>

                            <div className="d-flex align-items-center justify-content-end gap-2">
                              <p className="mb-0 text-smoke font-2">
                                + &nbsp; ₹ {material?.price}
                              </p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className="col-sm-12 d-flex justify-content-end">
                    {getCustomizationItem(item?.productId?._id)?.length > 0 && (
                      <div className="d-flex align-items-center justify-content-end gap-2">
                        <p className="mb-0 fw-bold">
                          ₹{" "}
                          {parseInt(item?.productId?.sellingPrice) +
                            calculateCustomizeTotal(item?.productId?._id)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="border py-2 px-3 mb-4">
            <div className="d-flex justify-content-between text-dark">
              <p>Subtotal</p>
              <p>₹{itemDetails?.subTotal?.toFixed(2)}</p>
            </div>
            {itemDetails?.charges?.map((chrg) => (
              <div className="d-flex justify-content-between text-dark">
                <p className="text-capitalize">
                  {chrg?.chargeId?.chargeName}
                  {chrg?.chargeId?.chargeType === "percentage" && (
                    <span className="ms-1 fs-8 mb-1">({chrg?.amount}%)</span>
                  )}
                </p>
                <p>
                  ₹{" "}
                  {chrg?.chargeId?.chargeType === "amount"
                    ? `${chrg?.amount?.toFixed(2)}`
                    : calculatePercentageAmount(chrg)}
                </p>
              </div>
            ))}

            <div className="d-flex justify-content-between text-dark">
              <p>Total</p>
              <p className="text-primary">₹{itemDetails?.amount?.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemsInfoModal;
