import React, { useEffect, useState } from "react";

function UploadProgressModal({ isUploading, totalTimeout }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isUploading) {
      const increment = 100 / (totalTimeout / 1000);
      let timer;

      const updateProgress = () => {
        setProgress((prev) => {
          const newProgress = prev + increment;
          if (newProgress >= 100) {
            clearInterval(timer);
            return 100;
          }
          return newProgress;
        });
      };

      timer = setInterval(updateProgress, 1000);

      return () => clearInterval(timer);
    } else {
      setProgress(0);
    }
  }, [isUploading, totalTimeout]);

  return (
    <div
      className="custom-modal"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered w-500p"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body ">
            <h5 className="ps-2 text-capitalize">Uploading CSV </h5>
            <div className="border-bottom"></div>
            <div className="row gx-3 px-3 mt-3 ">
              <div className="container mt-2">
                <div className="row justify-content-md-center">
                  <div className="col-sm-12">
                    {isUploading && (
                      <>
                        <div
                          className="progress rounded-3 bg-smoke-light-4 w-100"
                          role="progressbar"
                          aria-label="Success striped example"
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ height: "15px", width: "100%" }}
                        >
                          <div
                            className="progress-bar progress-bar-striped bg-success"
                            style={{
                              width: `${progress}%`,
                            }}
                          ></div>
                        </div>
                        <div className="d-flex justify-content-start mt-3 font-3 ms-1 fw-bold">
                          {Math.round(progress)}% Complete
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadProgressModal;
