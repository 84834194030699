import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import OTPBox from "../../../components/OTPBox/OTPBox";
import { useSelector } from "react-redux";
import { startApiCall } from "../../../global-functions/globalFunctions";
import ApiCall from "../../../api/ApiCall";
import { editProfileEndPoints } from "../../../api/Endpoints";
import { toast } from "react-toastify";
import { actionCreators } from "../../../redux-setup";
import ErrorMessage from "../../../components/Error/ErrorMessage";
import ApiLoader from "../../../components/Loaders/ApiLoader/ApiLoader";

const EditPhone = ({ setSuccessModal, setStep1, step1 }) => {
  const [step2, setStep2] = useState(false);

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  // ******** EDIT PHONE **********
  const [otpString, setOtpString] = useState("");
  const [otp, setotp] = useState(new Array(6).fill(""));

  const completeFirstStep = () => {
    setStep1(false);
    setStep2(true);
  };

  const handleResetSecondstep = () => {
    seterrorMessage("");
    setStep2(false);
    setOtpString("");
    setStep1(true);
  };

  const handleReset = () => {
    seterrorMessage("");
    setloader(false);
    setloader2(false);
    setStep2(false);
    setotp(new Array(6).fill(""));
    setOtpString("");
    const closeModalDom = document.getElementById("edit_phone");
    if (closeModalDom) closeModalDom.click();
  }

  // ******** FUNCTION FOR EDIT PHONE **********
  const handleEditPhone = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader);
    if (phoneNumber?.length !== 10) {
      setloader(false);
      seterrorMessage("Number Must be 10 character long");
    } else if (user?.phone === phoneNumber) {
      setloader(false);
      seterrorMessage("You Can't update the same number");
    } else {
      const sendData = {
        phone: phoneNumber,
        userId: user?._id,
      };
      const res = await ApiCall(
        "post",
        editProfileEndPoints.addPhoneNo,
        sendData
      );
      if (res?.success) {
        setotp(new Array(6).fill(""));
        setOtpString("");
        completeFirstStep();
        toast.success("Otp sent successfully!");
        setloader(false);
      } else {
        seterrorMessage(res?.error);
        setloader(false);
      }
    }
  };

  const handleOtpVerify = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setloader2);
    const sendData = {
      otp: otpString,
      userId: user?._id,
    };
    const res = await ApiCall(
      "post",
      editProfileEndPoints.validatePhoneOrEmail,
      sendData
    );
    if (res?.success) {
      const updatedUser = { ...user, phone: phoneNumber };
      dispatch(actionCreators.addAuthenticateUser(updatedUser));
      handleReset();
      setloader2(false);
      toast.success("Phone Number Update Successfully!");
      setSuccessModal(true);
    } else {
      seterrorMessage(res?.error);
      setloader2(false);
    }
  };

  useEffect(() => {
    if (step1) {
      setPhoneNumber(user?.phone ? user?.phone : phoneNumber);
    }
  }, [step1]);

  return (
    <div
      id="edit_phone"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body px-6 py-4">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
                </svg>
              </span>
            </button>
            <h5 className="header_dark">Personal information</h5>
            <form
              onSubmit={(e) => {
                if (step1) {
                  handleEditPhone(e);
                } else {
                  handleOtpVerify(e);
                }
              }}
            >
              {step1 && (
                <>
                  <div className="form-group mt-4">
                    <label className="form-label custom_label">Phone</label>
                    <input
                      className="custom_form_control"
                      type="number"
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                  {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                  <div className="modal-footer border-0 py-0 mt-4">
                    <button
                      className="btn btn-primary"
                      disabled={step1 && phoneNumber?.length !== 10}
                    >
                      {loader ? <ApiLoader /> : "Verify Number"}
                    </button>
                  </div>
                </>
              )}
              {step2 && (
                <>
                  <OTPBox
                    otp={otp}
                    setotp={setotp}
                    otpString={otpString}
                    setOtpString={setOtpString}
                    loader={loader2}
                    description={`Please enter the verification code we sent to your phone number ${phoneNumber}`}
                  />
                  {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                  <div className="modal-footer border-0 py-0 mt-3">
                    <span
                      className="btn btn-secondary"
                      onClick={() => {
                        if (step1) {
                          setStep1(false);
                          setPhoneNumber("");
                          seterrorMessage("");
                        } else {
                          handleResetSecondstep();
                        }
                      }}
                    >
                      Back
                    </span>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loader}
                    >
                      {loader2 ? <ApiLoader /> : "Save changes"}
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPhone;
