import React from "react";
import { Avatar } from "../../../../../components/Avatar/Avatar";
import {
  dateAndTimeFormatter,
  getName,
} from "../../../../../global-functions/globalFunctions";
import { useSelector } from "react-redux";

function DealInfoCard({
  selectTab,
  isUploading,
  handleAddAttachment,
  dealDetails,
  handleRemoveAttachment,
  setEditDealData,
  seteditGroup,
  setgroupAssigneeModal,
  setassigneeModal,
  seteditDealParticipant,
}) {
  const user = useSelector((state) => state.user);
  return (
    <>
      <div className="d-flex flex-column gap-2 h-420p overflow-y-scroll simple-scrollbar">
        {selectTab === "Attachment" && (
          <>
            <>
              <div className="d-flex align-items-center justify-content-between ps-3 mt-2">
                <p className="text-dark fs-5">Attachment</p>
                <div className="d-flex align-items-end gap-3">
                  {!isUploading ? (
                    <>
                      <input
                        type="file"
                        name="image"
                        id="profilePhoto"
                        accept="/*"
                        className="input-file justify-content-end"
                        onChange={handleAddAttachment}
                      />
                      <label
                        htmlFor={isUploading ? "" : "profilePhoto"}
                        className="pointer text-center"
                      >
                        <span className="btn btn-sm btn-outline-light">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            width="14"
                            height="14"
                            className="me-1"
                          >
                            <path d="M14.8287 7.75737L9.1718 13.4142C8.78127 13.8047 8.78127 14.4379 9.1718 14.8284C9.56232 15.219 10.1955 15.219 10.586 14.8284L16.2429 9.17158C17.4144 8.00001 17.4144 6.10052 16.2429 4.92894C15.0713 3.75737 13.1718 3.75737 12.0002 4.92894L6.34337 10.5858C4.39075 12.5384 4.39075 15.7042 6.34337 17.6569C8.29599 19.6095 11.4618 19.6095 13.4144 17.6569L19.0713 12L20.4855 13.4142L14.8287 19.0711C12.095 21.8047 7.66283 21.8047 4.92916 19.0711C2.19549 16.3374 2.19549 11.9053 4.92916 9.17158L10.586 3.51473C12.5386 1.56211 15.7045 1.56211 17.6571 3.51473C19.6097 5.46735 19.6097 8.63317 17.6571 10.5858L12.0002 16.2427C10.8287 17.4142 8.92916 17.4142 7.75759 16.2427C6.58601 15.0711 6.58601 13.1716 7.75759 12L13.4144 6.34316L14.8287 7.75737Z"></path>
                          </svg>{" "}
                          Attach
                        </span>
                      </label>
                    </>
                  ) : (
                    <span className="btn btn-sm btn-outline-light">
                      please wait..
                    </span>
                  )}
                </div>
              </div>
              {isUploading && (
                <span>Please wait until attachment finishes uploading...</span>
              )}
              {dealDetails?.attachment?.length === 0 && !isUploading && (
                <span className="ms-3 mt-2"> No Attachments.</span>
              )}
              <div className="d-flex justify-content-start flex-wrap gap-3 p-2 mt-1 ps-3">
                {dealDetails?.attachment?.map((att, index) => {
                  return (
                    <>
                      {/* ********** IMAGE VIEW **********  */}
                      {/* {att?.type?.includes("image") && ( */}
                      <div className="w-120p text-center shadow-hover p-1 border d-flex flex-column gap-1">
                        <div className="w-100 h-60p d-flex align-items-center justify-content-center p-2 border">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            className="bi bi-file-earmark"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
                          </svg>
                        </div>
                        <p className="text-dark fs-7 text-truncate mw-150p">
                          {att?.name}
                        </p>
                        <div className="d-flex align-items-center justify-content-between m-1">
                          <a
                            href={att?.url}
                            download
                            className="btn btn-sm btn-rounded btn-icon btn-outline-light"
                          >
                            <span
                              title="Download File"
                              className="feather-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-download"
                                viewBox="0 0 16 16"
                              >
                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                              </svg>
                            </span>
                          </a>
                          <span className="btn btn-sm btn-rounded btn-icon btn-outline-danger">
                            <span
                              onClick={() => {
                                if (!isUploading) {
                                  handleRemoveAttachment(index);
                                } else {
                                  //NO CLICK
                                }
                              }}
                              title="Delete File"
                              className="feather-icon"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                              </svg>
                            </span>
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          </>
        )}

        {/* About section */}
        {selectTab === "Deal info" && (
          <>
            <div
              className="d-flex w-100 justify-content-end pointer"
              data-bs-toggle="modal"
              data-bs-target="#create_new_deal"
            >
              <span
                className="btn btn-sm btn-outline-primary"
                onClick={() => {
                  setEditDealData(dealDetails);
                }}
              >
                {" "}
                Edit&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-pencil-square"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path
                    fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                  />
                </svg>
              </span>
            </div>
            <div className="d-flex flex-column px-4 gap-1">
              <p className="text-capitalize mt-2">
                1. Deal Name : &nbsp;
                <span className="text-dark">{dealDetails?.title}</span>
              </p>
              <p className="text-capitalize mt-2">
                2. Deal Description : &nbsp;
                <span className="text-dark">
                  {dealDetails?.note ? dealDetails?.note : "NA"}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                3. Deal Amount : &nbsp;
                <span className="text-teal">
                  {dealDetails?.amount ? `₹ ${dealDetails?.amount}` : "NA"}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                4. Closing Amount : &nbsp;
                <span className="text-teal">
                  {dealDetails?.closingAmount
                    ? `₹ ${dealDetails?.closingAmount}`
                    : "NA"}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                5. Assigned Group
                {dealDetails?.participantsGroupId?.length === 0 && (
                  <span
                    className="text-primary border pointer p-1 ms-2"
                    data-bs-toggle="modal"
                    data-bs-target="#select_group_crm"
                    onClick={() => {
                      setgroupAssigneeModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="20"
                      fill="currentColor"
                      className="bi bi-plus-square-dotted me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 0q-.25 0-.487.048l.194.98A1.5 1.5 0 0 1 2.5 1h.458V0zm2.292 0h-.917v1h.917zm1.833 0h-.917v1h.917zm1.833 0h-.916v1h.916zm1.834 0h-.917v1h.917zm1.833 0h-.917v1h.917zM13.5 0h-.458v1h.458q.151 0 .293.029l.194-.981A2.5 2.5 0 0 0 13.5 0m2.079 1.11a2.5 2.5 0 0 0-.69-.689l-.556.831q.248.167.415.415l.83-.556zM1.11.421a2.5 2.5 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415zM16 2.5q0-.25-.048-.487l-.98.194q.027.141.028.293v.458h1zM.048 2.013A2.5 2.5 0 0 0 0 2.5v.458h1V2.5q0-.151.029-.293zM0 3.875v.917h1v-.917zm16 .917v-.917h-1v.917zM0 5.708v.917h1v-.917zm16 .917v-.917h-1v.917zM0 7.542v.916h1v-.916zm15 .916h1v-.916h-1zM0 9.375v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .916v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .917v.458q0 .25.048.487l.98-.194A1.5 1.5 0 0 1 1 13.5v-.458zm16 .458v-.458h-1v.458q0 .151-.029.293l.981.194Q16 13.75 16 13.5M.421 14.89c.183.272.417.506.69.689l.556-.831a1.5 1.5 0 0 1-.415-.415zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373Q2.25 16 2.5 16h.458v-1H2.5q-.151 0-.293-.029zM13.5 16q.25 0 .487-.048l-.194-.98A1.5 1.5 0 0 1 13.5 15h-.458v1zm-9.625 0h.917v-1h-.917zm1.833 0h.917v-1h-.917zm1.834-1v1h.916v-1zm1.833 1h.917v-1h-.917zm1.833 0h.917v-1h-.917zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                    </svg>
                    Add Group
                  </span>
                )}
                {dealDetails?.participantsGroupId?.length > 0 && (
                  <>
                    {dealDetails?.participantsGroupId?.map((item, index) => {
                      return (
                        <span className="text-dark text-capitalize fw-bold text-center">
                          &nbsp;{index + 1}. {item?.name}
                        </span>
                      );
                    })}

                    <span
                      className="btn btn-sm btn-outline-light pointer ms-3 text-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#select_group_crm"
                      onClick={() => {
                        seteditGroup(dealDetails?.participantsGroupId);
                        setgroupAssigneeModal(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        className="bi bi-pencil-square me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                        />
                      </svg>
                    </span>
                  </>
                )}
              </p>
              <p className="text-capitalize mt-2">
                6. Assigned Participant : &nbsp;
                {dealDetails?.assignedTo?.length === 0 && (
                  <span
                    className="text-primary border pointer p-1"
                    onClick={() => {
                      setassigneeModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="20"
                      fill="currentColor"
                      className="bi bi-plus-square-dotted me-2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5 0q-.25 0-.487.048l.194.98A1.5 1.5 0 0 1 2.5 1h.458V0zm2.292 0h-.917v1h.917zm1.833 0h-.917v1h.917zm1.833 0h-.916v1h.916zm1.834 0h-.917v1h.917zm1.833 0h-.917v1h.917zM13.5 0h-.458v1h.458q.151 0 .293.029l.194-.981A2.5 2.5 0 0 0 13.5 0m2.079 1.11a2.5 2.5 0 0 0-.69-.689l-.556.831q.248.167.415.415l.83-.556zM1.11.421a2.5 2.5 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415zM16 2.5q0-.25-.048-.487l-.98.194q.027.141.028.293v.458h1zM.048 2.013A2.5 2.5 0 0 0 0 2.5v.458h1V2.5q0-.151.029-.293zM0 3.875v.917h1v-.917zm16 .917v-.917h-1v.917zM0 5.708v.917h1v-.917zm16 .917v-.917h-1v.917zM0 7.542v.916h1v-.916zm15 .916h1v-.916h-1zM0 9.375v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .916v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .917v.458q0 .25.048.487l.98-.194A1.5 1.5 0 0 1 1 13.5v-.458zm16 .458v-.458h-1v.458q0 .151-.029.293l.981.194Q16 13.75 16 13.5M.421 14.89c.183.272.417.506.69.689l.556-.831a1.5 1.5 0 0 1-.415-.415zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373Q2.25 16 2.5 16h.458v-1H2.5q-.151 0-.293-.029zM13.5 16q.25 0 .487-.048l-.194-.98A1.5 1.5 0 0 1 13.5 15h-.458v1zm-9.625 0h.917v-1h-.917zm1.833 0h.917v-1h-.917zm1.834-1v1h.916v-1zm1.833 1h.917v-1h-.917zm1.833 0h.917v-1h-.917zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                    </svg>
                    Add Participant
                  </span>
                )}
                {dealDetails?.assignedTo?.length > 0 && (
                  <>
                    {dealDetails?.assignedTo?.map((item) => {
                      return (
                        <span className="text-dark ms-2">
                          <Avatar
                            name={item?.name}
                            count={1}
                            image={item?.image}
                            size="xxs"
                            color="primary"
                          />
                          <span className="ms-1">{item?.name}</span>
                        </span>
                      );
                    })}
                    <span
                      className="btn btn-sm btn-outline-light pointer ms-3 text-primary"
                      onClick={() => {
                        setassigneeModal(true);
                        seteditDealParticipant(dealDetails?.assignedTo);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        className="bi bi-pencil-square me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fill-rule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                        />
                      </svg>
                    </span>
                  </>
                )}
              </p>

              <p className="text-capitalize mt-2">
                7. Date Created : &nbsp;
                <span className="text-dark">
                  {dateAndTimeFormatter(dealDetails?.createdAt)}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                8. Deal Creator : &nbsp;
                <span className="text-dark">
                  <Avatar
                    name={
                      dealDetails?.createdBy
                        ? dealDetails?.createdBy?.name
                        : dealDetails?.convertedBy?.name
                    }
                    count={1}
                    image={
                      dealDetails?.createdBy
                        ? dealDetails?.createdBy?.image
                        : dealDetails?.convertedBy?.image
                    }
                    size="xss"
                    color="primary"
                  />
                  <span className="ms-1">
                    {getName(
                      dealDetails?.createdBy
                        ? dealDetails?.createdBy
                        : dealDetails?.convertedBy,
                      user?._id
                    )}
                  </span>
                </span>
              </p>
              <p className="text-capitalize mt-2">
                9. Last Updated : &nbsp;
                <span className="text-dark">
                  {dateAndTimeFormatter(dealDetails?.updatedAt)}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                10. Closing Date: &nbsp;
                <span className="text-dark">
                  {dateAndTimeFormatter(dealDetails?.closingDate)}
                </span>
              </p>
              <p className="text-capitalize  mt-2">
                11. Status Group :
                <span className="text-dark ms-2">
                  {dealDetails?.groupId?.name}
                </span>
              </p>
              <p className="text-capitalize  mt-2">
                12. Status Type :
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill={dealDetails?.initialStatusId?.color}
                    className="bi bi-circle-fill ms-1 me-1"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                  &nbsp;
                  <span className="text-dark">
                    {dealDetails?.initialStatusId?.name}
                  </span>
                </span>
              </p>
              <p className="text-capitalize  mt-2">
                13. Status Step :
                <span className="ms-1 me-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    fill={dealDetails?.subStatusId?.color}
                    className="bi bi-circle-fill ms-1 me-1"
                    viewBox="0 0 16 16"
                  >
                    <circle cx="8" cy="8" r="8" />
                  </svg>
                  &nbsp;
                  <span className="text-dark">
                    {dealDetails?.subStatusId?.name}
                  </span>
                </span>
              </p>

              <p className="text-capitalize mt-2">
                14. Company :{" "}
                <span className="text-dark">
                  {dealDetails?.businessName ? dealDetails?.businessName : "NA"}
                </span>
              </p>
              <p className="mt-2">
                15. Website Link:{" "}
                <span className="text-primary">
                  {dealDetails?.websiteLink ? dealDetails?.websiteLink : "NA"}
                </span>
              </p>

              <p className="text-capitalize mt-2">
                16. Deal Source : &nbsp;
                <span className="text-dark">{dealDetails?.source}</span>
              </p>
              <p className="text-capitalize mt-2">
                16. Business type : &nbsp;
                <span className="text-dark">
                  {dealDetails?.businessType ? dealDetails?.businessType : "NA"}
                </span>
              </p>
              {dealDetails?.additionalInfo?.length > 0 &&
                dealDetails?.additionalInfo?.map((fld, index) => {
                  const isValidURL = (string) => {
                    try {
                      new URL(string);
                      return true;
                    } catch (_) {
                      return false;
                    }
                  };

                  return (
                    <p className="mt-2" key={index}>
                      {16 + index} . {fld?.fieldName} : &nbsp;
                      <span className="text-dark">
                        {fld?.fieldValue ? (
                          isValidURL(fld?.fieldValue) ? (
                            <a
                              href={fld?.fieldValue}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {fld?.fieldValue}
                            </a>
                          ) : (
                            fld?.fieldValue
                          )
                        ) : (
                          "NA"
                        )}
                      </span>
                    </p>
                  );
                })}
            </div>
          </>
        )}

        {/* Contact section */}
        {selectTab === "Contact info" && (
          <>
            <div className="d-flex flex-column px-4 gap-1">
              <p className="text-capitalize mt-2">
                1. Full Name :{" "}
                <span className="text-dark">
                  {dealDetails?.contactId?.name
                    ? dealDetails?.contactId?.name
                    : "NA"}
                </span>
              </p>
              <p className="text-capitalize mt-2">
                2. Phone Number :{" "}
                <span className="text-dark">
                  {dealDetails?.contactId?.phone}
                </span>
              </p>
              <p className="mt-2">
                3. Email Address :{" "}
                <span className="text-dark">
                  {dealDetails?.contactId?.email
                    ? dealDetails?.contactId?.email
                    : "NA"}
                </span>
              </p>
              <p className="mt-2 text-capitalize">
                4. State :{" "}
                <span className="text-dark">
                  {dealDetails?.contactId?.state
                    ? dealDetails?.contactId?.state
                    : "NA"}
                </span>
              </p>
              <p className="mt-2 text-capitalize">
                5. City :{" "}
                <span className="text-dark">
                  {dealDetails?.contactId?.city
                    ? dealDetails?.contactId?.city
                    : "NA"}
                </span>
              </p>
              <p className="mt-2">
                6. Pincode :{" "}
                <span className="text-dark">
                  {dealDetails?.contactId?.pinCode
                    ? dealDetails?.contactId?.pinCode
                    : "NA"}
                </span>
              </p>
              <p className="mt-2 text-capitalize">
                7. Address :{" "}
                <span className="text-dark">
                  {dealDetails?.contactId?.address
                    ? dealDetails?.contactId?.address
                    : "NA"}
                </span>
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DealInfoCard;
