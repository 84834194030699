import React from "react";
import { useNavigate } from "react-router-dom";
import { navigateToOrderHistory } from "../../../../Navigation/Navigation";

const InvoiceHeader = ({
  handleGetAllInvoice,
  search,
  handleSearch,
  selectStatus,
  setSelectStatus,
  dateType,
  setdateType,
  setdateModal,
  handleResetFilters,
}) => {
  const navigate = useNavigate();

  const allStatus = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Confirmed",
      value: "confirmed",
    },
    {
      name: "Pending",
      value: "pending",
    },
    {
      name: "Due",
      value: "due",
    },
  ];

  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title link-dark">
          <button
            className="btn btn-outline text-primary btn-md d-flex align-items-center gap-1"
            onClick={() => navigate(navigateToOrderHistory())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <p className="mb-0">Back</p>
          </button>
          <div className="v-separator d-lg-inline-block"></div>
          <h1>All Invoices</h1>
        </span>
      </div>
      <div className="todo-options-wrap">
        <div className="me-2">
          <select
            className="form-select form-select"
            value={selectStatus}
            onChange={(e) => {
              setSelectStatus(e.target.value);
            }}
          >
            {allStatus?.map((status) => {
              return <option value={status?.value}>{status?.name}</option>;
            })}
          </select>
        </div>
        <div className="selectable-dropdown me-1">
          <div className="dropdown selectable-dropdown">
            <span
              className={`fs-7 fw-medium text-dark text-capitalize border rounded p-2`}
              aria-expanded="false"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="text-secondary"> Date :&nbsp;</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
              </svg>
              &nbsp; {dateType}
            </span>
            <div
              role="menu"
              className="dropdown-menu"
              style={{ maxHeight: "40vh", overflow: "auto" }}
            >
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Today" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Today");
                    handleResetFilters();
                  }}
                >
                  1. Today
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Yesterday" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Yesterday");
                    handleResetFilters();
                  }}
                >
                  2. Yesterday
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className={`dropdown-item text-capitalize ${
                    dateType === "Date Range" ? "fw-bold" : ""
                  }`}
                  onClick={() => {
                    setdateType("Date Range");
                    setdateModal(true);
                  }}
                >
                  3. Date Range
                </div>
              </div>
            </div>
          </div>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllInvoice}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-lg-block" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
          />
        </form>
      </div>
    </header>
  );
};

export default InvoiceHeader;
