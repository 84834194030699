import React, { useEffect, useState } from "react";
import {
  filterSearchData,
  getAllEmployees,
  getAvatarClass,
  getCurrentWorkspaceId,
  getWorkSpaceRole,
  startApiCall,
} from "../../global-functions/globalFunctions";
import { Avatar } from "../Avatar/Avatar";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import ApiLoader from "../Loaders/ApiLoader/ApiLoader";
import DataLoader from "../Loaders/DataLoader/DataLoader";

function SelectEmployeeModal(props) {
  const {
    setisSelectedEmployeeModal,
    isSelectedEmployeeModal,
    listId,
    user,
    selectedEmployees,
    setselectedEmployees,
    selectedEmpId,
    setselectedEmpId,
    Crm,
  } = props;
  const [allEmployees, setallEmployees] = useState([]);
  const [searchKey, setsearchKey] = useState("");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  const filteredEmployees = filterSearchData(allEmployees, searchKey);

  const handleGetAllEmployee = async () => {
    startApiCall(null, setloader);
    if (Crm) {
      const res2 = await getAllEmployees({
        pageLimit: 50000,
        pageNumber: 1,
        searchKey: searchKey,
        businessId: getCurrentWorkspaceId(),
      });
      setallEmployees(res2?.data);
    } else {
      const res = await getAllEmployees({
        pageLimit: 50000,
        pageNumber: 1,
        s2adminId: user?._id,
        searchKey: searchKey,
        businessId: getCurrentWorkspaceId(),
        accessFor: "forAssignTask",
        listId: listId ? listId : "",
      });
      setallEmployees(res?.data);
    }

    setloader(false);
  };

  useEffect(() => {
    if (isSelectedEmployeeModal) {
      handleGetAllEmployee();
    }
  }, [isSelectedEmployeeModal]);

  const closeModal = (e) => {
    e && e.preventDefault();
    setallEmployees([]);
    setisSelectedEmployeeModal(false);
    setsearchKey("");
    const closeModalDom = document.getElementById(`select_employee`);
    if (closeModalDom) closeModalDom.click();
  };

  const toggleEmployeeSelection = (emp) => {
    setErrorMessage && setErrorMessage("");
    if (selectedEmpId?.includes(emp)) {
      selectedEmpId?.splice(selectedEmpId?.indexOf(emp), 1);
      setselectedEmpId([...selectedEmpId]);
    } else if (!selectedEmpId?.includes(emp)) {
      selectedEmpId?.push(emp);
      setselectedEmpId([...selectedEmpId]);
    }
  };

  useEffect(() => {
    let temp = [];
    selectedEmpId?.map((ae) => {
      allEmployees?.map((emp) => {
        if (emp?._id === ae) {
          temp.push(emp);
        }
      });
    });
    setselectedEmployees(temp);
  }, [selectedEmpId]);

  return (
    <div
      id="select_employee"
      className="modal fade add-new-contact"
      tabIndex="-1"
      role="dialog"
      style={{
        display: isSelectedEmployeeModal ? "block" : "none",
        pointerEvents: loader ? "none" : "",
      }}
      aria-hidden="true"
    >
      <form onSubmit={closeModal}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                onClick={closeModal}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <h5 className="mb-3">Select Employee</h5>
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-end align-items-center">
                  <span className="input-affix-wrapper input-search affix-border">
                    <input
                      type="text"
                      className="form-control bg-transparent"
                      data-navbar-search-close="false"
                      placeholder="Search People"
                      aria-label="Search"
                      value={searchKey}
                      onChange={(e) => {
                        setsearchKey(e.target.value);
                      }}
                    />
                    <span className="input-suffix">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </span>
                    </span>
                  </span>
                </div>
              </div>

              <div className="collapse-simple">
                <div className="card">
                  <div className="card-header">
                    <span
                      role="button"
                      data-bs-toggle="collapse"
                      href="#space_members"
                      aria-expanded="true"
                      className="fw-light text-uppercase"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-people-fill"
                          viewBox="0 2 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>{" "}
                        People
                        <span class="badge badge-pill badge-sm badge-soft-primary ms-1">
                          {allEmployees?.length}
                        </span>
                      </span>

                      <div className="avatar-group avatar-group-sm avatar-group-overlapped me-6 pointer">
                        {selectedEmployees?.slice(0, 4)?.map((emp, index) => {
                          let name = emp?.name;
                          const nameInitial = name?.[0]?.toUpperCase();
                          const avatarClass = getAvatarClass(nameInitial);

                          return (
                            <CustomTooltip text={emp?.name} placement="bottom">
                              <div
                                key={index}
                                className={`avatar avatar-xs ${avatarClass} avatar-rounded`}
                              >
                                <span className="initial-wrap">
                                  {nameInitial}
                                </span>
                              </div>
                            </CustomTooltip>
                          );
                        })}
                        {selectedEmployees?.length > 4 && (
                          <div className="avatar avatar-xs avatar-soft-indigo avatar-rounded">
                            <span className="initial-wrap">
                              +{selectedEmployees.length - 4}
                            </span>
                          </div>
                        )}
                      </div>
                    </span>
                  </div>

                  <div
                    id="space_members"
                    className="collapse show mh-430p "
                    style={{ overflowY: "scroll" }}
                  >
                    <div className="card-body">
                      {!loader && filteredEmployees?.length > 0 && (
                        <ul className="activity-list list-group list-group-flush">
                          {filteredEmployees?.map((employee) => {
                            const nameInitial =
                              employee.name?.[0].toUpperCase();
                            const avatarClass = getAvatarClass(nameInitial);
                            return (
                              <li className="list-group-item" key={employee.id}>
                                <div className="media">
                                  <div className="media-head">
                                    <Avatar
                                      name={employee?.name}
                                      count={1}
                                      image={employee?.image}
                                      size="xss"
                                      color="primary"
                                    />
                                  </div>
                                  <div className="media-body row">
                                    <div className="col-md-8 d-flex justify-content-between">
                                      <span
                                        className="text-dark"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {employee.name}
                                      </span>
                                      <span
                                        className={`badge badge-soft-primary`}
                                      >
                                        {getWorkSpaceRole(
                                          employee?.permissions,
                                          "roleName"
                                        )}
                                      </span>
                                    </div>

                                    <div className="col-md-4 d-flex justify-content-center">
                                      <input
                                        className="form-check-input success pointer"
                                        type="checkbox"
                                        value=""
                                        onChange={() =>
                                          toggleEmployeeSelection(employee?._id)
                                        }
                                        checked={selectedEmpId?.includes(
                                          employee?._id
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {!loader && filteredEmployees?.length === 0 && (
                        <p className="text-capitalize text-center text-dark">
                          No employee found
                        </p>
                      )}

                      {loader && <DataLoader />}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="button"
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-add-tasklist"
                >
                  {loader2 ? <ApiLoader /> : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SelectEmployeeModal;
