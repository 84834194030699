import React from "react";

const PerformanceHeader = ({
  filterType,
  setFilterType,
  handleRefresh,
  setdateModal,
  searchKey,
  setsearchKey,
}) => {
  return (
    <header className="todo-header">
      <span className="todoapp-title link-dark ext-capitalize">
        <h5 className="task-text mt-2">Performance</h5>
      </span>
      <div className="todo-options-wrap">
        <div className="todo-toolbar">
          <select
            className="form-select form-select-md "
            value={filterType}
            onChange={(e) => {
              if (e.target.value === "Date Range") {
                setdateModal(true);
                setFilterType(e.target.value);
              } else {
                setFilterType(e.target.value);
              }
            }}
          >
            <option value="thismonth">This Month</option>
            <option value="lastmonth">Last Month</option>
            <option value="thisweek">This Week</option>
            <option value="lastweek">Last Week</option>
            <option value="Date Range">Date Range</option>
          </select>
        </div>
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
          onClick={() => {
            handleRefresh();
          }}
        >
          <span className="btn-icon-wrap">
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
        <div className="v-separator d-lg-inline-block d-none"></div>
        <form className="d-sm-block d-none me-2" role="search">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={(e) => {
              setsearchKey(e.target.value);
            }}
            value={searchKey}
          />
        </form>
      </div>
    </header>
  );
};

export default PerformanceHeader;
