import React from "react";

const HolidayHeader = ({
  setAddHoliday,
  handleGetAllHolidays,
  isModuleReadWriteAccessForHoliday,
}) => {
  return (
    <header className="todo-header">
      <div className="d-flex align-items-center">
        <span className="todoapp-title  link-dark">
          <h1>All Holidays</h1>
          {isModuleReadWriteAccessForHoliday && (
            <div className="ms-3">
              <button
                className="btn btn-sm btn-outline-secondary flex-shrink-0 d-lg-inline-block"
                onClick={() => setAddHoliday(true)}
              >
                Add Holiday
              </button>
            </div>
          )}
        </span>
      </div>
      <div className="todo-options-wrap">
        <span
          className="btn btn-icon btn-flush-dark btn-rounded flush-soft-hover no-caret d-lg-inline-block me-0"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="dsdsd"
          data-bs-original-title="Refresh"
        >
          <span className="btn-icon-wrap" onClick={handleGetAllHolidays}>
            <span className="feather-icon">
              <i className="ri-refresh-line" title="Refresh business list"></i>
            </span>
          </span>
        </span>
      </div>
    </header>
  );
};

export default HolidayHeader;
